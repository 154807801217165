export const postalCodes = [
  { label: "L'ABERGEMENT CLEMENCIAT (01400)", value: "01400" },
  { label: "L'ABERGEMENT DE VAREY (01640)", value: "01640" },
  { label: "AMBERIEU EN BUGEY (01500)", value: "01500" },
  { label: "AMBERIEUX EN DOMBES (01330)", value: "01330" },
  { label: "AMBLEON (01300)", value: "01300" },
  { label: "AMBRONAY (01500)", value: "01500" },
  { label: "AMBUTRIX (01500)", value: "01500" },
  { label: "ANDERT ET CONDON (01300)", value: "01300" },
  { label: "ANGLEFORT (01350)", value: "01350" },
  { label: "APREMONT (01100)", value: "01100" },
  { label: "ARANC (01110)", value: "01110" },
  { label: "ARANDAS (01230)", value: "01230" },
  { label: "ARBENT (01100)", value: "01100" },
  { label: "ARBOYS EN BUGEY (01300)", value: "01300" },
  { label: "ARBOYS EN BUGEY (01300)", value: "01300" },
  { label: "ARBIGNY (01190)", value: "01190" },
  { label: "ARGIS (01230)", value: "01230" },
  { label: "ARMIX (01510)", value: "01510" },
  { label: "ARS SUR FORMANS (01480)", value: "01480" },
  { label: "ARTEMARE (01510)", value: "01510" },
  { label: "ASNIERES SUR SAONE (01570)", value: "01570" },
  { label: "ATTIGNAT (01340)", value: "01340" },
  { label: "BAGE DOMMARTIN (01380)", value: "01380" },
  { label: "BAGE DOMMARTIN (01380)", value: "01380" },
  { label: "BAGE LE CHATEL (01380)", value: "01380" },
  { label: "BALAN (01360)", value: "01360" },
  { label: "BANEINS (01990)", value: "01990" },
  { label: "BEAUPONT (01270)", value: "01270" },
  { label: "BEAUREGARD (01480)", value: "01480" },
  { label: "BELLIGNAT (01100)", value: "01100" },
  { label: "BELIGNEUX (01360)", value: "01360" },
  { label: "BELIGNEUX (01360)", value: "01360" },
  { label: "BELIGNEUX (01360)", value: "01360" },
  { label: "BELIGNEUX (01360)", value: "01360" },
  { label: "VALSERHONE (01200)", value: "01200" },
  { label: "VALSERHONE (01200)", value: "01200" },
  { label: "VALSERHONE (01200)", value: "01200" },
  { label: "VALSERHONE (01200)", value: "01200" },
  { label: "VALSERHONE (01200)", value: "01200" },
  { label: "BELLEY (01300)", value: "01300" },
  { label: "BELLEYDOUX (01130)", value: "01130" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "VALROMEY SUR SERAN (01260)", value: "01260" },
  { label: "BENONCES (01470)", value: "01470" },
  { label: "BENY (01370)", value: "01370" },
  { label: "BEREZIAT (01340)", value: "01340" },
  { label: "BETTANT (01500)", value: "01500" },
  { label: "BEY (01290)", value: "01290" },
  { label: "BEYNOST (01700)", value: "01700" },
  { label: "BILLIAT (01200)", value: "01200" },
  { label: "BIRIEUX (01330)", value: "01330" },
  { label: "BIZIAT (01290)", value: "01290" },
  { label: "BLYES (01150)", value: "01150" },
  { label: "LA BOISSE (01120)", value: "01120" },
  { label: "BOISSEY (01190)", value: "01190" },
  { label: "BOISSEY (01380)", value: "01380" },
  { label: "BOLOZON (01450)", value: "01450" },
  { label: "BOULIGNEUX (01330)", value: "01330" },
  { label: "BOURG EN BRESSE (01000)", value: "01000" },
  { label: "BOURG EN BRESSE (01000)", value: "01000" },
  { label: "BOURG ST CHRISTOPHE (01800)", value: "01800" },
  { label: "BOURG ST CHRISTOPHE (01800)", value: "01800" },
  { label: "BOYEUX ST JEROME (01640)", value: "01640" },
  { label: "BOZ (01190)", value: "01190" },
  { label: "BREGNIER CORDON (01300)", value: "01300" },
  { label: "BRENOD (01110)", value: "01110" },
  { label: "BRENS (01300)", value: "01300" },
  { label: "BRESSOLLES (01360)", value: "01360" },
  { label: "BRESSOLLES (01360)", value: "01360" },
  { label: "BRION (01460)", value: "01460" },
  { label: "BRIORD (01470)", value: "01470" },
  { label: "BUELLAS (01310)", value: "01310" },
  { label: "BUELLAS (01310)", value: "01310" },
  { label: "LA BURBANCHE (01510)", value: "01510" },
  { label: "CEIGNES (01430)", value: "01430" },
  { label: "CERDON (01450)", value: "01450" },
  { label: "CERTINES (01240)", value: "01240" },
  { label: "CESSY (01170)", value: "01170" },
  { label: "CEYZERIAT (01250)", value: "01250" },
  { label: "CEYZERIEU (01350)", value: "01350" },
  { label: "CHALAMONT (01320)", value: "01320" },
  { label: "CHALEINS (01480)", value: "01480" },
  { label: "CHALEY (01230)", value: "01230" },
  { label: "CHALLES LA MONTAGNE (01450)", value: "01450" },
  { label: "CHALLEX (01630)", value: "01630" },
  { label: "CHAMPAGNE EN VALROMEY (01260)", value: "01260" },
  { label: "CHAMPAGNE EN VALROMEY (01260)", value: "01260" },
  { label: "CHAMPAGNE EN VALROMEY (01260)", value: "01260" },
  { label: "CHAMPDOR CORCELLES (01110)", value: "01110" },
  { label: "CHAMPDOR CORCELLES (01110)", value: "01110" },
  { label: "CHAMPFROMIER (01410)", value: "01410" },
  { label: "CHANAY (01420)", value: "01420" },
  { label: "CHANEINS (01990)", value: "01990" },
  { label: "CHANOZ CHATENAY (01400)", value: "01400" },
  { label: "LA CHAPELLE DU CHATELARD (01240)", value: "01240" },
  { label: "CHARIX (01130)", value: "01130" },
  { label: "CHARNOZ SUR AIN (01800)", value: "01800" },
  { label: "CHATEAU GAILLARD (01500)", value: "01500" },
  { label: "CHATENAY (01320)", value: "01320" },
  { label: "CHATILLON LA PALUD (01320)", value: "01320" },
  { label: "CHATILLON SUR CHALARONNE (01400)", value: "01400" },
  { label: "CHAVANNES SUR REYSSOUZE (01190)", value: "01190" },
  { label: "NIVIGNE ET SURAN (01250)", value: "01250" },
  { label: "NIVIGNE ET SURAN (01250)", value: "01250" },
  { label: "CHAVEYRIAT (01660)", value: "01660" },
  { label: "CHAZEY BONS (01300)", value: "01300" },
  { label: "CHAZEY BONS (01510)", value: "01510" },
  { label: "CHAZEY SUR AIN (01150)", value: "01150" },
  { label: "CHEIGNIEU LA BALME (01510)", value: "01510" },
  { label: "CHEVILLARD (01430)", value: "01430" },
  { label: "CHEVROUX (01190)", value: "01190" },
  { label: "CHEVRY (01170)", value: "01170" },
  { label: "CHEZERY FORENS (01200)", value: "01200" },
  { label: "CHEZERY FORENS (01410)", value: "01410" },
  { label: "CIVRIEUX (01390)", value: "01390" },
  { label: "CIZE (01250)", value: "01250" },
  { label: "CLEYZIEU (01230)", value: "01230" },
  { label: "COLIGNY (01270)", value: "01270" },
  { label: "COLLONGES (01550)", value: "01550" },
  { label: "COLOMIEU (01300)", value: "01300" },
  { label: "CONAND (01230)", value: "01230" },
  { label: "CONDAMINE (01430)", value: "01430" },
  { label: "CONDEISSIAT (01400)", value: "01400" },
  { label: "CONFORT (01200)", value: "01200" },
  { label: "CONFRANCON (01310)", value: "01310" },
  { label: "CONTREVOZ (01300)", value: "01300" },
  { label: "CONZIEU (01300)", value: "01300" },
  { label: "CORBONOD (01420)", value: "01420" },
  { label: "CORLIER (01110)", value: "01110" },
  { label: "CORMORANCHE SUR SAONE (01290)", value: "01290" },
  { label: "CORMOZ (01560)", value: "01560" },
  { label: "CORVEISSIAT (01250)", value: "01250" },
  { label: "COURMANGOUX (01370)", value: "01370" },
  { label: "COURTES (01560)", value: "01560" },
  { label: "CRANS (01320)", value: "01320" },
  { label: "BRESSE VALLONS (01340)", value: "01340" },
  { label: "BRESSE VALLONS (01340)", value: "01340" },
  { label: "CRESSIN ROCHEFORT (01350)", value: "01350" },
  { label: "CROTTET (01290)", value: "01290" },
  { label: "CROTTET (01750)", value: "01750" },
  { label: "CROZET (01170)", value: "01170" },
  { label: "CRUZILLES LES MEPILLAT (01290)", value: "01290" },
  { label: "CULOZ BEON (01350)", value: "01350" },
  { label: "CULOZ BEON (01350)", value: "01350" },
  { label: "CURCIAT DONGALON (01560)", value: "01560" },
  { label: "CURTAFOND (01310)", value: "01310" },
  { label: "CUZIEU (01300)", value: "01300" },
  { label: "DAGNEUX (01120)", value: "01120" },
  { label: "DIVONNE LES BAINS (01220)", value: "01220" },
  { label: "DOMPIERRE SUR VEYLE (01240)", value: "01240" },
  { label: "DOMPIERRE SUR CHALARONNE (01400)", value: "01400" },
  { label: "DOMSURE (01270)", value: "01270" },
  { label: "DORTAN (01590)", value: "01590" },
  { label: "DOUVRES (01500)", value: "01500" },
  { label: "DROM (01250)", value: "01250" },
  { label: "DRUILLAT (01160)", value: "01160" },
  { label: "ECHALLON (01130)", value: "01130" },
  { label: "ECHENEVEX (01170)", value: "01170" },
  { label: "EVOSGES (01230)", value: "01230" },
  { label: "FARAMANS (01800)", value: "01800" },
  { label: "FAREINS (01480)", value: "01480" },
  { label: "FARGES (01550)", value: "01550" },
  { label: "FEILLENS (01570)", value: "01570" },
  { label: "FERNEY VOLTAIRE (01210)", value: "01210" },
  { label: "FLAXIEU (01350)", value: "01350" },
  { label: "FOISSIAT (01340)", value: "01340" },
  { label: "FRANCHELEINS (01090)", value: "01090" },
  { label: "FRANCHELEINS (01090)", value: "01090" },
  { label: "FRANCHELEINS (01090)", value: "01090" },
  { label: "FRANS (01480)", value: "01480" },
  { label: "GARNERANS (01140)", value: "01140" },
  { label: "GENOUILLEUX (01090)", value: "01090" },
  { label: "BEARD GEOVREISSIAT (01460)", value: "01460" },
  { label: "GEOVREISSET (01100)", value: "01100" },
  { label: "GEX (01170)", value: "01170" },
  { label: "GIRON (01130)", value: "01130" },
  { label: "GORREVOD (01190)", value: "01190" },
  { label: "GRAND CORENT (01250)", value: "01250" },
  { label: "GRIEGES (01290)", value: "01290" },
  { label: "GRILLY (01220)", value: "01220" },
  { label: "GROISSIAT (01100)", value: "01100" },
  { label: "GUEREINS (01090)", value: "01090" },
  { label: "HAUTECOURT ROMANECHE (01250)", value: "01250" },
  { label: "HAUTECOURT ROMANECHE (01250)", value: "01250" },
  { label: "PLATEAU D HAUTEVILLE (01110)", value: "01110" },
  { label: "PLATEAU D HAUTEVILLE (01110)", value: "01110" },
  { label: "PLATEAU D HAUTEVILLE (01110)", value: "01110" },
  { label: "PLATEAU D HAUTEVILLE (01110)", value: "01110" },
  { label: "HAUT VALROMEY (01260)", value: "01260" },
  { label: "HAUT VALROMEY (01260)", value: "01260" },
  { label: "HAUT VALROMEY (01260)", value: "01260" },
  { label: "HAUT VALROMEY (01260)", value: "01260" },
  { label: "ILLIAT (01140)", value: "01140" },
  { label: "INJOUX GENISSIAT (01200)", value: "01200" },
  { label: "INJOUX GENISSIAT (01200)", value: "01200" },
  { label: "INNIMOND (01680)", value: "01680" },
  { label: "IZENAVE (01430)", value: "01430" },
  { label: "IZERNORE (01580)", value: "01580" },
  { label: "IZIEU (01300)", value: "01300" },
  { label: "JASSANS RIOTTIER (01480)", value: "01480" },
  { label: "JASSERON (01250)", value: "01250" },
  { label: "JAYAT (01340)", value: "01340" },
  { label: "JOURNANS (01250)", value: "01250" },
  { label: "JOYEUX (01800)", value: "01800" },
  { label: "JUJURIEUX (01640)", value: "01640" },
  { label: "LABALME (01450)", value: "01450" },
  { label: "LAGNIEU (01150)", value: "01150" },
  { label: "LAIZ (01290)", value: "01290" },
  { label: "LE POIZAT LALLEYRIAT (01130)", value: "01130" },
  { label: "LE POIZAT LALLEYRIAT (01130)", value: "01130" },
  { label: "LANTENAY (01430)", value: "01430" },
  { label: "LAPEYROUSE (01330)", value: "01330" },
  { label: "LAVOURS (01350)", value: "01350" },
  { label: "LEAZ (01200)", value: "01200" },
  { label: "LELEX (01410)", value: "01410" },
  { label: "LENT (01240)", value: "01240" },
  { label: "LESCHEROUX (01560)", value: "01560" },
  { label: "LEYMENT (01150)", value: "01150" },
  { label: "LEYSSARD (01450)", value: "01450" },
  { label: "SURJOUX LHOPITAL (01420)", value: "01420" },
  { label: "SURJOUX LHOPITAL (01420)", value: "01420" },
  { label: "LHUIS (01680)", value: "01680" },
  { label: "LOMPNAS (01680)", value: "01680" },
  { label: "LOYETTES (01360)", value: "01360" },
  { label: "LURCY (01090)", value: "01090" },
  { label: "MAGNIEU (01300)", value: "01300" },
  { label: "MAGNIEU (01300)", value: "01300" },
  { label: "MAILLAT (01430)", value: "01430" },
  { label: "MALAFRETAZ (01340)", value: "01340" },
  { label: "MANTENAY MONTLIN (01560)", value: "01560" },
  { label: "MANZIAT (01570)", value: "01570" },
  { label: "MARBOZ (01851)", value: "01851" },
  { label: "MARCHAMP (01680)", value: "01680" },
  { label: "MARIGNIEU (01300)", value: "01300" },
  { label: "MARLIEUX (01240)", value: "01240" },
  { label: "MARSONNAS (01340)", value: "01340" },
  { label: "MARTIGNAT (01100)", value: "01100" },
  { label: "MASSIEUX (01600)", value: "01600" },
  { label: "MASSIGNIEU DE RIVES (01300)", value: "01300" },
  { label: "MATAFELON GRANGES (01580)", value: "01580" },
  { label: "MATAFELON GRANGES (01580)", value: "01580" },
  { label: "MEILLONNAS (01370)", value: "01370" },
  { label: "MERIGNAT (01450)", value: "01450" },
  { label: "MESSIMY SUR SAONE (01480)", value: "01480" },
  { label: "MEXIMIEUX (01800)", value: "01800" },
  { label: "BOHAS MEYRIAT RIGNAT (01250)", value: "01250" },
  { label: "BOHAS MEYRIAT RIGNAT (01250)", value: "01250" },
  { label: "BOHAS MEYRIAT RIGNAT (01250)", value: "01250" },
  { label: "MEZERIAT (01660)", value: "01660" },
  { label: "MIJOUX (01170)", value: "01170" },
  { label: "MIJOUX (01410)", value: "01410" },
  { label: "MIONNAY (01390)", value: "01390" },
  { label: "MIRIBEL (01700)", value: "01700" },
  { label: "MIRIBEL (01700)", value: "01700" },
  { label: "MIRIBEL (01700)", value: "01700" },
  { label: "MISERIEUX (01600)", value: "01600" },
  { label: "MOGNENEINS (01140)", value: "01140" },
  { label: "MONTAGNAT (01250)", value: "01250" },
  { label: "MONTAGNIEU (01470)", value: "01470" },
  { label: "MONTANGES (01200)", value: "01200" },
  { label: "MONTCEAUX (01090)", value: "01090" },
  { label: "MONTCET (01310)", value: "01310" },
  { label: "LE MONTELLIER (01800)", value: "01800" },
  { label: "MONTHIEUX (01390)", value: "01390" },
  { label: "MONTLUEL (01120)", value: "01120" },
  { label: "MONTLUEL (01120)", value: "01120" },
  { label: "MONTLUEL (01120)", value: "01120" },
  { label: "MONTMERLE SUR SAONE (01090)", value: "01090" },
  { label: "MONTRACOL (01310)", value: "01310" },
  { label: "MONTREAL LA CLUSE (01460)", value: "01460" },
  { label: "MONTREVEL EN BRESSE (01340)", value: "01340" },
  { label: "NURIEUX VOLOGNAT (01460)", value: "01460" },
  { label: "NURIEUX VOLOGNAT (01460)", value: "01460" },
  { label: "MURS ET GELIGNIEUX (01300)", value: "01300" },
  { label: "NANTUA (01130)", value: "01130" },
  { label: "NANTUA (01460)", value: "01460" },
  { label: "NEUVILLE LES DAMES (01400)", value: "01400" },
  { label: "NEUVILLE SUR AIN (01160)", value: "01160" },
  { label: "LES NEYROLLES (01130)", value: "01130" },
  { label: "NEYRON (01700)", value: "01700" },
  { label: "NIEVROZ (01120)", value: "01120" },
  { label: "NIVOLLET MONTGRIFFON (01230)", value: "01230" },
  { label: "ONCIEU (01230)", value: "01230" },
  { label: "ORDONNAZ (01510)", value: "01510" },
  { label: "ORNEX (01210)", value: "01210" },
  { label: "OUTRIAZ (01430)", value: "01430" },
  { label: "OYONNAX (01100)", value: "01100" },
  { label: "OYONNAX (01100)", value: "01100" },
  { label: "OYONNAX (01100)", value: "01100" },
  { label: "OZAN (01190)", value: "01190" },
  { label: "PARCIEUX (01600)", value: "01600" },
  { label: "PARVES ET NATTAGES (01300)", value: "01300" },
  { label: "PARVES ET NATTAGES (01300)", value: "01300" },
  { label: "PERON (01630)", value: "01630" },
  { label: "PERONNAS (01960)", value: "01960" },
  { label: "PEROUGES (01800)", value: "01800" },
  { label: "PERREX (01540)", value: "01540" },
  { label: "PEYRIAT (01430)", value: "01430" },
  { label: "PEYRIEU (01300)", value: "01300" },
  { label: "PEYZIEUX SUR SAONE (01140)", value: "01140" },
  { label: "PIRAJOUX (01270)", value: "01270" },
  { label: "PIZAY (01120)", value: "01120" },
  { label: "PLAGNE (01130)", value: "01130" },
  { label: "LE PLANTAY (01330)", value: "01330" },
  { label: "POLLIAT (01310)", value: "01310" },
  { label: "POLLIEU (01350)", value: "01350" },
  { label: "PONCIN (01450)", value: "01450" },
  { label: "PONT D AIN (01160)", value: "01160" },
  { label: "PONT DE VAUX (01190)", value: "01190" },
  { label: "PONT DE VEYLE (01290)", value: "01290" },
  { label: "PORT (01460)", value: "01460" },
  { label: "POUGNY (01550)", value: "01550" },
  { label: "POUILLAT (01250)", value: "01250" },
  { label: "PREMEYZEL (01300)", value: "01300" },
  { label: "PREMILLIEU (01110)", value: "01110" },
  { label: "PREVESSIN MOENS (01280)", value: "01280" },
  { label: "PREVESSIN MOENS (01280)", value: "01280" },
  { label: "PRIAY (01160)", value: "01160" },
  { label: "RAMASSE (01250)", value: "01250" },
  { label: "RANCE (01390)", value: "01390" },
  { label: "RELEVANT (01990)", value: "01990" },
  { label: "REPLONGES (01750)", value: "01750" },
  { label: "REVONNAS (01250)", value: "01250" },
  { label: "REYRIEUX (01600)", value: "01600" },
  { label: "REYSSOUZE (01190)", value: "01190" },
  { label: "RIGNIEUX LE FRANC (01800)", value: "01800" },
  { label: "ROMANS (01400)", value: "01400" },
  { label: "ROSSILLON (01510)", value: "01510" },
  { label: "RUFFIEU (01260)", value: "01260" },
  { label: "ST ALBAN (01450)", value: "01450" },
  { label: "ST ANDRE DE BAGE (01380)", value: "01380" },
  { label: "ST ANDRE DE CORCY (01390)", value: "01390" },
  { label: "ST ANDRE D HUIRIAT (01290)", value: "01290" },
  { label: "ST ANDRE LE BOUCHOUX (01240)", value: "01240" },
  { label: "ST ANDRE SUR VIEUX JONC (01960)", value: "01960" },
  { label: "ST BENIGNE (01190)", value: "01190" },
  { label: "GROSLEE ST BENOIT (01300)", value: "01300" },
  { label: "GROSLEE ST BENOIT (01300)", value: "01300" },
  { label: "ST BERNARD (01600)", value: "01600" },
  { label: "STE CROIX (01120)", value: "01120" },
  { label: "ST CYR SUR MENTHON (01380)", value: "01380" },
  { label: "ST DENIS LES BOURG (01000)", value: "01000" },
  { label: "ST DENIS EN BUGEY (01500)", value: "01500" },
  { label: "ST DIDIER D AUSSIAT (01340)", value: "01340" },
  { label: "ST DIDIER DE FORMANS (01600)", value: "01600" },
  { label: "ST DIDIER SUR CHALARONNE (01140)", value: "01140" },
  { label: "ST ELOI (01800)", value: "01800" },
  { label: "ST ETIENNE DU BOIS (01370)", value: "01370" },
  { label: "ST ETIENNE SUR CHALARONNE (01140)", value: "01140" },
  { label: "ST ETIENNE SUR REYSSOUZE (01190)", value: "01190" },
  { label: "STE EUPHEMIE (01600)", value: "01600" },
  { label: "ST GENIS POUILLY (01630)", value: "01630" },
  { label: "ST GENIS SUR MENTHON (01380)", value: "01380" },
  { label: "ST GEORGES SUR RENON (01400)", value: "01400" },
  { label: "ST GERMAIN DE JOUX (01130)", value: "01130" },
  { label: "ST GERMAIN LES PAROISSES (01300)", value: "01300" },
  { label: "ST GERMAIN SUR RENON (01240)", value: "01240" },
  { label: "ST JEAN DE GONVILLE (01630)", value: "01630" },
  { label: "ST JEAN DE NIOST (01800)", value: "01800" },
  { label: "ST JEAN DE THURIGNEUX (01390)", value: "01390" },
  { label: "ST JEAN LE VIEUX (01640)", value: "01640" },
  { label: "ST JEAN SUR REYSSOUZE (01560)", value: "01560" },
  { label: "ST JEAN SUR VEYLE (01290)", value: "01290" },
  { label: "STE JULIE (01150)", value: "01150" },
  { label: "ST JULIEN SUR REYSSOUZE (01560)", value: "01560" },
  { label: "ST JULIEN SUR VEYLE (01540)", value: "01540" },
  { label: "ST JUST (01250)", value: "01250" },
  { label: "ST LAURENT SUR SAONE (01750)", value: "01750" },
  { label: "ST MARCEL (01390)", value: "01390" },
  { label: "ST MARTIN DE BAVEL (01510)", value: "01510" },
  { label: "ST MARTIN DU FRENE (01430)", value: "01430" },
  { label: "ST MARTIN DU MONT (01160)", value: "01160" },
  { label: "ST MARTIN LE CHATEL (01310)", value: "01310" },
  { label: "ST MAURICE DE BEYNOST (01700)", value: "01700" },
  { label: "ST MAURICE DE GOURDANS (01800)", value: "01800" },
  { label: "ST MAURICE DE REMENS (01500)", value: "01500" },
  { label: "ST NIZIER LE BOUCHOUX (01560)", value: "01560" },
  { label: "ST NIZIER LE DESERT (01320)", value: "01320" },
  { label: "STE OLIVE (01330)", value: "01330" },
  { label: "ST PAUL DE VARAX (01240)", value: "01240" },
  { label: "ST RAMBERT EN BUGEY (01230)", value: "01230" },
  { label: "ST REMY (01310)", value: "01310" },
  { label: "ST SORLIN EN BUGEY (01150)", value: "01150" },
  { label: "ST SULPICE (01340)", value: "01340" },
  { label: "ST TRIVIER DE COURTES (01560)", value: "01560" },
  { label: "ST TRIVIER SUR MOIGNANS (01990)", value: "01990" },
  { label: "ST VULBAS (01150)", value: "01150" },
  { label: "SALAVRE (01270)", value: "01270" },
  { label: "SAMOGNAT (01580)", value: "01580" },
  { label: "SANDRANS (01400)", value: "01400" },
  { label: "SAULT BRENAZ (01150)", value: "01150" },
  { label: "SAUVERNY (01220)", value: "01220" },
  { label: "SAVIGNEUX (01480)", value: "01480" },
  { label: "SEGNY (01170)", value: "01170" },
  { label: "SEILLONNAZ (01470)", value: "01470" },
  { label: "SERGY (01630)", value: "01630" },
  { label: "SERMOYER (01190)", value: "01190" },
  { label: "SERRIERES DE BRIORD (01470)", value: "01470" },
  { label: "SERRIERES SUR AIN (01450)", value: "01450" },
  { label: "SERVAS (01960)", value: "01960" },
  { label: "SERVIGNAT (01560)", value: "01560" },
  { label: "SEYSSEL (01420)", value: "01420" },
  { label: "SIMANDRE SUR SURAN (01250)", value: "01250" },
  { label: "SONTHONNAX LA MONTAGNE (01580)", value: "01580" },
  { label: "SONTHONNAX LA MONTAGNE (01580)", value: "01580" },
  { label: "SOUCLIN (01150)", value: "01150" },
  { label: "SULIGNAT (01400)", value: "01400" },
  { label: "TALISSIEU (01510)", value: "01510" },
  { label: "TENAY (01230)", value: "01230" },
  { label: "THIL (01120)", value: "01120" },
  { label: "THOIRY (01710)", value: "01710" },
  { label: "THOISSEY (01140)", value: "01140" },
  { label: "TORCIEU (01230)", value: "01230" },
  { label: "TOSSIAT (01250)", value: "01250" },
  { label: "TOUSSIEUX (01600)", value: "01600" },
  { label: "TRAMOYES (01390)", value: "01390" },
  { label: "LA TRANCLIERE (01160)", value: "01160" },
  { label: "VAL REVERMONT (01370)", value: "01370" },
  { label: "VAL REVERMONT (01370)", value: "01370" },
  { label: "VAL REVERMONT (01370)", value: "01370" },
  { label: "TREVOUX (01600)", value: "01600" },
  { label: "VALEINS (01140)", value: "01140" },
  { label: "VANDEINS (01660)", value: "01660" },
  { label: "VARAMBON (01160)", value: "01160" },
  { label: "VAUX EN BUGEY (01150)", value: "01150" },
  { label: "VERJON (01270)", value: "01270" },
  { label: "VERNOUX (01560)", value: "01560" },
  { label: "VERSAILLEUX (01330)", value: "01330" },
  { label: "VERSONNEX (01210)", value: "01210" },
  { label: "VESANCY (01170)", value: "01170" },
  { label: "VESCOURS (01560)", value: "01560" },
  { label: "VESINES (01570)", value: "01570" },
  { label: "VIEU D IZENAVE (01430)", value: "01430" },
  { label: "VILLARS LES DOMBES (01330)", value: "01330" },
  { label: "VILLEBOIS (01150)", value: "01150" },
  { label: "VILLEMOTIER (01270)", value: "01270" },
  { label: "VILLENEUVE (01480)", value: "01480" },
  { label: "VILLEREVERSURE (01250)", value: "01250" },
  { label: "VILLES (01200)", value: "01200" },
  { label: "VILLETTE SUR AIN (01320)", value: "01320" },
  { label: "VILLIEU LOYES MOLLON (01800)", value: "01800" },
  { label: "VILLIEU LOYES MOLLON (01800)", value: "01800" },
  { label: "VILLIEU LOYES MOLLON (01800)", value: "01800" },
  { label: "VIRIAT (01440)", value: "01440" },
  { label: "VIRIEU LE GRAND (01510)", value: "01510" },
  { label: "ARVIERE EN VALROMEY (01260)", value: "01260" },
  { label: "ARVIERE EN VALROMEY (01260)", value: "01260" },
  { label: "ARVIERE EN VALROMEY (01260)", value: "01260" },
  { label: "ARVIERE EN VALROMEY (01260)", value: "01260" },
  { label: "VIRIGNIN (01300)", value: "01300" },
  { label: "VONGNES (01350)", value: "01350" },
  { label: "VONNAS (01540)", value: "01540" },
  { label: "ABBECOURT (02300)", value: "02300" },
  { label: "ACHERY (02800)", value: "02800" },
  { label: "ACY (02200)", value: "02200" },
  { label: "AGNICOURT ET SECHELLES (02340)", value: "02340" },
  { label: "AGUILCOURT (02190)", value: "02190" },
  { label: "AISONVILLE ET BERNOVILLE (02110)", value: "02110" },
  { label: "AIZELLES (02820)", value: "02820" },
  { label: "AIZY JOUY (02370)", value: "02370" },
  { label: "AIZY JOUY (02370)", value: "02370" },
  { label: "ALAINCOURT (02240)", value: "02240" },
  { label: "ALLEMANT (02320)", value: "02320" },
  { label: "AMBLENY (02290)", value: "02290" },
  { label: "AMBRIEF (02200)", value: "02200" },
  { label: "AMIFONTAINE (02190)", value: "02190" },
  { label: "AMIGNY ROUY (02700)", value: "02700" },
  { label: "ANCIENVILLE (02600)", value: "02600" },
  { label: "ANDELAIN (02800)", value: "02800" },
  { label: "ANGUILCOURT LE SART (02800)", value: "02800" },
  { label: "ANIZY LE GRAND (02320)", value: "02320" },
  { label: "ANIZY LE GRAND (02320)", value: "02320" },
  { label: "ANIZY LE GRAND (02320)", value: "02320" },
  { label: "ANNOIS (02480)", value: "02480" },
  { label: "ANY MARTIN RIEUX (02500)", value: "02500" },
  { label: "ARCHON (02360)", value: "02360" },
  { label: "ARCY STE RESTITUE (02130)", value: "02130" },
  { label: "ARCY STE RESTITUE (02130)", value: "02130" },
  { label: "ARMENTIERES SUR OURCQ (02210)", value: "02210" },
  { label: "ARRANCY (02860)", value: "02860" },
  { label: "ARTEMPS (02480)", value: "02480" },
  { label: "ASSIS SUR SERRE (02270)", value: "02270" },
  { label: "ATHIES SOUS LAON (02840)", value: "02840" },
  { label: "ATTILLY (02490)", value: "02490" },
  { label: "AUBENCHEUL AUX BOIS (02420)", value: "02420" },
  { label: "AUBENTON (02500)", value: "02500" },
  { label: "AUBIGNY AUX KAISNES (02590)", value: "02590" },
  { label: "AUBIGNY EN LAONNOIS (02820)", value: "02820" },
  { label: "AUDIGNICOURT (02300)", value: "02300" },
  { label: "AUDIGNY (02120)", value: "02120" },
  { label: "AUGY (02220)", value: "02220" },
  { label: "AULNOIS SOUS LAON (02000)", value: "02000" },
  { label: "LES AUTELS (02360)", value: "02360" },
  { label: "AUTREMENCOURT (02250)", value: "02250" },
  { label: "AUTREPPES (02580)", value: "02580" },
  { label: "AUTREVILLE (02300)", value: "02300" },
  { label: "AZY SUR MARNE (02400)", value: "02400" },
  { label: "BAGNEUX (02290)", value: "02290" },
  { label: "BANCIGNY (02140)", value: "02140" },
  { label: "BARENTON BUGNY (02000)", value: "02000" },
  { label: "BARENTON CEL (02000)", value: "02000" },
  { label: "BARENTON SUR SERRE (02270)", value: "02270" },
  { label: "BARISIS AUX BOIS (02700)", value: "02700" },
  { label: "BARZY EN THIERACHE (02170)", value: "02170" },
  { label: "BARZY SUR MARNE (02850)", value: "02850" },
  { label: "BASSOLES AULERS (02380)", value: "02380" },
  { label: "VALLEES EN CHAMPAGNE (02330)", value: "02330" },
  { label: "VALLEES EN CHAMPAGNE (02330)", value: "02330" },
  { label: "VALLEES EN CHAMPAGNE (02330)", value: "02330" },
  { label: "BAZOCHES ET ST THIBAUT (02220)", value: "02220" },
  { label: "BAZOCHES ET ST THIBAUT (02220)", value: "02220" },
  { label: "BEAUME (02500)", value: "02500" },
  { label: "BEAUMONT EN BEINE (02300)", value: "02300" },
  { label: "BEAUREVOIR (02110)", value: "02110" },
  { label: "BEAURIEUX (02160)", value: "02160" },
  { label: "BEAUTOR (02800)", value: "02800" },
  { label: "BEAUVOIS EN VERMANDOIS (02590)", value: "02590" },
  { label: "BECQUIGNY (02110)", value: "02110" },
  { label: "BELLEAU (02400)", value: "02400" },
  { label: "BELLENGLISE (02420)", value: "02420" },
  { label: "BELLEU (02200)", value: "02200" },
  { label: "BELLICOURT (02420)", value: "02420" },
  { label: "BENAY (02440)", value: "02440" },
  { label: "BERGUES SUR SAMBRE (02450)", value: "02450" },
  { label: "BERLANCOURT (02250)", value: "02250" },
  { label: "BERLISE (02340)", value: "02340" },
  { label: "BERNOT (02120)", value: "02120" },
  { label: "BERNY RIVIERE (02290)", value: "02290" },
  { label: "BERRIEUX (02820)", value: "02820" },
  { label: "BERRY AU BAC (02190)", value: "02190" },
  { label: "BERTAUCOURT EPOURDON (02800)", value: "02800" },
  { label: "BERTHENICOURT (02240)", value: "02240" },
  { label: "BERTRICOURT (02190)", value: "02190" },
  { label: "BESME (02300)", value: "02300" },
  { label: "BESMONT (02500)", value: "02500" },
  { label: "BESNY ET LOIZY (02870)", value: "02870" },
  { label: "BETHANCOURT EN VAUX (02300)", value: "02300" },
  { label: "BEUGNEUX (02210)", value: "02210" },
  { label: "BEUVARDES (02130)", value: "02130" },
  { label: "BEZU LE GUERY (02310)", value: "02310" },
  { label: "BEZU ST GERMAIN (02400)", value: "02400" },
  { label: "BICHANCOURT (02300)", value: "02300" },
  { label: "BIEUXY (02290)", value: "02290" },
  { label: "BIEVRES (02860)", value: "02860" },
  { label: "BILLY SUR AISNE (02200)", value: "02200" },
  { label: "BILLY SUR OURCQ (02210)", value: "02210" },
  { label: "BLANZY LES FISMES (02160)", value: "02160" },
  { label: "BLERANCOURT (02300)", value: "02300" },
  { label: "BLESMES (02400)", value: "02400" },
  { label: "BOHAIN EN VERMANDOIS (02110)", value: "02110" },
  { label: "BOIS LES PARGNY (02270)", value: "02270" },
  { label: "BONCOURT (02350)", value: "02350" },
  { label: "BONNEIL (02400)", value: "02400" },
  { label: "BONNESVALYN (02400)", value: "02400" },
  { label: "BONY (02420)", value: "02420" },
  { label: "BOSMONT SUR SERRE (02250)", value: "02250" },
  { label: "BOUCONVILLE VAUCLAIR (02860)", value: "02860" },
  { label: "BOUE (02450)", value: "02450" },
  { label: "BOUFFIGNEREUX (02160)", value: "02160" },
  { label: "BOURESCHES (02400)", value: "02400" },
  { label: "BOURG ET COMIN (02160)", value: "02160" },
  { label: "BOURGUIGNON SOUS COUCY (02300)", value: "02300" },
  { label: "BOURGUIGNON SOUS MONTBAVIN (02000)", value: "02000" },
  { label: "LA BOUTEILLE (02140)", value: "02140" },
  { label: "BRAINE (02220)", value: "02220" },
  { label: "BRANCOURT EN LAONNOIS (02320)", value: "02320" },
  { label: "BRANCOURT LE GRAND (02110)", value: "02110" },
  { label: "BRASLES (02400)", value: "02400" },
  { label: "BRAYE EN LAONNOIS (02000)", value: "02000" },
  { label: "BRAYE EN THIERACHE (02140)", value: "02140" },
  { label: "BRAY ST CHRISTOPHE (02480)", value: "02480" },
  { label: "BRAYE (02880)", value: "02880" },
  { label: "BRECY (02210)", value: "02210" },
  { label: "BRENELLE (02220)", value: "02220" },
  { label: "BRENY (02210)", value: "02210" },
  { label: "BRIE (02870)", value: "02870" },
  { label: "BRISSAY CHOIGNY (02240)", value: "02240" },
  { label: "BRISSY HAMEGICOURT (02240)", value: "02240" },
  { label: "BRUMETZ (02810)", value: "02810" },
  { label: "BRUNEHAMEL (02360)", value: "02360" },
  { label: "BRUYERES SUR FERE (02130)", value: "02130" },
  { label: "BRUYERES ET MONTBERAULT (02860)", value: "02860" },
  { label: "BRUYS (02220)", value: "02220" },
  { label: "BUCILLY (02500)", value: "02500" },
  { label: "BUCY LE LONG (02880)", value: "02880" },
  { label: "BUCY LES CERNY (02870)", value: "02870" },
  { label: "BUCY LES PIERREPONT (02350)", value: "02350" },
  { label: "BUIRE (02500)", value: "02500" },
  { label: "BUIRONFOSSE (02620)", value: "02620" },
  { label: "BURELLES (02140)", value: "02140" },
  { label: "BUSSIARES (02810)", value: "02810" },
  { label: "BUZANCY (02200)", value: "02200" },
  { label: "CAILLOUEL CREPIGNY (02300)", value: "02300" },
  { label: "CAMELIN (02300)", value: "02300" },
  { label: "CAMELIN (02300)", value: "02300" },
  { label: "LA CAPELLE (02260)", value: "02260" },
  { label: "CASTRES (02680)", value: "02680" },
  { label: "LE CATELET (02420)", value: "02420" },
  { label: "CAULAINCOURT (02490)", value: "02490" },
  { label: "CAUMONT (02300)", value: "02300" },
  { label: "CELLES LES CONDE (02330)", value: "02330" },
  { label: "CELLES SUR AISNE (02370)", value: "02370" },
  { label: "CERIZY (02240)", value: "02240" },
  { label: "CERNY EN LAONNOIS (02860)", value: "02860" },
  { label: "CERNY LES BUCY (02870)", value: "02870" },
  { label: "CERSEUIL (02220)", value: "02220" },
  { label: "CESSIERES SUZY (02320)", value: "02320" },
  { label: "CESSIERES SUZY (02320)", value: "02320" },
  { label: "CHACRISE (02200)", value: "02200" },
  { label: "CHAILLEVOIS (02000)", value: "02000" },
  { label: "CHALANDRY (02270)", value: "02270" },
  { label: "CHAMBRY (02000)", value: "02000" },
  { label: "CHAMOUILLE (02860)", value: "02860" },
  { label: "CHAMPS (02670)", value: "02670" },
  { label: "CHAOURSE (02340)", value: "02340" },
  { label: "LA CHAPELLE SUR CHEZY (02570)", value: "02570" },
  { label: "CHARLY SUR MARNE (02310)", value: "02310" },
  { label: "LE CHARMEL (02850)", value: "02850" },
  { label: "CHARMES (02800)", value: "02800" },
  { label: "CHARTEVES (02400)", value: "02400" },
  { label: "CHASSEMY (02370)", value: "02370" },
  { label: "CHATEAU THIERRY (02400)", value: "02400" },
  { label: "CHATILLON LES SONS (02270)", value: "02270" },
  { label: "CHATILLON SUR OISE (02240)", value: "02240" },
  { label: "CHAUDARDES (02160)", value: "02160" },
  { label: "CHAUDUN (02200)", value: "02200" },
  { label: "CHAUNY (02300)", value: "02300" },
  { label: "CHAVIGNON (02000)", value: "02000" },
  { label: "CHAVIGNY (02880)", value: "02880" },
  { label: "CHAVONNE (02370)", value: "02370" },
  { label: "CHERET (02860)", value: "02860" },
  { label: "CHERMIZY AILLES (02860)", value: "02860" },
  { label: "CHERY CHARTREUVE (02220)", value: "02220" },
  { label: "CHERY LES POUILLY (02000)", value: "02000" },
  { label: "CHERY LES ROZOY (02360)", value: "02360" },
  { label: "CHEVENNES (02250)", value: "02250" },
  { label: "CHEVREGNY (02000)", value: "02000" },
  { label: "CHEVRESIS MONCEAU (02270)", value: "02270" },
  { label: "CHEZY EN ORXOIS (02810)", value: "02810" },
  { label: "CHEZY SUR MARNE (02570)", value: "02570" },
  { label: "CHIERRY (02400)", value: "02400" },
  { label: "CHIGNY (02120)", value: "02120" },
  { label: "CHIVRES EN LAONNOIS (02350)", value: "02350" },
  { label: "CHIVRES VAL (02880)", value: "02880" },
  { label: "CHIVY LES ETOUVELLES (02000)", value: "02000" },
  { label: "CHOUY (02210)", value: "02210" },
  { label: "CIERGES (02130)", value: "02130" },
  { label: "CILLY (02250)", value: "02250" },
  { label: "CIRY SALSOGNE (02220)", value: "02220" },
  { label: "CLACY ET THIERRET (02000)", value: "02000" },
  { label: "CLAIRFONTAINE (02260)", value: "02260" },
  { label: "CLAMECY (02880)", value: "02880" },
  { label: "CLASTRES (02440)", value: "02440" },
  { label: "CLERMONT LES FERMES (02340)", value: "02340" },
  { label: "COEUVRES ET VALSERY (02600)", value: "02600" },
  { label: "COINCY (02210)", value: "02210" },
  { label: "COINGT (02360)", value: "02360" },
  { label: "COLLIGIS CRANDELAIN (02860)", value: "02860" },
  { label: "COLONFAY (02120)", value: "02120" },
  { label: "COMMENCHON (02300)", value: "02300" },
  { label: "CONCEVREUX (02160)", value: "02160" },
  { label: "CONDE EN BRIE (02330)", value: "02330" },
  { label: "CONDE SUR AISNE (02370)", value: "02370" },
  { label: "CONDE SUR SUIPPE (02190)", value: "02190" },
  { label: "CONDREN (02700)", value: "02700" },
  { label: "CONNIGIS (02330)", value: "02330" },
  { label: "CONTESCOURT (02680)", value: "02680" },
  { label: "CORBENY (02820)", value: "02820" },
  { label: "CORCY (02600)", value: "02600" },
  { label: "COUCY LE CHATEAU AUFFRIQUE (02380)", value: "02380" },
  { label: "COUCY LES EPPES (02840)", value: "02840" },
  { label: "COUCY LA VILLE (02380)", value: "02380" },
  { label: "COULONGES COHAN (02130)", value: "02130" },
  { label: "COULONGES COHAN (02130)", value: "02130" },
  { label: "COUPRU (02310)", value: "02310" },
  { label: "COURBES (02800)", value: "02800" },
  { label: "COURBOIN (02330)", value: "02330" },
  { label: "COURCELLES SUR VESLE (02220)", value: "02220" },
  { label: "COURCHAMPS (02810)", value: "02810" },
  { label: "COURMELLES (02200)", value: "02200" },
  { label: "COURMONT (02130)", value: "02130" },
  { label: "COURTEMONT VARENNES (02850)", value: "02850" },
  { label: "COURTRIZY ET FUSSIGNY (02820)", value: "02820" },
  { label: "COUVRELLES (02220)", value: "02220" },
  { label: "COUVRON ET AUMENCOURT (02270)", value: "02270" },
  { label: "COYOLLES (02600)", value: "02600" },
  { label: "CRAMAILLE (02130)", value: "02130" },
  { label: "CRAONNE (02160)", value: "02160" },
  { label: "CRAONNELLE (02160)", value: "02160" },
  { label: "CRECY AU MONT (02380)", value: "02380" },
  { label: "CRECY SUR SERRE (02270)", value: "02270" },
  { label: "CREPY (02870)", value: "02870" },
  { label: "CREZANCY (02650)", value: "02650" },
  { label: "CROIX FONSOMME (02110)", value: "02110" },
  { label: "LA CROIX SUR OURCQ (02210)", value: "02210" },
  { label: "CROUTTES SUR MARNE (02310)", value: "02310" },
  { label: "CROUY (02880)", value: "02880" },
  { label: "CRUPILLY (02120)", value: "02120" },
  { label: "CUFFIES (02880)", value: "02880" },
  { label: "CUGNY (02480)", value: "02480" },
  { label: "CUIRIEUX (02350)", value: "02350" },
  { label: "CUIRY HOUSSE (02220)", value: "02220" },
  { label: "CUIRY LES CHAUDARDES (02160)", value: "02160" },
  { label: "CUIRY LES IVIERS (02360)", value: "02360" },
  { label: "CUISSY ET GENY (02160)", value: "02160" },
  { label: "CUISY EN ALMONT (02200)", value: "02200" },
  { label: "CUTRY (02600)", value: "02600" },
  { label: "CYS LA COMMUNE (02220)", value: "02220" },
  { label: "DAGNY LAMBERCY (02140)", value: "02140" },
  { label: "DALLON (02680)", value: "02680" },
  { label: "DAMMARD (02470)", value: "02470" },
  { label: "DAMPLEUX (02600)", value: "02600" },
  { label: "DANIZY (02800)", value: "02800" },
  { label: "DERCY (02270)", value: "02270" },
  { label: "DEUILLET (02700)", value: "02700" },
  { label: "DHUIZEL (02220)", value: "02220" },
  { label: "DIZY LE GROS (02340)", value: "02340" },
  { label: "DOHIS (02360)", value: "02360" },
  { label: "DOLIGNON (02360)", value: "02360" },
  { label: "DOMMIERS (02600)", value: "02600" },
  { label: "DOMPTIN (02310)", value: "02310" },
  { label: "DORENGT (02450)", value: "02450" },
  { label: "DOUCHY (02590)", value: "02590" },
  { label: "DRAVEGNY (02130)", value: "02130" },
  { label: "DROIZY (02210)", value: "02210" },
  { label: "DURY (02480)", value: "02480" },
  { label: "EBOULEAU (02350)", value: "02350" },
  { label: "EFFRY (02500)", value: "02500" },
  { label: "ENGLANCOURT (02260)", value: "02260" },
  { label: "EPAGNY (02290)", value: "02290" },
  { label: "EPARCY (02500)", value: "02500" },
  { label: "EPAUX BEZU (02400)", value: "02400" },
  { label: "EPIEDS (02400)", value: "02400" },
  { label: "L'EPINE AUX BOIS (02540)", value: "02540" },
  { label: "EPPES (02840)", value: "02840" },
  { label: "ERLON (02250)", value: "02250" },
  { label: "ERLOY (02260)", value: "02260" },
  { label: "ESQUEHERIES (02170)", value: "02170" },
  { label: "ESSIGNY LE GRAND (02690)", value: "02690" },
  { label: "ESSIGNY LE PETIT (02100)", value: "02100" },
  { label: "ESSISES (02570)", value: "02570" },
  { label: "ESSOMES SUR MARNE (02400)", value: "02400" },
  { label: "ESTREES (02420)", value: "02420" },
  { label: "ETAMPES SUR MARNE (02400)", value: "02400" },
  { label: "ETAVES ET BOCQUIAUX (02110)", value: "02110" },
  { label: "ETOUVELLES (02000)", value: "02000" },
  { label: "ETREAUPONT (02580)", value: "02580" },
  { label: "ETREILLERS (02590)", value: "02590" },
  { label: "ETREPILLY (02400)", value: "02400" },
  { label: "ETREUX (02510)", value: "02510" },
  { label: "EVERGNICOURT (02190)", value: "02190" },
  { label: "FAVEROLLES (02600)", value: "02600" },
  { label: "FAYET (02100)", value: "02100" },
  { label: "LA FERE (02800)", value: "02800" },
  { label: "FERE EN TARDENOIS (02130)", value: "02130" },
  { label: "LA FERTE CHEVRESIS (02270)", value: "02270" },
  { label: "LA FERTE MILON (02460)", value: "02460" },
  { label: "LA FERTE MILON (02460)", value: "02460" },
  { label: "FESMY LE SART (02450)", value: "02450" },
  { label: "FESMY LE SART (02450)", value: "02450" },
  { label: "FESTIEUX (02840)", value: "02840" },
  { label: "FIEULAINE (02110)", value: "02110" },
  { label: "FILAIN (02000)", value: "02000" },
  { label: "LA FLAMENGRIE (02260)", value: "02260" },
  { label: "FLAVIGNY LE GRAND ET BEAURAIN (02120)", value: "02120" },
  { label: "FLAVY LE MARTEL (02520)", value: "02520" },
  { label: "FLEURY (02600)", value: "02600" },
  { label: "FLUQUIERES (02590)", value: "02590" },
  { label: "FOLEMBRAY (02670)", value: "02670" },
  { label: "FONSOMME (02110)", value: "02110" },
  { label: "FONTAINE LES CLERCS (02680)", value: "02680" },
  { label: "FONTAINE LES VERVINS (02140)", value: "02140" },
  { label: "FONTAINE NOTRE DAME (02110)", value: "02110" },
  { label: "FONTAINE UTERTE (02110)", value: "02110" },
  { label: "FONTENELLE (02170)", value: "02170" },
  { label: "FONTENOY (02290)", value: "02290" },
  { label: "FORESTE (02590)", value: "02590" },
  { label: "FOSSOY (02650)", value: "02650" },
  { label: "FOURDRAIN (02870)", value: "02870" },
  { label: "FRANCILLY SELENCY (02760)", value: "02760" },
  { label: "FRANQUEVILLE (02140)", value: "02140" },
  { label: "FRESNES EN TARDENOIS (02130)", value: "02130" },
  { label: "FRESNES SOUS COUCY (02380)", value: "02380" },
  { label: "FRESNOY LE GRAND (02230)", value: "02230" },
  { label: "FRESSANCOURT (02800)", value: "02800" },
  { label: "FRIERES FAILLOUEL (02700)", value: "02700" },
  { label: "FROIDESTREES (02260)", value: "02260" },
  { label: "FROIDMONT COHARTILLE (02270)", value: "02270" },
  { label: "GANDELU (02810)", value: "02810" },
  { label: "GAUCHY (02430)", value: "02430" },
  { label: "GERCY (02140)", value: "02140" },
  { label: "GERGNY (02260)", value: "02260" },
  { label: "GERMAINE (02590)", value: "02590" },
  { label: "GIBERCOURT (02440)", value: "02440" },
  { label: "GIZY (02350)", value: "02350" },
  { label: "GLAND (02400)", value: "02400" },
  { label: "GOUDELANCOURT LES BERRIEUX (02820)", value: "02820" },
  { label: "GOUDELANCOURT LES PIERREPONT (02350)", value: "02350" },
  { label: "GOUSSANCOURT (02130)", value: "02130" },
  { label: "GOUY (02420)", value: "02420" },
  { label: "GRANDLUP ET FAY (02350)", value: "02350" },
  { label: "GRANDRIEUX (02360)", value: "02360" },
  { label: "GRICOURT (02100)", value: "02100" },
  { label: "GRISOLLES (02210)", value: "02210" },
  { label: "GRONARD (02140)", value: "02140" },
  { label: "GROUGIS (02110)", value: "02110" },
  { label: "GRUGIES (02680)", value: "02680" },
  { label: "VILLENEUVE SUR AISNE (02190)", value: "02190" },
  { label: "VILLENEUVE SUR AISNE (02190)", value: "02190" },
  { label: "GUISE (02120)", value: "02120" },
  { label: "GUIVRY (02300)", value: "02300" },
  { label: "GUNY (02300)", value: "02300" },
  { label: "GUYENCOURT (02160)", value: "02160" },
  { label: "HANNAPES (02510)", value: "02510" },
  { label: "HAPPENCOURT (02480)", value: "02480" },
  { label: "HARAMONT (02600)", value: "02600" },
  { label: "HARCIGNY (02140)", value: "02140" },
  { label: "HARGICOURT (02420)", value: "02420" },
  { label: "HARLY (02100)", value: "02100" },
  { label: "HARTENNES ET TAUX (02210)", value: "02210" },
  { label: "HARY (02140)", value: "02140" },
  { label: "LEHAUCOURT (02420)", value: "02420" },
  { label: "HAUTEVESNES (02810)", value: "02810" },
  { label: "HAUTEVILLE (02120)", value: "02120" },
  { label: "HAUTION (02140)", value: "02140" },
  { label: "LA HERIE (02500)", value: "02500" },
  { label: "LE HERIE LA VIEVILLE (02120)", value: "02120" },
  { label: "HINACOURT (02440)", value: "02440" },
  { label: "HIRSON (02500)", value: "02500" },
  { label: "HOLNON (02760)", value: "02760" },
  { label: "HOMBLIERES (02720)", value: "02720" },
  { label: "HOURY (02140)", value: "02140" },
  { label: "HOUSSET (02250)", value: "02250" },
  { label: "IRON (02510)", value: "02510" },
  { label: "ITANCOURT (02240)", value: "02240" },
  { label: "IVIERS (02360)", value: "02360" },
  { label: "JAULGONNE (02850)", value: "02850" },
  { label: "JEANCOURT (02490)", value: "02490" },
  { label: "JEANTES (02140)", value: "02140" },
  { label: "JONCOURT (02420)", value: "02420" },
  { label: "JOUAIGNES (02220)", value: "02220" },
  { label: "JUMENCOURT (02380)", value: "02380" },
  { label: "JUMIGNY (02160)", value: "02160" },
  { label: "JUSSY (02480)", value: "02480" },
  { label: "JUVIGNY (02880)", value: "02880" },
  { label: "JUVINCOURT ET DAMARY (02190)", value: "02190" },
  { label: "LAFFAUX (02880)", value: "02880" },
  { label: "LAIGNY (02140)", value: "02140" },
  { label: "LANCHY (02590)", value: "02590" },
  { label: "LANDIFAY ET BERTAIGNEMONT (02120)", value: "02120" },
  { label: "LANDOUZY LA COUR (02140)", value: "02140" },
  { label: "LANDOUZY LA VILLE (02140)", value: "02140" },
  { label: "LANDRICOURT (02380)", value: "02380" },
  { label: "LANISCOURT (02000)", value: "02000" },
  { label: "LAON (02000)", value: "02000" },
  { label: "LAPPION (02150)", value: "02150" },
  { label: "LARGNY SUR AUTOMNE (02600)", value: "02600" },
  { label: "LATILLY (02210)", value: "02210" },
  { label: "LAUNOY (02210)", value: "02210" },
  { label: "LAVAL EN LAONNOIS (02860)", value: "02860" },
  { label: "LAVAQUERESSE (02450)", value: "02450" },
  { label: "LAVERSINE (02600)", value: "02600" },
  { label: "LEME (02140)", value: "02140" },
  { label: "LEMPIRE (02420)", value: "02420" },
  { label: "LERZY (02260)", value: "02260" },
  { label: "LESCHELLE (02170)", value: "02170" },
  { label: "LESDINS (02100)", value: "02100" },
  { label: "LESGES (02220)", value: "02220" },
  { label: "LESQUIELLES ST GERMAIN (02120)", value: "02120" },
  { label: "LEUILLY SOUS COUCY (02380)", value: "02380" },
  { label: "LEURY (02880)", value: "02880" },
  { label: "LEUZE (02500)", value: "02500" },
  { label: "LEVERGIES (02420)", value: "02420" },
  { label: "LHUYS (02220)", value: "02220" },
  { label: "LICY CLIGNON (02810)", value: "02810" },
  { label: "LIERVAL (02860)", value: "02860" },
  { label: "LIESSE NOTRE DAME (02350)", value: "02350" },
  { label: "LIEZ (02700)", value: "02700" },
  { label: "LIME (02220)", value: "02220" },
  { label: "LISLET (02340)", value: "02340" },
  { label: "LOGNY LES AUBENTON (02500)", value: "02500" },
  { label: "LONGPONT (02600)", value: "02600" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LES SEPTVALLONS (02160)", value: "02160" },
  { label: "LOR (02190)", value: "02190" },
  { label: "LOUATRE (02600)", value: "02600" },
  { label: "LOUPEIGNE (02130)", value: "02130" },
  { label: "LUCY LE BOCAGE (02400)", value: "02400" },
  { label: "LUGNY (02140)", value: "02140" },
  { label: "LUZOIR (02500)", value: "02500" },
  { label: "LY FONTAINE (02440)", value: "02440" },
  { label: "MAAST ET VIOLAINE (02220)", value: "02220" },
  { label: "MACHECOURT (02350)", value: "02350" },
  { label: "MACOGNY (02470)", value: "02470" },
  { label: "MACQUIGNY (02120)", value: "02120" },
  { label: "MAGNY LA FOSSE (02420)", value: "02420" },
  { label: "MAISSEMY (02490)", value: "02490" },
  { label: "MAIZY (02160)", value: "02160" },
  { label: "LA MALMAISON (02190)", value: "02190" },
  { label: "MALZY (02120)", value: "02120" },
  { label: "MANICAMP (02300)", value: "02300" },
  { label: "MARCHAIS (02350)", value: "02350" },
  { label: "DHUYS ET MORIN EN BRIE (02330)", value: "02330" },
  { label: "DHUYS ET MORIN EN BRIE (02540)", value: "02540" },
  { label: "DHUYS ET MORIN EN BRIE (02540)", value: "02540" },
  { label: "DHUYS ET MORIN EN BRIE (02540)", value: "02540" },
  { label: "MARCY (02720)", value: "02720" },
  { label: "MARCY SOUS MARLE (02250)", value: "02250" },
  { label: "MAREST DAMPCOURT (02300)", value: "02300" },
  { label: "MAREUIL EN DOLE (02130)", value: "02130" },
  { label: "MARFONTAINE (02140)", value: "02140" },
  { label: "MARGIVAL (02880)", value: "02880" },
  { label: "MARIGNY EN ORXOIS (02810)", value: "02810" },
  { label: "MARIZY STE GENEVIEVE (02470)", value: "02470" },
  { label: "MARIZY ST MARD (02470)", value: "02470" },
  { label: "MARLE (02250)", value: "02250" },
  { label: "MARLY GOMONT (02120)", value: "02120" },
  { label: "MARTIGNY (02500)", value: "02500" },
  { label: "MARTIGNY COURPIERRE (02860)", value: "02860" },
  { label: "MAUREGNY EN HAYE (02820)", value: "02820" },
  { label: "MAYOT (02800)", value: "02800" },
  { label: "MENNESSIS (02700)", value: "02700" },
  { label: "MENNEVRET (02630)", value: "02630" },
  { label: "MERCIN ET VAUX (02200)", value: "02200" },
  { label: "MERLIEUX ET FOUQUEROLLES (02000)", value: "02000" },
  { label: "MESBRECOURT RICHECOURT (02270)", value: "02270" },
  { label: "MESNIL ST LAURENT (02720)", value: "02720" },
  { label: "MEURIVAL (02160)", value: "02160" },
  { label: "MEZIERES SUR OISE (02240)", value: "02240" },
  { label: "MEZY MOULINS (02650)", value: "02650" },
  { label: "MISSY AUX BOIS (02200)", value: "02200" },
  { label: "MISSY LES PIERREPONT (02350)", value: "02350" },
  { label: "MISSY SUR AISNE (02880)", value: "02880" },
  { label: "MOLAIN (02110)", value: "02110" },
  { label: "MOLINCHART (02000)", value: "02000" },
  { label: "MONAMPTEUIL (02000)", value: "02000" },
  { label: "MONCEAU LE NEUF ET FAUCOUZY (02270)", value: "02270" },
  { label: "MONCEAU LES LEUPS (02270)", value: "02270" },
  { label: "MONCEAU LE WAAST (02840)", value: "02840" },
  { label: "MONCEAU SUR OISE (02120)", value: "02120" },
  { label: "MONDREPUIS (02500)", value: "02500" },
  { label: "MONNES (02470)", value: "02470" },
  { label: "MONS EN LAONNOIS (02000)", value: "02000" },
  { label: "MONTAIGU (02820)", value: "02820" },
  { label: "MONTBAVIN (02000)", value: "02000" },
  { label: "MONTBREHAIN (02110)", value: "02110" },
  { label: "MONTCHALONS (02860)", value: "02860" },
  { label: "MONTCORNET (02340)", value: "02340" },
  { label: "MONT D ORIGNY (02390)", value: "02390" },
  { label: "MONTESCOURT LIZEROLLES (02440)", value: "02440" },
  { label: "MONTFAUCON (02540)", value: "02540" },
  { label: "MONTGOBERT (02600)", value: "02600" },
  { label: "MONTGRU ST HILAIRE (02210)", value: "02210" },
  { label: "MONTHENAULT (02860)", value: "02860" },
  { label: "MONTHIERS (02400)", value: "02400" },
  { label: "MONTHUREL (02330)", value: "02330" },
  { label: "MONTIGNY EN ARROUAISE (02110)", value: "02110" },
  { label: "MONTIGNY L ALLIER (02810)", value: "02810" },
  { label: "MONTIGNY LE FRANC (02250)", value: "02250" },
  { label: "MONTIGNY LENGRAIN (02290)", value: "02290" },
  { label: "MONTIGNY LES CONDE (02330)", value: "02330" },
  { label: "MONTIGNY SOUS MARLE (02250)", value: "02250" },
  { label: "MONTIGNY SUR CRECY (02270)", value: "02270" },
  { label: "MONTLEVON (02330)", value: "02330" },
  { label: "MONTLOUE (02340)", value: "02340" },
  { label: "MONT NOTRE DAME (02220)", value: "02220" },
  { label: "MONTREUIL AUX LIONS (02310)", value: "02310" },
  { label: "MONT ST JEAN (02360)", value: "02360" },
  { label: "MONT ST MARTIN (02220)", value: "02220" },
  { label: "MONT ST PERE (02400)", value: "02400" },
  { label: "MORCOURT (02100)", value: "02100" },
  { label: "MORGNY EN THIERACHE (02360)", value: "02360" },
  { label: "MORSAIN (02290)", value: "02290" },
  { label: "MORTEFONTAINE (02600)", value: "02600" },
  { label: "MORTIERS (02270)", value: "02270" },
  { label: "MOULINS (02160)", value: "02160" },
  { label: "MOUSSY VERNEUIL (02160)", value: "02160" },
  { label: "MOY DE L AISNE (02610)", value: "02610" },
  { label: "MURET ET CROUTTES (02210)", value: "02210" },
  { label: "MUSCOURT (02160)", value: "02160" },
  { label: "NAMPCELLES LA COUR (02140)", value: "02140" },
  { label: "NAMPTEUIL SOUS MURET (02200)", value: "02200" },
  { label: "NANTEUIL LA FOSSE (02880)", value: "02880" },
  { label: "NANTEUIL NOTRE DAME (02210)", value: "02210" },
  { label: "NAUROY (02420)", value: "02420" },
  { label: "NESLES LA MONTAGNE (02400)", value: "02400" },
  { label: "NEUFCHATEL SUR AISNE (02190)", value: "02190" },
  { label: "NEUFLIEUX (02300)", value: "02300" },
  { label: "NEUILLY ST FRONT (02470)", value: "02470" },
  { label: "NEUVE MAISON (02500)", value: "02500" },
  { label: "LA NEUVILLE BOSMONT (02250)", value: "02250" },
  { label: "LA NEUVILLE EN BEINE (02300)", value: "02300" },
  { label: "LA NEUVILLE HOUSSET (02250)", value: "02250" },
  { label: "LA NEUVILLE LES DORENGT (02450)", value: "02450" },
  { label: "NEUVILLE ST AMAND (02100)", value: "02100" },
  { label: "NEUVILLE SUR AILETTE (02860)", value: "02860" },
  { label: "NEUVILLE SUR MARGIVAL (02880)", value: "02880" },
  { label: "NEUVILLETTE (02390)", value: "02390" },
  { label: "NIZY LE COMTE (02150)", value: "02150" },
  { label: "NOGENTEL (02400)", value: "02400" },
  { label: "NOGENT L ARTAUD (02310)", value: "02310" },
  { label: "NOIRCOURT (02340)", value: "02340" },
  { label: "NOROY SUR OURCQ (02600)", value: "02600" },
  { label: "LE NOUVION EN THIERACHE (02170)", value: "02170" },
  { label: "NOUVION ET CATILLON (02270)", value: "02270" },
  { label: "NOUVION ET CATILLON (02270)", value: "02270" },
  { label: "NOUVION LE COMTE (02800)", value: "02800" },
  { label: "NOUVION LE VINEUX (02860)", value: "02860" },
  { label: "NOUVRON VINGRE (02290)", value: "02290" },
  { label: "NOYALES (02120)", value: "02120" },
  { label: "BERNOY LE CHATEAU (02200)", value: "02200" },
  { label: "BERNOY LE CHATEAU (02200)", value: "02200" },
  { label: "OEUILLY (02160)", value: "02160" },
  { label: "OGNES (02300)", value: "02300" },
  { label: "OHIS (02500)", value: "02500" },
  { label: "OIGNY EN VALOIS (02600)", value: "02600" },
  { label: "OISY (02450)", value: "02450" },
  { label: "OLLEZY (02480)", value: "02480" },
  { label: "OMISSY (02100)", value: "02100" },
  { label: "ORAINVILLE (02190)", value: "02190" },
  { label: "ORGEVAL (02860)", value: "02860" },
  { label: "ORIGNY EN THIERACHE (02550)", value: "02550" },
  { label: "ORIGNY STE BENOITE (02390)", value: "02390" },
  { label: "OSLY COURTIL (02290)", value: "02290" },
  { label: "OSTEL (02370)", value: "02370" },
  { label: "OULCHES LA VALLEE FOULON (02160)", value: "02160" },
  { label: "OULCHY LA VILLE (02210)", value: "02210" },
  { label: "OULCHY LE CHATEAU (02210)", value: "02210" },
  { label: "OULCHY LE CHATEAU (02210)", value: "02210" },
  { label: "PAARS (02220)", value: "02220" },
  { label: "PAISSY (02160)", value: "02160" },
  { label: "PANCY COURTECON (02860)", value: "02860" },
  { label: "PAPLEUX (02260)", value: "02260" },
  { label: "PARCY ET TIGNY (02210)", value: "02210" },
  { label: "PARFONDEVAL (02360)", value: "02360" },
  { label: "PARFONDRU (02840)", value: "02840" },
  { label: "PARGNAN (02160)", value: "02160" },
  { label: "PARGNY FILAIN (02000)", value: "02000" },
  { label: "PARGNY LA DHUYS (02330)", value: "02330" },
  { label: "PARGNY LES BOIS (02270)", value: "02270" },
  { label: "PARPEVILLE (02240)", value: "02240" },
  { label: "PASLY (02200)", value: "02200" },
  { label: "PASSY EN VALOIS (02470)", value: "02470" },
  { label: "PASSY SUR MARNE (02850)", value: "02850" },
  { label: "PAVANT (02310)", value: "02310" },
  { label: "PERNANT (02200)", value: "02200" },
  { label: "PIERREMANDE (02300)", value: "02300" },
  { label: "PIERREPONT (02350)", value: "02350" },
  { label: "PIGNICOURT (02190)", value: "02190" },
  { label: "PINON (02320)", value: "02320" },
  { label: "PITHON (02480)", value: "02480" },
  { label: "PLEINE SELVE (02240)", value: "02240" },
  { label: "LE PLESSIER HULEU (02210)", value: "02210" },
  { label: "PLOISY (02200)", value: "02200" },
  { label: "PLOMION (02140)", value: "02140" },
  { label: "PLOYART ET VAURSEINE (02860)", value: "02860" },
  { label: "POMMIERS (02200)", value: "02200" },
  { label: "PONT ARCY (02160)", value: "02160" },
  { label: "PONTAVERT (02160)", value: "02160" },
  { label: "PONTRU (02490)", value: "02490" },
  { label: "PONTRUET (02490)", value: "02490" },
  { label: "PONT ST MARD (02380)", value: "02380" },
  { label: "POUILLY SUR SERRE (02270)", value: "02270" },
  { label: "PREMONT (02110)", value: "02110" },
  { label: "PREMONTRE (02320)", value: "02320" },
  { label: "PRESLES ET BOVES (02370)", value: "02370" },
  { label: "PRESLES ET THIERNY (02860)", value: "02860" },
  { label: "PRIEZ (02470)", value: "02470" },
  { label: "PRISCES (02140)", value: "02140" },
  { label: "PROISY (02120)", value: "02120" },
  { label: "PROIX (02120)", value: "02120" },
  { label: "PROUVAIS (02190)", value: "02190" },
  { label: "PROVISEUX ET PLESNOY (02190)", value: "02190" },
  { label: "PUISEUX EN RETZ (02600)", value: "02600" },
  { label: "PUISIEUX ET CLANLIEU (02120)", value: "02120" },
  { label: "QUIERZY (02300)", value: "02300" },
  { label: "QUINCY BASSE (02380)", value: "02380" },
  { label: "QUINCY SOUS LE MONT (02220)", value: "02220" },
  { label: "RAILLIMONT (02360)", value: "02360" },
  { label: "RAMICOURT (02110)", value: "02110" },
  { label: "REGNY (02240)", value: "02240" },
  { label: "REMAUCOURT (02100)", value: "02100" },
  { label: "REMIES (02270)", value: "02270" },
  { label: "REMIGNY (02440)", value: "02440" },
  { label: "RENANSART (02240)", value: "02240" },
  { label: "RENNEVAL (02340)", value: "02340" },
  { label: "RESIGNY (02360)", value: "02360" },
  { label: "RESSONS LE LONG (02290)", value: "02290" },
  { label: "RETHEUIL (02600)", value: "02600" },
  { label: "REUILLY SAUVIGNY (02850)", value: "02850" },
  { label: "RIBEAUVILLE (02110)", value: "02110" },
  { label: "RIBEMONT (02240)", value: "02240" },
  { label: "ROCOURT ST MARTIN (02210)", value: "02210" },
  { label: "ROCQUIGNY (02260)", value: "02260" },
  { label: "ROGECOURT (02800)", value: "02800" },
  { label: "ROGNY (02140)", value: "02140" },
  { label: "ROMENY SUR MARNE (02310)", value: "02310" },
  { label: "ROMERY (02120)", value: "02120" },
  { label: "RONCHERES (02130)", value: "02130" },
  { label: "ROUCY (02160)", value: "02160" },
  { label: "ROUGERIES (02140)", value: "02140" },
  { label: "ROUPY (02590)", value: "02590" },
  { label: "ROUVROY (02100)", value: "02100" },
  { label: "ROUVROY SUR SERRE (02360)", value: "02360" },
  { label: "ROYAUCOURT ET CHAILVET (02000)", value: "02000" },
  { label: "ROZET ST ALBIN (02210)", value: "02210" },
  { label: "ROZIERES SUR CRISE (02200)", value: "02200" },
  { label: "ROZOY BELLEVALLE (02540)", value: "02540" },
  { label: "GRAND ROZOY (02210)", value: "02210" },
  { label: "ROZOY SUR SERRE (02360)", value: "02360" },
  { label: "SACONIN ET BREUIL (02200)", value: "02200" },
  { label: "SAINS RICHAUMONT (02120)", value: "02120" },
  { label: "ST ALGIS (02260)", value: "02260" },
  { label: "ST AUBIN (02300)", value: "02300" },
  { label: "ST BANDRY (02290)", value: "02290" },
  { label: "ST CHRISTOPHE A BERRY (02290)", value: "02290" },
  { label: "ST CLEMENT (02360)", value: "02360" },
  { label: "STE CROIX (02820)", value: "02820" },
  { label: "ST ERME OUTRE ET RAMECOURT (02820)", value: "02820" },
  { label: "ST EUGENE (02330)", value: "02330" },
  { label: "STE GENEVIEVE (02340)", value: "02340" },
  { label: "ST GENGOULPH (02810)", value: "02810" },
  { label: "ST GOBAIN (02410)", value: "02410" },
  { label: "ST GOBERT (02140)", value: "02140" },
  { label: "ST MARD (02220)", value: "02220" },
  { label: "ST MARTIN RIVIERE (02110)", value: "02110" },
  { label: "ST MICHEL (02830)", value: "02830" },
  { label: "ST NICOLAS AUX BOIS (02410)", value: "02410" },
  { label: "ST PAUL AUX BOIS (02300)", value: "02300" },
  { label: "ST PIERRE AIGLE (02600)", value: "02600" },
  { label: "ST PIERRE LES FRANQUEVILLE (02140)", value: "02140" },
  { label: "ST PIERREMONT (02250)", value: "02250" },
  { label: "STE PREUVE (02350)", value: "02350" },
  { label: "ST QUENTIN (02100)", value: "02100" },
  { label: "ST REMY BLANZY (02210)", value: "02210" },
  { label: "ST SIMON (02640)", value: "02640" },
  { label: "ST THOMAS (02820)", value: "02820" },
  { label: "SAMOUSSY (02840)", value: "02840" },
  { label: "SANCY LES CHEMINOTS (02880)", value: "02880" },
  { label: "SAPONAY (02130)", value: "02130" },
  { label: "SAULCHERY (02310)", value: "02310" },
  { label: "SAVY (02590)", value: "02590" },
  { label: "SEBONCOURT (02110)", value: "02110" },
  { label: "SELENS (02300)", value: "02300" },
  { label: "LA SELVE (02150)", value: "02150" },
  { label: "SEPTMONTS (02200)", value: "02200" },
  { label: "SEPTVAUX (02410)", value: "02410" },
  { label: "SEQUEHART (02420)", value: "02420" },
  { label: "SERAIN (02110)", value: "02110" },
  { label: "SERAUCOURT LE GRAND (02790)", value: "02790" },
  { label: "SERCHES (02220)", value: "02220" },
  { label: "SERGY (02130)", value: "02130" },
  { label: "SERINGES ET NESLES (02130)", value: "02130" },
  { label: "SERMOISE (02220)", value: "02220" },
  { label: "SERVAL (02160)", value: "02160" },
  { label: "SERVAIS (02700)", value: "02700" },
  { label: "SERY LES MEZIERES (02240)", value: "02240" },
  { label: "SILLY LA POTERIE (02460)", value: "02460" },
  { label: "SINCENY (02300)", value: "02300" },
  { label: "SISSONNE (02150)", value: "02150" },
  { label: "SISSY (02240)", value: "02240" },
  { label: "SOISSONS (02200)", value: "02200" },
  { label: "SOIZE (02340)", value: "02340" },
  { label: "SOMMELANS (02470)", value: "02470" },
  { label: "SOMMERON (02260)", value: "02260" },
  { label: "SOMMETTE EAUCOURT (02480)", value: "02480" },
  { label: "SONS ET RONCHERES (02270)", value: "02270" },
  { label: "SORBAIS (02580)", value: "02580" },
  { label: "SOUCY (02600)", value: "02600" },
  { label: "SOUPIR (02160)", value: "02160" },
  { label: "LE SOURD (02140)", value: "02140" },
  { label: "SURFONTAINE (02240)", value: "02240" },
  { label: "TAILLEFONTAINE (02600)", value: "02600" },
  { label: "TANNIERES (02220)", value: "02220" },
  { label: "TARTIERS (02290)", value: "02290" },
  { label: "TAVAUX ET PONTSERICOURT (02250)", value: "02250" },
  { label: "TERGNIER (02700)", value: "02700" },
  { label: "TERGNIER (02700)", value: "02700" },
  { label: "TERGNIER (02700)", value: "02700" },
  { label: "TERGNIER (02700)", value: "02700" },
  { label: "TERNY SORNY (02880)", value: "02880" },
  { label: "THENAILLES (02140)", value: "02140" },
  { label: "THENELLES (02390)", value: "02390" },
  { label: "THIERNU (02250)", value: "02250" },
  { label: "LE THUEL (02340)", value: "02340" },
  { label: "TORCY EN VALOIS (02810)", value: "02810" },
  { label: "TOULIS ET ATTENCOURT (02250)", value: "02250" },
  { label: "TRAVECY (02800)", value: "02800" },
  { label: "TREFCON (02490)", value: "02490" },
  { label: "TRELOU SUR MARNE (02850)", value: "02850" },
  { label: "TROESNES (02460)", value: "02460" },
  { label: "TROSLY LOIRE (02300)", value: "02300" },
  { label: "TRUCY (02860)", value: "02860" },
  { label: "TUGNY ET PONT (02640)", value: "02640" },
  { label: "TUPIGNY (02120)", value: "02120" },
  { label: "UGNY LE GAY (02300)", value: "02300" },
  { label: "URCEL (02000)", value: "02000" },
  { label: "URVILLERS (02690)", value: "02690" },
  { label: "VADENCOURT (02120)", value: "02120" },
  { label: "VADENCOURT (02120)", value: "02120" },
  { label: "VAILLY SUR AISNE (02370)", value: "02370" },
  { label: "LA VALLEE AU BLE (02140)", value: "02140" },
  { label: "LA VALLEE MULATRE (02110)", value: "02110" },
  { label: "VARISCOURT (02190)", value: "02190" },
  { label: "VASSENS (02290)", value: "02290" },
  { label: "VASSENY (02220)", value: "02220" },
  { label: "VASSOGNE (02160)", value: "02160" },
  { label: "VAUCELLES ET BEFFECOURT (02000)", value: "02000" },
  { label: "VAUDESSON (02320)", value: "02320" },
  { label: "VAUXREZIS (02200)", value: "02200" },
  { label: "VAUXAILLON (02320)", value: "02320" },
  { label: "VAUX ANDIGNY (02110)", value: "02110" },
  { label: "VAUXBUIN (02200)", value: "02200" },
  { label: "VAUX EN VERMANDOIS (02590)", value: "02590" },
  { label: "VAUXTIN (02220)", value: "02220" },
  { label: "VENDELLES (02490)", value: "02490" },
  { label: "VENDEUIL (02800)", value: "02800" },
  { label: "VENDHUILE (02420)", value: "02420" },
  { label: "VENDIERES (02540)", value: "02540" },
  { label: "VENDRESSE BEAULNE (02160)", value: "02160" },
  { label: "VENEROLLES (02510)", value: "02510" },
  { label: "VENIZEL (02200)", value: "02200" },
  { label: "VERDILLY (02400)", value: "02400" },
  { label: "LE VERGUIER (02490)", value: "02490" },
  { label: "GRAND VERLY (02120)", value: "02120" },
  { label: "PETIT VERLY (02630)", value: "02630" },
  { label: "VERMAND (02490)", value: "02490" },
  { label: "VERNEUIL SOUS COUCY (02380)", value: "02380" },
  { label: "VERNEUIL SUR SERRE (02000)", value: "02000" },
  { label: "VERSIGNY (02800)", value: "02800" },
  { label: "VERVINS (02140)", value: "02140" },
  { label: "VESLES ET CAUMONT (02350)", value: "02350" },
  { label: "VESLUD (02840)", value: "02840" },
  { label: "VEUILLY LA POTERIE (02810)", value: "02810" },
  { label: "VEZAPONIN (02290)", value: "02290" },
  { label: "VEZILLY (02130)", value: "02130" },
  { label: "VIC SUR AISNE (02290)", value: "02290" },
  { label: "VICHEL NANTEUIL (02210)", value: "02210" },
  { label: "VIEL ARCY (02160)", value: "02160" },
  { label: "VIELS MAISONS (02540)", value: "02540" },
  { label: "VIERZY (02210)", value: "02210" },
  { label: "VIFFORT (02540)", value: "02540" },
  { label: "VIGNEUX HOCQUET (02340)", value: "02340" },
  { label: "LA VILLE AUX BOIS LES DIZY (02340)", value: "02340" },
  { label: "LA VILLE AUX BOIS LES PONTAVERT (02160)", value: "02160" },
  { label: "VILLEMONTOIRE (02210)", value: "02210" },
  { label: "VILLENEUVE ST GERMAIN (02200)", value: "02200" },
  { label: "VILLENEUVE SUR FERE (02130)", value: "02130" },
  { label: "VILLEQUIER AUMONT (02300)", value: "02300" },
  { label: "VILLERET (02420)", value: "02420" },
  { label: "VILLERS AGRON AIGUIZY (02130)", value: "02130" },
  { label: "VILLERS COTTERETS (02600)", value: "02600" },
  { label: "VILLERS COTTERETS (02600)", value: "02600" },
  { label: "VILLERS HELON (02600)", value: "02600" },
  { label: "VILLERS LE SEC (02240)", value: "02240" },
  { label: "VILLERS LES GUISE (02120)", value: "02120" },
  { label: "VILLERS ST CHRISTOPHE (02590)", value: "02590" },
  { label: "VILLERS SUR FERE (02130)", value: "02130" },
  { label: "VILLE SAVOYE (02220)", value: "02220" },
  { label: "VILLIERS ST DENIS (02310)", value: "02310" },
  { label: "VINCY REUIL ET MAGNY (02340)", value: "02340" },
  { label: "VIRY NOUREUIL (02300)", value: "02300" },
  { label: "VIVAISE (02870)", value: "02870" },
  { label: "VIVIERES (02600)", value: "02600" },
  { label: "VOHARIES (02140)", value: "02140" },
  { label: "VORGES (02860)", value: "02860" },
  { label: "VOULPAIX (02140)", value: "02140" },
  { label: "VOYENNE (02250)", value: "02250" },
  { label: "VREGNY (02880)", value: "02880" },
  { label: "VUILLERY (02880)", value: "02880" },
  { label: "WASSIGNY (02630)", value: "02630" },
  { label: "WATIGNY (02830)", value: "02830" },
  { label: "WIEGE FATY (02120)", value: "02120" },
  { label: "WIMY (02500)", value: "02500" },
  { label: "WISSIGNICOURT (02320)", value: "02320" },
  { label: "ABREST (03200)", value: "03200" },
  { label: "AGONGES (03210)", value: "03210" },
  { label: "AINAY LE CHATEAU (03360)", value: "03360" },
  { label: "ANDELAROCHE (03120)", value: "03120" },
  { label: "ARCHIGNAT (03380)", value: "03380" },
  { label: "ARFEUILLES (03120)", value: "03120" },
  { label: "ARPHEUILLES ST PRIEST (03420)", value: "03420" },
  { label: "ARRONNES (03250)", value: "03250" },
  { label: "AUBIGNY (03460)", value: "03460" },
  { label: "AUDES (03190)", value: "03190" },
  { label: "AUROUER (03460)", value: "03460" },
  { label: "AUTRY ISSARDS (03210)", value: "03210" },
  { label: "AVERMES (03000)", value: "03000" },
  { label: "AVRILLY (03130)", value: "03130" },
  { label: "BAGNEUX (03460)", value: "03460" },
  { label: "BARBERIER (03140)", value: "03140" },
  { label: "BARRAIS BUSSOLLES (03120)", value: "03120" },
  { label: "BAYET (03500)", value: "03500" },
  { label: "BEAULON (03230)", value: "03230" },
  { label: "BEAUNE D ALLIER (03390)", value: "03390" },
  { label: "BEGUES (03800)", value: "03800" },
  { label: "BELLENAVES (03330)", value: "03330" },
  { label: "BELLERIVE SUR ALLIER (03700)", value: "03700" },
  { label: "BERT (03130)", value: "03130" },
  { label: "BESSAY SUR ALLIER (03340)", value: "03340" },
  { label: "BESSON (03210)", value: "03210" },
  { label: "BEZENET (03170)", value: "03170" },
  { label: "BILLEZOIS (03120)", value: "03120" },
  { label: "BILLY (03260)", value: "03260" },
  { label: "BIOZAT (03800)", value: "03800" },
  { label: "BIZENEUILLE (03170)", value: "03170" },
  { label: "BLOMARD (03390)", value: "03390" },
  { label: "BOST (03300)", value: "03300" },
  { label: "BOUCE (03150)", value: "03150" },
  { label: "LE BOUCHAUD (03130)", value: "03130" },
  { label: "BOURBON L ARCHAMBAULT (03160)", value: "03160" },
  { label: "BRAIZE (03360)", value: "03360" },
  { label: "BRANSAT (03500)", value: "03500" },
  { label: "BRESNAY (03210)", value: "03210" },
  { label: "BRESSOLLES (03000)", value: "03000" },
  { label: "LE BRETHON (03350)", value: "03350" },
  { label: "LE BREUIL (03120)", value: "03120" },
  { label: "BROUT VERNET (03110)", value: "03110" },
  { label: "BRUGHEAS (03700)", value: "03700" },
  { label: "BUSSET (03270)", value: "03270" },
  { label: "BUXIERES LES MINES (03440)", value: "03440" },
  { label: "LA CELLE (03600)", value: "03600" },
  { label: "CERILLY (03350)", value: "03350" },
  { label: "CESSET (03500)", value: "03500" },
  { label: "LA CHABANNE (03250)", value: "03250" },
  { label: "CHAMBERAT (03370)", value: "03370" },
  { label: "CHAMBLET (03170)", value: "03170" },
  { label: "CHANTELLE (03140)", value: "03140" },
  { label: "CHAPEAU (03340)", value: "03340" },
  { label: "LA CHAPELAUDE (03380)", value: "03380" },
  { label: "LA CHAPELLE (03300)", value: "03300" },
  { label: "LA CHAPELLE AUX CHASSES (03230)", value: "03230" },
  { label: "CHAPPES (03390)", value: "03390" },
  { label: "CHAREIL CINTRAT (03140)", value: "03140" },
  { label: "CHARMEIL (03110)", value: "03110" },
  { label: "CHARMES (03800)", value: "03800" },
  { label: "CHARROUX (03140)", value: "03140" },
  { label: "CHASSENARD (03510)", value: "03510" },
  { label: "CHATEAU SUR ALLIER (03320)", value: "03320" },
  { label: "CHATEL DE NEUVRE (03500)", value: "03500" },
  { label: "CHATEL MONTAGNE (03250)", value: "03250" },
  { label: "CHATELPERRON (03220)", value: "03220" },
  { label: "CHATELUS (03120)", value: "03120" },
  { label: "CHATILLON (03210)", value: "03210" },
  { label: "CHAVENON (03440)", value: "03440" },
  { label: "CHAVROCHES (03220)", value: "03220" },
  { label: "CHAZEMAIS (03370)", value: "03370" },
  { label: "CHEMILLY (03210)", value: "03210" },
  { label: "CHEVAGNES (03230)", value: "03230" },
  { label: "CHEZELLE (03140)", value: "03140" },
  { label: "CHEZY (03230)", value: "03230" },
  { label: "CHIRAT L EGLISE (03330)", value: "03330" },
  { label: "CHOUVIGNY (03450)", value: "03450" },
  { label: "CINDRE (03220)", value: "03220" },
  { label: "COGNAT LYONNE (03110)", value: "03110" },
  { label: "COLOMBIER (03600)", value: "03600" },
  { label: "COMMENTRY (03600)", value: "03600" },
  { label: "CONTIGNY (03500)", value: "03500" },
  { label: "COSNE D ALLIER (03430)", value: "03430" },
  { label: "COULANDON (03000)", value: "03000" },
  { label: "COULANGES (03470)", value: "03470" },
  { label: "COULEUVRE (03320)", value: "03320" },
  { label: "COURCAIS (03370)", value: "03370" },
  { label: "COUTANSOUZE (03330)", value: "03330" },
  { label: "COUZON (03160)", value: "03160" },
  { label: "CRECHY (03150)", value: "03150" },
  { label: "CRESSANGES (03240)", value: "03240" },
  { label: "CREUZIER LE NEUF (03300)", value: "03300" },
  { label: "CREUZIER LE VIEUX (03300)", value: "03300" },
  { label: "CUSSET (03300)", value: "03300" },
  { label: "DENEUILLE LES CHANTELLE (03140)", value: "03140" },
  { label: "DENEUILLE LES MINES (03170)", value: "03170" },
  { label: "DESERTINES (03630)", value: "03630" },
  { label: "DEUX CHAISES (03240)", value: "03240" },
  { label: "DIOU (03290)", value: "03290" },
  { label: "DOMERAT (03410)", value: "03410" },
  { label: "DOMPIERRE SUR BESBRE (03290)", value: "03290" },
  { label: "LE DONJON (03130)", value: "03130" },
  { label: "DOYET (03170)", value: "03170" },
  { label: "DROITURIER (03120)", value: "03120" },
  { label: "DURDAT LAREQUILLE (03310)", value: "03310" },
  { label: "EBREUIL (03450)", value: "03450" },
  { label: "ECHASSIERES (03330)", value: "03330" },
  { label: "ESCUROLLES (03110)", value: "03110" },
  { label: "ESPINASSE VOZELLE (03110)", value: "03110" },
  { label: "ESTIVAREILLES (03190)", value: "03190" },
  { label: "ETROUSSAT (03140)", value: "03140" },
  { label: "FERRIERES SUR SICHON (03250)", value: "03250" },
  { label: "LA FERTE HAUTERIVE (03340)", value: "03340" },
  { label: "FLEURIEL (03140)", value: "03140" },
  { label: "FOURILLES (03140)", value: "03140" },
  { label: "FRANCHESSE (03160)", value: "03160" },
  { label: "GANNAT (03800)", value: "03800" },
  { label: "GANNAY SUR LOIRE (03230)", value: "03230" },
  { label: "GARNAT SUR ENGIEVRE (03230)", value: "03230" },
  { label: "GENNETINES (03400)", value: "03400" },
  { label: "GIPCY (03210)", value: "03210" },
  { label: "GOUISE (03340)", value: "03340" },
  { label: "LA GUILLERMIE (03250)", value: "03250" },
  { label: "HAUTERIVE (03270)", value: "03270" },
  { label: "HERISSON (03190)", value: "03190" },
  { label: "HURIEL (03380)", value: "03380" },
  { label: "HYDS (03600)", value: "03600" },
  { label: "ISLE ET BARDAIS (03360)", value: "03360" },
  { label: "ISSERPENT (03120)", value: "03120" },
  { label: "JALIGNY SUR BESBRE (03220)", value: "03220" },
  { label: "JENZAT (03800)", value: "03800" },
  { label: "LAFELINE (03500)", value: "03500" },
  { label: "LALIZOLLE (03450)", value: "03450" },
  { label: "LAMAIDS (03380)", value: "03380" },
  { label: "LANGY (03150)", value: "03150" },
  { label: "LAPALISSE (03120)", value: "03120" },
  { label: "LAPRUGNE (03250)", value: "03250" },
  { label: "LAVAULT STE ANNE (03100)", value: "03100" },
  { label: "LAVOINE (03250)", value: "03250" },
  { label: "LENAX (03130)", value: "03130" },
  { label: "LETELON (03360)", value: "03360" },
  { label: "LIERNOLLES (03130)", value: "03130" },
  { label: "LIGNEROLLES (03410)", value: "03410" },
  { label: "LIMOISE (03320)", value: "03320" },
  { label: "LODDES (03130)", value: "03130" },
  { label: "LORIGES (03500)", value: "03500" },
  { label: "LOUCHY MONTFAND (03500)", value: "03500" },
  { label: "LOUROUX BOURBONNAIS (03350)", value: "03350" },
  { label: "LOUROUX DE BEAUNE (03600)", value: "03600" },
  { label: "LOUROUX DE BOUBLE (03330)", value: "03330" },
  { label: "LUNEAU (03130)", value: "03130" },
  { label: "LURCY LEVIS (03320)", value: "03320" },
  { label: "LUSIGNY (03230)", value: "03230" },
  { label: "MAGNET (03260)", value: "03260" },
  { label: "HAUT BOCAGE (03190)", value: "03190" },
  { label: "HAUT BOCAGE (03190)", value: "03190" },
  { label: "HAUT BOCAGE (03190)", value: "03190" },
  { label: "MALICORNE (03600)", value: "03600" },
  { label: "MARCENAT (03260)", value: "03260" },
  { label: "MARCILLAT EN COMBRAILLE (03420)", value: "03420" },
  { label: "MARIGNY (03210)", value: "03210" },
  { label: "MARIOL (03270)", value: "03270" },
  { label: "LE MAYET D ECOLE (03800)", value: "03800" },
  { label: "LE MAYET DE MONTAGNE (03250)", value: "03250" },
  { label: "MAZERIER (03800)", value: "03800" },
  { label: "MAZIRAT (03420)", value: "03420" },
  { label: "MEAULNE VITRAY (03360)", value: "03360" },
  { label: "MEAULNE VITRAY (03360)", value: "03360" },
  { label: "MEILLARD (03500)", value: "03500" },
  { label: "MEILLERS (03210)", value: "03210" },
  { label: "MERCY (03340)", value: "03340" },
  { label: "MESPLES (03370)", value: "03370" },
  { label: "MOLINET (03510)", value: "03510" },
  { label: "MOLLES (03300)", value: "03300" },
  { label: "MONESTIER (03140)", value: "03140" },
  { label: "MONETAY SUR ALLIER (03500)", value: "03500" },
  { label: "MONETAY SUR LOIRE (03470)", value: "03470" },
  { label: "MONTAIGUET EN FOREZ (03130)", value: "03130" },
  { label: "MONTAIGU LE BLIN (03150)", value: "03150" },
  { label: "MONTBEUGNY (03340)", value: "03340" },
  { label: "MONTCOMBROUX LES MINES (03130)", value: "03130" },
  { label: "MONTEIGNET SUR L ANDELOT (03800)", value: "03800" },
  { label: "LE MONTET (03240)", value: "03240" },
  { label: "MONTILLY (03000)", value: "03000" },
  { label: "MONTLUCON (03100)", value: "03100" },
  { label: "MONTMARAULT (03390)", value: "03390" },
  { label: "MONTOLDRE (03150)", value: "03150" },
  { label: "MONTORD (03500)", value: "03500" },
  { label: "MONTVICQ (03170)", value: "03170" },
  { label: "MOULINS (03000)", value: "03000" },
  { label: "MURAT (03390)", value: "03390" },
  { label: "NADES (03450)", value: "03450" },
  { label: "NASSIGNY (03190)", value: "03190" },
  { label: "NAVES (03330)", value: "03330" },
  { label: "NERIS LES BAINS (03310)", value: "03310" },
  { label: "NEUILLY EN DONJON (03130)", value: "03130" },
  { label: "NEUILLY LE REAL (03340)", value: "03340" },
  { label: "NEURE (03320)", value: "03320" },
  { label: "NEUVY (03000)", value: "03000" },
  { label: "NIZEROLLES (03250)", value: "03250" },
  { label: "NOYANT D ALLIER (03210)", value: "03210" },
  { label: "PARAY LE FRESIL (03230)", value: "03230" },
  { label: "PARAY SOUS BRIAILLES (03500)", value: "03500" },
  { label: "PERIGNY (03120)", value: "03120" },
  { label: "LA PETITE MARCHE (03420)", value: "03420" },
  { label: "PIERREFITTE SUR LOIRE (03470)", value: "03470" },
  { label: "LE PIN (03130)", value: "03130" },
  { label: "POEZAT (03800)", value: "03800" },
  { label: "POUZY MESANGY (03320)", value: "03320" },
  { label: "PREMILHAT (03410)", value: "03410" },
  { label: "QUINSSAINES (03380)", value: "03380" },
  { label: "REUGNY (03190)", value: "03190" },
  { label: "ROCLES (03240)", value: "03240" },
  { label: "RONGERES (03150)", value: "03150" },
  { label: "RONNET (03420)", value: "03420" },
  { label: "ST ANGEL (03170)", value: "03170" },
  { label: "ST AUBIN LE MONIAL (03160)", value: "03160" },
  { label: "ST BONNET DE FOUR (03390)", value: "03390" },
  { label: "ST BONNET DE ROCHEFORT (03800)", value: "03800" },
  { label: "ST BONNET TRONCAIS (03360)", value: "03360" },
  { label: "ST CAPRAIS (03190)", value: "03190" },
  { label: "ST CHRISTOPHE EN BOURBONNAIS (03120)", value: "03120" },
  { label: "ST CLEMENT (03250)", value: "03250" },
  { label: "ST DESIRE (03370)", value: "03370" },
  { label: "ST DIDIER EN DONJON (03130)", value: "03130" },
  { label: "ST DIDIER LA FORET (03110)", value: "03110" },
  { label: "ST ELOY D ALLIER (03370)", value: "03370" },
  { label: "ST ENNEMOND (03400)", value: "03400" },
  { label: "ST ETIENNE DE VICQ (03300)", value: "03300" },
  { label: "ST FARGEOL (03420)", value: "03420" },
  { label: "ST FELIX (03260)", value: "03260" },
  { label: "ST GENEST (03310)", value: "03310" },
  { label: "ST GERAND DE VAUX (03340)", value: "03340" },
  { label: "ST GERAND LE PUY (03150)", value: "03150" },
  { label: "ST GERMAIN DES FOSSES (03260)", value: "03260" },
  { label: "ST GERMAIN DE SALLES (03140)", value: "03140" },
  { label: "ST HILAIRE (03440)", value: "03440" },
  { label: "ST LEGER SUR VOUZANCE (03130)", value: "03130" },
  { label: "ST LEON (03220)", value: "03220" },
  { label: "ST LEOPARDIN D AUGY (03160)", value: "03160" },
  { label: "ST LOUP (03150)", value: "03150" },
  { label: "ST MARCEL EN MURAT (03390)", value: "03390" },
  { label: "ST MARCEL EN MARCILLAT (03420)", value: "03420" },
  { label: "ST MARTIN DES LAIS (03230)", value: "03230" },
  { label: "ST MARTINIEN (03380)", value: "03380" },
  { label: "ST MENOUX (03210)", value: "03210" },
  { label: "ST NICOLAS DES BIEFS (03250)", value: "03250" },
  { label: "ST PALAIS (03370)", value: "03370" },
  { label: "ST PIERRE LAVAL (42620)", value: "42620" },
  { label: "ST PLAISIR (03160)", value: "03160" },
  { label: "ST PONT (03110)", value: "03110" },
  { label: "ST POURCAIN SUR BESBRE (03290)", value: "03290" },
  { label: "ST POURCAIN SUR SIOULE (03500)", value: "03500" },
  { label: "ST PRIEST D ANDELOT (03800)", value: "03800" },
  { label: "ST PRIEST EN MURAT (03390)", value: "03390" },
  { label: "ST PRIX (03120)", value: "03120" },
  { label: "ST REMY EN ROLLAT (03110)", value: "03110" },
  { label: "ST SAUVIER (03370)", value: "03370" },
  { label: "ST SORNIN (03240)", value: "03240" },
  { label: "STE THERENCE (03420)", value: "03420" },
  { label: "ST VICTOR (03410)", value: "03410" },
  { label: "ST VOIR (03220)", value: "03220" },
  { label: "ST YORRE (03270)", value: "03270" },
  { label: "SALIGNY SUR ROUDON (03470)", value: "03470" },
  { label: "SANSSAT (03150)", value: "03150" },
  { label: "SAULCET (03500)", value: "03500" },
  { label: "SAULZET (03800)", value: "03800" },
  { label: "SAUVAGNY (03430)", value: "03430" },
  { label: "SAZERET (03390)", value: "03390" },
  { label: "SERBANNES (03700)", value: "03700" },
  { label: "SERVILLY (03120)", value: "03120" },
  { label: "SEUILLET (03260)", value: "03260" },
  { label: "SORBIER (03220)", value: "03220" },
  { label: "SOUVIGNY (03210)", value: "03210" },
  { label: "SUSSAT (03450)", value: "03450" },
  { label: "TARGET (03140)", value: "03140" },
  { label: "TAXAT SENAT (03140)", value: "03140" },
  { label: "TEILLET ARGENTY (03410)", value: "03410" },
  { label: "TERJAT (03420)", value: "03420" },
  { label: "LE THEIL (03240)", value: "03240" },
  { label: "THENEUILLE (03350)", value: "03350" },
  { label: "THIEL SUR ACOLIN (03230)", value: "03230" },
  { label: "THIONNE (03220)", value: "03220" },
  { label: "TORTEZAIS (03430)", value: "03430" },
  { label: "TOULON SUR ALLIER (03400)", value: "03400" },
  { label: "TREBAN (03240)", value: "03240" },
  { label: "TREIGNAT (03380)", value: "03380" },
  { label: "TRETEAU (03220)", value: "03220" },
  { label: "TREVOL (03460)", value: "03460" },
  { label: "TREZELLES (03220)", value: "03220" },
  { label: "TRONGET (03240)", value: "03240" },
  { label: "URCAY (03360)", value: "03360" },
  { label: "USSEL D ALLIER (03140)", value: "03140" },
  { label: "VALIGNAT (03330)", value: "03330" },
  { label: "VALIGNY (03360)", value: "03360" },
  { label: "VALLON EN SULLY (03190)", value: "03190" },
  { label: "VARENNES SUR ALLIER (03150)", value: "03150" },
  { label: "VARENNES SUR TECHE (03220)", value: "03220" },
  { label: "VAUMAS (03220)", value: "03220" },
  { label: "VAUX (03190)", value: "03190" },
  { label: "VEAUCE (03450)", value: "03450" },
  { label: "VENAS (03190)", value: "03190" },
  { label: "VENDAT (03110)", value: "03110" },
  { label: "VERNEIX (03190)", value: "03190" },
  { label: "LE VERNET (03200)", value: "03200" },
  { label: "VERNEUIL EN BOURBONNAIS (03500)", value: "03500" },
  { label: "VERNUSSE (03390)", value: "03390" },
  { label: "LE VEURDRE (03320)", value: "03320" },
  { label: "VICHY (03200)", value: "03200" },
  { label: "VICQ (03450)", value: "03450" },
  { label: "VIEURE (03430)", value: "03430" },
  { label: "LE VILHAIN (03350)", value: "03350" },
  { label: "VILLEBRET (03310)", value: "03310" },
  { label: "VILLEFRANCHE D ALLIER (03430)", value: "03430" },
  { label: "VILLEFRANCHE D ALLIER (03430)", value: "03430" },
  { label: "VILLENEUVE SUR ALLIER (03460)", value: "03460" },
  { label: "VIPLAIX (03370)", value: "03370" },
  { label: "VOUSSAC (03140)", value: "03140" },
  { label: "YGRANDE (03160)", value: "03160" },
  { label: "YZEURE (03400)", value: "03400" },
  { label: "AIGLUN (04510)", value: "04510" },
  { label: "ALLEMAGNE EN PROVENCE (04500)", value: "04500" },
  { label: "ALLONS (04170)", value: "04170" },
  { label: "ALLOS (04260)", value: "04260" },
  { label: "ALLOS (04260)", value: "04260" },
  { label: "ANGLES (04170)", value: "04170" },
  { label: "ANNOT (04240)", value: "04240" },
  { label: "ARCHAIL (04420)", value: "04420" },
  { label: "AUBENAS LES ALPES (04110)", value: "04110" },
  { label: "AUBIGNOSC (04200)", value: "04200" },
  { label: "AUTHON (04200)", value: "04200" },
  { label: "AUZET (04140)", value: "04140" },
  { label: "BANON (04150)", value: "04150" },
  { label: "BARCELONNETTE (04400)", value: "04400" },
  { label: "BARLES (04140)", value: "04140" },
  { label: "BARRAS (04380)", value: "04380" },
  { label: "BARREME (04330)", value: "04330" },
  { label: "BAYONS (04250)", value: "04250" },
  { label: "BAYONS (04250)", value: "04250" },
  { label: "BAYONS (04250)", value: "04250" },
  { label: "BAYONS (04250)", value: "04250" },
  { label: "BEAUJEU (04420)", value: "04420" },
  { label: "BEAUVEZER (04370)", value: "04370" },
  { label: "BELLAFFAIRE (04250)", value: "04250" },
  { label: "BEVONS (04200)", value: "04200" },
  { label: "BEYNES (04270)", value: "04270" },
  { label: "BLIEUX (04330)", value: "04330" },
  { label: "BRAS D ASSE (04270)", value: "04270" },
  { label: "BRAUX (04240)", value: "04240" },
  { label: "UBAYE SERRE PONCON (04340)", value: "04340" },
  { label: "UBAYE SERRE PONCON (04340)", value: "04340" },
  { label: "LA BRILLANNE (04700)", value: "04700" },
  { label: "BRUNET (04210)", value: "04210" },
  { label: "LE BRUSQUET (04420)", value: "04420" },
  { label: "LE CAIRE (04250)", value: "04250" },
  { label: "CASTELLANE (04120)", value: "04120" },
  { label: "CASTELLANE (04120)", value: "04120" },
  { label: "CASTELLANE (04120)", value: "04120" },
  { label: "CASTELLANE (04120)", value: "04120" },
  { label: "CASTELLANE (04120)", value: "04120" },
  { label: "CASTELLANE (04120)", value: "04120" },
  { label: "LE CASTELLARD MELAN (04380)", value: "04380" },
  { label: "LE CASTELLARD MELAN (04380)", value: "04380" },
  { label: "LE CASTELLET (04700)", value: "04700" },
  { label: "CASTELLET LES SAUSSES (04320)", value: "04320" },
  { label: "VAL DE CHALVAGNE (04320)", value: "04320" },
  { label: "VAL DE CHALVAGNE (04320)", value: "04320" },
  { label: "VAL DE CHALVAGNE (04320)", value: "04320" },
  { label: "CERESTE (04280)", value: "04280" },
  { label: "LE CHAFFAUT ST JURSON (04510)", value: "04510" },
  { label: "LE CHAFFAUT ST JURSON (04510)", value: "04510" },
  { label: "CHAMPTERCIER (04660)", value: "04660" },
  { label: "CHATEAU ARNOUX ST AUBAN (04160)", value: "04160" },
  { label: "CHATEAU ARNOUX ST AUBAN (04600)", value: "04600" },
  { label: "CHATEAUFORT (04250)", value: "04250" },
  { label: "CHATEAUNEUF MIRAVAIL (04200)", value: "04200" },
  { label: "CHATEAUNEUF VAL ST DONAT (04200)", value: "04200" },
  { label: "CHATEAUREDON (04270)", value: "04270" },
  { label: "CHAUDON NORANTE (04330)", value: "04330" },
  { label: "CLAMENSANE (04250)", value: "04250" },
  { label: "CLARET (05110)", value: "05110" },
  { label: "CLUMANC (04330)", value: "04330" },
  { label: "COLMARS (04370)", value: "04370" },
  { label: "LA CONDAMINE CHATELARD (04530)", value: "04530" },
  { label: "LA CONDAMINE CHATELARD (04530)", value: "04530" },
  { label: "CORBIERES EN PROVENCE (04220)", value: "04220" },
  { label: "CRUIS (04230)", value: "04230" },
  { label: "CURBANS (05110)", value: "05110" },
  { label: "CUREL (04200)", value: "04200" },
  { label: "DAUPHIN (04300)", value: "04300" },
  { label: "DEMANDOLX (04120)", value: "04120" },
  { label: "DIGNE LES BAINS (04000)", value: "04000" },
  { label: "DIGNE LES BAINS (04000)", value: "04000" },
  { label: "DRAIX (04420)", value: "04420" },
  { label: "ENCHASTRAYES (04400)", value: "04400" },
  { label: "ENCHASTRAYES (04400)", value: "04400" },
  { label: "ENTRAGES (04000)", value: "04000" },
  { label: "ENTRAGES (04270)", value: "04270" },
  { label: "ENTREPIERRES (04200)", value: "04200" },
  { label: "ENTREPIERRES (04200)", value: "04200" },
  { label: "ENTREPIERRES (04200)", value: "04200" },
  { label: "ENTREVAUX (04320)", value: "04320" },
  { label: "ENTREVENNES (04700)", value: "04700" },
  { label: "L'ESCALE (04160)", value: "04160" },
  { label: "ESPARRON DE VERDON (04800)", value: "04800" },
  { label: "ESPARRON DE VERDON (04800)", value: "04800" },
  { label: "ESTOUBLON (04270)", value: "04270" },
  { label: "ESTOUBLON (04270)", value: "04270" },
  { label: "FAUCON DU CAIRE (04250)", value: "04250" },
  { label: "FAUCON DE BARCELONNETTE (04400)", value: "04400" },
  { label: "FONTIENNE (04230)", value: "04230" },
  { label: "FORCALQUIER (04300)", value: "04300" },
  { label: "LE FUGERET (04240)", value: "04240" },
  { label: "GANAGOBIE (04310)", value: "04310" },
  { label: "LA GARDE (04120)", value: "04120" },
  { label: "GIGORS (04250)", value: "04250" },
  { label: "GREOUX LES BAINS (04800)", value: "04800" },
  { label: "L'HOSPITALET (04150)", value: "04150" },
  { label: "JAUSIERS (04850)", value: "04850" },
  { label: "LA JAVIE (04000)", value: "04000" },
  { label: "LA JAVIE (04420)", value: "04420" },
  { label: "LAMBRUISSE (04170)", value: "04170" },
  { label: "LARDIERS (04230)", value: "04230" },
  { label: "LE LAUZET UBAYE (04340)", value: "04340" },
  { label: "LIMANS (04300)", value: "04300" },
  { label: "LURS (04700)", value: "04700" },
  { label: "MAJASTRES (04270)", value: "04270" },
  { label: "MALIJAI (04350)", value: "04350" },
  { label: "MALIJAI (04510)", value: "04510" },
  { label: "MALLEFOUGASSE AUGES (04230)", value: "04230" },
  { label: "MALLEMOISSON (04510)", value: "04510" },
  { label: "MANE (04300)", value: "04300" },
  { label: "MANOSQUE (04100)", value: "04100" },
  { label: "MARCOUX (04420)", value: "04420" },
  { label: "MEAILLES (04240)", value: "04240" },
  { label: "LES MEES (04190)", value: "04190" },
  { label: "MELVE (04250)", value: "04250" },
  { label: "VAL D ORONAYE (04530)", value: "04530" },
  { label: "VAL D ORONAYE (04530)", value: "04530" },
  { label: "MEZEL (04270)", value: "04270" },
  { label: "MIRABEAU (04510)", value: "04510" },
  { label: "MISON (04200)", value: "04200" },
  { label: "MONTAGNAC MONTPEZAT (04500)", value: "04500" },
  { label: "MONTAGNAC MONTPEZAT (04500)", value: "04500" },
  { label: "MONTCLAR (04140)", value: "04140" },
  { label: "MONTFORT (04600)", value: "04600" },
  { label: "MONTFURON (04110)", value: "04110" },
  { label: "MONTJUSTIN (04110)", value: "04110" },
  { label: "MONTLAUX (04230)", value: "04230" },
  { label: "MONTSALIER (04150)", value: "04150" },
  { label: "MORIEZ (04170)", value: "04170" },
  { label: "LA MOTTE DU CAIRE (04250)", value: "04250" },
  { label: "MOUSTIERS STE MARIE (04360)", value: "04360" },
  { label: "LA MURE ARGENS (04170)", value: "04170" },
  { label: "LA MURE ARGENS (04170)", value: "04170" },
  { label: "NIBLES (04250)", value: "04250" },
  { label: "NIOZELLES (04300)", value: "04300" },
  { label: "NOYERS SUR JABRON (04200)", value: "04200" },
  { label: "LES OMERGUES (04200)", value: "04200" },
  { label: "ONGLES (04230)", value: "04230" },
  { label: "OPPEDETTE (04110)", value: "04110" },
  { label: "ORAISON (04700)", value: "04700" },
  { label: "LA PALUD SUR VERDON (04120)", value: "04120" },
  { label: "LA PALUD SUR VERDON (04120)", value: "04120" },
  { label: "PEIPIN (04200)", value: "04200" },
  { label: "PEIPIN (04200)", value: "04200" },
  { label: "PEYROULES (04120)", value: "04120" },
  { label: "PEYRUIS (04310)", value: "04310" },
  { label: "PIEGUT (05130)", value: "05130" },
  { label: "PIERRERUE (04300)", value: "04300" },
  { label: "PIERREVERT (04860)", value: "04860" },
  { label: "PONTIS (05160)", value: "05160" },
  { label: "PRADS HAUTE BLEONE (04420)", value: "04420" },
  { label: "PRADS HAUTE BLEONE (04420)", value: "04420" },
  { label: "PRADS HAUTE BLEONE (04420)", value: "04420" },
  { label: "PUIMICHEL (04700)", value: "04700" },
  { label: "PUIMOISSON (04410)", value: "04410" },
  { label: "QUINSON (04500)", value: "04500" },
  { label: "REDORTIERS (04150)", value: "04150" },
  { label: "REILLANNE (04110)", value: "04110" },
  { label: "MEOLANS REVEL (04340)", value: "04340" },
  { label: "MEOLANS REVEL (04340)", value: "04340" },
  { label: "MEOLANS REVEL (04340)", value: "04340" },
  { label: "REVEST DES BROUSSES (04150)", value: "04150" },
  { label: "REVEST DU BION (04150)", value: "04150" },
  { label: "REVEST ST MARTIN (04230)", value: "04230" },
  { label: "RIEZ (04500)", value: "04500" },
  { label: "LA ROBINE SUR GALABRE (04000)", value: "04000" },
  { label: "LA ROBINE SUR GALABRE (04000)", value: "04000" },
  { label: "LA ROBINE SUR GALABRE (04000)", value: "04000" },
  { label: "LA ROBINE SUR GALABRE (04000)", value: "04000" },
  { label: "LA ROCHEGIRON (04150)", value: "04150" },
  { label: "LA ROCHETTE (06260)", value: "06260" },
  { label: "ROUGON (04120)", value: "04120" },
  { label: "ROUMOULES (04500)", value: "04500" },
  { label: "ST ANDRE LES ALPES (04170)", value: "04170" },
  { label: "ST BENOIT (04240)", value: "04240" },
  { label: "STE CROIX A LAUZE (04110)", value: "04110" },
  { label: "STE CROIX DU VERDON (04500)", value: "04500" },
  { label: "HAUTES DUYES (04380)", value: "04380" },
  { label: "HAUTES DUYES (04380)", value: "04380" },
  { label: "ST ETIENNE LES ORGUES (04230)", value: "04230" },
  { label: "ST GENIEZ (04200)", value: "04200" },
  { label: "ST JACQUES (04330)", value: "04330" },
  { label: "ST JEANNET (04270)", value: "04270" },
  { label: "ST JULIEN D ASSE (04270)", value: "04270" },
  { label: "ST JULIEN DU VERDON (04170)", value: "04170" },
  { label: "ST JURS (04410)", value: "04410" },
  { label: "ST LAURENT DU VERDON (04500)", value: "04500" },
  { label: "ST LIONS (04330)", value: "04330" },
  { label: "ST MAIME (04300)", value: "04300" },
  { label: "ST MARTIN DE BROMES (04800)", value: "04800" },
  { label: "ST MARTIN LES EAUX (04300)", value: "04300" },
  { label: "ST MARTIN LES SEYNE (04140)", value: "04140" },
  { label: "ST MICHEL L OBSERVATOIRE (04870)", value: "04870" },
  { label: "ST MICHEL L OBSERVATOIRE (04870)", value: "04870" },
  { label: "ST PAUL SUR UBAYE (04530)", value: "04530" },
  { label: "ST PIERRE (06260)", value: "06260" },
  { label: "ST PONS (04400)", value: "04400" },
  { label: "STE TULLE (04220)", value: "04220" },
  { label: "ST VINCENT SUR JABRON (04200)", value: "04200" },
  { label: "SALIGNAC (04290)", value: "04290" },
  { label: "SAUMANE (04150)", value: "04150" },
  { label: "SAUSSES (04320)", value: "04320" },
  { label: "SELONNET (04140)", value: "04140" },
  { label: "SENEZ (04270)", value: "04270" },
  { label: "SENEZ (04330)", value: "04330" },
  { label: "SEYNE (04140)", value: "04140" },
  { label: "SIGONCE (04300)", value: "04300" },
  { label: "SIGOYER (04200)", value: "04200" },
  { label: "SIMIANE LA ROTONDE (04150)", value: "04150" },
  { label: "SIMIANE LA ROTONDE (04150)", value: "04150" },
  { label: "SIMIANE LA ROTONDE (04150)", value: "04150" },
  { label: "SISTERON (04200)", value: "04200" },
  { label: "SOLEILHAS (04120)", value: "04120" },
  { label: "SOURRIBES (04290)", value: "04290" },
  { label: "TARTONNE (04330)", value: "04330" },
  { label: "THEZE (04200)", value: "04200" },
  { label: "THOARD (04380)", value: "04380" },
  { label: "THOARD (04380)", value: "04380" },
  { label: "THORAME BASSE (04170)", value: "04170" },
  { label: "THORAME HAUTE (04170)", value: "04170" },
  { label: "THORAME HAUTE (04170)", value: "04170" },
  { label: "LES THUILES (04400)", value: "04400" },
  { label: "TURRIERS (04250)", value: "04250" },
  { label: "UBRAYE (04240)", value: "04240" },
  { label: "UVERNET FOURS (04400)", value: "04400" },
  { label: "UVERNET FOURS (04400)", value: "04400" },
  { label: "UVERNET FOURS (04400)", value: "04400" },
  { label: "VACHERES (04110)", value: "04110" },
  { label: "VALAVOIRE (04250)", value: "04250" },
  { label: "VALBELLE (04200)", value: "04200" },
  { label: "VALENSOLE (04210)", value: "04210" },
  { label: "VALERNES (04200)", value: "04200" },
  { label: "VAUMEILH (04200)", value: "04200" },
  { label: "VENTEROL (05130)", value: "05130" },
  { label: "VERDACHES (04140)", value: "04140" },
  { label: "VERGONS (04170)", value: "04170" },
  { label: "LE VERNET (04140)", value: "04140" },
  { label: "VILLARS COLMARS (04370)", value: "04370" },
  { label: "VILLEMUS (04110)", value: "04110" },
  { label: "VILLENEUVE (04180)", value: "04180" },
  { label: "VOLONNE (04290)", value: "04290" },
  { label: "VOLX (04130)", value: "04130" },
  { label: "ABRIES RISTOLAS (05460)", value: "05460" },
  { label: "ABRIES RISTOLAS (05460)", value: "05460" },
  { label: "AIGUILLES (05470)", value: "05470" },
  { label: "ANCELLE (05260)", value: "05260" },
  { label: "L'ARGENTIERE LA BESSEE (05120)", value: "05120" },
  { label: "ARVIEUX (05350)", value: "05350" },
  { label: "ASPREMONT (05140)", value: "05140" },
  { label: "ASPRES LES CORPS (05800)", value: "05800" },
  { label: "ASPRES SUR BUECH (05140)", value: "05140" },
  { label: "AVANCON (05230)", value: "05230" },
  { label: "BARATIER (05200)", value: "05200" },
  { label: "BARCILLONNETTE (05110)", value: "05110" },
  { label: "BARRET SUR MEOUGE (05300)", value: "05300" },
  { label: "LA BATIE MONTSALEON (05700)", value: "05700" },
  { label: "LA BATIE NEUVE (05230)", value: "05230" },
  { label: "LA BATIE VIEILLE (05000)", value: "05000" },
  { label: "LA BEAUME (05140)", value: "05140" },
  { label: "LE BERSAC (05700)", value: "05700" },
  { label: "BREZIERS (05190)", value: "05190" },
  { label: "BRIANCON (05100)", value: "05100" },
  { label: "VALDOULE (05150)", value: "05150" },
  { label: "VALDOULE (05150)", value: "05150" },
  { label: "VALDOULE (05150)", value: "05150" },
  { label: "BUISSARD (05500)", value: "05500" },
  { label: "CEILLAC (05600)", value: "05600" },
  { label: "CERVIERES (05100)", value: "05100" },
  { label: "CHABESTAN (05400)", value: "05400" },
  { label: "CHABOTTES (05260)", value: "05260" },
  { label: "CHAMPCELLA (05310)", value: "05310" },
  { label: "CHAMPOLEON (05260)", value: "05260" },
  { label: "CHANOUSSE (05700)", value: "05700" },
  { label: "CHATEAUNEUF D OZE (05400)", value: "05400" },
  { label: "CHATEAUROUX LES ALPES (05380)", value: "05380" },
  { label: "CHATEAUVIEUX (05000)", value: "05000" },
  { label: "CHATEAU VILLE VIEILLE (05350)", value: "05350" },
  { label: "CHATEAU VILLE VIEILLE (05350)", value: "05350" },
  { label: "AUBESSAGNE (05500)", value: "05500" },
  { label: "AUBESSAGNE (05500)", value: "05500" },
  { label: "AUBESSAGNE (05800)", value: "05800" },
  { label: "CHORGES (05230)", value: "05230" },
  { label: "CREVOUX (05200)", value: "05200" },
  { label: "CROTS (05200)", value: "05200" },
  { label: "EMBRUN (05200)", value: "05200" },
  { label: "EOURRES (05300)", value: "05300" },
  { label: "L'EPINE (05700)", value: "05700" },
  { label: "ESPARRON (05110)", value: "05110" },
  { label: "ESPINASSES (05190)", value: "05190" },
  { label: "ETOILE ST CYRICE (05700)", value: "05700" },
  { label: "EYGLIERS (05600)", value: "05600" },
  { label: "GARDE COLOMBE (05300)", value: "05300" },
  { label: "GARDE COLOMBE (05300)", value: "05300" },
  { label: "GARDE COLOMBE (05300)", value: "05300" },
  { label: "LA FARE EN CHAMPSAUR (05500)", value: "05500" },
  { label: "LA FAURIE (05140)", value: "05140" },
  { label: "FOREST ST JULIEN (05260)", value: "05260" },
  { label: "FOUILLOUSE (05130)", value: "05130" },
  { label: "FREISSINIERES (05310)", value: "05310" },
  { label: "LA FREISSINOUSE (05000)", value: "05000" },
  { label: "FURMEYER (05400)", value: "05400" },
  { label: "GAP (05000)", value: "05000" },
  { label: "GAP (05000)", value: "05000" },
  { label: "LE GLAIZIL (05800)", value: "05800" },
  { label: "LA GRAVE (05320)", value: "05320" },
  { label: "LA CHAPELLE EN VALGAUDEMAR (05800)", value: "05800" },
  { label: "GUILLESTRE (05600)", value: "05600" },
  { label: "LA HAUTE BEAUME (05140)", value: "05140" },
  { label: "JARJAYES (05130)", value: "05130" },
  { label: "LARAGNE MONTEGLIN (05300)", value: "05300" },
  { label: "LARDIER ET VALENCA (05110)", value: "05110" },
  { label: "LAYE (05500)", value: "05500" },
  { label: "LAZER (05300)", value: "05300" },
  { label: "LETTRET (05130)", value: "05130" },
  { label: "MANTEYER (05400)", value: "05400" },
  { label: "MEREUIL (05700)", value: "05700" },
  { label: "MOLINES EN QUEYRAS (05350)", value: "05350" },
  { label: "MONETIER ALLEMONT (05110)", value: "05110" },
  { label: "LE MONETIER LES BAINS (05220)", value: "05220" },
  { label: "MONTBRAND (05140)", value: "05140" },
  { label: "MONTCLUS (05700)", value: "05700" },
  { label: "MONT DAUPHIN (05600)", value: "05600" },
  { label: "MONTGARDIN (05230)", value: "05230" },
  { label: "MONTGENEVRE (05100)", value: "05100" },
  { label: "MONTJAY (05150)", value: "05150" },
  { label: "MONTMAUR (05400)", value: "05400" },
  { label: "MONTROND (05700)", value: "05700" },
  { label: "LA MOTTE EN CHAMPSAUR (05500)", value: "05500" },
  { label: "MOYDANS (05150)", value: "05150" },
  { label: "NEFFES (05000)", value: "05000" },
  { label: "NEVACHE (05100)", value: "05100" },
  { label: "NOSSAGE ET BENEVENT (05700)", value: "05700" },
  { label: "LE NOYER (05500)", value: "05500" },
  { label: "ORCIERES (05170)", value: "05170" },
  { label: "ORCIERES (05170)", value: "05170" },
  { label: "ORPIERRE (05700)", value: "05700" },
  { label: "LES ORRES (05200)", value: "05200" },
  { label: "OZE (05400)", value: "05400" },
  { label: "PELLEAUTIER (05000)", value: "05000" },
  { label: "VALLOUISE PELVOUX (05290)", value: "05290" },
  { label: "VALLOUISE PELVOUX (05340)", value: "05340" },
  { label: "VALLOUISE PELVOUX (05340)", value: "05340" },
  { label: "LA PIARRE (05700)", value: "05700" },
  { label: "LE POET (05300)", value: "05300" },
  { label: "POLIGNY (05500)", value: "05500" },
  { label: "PRUNIERES (05230)", value: "05230" },
  { label: "PUY ST ANDRE (05100)", value: "05100" },
  { label: "PUY ST EUSEBE (05200)", value: "05200" },
  { label: "PUY ST PIERRE (05100)", value: "05100" },
  { label: "PUY ST VINCENT (05290)", value: "05290" },
  { label: "PUY SANIERES (05200)", value: "05200" },
  { label: "RABOU (05400)", value: "05400" },
  { label: "RAMBAUD (05000)", value: "05000" },
  { label: "REALLON (05160)", value: "05160" },
  { label: "REMOLLON (05190)", value: "05190" },
  { label: "REOTIER (05600)", value: "05600" },
  { label: "RIBEYRET (05150)", value: "05150" },
  { label: "VAL BUECH MEOUGE (05300)", value: "05300" },
  { label: "VAL BUECH MEOUGE (05300)", value: "05300" },
  { label: "VAL BUECH MEOUGE (05300)", value: "05300" },
  { label: "RISOUL (05600)", value: "05600" },
  { label: "ROCHEBRUNE (05190)", value: "05190" },
  { label: "LA ROCHE DE RAME (05310)", value: "05310" },
  { label: "LA ROCHE DES ARNAUDS (05400)", value: "05400" },
  { label: "LA ROCHETTE (05000)", value: "05000" },
  { label: "ROSANS (05150)", value: "05150" },
  { label: "ROUSSET (05190)", value: "05190" },
  { label: "ST ANDRE D EMBRUN (05200)", value: "05200" },
  { label: "ST ANDRE DE ROSANS (05150)", value: "05150" },
  { label: "ST APOLLINAIRE (05160)", value: "05160" },
  { label: "ST AUBAN D OZE (05400)", value: "05400" },
  { label: "ST BONNET EN CHAMPSAUR (05500)", value: "05500" },
  { label: "ST BONNET EN CHAMPSAUR (05500)", value: "05500" },
  { label: "ST BONNET EN CHAMPSAUR (05500)", value: "05500" },
  { label: "ST CHAFFREY (05330)", value: "05330" },
  { label: "ST CLEMENT SUR DURANCE (05600)", value: "05600" },
  { label: "STE COLOMBE (05700)", value: "05700" },
  { label: "ST CREPIN (05600)", value: "05600" },
  { label: "LE DEVOLUY (05250)", value: "05250" },
  { label: "LE DEVOLUY (05250)", value: "05250" },
  { label: "LE DEVOLUY (05250)", value: "05250" },
  { label: "LE DEVOLUY (05250)", value: "05250" },
  { label: "LE DEVOLUY (05250)", value: "05250" },
  { label: "ST ETIENNE LE LAUS (05130)", value: "05130" },
  { label: "ST FIRMIN (05800)", value: "05800" },
  { label: "ST JACQUES EN VALGODEMARD (05800)", value: "05800" },
  { label: "ST JEAN ST NICOLAS (05260)", value: "05260" },
  { label: "ST JEAN ST NICOLAS (05260)", value: "05260" },
  { label: "ST JULIEN EN BEAUCHENE (05140)", value: "05140" },
  { label: "ST JULIEN EN CHAMPSAUR (05500)", value: "05500" },
  { label: "ST LAURENT DU CROS (05500)", value: "05500" },
  { label: "ST LEGER LES MELEZES (05260)", value: "05260" },
  { label: "ST MARTIN DE QUEYRIERES (05120)", value: "05120" },
  { label: "ST MAURICE EN VALGODEMARD (05800)", value: "05800" },
  { label: "ST MICHEL DE CHAILLOL (05260)", value: "05260" },
  { label: "ST PIERRE D ARGENCON (05140)", value: "05140" },
  { label: "ST PIERRE AVEZ (05300)", value: "05300" },
  { label: "ST SAUVEUR (05200)", value: "05200" },
  { label: "ST VERAN (05350)", value: "05350" },
  { label: "LE SAIX (05400)", value: "05400" },
  { label: "SALEON (05300)", value: "05300" },
  { label: "SALERANS (05300)", value: "05300" },
  { label: "LA SALLE LES ALPES (05240)", value: "05240" },
  { label: "LA SAULCE (05110)", value: "05110" },
  { label: "LE SAUZE DU LAC (05160)", value: "05160" },
  { label: "SAVINES LE LAC (05160)", value: "05160" },
  { label: "SAVOURNON (05700)", value: "05700" },
  { label: "SERRES (05700)", value: "05700" },
  { label: "SIGOTTIER (05700)", value: "05700" },
  { label: "SIGOYER (05130)", value: "05130" },
  { label: "SORBIERS (05150)", value: "05150" },
  { label: "TALLARD (05130)", value: "05130" },
  { label: "THEUS (05190)", value: "05190" },
  { label: "TRESCLEOUX (05700)", value: "05700" },
  { label: "UPAIX (05300)", value: "05300" },
  { label: "VAL DES PRES (05100)", value: "05100" },
  { label: "VALSERRES (05130)", value: "05130" },
  { label: "VARS (05560)", value: "05560" },
  { label: "VENTAVON (05300)", value: "05300" },
  { label: "VEYNES (05400)", value: "05400" },
  { label: "LES VIGNEAUX (05120)", value: "05120" },
  { label: "VILLAR D ARENE (05480)", value: "05480" },
  { label: "VILLAR LOUBIERE (05800)", value: "05800" },
  { label: "VILLAR ST PANCRACE (05100)", value: "05100" },
  { label: "VITROLLES (05110)", value: "05110" },
  { label: "AIGLUN (06910)", value: "06910" },
  { label: "AMIRAT (06910)", value: "06910" },
  { label: "ANDON (06750)", value: "06750" },
  { label: "ANDON (06750)", value: "06750" },
  { label: "ANTIBES (06160)", value: "06160" },
  { label: "ANTIBES (06160)", value: "06160" },
  { label: "ANTIBES (06600)", value: "06600" },
  { label: "ANTIBES (06600)", value: "06600" },
  { label: "ASCROS (06260)", value: "06260" },
  { label: "ASPREMONT (06790)", value: "06790" },
  { label: "AURIBEAU SUR SIAGNE (06810)", value: "06810" },
  { label: "AUVARE (06260)", value: "06260" },
  { label: "BAIROLS (06420)", value: "06420" },
  { label: "LE BAR SUR LOUP (06620)", value: "06620" },
  { label: "BEAULIEU SUR MER (06310)", value: "06310" },
  { label: "BEAUSOLEIL (06240)", value: "06240" },
  { label: "BELVEDERE (06450)", value: "06450" },
  { label: "BENDEJUN (06390)", value: "06390" },
  { label: "BERRE LES ALPES (06390)", value: "06390" },
  { label: "BEUIL (06470)", value: "06470" },
  { label: "BEZAUDUN LES ALPES (06510)", value: "06510" },
  { label: "BIOT (06410)", value: "06410" },
  { label: "BLAUSASC (06440)", value: "06440" },
  { label: "LA BOLLENE VESUBIE (06450)", value: "06450" },
  { label: "BONSON (06830)", value: "06830" },
  { label: "BOUYON (06510)", value: "06510" },
  { label: "BREIL SUR ROYA (06540)", value: "06540" },
  { label: "BRIANCONNET (06850)", value: "06850" },
  { label: "LE BROC (06510)", value: "06510" },
  { label: "CABRIS (06530)", value: "06530" },
  { label: "CAGNES SUR MER (06800)", value: "06800" },
  { label: "CAGNES SUR MER (06800)", value: "06800" },
  { label: "CAILLE (06750)", value: "06750" },
  { label: "CANNES (06150)", value: "06150" },
  { label: "CANNES (06400)", value: "06400" },
  { label: "LE CANNET (06110)", value: "06110" },
  { label: "LE CANNET (06110)", value: "06110" },
  { label: "CANTARON (06340)", value: "06340" },
  { label: "CAP D AIL (06320)", value: "06320" },
  { label: "CARROS (06510)", value: "06510" },
  { label: "CASTAGNIERS (06670)", value: "06670" },
  { label: "CASTELLAR (06500)", value: "06500" },
  { label: "CASTILLON (06500)", value: "06500" },
  { label: "CAUSSOLS (06460)", value: "06460" },
  { label: "CHATEAUNEUF GRASSE (06740)", value: "06740" },
  { label: "CHATEAUNEUF VILLEVIEILLE (06390)", value: "06390" },
  { label: "CHATEAUNEUF D ENTRAUNES (06470)", value: "06470" },
  { label: "CIPIERES (06620)", value: "06620" },
  { label: "CLANS (06420)", value: "06420" },
  { label: "COARAZE (06390)", value: "06390" },
  { label: "LA COLLE SUR LOUP (06480)", value: "06480" },
  { label: "COLLONGUES (06910)", value: "06910" },
  { label: "COLOMARS (06670)", value: "06670" },
  { label: "CONSEGUDES (06510)", value: "06510" },
  { label: "CONTES (06390)", value: "06390" },
  { label: "COURMES (06620)", value: "06620" },
  { label: "COURSEGOULES (06140)", value: "06140" },
  { label: "LA CROIX SUR ROUDOULE (06260)", value: "06260" },
  { label: "CUEBRIS (06910)", value: "06910" },
  { label: "DALUIS (06470)", value: "06470" },
  { label: "DRAP (06340)", value: "06340" },
  { label: "DURANUS (06670)", value: "06670" },
  { label: "ENTRAUNES (06470)", value: "06470" },
  { label: "ENTRAUNES (06470)", value: "06470" },
  { label: "L'ESCARENE (06440)", value: "06440" },
  { label: "ESCRAGNOLLES (06460)", value: "06460" },
  { label: "EZE (06360)", value: "06360" },
  { label: "FALICON (06950)", value: "06950" },
  { label: "LES FERRES (06510)", value: "06510" },
  { label: "FONTAN (06540)", value: "06540" },
  { label: "GARS (06850)", value: "06850" },
  { label: "GATTIERES (06510)", value: "06510" },
  { label: "LA GAUDE (06610)", value: "06610" },
  { label: "GILETTE (06830)", value: "06830" },
  { label: "GORBIO (06500)", value: "06500" },
  { label: "GOURDON (06620)", value: "06620" },
  { label: "GRASSE (06130)", value: "06130" },
  { label: "GRASSE (06130)", value: "06130" },
  { label: "GRASSE (06130)", value: "06130" },
  { label: "GRASSE (06520)", value: "06520" },
  { label: "GREOLIERES (06620)", value: "06620" },
  { label: "GUILLAUMES (06470)", value: "06470" },
  { label: "ILONSE (06420)", value: "06420" },
  { label: "ISOLA (06420)", value: "06420" },
  { label: "LANTOSQUE (06450)", value: "06450" },
  { label: "LEVENS (06670)", value: "06670" },
  { label: "LEVENS (06670)", value: "06670" },
  { label: "LIEUCHE (06260)", value: "06260" },
  { label: "LUCERAM (06440)", value: "06440" },
  { label: "LUCERAM (06440)", value: "06440" },
  { label: "MALAUSSENE (06710)", value: "06710" },
  { label: "MANDELIEU LA NAPOULE (06210)", value: "06210" },
  { label: "MANDELIEU LA NAPOULE (06210)", value: "06210" },
  { label: "MARIE (06420)", value: "06420" },
  { label: "LE MAS (06910)", value: "06910" },
  { label: "MASSOINS (06710)", value: "06710" },
  { label: "MENTON (06500)", value: "06500" },
  { label: "MOUANS SARTOUX (06370)", value: "06370" },
  { label: "MOUGINS (06250)", value: "06250" },
  { label: "MOULINET (06380)", value: "06380" },
  { label: "LES MUJOULS (06910)", value: "06910" },
  { label: "NICE (06000)", value: "06000" },
  { label: "NICE (06100)", value: "06100" },
  { label: "NICE (06200)", value: "06200" },
  { label: "NICE (06200)", value: "06200" },
  { label: "NICE (06200)", value: "06200" },
  { label: "NICE (06300)", value: "06300" },
  { label: "OPIO (06650)", value: "06650" },
  { label: "PEGOMAS (06580)", value: "06580" },
  { label: "PEILLE (06440)", value: "06440" },
  { label: "PEILLE (06440)", value: "06440" },
  { label: "PEILLON (06440)", value: "06440" },
  { label: "LA PENNE (06260)", value: "06260" },
  { label: "PEONE (06470)", value: "06470" },
  { label: "PEONE (06470)", value: "06470" },
  { label: "PEYMEINADE (06530)", value: "06530" },
  { label: "PIERLAS (06260)", value: "06260" },
  { label: "PIERREFEU (06910)", value: "06910" },
  { label: "PUGET ROSTANG (06260)", value: "06260" },
  { label: "PUGET THENIERS (06260)", value: "06260" },
  { label: "REVEST LES ROCHES (06830)", value: "06830" },
  { label: "RIGAUD (06260)", value: "06260" },
  { label: "RIMPLAS (06420)", value: "06420" },
  { label: "ROQUEBILLIERE (06450)", value: "06450" },
  { label: "ROQUEBRUNE CAP MARTIN (06190)", value: "06190" },
  { label: "ROQUEBRUNE CAP MARTIN (06190)", value: "06190" },
  { label: "ROQUEFORT LES PINS (06330)", value: "06330" },
  { label: "ROQUESTERON (06910)", value: "06910" },
  { label: "LA ROQUE EN PROVENCE (06910)", value: "06910" },
  { label: "LA ROQUETTE SUR SIAGNE (06550)", value: "06550" },
  { label: "LA ROQUETTE SUR VAR (06670)", value: "06670" },
  { label: "ROUBION (06420)", value: "06420" },
  { label: "ROURE (06420)", value: "06420" },
  { label: "LE ROURET (06650)", value: "06650" },
  { label: "STE AGNES (06500)", value: "06500" },
  { label: "ST ANDRE DE LA ROCHE (06730)", value: "06730" },
  { label: "ST ANTONIN (06260)", value: "06260" },
  { label: "ST AUBAN (06850)", value: "06850" },
  { label: "ST BLAISE (06670)", value: "06670" },
  { label: "ST CEZAIRE SUR SIAGNE (06530)", value: "06530" },
  { label: "ST DALMAS LE SELVAGE (06660)", value: "06660" },
  { label: "ST ETIENNE DE TINEE (06660)", value: "06660" },
  { label: "ST ETIENNE DE TINEE (06660)", value: "06660" },
  { label: "ST JEAN CAP FERRAT (06230)", value: "06230" },
  { label: "ST JEANNET (06640)", value: "06640" },
  { label: "ST LAURENT DU VAR (06700)", value: "06700" },
  { label: "ST LEGER (06260)", value: "06260" },
  { label: "ST MARTIN D ENTRAUNES (06470)", value: "06470" },
  { label: "ST MARTIN DU VAR (06670)", value: "06670" },
  { label: "ST MARTIN VESUBIE (06450)", value: "06450" },
  { label: "ST MARTIN VESUBIE (06450)", value: "06450" },
  { label: "ST PAUL DE VENCE (06570)", value: "06570" },
  { label: "ST SAUVEUR SUR TINEE (06420)", value: "06420" },
  { label: "ST VALLIER DE THIEY (06460)", value: "06460" },
  { label: "SALLAGRIFFON (06910)", value: "06910" },
  { label: "SAORGE (06540)", value: "06540" },
  { label: "SAUZE (06470)", value: "06470" },
  { label: "SERANON (06750)", value: "06750" },
  { label: "SERANON (06750)", value: "06750" },
  { label: "SIGALE (06910)", value: "06910" },
  { label: "SOSPEL (06380)", value: "06380" },
  { label: "SPERACEDES (06530)", value: "06530" },
  { label: "THEOULE SUR MER (06590)", value: "06590" },
  { label: "THEOULE SUR MER (06590)", value: "06590" },
  { label: "THIERY (06710)", value: "06710" },
  { label: "LE TIGNET (06530)", value: "06530" },
  { label: "TOUDON (06830)", value: "06830" },
  { label: "TOUET DE L ESCARENE (06440)", value: "06440" },
  { label: "TOUET SUR VAR (06710)", value: "06710" },
  { label: "LA TOUR (06420)", value: "06420" },
  { label: "TOURETTE DU CHATEAU (06830)", value: "06830" },
  { label: "TOURNEFORT (06420)", value: "06420" },
  { label: "TOURRETTE LEVENS (06690)", value: "06690" },
  { label: "TOURRETTES SUR LOUP (06140)", value: "06140" },
  { label: "LA TRINITE (06340)", value: "06340" },
  { label: "LA TURBIE (06320)", value: "06320" },
  { label: "UTELLE (06450)", value: "06450" },
  { label: "UTELLE (06450)", value: "06450" },
  { label: "VALBONNE (06560)", value: "06560" },
  { label: "VALBONNE (06560)", value: "06560" },
  { label: "VALDEBLORE (06420)", value: "06420" },
  { label: "VALDEROURE (06750)", value: "06750" },
  { label: "VALLAURIS (06220)", value: "06220" },
  { label: "VALLAURIS (06220)", value: "06220" },
  { label: "VENANSON (06450)", value: "06450" },
  { label: "VENCE (06140)", value: "06140" },
  { label: "VILLARS SUR VAR (06710)", value: "06710" },
  { label: "VILLEFRANCHE SUR MER (06230)", value: "06230" },
  { label: "VILLENEUVE D ENTRAUNES (06470)", value: "06470" },
  { label: "VILLENEUVE LOUBET (06270)", value: "06270" },
  { label: "LA BRIGUE (06430)", value: "06430" },
  { label: "TENDE (06430)", value: "06430" },
  { label: "TENDE (06430)", value: "06430" },
  { label: "ACCONS (07160)", value: "07160" },
  { label: "AILHON (07200)", value: "07200" },
  { label: "AIZAC (07530)", value: "07530" },
  { label: "AJOUX (07000)", value: "07000" },
  { label: "ALBA LA ROMAINE (07400)", value: "07400" },
  { label: "ALBON D ARDECHE (07190)", value: "07190" },
  { label: "ALBOUSSIERE (07440)", value: "07440" },
  { label: "ALISSAS (07210)", value: "07210" },
  { label: "ANDANCE (07340)", value: "07340" },
  { label: "ANNONAY (07100)", value: "07100" },
  { label: "VALLEES D ANTRAIGUES ASPERJOC (07530)", value: "07530" },
  { label: "VALLEES D ANTRAIGUES ASPERJOC (07600)", value: "07600" },
  { label: "ARCENS (07310)", value: "07310" },
  { label: "ARDOIX (07290)", value: "07290" },
  { label: "ARLEBOSC (07410)", value: "07410" },
  { label: "ARRAS SUR RHONE (07370)", value: "07370" },
  { label: "LES ASSIONS (07140)", value: "07140" },
  { label: "ASTET (07330)", value: "07330" },
  { label: "AUBENAS (07200)", value: "07200" },
  { label: "AUBIGNAS (07400)", value: "07400" },
  { label: "BAIX (07210)", value: "07210" },
  { label: "BALAZUC (07120)", value: "07120" },
  { label: "BANNE (07460)", value: "07460" },
  { label: "BARNAS (07330)", value: "07330" },
  { label: "LE BEAGE (07630)", value: "07630" },
  { label: "BEAUCHASTEL (07800)", value: "07800" },
  { label: "BEAULIEU (07460)", value: "07460" },
  { label: "BEAUMONT (07110)", value: "07110" },
  { label: "BEAUVENE (07190)", value: "07190" },
  { label: "BERRIAS ET CASTELJAU (07460)", value: "07460" },
  { label: "BERRIAS ET CASTELJAU (07460)", value: "07460" },
  { label: "BERZEME (07580)", value: "07580" },
  { label: "BESSAS (07150)", value: "07150" },
  { label: "BIDON (07700)", value: "07700" },
  { label: "BOFFRES (07440)", value: "07440" },
  { label: "BOGY (07340)", value: "07340" },
  { label: "BOREE (07310)", value: "07310" },
  { label: "BORNE (07590)", value: "07590" },
  { label: "BOZAS (07410)", value: "07410" },
  { label: "BOUCIEU LE ROI (07270)", value: "07270" },
  { label: "BOULIEU LES ANNONAY (07100)", value: "07100" },
  { label: "BOURG ST ANDEOL (07700)", value: "07700" },
  { label: "BROSSAINC (07340)", value: "07340" },
  { label: "BURZET (07450)", value: "07450" },
  { label: "CELLIER DU LUC (07590)", value: "07590" },
  { label: "CHALENCON (07240)", value: "07240" },
  { label: "LE CHAMBON (07160)", value: "07160" },
  { label: "CHAMBONAS (07140)", value: "07140" },
  { label: "CHAMPAGNE (07340)", value: "07340" },
  { label: "CHAMPIS (07440)", value: "07440" },
  { label: "CHANDOLAS (07230)", value: "07230" },
  { label: "CHANEAC (07310)", value: "07310" },
  { label: "CHARMES SUR RHONE (07800)", value: "07800" },
  { label: "CHARNAS (07340)", value: "07340" },
  { label: "CHASSIERS (07110)", value: "07110" },
  { label: "CHATEAUBOURG (07130)", value: "07130" },
  { label: "CHATEAUNEUF DE VERNOUX (07240)", value: "07240" },
  { label: "CHAUZON (07120)", value: "07120" },
  { label: "CHAZEAUX (07110)", value: "07110" },
  { label: "CHEMINAS (07300)", value: "07300" },
  { label: "LE CHEYLARD (07160)", value: "07160" },
  { label: "CHIROLS (07380)", value: "07380" },
  { label: "CHOMERAC (07210)", value: "07210" },
  { label: "COLOMBIER LE CARDINAL (07430)", value: "07430" },
  { label: "COLOMBIER LE JEUNE (07270)", value: "07270" },
  { label: "COLOMBIER LE VIEUX (07410)", value: "07410" },
  { label: "CORNAS (07130)", value: "07130" },
  { label: "COUCOURON (07470)", value: "07470" },
  { label: "COUX (07000)", value: "07000" },
  { label: "LE CRESTET (07270)", value: "07270" },
  { label: "CREYSSEILLES (07000)", value: "07000" },
  { label: "CROS DE GEORAND (07510)", value: "07510" },
  { label: "CROS DE GEORAND (07630)", value: "07630" },
  { label: "CRUAS (07350)", value: "07350" },
  { label: "DARBRES (07170)", value: "07170" },
  { label: "DAVEZIEUX (07430)", value: "07430" },
  { label: "DESAIGNES (07570)", value: "07570" },
  { label: "DEVESSET (07320)", value: "07320" },
  { label: "DOMPNAC (07260)", value: "07260" },
  { label: "DORNAS (07160)", value: "07160" },
  { label: "DUNIERE SUR EYRIEUX (07360)", value: "07360" },
  { label: "ECLASSAN (07370)", value: "07370" },
  { label: "EMPURANY (07270)", value: "07270" },
  { label: "ETABLES (07300)", value: "07300" },
  { label: "FABRAS (07380)", value: "07380" },
  { label: "FAUGERES (07230)", value: "07230" },
  { label: "FELINES (07340)", value: "07340" },
  { label: "FLAVIAC (07000)", value: "07000" },
  { label: "FONS (07200)", value: "07200" },
  { label: "FREYSSENET (07000)", value: "07000" },
  { label: "GENESTELLE (07530)", value: "07530" },
  { label: "GILHAC ET BRUZAC (07800)", value: "07800" },
  { label: "GILHOC SUR ORMEZE (07270)", value: "07270" },
  { label: "GLUIRAS (07190)", value: "07190" },
  { label: "GLUN (07300)", value: "07300" },
  { label: "GOURDON (07000)", value: "07000" },
  { label: "GRAS (07700)", value: "07700" },
  { label: "GRAVIERES (07140)", value: "07140" },
  { label: "GROSPIERRES (07120)", value: "07120" },
  { label: "GUILHERAND GRANGES (07500)", value: "07500" },
  { label: "ST JULIEN D INTRES (07310)", value: "07310" },
  { label: "ST JULIEN D INTRES (07310)", value: "07310" },
  { label: "ST JULIEN D INTRES (07320)", value: "07320" },
  { label: "ISSAMOULENC (07190)", value: "07190" },
  { label: "ISSANLAS (07510)", value: "07510" },
  { label: "ISSANLAS (07660)", value: "07660" },
  { label: "ISSARLES (07470)", value: "07470" },
  { label: "JAUJAC (07380)", value: "07380" },
  { label: "JAUNAC (07160)", value: "07160" },
  { label: "JOANNAS (07110)", value: "07110" },
  { label: "JOYEUSE (07260)", value: "07260" },
  { label: "JUVINAS (07600)", value: "07600" },
  { label: "LABASTIDE SUR BESORGUES (07600)", value: "07600" },
  { label: "LABASTIDE DE VIRAC (07150)", value: "07150" },
  { label: "LABATIE D ANDAURE (07570)", value: "07570" },
  { label: "LABEAUME (07120)", value: "07120" },
  { label: "LABEGUDE (07200)", value: "07200" },
  { label: "LABLACHERE (07230)", value: "07230" },
  { label: "LABOULE (07110)", value: "07110" },
  { label: "LE LAC D ISSARLES (07470)", value: "07470" },
  { label: "LACHAMP RAPHAEL (07530)", value: "07530" },
  { label: "LACHAPELLE GRAILLOUSE (07470)", value: "07470" },
  { label: "LACHAPELLE SOUS AUBENAS (07200)", value: "07200" },
  { label: "LACHAPELLE SOUS CHANEAC (07310)", value: "07310" },
  { label: "LAFARRE (07520)", value: "07520" },
  { label: "LAGORCE (07150)", value: "07150" },
  { label: "LALEVADE D ARDECHE (07380)", value: "07380" },
  { label: "LALOUVESC (07520)", value: "07520" },
  { label: "LAMASTRE (07270)", value: "07270" },
  { label: "LANARCE (07660)", value: "07660" },
  { label: "LANAS (07200)", value: "07200" },
  { label: "LARGENTIERE (07110)", value: "07110" },
  { label: "LARNAS (07220)", value: "07220" },
  { label: "LAURAC EN VIVARAIS (07110)", value: "07110" },
  { label: "LAVEYRUNE (48250)", value: "48250" },
  { label: "LAVILLATTE (07660)", value: "07660" },
  { label: "LAVILLEDIEU (07170)", value: "07170" },
  { label: "LAVIOLLE (07530)", value: "07530" },
  { label: "LEMPS (07610)", value: "07610" },
  { label: "LENTILLERES (07200)", value: "07200" },
  { label: "LESPERON (07660)", value: "07660" },
  { label: "LIMONY (07340)", value: "07340" },
  { label: "LOUBARESSE (07110)", value: "07110" },
  { label: "LUSSAS (07170)", value: "07170" },
  { label: "LYAS (07000)", value: "07000" },
  { label: "MALARCE SUR LA THINES (07140)", value: "07140" },
  { label: "MALARCE SUR LA THINES (07140)", value: "07140" },
  { label: "MALARCE SUR LA THINES (07140)", value: "07140" },
  { label: "MALBOSC (07140)", value: "07140" },
  { label: "MARCOLS LES EAUX (07190)", value: "07190" },
  { label: "MARIAC (07160)", value: "07160" },
  { label: "MARS (07320)", value: "07320" },
  { label: "MAUVES (07300)", value: "07300" },
  { label: "MAYRES (07330)", value: "07330" },
  { label: "MAZAN L ABBAYE (07510)", value: "07510" },
  { label: "MERCUER (07200)", value: "07200" },
  { label: "MEYRAS (07380)", value: "07380" },
  { label: "MEYSSE (07400)", value: "07400" },
  { label: "MEZILHAC (07530)", value: "07530" },
  { label: "MIRABEL (07170)", value: "07170" },
  { label: "MONESTIER (07690)", value: "07690" },
  { label: "MONTPEZAT SOUS BAUZON (07560)", value: "07560" },
  { label: "MONTREAL (07110)", value: "07110" },
  { label: "MONTSELGUES (07140)", value: "07140" },
  { label: "BELSENTES (07160)", value: "07160" },
  { label: "BELSENTES (07160)", value: "07160" },
  { label: "NOZIERES (07270)", value: "07270" },
  { label: "LES OLLIERES SUR EYRIEUX (07360)", value: "07360" },
  { label: "ORGNAC L AVEN (07150)", value: "07150" },
  { label: "OZON (07370)", value: "07370" },
  { label: "PAILHARES (07410)", value: "07410" },
  { label: "PAYZAC (07230)", value: "07230" },
  { label: "PEAUGRES (07340)", value: "07340" },
  { label: "PEREYRES (07450)", value: "07450" },
  { label: "PEYRAUD (07340)", value: "07340" },
  { label: "LE PLAGNAL (07590)", value: "07590" },
  { label: "PLANZOLLES (07230)", value: "07230" },
  { label: "PLATS (07300)", value: "07300" },
  { label: "PONT DE LABEAUME (07380)", value: "07380" },
  { label: "POURCHERES (07000)", value: "07000" },
  { label: "LE POUZIN (07250)", value: "07250" },
  { label: "PRADES (07380)", value: "07380" },
  { label: "PRADONS (07120)", value: "07120" },
  { label: "PRANLES (07000)", value: "07000" },
  { label: "PREAUX (07290)", value: "07290" },
  { label: "PRIVAS (07000)", value: "07000" },
  { label: "PRUNET (07110)", value: "07110" },
  { label: "QUINTENAS (07290)", value: "07290" },
  { label: "RIBES (07260)", value: "07260" },
  { label: "ROCHECOLOMBE (07200)", value: "07200" },
  { label: "ROCHEMAURE (07400)", value: "07400" },
  { label: "ROCHEPAULE (07320)", value: "07320" },
  { label: "ROCHER (07110)", value: "07110" },
  { label: "ROCHESSAUVE (07210)", value: "07210" },
  { label: "LA ROCHETTE (07310)", value: "07310" },
  { label: "ROCLES (07110)", value: "07110" },
  { label: "ROIFFIEUX (07100)", value: "07100" },
  { label: "ROMPON (07250)", value: "07250" },
  { label: "ROMPON (07800)", value: "07800" },
  { label: "ROSIERES (07260)", value: "07260" },
  { label: "LE ROUX (07560)", value: "07560" },
  { label: "RUOMS (07120)", value: "07120" },
  { label: "SABLIERES (07260)", value: "07260" },
  { label: "SAGNES ET GOUDOULET (07450)", value: "07450" },
  { label: "ST AGREVE (07320)", value: "07320" },
  { label: "ST AGREVE (07320)", value: "07320" },
  { label: "ST ALBAN D AY (07790)", value: "07790" },
  { label: "ST ALBAN EN MONTAGNE (07590)", value: "07590" },
  { label: "ST ALBAN AURIOLLES (07120)", value: "07120" },
  { label: "ST ALBAN AURIOLLES (07120)", value: "07120" },
  { label: "ST ANDEOL DE BERG (07170)", value: "07170" },
  { label: "ST ANDEOL DE FOURCHADES (07160)", value: "07160" },
  { label: "ST ANDEOL DE VALS (07600)", value: "07600" },
  { label: "ST ANDRE DE CRUZIERES (07460)", value: "07460" },
  { label: "ST ANDRE EN VIVARAIS (07690)", value: "07690" },
  { label: "ST ANDRE LACHAMP (07230)", value: "07230" },
  { label: "ST APOLLINAIRE DE RIAS (07240)", value: "07240" },
  { label: "ST BARTHELEMY LE MEIL (07160)", value: "07160" },
  { label: "ST BARTHELEMY GROZON (07270)", value: "07270" },
  { label: "ST BARTHELEMY LE PLAIN (07300)", value: "07300" },
  { label: "ST BASILE (07270)", value: "07270" },
  { label: "ST BAUZILE (07210)", value: "07210" },
  { label: "ST CHRISTOL (07160)", value: "07160" },
  { label: "ST CIERGE LA SERRE (07800)", value: "07800" },
  { label: "ST CIERGE SOUS LE CHEYLARD (07160)", value: "07160" },
  { label: "ST CIRGUES DE PRADES (07380)", value: "07380" },
  { label: "ST CIRGUES EN MONTAGNE (07510)", value: "07510" },
  { label: "ST CLAIR (07430)", value: "07430" },
  { label: "ST CLEMENT (07310)", value: "07310" },
  { label: "ST CYR (07430)", value: "07430" },
  { label: "ST DESIRAT (07340)", value: "07340" },
  { label: "ST DIDIER SOUS AUBENAS (07200)", value: "07200" },
  { label: "ST ETIENNE DE BOULOGNE (07200)", value: "07200" },
  { label: "ST ETIENNE DE FONTBELLON (07200)", value: "07200" },
  { label: "ST ETIENNE DE LUGDARES (07590)", value: "07590" },
  { label: "ST ETIENNE DE SERRE (07190)", value: "07190" },
  { label: "ST ETIENNE DE VALOUX (07340)", value: "07340" },
  { label: "STE EULALIE (07510)", value: "07510" },
  { label: "ST FELICIEN (07410)", value: "07410" },
  { label: "ST FORTUNAT SUR EYRIEUX (07360)", value: "07360" },
  { label: "ST GENEST DE BEAUZON (07230)", value: "07230" },
  { label: "ST GENEST LACHAMP (07160)", value: "07160" },
  { label: "ST GENEST LACHAMP (07190)", value: "07190" },
  { label: "ST GEORGES LES BAINS (07800)", value: "07800" },
  { label: "ST GERMAIN (07170)", value: "07170" },
  { label: "ST GINEYS EN COIRON (07580)", value: "07580" },
  { label: "ST JACQUES D ATTICIEUX (07340)", value: "07340" },
  { label: "ST JEAN CHAMBRE (07240)", value: "07240" },
  { label: "ST JEAN DE MUZOLS (07300)", value: "07300" },
  { label: "ST JEAN LE CENTENIER (07580)", value: "07580" },
  { label: "ST JEAN ROURE (07160)", value: "07160" },
  { label: "ST JEURE D ANDAURE (07320)", value: "07320" },
  { label: "ST JEURE D AY (07290)", value: "07290" },
  { label: "ST JOSEPH DES BANCS (07530)", value: "07530" },
  { label: "ST JULIEN DU GUA (07190)", value: "07190" },
  { label: "ST JULIEN DU SERRE (07200)", value: "07200" },
  { label: "ST JULIEN EN ST ALBAN (07000)", value: "07000" },
  { label: "ST JULIEN LE ROUX (07240)", value: "07240" },
  { label: "ST JULIEN VOCANCE (07690)", value: "07690" },
  { label: "ST JUST D ARDECHE (07700)", value: "07700" },
  { label: "ST LAGER BRESSAC (07210)", value: "07210" },
  { label: "ST LAURENT DU PAPE (07800)", value: "07800" },
  { label: "ST LAURENT BAINS LAVAL D AURELLE (07590)", value: "07590" },
  { label: "ST LAURENT BAINS LAVAL D AURELLE (07590)", value: "07590" },
  { label: "ST LAURENT SOUS COIRON (07170)", value: "07170" },
  { label: "ST MARCEL D ARDECHE (07700)", value: "07700" },
  { label: "ST MARCEL LES ANNONAY (07100)", value: "07100" },
  { label: "STE MARGUERITE LAFIGERE (07140)", value: "07140" },
  { label: "ST MARTIAL (07310)", value: "07310" },
  { label: "ST MARTIN D ARDECHE (07700)", value: "07700" },
  { label: "ST MARTIN DE VALAMAS (07310)", value: "07310" },
  { label: "ST MARTIN SUR LAVEZON (07400)", value: "07400" },
  { label: "ST MARTIN SUR LAVEZON (07400)", value: "07400" },
  { label: "ST MAURICE D ARDECHE (07200)", value: "07200" },
  { label: "ST MAURICE D IBIE (07170)", value: "07170" },
  { label: "ST MAURICE EN CHALENCON (07190)", value: "07190" },
  { label: "ST MELANY (07260)", value: "07260" },
  { label: "ST MICHEL D AURANCE (07160)", value: "07160" },
  { label: "ST MICHEL DE BOULOGNE (07200)", value: "07200" },
  { label: "ST MICHEL DE CHABRILLANOUX (07360)", value: "07360" },
  { label: "ST MONTAN (07220)", value: "07220" },
  { label: "ST PAUL LE JEUNE (07460)", value: "07460" },
  { label: "ST PERAY (07130)", value: "07130" },
  { label: "ST PIERRE DE COLOMBIER (07450)", value: "07450" },
  { label: "ST PIERRE LA ROCHE (07400)", value: "07400" },
  { label: "ST PIERRE ST JEAN (07140)", value: "07140" },
  { label: "ST PIERRE ST JEAN (07140)", value: "07140" },
  { label: "ST PIERRE SUR DOUX (07520)", value: "07520" },
  { label: "ST PIERREVILLE (07190)", value: "07190" },
  { label: "ST PONS (07580)", value: "07580" },
  { label: "ST PRIEST (07000)", value: "07000" },
  { label: "ST PRIVAT (07200)", value: "07200" },
  { label: "ST PRIX (07270)", value: "07270" },
  { label: "ST REMEZE (07700)", value: "07700" },
  { label: "ST ROMAIN D AY (07290)", value: "07290" },
  { label: "ST ROMAIN DE LERPS (07130)", value: "07130" },
  { label: "ST SAUVEUR DE CRUZIERES (07460)", value: "07460" },
  { label: "ST SAUVEUR DE MONTAGUT (07190)", value: "07190" },
  { label: "ST SERNIN (07200)", value: "07200" },
  { label: "ST SYLVESTRE (07440)", value: "07440" },
  { label: "ST SYMPHORIEN SOUS CHOMERAC (07210)", value: "07210" },
  { label: "ST SYMPHORIEN DE MAHUN (07290)", value: "07290" },
  { label: "ST THOME (07220)", value: "07220" },
  { label: "ST VICTOR (07410)", value: "07410" },
  { label: "ST VINCENT DE BARRES (07210)", value: "07210" },
  { label: "ST VINCENT DE DURFORT (07360)", value: "07360" },
  { label: "SALAVAS (07150)", value: "07150" },
  { label: "LES SALELLES (07140)", value: "07140" },
  { label: "SAMPZON (07120)", value: "07120" },
  { label: "SANILHAC (07110)", value: "07110" },
  { label: "SARRAS (07370)", value: "07370" },
  { label: "SATILLIEU (07290)", value: "07290" },
  { label: "SAVAS (07430)", value: "07430" },
  { label: "SCEAUTRES (07400)", value: "07400" },
  { label: "SECHERAS (07610)", value: "07610" },
  { label: "SERRIERES (07340)", value: "07340" },
  { label: "SILHAC (07240)", value: "07240" },
  { label: "LA SOUCHE (07380)", value: "07380" },
  { label: "SOYONS (07130)", value: "07130" },
  { label: "TALENCIEUX (07340)", value: "07340" },
  { label: "TAURIERS (07110)", value: "07110" },
  { label: "LE TEIL (07400)", value: "07400" },
  { label: "THORRENC (07340)", value: "07340" },
  { label: "THUEYTS (07330)", value: "07330" },
  { label: "TOULAUD (07130)", value: "07130" },
  { label: "TOURNON SUR RHONE (07300)", value: "07300" },
  { label: "UCEL (07200)", value: "07200" },
  { label: "USCLADES ET RIEUTORD (07510)", value: "07510" },
  { label: "UZER (07110)", value: "07110" },
  { label: "VAGNAS (07150)", value: "07150" },
  { label: "VALGORGE (07110)", value: "07110" },
  { label: "VALLON PONT D ARC (07150)", value: "07150" },
  { label: "VALS LES BAINS (07600)", value: "07600" },
  { label: "VALVIGNERES (07400)", value: "07400" },
  { label: "VANOSC (07690)", value: "07690" },
  { label: "LES VANS (07140)", value: "07140" },
  { label: "LES VANS (07140)", value: "07140" },
  { label: "LES VANS (07140)", value: "07140" },
  { label: "LES VANS (07140)", value: "07140" },
  { label: "VAUDEVANT (07410)", value: "07410" },
  { label: "VERNON (07260)", value: "07260" },
  { label: "VERNOSC LES ANNONAY (07430)", value: "07430" },
  { label: "VERNOUX EN VIVARAIS (07240)", value: "07240" },
  { label: "VESSEAUX (07200)", value: "07200" },
  { label: "VEYRAS (07000)", value: "07000" },
  { label: "VILLENEUVE DE BERG (07170)", value: "07170" },
  { label: "VILLEVOCANCE (07690)", value: "07690" },
  { label: "VINEZAC (07110)", value: "07110" },
  { label: "VINZIEUX (07340)", value: "07340" },
  { label: "VION (07610)", value: "07610" },
  { label: "VIVIERS (07220)", value: "07220" },
  { label: "VOCANCE (07690)", value: "07690" },
  { label: "VOGUE (07200)", value: "07200" },
  { label: "LA VOULTE SUR RHONE (07800)", value: "07800" },
  { label: "ACY ROMANCE (08300)", value: "08300" },
  { label: "AIGLEMONT (08090)", value: "08090" },
  { label: "AIRE (08190)", value: "08190" },
  { label: "ALINCOURT (08310)", value: "08310" },
  { label: "ALLAND HUY ET SAUSSEUIL (08130)", value: "08130" },
  { label: "AMAGNE (08300)", value: "08300" },
  { label: "AMBLY FLEURY (08130)", value: "08130" },
  { label: "ANCHAMPS (08500)", value: "08500" },
  { label: "ANGECOURT (08450)", value: "08450" },
  { label: "ANNELLES (08310)", value: "08310" },
  { label: "ANTHENY (08260)", value: "08260" },
  { label: "AOUSTE (08290)", value: "08290" },
  { label: "APREMONT (08250)", value: "08250" },
  { label: "ARDEUIL ET MONTFAUXELLES (08400)", value: "08400" },
  { label: "LES GRANDES ARMOISES (08390)", value: "08390" },
  { label: "LES PETITES ARMOISES (08390)", value: "08390" },
  { label: "ARNICOURT (08300)", value: "08300" },
  { label: "ARREUX (08090)", value: "08090" },
  { label: "ARTAISE LE VIVIER (08390)", value: "08390" },
  { label: "ASFELD (08190)", value: "08190" },
  { label: "ATTIGNY (08130)", value: "08130" },
  { label: "AUBIGNY LES POTHEES (08150)", value: "08150" },
  { label: "AUBONCOURT VAUZELLES (08270)", value: "08270" },
  { label: "AUBRIVES (08320)", value: "08320" },
  { label: "AUFLANCE (08370)", value: "08370" },
  { label: "AUGE (08380)", value: "08380" },
  { label: "AURE (08400)", value: "08400" },
  { label: "AUSSONCE (08310)", value: "08310" },
  { label: "AUTHE (08240)", value: "08240" },
  { label: "AUTRECOURT ET POURRON (08210)", value: "08210" },
  { label: "AUTRUCHE (08240)", value: "08240" },
  { label: "AUTRY (08250)", value: "08250" },
  { label: "AUVILLERS LES FORGES (08260)", value: "08260" },
  { label: "AUVILLERS LES FORGES (08260)", value: "08260" },
  { label: "AVANCON (08300)", value: "08300" },
  { label: "AVAUX (08190)", value: "08190" },
  { label: "LES AYVELLES (08000)", value: "08000" },
  { label: "BAALONS (08430)", value: "08430" },
  { label: "BALAN (08200)", value: "08200" },
  { label: "BALHAM (08190)", value: "08190" },
  { label: "BALLAY (08400)", value: "08400" },
  { label: "BANOGNE RECOUVRANCE (08220)", value: "08220" },
  { label: "BARBAISE (08430)", value: "08430" },
  { label: "BARBY (08300)", value: "08300" },
  { label: "BAR LES BUZANCY (08240)", value: "08240" },
  { label: "BAYONVILLE (08240)", value: "08240" },
  { label: "BAZEILLES (08140)", value: "08140" },
  { label: "BAZEILLES (08140)", value: "08140" },
  { label: "BAZEILLES (08140)", value: "08140" },
  { label: "BAZEILLES (08140)", value: "08140" },
  { label: "BEAUMONT EN ARGONNE (08210)", value: "08210" },
  { label: "BEFFU ET LE MORTHOMME (08250)", value: "08250" },
  { label: "BELLEVILLE ET CHATILLON SUR BAR (08240)", value: "08240" },
  { label: "BELLEVILLE ET CHATILLON SUR BAR (08240)", value: "08240" },
  { label: "BELVAL (08090)", value: "08090" },
  { label: "BELVAL BOIS DES DAMES (08240)", value: "08240" },
  { label: "BERGNICOURT (08300)", value: "08300" },
  { label: "LA BERLIERE (08240)", value: "08240" },
  { label: "BERTONCOURT (08300)", value: "08300" },
  { label: "LA BESACE (08450)", value: "08450" },
  { label: "BIERMES (08300)", value: "08300" },
  { label: "BIEVRES (08370)", value: "08370" },
  { label: "BIGNICOURT (08310)", value: "08310" },
  { label: "BLAGNY (08110)", value: "08110" },
  { label: "BLANCHEFOSSE ET BAY (08290)", value: "08290" },
  { label: "BLANCHEFOSSE ET BAY (08290)", value: "08290" },
  { label: "BLANZY LA SALONNAISE (08190)", value: "08190" },
  { label: "BLOMBAY (08260)", value: "08260" },
  { label: "BOSSUS LES RUMIGNY (08290)", value: "08290" },
  { label: "BOUCONVILLE (08250)", value: "08250" },
  { label: "BOULT AUX BOIS (08240)", value: "08240" },
  { label: "BOULZICOURT (08410)", value: "08410" },
  { label: "BOURCQ (08400)", value: "08400" },
  { label: "BOURG FIDELE (08230)", value: "08230" },
  { label: "BOUVELLEMONT (08430)", value: "08430" },
  { label: "BOGNY SUR MEUSE (08120)", value: "08120" },
  { label: "BRECY BRIERES (08400)", value: "08400" },
  { label: "BREVILLY (08140)", value: "08140" },
  { label: "BRIENNE SUR AISNE (08190)", value: "08190" },
  { label: "BRIEULLES SUR BAR (08240)", value: "08240" },
  { label: "BRIQUENAY (08240)", value: "08240" },
  { label: "BROGNON (08380)", value: "08380" },
  { label: "BULSON (08450)", value: "08450" },
  { label: "BUZANCY (08240)", value: "08240" },
  { label: "BUZANCY (08240)", value: "08240" },
  { label: "CARIGNAN (08110)", value: "08110" },
  { label: "CARIGNAN (08110)", value: "08110" },
  { label: "CAUROY (08310)", value: "08310" },
  { label: "CERNION (08260)", value: "08260" },
  { label: "CHAGNY (08430)", value: "08430" },
  { label: "CHALANDRY ELAIRE (08160)", value: "08160" },
  { label: "CHALLERANGE (08400)", value: "08400" },
  { label: "CHAMPIGNEULLE (08250)", value: "08250" },
  { label: "CHAMPIGNEUL SUR VENCE (08430)", value: "08430" },
  { label: "CHAMPLIN (08260)", value: "08260" },
  { label: "LA CHAPELLE (08200)", value: "08200" },
  { label: "CHAPPES (08220)", value: "08220" },
  { label: "CHARBOGNE (08130)", value: "08130" },
  { label: "CHARDENY (08400)", value: "08400" },
  { label: "CHARLEVILLE MEZIERES (08000)", value: "08000" },
  { label: "CHARNOIS (08600)", value: "08600" },
  { label: "CHATEAU PORCIEN (08360)", value: "08360" },
  { label: "CHATEL CHEHERY (08250)", value: "08250" },
  { label: "LE CHATELET SUR SORMONNE (08150)", value: "08150" },
  { label: "LE CHATELET SUR RETOURNE (08300)", value: "08300" },
  { label: "CHAUMONT PORCIEN (08220)", value: "08220" },
  { label: "CHAUMONT PORCIEN (08220)", value: "08220" },
  { label: "CHAUMONT PORCIEN (08220)", value: "08220" },
  { label: "CHEMERY CHEHERY (08350)", value: "08350" },
  { label: "CHEMERY CHEHERY (08450)", value: "08450" },
  { label: "CHEMERY CHEHERY (08450)", value: "08450" },
  { label: "CHEMERY CHEHERY (08450)", value: "08450" },
  { label: "BAIRON ET SES ENVIRONS (08390)", value: "08390" },
  { label: "BAIRON ET SES ENVIRONS (08390)", value: "08390" },
  { label: "BAIRON ET SES ENVIRONS (08400)", value: "08400" },
  { label: "CHESNOIS AUBONCOURT (08270)", value: "08270" },
  { label: "CHEVEUGES (08350)", value: "08350" },
  { label: "CHEVIERES (08250)", value: "08250" },
  { label: "CHILLY (08260)", value: "08260" },
  { label: "CHOOZ (08600)", value: "08600" },
  { label: "CHUFFILLY ROCHE (08130)", value: "08130" },
  { label: "CLAVY WARBY (08460)", value: "08460" },
  { label: "CLIRON (08090)", value: "08090" },
  { label: "CONDE LES HERPY (08360)", value: "08360" },
  { label: "CONDE LES AUTRY (08250)", value: "08250" },
  { label: "CONTREUVE (08400)", value: "08400" },
  { label: "CORNAY (08250)", value: "08250" },
  { label: "CORNY MACHEROMENIL (08270)", value: "08270" },
  { label: "COUCY (08300)", value: "08300" },
  { label: "COULOMMES ET MARQUENY (08130)", value: "08130" },
  { label: "LA CROIX AUX BOIS (08400)", value: "08400" },
  { label: "DAIGNY (08140)", value: "08140" },
  { label: "DAMOUZY (08090)", value: "08090" },
  { label: "LES DEUX VILLES (08110)", value: "08110" },
  { label: "DEVILLE (08800)", value: "08800" },
  { label: "DOM LE MESNIL (08160)", value: "08160" },
  { label: "DOM LE MESNIL (08160)", value: "08160" },
  { label: "DOMMERY (08460)", value: "08460" },
  { label: "DONCHERY (08350)", value: "08350" },
  { label: "DOUMELY BEGNY (08220)", value: "08220" },
  { label: "DOUX (08300)", value: "08300" },
  { label: "DOUZY (08140)", value: "08140" },
  { label: "DOUZY (08140)", value: "08140" },
  { label: "DRAIZE (08220)", value: "08220" },
  { label: "DRICOURT (08310)", value: "08310" },
  { label: "L'ECAILLE (08300)", value: "08300" },
  { label: "L'ECHELLE (08150)", value: "08150" },
  { label: "ECLY (08300)", value: "08300" },
  { label: "ECORDAL (08130)", value: "08130" },
  { label: "ESCOMBRES ET LE CHESNOIS (08110)", value: "08110" },
  { label: "ESTREBAY (08260)", value: "08260" },
  { label: "ETALLE (08260)", value: "08260" },
  { label: "ETEIGNIERES (08260)", value: "08260" },
  { label: "ETREPIGNY (08160)", value: "08160" },
  { label: "EUILLY ET LOMBUT (08210)", value: "08210" },
  { label: "EVIGNY (08090)", value: "08090" },
  { label: "EXERMONT (08250)", value: "08250" },
  { label: "FAGNON (08090)", value: "08090" },
  { label: "FAISSAULT (08270)", value: "08270" },
  { label: "FALAISE (08400)", value: "08400" },
  { label: "FAUX (08270)", value: "08270" },
  { label: "FEPIN (08170)", value: "08170" },
  { label: "LA FEREE (08290)", value: "08290" },
  { label: "LA FERTE SUR CHIERS (08370)", value: "08370" },
  { label: "FLAIGNES HAVYS (08260)", value: "08260" },
  { label: "FLAIGNES HAVYS (08260)", value: "08260" },
  { label: "FLEIGNEUX (08200)", value: "08200" },
  { label: "FLEVILLE (08250)", value: "08250" },
  { label: "FLIGNY (08380)", value: "08380" },
  { label: "FLIZE (08160)", value: "08160" },
  { label: "FLIZE (08160)", value: "08160" },
  { label: "FLIZE (08160)", value: "08160" },
  { label: "FLIZE (08160)", value: "08160" },
  { label: "FLOING (08200)", value: "08200" },
  { label: "FOISCHES (08600)", value: "08600" },
  { label: "FOSSE (08240)", value: "08240" },
  { label: "FRAILLICOURT (08220)", value: "08220" },
  { label: "FRANCHEVAL (08140)", value: "08140" },
  { label: "LA FRANCHEVILLE (08000)", value: "08000" },
  { label: "LE FRETY (08290)", value: "08290" },
  { label: "FROMELENNES (08600)", value: "08600" },
  { label: "FROMELENNES (08600)", value: "08600" },
  { label: "FROMY (08370)", value: "08370" },
  { label: "FUMAY (08170)", value: "08170" },
  { label: "GERMONT (08240)", value: "08240" },
  { label: "GERNELLE (08440)", value: "08440" },
  { label: "GESPUNSART (08700)", value: "08700" },
  { label: "GIRONDELLE (08260)", value: "08260" },
  { label: "GIRONDELLE (08260)", value: "08260" },
  { label: "GIVET (08600)", value: "08600" },
  { label: "GIVONNE (08200)", value: "08200" },
  { label: "GIVRON (08220)", value: "08220" },
  { label: "GIVRY (08130)", value: "08130" },
  { label: "GLAIRE (08200)", value: "08200" },
  { label: "GOMONT (08190)", value: "08190" },
  { label: "GRANDCHAMP (08270)", value: "08270" },
  { label: "GRANDHAM (08250)", value: "08250" },
  { label: "GRANDPRE (08250)", value: "08250" },
  { label: "GRANDPRE (08250)", value: "08250" },
  { label: "LA GRANDVILLE (08700)", value: "08700" },
  { label: "GRIVY LOISY (08400)", value: "08400" },
  { label: "GRUYERES (08430)", value: "08430" },
  { label: "GUE D HOSSUS (08230)", value: "08230" },
  { label: "GUIGNICOURT SUR VENCE (08430)", value: "08430" },
  { label: "GUINCOURT (08130)", value: "08130" },
  { label: "HAGNICOURT (08430)", value: "08430" },
  { label: "HAM LES MOINES (08090)", value: "08090" },
  { label: "HAM SUR MEUSE (08600)", value: "08600" },
  { label: "HANNAPPES (08290)", value: "08290" },
  { label: "HANNOGNE ST MARTIN (08160)", value: "08160" },
  { label: "HANNOGNE ST REMY (08220)", value: "08220" },
  { label: "HARAUCOURT (08450)", value: "08450" },
  { label: "HARCY (08150)", value: "08150" },
  { label: "HARGNIES (08170)", value: "08170" },
  { label: "HARRICOURT (08240)", value: "08240" },
  { label: "HAUDRECY (08090)", value: "08090" },
  { label: "HAULME (08800)", value: "08800" },
  { label: "LES HAUTES RIVIERES (08800)", value: "08800" },
  { label: "LES HAUTES RIVIERES (08800)", value: "08800" },
  { label: "HAUTEVILLE (08300)", value: "08300" },
  { label: "HAUVINE (08310)", value: "08310" },
  { label: "HAYBES (08170)", value: "08170" },
  { label: "HERBEUVAL (08370)", value: "08370" },
  { label: "HERPY L ARLESIENNE (08360)", value: "08360" },
  { label: "HIERGES (08320)", value: "08320" },
  { label: "LA HORGNE (08430)", value: "08430" },
  { label: "HOUDILCOURT (08190)", value: "08190" },
  { label: "HOULDIZY (08090)", value: "08090" },
  { label: "ILLY (08200)", value: "08200" },
  { label: "IMECOURT (08240)", value: "08240" },
  { label: "INAUMONT (08300)", value: "08300" },
  { label: "ISSANCOURT ET RUMEL (08440)", value: "08440" },
  { label: "JANDUN (08430)", value: "08430" },
  { label: "JOIGNY SUR MEUSE (08700)", value: "08700" },
  { label: "JONVAL (08130)", value: "08130" },
  { label: "JUNIVILLE (08310)", value: "08310" },
  { label: "JUSTINE HERBIGNY (08270)", value: "08270" },
  { label: "LAIFOUR (08800)", value: "08800" },
  { label: "LAIFOUR (08800)", value: "08800" },
  { label: "LALOBBE (08460)", value: "08460" },
  { label: "LAMETZ (08130)", value: "08130" },
  { label: "LANCON (08250)", value: "08250" },
  { label: "LANDRES ET ST GEORGES (08240)", value: "08240" },
  { label: "LANDRICHAMPS (08600)", value: "08600" },
  { label: "LAUNOIS SUR VENCE (08430)", value: "08430" },
  { label: "LAVAL MORENCY (08150)", value: "08150" },
  { label: "LEFFINCOURT (08310)", value: "08310" },
  { label: "LEPRON LES VALLEES (08150)", value: "08150" },
  { label: "LETANNE (08210)", value: "08210" },
  { label: "LIART (08290)", value: "08290" },
  { label: "LINAY (08110)", value: "08110" },
  { label: "LIRY (08400)", value: "08400" },
  { label: "LOGNY BOGNY (08150)", value: "08150" },
  { label: "LONGWE (08400)", value: "08400" },
  { label: "LONNY (08150)", value: "08150" },
  { label: "LUCQUY (08300)", value: "08300" },
  { label: "LUMES (08440)", value: "08440" },
  { label: "MACHAULT (08310)", value: "08310" },
  { label: "MAISONCELLE ET VILLERS (08450)", value: "08450" },
  { label: "MALANDRY (08370)", value: "08370" },
  { label: "MANRE (08400)", value: "08400" },
  { label: "MARANWEZ (08460)", value: "08460" },
  { label: "MARBY (08260)", value: "08260" },
  { label: "MARCQ (08250)", value: "08250" },
  { label: "MARGNY (08370)", value: "08370" },
  { label: "MARGUT (08370)", value: "08370" },
  { label: "MARLEMONT (08290)", value: "08290" },
  { label: "MARQUIGNY (08390)", value: "08390" },
  { label: "MARS SOUS BOURCQ (08400)", value: "08400" },
  { label: "MARVAUX VIEUX (08400)", value: "08400" },
  { label: "MATTON ET CLEMENCY (08110)", value: "08110" },
  { label: "MAUBERT FONTAINE (08260)", value: "08260" },
  { label: "MAZERNY (08430)", value: "08430" },
  { label: "LES MAZURES (08500)", value: "08500" },
  { label: "MENIL ANNELLES (08310)", value: "08310" },
  { label: "MENIL LEPINOIS (08310)", value: "08310" },
  { label: "MESMONT (08270)", value: "08270" },
  { label: "MESSINCOURT (08110)", value: "08110" },
  { label: "MOGUES (08110)", value: "08110" },
  { label: "MOIRY (08370)", value: "08370" },
  { label: "MONDIGNY (08430)", value: "08430" },
  { label: "MONTCHEUTIN (08250)", value: "08250" },
  { label: "MONTCORNET (08090)", value: "08090" },
  { label: "MONTCY NOTRE DAME (08090)", value: "08090" },
  { label: "LE MONT DIEU (08390)", value: "08390" },
  { label: "MONTGON (08390)", value: "08390" },
  { label: "MONTHERME (08800)", value: "08800" },
  { label: "MONTHERME (08800)", value: "08800" },
  { label: "MONTHERME (08800)", value: "08800" },
  { label: "MONTHOIS (08400)", value: "08400" },
  { label: "MONTIGNY SUR MEUSE (08170)", value: "08170" },
  { label: "MONTIGNY SUR VENCE (08430)", value: "08430" },
  { label: "MONT LAURENT (08130)", value: "08130" },
  { label: "MONTMEILLANT (08220)", value: "08220" },
  { label: "MONT ST MARTIN (08400)", value: "08400" },
  { label: "MONT ST REMY (08310)", value: "08310" },
  { label: "MOURON (08250)", value: "08250" },
  { label: "MOUZON (08210)", value: "08210" },
  { label: "MOUZON (08210)", value: "08210" },
  { label: "MURTIN ET BOGNY (08150)", value: "08150" },
  { label: "NANTEUIL SUR AISNE (08300)", value: "08300" },
  { label: "NEUFLIZE (08300)", value: "08300" },
  { label: "NEUFMAISON (08460)", value: "08460" },
  { label: "NEUFMANIL (08700)", value: "08700" },
  { label: "LA NEUVILLE A MAIRE (08450)", value: "08450" },
  { label: "LA NEUVILLE AUX JOUTES (08380)", value: "08380" },
  { label: "NEUVILLE LEZ BEAULIEU (08380)", value: "08380" },
  { label: "NEUVILLE LEZ BEAULIEU (08380)", value: "08380" },
  { label: "LA NEUVILLE EN TOURNE A FUY (08310)", value: "08310" },
  { label: "NEUVILLE DAY (08130)", value: "08130" },
  { label: "NEUVILLE LES THIS (08090)", value: "08090" },
  { label: "LA NEUVILLE LES WASIGNY (08270)", value: "08270" },
  { label: "NEUVIZY (08430)", value: "08430" },
  { label: "NOIRVAL (08400)", value: "08400" },
  { label: "NOUART (08240)", value: "08240" },
  { label: "NOUART (08240)", value: "08240" },
  { label: "NOUVION SUR MEUSE (08160)", value: "08160" },
  { label: "NOUZONVILLE (08700)", value: "08700" },
  { label: "NOUZONVILLE (08700)", value: "08700" },
  { label: "NOVION PORCIEN (08270)", value: "08270" },
  { label: "NOVION PORCIEN (08270)", value: "08270" },
  { label: "NOVY CHEVRIERES (08300)", value: "08300" },
  { label: "NOYERS PONT MAUGIS (08350)", value: "08350" },
  { label: "OCHES (08240)", value: "08240" },
  { label: "OLIZY PRIMAT (08250)", value: "08250" },
  { label: "OLIZY PRIMAT (08250)", value: "08250" },
  { label: "OMICOURT (08450)", value: "08450" },
  { label: "OMONT (08430)", value: "08430" },
  { label: "OSNES (08110)", value: "08110" },
  { label: "PAUVRES (08310)", value: "08310" },
  { label: "PERTHES (08300)", value: "08300" },
  { label: "POILCOURT SYDNEY (08190)", value: "08190" },
  { label: "POIX TERRON (08430)", value: "08430" },
  { label: "POURU AUX BOIS (08140)", value: "08140" },
  { label: "POURU ST REMY (08140)", value: "08140" },
  { label: "PREZ (08290)", value: "08290" },
  { label: "PREZ (08290)", value: "08290" },
  { label: "PRIX LES MEZIERES (08000)", value: "08000" },
  { label: "PUILLY ET CHARBEAUX (08370)", value: "08370" },
  { label: "PUISEUX (08270)", value: "08270" },
  { label: "PURE (08110)", value: "08110" },
  { label: "QUATRE CHAMPS (08400)", value: "08400" },
  { label: "QUILLY (08400)", value: "08400" },
  { label: "RAILLICOURT (08430)", value: "08430" },
  { label: "RANCENNES (08600)", value: "08600" },
  { label: "RAUCOURT ET FLABA (08450)", value: "08450" },
  { label: "REGNIOWEZ (08230)", value: "08230" },
  { label: "REMAUCOURT (08220)", value: "08220" },
  { label: "REMILLY AILLICOURT (08450)", value: "08450" },
  { label: "REMILLY LES POTHEES (08150)", value: "08150" },
  { label: "RENNEVILLE (08220)", value: "08220" },
  { label: "RENWEZ (08150)", value: "08150" },
  { label: "RETHEL (08300)", value: "08300" },
  { label: "RETHEL (08300)", value: "08300" },
  { label: "REVIN (08500)", value: "08500" },
  { label: "RILLY SUR AISNE (08130)", value: "08130" },
  { label: "RIMOGNE (08150)", value: "08150" },
  { label: "ROCQUIGNY (08220)", value: "08220" },
  { label: "ROCQUIGNY (08220)", value: "08220" },
  { label: "ROCQUIGNY (08220)", value: "08220" },
  { label: "ROCQUIGNY (08220)", value: "08220" },
  { label: "ROCROI (08230)", value: "08230" },
  { label: "ROCROI (08230)", value: "08230" },
  { label: "ROCROI (08230)", value: "08230" },
  { label: "ROIZY (08190)", value: "08190" },
  { label: "LA ROMAGNE (08220)", value: "08220" },
  { label: "ROUVROY SUR AUDRY (08150)", value: "08150" },
  { label: "ROUVROY SUR AUDRY (08150)", value: "08150" },
  { label: "RUBIGNY (08220)", value: "08220" },
  { label: "RUMIGNY (08290)", value: "08290" },
  { label: "LA SABOTTERIE (08130)", value: "08130" },
  { label: "SACHY (08110)", value: "08110" },
  { label: "SAILLY (08110)", value: "08110" },
  { label: "ST AIGNAN (08350)", value: "08350" },
  { label: "ST CLEMENT A ARNES (08310)", value: "08310" },
  { label: "ST ETIENNE A ARNES (08310)", value: "08310" },
  { label: "ST FERGEUX (08360)", value: "08360" },
  { label: "ST FERGEUX (08360)", value: "08360" },
  { label: "ST GERMAINMONT (08190)", value: "08190" },
  { label: "ST JEAN AUX BOIS (08220)", value: "08220" },
  { label: "ST JUVIN (08250)", value: "08250" },
  { label: "ST LAMBERT ET MONT DE JEUX (08130)", value: "08130" },
  { label: "ST LAURENT (08090)", value: "08090" },
  { label: "ST LOUP EN CHAMPAGNE (08300)", value: "08300" },
  { label: "ST LOUP TERRIER (08130)", value: "08130" },
  { label: "ST MARCEAU (08160)", value: "08160" },
  { label: "ST MARCEL (08460)", value: "08460" },
  { label: "STE MARIE (08400)", value: "08400" },
  { label: "ST MENGES (08200)", value: "08200" },
  { label: "ST MOREL (08400)", value: "08400" },
  { label: "ST PIERRE A ARNES (08310)", value: "08310" },
  { label: "ST PIERREMONT (08240)", value: "08240" },
  { label: "ST PIERRE SUR VENCE (08430)", value: "08430" },
  { label: "ST QUENTIN LE PETIT (08220)", value: "08220" },
  { label: "ST REMY LE PETIT (08300)", value: "08300" },
  { label: "STE VAUBOURG (08130)", value: "08130" },
  { label: "SAPOGNE SUR MARCHE (08370)", value: "08370" },
  { label: "SAPOGNE ET FEUCHERES (08160)", value: "08160" },
  { label: "SAULCES CHAMPENOISES (08130)", value: "08130" },
  { label: "SAULCES MONCLIN (08270)", value: "08270" },
  { label: "SAULT LES RETHEL (08300)", value: "08300" },
  { label: "SAULT ST REMY (08190)", value: "08190" },
  { label: "SAUVILLE (08390)", value: "08390" },
  { label: "SAVIGNY SUR AISNE (08400)", value: "08400" },
  { label: "SECHAULT (08250)", value: "08250" },
  { label: "SECHEVAL (08150)", value: "08150" },
  { label: "SEDAN (08200)", value: "08200" },
  { label: "SEMIDE (08400)", value: "08400" },
  { label: "SEMUY (08130)", value: "08130" },
  { label: "SENUC (08250)", value: "08250" },
  { label: "SERAINCOURT (08220)", value: "08220" },
  { label: "SERAINCOURT (08220)", value: "08220" },
  { label: "SERY (08270)", value: "08270" },
  { label: "SEUIL (08300)", value: "08300" },
  { label: "SEVIGNY LA FORET (08230)", value: "08230" },
  { label: "SEVIGNY WALEPPE (08220)", value: "08220" },
  { label: "SIGNY L ABBAYE (08460)", value: "08460" },
  { label: "SIGNY L ABBAYE (08460)", value: "08460" },
  { label: "SIGNY LE PETIT (08380)", value: "08380" },
  { label: "SIGNY LE PETIT (08380)", value: "08380" },
  { label: "SIGNY MONTLIBERT (08370)", value: "08370" },
  { label: "SINGLY (08430)", value: "08430" },
  { label: "SOMMAUTHE (08240)", value: "08240" },
  { label: "SOMMERANCE (08250)", value: "08250" },
  { label: "SON (08300)", value: "08300" },
  { label: "SORBON (08300)", value: "08300" },
  { label: "SORCY BAUTHEMONT (08270)", value: "08270" },
  { label: "SORMONNE (08150)", value: "08150" },
  { label: "STONNE (08390)", value: "08390" },
  { label: "SUGNY (08400)", value: "08400" },
  { label: "SURY (08090)", value: "08090" },
  { label: "SUZANNE (08130)", value: "08130" },
  { label: "SY (08390)", value: "08390" },
  { label: "TAGNON (08300)", value: "08300" },
  { label: "TAILLETTE (08230)", value: "08230" },
  { label: "TAILLY (08240)", value: "08240" },
  { label: "TAILLY (08240)", value: "08240" },
  { label: "TAILLY (08240)", value: "08240" },
  { label: "TAILLY (08240)", value: "08240" },
  { label: "TAIZY (08360)", value: "08360" },
  { label: "TANNAY (08390)", value: "08390" },
  { label: "TARZY (08380)", value: "08380" },
  { label: "TETAIGNE (08110)", value: "08110" },
  { label: "THELONNE (08350)", value: "08350" },
  { label: "THENORGUES (08240)", value: "08240" },
  { label: "THILAY (08800)", value: "08800" },
  { label: "THILAY (08800)", value: "08800" },
  { label: "THIN LE MOUTIER (08460)", value: "08460" },
  { label: "THIS (08090)", value: "08090" },
  { label: "LE THOUR (08190)", value: "08190" },
  { label: "THUGNY TRUGNY (08300)", value: "08300" },
  { label: "TOGES (08400)", value: "08400" },
  { label: "TOULIGNY (08430)", value: "08430" },
  { label: "TOURCELLES CHAUMONT (08400)", value: "08400" },
  { label: "TOURNAVAUX (08800)", value: "08800" },
  { label: "TOURNES (08090)", value: "08090" },
  { label: "TOURTERON (08130)", value: "08130" },
  { label: "TREMBLOIS LES CARIGNAN (08110)", value: "08110" },
  { label: "TREMBLOIS LES ROCROI (08150)", value: "08150" },
  { label: "VANDY (08400)", value: "08400" },
  { label: "VAUX CHAMPAGNE (08130)", value: "08130" },
  { label: "VAUX EN DIEULET (08240)", value: "08240" },
  { label: "VAUX LES MOURON (08250)", value: "08250" },
  { label: "VAUX LES RUBIGNY (08220)", value: "08220" },
  { label: "VAUX LES MOUZON (08210)", value: "08210" },
  { label: "VAUX MONTREUIL (08270)", value: "08270" },
  { label: "VAUX VILLAINE (08150)", value: "08150" },
  { label: "VENDRESSE (08160)", value: "08160" },
  { label: "VENDRESSE (08160)", value: "08160" },
  { label: "VERPEL (08240)", value: "08240" },
  { label: "VERRIERES (08390)", value: "08390" },
  { label: "VIEL ST REMY (08270)", value: "08270" },
  { label: "VIEL ST REMY (08270)", value: "08270" },
  { label: "VIEUX LES ASFELD (08190)", value: "08190" },
  { label: "VILLERS DEVANT LE THOUR (08190)", value: "08190" },
  { label: "VILLERS DEVANT MOUZON (08210)", value: "08210" },
  { label: "VILLERS LE TILLEUL (08430)", value: "08430" },
  { label: "VILLERS LE TOURNEUR (08430)", value: "08430" },
  { label: "VILLERS SEMEUSE (08000)", value: "08000" },
  { label: "VILLERS SUR BAR (08350)", value: "08350" },
  { label: "VILLERS SUR LE MONT (08430)", value: "08430" },
  { label: "VILLE SUR LUMES (08440)", value: "08440" },
  { label: "VILLE SUR RETOURNE (08310)", value: "08310" },
  { label: "VILLY (08370)", value: "08370" },
  { label: "VIREUX MOLHAIN (08320)", value: "08320" },
  { label: "VIREUX WALLERAND (08320)", value: "08320" },
  { label: "VIVIER AU COURT (08440)", value: "08440" },
  { label: "VONCQ (08400)", value: "08400" },
  { label: "VOUZIERS (08400)", value: "08400" },
  { label: "VOUZIERS (08400)", value: "08400" },
  { label: "VOUZIERS (08400)", value: "08400" },
  { label: "VOUZIERS (08400)", value: "08400" },
  { label: "VRIGNE AUX BOIS (08330)", value: "08330" },
  { label: "VRIGNE AUX BOIS (08350)", value: "08350" },
  { label: "VRIGNE MEUSE (08350)", value: "08350" },
  { label: "WADELINCOURT (08200)", value: "08200" },
  { label: "WAGNON (08270)", value: "08270" },
  { label: "WARCQ (08000)", value: "08000" },
  { label: "WARNECOURT (08090)", value: "08090" },
  { label: "WASIGNY (08270)", value: "08270" },
  { label: "WIGNICOURT (08270)", value: "08270" },
  { label: "WILLIERS (08110)", value: "08110" },
  { label: "YONCQ (08210)", value: "08210" },
  { label: "YVERNAUMONT (08430)", value: "08430" },
  { label: "AIGUES JUNTES (09240)", value: "09240" },
  { label: "AIGUES VIVES (09600)", value: "09600" },
  { label: "L'AIGUILLON (09300)", value: "09300" },
  { label: "ALBIES (09310)", value: "09310" },
  { label: "ALEU (09320)", value: "09320" },
  { label: "ALLIAT (09400)", value: "09400" },
  { label: "ALLIERES (09240)", value: "09240" },
  { label: "ALOS (09200)", value: "09200" },
  { label: "ALZEN (09240)", value: "09240" },
  { label: "ANTRAS (09800)", value: "09800" },
  { label: "APPY (09250)", value: "09250" },
  { label: "ARABAUX (09000)", value: "09000" },
  { label: "ARGEIN (09800)", value: "09800" },
  { label: "ARIGNAC (09400)", value: "09400" },
  { label: "ARNAVE (09400)", value: "09400" },
  { label: "ARRIEN EN BETHMALE (09800)", value: "09800" },
  { label: "ARROUT (09800)", value: "09800" },
  { label: "ARTIGAT (09130)", value: "09130" },
  { label: "ARTIGUES (09460)", value: "09460" },
  { label: "ARTIX (09120)", value: "09120" },
  { label: "ARVIGNA (09100)", value: "09100" },
  { label: "ASCOU (09110)", value: "09110" },
  { label: "ASTON (09310)", value: "09310" },
  { label: "AUCAZEIN (09800)", value: "09800" },
  { label: "AUDRESSEIN (09800)", value: "09800" },
  { label: "AUGIREIN (09800)", value: "09800" },
  { label: "AULUS LES BAINS (09140)", value: "09140" },
  { label: "AUZAT (09220)", value: "09220" },
  { label: "AXIAT (09250)", value: "09250" },
  { label: "AX LES THERMES (09110)", value: "09110" },
  { label: "BAGERT (09230)", value: "09230" },
  { label: "BALACET (09800)", value: "09800" },
  { label: "BALAGUERES (09800)", value: "09800" },
  { label: "BALAGUERES (09800)", value: "09800" },
  { label: "BARJAC (09230)", value: "09230" },
  { label: "LA BASTIDE DE BESPLAS (09350)", value: "09350" },
  { label: "LA BASTIDE DE BOUSIGNAC (09500)", value: "09500" },
  { label: "LA BASTIDE DE LORDAT (09700)", value: "09700" },
  { label: "LA BASTIDE DU SALAT (09160)", value: "09160" },
  { label: "LA BASTIDE DE SEROU (09240)", value: "09240" },
  { label: "LA BASTIDE SUR L HERS (09600)", value: "09600" },
  { label: "BAULOU (09000)", value: "09000" },
  { label: "BEDEILHAC ET AYNAT (09400)", value: "09400" },
  { label: "BEDEILLE (09230)", value: "09230" },
  { label: "BELESTA (09300)", value: "09300" },
  { label: "BELLOC (09600)", value: "09600" },
  { label: "BENAC (09000)", value: "09000" },
  { label: "BENAGUES (09100)", value: "09100" },
  { label: "BENAIX (09300)", value: "09300" },
  { label: "BESSET (09500)", value: "09500" },
  { label: "BESTIAC (09250)", value: "09250" },
  { label: "BETCHAT (09160)", value: "09160" },
  { label: "BETHMALE (09800)", value: "09800" },
  { label: "BEZAC (09100)", value: "09100" },
  { label: "BEZAC (09100)", value: "09100" },
  { label: "BIERT (09320)", value: "09320" },
  { label: "BOMPAS (09400)", value: "09400" },
  { label: "BONAC IRAZEIN (09800)", value: "09800" },
  { label: "BONNAC (09100)", value: "09100" },
  { label: "LES BORDES SUR ARIZE (09350)", value: "09350" },
  { label: "BORDES UCHENTEIN (09800)", value: "09800" },
  { label: "BORDES UCHENTEIN (09800)", value: "09800" },
  { label: "LE BOSC (09000)", value: "09000" },
  { label: "BOUAN (09310)", value: "09310" },
  { label: "BOUSSENAC (09320)", value: "09320" },
  { label: "BRASSAC (09000)", value: "09000" },
  { label: "BRIE (09700)", value: "09700" },
  { label: "BURRET (09000)", value: "09000" },
  { label: "BUZAN (09800)", value: "09800" },
  { label: "LES CABANNES (09310)", value: "09310" },
  { label: "CADARCET (09240)", value: "09240" },
  { label: "CALZAN (09120)", value: "09120" },
  { label: "CAMARADE (09290)", value: "09290" },
  { label: "CAMON (09500)", value: "09500" },
  { label: "CAMPAGNE SUR ARIZE (09350)", value: "09350" },
  { label: "CANTE (09700)", value: "09700" },
  { label: "CAPOULET ET JUNAC (09400)", value: "09400" },
  { label: "CARCANIERES (09460)", value: "09460" },
  { label: "CARLA BAYLE (09130)", value: "09130" },
  { label: "CARLA DE ROQUEFORT (09300)", value: "09300" },
  { label: "LE CARLARET (09100)", value: "09100" },
  { label: "CASTELNAU DURBAN (09420)", value: "09420" },
  { label: "CASTERAS (09130)", value: "09130" },
  { label: "CASTEX (09350)", value: "09350" },
  { label: "CASTILLON EN COUSERANS (09800)", value: "09800" },
  { label: "CAUMONT (09160)", value: "09160" },
  { label: "CAUSSOU (09250)", value: "09250" },
  { label: "CAYCHAX (09250)", value: "09250" },
  { label: "CAZALS DES BAYLES (09500)", value: "09500" },
  { label: "CAZAUX (09120)", value: "09120" },
  { label: "CAZAVET (09160)", value: "09160" },
  { label: "CAZENAVE SERRES ET ALLENS (09400)", value: "09400" },
  { label: "CELLES (09000)", value: "09000" },
  { label: "CERIZOLS (09230)", value: "09230" },
  { label: "CESCAU (09800)", value: "09800" },
  { label: "CHATEAU VERDUN (09310)", value: "09310" },
  { label: "CLERMONT (09420)", value: "09420" },
  { label: "CONTRAZY (09230)", value: "09230" },
  { label: "COS (09000)", value: "09000" },
  { label: "COUFLENS (09140)", value: "09140" },
  { label: "COUFLENS (09140)", value: "09140" },
  { label: "COUSSA (09120)", value: "09120" },
  { label: "COUTENS (09500)", value: "09500" },
  { label: "CRAMPAGNA (09120)", value: "09120" },
  { label: "DALOU (09120)", value: "09120" },
  { label: "DAUMAZAN SUR ARIZE (09350)", value: "09350" },
  { label: "DREUILHE (09300)", value: "09300" },
  { label: "DUN (09600)", value: "09600" },
  { label: "DUN (09600)", value: "09600" },
  { label: "DUN (09600)", value: "09600" },
  { label: "DUN (09600)", value: "09600" },
  { label: "DURBAN SUR ARIZE (09240)", value: "09240" },
  { label: "DURFORT (09130)", value: "09130" },
  { label: "ENCOURTIECH (09200)", value: "09200" },
  { label: "ENGOMER (09800)", value: "09800" },
  { label: "ERCE (09140)", value: "09140" },
  { label: "ERP (09200)", value: "09200" },
  { label: "ESCLAGNE (09600)", value: "09600" },
  { label: "ESCOSSE (09100)", value: "09100" },
  { label: "ESPLAS (09700)", value: "09700" },
  { label: "ESPLAS DE SEROU (09420)", value: "09420" },
  { label: "EYCHEIL (09200)", value: "09200" },
  { label: "FABAS (09230)", value: "09230" },
  { label: "FERRIERES SUR ARIEGE (09000)", value: "09000" },
  { label: "FOIX (09000)", value: "09000" },
  { label: "FORNEX (09350)", value: "09350" },
  { label: "LE FOSSAT (09130)", value: "09130" },
  { label: "FOUGAX ET BARRINEUF (09300)", value: "09300" },
  { label: "FREYCHENET (09300)", value: "09300" },
  { label: "GABRE (09290)", value: "09290" },
  { label: "GAJAN (09190)", value: "09190" },
  { label: "GALEY (09800)", value: "09800" },
  { label: "GANAC (09000)", value: "09000" },
  { label: "GARANOU (09250)", value: "09250" },
  { label: "GAUDIES (09700)", value: "09700" },
  { label: "GENAT (09400)", value: "09400" },
  { label: "GESTIES (09220)", value: "09220" },
  { label: "GOURBIT (09400)", value: "09400" },
  { label: "GUDAS (09120)", value: "09120" },
  { label: "L'HERM (09000)", value: "09000" },
  { label: "L'HOSPITALET PRES L ANDORRE (09390)", value: "09390" },
  { label: "IGNAUX (09110)", value: "09110" },
  { label: "ILLARTEIN (09800)", value: "09800" },
  { label: "ILHAT (09300)", value: "09300" },
  { label: "ILLIER ET LARAMADE (09220)", value: "09220" },
  { label: "LES ISSARDS (09100)", value: "09100" },
  { label: "JUSTINIAC (09700)", value: "09700" },
  { label: "LABATUT (09700)", value: "09700" },
  { label: "LACAVE (09160)", value: "09160" },
  { label: "LACOURT (09200)", value: "09200" },
  { label: "LAGARDE (09500)", value: "09500" },
  { label: "LANOUX (09130)", value: "09130" },
  { label: "LAPEGE (09400)", value: "09400" },
  { label: "LAPENNE (09500)", value: "09500" },
  { label: "LARBONT (09240)", value: "09240" },
  { label: "LARCAT (09310)", value: "09310" },
  { label: "LARNAT (09310)", value: "09310" },
  { label: "LAROQUE D OLMES (09600)", value: "09600" },
  { label: "LASSERRE (09230)", value: "09230" },
  { label: "LASSUR (09310)", value: "09310" },
  { label: "LAVELANET (09300)", value: "09300" },
  { label: "LERAN (09600)", value: "09600" },
  { label: "LERCOUL (09220)", value: "09220" },
  { label: "LESCOUSSE (09100)", value: "09100" },
  { label: "LESCURE (09420)", value: "09420" },
  { label: "LESPARROU (09300)", value: "09300" },
  { label: "LEYCHERT (09300)", value: "09300" },
  { label: "LEZAT SUR LEZE (09210)", value: "09210" },
  { label: "LIEURAC (09300)", value: "09300" },
  { label: "LIMBRASSAC (09600)", value: "09600" },
  { label: "LISSAC (09700)", value: "09700" },
  { label: "LORDAT (09250)", value: "09250" },
  { label: "LOUBAUT (09350)", value: "09350" },
  { label: "LOUBENS (09120)", value: "09120" },
  { label: "LOUBIERES (09000)", value: "09000" },
  { label: "LUDIES (09100)", value: "09100" },
  { label: "LUZENAC (09250)", value: "09250" },
  { label: "MADIERE (09100)", value: "09100" },
  { label: "MALEGOUDE (09500)", value: "09500" },
  { label: "MALLEON (09120)", value: "09120" },
  { label: "MANSES (09500)", value: "09500" },
  { label: "LE MAS D AZIL (09290)", value: "09290" },
  { label: "MASSAT (09320)", value: "09320" },
  { label: "MAUVEZIN DE PRAT (09160)", value: "09160" },
  { label: "MAUVEZIN DE STE CROIX (09230)", value: "09230" },
  { label: "MAZERES (09270)", value: "09270" },
  { label: "MERAS (09350)", value: "09350" },
  { label: "MERCENAC (09160)", value: "09160" },
  { label: "MERCUS GARRABET (09400)", value: "09400" },
  { label: "MERCUS GARRABET (09400)", value: "09400" },
  { label: "MERENS LES VALS (09110)", value: "09110" },
  { label: "MERIGON (09230)", value: "09230" },
  { label: "MIGLOS (09400)", value: "09400" },
  { label: "MIJANES (09460)", value: "09460" },
  { label: "MIREPOIX (09500)", value: "09500" },
  { label: "MONESPLE (09130)", value: "09130" },
  { label: "MONTAGAGNE (09240)", value: "09240" },
  { label: "MONTAILLOU (09110)", value: "09110" },
  { label: "MONTARDIT (09230)", value: "09230" },
  { label: "MONTAUT (09700)", value: "09700" },
  { label: "MONTBEL (09600)", value: "09600" },
  { label: "MONTEGUT EN COUSERANS (09200)", value: "09200" },
  { label: "MONTEGUT PLANTAUREL (09120)", value: "09120" },
  { label: "MONTELS (09240)", value: "09240" },
  { label: "MONTESQUIEU AVANTES (09200)", value: "09200" },
  { label: "MONTFA (09350)", value: "09350" },
  { label: "MONTFERRIER (09300)", value: "09300" },
  { label: "MONTGAILHARD (09330)", value: "09330" },
  { label: "MONTGAUCH (09160)", value: "09160" },
  { label: "MONTJOIE EN COUSERANS (09200)", value: "09200" },
  { label: "MONTJOIE EN COUSERANS (09200)", value: "09200" },
  { label: "MONTOULIEU (09000)", value: "09000" },
  { label: "MONTSEGUR (09300)", value: "09300" },
  { label: "MONTSERON (09240)", value: "09240" },
  { label: "MOULIN NEUF (09500)", value: "09500" },
  { label: "MOULIS (09200)", value: "09200" },
  { label: "NALZEN (09300)", value: "09300" },
  { label: "NESCUS (09240)", value: "09240" },
  { label: "NIAUX (09400)", value: "09400" },
  { label: "ORGEIX (09110)", value: "09110" },
  { label: "ORGIBET (09800)", value: "09800" },
  { label: "ORLU (09110)", value: "09110" },
  { label: "ORNOLAC USSAT LES BAINS (09400)", value: "09400" },
  { label: "ORUS (09220)", value: "09220" },
  { label: "OUST (09140)", value: "09140" },
  { label: "PAILHES (09130)", value: "09130" },
  { label: "PAMIERS (09100)", value: "09100" },
  { label: "PECH (09310)", value: "09310" },
  { label: "PEREILLE (09300)", value: "09300" },
  { label: "PERLES ET CASTELET (09110)", value: "09110" },
  { label: "LE PEYRAT (09600)", value: "09600" },
  { label: "LE PLA (09460)", value: "09460" },
  { label: "LE PORT (09320)", value: "09320" },
  { label: "PRADES (09110)", value: "09110" },
  { label: "PRADETTES (09600)", value: "09600" },
  { label: "PRADIERES (09000)", value: "09000" },
  { label: "PRAT BONREPAUX (09160)", value: "09160" },
  { label: "PRAYOLS (09000)", value: "09000" },
  { label: "LE PUCH (09460)", value: "09460" },
  { label: "LES PUJOLS (09100)", value: "09100" },
  { label: "QUERIGUT (09460)", value: "09460" },
  { label: "QUIE (09400)", value: "09400" },
  { label: "RABAT LES TROIS SEIGNEURS (09400)", value: "09400" },
  { label: "RAISSAC (09300)", value: "09300" },
  { label: "REGAT (09600)", value: "09600" },
  { label: "RIEUCROS (09500)", value: "09500" },
  { label: "RIEUX DE PELLEPORT (09120)", value: "09120" },
  { label: "RIMONT (09420)", value: "09420" },
  { label: "RIVERENERT (09200)", value: "09200" },
  { label: "ROQUEFIXADE (09300)", value: "09300" },
  { label: "ROQUEFORT LES CASCADES (09300)", value: "09300" },
  { label: "ROUMENGOUX (09500)", value: "09500" },
  { label: "ROUZE (09460)", value: "09460" },
  { label: "SABARAT (09350)", value: "09350" },
  { label: "ST AMADOU (09100)", value: "09100" },
  { label: "ST BAUZEIL (09120)", value: "09120" },
  { label: "STE CROIX VOLVESTRE (09230)", value: "09230" },
  { label: "ST FELIX DE RIEUTORD (09120)", value: "09120" },
  { label: "ST FELIX DE TOURNEGAT (09500)", value: "09500" },
  { label: "STE FOI (09500)", value: "09500" },
  { label: "ST GIRONS (09200)", value: "09200" },
  { label: "ST JEAN D AIGUES VIVES (09300)", value: "09300" },
  { label: "ST JEAN DU CASTILLONNAIS (09800)", value: "09800" },
  { label: "ST JEAN DE VERGES (09000)", value: "09000" },
  { label: "ST JEAN DE VERGES (09000)", value: "09000" },
  { label: "ST JEAN DU FALGA (09100)", value: "09100" },
  { label: "ST JULIEN DE GRAS CAPOU (09500)", value: "09500" },
  { label: "ST LARY (09800)", value: "09800" },
  { label: "ST LIZIER (09190)", value: "09190" },
  { label: "ST MARTIN DE CARALP (09000)", value: "09000" },
  { label: "ST MARTIN D OYDES (09100)", value: "09100" },
  { label: "ST MICHEL (09100)", value: "09100" },
  { label: "ST PAUL DE JARRAT (09000)", value: "09000" },
  { label: "ST PIERRE DE RIVIERE (09000)", value: "09000" },
  { label: "ST QUENTIN LA TOUR (09500)", value: "09500" },
  { label: "ST QUIRC (09700)", value: "09700" },
  { label: "ST VICTOR ROUZAUD (09100)", value: "09100" },
  { label: "ST YBARS (09210)", value: "09210" },
  { label: "SALSEIN (09800)", value: "09800" },
  { label: "SAURAT (09400)", value: "09400" },
  { label: "SAURAT (09400)", value: "09400" },
  { label: "SAUTEL (09300)", value: "09300" },
  { label: "SAVERDUN (09700)", value: "09700" },
  { label: "SAVIGNAC LES ORMEAUX (09110)", value: "09110" },
  { label: "SEGURA (09120)", value: "09120" },
  { label: "SEIX (09140)", value: "09140" },
  { label: "SENCONAC (09250)", value: "09250" },
  { label: "LORP SENTARAILLE (09190)", value: "09190" },
  { label: "SENTEIN (09800)", value: "09800" },
  { label: "SENTENAC D OUST (09140)", value: "09140" },
  { label: "SENTENAC DE SEROU (09240)", value: "09240" },
  { label: "SERRES SUR ARGET (09000)", value: "09000" },
  { label: "SIEURAS (09130)", value: "09130" },
  { label: "SIGUER (09220)", value: "09220" },
  { label: "AULOS SINSAT (09310)", value: "09310" },
  { label: "AULOS SINSAT (09310)", value: "09310" },
  { label: "SOR (09800)", value: "09800" },
  { label: "SORGEAT (09110)", value: "09110" },
  { label: "SOUEIX ROGALLE (09140)", value: "09140" },
  { label: "SOUEIX ROGALLE (09140)", value: "09140" },
  { label: "SOULA (09000)", value: "09000" },
  { label: "SOULAN (09320)", value: "09320" },
  { label: "SURBA (09400)", value: "09400" },
  { label: "SUZAN (09240)", value: "09240" },
  { label: "TABRE (09600)", value: "09600" },
  { label: "TARASCON SUR ARIEGE (09400)", value: "09400" },
  { label: "TARASCON SUR ARIEGE (09400)", value: "09400" },
  { label: "TAURIGNAN CASTET (09160)", value: "09160" },
  { label: "TAURIGNAN VIEUX (09190)", value: "09190" },
  { label: "TEILHET (09500)", value: "09500" },
  { label: "THOUARS SUR ARIZE (09350)", value: "09350" },
  { label: "TIGNAC (09110)", value: "09110" },
  { label: "LA TOUR DU CRIEU (09100)", value: "09100" },
  { label: "TOURTOUSE (09230)", value: "09230" },
  { label: "TOURTROL (09500)", value: "09500" },
  { label: "TREMOULET (09700)", value: "09700" },
  { label: "TROYE D ARIEGE (09500)", value: "09500" },
  { label: "UNAC (09250)", value: "09250" },
  { label: "UNZENT (09100)", value: "09100" },
  { label: "URS (09310)", value: "09310" },
  { label: "USSAT (09400)", value: "09400" },
  { label: "USTOU (09140)", value: "09140" },
  { label: "USTOU (09140)", value: "09140" },
  { label: "VALS (09500)", value: "09500" },
  { label: "VARILHES (09120)", value: "09120" },
  { label: "VAYCHIS (09110)", value: "09110" },
  { label: "VEBRE (09310)", value: "09310" },
  { label: "VENTENAC (09120)", value: "09120" },
  { label: "VERDUN (09310)", value: "09310" },
  { label: "VERNAJOUL (09000)", value: "09000" },
  { label: "VERNAUX (09250)", value: "09250" },
  { label: "LE VERNET (09700)", value: "09700" },
  { label: "VERNIOLLE (09340)", value: "09340" },
  { label: "VAL DE SOS (09220)", value: "09220" },
  { label: "VAL DE SOS (09220)", value: "09220" },
  { label: "VAL DE SOS (09220)", value: "09220" },
  { label: "VAL DE SOS (09220)", value: "09220" },
  { label: "VILLENEUVE (09800)", value: "09800" },
  { label: "VILLENEUVE D OLMES (09300)", value: "09300" },
  { label: "VILLENEUVE DU LATOU (09130)", value: "09130" },
  { label: "VILLENEUVE DU PAREAGE (09100)", value: "09100" },
  { label: "VIRA (09120)", value: "09120" },
  { label: "VIVIES (09500)", value: "09500" },
  { label: "STE SUZANNE (09130)", value: "09130" },
  { label: "AILLEVILLE (10200)", value: "10200" },
  { label: "AIX VILLEMAUR PALIS (10160)", value: "10160" },
  { label: "AIX VILLEMAUR PALIS (10190)", value: "10190" },
  { label: "AIX VILLEMAUR PALIS (10190)", value: "10190" },
  { label: "ALLIBAUDIERES (10700)", value: "10700" },
  { label: "AMANCE (10140)", value: "10140" },
  { label: "ARCIS SUR AUBE (10700)", value: "10700" },
  { label: "ARCONVILLE (10200)", value: "10200" },
  { label: "ARGANCON (10140)", value: "10140" },
  { label: "ARRELLES (10340)", value: "10340" },
  { label: "ARREMBECOURT (10330)", value: "10330" },
  { label: "ARRENTIERES (10200)", value: "10200" },
  { label: "ARSONVAL (10200)", value: "10200" },
  { label: "ASSENAY (10320)", value: "10320" },
  { label: "ASSENCIERES (10220)", value: "10220" },
  { label: "AUBETERRE (10150)", value: "10150" },
  { label: "AULNAY (10240)", value: "10240" },
  { label: "AUXON (10130)", value: "10130" },
  { label: "VAL D AUZON (10220)", value: "10220" },
  { label: "VAL D AUZON (10220)", value: "10220" },
  { label: "VAL D AUZON (10220)", value: "10220" },
  { label: "AVANT LES MARCILLY (10400)", value: "10400" },
  { label: "AVANT LES RAMERUPT (10240)", value: "10240" },
  { label: "AVIREY LINGEY (10340)", value: "10340" },
  { label: "AVON LA PEZE (10290)", value: "10290" },
  { label: "AVREUIL (10130)", value: "10130" },
  { label: "BAGNEUX LA FOSSE (10340)", value: "10340" },
  { label: "BAILLY LE FRANC (10330)", value: "10330" },
  { label: "BALIGNICOURT (10330)", value: "10330" },
  { label: "BALNOT LA GRANGE (10210)", value: "10210" },
  { label: "BALNOT SUR LAIGNES (10110)", value: "10110" },
  { label: "BARBEREY ST SULPICE (10600)", value: "10600" },
  { label: "BARBUISE (10400)", value: "10400" },
  { label: "BARBUISE (10400)", value: "10400" },
  { label: "BAROVILLE (10200)", value: "10200" },
  { label: "BAR SUR AUBE (10200)", value: "10200" },
  { label: "BAR SUR SEINE (10110)", value: "10110" },
  { label: "BAYEL (10310)", value: "10310" },
  { label: "BERCENAY EN OTHE (10190)", value: "10190" },
  { label: "BERCENAY LE HAYER (10290)", value: "10290" },
  { label: "BERGERES (10200)", value: "10200" },
  { label: "BERNON (10130)", value: "10130" },
  { label: "BERTIGNOLLES (10110)", value: "10110" },
  { label: "BERULLE (10160)", value: "10160" },
  { label: "BESSY (10170)", value: "10170" },
  { label: "BETIGNICOURT (10500)", value: "10500" },
  { label: "BEUREY (10140)", value: "10140" },
  { label: "BLAINCOURT SUR AUBE (10500)", value: "10500" },
  { label: "BLIGNICOURT (10500)", value: "10500" },
  { label: "BLIGNY (10200)", value: "10200" },
  { label: "LES BORDES AUMONT (10800)", value: "10800" },
  { label: "BOSSANCOURT (10140)", value: "10140" },
  { label: "BOUILLY (10320)", value: "10320" },
  { label: "BOULAGES (10380)", value: "10380" },
  { label: "BOURANTON (10270)", value: "10270" },
  { label: "BOURDENAY (10290)", value: "10290" },
  { label: "BOURGUIGNONS (10110)", value: "10110" },
  { label: "BOUY LUXEMBOURG (10220)", value: "10220" },
  { label: "BOUY SUR ORVIN (10400)", value: "10400" },
  { label: "BRAGELOGNE BEAUVOIR (10340)", value: "10340" },
  { label: "BRAGELOGNE BEAUVOIR (10340)", value: "10340" },
  { label: "BRAUX (10500)", value: "10500" },
  { label: "BREVIANDES (10450)", value: "10450" },
  { label: "BREVIANDES (10450)", value: "10450" },
  { label: "BREVONNES (10220)", value: "10220" },
  { label: "BRIEL SUR BARSE (10140)", value: "10140" },
  { label: "BRIENNE LA VIEILLE (10500)", value: "10500" },
  { label: "BRIENNE LE CHATEAU (10500)", value: "10500" },
  { label: "BRILLECOURT (10240)", value: "10240" },
  { label: "BUCEY EN OTHE (10190)", value: "10190" },
  { label: "BUCHERES (10800)", value: "10800" },
  { label: "BUCHERES (10800)", value: "10800" },
  { label: "BUXEUIL (10110)", value: "10110" },
  { label: "BUXIERES SUR ARCE (10110)", value: "10110" },
  { label: "CELLES SUR OURCE (10110)", value: "10110" },
  { label: "CHACENAY (10110)", value: "10110" },
  { label: "LA CHAISE (10500)", value: "10500" },
  { label: "CHALETTE SUR VOIRE (10500)", value: "10500" },
  { label: "CHAMOY (10130)", value: "10130" },
  { label: "CHAMPFLEURY (10700)", value: "10700" },
  { label: "CHAMPIGNOL LEZ MONDEVILLE (10200)", value: "10200" },
  { label: "CHAMPIGNY SUR AUBE (10700)", value: "10700" },
  { label: "CHAMP SUR BARSE (10140)", value: "10140" },
  { label: "CHANNES (10340)", value: "10340" },
  { label: "CHAOURCE (10210)", value: "10210" },
  { label: "LA CHAPELLE ST LUC (10600)", value: "10600" },
  { label: "CHAPELLE VALLON (10700)", value: "10700" },
  { label: "CHAPPES (10260)", value: "10260" },
  { label: "CHARMONT SOUS BARBUISE (10150)", value: "10150" },
  { label: "CHARMONT SOUS BARBUISE (10150)", value: "10150" },
  { label: "CHARMOY (10290)", value: "10290" },
  { label: "CHARNY LE BACHOT (10380)", value: "10380" },
  { label: "CHASEREY (10210)", value: "10210" },
  { label: "CHATRES (10510)", value: "10510" },
  { label: "CHAUCHIGNY (10170)", value: "10170" },
  { label: "CHAUDREY (10240)", value: "10240" },
  { label: "CHAUFFOUR LES BAILLY (10110)", value: "10110" },
  { label: "CHAUMESNIL (10500)", value: "10500" },
  { label: "CHAVANGES (10330)", value: "10330" },
  { label: "LE CHENE (10700)", value: "10700" },
  { label: "CHENNEGY (10190)", value: "10190" },
  { label: "CHERVEY (10110)", value: "10110" },
  { label: "CHESLEY (10210)", value: "10210" },
  { label: "CHESSY LES PRES (10130)", value: "10130" },
  { label: "CLEREY (10390)", value: "10390" },
  { label: "COCLOIS (10240)", value: "10240" },
  { label: "COLOMBE LA FOSSE (10200)", value: "10200" },
  { label: "COLOMBE LE SEC (10200)", value: "10200" },
  { label: "CORMOST (10800)", value: "10800" },
  { label: "COURCELLES SUR VOIRE (10500)", value: "10500" },
  { label: "COURCEROY (10400)", value: "10400" },
  { label: "COURSAN EN OTHE (10130)", value: "10130" },
  { label: "COURTAOULT (10130)", value: "10130" },
  { label: "COURTENOT (10260)", value: "10260" },
  { label: "COURTERANGES (10270)", value: "10270" },
  { label: "COURTERON (10250)", value: "10250" },
  { label: "COUSSEGREY (10210)", value: "10210" },
  { label: "COUVIGNON (10200)", value: "10200" },
  { label: "COUVIGNON (10200)", value: "10200" },
  { label: "CRANCEY (10100)", value: "10100" },
  { label: "CRENEY PRES TROYES (10150)", value: "10150" },
  { label: "CRENEY PRES TROYES (10150)", value: "10150" },
  { label: "CRESANTIGNES (10320)", value: "10320" },
  { label: "CRESPY LE NEUF (10500)", value: "10500" },
  { label: "LES CROUTES (10130)", value: "10130" },
  { label: "CUNFIN (10360)", value: "10360" },
  { label: "CUSSANGY (10210)", value: "10210" },
  { label: "DAMPIERRE (10240)", value: "10240" },
  { label: "DAVREY (10130)", value: "10130" },
  { label: "DIENVILLE (10500)", value: "10500" },
  { label: "DIERREY ST JULIEN (10190)", value: "10190" },
  { label: "DIERREY ST PIERRE (10190)", value: "10190" },
  { label: "DOLANCOURT (10200)", value: "10200" },
  { label: "DOMMARTIN LE COQ (10240)", value: "10240" },
  { label: "DONNEMENT (10330)", value: "10330" },
  { label: "DOSCHES (10220)", value: "10220" },
  { label: "DOSNON (10700)", value: "10700" },
  { label: "DROUPT ST BASLE (10170)", value: "10170" },
  { label: "DROUPT STE MARIE (10170)", value: "10170" },
  { label: "EAUX PUISEAUX (10130)", value: "10130" },
  { label: "ECHEMINES (10350)", value: "10350" },
  { label: "ECLANCE (10200)", value: "10200" },
  { label: "EGUILLY SOUS BOIS (10110)", value: "10110" },
  { label: "ENGENTE (10200)", value: "10200" },
  { label: "EPAGNE (10500)", value: "10500" },
  { label: "EPOTHEMONT (10500)", value: "10500" },
  { label: "ERVY LE CHATEL (10130)", value: "10130" },
  { label: "ESSOYES (10360)", value: "10360" },
  { label: "ESTISSAC (10190)", value: "10190" },
  { label: "ESTISSAC (10190)", value: "10190" },
  { label: "ETOURVY (10210)", value: "10210" },
  { label: "ETRELLES SUR AUBE (10170)", value: "10170" },
  { label: "FAUX VILLECERF (10290)", value: "10290" },
  { label: "FAY LES MARCILLY (10290)", value: "10290" },
  { label: "FAYS LA CHAPELLE (10320)", value: "10320" },
  { label: "FERREUX QUINCEY (10400)", value: "10400" },
  { label: "FERREUX QUINCEY (10400)", value: "10400" },
  { label: "FEUGES (10150)", value: "10150" },
  { label: "FONTAINE (10200)", value: "10200" },
  { label: "FONTAINE LES GRES (10280)", value: "10280" },
  { label: "FONTAINE MACON (10400)", value: "10400" },
  { label: "FONTENAY DE BOSSERY (10400)", value: "10400" },
  { label: "FONTETTE (10360)", value: "10360" },
  { label: "FONTVANNES (10190)", value: "10190" },
  { label: "LA FOSSE CORDUAN (10100)", value: "10100" },
  { label: "FOUCHERES (10260)", value: "10260" },
  { label: "FRALIGNES (10110)", value: "10110" },
  { label: "FRAVAUX (10200)", value: "10200" },
  { label: "FRESNAY (10200)", value: "10200" },
  { label: "FRESNOY LE CHATEAU (10270)", value: "10270" },
  { label: "FULIGNY (10200)", value: "10200" },
  { label: "GELANNES (10100)", value: "10100" },
  { label: "GERAUDOT (10220)", value: "10220" },
  { label: "LES GRANDES CHAPELLES (10170)", value: "10170" },
  { label: "GRANDVILLE (10700)", value: "10700" },
  { label: "LES GRANGES (10210)", value: "10210" },
  { label: "GUMERY (10400)", value: "10400" },
  { label: "GYE SUR SEINE (10250)", value: "10250" },
  { label: "HAMPIGNY (10500)", value: "10500" },
  { label: "HERBISSE (10700)", value: "10700" },
  { label: "ISLE AUMONT (10800)", value: "10800" },
  { label: "ISLE AUBIGNY (10240)", value: "10240" },
  { label: "JASSEINES (10330)", value: "10330" },
  { label: "JAUCOURT (10200)", value: "10200" },
  { label: "JAVERNANT (10320)", value: "10320" },
  { label: "JESSAINS (10140)", value: "10140" },
  { label: "JEUGNY (10320)", value: "10320" },
  { label: "JONCREUIL (10330)", value: "10330" },
  { label: "JULLY SUR SARCE (10260)", value: "10260" },
  { label: "JUVANCOURT (10310)", value: "10310" },
  { label: "JUVANZE (10140)", value: "10140" },
  { label: "JUZANVIGNY (10500)", value: "10500" },
  { label: "LAGESSE (10210)", value: "10210" },
  { label: "LAINES AUX BOIS (10120)", value: "10120" },
  { label: "LANDREVILLE (10110)", value: "10110" },
  { label: "LANTAGES (10210)", value: "10210" },
  { label: "LASSICOURT (10500)", value: "10500" },
  { label: "LAUBRESSEL (10270)", value: "10270" },
  { label: "LAVAU (10150)", value: "10150" },
  { label: "LAVAU (10150)", value: "10150" },
  { label: "LENTILLES (10330)", value: "10330" },
  { label: "LESMONT (10500)", value: "10500" },
  { label: "LEVIGNY (10200)", value: "10200" },
  { label: "LHUITRE (10700)", value: "10700" },
  { label: "LIGNIERES (10130)", value: "10130" },
  { label: "LIGNOL LE CHATEAU (10200)", value: "10200" },
  { label: "LIREY (10320)", value: "10320" },
  { label: "LOCHES SUR OURCE (10110)", value: "10110" },
  { label: "LA LOGE AUX CHEVRES (10140)", value: "10140" },
  { label: "LA LOGE POMBLIN (10210)", value: "10210" },
  { label: "LES LOGES MARGUERON (10210)", value: "10210" },
  { label: "LONGCHAMP SUR AUJON (10310)", value: "10310" },
  { label: "LONGEVILLE SUR MOGNE (10320)", value: "10320" },
  { label: "LONGPRE LE SEC (10140)", value: "10140" },
  { label: "LONGSOLS (10240)", value: "10240" },
  { label: "LONGUEVILLE SUR AUBE (10170)", value: "10170" },
  { label: "LA LOUPTIERE THENARD (10400)", value: "10400" },
  { label: "LUSIGNY SUR BARSE (10270)", value: "10270" },
  { label: "LUYERES (10150)", value: "10150" },
  { label: "MACEY (10300)", value: "10300" },
  { label: "MACEY (10300)", value: "10300" },
  { label: "MACHY (10320)", value: "10320" },
  { label: "MAGNANT (10110)", value: "10110" },
  { label: "MAGNICOURT (10240)", value: "10240" },
  { label: "MAGNY FOUCHARD (10140)", value: "10140" },
  { label: "MAILLY LE CAMP (10230)", value: "10230" },
  { label: "MAISON DES CHAMPS (10140)", value: "10140" },
  { label: "MAISONS LES CHAOURCE (10210)", value: "10210" },
  { label: "MAISONS LES SOULAINES (10200)", value: "10200" },
  { label: "MAIZIERES LA GRANDE PAROISSE (10510)", value: "10510" },
  { label: "MAIZIERES LA GRANDE PAROISSE (10510)", value: "10510" },
  { label: "MAIZIERES LES BRIENNE (10500)", value: "10500" },
  { label: "MARAYE EN OTHE (10160)", value: "10160" },
  { label: "MARCILLY LE HAYER (10290)", value: "10290" },
  { label: "MARIGNY LE CHATEL (10350)", value: "10350" },
  { label: "MARNAY SUR SEINE (10400)", value: "10400" },
  { label: "MAROLLES LES BAILLY (10110)", value: "10110" },
  { label: "MAROLLES SOUS LIGNIERES (10130)", value: "10130" },
  { label: "MATHAUX (10500)", value: "10500" },
  { label: "MAUPAS (10320)", value: "10320" },
  { label: "MERGEY (10600)", value: "10600" },
  { label: "LE MERIOT (10400)", value: "10400" },
  { label: "MERREY SUR ARCE (10110)", value: "10110" },
  { label: "MERY SUR SEINE (10170)", value: "10170" },
  { label: "MESGRIGNY (10170)", value: "10170" },
  { label: "MESNIL LA COMTESSE (10700)", value: "10700" },
  { label: "MESNIL LETTRE (10240)", value: "10240" },
  { label: "MESNIL ST LOUP (10190)", value: "10190" },
  { label: "MESNIL ST PERE (10140)", value: "10140" },
  { label: "MESNIL SELLIERES (10220)", value: "10220" },
  { label: "MESSON (10190)", value: "10190" },
  { label: "METZ ROBERT (10210)", value: "10210" },
  { label: "MEURVILLE (10200)", value: "10200" },
  { label: "MOLINS SUR AUBE (10500)", value: "10500" },
  { label: "MONTAULIN (10270)", value: "10270" },
  { label: "MONTAULIN (10270)", value: "10270" },
  { label: "MONTCEAUX LES VAUDES (10260)", value: "10260" },
  { label: "MONTFEY (10130)", value: "10130" },
  { label: "MONTGUEUX (10300)", value: "10300" },
  { label: "MONTIERAMEY (10270)", value: "10270" },
  { label: "MONTIER EN L ISLE (10200)", value: "10200" },
  { label: "MONTIGNY LES MONTS (10130)", value: "10130" },
  { label: "MONTMARTIN LE HAUT (10140)", value: "10140" },
  { label: "MONTMORENCY BEAUFORT (10330)", value: "10330" },
  { label: "MONTPOTHIER (10400)", value: "10400" },
  { label: "MONTREUIL SUR BARSE (10270)", value: "10270" },
  { label: "MONTSUZAIN (10150)", value: "10150" },
  { label: "MOREMBERT (10240)", value: "10240" },
  { label: "MORVILLIERS (10500)", value: "10500" },
  { label: "LA MOTTE TILLY (10400)", value: "10400" },
  { label: "MOUSSEY (10800)", value: "10800" },
  { label: "MUSSY SUR SEINE (10250)", value: "10250" },
  { label: "NEUVILLE SUR SEINE (10250)", value: "10250" },
  { label: "NEUVILLE SUR VANNE (10190)", value: "10190" },
  { label: "NOE LES MALLETS (10360)", value: "10360" },
  { label: "LES NOES PRES TROYES (10420)", value: "10420" },
  { label: "NOGENT EN OTHE (10160)", value: "10160" },
  { label: "NOGENT SUR AUBE (10240)", value: "10240" },
  { label: "NOGENT SUR SEINE (10400)", value: "10400" },
  { label: "NOZAY (10700)", value: "10700" },
  { label: "ONJON (10220)", value: "10220" },
  { label: "ORIGNY LE SEC (10510)", value: "10510" },
  { label: "ORMES (10700)", value: "10700" },
  { label: "ORTILLON (10700)", value: "10700" },
  { label: "ORVILLIERS ST JULIEN (10170)", value: "10170" },
  { label: "OSSEY LES TROIS MAISONS (10100)", value: "10100" },
  { label: "PAISY COSDON (10160)", value: "10160" },
  { label: "PARGUES (10210)", value: "10210" },
  { label: "PARS LES CHAVANGES (10330)", value: "10330" },
  { label: "PARS LES ROMILLY (10100)", value: "10100" },
  { label: "LE PAVILLON STE JULIE (10350)", value: "10350" },
  { label: "PAYNS (10600)", value: "10600" },
  { label: "PEL ET DER (10500)", value: "10500" },
  { label: "PERIGNY LA ROSE (10400)", value: "10400" },
  { label: "PERTHES LES BRIENNE (10500)", value: "10500" },
  { label: "PETIT MESNIL (10500)", value: "10500" },
  { label: "PINEY (10220)", value: "10220" },
  { label: "PLAINES ST LANGE (10250)", value: "10250" },
  { label: "PLANCY L ABBAYE (10380)", value: "10380" },
  { label: "PLANCY L ABBAYE (10380)", value: "10380" },
  { label: "PLANTY (10160)", value: "10160" },
  { label: "PLESSIS BARBUISE (10400)", value: "10400" },
  { label: "POIVRES (10700)", value: "10700" },
  { label: "POLIGNY (10110)", value: "10110" },
  { label: "POLISOT (10110)", value: "10110" },
  { label: "POLISY (10110)", value: "10110" },
  { label: "PONT STE MARIE (10150)", value: "10150" },
  { label: "PONT SUR SEINE (10400)", value: "10400" },
  { label: "POUAN LES VALLEES (10700)", value: "10700" },
  { label: "POUGY (10240)", value: "10240" },
  { label: "POUY SUR VANNES (10290)", value: "10290" },
  { label: "PRASLIN (10210)", value: "10210" },
  { label: "PRECY NOTRE DAME (10500)", value: "10500" },
  { label: "PRECY ST MARTIN (10500)", value: "10500" },
  { label: "PREMIERFAIT (10170)", value: "10170" },
  { label: "PROVERVILLE (10200)", value: "10200" },
  { label: "PRUGNY (10190)", value: "10190" },
  { label: "PRUNAY BELLEVILLE (10350)", value: "10350" },
  { label: "PRUSY (10210)", value: "10210" },
  { label: "PUITS ET NUISEMENT (10140)", value: "10140" },
  { label: "RACINES (10130)", value: "10130" },
  { label: "RADONVILLIERS (10500)", value: "10500" },
  { label: "RAMERUPT (10240)", value: "10240" },
  { label: "RAMERUPT (10240)", value: "10240" },
  { label: "RANCES (10500)", value: "10500" },
  { label: "RHEGES (10170)", value: "10170" },
  { label: "LES RICEYS (10340)", value: "10340" },
  { label: "RIGNY LA NONNEUSE (10290)", value: "10290" },
  { label: "RIGNY LE FERRON (10160)", value: "10160" },
  { label: "RILLY STE SYRE (10280)", value: "10280" },
  { label: "LA RIVIERE DE CORPS (10440)", value: "10440" },
  { label: "ROMILLY SUR SEINE (10100)", value: "10100" },
  { label: "RONCENAY (10320)", value: "10320" },
  { label: "ROSIERES PRES TROYES (10430)", value: "10430" },
  { label: "ROSIERES PRES TROYES (10430)", value: "10430" },
  { label: "ROSNAY L HOPITAL (10500)", value: "10500" },
  { label: "LA ROTHIERE (10500)", value: "10500" },
  { label: "ROUILLY SACEY (10220)", value: "10220" },
  { label: "ROUILLY ST LOUP (10800)", value: "10800" },
  { label: "ROUILLY ST LOUP (10800)", value: "10800" },
  { label: "ROUVRES LES VIGNES (10200)", value: "10200" },
  { label: "RUMILLY LES VAUDES (10260)", value: "10260" },
  { label: "RUVIGNY (10410)", value: "10410" },
  { label: "ST ANDRE LES VERGERS (10120)", value: "10120" },
  { label: "ST AUBIN (10400)", value: "10400" },
  { label: "ST BENOIST SUR VANNE (10160)", value: "10160" },
  { label: "ST BENOIT SUR SEINE (10180)", value: "10180" },
  { label: "ST CHRISTOPHE DODINICOURT (10500)", value: "10500" },
  { label: "ST ETIENNE SOUS BARBUISE (10700)", value: "10700" },
  { label: "ST FLAVY (10350)", value: "10350" },
  { label: "ST GERMAIN (10120)", value: "10120" },
  { label: "ST GERMAIN (10120)", value: "10120" },
  { label: "ST GERMAIN (10120)", value: "10120" },
  { label: "ST HILAIRE SOUS ROMILLY (10100)", value: "10100" },
  { label: "ST JEAN DE BONNEVAL (10320)", value: "10320" },
  { label: "ST JULIEN LES VILLAS (10800)", value: "10800" },
  { label: "ST LEGER PRES TROYES (10800)", value: "10800" },
  { label: "ST LEGER SOUS BRIENNE (10500)", value: "10500" },
  { label: "ST LEGER SOUS MARGERIE (10330)", value: "10330" },
  { label: "ST LOUP DE BUFFIGNY (10100)", value: "10100" },
  { label: "ST LUPIEN (10350)", value: "10350" },
  { label: "ST LYE (10180)", value: "10180" },
  { label: "ST LYE (10180)", value: "10180" },
  { label: "ST MARDS EN OTHE (10160)", value: "10160" },
  { label: "ST MARTIN DE BOSSENAY (10100)", value: "10100" },
  { label: "STE MAURE (10150)", value: "10150" },
  { label: "STE MAURE (10150)", value: "10150" },
  { label: "ST MESMIN (10280)", value: "10280" },
  { label: "ST NABORD SUR AUBE (10700)", value: "10700" },
  { label: "ST NICOLAS LA CHAPELLE (10400)", value: "10400" },
  { label: "ST OULPH (10170)", value: "10170" },
  { label: "ST PARRES AUX TERTRES (10410)", value: "10410" },
  { label: "ST PARRES LES VAUDES (10260)", value: "10260" },
  { label: "ST PHAL (10130)", value: "10130" },
  { label: "ST POUANGE (10120)", value: "10120" },
  { label: "ST REMY SOUS BARBUISE (10700)", value: "10700" },
  { label: "STE SAVINE (10300)", value: "10300" },
  { label: "ST THIBAULT (10800)", value: "10800" },
  { label: "ST USAGE (10360)", value: "10360" },
  { label: "SALON (10700)", value: "10700" },
  { label: "SAULCY (10200)", value: "10200" },
  { label: "LA SAULSOTTE (10400)", value: "10400" },
  { label: "SAVIERES (10600)", value: "10600" },
  { label: "SEMOINE (10700)", value: "10700" },
  { label: "SOLIGNY LES ETANGS (10400)", value: "10400" },
  { label: "SOMMEVAL (10320)", value: "10320" },
  { label: "SOULAINES DHUYS (10200)", value: "10200" },
  { label: "SOULIGNY (10320)", value: "10320" },
  { label: "SPOY (10200)", value: "10200" },
  { label: "THENNELIERES (10410)", value: "10410" },
  { label: "THIEFFRAIN (10140)", value: "10140" },
  { label: "THIL (10200)", value: "10200" },
  { label: "THORS (10200)", value: "10200" },
  { label: "TORCY LE GRAND (10700)", value: "10700" },
  { label: "TORCY LE PETIT (10700)", value: "10700" },
  { label: "TORVILLIERS (10440)", value: "10440" },
  { label: "TRAINEL (10400)", value: "10400" },
  { label: "TRANCAULT (10290)", value: "10290" },
  { label: "TRANNES (10140)", value: "10140" },
  { label: "TROUANS (10700)", value: "10700" },
  { label: "TROUANS (10700)", value: "10700" },
  { label: "TROYES (10000)", value: "10000" },
  { label: "TURGY (10210)", value: "10210" },
  { label: "UNIENVILLE (10140)", value: "10140" },
  { label: "URVILLE (10200)", value: "10200" },
  { label: "VAILLY (10150)", value: "10150" },
  { label: "VALLANT ST GEORGES (10170)", value: "10170" },
  { label: "VALLENTIGNY (10500)", value: "10500" },
  { label: "VALLIERES (10210)", value: "10210" },
  { label: "VANLAY (10210)", value: "10210" },
  { label: "VAUCHASSIS (10190)", value: "10190" },
  { label: "VAUCHONVILLIERS (10140)", value: "10140" },
  { label: "VAUCOGNE (10240)", value: "10240" },
  { label: "VAUDES (10260)", value: "10260" },
  { label: "VAUPOISSON (10700)", value: "10700" },
  { label: "VENDEUVRE SUR BARSE (10140)", value: "10140" },
  { label: "LA VENDUE MIGNOT (10800)", value: "10800" },
  { label: "VERNONVILLIERS (10200)", value: "10200" },
  { label: "VERPILLIERES SUR OURCE (10360)", value: "10360" },
  { label: "VERRICOURT (10240)", value: "10240" },
  { label: "VERRIERES (10390)", value: "10390" },
  { label: "VIAPRES LE PETIT (10380)", value: "10380" },
  { label: "VILLACERF (10600)", value: "10600" },
  { label: "VILLADIN (10290)", value: "10290" },
  { label: "LA VILLE AUX BOIS (10500)", value: "10500" },
  { label: "VILLECHETIF (10410)", value: "10410" },
  { label: "VILLECHETIF (10410)", value: "10410" },
  { label: "VILLELOUP (10350)", value: "10350" },
  { label: "VILLEMEREUIL (10800)", value: "10800" },
  { label: "VILLEMOIRON EN OTHE (10160)", value: "10160" },
  { label: "VILLEMORIEN (10110)", value: "10110" },
  { label: "VILLEMOYENNE (10260)", value: "10260" },
  { label: "VILLENAUXE LA GRANDE (10370)", value: "10370" },
  { label: "VILLENAUXE LA GRANDE (10370)", value: "10370" },
  { label: "LA VILLENEUVE AU CHATELOT (10400)", value: "10400" },
  { label: "VILLENEUVE AU CHEMIN (10130)", value: "10130" },
  { label: "LA VILLENEUVE AU CHENE (10140)", value: "10140" },
  { label: "VILLERET (10330)", value: "10330" },
  { label: "VILLERY (10320)", value: "10320" },
  { label: "VILLE SOUS LA FERTE (10310)", value: "10310" },
  { label: "VILLE SOUS LA FERTE (10310)", value: "10310" },
  { label: "VILLE SUR ARCE (10110)", value: "10110" },
  { label: "VILLE SUR TERRE (10200)", value: "10200" },
  { label: "VILLETTE SUR AUBE (10700)", value: "10700" },
  { label: "VILLIERS HERBISSE (10700)", value: "10700" },
  { label: "VILLIERS LE BOIS (10210)", value: "10210" },
  { label: "VILLIERS SOUS PRASLIN (10210)", value: "10210" },
  { label: "VILLY EN TRODES (10140)", value: "10140" },
  { label: "VILLY LE BOIS (10800)", value: "10800" },
  { label: "VILLY LE MARECHAL (10800)", value: "10800" },
  { label: "VINETS (10700)", value: "10700" },
  { label: "VIREY SOUS BAR (10260)", value: "10260" },
  { label: "VITRY LE CROISE (10110)", value: "10110" },
  { label: "VIVIERS SUR ARTAUT (10110)", value: "10110" },
  { label: "VOIGNY (10200)", value: "10200" },
  { label: "VOSNON (10130)", value: "10130" },
  { label: "VOUE (10150)", value: "10150" },
  { label: "VOUGREY (10210)", value: "10210" },
  { label: "VULAINES (10160)", value: "10160" },
  { label: "YEVRES LE PETIT (10500)", value: "10500" },
  { label: "AIGUES VIVES (11800)", value: "11800" },
  { label: "AIROUX (11320)", value: "11320" },
  { label: "AJAC (11300)", value: "11300" },
  { label: "ALAIGNE (11240)", value: "11240" },
  { label: "ALAIRAC (11290)", value: "11290" },
  { label: "ALBAS (11360)", value: "11360" },
  { label: "ALBIERES (11330)", value: "11330" },
  { label: "ALET LES BAINS (11580)", value: "11580" },
  { label: "ALZONNE (11170)", value: "11170" },
  { label: "ANTUGNAC (11190)", value: "11190" },
  { label: "ARAGON (11600)", value: "11600" },
  { label: "ARGELIERS (11120)", value: "11120" },
  { label: "ARGENS MINERVOIS (11200)", value: "11200" },
  { label: "ARMISSAN (11110)", value: "11110" },
  { label: "ARQUES (11190)", value: "11190" },
  { label: "ARQUETTES EN VAL (11220)", value: "11220" },
  { label: "ARTIGUES (11140)", value: "11140" },
  { label: "ARZENS (11290)", value: "11290" },
  { label: "AUNAT (11140)", value: "11140" },
  { label: "AURIAC (11330)", value: "11330" },
  { label: "AXAT (11140)", value: "11140" },
  { label: "AZILLE (11700)", value: "11700" },
  { label: "BADENS (11800)", value: "11800" },
  { label: "BAGES (11100)", value: "11100" },
  { label: "BAGNOLES (11600)", value: "11600" },
  { label: "BARAIGNE (11410)", value: "11410" },
  { label: "BARBAIRA (11800)", value: "11800" },
  { label: "BELCAIRE (11340)", value: "11340" },
  { label: "BELCASTEL ET BUC (11580)", value: "11580" },
  { label: "BELFLOU (11410)", value: "11410" },
  { label: "BELFORT SUR REBENTY (11140)", value: "11140" },
  { label: "BELLEGARDE DU RAZES (11240)", value: "11240" },
  { label: "BELPECH (11420)", value: "11420" },
  { label: "BELVEZE DU RAZES (11240)", value: "11240" },
  { label: "BELVIANES ET CAVIRAC (11500)", value: "11500" },
  { label: "BELVIS (11340)", value: "11340" },
  { label: "BERRIAC (11000)", value: "11000" },
  { label: "BESSEDE DE SAULT (11140)", value: "11140" },
  { label: "LA BEZOLE (11300)", value: "11300" },
  { label: "BIZANET (11200)", value: "11200" },
  { label: "BIZE MINERVOIS (11120)", value: "11120" },
  { label: "BLOMAC (11700)", value: "11700" },
  { label: "BOUILHONNAC (11800)", value: "11800" },
  { label: "BOUISSE (11330)", value: "11330" },
  { label: "BOURIEGE (11300)", value: "11300" },
  { label: "BOURIGEOLE (11300)", value: "11300" },
  { label: "LE BOUSQUET (11140)", value: "11140" },
  { label: "BOUTENAC (11200)", value: "11200" },
  { label: "BRAM (11150)", value: "11150" },
  { label: "BREZILHAC (11270)", value: "11270" },
  { label: "BROUSSES ET VILLARET (11390)", value: "11390" },
  { label: "BRUGAIROLLES (11300)", value: "11300" },
  { label: "LES BRUNELS (11400)", value: "11400" },
  { label: "BUGARACH (11190)", value: "11190" },
  { label: "CABRESPINE (11160)", value: "11160" },
  { label: "CAHUZAC (11420)", value: "11420" },
  { label: "CAILHAU (11240)", value: "11240" },
  { label: "CAILHAVEL (11240)", value: "11240" },
  { label: "CAILLA (11140)", value: "11140" },
  { label: "CAMBIEURE (11240)", value: "11240" },
  { label: "CAMPAGNA DE SAULT (11140)", value: "11140" },
  { label: "CAMPAGNE SUR AUDE (11260)", value: "11260" },
  { label: "CAMPLONG D AUDE (11200)", value: "11200" },
  { label: "CAMPS SUR L AGLY (11190)", value: "11190" },
  { label: "CAMURAC (11340)", value: "11340" },
  { label: "CANET (11200)", value: "11200" },
  { label: "CAPENDU (11700)", value: "11700" },
  { label: "CARCASSONNE (11000)", value: "11000" },
  { label: "CARCASSONNE (11000)", value: "11000" },
  { label: "CARCASSONNE (11000)", value: "11000" },
  { label: "CARCASSONNE (11000)", value: "11000" },
  { label: "CARCASSONNE (11000)", value: "11000" },
  { label: "CARCASSONNE (11000)", value: "11000" },
  { label: "CARLIPA (11170)", value: "11170" },
  { label: "CASCASTEL DES CORBIERES (11360)", value: "11360" },
  { label: "LA CASSAIGNE (11270)", value: "11270" },
  { label: "CASSAIGNES (11190)", value: "11190" },
  { label: "LES CASSES (11320)", value: "11320" },
  { label: "CASTANS (11160)", value: "11160" },
  { label: "CASTELNAUDARY (11400)", value: "11400" },
  { label: "CASTELNAU D AUDE (11700)", value: "11700" },
  { label: "CASTELRENG (11300)", value: "11300" },
  { label: "CAUDEBRONDE (11390)", value: "11390" },
  { label: "VAL DE LAMBRONNE (11230)", value: "11230" },
  { label: "VAL DE LAMBRONNE (11230)", value: "11230" },
  { label: "CAUNES MINERVOIS (11160)", value: "11160" },
  { label: "CAUNETTE SUR LAUQUET (11250)", value: "11250" },
  { label: "CAUNETTES EN VAL (11220)", value: "11220" },
  { label: "CAUX ET SAUZENS (11170)", value: "11170" },
  { label: "CAVANAC (11570)", value: "11570" },
  { label: "CAVES (11510)", value: "11510" },
  { label: "CAZALRENOUX (11270)", value: "11270" },
  { label: "CAZILHAC (11570)", value: "11570" },
  { label: "CENNE MONESTIES (11170)", value: "11170" },
  { label: "CEPIE (11300)", value: "11300" },
  { label: "CHALABRE (11230)", value: "11230" },
  { label: "CITOU (11160)", value: "11160" },
  { label: "LE CLAT (11140)", value: "11140" },
  { label: "CLERMONT SUR LAUQUET (11250)", value: "11250" },
  { label: "COMIGNE (11700)", value: "11700" },
  { label: "COMUS (11340)", value: "11340" },
  { label: "CONILHAC CORBIERES (11200)", value: "11200" },
  { label: "CONQUES SUR ORBIEL (11600)", value: "11600" },
  { label: "CORBIERES (11230)", value: "11230" },
  { label: "COUDONS (11500)", value: "11500" },
  { label: "COUFFOULENS (11250)", value: "11250" },
  { label: "COUIZA (11190)", value: "11190" },
  { label: "COUNOZOULS (11140)", value: "11140" },
  { label: "COURNANEL (11300)", value: "11300" },
  { label: "COURSAN (11110)", value: "11110" },
  { label: "COURTAULY (11230)", value: "11230" },
  { label: "LA COURTETE (11240)", value: "11240" },
  { label: "COUSTAUSSA (11190)", value: "11190" },
  { label: "COUSTOUGE (11220)", value: "11220" },
  { label: "CRUSCADES (11200)", value: "11200" },
  { label: "CUBIERES SUR CINOBLE (11190)", value: "11190" },
  { label: "CUCUGNAN (11350)", value: "11350" },
  { label: "CUMIES (11410)", value: "11410" },
  { label: "CUXAC CABARDES (11390)", value: "11390" },
  { label: "CUXAC D AUDE (11590)", value: "11590" },
  { label: "DAVEJEAN (11330)", value: "11330" },
  { label: "DERNACUEILLETTE (11330)", value: "11330" },
  { label: "LA DIGNE D AMONT (11300)", value: "11300" },
  { label: "LA DIGNE D AVAL (11300)", value: "11300" },
  { label: "DONAZAC (11240)", value: "11240" },
  { label: "DOUZENS (11700)", value: "11700" },
  { label: "DUILHAC SOUS PEYREPERTUSE (11350)", value: "11350" },
  { label: "DURBAN CORBIERES (11360)", value: "11360" },
  { label: "EMBRES ET CASTELMAURE (11360)", value: "11360" },
  { label: "ESCALES (11200)", value: "11200" },
  { label: "ESCOULOUBRE (11140)", value: "11140" },
  { label: "ESCUEILLENS ET ST JUST DE BELENGARD (11240)", value: "11240" },
  { label: "ESCUEILLENS ET ST JUST DE BELENGARD (11240)", value: "11240" },
  { label: "ESPERAZA (11260)", value: "11260" },
  { label: "ESPEZEL (11340)", value: "11340" },
  { label: "VAL DU FABY (11260)", value: "11260" },
  { label: "VAL DU FABY (11260)", value: "11260" },
  { label: "FABREZAN (11200)", value: "11200" },
  { label: "FAJAC EN VAL (11220)", value: "11220" },
  { label: "FAJAC LA RELENQUE (11410)", value: "11410" },
  { label: "LA FAJOLLE (11140)", value: "11140" },
  { label: "FANJEAUX (11270)", value: "11270" },
  { label: "FELINES TERMENES (11330)", value: "11330" },
  { label: "FENDEILLE (11400)", value: "11400" },
  { label: "FENOUILLET DU RAZES (11240)", value: "11240" },
  { label: "FERRALS LES CORBIERES (11200)", value: "11200" },
  { label: "FERRAN (11240)", value: "11240" },
  { label: "FESTES ET ST ANDRE (11300)", value: "11300" },
  { label: "FEUILLA (11510)", value: "11510" },
  { label: "FITOU (11510)", value: "11510" },
  { label: "FITOU (11510)", value: "11510" },
  { label: "FLEURY (11560)", value: "11560" },
  { label: "FLEURY (11560)", value: "11560" },
  { label: "FLOURE (11800)", value: "11800" },
  { label: "FONTANES DE SAULT (11140)", value: "11140" },
  { label: "FONTCOUVERTE (11700)", value: "11700" },
  { label: "FONTERS DU RAZES (11400)", value: "11400" },
  { label: "FONTIERS CABARDES (11390)", value: "11390" },
  { label: "FONTIES D AUDE (11800)", value: "11800" },
  { label: "FONTJONCOUSE (11360)", value: "11360" },
  { label: "LA FORCE (11270)", value: "11270" },
  { label: "FOURNES CABARDES (11600)", value: "11600" },
  { label: "FOURTOU (11190)", value: "11190" },
  { label: "FRAISSE CABARDES (11600)", value: "11600" },
  { label: "FRAISSE DES CORBIERES (11360)", value: "11360" },
  { label: "GAJA ET VILLEDIEU (11300)", value: "11300" },
  { label: "GAJA LA SELVE (11270)", value: "11270" },
  { label: "GALINAGUES (11140)", value: "11140" },
  { label: "GARDIE (11250)", value: "11250" },
  { label: "GENERVILLE (11270)", value: "11270" },
  { label: "GINCLA (11140)", value: "11140" },
  { label: "GINESTAS (11120)", value: "11120" },
  { label: "GINOLES (11500)", value: "11500" },
  { label: "GOURVIEILLE (11410)", value: "11410" },
  { label: "GRAMAZIE (11240)", value: "11240" },
  { label: "GRANES (11500)", value: "11500" },
  { label: "GREFFEIL (11250)", value: "11250" },
  { label: "GRUISSAN (11430)", value: "11430" },
  { label: "GRUISSAN (11430)", value: "11430" },
  { label: "HOMPS (11200)", value: "11200" },
  { label: "HOUNOUX (11240)", value: "11240" },
  { label: "LES ILHES (11380)", value: "11380" },
  { label: "ISSEL (11400)", value: "11400" },
  { label: "JONQUIERES (11220)", value: "11220" },
  { label: "JOUCOU (11140)", value: "11140" },
  { label: "LABASTIDE D ANJOU (11320)", value: "11320" },
  { label: "LABASTIDE EN VAL (11220)", value: "11220" },
  { label: "LABASTIDE ESPARBAIRENQUE (11380)", value: "11380" },
  { label: "LABECEDE LAURAGAIS (11400)", value: "11400" },
  { label: "LACOMBE (11310)", value: "11310" },
  { label: "LADERN SUR LAUQUET (11250)", value: "11250" },
  { label: "LAFAGE (11420)", value: "11420" },
  { label: "LAGRASSE (11220)", value: "11220" },
  { label: "LAIRIERE (11330)", value: "11330" },
  { label: "LANET (11330)", value: "11330" },
  { label: "LA PALME (11480)", value: "11480" },
  { label: "LAPRADE (11390)", value: "11390" },
  { label: "LA REDORTE (11700)", value: "11700" },
  { label: "LAROQUE DE FA (11330)", value: "11330" },
  { label: "LASBORDES (11400)", value: "11400" },
  { label: "LASSERRE DE PROUILLE (11270)", value: "11270" },
  { label: "LASTOURS (11600)", value: "11600" },
  { label: "LAURABUC (11400)", value: "11400" },
  { label: "LAURAC (11270)", value: "11270" },
  { label: "LAURAGUEL (11300)", value: "11300" },
  { label: "LAURE MINERVOIS (11800)", value: "11800" },
  { label: "LAVALETTE (11290)", value: "11290" },
  { label: "LESPINASSIERE (11160)", value: "11160" },
  { label: "LEUC (11250)", value: "11250" },
  { label: "LEUCATE (11370)", value: "11370" },
  { label: "LEUCATE (11370)", value: "11370" },
  { label: "LEUCATE (11370)", value: "11370" },
  { label: "LEZIGNAN CORBIERES (11200)", value: "11200" },
  { label: "LIGNAIROLLES (11240)", value: "11240" },
  { label: "LIMOUSIS (11600)", value: "11600" },
  { label: "LIMOUX (11300)", value: "11300" },
  { label: "LOUPIA (11300)", value: "11300" },
  { label: "LA LOUVIERE LAURAGAIS (11410)", value: "11410" },
  { label: "LUC SUR AUDE (11190)", value: "11190" },
  { label: "LUC SUR ORBIEU (11200)", value: "11200" },
  { label: "MAGRIE (11300)", value: "11300" },
  { label: "MAILHAC (11120)", value: "11120" },
  { label: "MAISONS (11330)", value: "11330" },
  { label: "MALRAS (11300)", value: "11300" },
  { label: "MALVES EN MINERVOIS (11600)", value: "11600" },
  { label: "MALVIES (11300)", value: "11300" },
  { label: "MARCORIGNAN (11120)", value: "11120" },
  { label: "MARQUEIN (11410)", value: "11410" },
  { label: "MARSA (11140)", value: "11140" },
  { label: "MARSEILLETTE (11800)", value: "11800" },
  { label: "LES MARTYS (11390)", value: "11390" },
  { label: "MAS CABARDES (11380)", value: "11380" },
  { label: "MAS DES COURS (11570)", value: "11570" },
  { label: "MASSAC (11330)", value: "11330" },
  { label: "MAS SAINTES PUELLES (11400)", value: "11400" },
  { label: "MAYREVILLE (11420)", value: "11420" },
  { label: "MAYRONNES (11220)", value: "11220" },
  { label: "MAZEROLLES DU RAZES (11240)", value: "11240" },
  { label: "MAZUBY (11140)", value: "11140" },
  { label: "MERIAL (11140)", value: "11140" },
  { label: "MEZERVILLE (11410)", value: "11410" },
  { label: "MIRAVAL CABARDES (11380)", value: "11380" },
  { label: "MIREPEISSET (11120)", value: "11120" },
  { label: "MIREVAL LAURAGAIS (11400)", value: "11400" },
  { label: "MISSEGRE (11580)", value: "11580" },
  { label: "MOLANDIER (11420)", value: "11420" },
  { label: "MOLLEVILLE (11410)", value: "11410" },
  { label: "MONTAURIOL (11410)", value: "11410" },
  { label: "MONTAZELS (11190)", value: "11190" },
  { label: "MONTBRUN DES CORBIERES (11700)", value: "11700" },
  { label: "MONTCLAR (11250)", value: "11250" },
  { label: "MONTFERRAND (11320)", value: "11320" },
  { label: "MONTFORT SUR BOULZANE (11140)", value: "11140" },
  { label: "MONTGAILLARD (11330)", value: "11330" },
  { label: "MONTGRADAIL (11240)", value: "11240" },
  { label: "MONTHAUT (11240)", value: "11240" },
  { label: "MONTIRAT (11800)", value: "11800" },
  { label: "MONTJARDIN (11230)", value: "11230" },
  { label: "MONTJOI (11330)", value: "11330" },
  { label: "VAL DE DAGNE (11220)", value: "11220" },
  { label: "VAL DE DAGNE (11220)", value: "11220" },
  { label: "MONTMAUR (11320)", value: "11320" },
  { label: "MONTOLIEU (11170)", value: "11170" },
  { label: "MONTREAL (11290)", value: "11290" },
  { label: "MONTREDON DES CORBIERES (11100)", value: "11100" },
  { label: "MONTSERET (11200)", value: "11200" },
  { label: "MONZE (11800)", value: "11800" },
  { label: "MOUSSAN (11120)", value: "11120" },
  { label: "MOUSSOULENS (11170)", value: "11170" },
  { label: "MOUTHOUMET (11330)", value: "11330" },
  { label: "MOUX (11700)", value: "11700" },
  { label: "NARBONNE (11100)", value: "11100" },
  { label: "NARBONNE (11100)", value: "11100" },
  { label: "NEBIAS (11500)", value: "11500" },
  { label: "NEVIAN (11200)", value: "11200" },
  { label: "NIORT DE SAULT (11140)", value: "11140" },
  { label: "PORT LA NOUVELLE (11210)", value: "11210" },
  { label: "ORNAISONS (11200)", value: "11200" },
  { label: "ORSANS (11270)", value: "11270" },
  { label: "OUVEILLAN (11590)", value: "11590" },
  { label: "PADERN (11350)", value: "11350" },
  { label: "PALAIRAC (11330)", value: "11330" },
  { label: "PALAJA (11570)", value: "11570" },
  { label: "PARAZA (11200)", value: "11200" },
  { label: "PAULIGNE (11300)", value: "11300" },
  { label: "PAYRA SUR L HERS (11410)", value: "11410" },
  { label: "PAZIOLS (11350)", value: "11350" },
  { label: "PECHARIC ET LE PY (11420)", value: "11420" },
  { label: "PECH LUNA (11420)", value: "11420" },
  { label: "PENNAUTIER (11610)", value: "11610" },
  { label: "PEPIEUX (11700)", value: "11700" },
  { label: "PEXIORA (11150)", value: "11150" },
  { label: "PEYREFITTE DU RAZES (11230)", value: "11230" },
  { label: "PEYREFITTE SUR L HERS (11420)", value: "11420" },
  { label: "PEYRENS (11400)", value: "11400" },
  { label: "PEYRIAC DE MER (11440)", value: "11440" },
  { label: "PEYRIAC MINERVOIS (11160)", value: "11160" },
  { label: "PEYROLLES (11190)", value: "11190" },
  { label: "PEZENS (11170)", value: "11170" },
  { label: "PIEUSSE (11300)", value: "11300" },
  { label: "PLAIGNE (11420)", value: "11420" },
  { label: "PLAVILLA (11270)", value: "11270" },
  { label: "LA POMAREDE (11400)", value: "11400" },
  { label: "POMAS (11250)", value: "11250" },
  { label: "POMY (11300)", value: "11300" },
  { label: "PORTEL DES CORBIERES (11490)", value: "11490" },
  { label: "POUZOLS MINERVOIS (11120)", value: "11120" },
  { label: "PRADELLES CABARDES (11380)", value: "11380" },
  { label: "PREIXAN (11250)", value: "11250" },
  { label: "PUGINIER (11400)", value: "11400" },
  { label: "PUICHERIC (11700)", value: "11700" },
  { label: "PUILAURENS (11140)", value: "11140" },
  { label: "PUILAURENS (11140)", value: "11140" },
  { label: "PUIVERT (11230)", value: "11230" },
  { label: "QUILLAN (11500)", value: "11500" },
  { label: "QUILLAN (11500)", value: "11500" },
  { label: "QUINTILLAN (11360)", value: "11360" },
  { label: "QUIRBAJOU (11500)", value: "11500" },
  { label: "RAISSAC D AUDE (11200)", value: "11200" },
  { label: "RAISSAC SUR LAMPY (11170)", value: "11170" },
  { label: "RENNES LE CHATEAU (11190)", value: "11190" },
  { label: "RENNES LES BAINS (11190)", value: "11190" },
  { label: "RIBAUTE (11220)", value: "11220" },
  { label: "RIBOUISSE (11270)", value: "11270" },
  { label: "RICAUD (11400)", value: "11400" },
  { label: "RIEUX EN VAL (11220)", value: "11220" },
  { label: "RIEUX MINERVOIS (11160)", value: "11160" },
  { label: "RIVEL (11230)", value: "11230" },
  { label: "RODOME (11140)", value: "11140" },
  { label: "ROQUECOURBE MINERVOIS (11700)", value: "11700" },
  { label: "ROQUEFERE (11380)", value: "11380" },
  { label: "ROQUEFEUIL (11340)", value: "11340" },
  { label: "ROQUEFORT DE SAULT (11140)", value: "11140" },
  { label: "ROQUEFORT DES CORBIERES (11540)", value: "11540" },
  { label: "ROQUETAILLADE ET CONILHAC (11190)", value: "11190" },
  { label: "ROQUETAILLADE ET CONILHAC (11300)", value: "11300" },
  { label: "ROUBIA (11200)", value: "11200" },
  { label: "ROUFFIAC D AUDE (11250)", value: "11250" },
  { label: "ROUFFIAC DES CORBIERES (11350)", value: "11350" },
  { label: "ROULLENS (11290)", value: "11290" },
  { label: "ROUTIER (11240)", value: "11240" },
  { label: "RUSTIQUES (11800)", value: "11800" },
  { label: "ST AMANS (11270)", value: "11270" },
  { label: "ST ANDRE DE ROQUELONGUE (11200)", value: "11200" },
  { label: "ST BENOIT (11230)", value: "11230" },
  { label: "STE CAMELLE (11410)", value: "11410" },
  { label: "STE COLOMBE SUR GUETTE (11140)", value: "11140" },
  { label: "STE COLOMBE SUR L HERS (11230)", value: "11230" },
  { label: "ST COUAT D AUDE (11700)", value: "11700" },
  { label: "ST COUAT DU RAZES (11300)", value: "11300" },
  { label: "ST DENIS (11310)", value: "11310" },
  { label: "STE EULALIE (11170)", value: "11170" },
  { label: "ST FERRIOL (11500)", value: "11500" },
  { label: "ST FRICHOUX (11800)", value: "11800" },
  { label: "ST GAUDERIC (11270)", value: "11270" },
  { label: "ST HILAIRE (11250)", value: "11250" },
  { label: "ST JEAN DE BARROU (11360)", value: "11360" },
  { label: "ST JEAN DE PARACOL (11260)", value: "11260" },
  { label: "ST JULIA DE BEC (11500)", value: "11500" },
  { label: "ST JULIEN DE BRIOLA (11270)", value: "11270" },
  { label: "ST JUST ET LE BEZU (11500)", value: "11500" },
  { label: "ST LAURENT DE LA CABRERISSE (11220)", value: "11220" },
  { label: "ST LOUIS ET PARAHOU (11500)", value: "11500" },
  { label: "ST MARCEL SUR AUDE (11120)", value: "11120" },
  { label: "ST MARTIN DES PUITS (11220)", value: "11220" },
  { label: "ST MARTIN DE VILLEREGLAN (11300)", value: "11300" },
  { label: "ST MARTIN LALANDE (11400)", value: "11400" },
  { label: "ST MARTIN LE VIEIL (11170)", value: "11170" },
  { label: "ST MARTIN LYS (11500)", value: "11500" },
  { label: "ST MICHEL DE LANES (11410)", value: "11410" },
  { label: "ST NAZAIRE D AUDE (11120)", value: "11120" },
  { label: "ST PAPOUL (11400)", value: "11400" },
  { label: "ST PAULET (11320)", value: "11320" },
  { label: "ST PIERRE DES CHAMPS (11220)", value: "11220" },
  { label: "ST POLYCARPE (11300)", value: "11300" },
  { label: "ST SERNIN (11420)", value: "11420" },
  { label: "STE VALIERE (11120)", value: "11120" },
  { label: "SAISSAC (11310)", value: "11310" },
  { label: "SALLELES CABARDES (11600)", value: "11600" },
  { label: "SALLELES D AUDE (11590)", value: "11590" },
  { label: "SALLES D AUDE (11110)", value: "11110" },
  { label: "SALLES SUR L HERS (11410)", value: "11410" },
  { label: "SALSIGNE (11600)", value: "11600" },
  { label: "SALVEZINES (11140)", value: "11140" },
  { label: "SALZA (11330)", value: "11330" },
  { label: "SEIGNALENS (11240)", value: "11240" },
  { label: "LA SERPENT (11190)", value: "11190" },
  { label: "SERRES (11190)", value: "11190" },
  { label: "SERVIES EN VAL (11220)", value: "11220" },
  { label: "SIGEAN (11130)", value: "11130" },
  { label: "SONNAC SUR L HERS (11230)", value: "11230" },
  { label: "SOUGRAIGNE (11190)", value: "11190" },
  { label: "SOUILHANELS (11400)", value: "11400" },
  { label: "SOUILHE (11400)", value: "11400" },
  { label: "SOULATGE (11330)", value: "11330" },
  { label: "SOUPEX (11320)", value: "11320" },
  { label: "TALAIRAN (11220)", value: "11220" },
  { label: "TAURIZE (11220)", value: "11220" },
  { label: "TERMES (11330)", value: "11330" },
  { label: "TERROLES (11580)", value: "11580" },
  { label: "THEZAN DES CORBIERES (11200)", value: "11200" },
  { label: "LA TOURETTE CABARDES (11380)", value: "11380" },
  { label: "TOURNISSAN (11220)", value: "11220" },
  { label: "TOUROUZELLE (11200)", value: "11200" },
  { label: "TOURREILLES (11300)", value: "11300" },
  { label: "TRASSANEL (11160)", value: "11160" },
  { label: "TRAUSSE (11160)", value: "11160" },
  { label: "TREBES (11800)", value: "11800" },
  { label: "TREILLES (11510)", value: "11510" },
  { label: "TREVILLE (11400)", value: "11400" },
  { label: "TREZIERS (11230)", value: "11230" },
  { label: "TUCHAN (11350)", value: "11350" },
  { label: "VALMIGERE (11580)", value: "11580" },
  { label: "VENTENAC CABARDES (11610)", value: "11610" },
  { label: "VENTENAC EN MINERVOIS (11120)", value: "11120" },
  { label: "VERAZA (11580)", value: "11580" },
  { label: "VERDUN EN LAURAGAIS (11400)", value: "11400" },
  { label: "VERZEILLE (11250)", value: "11250" },
  { label: "VIGNEVIEILLE (11330)", value: "11330" },
  { label: "VILLALIER (11600)", value: "11600" },
  { label: "VILLANIERE (11600)", value: "11600" },
  { label: "VILLARDEBELLE (11580)", value: "11580" },
  { label: "VILLARDONNEL (11600)", value: "11600" },
  { label: "VILLAR EN VAL (11220)", value: "11220" },
  { label: "VILLAR ST ANSELME (11250)", value: "11250" },
  { label: "VILLARZEL CABARDES (11600)", value: "11600" },
  { label: "VILLARZEL DU RAZES (11300)", value: "11300" },
  { label: "VILLASAVARY (11150)", value: "11150" },
  { label: "VILLAUTOU (11420)", value: "11420" },
  { label: "VILLEBAZY (11250)", value: "11250" },
  { label: "VILLEDAIGNE (11200)", value: "11200" },
  { label: "VILLEDUBERT (11800)", value: "11800" },
  { label: "VILLEFLOURE (11570)", value: "11570" },
  { label: "VILLEFORT (11230)", value: "11230" },
  { label: "VILLEGAILHENC (11600)", value: "11600" },
  { label: "VILLEGLY (11600)", value: "11600" },
  { label: "VILLELONGUE D AUDE (11300)", value: "11300" },
  { label: "VILLEMAGNE (11310)", value: "11310" },
  { label: "VILLEMOUSTAUSSOU (11620)", value: "11620" },
  { label: "VILLENEUVE LA COMPTAL (11400)", value: "11400" },
  { label: "VILLENEUVE LES CORBIERES (11360)", value: "11360" },
  { label: "VILLENEUVE LES MONTREAL (11290)", value: "11290" },
  { label: "VILLENEUVE MINERVOIS (11160)", value: "11160" },
  { label: "VILLEPINTE (11150)", value: "11150" },
  { label: "VILLEROUGE TERMENES (11330)", value: "11330" },
  { label: "VILLESEQUE DES CORBIERES (11360)", value: "11360" },
  { label: "VILLESEQUELANDE (11170)", value: "11170" },
  { label: "VILLESISCLE (11150)", value: "11150" },
  { label: "VILLESPY (11170)", value: "11170" },
  { label: "VILLETRITOULS (11220)", value: "11220" },
  { label: "VINASSAN (11110)", value: "11110" },
  { label: "AGEN D AVEYRON (12630)", value: "12630" },
  { label: "AGUESSAC (12520)", value: "12520" },
  { label: "LES ALBRES (12220)", value: "12220" },
  { label: "ALMONT LES JUNIES (12300)", value: "12300" },
  { label: "ALRANCE (12430)", value: "12430" },
  { label: "AMBEYRAC (12260)", value: "12260" },
  { label: "ANGLARS ST FELIX (12390)", value: "12390" },
  { label: "ARNAC SUR DOURDOU (12360)", value: "12360" },
  { label: "ARQUES (12290)", value: "12290" },
  { label: "ARVIEU (12120)", value: "12120" },
  { label: "ASPRIERES (12700)", value: "12700" },
  { label: "AUBIN (12110)", value: "12110" },
  { label: "AUBIN (12110)", value: "12110" },
  { label: "AUBIN (12110)", value: "12110" },
  { label: "AURIAC LAGAST (12120)", value: "12120" },
  { label: "AUZITS (12390)", value: "12390" },
  { label: "AYSSENES (12430)", value: "12430" },
  { label: "BALAGUIER D OLT (12260)", value: "12260" },
  { label: "BALAGUIER SUR RANCE (12380)", value: "12380" },
  { label: "LE BAS SEGALA (12200)", value: "12200" },
  { label: "LE BAS SEGALA (12200)", value: "12200" },
  { label: "LE BAS SEGALA (12240)", value: "12240" },
  { label: "LA BASTIDE PRADINES (12490)", value: "12490" },
  { label: "LA BASTIDE SOLAGES (12550)", value: "12550" },
  { label: "BELCASTEL (12390)", value: "12390" },
  { label: "BELMONT SUR RANCE (12370)", value: "12370" },
  { label: "BERTHOLENE (12310)", value: "12310" },
  { label: "BESSUEJOULS (12500)", value: "12500" },
  { label: "BOISSE PENCHOT (12300)", value: "12300" },
  { label: "BOR ET BAR (12270)", value: "12270" },
  { label: "BOUILLAC (12300)", value: "12300" },
  { label: "BOURNAZEL (12390)", value: "12390" },
  { label: "BOUSSAC (12160)", value: "12160" },
  { label: "BOZOULS (12340)", value: "12340" },
  { label: "BRANDONNET (12350)", value: "12350" },
  { label: "BRASC (12550)", value: "12550" },
  { label: "BROMMAT (12600)", value: "12600" },
  { label: "BROQUIES (12480)", value: "12480" },
  { label: "BROUSSE LE CHATEAU (12480)", value: "12480" },
  { label: "BRUSQUE (12360)", value: "12360" },
  { label: "CABANES (12800)", value: "12800" },
  { label: "CALMELS ET LE VIALA (12400)", value: "12400" },
  { label: "CALMONT (12450)", value: "12450" },
  { label: "CALMONT (12450)", value: "12450" },
  { label: "CAMARES (12360)", value: "12360" },
  { label: "CAMBOULAZET (12160)", value: "12160" },
  { label: "CAMJAC (12800)", value: "12800" },
  { label: "CAMPAGNAC (12560)", value: "12560" },
  { label: "CAMPOURIEZ (12140)", value: "12140" },
  { label: "CAMPOURIEZ (12140)", value: "12140" },
  { label: "CAMPOURIEZ (12460)", value: "12460" },
  { label: "CAMPUAC (12580)", value: "12580" },
  { label: "CANET DE SALARS (12290)", value: "12290" },
  { label: "CANTOIN (12420)", value: "12420" },
  { label: "CAPDENAC GARE (12700)", value: "12700" },
  { label: "LA CAPELLE BALAGUIER (12260)", value: "12260" },
  { label: "LA CAPELLE BLEYS (12240)", value: "12240" },
  { label: "LA CAPELLE BONANCE (12130)", value: "12130" },
  { label: "BARAQUEVILLE (12160)", value: "12160" },
  { label: "BARAQUEVILLE (12160)", value: "12160" },
  { label: "CASSAGNES BEGONHES (12120)", value: "12120" },
  { label: "CASSUEJOULS (12210)", value: "12210" },
  { label: "CASTANET (12240)", value: "12240" },
  { label: "CASTELMARY (12800)", value: "12800" },
  { label: "CASTELNAU DE MANDAILLES (12500)", value: "12500" },
  { label: "CASTELNAU PEGAYROLS (12620)", value: "12620" },
  { label: "LA CAVALERIE (12230)", value: "12230" },
  { label: "LE CAYROL (12500)", value: "12500" },
  { label: "CENTRES (12120)", value: "12120" },
  { label: "CLAIRVAUX D AVEYRON (12330)", value: "12330" },
  { label: "LE CLAPIER (12540)", value: "12540" },
  { label: "COLOMBIES (12240)", value: "12240" },
  { label: "COMBRET (12370)", value: "12370" },
  { label: "COMPEYRE (12520)", value: "12520" },
  { label: "COMPOLIBAT (12350)", value: "12350" },
  { label: "COMPREGNAC (12100)", value: "12100" },
  { label: "COMPS LA GRAND VILLE (12120)", value: "12120" },
  { label: "CONDOM D AUBRAC (12470)", value: "12470" },
  { label: "CONNAC (12170)", value: "12170" },
  { label: "CONQUES EN ROUERGUE (12320)", value: "12320" },
  { label: "CONQUES EN ROUERGUE (12320)", value: "12320" },
  { label: "CONQUES EN ROUERGUE (12320)", value: "12320" },
  { label: "CONQUES EN ROUERGUE (12320)", value: "12320" },
  { label: "CORNUS (12540)", value: "12540" },
  { label: "LES COSTES GOZON (12400)", value: "12400" },
  { label: "COUBISOU (12190)", value: "12190" },
  { label: "COUPIAC (12550)", value: "12550" },
  { label: "LA COUVERTOIRADE (12230)", value: "12230" },
  { label: "CRANSAC (12110)", value: "12110" },
  { label: "CREISSELS (12100)", value: "12100" },
  { label: "CRESPIN (12800)", value: "12800" },
  { label: "LA CRESSE (12640)", value: "12640" },
  { label: "CURIERES (12210)", value: "12210" },
  { label: "DECAZEVILLE (12300)", value: "12300" },
  { label: "DRUELLE BALSAC (12000)", value: "12000" },
  { label: "DRUELLE BALSAC (12000)", value: "12000" },
  { label: "DRUELLE BALSAC (12510)", value: "12510" },
  { label: "DRUELLE BALSAC (12510)", value: "12510" },
  { label: "DRULHE (12350)", value: "12350" },
  { label: "DURENQUE (12170)", value: "12170" },
  { label: "LE FEL (12140)", value: "12140" },
  { label: "ENTRAYGUES SUR TRUYERE (12140)", value: "12140" },
  { label: "ESCANDOLIERES (12390)", value: "12390" },
  { label: "ESPALION (12500)", value: "12500" },
  { label: "ESPEYRAC (12140)", value: "12140" },
  { label: "ESTAING (12190)", value: "12190" },
  { label: "FAYET (12360)", value: "12360" },
  { label: "FIRMI (12300)", value: "12300" },
  { label: "FLAGNAC (12300)", value: "12300" },
  { label: "FLAVIN (12450)", value: "12450" },
  { label: "FLORENTIN LA CAPELLE (12140)", value: "12140" },
  { label: "FOISSAC (12260)", value: "12260" },
  { label: "LA FOUILLADE (12270)", value: "12270" },
  { label: "GABRIAC (12340)", value: "12340" },
  { label: "GAILLAC D AVEYRON (12310)", value: "12310" },
  { label: "GALGAN (12220)", value: "12220" },
  { label: "GISSAC (12360)", value: "12360" },
  { label: "GOLINHAC (12140)", value: "12140" },
  { label: "GOUTRENS (12390)", value: "12390" },
  { label: "GRAMOND (12160)", value: "12160" },
  { label: "L'HOSPITALET DU LARZAC (12230)", value: "12230" },
  { label: "HUPARLAC (12460)", value: "12460" },
  { label: "LACROIX BARREZ (12600)", value: "12600" },
  { label: "LAGUIOLE (12210)", value: "12210" },
  { label: "LAISSAC SEVERAC L EGLISE (12310)", value: "12310" },
  { label: "LAISSAC SEVERAC L EGLISE (12310)", value: "12310" },
  { label: "LANUEJOULS (12350)", value: "12350" },
  { label: "LAPANOUSE DE CERNON (12230)", value: "12230" },
  { label: "LASSOUTS (12500)", value: "12500" },
  { label: "LAVAL ROQUECEZIERE (12380)", value: "12380" },
  { label: "LEDERGUES (12170)", value: "12170" },
  { label: "LESCURE JAOUL (12440)", value: "12440" },
  { label: "LESTRADE ET THOUELS (12430)", value: "12430" },
  { label: "LIVINHAC LE HAUT (12300)", value: "12300" },
  { label: "LA LOUBIERE (12740)", value: "12740" },
  { label: "LUC LA PRIMAUBE (12450)", value: "12450" },
  { label: "LUC LA PRIMAUBE (12450)", value: "12450" },
  { label: "LUGAN (12220)", value: "12220" },
  { label: "LUNAC (12270)", value: "12270" },
  { label: "MALEVILLE (12350)", value: "12350" },
  { label: "MANHAC (12160)", value: "12160" },
  { label: "MARCILLAC VALLON (12330)", value: "12330" },
  { label: "MARNHAGUES ET LATOUR (12540)", value: "12540" },
  { label: "MARTIEL (12200)", value: "12200" },
  { label: "MARTRIN (12550)", value: "12550" },
  { label: "MAYRAN (12390)", value: "12390" },
  { label: "MELAGUES (12360)", value: "12360" },
  { label: "MELJAC (12120)", value: "12120" },
  { label: "MILLAU (12100)", value: "12100" },
  { label: "LE MONASTERE (12000)", value: "12000" },
  { label: "MONTAGNOL (12360)", value: "12360" },
  { label: "MONTAGNOL (12360)", value: "12360" },
  { label: "MONTBAZENS (12220)", value: "12220" },
  { label: "MONTCLAR (12550)", value: "12550" },
  { label: "MONTEILS (12200)", value: "12200" },
  { label: "MONTEZIC (12460)", value: "12460" },
  { label: "MONTFRANC (12380)", value: "12380" },
  { label: "MONTJAUX (12490)", value: "12490" },
  { label: "MONTLAUR (12400)", value: "12400" },
  { label: "FONDAMENTE (12540)", value: "12540" },
  { label: "MONTPEYROUX (12210)", value: "12210" },
  { label: "MONTROZIER (12630)", value: "12630" },
  { label: "MONTROZIER (12630)", value: "12630" },
  { label: "MONTSALES (12260)", value: "12260" },
  { label: "MORLHON LE HAUT (12200)", value: "12200" },
  { label: "MOSTUEJOULS (12720)", value: "12720" },
  { label: "MOURET (12330)", value: "12330" },
  { label: "MOYRAZES (12160)", value: "12160" },
  { label: "MURASSON (12370)", value: "12370" },
  { label: "MUR DE BARREZ (12600)", value: "12600" },
  { label: "MURET LE CHATEAU (12330)", value: "12330" },
  { label: "MUROLS (12600)", value: "12600" },
  { label: "NAJAC (12270)", value: "12270" },
  { label: "NANT (12230)", value: "12230" },
  { label: "NAUCELLE (12800)", value: "12800" },
  { label: "NAUSSAC (12700)", value: "12700" },
  { label: "NAUVIALE (12330)", value: "12330" },
  { label: "LE NAYRAC (12190)", value: "12190" },
  { label: "OLEMPS (12510)", value: "12510" },
  { label: "OLS ET RINHODES (12260)", value: "12260" },
  { label: "ONET LE CHATEAU (12000)", value: "12000" },
  { label: "ONET LE CHATEAU (12850)", value: "12850" },
  { label: "ONET LE CHATEAU (12850)", value: "12850" },
  { label: "PALMAS D AVEYRON (12310)", value: "12310" },
  { label: "PALMAS D AVEYRON (12310)", value: "12310" },
  { label: "PALMAS D AVEYRON (12340)", value: "12340" },
  { label: "PAULHE (12520)", value: "12520" },
  { label: "PEUX ET COUFFOULEUX (12360)", value: "12360" },
  { label: "PEYRELEAU (12720)", value: "12720" },
  { label: "PEYRUSSE LE ROC (12220)", value: "12220" },
  { label: "PIERREFICHE (12130)", value: "12130" },
  { label: "PLAISANCE (12550)", value: "12550" },
  { label: "POMAYROLS (12130)", value: "12130" },
  { label: "PONT DE SALARS (12290)", value: "12290" },
  { label: "POUSTHOMY (12380)", value: "12380" },
  { label: "PRADES D AUBRAC (12470)", value: "12470" },
  { label: "PRADES SALARS (12290)", value: "12290" },
  { label: "PRADINAS (12240)", value: "12240" },
  { label: "PREVINQUIERES (12350)", value: "12350" },
  { label: "PRIVEZAC (12350)", value: "12350" },
  { label: "MOUNES PROHENCOUX (12370)", value: "12370" },
  { label: "PRUINES (12320)", value: "12320" },
  { label: "QUINS (12800)", value: "12800" },
  { label: "QUINS (12800)", value: "12800" },
  { label: "REBOURGUIL (12400)", value: "12400" },
  { label: "REQUISTA (12170)", value: "12170" },
  { label: "RIEUPEYROUX (12240)", value: "12240" },
  { label: "RIGNAC (12390)", value: "12390" },
  { label: "RIVIERE SUR TARN (12640)", value: "12640" },
  { label: "RIVIERE SUR TARN (12640)", value: "12640" },
  { label: "RODELLE (12340)", value: "12340" },
  { label: "RODEZ (12000)", value: "12000" },
  { label: "ROQUEFORT SUR SOULZON (12250)", value: "12250" },
  { label: "LA ROQUE STE MARGUERITE (12100)", value: "12100" },
  { label: "LA ROUQUETTE (12200)", value: "12200" },
  { label: "ROUSSENNAC (12220)", value: "12220" },
  { label: "RULLAC ST CIRQ (12120)", value: "12120" },
  { label: "ST AFFRIQUE (12400)", value: "12400" },
  { label: "ST AMANS DES COTS (12460)", value: "12460" },
  { label: "ST ANDRE DE NAJAC (12270)", value: "12270" },
  { label: "ST ANDRE DE VEZINES (12720)", value: "12720" },
  { label: "ST BEAULIZE (12540)", value: "12540" },
  { label: "ST BEAUZELY (12620)", value: "12620" },
  { label: "ST CHELY D AUBRAC (12470)", value: "12470" },
  { label: "ST CHELY D AUBRAC (12470)", value: "12470" },
  { label: "ST CHRISTOPHE VALLON (12330)", value: "12330" },
  { label: "ST COME D OLT (12500)", value: "12500" },
  { label: "STE CROIX (12260)", value: "12260" },
  { label: "STE EULALIE D OLT (12130)", value: "12130" },
  { label: "STE EULALIE DE CERNON (12230)", value: "12230" },
  { label: "ST FELIX DE LUNEL (12320)", value: "12320" },
  { label: "ST FELIX DE SORGUES (12400)", value: "12400" },
  { label: "ARGENCES EN AUBRAC (12210)", value: "12210" },
  { label: "ARGENCES EN AUBRAC (12210)", value: "12210" },
  { label: "ARGENCES EN AUBRAC (12210)", value: "12210" },
  { label: "ARGENCES EN AUBRAC (12420)", value: "12420" },
  { label: "ARGENCES EN AUBRAC (12420)", value: "12420" },
  { label: "ARGENCES EN AUBRAC (12420)", value: "12420" },
  { label: "ST GENIEZ D OLT ET D AUBRAC (12130)", value: "12130" },
  { label: "ST GENIEZ D OLT ET D AUBRAC (12130)", value: "12130" },
  { label: "ST GEORGES DE LUZENCON (12100)", value: "12100" },
  { label: "ST HIPPOLYTE (12140)", value: "12140" },
  { label: "ST HIPPOLYTE (12140)", value: "12140" },
  { label: "ST IGEST (12260)", value: "12260" },
  { label: "ST IZAIRE (12480)", value: "12480" },
  { label: "ST JEAN D ALCAPIES (12250)", value: "12250" },
  { label: "ST JEAN DELNOUS (12170)", value: "12170" },
  { label: "ST JEAN DU BRUEL (12230)", value: "12230" },
  { label: "ST JEAN ET ST PAUL (12250)", value: "12250" },
  { label: "ST JUERY (12550)", value: "12550" },
  { label: "STE JULIETTE SUR VIAUR (12120)", value: "12120" },
  { label: "ST JUST SUR VIAUR (12170)", value: "12170" },
  { label: "ST JUST SUR VIAUR (12800)", value: "12800" },
  { label: "ST LAURENT DE LEVEZOU (12620)", value: "12620" },
  { label: "ST LAURENT D OLT (12560)", value: "12560" },
  { label: "ST LEONS (12780)", value: "12780" },
  { label: "ST MARTIN DE LENNE (12130)", value: "12130" },
  { label: "ST PARTHEM (12300)", value: "12300" },
  { label: "STE RADEGONDE (12850)", value: "12850" },
  { label: "ST REMY (12200)", value: "12200" },
  { label: "ST ROME DE CERNON (12490)", value: "12490" },
  { label: "ST ROME DE TARN (12490)", value: "12490" },
  { label: "ST SANTIN (12300)", value: "12300" },
  { label: "ST SATURNIN DE LENNE (12560)", value: "12560" },
  { label: "ST SERNIN SUR RANCE (12380)", value: "12380" },
  { label: "ST SEVER DU MOUSTIER (12370)", value: "12370" },
  { label: "ST SYMPHORIEN DE THENIERES (12460)", value: "12460" },
  { label: "ST SYMPHORIEN DE THENIERES (12460)", value: "12460" },
  { label: "ST VICTOR ET MELVIEU (12400)", value: "12400" },
  { label: "ST VICTOR ET MELVIEU (12400)", value: "12400" },
  { label: "SALLES COURBATIES (12260)", value: "12260" },
  { label: "SALLES CURAN (12410)", value: "12410" },
  { label: "SALLES LA SOURCE (12330)", value: "12330" },
  { label: "SALMIECH (12120)", value: "12120" },
  { label: "SALVAGNAC CAJARC (12260)", value: "12260" },
  { label: "CAUSSE ET DIEGE (12700)", value: "12700" },
  { label: "CAUSSE ET DIEGE (12700)", value: "12700" },
  { label: "CAUSSE ET DIEGE (12700)", value: "12700" },
  { label: "LA SALVETAT PEYRALES (12440)", value: "12440" },
  { label: "SANVENSA (12200)", value: "12200" },
  { label: "SAUCLIERES (12230)", value: "12230" },
  { label: "SAUJAC (12260)", value: "12260" },
  { label: "SAUVETERRE DE ROUERGUE (12800)", value: "12800" },
  { label: "SAVIGNAC (12200)", value: "12200" },
  { label: "SEBAZAC CONCOURES (12740)", value: "12740" },
  { label: "SEBRAZAC (12190)", value: "12190" },
  { label: "SEGUR (12290)", value: "12290" },
  { label: "LA SELVE (12170)", value: "12170" },
  { label: "SENERGUES (12320)", value: "12320" },
  { label: "LA SERRE (12380)", value: "12380" },
  { label: "SEVERAC D AVEYRON (12150)", value: "12150" },
  { label: "SEVERAC D AVEYRON (12150)", value: "12150" },
  { label: "SEVERAC D AVEYRON (12150)", value: "12150" },
  { label: "SEVERAC D AVEYRON (12150)", value: "12150" },
  { label: "SEVERAC D AVEYRON (12150)", value: "12150" },
  { label: "SONNAC (12700)", value: "12700" },
  { label: "SOULAGES BONNEVAL (12210)", value: "12210" },
  { label: "SYLVANES (12360)", value: "12360" },
  { label: "TAURIAC DE CAMARES (12360)", value: "12360" },
  { label: "TAURIAC DE NAUCELLE (12800)", value: "12800" },
  { label: "TAUSSAC (12600)", value: "12600" },
  { label: "TAYRAC (12440)", value: "12440" },
  { label: "THERONDELS (12600)", value: "12600" },
  { label: "TOULONJAC (12200)", value: "12200" },
  { label: "TOURNEMIRE (12250)", value: "12250" },
  { label: "TREMOUILLES (12290)", value: "12290" },
  { label: "LE TRUEL (12430)", value: "12430" },
  { label: "VABRES L ABBAYE (12400)", value: "12400" },
  { label: "VAILHOURLES (12200)", value: "12200" },
  { label: "VALADY (12330)", value: "12330" },
  { label: "VALADY (12330)", value: "12330" },
  { label: "VALZERGUES (12220)", value: "12220" },
  { label: "VAUREILLES (12220)", value: "12220" },
  { label: "VERRIERES (12520)", value: "12520" },
  { label: "VERSOLS ET LAPEYRE (12400)", value: "12400" },
  { label: "VEYREAU (12720)", value: "12720" },
  { label: "VEZINS DE LEVEZOU (12780)", value: "12780" },
  { label: "VIALA DU PAS DE JAUX (12250)", value: "12250" },
  { label: "VIALA DU TARN (12490)", value: "12490" },
  { label: "LE VIBAL (12290)", value: "12290" },
  { label: "VILLECOMTAL (12580)", value: "12580" },
  { label: "VILLEFRANCHE DE PANAT (12430)", value: "12430" },
  { label: "VILLEFRANCHE DE ROUERGUE (12200)", value: "12200" },
  { label: "VILLENEUVE (12260)", value: "12260" },
  { label: "VIMENET (12310)", value: "12310" },
  { label: "VIVIEZ (12110)", value: "12110" },
  { label: "CURAN (12410)", value: "12410" },
  { label: "AIX EN PROVENCE (13080)", value: "13080" },
  { label: "AIX EN PROVENCE (13090)", value: "13090" },
  { label: "AIX EN PROVENCE (13100)", value: "13100" },
  { label: "AIX EN PROVENCE (13100)", value: "13100" },
  { label: "AIX EN PROVENCE (13290)", value: "13290" },
  { label: "AIX EN PROVENCE (13540)", value: "13540" },
  { label: "ALLAUCH (13190)", value: "13190" },
  { label: "ALLAUCH (13190)", value: "13190" },
  { label: "ALLEINS (13980)", value: "13980" },
  { label: "ALLEINS (13980)", value: "13980" },
  { label: "ARLES (13104)", value: "13104" },
  { label: "ARLES (13123)", value: "13123" },
  { label: "ARLES (13129)", value: "13129" },
  { label: "ARLES (13200)", value: "13200" },
  { label: "ARLES (13200)", value: "13200" },
  { label: "ARLES (13280)", value: "13280" },
  { label: "ARLES (13280)", value: "13280" },
  { label: "AUBAGNE (13400)", value: "13400" },
  { label: "AUBAGNE (13400)", value: "13400" },
  { label: "AUREILLE (13930)", value: "13930" },
  { label: "AURIOL (13390)", value: "13390" },
  { label: "AURONS (13121)", value: "13121" },
  { label: "LA BARBEN (13330)", value: "13330" },
  { label: "BARBENTANE (13570)", value: "13570" },
  { label: "LES BAUX DE PROVENCE (13520)", value: "13520" },
  { label: "BEAURECUEIL (13100)", value: "13100" },
  { label: "BELCODENE (13720)", value: "13720" },
  { label: "BELCODENE (13720)", value: "13720" },
  { label: "BERRE L ETANG (13130)", value: "13130" },
  { label: "BOUC BEL AIR (13320)", value: "13320" },
  { label: "LA BOUILLADISSE (13720)", value: "13720" },
  { label: "LA BOUILLADISSE (13720)", value: "13720" },
  { label: "LA BOUILLADISSE (13720)", value: "13720" },
  { label: "LA BOUILLADISSE (13720)", value: "13720" },
  { label: "BOULBON (13150)", value: "13150" },
  { label: "CABANNES (13440)", value: "13440" },
  { label: "CABRIES (13480)", value: "13480" },
  { label: "CABRIES (13480)", value: "13480" },
  { label: "CADOLIVE (13950)", value: "13950" },
  { label: "CARRY LE ROUET (13620)", value: "13620" },
  { label: "CARRY LE ROUET (13620)", value: "13620" },
  { label: "CASSIS (13260)", value: "13260" },
  { label: "CEYRESTE (13600)", value: "13600" },
  { label: "CHARLEVAL (13350)", value: "13350" },
  { label: "CHATEAUNEUF LE ROUGE (13790)", value: "13790" },
  { label: "CHATEAUNEUF LES MARTIGUES (13220)", value: "13220" },
  { label: "CHATEAUNEUF LES MARTIGUES (13220)", value: "13220" },
  { label: "CHATEAURENARD (13160)", value: "13160" },
  { label: "LA CIOTAT (13600)", value: "13600" },
  { label: "CORNILLON CONFOUX (13250)", value: "13250" },
  { label: "CUGES LES PINS (13780)", value: "13780" },
  { label: "LA DESTROUSSE (13112)", value: "13112" },
  { label: "EGUILLES (13510)", value: "13510" },
  { label: "ENSUES LA REDONNE (13820)", value: "13820" },
  { label: "ENSUES LA REDONNE (13820)", value: "13820" },
  { label: "EYGALIERES (13810)", value: "13810" },
  { label: "EYGUIERES (13430)", value: "13430" },
  { label: "EYRAGUES (13630)", value: "13630" },
  { label: "LA FARE LES OLIVIERS (13580)", value: "13580" },
  { label: "FONTVIEILLE (13990)", value: "13990" },
  { label: "FOS SUR MER (13270)", value: "13270" },
  { label: "FUVEAU (13710)", value: "13710" },
  { label: "FUVEAU (13710)", value: "13710" },
  { label: "FUVEAU (13710)", value: "13710" },
  { label: "GARDANNE (13120)", value: "13120" },
  { label: "GARDANNE (13120)", value: "13120" },
  { label: "GARDANNE (13120)", value: "13120" },
  { label: "GEMENOS (13420)", value: "13420" },
  { label: "GIGNAC LA NERTHE (13180)", value: "13180" },
  { label: "GIGNAC LA NERTHE (13180)", value: "13180" },
  { label: "GRANS (13450)", value: "13450" },
  { label: "GRAVESON (13690)", value: "13690" },
  { label: "GREASQUE (13850)", value: "13850" },
  { label: "ISTRES (13118)", value: "13118" },
  { label: "ISTRES (13800)", value: "13800" },
  { label: "JOUQUES (13490)", value: "13490" },
  { label: "LAMANON (13113)", value: "13113" },
  { label: "LAMBESC (13410)", value: "13410" },
  { label: "LANCON PROVENCE (13680)", value: "13680" },
  { label: "MAILLANE (13910)", value: "13910" },
  { label: "MALLEMORT (13370)", value: "13370" },
  { label: "MALLEMORT (13370)", value: "13370" },
  { label: "MARIGNANE (13700)", value: "13700" },
  { label: "MARTIGUES (13117)", value: "13117" },
  { label: "MARTIGUES (13500)", value: "13500" },
  { label: "MARTIGUES (13500)", value: "13500" },
  { label: "MARTIGUES (13500)", value: "13500" },
  { label: "MARTIGUES (13500)", value: "13500" },
  { label: "MAS BLANC DES ALPILLES (13103)", value: "13103" },
  { label: "MAUSSANE LES ALPILLES (13520)", value: "13520" },
  { label: "MEYRARGUES (13650)", value: "13650" },
  { label: "MEYREUIL (13590)", value: "13590" },
  { label: "ST PIERRE DE MEZOARGUES (13150)", value: "13150" },
  { label: "MIMET (13105)", value: "13105" },
  { label: "MIRAMAS (13140)", value: "13140" },
  { label: "MOLLEGES (13940)", value: "13940" },
  { label: "MOURIES (13890)", value: "13890" },
  { label: "NOVES (13550)", value: "13550" },
  { label: "NOVES (13550)", value: "13550" },
  { label: "ORGON (13660)", value: "13660" },
  { label: "PARADOU (13520)", value: "13520" },
  { label: "PELISSANNE (13330)", value: "13330" },
  { label: "LA PENNE SUR HUVEAUNE (13821)", value: "13821" },
  { label: "LA PENNE SUR HUVEAUNE (13821)", value: "13821" },
  { label: "LES PENNES MIRABEAU (13170)", value: "13170" },
  { label: "LES PENNES MIRABEAU (13170)", value: "13170" },
  { label: "LES PENNES MIRABEAU (13170)", value: "13170" },
  { label: "PEYNIER (13790)", value: "13790" },
  { label: "PEYNIER (13790)", value: "13790" },
  { label: "PEYPIN (13124)", value: "13124" },
  { label: "PEYROLLES EN PROVENCE (13860)", value: "13860" },
  { label: "PLAN DE CUQUES (13380)", value: "13380" },
  { label: "PLAN D ORGON (13750)", value: "13750" },
  { label: "PORT DE BOUC (13110)", value: "13110" },
  { label: "PORT ST LOUIS DU RHONE (13230)", value: "13230" },
  { label: "PUYLOUBIER (13114)", value: "13114" },
  { label: "PUYLOUBIER (13114)", value: "13114" },
  { label: "LE PUY STE REPARADE (13610)", value: "13610" },
  { label: "ROGNAC (13340)", value: "13340" },
  { label: "ROGNES (13840)", value: "13840" },
  { label: "ROGNONAS (13870)", value: "13870" },
  { label: "LA ROQUE D ANTHERON (13640)", value: "13640" },
  { label: "ROQUEFORT LA BEDOULE (13830)", value: "13830" },
  { label: "ROQUEFORT LA BEDOULE (13830)", value: "13830" },
  { label: "ROQUEVAIRE (13360)", value: "13360" },
  { label: "ROQUEVAIRE (13360)", value: "13360" },
  { label: "ROQUEVAIRE (13360)", value: "13360" },
  { label: "ROUSSET (13790)", value: "13790" },
  { label: "LE ROVE (13740)", value: "13740" },
  { label: "LE ROVE (13740)", value: "13740" },
  { label: "LE ROVE (13740)", value: "13740" },
  { label: "ST ANDIOL (13670)", value: "13670" },
  { label: "ST ANTONIN SUR BAYON (13100)", value: "13100" },
  { label: "ST CANNAT (13760)", value: "13760" },
  { label: "ST CHAMAS (13250)", value: "13250" },
  { label: "ST ESTEVE JANSON (13610)", value: "13610" },
  { label: "ST ETIENNE DU GRES (13103)", value: "13103" },
  { label: "ST MARC JAUMEGARDE (13100)", value: "13100" },
  { label: "SAINTES MARIES DE LA MER (13460)", value: "13460" },
  { label: "ST MARTIN DE CRAU (13310)", value: "13310" },
  { label: "ST MITRE LES REMPARTS (13920)", value: "13920" },
  { label: "ST PAUL LES DURANCE (13115)", value: "13115" },
  { label: "ST REMY DE PROVENCE (13210)", value: "13210" },
  { label: "ST SAVOURNIN (13119)", value: "13119" },
  { label: "ST VICTORET (13730)", value: "13730" },
  { label: "SALON DE PROVENCE (13300)", value: "13300" },
  { label: "SAUSSET LES PINS (13960)", value: "13960" },
  { label: "SENAS (13560)", value: "13560" },
  { label: "SEPTEMES LES VALLONS (13240)", value: "13240" },
  { label: "SEPTEMES LES VALLONS (13240)", value: "13240" },
  { label: "SIMIANE COLLONGUE (13109)", value: "13109" },
  { label: "TARASCON (13150)", value: "13150" },
  { label: "LE THOLONET (13100)", value: "13100" },
  { label: "TRETS (13530)", value: "13530" },
  { label: "TRETS (13530)", value: "13530" },
  { label: "VAUVENARGUES (13126)", value: "13126" },
  { label: "VELAUX (13880)", value: "13880" },
  { label: "VENELLES (13770)", value: "13770" },
  { label: "VENTABREN (13122)", value: "13122" },
  { label: "VENTABREN (13122)", value: "13122" },
  { label: "VERNEGUES (13116)", value: "13116" },
  { label: "VERQUIERES (13670)", value: "13670" },
  { label: "VITROLLES (13127)", value: "13127" },
  { label: "COUDOUX (13111)", value: "13111" },
  { label: "CARNOUX EN PROVENCE (13470)", value: "13470" },
  { label: "MARSEILLE 01 (13001)", value: "13001" },
  { label: "MARSEILLE 02 (13002)", value: "13002" },
  { label: "MARSEILLE 03 (13003)", value: "13003" },
  { label: "MARSEILLE 04 (13004)", value: "13004" },
  { label: "MARSEILLE 05 (13005)", value: "13005" },
  { label: "MARSEILLE 06 (13006)", value: "13006" },
  { label: "MARSEILLE 07 (13007)", value: "13007" },
  { label: "MARSEILLE 08 (13008)", value: "13008" },
  { label: "MARSEILLE 08 (13008)", value: "13008" },
  { label: "MARSEILLE 08 (13008)", value: "13008" },
  { label: "MARSEILLE 09 (13009)", value: "13009" },
  { label: "MARSEILLE 09 (13009)", value: "13009" },
  { label: "MARSEILLE 09 (13009)", value: "13009" },
  { label: "MARSEILLE 10 (13010)", value: "13010" },
  { label: "MARSEILLE 11 (13011)", value: "13011" },
  { label: "MARSEILLE 11 (13011)", value: "13011" },
  { label: "MARSEILLE 12 (13012)", value: "13012" },
  { label: "MARSEILLE 13 (13013)", value: "13013" },
  { label: "MARSEILLE 13 (13013)", value: "13013" },
  { label: "MARSEILLE 13 (13013)", value: "13013" },
  { label: "MARSEILLE 13 (13013)", value: "13013" },
  { label: "MARSEILLE 13 (13013)", value: "13013" },
  { label: "MARSEILLE 13 (13013)", value: "13013" },
  { label: "MARSEILLE 14 (13014)", value: "13014" },
  { label: "MARSEILLE 15 (13015)", value: "13015" },
  { label: "MARSEILLE 16 (13016)", value: "13016" },
  { label: "MARSEILLE 16 (13016)", value: "13016" },
  { label: "ABLON (14600)", value: "14600" },
  { label: "AGY (14400)", value: "14400" },
  { label: "VALAMBRAY (14190)", value: "14190" },
  { label: "VALAMBRAY (14190)", value: "14190" },
  { label: "VALAMBRAY (14370)", value: "14370" },
  { label: "VALAMBRAY (14370)", value: "14370" },
  { label: "VALAMBRAY (14540)", value: "14540" },
  { label: "VALAMBRAY (14540)", value: "14540" },
  { label: "AMAYE SUR ORNE (14210)", value: "14210" },
  { label: "AMAYE SUR SEULLES (14310)", value: "14310" },
  { label: "AMFREVILLE (14860)", value: "14860" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14240)", value: "14240" },
  { label: "AURSEULLES (14250)", value: "14250" },
  { label: "ANGERVILLE (14430)", value: "14430" },
  { label: "COLOMBY ANGUERNY (14610)", value: "14610" },
  { label: "COLOMBY ANGUERNY (14610)", value: "14610" },
  { label: "ANISY (14610)", value: "14610" },
  { label: "ANNEBAULT (14430)", value: "14430" },
  { label: "ARGANCHY (14400)", value: "14400" },
  { label: "ARGENCES (14370)", value: "14370" },
  { label: "ARROMANCHES LES BAINS (14117)", value: "14117" },
  { label: "ASNELLES (14960)", value: "14960" },
  { label: "ASNIERES EN BESSIN (14710)", value: "14710" },
  { label: "AUBERVILLE (14640)", value: "14640" },
  { label: "AUBIGNY (14700)", value: "14700" },
  { label: "AUDRIEU (14250)", value: "14250" },
  { label: "LES MONTS D AUNAY (14260)", value: "14260" },
  { label: "LES MONTS D AUNAY (14260)", value: "14260" },
  { label: "LES MONTS D AUNAY (14260)", value: "14260" },
  { label: "LES MONTS D AUNAY (14260)", value: "14260" },
  { label: "LES MONTS D AUNAY (14260)", value: "14260" },
  { label: "LES MONTS D AUNAY (14770)", value: "14770" },
  { label: "LES MONTS D AUNAY (14770)", value: "14770" },
  { label: "LES MONTS D AUNAY (14770)", value: "14770" },
  { label: "AUTHIE (14280)", value: "14280" },
  { label: "LES AUTHIEUX SUR CALONNE (14130)", value: "14130" },
  { label: "AUVILLARS (14340)", value: "14340" },
  { label: "AVENAY (14210)", value: "14210" },
  { label: "BALLEROY SUR DROME (14490)", value: "14490" },
  { label: "BALLEROY SUR DROME (14490)", value: "14490" },
  { label: "BANNEVILLE LA CAMPAGNE (14940)", value: "14940" },
  { label: "MALHERBE SUR AJON (14260)", value: "14260" },
  { label: "MALHERBE SUR AJON (14260)", value: "14260" },
  { label: "BANVILLE (14480)", value: "14480" },
  { label: "BARBERY (14220)", value: "14220" },
  { label: "BARBEVILLE (14400)", value: "14400" },
  { label: "BARNEVILLE LA BERTRAN (14600)", value: "14600" },
  { label: "BARON SUR ODON (14210)", value: "14210" },
  { label: "BAROU EN AUGE (14620)", value: "14620" },
  { label: "BASLY (14610)", value: "14610" },
  { label: "BASSENEVILLE (14670)", value: "14670" },
  { label: "BAVENT (14860)", value: "14860" },
  { label: "BAVENT (14860)", value: "14860" },
  { label: "BAYEUX (14400)", value: "14400" },
  { label: "BAZENVILLE (14480)", value: "14480" },
  { label: "LA BAZOQUE (14490)", value: "14490" },
  { label: "BEAUMAIS (14620)", value: "14620" },
  { label: "BEAUMESNIL (14380)", value: "14380" },
  { label: "BEAUMONT EN AUGE (14950)", value: "14950" },
  { label: "BELLENGREVILLE (14370)", value: "14370" },
  { label: "BENERVILLE SUR MER (14910)", value: "14910" },
  { label: "BENOUVILLE (14970)", value: "14970" },
  { label: "SOULEUVRE EN BOCAGE (14260)", value: "14260" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "SOULEUVRE EN BOCAGE (14350)", value: "14350" },
  { label: "BENY SUR MER (14440)", value: "14440" },
  { label: "BERNESQ (14710)", value: "14710" },
  { label: "BERNIERES D AILLY (14170)", value: "14170" },
  { label: "BERNIERES SUR MER (14990)", value: "14990" },
  { label: "BIEVILLE BEUVILLE (14112)", value: "14112" },
  { label: "BIEVILLE BEUVILLE (14112)", value: "14112" },
  { label: "BEUVILLERS (14100)", value: "14100" },
  { label: "BEUVRON EN AUGE (14430)", value: "14430" },
  { label: "BLAINVILLE SUR ORNE (14550)", value: "14550" },
  { label: "BLANGY LE CHATEAU (14130)", value: "14130" },
  { label: "BLAY (14400)", value: "14400" },
  { label: "BLONVILLE SUR MER (14910)", value: "14910" },
  { label: "LE BO (14690)", value: "14690" },
  { label: "LA BOISSIERE (14340)", value: "14340" },
  { label: "BONNEBOSQ (14340)", value: "14340" },
  { label: "BONNEMAISON (14260)", value: "14260" },
  { label: "BONNEVILLE LA LOUVET (14130)", value: "14130" },
  { label: "BONNEVILLE SUR TOUQUES (14800)", value: "14800" },
  { label: "BONNOEIL (14700)", value: "14700" },
  { label: "BONS TASSILLY (14420)", value: "14420" },
  { label: "BOUGY (14210)", value: "14210" },
  { label: "BOULON (14220)", value: "14220" },
  { label: "BOURGEAUVILLE (14430)", value: "14430" },
  { label: "BOURGUEBUS (14540)", value: "14540" },
  { label: "BRANVILLE (14430)", value: "14430" },
  { label: "BREMOY (14260)", value: "14260" },
  { label: "BRETTEVILLE LE RABET (14190)", value: "14190" },
  { label: "THUE ET MUE (14210)", value: "14210" },
  { label: "THUE ET MUE (14250)", value: "14250" },
  { label: "THUE ET MUE (14740)", value: "14740" },
  { label: "THUE ET MUE (14740)", value: "14740" },
  { label: "THUE ET MUE (14740)", value: "14740" },
  { label: "THUE ET MUE (14740)", value: "14740" },
  { label: "BRETTEVILLE SUR LAIZE (14680)", value: "14680" },
  { label: "BRETTEVILLE SUR ODON (14760)", value: "14760" },
  { label: "LE BREUIL EN AUGE (14130)", value: "14130" },
  { label: "LE BREUIL EN BESSIN (14330)", value: "14330" },
  { label: "LE BREVEDENT (14130)", value: "14130" },
  { label: "BREVILLE LES MONTS (14860)", value: "14860" },
  { label: "BRICQUEVILLE (14710)", value: "14710" },
  { label: "BRUCOURT (14160)", value: "14160" },
  { label: "BUCEELS (14250)", value: "14250" },
  { label: "LE BU SUR ROUVRES (14190)", value: "14190" },
  { label: "CABOURG (14390)", value: "14390" },
  { label: "CAEN (14000)", value: "14000" },
  { label: "CAGNY (14630)", value: "14630" },
  { label: "CAHAGNES (14240)", value: "14240" },
  { label: "CAHAGNOLLES (14490)", value: "14490" },
  { label: "LA CAINE (14210)", value: "14210" },
  { label: "CAIRON (14610)", value: "14610" },
  { label: "LA CAMBE (14230)", value: "14230" },
  { label: "CAMBES EN PLAINE (14610)", value: "14610" },
  { label: "CAMBREMER (14340)", value: "14340" },
  { label: "CAMBREMER (14340)", value: "14340" },
  { label: "CAMBREMER (14340)", value: "14340" },
  { label: "CAMBREMER (14340)", value: "14340" },
  { label: "CAMBREMER (14340)", value: "14340" },
  { label: "CAMPAGNOLLES (14500)", value: "14500" },
  { label: "CAMPIGNY (14490)", value: "14490" },
  { label: "CANAPVILLE (14800)", value: "14800" },
  { label: "CANCHY (14230)", value: "14230" },
  { label: "CANTELOUP (14370)", value: "14370" },
  { label: "CARCAGNY (14740)", value: "14740" },
  { label: "CARDONVILLE (14230)", value: "14230" },
  { label: "CARPIQUET (14650)", value: "14650" },
  { label: "CARTIGNY L EPINAY (14330)", value: "14330" },
  { label: "CASTILLON (14490)", value: "14490" },
  { label: "CASTILLON EN AUGE (14140)", value: "14140" },
  { label: "CAUMONT SUR AURE (14240)", value: "14240" },
  { label: "CAUMONT SUR AURE (14240)", value: "14240" },
  { label: "CAUMONT SUR AURE (14240)", value: "14240" },
  { label: "CAUMONT SUR AURE (14240)", value: "14240" },
  { label: "CAUVICOURT (14190)", value: "14190" },
  { label: "CAUVILLE (14770)", value: "14770" },
  { label: "CERNAY (14290)", value: "14290" },
  { label: "CESNY AUX VIGNES (14270)", value: "14270" },
  { label: "CESNY LES SOURCES (14220)", value: "14220" },
  { label: "CESNY LES SOURCES (14220)", value: "14220" },
  { label: "CESNY LES SOURCES (14220)", value: "14220" },
  { label: "CESNY LES SOURCES (14220)", value: "14220" },
  { label: "CESNY LES SOURCES (14220)", value: "14220" },
  { label: "CHOUAIN (14250)", value: "14250" },
  { label: "CINTHEAUX (14680)", value: "14680" },
  { label: "CLARBEC (14130)", value: "14130" },
  { label: "CLECY (14570)", value: "14570" },
  { label: "CLEVILLE (14370)", value: "14370" },
  { label: "COLLEVILLE SUR MER (14710)", value: "14710" },
  { label: "COLLEVILLE MONTGOMERY (14880)", value: "14880" },
  { label: "COLOMBELLES (14460)", value: "14460" },
  { label: "COLOMBIERES (14710)", value: "14710" },
  { label: "COLOMBIERS SUR SEULLES (14480)", value: "14480" },
  { label: "COMBRAY (14220)", value: "14220" },
  { label: "COMMES (14520)", value: "14520" },
  { label: "CONDE SUR IFS (14270)", value: "14270" },
  { label: "CONDE EN NORMANDIE (14110)", value: "14110" },
  { label: "CONDE EN NORMANDIE (14110)", value: "14110" },
  { label: "CONDE EN NORMANDIE (14110)", value: "14110" },
  { label: "CONDE EN NORMANDIE (14770)", value: "14770" },
  { label: "CONDE EN NORMANDIE (14770)", value: "14770" },
  { label: "CONDE EN NORMANDIE (14770)", value: "14770" },
  { label: "CONDE SUR SEULLES (14400)", value: "14400" },
  { label: "COQUAINVILLIERS (14130)", value: "14130" },
  { label: "CORDEBUGLE (14100)", value: "14100" },
  { label: "CORDEY (14700)", value: "14700" },
  { label: "CORMELLES LE ROYAL (14123)", value: "14123" },
  { label: "CORMOLAIN (14240)", value: "14240" },
  { label: "COSSESSEVILLE (14690)", value: "14690" },
  { label: "COTTUN (14400)", value: "14400" },
  { label: "COURCY (14170)", value: "14170" },
  { label: "COURSEULLES SUR MER (14470)", value: "14470" },
  { label: "COURTONNE LA MEURDRAC (14100)", value: "14100" },
  { label: "COURTONNE LES DEUX EGLISES (14290)", value: "14290" },
  { label: "COURTONNE LES DEUX EGLISES (14290)", value: "14290" },
  { label: "COURVAUDON (14260)", value: "14260" },
  { label: "CREPON (14480)", value: "14480" },
  { label: "CRESSERONS (14440)", value: "14440" },
  { label: "CRESSEVEUILLE (14430)", value: "14430" },
  { label: "CREULLY SUR SEULLES (14480)", value: "14480" },
  { label: "CREULLY SUR SEULLES (14480)", value: "14480" },
  { label: "CREULLY SUR SEULLES (14480)", value: "14480" },
  { label: "CRICQUEBOEUF (14113)", value: "14113" },
  { label: "CRICQUEVILLE EN AUGE (14430)", value: "14430" },
  { label: "CRICQUEVILLE EN BESSIN (14450)", value: "14450" },
  { label: "CRISTOT (14250)", value: "14250" },
  { label: "CROCY (14620)", value: "14620" },
  { label: "CROISILLES (14220)", value: "14220" },
  { label: "CROUAY (14400)", value: "14400" },
  { label: "CULEY LE PATRY (14220)", value: "14220" },
  { label: "CUSSY (14400)", value: "14400" },
  { label: "CUVERVILLE (14840)", value: "14840" },
  { label: "DAMBLAINVILLE (14620)", value: "14620" },
  { label: "DANESTAL (14430)", value: "14430" },
  { label: "DEAUVILLE (14800)", value: "14800" },
  { label: "DEMOUVILLE (14840)", value: "14840" },
  { label: "LE DETROIT (14690)", value: "14690" },
  { label: "DEUX JUMEAUX (14230)", value: "14230" },
  { label: "DIVES SUR MER (14160)", value: "14160" },
  { label: "DONNAY (14220)", value: "14220" },
  { label: "DOUVILLE EN AUGE (14430)", value: "14430" },
  { label: "DOUVRES LA DELIVRANDE (14440)", value: "14440" },
  { label: "DOUVRES LA DELIVRANDE (14440)", value: "14440" },
  { label: "DOZULE (14430)", value: "14430" },
  { label: "DRUBEC (14130)", value: "14130" },
  { label: "BEAUFOUR DRUVAL (14340)", value: "14340" },
  { label: "BEAUFOUR DRUVAL (14340)", value: "14340" },
  { label: "BEAUFOUR DRUVAL (14340)", value: "14340" },
  { label: "DUCY STE MARGUERITE (14250)", value: "14250" },
  { label: "ELLON (14250)", value: "14250" },
  { label: "EMIEVILLE (14630)", value: "14630" },
  { label: "ENGLESQUEVILLE EN AUGE (14800)", value: "14800" },
  { label: "ENGLESQUEVILLE LA PERCEE (14710)", value: "14710" },
  { label: "EPANEY (14170)", value: "14170" },
  { label: "EPINAY SUR ODON (14310)", value: "14310" },
  { label: "EPRON (14610)", value: "14610" },
  { label: "EQUEMAUVILLE (14600)", value: "14600" },
  { label: "ERAINES (14700)", value: "14700" },
  { label: "ERNES (14270)", value: "14270" },
  { label: "ESCOVILLE (14850)", value: "14850" },
  { label: "ESPINS (14220)", value: "14220" },
  { label: "ESQUAY NOTRE DAME (14210)", value: "14210" },
  { label: "ESQUAY SUR SEULLES (14400)", value: "14400" },
  { label: "ESSON (14220)", value: "14220" },
  { label: "ESTREES LA CAMPAGNE (14190)", value: "14190" },
  { label: "ETERVILLE (14930)", value: "14930" },
  { label: "ETREHAM (14400)", value: "14400" },
  { label: "EVRECY (14210)", value: "14210" },
  { label: "FALAISE (14700)", value: "14700" },
  { label: "FAUGUERNON (14100)", value: "14100" },
  { label: "LE FAULQ (14130)", value: "14130" },
  { label: "FEUGUEROLLES BULLY (14320)", value: "14320" },
  { label: "FEUGUEROLLES BULLY (14320)", value: "14320" },
  { label: "FIERVILLE LES PARCS (14130)", value: "14130" },
  { label: "FIRFOL (14100)", value: "14100" },
  { label: "FLEURY SUR ORNE (14123)", value: "14123" },
  { label: "LA FOLIE (14710)", value: "14710" },
  { label: "LA FOLLETIERE ABENON (14290)", value: "14290" },
  { label: "FONTAINE ETOUPEFOUR (14790)", value: "14790" },
  { label: "FONTAINE HENRY (14610)", value: "14610" },
  { label: "FONTAINE LE PIN (14190)", value: "14190" },
  { label: "FONTENAY LE MARMION (14320)", value: "14320" },
  { label: "FONTENAY LE PESNEL (14250)", value: "14250" },
  { label: "FORMENTIN (14340)", value: "14340" },
  { label: "FORMIGNY LA BATAILLE (14710)", value: "14710" },
  { label: "FORMIGNY LA BATAILLE (14710)", value: "14710" },
  { label: "FORMIGNY LA BATAILLE (14710)", value: "14710" },
  { label: "FORMIGNY LA BATAILLE (14710)", value: "14710" },
  { label: "FOULOGNES (14240)", value: "14240" },
  { label: "FOURCHES (14620)", value: "14620" },
  { label: "FOURNEAUX LE VAL (14700)", value: "14700" },
  { label: "LE FOURNET (14340)", value: "14340" },
  { label: "FOURNEVILLE (14600)", value: "14600" },
  { label: "FRENOUVILLE (14630)", value: "14630" },
  { label: "LE FRESNE CAMILLY (14480)", value: "14480" },
  { label: "FRESNE LA MERE (14700)", value: "14700" },
  { label: "FRESNEY LE PUCEUX (14680)", value: "14680" },
  { label: "FRESNEY LE VIEUX (14220)", value: "14220" },
  { label: "FUMICHON (14590)", value: "14590" },
  { label: "GAVRUS (14210)", value: "14210" },
  { label: "GEFOSSE FONTENAY (14230)", value: "14230" },
  { label: "GENNEVILLE (14600)", value: "14600" },
  { label: "GERROTS (14430)", value: "14430" },
  { label: "GIBERVILLE (14730)", value: "14730" },
  { label: "GLANVILLE (14950)", value: "14950" },
  { label: "GLOS (14100)", value: "14100" },
  { label: "GONNEVILLE SUR HONFLEUR (14600)", value: "14600" },
  { label: "GONNEVILLE SUR MER (14510)", value: "14510" },
  { label: "GONNEVILLE EN AUGE (14810)", value: "14810" },
  { label: "GOUSTRANVILLE (14430)", value: "14430" },
  { label: "GOUVIX (14680)", value: "14680" },
  { label: "GRAINVILLE LANGANNERIE (14190)", value: "14190" },
  { label: "GRAINVILLE SUR ODON (14210)", value: "14210" },
  { label: "GRANDCAMP MAISY (14450)", value: "14450" },
  { label: "GRANDCAMP MAISY (14450)", value: "14450" },
  { label: "GRANGUES (14160)", value: "14160" },
  { label: "GRAYE SUR MER (14470)", value: "14470" },
  { label: "GRENTHEVILLE (14540)", value: "14540" },
  { label: "GRIMBOSQ (14220)", value: "14220" },
  { label: "GUERON (14400)", value: "14400" },
  { label: "HERMANVILLE SUR MER (14880)", value: "14880" },
  { label: "HERMIVAL LES VAUX (14100)", value: "14100" },
  { label: "HEROUVILLE ST CLAIR (14200)", value: "14200" },
  { label: "HEROUVILLETTE (14850)", value: "14850" },
  { label: "HEULAND (14430)", value: "14430" },
  { label: "LA HOGUETTE (14700)", value: "14700" },
  { label: "HONFLEUR (14600)", value: "14600" },
  { label: "HONFLEUR (14600)", value: "14600" },
  { label: "L'HOTELLERIE (14100)", value: "14100" },
  { label: "HOTOT EN AUGE (14430)", value: "14430" },
  { label: "HOTOT EN AUGE (14430)", value: "14430" },
  { label: "HOTOT EN AUGE (14430)", value: "14430" },
  { label: "HOTTOT LES BAGUES (14250)", value: "14250" },
  { label: "LA HOUBLONNIERE (14340)", value: "14340" },
  { label: "HOULGATE (14510)", value: "14510" },
  { label: "IFS (14123)", value: "14123" },
  { label: "ISIGNY SUR MER (14230)", value: "14230" },
  { label: "ISIGNY SUR MER (14230)", value: "14230" },
  { label: "ISIGNY SUR MER (14230)", value: "14230" },
  { label: "ISIGNY SUR MER (14230)", value: "14230" },
  { label: "ISIGNY SUR MER (14330)", value: "14330" },
  { label: "LES ISLES BARDEL (14690)", value: "14690" },
  { label: "JANVILLE (14670)", value: "14670" },
  { label: "JORT (14170)", value: "14170" },
  { label: "JUAYE MONDAYE (14250)", value: "14250" },
  { label: "DIALAN SUR CHAINE (14260)", value: "14260" },
  { label: "DIALAN SUR CHAINE (14260)", value: "14260" },
  { label: "JUVIGNY SUR SEULLES (14250)", value: "14250" },
  { label: "LAIZE CLINCHAMPS (14320)", value: "14320" },
  { label: "LAIZE CLINCHAMPS (14320)", value: "14320" },
  { label: "LANDELLES ET COUPIGNY (14380)", value: "14380" },
  { label: "LANDELLES ET COUPIGNY (14380)", value: "14380" },
  { label: "LANDES SUR AJON (14310)", value: "14310" },
  { label: "LANGRUNE SUR MER (14830)", value: "14830" },
  { label: "PONTS SUR SEULLES (14480)", value: "14480" },
  { label: "PONTS SUR SEULLES (14480)", value: "14480" },
  { label: "PONTS SUR SEULLES (14480)", value: "14480" },
  { label: "TERRES DE DRUANCE (14770)", value: "14770" },
  { label: "TERRES DE DRUANCE (14770)", value: "14770" },
  { label: "TERRES DE DRUANCE (14770)", value: "14770" },
  { label: "LEAUPARTIE (14340)", value: "14340" },
  { label: "LEFFARD (14700)", value: "14700" },
  { label: "LESSARD ET LE CHENE (14140)", value: "14140" },
  { label: "LINGEVRES (14250)", value: "14250" },
  { label: "LION SUR MER (14780)", value: "14780" },
  { label: "LISIEUX (14100)", value: "14100" },
  { label: "LISON (14330)", value: "14330" },
  { label: "LISORES (14140)", value: "14140" },
  { label: "LITTEAU (14490)", value: "14490" },
  { label: "LE MOLAY LITTRY (14330)", value: "14330" },
  { label: "LE MOLAY LITTRY (14330)", value: "14330" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14140)", value: "14140" },
  { label: "LIVAROT PAYS D AUGE (14290)", value: "14290" },
  { label: "LIVAROT PAYS D AUGE (14290)", value: "14290" },
  { label: "LIVAROT PAYS D AUGE (14290)", value: "14290" },
  { label: "LIVAROT PAYS D AUGE (14290)", value: "14290" },
  { label: "LES LOGES (14240)", value: "14240" },
  { label: "LES LOGES SAULCES (14700)", value: "14700" },
  { label: "LONGUES SUR MER (14400)", value: "14400" },
  { label: "LONGUEVILLE (14230)", value: "14230" },
  { label: "LONGVILLERS (14310)", value: "14310" },
  { label: "LOUCELLES (14250)", value: "14250" },
  { label: "LOUVAGNY (14170)", value: "14170" },
  { label: "LOUVIGNY (14111)", value: "14111" },
  { label: "LUC SUR MER (14530)", value: "14530" },
  { label: "MAGNY EN BESSIN (14400)", value: "14400" },
  { label: "MAISONCELLES PELVEY (14310)", value: "14310" },
  { label: "MAISONCELLES SUR AJON (14210)", value: "14210" },
  { label: "MAISONS (14400)", value: "14400" },
  { label: "MAIZET (14210)", value: "14210" },
  { label: "MAIZIERES (14190)", value: "14190" },
  { label: "MALTOT (14930)", value: "14930" },
  { label: "MANDEVILLE EN BESSIN (14710)", value: "14710" },
  { label: "MANERBE (14340)", value: "14340" },
  { label: "MANNEVILLE LA PIPARD (14130)", value: "14130" },
  { label: "LE MANOIR (14400)", value: "14400" },
  { label: "MANVIEUX (14117)", value: "14117" },
  { label: "LE MARAIS LA CHAPELLE (14620)", value: "14620" },
  { label: "MAROLLES (14100)", value: "14100" },
  { label: "MARTAINVILLE (14220)", value: "14220" },
  { label: "MARTIGNY SUR L ANTE (14700)", value: "14700" },
  { label: "MOULINS EN BESSIN (14480)", value: "14480" },
  { label: "MOULINS EN BESSIN (14480)", value: "14480" },
  { label: "MOULINS EN BESSIN (14480)", value: "14480" },
  { label: "MOULINS EN BESSIN (14740)", value: "14740" },
  { label: "MATHIEU (14920)", value: "14920" },
  { label: "MAY SUR ORNE (14320)", value: "14320" },
  { label: "MERVILLE FRANCEVILLE PLAGE (14810)", value: "14810" },
  { label: "MERY BISSIERES EN AUGE (14370)", value: "14370" },
  { label: "MERY BISSIERES EN AUGE (14370)", value: "14370" },
  { label: "MESLAY (14220)", value: "14220" },
  { label: "LE MESNIL AU GRAIN (14260)", value: "14260" },
  { label: "LE MESNIL EUDES (14100)", value: "14100" },
  { label: "LE MESNIL GUILLAUME (14100)", value: "14100" },
  { label: "LE MESNIL ROBERT (14380)", value: "14380" },
  { label: "LE MESNIL SIMON (14140)", value: "14140" },
  { label: "LE MESNIL SUR BLANGY (14130)", value: "14130" },
  { label: "LE MESNIL VILLEMENT (14690)", value: "14690" },
  { label: "MEUVAINES (14960)", value: "14960" },
  { label: "MEZIDON VALLEE D AUGE (14140)", value: "14140" },
  { label: "MEZIDON VALLEE D AUGE (14140)", value: "14140" },
  { label: "MEZIDON VALLEE D AUGE (14140)", value: "14140" },
  { label: "MEZIDON VALLEE D AUGE (14140)", value: "14140" },
  { label: "MEZIDON VALLEE D AUGE (14140)", value: "14140" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14270)", value: "14270" },
  { label: "MEZIDON VALLEE D AUGE (14340)", value: "14340" },
  { label: "MEZIDON VALLEE D AUGE (14370)", value: "14370" },
  { label: "LES MONCEAUX (14100)", value: "14100" },
  { label: "MONCEAUX EN BESSIN (14400)", value: "14400" },
  { label: "MONDEVILLE (14120)", value: "14120" },
  { label: "MONDRAINVILLE (14210)", value: "14210" },
  { label: "MONFREVILLE (14230)", value: "14230" },
  { label: "MONTFIQUET (14490)", value: "14490" },
  { label: "MONTIGNY (14210)", value: "14210" },
  { label: "MONTREUIL EN AUGE (14340)", value: "14340" },
  { label: "MONTS EN BESSIN (14310)", value: "14310" },
  { label: "MORTEAUX COULIBOEUF (14620)", value: "14620" },
  { label: "MOSLES (14400)", value: "14400" },
  { label: "MOUEN (14790)", value: "14790" },
  { label: "MOULINES (14220)", value: "14220" },
  { label: "MOULT CHICHEBOVILLE (14370)", value: "14370" },
  { label: "MOULT CHICHEBOVILLE (14370)", value: "14370" },
  { label: "LES MOUTIERS EN AUGE (14620)", value: "14620" },
  { label: "LES MOUTIERS EN CINGLAIS (14220)", value: "14220" },
  { label: "MOYAUX (14590)", value: "14590" },
  { label: "MUTRECY (14220)", value: "14220" },
  { label: "NONANT (14400)", value: "14400" },
  { label: "NOROLLES (14100)", value: "14100" },
  { label: "NORON L ABBAYE (14700)", value: "14700" },
  { label: "NORON LA POTERIE (14490)", value: "14490" },
  { label: "NORREY EN AUGE (14620)", value: "14620" },
  { label: "NOTRE DAME DE LIVAYE (14340)", value: "14340" },
  { label: "NOTRE DAME D ESTREES CORBON (14340)", value: "14340" },
  { label: "NOTRE DAME D ESTREES CORBON (14340)", value: "14340" },
  { label: "VAL D ARRY (14210)", value: "14210" },
  { label: "VAL D ARRY (14210)", value: "14210" },
  { label: "VAL D ARRY (14210)", value: "14210" },
  { label: "VAL D ARRY (14310)", value: "14310" },
  { label: "OLENDON (14170)", value: "14170" },
  { label: "ORBEC (14290)", value: "14290" },
  { label: "OSMANVILLE (14230)", value: "14230" },
  { label: "OUEZY (14270)", value: "14270" },
  { label: "OUFFIERES (14220)", value: "14220" },
  { label: "OUILLY DU HOULEY (14590)", value: "14590" },
  { label: "OUILLY LE TESSON (14190)", value: "14190" },
  { label: "OUILLY LE VICOMTE (14100)", value: "14100" },
  { label: "OUISTREHAM (14150)", value: "14150" },
  { label: "PARFOURU SUR ODON (14310)", value: "14310" },
  { label: "PENNEDEPIE (14600)", value: "14600" },
  { label: "PERIERS EN AUGE (14160)", value: "14160" },
  { label: "PERIERS SUR LE DAN (14112)", value: "14112" },
  { label: "PERIGNY (14770)", value: "14770" },
  { label: "PERRIERES (14170)", value: "14170" },
  { label: "PERTHEVILLE NERS (14700)", value: "14700" },
  { label: "PETIVILLE (14390)", value: "14390" },
  { label: "PIERREFITTE EN AUGE (14130)", value: "14130" },
  { label: "PIERREFITTE EN CINGLAIS (14690)", value: "14690" },
  { label: "PIERREPONT (14690)", value: "14690" },
  { label: "LE PIN (14590)", value: "14590" },
  { label: "PLANQUERY (14490)", value: "14490" },
  { label: "PLUMETOT (14440)", value: "14440" },
  { label: "LA POMMERAYE (14690)", value: "14690" },
  { label: "PONT BELLANGER (14380)", value: "14380" },
  { label: "PONTECOULANT (14110)", value: "14110" },
  { label: "PONT L EVEQUE (14130)", value: "14130" },
  { label: "PONT L EVEQUE (14130)", value: "14130" },
  { label: "PORT EN BESSIN HUPPAIN (14520)", value: "14520" },
  { label: "PORT EN BESSIN HUPPAIN (14520)", value: "14520" },
  { label: "POTIGNY (14420)", value: "14420" },
  { label: "PREAUX BOCAGE (14210)", value: "14210" },
  { label: "LE PRE D AUGE (14340)", value: "14340" },
  { label: "PRETREVILLE (14140)", value: "14140" },
  { label: "PUTOT EN AUGE (14430)", value: "14430" },
  { label: "BELLE VIE EN AUGE (14270)", value: "14270" },
  { label: "BELLE VIE EN AUGE (14270)", value: "14270" },
  { label: "BELLE VIE EN AUGE (14270)", value: "14270" },
  { label: "BELLE VIE EN AUGE (14340)", value: "14340" },
  { label: "QUETTEVILLE (14130)", value: "14130" },
  { label: "RANCHY (14400)", value: "14400" },
  { label: "RANVILLE (14860)", value: "14860" },
  { label: "RAPILLY (14690)", value: "14690" },
  { label: "REPENTIGNY (14340)", value: "14340" },
  { label: "REUX (14130)", value: "14130" },
  { label: "REVIERS (14470)", value: "14470" },
  { label: "LA RIVIERE ST SAUVEUR (14600)", value: "14600" },
  { label: "CASTINE EN PLAINE (14540)", value: "14540" },
  { label: "CASTINE EN PLAINE (14540)", value: "14540" },
  { label: "CASTINE EN PLAINE (14540)", value: "14540" },
  { label: "ROCQUES (14100)", value: "14100" },
  { label: "LA ROQUE BAIGNARD (14340)", value: "14340" },
  { label: "ROSEL (14740)", value: "14740" },
  { label: "ROTS (14740)", value: "14740" },
  { label: "ROTS (14740)", value: "14740" },
  { label: "ROTS (14980)", value: "14980" },
  { label: "ROUVRES (14190)", value: "14190" },
  { label: "RUBERCY (14710)", value: "14710" },
  { label: "RUMESNIL (14340)", value: "14340" },
  { label: "RYES (14400)", value: "14400" },
  { label: "LE CASTELET (14540)", value: "14540" },
  { label: "LE CASTELET (14540)", value: "14540" },
  { label: "ST ANDRE D HEBERTOT (14130)", value: "14130" },
  { label: "ST ANDRE SUR ORNE (14320)", value: "14320" },
  { label: "ST ARNOULT (14800)", value: "14800" },
  { label: "ST AUBIN D ARQUENAY (14970)", value: "14970" },
  { label: "ST AUBIN DES BOIS (14380)", value: "14380" },
  { label: "ST AUBIN SUR MER (14750)", value: "14750" },
  { label: "ST BENOIT D HEBERTOT (14130)", value: "14130" },
  { label: "ST COME DE FRESNE (14960)", value: "14960" },
  { label: "ST CONTEST (14280)", value: "14280" },
  { label: "STE CROIX SUR MER (14480)", value: "14480" },
  { label: "VALORBIQUET (14290)", value: "14290" },
  { label: "VALORBIQUET (14290)", value: "14290" },
  { label: "VALORBIQUET (14290)", value: "14290" },
  { label: "VALORBIQUET (14290)", value: "14290" },
  { label: "VALORBIQUET (14290)", value: "14290" },
  { label: "ST DENIS DE MAILLOC (14100)", value: "14100" },
  { label: "ST DENIS DE MERE (14110)", value: "14110" },
  { label: "ST DESIR (14100)", value: "14100" },
  { label: "ST ETIENNE LA THILLAYE (14950)", value: "14950" },
  { label: "VAL DE VIE (14140)", value: "14140" },
  { label: "VAL DE VIE (14140)", value: "14140" },
  { label: "VAL DE VIE (14140)", value: "14140" },
  { label: "VAL DE VIE (14140)", value: "14140" },
  { label: "ST GATIEN DES BOIS (14130)", value: "14130" },
  { label: "SEULLINE (14260)", value: "14260" },
  { label: "SEULLINE (14260)", value: "14260" },
  { label: "SEULLINE (14310)", value: "14310" },
  { label: "ST GERMAIN DE LIVET (14100)", value: "14100" },
  { label: "ST GERMAIN DU PERT (14230)", value: "14230" },
  { label: "ST GERMAIN LA BLANCHE HERBE (14280)", value: "14280" },
  { label: "ST GERMAIN LANGOT (14700)", value: "14700" },
  { label: "ST GERMAIN LE VASSON (14190)", value: "14190" },
  { label: "STE HONORINE DE DUCY (14240)", value: "14240" },
  { label: "AURE SUR MER (14520)", value: "14520" },
  { label: "AURE SUR MER (14710)", value: "14710" },
  { label: "STE HONORINE DU FAY (14210)", value: "14210" },
  { label: "ST HYMER (14130)", value: "14130" },
  { label: "ST JEAN DE LIVET (14100)", value: "14100" },
  { label: "ST JOUIN (14430)", value: "14430" },
  { label: "ST JULIEN SUR CALONNE (14130)", value: "14130" },
  { label: "ST LAMBERT (14570)", value: "14570" },
  { label: "ST LAURENT DE CONDEL (14220)", value: "14220" },
  { label: "ST LAURENT SUR MER (14710)", value: "14710" },
  { label: "ST LEGER DUBOSQ (14430)", value: "14430" },
  { label: "ST LOUET SUR SEULLES (14310)", value: "14310" },
  { label: "ST LOUP HORS (14400)", value: "14400" },
  { label: "ST MANVIEU NORREY (14740)", value: "14740" },
  { label: "ST MANVIEU NORREY (14740)", value: "14740" },
  { label: "ST MARCOUF (14330)", value: "14330" },
  { label: "STE MARGUERITE D ELLE (14330)", value: "14330" },
  { label: "STE MARIE OUTRE L EAU (14380)", value: "14380" },
  { label: "ST MARTIN AUX CHARTRAINS (14130)", value: "14130" },
  { label: "ST MARTIN DE BIENFAITE LA CRESSONNIERE (14290)", value: "14290" },
  { label: "ST MARTIN DE BIENFAITE LA CRESSONNIERE (14290)", value: "14290" },
  { label: "ST MARTIN DE BLAGNY (14710)", value: "14710" },
  { label: "ST MARTIN DE FONTENAY (14320)", value: "14320" },
  { label: "ST MARTIN DE LA LIEUE (14100)", value: "14100" },
  { label: "ST MARTIN DE MAILLOC (14100)", value: "14100" },
  { label: "ST MARTIN DE MIEUX (14700)", value: "14700" },
  { label: "ST MARTIN DES ENTREES (14400)", value: "14400" },
  { label: "ST OMER (14220)", value: "14220" },
  { label: "ST OUEN DU MESNIL OGER (14670)", value: "14670" },
  { label: "ST OUEN LE PIN (14340)", value: "14340" },
  { label: "ST PAIR (14670)", value: "14670" },
  { label: "ST PAUL DU VERNAY (14490)", value: "14490" },
  { label: "ST PHILBERT DES CHAMPS (14130)", value: "14130" },
  { label: "ST PIERRE AZIF (14950)", value: "14950" },
  { label: "ST PIERRE CANIVET (14700)", value: "14700" },
  { label: "ST PIERRE DES IFS (14100)", value: "14100" },
  { label: "ST PIERRE DU BU (14700)", value: "14700" },
  { label: "ST PIERRE DU FRESNE (14260)", value: "14260" },
  { label: "ST PIERRE DU JONQUET (14670)", value: "14670" },
  { label: "ST PIERRE DU MONT (14450)", value: "14450" },
  { label: "ST PIERRE EN AUGE (14140)", value: "14140" },
  { label: "ST PIERRE EN AUGE (14140)", value: "14140" },
  { label: "ST PIERRE EN AUGE (14140)", value: "14140" },
  { label: "ST PIERRE EN AUGE (14140)", value: "14140" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST PIERRE EN AUGE (14170)", value: "14170" },
  { label: "ST REMY (14570)", value: "14570" },
  { label: "ST SAMSON (14670)", value: "14670" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "NOUES DE SIENNE (14380)", value: "14380" },
  { label: "ST SYLVAIN (14190)", value: "14190" },
  { label: "ST VAAST EN AUGE (14640)", value: "14640" },
  { label: "ST VAAST SUR SEULLES (14250)", value: "14250" },
  { label: "ST VIGOR LE GRAND (14400)", value: "14400" },
  { label: "SALLEN (14240)", value: "14240" },
  { label: "SALLENELLES (14121)", value: "14121" },
  { label: "SANNERVILLE (14940)", value: "14940" },
  { label: "SAON (14330)", value: "14330" },
  { label: "SAONNET (14330)", value: "14330" },
  { label: "SASSY (14170)", value: "14170" },
  { label: "VAL DE DROME (14240)", value: "14240" },
  { label: "VAL DE DROME (14240)", value: "14240" },
  { label: "VAL DE DROME (14350)", value: "14350" },
  { label: "VAL DE DROME (14350)", value: "14350" },
  { label: "SOIGNOLLES (14190)", value: "14190" },
  { label: "SOLIERS (14540)", value: "14540" },
  { label: "SOMMERVIEU (14400)", value: "14400" },
  { label: "SOULANGY (14700)", value: "14700" },
  { label: "SOUMONT ST QUENTIN (14420)", value: "14420" },
  { label: "SUBLES (14400)", value: "14400" },
  { label: "SULLY (14400)", value: "14400" },
  { label: "SURRAIN (14710)", value: "14710" },
  { label: "SURVILLE (14130)", value: "14130" },
  { label: "TESSEL (14250)", value: "14250" },
  { label: "THAON (14610)", value: "14610" },
  { label: "LE THEIL EN AUGE (14130)", value: "14130" },
  { label: "THURY HARCOURT LE HOM (14220)", value: "14220" },
  { label: "THURY HARCOURT LE HOM (14220)", value: "14220" },
  { label: "THURY HARCOURT LE HOM (14220)", value: "14220" },
  { label: "THURY HARCOURT LE HOM (14220)", value: "14220" },
  { label: "THURY HARCOURT LE HOM (14220)", value: "14220" },
  { label: "TILLY SUR SEULLES (14250)", value: "14250" },
  { label: "LE TORQUESNE (14130)", value: "14130" },
  { label: "TOUFFREVILLE (14940)", value: "14940" },
  { label: "TOUQUES (14800)", value: "14800" },
  { label: "TOUR EN BESSIN (14400)", value: "14400" },
  { label: "TOURGEVILLE (14800)", value: "14800" },
  { label: "TOURNIERES (14330)", value: "14330" },
  { label: "TOURVILLE EN AUGE (14130)", value: "14130" },
  { label: "TOURVILLE SUR ODON (14210)", value: "14210" },
  { label: "TRACY BOCAGE (14310)", value: "14310" },
  { label: "TRACY SUR MER (14117)", value: "14117" },
  { label: "TREPREL (14690)", value: "14690" },
  { label: "TREVIERES (14710)", value: "14710" },
  { label: "TROARN (14670)", value: "14670" },
  { label: "TROARN (14670)", value: "14670" },
  { label: "MONTILLIERES SUR ORNE (14210)", value: "14210" },
  { label: "MONTILLIERES SUR ORNE (14210)", value: "14210" },
  { label: "LE TRONQUAY (14490)", value: "14490" },
  { label: "TROUVILLE SUR MER (14360)", value: "14360" },
  { label: "TRUNGY (14490)", value: "14490" },
  { label: "URVILLE (14190)", value: "14190" },
  { label: "USSY (14420)", value: "14420" },
  { label: "VACOGNES NEUILLY (14210)", value: "14210" },
  { label: "VACOGNES NEUILLY (14210)", value: "14210" },
  { label: "VALSEME (14340)", value: "14340" },
  { label: "VARAVILLE (14390)", value: "14390" },
  { label: "VALDALLIERE (14350)", value: "14350" },
  { label: "VALDALLIERE (14350)", value: "14350" },
  { label: "VALDALLIERE (14350)", value: "14350" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VALDALLIERE (14410)", value: "14410" },
  { label: "VAUCELLES (14400)", value: "14400" },
  { label: "VAUVILLE (14800)", value: "14800" },
  { label: "VAUX SUR AURE (14400)", value: "14400" },
  { label: "VAUX SUR SEULLES (14400)", value: "14400" },
  { label: "VENDES (14250)", value: "14250" },
  { label: "VENDEUVRE (14170)", value: "14170" },
  { label: "VENDEUVRE (14170)", value: "14170" },
  { label: "VENDEUVRE (14170)", value: "14170" },
  { label: "VERSAINVILLE (14700)", value: "14700" },
  { label: "VERSON (14790)", value: "14790" },
  { label: "VER SUR MER (14114)", value: "14114" },
  { label: "LA VESPIERE FRIARDEL (14290)", value: "14290" },
  { label: "LA VESPIERE FRIARDEL (14290)", value: "14290" },
  { label: "LE VEY (14570)", value: "14570" },
  { label: "VICQUES (14170)", value: "14170" },
  { label: "VICTOT PONTFOL (14430)", value: "14430" },
  { label: "VIENNE EN BESSIN (14400)", value: "14400" },
  { label: "VIERVILLE SUR MER (14710)", value: "14710" },
  { label: "VIEUX (14930)", value: "14930" },
  { label: "VIEUX BOURG (14130)", value: "14130" },
  { label: "VIGNATS (14700)", value: "14700" },
  { label: "VILLERS BOCAGE (14310)", value: "14310" },
  { label: "VILLERS CANIVET (14420)", value: "14420" },
  { label: "VILLERS SUR MER (14640)", value: "14640" },
  { label: "VILLERVILLE (14113)", value: "14113" },
  { label: "LA VILLETTE (14570)", value: "14570" },
  { label: "VILLONS LES BUISSONS (14610)", value: "14610" },
  { label: "VILLY LEZ FALAISE (14700)", value: "14700" },
  { label: "VILLY BOCAGE (14310)", value: "14310" },
  { label: "VIMONT (14370)", value: "14370" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "VIRE NORMANDIE (14500)", value: "14500" },
  { label: "PONT D OUILLY (14690)", value: "14690" },
  { label: "ALLANCHE (15160)", value: "15160" },
  { label: "ALLEUZE (15100)", value: "15100" },
  { label: "ALLY (15700)", value: "15700" },
  { label: "ALLY (15700)", value: "15700" },
  { label: "ANDELAT (15100)", value: "15100" },
  { label: "ANGLARDS DE ST FLOUR (15100)", value: "15100" },
  { label: "ANGLARDS DE SALERS (15380)", value: "15380" },
  { label: "ANTERRIEUX (15110)", value: "15110" },
  { label: "ANTIGNAC (15240)", value: "15240" },
  { label: "APCHON (15400)", value: "15400" },
  { label: "ARCHES (15200)", value: "15200" },
  { label: "ARNAC (15150)", value: "15150" },
  { label: "ARPAJON SUR CERE (15130)", value: "15130" },
  { label: "AURIAC L EGLISE (15500)", value: "15500" },
  { label: "AURILLAC (15000)", value: "15000" },
  { label: "AUZERS (15240)", value: "15240" },
  { label: "AYRENS (15250)", value: "15250" },
  { label: "BADAILHAC (15800)", value: "15800" },
  { label: "BARRIAC LES BOSQUETS (15700)", value: "15700" },
  { label: "BASSIGNAC (15240)", value: "15240" },
  { label: "BEAULIEU (15270)", value: "15270" },
  { label: "BOISSET (15600)", value: "15600" },
  { label: "BONNAC (15500)", value: "15500" },
  { label: "BRAGEAC (15700)", value: "15700" },
  { label: "ALBEPIERRE BREDONS (15300)", value: "15300" },
  { label: "BREZONS (15230)", value: "15230" },
  { label: "PUYCAPEL (15340)", value: "15340" },
  { label: "PUYCAPEL (15340)", value: "15340" },
  { label: "CARLAT (15130)", value: "15130" },
  { label: "CASSANIOUZE (15340)", value: "15340" },
  { label: "CAYROLS (15290)", value: "15290" },
  { label: "CELOUX (15500)", value: "15500" },
  { label: "CEZENS (15230)", value: "15230" },
  { label: "CHALIERS (15320)", value: "15320" },
  { label: "CHALVIGNAC (15200)", value: "15200" },
  { label: "CHAMPAGNAC (15350)", value: "15350" },
  { label: "CHAMPS SUR TARENTAINE MARCHAL (15270)", value: "15270" },
  { label: "CHAMPS SUR TARENTAINE MARCHAL (15270)", value: "15270" },
  { label: "CHANTERELLE (15190)", value: "15190" },
  { label: "LA CHAPELLE D ALAGNON (15300)", value: "15300" },
  { label: "LA CHAPELLE LAURENT (15500)", value: "15500" },
  { label: "CHARMENSAC (15500)", value: "15500" },
  { label: "CHAUDES AIGUES (15110)", value: "15110" },
  { label: "CHAUSSENAC (15700)", value: "15700" },
  { label: "CHAZELLES (15500)", value: "15500" },
  { label: "CHEYLADE (15400)", value: "15400" },
  { label: "LE CLAUX (15400)", value: "15400" },
  { label: "CLAVIERES (15320)", value: "15320" },
  { label: "COLLANDRES (15400)", value: "15400" },
  { label: "COLTINES (15170)", value: "15170" },
  { label: "CONDAT (15190)", value: "15190" },
  { label: "COREN (15100)", value: "15100" },
  { label: "CRANDELLES (15250)", value: "15250" },
  { label: "CROS DE MONTVERT (15150)", value: "15150" },
  { label: "CROS DE RONESQUE (15130)", value: "15130" },
  { label: "CUSSAC (15430)", value: "15430" },
  { label: "DEUX VERGES (15110)", value: "15110" },
  { label: "DIENNE (15300)", value: "15300" },
  { label: "DRUGEAC (15140)", value: "15140" },
  { label: "ESCORAILLES (15700)", value: "15700" },
  { label: "ESPINASSE (15110)", value: "15110" },
  { label: "LE FALGOUX (15380)", value: "15380" },
  { label: "LE FAU (15140)", value: "15140" },
  { label: "FERRIERES ST MARY (15170)", value: "15170" },
  { label: "FONTANGES (15140)", value: "15140" },
  { label: "FREIX ANGLARDS (15310)", value: "15310" },
  { label: "FRIDEFONT (15110)", value: "15110" },
  { label: "GIOU DE MAMOU (15130)", value: "15130" },
  { label: "GIRGOLS (15310)", value: "15310" },
  { label: "GLENAT (15150)", value: "15150" },
  { label: "GOURDIEGES (15230)", value: "15230" },
  { label: "JABRUN (15110)", value: "15110" },
  { label: "JALEYRAC (15200)", value: "15200" },
  { label: "JOURSAC (15170)", value: "15170" },
  { label: "JOU SOUS MONJOU (15800)", value: "15800" },
  { label: "JUNHAC (15120)", value: "15120" },
  { label: "JUSSAC (15250)", value: "15250" },
  { label: "LABESSERETTE (15120)", value: "15120" },
  { label: "LABROUSSE (15130)", value: "15130" },
  { label: "LACAPELLE BARRES (15230)", value: "15230" },
  { label: "LACAPELLE DEL FRAISSE (15120)", value: "15120" },
  { label: "LACAPELLE VIESCAMP (15150)", value: "15150" },
  { label: "LADINHAC (15120)", value: "15120" },
  { label: "LAFEUILLADE EN VEZIE (15130)", value: "15130" },
  { label: "LANDEYRAT (15160)", value: "15160" },
  { label: "LANOBRE (15270)", value: "15270" },
  { label: "LANOBRE (15270)", value: "15270" },
  { label: "LAPEYRUGUE (15120)", value: "15120" },
  { label: "LAROQUEBROU (15150)", value: "15150" },
  { label: "LAROQUEVIEILLE (15250)", value: "15250" },
  { label: "LASCELLE (15590)", value: "15590" },
  { label: "LASTIC (15500)", value: "15500" },
  { label: "LAURIE (15500)", value: "15500" },
  { label: "LAVEISSENET (15300)", value: "15300" },
  { label: "LAVEISSIERE (15300)", value: "15300" },
  { label: "LAVEISSIERE (15300)", value: "15300" },
  { label: "LAVEISSIERE (15300)", value: "15300" },
  { label: "LAVIGERIE (15300)", value: "15300" },
  { label: "LEUCAMP (15120)", value: "15120" },
  { label: "LEYNHAC (15600)", value: "15600" },
  { label: "LEYVAUX (43450)", value: "43450" },
  { label: "LIEUTADES (15110)", value: "15110" },
  { label: "LORCIERES (15320)", value: "15320" },
  { label: "VAL D ARCOMIE (15320)", value: "15320" },
  { label: "VAL D ARCOMIE (15320)", value: "15320" },
  { label: "VAL D ARCOMIE (15320)", value: "15320" },
  { label: "VAL D ARCOMIE (15320)", value: "15320" },
  { label: "VAL D ARCOMIE (15320)", value: "15320" },
  { label: "LUGARDE (15190)", value: "15190" },
  { label: "MADIC (15210)", value: "15210" },
  { label: "MALBO (15230)", value: "15230" },
  { label: "MANDAILLES ST JULIEN (15590)", value: "15590" },
  { label: "MANDAILLES ST JULIEN (15590)", value: "15590" },
  { label: "MARCENAT (15190)", value: "15190" },
  { label: "MARCHASTEL (15400)", value: "15400" },
  { label: "MARCOLES (15220)", value: "15220" },
  { label: "MARMANHAC (15250)", value: "15250" },
  { label: "MASSIAC (15500)", value: "15500" },
  { label: "MAURIAC (15200)", value: "15200" },
  { label: "MAURINES (15110)", value: "15110" },
  { label: "MAURS (15600)", value: "15600" },
  { label: "MEALLET (15200)", value: "15200" },
  { label: "MENET (15400)", value: "15400" },
  { label: "MENTIERES (15100)", value: "15100" },
  { label: "MOLEDES (15500)", value: "15500" },
  { label: "MOLOMPIZE (15500)", value: "15500" },
  { label: "LA MONSELIE (15240)", value: "15240" },
  { label: "MONTBOUDIF (15190)", value: "15190" },
  { label: "MONTCHAMP (15100)", value: "15100" },
  { label: "LE MONTEIL (15240)", value: "15240" },
  { label: "MONTGRELEIX (15190)", value: "15190" },
  { label: "MONTMURAT (15600)", value: "15600" },
  { label: "MONTSALVY (15120)", value: "15120" },
  { label: "MONTVERT (15150)", value: "15150" },
  { label: "MOUSSAGES (15380)", value: "15380" },
  { label: "MURAT (15300)", value: "15300" },
  { label: "MURAT (15300)", value: "15300" },
  { label: "NARNHAC (15230)", value: "15230" },
  { label: "NAUCELLES (15250)", value: "15250" },
  { label: "NEUSSARGUES EN PINATELLE (15170)", value: "15170" },
  { label: "NEUSSARGUES EN PINATELLE (15170)", value: "15170" },
  { label: "NEUSSARGUES EN PINATELLE (15170)", value: "15170" },
  { label: "NEUSSARGUES EN PINATELLE (15170)", value: "15170" },
  { label: "NEUSSARGUES EN PINATELLE (15300)", value: "15300" },
  { label: "NEUVEGLISE SUR TRUYERE (15100)", value: "15100" },
  { label: "NEUVEGLISE SUR TRUYERE (15260)", value: "15260" },
  { label: "NEUVEGLISE SUR TRUYERE (15260)", value: "15260" },
  { label: "NEUVEGLISE SUR TRUYERE (15260)", value: "15260" },
  { label: "NIEUDAN (15150)", value: "15150" },
  { label: "OMPS (15290)", value: "15290" },
  { label: "PAILHEROLS (15800)", value: "15800" },
  { label: "PARLAN (15290)", value: "15290" },
  { label: "PAULHAC (15430)", value: "15430" },
  { label: "PAULHENC (15230)", value: "15230" },
  { label: "PEYRUSSE (15170)", value: "15170" },
  { label: "PIERREFORT (15230)", value: "15230" },
  { label: "PLEAUX (15700)", value: "15700" },
  { label: "PLEAUX (15700)", value: "15700" },
  { label: "PLEAUX (15700)", value: "15700" },
  { label: "PLEAUX (15700)", value: "15700" },
  { label: "POLMINHAC (15800)", value: "15800" },
  { label: "PRADIERS (15160)", value: "15160" },
  { label: "PRUNET (15130)", value: "15130" },
  { label: "QUEZAC (15600)", value: "15600" },
  { label: "RAGEADE (15500)", value: "15500" },
  { label: "RAULHAC (15800)", value: "15800" },
  { label: "REILHAC (15250)", value: "15250" },
  { label: "REZENTIERES (15170)", value: "15170" },
  { label: "RIOM ES MONTAGNES (15400)", value: "15400" },
  { label: "ROANNES ST MARY (15220)", value: "15220" },
  { label: "ROFFIAC (15100)", value: "15100" },
  { label: "ROUFFIAC (15150)", value: "15150" },
  { label: "ROUMEGOUX (15290)", value: "15290" },
  { label: "ROUZIERS (15600)", value: "15600" },
  { label: "RUYNES EN MARGERIDE (15320)", value: "15320" },
  { label: "SAIGNES (15240)", value: "15240" },
  { label: "ST AMANDIN (15190)", value: "15190" },
  { label: "ST ANTOINE (15220)", value: "15220" },
  { label: "ST BONNET DE CONDAT (15190)", value: "15190" },
  { label: "ST BONNET DE SALERS (15140)", value: "15140" },
  { label: "ST CERNIN (15310)", value: "15310" },
  { label: "ST CHAMANT (15140)", value: "15140" },
  { label: "ST CIRGUES DE JORDANNE (15590)", value: "15590" },
  { label: "ST CIRGUES DE MALBERT (15140)", value: "15140" },
  { label: "ST CLEMENT (15800)", value: "15800" },
  { label: "ST CONSTANT FOURNOULES (15600)", value: "15600" },
  { label: "ST CONSTANT FOURNOULES (15600)", value: "15600" },
  { label: "ST ETIENNE CANTALES (15150)", value: "15150" },
  { label: "ST ETIENNE DE CARLAT (15130)", value: "15130" },
  { label: "ST ETIENNE DE MAURS (15600)", value: "15600" },
  { label: "ST ETIENNE DE CHOMEIL (15400)", value: "15400" },
  { label: "STE EULALIE (15140)", value: "15140" },
  { label: "ST FLOUR (15100)", value: "15100" },
  { label: "ST GEORGES (15100)", value: "15100" },
  { label: "ST GERONS (15150)", value: "15150" },
  { label: "ST HIPPOLYTE (15400)", value: "15400" },
  { label: "ST ILLIDE (15310)", value: "15310" },
  { label: "ST JACQUES DES BLATS (15800)", value: "15800" },
  { label: "ST JULIEN DE TOURSAC (15600)", value: "15600" },
  { label: "ST MAMET LA SALVETAT (15220)", value: "15220" },
  { label: "STE MARIE (15230)", value: "15230" },
  { label: "ST MARTIAL (15110)", value: "15110" },
  { label: "ST MARTIN CANTALES (15140)", value: "15140" },
  { label: "ST MARTIN SOUS VIGOUROUX (15230)", value: "15230" },
  { label: "ST MARTIN VALMEROUX (15140)", value: "15140" },
  { label: "ST MARTIN VALMEROUX (15140)", value: "15140" },
  { label: "ST MARY LE PLAIN (15500)", value: "15500" },
  { label: "ST PAUL DES LANDES (15250)", value: "15250" },
  { label: "ST PAUL DE SALERS (15140)", value: "15140" },
  { label: "ST PIERRE (15350)", value: "15350" },
  { label: "ST PONCY (15500)", value: "15500" },
  { label: "ST PROJET DE SALERS (15140)", value: "15140" },
  { label: "ST REMY DE CHAUDES AIGUES (15110)", value: "15110" },
  { label: "ST SANTIN CANTALES (15150)", value: "15150" },
  { label: "ST SANTIN DE MAURS (15600)", value: "15600" },
  { label: "ST SATURNIN (15190)", value: "15190" },
  { label: "ST SAURY (15290)", value: "15290" },
  { label: "ST SIMON (15130)", value: "15130" },
  { label: "ST URCIZE (15110)", value: "15110" },
  { label: "ST VICTOR (15150)", value: "15150" },
  { label: "ST VINCENT DE SALERS (15380)", value: "15380" },
  { label: "SALERS (15140)", value: "15140" },
  { label: "SALINS (15200)", value: "15200" },
  { label: "SANSAC DE MARMIESSE (15130)", value: "15130" },
  { label: "SANSAC VEINAZES (15120)", value: "15120" },
  { label: "SAUVAT (15240)", value: "15240" },
  { label: "LA SEGALASSIERE (15290)", value: "15290" },
  { label: "SEGUR LES VILLAS (15300)", value: "15300" },
  { label: "SENEZERGUES (15340)", value: "15340" },
  { label: "SIRAN (15150)", value: "15150" },
  { label: "SOULAGES (15100)", value: "15100" },
  { label: "SOURNIAC (15200)", value: "15200" },
  { label: "TALIZAT (15170)", value: "15170" },
  { label: "TANAVELLE (15100)", value: "15100" },
  { label: "TEISSIERES DE CORNET (15250)", value: "15250" },
  { label: "TEISSIERES LES BOULIES (15130)", value: "15130" },
  { label: "LES TERNES (15100)", value: "15100" },
  { label: "THIEZAC (15800)", value: "15800" },
  { label: "TIVIERS (15100)", value: "15100" },
  { label: "TOURNEMIRE (15310)", value: "15310" },
  { label: "TREMOUILLE (15270)", value: "15270" },
  { label: "LA TRINITAT (15110)", value: "15110" },
  { label: "LE TRIOULOU (15600)", value: "15600" },
  { label: "TRIZAC (15400)", value: "15400" },
  { label: "USSEL (15300)", value: "15300" },
  { label: "VABRES (15100)", value: "15100" },
  { label: "VALETTE (15400)", value: "15400" },
  { label: "VALJOUZE (15170)", value: "15170" },
  { label: "VALUEJOLS (15300)", value: "15300" },
  { label: "LE VAULMIER (15380)", value: "15380" },
  { label: "VEBRET (15240)", value: "15240" },
  { label: "VEDRINES ST LOUP (15100)", value: "15100" },
  { label: "VELZIC (15590)", value: "15590" },
  { label: "VERNOLS (15160)", value: "15160" },
  { label: "VEYRIERES (15350)", value: "15350" },
  { label: "VEZAC (15130)", value: "15130" },
  { label: "VEZE (15160)", value: "15160" },
  { label: "VEZELS ROUSSY (15130)", value: "15130" },
  { label: "VIC SUR CERE (15800)", value: "15800" },
  { label: "VIEILLESPESSE (15500)", value: "15500" },
  { label: "VIEILLEVIE (15120)", value: "15120" },
  { label: "LE VIGEAN (15200)", value: "15200" },
  { label: "VILLEDIEU (15100)", value: "15100" },
  { label: "VIRARGUES (15300)", value: "15300" },
  { label: "VITRAC (15220)", value: "15220" },
  { label: "YDES (15210)", value: "15210" },
  { label: "YOLET (15130)", value: "15130" },
  { label: "YTRAC (15000)", value: "15000" },
  { label: "YTRAC (15130)", value: "15130" },
  { label: "LE ROUGET PERS (15290)", value: "15290" },
  { label: "LE ROUGET PERS (15290)", value: "15290" },
  { label: "BESSE (15140)", value: "15140" },
  { label: "ABZAC (16500)", value: "16500" },
  { label: "LES ADJOTS (16700)", value: "16700" },
  { label: "AGRIS (16110)", value: "16110" },
  { label: "AIGRE (16140)", value: "16140" },
  { label: "AIGRE (16140)", value: "16140" },
  { label: "ALLOUE (16490)", value: "16490" },
  { label: "AMBERAC (16140)", value: "16140" },
  { label: "AMBERNAC (16490)", value: "16490" },
  { label: "ANAIS (16560)", value: "16560" },
  { label: "ANGEAC CHAMPAGNE (16130)", value: "16130" },
  { label: "ANGEAC CHARENTE (16120)", value: "16120" },
  { label: "ANGEDUC (16300)", value: "16300" },
  { label: "ANGOULEME (16000)", value: "16000" },
  { label: "ANSAC SUR VIENNE (16500)", value: "16500" },
  { label: "ARS (16130)", value: "16130" },
  { label: "ASNIERES SUR NOUERE (16290)", value: "16290" },
  { label: "AUBETERRE SUR DRONNE (16390)", value: "16390" },
  { label: "AUNAC SUR CHARENTE (16460)", value: "16460" },
  { label: "AUNAC SUR CHARENTE (16460)", value: "16460" },
  { label: "AUNAC SUR CHARENTE (16460)", value: "16460" },
  { label: "AUSSAC VADALLE (16560)", value: "16560" },
  { label: "BAIGNES STE RADEGONDE (16360)", value: "16360" },
  { label: "BALZAC (16430)", value: "16430" },
  { label: "BARBEZIERES (16140)", value: "16140" },
  { label: "BARBEZIEUX ST HILAIRE (16300)", value: "16300" },
  { label: "BARBEZIEUX ST HILAIRE (16300)", value: "16300" },
  { label: "BARDENAC (16210)", value: "16210" },
  { label: "BARRET (16300)", value: "16300" },
  { label: "BARRO (16700)", value: "16700" },
  { label: "BASSAC (16120)", value: "16120" },
  { label: "BAZAC (16210)", value: "16210" },
  { label: "BEAULIEU SUR SONNETTE (16450)", value: "16450" },
  { label: "BECHERESSE (16250)", value: "16250" },
  { label: "BELLON (16210)", value: "16210" },
  { label: "BENEST (16350)", value: "16350" },
  { label: "BERNAC (16700)", value: "16700" },
  { label: "BERNEUIL (16480)", value: "16480" },
  { label: "BESSAC (16250)", value: "16250" },
  { label: "BESSE (16140)", value: "16140" },
  { label: "BIOUSSAC (16700)", value: "16700" },
  { label: "BIRAC (16120)", value: "16120" },
  { label: "COTEAUX DU BLANZACAIS (16250)", value: "16250" },
  { label: "COTEAUX DU BLANZACAIS (16250)", value: "16250" },
  { label: "COTEAUX DU BLANZACAIS (16250)", value: "16250" },
  { label: "COTEAUX DU BLANZACAIS (16250)", value: "16250" },
  { label: "COTEAUX DU BLANZACAIS (16250)", value: "16250" },
  { label: "BLANZAGUET ST CYBARD (16320)", value: "16320" },
  { label: "BOISBRETEAU (16480)", value: "16480" },
  { label: "BONNES (16390)", value: "16390" },
  { label: "BONNEUIL (16120)", value: "16120" },
  { label: "BORS DE MONTMOREAU (16190)", value: "16190" },
  { label: "BORS DE BAIGNES (16360)", value: "16360" },
  { label: "LE BOUCHAGE (16350)", value: "16350" },
  { label: "BOUEX (16410)", value: "16410" },
  { label: "BOURG CHARENTE (16200)", value: "16200" },
  { label: "BOUTEVILLE (16120)", value: "16120" },
  { label: "BOUTIERS ST TROJAN (16100)", value: "16100" },
  { label: "BRETTES (16240)", value: "16240" },
  { label: "BREVILLE (16370)", value: "16370" },
  { label: "BRIE (16590)", value: "16590" },
  { label: "BRIE SOUS BARBEZIEUX (16300)", value: "16300" },
  { label: "BRIE SOUS CHALAIS (16210)", value: "16210" },
  { label: "BRIGUEUIL (16420)", value: "16420" },
  { label: "BRILLAC (16500)", value: "16500" },
  { label: "BROSSAC (16480)", value: "16480" },
  { label: "BUNZAC (16110)", value: "16110" },
  { label: "CELLEFROUIN (16260)", value: "16260" },
  { label: "CELLETTES (16230)", value: "16230" },
  { label: "CHABANAIS (16150)", value: "16150" },
  { label: "CHABRAC (16150)", value: "16150" },
  { label: "CHADURIE (16250)", value: "16250" },
  { label: "CHALAIS (16210)", value: "16210" },
  { label: "CHALAIS (16210)", value: "16210" },
  { label: "CHALAIS (16210)", value: "16210" },
  { label: "CHALLIGNAC (16300)", value: "16300" },
  { label: "CHAMPAGNE VIGNY (16250)", value: "16250" },
  { label: "CHAMPAGNE MOUTON (16350)", value: "16350" },
  { label: "CHAMPMILLON (16290)", value: "16290" },
  { label: "CHAMPNIERS (16430)", value: "16430" },
  { label: "CHANTILLAC (16360)", value: "16360" },
  { label: "LA CHAPELLE (16140)", value: "16140" },
  { label: "BOISNE LA TUDE (16320)", value: "16320" },
  { label: "BOISNE LA TUDE (16320)", value: "16320" },
  { label: "BOISNE LA TUDE (16320)", value: "16320" },
  { label: "CHARME (16140)", value: "16140" },
  { label: "CHARRAS (16380)", value: "16380" },
  { label: "CHASSENEUIL SUR BONNIEURE (16260)", value: "16260" },
  { label: "CHASSENON (16150)", value: "16150" },
  { label: "CHASSIECQ (16350)", value: "16350" },
  { label: "CHASSORS (16200)", value: "16200" },
  { label: "CHATEAUBERNARD (16100)", value: "16100" },
  { label: "CHATEAUNEUF SUR CHARENTE (16120)", value: "16120" },
  { label: "CHATIGNAC (16480)", value: "16480" },
  { label: "CHAZELLES (16380)", value: "16380" },
  { label: "CHENON (16460)", value: "16460" },
  { label: "CHERVES CHATELARS (16310)", value: "16310" },
  { label: "VAL DE COGNAC (16370)", value: "16370" },
  { label: "VAL DE COGNAC (16370)", value: "16370" },
  { label: "VAL DE COGNAC (16370)", value: "16370" },
  { label: "LA CHEVRERIE (16240)", value: "16240" },
  { label: "CHILLAC (16480)", value: "16480" },
  { label: "CHIRAC (16150)", value: "16150" },
  { label: "CLAIX (16440)", value: "16440" },
  { label: "COGNAC (16100)", value: "16100" },
  { label: "COMBIERS (16320)", value: "16320" },
  { label: "CONDAC (16700)", value: "16700" },
  { label: "CONDEON (16360)", value: "16360" },
  { label: "CONFOLENS (16500)", value: "16500" },
  { label: "CONFOLENS (16500)", value: "16500" },
  { label: "COULGENS (16560)", value: "16560" },
  { label: "COULONGES (16330)", value: "16330" },
  { label: "COURBILLAC (16200)", value: "16200" },
  { label: "COURCOME (16240)", value: "16240" },
  { label: "COURCOME (16700)", value: "16700" },
  { label: "COURCOME (16700)", value: "16700" },
  { label: "COURGEAC (16190)", value: "16190" },
  { label: "COURLAC (16210)", value: "16210" },
  { label: "LA COURONNE (16400)", value: "16400" },
  { label: "COUTURE (16460)", value: "16460" },
  { label: "CRITEUIL LA MAGDELEINE (16300)", value: "16300" },
  { label: "CURAC (16210)", value: "16210" },
  { label: "DEVIAT (16190)", value: "16190" },
  { label: "DIGNAC (16410)", value: "16410" },
  { label: "DIRAC (16410)", value: "16410" },
  { label: "DOUZAT (16290)", value: "16290" },
  { label: "EBREON (16140)", value: "16140" },
  { label: "ECHALLAT (16170)", value: "16170" },
  { label: "ECURAS (16220)", value: "16220" },
  { label: "EDON (16320)", value: "16320" },
  { label: "EMPURE (16240)", value: "16240" },
  { label: "EPENEDE (16490)", value: "16490" },
  { label: "LES ESSARDS (16210)", value: "16210" },
  { label: "ESSE (16500)", value: "16500" },
  { label: "ETAGNAC (16150)", value: "16150" },
  { label: "ETRIAC (16250)", value: "16250" },
  { label: "EXIDEUIL SUR VIENNE (16150)", value: "16150" },
  { label: "EYMOUTHIERS (16220)", value: "16220" },
  { label: "LA FAYE (16700)", value: "16700" },
  { label: "FEUILLADE (16380)", value: "16380" },
  { label: "FLEAC (16730)", value: "16730" },
  { label: "FLEURAC (16200)", value: "16200" },
  { label: "FONTENILLE (16230)", value: "16230" },
  { label: "LA FORET DE TESSE (16240)", value: "16240" },
  { label: "FOUQUEBRUNE (16410)", value: "16410" },
  { label: "FOUQUEURE (16140)", value: "16140" },
  { label: "FOUSSIGNAC (16200)", value: "16200" },
  { label: "GARAT (16410)", value: "16410" },
  { label: "GARDES LE PONTAROUX (16320)", value: "16320" },
  { label: "GENAC BIGNAC (16170)", value: "16170" },
  { label: "GENAC BIGNAC (16170)", value: "16170" },
  { label: "GENSAC LA PALLUE (16130)", value: "16130" },
  { label: "GENTE (16130)", value: "16130" },
  { label: "GIMEUX (16130)", value: "16130" },
  { label: "MAINXE GONDEVILLE (16200)", value: "16200" },
  { label: "MAINXE GONDEVILLE (16200)", value: "16200" },
  { label: "GOND PONTOUVRE (16160)", value: "16160" },
  { label: "LES GOURS (16140)", value: "16140" },
  { label: "LE GRAND MADIEU (16450)", value: "16450" },
  { label: "GRASSAC (16380)", value: "16380" },
  { label: "GUIMPS (16300)", value: "16300" },
  { label: "GUIZENGEARD (16480)", value: "16480" },
  { label: "GURAT (16320)", value: "16320" },
  { label: "HIERSAC (16290)", value: "16290" },
  { label: "HIESSE (16490)", value: "16490" },
  { label: "HOULETTE (16200)", value: "16200" },
  { label: "L'ISLE D ESPAGNAC (16340)", value: "16340" },
  { label: "JARNAC (16200)", value: "16200" },
  { label: "JAULDES (16560)", value: "16560" },
  { label: "JAVREZAC (16100)", value: "16100" },
  { label: "JUIGNAC (16190)", value: "16190" },
  { label: "JUILLAC LE COQ (16130)", value: "16130" },
  { label: "JUILLE (16230)", value: "16230" },
  { label: "JULIENNE (16200)", value: "16200" },
  { label: "VAL DES VIGNES (16250)", value: "16250" },
  { label: "VAL DES VIGNES (16250)", value: "16250" },
  { label: "VAL DES VIGNES (16250)", value: "16250" },
  { label: "VAL DES VIGNES (16250)", value: "16250" },
  { label: "LACHAISE (16300)", value: "16300" },
  { label: "LADIVILLE (16120)", value: "16120" },
  { label: "LAGARDE SUR LE NE (16300)", value: "16300" },
  { label: "LAPRADE (16390)", value: "16390" },
  { label: "LESSAC (16500)", value: "16500" },
  { label: "LESTERPS (16420)", value: "16420" },
  { label: "LESIGNAC DURAND (16310)", value: "16310" },
  { label: "LICHERES (16460)", value: "16460" },
  { label: "LIGNE (16140)", value: "16140" },
  { label: "LIGNIERES AMBLEVILLE (16130)", value: "16130" },
  { label: "LIGNIERES AMBLEVILLE (16300)", value: "16300" },
  { label: "LINARS (16730)", value: "16730" },
  { label: "LE LINDOIS (16310)", value: "16310" },
  { label: "LONDIGNY (16700)", value: "16700" },
  { label: "LONGRE (16240)", value: "16240" },
  { label: "LONNES (16230)", value: "16230" },
  { label: "TERRES DE HAUTE CHARENTE (16270)", value: "16270" },
  { label: "TERRES DE HAUTE CHARENTE (16270)", value: "16270" },
  { label: "TERRES DE HAUTE CHARENTE (16270)", value: "16270" },
  { label: "TERRES DE HAUTE CHARENTE (16270)", value: "16270" },
  { label: "TERRES DE HAUTE CHARENTE (16270)", value: "16270" },
  { label: "TERRES DE HAUTE CHARENTE (16270)", value: "16270" },
  { label: "LOUZAC ST ANDRE (16100)", value: "16100" },
  { label: "LOUZAC ST ANDRE (16100)", value: "16100" },
  { label: "LUPSAULT (16140)", value: "16140" },
  { label: "LUSSAC (16450)", value: "16450" },
  { label: "LUXE (16230)", value: "16230" },
  { label: "LA MAGDELEINE (16240)", value: "16240" },
  { label: "MAGNAC LAVALETTE VILLARS (16320)", value: "16320" },
  { label: "MAGNAC SUR TOUVRE (16600)", value: "16600" },
  { label: "MAINE DE BOIXE (16230)", value: "16230" },
  { label: "MAINZAC (16380)", value: "16380" },
  { label: "BELLEVIGNE (16120)", value: "16120" },
  { label: "BELLEVIGNE (16120)", value: "16120" },
  { label: "BELLEVIGNE (16120)", value: "16120" },
  { label: "BELLEVIGNE (16120)", value: "16120" },
  { label: "BELLEVIGNE (16120)", value: "16120" },
  { label: "MANOT (16500)", value: "16500" },
  { label: "MANSLE LES FONTAINES (16230)", value: "16230" },
  { label: "MANSLE LES FONTAINES (16230)", value: "16230" },
  { label: "MARCILLAC LANVILLE (16140)", value: "16140" },
  { label: "MAREUIL (16170)", value: "16170" },
  { label: "MARILLAC LE FRANC (16110)", value: "16110" },
  { label: "MARSAC (16570)", value: "16570" },
  { label: "MARTHON (16380)", value: "16380" },
  { label: "MASSIGNAC (16310)", value: "16310" },
  { label: "MAZEROLLES (16310)", value: "16310" },
  { label: "MEDILLAC (16210)", value: "16210" },
  { label: "MERIGNAC (16200)", value: "16200" },
  { label: "MERPINS (16100)", value: "16100" },
  { label: "MESNAC (16370)", value: "16370" },
  { label: "LES METAIRIES (16200)", value: "16200" },
  { label: "MONS (16140)", value: "16140" },
  { label: "MONTBOYER (16620)", value: "16620" },
  { label: "MONTBRON (16220)", value: "16220" },
  { label: "MONTMERAC (16300)", value: "16300" },
  { label: "MONTMERAC (16300)", value: "16300" },
  { label: "MONTEMBOEUF (16310)", value: "16310" },
  { label: "MONTIGNAC CHARENTE (16330)", value: "16330" },
  { label: "MONTIGNAC LE COQ (16390)", value: "16390" },
  { label: "MONTJEAN (16240)", value: "16240" },
  { label: "MONTMOREAU (16190)", value: "16190" },
  { label: "MONTMOREAU (16190)", value: "16190" },
  { label: "MONTMOREAU (16190)", value: "16190" },
  { label: "MONTMOREAU (16190)", value: "16190" },
  { label: "MONTMOREAU (16190)", value: "16190" },
  { label: "MONTROLLET (16420)", value: "16420" },
  { label: "MORNAC (16600)", value: "16600" },
  { label: "MOSNAC ST SIMEUX (16120)", value: "16120" },
  { label: "MOSNAC ST SIMEUX (16120)", value: "16120" },
  { label: "MOULIDARS (16290)", value: "16290" },
  { label: "MOUTHIERS SUR BOEME (16440)", value: "16440" },
  { label: "MOUTON (16460)", value: "16460" },
  { label: "MOUTONNEAU (16460)", value: "16460" },
  { label: "MOUZON (16310)", value: "16310" },
  { label: "NABINAUD (16390)", value: "16390" },
  { label: "NANCLARS (16230)", value: "16230" },
  { label: "NANTEUIL EN VALLEE (16700)", value: "16700" },
  { label: "NANTEUIL EN VALLEE (16700)", value: "16700" },
  { label: "NANTEUIL EN VALLEE (16700)", value: "16700" },
  { label: "NANTEUIL EN VALLEE (16700)", value: "16700" },
  { label: "NANTEUIL EN VALLEE (16700)", value: "16700" },
  { label: "NANTEUIL EN VALLEE (16700)", value: "16700" },
  { label: "NERCILLAC (16200)", value: "16200" },
  { label: "NERSAC (16440)", value: "16440" },
  { label: "NIEUIL (16270)", value: "16270" },
  { label: "NONAC (16190)", value: "16190" },
  { label: "ORADOUR (16140)", value: "16140" },
  { label: "ORADOUR FANAIS (16500)", value: "16500" },
  { label: "ORGEDEUIL (16220)", value: "16220" },
  { label: "ORIOLLES (16480)", value: "16480" },
  { label: "ORIVAL (16210)", value: "16210" },
  { label: "PAIZAY NAUDOUIN EMBOURIE (16240)", value: "16240" },
  { label: "PAIZAY NAUDOUIN EMBOURIE (16240)", value: "16240" },
  { label: "PALLUAUD (16390)", value: "16390" },
  { label: "PARZAC (16450)", value: "16450" },
  { label: "PASSIRAC (16480)", value: "16480" },
  { label: "PERIGNAC (16250)", value: "16250" },
  { label: "PILLAC (16390)", value: "16390" },
  { label: "LES PINS (16260)", value: "16260" },
  { label: "PLASSAC ROUFFIAC (16250)", value: "16250" },
  { label: "PLEUVILLE (16490)", value: "16490" },
  { label: "POULLIGNAC (16190)", value: "16190" },
  { label: "POURSAC (16700)", value: "16700" },
  { label: "PRANZAC (16110)", value: "16110" },
  { label: "PRESSIGNAC (16150)", value: "16150" },
  { label: "PUYMOYEN (16400)", value: "16400" },
  { label: "PUYREAUX (16230)", value: "16230" },
  { label: "RAIX (16240)", value: "16240" },
  { label: "RANVILLE BREUILLAUD (16140)", value: "16140" },
  { label: "REIGNAC (16360)", value: "16360" },
  { label: "REPARSAC (16200)", value: "16200" },
  { label: "RIOUX MARTIN (16210)", value: "16210" },
  { label: "RIVIERES (16110)", value: "16110" },
  { label: "LA ROCHEFOUCAULD EN ANGOUMOIS (16110)", value: "16110" },
  { label: "LA ROCHEFOUCAULD EN ANGOUMOIS (16110)", value: "16110" },
  { label: "LA ROCHETTE (16110)", value: "16110" },
  { label: "RONSENAC (16320)", value: "16320" },
  { label: "ROUFFIAC (16210)", value: "16210" },
  { label: "ROUGNAC (16320)", value: "16320" },
  { label: "ROUILLAC (16170)", value: "16170" },
  { label: "ROUILLAC (16170)", value: "16170" },
  { label: "ROUILLAC (16170)", value: "16170" },
  { label: "ROUILLAC (16170)", value: "16170" },
  { label: "ROULLET ST ESTEPHE (16440)", value: "16440" },
  { label: "ROULLET ST ESTEPHE (16440)", value: "16440" },
  { label: "ROUSSINES (16310)", value: "16310" },
  { label: "ROUZEDE (16220)", value: "16220" },
  { label: "RUELLE SUR TOUVRE (16600)", value: "16600" },
  { label: "RUFFEC (16700)", value: "16700" },
  { label: "ST ADJUTORY (16310)", value: "16310" },
  { label: "ST AMANT DE BOIXE (16330)", value: "16330" },
  { label: "GRAVES ST AMANT (16120)", value: "16120" },
  { label: "GRAVES ST AMANT (16120)", value: "16120" },
  { label: "ST AMANT DE NOUERE (16170)", value: "16170" },
  { label: "VAL DE BONNIEURE (16230)", value: "16230" },
  { label: "VAL DE BONNIEURE (16230)", value: "16230" },
  { label: "VAL DE BONNIEURE (16230)", value: "16230" },
  { label: "ST AULAIS LA CHAPELLE (16300)", value: "16300" },
  { label: "ST AVIT (16210)", value: "16210" },
  { label: "ST BONNET (16300)", value: "16300" },
  { label: "ST BRICE (16100)", value: "16100" },
  { label: "ST CHRISTOPHE (16420)", value: "16420" },
  { label: "ST CIERS SUR BONNIEURE (16230)", value: "16230" },
  { label: "ST CLAUD (16450)", value: "16450" },
  { label: "ST COUTANT (16350)", value: "16350" },
  { label: "ST CYBARDEAUX (16170)", value: "16170" },
  { label: "ST FELIX (16480)", value: "16480" },
  { label: "ST FORT SUR LE NE (16130)", value: "16130" },
  { label: "ST FRAIGNE (16140)", value: "16140" },
  { label: "ST FRONT (16460)", value: "16460" },
  { label: "ST GENIS D HIERSAC (16570)", value: "16570" },
  { label: "ST GEORGES (16700)", value: "16700" },
  { label: "ST GERMAIN DE MONTBRON (16380)", value: "16380" },
  { label: "ST GOURSON (16700)", value: "16700" },
  { label: "ST GROUX (16230)", value: "16230" },
  { label: "ST LAURENT DE CERIS (16450)", value: "16450" },
  { label: "ST LAURENT DE COGNAC (16100)", value: "16100" },
  { label: "ST LAURENT DES COMBES (16480)", value: "16480" },
  { label: "ST MARTIAL (16190)", value: "16190" },
  { label: "ST MARTIN DU CLOCHER (16700)", value: "16700" },
  { label: "ST MARY (16260)", value: "16260" },
  { label: "ST MAURICE DES LIONS (16500)", value: "16500" },
  { label: "ST MEDARD (16300)", value: "16300" },
  { label: "VAL D AUGE (16170)", value: "16170" },
  { label: "VAL D AUGE (16170)", value: "16170" },
  { label: "VAL D AUGE (16170)", value: "16170" },
  { label: "VAL D AUGE (16170)", value: "16170" },
  { label: "VAL D AUGE (16170)", value: "16170" },
  { label: "VAL D AUGE (16170)", value: "16170" },
  { label: "ST MEME LES CARRIERES (16720)", value: "16720" },
  { label: "ST MICHEL (16470)", value: "16470" },
  { label: "ST PALAIS DU NE (16300)", value: "16300" },
  { label: "ST PREUIL (16130)", value: "16130" },
  { label: "ST QUENTIN SUR CHARENTE (16150)", value: "16150" },
  { label: "ST QUENTIN DE CHALAIS (16210)", value: "16210" },
  { label: "ST ROMAIN (16210)", value: "16210" },
  { label: "ST SATURNIN (16290)", value: "16290" },
  { label: "STE SEVERE (16200)", value: "16200" },
  { label: "ST SEVERIN (16390)", value: "16390" },
  { label: "ST SIMON (16120)", value: "16120" },
  { label: "ST SORNIN (16220)", value: "16220" },
  { label: "STE SOULINE (16480)", value: "16480" },
  { label: "ST SULPICE DE RUFFEC (16460)", value: "16460" },
  { label: "ST VALLIER (16480)", value: "16480" },
  { label: "ST YRIEIX SUR CHARENTE (16710)", value: "16710" },
  { label: "SALLES D ANGLES (16130)", value: "16130" },
  { label: "SALLES DE BARBEZIEUX (16300)", value: "16300" },
  { label: "SALLES DE VILLEFAGNAN (16700)", value: "16700" },
  { label: "SALLES LAVALETTE (16190)", value: "16190" },
  { label: "SAULGOND (16420)", value: "16420" },
  { label: "SAUVAGNAC (16310)", value: "16310" },
  { label: "SAUVIGNAC (16480)", value: "16480" },
  { label: "SEGONZAC (16130)", value: "16130" },
  { label: "SERS (16410)", value: "16410" },
  { label: "SIGOGNE (16200)", value: "16200" },
  { label: "SIREUIL (16440)", value: "16440" },
  { label: "SOUFFRIGNAC (16380)", value: "16380" },
  { label: "SOUVIGNE (16240)", value: "16240" },
  { label: "SOYAUX (16800)", value: "16800" },
  { label: "SUAUX (16260)", value: "16260" },
  { label: "LA TACHE (16260)", value: "16260" },
  { label: "TAIZE AIZIE (16700)", value: "16700" },
  { label: "TAPONNAT FLEURIGNAC (16110)", value: "16110" },
  { label: "LE TATRE (16360)", value: "16360" },
  { label: "THEIL RABIER (16240)", value: "16240" },
  { label: "TORSAC (16410)", value: "16410" },
  { label: "TOURRIERS (16560)", value: "16560" },
  { label: "TOUVERAC (16360)", value: "16360" },
  { label: "TOUVRE (16600)", value: "16600" },
  { label: "TRIAC LAUTRAIT (16200)", value: "16200" },
  { label: "TRIAC LAUTRAIT (16200)", value: "16200" },
  { label: "TROIS PALIS (16730)", value: "16730" },
  { label: "TURGON (16350)", value: "16350" },
  { label: "TUSSON (16140)", value: "16140" },
  { label: "VALENCE (16460)", value: "16460" },
  { label: "VARS (16330)", value: "16330" },
  { label: "VAUX LAVALETTE (16320)", value: "16320" },
  { label: "VAUX ROUILLAC (16170)", value: "16170" },
  { label: "VENTOUSE (16460)", value: "16460" },
  { label: "VERDILLE (16140)", value: "16140" },
  { label: "VERNEUIL (16310)", value: "16310" },
  { label: "VERRIERES (16130)", value: "16130" },
  { label: "VERTEUIL SUR CHARENTE (16510)", value: "16510" },
  { label: "VERVANT (16330)", value: "16330" },
  { label: "VIBRAC (16120)", value: "16120" },
  { label: "LE VIEUX CERIER (16350)", value: "16350" },
  { label: "VIEUX RUFFEC (16350)", value: "16350" },
  { label: "VIGNOLLES (16300)", value: "16300" },
  { label: "MOULINS SUR TARDOIRE (16110)", value: "16110" },
  { label: "MOULINS SUR TARDOIRE (16220)", value: "16220" },
  { label: "VILLEBOIS LAVALETTE (16320)", value: "16320" },
  { label: "VILLEFAGNAN (16240)", value: "16240" },
  { label: "VILLEJOUBERT (16560)", value: "16560" },
  { label: "VILLIERS LE ROUX (16240)", value: "16240" },
  { label: "VILLOGNON (16230)", value: "16230" },
  { label: "VINDELLE (16430)", value: "16430" },
  { label: "VITRAC ST VINCENT (16310)", value: "16310" },
  { label: "VOEUIL ET GIGET (16400)", value: "16400" },
  { label: "VOUHARTE (16330)", value: "16330" },
  { label: "VOULGEZAC (16250)", value: "16250" },
  { label: "VOUTHON (16220)", value: "16220" },
  { label: "VOUZAN (16410)", value: "16410" },
  { label: "XAMBES (16330)", value: "16330" },
  { label: "YVIERS (16210)", value: "16210" },
  { label: "YVRAC ET MALLEYRAND (16110)", value: "16110" },
  { label: "AGUDELLE (17500)", value: "17500" },
  { label: "AIGREFEUILLE D AUNIS (17290)", value: "17290" },
  { label: "ILE D AIX (17123)", value: "17123" },
  { label: "ALLAS BOCAGE (17150)", value: "17150" },
  { label: "ALLAS CHAMPAGNE (17500)", value: "17500" },
  { label: "ANAIS (17540)", value: "17540" },
  { label: "ANDILLY (17230)", value: "17230" },
  { label: "ANDILLY (17230)", value: "17230" },
  { label: "ANGLIERS (17540)", value: "17540" },
  { label: "ANGOULINS (17690)", value: "17690" },
  { label: "ANNEPONT (17350)", value: "17350" },
  { label: "ANNEZAY (17380)", value: "17380" },
  { label: "ANTEZANT LA CHAPELLE (17400)", value: "17400" },
  { label: "ANTEZANT LA CHAPELLE (17400)", value: "17400" },
  { label: "ARCES (17120)", value: "17120" },
  { label: "ARCHIAC (17520)", value: "17520" },
  { label: "ARCHINGEAY (17380)", value: "17380" },
  { label: "ARDILLIERES (17290)", value: "17290" },
  { label: "ARS EN RE (17590)", value: "17590" },
  { label: "ARTHENAC (17520)", value: "17520" },
  { label: "ARVERT (17530)", value: "17530" },
  { label: "ASNIERES LA GIRAUD (17400)", value: "17400" },
  { label: "AUJAC (17770)", value: "17770" },
  { label: "AULNAY (17470)", value: "17470" },
  { label: "AULNAY (17470)", value: "17470" },
  { label: "AUMAGNE (17770)", value: "17770" },
  { label: "AUMAGNE (17770)", value: "17770" },
  { label: "AUTHON EBEON (17770)", value: "17770" },
  { label: "AUTHON EBEON (17770)", value: "17770" },
  { label: "AVY (17800)", value: "17800" },
  { label: "AYTRE (17440)", value: "17440" },
  { label: "BAGNIZEAU (17160)", value: "17160" },
  { label: "BALANZAC (17600)", value: "17600" },
  { label: "BALLANS (17160)", value: "17160" },
  { label: "BALLON (17290)", value: "17290" },
  { label: "LA BARDE (17360)", value: "17360" },
  { label: "BARZAN (17120)", value: "17120" },
  { label: "BAZAUGES (17490)", value: "17490" },
  { label: "BEAUGEAY (17620)", value: "17620" },
  { label: "BEAUVAIS SUR MATHA (17490)", value: "17490" },
  { label: "BEDENAC (17210)", value: "17210" },
  { label: "BELLUIRE (17800)", value: "17800" },
  { label: "BENON (17170)", value: "17170" },
  { label: "BERCLOUX (17770)", value: "17770" },
  { label: "BERNAY ST MARTIN (17330)", value: "17330" },
  { label: "BERNAY ST MARTIN (17330)", value: "17330" },
  { label: "BERNEUIL (17460)", value: "17460" },
  { label: "BEURLAY (17250)", value: "17250" },
  { label: "BIGNAY (17400)", value: "17400" },
  { label: "BIRON (17800)", value: "17800" },
  { label: "BLANZAC LES MATHA (17160)", value: "17160" },
  { label: "BLANZAY SUR BOUTONNE (17470)", value: "17470" },
  { label: "BOIS (17240)", value: "17240" },
  { label: "LE BOIS PLAGE EN RE (17580)", value: "17580" },
  { label: "BOISREDON (17150)", value: "17150" },
  { label: "BORDS (17430)", value: "17430" },
  { label: "BORESSE ET MARTRON (17270)", value: "17270" },
  { label: "BOSCAMNANT (17360)", value: "17360" },
  { label: "BOUGNEAU (17800)", value: "17800" },
  { label: "BOUHET (17540)", value: "17540" },
  { label: "BOURCEFRANC LE CHAPUS (17560)", value: "17560" },
  { label: "BOURGNEUF (17220)", value: "17220" },
  { label: "BOUTENAC TOUVENT (17120)", value: "17120" },
  { label: "BRAN (17210)", value: "17210" },
  { label: "BRESDON (17490)", value: "17490" },
  { label: "BREUIL LA REORTE (17700)", value: "17700" },
  { label: "BREUILLET (17920)", value: "17920" },
  { label: "BREUIL MAGNE (17870)", value: "17870" },
  { label: "BRIE SOUS ARCHIAC (17520)", value: "17520" },
  { label: "BRIE SOUS MATHA (17160)", value: "17160" },
  { label: "BRIE SOUS MORTAGNE (17120)", value: "17120" },
  { label: "BRIVES SUR CHARENTE (17800)", value: "17800" },
  { label: "BRIZAMBOURG (17770)", value: "17770" },
  { label: "LA BROUSSE (17160)", value: "17160" },
  { label: "BURIE (17770)", value: "17770" },
  { label: "BUSSAC SUR CHARENTE (17100)", value: "17100" },
  { label: "BUSSAC FORET (17210)", value: "17210" },
  { label: "CABARIOT (17430)", value: "17430" },
  { label: "CELLES (17520)", value: "17520" },
  { label: "CERCOUX (17270)", value: "17270" },
  { label: "CHADENAC (17800)", value: "17800" },
  { label: "CHAILLEVETTE (17890)", value: "17890" },
  { label: "CHAMBON (17290)", value: "17290" },
  { label: "CHAMOUILLAC (17130)", value: "17130" },
  { label: "CHAMPAGNAC (17500)", value: "17500" },
  { label: "CHAMPAGNE (17620)", value: "17620" },
  { label: "CHAMPAGNOLLES (17240)", value: "17240" },
  { label: "CHAMPDOLENT (17430)", value: "17430" },
  { label: "CHANIERS (17610)", value: "17610" },
  { label: "CHANTEMERLE SUR LA SOIE (17380)", value: "17380" },
  { label: "LA CHAPELLE DES POTS (17100)", value: "17100" },
  { label: "CHARRON (17230)", value: "17230" },
  { label: "CHARRON (17230)", value: "17230" },
  { label: "CHARTUZAC (17130)", value: "17130" },
  { label: "LE CHATEAU D OLERON (17480)", value: "17480" },
  { label: "LE CHATEAU D OLERON (17480)", value: "17480" },
  { label: "LE CHATEAU D OLERON (17480)", value: "17480" },
  { label: "LE CHATEAU D OLERON (17480)", value: "17480" },
  { label: "CHATELAILLON PLAGE (17340)", value: "17340" },
  { label: "CHATENET (17210)", value: "17210" },
  { label: "CHAUNAC (17130)", value: "17130" },
  { label: "LE CHAY (17600)", value: "17600" },
  { label: "CHENAC ST SEURIN D UZET (17120)", value: "17120" },
  { label: "CHEPNIERS (17210)", value: "17210" },
  { label: "CHERAC (17610)", value: "17610" },
  { label: "CHERBONNIERES (17470)", value: "17470" },
  { label: "CHERMIGNAC (17460)", value: "17460" },
  { label: "CHEVANCEAUX (17210)", value: "17210" },
  { label: "CHIVES (17510)", value: "17510" },
  { label: "CIERZAC (17520)", value: "17520" },
  { label: "CIRE D AUNIS (17290)", value: "17290" },
  { label: "CLAM (17500)", value: "17500" },
  { label: "CLAVETTE (17220)", value: "17220" },
  { label: "CLERAC (17270)", value: "17270" },
  { label: "CLION (17240)", value: "17240" },
  { label: "LA CLISSE (17600)", value: "17600" },
  { label: "LA CLOTTE (17360)", value: "17360" },
  { label: "COIVERT (17330)", value: "17330" },
  { label: "COLOMBIERS (17460)", value: "17460" },
  { label: "CONSAC (17150)", value: "17150" },
  { label: "CONTRE (17470)", value: "17470" },
  { label: "CORIGNAC (17130)", value: "17130" },
  { label: "CORME ECLUSE (17600)", value: "17600" },
  { label: "CORME ROYAL (17600)", value: "17600" },
  { label: "LA COUARDE SUR MER (17670)", value: "17670" },
  { label: "COULONGES (17800)", value: "17800" },
  { label: "COURANT (17330)", value: "17330" },
  { label: "COURCELLES (17400)", value: "17400" },
  { label: "COURCERAC (17160)", value: "17160" },
  { label: "COURCON (17170)", value: "17170" },
  { label: "COURCOURY (17100)", value: "17100" },
  { label: "COURPIGNAC (17130)", value: "17130" },
  { label: "COUX (17130)", value: "17130" },
  { label: "COZES (17120)", value: "17120" },
  { label: "CRAM CHABAN (17170)", value: "17170" },
  { label: "CRAVANS (17260)", value: "17260" },
  { label: "CRAZANNES (17350)", value: "17350" },
  { label: "CRESSE (17160)", value: "17160" },
  { label: "CROIX CHAPEAU (17220)", value: "17220" },
  { label: "LA CROIX COMTESSE (17330)", value: "17330" },
  { label: "DAMPIERRE SUR BOUTONNE (17470)", value: "17470" },
  { label: "DOEUIL SUR LE MIGNON (17330)", value: "17330" },
  { label: "DOLUS D OLERON (17550)", value: "17550" },
  { label: "DOMPIERRE SUR CHARENTE (17610)", value: "17610" },
  { label: "DOMPIERRE SUR MER (17139)", value: "17139" },
  { label: "DOMPIERRE SUR MER (17139)", value: "17139" },
  { label: "LE DOUHET (17100)", value: "17100" },
  { label: "ECHEBRUNE (17800)", value: "17800" },
  { label: "ECHILLAIS (17620)", value: "17620" },
  { label: "ECHILLAIS (17620)", value: "17620" },
  { label: "ECOYEUX (17770)", value: "17770" },
  { label: "ECURAT (17810)", value: "17810" },
  { label: "LES EDUTS (17510)", value: "17510" },
  { label: "LES EGLISES D ARGENTEUIL (17400)", value: "17400" },
  { label: "L'EGUILLE (17600)", value: "17600" },
  { label: "EPARGNES (17120)", value: "17120" },
  { label: "ESNANDES (17137)", value: "17137" },
  { label: "LES ESSARDS (17250)", value: "17250" },
  { label: "ETAULES (17750)", value: "17750" },
  { label: "EXPIREMONT (17130)", value: "17130" },
  { label: "FENIOUX (17350)", value: "17350" },
  { label: "FERRIERES (17170)", value: "17170" },
  { label: "FLEAC SUR SEUGNE (17800)", value: "17800" },
  { label: "FLOIRAC (17120)", value: "17120" },
  { label: "FLOIRAC (17240)", value: "17240" },
  { label: "LA FLOTTE (17630)", value: "17630" },
  { label: "FONTAINE CHALENDRAY (17510)", value: "17510" },
  { label: "FONTAINES D OZILLAC (17500)", value: "17500" },
  { label: "FONTCOUVERTE (17100)", value: "17100" },
  { label: "FONTENET (17400)", value: "17400" },
  { label: "FORGES (17290)", value: "17290" },
  { label: "FORGES (17290)", value: "17290" },
  { label: "LE FOUILLOUX (17270)", value: "17270" },
  { label: "FOURAS (17450)", value: "17450" },
  { label: "GEAY (17250)", value: "17250" },
  { label: "GEMOZAC (17260)", value: "17260" },
  { label: "LA GENETOUZE (17360)", value: "17360" },
  { label: "GENOUILLE (17430)", value: "17430" },
  { label: "GERMIGNAC (17520)", value: "17520" },
  { label: "GIBOURNE (17160)", value: "17160" },
  { label: "LE GICQ (17160)", value: "17160" },
  { label: "GIVREZAC (17260)", value: "17260" },
  { label: "LES GONDS (17100)", value: "17100" },
  { label: "GOURVILLETTE (17490)", value: "17490" },
  { label: "GRANDJEAN (17350)", value: "17350" },
  { label: "LA GREVE SUR MIGNON (17170)", value: "17170" },
  { label: "GREZAC (17120)", value: "17120" },
  { label: "LA GRIPPERIE ST SYMPHORIEN (17620)", value: "17620" },
  { label: "LE GUA (17600)", value: "17600" },
  { label: "LE GUE D ALLERE (17540)", value: "17540" },
  { label: "GUITINIERES (17500)", value: "17500" },
  { label: "HAIMPS (17160)", value: "17160" },
  { label: "L'HOUMEAU (17137)", value: "17137" },
  { label: "LA JARD (17460)", value: "17460" },
  { label: "JARNAC CHAMPAGNE (17520)", value: "17520" },
  { label: "LA JARNE (17220)", value: "17220" },
  { label: "LA JARRIE (17220)", value: "17220" },
  { label: "LA JARRIE AUDOUIN (17330)", value: "17330" },
  { label: "JAZENNES (17260)", value: "17260" },
  { label: "JONZAC (17500)", value: "17500" },
  { label: "JUICQ (17770)", value: "17770" },
  { label: "JUSSAS (17130)", value: "17130" },
  { label: "LAGORD (17140)", value: "17140" },
  { label: "LA LAIGNE (17170)", value: "17170" },
  { label: "LANDES (17380)", value: "17380" },
  { label: "LANDRAIS (17290)", value: "17290" },
  { label: "LEOVILLE (17500)", value: "17500" },
  { label: "LOIRE LES MARAIS (17870)", value: "17870" },
  { label: "LOIRE SUR NIE (17470)", value: "17470" },
  { label: "LOIX (17111)", value: "17111" },
  { label: "LONGEVES (17230)", value: "17230" },
  { label: "LONZAC (17520)", value: "17520" },
  { label: "LORIGNAC (17240)", value: "17240" },
  { label: "LOULAY (17330)", value: "17330" },
  { label: "LOUZIGNAC (17160)", value: "17160" },
  { label: "LOZAY (17330)", value: "17330" },
  { label: "LUCHAT (17600)", value: "17600" },
  { label: "LUSSAC (17500)", value: "17500" },
  { label: "LUSSANT (17430)", value: "17430" },
  { label: "MACQUEVILLE (17490)", value: "17490" },
  { label: "MARANS (17230)", value: "17230" },
  { label: "MARENNES HIERS BROUAGE (17320)", value: "17320" },
  { label: "MARENNES HIERS BROUAGE (17320)", value: "17320" },
  { label: "MARENNES HIERS BROUAGE (17320)", value: "17320" },
  { label: "MARIGNAC (17800)", value: "17800" },
  { label: "MARSAIS (17700)", value: "17700" },
  { label: "MARSAIS (17700)", value: "17700" },
  { label: "MARSILLY (17137)", value: "17137" },
  { label: "MASSAC (17490)", value: "17490" },
  { label: "MATHA (17160)", value: "17160" },
  { label: "LES MATHES (17570)", value: "17570" },
  { label: "MAZERAY (17400)", value: "17400" },
  { label: "MAZEROLLES (17800)", value: "17800" },
  { label: "MEDIS (17600)", value: "17600" },
  { label: "MERIGNAC (17210)", value: "17210" },
  { label: "MESCHERS SUR GIRONDE (17132)", value: "17132" },
  { label: "MESSAC (17130)", value: "17130" },
  { label: "MEURSAC (17120)", value: "17120" },
  { label: "MEUX (17500)", value: "17500" },
  { label: "MIGRE (17330)", value: "17330" },
  { label: "MIGRON (17770)", value: "17770" },
  { label: "MIRAMBEAU (17150)", value: "17150" },
  { label: "MIRAMBEAU (17150)", value: "17150" },
  { label: "MOEZE (17780)", value: "17780" },
  { label: "MONS (17160)", value: "17160" },
  { label: "MONTENDRE (17130)", value: "17130" },
  { label: "MONTENDRE (17130)", value: "17130" },
  { label: "MONTENDRE (17130)", value: "17130" },
  { label: "MONTGUYON (17270)", value: "17270" },
  { label: "MONTILS (17800)", value: "17800" },
  { label: "MONTLIEU LA GARDE (17210)", value: "17210" },
  { label: "MONTPELLIER DE MEDILLAN (17260)", value: "17260" },
  { label: "MONTROY (17220)", value: "17220" },
  { label: "MORAGNE (17430)", value: "17430" },
  { label: "MORNAC SUR SEUDRE (17113)", value: "17113" },
  { label: "MORTAGNE SUR GIRONDE (17120)", value: "17120" },
  { label: "MORTIERS (17500)", value: "17500" },
  { label: "MOSNAC (17240)", value: "17240" },
  { label: "LE MUNG (17350)", value: "17350" },
  { label: "MURON (17430)", value: "17430" },
  { label: "NACHAMPS (17380)", value: "17380" },
  { label: "NANCRAS (17600)", value: "17600" },
  { label: "NANTILLE (17770)", value: "17770" },
  { label: "NERE (17510)", value: "17510" },
  { label: "NEUILLAC (17520)", value: "17520" },
  { label: "NEULLES (17500)", value: "17500" },
  { label: "NEUVICQ (17270)", value: "17270" },
  { label: "NEUVICQ LE CHATEAU (17490)", value: "17490" },
  { label: "NIEUL LES SAINTES (17810)", value: "17810" },
  { label: "NIEUL LE VIROUIL (17150)", value: "17150" },
  { label: "NIEUL SUR MER (17137)", value: "17137" },
  { label: "NIEUL SUR MER (17137)", value: "17137" },
  { label: "NIEULLE SUR SEUDRE (17600)", value: "17600" },
  { label: "LES NOUILLERS (17380)", value: "17380" },
  { label: "NUAILLE D AUNIS (17540)", value: "17540" },
  { label: "NUAILLE SUR BOUTONNE (17470)", value: "17470" },
  { label: "ORIGNOLLES (17210)", value: "17210" },
  { label: "OZILLAC (17500)", value: "17500" },
  { label: "PAILLE (17470)", value: "17470" },
  { label: "PERIGNAC (17800)", value: "17800" },
  { label: "PERIGNY (17180)", value: "17180" },
  { label: "PESSINES (17810)", value: "17810" },
  { label: "LE PIN (17210)", value: "17210" },
  { label: "ESSOUVERT (17400)", value: "17400" },
  { label: "ESSOUVERT (17400)", value: "17400" },
  { label: "PISANY (17600)", value: "17600" },
  { label: "PLASSAC (17240)", value: "17240" },
  { label: "PLASSAY (17250)", value: "17250" },
  { label: "POLIGNAC (17210)", value: "17210" },
  { label: "POMMIERS MOULONS (17130)", value: "17130" },
  { label: "POMMIERS MOULONS (17130)", value: "17130" },
  { label: "PONS (17800)", value: "17800" },
  { label: "PONT L ABBE D ARNOULT (17250)", value: "17250" },
  { label: "PORT D ENVAUX (17350)", value: "17350" },
  { label: "LES PORTES EN RE (17880)", value: "17880" },
  { label: "POUILLAC (17210)", value: "17210" },
  { label: "POURSAY GARNAUD (17400)", value: "17400" },
  { label: "PREGUILLAC (17460)", value: "17460" },
  { label: "PRIGNAC (17160)", value: "17160" },
  { label: "PUILBOREAU (17138)", value: "17138" },
  { label: "PUY DU LAC (17380)", value: "17380" },
  { label: "PUYRAVAULT (17700)", value: "17700" },
  { label: "PUYROLLAND (17380)", value: "17380" },
  { label: "REAUX SUR TREFLE (17500)", value: "17500" },
  { label: "REAUX SUR TREFLE (17500)", value: "17500" },
  { label: "REAUX SUR TREFLE (17500)", value: "17500" },
  { label: "RETAUD (17460)", value: "17460" },
  { label: "RIVEDOUX PLAGE (17940)", value: "17940" },
  { label: "RIOUX (17460)", value: "17460" },
  { label: "ROCHEFORT (17300)", value: "17300" },
  { label: "LA ROCHELLE (17000)", value: "17000" },
  { label: "LA ROCHELLE (17000)", value: "17000" },
  { label: "LA ROCHELLE (17000)", value: "17000" },
  { label: "ROMAZIERES (17510)", value: "17510" },
  { label: "ROMEGOUX (17250)", value: "17250" },
  { label: "LA RONDE (17170)", value: "17170" },
  { label: "ROUFFIAC (17800)", value: "17800" },
  { label: "ROUFFIGNAC (17130)", value: "17130" },
  { label: "ROYAN (17200)", value: "17200" },
  { label: "SABLONCEAUX (17600)", value: "17600" },
  { label: "ST AGNANT (17620)", value: "17620" },
  { label: "ST AIGULIN (17360)", value: "17360" },
  { label: "ST ANDRE DE LIDON (17260)", value: "17260" },
  { label: "ST AUGUSTIN (17570)", value: "17570" },
  { label: "ST BONNET SUR GIRONDE (17150)", value: "17150" },
  { label: "ST BRIS DES BOIS (17770)", value: "17770" },
  { label: "ST CESAIRE (17770)", value: "17770" },
  { label: "ST CHRISTOPHE (17220)", value: "17220" },
  { label: "ST CIERS CHAMPAGNE (17520)", value: "17520" },
  { label: "ST CIERS DU TAILLON (17240)", value: "17240" },
  { label: "ST CLEMENT DES BALEINES (17590)", value: "17590" },
  { label: "ST CLEMENT DES BALEINES (17590)", value: "17590" },
  { label: "STE COLOMBE (17210)", value: "17210" },
  { label: "ST COUTANT LE GRAND (17430)", value: "17430" },
  { label: "ST CREPIN (17380)", value: "17380" },
  { label: "ST CYR DU DORET (17170)", value: "17170" },
  { label: "ST DENIS D OLERON (17650)", value: "17650" },
  { label: "ST DIZANT DU BOIS (17150)", value: "17150" },
  { label: "ST DIZANT DU GUA (17240)", value: "17240" },
  { label: "ST EUGENE (17520)", value: "17520" },
  { label: "ST FELIX (17330)", value: "17330" },
  { label: "ST FORT SUR GIRONDE (17240)", value: "17240" },
  { label: "ST FROULT (17780)", value: "17780" },
  { label: "STE GEMME (17250)", value: "17250" },
  { label: "ST GENIS DE SAINTONGE (17240)", value: "17240" },
  { label: "ST GEORGES ANTIGNAC (17240)", value: "17240" },
  { label: "ST GEORGES ANTIGNAC (17240)", value: "17240" },
  { label: "ST GEORGES DE DIDONNE (17110)", value: "17110" },
  { label: "ST GEORGES DE LONGUEPIERRE (17470)", value: "17470" },
  { label: "ST GEORGES DES AGOUTS (17150)", value: "17150" },
  { label: "ST GEORGES DES COTEAUX (17810)", value: "17810" },
  { label: "ST GEORGES D OLERON (17190)", value: "17190" },
  { label: "ST GEORGES D OLERON (17190)", value: "17190" },
  { label: "ST GEORGES D OLERON (17190)", value: "17190" },
  { label: "ST GEORGES D OLERON (17190)", value: "17190" },
  { label: "ST GEORGES D OLERON (17190)", value: "17190" },
  { label: "ST GEORGES D OLERON (17190)", value: "17190" },
  { label: "ST GEORGES DU BOIS (17700)", value: "17700" },
  { label: "ST GERMAIN DE LUSIGNAN (17500)", value: "17500" },
  { label: "ST PIERRE LA NOUE (17700)", value: "17700" },
  { label: "ST PIERRE LA NOUE (17700)", value: "17700" },
  { label: "ST GERMAIN DE VIBRAC (17500)", value: "17500" },
  { label: "ST GERMAIN DU SEUDRE (17240)", value: "17240" },
  { label: "ST GREGOIRE D ARDENNES (17240)", value: "17240" },
  { label: "ST HILAIRE DE VILLEFRANCHE (17770)", value: "17770" },
  { label: "ST HILAIRE DE VILLEFRANCHE (17770)", value: "17770" },
  { label: "ST HILAIRE DU BOIS (17500)", value: "17500" },
  { label: "ST HIPPOLYTE (17430)", value: "17430" },
  { label: "ST JEAN D ANGELY (17400)", value: "17400" },
  { label: "ST JEAN D ANGLE (17620)", value: "17620" },
  { label: "ST JEAN DE LIVERSAY (17170)", value: "17170" },
  { label: "ST JULIEN DE L ESCAP (17400)", value: "17400" },
  { label: "ST JUST LUZAC (17320)", value: "17320" },
  { label: "ST JUST LUZAC (17320)", value: "17320" },
  { label: "ST LAURENT DE LA PREE (17450)", value: "17450" },
  { label: "ST LEGER (17800)", value: "17800" },
  { label: "STE LHEURINE (17520)", value: "17520" },
  { label: "ST LOUP (17380)", value: "17380" },
  { label: "ST MAIGRIN (17520)", value: "17520" },
  { label: "ST MANDE SUR BREDOIRE (17470)", value: "17470" },
  { label: "ST MARD (17700)", value: "17700" },
  { label: "STE MARIE DE RE (17740)", value: "17740" },
  { label: "ST MARTIAL (17330)", value: "17330" },
  { label: "ST MARTIAL DE MIRAMBEAU (17150)", value: "17150" },
  { label: "ST MARTIAL DE VITATERNE (17500)", value: "17500" },
  { label: "ST MARTIAL SUR NE (17520)", value: "17520" },
  { label: "ST MARTIN D ARY (17270)", value: "17270" },
  { label: "ST MARTIN DE COUX (17360)", value: "17360" },
  { label: "ST MARTIN DE JUILLERS (17400)", value: "17400" },
  { label: "ST MARTIN DE RE (17410)", value: "17410" },
  { label: "ST MEDARD (17500)", value: "17500" },
  { label: "ST MEDARD D AUNIS (17220)", value: "17220" },
  { label: "STE MEME (17770)", value: "17770" },
  { label: "ST NAZAIRE SUR CHARENTE (17780)", value: "17780" },
  { label: "ST OUEN D AUNIS (17230)", value: "17230" },
  { label: "ST OUEN LA THENE (17490)", value: "17490" },
  { label: "ST PALAIS DE NEGRIGNAC (17210)", value: "17210" },
  { label: "ST PALAIS DE PHIOLIN (17800)", value: "17800" },
  { label: "ST PALAIS SUR MER (17420)", value: "17420" },
  { label: "ST PALAIS SUR MER (17420)", value: "17420" },
  { label: "ST PARDOULT (17400)", value: "17400" },
  { label: "ST PIERRE D AMILLY (17700)", value: "17700" },
  { label: "ST PIERRE DE JUILLERS (17400)", value: "17400" },
  { label: "ST PIERRE DE L ISLE (17330)", value: "17330" },
  { label: "ST PIERRE D OLERON (17310)", value: "17310" },
  { label: "ST PIERRE D OLERON (17310)", value: "17310" },
  { label: "ST PIERRE DU PALAIS (17270)", value: "17270" },
  { label: "ST PORCHAIRE (17250)", value: "17250" },
  { label: "ST QUANTIN DE RANCANNE (17800)", value: "17800" },
  { label: "STE RADEGONDE (17250)", value: "17250" },
  { label: "STE RAMEE (17240)", value: "17240" },
  { label: "ST ROGATIEN (17220)", value: "17220" },
  { label: "ST ROMAIN DE BENET (17600)", value: "17600" },
  { label: "ST SATURNIN DU BOIS (17700)", value: "17700" },
  { label: "ST SAUVANT (17610)", value: "17610" },
  { label: "ST SAUVEUR D AUNIS (17540)", value: "17540" },
  { label: "ST SAVINIEN (17350)", value: "17350" },
  { label: "ST SAVINIEN (17350)", value: "17350" },
  { label: "ST SAVINIEN (17350)", value: "17350" },
  { label: "ST SEURIN DE PALENNE (17800)", value: "17800" },
  { label: "ST SEVER DE SAINTONGE (17800)", value: "17800" },
  { label: "ST SEVERIN SUR BOUTONNE (17330)", value: "17330" },
  { label: "ST SIGISMOND DE CLERMONT (17240)", value: "17240" },
  { label: "ST SIMON DE BORDES (17500)", value: "17500" },
  { label: "ST SIMON DE PELLOUAILLE (17260)", value: "17260" },
  { label: "ST SORLIN DE CONAC (17150)", value: "17150" },
  { label: "ST SORNIN (17600)", value: "17600" },
  { label: "STE SOULLE (17220)", value: "17220" },
  { label: "STE SOULLE (17220)", value: "17220" },
  { label: "ST SULPICE D ARNOULT (17250)", value: "17250" },
  { label: "ST SULPICE DE ROYAN (17200)", value: "17200" },
  { label: "ST THOMAS DE CONAC (17150)", value: "17150" },
  { label: "ST TROJAN LES BAINS (17370)", value: "17370" },
  { label: "ST VAIZE (17100)", value: "17100" },
  { label: "ST VIVIEN (17220)", value: "17220" },
  { label: "ST XANDRE (17138)", value: "17138" },
  { label: "SAINTES (17100)", value: "17100" },
  { label: "SALEIGNES (17510)", value: "17510" },
  { label: "SALIGNAC DE MIRAMBEAU (17130)", value: "17130" },
  { label: "SALIGNAC SUR CHARENTE (17800)", value: "17800" },
  { label: "SALLES SUR MER (17220)", value: "17220" },
  { label: "SAUJON (17600)", value: "17600" },
  { label: "SEIGNE (17510)", value: "17510" },
  { label: "SEMILLAC (17150)", value: "17150" },
  { label: "SEMOUSSAC (17150)", value: "17150" },
  { label: "SEMUSSAC (17120)", value: "17120" },
  { label: "LE SEURE (17770)", value: "17770" },
  { label: "SIECQ (17490)", value: "17490" },
  { label: "SONNAC (17160)", value: "17160" },
  { label: "SOUBISE (17780)", value: "17780" },
  { label: "SOUBRAN (17150)", value: "17150" },
  { label: "SOULIGNONNE (17250)", value: "17250" },
  { label: "SOUMERAS (17130)", value: "17130" },
  { label: "SOUSMOULINS (17130)", value: "17130" },
  { label: "SURGERES (17700)", value: "17700" },
  { label: "TAILLANT (17350)", value: "17350" },
  { label: "TAILLEBOURG (17350)", value: "17350" },
  { label: "TALMONT SUR GIRONDE (17120)", value: "17120" },
  { label: "TANZAC (17260)", value: "17260" },
  { label: "TAUGON (17170)", value: "17170" },
  { label: "TERNANT (17400)", value: "17400" },
  { label: "TESSON (17460)", value: "17460" },
  { label: "THAIMS (17120)", value: "17120" },
  { label: "THAIRE (17290)", value: "17290" },
  { label: "THENAC (17460)", value: "17460" },
  { label: "THEZAC (17600)", value: "17600" },
  { label: "THORS (17160)", value: "17160" },
  { label: "LE THOU (17290)", value: "17290" },
  { label: "TONNAY BOUTONNE (17380)", value: "17380" },
  { label: "TONNAY CHARENTE (17430)", value: "17430" },
  { label: "TORXE (17380)", value: "17380" },
  { label: "LES TOUCHES DE PERIGNY (17160)", value: "17160" },
  { label: "LA TREMBLADE (17390)", value: "17390" },
  { label: "LA TREMBLADE (17390)", value: "17390" },
  { label: "TRIZAY (17250)", value: "17250" },
  { label: "TUGERAS ST MAURICE (17130)", value: "17130" },
  { label: "TUGERAS ST MAURICE (17130)", value: "17130" },
  { label: "LA VALLEE (17250)", value: "17250" },
  { label: "LA DEVISE (17380)", value: "17380" },
  { label: "LA DEVISE (17380)", value: "17380" },
  { label: "LA DEVISE (17700)", value: "17700" },
  { label: "VANZAC (17500)", value: "17500" },
  { label: "VARAIZE (17400)", value: "17400" },
  { label: "VARZAY (17460)", value: "17460" },
  { label: "VAUX SUR MER (17640)", value: "17640" },
  { label: "VENERAND (17100)", value: "17100" },
  { label: "VERGEROUX (17300)", value: "17300" },
  { label: "VERGNE (17330)", value: "17330" },
  { label: "LA VERGNE (17400)", value: "17400" },
  { label: "VERINES (17540)", value: "17540" },
  { label: "VERVANT (17400)", value: "17400" },
  { label: "VIBRAC (17130)", value: "17130" },
  { label: "VILLARS EN PONS (17260)", value: "17260" },
  { label: "VILLARS LES BOIS (17770)", value: "17770" },
  { label: "LA VILLEDIEU (17470)", value: "17470" },
  { label: "VILLEDOUX (17230)", value: "17230" },
  { label: "VILLEMORIN (17470)", value: "17470" },
  { label: "VILLENEUVE LA COMTESSE (17330)", value: "17330" },
  { label: "VILLENEUVE LA COMTESSE (17330)", value: "17330" },
  { label: "VILLEXAVIER (17500)", value: "17500" },
  { label: "VILLIERS COUTURE (17510)", value: "17510" },
  { label: "VINAX (17510)", value: "17510" },
  { label: "VIROLLET (17260)", value: "17260" },
  { label: "VIRSON (17290)", value: "17290" },
  { label: "VOISSAY (17400)", value: "17400" },
  { label: "VOUHE (17700)", value: "17700" },
  { label: "YVES (17340)", value: "17340" },
  { label: "YVES (17340)", value: "17340" },
  { label: "PORT DES BARQUES (17730)", value: "17730" },
  { label: "LE GRAND VILLAGE PLAGE (17370)", value: "17370" },
  { label: "LA BREE LES BAINS (17840)", value: "17840" },
  { label: "ACHERES (18250)", value: "18250" },
  { label: "AINAY LE VIEIL (18200)", value: "18200" },
  { label: "LES AIX D ANGILLON (18220)", value: "18220" },
  { label: "ALLOGNY (18110)", value: "18110" },
  { label: "ALLOUIS (18500)", value: "18500" },
  { label: "ANNOIX (18340)", value: "18340" },
  { label: "APREMONT SUR ALLIER (18150)", value: "18150" },
  { label: "ARCAY (18340)", value: "18340" },
  { label: "ARCOMPS (18200)", value: "18200" },
  { label: "ARCOMPS (18200)", value: "18200" },
  { label: "ARDENAIS (18170)", value: "18170" },
  { label: "ARGENT SUR SAULDRE (18410)", value: "18410" },
  { label: "ARGENVIERES (18140)", value: "18140" },
  { label: "ARPHEUILLES (18200)", value: "18200" },
  { label: "ASSIGNY (18260)", value: "18260" },
  { label: "AUBIGNY SUR NERE (18700)", value: "18700" },
  { label: "AUBINGES (18220)", value: "18220" },
  { label: "AUGY SUR AUBOIS (18600)", value: "18600" },
  { label: "AVORD (18520)", value: "18520" },
  { label: "AZY (18220)", value: "18220" },
  { label: "BANNAY (18300)", value: "18300" },
  { label: "BANNEGON (18210)", value: "18210" },
  { label: "BARLIEU (18260)", value: "18260" },
  { label: "BAUGY (18800)", value: "18800" },
  { label: "BAUGY (18800)", value: "18800" },
  { label: "BAUGY (18800)", value: "18800" },
  { label: "BEDDES (18370)", value: "18370" },
  { label: "BEFFES (18320)", value: "18320" },
  { label: "BELLEVILLE SUR LOIRE (18240)", value: "18240" },
  { label: "BENGY SUR CRAON (18520)", value: "18520" },
  { label: "BERRY BOUY (18500)", value: "18500" },
  { label: "BESSAIS LE FROMENTAL (18210)", value: "18210" },
  { label: "BLANCAFORT (18410)", value: "18410" },
  { label: "BLET (18350)", value: "18350" },
  { label: "BOULLERET (18240)", value: "18240" },
  { label: "BOURGES (18000)", value: "18000" },
  { label: "BOUZAIS (18200)", value: "18200" },
  { label: "BRECY (18220)", value: "18220" },
  { label: "BRINAY (18120)", value: "18120" },
  { label: "BRINON SUR SAULDRE (18410)", value: "18410" },
  { label: "BRUERE ALLICHAMPS (18200)", value: "18200" },
  { label: "BUE (18300)", value: "18300" },
  { label: "BUSSY (18130)", value: "18130" },
  { label: "LA CELETTE (18360)", value: "18360" },
  { label: "LA CELLE (18200)", value: "18200" },
  { label: "LA CELLE CONDE (18160)", value: "18160" },
  { label: "CERBOIS (18120)", value: "18120" },
  { label: "CHALIVOY MILON (18130)", value: "18130" },
  { label: "CHAMBON (18190)", value: "18190" },
  { label: "LA CHAPELLE D ANGILLON (18380)", value: "18380" },
  { label: "LA CHAPELLE HUGON (18150)", value: "18150" },
  { label: "LA CHAPELLE MONTLINARD (18140)", value: "18140" },
  { label: "LA CHAPELLE ST URSIN (18570)", value: "18570" },
  { label: "LA CHAPELOTTE (18250)", value: "18250" },
  { label: "CHARENTON DU CHER (18210)", value: "18210" },
  { label: "CHARENTONNAY (18140)", value: "18140" },
  { label: "CHARLY (18350)", value: "18350" },
  { label: "CHAROST (18290)", value: "18290" },
  { label: "CHASSY (18800)", value: "18800" },
  { label: "CHASSY (18800)", value: "18800" },
  { label: "CHATEAUMEILLANT (18370)", value: "18370" },
  { label: "CHATEAUNEUF SUR CHER (18190)", value: "18190" },
  { label: "LE CHATELET (18170)", value: "18170" },
  { label: "CHAUMONT (18350)", value: "18350" },
  { label: "CHAUMOUX MARCILLY (18140)", value: "18140" },
  { label: "LE CHAUTAY (18150)", value: "18150" },
  { label: "CHAVANNES (18190)", value: "18190" },
  { label: "CHERY (18120)", value: "18120" },
  { label: "CHEZAL BENOIT (18160)", value: "18160" },
  { label: "CIVRAY (18290)", value: "18290" },
  { label: "CLEMONT (18410)", value: "18410" },
  { label: "COGNY (18130)", value: "18130" },
  { label: "COLOMBIERS (18200)", value: "18200" },
  { label: "CONCRESSAULT (18260)", value: "18260" },
  { label: "CONTRES (18130)", value: "18130" },
  { label: "CORNUSSE (18350)", value: "18350" },
  { label: "CORQUOY (18190)", value: "18190" },
  { label: "CORQUOY (18340)", value: "18340" },
  { label: "COUARGUES (18300)", value: "18300" },
  { label: "COURS LES BARRES (18320)", value: "18320" },
  { label: "COUST (18210)", value: "18210" },
  { label: "COUY (18140)", value: "18140" },
  { label: "CREZANCAY SUR CHER (18190)", value: "18190" },
  { label: "CREZANCY EN SANCERRE (18300)", value: "18300" },
  { label: "CROISY (18350)", value: "18350" },
  { label: "CROSSES (18340)", value: "18340" },
  { label: "CUFFY (18150)", value: "18150" },
  { label: "CULAN (18270)", value: "18270" },
  { label: "DAMPIERRE EN CROT (18260)", value: "18260" },
  { label: "DAMPIERRE EN GRACAY (18310)", value: "18310" },
  { label: "DREVANT (18200)", value: "18200" },
  { label: "DUN SUR AURON (18130)", value: "18130" },
  { label: "ENNORDRES (18380)", value: "18380" },
  { label: "EPINEUIL LE FLEURIEL (18360)", value: "18360" },
  { label: "ETRECHY (18800)", value: "18800" },
  { label: "FARGES ALLICHAMPS (18200)", value: "18200" },
  { label: "FARGES EN SEPTAINE (18800)", value: "18800" },
  { label: "FAVERDINES (18360)", value: "18360" },
  { label: "FEUX (18300)", value: "18300" },
  { label: "FLAVIGNY (18350)", value: "18350" },
  { label: "FOECY (18500)", value: "18500" },
  { label: "FUSSY (18110)", value: "18110" },
  { label: "GARDEFORT (18300)", value: "18300" },
  { label: "GARIGNY (18140)", value: "18140" },
  { label: "GENOUILLY (18310)", value: "18310" },
  { label: "GERMIGNY L EXEMPT (18150)", value: "18150" },
  { label: "GIVARDON (18600)", value: "18600" },
  { label: "GRACAY (18310)", value: "18310" },
  { label: "GROISES (18140)", value: "18140" },
  { label: "GRON (18800)", value: "18800" },
  { label: "GROSSOUVRE (18600)", value: "18600" },
  { label: "LA GROUTTE (18200)", value: "18200" },
  { label: "LA GUERCHE SUR L AUBOIS (18150)", value: "18150" },
  { label: "HENRICHEMONT (18250)", value: "18250" },
  { label: "HERRY (18140)", value: "18140" },
  { label: "HUMBLIGNY (18250)", value: "18250" },
  { label: "IDS ST ROCH (18170)", value: "18170" },
  { label: "IGNOL (18350)", value: "18350" },
  { label: "INEUIL (18160)", value: "18160" },
  { label: "IVOY LE PRE (18380)", value: "18380" },
  { label: "JALOGNES (18300)", value: "18300" },
  { label: "JARS (18260)", value: "18260" },
  { label: "JOUET SUR L AUBOIS (18320)", value: "18320" },
  { label: "JUSSY CHAMPAGNE (18130)", value: "18130" },
  { label: "JUSSY LE CHAUDRIER (18140)", value: "18140" },
  { label: "LANTAN (18130)", value: "18130" },
  { label: "LAPAN (18340)", value: "18340" },
  { label: "LAZENAY (18120)", value: "18120" },
  { label: "LERE (18240)", value: "18240" },
  { label: "LEVET (18340)", value: "18340" },
  { label: "LIGNIERES (18160)", value: "18160" },
  { label: "LIMEUX (18120)", value: "18120" },
  { label: "LISSAY LOCHY (18340)", value: "18340" },
  { label: "LOYE SUR ARNON (18170)", value: "18170" },
  { label: "LUGNY CHAMPAGNE (18140)", value: "18140" },
  { label: "LUNERY (18400)", value: "18400" },
  { label: "LURY SUR ARNON (18120)", value: "18120" },
  { label: "MAISONNAIS (18170)", value: "18170" },
  { label: "MARCAIS (18170)", value: "18170" },
  { label: "MAREUIL SUR ARNON (18290)", value: "18290" },
  { label: "MARMAGNE (18500)", value: "18500" },
  { label: "MARSEILLES LES AUBIGNY (18320)", value: "18320" },
  { label: "MASSAY (18120)", value: "18120" },
  { label: "MEHUN SUR YEVRE (18500)", value: "18500" },
  { label: "MEILLANT (18200)", value: "18200" },
  { label: "MENETOU COUTURE (18320)", value: "18320" },
  { label: "MENETOU RATEL (18300)", value: "18300" },
  { label: "MENETOU SALON (18510)", value: "18510" },
  { label: "MENETREOL SOUS SANCERRE (18300)", value: "18300" },
  { label: "MENETREOL SUR SAULDRE (18700)", value: "18700" },
  { label: "MEREAU (18120)", value: "18120" },
  { label: "MERY ES BOIS (18380)", value: "18380" },
  { label: "MERY SUR CHER (18100)", value: "18100" },
  { label: "MONTIGNY (18250)", value: "18250" },
  { label: "MONTLOUIS (18160)", value: "18160" },
  { label: "MORLAC (18170)", value: "18170" },
  { label: "MORNAY BERRY (18350)", value: "18350" },
  { label: "MORNAY SUR ALLIER (18600)", value: "18600" },
  { label: "MOROGUES (18220)", value: "18220" },
  { label: "MORTHOMIERS (18570)", value: "18570" },
  { label: "MOULINS SUR YEVRE (18390)", value: "18390" },
  { label: "NANCAY (18330)", value: "18330" },
  { label: "NERONDES (18350)", value: "18350" },
  { label: "NEUILLY EN DUN (18600)", value: "18600" },
  { label: "NEUILLY EN SANCERRE (18250)", value: "18250" },
  { label: "NEUVY DEUX CLOCHERS (18250)", value: "18250" },
  { label: "NEUVY LE BARROIS (18600)", value: "18600" },
  { label: "NEUVY SUR BARANGEON (18330)", value: "18330" },
  { label: "NOHANT EN GOUT (18390)", value: "18390" },
  { label: "NOHANT EN GRACAY (18310)", value: "18310" },
  { label: "LE NOYER (18260)", value: "18260" },
  { label: "NOZIERES (18200)", value: "18200" },
  { label: "OIZON (18700)", value: "18700" },
  { label: "ORCENAIS (18200)", value: "18200" },
  { label: "ORVAL (18200)", value: "18200" },
  { label: "OSMERY (18130)", value: "18130" },
  { label: "OSMERY (18350)", value: "18350" },
  { label: "OSMOY (18390)", value: "18390" },
  { label: "OUROUER LES BOURDELINS (18350)", value: "18350" },
  { label: "PARASSY (18220)", value: "18220" },
  { label: "PARNAY (18130)", value: "18130" },
  { label: "LA PERCHE (18200)", value: "18200" },
  { label: "PIGNY (18110)", value: "18110" },
  { label: "PLAIMPIED GIVAUDINS (18340)", value: "18340" },
  { label: "PLOU (18290)", value: "18290" },
  { label: "POISIEUX (18290)", value: "18290" },
  { label: "LE PONDY (18210)", value: "18210" },
  { label: "PRECY (18140)", value: "18140" },
  { label: "PRESLY (18380)", value: "18380" },
  { label: "PREUILLY (18120)", value: "18120" },
  { label: "PREVERANGES (18370)", value: "18370" },
  { label: "PRIMELLES (18400)", value: "18400" },
  { label: "QUANTILLY (18110)", value: "18110" },
  { label: "QUINCY (18120)", value: "18120" },
  { label: "RAYMOND (18130)", value: "18130" },
  { label: "REIGNY (18270)", value: "18270" },
  { label: "REZAY (18170)", value: "18170" },
  { label: "RIANS (18220)", value: "18220" },
  { label: "SAGONNE (18600)", value: "18600" },
  { label: "ST AIGNAN DES NOYERS (18600)", value: "18600" },
  { label: "ST AMAND MONTROND (18200)", value: "18200" },
  { label: "ST AMBROIX (18290)", value: "18290" },
  { label: "ST BAUDEL (18160)", value: "18160" },
  { label: "ST BOUIZE (18300)", value: "18300" },
  { label: "ST CAPRAIS (18400)", value: "18400" },
  { label: "ST CEOLS (18220)", value: "18220" },
  { label: "ST CHRISTOPHE LE CHAUDRY (18270)", value: "18270" },
  { label: "ST DENIS DE PALIN (18130)", value: "18130" },
  { label: "ST DOULCHARD (18230)", value: "18230" },
  { label: "ST ELOY DE GY (18110)", value: "18110" },
  { label: "ST FLORENT SUR CHER (18400)", value: "18400" },
  { label: "STE GEMME EN SANCERROIS (18240)", value: "18240" },
  { label: "ST GEORGES DE POISIEUX (18200)", value: "18200" },
  { label: "ST GEORGES SUR LA PREE (18100)", value: "18100" },
  { label: "ST GEORGES SUR MOULON (18110)", value: "18110" },
  { label: "ST GERMAIN DES BOIS (18340)", value: "18340" },
  { label: "ST GERMAIN DU PUY (18390)", value: "18390" },
  { label: "ST HILAIRE DE COURT (18100)", value: "18100" },
  { label: "ST HILAIRE DE GONDILLY (18320)", value: "18320" },
  { label: "ST HILAIRE DE GONDILLY (18350)", value: "18350" },
  { label: "ST HILAIRE EN LIGNIERES (18160)", value: "18160" },
  { label: "ST JEANVRIN (18370)", value: "18370" },
  { label: "ST JUST (18340)", value: "18340" },
  { label: "ST LAURENT (18330)", value: "18330" },
  { label: "ST LEGER LE PETIT (18140)", value: "18140" },
  { label: "ST LOUP DES CHAUMES (18190)", value: "18190" },
  { label: "ST MARTIN D AUXIGNY (18110)", value: "18110" },
  { label: "ST MARTIN DES CHAMPS (18140)", value: "18140" },
  { label: "ST MAUR (18270)", value: "18270" },
  { label: "ST MICHEL DE VOLANGIS (18390)", value: "18390" },
  { label: "STE MONTAINE (18700)", value: "18700" },
  { label: "ST OUTRILLE (18310)", value: "18310" },
  { label: "ST PALAIS (18110)", value: "18110" },
  { label: "ST PIERRE LES BOIS (18170)", value: "18170" },
  { label: "ST PIERRE LES ETIEUX (18210)", value: "18210" },
  { label: "ST PRIEST LA MARCHE (18370)", value: "18370" },
  { label: "ST SATUR (18300)", value: "18300" },
  { label: "ST SATURNIN (18370)", value: "18370" },
  { label: "STE SOLANGE (18220)", value: "18220" },
  { label: "ST SYMPHORIEN (18190)", value: "18190" },
  { label: "STE THORETTE (18500)", value: "18500" },
  { label: "ST VITTE (18360)", value: "18360" },
  { label: "SANCERGUES (18140)", value: "18140" },
  { label: "SANCERRE (18300)", value: "18300" },
  { label: "SANCOINS (18600)", value: "18600" },
  { label: "SANTRANGES (18240)", value: "18240" },
  { label: "SAUGY (18290)", value: "18290" },
  { label: "SAULZAIS LE POTIER (18360)", value: "18360" },
  { label: "SAVIGNY EN SANCERRE (18240)", value: "18240" },
  { label: "SAVIGNY EN SEPTAINE (18390)", value: "18390" },
  { label: "SENNECAY (18340)", value: "18340" },
  { label: "SENS BEAUJEU (18300)", value: "18300" },
  { label: "SERRUELLES (18190)", value: "18190" },
  { label: "SEVRY (18140)", value: "18140" },
  { label: "SIDIAILLES (18270)", value: "18270" },
  { label: "SOULANGIS (18220)", value: "18220" },
  { label: "SOYE EN SEPTAINE (18340)", value: "18340" },
  { label: "LE SUBDRAY (18570)", value: "18570" },
  { label: "SUBLIGNY (18260)", value: "18260" },
  { label: "SURY PRES LERE (18240)", value: "18240" },
  { label: "SURY EN VAUX (18300)", value: "18300" },
  { label: "SURY ES BOIS (18260)", value: "18260" },
  { label: "TENDRON (18350)", value: "18350" },
  { label: "THAUMIERS (18210)", value: "18210" },
  { label: "THAUVENAY (18300)", value: "18300" },
  { label: "THENIOUX (18100)", value: "18100" },
  { label: "THOU (18260)", value: "18260" },
  { label: "TORTERON (18320)", value: "18320" },
  { label: "TOUCHAY (18160)", value: "18160" },
  { label: "TROUY (18570)", value: "18570" },
  { label: "UZAY LE VENON (18190)", value: "18190" },
  { label: "VAILLY SUR SAULDRE (18260)", value: "18260" },
  { label: "VALLENAY (18190)", value: "18190" },
  { label: "VALLENAY (18190)", value: "18190" },
  { label: "VASSELAY (18110)", value: "18110" },
  { label: "VEAUGUES (18300)", value: "18300" },
  { label: "VENESMES (18190)", value: "18190" },
  { label: "VERDIGNY (18300)", value: "18300" },
  { label: "VEREAUX (18600)", value: "18600" },
  { label: "VERNAIS (18210)", value: "18210" },
  { label: "VERNEUIL (18210)", value: "18210" },
  { label: "VESDUN (18360)", value: "18360" },
  { label: "VIERZON (18100)", value: "18100" },
  { label: "VIGNOUX SOUS LES AIX (18110)", value: "18110" },
  { label: "VIGNOUX SUR BARANGEON (18500)", value: "18500" },
  { label: "VILLABON (18800)", value: "18800" },
  { label: "VILLECELIN (18160)", value: "18160" },
  { label: "VILLEGENON (18260)", value: "18260" },
  { label: "VILLENEUVE SUR CHER (18400)", value: "18400" },
  { label: "VILLEQUIERS (18800)", value: "18800" },
  { label: "VINON (18300)", value: "18300" },
  { label: "VORLY (18340)", value: "18340" },
  { label: "VORNAY (18130)", value: "18130" },
  { label: "VOUZERON (18330)", value: "18330" },
  { label: "AFFIEUX (19260)", value: "19260" },
  { label: "AIX (19200)", value: "19200" },
  { label: "ALBIGNAC (19190)", value: "19190" },
  { label: "ALBUSSAC (19380)", value: "19380" },
  { label: "ALLASSAC (19240)", value: "19240" },
  { label: "ALLEYRAT (19200)", value: "19200" },
  { label: "ALTILLAC (19120)", value: "19120" },
  { label: "AMBRUGEAT (19250)", value: "19250" },
  { label: "LES ANGLES SUR CORREZE (19000)", value: "19000" },
  { label: "ARGENTAT SUR DORDOGNE (19320)", value: "19320" },
  { label: "ARGENTAT SUR DORDOGNE (19400)", value: "19400" },
  { label: "ARNAC POMPADOUR (19230)", value: "19230" },
  { label: "ASTAILLAC (19120)", value: "19120" },
  { label: "AUBAZINES (19190)", value: "19190" },
  { label: "AURIAC (19220)", value: "19220" },
  { label: "AYEN (19310)", value: "19310" },
  { label: "BAR (19800)", value: "19800" },
  { label: "BASSIGNAC LE BAS (19430)", value: "19430" },
  { label: "BASSIGNAC LE HAUT (19220)", value: "19220" },
  { label: "BEAULIEU SUR DORDOGNE (19120)", value: "19120" },
  { label: "BEAULIEU SUR DORDOGNE (19120)", value: "19120" },
  { label: "BEAUMONT (19390)", value: "19390" },
  { label: "BELLECHASSAGNE (19290)", value: "19290" },
  { label: "BENAYES (19510)", value: "19510" },
  { label: "BEYNAT (19190)", value: "19190" },
  { label: "BEYSSAC (19230)", value: "19230" },
  { label: "BEYSSENAC (19230)", value: "19230" },
  { label: "BILHAC (19120)", value: "19120" },
  { label: "BONNEFOND (19170)", value: "19170" },
  { label: "BORT LES ORGUES (19110)", value: "19110" },
  { label: "BRANCEILLES (19500)", value: "19500" },
  { label: "BRIGNAC LA PLAINE (19310)", value: "19310" },
  { label: "BRIVE LA GAILLARDE (19100)", value: "19100" },
  { label: "BUGEAT (19170)", value: "19170" },
  { label: "CAMPS ST MATHURIN LEOBAZEL (19430)", value: "19430" },
  { label: "CAMPS ST MATHURIN LEOBAZEL (19430)", value: "19430" },
  { label: "CHABRIGNAC (19350)", value: "19350" },
  { label: "CHAMBERET (19370)", value: "19370" },
  { label: "CHAMBOULIVE (19450)", value: "19450" },
  { label: "CHAMEYRAT (19330)", value: "19330" },
  { label: "CHAMPAGNAC LA NOAILLE (19320)", value: "19320" },
  { label: "CHAMPAGNAC LA PRUNE (19320)", value: "19320" },
  { label: "CHANAC LES MINES (19150)", value: "19150" },
  { label: "CHANTEIX (19330)", value: "19330" },
  { label: "LA CHAPELLE AUX BROCS (19360)", value: "19360" },
  { label: "LA CHAPELLE AUX SAINTS (19120)", value: "19120" },
  { label: "LA CHAPELLE ST GERAUD (19430)", value: "19430" },
  { label: "CHAPELLE SPINASSE (19300)", value: "19300" },
  { label: "CHARTRIER FERRIERE (19600)", value: "19600" },
  { label: "LE CHASTANG (19190)", value: "19190" },
  { label: "CHASTEAUX (19600)", value: "19600" },
  { label: "CHAUFFOUR SUR VELL (19500)", value: "19500" },
  { label: "CHAUMEIL (19390)", value: "19390" },
  { label: "CHAVANAC (19290)", value: "19290" },
  { label: "CHAVEROCHE (19200)", value: "19200" },
  { label: "CHENAILLER MASCHEIX (19120)", value: "19120" },
  { label: "CHIRAC BELLEVUE (19160)", value: "19160" },
  { label: "CLERGOUX (19320)", value: "19320" },
  { label: "COLLONGES LA ROUGE (19500)", value: "19500" },
  { label: "COMBRESSOL (19250)", value: "19250" },
  { label: "CONCEZE (19350)", value: "19350" },
  { label: "CONDAT SUR GANAVEIX (19140)", value: "19140" },
  { label: "CORNIL (19150)", value: "19150" },
  { label: "CORREZE (19800)", value: "19800" },
  { label: "COSNAC (19360)", value: "19360" },
  { label: "COUFFY SUR SARSONNE (19340)", value: "19340" },
  { label: "COURTEIX (19340)", value: "19340" },
  { label: "CUBLAC (19520)", value: "19520" },
  { label: "CUREMONTE (19500)", value: "19500" },
  { label: "DAMPNIAT (19360)", value: "19360" },
  { label: "DARAZAC (19220)", value: "19220" },
  { label: "DARNETS (19300)", value: "19300" },
  { label: "DAVIGNAC (19250)", value: "19250" },
  { label: "DONZENAC (19270)", value: "19270" },
  { label: "EGLETONS (19300)", value: "19300" },
  { label: "L'EGLISE AUX BOIS (19170)", value: "19170" },
  { label: "ESPAGNAC (19150)", value: "19150" },
  { label: "ESPARTIGNAC (19140)", value: "19140" },
  { label: "ESTIVALS (19600)", value: "19600" },
  { label: "ESTIVAUX (19410)", value: "19410" },
  { label: "EYBURIE (19140)", value: "19140" },
  { label: "EYGURANDE (19340)", value: "19340" },
  { label: "EYREIN (19800)", value: "19800" },
  { label: "FAVARS (19330)", value: "19330" },
  { label: "FEYT (19340)", value: "19340" },
  { label: "FORGES (19380)", value: "19380" },
  { label: "GIMEL LES CASCADES (19800)", value: "19800" },
  { label: "GOULLES (19430)", value: "19430" },
  { label: "GOURDON MURAT (19170)", value: "19170" },
  { label: "GRANDSAIGNE (19300)", value: "19300" },
  { label: "GROS CHASTANG (19320)", value: "19320" },
  { label: "GUMOND (19320)", value: "19320" },
  { label: "HAUTEFAGE (19400)", value: "19400" },
  { label: "JUGEALS NAZARETH (19500)", value: "19500" },
  { label: "JUILLAC (19350)", value: "19350" },
  { label: "LACELLE (19170)", value: "19170" },
  { label: "LADIGNAC SUR RONDELLES (19150)", value: "19150" },
  { label: "LAFAGE SUR SOMBRE (19320)", value: "19320" },
  { label: "LAGARDE MARC LA TOUR (19150)", value: "19150" },
  { label: "LAGARDE MARC LA TOUR (19150)", value: "19150" },
  { label: "LAGLEYGEOLLE (19500)", value: "19500" },
  { label: "LAGRAULIERE (19700)", value: "19700" },
  { label: "LAGUENNE SUR AVALOUZE (19150)", value: "19150" },
  { label: "LAGUENNE SUR AVALOUZE (19150)", value: "19150" },
  { label: "LAMAZIERE BASSE (19160)", value: "19160" },
  { label: "LAMAZIERE HAUTE (19340)", value: "19340" },
  { label: "LAMONGERIE (19510)", value: "19510" },
  { label: "LANTEUIL (19190)", value: "19190" },
  { label: "LAPLEAU (19550)", value: "19550" },
  { label: "LARCHE (19600)", value: "19600" },
  { label: "LAROCHE PRES FEYT (19340)", value: "19340" },
  { label: "LASCAUX (19130)", value: "19130" },
  { label: "LATRONCHE (19160)", value: "19160" },
  { label: "LAVAL SUR LUZEGE (19550)", value: "19550" },
  { label: "LESTARDS (19170)", value: "19170" },
  { label: "LIGINIAC (19160)", value: "19160" },
  { label: "LIGNAREIX (19200)", value: "19200" },
  { label: "LIGNEYRAC (19500)", value: "19500" },
  { label: "LIOURDRES (19120)", value: "19120" },
  { label: "LISSAC SUR COUZE (19600)", value: "19600" },
  { label: "LE LONZAC (19470)", value: "19470" },
  { label: "LOSTANGES (19500)", value: "19500" },
  { label: "LOUIGNAC (19310)", value: "19310" },
  { label: "LUBERSAC (19210)", value: "19210" },
  { label: "MADRANGES (19470)", value: "19470" },
  { label: "MALEMORT (19360)", value: "19360" },
  { label: "MALEMORT (19360)", value: "19360" },
  { label: "MANSAC (19520)", value: "19520" },
  { label: "MARCILLAC LA CROISILLE (19320)", value: "19320" },
  { label: "MARCILLAC LA CROZE (19500)", value: "19500" },
  { label: "MARGERIDES (19200)", value: "19200" },
  { label: "MASSERET (19510)", value: "19510" },
  { label: "MAUSSAC (19250)", value: "19250" },
  { label: "MEILHARDS (19510)", value: "19510" },
  { label: "MENOIRE (19190)", value: "19190" },
  { label: "MERCOEUR (19430)", value: "19430" },
  { label: "MERLINES (19340)", value: "19340" },
  { label: "MESTES (19200)", value: "19200" },
  { label: "MEYMAC (19250)", value: "19250" },
  { label: "MEYRIGNAC L EGLISE (19800)", value: "19800" },
  { label: "MEYSSAC (19500)", value: "19500" },
  { label: "MILLEVACHES (19290)", value: "19290" },
  { label: "MONCEAUX SUR DORDOGNE (19400)", value: "19400" },
  { label: "MONESTIER MERLINES (19340)", value: "19340" },
  { label: "MONESTIER PORT DIEU (19110)", value: "19110" },
  { label: "MONTAIGNAC SUR DOUSTRE (19300)", value: "19300" },
  { label: "MONTAIGNAC SUR DOUSTRE (19300)", value: "19300" },
  { label: "MONTGIBAUD (19210)", value: "19210" },
  { label: "MOUSTIER VENTADOUR (19300)", value: "19300" },
  { label: "NAVES (19460)", value: "19460" },
  { label: "NESPOULS (19600)", value: "19600" },
  { label: "NEUVIC (19160)", value: "19160" },
  { label: "NEUVILLE (19380)", value: "19380" },
  { label: "NOAILHAC (19500)", value: "19500" },
  { label: "NOAILLES (19600)", value: "19600" },
  { label: "NONARDS (19120)", value: "19120" },
  { label: "OBJAT (19130)", value: "19130" },
  { label: "ORGNAC SUR VEZERE (19410)", value: "19410" },
  { label: "ORLIAC DE BAR (19390)", value: "19390" },
  { label: "PALAZINGES (19190)", value: "19190" },
  { label: "PALISSE (19160)", value: "19160" },
  { label: "PANDRIGNES (19150)", value: "19150" },
  { label: "PERET BEL AIR (19300)", value: "19300" },
  { label: "PEROLS SUR VEZERE (19170)", value: "19170" },
  { label: "PERPEZAC LE BLANC (19310)", value: "19310" },
  { label: "PERPEZAC LE NOIR (19410)", value: "19410" },
  { label: "LE PESCHER (19190)", value: "19190" },
  { label: "PEYRELEVADE (19290)", value: "19290" },
  { label: "PEYRISSAC (19260)", value: "19260" },
  { label: "PIERREFITTE (19450)", value: "19450" },
  { label: "CONFOLENT PORT DIEU (19200)", value: "19200" },
  { label: "PRADINES (19170)", value: "19170" },
  { label: "PUY D ARNAC (19120)", value: "19120" },
  { label: "QUEYSSAC LES VIGNES (19120)", value: "19120" },
  { label: "REYGADE (19430)", value: "19430" },
  { label: "RILHAC TREIGNAC (19260)", value: "19260" },
  { label: "RILHAC XAINTRIE (19220)", value: "19220" },
  { label: "LA ROCHE CANILLAC (19320)", value: "19320" },
  { label: "ROCHE LE PEYROUX (19160)", value: "19160" },
  { label: "ROSIERS D EGLETONS (19300)", value: "19300" },
  { label: "ROSIERS DE JUILLAC (19350)", value: "19350" },
  { label: "SADROC (19270)", value: "19270" },
  { label: "SAILLAC (19500)", value: "19500" },
  { label: "ST ANGEL (19200)", value: "19200" },
  { label: "ST AUGUSTIN (19390)", value: "19390" },
  { label: "ST AULAIRE (19130)", value: "19130" },
  { label: "ST BAZILE DE MEYSSAC (19500)", value: "19500" },
  { label: "ST BONNET ELVERT (19380)", value: "19380" },
  { label: "ST BONNET LA RIVIERE (19130)", value: "19130" },
  { label: "ST BONNET L ENFANTIER (19410)", value: "19410" },
  { label: "ST BONNET LES TOURS DE MERLE (19430)", value: "19430" },
  { label: "ST BONNET PRES BORT (19200)", value: "19200" },
  { label: "ST CERNIN DE LARCHE (19600)", value: "19600" },
  { label: "ST CHAMANT (19380)", value: "19380" },
  { label: "ST CIRGUES LA LOUTRE (19220)", value: "19220" },
  { label: "ST CLEMENT (19700)", value: "19700" },
  { label: "ST CYPRIEN (19130)", value: "19130" },
  { label: "ST CYR LA ROCHE (19130)", value: "19130" },
  { label: "ST ELOY LES TUILERIES (19210)", value: "19210" },
  { label: "ST ETIENNE AUX CLOS (19200)", value: "19200" },
  { label: "ST ETIENNE LA GENESTE (19160)", value: "19160" },
  { label: "ST EXUPERY LES ROCHES (19200)", value: "19200" },
  { label: "STE FEREOLE (19270)", value: "19270" },
  { label: "STE FORTUNADE (19490)", value: "19490" },
  { label: "ST FREJOUX (19200)", value: "19200" },
  { label: "ST GENIEZ O MERLE (19220)", value: "19220" },
  { label: "ST GERMAIN LAVOLPS (19290)", value: "19290" },
  { label: "ST GERMAIN LES VERGNES (19330)", value: "19330" },
  { label: "ST HILAIRE FOISSAC (19550)", value: "19550" },
  { label: "ST HILAIRE LES COURBES (19170)", value: "19170" },
  { label: "ST HILAIRE LUC (19160)", value: "19160" },
  { label: "ST HILAIRE PEYROUX (19560)", value: "19560" },
  { label: "ST HILAIRE TAURIEUX (19400)", value: "19400" },
  { label: "ST JAL (19700)", value: "19700" },
  { label: "ST JULIEN AUX BOIS (19220)", value: "19220" },
  { label: "ST JULIEN LE PELERIN (19430)", value: "19430" },
  { label: "ST JULIEN LE VENDOMOIS (19210)", value: "19210" },
  { label: "ST JULIEN MAUMONT (19500)", value: "19500" },
  { label: "STE MARIE LAPANOUZE (19160)", value: "19160" },
  { label: "ST MARTIAL DE GIMEL (19150)", value: "19150" },
  { label: "ST MARTIAL ENTRAYGUES (19400)", value: "19400" },
  { label: "ST MARTIN LA MEANNE (19320)", value: "19320" },
  { label: "ST MARTIN SEPERT (19210)", value: "19210" },
  { label: "ST MERD DE LAPLEAU (19320)", value: "19320" },
  { label: "ST MERD LES OUSSINES (19170)", value: "19170" },
  { label: "ST MEXANT (19330)", value: "19330" },
  { label: "ST PANTALEON DE LAPLEAU (19160)", value: "19160" },
  { label: "ST PANTALEON DE LARCHE (19600)", value: "19600" },
  { label: "ST PARDOUX CORBIER (19210)", value: "19210" },
  { label: "ST PARDOUX LA CROISILLE (19320)", value: "19320" },
  { label: "ST PARDOUX LE NEUF (19200)", value: "19200" },
  { label: "ST PARDOUX LE VIEUX (19200)", value: "19200" },
  { label: "ST PARDOUX L ORTIGIER (19270)", value: "19270" },
  { label: "ST PAUL (19150)", value: "19150" },
  { label: "ST PRIEST DE GIMEL (19800)", value: "19800" },
  { label: "ST PRIVAT (19220)", value: "19220" },
  { label: "ST REMY (19290)", value: "19290" },
  { label: "ST ROBERT (19310)", value: "19310" },
  { label: "ST SALVADOUR (19700)", value: "19700" },
  { label: "ST SETIERS (19290)", value: "19290" },
  { label: "ST SOLVE (19130)", value: "19130" },
  { label: "ST SORNIN LAVOLPS (19230)", value: "19230" },
  { label: "ST SULPICE LES BOIS (19250)", value: "19250" },
  { label: "ST SYLVAIN (19380)", value: "19380" },
  { label: "ST VIANCE (19240)", value: "19240" },
  { label: "ST VICTOUR (19200)", value: "19200" },
  { label: "ST YBARD (19140)", value: "19140" },
  { label: "ST YRIEIX LE DEJALAT (19300)", value: "19300" },
  { label: "SALON LA TOUR (19510)", value: "19510" },
  { label: "SARRAN (19800)", value: "19800" },
  { label: "SARROUX ST JULIEN (19110)", value: "19110" },
  { label: "SARROUX ST JULIEN (19110)", value: "19110" },
  { label: "SEGONZAC (19310)", value: "19310" },
  { label: "SEGUR LE CHATEAU (19230)", value: "19230" },
  { label: "SEILHAC (19700)", value: "19700" },
  { label: "SERANDON (19160)", value: "19160" },
  { label: "SERILHAC (19190)", value: "19190" },
  { label: "SERVIERES LE CHATEAU (19220)", value: "19220" },
  { label: "SEXCLES (19430)", value: "19430" },
  { label: "SIONIAC (19120)", value: "19120" },
  { label: "SORNAC (19290)", value: "19290" },
  { label: "SOUDAINE LAVINADIERE (19370)", value: "19370" },
  { label: "SOUDEILLES (19300)", value: "19300" },
  { label: "SOURSAC (19550)", value: "19550" },
  { label: "TARNAC (19170)", value: "19170" },
  { label: "THALAMY (19200)", value: "19200" },
  { label: "TOY VIAM (19170)", value: "19170" },
  { label: "TREIGNAC (19260)", value: "19260" },
  { label: "TROCHE (19230)", value: "19230" },
  { label: "TUDEILS (19120)", value: "19120" },
  { label: "TULLE (19000)", value: "19000" },
  { label: "TURENNE (19500)", value: "19500" },
  { label: "USSAC (19270)", value: "19270" },
  { label: "USSEL (19200)", value: "19200" },
  { label: "USSEL (19200)", value: "19200" },
  { label: "USSEL (19200)", value: "19200" },
  { label: "UZERCHE (19140)", value: "19140" },
  { label: "VALIERGUES (19200)", value: "19200" },
  { label: "VARETZ (19240)", value: "19240" },
  { label: "VARS SUR ROSEIX (19130)", value: "19130" },
  { label: "VEGENNES (19120)", value: "19120" },
  { label: "VEIX (19260)", value: "19260" },
  { label: "VEYRIERES (19200)", value: "19200" },
  { label: "VIAM (19170)", value: "19170" },
  { label: "VIGEOIS (19410)", value: "19410" },
  { label: "VIGNOLS (19130)", value: "19130" },
  { label: "VITRAC SUR MONTANE (19800)", value: "19800" },
  { label: "VOUTEZAC (19130)", value: "19130" },
  { label: "YSSANDON (19310)", value: "19310" },
  { label: "AGENCOURT (21700)", value: "21700" },
  { label: "AGEY (21410)", value: "21410" },
  { label: "AHUY (21121)", value: "21121" },
  { label: "AIGNAY LE DUC (21510)", value: "21510" },
  { label: "AISEREY (21110)", value: "21110" },
  { label: "AISEY SUR SEINE (21400)", value: "21400" },
  { label: "AISY SOUS THIL (21390)", value: "21390" },
  { label: "ALISE STE REINE (21150)", value: "21150" },
  { label: "ALLEREY (21230)", value: "21230" },
  { label: "ALOXE CORTON (21420)", value: "21420" },
  { label: "AMPILLY LES BORDES (21450)", value: "21450" },
  { label: "AMPILLY LE SEC (21400)", value: "21400" },
  { label: "ANCEY (21410)", value: "21410" },
  { label: "ANTHEUIL (21360)", value: "21360" },
  { label: "ANTIGNY LA VILLE (21230)", value: "21230" },
  { label: "ARCEAU (21310)", value: "21310" },
  { label: "ARCENANT (21700)", value: "21700" },
  { label: "ARCEY (21410)", value: "21410" },
  { label: "ARCONCEY (21320)", value: "21320" },
  { label: "ARC SUR TILLE (21560)", value: "21560" },
  { label: "ARGILLY (21700)", value: "21700" },
  { label: "ARNAY LE DUC (21230)", value: "21230" },
  { label: "ARNAY SOUS VITTEAUX (21350)", value: "21350" },
  { label: "ARRANS (21500)", value: "21500" },
  { label: "ASNIERES EN MONTAGNE (21500)", value: "21500" },
  { label: "ASNIERES LES DIJON (21380)", value: "21380" },
  { label: "ATHEE (21130)", value: "21130" },
  { label: "ATHIE (21500)", value: "21500" },
  { label: "AUBAINE (21360)", value: "21360" },
  { label: "AUBIGNY EN PLAINE (21170)", value: "21170" },
  { label: "AUBIGNY LA RONCE (21340)", value: "21340" },
  { label: "AUBIGNY LES SOMBERNON (21540)", value: "21540" },
  { label: "AUTRICOURT (21570)", value: "21570" },
  { label: "AUVILLARS SUR SAONE (21250)", value: "21250" },
  { label: "AUXANT (21360)", value: "21360" },
  { label: "AUXEY DURESSES (21190)", value: "21190" },
  { label: "AUXONNE (21130)", value: "21130" },
  { label: "AVELANGES (21120)", value: "21120" },
  { label: "AVOSNES (21350)", value: "21350" },
  { label: "AVOT (21580)", value: "21580" },
  { label: "BAGNOT (21700)", value: "21700" },
  { label: "BAIGNEUX LES JUIFS (21450)", value: "21450" },
  { label: "BALOT (21330)", value: "21330" },
  { label: "BARBIREY SUR OUCHE (21410)", value: "21410" },
  { label: "BARD LE REGULIER (21430)", value: "21430" },
  { label: "BARD LES EPOISSES (21460)", value: "21460" },
  { label: "BARGES (21910)", value: "21910" },
  { label: "BARJON (21580)", value: "21580" },
  { label: "BAUBIGNY (21340)", value: "21340" },
  { label: "BAULME LA ROCHE (21410)", value: "21410" },
  { label: "BEAULIEU (21510)", value: "21510" },
  { label: "BEAUMONT SUR VINGEANNE (21310)", value: "21310" },
  { label: "BEAUNE (21200)", value: "21200" },
  { label: "BEAUNOTTE (21510)", value: "21510" },
  { label: "BEIRE LE CHATEL (21310)", value: "21310" },
  { label: "BEIRE LE FORT (21110)", value: "21110" },
  { label: "BELAN SUR OURCE (21570)", value: "21570" },
  { label: "BELLEFOND (21490)", value: "21490" },
  { label: "BELLENEUVE (21310)", value: "21310" },
  { label: "BELLENOD SUR SEINE (21510)", value: "21510" },
  { label: "BELLENOT SOUS POUILLY (21320)", value: "21320" },
  { label: "BENEUVRE (21290)", value: "21290" },
  { label: "BENOISEY (21500)", value: "21500" },
  { label: "BESSEY EN CHAUME (21360)", value: "21360" },
  { label: "BESSEY LA COUR (21360)", value: "21360" },
  { label: "BESSEY LES CITEAUX (21110)", value: "21110" },
  { label: "BEUREY BAUGUAY (21320)", value: "21320" },
  { label: "BEURIZOT (21350)", value: "21350" },
  { label: "BEVY (21220)", value: "21220" },
  { label: "BEZE (21310)", value: "21310" },
  { label: "BEZOUOTTE (21310)", value: "21310" },
  { label: "BILLEY (21130)", value: "21130" },
  { label: "BILLY LES CHANCEAUX (21450)", value: "21450" },
  { label: "BINGES (21270)", value: "21270" },
  { label: "BISSEY LA COTE (21520)", value: "21520" },
  { label: "BISSEY LA PIERRE (21330)", value: "21330" },
  { label: "BLAGNY SUR VINGEANNE (21310)", value: "21310" },
  { label: "BLAISY BAS (21540)", value: "21540" },
  { label: "BLAISY HAUT (21540)", value: "21540" },
  { label: "BLANCEY (21320)", value: "21320" },
  { label: "BLANOT (21430)", value: "21430" },
  { label: "SOURCE SEINE (21690)", value: "21690" },
  { label: "SOURCE SEINE (21690)", value: "21690" },
  { label: "BLIGNY LE SEC (21440)", value: "21440" },
  { label: "BLIGNY LES BEAUNE (21200)", value: "21200" },
  { label: "BLIGNY SUR OUCHE (21360)", value: "21360" },
  { label: "BONCOURT LE BOIS (21700)", value: "21700" },
  { label: "BONNENCONTRE (21250)", value: "21250" },
  { label: "BOUDREVILLE (21520)", value: "21520" },
  { label: "BOUHEY (21360)", value: "21360" },
  { label: "BOUILLAND (21420)", value: "21420" },
  { label: "BOUIX (21330)", value: "21330" },
  { label: "BOURBERAIN (21610)", value: "21610" },
  { label: "BOUSSELANGE (21250)", value: "21250" },
  { label: "BOUSSENOIS (21260)", value: "21260" },
  { label: "BOUSSEY (21350)", value: "21350" },
  { label: "BOUX SOUS SALMAISE (21690)", value: "21690" },
  { label: "BOUZE LES BEAUNE (21200)", value: "21200" },
  { label: "BRAIN (21350)", value: "21350" },
  { label: "BRAUX (21390)", value: "21390" },
  { label: "BRAZEY EN MORVAN (21430)", value: "21430" },
  { label: "BRAZEY EN PLAINE (21470)", value: "21470" },
  { label: "BREMUR ET VAUROIS (21400)", value: "21400" },
  { label: "BRESSEY SUR TILLE (21560)", value: "21560" },
  { label: "BRETENIERE (21110)", value: "21110" },
  { label: "BRETIGNY (21490)", value: "21490" },
  { label: "BRIANNY (21390)", value: "21390" },
  { label: "BRION SUR OURCE (21570)", value: "21570" },
  { label: "BROCHON (21220)", value: "21220" },
  { label: "BROGNON (21490)", value: "21490" },
  { label: "BROIN (21250)", value: "21250" },
  { label: "BROINDON (21220)", value: "21220" },
  { label: "BUFFON (21500)", value: "21500" },
  { label: "BUNCEY (21400)", value: "21400" },
  { label: "BURE LES TEMPLIERS (21290)", value: "21290" },
  { label: "BUSSEAUT (21510)", value: "21510" },
  { label: "BUSSEROTTE ET MONTENAILLE (21580)", value: "21580" },
  { label: "BUSSIERES (21580)", value: "21580" },
  { label: "LA BUSSIERE SUR OUCHE (21360)", value: "21360" },
  { label: "BUSSY LA PESLE (21540)", value: "21540" },
  { label: "BUSSY LE GRAND (21150)", value: "21150" },
  { label: "BUXEROLLES (21290)", value: "21290" },
  { label: "CENSEREY (21430)", value: "21430" },
  { label: "CERILLY (21330)", value: "21330" },
  { label: "CESSEY SUR TILLE (21110)", value: "21110" },
  { label: "CHAIGNAY (21120)", value: "21120" },
  { label: "CHAILLY SUR ARMANCON (21320)", value: "21320" },
  { label: "CHAMBAIN (21290)", value: "21290" },
  { label: "CHAMBEIRE (21110)", value: "21110" },
  { label: "CHAMBLANC (21250)", value: "21250" },
  { label: "CHAMBOEUF (21220)", value: "21220" },
  { label: "CHAMBOLLE MUSIGNY (21220)", value: "21220" },
  { label: "CHAMESSON (21400)", value: "21400" },
  { label: "CHAMPAGNE SUR VINGEANNE (21310)", value: "21310" },
  { label: "CHAMPAGNY (21440)", value: "21440" },
  { label: "CHAMP D OISEAU (21500)", value: "21500" },
  { label: "CHAMPDOTRE (21130)", value: "21130" },
  { label: "CHAMPEAU EN MORVAN (21210)", value: "21210" },
  { label: "CHAMPIGNOLLES (21230)", value: "21230" },
  { label: "CHAMPRENAULT (21690)", value: "21690" },
  { label: "CHANCEAUX (21440)", value: "21440" },
  { label: "CHANNAY (21330)", value: "21330" },
  { label: "CHARENCEY (21690)", value: "21690" },
  { label: "CHARIGNY (21140)", value: "21140" },
  { label: "CHARMES (21310)", value: "21310" },
  { label: "CHARNY (21350)", value: "21350" },
  { label: "CHARREY SUR SAONE (21170)", value: "21170" },
  { label: "CHARREY SUR SEINE (21400)", value: "21400" },
  { label: "CHASSAGNE MONTRACHET (21190)", value: "21190" },
  { label: "CHASSEY (21150)", value: "21150" },
  { label: "CHATEAUNEUF (21320)", value: "21320" },
  { label: "CHATELLENOT (21320)", value: "21320" },
  { label: "CHATILLON SUR SEINE (21400)", value: "21400" },
  { label: "CHAUDENAY LA VILLE (21360)", value: "21360" },
  { label: "CHAUDENAY LE CHATEAU (21360)", value: "21360" },
  { label: "CHAUGEY (21290)", value: "21290" },
  { label: "CHAUME ET COURCHAMP (21610)", value: "21610" },
  { label: "CHAUME ET COURCHAMP (21610)", value: "21610" },
  { label: "LA CHAUME (21520)", value: "21520" },
  { label: "CHAUME LES BAIGNEUX (21450)", value: "21450" },
  { label: "CHAUMONT LE BOIS (21400)", value: "21400" },
  { label: "CHAUX (21700)", value: "21700" },
  { label: "CHAZEUIL (21260)", value: "21260" },
  { label: "CHAZILLY (21320)", value: "21320" },
  { label: "CHEMIN D AISEY (21400)", value: "21400" },
  { label: "CHENOVE (21300)", value: "21300" },
  { label: "CHEUGE (21310)", value: "21310" },
  { label: "CHEVANNAY (21540)", value: "21540" },
  { label: "CHEVANNES (21220)", value: "21220" },
  { label: "CHEVIGNY EN VALIERE (21200)", value: "21200" },
  { label: "CHEVIGNY ST SAUVEUR (21800)", value: "21800" },
  { label: "CHIVRES (21820)", value: "21820" },
  { label: "CHOREY LES BEAUNE (21200)", value: "21200" },
  { label: "CIREY LES PONTAILLER (21270)", value: "21270" },
  { label: "CIVRY EN MONTAGNE (21320)", value: "21320" },
  { label: "CLAMEREY (21390)", value: "21390" },
  { label: "VALFORET (21220)", value: "21220" },
  { label: "VALFORET (21220)", value: "21220" },
  { label: "CLENAY (21490)", value: "21490" },
  { label: "CLERY (21270)", value: "21270" },
  { label: "CLOMOT (21230)", value: "21230" },
  { label: "COLLONGES LES BEVY (21220)", value: "21220" },
  { label: "COLLONGES ET PREMIERES (21110)", value: "21110" },
  { label: "COLLONGES ET PREMIERES (21110)", value: "21110" },
  { label: "COLOMBIER (21360)", value: "21360" },
  { label: "COMBERTAULT (21200)", value: "21200" },
  { label: "COMBLANCHIEN (21700)", value: "21700" },
  { label: "COMMARIN (21320)", value: "21320" },
  { label: "CORBERON (21250)", value: "21250" },
  { label: "CORCELLES LES ARTS (21190)", value: "21190" },
  { label: "CORCELLES LES CITEAUX (21910)", value: "21910" },
  { label: "CORCELLES LES MONTS (21160)", value: "21160" },
  { label: "CORGENGOUX (21250)", value: "21250" },
  { label: "CORGOLOIN (21700)", value: "21700" },
  { label: "CORMOT VAUCHIGNON (21340)", value: "21340" },
  { label: "CORMOT VAUCHIGNON (21340)", value: "21340" },
  { label: "CORPEAU (21190)", value: "21190" },
  { label: "CORPOYER LA CHAPELLE (21150)", value: "21150" },
  { label: "CORROMBLES (21460)", value: "21460" },
  { label: "CORSAINT (21460)", value: "21460" },
  { label: "COUCHEY (21160)", value: "21160" },
  { label: "COULMIER LE SEC (21400)", value: "21400" },
  { label: "COURBAN (21520)", value: "21520" },
  { label: "COURCELLES FREMOY (21460)", value: "21460" },
  { label: "COURCELLES LES MONTBARD (21500)", value: "21500" },
  { label: "COURCELLES LES SEMUR (21140)", value: "21140" },
  { label: "COURLON (21580)", value: "21580" },
  { label: "COURTIVRON (21120)", value: "21120" },
  { label: "COUTERNON (21560)", value: "21560" },
  { label: "CREANCEY (21320)", value: "21320" },
  { label: "CRECEY SUR TILLE (21120)", value: "21120" },
  { label: "CREPAND (21500)", value: "21500" },
  { label: "CRUGEY (21360)", value: "21360" },
  { label: "CUISEREY (21310)", value: "21310" },
  { label: "CULETRE (21230)", value: "21230" },
  { label: "CURLEY (21220)", value: "21220" },
  { label: "CURTIL ST SEINE (21380)", value: "21380" },
  { label: "CURTIL VERGY (21220)", value: "21220" },
  { label: "CUSSEY LES FORGES (21580)", value: "21580" },
  { label: "CUSSY LA COLONNE (21360)", value: "21360" },
  { label: "CUSSY LE CHATEL (21230)", value: "21230" },
  { label: "DAIX (21121)", value: "21121" },
  { label: "DAMPIERRE EN MONTAGNE (21350)", value: "21350" },
  { label: "DAMPIERRE ET FLEE (21310)", value: "21310" },
  { label: "DARCEY (21150)", value: "21150" },
  { label: "DAROIS (21121)", value: "21121" },
  { label: "DETAIN ET BRUANT (21220)", value: "21220" },
  { label: "DIANCEY (21430)", value: "21430" },
  { label: "DIENAY (21120)", value: "21120" },
  { label: "DIJON (21000)", value: "21000" },
  { label: "DOMPIERRE EN MORVAN (21390)", value: "21390" },
  { label: "DRAMBON (21270)", value: "21270" },
  { label: "DREE (21540)", value: "21540" },
  { label: "DUESME (21510)", value: "21510" },
  { label: "EBATY (21190)", value: "21190" },
  { label: "ECHALOT (21510)", value: "21510" },
  { label: "ECHANNAY (21540)", value: "21540" },
  { label: "ECHENON (21170)", value: "21170" },
  { label: "ECHEVANNES (21120)", value: "21120" },
  { label: "ECHEVRONNE (21420)", value: "21420" },
  { label: "ECHIGEY (21110)", value: "21110" },
  { label: "ECUTIGNY (21360)", value: "21360" },
  { label: "EGUILLY (21320)", value: "21320" },
  { label: "EPAGNY (21380)", value: "21380" },
  { label: "EPERNAY SOUS GEVREY (21220)", value: "21220" },
  { label: "EPOISSES (21460)", value: "21460" },
  { label: "ERINGES (21500)", value: "21500" },
  { label: "ESBARRES (21170)", value: "21170" },
  { label: "ESSAROIS (21290)", value: "21290" },
  { label: "ESSEY (21320)", value: "21320" },
  { label: "ETAIS (21500)", value: "21500" },
  { label: "ETALANTE (21510)", value: "21510" },
  { label: "L'ETANG VERGY (21220)", value: "21220" },
  { label: "ETAULES (21121)", value: "21121" },
  { label: "ETEVAUX (21270)", value: "21270" },
  { label: "ETORMAY (21450)", value: "21450" },
  { label: "ETROCHEY (21400)", value: "21400" },
  { label: "FAIN LES MONTBARD (21500)", value: "21500" },
  { label: "FAIN LES MOUTIERS (21500)", value: "21500" },
  { label: "FAUVERNEY (21110)", value: "21110" },
  { label: "FAVEROLLES LES LUCEY (21290)", value: "21290" },
  { label: "FENAY (21600)", value: "21600" },
  { label: "LE FETE (21230)", value: "21230" },
  { label: "FIXIN (21220)", value: "21220" },
  { label: "FLACEY (21490)", value: "21490" },
  { label: "FLAGEY ECHEZEAUX (21640)", value: "21640" },
  { label: "FLAGEY LES AUXONNE (21130)", value: "21130" },
  { label: "FLAMMERANS (21130)", value: "21130" },
  { label: "FLAVIGNEROT (21160)", value: "21160" },
  { label: "FLAVIGNY SUR OZERAIN (21150)", value: "21150" },
  { label: "LE VAL LARREY (21140)", value: "21140" },
  { label: "LE VAL LARREY (21390)", value: "21390" },
  { label: "FLEUREY SUR OUCHE (21410)", value: "21410" },
  { label: "FOISSY (21230)", value: "21230" },
  { label: "FONCEGRIVE (21260)", value: "21260" },
  { label: "FONTAINES EN DUESMOIS (21450)", value: "21450" },
  { label: "FONTAINE FRANCAISE (21610)", value: "21610" },
  { label: "FONTAINE LES DIJON (21121)", value: "21121" },
  { label: "FONTAINES LES SECHES (21330)", value: "21330" },
  { label: "FONTANGY (21390)", value: "21390" },
  { label: "FONTENELLE (21610)", value: "21610" },
  { label: "FORLEANS (21460)", value: "21460" },
  { label: "FRAIGNOT ET VESVROTTE (21580)", value: "21580" },
  { label: "FRANCHEVILLE (21440)", value: "21440" },
  { label: "FRANXAULT (21170)", value: "21170" },
  { label: "FRENOIS (21120)", value: "21120" },
  { label: "FRESNES (21500)", value: "21500" },
  { label: "FROLOIS (21150)", value: "21150" },
  { label: "FUSSEY (21700)", value: "21700" },
  { label: "GEMEAUX (21120)", value: "21120" },
  { label: "GENAY (21140)", value: "21140" },
  { label: "GENLIS (21110)", value: "21110" },
  { label: "GERGUEIL (21410)", value: "21410" },
  { label: "GERLAND (21700)", value: "21700" },
  { label: "GEVREY CHAMBERTIN (21220)", value: "21220" },
  { label: "GEVROLLES (21520)", value: "21520" },
  { label: "GILLY LES CITEAUX (21640)", value: "21640" },
  { label: "GISSEY LE VIEIL (21350)", value: "21350" },
  { label: "GISSEY SOUS FLAVIGNY (21150)", value: "21150" },
  { label: "GISSEY SUR OUCHE (21410)", value: "21410" },
  { label: "GLANON (21250)", value: "21250" },
  { label: "GOMMEVILLE (21400)", value: "21400" },
  { label: "LES GOULLES (21520)", value: "21520" },
  { label: "GRANCEY LE CHATEAU NEUVELLE (21580)", value: "21580" },
  { label: "GRANCEY LE CHATEAU NEUVELLE (21580)", value: "21580" },
  { label: "GRANCEY SUR OURCE (21570)", value: "21570" },
  { label: "GRENANT LES SOMBERNON (21540)", value: "21540" },
  { label: "GRESIGNY STE REINE (21150)", value: "21150" },
  { label: "GRIGNON (21150)", value: "21150" },
  { label: "GRISELLES (21330)", value: "21330" },
  { label: "GROSBOIS EN MONTAGNE (21540)", value: "21540" },
  { label: "GROSBOIS LES TICHEY (21250)", value: "21250" },
  { label: "GURGY LA VILLE (21290)", value: "21290" },
  { label: "GURGY LE CHATEAU (21290)", value: "21290" },
  { label: "HAUTEROCHE (21150)", value: "21150" },
  { label: "HAUTEVILLE LES DIJON (21121)", value: "21121" },
  { label: "HEUILLEY SUR SAONE (21270)", value: "21270" },
  { label: "IS SUR TILLE (21120)", value: "21120" },
  { label: "IZEURE (21110)", value: "21110" },
  { label: "IZIER (21110)", value: "21110" },
  { label: "JAILLY LES MOULINS (21150)", value: "21150" },
  { label: "JALLANGES (21250)", value: "21250" },
  { label: "JANCIGNY (21310)", value: "21310" },
  { label: "JEUX LES BARD (21460)", value: "21460" },
  { label: "JOUEY (21230)", value: "21230" },
  { label: "JOURS LES BAIGNEUX (21450)", value: "21450" },
  { label: "VAL MONT (21340)", value: "21340" },
  { label: "VAL MONT (21340)", value: "21340" },
  { label: "JUILLENAY (21210)", value: "21210" },
  { label: "JUILLY (21140)", value: "21140" },
  { label: "LABERGEMENT FOIGNEY (21110)", value: "21110" },
  { label: "LABERGEMENT LES AUXONNE (21130)", value: "21130" },
  { label: "LABERGEMENT LES SEURRE (21820)", value: "21820" },
  { label: "LABRUYERE (21250)", value: "21250" },
  { label: "LACANCHE (21230)", value: "21230" },
  { label: "LACOUR D ARCENAY (21210)", value: "21210" },
  { label: "LAIGNES (21330)", value: "21330" },
  { label: "LAMARCHE SUR SAONE (21760)", value: "21760" },
  { label: "LAMARGELLE (21440)", value: "21440" },
  { label: "LANTENAY (21370)", value: "21370" },
  { label: "LANTHES (21250)", value: "21250" },
  { label: "LANTILLY (21140)", value: "21140" },
  { label: "LAPERRIERE SUR SAONE (21170)", value: "21170" },
  { label: "LARREY (21330)", value: "21330" },
  { label: "LECHATELET (21250)", value: "21250" },
  { label: "LERY (21440)", value: "21440" },
  { label: "LEUGLAY (21290)", value: "21290" },
  { label: "LEVERNOIS (21200)", value: "21200" },
  { label: "LICEY SUR VINGEANNE (21610)", value: "21610" },
  { label: "LIERNAIS (21430)", value: "21430" },
  { label: "LIGNEROLLES (21520)", value: "21520" },
  { label: "LONGCHAMP (21110)", value: "21110" },
  { label: "LONGEAULT PLUVAULT (21110)", value: "21110" },
  { label: "LONGEAULT PLUVAULT (21110)", value: "21110" },
  { label: "LONGECOURT EN PLAINE (21110)", value: "21110" },
  { label: "LONGECOURT LES CULETRE (21230)", value: "21230" },
  { label: "LONGVIC (21600)", value: "21600" },
  { label: "LOSNE (21170)", value: "21170" },
  { label: "LOUESME (21520)", value: "21520" },
  { label: "LUCENAY LE DUC (21150)", value: "21150" },
  { label: "LUCEY (21290)", value: "21290" },
  { label: "LUSIGNY SUR OUCHE (21360)", value: "21360" },
  { label: "LUX (21120)", value: "21120" },
  { label: "MACONGE (21320)", value: "21320" },
  { label: "MAGNIEN (21230)", value: "21230" },
  { label: "MAGNY LAMBERT (21450)", value: "21450" },
  { label: "MAGNY LA VILLE (21140)", value: "21140" },
  { label: "MAGNY LES AUBIGNY (21170)", value: "21170" },
  { label: "MAGNY MONTARLOT (21130)", value: "21130" },
  { label: "MAGNY LES VILLERS (21700)", value: "21700" },
  { label: "MAGNY ST MEDARD (21310)", value: "21310" },
  { label: "MAGNY SUR TILLE (21110)", value: "21110" },
  { label: "LES MAILLYS (21130)", value: "21130" },
  { label: "MAISEY LE DUC (21400)", value: "21400" },
  { label: "MALAIN (21410)", value: "21410" },
  { label: "MALIGNY (21230)", value: "21230" },
  { label: "MANLAY (21430)", value: "21430" },
  { label: "MARANDEUIL (21270)", value: "21270" },
  { label: "MARCELLOIS (21350)", value: "21350" },
  { label: "MARCENAY (21330)", value: "21330" },
  { label: "MARCHESEUIL (21430)", value: "21430" },
  { label: "MARCIGNY SOUS THIL (21390)", value: "21390" },
  { label: "MARCILLY ET DRACY (21350)", value: "21350" },
  { label: "MARCILLY OGNY (21320)", value: "21320" },
  { label: "MARCILLY SUR TILLE (21120)", value: "21120" },
  { label: "MAREY LES FUSSEY (21700)", value: "21700" },
  { label: "MAREY SUR TILLE (21120)", value: "21120" },
  { label: "MARIGNY LE CAHOUET (21150)", value: "21150" },
  { label: "MARIGNY LES REULLEE (21200)", value: "21200" },
  { label: "MARLIENS (21110)", value: "21110" },
  { label: "MARMAGNE (21500)", value: "21500" },
  { label: "MARSANNAY LA COTE (21160)", value: "21160" },
  { label: "MARSANNAY LE BOIS (21380)", value: "21380" },
  { label: "MARTROIS (21320)", value: "21320" },
  { label: "MASSINGY (21400)", value: "21400" },
  { label: "MASSINGY LES SEMUR (21140)", value: "21140" },
  { label: "MASSINGY LES VITTEAUX (21350)", value: "21350" },
  { label: "MAUVILLY (21510)", value: "21510" },
  { label: "MAVILLY MANDELOT (21190)", value: "21190" },
  { label: "MAXILLY SUR SAONE (21270)", value: "21270" },
  { label: "MEILLY SUR ROUVRES (21320)", value: "21320" },
  { label: "LE MEIX (21580)", value: "21580" },
  { label: "MELOISEY (21190)", value: "21190" },
  { label: "MENESBLE (21290)", value: "21290" },
  { label: "MENESSAIRE (21430)", value: "21430" },
  { label: "MENETREUX LE PITOIS (21150)", value: "21150" },
  { label: "MERCEUIL (21190)", value: "21190" },
  { label: "MESMONT (21540)", value: "21540" },
  { label: "MESSANGES (21220)", value: "21220" },
  { label: "MESSIGNY ET VANTOUX (21380)", value: "21380" },
  { label: "MESSIGNY ET VANTOUX (21380)", value: "21380" },
  { label: "MEUILLEY (21700)", value: "21700" },
  { label: "MEULSON (21510)", value: "21510" },
  { label: "MEURSANGES (21200)", value: "21200" },
  { label: "MEURSAULT (21190)", value: "21190" },
  { label: "MILLERY (21140)", value: "21140" },
  { label: "MIMEURE (21230)", value: "21230" },
  { label: "MINOT (21510)", value: "21510" },
  { label: "MIREBEAU SUR BEZE (21310)", value: "21310" },
  { label: "MISSERY (21210)", value: "21210" },
  { label: "MOITRON (21510)", value: "21510" },
  { label: "MOLESME (21330)", value: "21330" },
  { label: "MOLINOT (21340)", value: "21340" },
  { label: "MOLOY (21120)", value: "21120" },
  { label: "MOLPHEY (21210)", value: "21210" },
  { label: "MONTAGNY LES BEAUNE (21200)", value: "21200" },
  { label: "MONTAGNY LES SEURRE (21250)", value: "21250" },
  { label: "MONTBARD (21500)", value: "21500" },
  { label: "MONTBERTHAULT (21460)", value: "21460" },
  { label: "MONTCEAU ET ECHARNANT (21360)", value: "21360" },
  { label: "MONTHELIE (21190)", value: "21190" },
  { label: "MONTIGNY MONTFORT (21500)", value: "21500" },
  { label: "MONTIGNY ST BARTHELEMY (21390)", value: "21390" },
  { label: "MONTIGNY SUR ARMANCON (21140)", value: "21140" },
  { label: "MONTIGNY SUR AUBE (21520)", value: "21520" },
  { label: "MONTIGNY MORNAY VILLENEUVE VINGEANNE (21610)", value: "21610" },
  { label: "MONTIGNY MORNAY VILLENEUVE VINGEANNE (21610)", value: "21610" },
  { label: "MONTIGNY MORNAY VILLENEUVE VINGEANNE (21610)", value: "21610" },
  { label: "MONTLAY EN AUXOIS (21210)", value: "21210" },
  { label: "MONTLIOT ET COURCELLES (21400)", value: "21400" },
  { label: "MONTMAIN (21250)", value: "21250" },
  { label: "MONTMANCON (21270)", value: "21270" },
  { label: "MONTMOYEN (21290)", value: "21290" },
  { label: "MONTOILLOT (21540)", value: "21540" },
  { label: "MONTOT (21170)", value: "21170" },
  { label: "MONT ST JEAN (21320)", value: "21320" },
  { label: "MOREY ST DENIS (21220)", value: "21220" },
  { label: "MOSSON (21400)", value: "21400" },
  { label: "LA MOTTE TERNANT (21210)", value: "21210" },
  { label: "MOUTIERS ST JEAN (21500)", value: "21500" },
  { label: "MUSIGNY (21230)", value: "21230" },
  { label: "MUSSY LA FOSSE (21150)", value: "21150" },
  { label: "NAN SOUS THIL (21390)", value: "21390" },
  { label: "NANTOUX (21190)", value: "21190" },
  { label: "NESLE ET MASSOULT (21330)", value: "21330" },
  { label: "NEUILLY CRIMOLOIS (21800)", value: "21800" },
  { label: "NEUILLY CRIMOLOIS (21800)", value: "21800" },
  { label: "NICEY (21330)", value: "21330" },
  { label: "NOD SUR SEINE (21400)", value: "21400" },
  { label: "NOGENT LES MONTBARD (21500)", value: "21500" },
  { label: "NOIDAN (21390)", value: "21390" },
  { label: "NOIRON SOUS GEVREY (21910)", value: "21910" },
  { label: "NOIRON SUR BEZE (21310)", value: "21310" },
  { label: "NOIRON SUR SEINE (21400)", value: "21400" },
  { label: "NOLAY (21340)", value: "21340" },
  { label: "NOLAY (21340)", value: "21340" },
  { label: "NORGES LA VILLE (21490)", value: "21490" },
  { label: "NORMIER (21390)", value: "21390" },
  { label: "NUITS ST GEORGES (21700)", value: "21700" },
  { label: "OBTREE (21400)", value: "21400" },
  { label: "OIGNY (21450)", value: "21450" },
  { label: "OISILLY (21310)", value: "21310" },
  { label: "ORAIN (21610)", value: "21610" },
  { label: "ORGEUX (21490)", value: "21490" },
  { label: "ORIGNY (21510)", value: "21510" },
  { label: "ORRET (21450)", value: "21450" },
  { label: "ORVILLE (21260)", value: "21260" },
  { label: "OUGES (21600)", value: "21600" },
  { label: "PAGNY LA VILLE (21250)", value: "21250" },
  { label: "PAGNY LE CHATEAU (21250)", value: "21250" },
  { label: "PAINBLANC (21360)", value: "21360" },
  { label: "PANGES (21540)", value: "21540" },
  { label: "PASQUES (21370)", value: "21370" },
  { label: "PELLEREY (21440)", value: "21440" },
  { label: "PERNAND VERGELESSES (21420)", value: "21420" },
  { label: "PERRIGNY LES DIJON (21160)", value: "21160" },
  { label: "PERRIGNY SUR L OGNON (21270)", value: "21270" },
  { label: "PICHANGES (21120)", value: "21120" },
  { label: "PLANAY (21500)", value: "21500" },
  { label: "PLOMBIERES LES DIJON (21370)", value: "21370" },
  { label: "PLUVET (21110)", value: "21110" },
  { label: "POINCON LES LARREY (21330)", value: "21330" },
  { label: "POISEUL LA GRANGE (21440)", value: "21440" },
  { label: "POISEUL LA VILLE ET LAPERRIERE (21450)", value: "21450" },
  { label: "POISEUL LES SAULX (21120)", value: "21120" },
  { label: "POMMARD (21630)", value: "21630" },
  { label: "PONCEY LES ATHEE (21130)", value: "21130" },
  { label: "PONCEY SUR L IGNON (21440)", value: "21440" },
  { label: "PONT (21130)", value: "21130" },
  { label: "PONTAILLER SUR SAONE (21270)", value: "21270" },
  { label: "PONT ET MASSENE (21140)", value: "21140" },
  { label: "POSANGES (21350)", value: "21350" },
  { label: "POTHIERES (21400)", value: "21400" },
  { label: "POUILLENAY (21150)", value: "21150" },
  { label: "POUILLY EN AUXOIS (21320)", value: "21320" },
  { label: "POUILLY SUR SAONE (21250)", value: "21250" },
  { label: "POUILLY SUR VINGEANNE (21610)", value: "21610" },
  { label: "PRALON (21410)", value: "21410" },
  { label: "PRECY SOUS THIL (21390)", value: "21390" },
  { label: "PREMEAUX PRISSEY (21700)", value: "21700" },
  { label: "PREMEAUX PRISSEY (21700)", value: "21700" },
  { label: "PRENOIS (21370)", value: "21370" },
  { label: "PRUSLY SUR OURCE (21400)", value: "21400" },
  { label: "PUITS (21400)", value: "21400" },
  { label: "PULIGNY MONTRACHET (21190)", value: "21190" },
  { label: "QUEMIGNY SUR SEINE (21510)", value: "21510" },
  { label: "QUETIGNY (21800)", value: "21800" },
  { label: "QUINCEROT (21500)", value: "21500" },
  { label: "QUINCEY (21700)", value: "21700" },
  { label: "QUINCY LE VICOMTE (21500)", value: "21500" },
  { label: "RECEY SUR OURCE (21290)", value: "21290" },
  { label: "REMILLY EN MONTAGNE (21540)", value: "21540" },
  { label: "REMILLY SUR TILLE (21560)", value: "21560" },
  { label: "RENEVE (21310)", value: "21310" },
  { label: "REULLE VERGY (21220)", value: "21220" },
  { label: "RIEL LES EAUX (21570)", value: "21570" },
  { label: "LA ROCHE EN BRENIL (21530)", value: "21530" },
  { label: "ROCHEFORT SUR BREVON (21510)", value: "21510" },
  { label: "LA ROCHEPOT (21340)", value: "21340" },
  { label: "LA ROCHE VANNEAU (21150)", value: "21150" },
  { label: "ROILLY (21390)", value: "21390" },
  { label: "ROUGEMONT (21500)", value: "21500" },
  { label: "ROUVRAY (21530)", value: "21530" },
  { label: "ROUVRES EN PLAINE (21110)", value: "21110" },
  { label: "ROUVRES SOUS MEILLY (21320)", value: "21320" },
  { label: "RUFFEY LES BEAUNE (21200)", value: "21200" },
  { label: "RUFFEY LES ECHIREY (21490)", value: "21490" },
  { label: "SACQUENAY (21260)", value: "21260" },
  { label: "SAFFRES (21350)", value: "21350" },
  { label: "ST ANDEUX (21530)", value: "21530" },
  { label: "ST ANTHOT (21540)", value: "21540" },
  { label: "ST APOLLINAIRE (21850)", value: "21850" },
  { label: "ST AUBIN (21190)", value: "21190" },
  { label: "ST BERNARD (21700)", value: "21700" },
  { label: "ST BROING LES MOINES (21290)", value: "21290" },
  { label: "STE COLOMBE EN AUXOIS (21350)", value: "21350" },
  { label: "STE COLOMBE SUR SEINE (21400)", value: "21400" },
  { label: "ST DIDIER (21210)", value: "21210" },
  { label: "ST EUPHRONE (21140)", value: "21140" },
  { label: "ST GERMAIN DE MODEON (21530)", value: "21530" },
  { label: "ST GERMAIN LE ROCHEUX (21510)", value: "21510" },
  { label: "ST GERMAIN LES SENAILLY (21500)", value: "21500" },
  { label: "ST HELIER (21690)", value: "21690" },
  { label: "ST JEAN DE BOEUF (21410)", value: "21410" },
  { label: "ST JEAN DE LOSNE (21170)", value: "21170" },
  { label: "ST JULIEN (21490)", value: "21490" },
  { label: "ST LEGER TRIEY (21270)", value: "21270" },
  { label: "ST MARC SUR SEINE (21450)", value: "21450" },
  { label: "STE MARIE LA BLANCHE (21200)", value: "21200" },
  { label: "STE MARIE SUR OUCHE (21410)", value: "21410" },
  { label: "STE MARIE SUR OUCHE (21410)", value: "21410" },
  { label: "ST MARTIN DE LA MER (21210)", value: "21210" },
  { label: "ST MARTIN DU MONT (21440)", value: "21440" },
  { label: "ST MAURICE SUR VINGEANNE (21610)", value: "21610" },
  { label: "ST MESMIN (21540)", value: "21540" },
  { label: "ST NICOLAS LES CITEAUX (21700)", value: "21700" },
  { label: "ST PHILIBERT (21220)", value: "21220" },
  { label: "ST PIERRE EN VAUX (21230)", value: "21230" },
  { label: "ST PRIX LES ARNAY (21230)", value: "21230" },
  { label: "ST REMY (21500)", value: "21500" },
  { label: "ST ROMAIN (21190)", value: "21190" },
  { label: "STE SABINE (21320)", value: "21320" },
  { label: "ST SAUVEUR (21270)", value: "21270" },
  { label: "ST SEINE EN BACHE (21130)", value: "21130" },
  { label: "ST SEINE L ABBAYE (21440)", value: "21440" },
  { label: "ST SEINE SUR VINGEANNE (21610)", value: "21610" },
  { label: "ST SYMPHORIEN SUR SAONE (21170)", value: "21170" },
  { label: "ST THIBAULT (21350)", value: "21350" },
  { label: "ST USAGE (21170)", value: "21170" },
  { label: "ST VICTOR SUR OUCHE (21410)", value: "21410" },
  { label: "SALIVES (21580)", value: "21580" },
  { label: "SALMAISE (21690)", value: "21690" },
  { label: "SAMEREY (21170)", value: "21170" },
  { label: "SANTENAY (21590)", value: "21590" },
  { label: "SANTOSSE (21340)", value: "21340" },
  { label: "SAULIEU (21210)", value: "21210" },
  { label: "SAULON LA CHAPELLE (21910)", value: "21910" },
  { label: "SAULON LA RUE (21910)", value: "21910" },
  { label: "SAULX LE DUC (21120)", value: "21120" },
  { label: "SAUSSEY (21360)", value: "21360" },
  { label: "SAUSSY (21380)", value: "21380" },
  { label: "SAVIGNY LES BEAUNE (21420)", value: "21420" },
  { label: "SAVIGNY LE SEC (21380)", value: "21380" },
  { label: "SAVIGNY SOUS MALAIN (21540)", value: "21540" },
  { label: "SAVILLY (21430)", value: "21430" },
  { label: "SAVOISY (21500)", value: "21500" },
  { label: "SAVOLLES (21310)", value: "21310" },
  { label: "SAVOUGES (21910)", value: "21910" },
  { label: "SEGROIS (21220)", value: "21220" },
  { label: "SEIGNY (21150)", value: "21150" },
  { label: "SELONGEY (21260)", value: "21260" },
  { label: "SEMAREY (21320)", value: "21320" },
  { label: "SEMEZANGES (21220)", value: "21220" },
  { label: "SEMOND (21450)", value: "21450" },
  { label: "SEMUR EN AUXOIS (21140)", value: "21140" },
  { label: "SENAILLY (21500)", value: "21500" },
  { label: "SENNECEY LES DIJON (21800)", value: "21800" },
  { label: "LADOIX SERRIGNY (21550)", value: "21550" },
  { label: "SEURRE (21250)", value: "21250" },
  { label: "SINCEY LES ROUVRAY (21530)", value: "21530" },
  { label: "SOIRANS (21110)", value: "21110" },
  { label: "SOISSONS SUR NACEY (21270)", value: "21270" },
  { label: "SOMBERNON (21540)", value: "21540" },
  { label: "SOUHEY (21140)", value: "21140" },
  { label: "SOUSSEY SUR BRIONNE (21350)", value: "21350" },
  { label: "SPOY (21120)", value: "21120" },
  { label: "SUSSEY (21430)", value: "21430" },
  { label: "SUSSEY (21430)", value: "21430" },
  { label: "TAILLY (21190)", value: "21190" },
  { label: "TALANT (21240)", value: "21240" },
  { label: "TALMAY (21270)", value: "21270" },
  { label: "TANAY (21310)", value: "21310" },
  { label: "TARSUL (21120)", value: "21120" },
  { label: "TART LE BAS (21110)", value: "21110" },
  { label: "TART (21110)", value: "21110" },
  { label: "TART (21110)", value: "21110" },
  { label: "TELLECEY (21270)", value: "21270" },
  { label: "TERNANT (21220)", value: "21220" },
  { label: "TERREFONDREE (21290)", value: "21290" },
  { label: "THENISSEY (21150)", value: "21150" },
  { label: "THOIRES (21570)", value: "21570" },
  { label: "THOISY LA BERCHERE (21210)", value: "21210" },
  { label: "THOISY LE DESERT (21320)", value: "21320" },
  { label: "THOMIREY (21360)", value: "21360" },
  { label: "THOREY EN PLAINE (21110)", value: "21110" },
  { label: "THOREY SOUS CHARNY (21350)", value: "21350" },
  { label: "THOREY SUR OUCHE (21360)", value: "21360" },
  { label: "THOSTE (21460)", value: "21460" },
  { label: "THURY (21340)", value: "21340" },
  { label: "TICHEY (21250)", value: "21250" },
  { label: "TIL CHATEL (21120)", value: "21120" },
  { label: "TILLENAY (21130)", value: "21130" },
  { label: "TORCY ET POULIGNY (21460)", value: "21460" },
  { label: "TOUILLON (21500)", value: "21500" },
  { label: "TOUTRY (21460)", value: "21460" },
  { label: "TRECLUN (21130)", value: "21130" },
  { label: "TROCHERES (21310)", value: "21310" },
  { label: "TROUHANS (21170)", value: "21170" },
  { label: "TROUHAUT (21440)", value: "21440" },
  { label: "TRUGNY (21250)", value: "21250" },
  { label: "TURCEY (21540)", value: "21540" },
  { label: "UNCEY LE FRANC (21350)", value: "21350" },
  { label: "URCY (21220)", value: "21220" },
  { label: "VAL SUZON (21121)", value: "21121" },
  { label: "VANDENESSE EN AUXOIS (21320)", value: "21320" },
  { label: "VANNAIRE (21400)", value: "21400" },
  { label: "VANVEY (21400)", value: "21400" },
  { label: "VARANGES (21110)", value: "21110" },
  { label: "VAROIS ET CHAIGNOT (21490)", value: "21490" },
  { label: "VAUX SAULES (21440)", value: "21440" },
  { label: "VEILLY (21360)", value: "21360" },
  { label: "VELARS SUR OUCHE (21370)", value: "21370" },
  { label: "VELOGNY (21350)", value: "21350" },
  { label: "VENAREY LES LAUMES (21150)", value: "21150" },
  { label: "VENAREY LES LAUMES (21150)", value: "21150" },
  { label: "VERDONNET (21330)", value: "21330" },
  { label: "VERNOIS LES VESVRES (21260)", value: "21260" },
  { label: "VERNOT (21120)", value: "21120" },
  { label: "VERONNES (21260)", value: "21260" },
  { label: "VERONNES (21260)", value: "21260" },
  { label: "VERREY SOUS DREE (21540)", value: "21540" },
  { label: "VERREY SOUS SALMAISE (21690)", value: "21690" },
  { label: "VERTAULT (21330)", value: "21330" },
  { label: "VESVRES (21350)", value: "21350" },
  { label: "VEUVEY SUR OUCHE (21360)", value: "21360" },
  { label: "VEUXHAULLES SUR AUBE (21520)", value: "21520" },
  { label: "VIANGES (21430)", value: "21430" },
  { label: "VIC DE CHASSENAY (21140)", value: "21140" },
  { label: "VIC DES PRES (21360)", value: "21360" },
  { label: "VIC SOUS THIL (21390)", value: "21390" },
  { label: "VIEILMOULIN (21540)", value: "21540" },
  { label: "VIELVERGE (21270)", value: "21270" },
  { label: "VIEUX CHATEAU (21460)", value: "21460" },
  { label: "VIEVIGNE (21310)", value: "21310" },
  { label: "VIEVY (21230)", value: "21230" },
  { label: "VIGNOLES (21200)", value: "21200" },
  { label: "VILLAINES EN DUESMOIS (21450)", value: "21450" },
  { label: "VILLAINES LES PREVOTES (21500)", value: "21500" },
  { label: "VILLARGOIX (21210)", value: "21210" },
  { label: "VILLARS FONTAINE (21700)", value: "21700" },
  { label: "VILLARS ET VILLENOTTE (21140)", value: "21140" },
  { label: "VILLEBERNY (21350)", value: "21350" },
  { label: "VILLEBICHOT (21700)", value: "21700" },
  { label: "VILLECOMTE (21120)", value: "21120" },
  { label: "VILLEDIEU (21330)", value: "21330" },
  { label: "VILLEFERRY (21350)", value: "21350" },
  { label: "LA VILLENEUVE LES CONVERS (21450)", value: "21450" },
  { label: "VILLENEUVE SOUS CHARIGNY (21140)", value: "21140" },
  { label: "VILLERS LA FAYE (21700)", value: "21700" },
  { label: "VILLERS LES POTS (21130)", value: "21130" },
  { label: "VILLERS PATRAS (21400)", value: "21400" },
  { label: "VILLERS ROTIN (21130)", value: "21130" },
  { label: "VILLEY SUR TILLE (21120)", value: "21120" },
  { label: "VILLIERS EN MORVAN (21430)", value: "21430" },
  { label: "VILLIERS LE DUC (21400)", value: "21400" },
  { label: "VILLOTTE ST SEINE (21690)", value: "21690" },
  { label: "VILLOTTE SUR OURCE (21400)", value: "21400" },
  { label: "VILLY EN AUXOIS (21350)", value: "21350" },
  { label: "VILLY LE MOUTIER (21250)", value: "21250" },
  { label: "VISERNY (21500)", value: "21500" },
  { label: "VITTEAUX (21350)", value: "21350" },
  { label: "VIX (21400)", value: "21400" },
  { label: "VOLNAY (21190)", value: "21190" },
  { label: "VONGES (21270)", value: "21270" },
  { label: "VOSNE ROMANEE (21700)", value: "21700" },
  { label: "VOUDENAY (21230)", value: "21230" },
  { label: "VOUGEOT (21640)", value: "21640" },
  { label: "VOULAINES LES TEMPLIERS (21290)", value: "21290" },
  { label: "ALLINEUC (22460)", value: "22460" },
  { label: "ANDEL (22400)", value: "22400" },
  { label: "AUCALEUC (22100)", value: "22100" },
  { label: "BEGARD (22140)", value: "22140" },
  { label: "BEGARD (22140)", value: "22140" },
  { label: "BEGARD (22140)", value: "22140" },
  { label: "BEGARD (22140)", value: "22140" },
  { label: "BELLE ISLE EN TERRE (22810)", value: "22810" },
  { label: "BELLE ISLE EN TERRE (22810)", value: "22810" },
  { label: "BERHET (22140)", value: "22140" },
  { label: "BOBITAL (22100)", value: "22100" },
  { label: "LE BODEO (22320)", value: "22320" },
  { label: "BOQUEHO (22170)", value: "22170" },
  { label: "LA BOUILLIE (22240)", value: "22240" },
  { label: "BOURBRIAC (22390)", value: "22390" },
  { label: "BOURSEUL (22130)", value: "22130" },
  { label: "BREHAND (22510)", value: "22510" },
  { label: "ILE DE BREHAT (22870)", value: "22870" },
  { label: "BRELIDY (22140)", value: "22140" },
  { label: "BRINGOLO (22170)", value: "22170" },
  { label: "BROONS (22250)", value: "22250" },
  { label: "BRUSVILY (22100)", value: "22100" },
  { label: "BULAT PESTIVIEN (22160)", value: "22160" },
  { label: "CALANHEL (22160)", value: "22160" },
  { label: "CALLAC (22160)", value: "22160" },
  { label: "CALORGUEN (22100)", value: "22100" },
  { label: "LE CAMBOUT (22210)", value: "22210" },
  { label: "CAMLEZ (22450)", value: "22450" },
  { label: "CANIHUEL (22480)", value: "22480" },
  { label: "CAOUENNEC LANVEZEAC (22300)", value: "22300" },
  { label: "CAOUENNEC LANVEZEAC (22300)", value: "22300" },
  { label: "CARNOET (22160)", value: "22160" },
  { label: "CAULNES (22350)", value: "22350" },
  { label: "CAUREL (22530)", value: "22530" },
  { label: "CAVAN (22140)", value: "22140" },
  { label: "LES CHAMPS GERAUX (22630)", value: "22630" },
  { label: "LA CHAPELLE BLANCHE (22350)", value: "22350" },
  { label: "LA CHAPELLE NEUVE (22160)", value: "22160" },
  { label: "LA CHEZE (22210)", value: "22210" },
  { label: "COADOUT (22970)", value: "22970" },
  { label: "COATASCORN (22140)", value: "22140" },
  { label: "COATREVEN (22450)", value: "22450" },
  { label: "COETLOGON (22210)", value: "22210" },
  { label: "COETMIEUX (22400)", value: "22400" },
  { label: "COHINIAC (22800)", value: "22800" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "LE MENE (22330)", value: "22330" },
  { label: "CORLAY (22320)", value: "22320" },
  { label: "CORSEUL (22130)", value: "22130" },
  { label: "CREHEN (22130)", value: "22130" },
  { label: "DINAN (22100)", value: "22100" },
  { label: "DINAN (22100)", value: "22100" },
  { label: "DUAULT (22160)", value: "22160" },
  { label: "EREAC (22250)", value: "22250" },
  { label: "ERQUY (22430)", value: "22430" },
  { label: "ERQUY (22430)", value: "22430" },
  { label: "ERQUY (22430)", value: "22430" },
  { label: "BINIC ETABLES SUR MER (22520)", value: "22520" },
  { label: "BINIC ETABLES SUR MER (22680)", value: "22680" },
  { label: "EVRAN (22630)", value: "22630" },
  { label: "LE FAOUET (22290)", value: "22290" },
  { label: "LE FOEIL (22800)", value: "22800" },
  { label: "GAUSSON (22150)", value: "22150" },
  { label: "GLOMEL (22110)", value: "22110" },
  { label: "GOMENE (22230)", value: "22230" },
  { label: "GOMMENEC H (22290)", value: "22290" },
  { label: "GOUAREC (22570)", value: "22570" },
  { label: "GOUDELIN (22290)", value: "22290" },
  { label: "GRACES (22200)", value: "22200" },
  { label: "GRACES (22200)", value: "22200" },
  { label: "GRACE UZEL (22460)", value: "22460" },
  { label: "GUENROC (22350)", value: "22350" },
  { label: "GUINGAMP (22200)", value: "22200" },
  { label: "GUITTE (22350)", value: "22350" },
  { label: "GURUNHUEL (22390)", value: "22390" },
  { label: "LA HARMOYE (22320)", value: "22320" },
  { label: "LE HAUT CORLAY (22320)", value: "22320" },
  { label: "HEMONSTOIR (22600)", value: "22600" },
  { label: "HENANBIHEN (22550)", value: "22550" },
  { label: "HENANSAL (22400)", value: "22400" },
  { label: "HENON (22150)", value: "22150" },
  { label: "HILLION (22120)", value: "22120" },
  { label: "HILLION (22120)", value: "22120" },
  { label: "LE HINGLE (22100)", value: "22100" },
  { label: "ILLIFAUT (22230)", value: "22230" },
  { label: "JUGON LES LACS (22270)", value: "22270" },
  { label: "JUGON LES LACS (22270)", value: "22270" },
  { label: "JUGON LES LACS (22270)", value: "22270" },
  { label: "JUGON LES LACS (22270)", value: "22270" },
  { label: "KERBORS (22610)", value: "22610" },
  { label: "KERFOT (22500)", value: "22500" },
  { label: "KERGRIST MOELOU (22110)", value: "22110" },
  { label: "KERIEN (22480)", value: "22480" },
  { label: "KERMARIA SULARD (22450)", value: "22450" },
  { label: "KERMOROC H (22140)", value: "22140" },
  { label: "KERPERT (22480)", value: "22480" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LAMBALLE ARMOR (22400)", value: "22400" },
  { label: "LANCIEUX (22770)", value: "22770" },
  { label: "LANDEBAERON (22140)", value: "22140" },
  { label: "LANDEBIA (22130)", value: "22130" },
  { label: "LA LANDEC (22980)", value: "22980" },
  { label: "LANDEHEN (22400)", value: "22400" },
  { label: "LANFAINS (22800)", value: "22800" },
  { label: "LANGOAT (22450)", value: "22450" },
  { label: "LANGROLAY SUR RANCE (22490)", value: "22490" },
  { label: "LANGUEDIAS (22980)", value: "22980" },
  { label: "LANGUENAN (22130)", value: "22130" },
  { label: "LANGUEUX (22360)", value: "22360" },
  { label: "LANGUEUX (22360)", value: "22360" },
  { label: "BON REPOS SUR BLAVET (22570)", value: "22570" },
  { label: "BON REPOS SUR BLAVET (22570)", value: "22570" },
  { label: "BON REPOS SUR BLAVET (22570)", value: "22570" },
  { label: "LANLEFF (22290)", value: "22290" },
  { label: "LANLOUP (22580)", value: "22580" },
  { label: "LANMERIN (22300)", value: "22300" },
  { label: "LANMODEZ (22610)", value: "22610" },
  { label: "LANNEBERT (22290)", value: "22290" },
  { label: "LANNION (22300)", value: "22300" },
  { label: "LANNION (22300)", value: "22300" },
  { label: "LANRELAS (22250)", value: "22250" },
  { label: "LANRIVAIN (22480)", value: "22480" },
  { label: "LANRODEC (22170)", value: "22170" },
  { label: "LANTIC (22410)", value: "22410" },
  { label: "LANTIC (22410)", value: "22410" },
  { label: "LANVALLAY (22100)", value: "22100" },
  { label: "LANVALLAY (22100)", value: "22100" },
  { label: "LANVALLAY (22100)", value: "22100" },
  { label: "LANVELLEC (22420)", value: "22420" },
  { label: "LANVOLLON (22290)", value: "22290" },
  { label: "LAURENAN (22230)", value: "22230" },
  { label: "LESCOUET GOUAREC (22570)", value: "22570" },
  { label: "LE LESLAY (22800)", value: "22800" },
  { label: "LEZARDRIEUX (22740)", value: "22740" },
  { label: "LEZARDRIEUX (22740)", value: "22740" },
  { label: "LOCARN (22340)", value: "22340" },
  { label: "LOC ENVEL (22810)", value: "22810" },
  { label: "LOGUIVY PLOUGRAS (22780)", value: "22780" },
  { label: "LOHUEC (22160)", value: "22160" },
  { label: "LOSCOUET SUR MEU (22230)", value: "22230" },
  { label: "LOUANNEC (22700)", value: "22700" },
  { label: "LOUARGAT (22540)", value: "22540" },
  { label: "LOUARGAT (22540)", value: "22540" },
  { label: "LOUDEAC (22600)", value: "22600" },
  { label: "MAEL CARHAIX (22340)", value: "22340" },
  { label: "MAEL PESTIVIEN (22160)", value: "22160" },
  { label: "MAGOAR (22480)", value: "22480" },
  { label: "LA MALHOURE (22640)", value: "22640" },
  { label: "MANTALLOT (22450)", value: "22450" },
  { label: "MATIGNON (22550)", value: "22550" },
  { label: "LA MEAUGON (22440)", value: "22440" },
  { label: "MEGRIT (22270)", value: "22270" },
  { label: "MELLIONNEC (22110)", value: "22110" },
  { label: "MERDRIGNAC (22230)", value: "22230" },
  { label: "MERILLAC (22230)", value: "22230" },
  { label: "MERLEAC (22460)", value: "22460" },
  { label: "LE MERZER (22200)", value: "22200" },
  { label: "MINIHY TREGUIER (22220)", value: "22220" },
  { label: "MONCONTOUR (22510)", value: "22510" },
  { label: "LA MOTTE (22600)", value: "22600" },
  { label: "MOUSTERU (22200)", value: "22200" },
  { label: "LE MOUSTOIR (22340)", value: "22340" },
  { label: "GUERLEDAN (22530)", value: "22530" },
  { label: "GUERLEDAN (22530)", value: "22530" },
  { label: "NOYAL (22400)", value: "22400" },
  { label: "PABU (22200)", value: "22200" },
  { label: "PAIMPOL (22500)", value: "22500" },
  { label: "PAULE (22340)", value: "22340" },
  { label: "PEDERNEC (22540)", value: "22540" },
  { label: "PEDERNEC (22540)", value: "22540" },
  { label: "PENGUILY (22510)", value: "22510" },
  { label: "PENVENAN (22710)", value: "22710" },
  { label: "PENVENAN (22710)", value: "22710" },
  { label: "PENVENAN (22710)", value: "22710" },
  { label: "PERROS GUIREC (22700)", value: "22700" },
  { label: "PERROS GUIREC (22700)", value: "22700" },
  { label: "PERROS GUIREC (22700)", value: "22700" },
  { label: "PEUMERIT QUINTIN (22480)", value: "22480" },
  { label: "PLAINE HAUTE (22800)", value: "22800" },
  { label: "PLAINTEL (22940)", value: "22940" },
  { label: "PLANCOET (22130)", value: "22130" },
  { label: "PLEBOULLE (22550)", value: "22550" },
  { label: "PLEDELIAC (22270)", value: "22270" },
  { label: "PLEDRAN (22960)", value: "22960" },
  { label: "PLEGUIEN (22290)", value: "22290" },
  { label: "PLEHEDEL (22290)", value: "22290" },
  { label: "FREHEL (22240)", value: "22240" },
  { label: "FREHEL (22240)", value: "22240" },
  { label: "FREHEL (22240)", value: "22240" },
  { label: "PLELAN LE PETIT (22980)", value: "22980" },
  { label: "PLELAUFF (22570)", value: "22570" },
  { label: "PLELO (22170)", value: "22170" },
  { label: "PLELO (22170)", value: "22170" },
  { label: "PLEMET (22210)", value: "22210" },
  { label: "PLEMET (22210)", value: "22210" },
  { label: "PLEMY (22150)", value: "22150" },
  { label: "PLENEE JUGON (22640)", value: "22640" },
  { label: "PLENEUF VAL ANDRE (22370)", value: "22370" },
  { label: "PLERIN (22190)", value: "22190" },
  { label: "PLERIN (22190)", value: "22190" },
  { label: "PLERIN (22190)", value: "22190" },
  { label: "PLERNEUF (22170)", value: "22170" },
  { label: "PLESIDY (22720)", value: "22720" },
  { label: "PLESLIN TRIGAVOU (22490)", value: "22490" },
  { label: "PLESLIN TRIGAVOU (22490)", value: "22490" },
  { label: "PLESTAN (22640)", value: "22640" },
  { label: "PLESTIN LES GREVES (22310)", value: "22310" },
  { label: "PLESTIN LES GREVES (22310)", value: "22310" },
  { label: "PLEUBIAN (22610)", value: "22610" },
  { label: "PLEUBIAN (22610)", value: "22610" },
  { label: "PLEUDANIEL (22740)", value: "22740" },
  { label: "PLEUDIHEN SUR RANCE (22690)", value: "22690" },
  { label: "PLEUMEUR BODOU (22560)", value: "22560" },
  { label: "PLEUMEUR BODOU (22560)", value: "22560" },
  { label: "PLEUMEUR BODOU (22560)", value: "22560" },
  { label: "PLEUMEUR GAUTIER (22740)", value: "22740" },
  { label: "PLEVEN (22130)", value: "22130" },
  { label: "PLEVENON (22240)", value: "22240" },
  { label: "PLEVIN (22340)", value: "22340" },
  { label: "PLOEUC L HERMITAGE (22150)", value: "22150" },
  { label: "PLOEUC L HERMITAGE (22150)", value: "22150" },
  { label: "PLOEZAL (22260)", value: "22260" },
  { label: "PLOREC SUR ARGUENON (22130)", value: "22130" },
  { label: "CHATELAUDREN PLOUAGAT (22170)", value: "22170" },
  { label: "CHATELAUDREN PLOUAGAT (22170)", value: "22170" },
  { label: "PLOUARET (22420)", value: "22420" },
  { label: "PLOUASNE (22830)", value: "22830" },
  { label: "BEAUSSAIS SUR MER (22650)", value: "22650" },
  { label: "BEAUSSAIS SUR MER (22650)", value: "22650" },
  { label: "BEAUSSAIS SUR MER (22650)", value: "22650" },
  { label: "PLOUBAZLANEC (22620)", value: "22620" },
  { label: "PLOUBAZLANEC (22620)", value: "22620" },
  { label: "PLOUBAZLANEC (22620)", value: "22620" },
  { label: "PLOUBEZRE (22300)", value: "22300" },
  { label: "PLOUEC DU TRIEUX (22260)", value: "22260" },
  { label: "PLOUER SUR RANCE (22490)", value: "22490" },
  { label: "PLOUEZEC (22470)", value: "22470" },
  { label: "PLOUEZEC (22470)", value: "22470" },
  { label: "PLOUFRAGAN (22440)", value: "22440" },
  { label: "PLOUFRAGAN (22440)", value: "22440" },
  { label: "PLOUGONVER (22810)", value: "22810" },
  { label: "PLOUGRAS (22780)", value: "22780" },
  { label: "PLOUGRESCANT (22820)", value: "22820" },
  { label: "PLOUGUENAST LANGAST (22150)", value: "22150" },
  { label: "PLOUGUENAST LANGAST (22150)", value: "22150" },
  { label: "PLOUGUERNEVEL (22110)", value: "22110" },
  { label: "PLOUGUIEL (22220)", value: "22220" },
  { label: "PLOUHA (22580)", value: "22580" },
  { label: "PLOUHA (22580)", value: "22580" },
  { label: "PLOUISY (22200)", value: "22200" },
  { label: "PLOULEC H (22300)", value: "22300" },
  { label: "PLOULEC H (22300)", value: "22300" },
  { label: "PLOUMAGOAR (22970)", value: "22970" },
  { label: "PLOUMILLIAU (22300)", value: "22300" },
  { label: "PLOUNERIN (22780)", value: "22780" },
  { label: "PLOUNEVEZ MOEDEC (22810)", value: "22810" },
  { label: "PLOUNEVEZ QUINTIN (22110)", value: "22110" },
  { label: "PLOURAC H (22160)", value: "22160" },
  { label: "PLOURHAN (22410)", value: "22410" },
  { label: "PLOURIVO (22860)", value: "22860" },
  { label: "PLOUVARA (22170)", value: "22170" },
  { label: "PLOUZELAMBRE (22420)", value: "22420" },
  { label: "PLUDUAL (22290)", value: "22290" },
  { label: "PLUDUNO (22130)", value: "22130" },
  { label: "PLUFUR (22310)", value: "22310" },
  { label: "PLUMAUDAN (22350)", value: "22350" },
  { label: "PLUMAUGAT (22250)", value: "22250" },
  { label: "PLUMIEUX (22210)", value: "22210" },
  { label: "PLURIEN (22240)", value: "22240" },
  { label: "PLUSQUELLEC (22160)", value: "22160" },
  { label: "PLUSSULIEN (22320)", value: "22320" },
  { label: "PLUZUNET (22140)", value: "22140" },
  { label: "POMMERET (22120)", value: "22120" },
  { label: "POMMERIT LE VICOMTE (22200)", value: "22200" },
  { label: "PONT MELVEZ (22390)", value: "22390" },
  { label: "PONTRIEUX (22260)", value: "22260" },
  { label: "PORDIC (22590)", value: "22590" },
  { label: "PORDIC (22590)", value: "22590" },
  { label: "PORDIC (22590)", value: "22590" },
  { label: "PRAT (22140)", value: "22140" },
  { label: "LA PRENESSAYE (22210)", value: "22210" },
  { label: "QUEMPER GUEZENNEC (22260)", value: "22260" },
  { label: "QUEMPERVEN (22450)", value: "22450" },
  { label: "QUESSOY (22120)", value: "22120" },
  { label: "QUESSOY (22120)", value: "22120" },
  { label: "QUEVERT (22100)", value: "22100" },
  { label: "QUEVERT (22100)", value: "22100" },
  { label: "LE QUILLIO (22460)", value: "22460" },
  { label: "QUINTENIC (22400)", value: "22400" },
  { label: "QUINTIN (22800)", value: "22800" },
  { label: "LE QUIOU (22630)", value: "22630" },
  { label: "LA ROCHE JAUDY (22450)", value: "22450" },
  { label: "LA ROCHE JAUDY (22450)", value: "22450" },
  { label: "LA ROCHE JAUDY (22450)", value: "22450" },
  { label: "LA ROCHE JAUDY (22450)", value: "22450" },
  { label: "ROSPEZ (22300)", value: "22300" },
  { label: "ROSTRENEN (22110)", value: "22110" },
  { label: "ROSTRENEN (22110)", value: "22110" },
  { label: "ROUILLAC (22250)", value: "22250" },
  { label: "RUCA (22550)", value: "22550" },
  { label: "RUNAN (22260)", value: "22260" },
  { label: "ST ADRIEN (22390)", value: "22390" },
  { label: "ST AGATHON (22200)", value: "22200" },
  { label: "ST ALBAN (22400)", value: "22400" },
  { label: "ST ANDRE DES EAUX (22630)", value: "22630" },
  { label: "ST BARNABE (22600)", value: "22600" },
  { label: "ST BIHY (22800)", value: "22800" },
  { label: "ST BRANDAN (22800)", value: "22800" },
  { label: "ST BRIEUC (22000)", value: "22000" },
  { label: "ST CARADEC (22600)", value: "22600" },
  { label: "ST CARNE (22100)", value: "22100" },
  { label: "ST CARREUC (22150)", value: "22150" },
  { label: "ST CAST LE GUILDO (22380)", value: "22380" },
  { label: "ST CAST LE GUILDO (22380)", value: "22380" },
  { label: "ST CLET (22260)", value: "22260" },
  { label: "ST CONNAN (22480)", value: "22480" },
  { label: "ST CONNEC (22530)", value: "22530" },
  { label: "ST DENOUAL (22400)", value: "22400" },
  { label: "ST DONAN (22800)", value: "22800" },
  { label: "ST ETIENNE DU GUE DE L ISLE (22210)", value: "22210" },
  { label: "ST FIACRE (22720)", value: "22720" },
  { label: "ST GILDAS (22800)", value: "22800" },
  { label: "ST GILLES LES BOIS (22290)", value: "22290" },
  { label: "ST GILLES PLIGEAUX (22480)", value: "22480" },
  { label: "ST GILLES VIEUX MARCHE (22530)", value: "22530" },
  { label: "ST GLEN (22510)", value: "22510" },
  { label: "ST HELEN (22100)", value: "22100" },
  { label: "ST HERVE (22460)", value: "22460" },
  { label: "ST JACUT DE LA MER (22750)", value: "22750" },
  { label: "ST JEAN KERDANIEL (22170)", value: "22170" },
  { label: "ST JOUAN DE L ISLE (22350)", value: "22350" },
  { label: "ST JUDOCE (22630)", value: "22630" },
  { label: "ST JULIEN (22940)", value: "22940" },
  { label: "ST JUVAT (22630)", value: "22630" },
  { label: "ST LAUNEUC (22230)", value: "22230" },
  { label: "ST LAURENT (22140)", value: "22140" },
  { label: "ST LORMEL (22130)", value: "22130" },
  { label: "ST MADEN (22350)", value: "22350" },
  { label: "ST MARTIN DES PRES (22320)", value: "22320" },
  { label: "ST MAUDAN (22600)", value: "22600" },
  { label: "ST MAUDEZ (22980)", value: "22980" },
  { label: "ST MAYEUX (22320)", value: "22320" },
  { label: "ST MELOIR DES BOIS (22980)", value: "22980" },
  { label: "ST MICHEL DE PLELAN (22980)", value: "22980" },
  { label: "ST MICHEL EN GREVE (22300)", value: "22300" },
  { label: "ST NICODEME (22160)", value: "22160" },
  { label: "ST NICOLAS DU PELEM (22480)", value: "22480" },
  { label: "ST PEVER (22720)", value: "22720" },
  { label: "ST POTAN (22550)", value: "22550" },
  { label: "ST QUAY PERROS (22700)", value: "22700" },
  { label: "ST QUAY PORTRIEUX (22410)", value: "22410" },
  { label: "ST QUAY PORTRIEUX (22410)", value: "22410" },
  { label: "ST RIEUL (22270)", value: "22270" },
  { label: "ST SAMSON SUR RANCE (22100)", value: "22100" },
  { label: "ST SAMSON SUR RANCE (22100)", value: "22100" },
  { label: "ST SERVAIS (22160)", value: "22160" },
  { label: "ST THELO (22460)", value: "22460" },
  { label: "STE TREPHINE (22480)", value: "22480" },
  { label: "ST TRIMOEL (22510)", value: "22510" },
  { label: "ST VRAN (22230)", value: "22230" },
  { label: "ST IGEAUX (22570)", value: "22570" },
  { label: "SENVEN LEHART (22720)", value: "22720" },
  { label: "SEVIGNAC (22250)", value: "22250" },
  { label: "SQUIFFIEC (22200)", value: "22200" },
  { label: "TADEN (22100)", value: "22100" },
  { label: "TONQUEDEC (22140)", value: "22140" },
  { label: "TRAMAIN (22640)", value: "22640" },
  { label: "TREBEDAN (22980)", value: "22980" },
  { label: "TREBEURDEN (22560)", value: "22560" },
  { label: "TREBRIVAN (22340)", value: "22340" },
  { label: "TREBRY (22510)", value: "22510" },
  { label: "TREDANIEL (22510)", value: "22510" },
  { label: "TREDARZEC (22220)", value: "22220" },
  { label: "TREDIAS (22250)", value: "22250" },
  { label: "TREDREZ LOCQUEMEAU (22300)", value: "22300" },
  { label: "TREDREZ LOCQUEMEAU (22300)", value: "22300" },
  { label: "TREDUDER (22310)", value: "22310" },
  { label: "TREFFRIN (22340)", value: "22340" },
  { label: "TREFUMEL (22630)", value: "22630" },
  { label: "TREGASTEL (22730)", value: "22730" },
  { label: "TREGLAMUS (22540)", value: "22540" },
  { label: "TREGOMEUR (22590)", value: "22590" },
  { label: "TREGONNEAU (22200)", value: "22200" },
  { label: "TREGROM (22420)", value: "22420" },
  { label: "TREGUEUX (22950)", value: "22950" },
  { label: "TREGUEUX (22950)", value: "22950" },
  { label: "TREGUIDEL (22290)", value: "22290" },
  { label: "TREGUIER (22220)", value: "22220" },
  { label: "TRELEVERN (22660)", value: "22660" },
  { label: "TRELIVAN (22100)", value: "22100" },
  { label: "TREMARGAT (22110)", value: "22110" },
  { label: "TREMEL (22310)", value: "22310" },
  { label: "TREMEREUC (22490)", value: "22490" },
  { label: "TREMEUR (22250)", value: "22250" },
  { label: "TREMEVEN (22290)", value: "22290" },
  { label: "TREMOREL (22230)", value: "22230" },
  { label: "TREMUSON (22440)", value: "22440" },
  { label: "TREOGAN (22340)", value: "22340" },
  { label: "TRESSIGNAUX (22290)", value: "22290" },
  { label: "TREVE (22600)", value: "22600" },
  { label: "TREVENEUC (22410)", value: "22410" },
  { label: "TREVEREC (22290)", value: "22290" },
  { label: "TREVOU TREGUIGNEC (22660)", value: "22660" },
  { label: "TREVRON (22100)", value: "22100" },
  { label: "TREZENY (22450)", value: "22450" },
  { label: "TROGUERY (22450)", value: "22450" },
  { label: "UZEL (22460)", value: "22460" },
  { label: "LA VICOMTE SUR RANCE (22690)", value: "22690" },
  { label: "LE VIEUX BOURG (22800)", value: "22800" },
  { label: "LE VIEUX MARCHE (22420)", value: "22420" },
  { label: "VILDE GUINGALAN (22980)", value: "22980" },
  { label: "YFFINIAC (22120)", value: "22120" },
  { label: "YVIAS (22930)", value: "22930" },
  { label: "YVIGNAC LA TOUR (22350)", value: "22350" },
  { label: "AHUN (23150)", value: "23150" },
  { label: "AJAIN (23380)", value: "23380" },
  { label: "ALLEYRAT (23200)", value: "23200" },
  { label: "ANZEME (23000)", value: "23000" },
  { label: "ARFEUILLE CHATAIN (23700)", value: "23700" },
  { label: "ARRENES (23210)", value: "23210" },
  { label: "ARS (23480)", value: "23480" },
  { label: "AUBUSSON (23200)", value: "23200" },
  { label: "AUGE (23170)", value: "23170" },
  { label: "AUGERES (23210)", value: "23210" },
  { label: "AULON (23210)", value: "23210" },
  { label: "AURIAT (23400)", value: "23400" },
  { label: "AUZANCES (23700)", value: "23700" },
  { label: "AZAT CHATENET (23210)", value: "23210" },
  { label: "AZERABLES (23160)", value: "23160" },
  { label: "BANIZE (23120)", value: "23120" },
  { label: "BASVILLE (23260)", value: "23260" },
  { label: "BAZELAT (23160)", value: "23160" },
  { label: "BEISSAT (23260)", value: "23260" },
  { label: "BELLEGARDE EN MARCHE (23190)", value: "23190" },
  { label: "BENEVENT L ABBAYE (23210)", value: "23210" },
  { label: "BETETE (23270)", value: "23270" },
  { label: "BLAUDEIX (23140)", value: "23140" },
  { label: "BLESSAC (23200)", value: "23200" },
  { label: "BONNAT (23220)", value: "23220" },
  { label: "BORD ST GEORGES (23230)", value: "23230" },
  { label: "BOSMOREAU LES MINES (23400)", value: "23400" },
  { label: "BOSROGER (23200)", value: "23200" },
  { label: "LE BOURG D HEM (23220)", value: "23220" },
  { label: "BOURGANEUF (23400)", value: "23400" },
  { label: "BOUSSAC (23600)", value: "23600" },
  { label: "BOUSSAC BOURG (23600)", value: "23600" },
  { label: "LA BRIONNE (23000)", value: "23000" },
  { label: "BROUSSE (23700)", value: "23700" },
  { label: "BUDELIERE (23170)", value: "23170" },
  { label: "BUSSIERE DUNOISE (23320)", value: "23320" },
  { label: "BUSSIERE NOUVELLE (23700)", value: "23700" },
  { label: "BUSSIERE ST GEORGES (23600)", value: "23600" },
  { label: "LA CELLE DUNOISE (23800)", value: "23800" },
  { label: "LA CELLE SOUS GOUZON (23230)", value: "23230" },
  { label: "LA CELLETTE (23350)", value: "23350" },
  { label: "CEYROUX (23210)", value: "23210" },
  { label: "CHAMBERAUD (23480)", value: "23480" },
  { label: "CHAMBON STE CROIX (23220)", value: "23220" },
  { label: "CHAMBON SUR VOUEIZE (23170)", value: "23170" },
  { label: "CHAMBONCHARD (23110)", value: "23110" },
  { label: "CHAMBORAND (23240)", value: "23240" },
  { label: "CHAMPAGNAT (23190)", value: "23190" },
  { label: "CHAMPSANGLARD (23220)", value: "23220" },
  { label: "LA CHAPELLE BALOUE (23160)", value: "23160" },
  { label: "LA CHAPELLE ST MARTIAL (23250)", value: "23250" },
  { label: "LA CHAPELLE TAILLEFERT (23000)", value: "23000" },
  { label: "CHARD (23700)", value: "23700" },
  { label: "CHARRON (23700)", value: "23700" },
  { label: "CHATELARD (23700)", value: "23700" },
  { label: "CHATELUS LE MARCHEIX (23430)", value: "23430" },
  { label: "CHATELUS MALVALEIX (23270)", value: "23270" },
  { label: "LE CHAUCHET (23130)", value: "23130" },
  { label: "LA CHAUSSADE (23200)", value: "23200" },
  { label: "CHAVANAT (23250)", value: "23250" },
  { label: "CHENERAILLES (23130)", value: "23130" },
  { label: "CHENIERS (23220)", value: "23220" },
  { label: "CLAIRAVAUX (23500)", value: "23500" },
  { label: "CLUGNAT (23270)", value: "23270" },
  { label: "COLONDANNES (23800)", value: "23800" },
  { label: "LE COMPAS (23700)", value: "23700" },
  { label: "LA COURTINE (23100)", value: "23100" },
  { label: "CRESSAT (23140)", value: "23140" },
  { label: "CROCQ (23260)", value: "23260" },
  { label: "CROZANT (23160)", value: "23160" },
  { label: "CROZE (23500)", value: "23500" },
  { label: "DOMEYROT (23140)", value: "23140" },
  { label: "DONTREIX (23700)", value: "23700" },
  { label: "LE DONZEIL (23480)", value: "23480" },
  { label: "DUN LE PALESTEL (23800)", value: "23800" },
  { label: "EVAUX LES BAINS (23110)", value: "23110" },
  { label: "FAUX LA MONTAGNE (23340)", value: "23340" },
  { label: "FAUX MAZURAS (23400)", value: "23400" },
  { label: "FELLETIN (23500)", value: "23500" },
  { label: "FENIERS (23100)", value: "23100" },
  { label: "FLAYAT (23260)", value: "23260" },
  { label: "FLEURAT (23320)", value: "23320" },
  { label: "FONTANIERES (23110)", value: "23110" },
  { label: "LA FORET DU TEMPLE (23360)", value: "23360" },
  { label: "FRANSECHES (23480)", value: "23480" },
  { label: "FRESSELINES (23450)", value: "23450" },
  { label: "GARTEMPE (23320)", value: "23320" },
  { label: "GENOUILLAC (23350)", value: "23350" },
  { label: "GENTIOUX PIGEROLLES (23340)", value: "23340" },
  { label: "GENTIOUX PIGEROLLES (23340)", value: "23340" },
  { label: "GIOUX (23500)", value: "23500" },
  { label: "GLENIC (23380)", value: "23380" },
  { label: "GOUZON (23230)", value: "23230" },
  { label: "GOUZON (23230)", value: "23230" },
  { label: "GOUZON (23230)", value: "23230" },
  { label: "LE GRAND BOURG (23240)", value: "23240" },
  { label: "GUERET (23000)", value: "23000" },
  { label: "ISSOUDUN LETRIEIX (23130)", value: "23130" },
  { label: "JALESCHES (23270)", value: "23270" },
  { label: "JANAILLAT (23250)", value: "23250" },
  { label: "JARNAGES (23140)", value: "23140" },
  { label: "JOUILLAT (23220)", value: "23220" },
  { label: "LADAPEYRE (23270)", value: "23270" },
  { label: "LAFAT (23800)", value: "23800" },
  { label: "LAVAUFRANCHE (23600)", value: "23600" },
  { label: "LAVAVEIX LES MINES (23150)", value: "23150" },
  { label: "LEPAUD (23170)", value: "23170" },
  { label: "LEPINAS (23150)", value: "23150" },
  { label: "LEYRAT (23600)", value: "23600" },
  { label: "LINARD MALVAL (23220)", value: "23220" },
  { label: "LINARD MALVAL (23220)", value: "23220" },
  { label: "LIOUX LES MONGES (23700)", value: "23700" },
  { label: "LIZIERES (23240)", value: "23240" },
  { label: "LOURDOUEIX ST PIERRE (23360)", value: "23360" },
  { label: "LOURDOUEIX ST PIERRE (23360)", value: "23360" },
  { label: "LUPERSAT (23190)", value: "23190" },
  { label: "LUSSAT (23170)", value: "23170" },
  { label: "MAGNAT L ETRANGE (23260)", value: "23260" },
  { label: "MAINSAT (23700)", value: "23700" },
  { label: "MAISON FEYNE (23800)", value: "23800" },
  { label: "MAISONNISSES (23150)", value: "23150" },
  { label: "MALLERET (23260)", value: "23260" },
  { label: "MALLERET BOUSSAC (23600)", value: "23600" },
  { label: "MANSAT LA COURRIERE (23400)", value: "23400" },
  { label: "LES MARS (23700)", value: "23700" },
  { label: "MARSAC (23210)", value: "23210" },
  { label: "LE MAS D ARTIGE (23100)", value: "23100" },
  { label: "MAUTES (23190)", value: "23190" },
  { label: "MAZEIRAT (23150)", value: "23150" },
  { label: "LA MAZIERE AUX BONS HOMMES (23260)", value: "23260" },
  { label: "MEASNES (23360)", value: "23360" },
  { label: "MERINCHAL (23420)", value: "23420" },
  { label: "MONTAIGUT LE BLANC (23320)", value: "23320" },
  { label: "MONTBOUCHER (23400)", value: "23400" },
  { label: "LE MONTEIL AU VICOMTE (23460)", value: "23460" },
  { label: "MORTROUX (23220)", value: "23220" },
  { label: "MOURIOUX VIEILLEVILLE (23210)", value: "23210" },
  { label: "MOUTIER D AHUN (23150)", value: "23150" },
  { label: "MOUTIER MALCARD (23220)", value: "23220" },
  { label: "MOUTIER ROZEILLE (23200)", value: "23200" },
  { label: "NAILLAT (23800)", value: "23800" },
  { label: "NEOUX (23200)", value: "23200" },
  { label: "NOTH (23300)", value: "23300" },
  { label: "LA NOUAILLE (23500)", value: "23500" },
  { label: "NOUHANT (23170)", value: "23170" },
  { label: "NOUZERINES (23600)", value: "23600" },
  { label: "NOUZEROLLES (23360)", value: "23360" },
  { label: "NOUZIERS (23350)", value: "23350" },
  { label: "PARSAC RIMONDEIX (23140)", value: "23140" },
  { label: "PARSAC RIMONDEIX (23140)", value: "23140" },
  { label: "PEYRABOUT (23000)", value: "23000" },
  { label: "PEYRAT LA NONIERE (23130)", value: "23130" },
  { label: "PIERREFITTE (23130)", value: "23130" },
  { label: "PIONNAT (23140)", value: "23140" },
  { label: "PONTARION (23250)", value: "23250" },
  { label: "PONTCHARRAUD (23260)", value: "23260" },
  { label: "LA POUGE (23250)", value: "23250" },
  { label: "POUSSANGES (23500)", value: "23500" },
  { label: "PUY MALSIGNAT (23130)", value: "23130" },
  { label: "RETERRE (23110)", value: "23110" },
  { label: "ROCHES (23270)", value: "23270" },
  { label: "ROUGNAT (23700)", value: "23700" },
  { label: "ROYERE DE VASSIVIERE (23460)", value: "23460" },
  { label: "SAGNAT (23800)", value: "23800" },
  { label: "SANNAT (23110)", value: "23110" },
  { label: "SARDENT (23250)", value: "23250" },
  { label: "LA SAUNIERE (23000)", value: "23000" },
  { label: "SAVENNES (23000)", value: "23000" },
  { label: "SERMUR (23700)", value: "23700" },
  { label: "LA SERRE BUSSIERE VIEILLE (23190)", value: "23190" },
  { label: "SOUBREBOST (23250)", value: "23250" },
  { label: "SOUMANS (23600)", value: "23600" },
  { label: "SOUS PARSAT (23150)", value: "23150" },
  { label: "LA SOUTERRAINE (23300)", value: "23300" },
  { label: "ST AGNANT DE VERSILLAT (23300)", value: "23300" },
  { label: "ST AGNANT PRES CROCQ (23260)", value: "23260" },
  { label: "ST ALPINIEN (23200)", value: "23200" },
  { label: "ST AMAND (23200)", value: "23200" },
  { label: "ST AMAND JARTOUDEIX (23400)", value: "23400" },
  { label: "ST AVIT DE TARDES (23200)", value: "23200" },
  { label: "ST AVIT LE PAUVRE (23480)", value: "23480" },
  { label: "ST BARD (23260)", value: "23260" },
  { label: "ST CHABRAIS (23130)", value: "23130" },
  { label: "ST CHRISTOPHE (23000)", value: "23000" },
  { label: "ST DIZIER LA TOUR (23130)", value: "23130" },
  { label: "ST DIZIER LES DOMAINES (23270)", value: "23270" },
  { label: "ST DIZIER MASBARAUD (23400)", value: "23400" },
  { label: "ST DIZIER MASBARAUD (23400)", value: "23400" },
  { label: "ST DOMET (23190)", value: "23190" },
  { label: "ST ELOI (23000)", value: "23000" },
  { label: "FURSAC (23290)", value: "23290" },
  { label: "FURSAC (23290)", value: "23290" },
  { label: "STE FEYRE (23000)", value: "23000" },
  { label: "STE FEYRE LA MONTAGNE (23500)", value: "23500" },
  { label: "ST FIEL (23000)", value: "23000" },
  { label: "ST FRION (23500)", value: "23500" },
  { label: "ST GEORGES LA POUGE (23250)", value: "23250" },
  { label: "ST GEORGES NIGREMONT (23500)", value: "23500" },
  { label: "ST GERMAIN BEAUPRE (23160)", value: "23160" },
  { label: "ST GERMAIN BEAUPRE (23160)", value: "23160" },
  { label: "ST GOUSSAUD (23430)", value: "23430" },
  { label: "ST HILAIRE LA PLAINE (23150)", value: "23150" },
  { label: "ST HILAIRE LE CHATEAU (23250)", value: "23250" },
  { label: "ST JULIEN LA GENETE (23110)", value: "23110" },
  { label: "ST JULIEN LE CHATEL (23130)", value: "23130" },
  { label: "ST JUNIEN LA BREGERE (23400)", value: "23400" },
  { label: "ST LAURENT (23000)", value: "23000" },
  { label: "ST LEGER BRIDEREIX (23300)", value: "23300" },
  { label: "ST LEGER LE GUERETOIS (23000)", value: "23000" },
  { label: "ST LOUP (23130)", value: "23130" },
  { label: "ST MAIXANT (23200)", value: "23200" },
  { label: "ST MARC A FRONGIER (23200)", value: "23200" },
  { label: "ST MARC A LOUBAUD (23460)", value: "23460" },
  { label: "ST MARIEN (23600)", value: "23600" },
  { label: "ST MARTIAL LE MONT (23150)", value: "23150" },
  { label: "ST MARTIAL LE VIEUX (23100)", value: "23100" },
  { label: "ST MARTIN CHATEAU (23460)", value: "23460" },
  { label: "ST MARTIN STE CATHERINE (23430)", value: "23430" },
  { label: "ST MARTIN STE CATHERINE (23430)", value: "23430" },
  { label: "ST MAURICE PRES CROCQ (23260)", value: "23260" },
  { label: "ST MAURICE LA SOUTERRAINE (23300)", value: "23300" },
  { label: "ST MAURICE LA SOUTERRAINE (23300)", value: "23300" },
  { label: "ST MEDARD LA ROCHETTE (23200)", value: "23200" },
  { label: "ST MEDARD LA ROCHETTE (23200)", value: "23200" },
  { label: "ST MEDARD LA ROCHETTE (23200)", value: "23200" },
  { label: "ST MERD LA BREUILLE (23100)", value: "23100" },
  { label: "ST MICHEL DE VEISSE (23480)", value: "23480" },
  { label: "ST MOREIL (23400)", value: "23400" },
  { label: "ST ORADOUX DE CHIROUZE (23100)", value: "23100" },
  { label: "ST ORADOUX PRES CROCQ (23260)", value: "23260" },
  { label: "ST PARDOUX D ARNET (23260)", value: "23260" },
  { label: "ST PARDOUX MORTEROLLES (23400)", value: "23400" },
  { label: "ST PARDOUX LE NEUF (23200)", value: "23200" },
  { label: "ST PARDOUX LES CARDS (23150)", value: "23150" },
  { label: "ST PIERRE CHERIGNAT (23430)", value: "23430" },
  { label: "ST PIERRE BELLEVUE (23460)", value: "23460" },
  { label: "ST PIERRE BELLEVUE (23460)", value: "23460" },
  { label: "ST PIERRE LE BOST (23600)", value: "23600" },
  { label: "ST PRIEST (23110)", value: "23110" },
  { label: "ST PRIEST LA FEUILLE (23300)", value: "23300" },
  { label: "ST PRIEST LA PLAINE (23240)", value: "23240" },
  { label: "ST PRIEST PALUS (23400)", value: "23400" },
  { label: "ST QUENTIN LA CHABANNE (23500)", value: "23500" },
  { label: "ST SEBASTIEN (23160)", value: "23160" },
  { label: "ST SILVAIN BAS LE ROC (23600)", value: "23600" },
  { label: "ST SILVAIN BELLEGARDE (23190)", value: "23190" },
  { label: "ST SILVAIN MONTAIGUT (23320)", value: "23320" },
  { label: "ST SILVAIN SOUS TOULX (23140)", value: "23140" },
  { label: "ST SULPICE LE DUNOIS (23800)", value: "23800" },
  { label: "ST SULPICE LE GUERETOIS (23000)", value: "23000" },
  { label: "ST SULPICE LES CHAMPS (23480)", value: "23480" },
  { label: "ST VAURY (23320)", value: "23320" },
  { label: "ST VICTOR EN MARCHE (23000)", value: "23000" },
  { label: "ST YRIEIX LA MONTAGNE (23460)", value: "23460" },
  { label: "ST YRIEIX LES BOIS (23150)", value: "23150" },
  { label: "TARDES (23170)", value: "23170" },
  { label: "TERCILLAT (23350)", value: "23350" },
  { label: "THAURON (23250)", value: "23250" },
  { label: "TOULX STE CROIX (23600)", value: "23600" },
  { label: "TROIS FONDS (23230)", value: "23230" },
  { label: "VALLIERE (23120)", value: "23120" },
  { label: "VAREILLES (23300)", value: "23300" },
  { label: "VERNEIGES (23170)", value: "23170" },
  { label: "VIDAILLAT (23250)", value: "23250" },
  { label: "VIERSAT (23170)", value: "23170" },
  { label: "VIGEVILLE (23140)", value: "23140" },
  { label: "VILLARD (23800)", value: "23800" },
  { label: "LA VILLEDIEU (23340)", value: "23340" },
  { label: "LA VILLENEUVE (23260)", value: "23260" },
  { label: "LA VILLETELLE (23260)", value: "23260" },
  { label: "ABJAT SUR BANDIAT (24300)", value: "24300" },
  { label: "AGONAC (24460)", value: "24460" },
  { label: "AJAT (24210)", value: "24210" },
  { label: "ALLES SUR DORDOGNE (24480)", value: "24480" },
  { label: "ALLAS LES MINES (24220)", value: "24220" },
  { label: "ALLEMANS (24600)", value: "24600" },
  { label: "ANGOISSE (24270)", value: "24270" },
  { label: "ANLHIAC (24160)", value: "24160" },
  { label: "ANNESSE ET BEAULIEU (24430)", value: "24430" },
  { label: "ANTONNE ET TRIGONANT (24420)", value: "24420" },
  { label: "ARCHIGNAC (24590)", value: "24590" },
  { label: "AUBAS (24290)", value: "24290" },
  { label: "AUDRIX (24260)", value: "24260" },
  { label: "AUGIGNAC (24300)", value: "24300" },
  { label: "AURIAC DU PERIGORD (24290)", value: "24290" },
  { label: "AZERAT (24210)", value: "24210" },
  { label: "LA BACHELLERIE (24210)", value: "24210" },
  { label: "BADEFOLS D ANS (24390)", value: "24390" },
  { label: "BADEFOLS SUR DORDOGNE (24150)", value: "24150" },
  { label: "BANEUIL (24150)", value: "24150" },
  { label: "BARDOU (24560)", value: "24560" },
  { label: "BARS (24210)", value: "24210" },
  { label: "BASSILLAC ET AUBEROCHE (24330)", value: "24330" },
  { label: "BASSILLAC ET AUBEROCHE (24330)", value: "24330" },
  { label: "BASSILLAC ET AUBEROCHE (24330)", value: "24330" },
  { label: "BASSILLAC ET AUBEROCHE (24330)", value: "24330" },
  { label: "BASSILLAC ET AUBEROCHE (24330)", value: "24330" },
  { label: "BASSILLAC ET AUBEROCHE (24640)", value: "24640" },
  { label: "BAYAC (24150)", value: "24150" },
  { label: "BEAUMONTOIS EN PERIGORD (24440)", value: "24440" },
  { label: "BEAUMONTOIS EN PERIGORD (24440)", value: "24440" },
  { label: "BEAUMONTOIS EN PERIGORD (24440)", value: "24440" },
  { label: "BEAUMONTOIS EN PERIGORD (24440)", value: "24440" },
  { label: "BEAUMONTOIS EN PERIGORD (24440)", value: "24440" },
  { label: "BEAUPOUYET (24400)", value: "24400" },
  { label: "BEAUREGARD DE TERRASSON (24120)", value: "24120" },
  { label: "BEAUREGARD ET BASSAC (24140)", value: "24140" },
  { label: "BEAURONNE (24400)", value: "24400" },
  { label: "BELEYMAS (24140)", value: "24140" },
  { label: "PAYS DE BELVES (24170)", value: "24170" },
  { label: "PAYS DE BELVES (24170)", value: "24170" },
  { label: "PAYS DE BELVES (24170)", value: "24170" },
  { label: "BERBIGUIERES (24220)", value: "24220" },
  { label: "BERGERAC (24100)", value: "24100" },
  { label: "BERTRIC BUREE (24320)", value: "24320" },
  { label: "BESSE (24550)", value: "24550" },
  { label: "BEYNAC ET CAZENAC (24220)", value: "24220" },
  { label: "BIRAS (24310)", value: "24310" },
  { label: "BIRON (24540)", value: "24540" },
  { label: "BOISSE (24560)", value: "24560" },
  { label: "BOISSEUILH (24390)", value: "24390" },
  { label: "BONNEVILLE ET ST AVIT DE FUMADIERES (24230)", value: "24230" },
  { label: "BORREZE (24590)", value: "24590" },
  { label: "BOSSET (24130)", value: "24130" },
  { label: "BOUILLAC (24480)", value: "24480" },
  { label: "BOULAZAC ISLE MANOIRE (24330)", value: "24330" },
  { label: "BOULAZAC ISLE MANOIRE (24330)", value: "24330" },
  { label: "BOULAZAC ISLE MANOIRE (24750)", value: "24750" },
  { label: "BOULAZAC ISLE MANOIRE (24750)", value: "24750" },
  { label: "BOUNIAGUES (24560)", value: "24560" },
  { label: "BOURDEILLES (24310)", value: "24310" },
  { label: "LE BOURDEIX (24300)", value: "24300" },
  { label: "BOURG DES MAISONS (24320)", value: "24320" },
  { label: "BOURG DU BOST (24600)", value: "24600" },
  { label: "BOURGNAC (24400)", value: "24400" },
  { label: "BOURNIQUEL (24150)", value: "24150" },
  { label: "BOURROU (24110)", value: "24110" },
  { label: "BOUTEILLES ST SEBASTIEN (24320)", value: "24320" },
  { label: "BOUZIC (24250)", value: "24250" },
  { label: "BRANTOME EN PERIGORD (24310)", value: "24310" },
  { label: "BRANTOME EN PERIGORD (24310)", value: "24310" },
  { label: "BRANTOME EN PERIGORD (24310)", value: "24310" },
  { label: "BRANTOME EN PERIGORD (24310)", value: "24310" },
  { label: "BRANTOME EN PERIGORD (24310)", value: "24310" },
  { label: "BRANTOME EN PERIGORD (24310)", value: "24310" },
  { label: "BRANTOME EN PERIGORD (24460)", value: "24460" },
  { label: "BRANTOME EN PERIGORD (24530)", value: "24530" },
  { label: "BROUCHAUD (24210)", value: "24210" },
  { label: "LE BUGUE (24260)", value: "24260" },
  { label: "LE BUISSON DE CADOUIN (24480)", value: "24480" },
  { label: "LE BUISSON DE CADOUIN (24480)", value: "24480" },
  { label: "LE BUISSON DE CADOUIN (24480)", value: "24480" },
  { label: "BUSSAC (24350)", value: "24350" },
  { label: "BUSSEROLLES (24360)", value: "24360" },
  { label: "BUSSIERE BADIL (24360)", value: "24360" },
  { label: "CALES (24150)", value: "24150" },
  { label: "CALVIAC EN PERIGORD (24370)", value: "24370" },
  { label: "CAMPAGNAC LES QUERCY (24550)", value: "24550" },
  { label: "CAMPAGNE (24260)", value: "24260" },
  { label: "CAMPSEGRET (24140)", value: "24140" },
  { label: "CAPDROT (24540)", value: "24540" },
  { label: "CARLUX (24370)", value: "24370" },
  { label: "CARSAC AILLAC (24200)", value: "24200" },
  { label: "CARSAC DE GURSON (24610)", value: "24610" },
  { label: "CARVES (24170)", value: "24170" },
  { label: "LA CASSAGNE (24120)", value: "24120" },
  { label: "CASTELNAUD LA CHAPELLE (24250)", value: "24250" },
  { label: "CASTELNAUD LA CHAPELLE (24250)", value: "24250" },
  { label: "CASTELS ET BEZENAC (24220)", value: "24220" },
  { label: "CASTELS ET BEZENAC (24220)", value: "24220" },
  { label: "CAUSE DE CLERANS (24150)", value: "24150" },
  { label: "CELLES (24600)", value: "24600" },
  { label: "CENAC ET ST JULIEN (24250)", value: "24250" },
  { label: "CHALAGNAC (24380)", value: "24380" },
  { label: "CHALAIS (24800)", value: "24800" },
  { label: "CHAMPAGNAC DE BELAIR (24530)", value: "24530" },
  { label: "CHAMPAGNE ET FONTAINE (24320)", value: "24320" },
  { label: "CHAMPCEVINEL (24750)", value: "24750" },
  { label: "CHAMPNIERS ET REILHAC (24360)", value: "24360" },
  { label: "CHAMPS ROMAIN (24470)", value: "24470" },
  { label: "CHANCELADE (24650)", value: "24650" },
  { label: "CHANTERAC (24190)", value: "24190" },
  { label: "CHAPDEUIL (24320)", value: "24320" },
  { label: "LA CHAPELLE AUBAREIL (24290)", value: "24290" },
  { label: "LA CHAPELLE FAUCHER (24530)", value: "24530" },
  { label: "LA CHAPELLE GONAGUET (24350)", value: "24350" },
  { label: "LA CHAPELLE GRESIGNAC (24320)", value: "24320" },
  { label: "LA CHAPELLE MONTABOURLET (24320)", value: "24320" },
  { label: "LA CHAPELLE MONTMOREAU (24300)", value: "24300" },
  { label: "LA CHAPELLE ST JEAN (24390)", value: "24390" },
  { label: "CHASSAIGNES (24600)", value: "24600" },
  { label: "CHATEAU L EVEQUE (24460)", value: "24460" },
  { label: "CHATRES (24120)", value: "24120" },
  { label: "LES COTEAUX PERIGOURDINS (24120)", value: "24120" },
  { label: "LES COTEAUX PERIGOURDINS (24120)", value: "24120" },
  { label: "CHERVAL (24320)", value: "24320" },
  { label: "CHERVEIX CUBAS (24390)", value: "24390" },
  { label: "CHOURGNAC (24640)", value: "24640" },
  { label: "CLADECH (24170)", value: "24170" },
  { label: "CLERMONT DE BEAUREGARD (24140)", value: "24140" },
  { label: "CLERMONT D EXCIDEUIL (24160)", value: "24160" },
  { label: "COLOMBIER (24560)", value: "24560" },
  { label: "COMBERANCHE ET EPELUCHE (24600)", value: "24600" },
  { label: "CONDAT SUR TRINCOU (24530)", value: "24530" },
  { label: "CONDAT SUR VEZERE (24570)", value: "24570" },
  { label: "CONNEZAC (24300)", value: "24300" },
  { label: "CONNE DE LABARDE (24560)", value: "24560" },
  { label: "LA COQUILLE (24450)", value: "24450" },
  { label: "CORGNAC SUR L ISLE (24800)", value: "24800" },
  { label: "CORNILLE (24750)", value: "24750" },
  { label: "COUBJOURS (24390)", value: "24390" },
  { label: "COULAURES (24420)", value: "24420" },
  { label: "COULOUNIEIX CHAMIERS (24660)", value: "24660" },
  { label: "COURSAC (24430)", value: "24430" },
  { label: "COURS DE PILE (24520)", value: "24520" },
  { label: "COUTURES (24320)", value: "24320" },
  { label: "COUX ET BIGAROQUE MOUZENS (24220)", value: "24220" },
  { label: "COUX ET BIGAROQUE MOUZENS (24220)", value: "24220" },
  { label: "COUZE ET ST FRONT (24150)", value: "24150" },
  { label: "CREYSSAC (24350)", value: "24350" },
  { label: "CREYSSE (24100)", value: "24100" },
  { label: "CREYSSENSAC ET PISSOT (24380)", value: "24380" },
  { label: "CUBJAC AUVEZERE VAL D ANS (24640)", value: "24640" },
  { label: "CUBJAC AUVEZERE VAL D ANS (24640)", value: "24640" },
  { label: "CUBJAC AUVEZERE VAL D ANS (24640)", value: "24640" },
  { label: "CUNEGES (24240)", value: "24240" },
  { label: "DAGLAN (24250)", value: "24250" },
  { label: "DOISSAT (24170)", value: "24170" },
  { label: "DOMME (24250)", value: "24250" },
  { label: "LA DORNAC (24120)", value: "24120" },
  { label: "DOUCHAPT (24350)", value: "24350" },
  { label: "DOUVILLE (24140)", value: "24140" },
  { label: "LA DOUZE (24330)", value: "24330" },
  { label: "DOUZILLAC (24190)", value: "24190" },
  { label: "DUSSAC (24270)", value: "24270" },
  { label: "ECHOURGNAC (24410)", value: "24410" },
  { label: "EGLISE NEUVE DE VERGT (24380)", value: "24380" },
  { label: "EGLISE NEUVE D ISSAC (24400)", value: "24400" },
  { label: "ESCOIRE (24420)", value: "24420" },
  { label: "ETOUARS (24360)", value: "24360" },
  { label: "EXCIDEUIL (24160)", value: "24160" },
  { label: "EYGURANDE ET GARDEDEUIL (24700)", value: "24700" },
  { label: "EYMET (24500)", value: "24500" },
  { label: "PLAISANCE (24560)", value: "24560" },
  { label: "PLAISANCE (24560)", value: "24560" },
  { label: "PLAISANCE (24560)", value: "24560" },
  { label: "EYZERAC (24800)", value: "24800" },
  { label: "LES EYZIES (24260)", value: "24260" },
  { label: "LES EYZIES (24620)", value: "24620" },
  { label: "LES EYZIES (24620)", value: "24620" },
  { label: "LES EYZIES (24620)", value: "24620" },
  { label: "FANLAC (24290)", value: "24290" },
  { label: "LES FARGES (24290)", value: "24290" },
  { label: "FAURILLES (24560)", value: "24560" },
  { label: "FAUX (24560)", value: "24560" },
  { label: "LA FEUILLADE (24120)", value: "24120" },
  { label: "FIRBEIX (24450)", value: "24450" },
  { label: "LE FLEIX (24130)", value: "24130" },
  { label: "FLEURAC (24580)", value: "24580" },
  { label: "FLORIMONT GAUMIER (24250)", value: "24250" },
  { label: "FONROQUE (24500)", value: "24500" },
  { label: "FOSSEMAGNE (24210)", value: "24210" },
  { label: "FOUGUEYROLLES (33220)", value: "33220" },
  { label: "FOULEIX (24380)", value: "24380" },
  { label: "FRAISSE (24130)", value: "24130" },
  { label: "GABILLOU (24210)", value: "24210" },
  { label: "GAGEAC ET ROUILLAC (24240)", value: "24240" },
  { label: "GARDONNE (24680)", value: "24680" },
  { label: "GAUGEAC (24540)", value: "24540" },
  { label: "GENIS (24160)", value: "24160" },
  { label: "GINESTET (24130)", value: "24130" },
  { label: "GOUT ROSSIGNOL (24320)", value: "24320" },
  { label: "GRAND BRASSAC (24350)", value: "24350" },
  { label: "GRANGES D ANS (24390)", value: "24390" },
  { label: "GRIGNOLS (24110)", value: "24110" },
  { label: "GRIVES (24170)", value: "24170" },
  { label: "GROLEJAC (24250)", value: "24250" },
  { label: "GRUN BORDAS (24380)", value: "24380" },
  { label: "HAUTEFAYE (24300)", value: "24300" },
  { label: "HAUTEFORT (24390)", value: "24390" },
  { label: "ISSAC (24400)", value: "24400" },
  { label: "ISSIGEAC (24560)", value: "24560" },
  { label: "JAURE (24140)", value: "24140" },
  { label: "JAVERLHAC ET LA CHAPELLE ST ROBERT (24300)", value: "24300" },
  { label: "JAYAC (24590)", value: "24590" },
  { label: "LA JEMAYE PONTEYRAUD (24410)", value: "24410" },
  { label: "LA JEMAYE PONTEYRAUD (24410)", value: "24410" },
  { label: "JOURNIAC (24260)", value: "24260" },
  { label: "JUMILHAC LE GRAND (24630)", value: "24630" },
  { label: "LACROPTE (24380)", value: "24380" },
  { label: "RUDEAU LADOSSE (24340)", value: "24340" },
  { label: "LA FORCE (24130)", value: "24130" },
  { label: "LALINDE (24150)", value: "24150" },
  { label: "LAMONZIE MONTASTRUC (24520)", value: "24520" },
  { label: "LAMONZIE ST MARTIN (24680)", value: "24680" },
  { label: "LAMOTHE MONTRAVEL (24230)", value: "24230" },
  { label: "LANOUAILLE (24270)", value: "24270" },
  { label: "LANQUAIS (24150)", value: "24150" },
  { label: "LE LARDIN ST LAZARE (24570)", value: "24570" },
  { label: "LARZAC (24170)", value: "24170" },
  { label: "LAVALADE (24540)", value: "24540" },
  { label: "LAVAUR (24550)", value: "24550" },
  { label: "LES LECHES (24400)", value: "24400" },
  { label: "LEGUILLAC DE L AUCHE (24110)", value: "24110" },
  { label: "LEMBRAS (24100)", value: "24100" },
  { label: "LEMPZOURS (24800)", value: "24800" },
  { label: "LIMEUIL (24510)", value: "24510" },
  { label: "LIMEYRAT (24210)", value: "24210" },
  { label: "LIORAC SUR LOUYRE (24520)", value: "24520" },
  { label: "LISLE (24350)", value: "24350" },
  { label: "LOLME (24540)", value: "24540" },
  { label: "LOUBEJAC (24550)", value: "24550" },
  { label: "LUNAS (24130)", value: "24130" },
  { label: "LUSIGNAC (24320)", value: "24320" },
  { label: "LUSSAS ET NONTRONNEAU (24300)", value: "24300" },
  { label: "MANZAC SUR VERN (24110)", value: "24110" },
  { label: "MARCILLAC ST QUENTIN (24200)", value: "24200" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MAREUIL EN PERIGORD (24340)", value: "24340" },
  { label: "MARNAC (24220)", value: "24220" },
  { label: "MARQUAY (24620)", value: "24620" },
  { label: "MARSAC SUR L ISLE (24430)", value: "24430" },
  { label: "MARSALES (24540)", value: "24540" },
  { label: "EYRAUD CREMPSE MAURENS (24130)", value: "24130" },
  { label: "EYRAUD CREMPSE MAURENS (24140)", value: "24140" },
  { label: "EYRAUD CREMPSE MAURENS (24140)", value: "24140" },
  { label: "EYRAUD CREMPSE MAURENS (24140)", value: "24140" },
  { label: "MAUZAC ET GRAND CASTANG (24150)", value: "24150" },
  { label: "MAUZAC ET GRAND CASTANG (24150)", value: "24150" },
  { label: "MAUZENS ET MIREMONT (24260)", value: "24260" },
  { label: "MAYAC (24420)", value: "24420" },
  { label: "MAZEYROLLES (24550)", value: "24550" },
  { label: "MENESPLET (24700)", value: "24700" },
  { label: "MENSIGNAC (24350)", value: "24350" },
  { label: "MESCOULES (24240)", value: "24240" },
  { label: "MEYRALS (24220)", value: "24220" },
  { label: "MIALET (24450)", value: "24450" },
  { label: "MILHAC DE NONTRON (24470)", value: "24470" },
  { label: "MINZAC (24610)", value: "24610" },
  { label: "MOLIERES (24480)", value: "24480" },
  { label: "MONBAZILLAC (24240)", value: "24240" },
  { label: "MONESTIER (24240)", value: "24240" },
  { label: "MONFAUCON (24130)", value: "24130" },
  { label: "MONMADALES (24560)", value: "24560" },
  { label: "MONMARVES (24560)", value: "24560" },
  { label: "MONPAZIER (24540)", value: "24540" },
  { label: "MONSAC (24440)", value: "24440" },
  { label: "MONSAGUEL (24560)", value: "24560" },
  { label: "MONTAGNAC D AUBEROCHE (24210)", value: "24210" },
  { label: "MONTAGNAC LA CREMPSE (24140)", value: "24140" },
  { label: "MONTAGRIER (24350)", value: "24350" },
  { label: "MONTAUT (24560)", value: "24560" },
  { label: "MONTAZEAU (24230)", value: "24230" },
  { label: "MONTCARET (24230)", value: "24230" },
  { label: "MONTFERRAND DU PERIGORD (24440)", value: "24440" },
  { label: "MONTIGNAC LASCAUX (24290)", value: "24290" },
  { label: "MONTPEYROUX (24610)", value: "24610" },
  { label: "MONPLAISANT (24170)", value: "24170" },
  { label: "MONTPON MENESTEROL (24700)", value: "24700" },
  { label: "MONTREM (24110)", value: "24110" },
  { label: "MOULEYDIER (24520)", value: "24520" },
  { label: "MOULIN NEUF (24700)", value: "24700" },
  { label: "MUSSIDAN (24400)", value: "24400" },
  { label: "NABIRAT (24250)", value: "24250" },
  { label: "NADAILLAC (24590)", value: "24590" },
  { label: "NAILHAC (24390)", value: "24390" },
  { label: "NANTEUIL AURIAC DE BOURZAC (24320)", value: "24320" },
  { label: "NANTEUIL AURIAC DE BOURZAC (24320)", value: "24320" },
  { label: "NANTHEUIL (24800)", value: "24800" },
  { label: "NANTHIAT (24800)", value: "24800" },
  { label: "NASTRINGUES (24230)", value: "24230" },
  { label: "NAUSSANNES (24440)", value: "24440" },
  { label: "NEGRONDES (24460)", value: "24460" },
  { label: "NEUVIC (24190)", value: "24190" },
  { label: "NONTRON (24300)", value: "24300" },
  { label: "SANILHAC (24380)", value: "24380" },
  { label: "SANILHAC (24660)", value: "24660" },
  { label: "SANILHAC (24750)", value: "24750" },
  { label: "ORLIAC (24170)", value: "24170" },
  { label: "PARCOUL CHENAUD (24410)", value: "24410" },
  { label: "PARCOUL CHENAUD (24410)", value: "24410" },
  { label: "PAULIN (24590)", value: "24590" },
  { label: "PAUNAT (24510)", value: "24510" },
  { label: "PAUSSAC ET ST VIVIEN (24310)", value: "24310" },
  { label: "PAYZAC (24270)", value: "24270" },
  { label: "PAZAYAC (24120)", value: "24120" },
  { label: "PERIGUEUX (24000)", value: "24000" },
  { label: "PETIT BERSAC (24600)", value: "24600" },
  { label: "PEYRIGNAC (24210)", value: "24210" },
  { label: "PECHS DE L ESPERANCE (24370)", value: "24370" },
  { label: "PECHS DE L ESPERANCE (24370)", value: "24370" },
  { label: "PECHS DE L ESPERANCE (24370)", value: "24370" },
  { label: "PEYZAC LE MOUSTIER (24620)", value: "24620" },
  { label: "PEZULS (24510)", value: "24510" },
  { label: "PIEGUT PLUVIERS (24360)", value: "24360" },
  { label: "LE PIZOU (24700)", value: "24700" },
  { label: "PLAZAC (24580)", value: "24580" },
  { label: "POMPORT (24240)", value: "24240" },
  { label: "PONTOURS (24150)", value: "24150" },
  { label: "PORT STE FOY ET PONCHAPT (33220)", value: "33220" },
  { label: "PRATS DE CARLUX (24370)", value: "24370" },
  { label: "PRATS DU PERIGORD (24550)", value: "24550" },
  { label: "PRESSIGNAC VICQ (24150)", value: "24150" },
  { label: "PREYSSAC D EXCIDEUIL (24160)", value: "24160" },
  { label: "PRIGONRIEUX (24130)", value: "24130" },
  { label: "PROISSANS (24200)", value: "24200" },
  { label: "QUEYSSAC (24140)", value: "24140" },
  { label: "QUINSAC (24530)", value: "24530" },
  { label: "RAMPIEUX (24440)", value: "24440" },
  { label: "RAZAC D EYMET (24500)", value: "24500" },
  { label: "RAZAC DE SAUSSIGNAC (24240)", value: "24240" },
  { label: "RAZAC SUR L ISLE (24430)", value: "24430" },
  { label: "RIBAGNAC (24240)", value: "24240" },
  { label: "RIBERAC (24600)", value: "24600" },
  { label: "LA ROCHEBEAUCOURT ET ARGENTINE (24340)", value: "24340" },
  { label: "LA ROCHE CHALAIS (24490)", value: "24490" },
  { label: "LA ROCHE CHALAIS (24490)", value: "24490" },
  { label: "LA ROCHE CHALAIS (24490)", value: "24490" },
  { label: "LA ROQUE GAGEAC (24250)", value: "24250" },
  { label: "ROUFFIGNAC ST CERNIN DE REILHAC (24580)", value: "24580" },
  { label: "ROUFFIGNAC ST CERNIN DE REILHAC (24580)", value: "24580" },
  { label: "ROUFFIGNAC DE SIGOULES (24240)", value: "24240" },
  { label: "SADILLAC (24500)", value: "24500" },
  { label: "SAGELAT (24170)", value: "24170" },
  { label: "ST AGNE (24520)", value: "24520" },
  { label: "VAL DE LOUYRE ET CAUDEAU (24380)", value: "24380" },
  { label: "VAL DE LOUYRE ET CAUDEAU (24510)", value: "24510" },
  { label: "VAL DE LOUYRE ET CAUDEAU (24510)", value: "24510" },
  { label: "COLY ST AMAND (24120)", value: "24120" },
  { label: "COLY ST AMAND (24290)", value: "24290" },
  { label: "ST AMAND DE VERGT (24380)", value: "24380" },
  { label: "ST ANDRE D ALLAS (24200)", value: "24200" },
  { label: "ST ANDRE DE DOUBLE (24190)", value: "24190" },
  { label: "ST ANTOINE DE BREUILH (24230)", value: "24230" },
  { label: "ST AQUILIN (24110)", value: "24110" },
  { label: "ST ASTIER (24110)", value: "24110" },
  { label: "ST AUBIN DE CADELECH (24500)", value: "24500" },
  { label: "ST AUBIN DE LANQUAIS (24560)", value: "24560" },
  { label: "ST AUBIN DE NABIRAT (24250)", value: "24250" },
  { label: "ST AULAYE PUYMANGOU (24410)", value: "24410" },
  { label: "ST AULAYE PUYMANGOU (24410)", value: "24410" },
  { label: "ST AVIT DE VIALARD (24260)", value: "24260" },
  { label: "ST AVIT RIVIERE (24540)", value: "24540" },
  { label: "ST AVIT SENIEUR (24440)", value: "24440" },
  { label: "ST BARTHELEMY DE BELLEGARDE (24700)", value: "24700" },
  { label: "ST BARTHELEMY DE BUSSIERE (24360)", value: "24360" },
  { label: "ST CAPRAISE DE LALINDE (24150)", value: "24150" },
  { label: "ST CAPRAISE D EYMET (24500)", value: "24500" },
  { label: "ST CASSIEN (24540)", value: "24540" },
  { label: "ST CERNIN DE LABARDE (24560)", value: "24560" },
  { label: "ST CERNIN DE L HERM (24550)", value: "24550" },
  { label: "ST CHAMASSY (24260)", value: "24260" },
  { label: "ST CREPIN D AUBEROCHE (24330)", value: "24330" },
  { label: "ST CREPIN ET CARLUCET (24590)", value: "24590" },
  { label: "STE CROIX (24440)", value: "24440" },
  { label: "STE CROIX DE MAREUIL (24340)", value: "24340" },
  { label: "ST CYBRANET (24250)", value: "24250" },
  { label: "ST CYPRIEN (24220)", value: "24220" },
  { label: "ST CYR LES CHAMPAGNES (24270)", value: "24270" },
  { label: "ST ESTEPHE (24360)", value: "24360" },
  { label: "ST ETIENNE DE PUYCORBIER (24400)", value: "24400" },
  { label: "STE EULALIE D ANS (24640)", value: "24640" },
  { label: "ST FELIX DE BOURDEILLES (24340)", value: "24340" },
  { label: "ST FELIX DE REILLAC ET MORTEMART (24260)", value: "24260" },
  { label: "ST FELIX DE VILLADEIX (24510)", value: "24510" },
  { label: "STE FOY DE BELVES (24170)", value: "24170" },
  { label: "STE FOY DE LONGAS (24510)", value: "24510" },
  { label: "ST FRONT D ALEMPS (24460)", value: "24460" },
  { label: "ST FRONT DE PRADOUX (24400)", value: "24400" },
  { label: "ST FRONT LA RIVIERE (24300)", value: "24300" },
  { label: "ST FRONT SUR NIZONNE (24300)", value: "24300" },
  { label: "ST GENIES (24590)", value: "24590" },
  { label: "ST GEORGES BLANCANEIX (24130)", value: "24130" },
  { label: "ST GEORGES DE MONTCLARD (24140)", value: "24140" },
  { label: "ST GERAUD DE CORPS (24700)", value: "24700" },
  { label: "ST GERMAIN DE BELVES (24170)", value: "24170" },
  { label: "ST GERMAIN DES PRES (24160)", value: "24160" },
  { label: "ST GERMAIN DU SALEMBRE (24190)", value: "24190" },
  { label: "ST GERMAIN ET MONS (24520)", value: "24520" },
  { label: "ST GERY (24400)", value: "24400" },
  { label: "ST GEYRAC (24330)", value: "24330" },
  { label: "ST HILAIRE D ESTISSAC (24140)", value: "24140" },
  { label: "ST JULIEN INNOCENCE EULALIE (24500)", value: "24500" },
  { label: "ST JULIEN INNOCENCE EULALIE (24500)", value: "24500" },
  { label: "ST JULIEN INNOCENCE EULALIE (24500)", value: "24500" },
  { label: "ST JEAN D ATAUX (24190)", value: "24190" },
  { label: "ST JEAN DE COLE (24800)", value: "24800" },
  { label: "ST JEAN D ESTISSAC (24140)", value: "24140" },
  { label: "ST JORY DE CHALAIS (24800)", value: "24800" },
  { label: "ST JORY LAS BLOUX (24160)", value: "24160" },
  { label: "ST JULIEN DE LAMPON (24370)", value: "24370" },
  { label: "ST JUST (24320)", value: "24320" },
  { label: "ST LAURENT DES HOMMES (24400)", value: "24400" },
  { label: "ST LAURENT DES VIGNES (24100)", value: "24100" },
  { label: "ST LAURENT LA VALLEE (24170)", value: "24170" },
  { label: "ST LEON D ISSIGEAC (24560)", value: "24560" },
  { label: "ST LEON SUR L ISLE (24110)", value: "24110" },
  { label: "ST LEON SUR VEZERE (24290)", value: "24290" },
  { label: "ST LOUIS EN L ISLE (24400)", value: "24400" },
  { label: "ST MARCEL DU PERIGORD (24510)", value: "24510" },
  { label: "ST MARCORY (24540)", value: "24540" },
  { label: "ST MARTIAL D ALBAREDE (24160)", value: "24160" },
  { label: "ST MARTIAL D ARTENSET (24700)", value: "24700" },
  { label: "ST MARTIAL DE NABIRAT (24250)", value: "24250" },
  { label: "ST MARTIAL DE VALETTE (24300)", value: "24300" },
  { label: "ST MARTIAL VIVEYROL (24320)", value: "24320" },
  { label: "ST MARTIN DE FRESSENGEAS (24800)", value: "24800" },
  { label: "ST MARTIN DE GURSON (24610)", value: "24610" },
  { label: "ST MARTIN DE RIBERAC (24600)", value: "24600" },
  { label: "ST MARTIN DES COMBES (24140)", value: "24140" },
  { label: "ST MARTIN L ASTIER (24400)", value: "24400" },
  { label: "ST MARTIN LE PIN (24300)", value: "24300" },
  { label: "ST MAYME DE PEREYROL (24380)", value: "24380" },
  { label: "ST MEARD DE DRONE (24600)", value: "24600" },
  { label: "ST MEARD DE GURCON (24610)", value: "24610" },
  { label: "ST MEDARD DE MUSSIDAN (24400)", value: "24400" },
  { label: "ST MEDARD D EXCIDEUIL (24160)", value: "24160" },
  { label: "ST MESMIN (24270)", value: "24270" },
  { label: "ST MICHEL DE DOUBLE (24400)", value: "24400" },
  { label: "ST MICHEL DE MONTAIGNE (24230)", value: "24230" },
  { label: "ST MICHEL DE VILLADEIX (24380)", value: "24380" },
  { label: "STE MONDANE (24370)", value: "24370" },
  { label: "STE NATHALENE (24200)", value: "24200" },
  { label: "ST NEXANS (24520)", value: "24520" },
  { label: "STE ORSE (24210)", value: "24210" },
  { label: "ST PANCRACE (24530)", value: "24530" },
  { label: "ST PANTALY D EXCIDEUIL (24160)", value: "24160" },
  { label: "ST PARDOUX DE DRONE (24600)", value: "24600" },
  { label: "ST PARDOUX ET VIELVIC (24170)", value: "24170" },
  { label: "ST PARDOUX LA RIVIERE (24470)", value: "24470" },
  { label: "ST PAUL DE SERRE (24380)", value: "24380" },
  { label: "ST PAUL LA ROCHE (24800)", value: "24800" },
  { label: "ST PAUL LIZONNE (24320)", value: "24320" },
  { label: "ST PERDOUX (24560)", value: "24560" },
  { label: "ST PIERRE DE CHIGNAC (24330)", value: "24330" },
  { label: "ST PIERRE DE COLE (24800)", value: "24800" },
  { label: "ST PIERRE DE FRUGIE (24450)", value: "24450" },
  { label: "ST PIERRE D EYRAUD (24130)", value: "24130" },
  { label: "ST POMPON (24170)", value: "24170" },
  { label: "ST PRIEST LES FOUGERES (24450)", value: "24450" },
  { label: "ST PRIVAT EN PERIGORD (24410)", value: "24410" },
  { label: "ST PRIVAT EN PERIGORD (24410)", value: "24410" },
  { label: "ST PRIVAT EN PERIGORD (24410)", value: "24410" },
  { label: "ST RABIER (24210)", value: "24210" },
  { label: "STE RADEGONDE (24560)", value: "24560" },
  { label: "ST RAPHAEL (24160)", value: "24160" },
  { label: "ST REMY (24700)", value: "24700" },
  { label: "ST ROMAIN DE MONPAZIER (24540)", value: "24540" },
  { label: "ST ROMAIN ET ST CLEMENT (24800)", value: "24800" },
  { label: "ST SAUD LACOUSSIERE (24470)", value: "24470" },
  { label: "ST SAUVEUR (24520)", value: "24520" },
  { label: "ST SAUVEUR LALANDE (24700)", value: "24700" },
  { label: "ST SEURIN DE PRATS (24230)", value: "24230" },
  { label: "ST SEVERIN D ESTISSAC (24190)", value: "24190" },
  { label: "ST SULPICE DE ROUMAGNAC (24600)", value: "24600" },
  { label: "ST SULPICE D EXCIDEUIL (24800)", value: "24800" },
  { label: "STE TRIE (24160)", value: "24160" },
  { label: "ST VICTOR (24350)", value: "24350" },
  { label: "ST VINCENT DE CONNEZAC (24190)", value: "24190" },
  { label: "ST VINCENT DE COSSE (24220)", value: "24220" },
  { label: "ST VINCENT JALMOUTIERS (24410)", value: "24410" },
  { label: "ST VINCENT LE PALUEL (24200)", value: "24200" },
  { label: "ST VINCENT SUR L ISLE (24420)", value: "24420" },
  { label: "ST VIVIEN (24230)", value: "24230" },
  { label: "SALAGNAC (24160)", value: "24160" },
  { label: "SALAGNAC (24160)", value: "24160" },
  { label: "SALIGNAC EYVIGUES (24590)", value: "24590" },
  { label: "SALLES DE BELVES (24170)", value: "24170" },
  { label: "SALON (24380)", value: "24380" },
  { label: "SARLANDE (24270)", value: "24270" },
  { label: "SARLAT LA CANEDA (24200)", value: "24200" },
  { label: "SARLIAC SUR L ISLE (24420)", value: "24420" },
  { label: "SARRAZAC (24800)", value: "24800" },
  { label: "SAUSSIGNAC (24240)", value: "24240" },
  { label: "SAVIGNAC DE MIREMONT (24260)", value: "24260" },
  { label: "SAVIGNAC DE NONTRON (24300)", value: "24300" },
  { label: "SAVIGNAC LEDRIER (24270)", value: "24270" },
  { label: "SAVIGNAC LES EGLISES (24420)", value: "24420" },
  { label: "SCEAU ST ANGEL (24300)", value: "24300" },
  { label: "SEGONZAC (24600)", value: "24600" },
  { label: "SERGEAC (24290)", value: "24290" },
  { label: "SERRES ET MONTGUYARD (24500)", value: "24500" },
  { label: "SERVANCHES (24410)", value: "24410" },
  { label: "SIGOULES ET FLAUGEAC (24240)", value: "24240" },
  { label: "SIGOULES ET FLAUGEAC (24240)", value: "24240" },
  { label: "SIMEYROLS (24370)", value: "24370" },
  { label: "SINGLEYRAC (24500)", value: "24500" },
  { label: "SIORAC DE RIBERAC (24600)", value: "24600" },
  { label: "SIORAC EN PERIGORD (24170)", value: "24170" },
  { label: "SORGES ET LIGUEUX EN PERIGORD (24420)", value: "24420" },
  { label: "SORGES ET LIGUEUX EN PERIGORD (24460)", value: "24460" },
  { label: "SOUDAT (24360)", value: "24360" },
  { label: "SOULAURES (24540)", value: "24540" },
  { label: "SOURZAC (24400)", value: "24400" },
  { label: "TAMNIES (24620)", value: "24620" },
  { label: "TEILLOTS (24390)", value: "24390" },
  { label: "TEMPLE LAGUYON (24390)", value: "24390" },
  { label: "TERRASSON LAVILLEDIEU (24120)", value: "24120" },
  { label: "TEYJAT (24300)", value: "24300" },
  { label: "THENAC (24240)", value: "24240" },
  { label: "THENAC (24240)", value: "24240" },
  { label: "THENAC (24240)", value: "24240" },
  { label: "THENON (24210)", value: "24210" },
  { label: "THIVIERS (24800)", value: "24800" },
  { label: "THONAC (24290)", value: "24290" },
  { label: "TOCANE ST APRE (24350)", value: "24350" },
  { label: "LA TOUR BLANCHE CERCLES (24320)", value: "24320" },
  { label: "LA TOUR BLANCHE CERCLES (24320)", value: "24320" },
  { label: "TOURTOIRAC (24390)", value: "24390" },
  { label: "TRELISSAC (24750)", value: "24750" },
  { label: "TREMOLAT (24510)", value: "24510" },
  { label: "TURSAC (24620)", value: "24620" },
  { label: "URVAL (24480)", value: "24480" },
  { label: "VALLEREUIL (24190)", value: "24190" },
  { label: "VALOJOULX (24290)", value: "24290" },
  { label: "VANXAINS (24600)", value: "24600" },
  { label: "VARAIGNES (24360)", value: "24360" },
  { label: "VARENNES (24150)", value: "24150" },
  { label: "VAUNAC (24800)", value: "24800" },
  { label: "VELINES (24230)", value: "24230" },
  { label: "VENDOIRE (24320)", value: "24320" },
  { label: "VERDON (24520)", value: "24520" },
  { label: "VERGT (24380)", value: "24380" },
  { label: "VERGT DE BIRON (24540)", value: "24540" },
  { label: "VERTEILLAC (24320)", value: "24320" },
  { label: "VEYRIGNAC (24370)", value: "24370" },
  { label: "VEYRINES DE DOMME (24250)", value: "24250" },
  { label: "VEYRINES DE VERGT (24380)", value: "24380" },
  { label: "VEZAC (24220)", value: "24220" },
  { label: "VILLAC (24120)", value: "24120" },
  { label: "VILLAMBLARD (24140)", value: "24140" },
  { label: "VILLARS (24530)", value: "24530" },
  { label: "VILLEFRANCHE DE LONCHAT (24610)", value: "24610" },
  { label: "VILLEFRANCHE DU PERIGORD (24550)", value: "24550" },
  { label: "VILLETOUREIX (24600)", value: "24600" },
  { label: "VITRAC (24200)", value: "24200" },
  { label: "ABBANS DESSOUS (25320)", value: "25320" },
  { label: "ABBANS DESSUS (25440)", value: "25440" },
  { label: "ABBENANS (25340)", value: "25340" },
  { label: "ABBEVILLERS (25310)", value: "25310" },
  { label: "ACCOLANS (25250)", value: "25250" },
  { label: "ADAM LES PASSAVANT (25360)", value: "25360" },
  { label: "ADAM LES VERCEL (25530)", value: "25530" },
  { label: "AIBRE (25750)", value: "25750" },
  { label: "AISSEY (25360)", value: "25360" },
  { label: "ALLENJOIE (25490)", value: "25490" },
  { label: "LES ALLIES (25300)", value: "25300" },
  { label: "ALLONDANS (25550)", value: "25550" },
  { label: "AMAGNEY (25220)", value: "25220" },
  { label: "AMANCEY (25330)", value: "25330" },
  { label: "AMATHAY VESIGNEUX (25330)", value: "25330" },
  { label: "AMONDANS (25330)", value: "25330" },
  { label: "ANTEUIL (25340)", value: "25340" },
  { label: "ANTEUIL (25340)", value: "25340" },
  { label: "ANTEUIL (25340)", value: "25340" },
  { label: "APPENANS (25250)", value: "25250" },
  { label: "ARBOUANS (25400)", value: "25400" },
  { label: "ARC ET SENANS (25610)", value: "25610" },
  { label: "ARCEY (25750)", value: "25750" },
  { label: "ARCON (25300)", value: "25300" },
  { label: "ARC SOUS CICON (25520)", value: "25520" },
  { label: "ARC SOUS MONTENOT (25270)", value: "25270" },
  { label: "AUBONNE (25520)", value: "25520" },
  { label: "AUDEUX (25170)", value: "25170" },
  { label: "AUDINCOURT (25400)", value: "25400" },
  { label: "AUTECHAUX (25110)", value: "25110" },
  { label: "AUTECHAUX ROIDE (25150)", value: "25150" },
  { label: "LES AUXONS (25870)", value: "25870" },
  { label: "LES AUXONS (25870)", value: "25870" },
  { label: "AVANNE AVENEY (25720)", value: "25720" },
  { label: "AVANNE AVENEY (25720)", value: "25720" },
  { label: "AVILLEY (25680)", value: "25680" },
  { label: "AVOUDREY (25690)", value: "25690" },
  { label: "BADEVEL (25490)", value: "25490" },
  { label: "BANNANS (25560)", value: "25560" },
  { label: "LE BARBOUX (25210)", value: "25210" },
  { label: "BART (25420)", value: "25420" },
  { label: "BARTHERANS (25440)", value: "25440" },
  { label: "BATTENANS LES MINES (25640)", value: "25640" },
  { label: "BATTENANS VARIN (25380)", value: "25380" },
  { label: "BAUME LES DAMES (25110)", value: "25110" },
  { label: "BAUME LES DAMES (25110)", value: "25110" },
  { label: "BAVANS (25550)", value: "25550" },
  { label: "BELFAYS (25470)", value: "25470" },
  { label: "LE BELIEU (25500)", value: "25500" },
  { label: "BELLEHERBE (25380)", value: "25380" },
  { label: "BELLEHERBE (25380)", value: "25380" },
  { label: "BELMONT (25530)", value: "25530" },
  { label: "BELVOIR (25430)", value: "25430" },
  { label: "BERCHE (25420)", value: "25420" },
  { label: "BERTHELANGE (25410)", value: "25410" },
  { label: "BESANCON (25000)", value: "25000" },
  { label: "BETHONCOURT (25200)", value: "25200" },
  { label: "BEURE (25720)", value: "25720" },
  { label: "BEUTAL (25250)", value: "25250" },
  { label: "VAL D USIERS (25520)", value: "25520" },
  { label: "VAL D USIERS (25520)", value: "25520" },
  { label: "VAL D USIERS (25520)", value: "25520" },
  { label: "BIEF (25190)", value: "25190" },
  { label: "LE BIZOT (25210)", value: "25210" },
  { label: "BLAMONT (25310)", value: "25310" },
  { label: "BLARIANS (25640)", value: "25640" },
  { label: "BLUSSANGEAUX (25250)", value: "25250" },
  { label: "BLUSSANS (25250)", value: "25250" },
  { label: "BOLANDOZ (25330)", value: "25330" },
  { label: "BONDEVAL (25230)", value: "25230" },
  { label: "BONNAL (25680)", value: "25680" },
  { label: "BONNAY (25870)", value: "25870" },
  { label: "BONNETAGE (25210)", value: "25210" },
  { label: "BONNEVAUX (25560)", value: "25560" },
  { label: "LA BOSSE (25210)", value: "25210" },
  { label: "BOUCLANS (25360)", value: "25360" },
  { label: "BOUCLANS (25360)", value: "25360" },
  { label: "BOUJAILLES (25560)", value: "25560" },
  { label: "BOURGUIGNON (25150)", value: "25150" },
  { label: "BOURNOIS (25250)", value: "25250" },
  { label: "BOUSSIERES (25320)", value: "25320" },
  { label: "BOUVERANS (25560)", value: "25560" },
  { label: "BRAILLANS (25640)", value: "25640" },
  { label: "BRANNE (25340)", value: "25340" },
  { label: "BRECONCHAUX (25640)", value: "25640" },
  { label: "BREMONDANS (25530)", value: "25530" },
  { label: "BRERES (25440)", value: "25440" },
  { label: "LES BRESEUX (25120)", value: "25120" },
  { label: "LA BRETENIERE (25640)", value: "25640" },
  { label: "BRETIGNEY (25250)", value: "25250" },
  { label: "BRETIGNEY NOTRE DAME (25110)", value: "25110" },
  { label: "BRETONVILLERS (25380)", value: "25380" },
  { label: "BREY ET MAISON DU BOIS (25240)", value: "25240" },
  { label: "BROGNARD (25600)", value: "25600" },
  { label: "BUFFARD (25440)", value: "25440" },
  { label: "BUGNY (25520)", value: "25520" },
  { label: "BULLE (25560)", value: "25560" },
  { label: "BURGILLE (25170)", value: "25170" },
  { label: "BURGILLE (25170)", value: "25170" },
  { label: "BURGILLE (25170)", value: "25170" },
  { label: "BURNEVILLERS (25470)", value: "25470" },
  { label: "BUSY (25320)", value: "25320" },
  { label: "BY (25440)", value: "25440" },
  { label: "BYANS SUR DOUBS (25320)", value: "25320" },
  { label: "CADEMENE (25290)", value: "25290" },
  { label: "CENDREY (25640)", value: "25640" },
  { label: "CERNAY L EGLISE (25120)", value: "25120" },
  { label: "CESSEY (25440)", value: "25440" },
  { label: "CHAFFOIS (25300)", value: "25300" },
  { label: "CHALEZE (25220)", value: "25220" },
  { label: "CHALEZEULE (25220)", value: "25220" },
  { label: "CHAMESEY (25380)", value: "25380" },
  { label: "CHAMESOL (25190)", value: "25190" },
  { label: "CHAMPAGNEY (25170)", value: "25170" },
  { label: "CHAMPLIVE (25360)", value: "25360" },
  { label: "CHAMPOUX (25640)", value: "25640" },
  { label: "CHAMPVANS LES MOULINS (25170)", value: "25170" },
  { label: "CHANTRANS (25330)", value: "25330" },
  { label: "CHAPELLE DES BOIS (25240)", value: "25240" },
  { label: "CHAPELLE D HUIN (25270)", value: "25270" },
  { label: "CHARMAUVILLERS (25470)", value: "25470" },
  { label: "CHARMOILLE (25380)", value: "25380" },
  { label: "CHARNAY (25440)", value: "25440" },
  { label: "CHARQUEMONT (25140)", value: "25140" },
  { label: "CHARQUEMONT (25140)", value: "25140" },
  { label: "CHASSAGNE ST DENIS (25290)", value: "25290" },
  { label: "CHATEAUVIEUX LES FOSSES (25840)", value: "25840" },
  { label: "CHATELBLANC (25240)", value: "25240" },
  { label: "CHATILLON GUYOTTE (25640)", value: "25640" },
  { label: "CHATILLON LE DUC (25870)", value: "25870" },
  { label: "CHAUCENNE (25170)", value: "25170" },
  { label: "LES TERRES DE CHAUX (25190)", value: "25190" },
  { label: "LA CHAUX (25650)", value: "25650" },
  { label: "CHAUX LES PASSAVANT (25530)", value: "25530" },
  { label: "CHAUX NEUVE (25240)", value: "25240" },
  { label: "CHAY (25440)", value: "25440" },
  { label: "CHAZOT (25430)", value: "25430" },
  { label: "CHEMAUDIN ET VAUX (25320)", value: "25320" },
  { label: "CHEMAUDIN ET VAUX (25770)", value: "25770" },
  { label: "LA CHENALOTTE (25500)", value: "25500" },
  { label: "CHENECEY BUILLON (25440)", value: "25440" },
  { label: "CHEVIGNEY SUR L OGNON (25170)", value: "25170" },
  { label: "CHEVIGNEY LES VERCEL (25530)", value: "25530" },
  { label: "LA CHEVILLOTTE (25620)", value: "25620" },
  { label: "CHEVROZ (25870)", value: "25870" },
  { label: "CHOUZELOT (25440)", value: "25440" },
  { label: "CLERON (25330)", value: "25330" },
  { label: "PAYS DE CLERVAL (25340)", value: "25340" },
  { label: "PAYS DE CLERVAL (25340)", value: "25340" },
  { label: "PAYS DE CLERVAL (25340)", value: "25340" },
  { label: "LA CLUSE ET MIJOUX (25300)", value: "25300" },
  { label: "COLOMBIER FONTAINE (25260)", value: "25260" },
  { label: "LES COMBES (25500)", value: "25500" },
  { label: "CONSOLATION MAISONNETTES (25390)", value: "25390" },
  { label: "CORCELLES FERRIERES (25410)", value: "25410" },
  { label: "CORCELLE MIESLOT (25640)", value: "25640" },
  { label: "CORCONDRAY (25410)", value: "25410" },
  { label: "COTEBRUNE (25360)", value: "25360" },
  { label: "COURCELLES LES MONTBELIARD (25420)", value: "25420" },
  { label: "COURCELLES (25440)", value: "25440" },
  { label: "COURCHAPON (25170)", value: "25170" },
  { label: "COUR ST MAURICE (25380)", value: "25380" },
  { label: "COURTEFONTAINE (25470)", value: "25470" },
  { label: "COURTETAIN ET SALANS (25530)", value: "25530" },
  { label: "COURVIERES (25560)", value: "25560" },
  { label: "CROSEY LE GRAND (25340)", value: "25340" },
  { label: "CROSEY LE PETIT (25340)", value: "25340" },
  { label: "LE CROUZET (25240)", value: "25240" },
  { label: "CROUZET MIGETTE (25270)", value: "25270" },
  { label: "CUBRIAL (25680)", value: "25680" },
  { label: "CUBRY (25680)", value: "25680" },
  { label: "CUSANCE (25110)", value: "25110" },
  { label: "CUSE ET ADRISANS (25680)", value: "25680" },
  { label: "CUSSEY SUR LISON (25440)", value: "25440" },
  { label: "CUSSEY SUR LISON (25440)", value: "25440" },
  { label: "CUSSEY SUR L OGNON (25870)", value: "25870" },
  { label: "DAMBELIN (25150)", value: "25150" },
  { label: "DAMBELIN (25150)", value: "25150" },
  { label: "DAMBENOIS (25600)", value: "25600" },
  { label: "DAMMARTIN LES TEMPLIERS (25110)", value: "25110" },
  { label: "DAMPIERRE LES BOIS (25490)", value: "25490" },
  { label: "DAMPIERRE SUR LE DOUBS (25420)", value: "25420" },
  { label: "DAMPJOUX (25190)", value: "25190" },
  { label: "DAMPRICHARD (25450)", value: "25450" },
  { label: "DANNEMARIE (25310)", value: "25310" },
  { label: "DANNEMARIE SUR CRETE (25410)", value: "25410" },
  { label: "DASLE (25230)", value: "25230" },
  { label: "DELUZ (25960)", value: "25960" },
  { label: "DESANDANS (25750)", value: "25750" },
  { label: "DESERVILLERS (25330)", value: "25330" },
  { label: "DEVECEY (25870)", value: "25870" },
  { label: "DOMMARTIN (25300)", value: "25300" },
  { label: "DOMPIERRE LES TILLEULS (25560)", value: "25560" },
  { label: "DOMPREL (25510)", value: "25510" },
  { label: "DOUBS (25300)", value: "25300" },
  { label: "DUNG (25550)", value: "25550" },
  { label: "DURNES (25580)", value: "25580" },
  { label: "ECHAY (25440)", value: "25440" },
  { label: "ECHENANS (25550)", value: "25550" },
  { label: "ECHEVANNES (25580)", value: "25580" },
  { label: "ECOLE VALENTIN (25480)", value: "25480" },
  { label: "ECOLE VALENTIN (25480)", value: "25480" },
  { label: "LES ECORCES (25140)", value: "25140" },
  { label: "ECOT (25150)", value: "25150" },
  { label: "L'ECOUVOTTE (25640)", value: "25640" },
  { label: "ECURCEY (25150)", value: "25150" },
  { label: "EMAGNY (25170)", value: "25170" },
  { label: "EPENOUSE (25530)", value: "25530" },
  { label: "EPENOY (25800)", value: "25800" },
  { label: "EPEUGNEY (25290)", value: "25290" },
  { label: "ESNANS (25110)", value: "25110" },
  { label: "ETALANS (25580)", value: "25580" },
  { label: "ETALANS (25580)", value: "25580" },
  { label: "ETALANS (25620)", value: "25620" },
  { label: "ETERNOZ (25330)", value: "25330" },
  { label: "ETERNOZ (25330)", value: "25330" },
  { label: "ETERNOZ (25330)", value: "25330" },
  { label: "ETERNOZ (25330)", value: "25330" },
  { label: "ETERNOZ (25330)", value: "25330" },
  { label: "ETOUVANS (25260)", value: "25260" },
  { label: "ETRABONNE (25170)", value: "25170" },
  { label: "ETRAPPE (25250)", value: "25250" },
  { label: "ETRAY (25800)", value: "25800" },
  { label: "ETUPES (25460)", value: "25460" },
  { label: "EVILLERS (25520)", value: "25520" },
  { label: "EXINCOURT (25400)", value: "25400" },
  { label: "EYSSON (25530)", value: "25530" },
  { label: "FAIMBE (25250)", value: "25250" },
  { label: "FALLERANS (25580)", value: "25580" },
  { label: "FERRIERES LE LAC (25470)", value: "25470" },
  { label: "FERRIERES LES BOIS (25410)", value: "25410" },
  { label: "FERTANS (25330)", value: "25330" },
  { label: "FESCHES LE CHATEL (25490)", value: "25490" },
  { label: "FESSEVILLERS (25470)", value: "25470" },
  { label: "FEULE (25190)", value: "25190" },
  { label: "LES FINS (25500)", value: "25500" },
  { label: "FLAGEY (25330)", value: "25330" },
  { label: "FLAGEY RIGNEY (25640)", value: "25640" },
  { label: "FLANGEBOUCHE (25390)", value: "25390" },
  { label: "FLEUREY (25190)", value: "25190" },
  { label: "FONTAIN (25660)", value: "25660" },
  { label: "FONTAIN (25720)", value: "25720" },
  { label: "FONTAINE LES CLERVAL (25340)", value: "25340" },
  { label: "FONTENELLE MONTBY (25340)", value: "25340" },
  { label: "LES FONTENELLES (25210)", value: "25210" },
  { label: "FONTENOTTE (25110)", value: "25110" },
  { label: "FOURBANNE (25110)", value: "25110" },
  { label: "FOURCATIER ET MAISON NEUVE (25370)", value: "25370" },
  { label: "FOURG (25440)", value: "25440" },
  { label: "LES FOURGS (25300)", value: "25300" },
  { label: "FOURNET BLANCHEROCHE (25140)", value: "25140" },
  { label: "FRAMBOUHANS (25140)", value: "25140" },
  { label: "FRANEY (25170)", value: "25170" },
  { label: "FRANOIS (25770)", value: "25770" },
  { label: "FRASNE (25560)", value: "25560" },
  { label: "FROIDEVAUX (25190)", value: "25190" },
  { label: "FUANS (25390)", value: "25390" },
  { label: "GELLIN (25240)", value: "25240" },
  { label: "GEMONVAL (25250)", value: "25250" },
  { label: "GENEUILLE (25870)", value: "25870" },
  { label: "GENEY (25250)", value: "25250" },
  { label: "GENNES (25660)", value: "25660" },
  { label: "GERMEFONTAINE (25510)", value: "25510" },
  { label: "GERMONDANS (25640)", value: "25640" },
  { label: "GEVRESIN (25270)", value: "25270" },
  { label: "GILLEY (25650)", value: "25650" },
  { label: "GLAMONDANS (25360)", value: "25360" },
  { label: "GLAY (25310)", value: "25310" },
  { label: "GLERE (25190)", value: "25190" },
  { label: "GLERE (25190)", value: "25190" },
  { label: "GLERE (25190)", value: "25190" },
  { label: "GONDENANS MONTBY (25340)", value: "25340" },
  { label: "GONDENANS LES MOULINS (25680)", value: "25680" },
  { label: "GONSANS (25360)", value: "25360" },
  { label: "GOUHELANS (25680)", value: "25680" },
  { label: "GOUMOIS (25470)", value: "25470" },
  { label: "GOUX LES DAMBELIN (25150)", value: "25150" },
  { label: "GOUX SOUS LANDET (25440)", value: "25440" },
  { label: "GRAND CHARMONT (25200)", value: "25200" },
  { label: "GRAND COMBE CHATELEU (25570)", value: "25570" },
  { label: "GRAND COMBE DES BOIS (25210)", value: "25210" },
  { label: "GRANDFONTAINE (25320)", value: "25320" },
  { label: "FOURNETS LUISANS (25390)", value: "25390" },
  { label: "FOURNETS LUISANS (25390)", value: "25390" },
  { label: "GRANDFONTAINE SUR CREUSE (25510)", value: "25510" },
  { label: "LA GRANGE (25380)", value: "25380" },
  { label: "GRANGES NARBOZ (25300)", value: "25300" },
  { label: "LES GRANGETTES (25160)", value: "25160" },
  { label: "LES GRAS (25790)", value: "25790" },
  { label: "LE GRATTERIS (25620)", value: "25620" },
  { label: "GROSBOIS (25110)", value: "25110" },
  { label: "GUILLON LES BAINS (25110)", value: "25110" },
  { label: "GUYANS DURNES (25580)", value: "25580" },
  { label: "GUYANS VENNES (25390)", value: "25390" },
  { label: "HAUTERIVE LA FRESSE (25650)", value: "25650" },
  { label: "HERIMONCOURT (25310)", value: "25310" },
  { label: "L'HOPITAL DU GROSBOIS (25620)", value: "25620" },
  { label: "L'HOPITAL ST LIEFFROY (25340)", value: "25340" },
  { label: "LES HOPITAUX NEUFS (25370)", value: "25370" },
  { label: "LES HOPITAUX VIEUX (25370)", value: "25370" },
  { label: "HOUTAUD (25300)", value: "25300" },
  { label: "HUANNE MONTMARTIN (25680)", value: "25680" },
  { label: "HYEMONDANS (25250)", value: "25250" },
  { label: "HYEVRE MAGNY (25110)", value: "25110" },
  { label: "HYEVRE PAROISSE (25110)", value: "25110" },
  { label: "HYEVRE PAROISSE (25110)", value: "25110" },
  { label: "INDEVILLERS (25470)", value: "25470" },
  { label: "L'ISLE SUR LE DOUBS (25250)", value: "25250" },
  { label: "ISSANS (25550)", value: "25550" },
  { label: "JALLERANGE (25170)", value: "25170" },
  { label: "JOUGNE (25370)", value: "25370" },
  { label: "LABERGEMENT STE MARIE (25160)", value: "25160" },
  { label: "LABERGEMENT STE MARIE (25160)", value: "25160" },
  { label: "VILLERS LE LAC (25130)", value: "25130" },
  { label: "LAIRE (25550)", value: "25550" },
  { label: "LAISSEY (25820)", value: "25820" },
  { label: "LANANS (25360)", value: "25360" },
  { label: "LANDRESSE (25530)", value: "25530" },
  { label: "LANTENNE VERTIERE (25170)", value: "25170" },
  { label: "LANTHENANS (25250)", value: "25250" },
  { label: "LARNOD (25720)", value: "25720" },
  { label: "LAVAL LE PRIEURE (25210)", value: "25210" },
  { label: "LAVANS QUINGEY (25440)", value: "25440" },
  { label: "LAVANS VUILLAFANS (25580)", value: "25580" },
  { label: "LAVERNAY (25170)", value: "25170" },
  { label: "LAVIRON (25510)", value: "25510" },
  { label: "LEVIER (25270)", value: "25270" },
  { label: "LEVIER (25270)", value: "25270" },
  { label: "LEVIER (25270)", value: "25270" },
  { label: "LIEBVILLERS (25190)", value: "25190" },
  { label: "LIESLE (25440)", value: "25440" },
  { label: "LIZINE (25330)", value: "25330" },
  { label: "LODS (25930)", value: "25930" },
  { label: "LOMBARD (25440)", value: "25440" },
  { label: "LOMONT SUR CRETE (25110)", value: "25110" },
  { label: "LONGECHAUX (25690)", value: "25690" },
  { label: "LONGEMAISON (25690)", value: "25690" },
  { label: "LONGEVELLE LES RUSSEY (25380)", value: "25380" },
  { label: "LONGEVELLE SUR DOUBS (25260)", value: "25260" },
  { label: "LONGEVILLE (25330)", value: "25330" },
  { label: "LA LONGEVILLE (25650)", value: "25650" },
  { label: "LONGEVILLES MONT D OR (25370)", value: "25370" },
  { label: "LORAY (25390)", value: "25390" },
  { label: "LOUGRES (25260)", value: "25260" },
  { label: "LE LUHIER (25210)", value: "25210" },
  { label: "LUXIOL (25110)", value: "25110" },
  { label: "MAGNY CHATELARD (25360)", value: "25360" },
  { label: "MAICHE (25120)", value: "25120" },
  { label: "MAISONS DU BOIS LIEVREMONT (25650)", value: "25650" },
  { label: "MAISONS DU BOIS LIEVREMONT (25650)", value: "25650" },
  { label: "MALANS (25330)", value: "25330" },
  { label: "MALBRANS (25620)", value: "25620" },
  { label: "MALBUISSON (25160)", value: "25160" },
  { label: "MALPAS (25160)", value: "25160" },
  { label: "MAMIROLLE (25620)", value: "25620" },
  { label: "MANCENANS (25250)", value: "25250" },
  { label: "MANCENANS LIZERNE (25120)", value: "25120" },
  { label: "MANDEURE (25350)", value: "25350" },
  { label: "MARCHAUX CHAUDEFONTAINE (25640)", value: "25640" },
  { label: "MARCHAUX CHAUDEFONTAINE (25640)", value: "25640" },
  { label: "MARVELISE (25250)", value: "25250" },
  { label: "MATHAY (25700)", value: "25700" },
  { label: "MAZEROLLES LE SALIN (25170)", value: "25170" },
  { label: "MEDIERE (25250)", value: "25250" },
  { label: "LE MEMONT (25210)", value: "25210" },
  { label: "MERCEY LE GRAND (25410)", value: "25410" },
  { label: "MERCEY LE GRAND (25410)", value: "25410" },
  { label: "LES MONTS RONDS (25620)", value: "25620" },
  { label: "LES MONTS RONDS (25660)", value: "25660" },
  { label: "MEREY VIEILLEY (25870)", value: "25870" },
  { label: "MESANDANS (25680)", value: "25680" },
  { label: "MESLIERES (25310)", value: "25310" },
  { label: "MESMAY (25440)", value: "25440" },
  { label: "METABIEF (25370)", value: "25370" },
  { label: "MISEREY SALINES (25480)", value: "25480" },
  { label: "MONCEY (25870)", value: "25870" },
  { label: "MONCLEY (25170)", value: "25170" },
  { label: "MONDON (25680)", value: "25680" },
  { label: "MONTAGNEY SERVIGNEY (25680)", value: "25680" },
  { label: "MONTAGNEY SERVIGNEY (25680)", value: "25680" },
  { label: "MONTANCY (25190)", value: "25190" },
  { label: "MONTANDON (25190)", value: "25190" },
  { label: "MONTBELIARD (25200)", value: "25200" },
  { label: "MONTBELIARDOT (25210)", value: "25210" },
  { label: "MONTBENOIT (25650)", value: "25650" },
  { label: "MONT DE LAVAL (25210)", value: "25210" },
  { label: "MONT DE VOUGNEY (25120)", value: "25120" },
  { label: "MONT DE VOUGNEY (25120)", value: "25120" },
  { label: "MONTECHEROUX (25190)", value: "25190" },
  { label: "MONTENOIS (25260)", value: "25260" },
  { label: "MONTFAUCON (25660)", value: "25660" },
  { label: "MONTFERRAND LE CHATEAU (25320)", value: "25320" },
  { label: "MONTFLOVIN (25650)", value: "25650" },
  { label: "MONTGESOYE (25111)", value: "25111" },
  { label: "MONTIVERNAGE (25110)", value: "25110" },
  { label: "MONTJOIE LE CHATEAU (25190)", value: "25190" },
  { label: "MONTLEBON (25500)", value: "25500" },
  { label: "MONTMAHOUX (25270)", value: "25270" },
  { label: "MONTPERREUX (25160)", value: "25160" },
  { label: "MONTROND LE CHATEAU (25660)", value: "25660" },
  { label: "MONTUSSAINT (25680)", value: "25680" },
  { label: "MORRE (25660)", value: "25660" },
  { label: "MORTEAU (25500)", value: "25500" },
  { label: "MOUTHE (25240)", value: "25240" },
  { label: "LE MOUTHEROT (25170)", value: "25170" },
  { label: "MOUTHIER HAUTE PIERRE (25920)", value: "25920" },
  { label: "MYON (25440)", value: "25440" },
  { label: "NAISEY LES GRANGES (25360)", value: "25360" },
  { label: "NAISEY LES GRANGES (25360)", value: "25360" },
  { label: "NANCRAY (25360)", value: "25360" },
  { label: "NANS (25680)", value: "25680" },
  { label: "NANS SOUS STE ANNE (25330)", value: "25330" },
  { label: "NARBIEF (25210)", value: "25210" },
  { label: "NEUCHATEL URTIERE (25150)", value: "25150" },
  { label: "LES PREMIERS SAPINS (25580)", value: "25580" },
  { label: "LES PREMIERS SAPINS (25580)", value: "25580" },
  { label: "LES PREMIERS SAPINS (25580)", value: "25580" },
  { label: "LES PREMIERS SAPINS (25580)", value: "25580" },
  { label: "LES PREMIERS SAPINS (25580)", value: "25580" },
  { label: "LES PREMIERS SAPINS (25580)", value: "25580" },
  { label: "NOEL CERNEUX (25500)", value: "25500" },
  { label: "NOIREFONTAINE (25190)", value: "25190" },
  { label: "NOIRONTE (25170)", value: "25170" },
  { label: "NOMMAY (25600)", value: "25600" },
  { label: "NOVILLARS (25220)", value: "25220" },
  { label: "OLLANS (25640)", value: "25640" },
  { label: "ONANS (25250)", value: "25250" },
  { label: "ORCHAMPS VENNES (25390)", value: "25390" },
  { label: "ORGEANS BLANCHEFONTAINE (25120)", value: "25120" },
  { label: "ORGEANS BLANCHEFONTAINE (25120)", value: "25120" },
  { label: "ORNANS (25290)", value: "25290" },
  { label: "ORNANS (25620)", value: "25620" },
  { label: "ORSANS (25530)", value: "25530" },
  { label: "ORVE (25430)", value: "25430" },
  { label: "OSSE (25360)", value: "25360" },
  { label: "OSSELLE ROUTELLE (25320)", value: "25320" },
  { label: "OSSELLE ROUTELLE (25410)", value: "25410" },
  { label: "OUGNEY DOUVOT (25640)", value: "25640" },
  { label: "OUHANS (25520)", value: "25520" },
  { label: "OUVANS (25530)", value: "25530" },
  { label: "OYE ET PALLET (25160)", value: "25160" },
  { label: "PALANTINE (25440)", value: "25440" },
  { label: "PALISE (25870)", value: "25870" },
  { label: "PAROY (25440)", value: "25440" },
  { label: "PASSAVANT (25360)", value: "25360" },
  { label: "PASSONFONTAINE (25690)", value: "25690" },
  { label: "PELOUSEY (25170)", value: "25170" },
  { label: "PESEUX (25190)", value: "25190" },
  { label: "PESSANS (25440)", value: "25440" },
  { label: "PETITE CHAUX (25240)", value: "25240" },
  { label: "PIERREFONTAINE LES BLAMONT (25310)", value: "25310" },
  { label: "PIERREFONTAINE LES VARANS (25510)", value: "25510" },
  { label: "PIREY (25480)", value: "25480" },
  { label: "PLACEY (25170)", value: "25170" },
  { label: "PLAIMBOIS DU MIROIR (25210)", value: "25210" },
  { label: "PLAIMBOIS VENNES (25390)", value: "25390" },
  { label: "LES PLAINS ET GRANDS ESSARTS (25470)", value: "25470" },
  { label: "LA PLANEE (25160)", value: "25160" },
  { label: "LE VAL (25440)", value: "25440" },
  { label: "LE VAL (25440)", value: "25440" },
  { label: "POMPIERRE SUR DOUBS (25340)", value: "25340" },
  { label: "PONTARLIER (25300)", value: "25300" },
  { label: "PONT DE ROIDE VERMONDANS (25150)", value: "25150" },
  { label: "PONT DE ROIDE VERMONDANS (25150)", value: "25150" },
  { label: "LES PONTETS (25240)", value: "25240" },
  { label: "PONT LES MOULINS (25110)", value: "25110" },
  { label: "POUILLEY FRANCAIS (25410)", value: "25410" },
  { label: "POUILLEY LES VIGNES (25115)", value: "25115" },
  { label: "POULIGNEY LUSANS (25640)", value: "25640" },
  { label: "POULIGNEY LUSANS (25640)", value: "25640" },
  { label: "PRESENTEVILLERS (25550)", value: "25550" },
  { label: "LA PRETIERE (25250)", value: "25250" },
  { label: "PROVENCHERE (25380)", value: "25380" },
  { label: "PUESSANS (25680)", value: "25680" },
  { label: "PUGEY (25720)", value: "25720" },
  { label: "LE PUY (25640)", value: "25640" },
  { label: "QUINGEY (25440)", value: "25440" },
  { label: "RAHON (25430)", value: "25430" },
  { label: "RANCENAY (25320)", value: "25320" },
  { label: "RANDEVILLERS (25430)", value: "25430" },
  { label: "RANG (25250)", value: "25250" },
  { label: "RAYNANS (25550)", value: "25550" },
  { label: "RECOLOGNE (25170)", value: "25170" },
  { label: "RECULFOZ (25240)", value: "25240" },
  { label: "REMONDANS VAIVRE (25150)", value: "25150" },
  { label: "REMONDANS VAIVRE (25150)", value: "25150" },
  { label: "REMORAY BOUJEONS (25160)", value: "25160" },
  { label: "REMORAY BOUJEONS (25160)", value: "25160" },
  { label: "RENEDALE (25520)", value: "25520" },
  { label: "RENNES SUR LOUE (25440)", value: "25440" },
  { label: "REUGNEY (25330)", value: "25330" },
  { label: "RIGNEY (25640)", value: "25640" },
  { label: "RIGNOSOT (25640)", value: "25640" },
  { label: "RILLANS (25110)", value: "25110" },
  { label: "LA RIVIERE DRUGEON (25560)", value: "25560" },
  { label: "ROCHEJEAN (25370)", value: "25370" },
  { label: "ROCHE LEZ BEAUPRE (25220)", value: "25220" },
  { label: "ROCHE LES CLERVAL (25340)", value: "25340" },
  { label: "ROCHES LES BLAMONT (25310)", value: "25310" },
  { label: "ROGNON (25680)", value: "25680" },
  { label: "ROMAIN (25680)", value: "25680" },
  { label: "RONCHAUX (25440)", value: "25440" },
  { label: "RONDEFONTAINE (25240)", value: "25240" },
  { label: "ROSET FLUANS (25410)", value: "25410" },
  { label: "ROSIERES SUR BARBECHE (25190)", value: "25190" },
  { label: "ROSUREUX (25380)", value: "25380" },
  { label: "ROUGEMONT (25680)", value: "25680" },
  { label: "ROUGEMONT (25680)", value: "25680" },
  { label: "ROUGEMONT (25680)", value: "25680" },
  { label: "ROUGEMONT (25680)", value: "25680" },
  { label: "ROUGEMONTOT (25640)", value: "25640" },
  { label: "ROUHE (25440)", value: "25440" },
  { label: "ROULANS (25640)", value: "25640" },
  { label: "RUFFEY LE CHATEAU (25170)", value: "25170" },
  { label: "RUREY (25290)", value: "25290" },
  { label: "LE RUSSEY (25210)", value: "25210" },
  { label: "STE ANNE (25270)", value: "25270" },
  { label: "ST ANTOINE (25370)", value: "25370" },
  { label: "STE COLOMBE (25300)", value: "25300" },
  { label: "ST GEORGES ARMONT (25340)", value: "25340" },
  { label: "ST GORGON MAIN (25520)", value: "25520" },
  { label: "ST HILAIRE (25640)", value: "25640" },
  { label: "ST HIPPOLYTE (25190)", value: "25190" },
  { label: "ST HIPPOLYTE (25190)", value: "25190" },
  { label: "ST JUAN (25360)", value: "25360" },
  { label: "ST JULIEN LES MONTBELIARD (25550)", value: "25550" },
  { label: "ST JULIEN LES RUSSEY (25210)", value: "25210" },
  { label: "STE MARIE (25113)", value: "25113" },
  { label: "ST MAURICE COLOMBIER (25260)", value: "25260" },
  { label: "ST MAURICE COLOMBIER (25260)", value: "25260" },
  { label: "ST POINT LAC (25160)", value: "25160" },
  { label: "STE SUZANNE (25630)", value: "25630" },
  { label: "ST VIT (25410)", value: "25410" },
  { label: "ST VIT (25410)", value: "25410" },
  { label: "SAMSON (25440)", value: "25440" },
  { label: "SANCEY (25430)", value: "25430" },
  { label: "SANCEY (25430)", value: "25430" },
  { label: "SAONE (25660)", value: "25660" },
  { label: "SARAZ (25330)", value: "25330" },
  { label: "SARRAGEOIS (25240)", value: "25240" },
  { label: "SAULES (25580)", value: "25580" },
  { label: "SAUVAGNEY (25170)", value: "25170" },
  { label: "SCEY MAISIERES (25290)", value: "25290" },
  { label: "SCEY MAISIERES (25290)", value: "25290" },
  { label: "SECHIN (25110)", value: "25110" },
  { label: "SELONCOURT (25230)", value: "25230" },
  { label: "SEMONDANS (25750)", value: "25750" },
  { label: "SEPTFONTAINES (25270)", value: "25270" },
  { label: "SERRE LES SAPINS (25770)", value: "25770" },
  { label: "SERVIN (25430)", value: "25430" },
  { label: "SILLEY AMANCEY (25330)", value: "25330" },
  { label: "SILLEY BLEFOND (25110)", value: "25110" },
  { label: "SOCHAUX (25600)", value: "25600" },
  { label: "SOLEMONT (25190)", value: "25190" },
  { label: "LA SOMMETTE (25510)", value: "25510" },
  { label: "SOULCE CERNAY (25190)", value: "25190" },
  { label: "SOURANS (25250)", value: "25250" },
  { label: "SOYE (25250)", value: "25250" },
  { label: "SURMONT (25380)", value: "25380" },
  { label: "TAILLECOURT (25400)", value: "25400" },
  { label: "TALLANS (25680)", value: "25680" },
  { label: "TALLENAY (25870)", value: "25870" },
  { label: "TARCENAY FOUCHERANS (25620)", value: "25620" },
  { label: "TARCENAY FOUCHERANS (25620)", value: "25620" },
  { label: "THIEBOUHANS (25470)", value: "25470" },
  { label: "THISE (25220)", value: "25220" },
  { label: "THORAISE (25320)", value: "25320" },
  { label: "THULAY (25310)", value: "25310" },
  { label: "THUREY LE MONT (25870)", value: "25870" },
  { label: "TORPES (25320)", value: "25320" },
  { label: "TOUILLON ET LOUTELET (25370)", value: "25370" },
  { label: "LA TOUR DE SCAY (25640)", value: "25640" },
  { label: "TOURNANS (25680)", value: "25680" },
  { label: "TREPOT (25620)", value: "25620" },
  { label: "TRESSANDANS (25680)", value: "25680" },
  { label: "TREVILLERS (25470)", value: "25470" },
  { label: "TROUVANS (25680)", value: "25680" },
  { label: "URTIERE (25470)", value: "25470" },
  { label: "UZELLE (25340)", value: "25340" },
  { label: "VAIRE (25220)", value: "25220" },
  { label: "VAIRE (25220)", value: "25220" },
  { label: "VAIRE (25220)", value: "25220" },
  { label: "VALDAHON (25800)", value: "25800" },
  { label: "VAL DE ROULANS (25640)", value: "25640" },
  { label: "VALENTIGNEY (25700)", value: "25700" },
  { label: "VALLEROY (25870)", value: "25870" },
  { label: "VALONNE (25190)", value: "25190" },
  { label: "VALOREILLE (25190)", value: "25190" },
  { label: "VANDONCOURT (25230)", value: "25230" },
  { label: "VAUCLUSE (25380)", value: "25380" },
  { label: "VAUCLUSOTTE (25380)", value: "25380" },
  { label: "VAUDRIVILLERS (25360)", value: "25360" },
  { label: "VAUFREY (25190)", value: "25190" },
  { label: "VAUX ET CHANTEGRUE (25160)", value: "25160" },
  { label: "VELESMES ESSARTS (25410)", value: "25410" },
  { label: "VELLEROT LES BELVOIR (25430)", value: "25430" },
  { label: "VELLEROT LES VERCEL (25530)", value: "25530" },
  { label: "VELLEVANS (25430)", value: "25430" },
  { label: "VENISE (25870)", value: "25870" },
  { label: "VENNANS (25640)", value: "25640" },
  { label: "VENNES (25390)", value: "25390" },
  { label: "VERCEL VILLEDIEU LE CAMP (25530)", value: "25530" },
  { label: "VERGRANNE (25110)", value: "25110" },
  { label: "VERNE (25110)", value: "25110" },
  { label: "VERNIERFONTAINE (25580)", value: "25580" },
  { label: "VERNOIS LES BELVOIR (25430)", value: "25430" },
  { label: "LE VERNOY (25750)", value: "25750" },
  { label: "VERRIERES DE JOUX (25300)", value: "25300" },
  { label: "LA VEZE (25660)", value: "25660" },
  { label: "VIEILLEY (25870)", value: "25870" },
  { label: "VIETHOREY (25340)", value: "25340" },
  { label: "VIEUX CHARMONT (25600)", value: "25600" },
  { label: "VILLARS LES BLAMONT (25310)", value: "25310" },
  { label: "VILLARS ST GEORGES (25410)", value: "25410" },
  { label: "VILLARS SOUS DAMPJOUX (25190)", value: "25190" },
  { label: "VILLARS SOUS ECOT (25150)", value: "25150" },
  { label: "LES VILLEDIEU (25240)", value: "25240" },
  { label: "VILLE DU PONT (25650)", value: "25650" },
  { label: "VILLENEUVE D AMONT (25270)", value: "25270" },
  { label: "VILLERS BUZON (25170)", value: "25170" },
  { label: "VILLERS CHIEF (25530)", value: "25530" },
  { label: "VILLERS GRELOT (25640)", value: "25640" },
  { label: "VILLERS LA COMBE (25510)", value: "25510" },
  { label: "VILLERS ST MARTIN (25110)", value: "25110" },
  { label: "VILLERS SOUS CHALAMONT (25270)", value: "25270" },
  { label: "VOILLANS (25110)", value: "25110" },
  { label: "VOIRES (25580)", value: "25580" },
  { label: "VORGES LES PINS (25320)", value: "25320" },
  { label: "VOUJEAUCOURT (25420)", value: "25420" },
  { label: "VUILLAFANS (25840)", value: "25840" },
  { label: "VUILLECIN (25300)", value: "25300" },
  { label: "VYT LES BELVOIR (25430)", value: "25430" },
  { label: "SOLAURE EN DIOIS (26150)", value: "26150" },
  { label: "SOLAURE EN DIOIS (26150)", value: "26150" },
  { label: "ALBON (26140)", value: "26140" },
  { label: "ALEYRAC (26770)", value: "26770" },
  { label: "ALIXAN (26300)", value: "26300" },
  { label: "ALLAN (26780)", value: "26780" },
  { label: "ALLEX (26400)", value: "26400" },
  { label: "AMBONIL (26800)", value: "26800" },
  { label: "ANCONE (26200)", value: "26200" },
  { label: "ANDANCETTE (26140)", value: "26140" },
  { label: "ANDANCETTE (26140)", value: "26140" },
  { label: "ANNEYRON (26140)", value: "26140" },
  { label: "AOUSTE SUR SYE (26400)", value: "26400" },
  { label: "ARNAYON (26470)", value: "26470" },
  { label: "ARPAVON (26110)", value: "26110" },
  { label: "ARTHEMONAY (26260)", value: "26260" },
  { label: "AUBENASSON (26340)", value: "26340" },
  { label: "AUBRES (26110)", value: "26110" },
  { label: "AUCELON (26340)", value: "26340" },
  { label: "AULAN (26570)", value: "26570" },
  { label: "AUREL (26340)", value: "26340" },
  { label: "LA REPARA AURIPLES (26400)", value: "26400" },
  { label: "AUTICHAMP (26400)", value: "26400" },
  { label: "BALLONS (26560)", value: "26560" },
  { label: "BARBIERES (26300)", value: "26300" },
  { label: "BARCELONNE (26120)", value: "26120" },
  { label: "BARNAVE (26310)", value: "26310" },
  { label: "BARRET DE LIOURE (26570)", value: "26570" },
  { label: "BARSAC (26150)", value: "26150" },
  { label: "BATHERNAY (26260)", value: "26260" },
  { label: "LA BATIE DES FONDS (26310)", value: "26310" },
  { label: "LA BATIE ROLLAND (26160)", value: "26160" },
  { label: "LA BAUME CORNILLANE (26120)", value: "26120" },
  { label: "LA BAUME DE TRANSIT (26790)", value: "26790" },
  { label: "LA BAUME D HOSTUN (26730)", value: "26730" },
  { label: "BEAUFORT SUR GERVANNE (26400)", value: "26400" },
  { label: "BEAUMONT EN DIOIS (26310)", value: "26310" },
  { label: "BEAUMONT LES VALENCE (26760)", value: "26760" },
  { label: "BEAUMONT MONTEUX (26600)", value: "26600" },
  { label: "BEAUREGARD BARET (26300)", value: "26300" },
  { label: "BEAURIERES (26310)", value: "26310" },
  { label: "BEAUSEMBLANT (26240)", value: "26240" },
  { label: "BEAUVALLON (26800)", value: "26800" },
  { label: "BEAUVOISIN (26170)", value: "26170" },
  { label: "LA BEGUDE DE MAZENC (26160)", value: "26160" },
  { label: "BELLECOMBE TARENDOL (26110)", value: "26110" },
  { label: "BELLEGARDE EN DIOIS (26470)", value: "26470" },
  { label: "BENIVAY OLLON (26170)", value: "26170" },
  { label: "BESAYES (26300)", value: "26300" },
  { label: "BESIGNAN (26110)", value: "26110" },
  { label: "BEZAUDUN SUR BINE (26460)", value: "26460" },
  { label: "BONLIEU SUR ROUBION (26160)", value: "26160" },
  { label: "BOUCHET (26790)", value: "26790" },
  { label: "BOULC (26410)", value: "26410" },
  { label: "BOULC (26410)", value: "26410" },
  { label: "BOULC (26410)", value: "26410" },
  { label: "BOURDEAUX (26460)", value: "26460" },
  { label: "BOURG DE PEAGE (26300)", value: "26300" },
  { label: "BOURG LES VALENCE (26500)", value: "26500" },
  { label: "BOUVANTE (26190)", value: "26190" },
  { label: "BOUVIERES (26460)", value: "26460" },
  { label: "BREN (26260)", value: "26260" },
  { label: "BRETTE (26340)", value: "26340" },
  { label: "BUIS LES BARONNIES (26170)", value: "26170" },
  { label: "CHABEUIL (26120)", value: "26120" },
  { label: "CHABEUIL (26120)", value: "26120" },
  { label: "CHABRILLAN (26400)", value: "26400" },
  { label: "LE CHAFFAL (26190)", value: "26190" },
  { label: "LE CHAFFAL (26190)", value: "26190" },
  { label: "CHALANCON (26470)", value: "26470" },
  { label: "LE CHALON (26350)", value: "26350" },
  { label: "CHAMALOC (26150)", value: "26150" },
  { label: "CHAMARET (26230)", value: "26230" },
  { label: "CHANOS CURSON (26600)", value: "26600" },
  { label: "CHANTEMERLE LES BLES (26600)", value: "26600" },
  { label: "CHANTEMERLE LES GRIGNAN (26230)", value: "26230" },
  { label: "LA CHAPELLE EN VERCORS (26420)", value: "26420" },
  { label: "LA CHAPELLE EN VERCORS (26420)", value: "26420" },
  { label: "LA CHARCE (26470)", value: "26470" },
  { label: "CHARENS (26310)", value: "26310" },
  { label: "CHARMES SUR L HERBASSE (26260)", value: "26260" },
  { label: "CHAROLS (26450)", value: "26450" },
  { label: "CHARPEY (26300)", value: "26300" },
  { label: "CHARPEY (26300)", value: "26300" },
  { label: "CHASTEL ARNAUD (26340)", value: "26340" },
  { label: "CHATEAUDOUBLE (26120)", value: "26120" },
  { label: "CHATEAUNEUF DE BORDETTE (26110)", value: "26110" },
  { label: "CHATEAUNEUF DE GALAURE (26330)", value: "26330" },
  { label: "CHATEAUNEUF SUR ISERE (26300)", value: "26300" },
  { label: "CHATEAUNEUF DU RHONE (26780)", value: "26780" },
  { label: "CHATILLON EN DIOIS (26410)", value: "26410" },
  { label: "CHATILLON EN DIOIS (26410)", value: "26410" },
  { label: "CHATILLON EN DIOIS (26410)", value: "26410" },
  { label: "CHATILLON ST JEAN (26750)", value: "26750" },
  { label: "CHATUZANGE LE GOUBET (26300)", value: "26300" },
  { label: "CHATUZANGE LE GOUBET (26300)", value: "26300" },
  { label: "CHAUDEBONNE (26110)", value: "26110" },
  { label: "LA CHAUDIERE (26340)", value: "26340" },
  { label: "CHAUVAC LAUX MONTAUX (26510)", value: "26510" },
  { label: "CHAUVAC LAUX MONTAUX (26510)", value: "26510" },
  { label: "CHAVANNES (26260)", value: "26260" },
  { label: "CLANSAYES (26130)", value: "26130" },
  { label: "CLAVEYSON (26240)", value: "26240" },
  { label: "CLEON D ANDRAN (26450)", value: "26450" },
  { label: "CLERIEUX (26260)", value: "26260" },
  { label: "CLIOUSCLAT (26270)", value: "26270" },
  { label: "COBONNE (26400)", value: "26400" },
  { label: "COLONZELLE (26230)", value: "26230" },
  { label: "COMBOVIN (26120)", value: "26120" },
  { label: "COMPS (26220)", value: "26220" },
  { label: "CONDILLAC (26740)", value: "26740" },
  { label: "CONDORCET (26110)", value: "26110" },
  { label: "CORNILLAC (26510)", value: "26510" },
  { label: "CORNILLON SUR L OULE (26510)", value: "26510" },
  { label: "LA COUCOURDE (26740)", value: "26740" },
  { label: "LA COUCOURDE (26740)", value: "26740" },
  { label: "CREPOL (26350)", value: "26350" },
  { label: "CREST (26400)", value: "26400" },
  { label: "CROZES HERMITAGE (26600)", value: "26600" },
  { label: "CRUPIES (26460)", value: "26460" },
  { label: "CURNIER (26110)", value: "26110" },
  { label: "DIE (26150)", value: "26150" },
  { label: "DIEULEFIT (26220)", value: "26220" },
  { label: "DIVAJEU (26400)", value: "26400" },
  { label: "DONZERE (26290)", value: "26290" },
  { label: "ECHEVIS (26190)", value: "26190" },
  { label: "EPINOUZE (26210)", value: "26210" },
  { label: "EROME (26600)", value: "26600" },
  { label: "ESPELUCHE (26780)", value: "26780" },
  { label: "ESPENEL (26340)", value: "26340" },
  { label: "ESTABLET (26470)", value: "26470" },
  { label: "ETOILE SUR RHONE (26800)", value: "26800" },
  { label: "ETOILE SUR RHONE (26800)", value: "26800" },
  { label: "EURRE (26400)", value: "26400" },
  { label: "EYGALAYES (26560)", value: "26560" },
  { label: "EYGALIERS (26170)", value: "26170" },
  { label: "EYGLUY ESCOULIN (26400)", value: "26400" },
  { label: "EYMEUX (26730)", value: "26730" },
  { label: "EYMEUX (26730)", value: "26730" },
  { label: "EYROLES (26110)", value: "26110" },
  { label: "EYZAHUT (26160)", value: "26160" },
  { label: "FAY LE CLOS (26240)", value: "26240" },
  { label: "FELINES SUR RIMANDOULE (26160)", value: "26160" },
  { label: "FERRASSIERES (26570)", value: "26570" },
  { label: "VAL MARAVEL (26310)", value: "26310" },
  { label: "VAL MARAVEL (26310)", value: "26310" },
  { label: "VAL MARAVEL (26310)", value: "26310" },
  { label: "FRANCILLON SUR ROUBION (26400)", value: "26400" },
  { label: "LA GARDE ADHEMAR (26700)", value: "26700" },
  { label: "GENISSIEUX (26750)", value: "26750" },
  { label: "GEYSSANS (26750)", value: "26750" },
  { label: "GIGORS ET LOZERON (26400)", value: "26400" },
  { label: "GLANDAGE (26410)", value: "26410" },
  { label: "LE GRAND SERRE (26530)", value: "26530" },
  { label: "GRANE (26400)", value: "26400" },
  { label: "LES GRANGES GONTARDES (26290)", value: "26290" },
  { label: "GRIGNAN (26230)", value: "26230" },
  { label: "GUMIANE (26470)", value: "26470" },
  { label: "HAUTERIVES (26390)", value: "26390" },
  { label: "HOSTUN (26730)", value: "26730" },
  { label: "IZON LA BRUISSE (26560)", value: "26560" },
  { label: "JONCHERES (26310)", value: "26310" },
  { label: "LABOREL (26560)", value: "26560" },
  { label: "LACHAU (26560)", value: "26560" },
  { label: "LAPEYROUSE MORNAY (26210)", value: "26210" },
  { label: "LARNAGE (26600)", value: "26600" },
  { label: "LA LAUPIE (26740)", value: "26740" },
  { label: "LAVAL D AIX (26150)", value: "26150" },
  { label: "LAVEYRON (26240)", value: "26240" },
  { label: "LEMPS (26510)", value: "26510" },
  { label: "LENS LESTANG (26210)", value: "26210" },
  { label: "LEONCEL (26190)", value: "26190" },
  { label: "LESCHES EN DIOIS (26310)", value: "26310" },
  { label: "LIVRON SUR DROME (26250)", value: "26250" },
  { label: "LIVRON SUR DROME (26250)", value: "26250" },
  { label: "LORIOL SUR DROME (26270)", value: "26270" },
  { label: "LUC EN DIOIS (26310)", value: "26310" },
  { label: "LUS LA CROIX HAUTE (26620)", value: "26620" },
  { label: "MALATAVERNE (26780)", value: "26780" },
  { label: "MALISSARD (26120)", value: "26120" },
  { label: "MANAS (26160)", value: "26160" },
  { label: "MANTHES (26210)", value: "26210" },
  { label: "MARCHES (26300)", value: "26300" },
  { label: "MARGES (26260)", value: "26260" },
  { label: "MARIGNAC EN DIOIS (26150)", value: "26150" },
  { label: "MARSANNE (26740)", value: "26740" },
  { label: "MARSAZ (26260)", value: "26260" },
  { label: "MENGLON (26410)", value: "26410" },
  { label: "MERCUROL VEAUNES (26600)", value: "26600" },
  { label: "MERCUROL VEAUNES (26600)", value: "26600" },
  { label: "MERINDOL LES OLIVIERS (26170)", value: "26170" },
  { label: "MEVOUILLON (26560)", value: "26560" },
  { label: "MIRABEL AUX BARONNIES (26110)", value: "26110" },
  { label: "MIRABEL ET BLACONS (26400)", value: "26400" },
  { label: "MIRMANDE (26270)", value: "26270" },
  { label: "MISCON (26310)", value: "26310" },
  { label: "MOLLANS SUR OUVEZE (26170)", value: "26170" },
  { label: "MONTAUBAN SUR L OUVEZE (26170)", value: "26170" },
  { label: "MONTAULIEU (26110)", value: "26110" },
  { label: "MONTBOUCHER SUR JABRON (26740)", value: "26740" },
  { label: "MONTBRISON SUR LEZ (26770)", value: "26770" },
  { label: "MONTBRUN LES BAINS (26570)", value: "26570" },
  { label: "MONTCHENU (26350)", value: "26350" },
  { label: "MONTCLAR SUR GERVANNE (26400)", value: "26400" },
  { label: "MONTELEGER (26760)", value: "26760" },
  { label: "MONTELIER (26120)", value: "26120" },
  { label: "MONTELIER (26120)", value: "26120" },
  { label: "MONTELIMAR (26200)", value: "26200" },
  { label: "MONTFERRAND LA FARE (26510)", value: "26510" },
  { label: "MONTFROC (26560)", value: "26560" },
  { label: "MONTGUERS (26170)", value: "26170" },
  { label: "MONTJOUX (26220)", value: "26220" },
  { label: "MONTJOYER (26230)", value: "26230" },
  { label: "MONTLAUR EN DIOIS (26310)", value: "26310" },
  { label: "MONTMAUR EN DIOIS (26150)", value: "26150" },
  { label: "MONTMEYRAN (26120)", value: "26120" },
  { label: "MONTMIRAL (26750)", value: "26750" },
  { label: "MONTOISON (26800)", value: "26800" },
  { label: "MONTREAL LES SOURCES (26510)", value: "26510" },
  { label: "VALHERBASSE (26350)", value: "26350" },
  { label: "VALHERBASSE (26350)", value: "26350" },
  { label: "VALHERBASSE (26350)", value: "26350" },
  { label: "MONTSEGUR SUR LAUZON (26130)", value: "26130" },
  { label: "MONTVENDRE (26120)", value: "26120" },
  { label: "MORAS EN VALLOIRE (26210)", value: "26210" },
  { label: "MORNANS (26460)", value: "26460" },
  { label: "LA MOTTE CHALANCON (26470)", value: "26470" },
  { label: "ST JEAN DE GALAURE (26240)", value: "26240" },
  { label: "ST JEAN DE GALAURE (26240)", value: "26240" },
  { label: "LA MOTTE FANJAS (26190)", value: "26190" },
  { label: "MOURS ST EUSEBE (26540)", value: "26540" },
  { label: "NYONS (26110)", value: "26110" },
  { label: "OMBLEZE (26400)", value: "26400" },
  { label: "ORCINAS (26220)", value: "26220" },
  { label: "ORIOL EN ROYANS (26190)", value: "26190" },
  { label: "OURCHES (26120)", value: "26120" },
  { label: "PARNANS (26750)", value: "26750" },
  { label: "LE PEGUE (26770)", value: "26770" },
  { label: "PELONNE (26510)", value: "26510" },
  { label: "PENNES LE SEC (26340)", value: "26340" },
  { label: "LA PENNE SUR L OUVEZE (26170)", value: "26170" },
  { label: "PEYRINS (26380)", value: "26380" },
  { label: "PEYRUS (26120)", value: "26120" },
  { label: "PIEGON (26110)", value: "26110" },
  { label: "PIEGROS LA CLASTRE (26400)", value: "26400" },
  { label: "PIERRELATTE (26700)", value: "26700" },
  { label: "PIERRELONGUE (26170)", value: "26170" },
  { label: "LES PILLES (26110)", value: "26110" },
  { label: "PLAISIANS (26170)", value: "26170" },
  { label: "PLAN DE BAIX (26400)", value: "26400" },
  { label: "LE POET CELARD (26460)", value: "26460" },
  { label: "LE POET EN PERCIP (26170)", value: "26170" },
  { label: "LE POET LAVAL (26160)", value: "26160" },
  { label: "LE POET SIGILLAT (26110)", value: "26110" },
  { label: "POMMEROL (26470)", value: "26470" },
  { label: "PONET ET ST AUBAN (26150)", value: "26150" },
  { label: "PONSAS (26240)", value: "26240" },
  { label: "PONTAIX (26150)", value: "26150" },
  { label: "PONT DE BARRET (26160)", value: "26160" },
  { label: "PONT DE L ISERE (26600)", value: "26600" },
  { label: "PORTES EN VALDAINE (26160)", value: "26160" },
  { label: "PORTES LES VALENCE (26800)", value: "26800" },
  { label: "POYOLS (26310)", value: "26310" },
  { label: "PRADELLE (26340)", value: "26340" },
  { label: "LES PRES (26310)", value: "26310" },
  { label: "PROPIAC (26170)", value: "26170" },
  { label: "PUYGIRON (26160)", value: "26160" },
  { label: "PUY ST MARTIN (26450)", value: "26450" },
  { label: "RATIERES (26330)", value: "26330" },
  { label: "REAUVILLE (26230)", value: "26230" },
  { label: "RECOUBEAU JANSAC (26310)", value: "26310" },
  { label: "RECOUBEAU JANSAC (26310)", value: "26310" },
  { label: "REILHANETTE (26570)", value: "26570" },
  { label: "REMUZAT (26510)", value: "26510" },
  { label: "RIMON ET SAVEL (26340)", value: "26340" },
  { label: "RIOMS (26170)", value: "26170" },
  { label: "ROCHEBAUDIN (26160)", value: "26160" },
  { label: "ROCHEBRUNE (26110)", value: "26110" },
  { label: "ROCHECHINARD (26190)", value: "26190" },
  { label: "LA ROCHE DE GLUN (26600)", value: "26600" },
  { label: "ROCHEFORT EN VALDAINE (26160)", value: "26160" },
  { label: "ROCHEFORT SAMSON (26300)", value: "26300" },
  { label: "ROCHEFOURCHAT (26340)", value: "26340" },
  { label: "ROCHEGUDE (26790)", value: "26790" },
  { label: "ROCHE ST SECRET BECONNE (26770)", value: "26770" },
  { label: "ROCHE ST SECRET BECONNE (26770)", value: "26770" },
  { label: "LA ROCHE SUR GRANE (26400)", value: "26400" },
  { label: "LA ROCHE SUR LE BUIS (26170)", value: "26170" },
  { label: "LA ROCHETTE DU BUIS (26170)", value: "26170" },
  { label: "ROMANS SUR ISERE (26100)", value: "26100" },
  { label: "ROMEYER (26150)", value: "26150" },
  { label: "ROTTIER (26470)", value: "26470" },
  { label: "ROUSSAS (26230)", value: "26230" },
  { label: "ROUSSET LES VIGNES (26770)", value: "26770" },
  { label: "ROUSSIEUX (26510)", value: "26510" },
  { label: "ROYNAC (26450)", value: "26450" },
  { label: "SAHUNE (26510)", value: "26510" },
  { label: "SAILLANS (26340)", value: "26340" },
  { label: "ST AGNAN EN VERCORS (26420)", value: "26420" },
  { label: "ST AGNAN EN VERCORS (26420)", value: "26420" },
  { label: "ST AGNAN EN VERCORS (26420)", value: "26420" },
  { label: "ST ANDEOL (26150)", value: "26150" },
  { label: "ST AUBAN SUR L OUVEZE (26170)", value: "26170" },
  { label: "ST AVIT (26330)", value: "26330" },
  { label: "ST BARDOUX (26260)", value: "26260" },
  { label: "ST BARTHELEMY DE VALS (26240)", value: "26240" },
  { label: "ST BENOIT EN DIOIS (26340)", value: "26340" },
  { label: "ST CHRISTOPHE ET LE LARIS (26350)", value: "26350" },
  { label: "STE CROIX (26150)", value: "26150" },
  { label: "ST DIZIER EN DIOIS (26310)", value: "26310" },
  { label: "ST DONAT SUR L HERBASSE (26260)", value: "26260" },
  { label: "STE EULALIE EN ROYANS (26190)", value: "26190" },
  { label: "STE EUPHEMIE SUR OUVEZE (26170)", value: "26170" },
  { label: "ST FERREOL TRENTE PAS (26110)", value: "26110" },
  { label: "ST GERVAIS SUR ROUBION (26160)", value: "26160" },
  { label: "STE JALLE (26110)", value: "26110" },
  { label: "ST JEAN EN ROYANS (26190)", value: "26190" },
  { label: "ST JULIEN EN QUINT (26150)", value: "26150" },
  { label: "ST JULIEN EN VERCORS (26420)", value: "26420" },
  { label: "ST LAURENT D ONAY (26350)", value: "26350" },
  { label: "ST LAURENT EN ROYANS (26190)", value: "26190" },
  { label: "ST MARCEL LES SAUZET (26740)", value: "26740" },
  { label: "ST MARCEL LES VALENCE (26320)", value: "26320" },
  { label: "ST MARTIN D AOUT (26330)", value: "26330" },
  { label: "ST MARTIN EN VERCORS (26420)", value: "26420" },
  { label: "ST MARTIN LE COLONEL (26190)", value: "26190" },
  { label: "ST MAURICE SUR EYGUES (26110)", value: "26110" },
  { label: "ST MAY (26510)", value: "26510" },
  { label: "ST MICHEL SUR SAVASSE (26750)", value: "26750" },
  { label: "ST NAZAIRE EN ROYANS (26190)", value: "26190" },
  { label: "ST NAZAIRE LE DESERT (26340)", value: "26340" },
  { label: "ST PANTALEON LES VIGNES (26770)", value: "26770" },
  { label: "ST PAUL LES ROMANS (26750)", value: "26750" },
  { label: "ST PAUL TROIS CHATEAUX (26130)", value: "26130" },
  { label: "ST RAMBERT D ALBON (26140)", value: "26140" },
  { label: "ST RESTITUT (26130)", value: "26130" },
  { label: "ST ROMAN (26410)", value: "26410" },
  { label: "ST SAUVEUR EN DIOIS (26340)", value: "26340" },
  { label: "ST SAUVEUR GOUVERNET (26110)", value: "26110" },
  { label: "ST SORLIN EN VALLOIRE (26210)", value: "26210" },
  { label: "ST THOMAS EN ROYANS (26190)", value: "26190" },
  { label: "ST UZE (26240)", value: "26240" },
  { label: "ST VALLIER (26240)", value: "26240" },
  { label: "SALETTES (26160)", value: "26160" },
  { label: "SALLES SOUS BOIS (26770)", value: "26770" },
  { label: "SAOU (26400)", value: "26400" },
  { label: "SAULCE SUR RHONE (26270)", value: "26270" },
  { label: "SAULCE SUR RHONE (26270)", value: "26270" },
  { label: "SAUZET (26740)", value: "26740" },
  { label: "SAVASSE (26740)", value: "26740" },
  { label: "SAVASSE (26740)", value: "26740" },
  { label: "SEDERON (26560)", value: "26560" },
  { label: "SERVES SUR RHONE (26600)", value: "26600" },
  { label: "SOLERIEUX (26130)", value: "26130" },
  { label: "SOUSPIERRE (26160)", value: "26160" },
  { label: "SOYANS (26400)", value: "26400" },
  { label: "SUZE LA ROUSSE (26790)", value: "26790" },
  { label: "SUZE (26400)", value: "26400" },
  { label: "TAIN L HERMITAGE (26600)", value: "26600" },
  { label: "TAULIGNAN (26770)", value: "26770" },
  { label: "TERSANNE (26390)", value: "26390" },
  { label: "TEYSSIERES (26220)", value: "26220" },
  { label: "LES TONILS (26460)", value: "26460" },
  { label: "LA TOUCHE (26160)", value: "26160" },
  { label: "LES TOURRETTES (26740)", value: "26740" },
  { label: "TRIORS (26750)", value: "26750" },
  { label: "TRUINAS (26460)", value: "26460" },
  { label: "TULETTE (26790)", value: "26790" },
  { label: "UPIE (26120)", value: "26120" },
  { label: "VACHERES EN QUINT (26150)", value: "26150" },
  { label: "VALAURIE (26230)", value: "26230" },
  { label: "VALDROME (26310)", value: "26310" },
  { label: "VALENCE (26000)", value: "26000" },
  { label: "VALOUSE (26110)", value: "26110" },
  { label: "VASSIEUX EN VERCORS (26420)", value: "26420" },
  { label: "VAUNAVEYS LA ROCHETTE (26400)", value: "26400" },
  { label: "VAUNAVEYS LA ROCHETTE (26400)", value: "26400" },
  { label: "VENTEROL (26110)", value: "26110" },
  { label: "VERCHENY (26340)", value: "26340" },
  { label: "VERCLAUSE (26510)", value: "26510" },
  { label: "VERCOIRAN (26170)", value: "26170" },
  { label: "VERONNE (26340)", value: "26340" },
  { label: "VERS SUR MEOUGE (26560)", value: "26560" },
  { label: "VESC (26220)", value: "26220" },
  { label: "VILLEBOIS LES PINS (05700)", value: "05700" },
  { label: "VILLEFRANCHE LE CHATEAU (26560)", value: "26560" },
  { label: "VILLEPERDRIX (26510)", value: "26510" },
  { label: "VINSOBRES (26110)", value: "26110" },
  { label: "VOLVENT (26470)", value: "26470" },
  { label: "GRANGES LES BEAUMONT (26600)", value: "26600" },
  { label: "GERVANS (26600)", value: "26600" },
  { label: "JAILLANS (26300)", value: "26300" },
  { label: "ST VINCENT LA COMMANDERIE (26300)", value: "26300" },
  { label: "ACLOU (27800)", value: "27800" },
  { label: "ACON (27570)", value: "27570" },
  { label: "ACQUIGNY (27400)", value: "27400" },
  { label: "ACQUIGNY (27400)", value: "27400" },
  { label: "AIGLEVILLE (27120)", value: "27120" },
  { label: "AILLY (27600)", value: "27600" },
  { label: "AIZIER (27500)", value: "27500" },
  { label: "ALIZAY (27460)", value: "27460" },
  { label: "AMBENAY (27250)", value: "27250" },
  { label: "AMECOURT (27140)", value: "27140" },
  { label: "AMFREVILLE ST AMAND (27370)", value: "27370" },
  { label: "AMFREVILLE ST AMAND (27370)", value: "27370" },
  { label: "AMFREVILLE LES CHAMPS (27380)", value: "27380" },
  { label: "AMFREVILLE SOUS LES MONTS (27380)", value: "27380" },
  { label: "AMFREVILLE SUR ITON (27400)", value: "27400" },
  { label: "ANDE (27430)", value: "27430" },
  { label: "LES ANDELYS (27700)", value: "27700" },
  { label: "ANGERVILLE LA CAMPAGNE (27930)", value: "27930" },
  { label: "APPEVILLE ANNEBAULT (27290)", value: "27290" },
  { label: "ARMENTIERES SUR AVRE (27820)", value: "27820" },
  { label: "ARNIERES SUR ITON (27180)", value: "27180" },
  { label: "ASNIERES (27260)", value: "27260" },
  { label: "LE VAL D HAZEY (27600)", value: "27600" },
  { label: "LE VAL D HAZEY (27600)", value: "27600" },
  { label: "LE VAL D HAZEY (27940)", value: "27940" },
  { label: "AULNAY SUR ITON (27180)", value: "27180" },
  { label: "AUTHEUIL AUTHOUILLET (27490)", value: "27490" },
  { label: "AUTHEUIL AUTHOUILLET (27490)", value: "27490" },
  { label: "AUTHEVERNES (27420)", value: "27420" },
  { label: "LES AUTHIEUX (27220)", value: "27220" },
  { label: "AUTHOU (27290)", value: "27290" },
  { label: "AVIRON (27930)", value: "27930" },
  { label: "CHAMBOIS (27240)", value: "27240" },
  { label: "CHAMBOIS (27240)", value: "27240" },
  { label: "CHAMBOIS (27240)", value: "27240" },
  { label: "BACQUEPUIS (27930)", value: "27930" },
  { label: "BACQUEVILLE (27440)", value: "27440" },
  { label: "BAILLEUL LA VALLEE (27260)", value: "27260" },
  { label: "BALINES (27130)", value: "27130" },
  { label: "BARC (27170)", value: "27170" },
  { label: "LES BARILS (27130)", value: "27130" },
  { label: "BARNEVILLE SUR SEINE (27310)", value: "27310" },
  { label: "BARQUET (27170)", value: "27170" },
  { label: "BARVILLE (27230)", value: "27230" },
  { label: "LES BAUX DE BRETEUIL (27160)", value: "27160" },
  { label: "LES BAUX STE CROIX (27180)", value: "27180" },
  { label: "BAZINCOURT SUR EPTE (27140)", value: "27140" },
  { label: "BAZOQUES (27230)", value: "27230" },
  { label: "BEAUBRAY (27190)", value: "27190" },
  { label: "BEAUFICEL EN LYONS (27480)", value: "27480" },
  { label: "MESNIL EN OUCHE (27270)", value: "27270" },
  { label: "MESNIL EN OUCHE (27330)", value: "27330" },
  { label: "MESNIL EN OUCHE (27330)", value: "27330" },
  { label: "MESNIL EN OUCHE (27330)", value: "27330" },
  { label: "MESNIL EN OUCHE (27330)", value: "27330" },
  { label: "MESNIL EN OUCHE (27330)", value: "27330" },
  { label: "MESNIL EN OUCHE (27330)", value: "27330" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "MESNIL EN OUCHE (27410)", value: "27410" },
  { label: "BEAUMONTEL (27170)", value: "27170" },
  { label: "BEAUMONT LE ROGER (27170)", value: "27170" },
  { label: "LE BEC HELLOUIN (27800)", value: "27800" },
  { label: "LE BEC THOMAS (27370)", value: "27370" },
  { label: "BEMECOURT (27160)", value: "27160" },
  { label: "BERENGEVILLE LA CAMPAGNE (27110)", value: "27110" },
  { label: "BERNAY (27300)", value: "27300" },
  { label: "BERNIENVILLE (27180)", value: "27180" },
  { label: "LES TROIS LACS (27700)", value: "27700" },
  { label: "LES TROIS LACS (27700)", value: "27700" },
  { label: "LES TROIS LACS (27940)", value: "27940" },
  { label: "BERNOUVILLE (27660)", value: "27660" },
  { label: "BERTHOUVILLE (27800)", value: "27800" },
  { label: "LES MONTS DU ROUMOIS (27370)", value: "27370" },
  { label: "LES MONTS DU ROUMOIS (27520)", value: "27520" },
  { label: "LES MONTS DU ROUMOIS (27520)", value: "27520" },
  { label: "BERVILLE LA CAMPAGNE (27170)", value: "27170" },
  { label: "BERVILLE SUR MER (27210)", value: "27210" },
  { label: "BEUZEVILLE (27210)", value: "27210" },
  { label: "BEZU LA FORET (27480)", value: "27480" },
  { label: "BEZU ST ELOI (27660)", value: "27660" },
  { label: "BOIS ANZERAY (27330)", value: "27330" },
  { label: "BOIS ARNAULT (27250)", value: "27250" },
  { label: "FRENELLES EN VEXIN (27150)", value: "27150" },
  { label: "FRENELLES EN VEXIN (27700)", value: "27700" },
  { label: "FRENELLES EN VEXIN (27700)", value: "27700" },
  { label: "LE BOIS HELLAIN (27260)", value: "27260" },
  { label: "BOIS JEROME ST OUEN (27620)", value: "27620" },
  { label: "BOIS LE ROI (27220)", value: "27220" },
  { label: "BOISNEY (27800)", value: "27800" },
  { label: "BOIS NORMAND PRES LYRE (27330)", value: "27330" },
  { label: "BOISSET LES PREVANCHES (27120)", value: "27120" },
  { label: "BOISSEY LE CHATEL (27520)", value: "27520" },
  { label: "LA BOISSIERE (27220)", value: "27220" },
  { label: "BOISSY LAMBERVILLE (27300)", value: "27300" },
  { label: "BONCOURT (27120)", value: "27120" },
  { label: "LA BONNEVILLE SUR ITON (27190)", value: "27190" },
  { label: "BONNEVILLE APTOT (27290)", value: "27290" },
  { label: "FLANCOURT CRESCY EN ROUMOIS (27310)", value: "27310" },
  { label: "FLANCOURT CRESCY EN ROUMOIS (27310)", value: "27310" },
  { label: "FLANCOURT CRESCY EN ROUMOIS (27310)", value: "27310" },
  { label: "THENOUVILLE (27290)", value: "27290" },
  { label: "THENOUVILLE (27520)", value: "27520" },
  { label: "THENOUVILLE (27520)", value: "27520" },
  { label: "BOSROUMOIS (27670)", value: "27670" },
  { label: "BOSROUMOIS (27670)", value: "27670" },
  { label: "BOSGOUET (27310)", value: "27310" },
  { label: "BOSQUENTIN (27480)", value: "27480" },
  { label: "BOSROBERT (27800)", value: "27800" },
  { label: "LES BOTTEREAUX (27250)", value: "27250" },
  { label: "LES BOTTEREAUX (27250)", value: "27250" },
  { label: "LES BOTTEREAUX (27250)", value: "27250" },
  { label: "BOUAFLES (27700)", value: "27700" },
  { label: "BOUCHEVILLIERS (27150)", value: "27150" },
  { label: "LE BOULAY MORIN (27930)", value: "27930" },
  { label: "BOULLEVILLE (27210)", value: "27210" },
  { label: "BOULLEVILLE (27210)", value: "27210" },
  { label: "BOUQUELON (27500)", value: "27500" },
  { label: "BOUQUETOT (27310)", value: "27310" },
  { label: "BOURG ACHARD (27310)", value: "27310" },
  { label: "BOURG BEAUDOUIN (27380)", value: "27380" },
  { label: "GRAND BOURGTHEROULDE (27520)", value: "27520" },
  { label: "GRAND BOURGTHEROULDE (27520)", value: "27520" },
  { label: "GRAND BOURGTHEROULDE (27520)", value: "27520" },
  { label: "GRAND BOURGTHEROULDE (27520)", value: "27520" },
  { label: "GRAND BOURGTHEROULDE (27520)", value: "27520" },
  { label: "BOURNAINVILLE FAVEROLLES (27230)", value: "27230" },
  { label: "BOURNAINVILLE FAVEROLLES (27230)", value: "27230" },
  { label: "BOURNAINVILLE FAVEROLLES (27230)", value: "27230" },
  { label: "BOURNEVILLE STE CROIX (27500)", value: "27500" },
  { label: "BOURNEVILLE STE CROIX (27500)", value: "27500" },
  { label: "BOURTH (27580)", value: "27580" },
  { label: "BRAY (27170)", value: "27170" },
  { label: "BRESTOT (27350)", value: "27350" },
  { label: "BRETAGNOLLES (27220)", value: "27220" },
  { label: "BRETEUIL (27160)", value: "27160" },
  { label: "BRETEUIL (27160)", value: "27160" },
  { label: "BRETEUIL (27160)", value: "27160" },
  { label: "BRETIGNY (27800)", value: "27800" },
  { label: "BREUILPONT (27640)", value: "27640" },
  { label: "BREUX SUR AVRE (27570)", value: "27570" },
  { label: "BRIONNE (27800)", value: "27800" },
  { label: "BROGLIE (27270)", value: "27270" },
  { label: "BROSVILLE (27930)", value: "27930" },
  { label: "BUEIL (27730)", value: "27730" },
  { label: "BUREY (27190)", value: "27190" },
  { label: "CAILLOUET ORGEVILLE (27120)", value: "27120" },
  { label: "CAILLY SUR EURE (27490)", value: "27490" },
  { label: "CALLEVILLE (27800)", value: "27800" },
  { label: "CAMPIGNY (27500)", value: "27500" },
  { label: "CANAPPEVILLE (27400)", value: "27400" },
  { label: "CAORCHES ST NICOLAS (27300)", value: "27300" },
  { label: "CAORCHES ST NICOLAS (27300)", value: "27300" },
  { label: "CAPELLE LES GRANDS (27270)", value: "27270" },
  { label: "CAUGE (27180)", value: "27180" },
  { label: "CAUMONT (27310)", value: "27310" },
  { label: "CAUVERVILLE EN ROUMOIS (27350)", value: "27350" },
  { label: "CESSEVILLE (27110)", value: "27110" },
  { label: "CHAIGNES (27120)", value: "27120" },
  { label: "CHAISE DIEU DU THEIL (27580)", value: "27580" },
  { label: "CHAMBLAC (27270)", value: "27270" },
  { label: "CHAMBLAC (27270)", value: "27270" },
  { label: "CHAMBORD (27250)", value: "27250" },
  { label: "CHAMBRAY (27120)", value: "27120" },
  { label: "CHAMP DOLENT (27190)", value: "27190" },
  { label: "CHAMPENARD (27600)", value: "27600" },
  { label: "CHAMPIGNY LA FUTELAYE (27220)", value: "27220" },
  { label: "LA CHAPELLE BAYVEL (27260)", value: "27260" },
  { label: "LA CHAPELLE DU BOIS DES FAULX (27930)", value: "27930" },
  { label: "LA CHAPELLE GAUTHIER (27270)", value: "27270" },
  { label: "LA CHAPELLE HARENG (27230)", value: "27230" },
  { label: "CHARLEVAL (27380)", value: "27380" },
  { label: "CHATEAU SUR EPTE (27420)", value: "27420" },
  { label: "CHAUVINCOURT PROVEMONT (27150)", value: "27150" },
  { label: "CHAUVINCOURT PROVEMONT (27150)", value: "27150" },
  { label: "CHAVIGNY BAILLEUL (27220)", value: "27220" },
  { label: "CHENNEBRUN (27820)", value: "27820" },
  { label: "CHERONVILLIERS (27250)", value: "27250" },
  { label: "MARBOIS (27160)", value: "27160" },
  { label: "MARBOIS (27160)", value: "27160" },
  { label: "MARBOIS (27240)", value: "27240" },
  { label: "MARBOIS (27240)", value: "27240" },
  { label: "CIERREY (27930)", value: "27930" },
  { label: "CLAVILLE (27180)", value: "27180" },
  { label: "COLLANDRES QUINCARNON (27190)", value: "27190" },
  { label: "COLLETOT (27500)", value: "27500" },
  { label: "COMBON (27170)", value: "27170" },
  { label: "CONCHES EN OUCHE (27190)", value: "27190" },
  { label: "CONDE SUR RISLE (27290)", value: "27290" },
  { label: "CONNELLES (27430)", value: "27430" },
  { label: "CONTEVILLE (27210)", value: "27210" },
  { label: "CORMEILLES (27260)", value: "27260" },
  { label: "LE CORMIER (27120)", value: "27120" },
  { label: "CORNEVILLE LA FOUQUETIERE (27300)", value: "27300" },
  { label: "CORNEVILLE SUR RISLE (27500)", value: "27500" },
  { label: "COUDRAY (27150)", value: "27150" },
  { label: "COUDRES (27220)", value: "27220" },
  { label: "COURBEPINE (27300)", value: "27300" },
  { label: "COURCELLES SUR SEINE (27940)", value: "27940" },
  { label: "COURDEMANCHE (27320)", value: "27320" },
  { label: "COURTEILLES (27130)", value: "27130" },
  { label: "LA COUTURE BOUSSEY (27750)", value: "27750" },
  { label: "CRASVILLE (27400)", value: "27400" },
  { label: "CRESTOT (27110)", value: "27110" },
  { label: "CRIQUEBEUF LA CAMPAGNE (27110)", value: "27110" },
  { label: "CRIQUEBEUF SUR SEINE (27340)", value: "27340" },
  { label: "LA CROISILLE (27190)", value: "27190" },
  { label: "CROISY SUR EURE (27120)", value: "27120" },
  { label: "CLEF VALLEE D EURE (27490)", value: "27490" },
  { label: "CLEF VALLEE D EURE (27490)", value: "27490" },
  { label: "CLEF VALLEE D EURE (27490)", value: "27490" },
  { label: "CROSVILLE LA VIEILLE (27110)", value: "27110" },
  { label: "CROTH (27530)", value: "27530" },
  { label: "CUVERVILLE (27700)", value: "27700" },
  { label: "LES DAMPS (27340)", value: "27340" },
  { label: "MESNILS SUR ITON (27160)", value: "27160" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "MESNILS SUR ITON (27240)", value: "27240" },
  { label: "DANGU (27720)", value: "27720" },
  { label: "DARDEZ (27930)", value: "27930" },
  { label: "DAUBEUF LA CAMPAGNE (27110)", value: "27110" },
  { label: "DAUBEUF PRES VATTEVILLE (27430)", value: "27430" },
  { label: "DOUAINS (27120)", value: "27120" },
  { label: "DOUDEAUVILLE EN VEXIN (27150)", value: "27150" },
  { label: "DOUVILLE SUR ANDELLE (27380)", value: "27380" },
  { label: "DROISY (27320)", value: "27320" },
  { label: "DROISY (27320)", value: "27320" },
  { label: "DRUCOURT (27230)", value: "27230" },
  { label: "DURANVILLE (27230)", value: "27230" },
  { label: "ECAQUELON (27290)", value: "27290" },
  { label: "ECARDENVILLE LA CAMPAGNE (27170)", value: "27170" },
  { label: "ECAUVILLE (27110)", value: "27110" },
  { label: "VEXIN SUR EPTE (27420)", value: "27420" },
  { label: "VEXIN SUR EPTE (27420)", value: "27420" },
  { label: "VEXIN SUR EPTE (27510)", value: "27510" },
  { label: "VEXIN SUR EPTE (27510)", value: "27510" },
  { label: "VEXIN SUR EPTE (27510)", value: "27510" },
  { label: "VEXIN SUR EPTE (27510)", value: "27510" },
  { label: "VEXIN SUR EPTE (27510)", value: "27510" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "VEXIN SUR EPTE (27630)", value: "27630" },
  { label: "ECOUIS (27440)", value: "27440" },
  { label: "ECQUETOT (27110)", value: "27110" },
  { label: "EMALLEVILLE (27930)", value: "27930" },
  { label: "EMANVILLE (27190)", value: "27190" },
  { label: "EPAIGNES (27260)", value: "27260" },
  { label: "EPEGARD (27110)", value: "27110" },
  { label: "EPIEDS (27730)", value: "27730" },
  { label: "EPREVILLE EN LIEUVIN (27560)", value: "27560" },
  { label: "EPREVILLE PRES LE NEUBOURG (27110)", value: "27110" },
  { label: "ETREPAGNY (27150)", value: "27150" },
  { label: "ETREVILLE (27350)", value: "27350" },
  { label: "ETURQUERAYE (27350)", value: "27350" },
  { label: "EVREUX (27000)", value: "27000" },
  { label: "EVREUX (27000)", value: "27000" },
  { label: "EZY SUR EURE (27530)", value: "27530" },
  { label: "FAINS (27120)", value: "27120" },
  { label: "FARCEAUX (27150)", value: "27150" },
  { label: "FATOUVILLE GRESTAIN (27210)", value: "27210" },
  { label: "FAUVILLE (27930)", value: "27930" },
  { label: "FAVEROLLES LA CAMPAGNE (27190)", value: "27190" },
  { label: "LE FAVRIL (27230)", value: "27230" },
  { label: "FERRIERES HAUT CLOCHER (27190)", value: "27190" },
  { label: "FERRIERES ST HILAIRE (27270)", value: "27270" },
  { label: "LA FERRIERE SUR RISLE (27760)", value: "27760" },
  { label: "FEUGUEROLLES (27110)", value: "27110" },
  { label: "LE FIDELAIRE (27190)", value: "27190" },
  { label: "FIQUEFLEUR EQUAINVILLE (27210)", value: "27210" },
  { label: "FLEURY LA FORET (27480)", value: "27480" },
  { label: "FLEURY SUR ANDELLE (27380)", value: "27380" },
  { label: "FLIPOU (27380)", value: "27380" },
  { label: "FOLLEVILLE (27230)", value: "27230" },
  { label: "FONTAINE BELLENGER (27600)", value: "27600" },
  { label: "FONTAINE L ABBE (27470)", value: "27470" },
  { label: "FONTAINE LA LOUVET (27230)", value: "27230" },
  { label: "FONTAINE SOUS JOUY (27120)", value: "27120" },
  { label: "LA FORET DU PARC (27220)", value: "27220" },
  { label: "FORT MOVILLE (27210)", value: "27210" },
  { label: "FOUCRAINVILLE (27220)", value: "27220" },
  { label: "FOULBEC (27210)", value: "27210" },
  { label: "FOUQUEVILLE (27370)", value: "27370" },
  { label: "LE PERREY (27500)", value: "27500" },
  { label: "LE PERREY (27680)", value: "27680" },
  { label: "LE PERREY (27680)", value: "27680" },
  { label: "FRANQUEVILLE (27800)", value: "27800" },
  { label: "FRENEUSE SUR RISLE (27290)", value: "27290" },
  { label: "FRESNE CAUVERVILLE (27260)", value: "27260" },
  { label: "FRESNEY (27220)", value: "27220" },
  { label: "GADENCOURT (27120)", value: "27120" },
  { label: "GAILLON (27600)", value: "27600" },
  { label: "GAMACHES EN VEXIN (27150)", value: "27150" },
  { label: "LA BARONNIE (27220)", value: "27220" },
  { label: "LA BARONNIE (27220)", value: "27220" },
  { label: "GARENNES SUR EURE (27780)", value: "27780" },
  { label: "GASNY (27620)", value: "27620" },
  { label: "GAUCIEL (27930)", value: "27930" },
  { label: "GAUDREVILLE LA RIVIERE (27190)", value: "27190" },
  { label: "GAUVILLE LA CAMPAGNE (27930)", value: "27930" },
  { label: "GISORS (27140)", value: "27140" },
  { label: "GIVERNY (27620)", value: "27620" },
  { label: "GIVERVILLE (27560)", value: "27560" },
  { label: "GLISOLLES (27190)", value: "27190" },
  { label: "GLOS SUR RISLE (27290)", value: "27290" },
  { label: "LA GOULAFRIERE (27390)", value: "27390" },
  { label: "GOUPIL OTHON (27170)", value: "27170" },
  { label: "GOUPIL OTHON (27170)", value: "27170" },
  { label: "GOURNAY LE GUERIN (27580)", value: "27580" },
  { label: "VAL D ORGER (27380)", value: "27380" },
  { label: "VAL D ORGER (27440)", value: "27440" },
  { label: "GRAND CAMP (27270)", value: "27270" },
  { label: "GRAVERON SEMERVILLE (27110)", value: "27110" },
  { label: "GRAVIGNY (27930)", value: "27930" },
  { label: "GROSLEY SUR RISLE (27170)", value: "27170" },
  { label: "GROSSOEUVRE (27220)", value: "27220" },
  { label: "LE BOSC DU THEIL (27370)", value: "27370" },
  { label: "LE BOSC DU THEIL (27370)", value: "27370" },
  { label: "GUERNY (27720)", value: "27720" },
  { label: "GUICHAINVILLE (27930)", value: "27930" },
  { label: "GUISENIERS (27700)", value: "27700" },
  { label: "L'HABIT (27220)", value: "27220" },
  { label: "HACQUEVILLE (27150)", value: "27150" },
  { label: "HARCOURT (27800)", value: "27800" },
  { label: "HARDENCOURT COCHEREL (27120)", value: "27120" },
  { label: "LA HARENGERE (27370)", value: "27370" },
  { label: "HARQUENCY (27700)", value: "27700" },
  { label: "HAUVILLE (27350)", value: "27350" },
  { label: "LA HAYE AUBREE (27350)", value: "27350" },
  { label: "LA HAYE DE CALLEVILLE (27800)", value: "27800" },
  { label: "LA HAYE DE ROUTOT (27350)", value: "27350" },
  { label: "LA HAYE DU THEIL (27370)", value: "27370" },
  { label: "LA HAYE LE COMTE (27400)", value: "27400" },
  { label: "LA HAYE MALHERBE (27400)", value: "27400" },
  { label: "LA HAYE ST SYLVESTRE (27330)", value: "27330" },
  { label: "HEBECOURT (27150)", value: "27150" },
  { label: "HECMANVILLE (27800)", value: "27800" },
  { label: "HECOURT (27120)", value: "27120" },
  { label: "HECTOMARE (27110)", value: "27110" },
  { label: "HENNEZIS (27700)", value: "27700" },
  { label: "HERQUEVILLE (27430)", value: "27430" },
  { label: "HEUBECOURT HARICOURT (27630)", value: "27630" },
  { label: "HEUBECOURT HARICOURT (27630)", value: "27630" },
  { label: "HEUBECOURT HARICOURT (27630)", value: "27630" },
  { label: "HEUDEBOUVILLE (27400)", value: "27400" },
  { label: "HEUDEBOUVILLE (27600)", value: "27600" },
  { label: "HEUDICOURT (27860)", value: "27860" },
  { label: "HEUDREVILLE EN LIEUVIN (27230)", value: "27230" },
  { label: "HEUDREVILLE SUR EURE (27400)", value: "27400" },
  { label: "LA HEUNIERE (27950)", value: "27950" },
  { label: "HEUQUEVILLE (27700)", value: "27700" },
  { label: "LES HOGUES (27910)", value: "27910" },
  { label: "HONDOUVILLE (27400)", value: "27400" },
  { label: "HONGUEMARE GUENOUVILLE (27310)", value: "27310" },
  { label: "L'HOSMES (27570)", value: "27570" },
  { label: "HOUETTEVILLE (27400)", value: "27400" },
  { label: "HOULBEC COCHEREL (27120)", value: "27120" },
  { label: "LA HOUSSAYE (27410)", value: "27410" },
  { label: "HOUVILLE EN VEXIN (27440)", value: "27440" },
  { label: "HUEST (27930)", value: "27930" },
  { label: "IGOVILLE (27460)", value: "27460" },
  { label: "ILLEVILLE SUR MONTFORT (27290)", value: "27290" },
  { label: "ILLIERS L EVEQUE (27770)", value: "27770" },
  { label: "INCARVILLE (27400)", value: "27400" },
  { label: "IRREVILLE (27930)", value: "27930" },
  { label: "IVILLE (27110)", value: "27110" },
  { label: "IVRY LA BATAILLE (27540)", value: "27540" },
  { label: "JOUY SUR EURE (27120)", value: "27120" },
  { label: "JUIGNETTES (27250)", value: "27250" },
  { label: "JUMELLES (27220)", value: "27220" },
  { label: "LA LANDE ST LEGER (27210)", value: "27210" },
  { label: "LA LANDE ST LEGER (27210)", value: "27210" },
  { label: "LE LANDIN (27350)", value: "27350" },
  { label: "LAUNAY (27470)", value: "27470" },
  { label: "LERY (27690)", value: "27690" },
  { label: "LETTEGUIVES (27910)", value: "27910" },
  { label: "LIEUREY (27560)", value: "27560" },
  { label: "LIGNEROLLES (27220)", value: "27220" },
  { label: "LILLY (27480)", value: "27480" },
  { label: "LISORS (27440)", value: "27440" },
  { label: "LIVET SUR AUTHOU (27800)", value: "27800" },
  { label: "LONGCHAMPS (27150)", value: "27150" },
  { label: "LORLEAU (27480)", value: "27480" },
  { label: "LOUVERSEY (27190)", value: "27190" },
  { label: "LOUVIERS (27400)", value: "27400" },
  { label: "LOUYE (27650)", value: "27650" },
  { label: "LYONS LA FORET (27480)", value: "27480" },
  { label: "LA MADELEINE DE NONANCOURT (27320)", value: "27320" },
  { label: "MAINNEVILLE (27150)", value: "27150" },
  { label: "MALLEVILLE SUR LE BEC (27800)", value: "27800" },
  { label: "MALOUY (27300)", value: "27300" },
  { label: "MANDEVILLE (27370)", value: "27370" },
  { label: "MANDRES (27130)", value: "27130" },
  { label: "MANNEVILLE LA RAOULT (27210)", value: "27210" },
  { label: "MANNEVILLE SUR RISLE (27500)", value: "27500" },
  { label: "LE MANOIR (27460)", value: "27460" },
  { label: "MARAIS VERNIER (27680)", value: "27680" },
  { label: "MARBEUF (27110)", value: "27110" },
  { label: "MARCILLY LA CAMPAGNE (27320)", value: "27320" },
  { label: "MARCILLY SUR EURE (27810)", value: "27810" },
  { label: "MARTAGNY (27150)", value: "27150" },
  { label: "MARTAINVILLE (27210)", value: "27210" },
  { label: "MARTOT (27340)", value: "27340" },
  { label: "MELICOURT (27390)", value: "27390" },
  { label: "MENESQUEVILLE (27850)", value: "27850" },
  { label: "MENILLES (27120)", value: "27120" },
  { label: "MENNEVAL (27300)", value: "27300" },
  { label: "MERCEY (27950)", value: "27950" },
  { label: "MEREY (27640)", value: "27640" },
  { label: "LE MESNIL FUGUET (27930)", value: "27930" },
  { label: "LE MESNIL JOURDAIN (27400)", value: "27400" },
  { label: "MESNIL ROUSSET (27390)", value: "27390" },
  { label: "MESNIL SOUS VIENNE (27150)", value: "27150" },
  { label: "MESNIL SUR L ESTREE (27650)", value: "27650" },
  { label: "MESNIL VERCLIVES (27440)", value: "27440" },
  { label: "MEZIERES EN VEXIN (27510)", value: "27510" },
  { label: "MISEREY (27930)", value: "27930" },
  { label: "MOISVILLE (27320)", value: "27320" },
  { label: "TERRES DE BORD (27340)", value: "27340" },
  { label: "TERRES DE BORD (27400)", value: "27400" },
  { label: "MONTFORT SUR RISLE (27290)", value: "27290" },
  { label: "MONTREUIL L ARGILLE (27390)", value: "27390" },
  { label: "MONTREUIL L ARGILLE (27390)", value: "27390" },
  { label: "MORAINVILLE JOUVEAUX (27260)", value: "27260" },
  { label: "MORAINVILLE JOUVEAUX (27260)", value: "27260" },
  { label: "MORGNY (27150)", value: "27150" },
  { label: "MORSAN (27800)", value: "27800" },
  { label: "MOUETTES (27220)", value: "27220" },
  { label: "MOUFLAINES (27420)", value: "27420" },
  { label: "MOUSSEAUX NEUVILLE (27220)", value: "27220" },
  { label: "MUIDS (27430)", value: "27430" },
  { label: "MUZY (27650)", value: "27650" },
  { label: "NAGEL SEEZ MESNIL (27190)", value: "27190" },
  { label: "NASSANDRES SUR RISLE (27170)", value: "27170" },
  { label: "NASSANDRES SUR RISLE (27300)", value: "27300" },
  { label: "NASSANDRES SUR RISLE (27550)", value: "27550" },
  { label: "NASSANDRES SUR RISLE (27550)", value: "27550" },
  { label: "NEAUFLES ST MARTIN (27830)", value: "27830" },
  { label: "NEAUFLES AUVERGNY (27250)", value: "27250" },
  { label: "NEAUFLES AUVERGNY (27250)", value: "27250" },
  { label: "NEAUFLES AUVERGNY (27250)", value: "27250" },
  { label: "LE NEUBOURG (27110)", value: "27110" },
  { label: "NEUILLY (27730)", value: "27730" },
  { label: "LA NEUVE GRANGE (27150)", value: "27150" },
  { label: "LA NEUVE LYRE (27330)", value: "27330" },
  { label: "LA NEUVILLE DU BOSC (27890)", value: "27890" },
  { label: "NEUVILLE SUR AUTHOU (27800)", value: "27800" },
  { label: "NOARDS (27560)", value: "27560" },
  { label: "LA NOE POULAIN (27560)", value: "27560" },
  { label: "NOGENT LE SEC (27190)", value: "27190" },
  { label: "NOJEON EN VEXIN (27150)", value: "27150" },
  { label: "NONANCOURT (27320)", value: "27320" },
  { label: "NORMANVILLE (27930)", value: "27930" },
  { label: "NOTRE DAME DE L ISLE (27940)", value: "27940" },
  { label: "NOTRE DAME D EPINE (27800)", value: "27800" },
  { label: "NOTRE DAME DU HAMEL (27390)", value: "27390" },
  { label: "LE NOYER EN OUCHE (27410)", value: "27410" },
  { label: "NOYERS (27720)", value: "27720" },
  { label: "ORMES (27190)", value: "27190" },
  { label: "LE VAL DORE (27190)", value: "27190" },
  { label: "LE VAL DORE (27190)", value: "27190" },
  { label: "LE VAL DORE (27190)", value: "27190" },
  { label: "PACY SUR EURE (27120)", value: "27120" },
  { label: "PACY SUR EURE (27120)", value: "27120" },
  { label: "PARVILLE (27180)", value: "27180" },
  { label: "PERRIERS SUR ANDELLE (27910)", value: "27910" },
  { label: "PERRUEL (27910)", value: "27910" },
  { label: "PIENCOURT (27230)", value: "27230" },
  { label: "PINTERVILLE (27400)", value: "27400" },
  { label: "PISEUX (27130)", value: "27130" },
  { label: "PITRES (27590)", value: "27590" },
  { label: "LES PLACES (27230)", value: "27230" },
  { label: "PLAINVILLE (27300)", value: "27300" },
  { label: "LE PLANQUAY (27230)", value: "27230" },
  { label: "PLASNES (27300)", value: "27300" },
  { label: "LE PLESSIS GROHAN (27180)", value: "27180" },
  { label: "LE PLESSIS HEBERT (27120)", value: "27120" },
  { label: "LE PLESSIS STE OPPORTUNE (27170)", value: "27170" },
  { label: "PONT AUDEMER (27500)", value: "27500" },
  { label: "PONT AUDEMER (27500)", value: "27500" },
  { label: "PONT AUDEMER (27500)", value: "27500" },
  { label: "PONT AUTHOU (27290)", value: "27290" },
  { label: "PONT DE L ARCHE (27340)", value: "27340" },
  { label: "PONT ST PIERRE (27360)", value: "27360" },
  { label: "PORTE DE SEINE (27100)", value: "27100" },
  { label: "PORTE DE SEINE (27430)", value: "27430" },
  { label: "PORTES (27190)", value: "27190" },
  { label: "PORT MORT (27940)", value: "27940" },
  { label: "POSES (27740)", value: "27740" },
  { label: "LA POTERIE MATHIEU (27560)", value: "27560" },
  { label: "LES PREAUX (27500)", value: "27500" },
  { label: "PRESSAGNY L ORGUEILLEUX (27510)", value: "27510" },
  { label: "PREY (27220)", value: "27220" },
  { label: "PUCHAY (27150)", value: "27150" },
  { label: "PULLAY (27130)", value: "27130" },
  { label: "LA PYLE (27370)", value: "27370" },
  { label: "QUATREMARE (27400)", value: "27400" },
  { label: "QUILLEBEUF SUR SEINE (27680)", value: "27680" },
  { label: "QUITTEBEUF (27110)", value: "27110" },
  { label: "RADEPONT (27380)", value: "27380" },
  { label: "RENNEVILLE (27910)", value: "27910" },
  { label: "REUILLY (27930)", value: "27930" },
  { label: "RICHEVILLE (27420)", value: "27420" },
  { label: "ROMILLY LA PUTHENAYE (27170)", value: "27170" },
  { label: "ROMILLY SUR ANDELLE (27610)", value: "27610" },
  { label: "LA ROQUETTE (27700)", value: "27700" },
  { label: "ROSAY SUR LIEURE (27790)", value: "27790" },
  { label: "ROUGEMONTIERS (27350)", value: "27350" },
  { label: "ROUGE PERRIERS (27110)", value: "27110" },
  { label: "ROUTOT (27350)", value: "27350" },
  { label: "ROUVRAY (27120)", value: "27120" },
  { label: "RUGLES (27250)", value: "27250" },
  { label: "SACQUENVILLE (27930)", value: "27930" },
  { label: "ST AGNAN DE CERNIERES (27390)", value: "27390" },
  { label: "ST ANDRE DE L EURE (27220)", value: "27220" },
  { label: "ST ANTONIN DE SOMMAIRE (27250)", value: "27250" },
  { label: "ST AUBIN D ECROSVILLE (27110)", value: "27110" },
  { label: "ST AUBIN DE SCELLON (27230)", value: "27230" },
  { label: "ST AUBIN DU THENNEY (27270)", value: "27270" },
  { label: "TREIS SANTS EN OUCHE (27270)", value: "27270" },
  { label: "TREIS SANTS EN OUCHE (27300)", value: "27300" },
  { label: "TREIS SANTS EN OUCHE (27300)", value: "27300" },
  { label: "ST AUBIN SUR GAILLON (27600)", value: "27600" },
  { label: "ST AUBIN SUR QUILLEBEUF (27680)", value: "27680" },
  { label: "ST BENOIT DES OMBRES (27450)", value: "27450" },
  { label: "ST CHRISTOPHE SUR AVRE (27820)", value: "27820" },
  { label: "ST CHRISTOPHE SUR CONDE (27450)", value: "27450" },
  { label: "STE COLOMBE LA COMMANDERIE (27110)", value: "27110" },
  { label: "STE COLOMBE PRES VERNON (27950)", value: "27950" },
  { label: "ST CYR DE SALERNE (27800)", value: "27800" },
  { label: "LE VAUDREUIL (27100)", value: "27100" },
  { label: "ST CYR LA CAMPAGNE (27370)", value: "27370" },
  { label: "ST DENIS D AUGERONS (27390)", value: "27390" },
  { label: "ST DENIS DES MONTS (27520)", value: "27520" },
  { label: "ST DENIS LE FERMENT (27140)", value: "27140" },
  { label: "ST DENIS LE FERMENT (27140)", value: "27140" },
  { label: "ST DIDIER DES BOIS (27370)", value: "27370" },
  { label: "ST ELIER (27190)", value: "27190" },
  { label: "ST ELOI DE FOURQUES (27800)", value: "27800" },
  { label: "ST ETIENNE DU VAUVRAY (27430)", value: "27430" },
  { label: "ST ETIENNE L ALLIER (27450)", value: "27450" },
  { label: "ST ETIENNE SOUS BAILLEUL (27920)", value: "27920" },
  { label: "STE GENEVIEVE LES GASNY (27620)", value: "27620" },
  { label: "LE MESNIL ST JEAN (27560)", value: "27560" },
  { label: "LE MESNIL ST JEAN (27560)", value: "27560" },
  { label: "ST GEORGES DU VIEVRE (27450)", value: "27450" },
  { label: "ST GEORGES MOTEL (27710)", value: "27710" },
  { label: "ST GERMAIN DE FRESNEY (27220)", value: "27220" },
  { label: "ST GERMAIN DE PASQUIER (27370)", value: "27370" },
  { label: "ST GERMAIN DES ANGLES (27930)", value: "27930" },
  { label: "ST GERMAIN LA CAMPAGNE (27230)", value: "27230" },
  { label: "ST GERMAIN SUR AVRE (27320)", value: "27320" },
  { label: "ST GREGOIRE DU VIEVRE (27450)", value: "27450" },
  { label: "ST JEAN DU THENNEY (27270)", value: "27270" },
  { label: "ST JULIEN DE LA LIEGUE (27600)", value: "27600" },
  { label: "LA CHAPELLE LONGUEVILLE (27950)", value: "27950" },
  { label: "LA CHAPELLE LONGUEVILLE (27950)", value: "27950" },
  { label: "LA CHAPELLE LONGUEVILLE (27950)", value: "27950" },
  { label: "ST LAURENT DES BOIS (27220)", value: "27220" },
  { label: "ST LAURENT DU TENCEMENT (27390)", value: "27390" },
  { label: "ST LEGER DE ROTES (27300)", value: "27300" },
  { label: "ST LEGER DU GENNETEY (27520)", value: "27520" },
  { label: "ST LUC (27930)", value: "27930" },
  { label: "ST MACLOU (27210)", value: "27210" },
  { label: "ST MARCEL (27950)", value: "27950" },
  { label: "ST MARDS DE BLACARVILLE (27500)", value: "27500" },
  { label: "ST MARDS DE FRESNE (27230)", value: "27230" },
  { label: "LE LESME (27160)", value: "27160" },
  { label: "LE LESME (27160)", value: "27160" },
  { label: "STE MARIE DE VATIMESNIL (27150)", value: "27150" },
  { label: "STE MARTHE (27190)", value: "27190" },
  { label: "ST MARTIN DU TILLEUL (27300)", value: "27300" },
  { label: "ST MARTIN LA CAMPAGNE (27930)", value: "27930" },
  { label: "ST MARTIN ST FIRMIN (27450)", value: "27450" },
  { label: "ST MESLIN DU BOSC (27370)", value: "27370" },
  { label: "STE OPPORTUNE DU BOSC (27110)", value: "27110" },
  { label: "STE OPPORTUNE LA MARE (27680)", value: "27680" },
  { label: "STE MARIE D ATTEZ (27160)", value: "27160" },
  { label: "STE MARIE D ATTEZ (27160)", value: "27160" },
  { label: "STE MARIE D ATTEZ (27160)", value: "27160" },
  { label: "ST OUEN DE PONTCHEUIL (27370)", value: "27370" },
  { label: "ST OUEN DE THOUBERVILLE (27310)", value: "27310" },
  { label: "ST OUEN DU TILLEUL (27670)", value: "27670" },
  { label: "ST PAUL DE FOURQUES (27800)", value: "27800" },
  { label: "ST PHILBERT SUR BOISSEY (27520)", value: "27520" },
  { label: "ST PHILBERT SUR RISLE (27290)", value: "27290" },
  { label: "ST PIERRE DE BAILLEUL (27920)", value: "27920" },
  { label: "ST PIERRE DE CERNIERES (27390)", value: "27390" },
  { label: "ST PIERRE DE CORMEILLES (27260)", value: "27260" },
  { label: "ST PIERRE DE SALERNE (27800)", value: "27800" },
  { label: "ST PIERRE DES FLEURS (27370)", value: "27370" },
  { label: "ST PIERRE DES IFS (27450)", value: "27450" },
  { label: "ST PIERRE DU BOSGUERARD (27370)", value: "27370" },
  { label: "ST PIERRE DU VAL (27210)", value: "27210" },
  { label: "ST PIERRE DU VAUVRAY (27430)", value: "27430" },
  { label: "ST PIERRE LA GARENNE (27600)", value: "27600" },
  { label: "ST SAMSON DE LA ROQUE (27680)", value: "27680" },
  { label: "ST SEBASTIEN DE MORSENT (27180)", value: "27180" },
  { label: "ST SIMEON (27560)", value: "27560" },
  { label: "ST SULPICE DE GRIMBOUVILLE (27210)", value: "27210" },
  { label: "ST SYLVESTRE DE CORMEILLES (27260)", value: "27260" },
  { label: "ST SYMPHORIEN (27500)", value: "27500" },
  { label: "ST VICTOR DE CHRETIENVILLE (27300)", value: "27300" },
  { label: "ST VICTOR D EPINE (27800)", value: "27800" },
  { label: "ST VICTOR SUR AVRE (27130)", value: "27130" },
  { label: "ST VIGOR (27930)", value: "27930" },
  { label: "ST VINCENT DES BOIS (27950)", value: "27950" },
  { label: "ST VINCENT DU BOULAY (27230)", value: "27230" },
  { label: "SANCOURT (27150)", value: "27150" },
  { label: "SASSEY (27930)", value: "27930" },
  { label: "LA SAUSSAYE (27370)", value: "27370" },
  { label: "SAUSSAY LA CAMPAGNE (27150)", value: "27150" },
  { label: "SEBECOURT (27190)", value: "27190" },
  { label: "SELLES (27500)", value: "27500" },
  { label: "SEREZ (27220)", value: "27220" },
  { label: "SERQUIGNY (27470)", value: "27470" },
  { label: "SURTAUVILLE (27400)", value: "27400" },
  { label: "SURVILLE (27400)", value: "27400" },
  { label: "SUZAY (27420)", value: "27420" },
  { label: "LE THEIL NOLENT (27230)", value: "27230" },
  { label: "THIBERVILLE (27230)", value: "27230" },
  { label: "THIBOUVILLE (27800)", value: "27800" },
  { label: "THIERVILLE (27290)", value: "27290" },
  { label: "LE THIL (27150)", value: "27150" },
  { label: "LES THILLIERS EN VEXIN (27420)", value: "27420" },
  { label: "LE THUIT (27700)", value: "27700" },
  { label: "LE THUIT DE L OISON (27370)", value: "27370" },
  { label: "LE THUIT DE L OISON (27370)", value: "27370" },
  { label: "LE THUIT DE L OISON (27370)", value: "27370" },
  { label: "TILLEUL DAME AGNES (27170)", value: "27170" },
  { label: "LE TILLEUL LAMBERT (27110)", value: "27110" },
  { label: "TILLIERES SUR AVRE (27570)", value: "27570" },
  { label: "TILLY (27510)", value: "27510" },
  { label: "TOCQUEVILLE (27500)", value: "27500" },
  { label: "LE TORPT (27210)", value: "27210" },
  { label: "TOUFFREVILLE (27440)", value: "27440" },
  { label: "TOURNEDOS BOIS HUBERT (27180)", value: "27180" },
  { label: "TOURNEVILLE (27930)", value: "27930" },
  { label: "TOURVILLE LA CAMPAGNE (27370)", value: "27370" },
  { label: "TOURVILLE SUR PONT AUDEMER (27500)", value: "27500" },
  { label: "TOUTAINVILLE (27500)", value: "27500" },
  { label: "LE TREMBLAY OMONVILLE (27110)", value: "27110" },
  { label: "LA TRINITE (27930)", value: "27930" },
  { label: "LA TRINITE DE REVILLE (27270)", value: "27270" },
  { label: "LA TRINITE DE THOUBERVILLE (27310)", value: "27310" },
  { label: "TRIQUEVILLE (27500)", value: "27500" },
  { label: "LE TRONCQ (27110)", value: "27110" },
  { label: "LE TRONQUAY (27480)", value: "27480" },
  { label: "TROUVILLE LA HAULE (27680)", value: "27680" },
  { label: "LA VACHERIE (27400)", value: "27400" },
  { label: "VALAILLES (27300)", value: "27300" },
  { label: "LE VAL DAVID (27120)", value: "27120" },
  { label: "VALLETOT (27350)", value: "27350" },
  { label: "VANDRIMARE (27380)", value: "27380" },
  { label: "VANNECROCQ (27210)", value: "27210" },
  { label: "VASCOEUIL (27910)", value: "27910" },
  { label: "VATTEVILLE (27430)", value: "27430" },
  { label: "VAUX SUR EURE (27120)", value: "27120" },
  { label: "VENON (27110)", value: "27110" },
  { label: "LES VENTES (27180)", value: "27180" },
  { label: "VERNEUIL D AVRE ET D ITON (27130)", value: "27130" },
  { label: "VERNEUIL D AVRE ET D ITON (27160)", value: "27160" },
  { label: "VERNEUSSES (27390)", value: "27390" },
  { label: "VERNON (27200)", value: "27200" },
  { label: "VESLY (27870)", value: "27870" },
  { label: "VEZILLON (27700)", value: "27700" },
  { label: "LE VIEIL EVREUX (27930)", value: "27930" },
  { label: "LA VIEILLE LYRE (27330)", value: "27330" },
  { label: "LA VIEILLE LYRE (27330)", value: "27330" },
  { label: "VIEUX PORT (27680)", value: "27680" },
  { label: "VILLEGATS (27120)", value: "27120" },
  { label: "VILLERS EN VEXIN (27420)", value: "27420" },
  { label: "VILLERS SUR LE ROULE (27940)", value: "27940" },
  { label: "VILLETTES (27110)", value: "27110" },
  { label: "SYLVAINS LES MOULINS (27240)", value: "27240" },
  { label: "SYLVAINS LES MOULINS (27240)", value: "27240" },
  { label: "SYLVAINS LES MOULINS (27240)", value: "27240" },
  { label: "VILLEZ SOUS BAILLEUL (27950)", value: "27950" },
  { label: "VILLEZ SUR LE NEUBOURG (27110)", value: "27110" },
  { label: "VILLIERS EN DESOEUVRE (27640)", value: "27640" },
  { label: "VIRONVAY (27400)", value: "27400" },
  { label: "VITOT (27110)", value: "27110" },
  { label: "VOISCREVILLE (27520)", value: "27520" },
  { label: "VRAIVILLE (27370)", value: "27370" },
  { label: "VAL DE REUIL (27100)", value: "27100" },
  { label: "ABONDANT (28410)", value: "28410" },
  { label: "ALLAINVILLE (28500)", value: "28500" },
  { label: "ALLONNES (28150)", value: "28150" },
  { label: "ALLUYES (28800)", value: "28800" },
  { label: "AMILLY (28300)", value: "28300" },
  { label: "ANET (28260)", value: "28260" },
  { label: "ARDELLES (28170)", value: "28170" },
  { label: "ARDELU (28700)", value: "28700" },
  { label: "ARGENVILLIERS (28480)", value: "28480" },
  { label: "VALD YERRE (28290)", value: "28290" },
  { label: "VALD YERRE (28290)", value: "28290" },
  { label: "VALD YERRE (28290)", value: "28290" },
  { label: "VALD YERRE (28290)", value: "28290" },
  { label: "VALD YERRE (28290)", value: "28290" },
  { label: "VALD YERRE (28290)", value: "28290" },
  { label: "AUNAY SOUS AUNEAU (28700)", value: "28700" },
  { label: "AUNAY SOUS CRECY (28500)", value: "28500" },
  { label: "AUNEAU BLEURY ST SYMPHORIEN (28700)", value: "28700" },
  { label: "AUNEAU BLEURY ST SYMPHORIEN (28700)", value: "28700" },
  { label: "AUNEAU BLEURY ST SYMPHORIEN (28700)", value: "28700" },
  { label: "LES AUTELS VILLEVILLON (28330)", value: "28330" },
  { label: "AUTHON DU PERCHE (28330)", value: "28330" },
  { label: "AUTHON DU PERCHE (28330)", value: "28330" },
  { label: "BAIGNEAUX (28140)", value: "28140" },
  { label: "BAILLEAU LE PIN (28120)", value: "28120" },
  { label: "BAILLEAU L EVEQUE (28300)", value: "28300" },
  { label: "BAILLEAU ARMENONVILLE (28320)", value: "28320" },
  { label: "BAILLEAU ARMENONVILLE (28320)", value: "28320" },
  { label: "BARJOUVILLE (28630)", value: "28630" },
  { label: "BARMAINVILLE (28310)", value: "28310" },
  { label: "BAUDREVILLE (28310)", value: "28310" },
  { label: "LA BAZOCHE GOUET (28330)", value: "28330" },
  { label: "BAZOCHES EN DUNOIS (28140)", value: "28140" },
  { label: "BAZOCHES LES HAUTES (28140)", value: "28140" },
  { label: "BEAUCHE (28270)", value: "28270" },
  { label: "BEAUMONT LES AUTELS (28480)", value: "28480" },
  { label: "BEAUVILLIERS (28150)", value: "28150" },
  { label: "BELHOMERT GUEHOUVILLE (28240)", value: "28240" },
  { label: "BERCHERES ST GERMAIN (28300)", value: "28300" },
  { label: "BERCHERES ST GERMAIN (28300)", value: "28300" },
  { label: "BERCHERES LES PIERRES (28630)", value: "28630" },
  { label: "BERCHERES SUR VESGRE (28260)", value: "28260" },
  { label: "BEROU LA MULOTIERE (28270)", value: "28270" },
  { label: "BETHONVILLIERS (28330)", value: "28330" },
  { label: "BEVILLE LE COMTE (28700)", value: "28700" },
  { label: "BILLANCELLES (28190)", value: "28190" },
  { label: "BLANDAINVILLE (28120)", value: "28120" },
  { label: "BOISSY EN DROUAIS (28500)", value: "28500" },
  { label: "BOISSY LES PERCHE (28340)", value: "28340" },
  { label: "BOISVILLE LA ST PERE (28150)", value: "28150" },
  { label: "LA BOURDINIERE ST LOUP (28360)", value: "28360" },
  { label: "LA BOURDINIERE ST LOUP (28360)", value: "28360" },
  { label: "BONCE (28150)", value: "28150" },
  { label: "BONCOURT (28260)", value: "28260" },
  { label: "BONNEVAL (28800)", value: "28800" },
  { label: "BOUGLAINVAL (28130)", value: "28130" },
  { label: "LE BOULLAY LES DEUX EGLISES (28170)", value: "28170" },
  { label: "LE BOULLAY MIVOYE (28210)", value: "28210" },
  { label: "LE BOULLAY THIERRY (28210)", value: "28210" },
  { label: "BOUTIGNY PROUAIS (28410)", value: "28410" },
  { label: "BOUTIGNY PROUAIS (28410)", value: "28410" },
  { label: "BOUVILLE (28800)", value: "28800" },
  { label: "BRECHAMPS (28210)", value: "28210" },
  { label: "BREZOLLES (28270)", value: "28270" },
  { label: "BRICONVILLE (28300)", value: "28300" },
  { label: "BROU (28160)", value: "28160" },
  { label: "BROUE (28410)", value: "28410" },
  { label: "BU (28410)", value: "28410" },
  { label: "BULLAINVILLE (28800)", value: "28800" },
  { label: "CERNAY (28120)", value: "28120" },
  { label: "CHALLET (28300)", value: "28300" },
  { label: "CHAMPHOL (28300)", value: "28300" },
  { label: "CHAMPROND EN GATINE (28240)", value: "28240" },
  { label: "CHAMPROND EN PERCHET (28400)", value: "28400" },
  { label: "CHAMPSERU (28700)", value: "28700" },
  { label: "LA CHAPELLE D AUNAINVILLE (28700)", value: "28700" },
  { label: "LA CHAPELLE DU NOYER (28200)", value: "28200" },
  { label: "LA CHAPELLE FORAINVILLIERS (28500)", value: "28500" },
  { label: "LA CHAPELLE FORTIN (28340)", value: "28340" },
  { label: "CHAPELLE GUILLAUME (28330)", value: "28330" },
  { label: "CHAPELLE ROYALE (28290)", value: "28290" },
  { label: "CHARBONNIERES (28330)", value: "28330" },
  { label: "CHARONVILLE (28120)", value: "28120" },
  { label: "CHARPONT (28500)", value: "28500" },
  { label: "CHARTAINVILLIERS (28130)", value: "28130" },
  { label: "CHARTRES (28000)", value: "28000" },
  { label: "CHASSANT (28480)", value: "28480" },
  { label: "CHATAINCOURT (28270)", value: "28270" },
  { label: "CHATEAUDUN (28200)", value: "28200" },
  { label: "CHATEAUNEUF EN THYMERAIS (28170)", value: "28170" },
  { label: "LES CHATELETS (28270)", value: "28270" },
  { label: "LES CHATELLIERS NOTRE DAME (28120)", value: "28120" },
  { label: "CHATENAY (28700)", value: "28700" },
  { label: "CHAUDON (28210)", value: "28210" },
  { label: "CHAUFFOURS (28120)", value: "28120" },
  { label: "LA CHAUSSEE D IVRY (28260)", value: "28260" },
  { label: "CHERISY (28500)", value: "28500" },
  { label: "CHUISNES (28190)", value: "28190" },
  { label: "CINTRAY (28300)", value: "28300" },
  { label: "CLEVILLIERS (28300)", value: "28300" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "CLOYES LES TROIS RIVIERES (28220)", value: "28220" },
  { label: "COLTAINVILLE (28300)", value: "28300" },
  { label: "COMBRES (28480)", value: "28480" },
  { label: "CONIE MOLITARD (28200)", value: "28200" },
  { label: "CORANCEZ (28630)", value: "28630" },
  { label: "CORMAINVILLE (28140)", value: "28140" },
  { label: "LES CORVEES LES YYS (28240)", value: "28240" },
  { label: "LE COUDRAY (28630)", value: "28630" },
  { label: "COUDRAY AU PERCHE (28330)", value: "28330" },
  { label: "COULOMBS (28210)", value: "28210" },
  { label: "COURBEHAYE (28140)", value: "28140" },
  { label: "COURVILLE SUR EURE (28190)", value: "28190" },
  { label: "CRECY COUVE (28500)", value: "28500" },
  { label: "CROISILLES (28210)", value: "28210" },
  { label: "LA CROIX DU PERCHE (28480)", value: "28480" },
  { label: "CRUCEY VILLAGES (28270)", value: "28270" },
  { label: "CRUCEY VILLAGES (28270)", value: "28270" },
  { label: "CRUCEY VILLAGES (28270)", value: "28270" },
  { label: "DAMBRON (28140)", value: "28140" },
  { label: "DAMMARIE (28360)", value: "28360" },
  { label: "DAMPIERRE SOUS BROU (28160)", value: "28160" },
  { label: "DAMPIERRE SUR AVRE (28350)", value: "28350" },
  { label: "DANCY (28800)", value: "28800" },
  { label: "DANGEAU (28160)", value: "28160" },
  { label: "DANGEAU (28160)", value: "28160" },
  { label: "DANGEAU (28160)", value: "28160" },
  { label: "DANGERS (28190)", value: "28190" },
  { label: "DENONVILLE (28700)", value: "28700" },
  { label: "DIGNY (28250)", value: "28250" },
  { label: "DONNEMAIN ST MAMES (28200)", value: "28200" },
  { label: "DREUX (28100)", value: "28100" },
  { label: "DROUE SUR DROUETTE (28230)", value: "28230" },
  { label: "ECLUZELLES (28500)", value: "28500" },
  { label: "ECROSNES (28320)", value: "28320" },
  { label: "EPEAUTROLLES (28120)", value: "28120" },
  { label: "EPERNON (28230)", value: "28230" },
  { label: "ERMENONVILLE LA GRANDE (28120)", value: "28120" },
  { label: "ERMENONVILLE LA PETITE (28120)", value: "28120" },
  { label: "ESCORPAIN (28270)", value: "28270" },
  { label: "LES ETILLEUX (28330)", value: "28330" },
  { label: "FAVEROLLES (28210)", value: "28210" },
  { label: "FAVIERES (28170)", value: "28170" },
  { label: "LE FAVRIL (28190)", value: "28190" },
  { label: "LA FERTE VIDAME (28340)", value: "28340" },
  { label: "FESSANVILLIERS MATTANVILLIERS (28270)", value: "28270" },
  { label: "FLACEY (28800)", value: "28800" },
  { label: "FONTAINE LA GUYON (28190)", value: "28190" },
  { label: "FONTAINE LES RIBOUTS (28170)", value: "28170" },
  { label: "FONTAINE SIMON (28240)", value: "28240" },
  { label: "FONTENAY SUR CONIE (28140)", value: "28140" },
  { label: "FONTENAY SUR EURE (28630)", value: "28630" },
  { label: "LA FRAMBOISIERE (28250)", value: "28250" },
  { label: "FRANCOURVILLE (28700)", value: "28700" },
  { label: "FRAZE (28160)", value: "28160" },
  { label: "FRESNAY LE COMTE (28360)", value: "28360" },
  { label: "FRESNAY LE GILMERT (28300)", value: "28300" },
  { label: "FRESNAY L EVEQUE (28310)", value: "28310" },
  { label: "FRIAIZE (28240)", value: "28240" },
  { label: "FRUNCE (28190)", value: "28190" },
  { label: "GALLARDON (28320)", value: "28320" },
  { label: "GALLARDON (28320)", value: "28320" },
  { label: "GARANCIERES EN BEAUCE (28700)", value: "28700" },
  { label: "GARANCIERES EN DROUAIS (28500)", value: "28500" },
  { label: "GARNAY (28500)", value: "28500" },
  { label: "GAS (28320)", value: "28320" },
  { label: "GASVILLE OISEME (28300)", value: "28300" },
  { label: "LA GAUDAINE (28400)", value: "28400" },
  { label: "LE GAULT ST DENIS (28800)", value: "28800" },
  { label: "GELLAINVILLE (28630)", value: "28630" },
  { label: "GERMAINVILLE (28500)", value: "28500" },
  { label: "GILLES (28260)", value: "28260" },
  { label: "GOHORY (28160)", value: "28160" },
  { label: "GOMMERVILLE (28310)", value: "28310" },
  { label: "GOMMERVILLE (28310)", value: "28310" },
  { label: "GOMMERVILLE (28700)", value: "28700" },
  { label: "GOUILLONS (28310)", value: "28310" },
  { label: "GOUSSAINVILLE (28410)", value: "28410" },
  { label: "GOUSSAINVILLE (28410)", value: "28410" },
  { label: "GUAINVILLE (28260)", value: "28260" },
  { label: "LE GUE DE LONGROI (28700)", value: "28700" },
  { label: "GUILLEVILLE (28310)", value: "28310" },
  { label: "GUILLONVILLE (28140)", value: "28140" },
  { label: "HANCHES (28130)", value: "28130" },
  { label: "HAPPONVILLIERS (28480)", value: "28480" },
  { label: "HAVELU (28410)", value: "28410" },
  { label: "HOUVILLE LA BRANCHE (28700)", value: "28700" },
  { label: "HOUX (28130)", value: "28130" },
  { label: "ILLIERS COMBRAY (28120)", value: "28120" },
  { label: "INTREVILLE (28310)", value: "28310" },
  { label: "JALLANS (28200)", value: "28200" },
  { label: "JANVILLE EN BEAUCE (28310)", value: "28310" },
  { label: "JANVILLE EN BEAUCE (28310)", value: "28310" },
  { label: "JANVILLE EN BEAUCE (28310)", value: "28310" },
  { label: "JANVILLE EN BEAUCE (28310)", value: "28310" },
  { label: "JAUDRAIS (28250)", value: "28250" },
  { label: "JOUY (28300)", value: "28300" },
  { label: "LAMBLORE (28340)", value: "28340" },
  { label: "LANDELLES (28190)", value: "28190" },
  { label: "LAONS (28270)", value: "28270" },
  { label: "LETHUIN (28700)", value: "28700" },
  { label: "LEVAINVILLE (28700)", value: "28700" },
  { label: "LEVES (28300)", value: "28300" },
  { label: "LEVESVILLE LA CHENARD (28310)", value: "28310" },
  { label: "LOGRON (28200)", value: "28200" },
  { label: "LOIGNY LA BATAILLE (28140)", value: "28140" },
  { label: "LORMAYE (28210)", value: "28210" },
  { label: "LA LOUPE (28240)", value: "28240" },
  { label: "LOUVILLE LA CHENARD (28150)", value: "28150" },
  { label: "LOUVILLIERS EN DROUAIS (28500)", value: "28500" },
  { label: "LOUVILLIERS LES PERCHE (28250)", value: "28250" },
  { label: "LUCE (28110)", value: "28110" },
  { label: "LUIGNY (28480)", value: "28480" },
  { label: "LUISANT (28600)", value: "28600" },
  { label: "LUMEAU (28140)", value: "28140" },
  { label: "LUPLANTE (28360)", value: "28360" },
  { label: "LURAY (28500)", value: "28500" },
  { label: "MAGNY (28120)", value: "28120" },
  { label: "MAILLEBOIS (28170)", value: "28170" },
  { label: "MAILLEBOIS (28170)", value: "28170" },
  { label: "MAILLEBOIS (28170)", value: "28170" },
  { label: "MAINTENON (28130)", value: "28130" },
  { label: "MAINVILLIERS (28300)", value: "28300" },
  { label: "MAISONS (28700)", value: "28700" },
  { label: "LA MANCELIERE (28270)", value: "28270" },
  { label: "MANOU (28240)", value: "28240" },
  { label: "MARBOUE (28200)", value: "28200" },
  { label: "MARCHEVILLE (28120)", value: "28120" },
  { label: "MARCHEZAIS (28410)", value: "28410" },
  { label: "ARCISSES (28400)", value: "28400" },
  { label: "ARCISSES (28400)", value: "28400" },
  { label: "ARCISSES (28400)", value: "28400" },
  { label: "MAROLLES LES BUIS (28400)", value: "28400" },
  { label: "MARVILLE MOUTIERS BRULE (28500)", value: "28500" },
  { label: "MEAUCE (28240)", value: "28240" },
  { label: "MEREGLISE (28120)", value: "28120" },
  { label: "MEROUVILLE (28310)", value: "28310" },
  { label: "MESLAY LE GRENET (28120)", value: "28120" },
  { label: "MESLAY LE VIDAME (28360)", value: "28360" },
  { label: "LE MESNIL SIMON (28260)", value: "28260" },
  { label: "LE MESNIL THOMAS (28250)", value: "28250" },
  { label: "MEVOISINS (28130)", value: "28130" },
  { label: "MEZIERES EN DROUAIS (28500)", value: "28500" },
  { label: "MIERMAIGNE (28480)", value: "28480" },
  { label: "MIGNIERES (28630)", value: "28630" },
  { label: "MITTAINVILLIERS VERIGNY (28190)", value: "28190" },
  { label: "MITTAINVILLIERS VERIGNY (28190)", value: "28190" },
  { label: "MOINVILLE LA JEULIN (28700)", value: "28700" },
  { label: "MOLEANS (28200)", value: "28200" },
  { label: "MONDONVILLE ST JEAN (28700)", value: "28700" },
  { label: "MONTBOISSIER (28800)", value: "28800" },
  { label: "MONTHARVILLE (28800)", value: "28800" },
  { label: "MONTIGNY LE CHARTIF (28120)", value: "28120" },
  { label: "MONTIGNY SUR AVRE (28270)", value: "28270" },
  { label: "MONTIREAU (28240)", value: "28240" },
  { label: "MONTLANDON (28240)", value: "28240" },
  { label: "MONTREUIL (28500)", value: "28500" },
  { label: "MORAINVILLE (28700)", value: "28700" },
  { label: "MORANCEZ (28630)", value: "28630" },
  { label: "MORIERS (28800)", value: "28800" },
  { label: "MORVILLIERS (28340)", value: "28340" },
  { label: "MOTTEREAU (28160)", value: "28160" },
  { label: "MOULHARD (28160)", value: "28160" },
  { label: "MOUTIERS (28150)", value: "28150" },
  { label: "NERON (28210)", value: "28210" },
  { label: "NEUVY EN BEAUCE (28310)", value: "28310" },
  { label: "NEUVY EN DUNOIS (28800)", value: "28800" },
  { label: "NOGENT LE PHAYE (28630)", value: "28630" },
  { label: "NOGENT LE ROI (28210)", value: "28210" },
  { label: "NOGENT LE ROI (28210)", value: "28210" },
  { label: "NOGENT LE ROTROU (28400)", value: "28400" },
  { label: "NOGENT SUR EURE (28120)", value: "28120" },
  { label: "NONVILLIERS GRANDHOUX (28120)", value: "28120" },
  { label: "NOTTONVILLE (28140)", value: "28140" },
  { label: "OINVILLE ST LIPHARD (28310)", value: "28310" },
  { label: "OINVILLE SOUS AUNEAU (28700)", value: "28700" },
  { label: "OLLE (28120)", value: "28120" },
  { label: "ORGERES EN BEAUCE (28140)", value: "28140" },
  { label: "ORMOY (28210)", value: "28210" },
  { label: "ORROUER (28190)", value: "28190" },
  { label: "OUARVILLE (28150)", value: "28150" },
  { label: "OUERRE (28500)", value: "28500" },
  { label: "OULINS (28260)", value: "28260" },
  { label: "OYSONVILLE (28700)", value: "28700" },
  { label: "PERONVILLE (28140)", value: "28140" },
  { label: "PIERRES (28130)", value: "28130" },
  { label: "LES PINTHIERES (28210)", value: "28210" },
  { label: "POINVILLE (28310)", value: "28310" },
  { label: "POISVILLIERS (28300)", value: "28300" },
  { label: "PONTGOUIN (28190)", value: "28190" },
  { label: "POUPRY (28140)", value: "28140" },
  { label: "PRASVILLE (28150)", value: "28150" },
  { label: "PRE ST EVROULT (28800)", value: "28800" },
  { label: "PRE ST MARTIN (28800)", value: "28800" },
  { label: "PRUDEMANCHE (28270)", value: "28270" },
  { label: "PRUNAY LE GILLON (28360)", value: "28360" },
  { label: "LA PUISAYE (28250)", value: "28250" },
  { label: "PUISEUX (28170)", value: "28170" },
  { label: "RECLAINVILLE (28150)", value: "28150" },
  { label: "LES RESSUINTES (28340)", value: "28340" },
  { label: "REVERCOURT (28270)", value: "28270" },
  { label: "ROHAIRE (28340)", value: "28340" },
  { label: "ROINVILLE (28700)", value: "28700" },
  { label: "ROUVRAY ST DENIS (28310)", value: "28310" },
  { label: "ROUVRES (28260)", value: "28260" },
  { label: "RUEIL LA GADELIERE (28270)", value: "28270" },
  { label: "ST ANGE ET TORCAY (28170)", value: "28170" },
  { label: "ST ARNOULT DES BOIS (28190)", value: "28190" },
  { label: "ST AUBIN DES BOIS (28300)", value: "28300" },
  { label: "ST AVIT LES GUESPIERES (28120)", value: "28120" },
  { label: "ST BOMER (28330)", value: "28330" },
  { label: "ST CHRISTOPHE (28200)", value: "28200" },
  { label: "VILLEMAURY (28200)", value: "28200" },
  { label: "VILLEMAURY (28200)", value: "28200" },
  { label: "VILLEMAURY (28200)", value: "28200" },
  { label: "VILLEMAURY (28200)", value: "28200" },
  { label: "SAINTIGNY (28480)", value: "28480" },
  { label: "SAINTIGNY (28480)", value: "28480" },
  { label: "STE GEMME MORONVAL (28500)", value: "28500" },
  { label: "ST DENIS DES PUITS (28240)", value: "28240" },
  { label: "ST DENIS LANNERAY (28200)", value: "28200" },
  { label: "ST DENIS LANNERAY (28200)", value: "28200" },
  { label: "ST ELIPH (28240)", value: "28240" },
  { label: "ST EMAN (28120)", value: "28120" },
  { label: "ST GEORGES SUR EURE (28190)", value: "28190" },
  { label: "ST GERMAIN LE GAILLARD (28190)", value: "28190" },
  { label: "ST JEAN DE REBERVILLIERS (28170)", value: "28170" },
  { label: "ST JEAN PIERRE FIXTE (28400)", value: "28400" },
  { label: "ST LAURENT LA GATINE (28210)", value: "28210" },
  { label: "ST LEGER DES AUBEES (28700)", value: "28700" },
  { label: "ST LUBIN DE CRAVANT (28270)", value: "28270" },
  { label: "ST LUBIN DE LA HAYE (28410)", value: "28410" },
  { label: "ST LUBIN DES JONCHERETS (28350)", value: "28350" },
  { label: "ST LUCIEN (28210)", value: "28210" },
  { label: "ST LUPERCE (28190)", value: "28190" },
  { label: "ST MAIXME HAUTERIVE (28170)", value: "28170" },
  { label: "ST MARTIN DE NIGELLES (28130)", value: "28130" },
  { label: "ST MAUR SUR LE LOIR (28800)", value: "28800" },
  { label: "ST MAURICE ST GERMAIN (28240)", value: "28240" },
  { label: "ST OUEN MARCHEFROY (28260)", value: "28260" },
  { label: "ST PIAT (28130)", value: "28130" },
  { label: "ST PREST (28300)", value: "28300" },
  { label: "ST REMY SUR AVRE (28380)", value: "28380" },
  { label: "ST SAUVEUR MARVILLE (28170)", value: "28170" },
  { label: "ST SAUVEUR MARVILLE (28170)", value: "28170" },
  { label: "ST VICTOR DE BUTHON (28240)", value: "28240" },
  { label: "SAINVILLE (28700)", value: "28700" },
  { label: "SANCHEVILLE (28800)", value: "28800" },
  { label: "SANDARVILLE (28120)", value: "28120" },
  { label: "SANTEUIL (28700)", value: "28700" },
  { label: "SANTILLY (28310)", value: "28310" },
  { label: "LA SAUCELLE (28250)", value: "28250" },
  { label: "SAULNIERES (28500)", value: "28500" },
  { label: "SAUMERAY (28800)", value: "28800" },
  { label: "SAUSSAY (28260)", value: "28260" },
  { label: "SENANTES (28210)", value: "28210" },
  { label: "SENONCHES (28250)", value: "28250" },
  { label: "SENONCHES (28250)", value: "28250" },
  { label: "SENONCHES (28250)", value: "28250" },
  { label: "SERAZEREUX (28170)", value: "28170" },
  { label: "SERVILLE (28410)", value: "28410" },
  { label: "SOREL MOUSSEL (28260)", value: "28260" },
  { label: "SOUANCE AU PERCHE (28400)", value: "28400" },
  { label: "SOULAIRES (28130)", value: "28130" },
  { label: "SOURS (28630)", value: "28630" },
  { label: "TERMINIERS (28140)", value: "28140" },
  { label: "THEUVILLE (28150)", value: "28150" },
  { label: "THEUVILLE (28360)", value: "28360" },
  { label: "LE THIEULIN (28240)", value: "28240" },
  { label: "THIMERT GATELLES (28170)", value: "28170" },
  { label: "THIMERT GATELLES (28170)", value: "28170" },
  { label: "THIRON GARDAIS (28480)", value: "28480" },
  { label: "THIVARS (28630)", value: "28630" },
  { label: "THIVILLE (28200)", value: "28200" },
  { label: "TILLAY LE PENEUX (28140)", value: "28140" },
  { label: "TOURY (28310)", value: "28310" },
  { label: "TRANCRAINVILLE (28310)", value: "28310" },
  { label: "TREMBLAY LES VILLAGES (28170)", value: "28170" },
  { label: "TREMBLAY LES VILLAGES (28170)", value: "28170" },
  { label: "TREMBLAY LES VILLAGES (28170)", value: "28170" },
  { label: "TREMBLAY LES VILLAGES (28170)", value: "28170" },
  { label: "TREMBLAY LES VILLAGES (28170)", value: "28170" },
  { label: "TREMBLAY LES VILLAGES (28170)", value: "28170" },
  { label: "TREON (28500)", value: "28500" },
  { label: "TRIZAY COUTRETOT ST SERGE (28400)", value: "28400" },
  { label: "TRIZAY LES BONNEVAL (28800)", value: "28800" },
  { label: "UMPEAU (28700)", value: "28700" },
  { label: "UNVERRE (28160)", value: "28160" },
  { label: "VARIZE (28140)", value: "28140" },
  { label: "VAUPILLON (28240)", value: "28240" },
  { label: "VER LES CHARTRES (28630)", value: "28630" },
  { label: "VERNOUILLET (28500)", value: "28500" },
  { label: "VERT EN DROUAIS (28500)", value: "28500" },
  { label: "EOLE EN BEAUCE (28150)", value: "28150" },
  { label: "EOLE EN BEAUCE (28150)", value: "28150" },
  { label: "EOLE EN BEAUCE (28150)", value: "28150" },
  { label: "EOLE EN BEAUCE (28150)", value: "28150" },
  { label: "EOLE EN BEAUCE (28150)", value: "28150" },
  { label: "VICHERES (28480)", value: "28480" },
  { label: "VIERVILLE (28700)", value: "28700" },
  { label: "VIEUVICQ (28120)", value: "28120" },
  { label: "VILLAMPUY (28200)", value: "28200" },
  { label: "VILLARS (28150)", value: "28150" },
  { label: "VILLEBON (28190)", value: "28190" },
  { label: "VILLEMEUX SUR EURE (28210)", value: "28210" },
  { label: "VILLIERS LE MORHIER (28130)", value: "28130" },
  { label: "VILLIERS ST ORIEN (28800)", value: "28800" },
  { label: "VITRAY EN BEAUCE (28360)", value: "28360" },
  { label: "VOISE (28700)", value: "28700" },
  { label: "LES VILLAGES VOVEENS (28150)", value: "28150" },
  { label: "LES VILLAGES VOVEENS (28150)", value: "28150" },
  { label: "LES VILLAGES VOVEENS (28150)", value: "28150" },
  { label: "LES VILLAGES VOVEENS (28150)", value: "28150" },
  { label: "YERMENONVILLE (28130)", value: "28130" },
  { label: "YEVRES (28160)", value: "28160" },
  { label: "YMERAY (28320)", value: "28320" },
  { label: "YMONVILLE (28150)", value: "28150" },
  { label: "ARGOL (29560)", value: "29560" },
  { label: "ARZANO (29300)", value: "29300" },
  { label: "AUDIERNE (29770)", value: "29770" },
  { label: "AUDIERNE (29770)", value: "29770" },
  { label: "BANNALEC (29380)", value: "29380" },
  { label: "BAYE (29300)", value: "29300" },
  { label: "BENODET (29950)", value: "29950" },
  { label: "BERRIEN (29690)", value: "29690" },
  { label: "BEUZEC CAP SIZUN (29790)", value: "29790" },
  { label: "BODILIS (29400)", value: "29400" },
  { label: "BOHARS (29820)", value: "29820" },
  { label: "BOLAZEC (29640)", value: "29640" },
  { label: "BOTMEUR (29690)", value: "29690" },
  { label: "BOTSORHEL (29650)", value: "29650" },
  { label: "BOURG BLANC (29860)", value: "29860" },
  { label: "BRASPARTS (29190)", value: "29190" },
  { label: "BRELES (29810)", value: "29810" },
  { label: "BRENNILIS (29690)", value: "29690" },
  { label: "BREST (29200)", value: "29200" },
  { label: "BRIEC (29510)", value: "29510" },
  { label: "PLOUNEOUR BRIGNOGAN PLAGES (29890)", value: "29890" },
  { label: "PLOUNEOUR BRIGNOGAN PLAGES (29890)", value: "29890" },
  { label: "CAMARET SUR MER (29570)", value: "29570" },
  { label: "CARANTEC (29660)", value: "29660" },
  { label: "CARHAIX PLOUGUER (29270)", value: "29270" },
  { label: "CAST (29150)", value: "29150" },
  { label: "CHATEAULIN (29150)", value: "29150" },
  { label: "CHATEAUNEUF DU FAOU (29520)", value: "29520" },
  { label: "CLEDEN CAP SIZUN (29770)", value: "29770" },
  { label: "CLEDEN POHER (29270)", value: "29270" },
  { label: "CLEDER (29233)", value: "29233" },
  { label: "CLOHARS CARNOET (29360)", value: "29360" },
  { label: "CLOHARS CARNOET (29360)", value: "29360" },
  { label: "CLOHARS FOUESNANT (29950)", value: "29950" },
  { label: "LE CLOITRE PLEYBEN (29190)", value: "29190" },
  { label: "LE CLOITRE ST THEGONNEC (29410)", value: "29410" },
  { label: "COAT MEAL (29870)", value: "29870" },
  { label: "COLLOREC (29530)", value: "29530" },
  { label: "COMBRIT (29120)", value: "29120" },
  { label: "COMMANA (29450)", value: "29450" },
  { label: "CONCARNEAU (29900)", value: "29900" },
  { label: "LE CONQUET (29217)", value: "29217" },
  { label: "CORAY (29370)", value: "29370" },
  { label: "CROZON (29160)", value: "29160" },
  { label: "CROZON (29160)", value: "29160" },
  { label: "CROZON (29160)", value: "29160" },
  { label: "DAOULAS (29460)", value: "29460" },
  { label: "DINEAULT (29150)", value: "29150" },
  { label: "DIRINON (29460)", value: "29460" },
  { label: "DOUARNENEZ (29100)", value: "29100" },
  { label: "DOUARNENEZ (29100)", value: "29100" },
  { label: "LE DRENNEC (29860)", value: "29860" },
  { label: "EDERN (29510)", value: "29510" },
  { label: "ELLIANT (29370)", value: "29370" },
  { label: "ERGUE GABERIC (29500)", value: "29500" },
  { label: "LE FAOU (29590)", value: "29590" },
  { label: "LA FEUILLEE (29690)", value: "29690" },
  { label: "LE FOLGOET (29260)", value: "29260" },
  { label: "LA FOREST LANDERNEAU (29800)", value: "29800" },
  { label: "LA FORET FOUESNANT (29940)", value: "29940" },
  { label: "FOUESNANT (29170)", value: "29170" },
  { label: "FOUESNANT (29170)", value: "29170" },
  { label: "GARLAN (29610)", value: "29610" },
  { label: "GOUESNACH (29950)", value: "29950" },
  { label: "GOUESNOU (29850)", value: "29850" },
  { label: "GOUEZEC (29190)", value: "29190" },
  { label: "GOULIEN (29770)", value: "29770" },
  { label: "GOULVEN (29890)", value: "29890" },
  { label: "GOURLIZON (29710)", value: "29710" },
  { label: "GUENGAT (29180)", value: "29180" },
  { label: "GUERLESQUIN (29650)", value: "29650" },
  { label: "GUICLAN (29410)", value: "29410" },
  { label: "GUILERS (29820)", value: "29820" },
  { label: "GUILER SUR GOYEN (29710)", value: "29710" },
  { label: "GUILLIGOMARC H (29300)", value: "29300" },
  { label: "GUILVINEC (29730)", value: "29730" },
  { label: "GUIMAEC (29620)", value: "29620" },
  { label: "GUIMILIAU (29400)", value: "29400" },
  { label: "GUIPAVAS (29490)", value: "29490" },
  { label: "MILIZAC GUIPRONVEL (29290)", value: "29290" },
  { label: "MILIZAC GUIPRONVEL (29290)", value: "29290" },
  { label: "GUISSENY (29880)", value: "29880" },
  { label: "HANVEC (29460)", value: "29460" },
  { label: "HENVIC (29670)", value: "29670" },
  { label: "HOPITAL CAMFROUT (29460)", value: "29460" },
  { label: "HUELGOAT (29690)", value: "29690" },
  { label: "ILE DE BATZ (29253)", value: "29253" },
  { label: "ILE DE SEIN (29990)", value: "29990" },
  { label: "ILE MOLENE (29259)", value: "29259" },
  { label: "ILE TUDY (29980)", value: "29980" },
  { label: "IRVILLAC (29460)", value: "29460" },
  { label: "LE JUCH (29100)", value: "29100" },
  { label: "KERGLOFF (29270)", value: "29270" },
  { label: "KERLAZ (29100)", value: "29100" },
  { label: "KERLOUAN (29890)", value: "29890" },
  { label: "KERNILIS (29260)", value: "29260" },
  { label: "KERNOUES (29260)", value: "29260" },
  { label: "KERSAINT PLABENNEC (29860)", value: "29860" },
  { label: "LAMPAUL GUIMILIAU (29400)", value: "29400" },
  { label: "LAMPAUL PLOUARZEL (29810)", value: "29810" },
  { label: "LAMPAUL PLOUDALMEZEAU (29830)", value: "29830" },
  { label: "LANARVILY (29260)", value: "29260" },
  { label: "LANDEDA (29870)", value: "29870" },
  { label: "LANDEDA (29870)", value: "29870" },
  { label: "LANDELEAU (29530)", value: "29530" },
  { label: "LANDERNEAU (29800)", value: "29800" },
  { label: "LANDEVENNEC (29560)", value: "29560" },
  { label: "LANDIVISIAU (29400)", value: "29400" },
  { label: "LANDREVARZEC (29510)", value: "29510" },
  { label: "LANDUDAL (29510)", value: "29510" },
  { label: "LANDUDEC (29710)", value: "29710" },
  { label: "LANDUNVEZ (29840)", value: "29840" },
  { label: "LANGOLEN (29510)", value: "29510" },
  { label: "LANHOUARNEAU (29430)", value: "29430" },
  { label: "LANILDUT (29840)", value: "29840" },
  { label: "LANMEUR (29620)", value: "29620" },
  { label: "LANNEANOU (29640)", value: "29640" },
  { label: "LANNEDERN (29190)", value: "29190" },
  { label: "LANNEUFFRET (29400)", value: "29400" },
  { label: "LANNILIS (29870)", value: "29870" },
  { label: "LANRIVOARE (29290)", value: "29290" },
  { label: "LANVEOC (29160)", value: "29160" },
  { label: "LAZ (29520)", value: "29520" },
  { label: "LENNON (29190)", value: "29190" },
  { label: "LESNEVEN (29260)", value: "29260" },
  { label: "LEUHAN (29390)", value: "29390" },
  { label: "LOC BREVALAIRE (29260)", value: "29260" },
  { label: "LOC EGUINER (29400)", value: "29400" },
  { label: "LOCMARIA PLOUZANE (29280)", value: "29280" },
  { label: "LOCMELAR (29400)", value: "29400" },
  { label: "LOCQUENOLE (29670)", value: "29670" },
  { label: "LOCQUIREC (29241)", value: "29241" },
  { label: "LOCRONAN (29180)", value: "29180" },
  { label: "LOCTUDY (29750)", value: "29750" },
  { label: "LOCUNOLE (29310)", value: "29310" },
  { label: "LOGONNA DAOULAS (29460)", value: "29460" },
  { label: "LOPEREC (29590)", value: "29590" },
  { label: "LOPERHET (29470)", value: "29470" },
  { label: "LOQUEFFRET (29530)", value: "29530" },
  { label: "LOTHEY (29190)", value: "29190" },
  { label: "MAHALON (29790)", value: "29790" },
  { label: "LA MARTYRE (29800)", value: "29800" },
  { label: "CONFORT MEILARS (29790)", value: "29790" },
  { label: "MELGVEN (29140)", value: "29140" },
  { label: "MELLAC (29300)", value: "29300" },
  { label: "MESPAUL (29420)", value: "29420" },
  { label: "MOELAN SUR MER (29350)", value: "29350" },
  { label: "MOELAN SUR MER (29350)", value: "29350" },
  { label: "MORLAIX (29600)", value: "29600" },
  { label: "MOTREFF (29270)", value: "29270" },
  { label: "NEVEZ (29920)", value: "29920" },
  { label: "NEVEZ (29920)", value: "29920" },
  { label: "OUESSANT (29242)", value: "29242" },
  { label: "PENCRAN (29800)", value: "29800" },
  { label: "PENMARCH (29760)", value: "29760" },
  { label: "PENMARCH (29760)", value: "29760" },
  { label: "PEUMERIT (29710)", value: "29710" },
  { label: "PLABENNEC (29860)", value: "29860" },
  { label: "PLEUVEN (29170)", value: "29170" },
  { label: "PLEYBEN (29190)", value: "29190" },
  { label: "PLEYBER CHRIST (29410)", value: "29410" },
  { label: "PLOBANNALEC LESCONIL (29740)", value: "29740" },
  { label: "PLOBANNALEC LESCONIL (29740)", value: "29740" },
  { label: "PLOEVEN (29550)", value: "29550" },
  { label: "PLOGASTEL ST GERMAIN (29710)", value: "29710" },
  { label: "PLOGOFF (29770)", value: "29770" },
  { label: "PLOGONNEC (29180)", value: "29180" },
  { label: "PLOMELIN (29700)", value: "29700" },
  { label: "PLOMEUR (29120)", value: "29120" },
  { label: "PLOMODIERN (29550)", value: "29550" },
  { label: "PLONEIS (29710)", value: "29710" },
  { label: "PLONEOUR LANVERN (29720)", value: "29720" },
  { label: "PLONEVEZ DU FAOU (29530)", value: "29530" },
  { label: "PLONEVEZ PORZAY (29550)", value: "29550" },
  { label: "PLOUARZEL (29810)", value: "29810" },
  { label: "PLOUDALMEZEAU (29830)", value: "29830" },
  { label: "PLOUDALMEZEAU (29830)", value: "29830" },
  { label: "PLOUDANIEL (29260)", value: "29260" },
  { label: "PLOUDIRY (29800)", value: "29800" },
  { label: "PLOUEDERN (29800)", value: "29800" },
  { label: "PLOUEGAT GUERAND (29620)", value: "29620" },
  { label: "PLOUEGAT MOYSAN (29650)", value: "29650" },
  { label: "PLOUENAN (29420)", value: "29420" },
  { label: "PLOUESCAT (29430)", value: "29430" },
  { label: "PLOUEZOC H (29252)", value: "29252" },
  { label: "PLOUGAR (29440)", value: "29440" },
  { label: "PLOUGASNOU (29630)", value: "29630" },
  { label: "PLOUGASNOU (29630)", value: "29630" },
  { label: "PLOUGASTEL DAOULAS (29470)", value: "29470" },
  { label: "PLOUGONVELIN (29217)", value: "29217" },
  { label: "PLOUGONVELIN (29217)", value: "29217" },
  { label: "PLOUGONVEN (29640)", value: "29640" },
  { label: "PLOUGOULM (29250)", value: "29250" },
  { label: "PLOUGOURVEST (29400)", value: "29400" },
  { label: "PLOUGUERNEAU (29880)", value: "29880" },
  { label: "PLOUGUIN (29830)", value: "29830" },
  { label: "PLOUHINEC (29780)", value: "29780" },
  { label: "PLOUIDER (29260)", value: "29260" },
  { label: "PLOUIGNEAU (29610)", value: "29610" },
  { label: "PLOUIGNEAU (29650)", value: "29650" },
  { label: "PLOUMOGUER (29810)", value: "29810" },
  { label: "PLOUNEOUR MENEZ (29410)", value: "29410" },
  { label: "PLOUNEVENTER (29400)", value: "29400" },
  { label: "PLOUNEVEZEL (29270)", value: "29270" },
  { label: "PLOUNEVEZ LOCHRIST (29430)", value: "29430" },
  { label: "PLOURIN LES MORLAIX (29600)", value: "29600" },
  { label: "PLOURIN (29830)", value: "29830" },
  { label: "PLOUVIEN (29860)", value: "29860" },
  { label: "PLOUVORN (29420)", value: "29420" },
  { label: "PLOUYE (29690)", value: "29690" },
  { label: "PLOUZANE (29280)", value: "29280" },
  { label: "PLOUZEVEDE (29440)", value: "29440" },
  { label: "PLOVAN (29720)", value: "29720" },
  { label: "PLOZEVET (29710)", value: "29710" },
  { label: "PLUGUFFAN (29700)", value: "29700" },
  { label: "PONT AVEN (29930)", value: "29930" },
  { label: "PONT CROIX (29790)", value: "29790" },
  { label: "PONT L ABBE (29120)", value: "29120" },
  { label: "PORSPODER (29840)", value: "29840" },
  { label: "PORSPODER (29840)", value: "29840" },
  { label: "PORT LAUNAY (29150)", value: "29150" },
  { label: "POULDERGAT (29100)", value: "29100" },
  { label: "POULDREUZIC (29710)", value: "29710" },
  { label: "POULLAN SUR MER (29100)", value: "29100" },
  { label: "POULLAOUEN (29246)", value: "29246" },
  { label: "POULLAOUEN (29690)", value: "29690" },
  { label: "PRIMELIN (29770)", value: "29770" },
  { label: "QUEMENEVEN (29180)", value: "29180" },
  { label: "QUERRIEN (29310)", value: "29310" },
  { label: "QUIMPER (29000)", value: "29000" },
  { label: "QUIMPERLE (29300)", value: "29300" },
  { label: "REDENE (29300)", value: "29300" },
  { label: "LE RELECQ KERHUON (29480)", value: "29480" },
  { label: "RIEC SUR BELON (29340)", value: "29340" },
  { label: "LA ROCHE MAURICE (29800)", value: "29800" },
  { label: "ROSCANVEL (29570)", value: "29570" },
  { label: "ROSCOFF (29680)", value: "29680" },
  { label: "ROSNOEN (29590)", value: "29590" },
  { label: "ROSPORDEN (29140)", value: "29140" },
  { label: "ROSPORDEN (29140)", value: "29140" },
  { label: "ST COULITZ (29150)", value: "29150" },
  { label: "ST DERRIEN (29440)", value: "29440" },
  { label: "ST DIVY (29800)", value: "29800" },
  { label: "ST ELOY (29460)", value: "29460" },
  { label: "ST EVARZEC (29170)", value: "29170" },
  { label: "ST FREGANT (29260)", value: "29260" },
  { label: "ST GOAZEC (29520)", value: "29520" },
  { label: "ST HERNIN (29270)", value: "29270" },
  { label: "ST JEAN DU DOIGT (29630)", value: "29630" },
  { label: "ST JEAN TROLIMON (29120)", value: "29120" },
  { label: "ST MARTIN DES CHAMPS (29600)", value: "29600" },
  { label: "ST MEEN (29260)", value: "29260" },
  { label: "ST NIC (29550)", value: "29550" },
  { label: "ST PABU (29830)", value: "29830" },
  { label: "ST POL DE LEON (29250)", value: "29250" },
  { label: "ST RENAN (29290)", value: "29290" },
  { label: "ST RIVOAL (29190)", value: "29190" },
  { label: "ST SAUVEUR (29400)", value: "29400" },
  { label: "ST SEGAL (29590)", value: "29590" },
  { label: "ST SERVAIS (29400)", value: "29400" },
  { label: "STE SEVE (29600)", value: "29600" },
  { label: "ST THEGONNEC LOC EGUINER (29410)", value: "29410" },
  { label: "ST THEGONNEC LOC EGUINER (29410)", value: "29410" },
  { label: "ST THOIS (29520)", value: "29520" },
  { label: "ST THONAN (29800)", value: "29800" },
  { label: "ST THURIEN (29380)", value: "29380" },
  { label: "ST URBAIN (29800)", value: "29800" },
  { label: "ST VOUGAY (29440)", value: "29440" },
  { label: "ST YVI (29140)", value: "29140" },
  { label: "SANTEC (29250)", value: "29250" },
  { label: "SCAER (29390)", value: "29390" },
  { label: "SCRIGNAC (29640)", value: "29640" },
  { label: "SIBIRIL (29250)", value: "29250" },
  { label: "SIZUN (29450)", value: "29450" },
  { label: "SPEZET (29540)", value: "29540" },
  { label: "TAULE (29670)", value: "29670" },
  { label: "TELGRUC SUR MER (29560)", value: "29560" },
  { label: "TOURCH (29140)", value: "29140" },
  { label: "TREBABU (29217)", value: "29217" },
  { label: "TREFFIAGAT (29730)", value: "29730" },
  { label: "TREFLAOUENAN (29440)", value: "29440" },
  { label: "TREFLEVENEZ (29800)", value: "29800" },
  { label: "TREFLEZ (29430)", value: "29430" },
  { label: "TREGARANTEC (29260)", value: "29260" },
  { label: "TREGARVAN (29560)", value: "29560" },
  { label: "TREGLONOU (29870)", value: "29870" },
  { label: "TREGOUREZ (29970)", value: "29970" },
  { label: "TREGUENNEC (29720)", value: "29720" },
  { label: "TREGUNC (29910)", value: "29910" },
  { label: "LE TREHOU (29450)", value: "29450" },
  { label: "TREMAOUEZAN (29800)", value: "29800" },
  { label: "TREMEOC (29120)", value: "29120" },
  { label: "TREMEVEN (29300)", value: "29300" },
  { label: "TREOGAT (29720)", value: "29720" },
  { label: "TREOUERGAT (29290)", value: "29290" },
  { label: "LE TREVOUX (29380)", value: "29380" },
  { label: "TREZILIDE (29440)", value: "29440" },
  { label: "PONT DE BUIS LES QUIMERCH (29590)", value: "29590" },
  { label: "PONT DE BUIS LES QUIMERCH (29590)", value: "29590" },
  { label: "AFA (20167)", value: "20167" },
  { label: "AJACCIO (20000)", value: "20000" },
  { label: "AJACCIO (20090)", value: "20090" },
  { label: "AJACCIO (20167)", value: "20167" },
  { label: "ALATA (20167)", value: "20167" },
  { label: "ALBITRECCIA (20128)", value: "20128" },
  { label: "ALBITRECCIA (20166)", value: "20166" },
  { label: "ALTAGENE (20112)", value: "20112" },
  { label: "AMBIEGNA (20151)", value: "20151" },
  { label: "APPIETTO (20167)", value: "20167" },
  { label: "ARBELLARA (20110)", value: "20110" },
  { label: "ARBORI (20160)", value: "20160" },
  { label: "ARGIUSTA MORICCIO (20140)", value: "20140" },
  { label: "ARRO (20151)", value: "20151" },
  { label: "AULLENE (20116)", value: "20116" },
  { label: "AZILONE AMPAZA (20190)", value: "20190" },
  { label: "AZZANA (20121)", value: "20121" },
  { label: "BALOGNA (20160)", value: "20160" },
  { label: "BASTELICA (20119)", value: "20119" },
  { label: "BASTELICACCIA (20129)", value: "20129" },
  { label: "BELVEDERE CAMPOMORO (20110)", value: "20110" },
  { label: "BILIA (20100)", value: "20100" },
  { label: "BOCOGNANO (20136)", value: "20136" },
  { label: "BONIFACIO (20169)", value: "20169" },
  { label: "CALCATOGGIO (20111)", value: "20111" },
  { label: "CAMPO (20142)", value: "20142" },
  { label: "CANNELLE (20151)", value: "20151" },
  { label: "CARBINI (20170)", value: "20170" },
  { label: "CARBUCCIA (20133)", value: "20133" },
  { label: "CARDO TORGIA (20190)", value: "20190" },
  { label: "CARGESE (20130)", value: "20130" },
  { label: "CARGIACA (20164)", value: "20164" },
  { label: "CASAGLIONE (20111)", value: "20111" },
  { label: "CASALABRIVA (20140)", value: "20140" },
  { label: "CAURO (20117)", value: "20117" },
  { label: "CIAMANNACCE (20134)", value: "20134" },
  { label: "COGGIA (20118)", value: "20118" },
  { label: "COGGIA (20160)", value: "20160" },
  { label: "COGNOCOLI MONTICCHI (20123)", value: "20123" },
  { label: "COGNOCOLI MONTICCHI (20166)", value: "20166" },
  { label: "CONCA (20135)", value: "20135" },
  { label: "CORRANO (20168)", value: "20168" },
  { label: "COTI CHIAVARI (20138)", value: "20138" },
  { label: "COZZANO (20148)", value: "20148" },
  { label: "CRISTINACCE (20126)", value: "20126" },
  { label: "CUTTOLI CORTICCHIATO (20167)", value: "20167" },
  { label: "ECCICA SUARELLA (20117)", value: "20117" },
  { label: "EVISA (20126)", value: "20126" },
  { label: "FIGARI (20114)", value: "20114" },
  { label: "FOCE (20100)", value: "20100" },
  { label: "FORCIOLO (20190)", value: "20190" },
  { label: "FOZZANO (20143)", value: "20143" },
  { label: "FRASSETO (20157)", value: "20157" },
  { label: "GIUNCHETO (20100)", value: "20100" },
  { label: "GRANACE (20100)", value: "20100" },
  { label: "GROSSA (20100)", value: "20100" },
  { label: "GROSSETO PRUGNA (20128)", value: "20128" },
  { label: "GROSSETO PRUGNA (20166)", value: "20166" },
  { label: "GUAGNO (20160)", value: "20160" },
  { label: "GUARGUALE (20128)", value: "20128" },
  { label: "GUITERA LES BAINS (20153)", value: "20153" },
  { label: "LECCI (20137)", value: "20137" },
  { label: "LETIA (20160)", value: "20160" },
  { label: "LEVIE (20170)", value: "20170" },
  { label: "LOPIGNA (20139)", value: "20139" },
  { label: "LORETO DI TALLANO (20165)", value: "20165" },
  { label: "MARIGNANA (20141)", value: "20141" },
  { label: "MELA (20112)", value: "20112" },
  { label: "MOCA CROCE (20140)", value: "20140" },
  { label: "MONACIA D AULLENE (20171)", value: "20171" },
  { label: "MURZO (20160)", value: "20160" },
  { label: "OCANA (20117)", value: "20117" },
  { label: "OLIVESE (20140)", value: "20140" },
  { label: "OLMETO (20113)", value: "20113" },
  { label: "OLMICCIA (20112)", value: "20112" },
  { label: "ORTO (20125)", value: "20125" },
  { label: "OSANI (20147)", value: "20147" },
  { label: "OTA (20150)", value: "20150" },
  { label: "OTA (20150)", value: "20150" },
  { label: "PALNECA (20134)", value: "20134" },
  { label: "PARTINELLO (20147)", value: "20147" },
  { label: "PASTRICCIOLA (20121)", value: "20121" },
  { label: "PERI (20167)", value: "20167" },
  { label: "PETRETO BICCHISANO (20140)", value: "20140" },
  { label: "PIANA (20115)", value: "20115" },
  { label: "PIANOTTOLI CALDARELLO (20131)", value: "20131" },
  { label: "PIETROSELLA (20166)", value: "20166" },
  { label: "PILA CANALE (20123)", value: "20123" },
  { label: "POGGIOLO (20125)", value: "20125" },
  { label: "POGGIOLO (20160)", value: "20160" },
  { label: "PORTO VECCHIO (20137)", value: "20137" },
  { label: "PROPRIANO (20110)", value: "20110" },
  { label: "QUASQUARA (20142)", value: "20142" },
  { label: "QUENZA (20122)", value: "20122" },
  { label: "RENNO (20160)", value: "20160" },
  { label: "REZZA (20121)", value: "20121" },
  { label: "ROSAZIA (20121)", value: "20121" },
  { label: "SALICE (20121)", value: "20121" },
  { label: "SAMPOLO (20134)", value: "20134" },
  { label: "SARI SOLENZARA (20145)", value: "20145" },
  { label: "SARI SOLENZARA (20145)", value: "20145" },
  { label: "SARI D ORCINO (20151)", value: "20151" },
  { label: "SARROLA CARCOPINO (20167)", value: "20167" },
  { label: "SARTENE (20100)", value: "20100" },
  { label: "SERRA DI FERRO (20140)", value: "20140" },
  { label: "SERRA DI FERRO (20140)", value: "20140" },
  { label: "SERRA DI SCOPAMENE (20127)", value: "20127" },
  { label: "SERRIERA (20147)", value: "20147" },
  { label: "SOCCIA (20125)", value: "20125" },
  { label: "SOLLACARO (20140)", value: "20140" },
  { label: "SORBOLLANO (20152)", value: "20152" },
  { label: "SOTTA (20146)", value: "20146" },
  { label: "SANT ANDREA D ORCINO (20151)", value: "20151" },
  { label: "SAN GAVINO DI CARBINI (20137)", value: "20137" },
  { label: "SAN GAVINO DI CARBINI (20170)", value: "20170" },
  { label: "STE LUCIE DE TALLANO (20112)", value: "20112" },
  { label: "SANTA MARIA FIGANIELLA (20143)", value: "20143" },
  { label: "SANTA MARIA SICHE (20190)", value: "20190" },
  { label: "TASSO (20134)", value: "20134" },
  { label: "TAVACO (20167)", value: "20167" },
  { label: "TAVERA (20163)", value: "20163" },
  { label: "TOLLA (20117)", value: "20117" },
  { label: "UCCIANI (20133)", value: "20133" },
  { label: "URBALACONE (20128)", value: "20128" },
  { label: "VALLE DI MEZZANA (20167)", value: "20167" },
  { label: "VERO (20172)", value: "20172" },
  { label: "VICO (20118)", value: "20118" },
  { label: "VICO (20160)", value: "20160" },
  { label: "VIGGIANELLO (20110)", value: "20110" },
  { label: "VILLANOVA (20167)", value: "20167" },
  { label: "ZERUBIA (20116)", value: "20116" },
  { label: "ZEVACO (20173)", value: "20173" },
  { label: "ZICAVO (20132)", value: "20132" },
  { label: "ZIGLIARA (20190)", value: "20190" },
  { label: "ZONZA (20124)", value: "20124" },
  { label: "ZONZA (20144)", value: "20144" },
  { label: "ZOZA (20112)", value: "20112" },
  { label: "AGHIONE (20270)", value: "20270" },
  { label: "AITI (20244)", value: "20244" },
  { label: "ALANDO (20212)", value: "20212" },
  { label: "ALBERTACCE (20224)", value: "20224" },
  { label: "ALBERTACCE (20224)", value: "20224" },
  { label: "ALERIA (20270)", value: "20270" },
  { label: "ALERIA (20270)", value: "20270" },
  { label: "ALGAJOLA (20220)", value: "20220" },
  { label: "ALTIANI (20251)", value: "20251" },
  { label: "ALZI (20212)", value: "20212" },
  { label: "AMPRIANI (20272)", value: "20272" },
  { label: "ANTISANTI (20270)", value: "20270" },
  { label: "AREGNO (20220)", value: "20220" },
  { label: "ASCO (20276)", value: "20276" },
  { label: "AVAPESSA (20225)", value: "20225" },
  { label: "BARBAGGIO (20253)", value: "20253" },
  { label: "BARRETTALI (20228)", value: "20228" },
  { label: "BASTIA (20200)", value: "20200" },
  { label: "BASTIA (20200)", value: "20200" },
  { label: "BASTIA (20600)", value: "20600" },
  { label: "BELGODERE (20226)", value: "20226" },
  { label: "BELGODERE (20226)", value: "20226" },
  { label: "BIGORNO (20252)", value: "20252" },
  { label: "BIGUGLIA (20620)", value: "20620" },
  { label: "BIGUGLIA (20620)", value: "20620" },
  { label: "BISINCHI (20235)", value: "20235" },
  { label: "BORGO (20290)", value: "20290" },
  { label: "BORGO (20290)", value: "20290" },
  { label: "BRANDO (20222)", value: "20222" },
  { label: "BRANDO (20222)", value: "20222" },
  { label: "BRANDO (20222)", value: "20222" },
  { label: "BUSTANICO (20212)", value: "20212" },
  { label: "CAGNANO (20228)", value: "20228" },
  { label: "CALACUCCIA (20224)", value: "20224" },
  { label: "CALENZANA (20214)", value: "20214" },
  { label: "CALENZANA (20214)", value: "20214" },
  { label: "CALENZANA (20260)", value: "20260" },
  { label: "CALVI (20260)", value: "20260" },
  { label: "CAMBIA (20244)", value: "20244" },
  { label: "CAMPANA (20229)", value: "20229" },
  { label: "CAMPI (20270)", value: "20270" },
  { label: "CAMPILE (20290)", value: "20290" },
  { label: "CAMPITELLO (20252)", value: "20252" },
  { label: "CANALE DI VERDE (20230)", value: "20230" },
  { label: "CANARI (20217)", value: "20217" },
  { label: "CANAVAGGIA (20235)", value: "20235" },
  { label: "CARCHETO BRUSTICO (20229)", value: "20229" },
  { label: "CARPINETO (20229)", value: "20229" },
  { label: "CARTICASI (20244)", value: "20244" },
  { label: "CASABIANCA (20237)", value: "20237" },
  { label: "CASALTA (20215)", value: "20215" },
  { label: "CASAMACCIOLI (20224)", value: "20224" },
  { label: "CASANOVA (20250)", value: "20250" },
  { label: "CASEVECCHIE (20270)", value: "20270" },
  { label: "CASTELLARE DI CASINCA (20213)", value: "20213" },
  { label: "CASTELLARE DI MERCURIO (20212)", value: "20212" },
  { label: "CASTELLO DI ROSTINO (20235)", value: "20235" },
  { label: "CASTIFAO (20218)", value: "20218" },
  { label: "CASTIGLIONE (20218)", value: "20218" },
  { label: "CASTINETA (20218)", value: "20218" },
  { label: "CASTIRLA (20236)", value: "20236" },
  { label: "CATERI (20225)", value: "20225" },
  { label: "CENTURI (20238)", value: "20238" },
  { label: "CERVIONE (20221)", value: "20221" },
  { label: "CERVIONE (20221)", value: "20221" },
  { label: "CHIATRA (20230)", value: "20230" },
  { label: "CORBARA (20220)", value: "20220" },
  { label: "CORBARA (20256)", value: "20256" },
  { label: "CORSCIA (20224)", value: "20224" },
  { label: "CORTE (20250)", value: "20250" },
  { label: "COSTA (20226)", value: "20226" },
  { label: "CROCE (20237)", value: "20237" },
  { label: "CROCICCHIA (20290)", value: "20290" },
  { label: "ERBAJOLO (20212)", value: "20212" },
  { label: "ERONE (20244)", value: "20244" },
  { label: "ERSA (20275)", value: "20275" },
  { label: "FARINOLE (20253)", value: "20253" },
  { label: "FAVALELLO (20212)", value: "20212" },
  { label: "FELCE (20234)", value: "20234" },
  { label: "FELICETO (20225)", value: "20225" },
  { label: "FICAJA (20237)", value: "20237" },
  { label: "FOCICCHIA (20212)", value: "20212" },
  { label: "FURIANI (20600)", value: "20600" },
  { label: "GALERIA (20245)", value: "20245" },
  { label: "GAVIGNANO (20218)", value: "20218" },
  { label: "GHISONACCIA (20240)", value: "20240" },
  { label: "GHISONACCIA (20240)", value: "20240" },
  { label: "GHISONACCIA (20240)", value: "20240" },
  { label: "GHISONI (20227)", value: "20227" },
  { label: "GIOCATOJO (20237)", value: "20237" },
  { label: "GIUNCAGGIO (20251)", value: "20251" },
  { label: "L'ILE ROUSSE (20220)", value: "20220" },
  { label: "ISOLACCIO DI FIUMORBO (20243)", value: "20243" },
  { label: "ISOLACCIO DI FIUMORBO (20243)", value: "20243" },
  { label: "ISOLACCIO DI FIUMORBO (20243)", value: "20243" },
  { label: "LAMA (20218)", value: "20218" },
  { label: "LANO (20244)", value: "20244" },
  { label: "LAVATOGGIO (20225)", value: "20225" },
  { label: "LENTO (20252)", value: "20252" },
  { label: "LINGUIZZETTA (20230)", value: "20230" },
  { label: "LINGUIZZETTA (20230)", value: "20230" },
  { label: "LORETO DI CASINCA (20215)", value: "20215" },
  { label: "LOZZI (20224)", value: "20224" },
  { label: "LUCCIANA (20290)", value: "20290" },
  { label: "LUCCIANA (20290)", value: "20290" },
  { label: "LUCCIANA (20290)", value: "20290" },
  { label: "LUGO DI NAZZA (20240)", value: "20240" },
  { label: "LUMIO (20260)", value: "20260" },
  { label: "LURI (20228)", value: "20228" },
  { label: "LURI (20228)", value: "20228" },
  { label: "MANSO (20245)", value: "20245" },
  { label: "MATRA (20270)", value: "20270" },
  { label: "MAUSOLEO (20259)", value: "20259" },
  { label: "MAZZOLA (20212)", value: "20212" },
  { label: "MERIA (20287)", value: "20287" },
  { label: "MOITA (20270)", value: "20270" },
  { label: "MOLTIFAO (20218)", value: "20218" },
  { label: "MONACIA D OREZZA (20229)", value: "20229" },
  { label: "MONCALE (20214)", value: "20214" },
  { label: "MONTE (20290)", value: "20290" },
  { label: "MONTEGROSSO (20214)", value: "20214" },
  { label: "MONTEGROSSO (20214)", value: "20214" },
  { label: "MONTEGROSSO (20214)", value: "20214" },
  { label: "MONTEGROSSO (20214)", value: "20214" },
  { label: "MONTICELLO (20220)", value: "20220" },
  { label: "MOROSAGLIA (20218)", value: "20218" },
  { label: "MOROSAGLIA (20218)", value: "20218" },
  { label: "MORSIGLIA (20238)", value: "20238" },
  { label: "MURACCIOLE (20219)", value: "20219" },
  { label: "MURATO (20239)", value: "20239" },
  { label: "MURO (20225)", value: "20225" },
  { label: "NESSA (20225)", value: "20225" },
  { label: "NOCARIO (20229)", value: "20229" },
  { label: "NOCETA (20242)", value: "20242" },
  { label: "NONZA (20217)", value: "20217" },
  { label: "NOVALE (20234)", value: "20234" },
  { label: "NOVELLA (20226)", value: "20226" },
  { label: "OCCHIATANA (20226)", value: "20226" },
  { label: "OGLIASTRO (20217)", value: "20217" },
  { label: "OLCANI (20217)", value: "20217" },
  { label: "OLETTA (20232)", value: "20232" },
  { label: "OLMETA DI CAPOCORSO (20217)", value: "20217" },
  { label: "OLMETA DI TUDA (20232)", value: "20232" },
  { label: "OLMI CAPPELLA (20259)", value: "20259" },
  { label: "OLMO (20290)", value: "20290" },
  { label: "OMESSA (20236)", value: "20236" },
  { label: "OMESSA (20236)", value: "20236" },
  { label: "ORTALE (20234)", value: "20234" },
  { label: "ORTIPORIO (20290)", value: "20290" },
  { label: "PALASCA (20226)", value: "20226" },
  { label: "PANCHERACCIA (20251)", value: "20251" },
  { label: "PANCHERACCIA (20270)", value: "20270" },
  { label: "PARATA (20229)", value: "20229" },
  { label: "PATRIMONIO (20253)", value: "20253" },
  { label: "PENTA ACQUATELLA (20290)", value: "20290" },
  { label: "PENTA DI CASINCA (20213)", value: "20213" },
  { label: "PENTA DI CASINCA (20213)", value: "20213" },
  { label: "PERELLI (20234)", value: "20234" },
  { label: "PERO CASEVECCHIE (20230)", value: "20230" },
  { label: "PIANELLO (20272)", value: "20272" },
  { label: "PIANO (20215)", value: "20215" },
  { label: "PIAZZALI (20234)", value: "20234" },
  { label: "PIAZZOLE (20229)", value: "20229" },
  { label: "PIEDICORTE DI GAGGIO (20251)", value: "20251" },
  { label: "PIEDICROCE (20229)", value: "20229" },
  { label: "PIEDIGRIGGIO (20218)", value: "20218" },
  { label: "PIEDIPARTINO (20229)", value: "20229" },
  { label: "PIE D OREZZA (20229)", value: "20229" },
  { label: "PIETRALBA (20218)", value: "20218" },
  { label: "PIETRACORBARA (20233)", value: "20233" },
  { label: "PIETRA DI VERDE (20230)", value: "20230" },
  { label: "PIETRASERENA (20251)", value: "20251" },
  { label: "PIETRICAGGIO (20234)", value: "20234" },
  { label: "PIETROSO (20242)", value: "20242" },
  { label: "PIEVE (20246)", value: "20246" },
  { label: "PIGNA (20220)", value: "20220" },
  { label: "PINO (20228)", value: "20228" },
  { label: "PIOBETTA (20234)", value: "20234" },
  { label: "PIOGGIOLA (20259)", value: "20259" },
  { label: "POGGIO DI NAZZA (20240)", value: "20240" },
  { label: "POGGIO DI VENACO (20250)", value: "20250" },
  { label: "POGGIO D OLETTA (20232)", value: "20232" },
  { label: "POGGIO MARINACCIO (20237)", value: "20237" },
  { label: "POGGIO MEZZANA (20230)", value: "20230" },
  { label: "POLVEROSO (20229)", value: "20229" },
  { label: "POPOLASCA (20218)", value: "20218" },
  { label: "PORRI (20215)", value: "20215" },
  { label: "LA PORTA (20237)", value: "20237" },
  { label: "PRATO DI GIOVELLINA (20218)", value: "20218" },
  { label: "PRUNELLI DI CASACCONI (20290)", value: "20290" },
  { label: "PRUNELLI DI FIUMORBO (20243)", value: "20243" },
  { label: "PRUNELLI DI FIUMORBO (20243)", value: "20243" },
  { label: "PRUNELLI DI FIUMORBO (20243)", value: "20243" },
  { label: "PRUNO (20213)", value: "20213" },
  { label: "PRUNO (20213)", value: "20213" },
  { label: "QUERCITELLO (20237)", value: "20237" },
  { label: "RAPAGGIO (20229)", value: "20229" },
  { label: "RAPALE (20246)", value: "20246" },
  { label: "RIVENTOSA (20250)", value: "20250" },
  { label: "ROGLIANO (20247)", value: "20247" },
  { label: "ROGLIANO (20248)", value: "20248" },
  { label: "ROSPIGLIANI (20242)", value: "20242" },
  { label: "RUSIO (20244)", value: "20244" },
  { label: "RUTALI (20239)", value: "20239" },
  { label: "SALICETO (20218)", value: "20218" },
  { label: "SCATA (20213)", value: "20213" },
  { label: "SCOLCA (20290)", value: "20290" },
  { label: "SERMANO (20212)", value: "20212" },
  { label: "SERRA DI FIUMORBO (20243)", value: "20243" },
  { label: "SILVARECCIO (20215)", value: "20215" },
  { label: "SISCO (20233)", value: "20233" },
  { label: "SOLARO (20240)", value: "20240" },
  { label: "SORBO OCAGNANO (20213)", value: "20213" },
  { label: "SORBO OCAGNANO (20213)", value: "20213" },
  { label: "SORIO (20246)", value: "20246" },
  { label: "SOVERIA (20250)", value: "20250" },
  { label: "SPELONCATO (20226)", value: "20226" },
  { label: "STAZZONA (20229)", value: "20229" },
  { label: "SANT ANDREA DI BOZIO (20212)", value: "20212" },
  { label: "SANT ANDREA DI COTONE (20221)", value: "20221" },
  { label: "SANT ANTONINO (20220)", value: "20220" },
  { label: "SAN DAMIANO (20213)", value: "20213" },
  { label: "ST FLORENT (20217)", value: "20217" },
  { label: "SAN GAVINO D AMPUGNANI (20213)", value: "20213" },
  { label: "SAN GAVINO DI TENDA (20246)", value: "20246" },
  { label: "SAN GIOVANNI DI MORIANI (20230)", value: "20230" },
  { label: "SAN GIULIANO (20230)", value: "20230" },
  { label: "SAN GIULIANO (20230)", value: "20230" },
  { label: "SAN LORENZO (20244)", value: "20244" },
  { label: "SAN MARTINO DI LOTA (20200)", value: "20200" },
  { label: "SAN MARTINO DI LOTA (20200)", value: "20200" },
  { label: "SANTA LUCIA DI MERCURIO (20250)", value: "20250" },
  { label: "SANTA LUCIA DI MORIANI (20230)", value: "20230" },
  { label: "SANTA MARIA DI LOTA (20200)", value: "20200" },
  { label: "SANTA MARIA DI LOTA (20200)", value: "20200" },
  { label: "SANTA MARIA POGGIO (20221)", value: "20221" },
  { label: "SAN NICOLAO (20230)", value: "20230" },
  { label: "SAN NICOLAO (20230)", value: "20230" },
  { label: "SAN NICOLAO (20230)", value: "20230" },
  { label: "SANTO PIETRO DI TENDA (20217)", value: "20217" },
  { label: "SANTO PIETRO DI TENDA (20246)", value: "20246" },
  { label: "SANTO PIETRO DI VENACO (20250)", value: "20250" },
  { label: "SANTA REPARATA DI BALAGNA (20220)", value: "20220" },
  { label: "SANTA REPARATA DI MORIANI (20230)", value: "20230" },
  { label: "TAGLIO ISOLACCIO (20230)", value: "20230" },
  { label: "TALASANI (20230)", value: "20230" },
  { label: "TALASANI (20230)", value: "20230" },
  { label: "TALLONE (20270)", value: "20270" },
  { label: "TALLONE (20270)", value: "20270" },
  { label: "TARRANO (20234)", value: "20234" },
  { label: "TOMINO (20248)", value: "20248" },
  { label: "TOX (20270)", value: "20270" },
  { label: "TRALONCA (20250)", value: "20250" },
  { label: "URTACA (20218)", value: "20218" },
  { label: "VALLECALLE (20232)", value: "20232" },
  { label: "VALLE D ALESANI (20234)", value: "20234" },
  { label: "VALLE DI CAMPOLORO (20221)", value: "20221" },
  { label: "VALLE DI ROSTINO (20235)", value: "20235" },
  { label: "VALLE D OREZZA (20229)", value: "20229" },
  { label: "VALLICA (20259)", value: "20259" },
  { label: "VELONE ORNETO (20230)", value: "20230" },
  { label: "VENACO (20231)", value: "20231" },
  { label: "VENTISERI (20240)", value: "20240" },
  { label: "VENTISERI (20240)", value: "20240" },
  { label: "VENTISERI (20240)", value: "20240" },
  { label: "VENZOLASCA (20215)", value: "20215" },
  { label: "VERDESE (20229)", value: "20229" },
  { label: "VESCOVATO (20215)", value: "20215" },
  { label: "VEZZANI (20242)", value: "20242" },
  { label: "VIGNALE (20290)", value: "20290" },
  { label: "VILLE DI PARASO (20279)", value: "20279" },
  { label: "VILLE DI PIETRABUGNO (20200)", value: "20200" },
  { label: "VIVARIO (20219)", value: "20219" },
  { label: "VIVARIO (20219)", value: "20219" },
  { label: "VIVARIO (20219)", value: "20219" },
  { label: "VOLPAJOLA (20290)", value: "20290" },
  { label: "VOLPAJOLA (20290)", value: "20290" },
  { label: "ZALANA (20272)", value: "20272" },
  { label: "ZILIA (20214)", value: "20214" },
  { label: "ZUANI (20272)", value: "20272" },
  { label: "SAN GAVINO DI FIUMORBO (20243)", value: "20243" },
  { label: "CHISA (20240)", value: "20240" },
  { label: "AIGALIERS (30700)", value: "30700" },
  { label: "AIGREMONT (30350)", value: "30350" },
  { label: "AIGUES MORTES (30220)", value: "30220" },
  { label: "AIGUES VIVES (30670)", value: "30670" },
  { label: "AIGUEZE (30760)", value: "30760" },
  { label: "AIMARGUES (30470)", value: "30470" },
  { label: "ALES (30100)", value: "30100" },
  { label: "ALLEGRE LES FUMADES (30500)", value: "30500" },
  { label: "ALZON (30770)", value: "30770" },
  { label: "ANDUZE (30140)", value: "30140" },
  { label: "LES ANGLES (30133)", value: "30133" },
  { label: "ARAMON (30390)", value: "30390" },
  { label: "ARGILLIERS (30210)", value: "30210" },
  { label: "ARPAILLARGUES ET AUREILLAC (30700)", value: "30700" },
  { label: "ARPHY (30120)", value: "30120" },
  { label: "ARRE (30120)", value: "30120" },
  { label: "ARRIGAS (30770)", value: "30770" },
  { label: "ASPERES (30250)", value: "30250" },
  { label: "AUBAIS (30250)", value: "30250" },
  { label: "AUBORD (30620)", value: "30620" },
  { label: "AUBUSSARGUES (30190)", value: "30190" },
  { label: "AUJAC (30450)", value: "30450" },
  { label: "AUJARGUES (30250)", value: "30250" },
  { label: "AULAS (30120)", value: "30120" },
  { label: "AUMESSAS (30770)", value: "30770" },
  { label: "AVEZE (30120)", value: "30120" },
  { label: "BAGARD (30140)", value: "30140" },
  { label: "BAGNOLS SUR CEZE (30200)", value: "30200" },
  { label: "BARJAC (30430)", value: "30430" },
  { label: "BARON (30700)", value: "30700" },
  { label: "LA BASTIDE D ENGRAS (30330)", value: "30330" },
  { label: "BEAUCAIRE (30300)", value: "30300" },
  { label: "BEAUVOISIN (30640)", value: "30640" },
  { label: "BEAUVOISIN (30640)", value: "30640" },
  { label: "BELLEGARDE (30127)", value: "30127" },
  { label: "BELVEZET (30580)", value: "30580" },
  { label: "BERNIS (30620)", value: "30620" },
  { label: "BESSEGES (30160)", value: "30160" },
  { label: "BESSEGES (30160)", value: "30160" },
  { label: "BEZ ET ESPARON (30120)", value: "30120" },
  { label: "BEZOUCE (30320)", value: "30320" },
  { label: "BLANDAS (30770)", value: "30770" },
  { label: "BLAUZAC (30700)", value: "30700" },
  { label: "BOISSET ET GAUJAC (30140)", value: "30140" },
  { label: "BOISSIERES (30114)", value: "30114" },
  { label: "BONNEVAUX (30450)", value: "30450" },
  { label: "BORDEZAC (30160)", value: "30160" },
  { label: "BOUCOIRAN ET NOZIERES (30190)", value: "30190" },
  { label: "BOUILLARGUES (30230)", value: "30230" },
  { label: "BOUQUET (30580)", value: "30580" },
  { label: "BOURDIC (30190)", value: "30190" },
  { label: "BRAGASSARGUES (30260)", value: "30260" },
  { label: "BRANOUX LES TAILLADES (30110)", value: "30110" },
  { label: "BREAU MARS (30120)", value: "30120" },
  { label: "BREAU MARS (30120)", value: "30120" },
  { label: "BRIGNON (30190)", value: "30190" },
  { label: "BROUZET LES QUISSAC (30260)", value: "30260" },
  { label: "BROUZET LES ALES (30580)", value: "30580" },
  { label: "LA BRUGUIERE (30580)", value: "30580" },
  { label: "CABRIERES (30210)", value: "30210" },
  { label: "LA CADIERE ET CAMBO (30170)", value: "30170" },
  { label: "LE CAILAR (30740)", value: "30740" },
  { label: "CAISSARGUES (30132)", value: "30132" },
  { label: "LA CALMETTE (30190)", value: "30190" },
  { label: "CALVISSON (30420)", value: "30420" },
  { label: "CAMPESTRE ET LUC (30770)", value: "30770" },
  { label: "CANAULES ET ARGENTIERES (30350)", value: "30350" },
  { label: "CANNES ET CLAIRAN (30260)", value: "30260" },
  { label: "LA CAPELLE ET MASMOLENE (30700)", value: "30700" },
  { label: "CARDET (30350)", value: "30350" },
  { label: "CARNAS (30260)", value: "30260" },
  { label: "CARSAN (30130)", value: "30130" },
  { label: "CASSAGNOLES (30350)", value: "30350" },
  { label: "CASTELNAU VALENCE (30190)", value: "30190" },
  { label: "CASTILLON DU GARD (30210)", value: "30210" },
  { label: "CAUSSE BEGON (30750)", value: "30750" },
  { label: "CAVEIRAC (30820)", value: "30820" },
  { label: "CAVILLARGUES (30330)", value: "30330" },
  { label: "CENDRAS (30480)", value: "30480" },
  { label: "CHAMBON (30450)", value: "30450" },
  { label: "CHAMBORIGAUD (30530)", value: "30530" },
  { label: "CHUSCLAN (30200)", value: "30200" },
  { label: "CHUSCLAN (30200)", value: "30200" },
  { label: "CLARENSAC (30870)", value: "30870" },
  { label: "CODOGNAN (30920)", value: "30920" },
  { label: "CODOLET (30200)", value: "30200" },
  { label: "COLLIAS (30210)", value: "30210" },
  { label: "COLLORGUES (30190)", value: "30190" },
  { label: "COLOGNAC (30460)", value: "30460" },
  { label: "COMBAS (30250)", value: "30250" },
  { label: "COMPS (30300)", value: "30300" },
  { label: "CONCOULES (30450)", value: "30450" },
  { label: "CONGENIES (30111)", value: "30111" },
  { label: "CONNAUX (30330)", value: "30330" },
  { label: "CONQUEYRAC (30170)", value: "30170" },
  { label: "CORBES (30140)", value: "30140" },
  { label: "CORCONNE (30260)", value: "30260" },
  { label: "CORNILLON (30630)", value: "30630" },
  { label: "COURRY (30500)", value: "30500" },
  { label: "CRESPIAN (30260)", value: "30260" },
  { label: "CROS (30170)", value: "30170" },
  { label: "CRUVIERS LASCOURS (30360)", value: "30360" },
  { label: "DEAUX (30360)", value: "30360" },
  { label: "DIONS (30190)", value: "30190" },
  { label: "DOMAZAN (30390)", value: "30390" },
  { label: "DOMESSARGUES (30350)", value: "30350" },
  { label: "DOURBIES (30750)", value: "30750" },
  { label: "DURFORT ET ST MARTIN DE SOSSENAC (30170)", value: "30170" },
  { label: "ESTEZARGUES (30390)", value: "30390" },
  { label: "L'ESTRECHURE (30124)", value: "30124" },
  { label: "EUZET (30360)", value: "30360" },
  { label: "FLAUX (30700)", value: "30700" },
  { label: "FOISSAC (30700)", value: "30700" },
  { label: "FONS (30730)", value: "30730" },
  { label: "FONS SUR LUSSAN (30580)", value: "30580" },
  { label: "FONTANES (30250)", value: "30250" },
  { label: "FONTARECHES (30580)", value: "30580" },
  { label: "FOURNES (30210)", value: "30210" },
  { label: "FOURQUES (30300)", value: "30300" },
  { label: "FRESSAC (30170)", value: "30170" },
  { label: "GAGNIERES (30160)", value: "30160" },
  { label: "GAILHAN (30260)", value: "30260" },
  { label: "GAJAN (30730)", value: "30730" },
  { label: "GALLARGUES LE MONTUEUX (30660)", value: "30660" },
  { label: "LE GARN (30760)", value: "30760" },
  { label: "GARONS (30128)", value: "30128" },
  { label: "GARRIGUES STE EULALIE (30190)", value: "30190" },
  { label: "GAUJAC (30330)", value: "30330" },
  { label: "GENERAC (30510)", value: "30510" },
  { label: "GENERARGUES (30140)", value: "30140" },
  { label: "GENOLHAC (30450)", value: "30450" },
  { label: "GENOLHAC (30450)", value: "30450" },
  { label: "GOUDARGUES (30630)", value: "30630" },
  { label: "LA GRAND COMBE (30110)", value: "30110" },
  { label: "LA GRAND COMBE (30110)", value: "30110" },
  { label: "LE GRAU DU ROI (30240)", value: "30240" },
  { label: "LE GRAU DU ROI (30240)", value: "30240" },
  { label: "ISSIRAC (30760)", value: "30760" },
  { label: "JONQUIERES ST VINCENT (30300)", value: "30300" },
  { label: "JUNAS (30250)", value: "30250" },
  { label: "LAMELOUZE (30110)", value: "30110" },
  { label: "LANGLADE (30980)", value: "30980" },
  { label: "LANUEJOLS (30750)", value: "30750" },
  { label: "LASALLE (30460)", value: "30460" },
  { label: "LAUDUN L ARDOISE (30290)", value: "30290" },
  { label: "LAUDUN L ARDOISE (30290)", value: "30290" },
  { label: "LAVAL PRADEL (30110)", value: "30110" },
  { label: "LAVAL ST ROMAN (30760)", value: "30760" },
  { label: "LECQUES (30250)", value: "30250" },
  { label: "LEDENON (30210)", value: "30210" },
  { label: "LEDIGNAN (30350)", value: "30350" },
  { label: "LEZAN (30350)", value: "30350" },
  { label: "LIOUC (30260)", value: "30260" },
  { label: "LIRAC (30126)", value: "30126" },
  { label: "LOGRIAN FLORIAN (30610)", value: "30610" },
  { label: "LUSSAN (30580)", value: "30580" },
  { label: "LES MAGES (30960)", value: "30960" },
  { label: "MALONS ET ELZE (30450)", value: "30450" },
  { label: "MANDAGOUT (30120)", value: "30120" },
  { label: "MANDUEL (30129)", value: "30129" },
  { label: "MARGUERITTES (30320)", value: "30320" },
  { label: "MARTIGNARGUES (30360)", value: "30360" },
  { label: "LE MARTINET (30960)", value: "30960" },
  { label: "MARUEJOLS LES GARDON (30350)", value: "30350" },
  { label: "MASSANES (30350)", value: "30350" },
  { label: "MASSILLARGUES ATTUECH (30140)", value: "30140" },
  { label: "MAURESSARGUES (30350)", value: "30350" },
  { label: "MEJANNES LE CLAP (30430)", value: "30430" },
  { label: "MEJANNES LES ALES (30340)", value: "30340" },
  { label: "MEYNES (30840)", value: "30840" },
  { label: "MEYRANNES (30410)", value: "30410" },
  { label: "MIALET (30140)", value: "30140" },
  { label: "MILHAUD (30540)", value: "30540" },
  { label: "MOLIERES CAVAILLAC (30120)", value: "30120" },
  { label: "MOLIERES SUR CEZE (30410)", value: "30410" },
  { label: "MONOBLET (30170)", value: "30170" },
  { label: "MONS (30340)", value: "30340" },
  { label: "MONTAREN ET ST MEDIERS (30700)", value: "30700" },
  { label: "MONTCLUS (30630)", value: "30630" },
  { label: "MONTDARDIER (30120)", value: "30120" },
  { label: "MONTEILS (30360)", value: "30360" },
  { label: "MONTFAUCON (30150)", value: "30150" },
  { label: "MONTFRIN (30490)", value: "30490" },
  { label: "MONTIGNARGUES (30190)", value: "30190" },
  { label: "MONTMIRAT (30260)", value: "30260" },
  { label: "MONTPEZAT (30730)", value: "30730" },
  { label: "MOULEZAN (30350)", value: "30350" },
  { label: "MOUSSAC (30190)", value: "30190" },
  { label: "MUS (30121)", value: "30121" },
  { label: "NAGES ET SOLORGUES (30114)", value: "30114" },
  { label: "NAVACELLES (30580)", value: "30580" },
  { label: "NERS (30360)", value: "30360" },
  { label: "NIMES (30000)", value: "30000" },
  { label: "NIMES (30900)", value: "30900" },
  { label: "NIMES (30900)", value: "30900" },
  { label: "ORSAN (30200)", value: "30200" },
  { label: "ORTHOUX SERIGNAC QUILHAN (30260)", value: "30260" },
  { label: "PARIGNARGUES (30730)", value: "30730" },
  { label: "PEYREMALE (30160)", value: "30160" },
  { label: "PEYROLLES (30124)", value: "30124" },
  { label: "LE PIN (30330)", value: "30330" },
  { label: "LES PLANS (30340)", value: "30340" },
  { label: "LES PLANTIERS (30122)", value: "30122" },
  { label: "POMMIERS (30120)", value: "30120" },
  { label: "POMPIGNAN (30170)", value: "30170" },
  { label: "PONTEILS ET BRESIS (30450)", value: "30450" },
  { label: "PONT ST ESPRIT (30130)", value: "30130" },
  { label: "PORTES (30530)", value: "30530" },
  { label: "POTELIERES (30500)", value: "30500" },
  { label: "POUGNADORESSE (30330)", value: "30330" },
  { label: "POULX (30320)", value: "30320" },
  { label: "POUZILHAC (30210)", value: "30210" },
  { label: "PUECHREDON (30610)", value: "30610" },
  { label: "PUJAUT (30131)", value: "30131" },
  { label: "QUISSAC (30260)", value: "30260" },
  { label: "REDESSAN (30129)", value: "30129" },
  { label: "REMOULINS (30210)", value: "30210" },
  { label: "REVENS (30750)", value: "30750" },
  { label: "RIBAUTE LES TAVERNES (30720)", value: "30720" },
  { label: "RIVIERES (30430)", value: "30430" },
  { label: "ROBIAC ROCHESSADOULE (30160)", value: "30160" },
  { label: "ROCHEFORT DU GARD (30650)", value: "30650" },
  { label: "ROCHEGUDE (30430)", value: "30430" },
  { label: "ROGUES (30120)", value: "30120" },
  { label: "ROQUEDUR (30440)", value: "30440" },
  { label: "ROQUEMAURE (30150)", value: "30150" },
  { label: "LA ROQUE SUR CEZE (30200)", value: "30200" },
  { label: "ROUSSON (30340)", value: "30340" },
  { label: "LA ROUVIERE (30190)", value: "30190" },
  { label: "SABRAN (30200)", value: "30200" },
  { label: "ST ALEXANDRE (30130)", value: "30130" },
  { label: "ST AMBROIX (30500)", value: "30500" },
  { label: "STE ANASTASIE (30190)", value: "30190" },
  { label: "ST ANDRE DE MAJENCOULES (30570)", value: "30570" },
  { label: "ST ANDRE DE ROQUEPERTUIS (30630)", value: "30630" },
  { label: "ST ANDRE DE VALBORGNE (30940)", value: "30940" },
  { label: "ST ANDRE D OLERARGUES (30330)", value: "30330" },
  { label: "ST BAUZELY (30730)", value: "30730" },
  { label: "ST BENEZET (30350)", value: "30350" },
  { label: "ST BONNET DU GARD (30210)", value: "30210" },
  { label: "ST BONNET DE SALENDRINQUE (30460)", value: "30460" },
  { label: "ST BRES (30500)", value: "30500" },
  { label: "ST BRESSON (30440)", value: "30440" },
  { label: "STE CECILE D ANDORGE (30110)", value: "30110" },
  { label: "ST CESAIRE DE GAUZIGNAN (30360)", value: "30360" },
  { label: "ST CHAPTES (30190)", value: "30190" },
  { label: "ST CHRISTOL DE RODIERES (30760)", value: "30760" },
  { label: "ST CHRISTOL LEZ ALES (30380)", value: "30380" },
  { label: "ST CLEMENT (30260)", value: "30260" },
  { label: "ST COME ET MARUEJOLS (30870)", value: "30870" },
  { label: "STE CROIX DE CADERLE (30460)", value: "30460" },
  { label: "ST DENIS (30500)", value: "30500" },
  { label: "ST DEZERY (30190)", value: "30190" },
  { label: "ST DIONISY (30980)", value: "30980" },
  { label: "ST ETIENNE DE L OLM (30360)", value: "30360" },
  { label: "ST ETIENNE DES SORTS (30200)", value: "30200" },
  { label: "ST FELIX DE PALLIERES (30140)", value: "30140" },
  { label: "ST FLORENT SUR AUZONNET (30960)", value: "30960" },
  { label: "ST GENIES DE COMOLAS (30150)", value: "30150" },
  { label: "ST GENIES DE MALGOIRES (30190)", value: "30190" },
  { label: "ST GERVAIS (30200)", value: "30200" },
  { label: "ST GERVASY (30320)", value: "30320" },
  { label: "ST GILLES (30800)", value: "30800" },
  { label: "ST HILAIRE DE BRETHMAS (30560)", value: "30560" },
  { label: "ST HILAIRE D OZILHAN (30210)", value: "30210" },
  { label: "ST HIPPOLYTE DE CATON (30360)", value: "30360" },
  { label: "ST HIPPOLYTE DE MONTAIGU (30700)", value: "30700" },
  { label: "ST HIPPOLYTE DU FORT (30170)", value: "30170" },
  { label: "ST JEAN DE CEYRARGUES (30360)", value: "30360" },
  { label: "ST JEAN DE CRIEULON (30610)", value: "30610" },
  { label: "ST JEAN DE MARUEJOLS ET AVEJAN (30430)", value: "30430" },
  { label: "ST JEAN DE SERRES (30350)", value: "30350" },
  { label: "ST JEAN DE VALERISCLE (30960)", value: "30960" },
  { label: "ST JEAN DU GARD (30270)", value: "30270" },
  { label: "ST JEAN DU PIN (30140)", value: "30140" },
  { label: "ST JULIEN DE CASSAGNAS (30500)", value: "30500" },
  { label: "ST JULIEN DE LA NEF (30440)", value: "30440" },
  { label: "ST JULIEN DE PEYROLAS (30760)", value: "30760" },
  { label: "ST JULIEN LES ROSIERS (30340)", value: "30340" },
  { label: "ST JUST ET VACQUIERES (30580)", value: "30580" },
  { label: "ST LAURENT D AIGOUZE (30220)", value: "30220" },
  { label: "ST LAURENT DE CARNOLS (30200)", value: "30200" },
  { label: "ST LAURENT DES ARBRES (30126)", value: "30126" },
  { label: "ST LAURENT LA VERNEDE (30330)", value: "30330" },
  { label: "ST LAURENT LE MINIER (30440)", value: "30440" },
  { label: "ST MAMERT DU GARD (30730)", value: "30730" },
  { label: "ST MARCEL DE CAREIRET (30330)", value: "30330" },
  { label: "ST MARTIAL (30440)", value: "30440" },
  { label: "ST MARTIN DE VALGALGUES (30520)", value: "30520" },
  { label: "ST MAURICE DE CAZEVIEILLE (30360)", value: "30360" },
  { label: "ST MAXIMIN (30700)", value: "30700" },
  { label: "ST MICHEL D EUZET (30200)", value: "30200" },
  { label: "ST NAZAIRE (30200)", value: "30200" },
  { label: "ST NAZAIRE DES GARDIES (30610)", value: "30610" },
  { label: "ST PAULET DE CAISSON (30130)", value: "30130" },
  { label: "ST PAUL LA COSTE (30480)", value: "30480" },
  { label: "ST PONS LA CALM (30330)", value: "30330" },
  { label: "ST PRIVAT DE CHAMPCLOS (30430)", value: "30430" },
  { label: "ST PRIVAT DES VIEUX (30340)", value: "30340" },
  { label: "ST QUENTIN LA POTERIE (30700)", value: "30700" },
  { label: "ST ROMAN DE CODIERES (30440)", value: "30440" },
  { label: "ST SAUVEUR CAMPRIEU (30750)", value: "30750" },
  { label: "ST SAUVEUR CAMPRIEU (30750)", value: "30750" },
  { label: "ST SEBASTIEN D AIGREFEUILLE (30140)", value: "30140" },
  { label: "ST SIFFRET (30700)", value: "30700" },
  { label: "ST THEODORIT (30260)", value: "30260" },
  { label: "ST VICTOR DES OULES (30700)", value: "30700" },
  { label: "ST VICTOR LA COSTE (30290)", value: "30290" },
  { label: "ST VICTOR DE MALCAP (30500)", value: "30500" },
  { label: "SALAZAC (30760)", value: "30760" },
  { label: "SALINDRES (30340)", value: "30340" },
  { label: "SALINELLES (30250)", value: "30250" },
  { label: "LES SALLES DU GARDON (30110)", value: "30110" },
  { label: "SANILHAC SAGRIES (30700)", value: "30700" },
  { label: "SARDAN (30260)", value: "30260" },
  { label: "SAUMANE (30125)", value: "30125" },
  { label: "SAUVE (30610)", value: "30610" },
  { label: "SAUVETERRE (30150)", value: "30150" },
  { label: "SAUZET (30190)", value: "30190" },
  { label: "SAVIGNARGUES (30350)", value: "30350" },
  { label: "SAZE (30650)", value: "30650" },
  { label: "SENECHAS (30450)", value: "30450" },
  { label: "SERNHAC (30210)", value: "30210" },
  { label: "SERVAS (30340)", value: "30340" },
  { label: "SERVIERS ET LABAUME (30700)", value: "30700" },
  { label: "SEYNES (30580)", value: "30580" },
  { label: "SOMMIERES (30250)", value: "30250" },
  { label: "SOUDORGUES (30460)", value: "30460" },
  { label: "SOUSTELLE (30110)", value: "30110" },
  { label: "SOUVIGNARGUES (30250)", value: "30250" },
  { label: "SUMENE (30440)", value: "30440" },
  { label: "SUMENE (30440)", value: "30440" },
  { label: "TAVEL (30126)", value: "30126" },
  { label: "THARAUX (30430)", value: "30430" },
  { label: "THEZIERS (30390)", value: "30390" },
  { label: "THOIRAS (30140)", value: "30140" },
  { label: "TORNAC (30140)", value: "30140" },
  { label: "TRESQUES (30330)", value: "30330" },
  { label: "TREVES (30750)", value: "30750" },
  { label: "UCHAUD (30620)", value: "30620" },
  { label: "UZES (30700)", value: "30700" },
  { label: "VABRES (30460)", value: "30460" },
  { label: "VALLABREGUES (30300)", value: "30300" },
  { label: "VALLABRIX (30700)", value: "30700" },
  { label: "VALLERARGUES (30580)", value: "30580" },
  { label: "VAL D AIGOUAL (30570)", value: "30570" },
  { label: "VAL D AIGOUAL (30570)", value: "30570" },
  { label: "VAL D AIGOUAL (30570)", value: "30570" },
  { label: "VAL D AIGOUAL (30570)", value: "30570" },
  { label: "VALLIGUIERES (30210)", value: "30210" },
  { label: "VAUVERT (30600)", value: "30600" },
  { label: "VAUVERT (30600)", value: "30600" },
  { label: "VAUVERT (30600)", value: "30600" },
  { label: "VAUVERT (30600)", value: "30600" },
  { label: "VENEJAN (30200)", value: "30200" },
  { label: "VERFEUIL (30630)", value: "30630" },
  { label: "VERGEZE (30310)", value: "30310" },
  { label: "LA VERNAREDE (30530)", value: "30530" },
  { label: "VERS PONT DU GARD (30210)", value: "30210" },
  { label: "VESTRIC ET CANDIAC (30600)", value: "30600" },
  { label: "VEZENOBRES (30360)", value: "30360" },
  { label: "VIC LE FESQ (30260)", value: "30260" },
  { label: "LE VIGAN (30120)", value: "30120" },
  { label: "VILLENEUVE LES AVIGNON (30400)", value: "30400" },
  { label: "VILLEVIEILLE (30250)", value: "30250" },
  { label: "VISSEC (30770)", value: "30770" },
  { label: "MONTAGNAC (30350)", value: "30350" },
  { label: "ST PAUL LES FONTS (30330)", value: "30330" },
  { label: "RODILHAN (30230)", value: "30230" },
  { label: "AGASSAC (31230)", value: "31230" },
  { label: "AIGNES (31550)", value: "31550" },
  { label: "AIGREFEUILLE (31280)", value: "31280" },
  { label: "AYGUESVIVES (31450)", value: "31450" },
  { label: "ALAN (31420)", value: "31420" },
  { label: "ALBIAC (31460)", value: "31460" },
  { label: "AMBAX (31230)", value: "31230" },
  { label: "ANAN (31230)", value: "31230" },
  { label: "ANTICHAN DE FRONTIGNES (31510)", value: "31510" },
  { label: "ANTIGNAC (31110)", value: "31110" },
  { label: "ARBAS (31160)", value: "31160" },
  { label: "ARBON (31160)", value: "31160" },
  { label: "ARDIEGE (31210)", value: "31210" },
  { label: "ARGUENOS (31160)", value: "31160" },
  { label: "ARGUT DESSOUS (31440)", value: "31440" },
  { label: "ARLOS (31440)", value: "31440" },
  { label: "ARNAUD GUILHEM (31360)", value: "31360" },
  { label: "ARTIGUE (31110)", value: "31110" },
  { label: "ASPET (31160)", value: "31160" },
  { label: "ASPRET SARRAT (31800)", value: "31800" },
  { label: "AUCAMVILLE (31140)", value: "31140" },
  { label: "AULON (31420)", value: "31420" },
  { label: "AURAGNE (31190)", value: "31190" },
  { label: "AUREVILLE (31320)", value: "31320" },
  { label: "AURIAC SUR VENDINELLE (31460)", value: "31460" },
  { label: "AURIBAIL (31190)", value: "31190" },
  { label: "AURIGNAC (31420)", value: "31420" },
  { label: "AURIN (31570)", value: "31570" },
  { label: "AUSSEING (31260)", value: "31260" },
  { label: "AUSSON (31210)", value: "31210" },
  { label: "AUSSONNE (31840)", value: "31840" },
  { label: "AUTERIVE (31190)", value: "31190" },
  { label: "AUZAS (31360)", value: "31360" },
  { label: "AUZEVILLE TOLOSANE (31320)", value: "31320" },
  { label: "AUZIELLE (31650)", value: "31650" },
  { label: "AVIGNONET LAURAGAIS (31290)", value: "31290" },
  { label: "AZAS (31380)", value: "31380" },
  { label: "BACHAS (31420)", value: "31420" },
  { label: "BACHOS (31440)", value: "31440" },
  { label: "BAGIRY (31510)", value: "31510" },
  { label: "BAGNERES DE LUCHON (31110)", value: "31110" },
  { label: "BAGNERES DE LUCHON (31110)", value: "31110" },
  { label: "BALESTA (31580)", value: "31580" },
  { label: "BALMA (31130)", value: "31130" },
  { label: "BARBAZAN (31510)", value: "31510" },
  { label: "BAREN (31440)", value: "31440" },
  { label: "BAX (31310)", value: "31310" },
  { label: "BAZIEGE (31450)", value: "31450" },
  { label: "BAZUS (31380)", value: "31380" },
  { label: "BEAUCHALOT (31360)", value: "31360" },
  { label: "BEAUFORT (31370)", value: "31370" },
  { label: "BEAUMONT SUR LEZE (31870)", value: "31870" },
  { label: "BEAUPUY (31850)", value: "31850" },
  { label: "BEAUTEVILLE (31290)", value: "31290" },
  { label: "BEAUVILLE (31460)", value: "31460" },
  { label: "BEAUZELLE (31700)", value: "31700" },
  { label: "BELBERAUD (31450)", value: "31450" },
  { label: "BELBEZE DE LAURAGAIS (31450)", value: "31450" },
  { label: "BELBEZE EN COMMINGES (31260)", value: "31260" },
  { label: "BELESTA EN LAURAGAIS (31540)", value: "31540" },
  { label: "BELLEGARDE STE MARIE (31530)", value: "31530" },
  { label: "BELLESSERRE (31480)", value: "31480" },
  { label: "BENQUE (31420)", value: "31420" },
  { label: "BENQUE DESSOUS ET DESSUS (31110)", value: "31110" },
  { label: "BERAT (31370)", value: "31370" },
  { label: "BESSIERES (31660)", value: "31660" },
  { label: "BEZINS GARRAUX (31440)", value: "31440" },
  { label: "BILLIERE (31110)", value: "31110" },
  { label: "BLAGNAC (31700)", value: "31700" },
  { label: "BLAJAN (31350)", value: "31350" },
  { label: "BOIS DE LA PIERRE (31390)", value: "31390" },
  { label: "BOISSEDE (31230)", value: "31230" },
  { label: "BONDIGOUX (31340)", value: "31340" },
  { label: "BONREPOS RIQUET (31590)", value: "31590" },
  { label: "BONREPOS SUR AUSSONNELLE (31470)", value: "31470" },
  { label: "BORDES DE RIVIERE (31210)", value: "31210" },
  { label: "LE BORN (31340)", value: "31340" },
  { label: "BOUDRAC (31580)", value: "31580" },
  { label: "BOULOC (31620)", value: "31620" },
  { label: "BOULOGNE SUR GESSE (31350)", value: "31350" },
  { label: "BOURG D OUEIL (31110)", value: "31110" },
  { label: "BOURG ST BERNARD (31570)", value: "31570" },
  { label: "BOUSSAN (31420)", value: "31420" },
  { label: "BOUSSENS (31360)", value: "31360" },
  { label: "BOUTX (31160)", value: "31160" },
  { label: "BOUTX (31160)", value: "31160" },
  { label: "BOUTX (31440)", value: "31440" },
  { label: "BOUTX (31440)", value: "31440" },
  { label: "BOUZIN (31420)", value: "31420" },
  { label: "BRAGAYRAC (31470)", value: "31470" },
  { label: "BRAX (31490)", value: "31490" },
  { label: "BRETX (31530)", value: "31530" },
  { label: "BRIGNEMONT (31480)", value: "31480" },
  { label: "BRUGUIERES (31150)", value: "31150" },
  { label: "BURGALAYS (31440)", value: "31440" },
  { label: "LE BURGAUD (31330)", value: "31330" },
  { label: "BUZET SUR TARN (31660)", value: "31660" },
  { label: "CABANAC CAZAUX (31160)", value: "31160" },
  { label: "CABANAC SEGUENVILLE (31480)", value: "31480" },
  { label: "LE CABANIAL (31460)", value: "31460" },
  { label: "CADOURS (31480)", value: "31480" },
  { label: "CAIGNAC (31560)", value: "31560" },
  { label: "CALMONT (31560)", value: "31560" },
  { label: "CAMBERNARD (31470)", value: "31470" },
  { label: "CAMBIAC (31460)", value: "31460" },
  { label: "CANENS (31310)", value: "31310" },
  { label: "CAPENS (31410)", value: "31410" },
  { label: "CARAGOUDES (31460)", value: "31460" },
  { label: "CARAMAN (31460)", value: "31460" },
  { label: "CARBONNE (31390)", value: "31390" },
  { label: "CARDEILHAC (31350)", value: "31350" },
  { label: "CASSAGNABERE TOURNAS (31420)", value: "31420" },
  { label: "CASSAGNE (31260)", value: "31260" },
  { label: "CASTAGNAC (31310)", value: "31310" },
  { label: "CASTAGNEDE (31260)", value: "31260" },
  { label: "CASTANET TOLOSAN (31320)", value: "31320" },
  { label: "CASTELBIAGUE (31160)", value: "31160" },
  { label: "CASTELGAILLARD (31230)", value: "31230" },
  { label: "CASTELGINEST (31780)", value: "31780" },
  { label: "CASTELMAUROU (31180)", value: "31180" },
  { label: "CASTELNAU D ESTRETEFONDS (31620)", value: "31620" },
  { label: "CASTELNAU PICAMPEAU (31430)", value: "31430" },
  { label: "LE CASTERA (31530)", value: "31530" },
  { label: "CASTERA VIGNOLES (31350)", value: "31350" },
  { label: "CASTIES LABRANDE (31430)", value: "31430" },
  { label: "CASTILLON DE LARBOUST (31110)", value: "31110" },
  { label: "CASTILLON DE ST MARTORY (31360)", value: "31360" },
  { label: "CATHERVIELLE (31110)", value: "31110" },
  { label: "CAUBIAC (31480)", value: "31480" },
  { label: "CAUBOUS (31110)", value: "31110" },
  { label: "CAUJAC (31190)", value: "31190" },
  { label: "CAZARILH LASPENES (31110)", value: "31110" },
  { label: "CAZARIL TAMBOURES (31580)", value: "31580" },
  { label: "CAZAUNOUS (31160)", value: "31160" },
  { label: "CAZAUX LAYRISSE (31440)", value: "31440" },
  { label: "CAZEAUX DE LARBOUST (31110)", value: "31110" },
  { label: "CAZENEUVE MONTAUT (31420)", value: "31420" },
  { label: "CAZERES (31220)", value: "31220" },
  { label: "CEPET (31620)", value: "31620" },
  { label: "CESSALES (31290)", value: "31290" },
  { label: "CHARLAS (31350)", value: "31350" },
  { label: "CHAUM (31440)", value: "31440" },
  { label: "CHEIN DESSUS (31160)", value: "31160" },
  { label: "CIADOUX (31350)", value: "31350" },
  { label: "CIER DE LUCHON (31110)", value: "31110" },
  { label: "CIER DE RIVIERE (31510)", value: "31510" },
  { label: "CIERP GAUD (31440)", value: "31440" },
  { label: "CIERP GAUD (31440)", value: "31440" },
  { label: "CINTEGABELLE (31550)", value: "31550" },
  { label: "CIRES (31110)", value: "31110" },
  { label: "CLARAC (31210)", value: "31210" },
  { label: "CLERMONT LE FORT (31810)", value: "31810" },
  { label: "COLOMIERS (31770)", value: "31770" },
  { label: "CORNEBARRIEU (31700)", value: "31700" },
  { label: "CORRONSAC (31450)", value: "31450" },
  { label: "COUEILLES (31230)", value: "31230" },
  { label: "COULADERE (31220)", value: "31220" },
  { label: "COURET (31160)", value: "31160" },
  { label: "COX (31480)", value: "31480" },
  { label: "CUGNAUX (31270)", value: "31270" },
  { label: "CUGURON (31210)", value: "31210" },
  { label: "LE CUING (31210)", value: "31210" },
  { label: "DAUX (31700)", value: "31700" },
  { label: "DEYME (31450)", value: "31450" },
  { label: "DONNEVILLE (31450)", value: "31450" },
  { label: "DREMIL LAFAGE (31280)", value: "31280" },
  { label: "DRUDAS (31480)", value: "31480" },
  { label: "EAUNES (31600)", value: "31600" },
  { label: "EMPEAUX (31470)", value: "31470" },
  { label: "ENCAUSSE LES THERMES (31160)", value: "31160" },
  { label: "EOUX (31420)", value: "31420" },
  { label: "ESCALQUENS (31750)", value: "31750" },
  { label: "ESCANECRABE (31350)", value: "31350" },
  { label: "ESPANES (31450)", value: "31450" },
  { label: "ESPARRON (31420)", value: "31420" },
  { label: "ESPERCE (31190)", value: "31190" },
  { label: "ESTADENS (31160)", value: "31160" },
  { label: "ESTANCARBON (31800)", value: "31800" },
  { label: "ESTENOS (31440)", value: "31440" },
  { label: "EUP (31440)", value: "31440" },
  { label: "FABAS (31230)", value: "31230" },
  { label: "LE FAGET (31460)", value: "31460" },
  { label: "FALGA (31540)", value: "31540" },
  { label: "LE FAUGA (31410)", value: "31410" },
  { label: "FENOUILLET (31150)", value: "31150" },
  { label: "FIGAROL (31260)", value: "31260" },
  { label: "FLOURENS (31130)", value: "31130" },
  { label: "FOLCARDE (31290)", value: "31290" },
  { label: "FONBEAUZARD (31140)", value: "31140" },
  { label: "FONSORBES (31470)", value: "31470" },
  { label: "FONTENILLES (31470)", value: "31470" },
  { label: "FORGUES (31370)", value: "31370" },
  { label: "FOS (31440)", value: "31440" },
  { label: "FOUGARON (31160)", value: "31160" },
  { label: "FOURQUEVAUX (31450)", value: "31450" },
  { label: "LE FOUSSERET (31430)", value: "31430" },
  { label: "FRANCARVILLE (31460)", value: "31460" },
  { label: "FRANCAZAL (31260)", value: "31260" },
  { label: "FRANCON (31420)", value: "31420" },
  { label: "FRANQUEVIELLE (31210)", value: "31210" },
  { label: "LE FRECHET (31360)", value: "31360" },
  { label: "FRONSAC (31440)", value: "31440" },
  { label: "FRONTIGNAN DE COMMINGES (31510)", value: "31510" },
  { label: "FRONTIGNAN SAVES (31230)", value: "31230" },
  { label: "FRONTON (31620)", value: "31620" },
  { label: "FROUZINS (31270)", value: "31270" },
  { label: "FUSTIGNAC (31430)", value: "31430" },
  { label: "GAGNAC SUR GARONNE (31150)", value: "31150" },
  { label: "GAILLAC TOULZA (31550)", value: "31550" },
  { label: "GALIE (31510)", value: "31510" },
  { label: "GANTIES (31160)", value: "31160" },
  { label: "GARAC (31480)", value: "31480" },
  { label: "GARDOUCH (31290)", value: "31290" },
  { label: "GARGAS (31620)", value: "31620" },
  { label: "GARIDECH (31380)", value: "31380" },
  { label: "GARIN (31110)", value: "31110" },
  { label: "GAURE (31590)", value: "31590" },
  { label: "GEMIL (31380)", value: "31380" },
  { label: "GENOS (31510)", value: "31510" },
  { label: "GENSAC DE BOULOGNE (31350)", value: "31350" },
  { label: "GENSAC SUR GARONNE (31310)", value: "31310" },
  { label: "GIBEL (31560)", value: "31560" },
  { label: "GOUAUX DE LARBOUST (31110)", value: "31110" },
  { label: "GOUAUX DE LUCHON (31110)", value: "31110" },
  { label: "GOUDEX (31230)", value: "31230" },
  { label: "GOURDAN POLIGNAN (31210)", value: "31210" },
  { label: "GOUTEVERNISSE (31310)", value: "31310" },
  { label: "GOUZENS (31310)", value: "31310" },
  { label: "GOYRANS (31120)", value: "31120" },
  { label: "GRAGNAGUE (31380)", value: "31380" },
  { label: "GRATENS (31430)", value: "31430" },
  { label: "GRATENTOUR (31150)", value: "31150" },
  { label: "GRAZAC (31190)", value: "31190" },
  { label: "GRENADE (31330)", value: "31330" },
  { label: "GREPIAC (31190)", value: "31190" },
  { label: "LE GRES (31480)", value: "31480" },
  { label: "GURAN (31440)", value: "31440" },
  { label: "HERRAN (31160)", value: "31160" },
  { label: "HIS (31260)", value: "31260" },
  { label: "HUOS (31210)", value: "31210" },
  { label: "L'ISLE EN DODON (31230)", value: "31230" },
  { label: "ISSUS (31450)", value: "31450" },
  { label: "IZAUT DE L HOTEL (31160)", value: "31160" },
  { label: "JURVIELLE (31110)", value: "31110" },
  { label: "JUZES (31540)", value: "31540" },
  { label: "JUZET DE LUCHON (31110)", value: "31110" },
  { label: "JUZET D IZAUT (31160)", value: "31160" },
  { label: "LABARTHE INARD (31800)", value: "31800" },
  { label: "LABARTHE RIVIERE (31800)", value: "31800" },
  { label: "LABARTHE SUR LEZE (31860)", value: "31860" },
  { label: "LABASTIDE BEAUVOIR (31450)", value: "31450" },
  { label: "LABASTIDE CLERMONT (31370)", value: "31370" },
  { label: "LABASTIDE PAUMES (31230)", value: "31230" },
  { label: "LABASTIDE ST SERNIN (31620)", value: "31620" },
  { label: "LABASTIDETTE (31600)", value: "31600" },
  { label: "LABEGE (31670)", value: "31670" },
  { label: "LABROQUERE (31510)", value: "31510" },
  { label: "LABRUYERE DORSA (31190)", value: "31190" },
  { label: "LACAUGNE (31390)", value: "31390" },
  { label: "LACROIX FALGARDE (31120)", value: "31120" },
  { label: "LAFFITE TOUPIERE (31360)", value: "31360" },
  { label: "LAFITTE VIGORDANE (31390)", value: "31390" },
  { label: "LAGARDE (31290)", value: "31290" },
  { label: "LAGARDELLE SUR LEZE (31870)", value: "31870" },
  { label: "LAGRACE DIEU (31190)", value: "31190" },
  { label: "LAGRAULET ST NICOLAS (31480)", value: "31480" },
  { label: "LAHAGE (31370)", value: "31370" },
  { label: "LAHITERE (31310)", value: "31310" },
  { label: "LALOURET LAFFITEAU (31800)", value: "31800" },
  { label: "LAMASQUERE (31600)", value: "31600" },
  { label: "LANDORTHE (31800)", value: "31800" },
  { label: "LANTA (31570)", value: "31570" },
  { label: "LAPEYRERE (31310)", value: "31310" },
  { label: "LAPEYROUSE FOSSAT (31180)", value: "31180" },
  { label: "LARCAN (31800)", value: "31800" },
  { label: "LAREOLE (31480)", value: "31480" },
  { label: "LARROQUE (31580)", value: "31580" },
  { label: "LASSERRE PRADERE (31530)", value: "31530" },
  { label: "LASSERRE PRADERE (31530)", value: "31530" },
  { label: "LATOUE (31800)", value: "31800" },
  { label: "LATOUR (31310)", value: "31310" },
  { label: "LATRAPE (31310)", value: "31310" },
  { label: "LAUNAC (31330)", value: "31330" },
  { label: "LAUNAGUET (31140)", value: "31140" },
  { label: "LAUTIGNAC (31370)", value: "31370" },
  { label: "LAUZERVILLE (31650)", value: "31650" },
  { label: "LAVALETTE (31590)", value: "31590" },
  { label: "LAVELANET DE COMMINGES (31220)", value: "31220" },
  { label: "LAVERNOSE LACASSE (31410)", value: "31410" },
  { label: "LAYRAC SUR TARN (31340)", value: "31340" },
  { label: "LECUSSAN (31580)", value: "31580" },
  { label: "LEGE (31440)", value: "31440" },
  { label: "LEGUEVIN (31490)", value: "31490" },
  { label: "LESCUNS (31220)", value: "31220" },
  { label: "LESPINASSE (31150)", value: "31150" },
  { label: "LESPITEAU (31160)", value: "31160" },
  { label: "LESPUGUE (31350)", value: "31350" },
  { label: "LESTELLE DE ST MARTORY (31360)", value: "31360" },
  { label: "LEVIGNAC (31530)", value: "31530" },
  { label: "LHERM (31600)", value: "31600" },
  { label: "LIEOUX (31800)", value: "31800" },
  { label: "LILHAC (31230)", value: "31230" },
  { label: "LODES (31800)", value: "31800" },
  { label: "LONGAGES (31410)", value: "31410" },
  { label: "LOUBENS LAURAGAIS (31460)", value: "31460" },
  { label: "LOUDET (31580)", value: "31580" },
  { label: "LOURDE (31510)", value: "31510" },
  { label: "LUSCAN (31510)", value: "31510" },
  { label: "LUSSAN ADEILHAC (31430)", value: "31430" },
  { label: "LUX (31290)", value: "31290" },
  { label: "LA MAGDELAINE SUR TARN (31340)", value: "31340" },
  { label: "MAILHOLAS (31310)", value: "31310" },
  { label: "MALVEZIE (31510)", value: "31510" },
  { label: "MANCIOUX (31360)", value: "31360" },
  { label: "MANE (31260)", value: "31260" },
  { label: "MARIGNAC (31440)", value: "31440" },
  { label: "MARIGNAC LASCLARES (31430)", value: "31430" },
  { label: "MARIGNAC LASPEYRES (31220)", value: "31220" },
  { label: "MARLIAC (31550)", value: "31550" },
  { label: "MARQUEFAVE (31390)", value: "31390" },
  { label: "MARSOULAS (31260)", value: "31260" },
  { label: "MARTISSERRE (31230)", value: "31230" },
  { label: "MARTRES DE RIVIERE (31210)", value: "31210" },
  { label: "MARTRES TOLOSANE (31220)", value: "31220" },
  { label: "MASCARVILLE (31460)", value: "31460" },
  { label: "MASSABRAC (31310)", value: "31310" },
  { label: "MAURAN (31220)", value: "31220" },
  { label: "MAUREMONT (31290)", value: "31290" },
  { label: "MAURENS (31540)", value: "31540" },
  { label: "MAURESSAC (31190)", value: "31190" },
  { label: "MAUREVILLE (31460)", value: "31460" },
  { label: "MAUVAISIN (31190)", value: "31190" },
  { label: "MAUVEZIN (31230)", value: "31230" },
  { label: "MAUZAC (31410)", value: "31410" },
  { label: "MAYREGNE (31110)", value: "31110" },
  { label: "MAZERES SUR SALAT (31260)", value: "31260" },
  { label: "MELLES (31440)", value: "31440" },
  { label: "MENVILLE (31530)", value: "31530" },
  { label: "MERENVIELLE (31530)", value: "31530" },
  { label: "MERVILLA (31320)", value: "31320" },
  { label: "MERVILLE (31330)", value: "31330" },
  { label: "MILHAS (31160)", value: "31160" },
  { label: "MIRAMBEAU (31230)", value: "31230" },
  { label: "MIRAMONT DE COMMINGES (31800)", value: "31800" },
  { label: "MIREMONT (31190)", value: "31190" },
  { label: "MIREPOIX SUR TARN (31340)", value: "31340" },
  { label: "MOLAS (31230)", value: "31230" },
  { label: "MONCAUP (31160)", value: "31160" },
  { label: "MONDAVEZAN (31220)", value: "31220" },
  { label: "MONDILHAN (31350)", value: "31350" },
  { label: "MONDONVILLE (31700)", value: "31700" },
  { label: "MONDOUZIL (31850)", value: "31850" },
  { label: "MONES (31370)", value: "31370" },
  { label: "MONESTROL (31560)", value: "31560" },
  { label: "MONS (31280)", value: "31280" },
  { label: "MONTAIGUT SUR SAVE (31530)", value: "31530" },
  { label: "MONTASTRUC DE SALIES (31160)", value: "31160" },
  { label: "MONTASTRUC LA CONSEILLERE (31380)", value: "31380" },
  { label: "MONTASTRUC SAVES (31370)", value: "31370" },
  { label: "MONTAUBAN DE LUCHON (31110)", value: "31110" },
  { label: "MONTAUT (31410)", value: "31410" },
  { label: "MONTBERAUD (31220)", value: "31220" },
  { label: "MONTBERNARD (31230)", value: "31230" },
  { label: "MONTBERON (31140)", value: "31140" },
  { label: "MONTBRUN BOCAGE (31310)", value: "31310" },
  { label: "MONTBRUN LAURAGAIS (31450)", value: "31450" },
  { label: "MONTCLAR DE COMMINGES (31220)", value: "31220" },
  { label: "MONTCLAR LAURAGAIS (31290)", value: "31290" },
  { label: "MONT DE GALIE (31510)", value: "31510" },
  { label: "MONTEGUT BOURJAC (31430)", value: "31430" },
  { label: "MONTEGUT LAURAGAIS (31540)", value: "31540" },
  { label: "MONTESPAN (31260)", value: "31260" },
  { label: "MONTESQUIEU GUITTAUT (31230)", value: "31230" },
  { label: "MONTESQUIEU LAURAGAIS (31450)", value: "31450" },
  { label: "MONTESQUIEU VOLVESTRE (31310)", value: "31310" },
  { label: "MONTGAILLARD DE SALIES (31260)", value: "31260" },
  { label: "MONTGAILLARD LAURAGAIS (31290)", value: "31290" },
  { label: "MONTGAILLARD SUR SAVE (31350)", value: "31350" },
  { label: "MONTGAZIN (31410)", value: "31410" },
  { label: "MONTGEARD (31560)", value: "31560" },
  { label: "MONTGISCARD (31450)", value: "31450" },
  { label: "MONTGRAS (31370)", value: "31370" },
  { label: "MONTJOIRE (31380)", value: "31380" },
  { label: "MONTLAUR (31450)", value: "31450" },
  { label: "MONTMAURIN (31350)", value: "31350" },
  { label: "MONTOULIEU ST BERNARD (31420)", value: "31420" },
  { label: "MONTOUSSIN (31430)", value: "31430" },
  { label: "MONTPITOL (31380)", value: "31380" },
  { label: "MONTRABE (31850)", value: "31850" },
  { label: "MONTREJEAU (31210)", value: "31210" },
  { label: "MONTSAUNES (31260)", value: "31260" },
  { label: "MOURVILLES BASSES (31460)", value: "31460" },
  { label: "MOURVILLES HAUTES (31540)", value: "31540" },
  { label: "MOUSTAJON (31110)", value: "31110" },
  { label: "MURET (31600)", value: "31600" },
  { label: "NAILLOUX (31560)", value: "31560" },
  { label: "NENIGAN (31350)", value: "31350" },
  { label: "NIZAN GESSE (31350)", value: "31350" },
  { label: "NOE (31410)", value: "31410" },
  { label: "NOGARET (31540)", value: "31540" },
  { label: "NOUEILLES (31450)", value: "31450" },
  { label: "ODARS (31450)", value: "31450" },
  { label: "ONDES (31330)", value: "31330" },
  { label: "OO (31110)", value: "31110" },
  { label: "ORE (31510)", value: "31510" },
  { label: "PALAMINY (31220)", value: "31220" },
  { label: "PAULHAC (31380)", value: "31380" },
  { label: "PAYSSOUS (31510)", value: "31510" },
  { label: "PECHABOU (31320)", value: "31320" },
  { label: "PECHBONNIEU (31140)", value: "31140" },
  { label: "PECHBUSQUE (31320)", value: "31320" },
  { label: "PEGUILHAN (31350)", value: "31350" },
  { label: "PEGUILHAN (31350)", value: "31350" },
  { label: "PELLEPORT (31480)", value: "31480" },
  { label: "PEYRISSAS (31420)", value: "31420" },
  { label: "PEYROUZET (31420)", value: "31420" },
  { label: "PEYSSIES (31390)", value: "31390" },
  { label: "PIBRAC (31820)", value: "31820" },
  { label: "PIN BALMA (31130)", value: "31130" },
  { label: "LE PIN MURELET (31370)", value: "31370" },
  { label: "PINSAGUEL (31120)", value: "31120" },
  { label: "PINS JUSTARET (31860)", value: "31860" },
  { label: "PLAGNE (31220)", value: "31220" },
  { label: "PLAGNOLE (31370)", value: "31370" },
  { label: "PLAISANCE DU TOUCH (31830)", value: "31830" },
  { label: "LE PLAN (31220)", value: "31220" },
  { label: "POINTIS DE RIVIERE (31210)", value: "31210" },
  { label: "POINTIS INARD (31800)", value: "31800" },
  { label: "POLASTRON (31430)", value: "31430" },
  { label: "POMPERTUZAT (31450)", value: "31450" },
  { label: "PONLAT TAILLEBOURG (31210)", value: "31210" },
  { label: "PORTET D ASPET (31160)", value: "31160" },
  { label: "PORTET DE LUCHON (31110)", value: "31110" },
  { label: "PORTET SUR GARONNE (31120)", value: "31120" },
  { label: "POUBEAU (31110)", value: "31110" },
  { label: "POUCHARRAMET (31370)", value: "31370" },
  { label: "POUY DE TOUGES (31430)", value: "31430" },
  { label: "POUZE (31450)", value: "31450" },
  { label: "PRESERVILLE (31570)", value: "31570" },
  { label: "PROUPIARY (31360)", value: "31360" },
  { label: "PRUNET (31460)", value: "31460" },
  { label: "PUYDANIEL (31190)", value: "31190" },
  { label: "PUYMAURIN (31230)", value: "31230" },
  { label: "PUYSSEGUR (31480)", value: "31480" },
  { label: "QUINT FONSEGRIVES (31130)", value: "31130" },
  { label: "RAMONVILLE ST AGNE (31520)", value: "31520" },
  { label: "RAZECUEILLE (31160)", value: "31160" },
  { label: "REBIGUE (31320)", value: "31320" },
  { label: "REGADES (31800)", value: "31800" },
  { label: "RENNEVILLE (31290)", value: "31290" },
  { label: "REVEL (31250)", value: "31250" },
  { label: "REVEL (31250)", value: "31250" },
  { label: "RIEUCAZE (31800)", value: "31800" },
  { label: "RIEUMAJOU (31290)", value: "31290" },
  { label: "RIEUMES (31370)", value: "31370" },
  { label: "RIEUX VOLVESTRE (31310)", value: "31310" },
  { label: "RIOLAS (31230)", value: "31230" },
  { label: "ROQUEFORT SUR GARONNE (31360)", value: "31360" },
  { label: "ROQUES (31120)", value: "31120" },
  { label: "ROQUESERIERE (31380)", value: "31380" },
  { label: "ROQUETTES (31120)", value: "31120" },
  { label: "ROUEDE (31160)", value: "31160" },
  { label: "ROUFFIAC TOLOSAN (31180)", value: "31180" },
  { label: "ROUMENS (31540)", value: "31540" },
  { label: "SABONNERES (31370)", value: "31370" },
  { label: "SACCOURVIELLE (31110)", value: "31110" },
  { label: "SAIGUEDE (31470)", value: "31470" },
  { label: "ST ALBAN (31140)", value: "31140" },
  { label: "ST ANDRE (31420)", value: "31420" },
  { label: "ST ARAILLE (31430)", value: "31430" },
  { label: "ST AVENTIN (31110)", value: "31110" },
  { label: "ST BEAT LEZ (31440)", value: "31440" },
  { label: "ST BEAT LEZ (31440)", value: "31440" },
  { label: "ST BERTRAND DE COMMINGES (31510)", value: "31510" },
  { label: "ST CEZERT (31330)", value: "31330" },
  { label: "ST CHRISTAUD (31310)", value: "31310" },
  { label: "ST CLAR DE RIVIERE (31600)", value: "31600" },
  { label: "ST ELIX LE CHATEAU (31430)", value: "31430" },
  { label: "ST ELIX SEGLAN (31420)", value: "31420" },
  { label: "ST FELIX LAURAGAIS (31540)", value: "31540" },
  { label: "ST FERREOL DE COMMINGES (31350)", value: "31350" },
  { label: "STE FOY D AIGREFEUILLE (31570)", value: "31570" },
  { label: "STE FOY DE PEYROLIERES (31470)", value: "31470" },
  { label: "ST FRAJOU (31230)", value: "31230" },
  { label: "ST GAUDENS (31800)", value: "31800" },
  { label: "ST GENIES BELLEVUE (31180)", value: "31180" },
  { label: "ST GERMIER (31290)", value: "31290" },
  { label: "ST HILAIRE (31410)", value: "31410" },
  { label: "ST IGNAN (31800)", value: "31800" },
  { label: "ST JEAN (31240)", value: "31240" },
  { label: "ST JEAN LHERM (31380)", value: "31380" },
  { label: "ST JORY (31790)", value: "31790" },
  { label: "ST JULIA (31540)", value: "31540" },
  { label: "ST JULIEN SUR GARONNE (31220)", value: "31220" },
  { label: "ST LARY BOUJEAN (31350)", value: "31350" },
  { label: "ST LAURENT (31230)", value: "31230" },
  { label: "ST LEON (31560)", value: "31560" },
  { label: "STE LIVRADE (31530)", value: "31530" },
  { label: "ST LOUP CAMMAS (31140)", value: "31140" },
  { label: "ST LOUP EN COMMINGES (31350)", value: "31350" },
  { label: "ST LYS (31470)", value: "31470" },
  { label: "ST MAMET (31110)", value: "31110" },
  { label: "ST MARCEL PAULEL (31590)", value: "31590" },
  { label: "ST MARCET (31800)", value: "31800" },
  { label: "ST MARTORY (31360)", value: "31360" },
  { label: "ST MEDARD (31360)", value: "31360" },
  { label: "ST MICHEL (31220)", value: "31220" },
  { label: "ST ORENS DE GAMEVILLE (31650)", value: "31650" },
  { label: "ST PAUL SUR SAVE (31530)", value: "31530" },
  { label: "ST PAUL D OUEIL (31110)", value: "31110" },
  { label: "ST PE D ARDET (31510)", value: "31510" },
  { label: "ST PE DELBOSC (31350)", value: "31350" },
  { label: "ST PIERRE (31590)", value: "31590" },
  { label: "ST PIERRE DE LAGES (31570)", value: "31570" },
  { label: "ST PLANCARD (31580)", value: "31580" },
  { label: "ST ROME (31290)", value: "31290" },
  { label: "ST RUSTICE (31620)", value: "31620" },
  { label: "ST SAUVEUR (31790)", value: "31790" },
  { label: "ST SULPICE SUR LEZE (31410)", value: "31410" },
  { label: "ST THOMAS (31470)", value: "31470" },
  { label: "ST VINCENT (31290)", value: "31290" },
  { label: "SAJAS (31370)", value: "31370" },
  { label: "SALEICH (31260)", value: "31260" },
  { label: "SALERM (31230)", value: "31230" },
  { label: "SALIES DU SALAT (31260)", value: "31260" },
  { label: "SALLES ET PRATVIEL (31110)", value: "31110" },
  { label: "SALLES SUR GARONNE (31390)", value: "31390" },
  { label: "LA SALVETAT ST GILLES (31880)", value: "31880" },
  { label: "LA SALVETAT LAURAGAIS (31460)", value: "31460" },
  { label: "SAMAN (31350)", value: "31350" },
  { label: "SAMOUILLAN (31420)", value: "31420" },
  { label: "SANA (31220)", value: "31220" },
  { label: "SARRECAVE (31350)", value: "31350" },
  { label: "SARREMEZAN (31350)", value: "31350" },
  { label: "SAUBENS (31600)", value: "31600" },
  { label: "SAUSSENS (31460)", value: "31460" },
  { label: "SAUVETERRE DE COMMINGES (31510)", value: "31510" },
  { label: "SAUX ET POMAREDE (31800)", value: "31800" },
  { label: "SAVARTHES (31800)", value: "31800" },
  { label: "SAVERES (31370)", value: "31370" },
  { label: "SEDEILHAC (31580)", value: "31580" },
  { label: "SEGREVILLE (31460)", value: "31460" },
  { label: "SEILH (31840)", value: "31840" },
  { label: "SEILHAN (31510)", value: "31510" },
  { label: "SENARENS (31430)", value: "31430" },
  { label: "SENGOUAGNET (31160)", value: "31160" },
  { label: "SEPX (31360)", value: "31360" },
  { label: "SEYRE (31560)", value: "31560" },
  { label: "SEYSSES (31600)", value: "31600" },
  { label: "SIGNAC (31440)", value: "31440" },
  { label: "SODE (31110)", value: "31110" },
  { label: "SOUEICH (31160)", value: "31160" },
  { label: "TARABEL (31570)", value: "31570" },
  { label: "TERREBASSE (31420)", value: "31420" },
  { label: "THIL (31530)", value: "31530" },
  { label: "TOUILLE (31260)", value: "31260" },
  { label: "TOULOUSE (31000)", value: "31000" },
  { label: "TOULOUSE (31100)", value: "31100" },
  { label: "TOULOUSE (31200)", value: "31200" },
  { label: "TOULOUSE (31300)", value: "31300" },
  { label: "TOULOUSE (31400)", value: "31400" },
  { label: "TOULOUSE (31500)", value: "31500" },
  { label: "LES TOURREILLES (31210)", value: "31210" },
  { label: "TOURNEFEUILLE (31170)", value: "31170" },
  { label: "TOUTENS (31460)", value: "31460" },
  { label: "TREBONS DE LUCHON (31110)", value: "31110" },
  { label: "TREBONS SUR LA GRASSE (31290)", value: "31290" },
  { label: "L'UNION (31240)", value: "31240" },
  { label: "URAU (31260)", value: "31260" },
  { label: "VACQUIERS (31340)", value: "31340" },
  { label: "VALCABRERE (31510)", value: "31510" },
  { label: "VALENTINE (31800)", value: "31800" },
  { label: "VALLEGUE (31290)", value: "31290" },
  { label: "VALLESVILLES (31570)", value: "31570" },
  { label: "VARENNES (31450)", value: "31450" },
  { label: "VAUDREUILLE (31250)", value: "31250" },
  { label: "VAUX (31540)", value: "31540" },
  { label: "VENDINE (31460)", value: "31460" },
  { label: "VENERQUE (31810)", value: "31810" },
  { label: "VERFEIL (31590)", value: "31590" },
  { label: "VERNET (31810)", value: "31810" },
  { label: "VIEILLE TOULOUSE (31320)", value: "31320" },
  { label: "VIEILLEVIGNE (31290)", value: "31290" },
  { label: "VIGNAUX (31480)", value: "31480" },
  { label: "VIGOULET AUZIL (31320)", value: "31320" },
  { label: "VILLARIES (31380)", value: "31380" },
  { label: "VILLATE (31860)", value: "31860" },
  { label: "VILLAUDRIC (31620)", value: "31620" },
  { label: "VILLEFRANCHE DE LAURAGAIS (31290)", value: "31290" },
  { label: "VILLEMATIER (31340)", value: "31340" },
  { label: "VILLEMUR SUR TARN (31340)", value: "31340" },
  { label: "VILLENEUVE DE RIVIERE (31800)", value: "31800" },
  { label: "VILLENEUVE LECUSSAN (31580)", value: "31580" },
  { label: "VILLENEUVE LES BOULOC (31620)", value: "31620" },
  { label: "VILLENEUVE TOLOSANE (31270)", value: "31270" },
  { label: "VILLENOUVELLE (31290)", value: "31290" },
  { label: "BINOS (31440)", value: "31440" },
  { label: "ESCOULIS (31260)", value: "31260" },
  { label: "LARRA (31330)", value: "31330" },
  { label: "CAZAC (31230)", value: "31230" },
  { label: "AIGNAN (32290)", value: "32290" },
  { label: "ANSAN (32270)", value: "32270" },
  { label: "ANTRAS (32360)", value: "32360" },
  { label: "ARBLADE LE BAS (32720)", value: "32720" },
  { label: "ARBLADE LE HAUT (32110)", value: "32110" },
  { label: "ARDIZAS (32430)", value: "32430" },
  { label: "ARMENTIEUX (32230)", value: "32230" },
  { label: "ARMOUS ET CAU (32230)", value: "32230" },
  { label: "ARROUEDE (32140)", value: "32140" },
  { label: "AUBIET (32270)", value: "32270" },
  { label: "AUCH (32000)", value: "32000" },
  { label: "AUGNAX (32120)", value: "32120" },
  { label: "AUJAN MOURNEDE (32300)", value: "32300" },
  { label: "AURADE (32600)", value: "32600" },
  { label: "AURENSAN (32400)", value: "32400" },
  { label: "AURIMONT (32450)", value: "32450" },
  { label: "AUTERIVE (32550)", value: "32550" },
  { label: "AUX AUSSAT (32170)", value: "32170" },
  { label: "AVENSAC (32120)", value: "32120" },
  { label: "AVERON BERGELLE (32290)", value: "32290" },
  { label: "AVEZAN (32380)", value: "32380" },
  { label: "AYGUETINTE (32410)", value: "32410" },
  { label: "AYZIEU (32800)", value: "32800" },
  { label: "BAJONNETTE (32120)", value: "32120" },
  { label: "BARCELONNE DU GERS (32720)", value: "32720" },
  { label: "BARCUGNAN (32170)", value: "32170" },
  { label: "BARRAN (32350)", value: "32350" },
  { label: "BARS (32300)", value: "32300" },
  { label: "BASCOUS (32190)", value: "32190" },
  { label: "BASSOUES (32320)", value: "32320" },
  { label: "BAZIAN (32320)", value: "32320" },
  { label: "BAZUGUES (32170)", value: "32170" },
  { label: "BEAUCAIRE (32410)", value: "32410" },
  { label: "BEAUMARCHES (32160)", value: "32160" },
  { label: "BEAUMONT (32100)", value: "32100" },
  { label: "BEAUPUY (32600)", value: "32600" },
  { label: "BECCAS (32730)", value: "32730" },
  { label: "BEDECHAN (32450)", value: "32450" },
  { label: "BELLEGARDE (32140)", value: "32140" },
  { label: "BELLOC ST CLAMENS (32300)", value: "32300" },
  { label: "BELMONT (32190)", value: "32190" },
  { label: "BERAUT (32100)", value: "32100" },
  { label: "BERDOUES (32300)", value: "32300" },
  { label: "BERNEDE (32400)", value: "32400" },
  { label: "BERRAC (32480)", value: "32480" },
  { label: "BETCAVE AGUIN (32420)", value: "32420" },
  { label: "BETOUS (32110)", value: "32110" },
  { label: "BETPLAN (32730)", value: "32730" },
  { label: "BEZERIL (32130)", value: "32130" },
  { label: "BEZOLLES (32310)", value: "32310" },
  { label: "BEZUES BAJON (32140)", value: "32140" },
  { label: "BIRAN (32350)", value: "32350" },
  { label: "BIVES (32380)", value: "32380" },
  { label: "BLANQUEFORT (32270)", value: "32270" },
  { label: "BLAZIERT (32100)", value: "32100" },
  { label: "BLOUSSON SERIAN (32230)", value: "32230" },
  { label: "BONAS (32410)", value: "32410" },
  { label: "BOUCAGNERES (32550)", value: "32550" },
  { label: "BOULAUR (32450)", value: "32450" },
  { label: "BOURROUILLAN (32370)", value: "32370" },
  { label: "BOUZON GELLENAVE (32290)", value: "32290" },
  { label: "BRETAGNE D ARMAGNAC (32800)", value: "32800" },
  { label: "LE BROUILH MONBERT (32350)", value: "32350" },
  { label: "LE BROUILH MONBERT (32350)", value: "32350" },
  { label: "BRUGNENS (32500)", value: "32500" },
  { label: "CABAS LOUMASSES (32140)", value: "32140" },
  { label: "CADEILHAN (32380)", value: "32380" },
  { label: "CADEILLAN (32220)", value: "32220" },
  { label: "CAHUZAC SUR ADOUR (32400)", value: "32400" },
  { label: "CAILLAVET (32190)", value: "32190" },
  { label: "CALLIAN (32190)", value: "32190" },
  { label: "CAMPAGNE D ARMAGNAC (32800)", value: "32800" },
  { label: "CASSAIGNE (32100)", value: "32100" },
  { label: "CASTELNAU BARBARENS (32450)", value: "32450" },
  { label: "CASTELNAU D ANGLES (32320)", value: "32320" },
  { label: "CASTELNAU D ARBIEU (32500)", value: "32500" },
  { label: "CASTELNAU D AUZAN LABARRERE (32250)", value: "32250" },
  { label: "CASTELNAU D AUZAN LABARRERE (32440)", value: "32440" },
  { label: "CASTELNAU SUR L AUVIGNON (32100)", value: "32100" },
  { label: "CASTELNAVET (32290)", value: "32290" },
  { label: "CASTERA LECTOUROIS (32700)", value: "32700" },
  { label: "CASTERA VERDUZAN (32410)", value: "32410" },
  { label: "CASTERON (32380)", value: "32380" },
  { label: "CASTET ARROUY (32340)", value: "32340" },
  { label: "CASTEX (32170)", value: "32170" },
  { label: "CASTEX D ARMAGNAC (32240)", value: "32240" },
  { label: "CASTILLON DEBATS (32190)", value: "32190" },
  { label: "CASTILLON MASSAS (32360)", value: "32360" },
  { label: "CASTILLON SAVES (32490)", value: "32490" },
  { label: "CASTIN (32810)", value: "32810" },
  { label: "CATONVIELLE (32200)", value: "32200" },
  { label: "CAUMONT (32400)", value: "32400" },
  { label: "CAUPENNE D ARMAGNAC (32110)", value: "32110" },
  { label: "CAUSSENS (32100)", value: "32100" },
  { label: "CAZAUBON (32150)", value: "32150" },
  { label: "CAZAUBON (32150)", value: "32150" },
  { label: "CAZAUX D ANGLES (32190)", value: "32190" },
  { label: "CAZAUX SAVES (32130)", value: "32130" },
  { label: "CAZAUX VILLECOMTAL (32230)", value: "32230" },
  { label: "CAZENEUVE (32800)", value: "32800" },
  { label: "CERAN (32500)", value: "32500" },
  { label: "CEZAN (32410)", value: "32410" },
  { label: "CHELAN (32140)", value: "32140" },
  { label: "CLERMONT POUYGUILLES (32300)", value: "32300" },
  { label: "CLERMONT SAVES (32600)", value: "32600" },
  { label: "COLOGNE (32430)", value: "32430" },
  { label: "CONDOM (32100)", value: "32100" },
  { label: "CORNEILLAN (32400)", value: "32400" },
  { label: "COULOUME MONDEBAT (32160)", value: "32160" },
  { label: "COURRENSAN (32330)", value: "32330" },
  { label: "COURTIES (32230)", value: "32230" },
  { label: "CRASTES (32270)", value: "32270" },
  { label: "CRAVENCERES (32110)", value: "32110" },
  { label: "CUELAS (32300)", value: "32300" },
  { label: "DEMU (32190)", value: "32190" },
  { label: "DUFFORT (32170)", value: "32170" },
  { label: "DURAN (32810)", value: "32810" },
  { label: "DURBAN (32260)", value: "32260" },
  { label: "EAUZE (32800)", value: "32800" },
  { label: "ENCAUSSE (32430)", value: "32430" },
  { label: "ENDOUFIELLE (32600)", value: "32600" },
  { label: "ESCLASSAN LABASTIDE (32140)", value: "32140" },
  { label: "ESCORNEBOEUF (32200)", value: "32200" },
  { label: "ESPAON (32220)", value: "32220" },
  { label: "ESPAS (32370)", value: "32370" },
  { label: "ESTAMPES (32170)", value: "32170" },
  { label: "ESTANG (32240)", value: "32240" },
  { label: "ESTIPOUY (32300)", value: "32300" },
  { label: "ESTRAMIAC (32380)", value: "32380" },
  { label: "FAGET ABBATIAL (32450)", value: "32450" },
  { label: "FLAMARENS (32340)", value: "32340" },
  { label: "FLEURANCE (32500)", value: "32500" },
  { label: "FOURCES (32250)", value: "32250" },
  { label: "FREGOUVILLE (32490)", value: "32490" },
  { label: "FUSTEROUAU (32400)", value: "32400" },
  { label: "GALIAX (32160)", value: "32160" },
  { label: "GARRAVET (32220)", value: "32220" },
  { label: "GAUDONVILLE (32380)", value: "32380" },
  { label: "GAUJAC (32220)", value: "32220" },
  { label: "GAUJAN (32420)", value: "32420" },
  { label: "GAVARRET SUR AULOUSTE (32390)", value: "32390" },
  { label: "GAZAUPOUY (32480)", value: "32480" },
  { label: "GAZAX ET BACCARISSE (32230)", value: "32230" },
  { label: "GEE RIVIERE (32720)", value: "32720" },
  { label: "GIMBREDE (32340)", value: "32340" },
  { label: "GIMONT (32200)", value: "32200" },
  { label: "GISCARO (32200)", value: "32200" },
  { label: "GONDRIN (32330)", value: "32330" },
  { label: "GOUTZ (32500)", value: "32500" },
  { label: "GOUX (32400)", value: "32400" },
  { label: "HAGET (32730)", value: "32730" },
  { label: "HAULIES (32550)", value: "32550" },
  { label: "HOMPS (32120)", value: "32120" },
  { label: "LE HOUGA (32460)", value: "32460" },
  { label: "IDRAC RESPAILLES (32300)", value: "32300" },
  { label: "L'ISLE ARNE (32270)", value: "32270" },
  { label: "L'ISLE BOUZON (32380)", value: "32380" },
  { label: "L'ISLE DE NOE (32300)", value: "32300" },
  { label: "L'ISLE JOURDAIN (32600)", value: "32600" },
  { label: "IZOTGES (32400)", value: "32400" },
  { label: "JEGUN (32360)", value: "32360" },
  { label: "JU BELLOC (32160)", value: "32160" },
  { label: "JUILLAC (32230)", value: "32230" },
  { label: "JUILLES (32200)", value: "32200" },
  { label: "JUSTIAN (32190)", value: "32190" },
  { label: "LAAS (32170)", value: "32170" },
  { label: "LABARTHE (32260)", value: "32260" },
  { label: "LABARTHETE (32400)", value: "32400" },
  { label: "LABASTIDE SAVES (32130)", value: "32130" },
  { label: "LABEJAN (32300)", value: "32300" },
  { label: "LABRIHE (32120)", value: "32120" },
  { label: "LADEVEZE RIVIERE (32230)", value: "32230" },
  { label: "LADEVEZE VILLE (32230)", value: "32230" },
  { label: "LAGARDE (32700)", value: "32700" },
  { label: "LAGARDE HACHAN (32300)", value: "32300" },
  { label: "LAGARDERE (32310)", value: "32310" },
  { label: "LAGRAULET DU GERS (32330)", value: "32330" },
  { label: "LAGUIAN MAZOUS (32170)", value: "32170" },
  { label: "LAHAS (32130)", value: "32130" },
  { label: "LAHITTE (32810)", value: "32810" },
  { label: "LALANNE (32500)", value: "32500" },
  { label: "LALANNE ARQUE (32140)", value: "32140" },
  { label: "LAMAGUERE (32260)", value: "32260" },
  { label: "LAMAZERE (32300)", value: "32300" },
  { label: "LAMOTHE GOAS (32500)", value: "32500" },
  { label: "LANNEMAIGNAN (32240)", value: "32240" },
  { label: "LANNEPAX (32190)", value: "32190" },
  { label: "LANNE SOUBIRAN (32110)", value: "32110" },
  { label: "LANNUX (32400)", value: "32400" },
  { label: "LAREE (32150)", value: "32150" },
  { label: "LARRESSINGLE (32100)", value: "32100" },
  { label: "LARROQUE ENGALIN (32480)", value: "32480" },
  { label: "LARROQUE ST SERNIN (32410)", value: "32410" },
  { label: "LARROQUE SUR L OSSE (32100)", value: "32100" },
  { label: "LARTIGUE (32450)", value: "32450" },
  { label: "LASSERRADE (32160)", value: "32160" },
  { label: "LASSERAN (32550)", value: "32550" },
  { label: "LASSEUBE PROPRE (32550)", value: "32550" },
  { label: "LAUJUZAN (32110)", value: "32110" },
  { label: "LAURAET (32330)", value: "32330" },
  { label: "LAVARDENS (32360)", value: "32360" },
  { label: "LAVERAET (32230)", value: "32230" },
  { label: "LAYMONT (32220)", value: "32220" },
  { label: "LEBOULIN (32810)", value: "32810" },
  { label: "LECTOURE (32700)", value: "32700" },
  { label: "LELIN LAPUJOLLE (32400)", value: "32400" },
  { label: "LIAS (32600)", value: "32600" },
  { label: "LIAS D ARMAGNAC (32240)", value: "32240" },
  { label: "LIGARDES (32480)", value: "32480" },
  { label: "LOMBEZ (32220)", value: "32220" },
  { label: "LOUBEDAT (32110)", value: "32110" },
  { label: "LOUBERSAN (32300)", value: "32300" },
  { label: "LOURTIES MONBRUN (32140)", value: "32140" },
  { label: "LOUSLITGES (32230)", value: "32230" },
  { label: "LOUSSOUS DEBAT (32290)", value: "32290" },
  { label: "LUPIAC (32290)", value: "32290" },
  { label: "LUPPE VIOLLES (32110)", value: "32110" },
  { label: "LUSSAN (32270)", value: "32270" },
  { label: "MAGNAN (32110)", value: "32110" },
  { label: "MAGNAS (32380)", value: "32380" },
  { label: "MAIGNAUT TAUZIA (32310)", value: "32310" },
  { label: "MALABAT (32730)", value: "32730" },
  { label: "MANAS BASTANOUS (32170)", value: "32170" },
  { label: "MANCIET (32370)", value: "32370" },
  { label: "MANENT MONTANE (32140)", value: "32140" },
  { label: "MANSEMPUY (32120)", value: "32120" },
  { label: "MANSENCOME (32310)", value: "32310" },
  { label: "MARAMBAT (32190)", value: "32190" },
  { label: "MARAVAT (32120)", value: "32120" },
  { label: "MARCIAC (32230)", value: "32230" },
  { label: "MARESTAING (32490)", value: "32490" },
  { label: "MARGOUET MEYMES (32290)", value: "32290" },
  { label: "MARGUESTAU (32150)", value: "32150" },
  { label: "MARSAN (32270)", value: "32270" },
  { label: "MARSEILLAN (32170)", value: "32170" },
  { label: "MARSOLAN (32700)", value: "32700" },
  { label: "MASCARAS (32230)", value: "32230" },
  { label: "MAS D AUVIGNON (32700)", value: "32700" },
  { label: "MASSEUBE (32140)", value: "32140" },
  { label: "MAULEON D ARMAGNAC (32240)", value: "32240" },
  { label: "MAULICHERES (32400)", value: "32400" },
  { label: "MAUMUSSON LAGUIAN (32400)", value: "32400" },
  { label: "MAUPAS (32240)", value: "32240" },
  { label: "MAURENS (32200)", value: "32200" },
  { label: "MAUROUX (32380)", value: "32380" },
  { label: "MAUVEZIN (32120)", value: "32120" },
  { label: "MEILHAN (32420)", value: "32420" },
  { label: "MERENS (32360)", value: "32360" },
  { label: "MIELAN (32170)", value: "32170" },
  { label: "MIRADOUX (32340)", value: "32340" },
  { label: "MIRAMONT D ASTARAC (32300)", value: "32300" },
  { label: "MIRAMONT LATOUR (32390)", value: "32390" },
  { label: "MIRANDE (32300)", value: "32300" },
  { label: "MIRANNES (32350)", value: "32350" },
  { label: "MIREPOIX (32390)", value: "32390" },
  { label: "MONBARDON (32420)", value: "32420" },
  { label: "MONBLANC (32130)", value: "32130" },
  { label: "MONBRUN (32600)", value: "32600" },
  { label: "MONCASSIN (32300)", value: "32300" },
  { label: "MONCLAR (32150)", value: "32150" },
  { label: "MONCLAR SUR LOSSE (32300)", value: "32300" },
  { label: "MONCORNEIL GRAZAN (32260)", value: "32260" },
  { label: "MONFERRAN PLAVES (32260)", value: "32260" },
  { label: "MONFERRAN SAVES (32490)", value: "32490" },
  { label: "MONFORT (32120)", value: "32120" },
  { label: "MONGAUSY (32220)", value: "32220" },
  { label: "MONGUILHEM (32240)", value: "32240" },
  { label: "MONLAUR BERNET (32140)", value: "32140" },
  { label: "MONLEZUN (32230)", value: "32230" },
  { label: "MONLEZUN D ARMAGNAC (32240)", value: "32240" },
  { label: "MONPARDIAC (32170)", value: "32170" },
  { label: "MONTADET (32220)", value: "32220" },
  { label: "MONTAMAT (32220)", value: "32220" },
  { label: "MONTAUT (32300)", value: "32300" },
  { label: "MONTAUT LES CRENEAUX (32810)", value: "32810" },
  { label: "MONT D ASTARAC (32140)", value: "32140" },
  { label: "MONT DE MARRAST (32170)", value: "32170" },
  { label: "MONTEGUT (32550)", value: "32550" },
  { label: "MONTEGUT ARROS (32730)", value: "32730" },
  { label: "MONTEGUT SAVES (32220)", value: "32220" },
  { label: "MONTESQUIOU (32320)", value: "32320" },
  { label: "MONTESTRUC SUR GERS (32390)", value: "32390" },
  { label: "MONTIES (32420)", value: "32420" },
  { label: "MONTIRON (32200)", value: "32200" },
  { label: "MONTPEZAT (32220)", value: "32220" },
  { label: "MONTREAL (32250)", value: "32250" },
  { label: "MORMES (32240)", value: "32240" },
  { label: "MOUCHAN (32330)", value: "32330" },
  { label: "MOUCHES (32300)", value: "32300" },
  { label: "MOUREDE (32190)", value: "32190" },
  { label: "NIZAS (32130)", value: "32130" },
  { label: "NOGARO (32110)", value: "32110" },
  { label: "NOILHAN (32130)", value: "32130" },
  { label: "NOUGAROULET (32270)", value: "32270" },
  { label: "NOULENS (32800)", value: "32800" },
  { label: "ORBESSAN (32260)", value: "32260" },
  { label: "ORDAN LARROQUE (32350)", value: "32350" },
  { label: "ORNEZAN (32260)", value: "32260" },
  { label: "PALLANNE (32230)", value: "32230" },
  { label: "PANASSAC (32140)", value: "32140" },
  { label: "PANJAS (32110)", value: "32110" },
  { label: "PAUILHAC (32500)", value: "32500" },
  { label: "PAVIE (32550)", value: "32550" },
  { label: "PEBEES (32130)", value: "32130" },
  { label: "PELLEFIGUE (32420)", value: "32420" },
  { label: "PERCHEDE (32460)", value: "32460" },
  { label: "PERGAIN TAILLAC (32700)", value: "32700" },
  { label: "PESSAN (32550)", value: "32550" },
  { label: "PESSOULENS (32380)", value: "32380" },
  { label: "PEYRECAVE (32340)", value: "32340" },
  { label: "PEYRUSSE GRANDE (32320)", value: "32320" },
  { label: "PEYRUSSE MASSAS (32360)", value: "32360" },
  { label: "PEYRUSSE VIEILLE (32230)", value: "32230" },
  { label: "PIS (32500)", value: "32500" },
  { label: "PLAISANCE (32160)", value: "32160" },
  { label: "PLIEUX (32340)", value: "32340" },
  { label: "POLASTRON (32130)", value: "32130" },
  { label: "POMPIAC (32130)", value: "32130" },
  { label: "PONSAMPERE (32300)", value: "32300" },
  { label: "PONSAN SOUBIRAN (32300)", value: "32300" },
  { label: "POUYDRAGUIN (32290)", value: "32290" },
  { label: "POUYLEBON (32320)", value: "32320" },
  { label: "POUY LOUBRIN (32260)", value: "32260" },
  { label: "POUY ROQUELAURE (32480)", value: "32480" },
  { label: "PRECHAC (32390)", value: "32390" },
  { label: "PRECHAC SUR ADOUR (32160)", value: "32160" },
  { label: "PREIGNAN (32810)", value: "32810" },
  { label: "PRENERON (32190)", value: "32190" },
  { label: "PROJAN (32400)", value: "32400" },
  { label: "PUJAUDRAN (32600)", value: "32600" },
  { label: "PUYCASQUIER (32120)", value: "32120" },
  { label: "PUYLAUSIC (32220)", value: "32220" },
  { label: "PUYSEGUR (32390)", value: "32390" },
  { label: "RAMOUZENS (32800)", value: "32800" },
  { label: "RAZENGUES (32600)", value: "32600" },
  { label: "REANS (32800)", value: "32800" },
  { label: "REJAUMONT (32390)", value: "32390" },
  { label: "RICOURT (32230)", value: "32230" },
  { label: "RIGUEPEU (32320)", value: "32320" },
  { label: "RISCLE (32400)", value: "32400" },
  { label: "RISCLE (32400)", value: "32400" },
  { label: "LA ROMIEU (32480)", value: "32480" },
  { label: "ROQUEBRUNE (32190)", value: "32190" },
  { label: "ROQUEFORT (32390)", value: "32390" },
  { label: "ROQUELAURE (32810)", value: "32810" },
  { label: "ROQUELAURE ST AUBIN (32430)", value: "32430" },
  { label: "ROQUEPINE (32100)", value: "32100" },
  { label: "ROQUES (32310)", value: "32310" },
  { label: "ROZES (32190)", value: "32190" },
  { label: "SABAILLAN (32420)", value: "32420" },
  { label: "SABAZAN (32290)", value: "32290" },
  { label: "SADEILLAN (32170)", value: "32170" },
  { label: "ST ANDRE (32200)", value: "32200" },
  { label: "STE ANNE (32430)", value: "32430" },
  { label: "ST ANTOINE (32340)", value: "32340" },
  { label: "ST ANTONIN (32120)", value: "32120" },
  { label: "ST ARAILLES (32350)", value: "32350" },
  { label: "ST ARROMAN (32300)", value: "32300" },
  { label: "ST AUNIX LENGROS (32160)", value: "32160" },
  { label: "STE AURENCE CAZAUX (32300)", value: "32300" },
  { label: "ST AVIT FRANDAT (32700)", value: "32700" },
  { label: "ST BLANCARD (32140)", value: "32140" },
  { label: "ST BRES (32120)", value: "32120" },
  { label: "ST CHRISTAUD (32320)", value: "32320" },
  { label: "STE CHRISTIE (32390)", value: "32390" },
  { label: "STE CHRISTIE D ARMAGNAC (32370)", value: "32370" },
  { label: "ST CLAR (32380)", value: "32380" },
  { label: "ST CREAC (32380)", value: "32380" },
  { label: "ST CRICQ (32430)", value: "32430" },
  { label: "STE DODE (32170)", value: "32170" },
  { label: "ST ELIX D ASTARAC (32450)", value: "32450" },
  { label: "ST ELIX THEUX (32300)", value: "32300" },
  { label: "STE GEMME (32120)", value: "32120" },
  { label: "ST GEORGES (32430)", value: "32430" },
  { label: "ST GERME (32400)", value: "32400" },
  { label: "ST GERMIER (32200)", value: "32200" },
  { label: "ST GRIEDE (32110)", value: "32110" },
  { label: "ST JEAN LE COMTAL (32550)", value: "32550" },
  { label: "ST JEAN POUTGE (32190)", value: "32190" },
  { label: "ST JUSTIN (32230)", value: "32230" },
  { label: "ST LARY (32360)", value: "32360" },
  { label: "ST LEONARD (32380)", value: "32380" },
  { label: "ST LIZIER DU PLANTE (32220)", value: "32220" },
  { label: "ST LOUBE (32220)", value: "32220" },
  { label: "STE MARIE (32200)", value: "32200" },
  { label: "ST MARTIN (32300)", value: "32300" },
  { label: "ST MARTIN D ARMAGNAC (32110)", value: "32110" },
  { label: "ST MARTIN DE GOYNE (32480)", value: "32480" },
  { label: "ST MARTIN GIMOIS (32450)", value: "32450" },
  { label: "ST MAUR (32300)", value: "32300" },
  { label: "ST MEDARD (32300)", value: "32300" },
  { label: "STE MERE (32700)", value: "32700" },
  { label: "ST MEZARD (32700)", value: "32700" },
  { label: "ST MICHEL (32300)", value: "32300" },
  { label: "ST MONT (32400)", value: "32400" },
  { label: "ST ORENS (32120)", value: "32120" },
  { label: "ST ORENS POUY PETIT (32100)", value: "32100" },
  { label: "ST OST (32300)", value: "32300" },
  { label: "ST PAUL DE BAISE (32190)", value: "32190" },
  { label: "ST PIERRE D AUBEZIES (32290)", value: "32290" },
  { label: "ST PUY (32310)", value: "32310" },
  { label: "STE RADEGONDE (32500)", value: "32500" },
  { label: "ST SAUVY (32270)", value: "32270" },
  { label: "ST SOULAN (32220)", value: "32220" },
  { label: "SALLES D ARMAGNAC (32370)", value: "32370" },
  { label: "SAMARAN (32140)", value: "32140" },
  { label: "SAMATAN (32130)", value: "32130" },
  { label: "SANSAN (32260)", value: "32260" },
  { label: "SARAMON (32450)", value: "32450" },
  { label: "SARCOS (32420)", value: "32420" },
  { label: "SARRAGACHIES (32400)", value: "32400" },
  { label: "SARRAGUZAN (32170)", value: "32170" },
  { label: "SARRANT (32120)", value: "32120" },
  { label: "LA SAUVETAT (32500)", value: "32500" },
  { label: "SAUVETERRE (32220)", value: "32220" },
  { label: "SAUVIAC (32300)", value: "32300" },
  { label: "SAUVIMONT (32220)", value: "32220" },
  { label: "SAVIGNAC MONA (32130)", value: "32130" },
  { label: "SCIEURAC ET FLOURES (32230)", value: "32230" },
  { label: "SEAILLES (32190)", value: "32190" },
  { label: "SEGOS (32400)", value: "32400" },
  { label: "SEGOUFIELLE (32600)", value: "32600" },
  { label: "SEISSAN (32260)", value: "32260" },
  { label: "SEISSAN (32260)", value: "32260" },
  { label: "SEMBOUES (32230)", value: "32230" },
  { label: "SEMEZIES CACHAN (32450)", value: "32450" },
  { label: "SEMPESSERRE (32700)", value: "32700" },
  { label: "SERE (32140)", value: "32140" },
  { label: "SEREMPUY (32120)", value: "32120" },
  { label: "SEYSSES SAVES (32130)", value: "32130" },
  { label: "SIMORRE (32420)", value: "32420" },
  { label: "SION (32110)", value: "32110" },
  { label: "SIRAC (32430)", value: "32430" },
  { label: "SOLOMIAC (32120)", value: "32120" },
  { label: "SORBETS (32110)", value: "32110" },
  { label: "TACHOIRES (32260)", value: "32260" },
  { label: "TARSAC (32400)", value: "32400" },
  { label: "TASQUE (32160)", value: "32160" },
  { label: "TAYBOSC (32120)", value: "32120" },
  { label: "TERRAUBE (32700)", value: "32700" },
  { label: "TERMES D ARMAGNAC (32400)", value: "32400" },
  { label: "THOUX (32430)", value: "32430" },
  { label: "TIESTE URAGNOUX (32160)", value: "32160" },
  { label: "TILLAC (32170)", value: "32170" },
  { label: "TIRENT PONTEJAC (32450)", value: "32450" },
  { label: "TOUGET (32430)", value: "32430" },
  { label: "TOUJOUSE (32240)", value: "32240" },
  { label: "TOURDUN (32230)", value: "32230" },
  { label: "TOURNAN (32420)", value: "32420" },
  { label: "TOURNECOUPE (32380)", value: "32380" },
  { label: "TOURRENQUETS (32390)", value: "32390" },
  { label: "TRAVERSERES (32450)", value: "32450" },
  { label: "TRONCENS (32230)", value: "32230" },
  { label: "TUDELLE (32190)", value: "32190" },
  { label: "URDENS (32500)", value: "32500" },
  { label: "URGOSSE (32110)", value: "32110" },
  { label: "VALENCE SUR BAISE (32310)", value: "32310" },
  { label: "VERGOIGNAN (32720)", value: "32720" },
  { label: "VERLUS (32400)", value: "32400" },
  { label: "VIC FEZENSAC (32190)", value: "32190" },
  { label: "VIC FEZENSAC (32190)", value: "32190" },
  { label: "VIELLA (32400)", value: "32400" },
  { label: "VILLECOMTAL SUR ARROS (32730)", value: "32730" },
  { label: "VILLEFRANCHE D ASTARAC (32420)", value: "32420" },
  { label: "VIOZAN (32300)", value: "32300" },
  { label: "ST CAPRAIS (32200)", value: "32200" },
  { label: "AUSSOS (32140)", value: "32140" },
  { label: "ABZAC (33230)", value: "33230" },
  { label: "AILLAS (33124)", value: "33124" },
  { label: "AMBARES ET LAGRAVE (33440)", value: "33440" },
  { label: "AMBES (33810)", value: "33810" },
  { label: "ANDERNOS LES BAINS (33510)", value: "33510" },
  { label: "ANGLADE (33390)", value: "33390" },
  { label: "ARBANATS (33640)", value: "33640" },
  { label: "PORTE DE BENAUGE (33760)", value: "33760" },
  { label: "PORTE DE BENAUGE (33760)", value: "33760" },
  { label: "ARCACHON (33120)", value: "33120" },
  { label: "ARCINS (33460)", value: "33460" },
  { label: "ARES (33740)", value: "33740" },
  { label: "ARSAC (33460)", value: "33460" },
  { label: "ARTIGUES PRES BORDEAUX (33370)", value: "33370" },
  { label: "LES ARTIGUES DE LUSSAC (33570)", value: "33570" },
  { label: "ARVEYRES (33500)", value: "33500" },
  { label: "ASQUES (33240)", value: "33240" },
  { label: "AUBIAC (33430)", value: "33430" },
  { label: "VAL DE VIRVEE (33240)", value: "33240" },
  { label: "VAL DE VIRVEE (33240)", value: "33240" },
  { label: "VAL DE VIRVEE (33240)", value: "33240" },
  { label: "AUDENGE (33980)", value: "33980" },
  { label: "AURIOLLES (33790)", value: "33790" },
  { label: "AUROS (33124)", value: "33124" },
  { label: "AVENSAN (33480)", value: "33480" },
  { label: "AYGUEMORTE LES GRAVES (33640)", value: "33640" },
  { label: "BAGAS (33190)", value: "33190" },
  { label: "BAIGNEAUX (33760)", value: "33760" },
  { label: "BALIZAC (33730)", value: "33730" },
  { label: "BARIE (33190)", value: "33190" },
  { label: "BARON (33750)", value: "33750" },
  { label: "LE BARP (33114)", value: "33114" },
  { label: "BARSAC (33720)", value: "33720" },
  { label: "BASSANNE (33190)", value: "33190" },
  { label: "BASSENS (33530)", value: "33530" },
  { label: "BAURECH (33880)", value: "33880" },
  { label: "BAYAS (33230)", value: "33230" },
  { label: "BAYON SUR GIRONDE (33710)", value: "33710" },
  { label: "BAZAS (33430)", value: "33430" },
  { label: "BEAUTIRAN (33640)", value: "33640" },
  { label: "BEGADAN (33340)", value: "33340" },
  { label: "BEGLES (33130)", value: "33130" },
  { label: "BEGUEY (33410)", value: "33410" },
  { label: "BELIN BELIET (33830)", value: "33830" },
  { label: "BELIN BELIET (33830)", value: "33830" },
  { label: "BELLEBAT (33760)", value: "33760" },
  { label: "BELLEFOND (33760)", value: "33760" },
  { label: "BELVES DE CASTILLON (33350)", value: "33350" },
  { label: "BERNOS BEAULAC (33430)", value: "33430" },
  { label: "BERSON (33390)", value: "33390" },
  { label: "BERTHEZ (33124)", value: "33124" },
  { label: "BEYCHAC ET CAILLAU (33750)", value: "33750" },
  { label: "BIEUJAC (33210)", value: "33210" },
  { label: "BIGANOS (33380)", value: "33380" },
  { label: "BIGANOS (33380)", value: "33380" },
  { label: "LES BILLAUX (33500)", value: "33500" },
  { label: "BIRAC (33430)", value: "33430" },
  { label: "BLAIGNAC (33190)", value: "33190" },
  { label: "BLAIGNAN PRIGNAC (33340)", value: "33340" },
  { label: "BLAIGNAN PRIGNAC (33340)", value: "33340" },
  { label: "BLANQUEFORT (33290)", value: "33290" },
  { label: "BLASIMON (33540)", value: "33540" },
  { label: "BLAYE (33390)", value: "33390" },
  { label: "BLESIGNAC (33670)", value: "33670" },
  { label: "BOMMES (33210)", value: "33210" },
  { label: "BONNETAN (33370)", value: "33370" },
  { label: "BONZAC (33910)", value: "33910" },
  { label: "BORDEAUX (33000)", value: "33000" },
  { label: "BORDEAUX (33100)", value: "33100" },
  { label: "BORDEAUX (33200)", value: "33200" },
  { label: "BORDEAUX (33300)", value: "33300" },
  { label: "BORDEAUX (33800)", value: "33800" },
  { label: "BOSSUGAN (33350)", value: "33350" },
  { label: "BOULIAC (33270)", value: "33270" },
  { label: "BOURDELLES (33190)", value: "33190" },
  { label: "BOURG (33710)", value: "33710" },
  { label: "BOURIDEYS (33113)", value: "33113" },
  { label: "LE BOUSCAT (33110)", value: "33110" },
  { label: "BRACH (33480)", value: "33480" },
  { label: "BRANNE (33420)", value: "33420" },
  { label: "BRANNENS (33124)", value: "33124" },
  { label: "BRAUD ET ST LOUIS (33820)", value: "33820" },
  { label: "BROUQUEYRAN (33124)", value: "33124" },
  { label: "BRUGES (33520)", value: "33520" },
  { label: "BUDOS (33720)", value: "33720" },
  { label: "CABANAC ET VILLAGRAINS (33650)", value: "33650" },
  { label: "CABARA (33420)", value: "33420" },
  { label: "CADARSAC (33750)", value: "33750" },
  { label: "CADAUJAC (33140)", value: "33140" },
  { label: "CADILLAC SUR GARONNE (33410)", value: "33410" },
  { label: "CADILLAC EN FRONSADAIS (33240)", value: "33240" },
  { label: "CAMARSAC (33750)", value: "33750" },
  { label: "CAMBES (33880)", value: "33880" },
  { label: "CAMBLANES ET MEYNAC (33360)", value: "33360" },
  { label: "CAMIAC ET ST DENIS (33420)", value: "33420" },
  { label: "CAMIRAN (33190)", value: "33190" },
  { label: "CAMPS SUR L ISLE (33660)", value: "33660" },
  { label: "CAMPUGNAN (33390)", value: "33390" },
  { label: "CANEJAN (33610)", value: "33610" },
  { label: "CAPIAN (33550)", value: "33550" },
  { label: "CAPLONG (33220)", value: "33220" },
  { label: "CAPTIEUX (33840)", value: "33840" },
  { label: "CARBON BLANC (33560)", value: "33560" },
  { label: "CARCANS (33121)", value: "33121" },
  { label: "CARCANS (33121)", value: "33121" },
  { label: "CARDAN (33410)", value: "33410" },
  { label: "CARIGNAN DE BORDEAUX (33360)", value: "33360" },
  { label: "CARS (33390)", value: "33390" },
  { label: "CARTELEGUE (33390)", value: "33390" },
  { label: "CASSEUIL (33190)", value: "33190" },
  { label: "CASTELMORON D ALBRET (33540)", value: "33540" },
  { label: "CASTELNAU DE MEDOC (33480)", value: "33480" },
  { label: "CASTELVIEL (33540)", value: "33540" },
  { label: "CASTETS ET CASTILLON (33210)", value: "33210" },
  { label: "CASTETS ET CASTILLON (33210)", value: "33210" },
  { label: "CASTILLON LA BATAILLE (33350)", value: "33350" },
  { label: "CASTRES GIRONDE (33640)", value: "33640" },
  { label: "CAUDROT (33490)", value: "33490" },
  { label: "CAUMONT (33540)", value: "33540" },
  { label: "CAUVIGNAC (33690)", value: "33690" },
  { label: "CAVIGNAC (33620)", value: "33620" },
  { label: "CAZALIS (33113)", value: "33113" },
  { label: "CAZATS (33430)", value: "33430" },
  { label: "CAZAUGITAT (33790)", value: "33790" },
  { label: "CENAC (33360)", value: "33360" },
  { label: "CENON (33150)", value: "33150" },
  { label: "CERONS (33720)", value: "33720" },
  { label: "CESSAC (33760)", value: "33760" },
  { label: "CESTAS (33610)", value: "33610" },
  { label: "CESTAS (33610)", value: "33610" },
  { label: "CEZAC (33620)", value: "33620" },
  { label: "CHAMADELLE (33230)", value: "33230" },
  { label: "CISSAC MEDOC (33250)", value: "33250" },
  { label: "CIVRAC DE BLAYE (33920)", value: "33920" },
  { label: "CIVRAC SUR DORDOGNE (33350)", value: "33350" },
  { label: "CIVRAC EN MEDOC (33340)", value: "33340" },
  { label: "CLEYRAC (33540)", value: "33540" },
  { label: "COIMERES (33210)", value: "33210" },
  { label: "COIRAC (33540)", value: "33540" },
  { label: "COMPS (33710)", value: "33710" },
  { label: "COUBEYRAC (33890)", value: "33890" },
  { label: "COUQUEQUES (33340)", value: "33340" },
  { label: "COURPIAC (33760)", value: "33760" },
  { label: "COURS DE MONSEGUR (33580)", value: "33580" },
  { label: "COURS LES BAINS (33690)", value: "33690" },
  { label: "COUTRAS (33230)", value: "33230" },
  { label: "COUTURES (33580)", value: "33580" },
  { label: "CREON (33670)", value: "33670" },
  { label: "CROIGNON (33750)", value: "33750" },
  { label: "CUBNEZAIS (33620)", value: "33620" },
  { label: "CUBZAC LES PONTS (33240)", value: "33240" },
  { label: "CUDOS (33430)", value: "33430" },
  { label: "CURSAN (33670)", value: "33670" },
  { label: "CUSSAC FORT MEDOC (33460)", value: "33460" },
  { label: "DAIGNAC (33420)", value: "33420" },
  { label: "DARDENAC (33420)", value: "33420" },
  { label: "DAUBEZE (33540)", value: "33540" },
  { label: "DIEULIVOL (33580)", value: "33580" },
  { label: "DONNEZAC (33860)", value: "33860" },
  { label: "DONZAC (33410)", value: "33410" },
  { label: "DOULEZON (33350)", value: "33350" },
  { label: "LES EGLISOTTES ET CHALAURES (33230)", value: "33230" },
  { label: "ESCAUDES (33840)", value: "33840" },
  { label: "ESCOUSSANS (33760)", value: "33760" },
  { label: "ESPIET (33420)", value: "33420" },
  { label: "LES ESSEINTES (33190)", value: "33190" },
  { label: "ETAULIERS (33820)", value: "33820" },
  { label: "EYNESSE (33220)", value: "33220" },
  { label: "EYRANS (33390)", value: "33390" },
  { label: "EYSINES (33320)", value: "33320" },
  { label: "FALEYRAS (33760)", value: "33760" },
  { label: "FARGUES (33210)", value: "33210" },
  { label: "FARGUES ST HILAIRE (33370)", value: "33370" },
  { label: "LE FIEU (33230)", value: "33230" },
  { label: "FLOIRAC (33270)", value: "33270" },
  { label: "FLAUJAGUES (33350)", value: "33350" },
  { label: "FLOUDES (33190)", value: "33190" },
  { label: "FONTET (33190)", value: "33190" },
  { label: "FOSSES ET BALEYSSAC (33190)", value: "33190" },
  { label: "FOURS (33390)", value: "33390" },
  { label: "FRANCS (33570)", value: "33570" },
  { label: "FRONSAC (33126)", value: "33126" },
  { label: "FRONTENAC (33760)", value: "33760" },
  { label: "GABARNAC (33410)", value: "33410" },
  { label: "GAILLAN EN MEDOC (33340)", value: "33340" },
  { label: "GAJAC (33430)", value: "33430" },
  { label: "GALGON (33133)", value: "33133" },
  { label: "GANS (33430)", value: "33430" },
  { label: "GARDEGAN ET TOURTIRAC (33350)", value: "33350" },
  { label: "GAURIAC (33710)", value: "33710" },
  { label: "GAURIAGUET (33240)", value: "33240" },
  { label: "GENERAC (33920)", value: "33920" },
  { label: "GENISSAC (33420)", value: "33420" },
  { label: "GENSAC (33890)", value: "33890" },
  { label: "GIRONDE SUR DROPT (33190)", value: "33190" },
  { label: "GISCOS (33840)", value: "33840" },
  { label: "GORNAC (33540)", value: "33540" },
  { label: "GOUALADE (33840)", value: "33840" },
  { label: "GOURS (33660)", value: "33660" },
  { label: "GRADIGNAN (33170)", value: "33170" },
  { label: "GRAYAN ET L HOPITAL (33590)", value: "33590" },
  { label: "GREZILLAC (33420)", value: "33420" },
  { label: "GRIGNOLS (33690)", value: "33690" },
  { label: "GUILLAC (33420)", value: "33420" },
  { label: "GUILLOS (33720)", value: "33720" },
  { label: "GUITRES (33230)", value: "33230" },
  { label: "GUJAN MESTRAS (33470)", value: "33470" },
  { label: "LE HAILLAN (33185)", value: "33185" },
  { label: "HAUX (33550)", value: "33550" },
  { label: "HOSTENS (33125)", value: "33125" },
  { label: "HOURTIN (33990)", value: "33990" },
  { label: "HURE (33190)", value: "33190" },
  { label: "ILLATS (33720)", value: "33720" },
  { label: "ISLE ST GEORGES (33640)", value: "33640" },
  { label: "IZON (33450)", value: "33450" },
  { label: "JAU DIGNAC ET LOIRAC (33590)", value: "33590" },
  { label: "JUGAZAN (33420)", value: "33420" },
  { label: "JUILLAC (33890)", value: "33890" },
  { label: "LABARDE (33460)", value: "33460" },
  { label: "LABESCAU (33690)", value: "33690" },
  { label: "LA BREDE (33650)", value: "33650" },
  { label: "LACANAU (33680)", value: "33680" },
  { label: "LACANAU (33680)", value: "33680" },
  { label: "LADAUX (33760)", value: "33760" },
  { label: "LADOS (33124)", value: "33124" },
  { label: "LAGORCE (33230)", value: "33230" },
  { label: "LA LANDE DE FRONSAC (33240)", value: "33240" },
  { label: "LAMARQUE (33460)", value: "33460" },
  { label: "LAMOTHE LANDERRON (33190)", value: "33190" },
  { label: "LALANDE DE POMEROL (33500)", value: "33500" },
  { label: "LANDERROUAT (33790)", value: "33790" },
  { label: "LANDERROUET SUR SEGUR (33540)", value: "33540" },
  { label: "LANDIRAS (33720)", value: "33720" },
  { label: "LANGOIRAN (33550)", value: "33550" },
  { label: "LANGON (33210)", value: "33210" },
  { label: "LANSAC (33710)", value: "33710" },
  { label: "LANTON (33138)", value: "33138" },
  { label: "LANTON (33138)", value: "33138" },
  { label: "LAPOUYADE (33620)", value: "33620" },
  { label: "LAROQUE (33410)", value: "33410" },
  { label: "LARTIGUE (33840)", value: "33840" },
  { label: "LARUSCADE (33620)", value: "33620" },
  { label: "LATRESNE (33360)", value: "33360" },
  { label: "LAVAZAN (33690)", value: "33690" },
  { label: "LEGE CAP FERRET (33950)", value: "33950" },
  { label: "LEGE CAP FERRET (33950)", value: "33950" },
  { label: "LEGE CAP FERRET (33970)", value: "33970" },
  { label: "LEGE CAP FERRET (33970)", value: "33970" },
  { label: "LEOGEATS (33210)", value: "33210" },
  { label: "LEOGNAN (33850)", value: "33850" },
  { label: "LERM ET MUSSET (33840)", value: "33840" },
  { label: "LESPARRE MEDOC (33340)", value: "33340" },
  { label: "LESTIAC SUR GARONNE (33550)", value: "33550" },
  { label: "LES LEVES ET THOUMEYRAGUES (33220)", value: "33220" },
  { label: "LIBOURNE (33500)", value: "33500" },
  { label: "LIGNAN DE BAZAS (33430)", value: "33430" },
  { label: "LIGNAN DE BORDEAUX (33360)", value: "33360" },
  { label: "LIGUEUX (33220)", value: "33220" },
  { label: "LISTRAC DE DUREZE (33790)", value: "33790" },
  { label: "LISTRAC MEDOC (33480)", value: "33480" },
  { label: "LORMONT (33310)", value: "33310" },
  { label: "LOUBENS (33190)", value: "33190" },
  { label: "LOUCHATS (33125)", value: "33125" },
  { label: "LOUPES (33370)", value: "33370" },
  { label: "LOUPIAC (33410)", value: "33410" },
  { label: "LOUPIAC DE LA REOLE (33190)", value: "33190" },
  { label: "LUCMAU (33840)", value: "33840" },
  { label: "LUDON MEDOC (33290)", value: "33290" },
  { label: "LUGAIGNAC (33420)", value: "33420" },
  { label: "LUGASSON (33760)", value: "33760" },
  { label: "LUGON ET L ILE DU CARNAY (33240)", value: "33240" },
  { label: "LUGOS (33830)", value: "33830" },
  { label: "LUSSAC (33570)", value: "33570" },
  { label: "MACAU (33460)", value: "33460" },
  { label: "MADIRAC (33670)", value: "33670" },
  { label: "MARANSIN (33230)", value: "33230" },
  { label: "MARCENAIS (33620)", value: "33620" },
  { label: "MARGAUX CANTENAC (33460)", value: "33460" },
  { label: "MARGAUX CANTENAC (33460)", value: "33460" },
  { label: "MARGUERON (33220)", value: "33220" },
  { label: "MARIMBAULT (33430)", value: "33430" },
  { label: "MARIONS (33690)", value: "33690" },
  { label: "MARSAS (33620)", value: "33620" },
  { label: "MARTIGNAS SUR JALLE (33127)", value: "33127" },
  { label: "MARTILLAC (33650)", value: "33650" },
  { label: "MARTRES (33760)", value: "33760" },
  { label: "MASSEILLES (33690)", value: "33690" },
  { label: "MASSUGAS (33790)", value: "33790" },
  { label: "MAURIAC (33540)", value: "33540" },
  { label: "MAZERES (33210)", value: "33210" },
  { label: "MAZION (33390)", value: "33390" },
  { label: "MERIGNAC (33700)", value: "33700" },
  { label: "MERIGNAS (33350)", value: "33350" },
  { label: "MESTERRIEUX (33540)", value: "33540" },
  { label: "MIOS (33380)", value: "33380" },
  { label: "MIOS (33380)", value: "33380" },
  { label: "MOMBRIER (33710)", value: "33710" },
  { label: "MONGAUZY (33190)", value: "33190" },
  { label: "MONPRIMBLANC (33410)", value: "33410" },
  { label: "MONSEGUR (33580)", value: "33580" },
  { label: "MONTAGNE (33570)", value: "33570" },
  { label: "MONTAGNE (33570)", value: "33570" },
  { label: "MONTAGNE (33570)", value: "33570" },
  { label: "MONTAGOUDIN (33190)", value: "33190" },
  { label: "MONTIGNAC (33760)", value: "33760" },
  { label: "MONTUSSAN (33450)", value: "33450" },
  { label: "MORIZES (33190)", value: "33190" },
  { label: "MOUILLAC (33240)", value: "33240" },
  { label: "MOULIETS ET VILLEMARTIN (33350)", value: "33350" },
  { label: "MOULIS EN MEDOC (33480)", value: "33480" },
  { label: "MOULON (33420)", value: "33420" },
  { label: "MOURENS (33410)", value: "33410" },
  { label: "NAUJAC SUR MER (33990)", value: "33990" },
  { label: "NAUJAN ET POSTIAC (33420)", value: "33420" },
  { label: "NEAC (33500)", value: "33500" },
  { label: "NERIGEAN (33750)", value: "33750" },
  { label: "NEUFFONS (33580)", value: "33580" },
  { label: "LE NIZAN (33430)", value: "33430" },
  { label: "NOAILLAC (33190)", value: "33190" },
  { label: "NOAILLAN (33730)", value: "33730" },
  { label: "OMET (33410)", value: "33410" },
  { label: "ORDONNAC (33340)", value: "33340" },
  { label: "ORIGNE (33113)", value: "33113" },
  { label: "PAILLET (33550)", value: "33550" },
  { label: "PAREMPUYRE (33290)", value: "33290" },
  { label: "PAUILLAC (33250)", value: "33250" },
  { label: "LES PEINTURES (33230)", value: "33230" },
  { label: "PELLEGRUE (33790)", value: "33790" },
  { label: "PERISSAC (33240)", value: "33240" },
  { label: "PESSAC (33600)", value: "33600" },
  { label: "PESSAC SUR DORDOGNE (33890)", value: "33890" },
  { label: "PETIT PALAIS ET CORNEMPS (33570)", value: "33570" },
  { label: "PEUJARD (33240)", value: "33240" },
  { label: "LE PIAN MEDOC (33290)", value: "33290" },
  { label: "LE PIAN SUR GARONNE (33490)", value: "33490" },
  { label: "PINEUILH (33220)", value: "33220" },
  { label: "PLASSAC (33390)", value: "33390" },
  { label: "PLEINE SELVE (33820)", value: "33820" },
  { label: "PODENSAC (33720)", value: "33720" },
  { label: "POMEROL (33500)", value: "33500" },
  { label: "POMPEJAC (33730)", value: "33730" },
  { label: "POMPIGNAC (33370)", value: "33370" },
  { label: "PONDAURAT (33190)", value: "33190" },
  { label: "PORCHERES (33660)", value: "33660" },
  { label: "LE PORGE (33680)", value: "33680" },
  { label: "PORTETS (33640)", value: "33640" },
  { label: "LE POUT (33670)", value: "33670" },
  { label: "PRECHAC (33730)", value: "33730" },
  { label: "PREIGNAC (33210)", value: "33210" },
  { label: "PRIGNAC ET MARCAMPS (33710)", value: "33710" },
  { label: "PUGNAC (33710)", value: "33710" },
  { label: "PUGNAC (33710)", value: "33710" },
  { label: "PUISSEGUIN (33570)", value: "33570" },
  { label: "PUISSEGUIN (33570)", value: "33570" },
  { label: "PUJOLS SUR CIRON (33210)", value: "33210" },
  { label: "PUJOLS (33350)", value: "33350" },
  { label: "LE PUY (33580)", value: "33580" },
  { label: "PUYBARBAN (33190)", value: "33190" },
  { label: "PUYNORMAND (33660)", value: "33660" },
  { label: "QUEYRAC (33340)", value: "33340" },
  { label: "QUINSAC (33360)", value: "33360" },
  { label: "RAUZAN (33420)", value: "33420" },
  { label: "REIGNAC (33860)", value: "33860" },
  { label: "LA REOLE (33190)", value: "33190" },
  { label: "RIMONS (33580)", value: "33580" },
  { label: "RIOCAUD (33220)", value: "33220" },
  { label: "RIONS (33410)", value: "33410" },
  { label: "LA RIVIERE (33126)", value: "33126" },
  { label: "ROAILLAN (33210)", value: "33210" },
  { label: "ROMAGNE (33760)", value: "33760" },
  { label: "ROQUEBRUNE (33580)", value: "33580" },
  { label: "LA ROQUILLE (33220)", value: "33220" },
  { label: "RUCH (33350)", value: "33350" },
  { label: "SABLONS (33910)", value: "33910" },
  { label: "SADIRAC (33670)", value: "33670" },
  { label: "SAILLANS (33141)", value: "33141" },
  { label: "ST AIGNAN (33126)", value: "33126" },
  { label: "ST ANDRE DE CUBZAC (33240)", value: "33240" },
  { label: "ST ANDRE DU BOIS (33490)", value: "33490" },
  { label: "ST ANDRE ET APPELLES (33220)", value: "33220" },
  { label: "ST ANDRONY (33390)", value: "33390" },
  { label: "ST ANTOINE DU QUEYRET (33790)", value: "33790" },
  { label: "ST ANTOINE SUR L ISLE (33660)", value: "33660" },
  { label: "ST AUBIN DE BLAYE (33820)", value: "33820" },
  { label: "ST AUBIN DE BRANNE (33420)", value: "33420" },
  { label: "ST AUBIN DE MEDOC (33160)", value: "33160" },
  { label: "ST AVIT DE SOULEGE (33220)", value: "33220" },
  { label: "ST AVIT ST NAZAIRE (33220)", value: "33220" },
  { label: "ST AVIT ST NAZAIRE (33220)", value: "33220" },
  { label: "ST BRICE (33540)", value: "33540" },
  { label: "VAL DE LIVENNE (33820)", value: "33820" },
  { label: "VAL DE LIVENNE (33860)", value: "33860" },
  { label: "ST CAPRAIS DE BORDEAUX (33880)", value: "33880" },
  { label: "ST CHRISTOLY DE BLAYE (33920)", value: "33920" },
  { label: "ST CHRISTOLY MEDOC (33340)", value: "33340" },
  { label: "ST CHRISTOPHE DES BARDES (33330)", value: "33330" },
  { label: "ST CHRISTOPHE DE DOUBLE (33230)", value: "33230" },
  { label: "ST CIBARD (33570)", value: "33570" },
  { label: "ST CIERS D ABZAC (33910)", value: "33910" },
  { label: "ST CIERS DE CANESSE (33710)", value: "33710" },
  { label: "ST CIERS SUR GIRONDE (33820)", value: "33820" },
  { label: "STE COLOMBE (33350)", value: "33350" },
  { label: "ST COME (33430)", value: "33430" },
  { label: "STE CROIX DU MONT (33410)", value: "33410" },
  { label: "ST DENIS DE PILE (33910)", value: "33910" },
  { label: "ST EMILION (33330)", value: "33330" },
  { label: "ST ESTEPHE (33180)", value: "33180" },
  { label: "ST ETIENNE DE LISSE (33330)", value: "33330" },
  { label: "STE EULALIE (33560)", value: "33560" },
  { label: "ST EXUPERY (33190)", value: "33190" },
  { label: "ST FELIX DE FONCAUDE (33540)", value: "33540" },
  { label: "ST FERME (33580)", value: "33580" },
  { label: "STE FLORENCE (33350)", value: "33350" },
  { label: "STE FOY LA GRANDE (33220)", value: "33220" },
  { label: "STE FOY LA LONGUE (33490)", value: "33490" },
  { label: "STE GEMME (33580)", value: "33580" },
  { label: "ST GENES DE BLAYE (33390)", value: "33390" },
  { label: "ST GENES DE CASTILLON (33350)", value: "33350" },
  { label: "ST GENES DE FRONSAC (33240)", value: "33240" },
  { label: "ST GENES DE LOMBAUD (33670)", value: "33670" },
  { label: "ST GENIS DU BOIS (33760)", value: "33760" },
  { label: "ST GERMAIN DE GRAVE (33490)", value: "33490" },
  { label: "ST GERMAIN D ESTEUIL (33340)", value: "33340" },
  { label: "ST GERMAIN DU PUCH (33750)", value: "33750" },
  { label: "ST GERMAIN DE LA RIVIERE (33240)", value: "33240" },
  { label: "ST GERVAIS (33240)", value: "33240" },
  { label: "ST GIRONS D AIGUEVIVES (33920)", value: "33920" },
  { label: "STE HELENE (33480)", value: "33480" },
  { label: "ST HILAIRE DE LA NOAILLE (33190)", value: "33190" },
  { label: "ST HILAIRE DU BOIS (33540)", value: "33540" },
  { label: "ST HIPPOLYTE (33330)", value: "33330" },
  { label: "ST JEAN DE BLAIGNAC (33420)", value: "33420" },
  { label: "ST JEAN D ILLAC (33127)", value: "33127" },
  { label: "ST JULIEN BEYCHEVELLE (33250)", value: "33250" },
  { label: "ST LAURENT MEDOC (33112)", value: "33112" },
  { label: "ST LAURENT D ARCE (33240)", value: "33240" },
  { label: "ST LAURENT DES COMBES (33330)", value: "33330" },
  { label: "ST LAURENT DU BOIS (33540)", value: "33540" },
  { label: "ST LAURENT DU PLAN (33190)", value: "33190" },
  { label: "ST LEGER DE BALSON (33113)", value: "33113" },
  { label: "ST LEON (33670)", value: "33670" },
  { label: "ST LOUBERT (33210)", value: "33210" },
  { label: "ST LOUBES (33450)", value: "33450" },
  { label: "ST LOUIS DE MONTFERRAND (33440)", value: "33440" },
  { label: "ST MACAIRE (33490)", value: "33490" },
  { label: "ST MAGNE (33125)", value: "33125" },
  { label: "ST MAGNE DE CASTILLON (33350)", value: "33350" },
  { label: "ST MAIXANT (33490)", value: "33490" },
  { label: "ST MARIENS (33620)", value: "33620" },
  { label: "ST MARTIAL (33490)", value: "33490" },
  { label: "ST MARTIN LACAUSSADE (33390)", value: "33390" },
  { label: "ST MARTIN DE LAYE (33910)", value: "33910" },
  { label: "ST MARTIN DE LERM (33540)", value: "33540" },
  { label: "ST MARTIN DE SESCAS (33490)", value: "33490" },
  { label: "ST MARTIN DU BOIS (33910)", value: "33910" },
  { label: "ST MARTIN DU PUY (33540)", value: "33540" },
  { label: "ST MEDARD DE GUIZIERES (33230)", value: "33230" },
  { label: "ST MEDARD D EYRANS (33650)", value: "33650" },
  { label: "ST MEDARD EN JALLES (33160)", value: "33160" },
  { label: "ST MICHEL DE CASTELNAU (33840)", value: "33840" },
  { label: "ST MICHEL DE FRONSAC (33126)", value: "33126" },
  { label: "ST MICHEL DE RIEUFRET (33720)", value: "33720" },
  { label: "ST MICHEL DE LAPUJADE (33190)", value: "33190" },
  { label: "ST MORILLON (33650)", value: "33650" },
  { label: "ST PALAIS (33820)", value: "33820" },
  { label: "ST PARDON DE CONQUES (33210)", value: "33210" },
  { label: "ST PAUL (33390)", value: "33390" },
  { label: "ST PEY D ARMENS (33330)", value: "33330" },
  { label: "ST PEY DE CASTETS (33350)", value: "33350" },
  { label: "ST PHILIPPE D AIGUILLE (33350)", value: "33350" },
  { label: "ST PHILIPPE DU SEIGNAL (33220)", value: "33220" },
  { label: "ST PIERRE D AURILLAC (33490)", value: "33490" },
  { label: "ST PIERRE DE BAT (33760)", value: "33760" },
  { label: "ST PIERRE DE MONS (33210)", value: "33210" },
  { label: "ST QUENTIN DE BARON (33750)", value: "33750" },
  { label: "ST QUENTIN DE CAPLONG (33220)", value: "33220" },
  { label: "STE RADEGONDE (33350)", value: "33350" },
  { label: "ST ROMAIN LA VIRVEE (33240)", value: "33240" },
  { label: "ST SAUVEUR (33250)", value: "33250" },
  { label: "ST SAUVEUR DE PUYNORMAND (33660)", value: "33660" },
  { label: "ST SAVIN (33920)", value: "33920" },
  { label: "ST SELVE (33650)", value: "33650" },
  { label: "ST SEURIN DE BOURG (33710)", value: "33710" },
  { label: "ST SEURIN DE CADOURNE (33180)", value: "33180" },
  { label: "ST SEURIN DE CURSAC (33390)", value: "33390" },
  { label: "ST SEURIN SUR L ISLE (33660)", value: "33660" },
  { label: "ST SEVE (33190)", value: "33190" },
  { label: "ST SULPICE DE FALEYRENS (33330)", value: "33330" },
  { label: "ST SULPICE DE GUILLERAGUES (33580)", value: "33580" },
  { label: "ST SULPICE DE POMMIERS (33540)", value: "33540" },
  { label: "ST SULPICE ET CAMEYRAC (33450)", value: "33450" },
  { label: "ST SYMPHORIEN (33113)", value: "33113" },
  { label: "STE TERRE (33350)", value: "33350" },
  { label: "ST TROJAN (33710)", value: "33710" },
  { label: "ST VINCENT DE PAUL (33440)", value: "33440" },
  { label: "ST VINCENT DE PERTIGNAS (33420)", value: "33420" },
  { label: "ST VIVIEN DE BLAYE (33920)", value: "33920" },
  { label: "ST VIVIEN DE MEDOC (33590)", value: "33590" },
  { label: "ST VIVIEN DE MONSEGUR (33580)", value: "33580" },
  { label: "ST YZAN DE SOUDIAC (33920)", value: "33920" },
  { label: "ST YZANS DE MEDOC (33340)", value: "33340" },
  { label: "SALAUNES (33160)", value: "33160" },
  { label: "SALLEBOEUF (33370)", value: "33370" },
  { label: "SALLES (33770)", value: "33770" },
  { label: "LES SALLES DE CASTILLON (33350)", value: "33350" },
  { label: "SAMONAC (33710)", value: "33710" },
  { label: "SAUCATS (33650)", value: "33650" },
  { label: "SAUGON (33920)", value: "33920" },
  { label: "SAUMOS (33680)", value: "33680" },
  { label: "SAUTERNES (33210)", value: "33210" },
  { label: "LA SAUVE (33670)", value: "33670" },
  { label: "SAUVETERRE DE GUYENNE (33540)", value: "33540" },
  { label: "SAUVIAC (33430)", value: "33430" },
  { label: "SAVIGNAC (33124)", value: "33124" },
  { label: "SAVIGNAC DE L ISLE (33910)", value: "33910" },
  { label: "SEMENS (33490)", value: "33490" },
  { label: "SENDETS (33690)", value: "33690" },
  { label: "SIGALENS (33690)", value: "33690" },
  { label: "SILLAS (33690)", value: "33690" },
  { label: "SOULAC SUR MER (33780)", value: "33780" },
  { label: "SOULIGNAC (33760)", value: "33760" },
  { label: "SOUSSAC (33790)", value: "33790" },
  { label: "SOUSSANS (33460)", value: "33460" },
  { label: "TABANAC (33550)", value: "33550" },
  { label: "LE TAILLAN MEDOC (33320)", value: "33320" },
  { label: "TAILLECAVAT (33580)", value: "33580" },
  { label: "TALAIS (33590)", value: "33590" },
  { label: "TALENCE (33400)", value: "33400" },
  { label: "TARGON (33760)", value: "33760" },
  { label: "TARNES (33240)", value: "33240" },
  { label: "TAURIAC (33710)", value: "33710" },
  { label: "TAYAC (33570)", value: "33570" },
  { label: "LE TEICH (33470)", value: "33470" },
  { label: "LE TEMPLE (33680)", value: "33680" },
  { label: "LA TESTE DE BUCH (33115)", value: "33115" },
  { label: "LA TESTE DE BUCH (33115)", value: "33115" },
  { label: "LA TESTE DE BUCH (33260)", value: "33260" },
  { label: "LA TESTE DE BUCH (33260)", value: "33260" },
  { label: "TEUILLAC (33710)", value: "33710" },
  { label: "TIZAC DE CURTON (33420)", value: "33420" },
  { label: "TIZAC DE LAPOUYADE (33620)", value: "33620" },
  { label: "TOULENNE (33210)", value: "33210" },
  { label: "LE TOURNE (33550)", value: "33550" },
  { label: "TRESSES (33370)", value: "33370" },
  { label: "LE TUZAN (33125)", value: "33125" },
  { label: "UZESTE (33730)", value: "33730" },
  { label: "VALEYRAC (33340)", value: "33340" },
  { label: "VAYRES (33870)", value: "33870" },
  { label: "VENDAYS MONTALIVET (33930)", value: "33930" },
  { label: "VENSAC (33590)", value: "33590" },
  { label: "VERAC (33240)", value: "33240" },
  { label: "VERDELAIS (33490)", value: "33490" },
  { label: "LE VERDON SUR MER (33123)", value: "33123" },
  { label: "VERTHEUIL (33180)", value: "33180" },
  { label: "VIGNONET (33330)", value: "33330" },
  { label: "VILLANDRAUT (33730)", value: "33730" },
  { label: "VILLEGOUGE (33141)", value: "33141" },
  { label: "VILLENAVE DE RIONS (33550)", value: "33550" },
  { label: "VILLENAVE D ORNON (33140)", value: "33140" },
  { label: "VILLENAVE D ORNON (33140)", value: "33140" },
  { label: "VILLENEUVE (33710)", value: "33710" },
  { label: "VIRELADE (33720)", value: "33720" },
  { label: "VIRSAC (33240)", value: "33240" },
  { label: "YVRAC (33370)", value: "33370" },
  { label: "MARCHEPRIME (33380)", value: "33380" },
  { label: "ABEILHAN (34290)", value: "34290" },
  { label: "ADISSAN (34230)", value: "34230" },
  { label: "AGDE (34300)", value: "34300" },
  { label: "AGDE (34300)", value: "34300" },
  { label: "AGEL (34210)", value: "34210" },
  { label: "AGONES (34190)", value: "34190" },
  { label: "AIGNE (34210)", value: "34210" },
  { label: "AIGUES VIVES (34210)", value: "34210" },
  { label: "LES AIRES (34600)", value: "34600" },
  { label: "ALIGNAN DU VENT (34290)", value: "34290" },
  { label: "ANIANE (34150)", value: "34150" },
  { label: "ARBORAS (34150)", value: "34150" },
  { label: "ARGELLIERS (34380)", value: "34380" },
  { label: "ASPIRAN (34800)", value: "34800" },
  { label: "ASSAS (34820)", value: "34820" },
  { label: "ASSIGNAN (34360)", value: "34360" },
  { label: "AUMELAS (34230)", value: "34230" },
  { label: "AUMES (34530)", value: "34530" },
  { label: "AUTIGNAC (34480)", value: "34480" },
  { label: "AVENE (34260)", value: "34260" },
  { label: "AZILLANET (34210)", value: "34210" },
  { label: "BABEAU BOULDOUX (34360)", value: "34360" },
  { label: "BAILLARGUES (34670)", value: "34670" },
  { label: "BALARUC LES BAINS (34540)", value: "34540" },
  { label: "BALARUC LE VIEUX (34540)", value: "34540" },
  { label: "BASSAN (34290)", value: "34290" },
  { label: "BEAUFORT (34210)", value: "34210" },
  { label: "BEAULIEU (34160)", value: "34160" },
  { label: "BEDARIEUX (34600)", value: "34600" },
  { label: "BELARGA (34230)", value: "34230" },
  { label: "BERLOU (34360)", value: "34360" },
  { label: "BESSAN (34550)", value: "34550" },
  { label: "BEZIERS (34500)", value: "34500" },
  { label: "BOISSERON (34160)", value: "34160" },
  { label: "BOISSET (34220)", value: "34220" },
  { label: "LA BOISSIERE (34150)", value: "34150" },
  { label: "LE BOSC (34700)", value: "34700" },
  { label: "BOUJAN SUR LIBRON (34760)", value: "34760" },
  { label: "LE BOUSQUET D ORB (34260)", value: "34260" },
  { label: "BOUZIGUES (34140)", value: "34140" },
  { label: "BRENAS (34650)", value: "34650" },
  { label: "BRIGNAC (34800)", value: "34800" },
  { label: "BRISSAC (34190)", value: "34190" },
  { label: "BUZIGNARGUES (34160)", value: "34160" },
  { label: "CABREROLLES (34480)", value: "34480" },
  { label: "CABRIERES (34800)", value: "34800" },
  { label: "CAMBON ET SALVERGUES (34330)", value: "34330" },
  { label: "CAMPAGNAN (34230)", value: "34230" },
  { label: "CAMPAGNE (34160)", value: "34160" },
  { label: "CAMPLONG (34260)", value: "34260" },
  { label: "CANDILLARGUES (34130)", value: "34130" },
  { label: "CANET (34800)", value: "34800" },
  { label: "CAPESTANG (34310)", value: "34310" },
  { label: "CARLENCAS ET LEVAS (34600)", value: "34600" },
  { label: "CASSAGNOLES (34210)", value: "34210" },
  { label: "CASTANET LE HAUT (34610)", value: "34610" },
  { label: "CASTELNAU DE GUERS (34120)", value: "34120" },
  { label: "CASTELNAU LE LEZ (34170)", value: "34170" },
  { label: "CASTRIES (34160)", value: "34160" },
  { label: "LA CAUNETTE (34210)", value: "34210" },
  { label: "CAUSSE DE LA SELLE (34380)", value: "34380" },
  { label: "CAUSSES ET VEYRAN (34490)", value: "34490" },
  { label: "CAUSSINIOJOULS (34600)", value: "34600" },
  { label: "CAUX (34720)", value: "34720" },
  { label: "LE CAYLAR (34520)", value: "34520" },
  { label: "CAZEDARNES (34460)", value: "34460" },
  { label: "CAZEVIEILLE (34270)", value: "34270" },
  { label: "CAZILHAC (34190)", value: "34190" },
  { label: "CAZOULS D HERAULT (34120)", value: "34120" },
  { label: "CAZOULS LES BEZIERS (34370)", value: "34370" },
  { label: "CEBAZAN (34360)", value: "34360" },
  { label: "CEILHES ET ROCOZELS (34260)", value: "34260" },
  { label: "CELLES (34700)", value: "34700" },
  { label: "CERS (34420)", value: "34420" },
  { label: "CESSENON SUR ORB (34460)", value: "34460" },
  { label: "CESSERAS (34210)", value: "34210" },
  { label: "CEYRAS (34800)", value: "34800" },
  { label: "CLAPIERS (34830)", value: "34830" },
  { label: "CLARET (34270)", value: "34270" },
  { label: "CLERMONT L HERAULT (34800)", value: "34800" },
  { label: "COLOMBIERES SUR ORB (34390)", value: "34390" },
  { label: "COLOMBIERS (34440)", value: "34440" },
  { label: "COMBAILLAUX (34980)", value: "34980" },
  { label: "COMBES (34240)", value: "34240" },
  { label: "CORNEILHAN (34490)", value: "34490" },
  { label: "COULOBRES (34290)", value: "34290" },
  { label: "COURNIOU (34220)", value: "34220" },
  { label: "COURNONSEC (34660)", value: "34660" },
  { label: "COURNONTERRAL (34660)", value: "34660" },
  { label: "CREISSAN (34370)", value: "34370" },
  { label: "LE CRES (34920)", value: "34920" },
  { label: "LE CROS (34520)", value: "34520" },
  { label: "CRUZY (34310)", value: "34310" },
  { label: "DIO ET VALQUIERES (34650)", value: "34650" },
  { label: "ESPONDEILHAN (34290)", value: "34290" },
  { label: "FABREGUES (34690)", value: "34690" },
  { label: "FAUGERES (34600)", value: "34600" },
  { label: "FELINES MINERVOIS (34210)", value: "34210" },
  { label: "FERRALS LES MONTAGNES (34210)", value: "34210" },
  { label: "FERRIERES LES VERRERIES (34190)", value: "34190" },
  { label: "FERRIERES POUSSAROU (34360)", value: "34360" },
  { label: "FLORENSAC (34510)", value: "34510" },
  { label: "FONTANES (34270)", value: "34270" },
  { label: "FONTES (34320)", value: "34320" },
  { label: "FOS (34320)", value: "34320" },
  { label: "FOUZILHON (34480)", value: "34480" },
  { label: "FOZIERES (34700)", value: "34700" },
  { label: "FRAISSE SUR AGOUT (34330)", value: "34330" },
  { label: "FRONTIGNAN (34110)", value: "34110" },
  { label: "FRONTIGNAN (34110)", value: "34110" },
  { label: "GABIAN (34320)", value: "34320" },
  { label: "GALARGUES (34160)", value: "34160" },
  { label: "GANGES (34190)", value: "34190" },
  { label: "GARRIGUES (34160)", value: "34160" },
  { label: "GIGEAN (34770)", value: "34770" },
  { label: "GIGNAC (34150)", value: "34150" },
  { label: "GORNIES (34190)", value: "34190" },
  { label: "GRABELS (34790)", value: "34790" },
  { label: "GRAISSESSAC (34260)", value: "34260" },
  { label: "GUZARGUES (34820)", value: "34820" },
  { label: "HEREPIAN (34600)", value: "34600" },
  { label: "JACOU (34830)", value: "34830" },
  { label: "JONCELS (34650)", value: "34650" },
  { label: "JONQUIERES (34725)", value: "34725" },
  { label: "JUVIGNAC (34990)", value: "34990" },
  { label: "LACOSTE (34800)", value: "34800" },
  { label: "LAGAMAS (34150)", value: "34150" },
  { label: "LAMALOU LES BAINS (34240)", value: "34240" },
  { label: "LANSARGUES (34130)", value: "34130" },
  { label: "LAROQUE (34190)", value: "34190" },
  { label: "LATTES (34970)", value: "34970" },
  { label: "LAURENS (34480)", value: "34480" },
  { label: "LAURET (34270)", value: "34270" },
  { label: "LAUROUX (34700)", value: "34700" },
  { label: "LAVALETTE (34700)", value: "34700" },
  { label: "LAVERUNE (34880)", value: "34880" },
  { label: "LESPIGNAN (34710)", value: "34710" },
  { label: "LEZIGNAN LA CEBE (34120)", value: "34120" },
  { label: "LIAUSSON (34800)", value: "34800" },
  { label: "LIEURAN CABRIERES (34800)", value: "34800" },
  { label: "LIEURAN LES BEZIERS (34290)", value: "34290" },
  { label: "LIGNAN SUR ORB (34490)", value: "34490" },
  { label: "LA LIVINIERE (34210)", value: "34210" },
  { label: "LODEVE (34700)", value: "34700" },
  { label: "LOUPIAN (34140)", value: "34140" },
  { label: "LUNAS (34650)", value: "34650" },
  { label: "LUNEL (34400)", value: "34400" },
  { label: "LUNEL VIEL (34400)", value: "34400" },
  { label: "MAGALAS (34480)", value: "34480" },
  { label: "MARAUSSAN (34370)", value: "34370" },
  { label: "MARGON (34320)", value: "34320" },
  { label: "MARSEILLAN (34340)", value: "34340" },
  { label: "MARSEILLAN (34340)", value: "34340" },
  { label: "MARSILLARGUES (34590)", value: "34590" },
  { label: "MAS DE LONDRES (34380)", value: "34380" },
  { label: "LES MATELLES (34270)", value: "34270" },
  { label: "MAUGUIO (34130)", value: "34130" },
  { label: "MAUGUIO (34130)", value: "34130" },
  { label: "MAUREILHAN (34370)", value: "34370" },
  { label: "MERIFONS (34800)", value: "34800" },
  { label: "MEZE (34140)", value: "34140" },
  { label: "MINERVE (34210)", value: "34210" },
  { label: "MIREVAL (34110)", value: "34110" },
  { label: "MONS (34390)", value: "34390" },
  { label: "MONTADY (34310)", value: "34310" },
  { label: "MONTAGNAC (34530)", value: "34530" },
  { label: "MONTARNAUD (34570)", value: "34570" },
  { label: "MONTAUD (34160)", value: "34160" },
  { label: "MONTBAZIN (34560)", value: "34560" },
  { label: "MONTBLANC (34290)", value: "34290" },
  { label: "MONTELS (34310)", value: "34310" },
  { label: "MONTESQUIEU (34320)", value: "34320" },
  { label: "MONTFERRIER SUR LEZ (34980)", value: "34980" },
  { label: "MONTOULIERS (34310)", value: "34310" },
  { label: "MONTOULIEU (34190)", value: "34190" },
  { label: "MONTPELLIER (34000)", value: "34000" },
  { label: "MONTPELLIER (34070)", value: "34070" },
  { label: "MONTPELLIER (34080)", value: "34080" },
  { label: "MONTPELLIER (34090)", value: "34090" },
  { label: "MONTPEYROUX (34150)", value: "34150" },
  { label: "MOULES ET BAUCELS (34190)", value: "34190" },
  { label: "MOUREZE (34800)", value: "34800" },
  { label: "MUDAISON (34130)", value: "34130" },
  { label: "MURLES (34980)", value: "34980" },
  { label: "MURVIEL LES BEZIERS (34490)", value: "34490" },
  { label: "MURVIEL LES MONTPELLIER (34570)", value: "34570" },
  { label: "NEBIAN (34800)", value: "34800" },
  { label: "NEFFIES (34320)", value: "34320" },
  { label: "NEZIGNAN L EVEQUE (34120)", value: "34120" },
  { label: "NISSAN LEZ ENSERUNE (34440)", value: "34440" },
  { label: "NIZAS (34320)", value: "34320" },
  { label: "NOTRE DAME DE LONDRES (34380)", value: "34380" },
  { label: "OCTON (34800)", value: "34800" },
  { label: "OLARGUES (34390)", value: "34390" },
  { label: "OLMET ET VILLECUN (34700)", value: "34700" },
  { label: "OLONZAC (34210)", value: "34210" },
  { label: "OUPIA (34210)", value: "34210" },
  { label: "PAILHES (34490)", value: "34490" },
  { label: "PALAVAS LES FLOTS (34250)", value: "34250" },
  { label: "PARDAILHAN (34360)", value: "34360" },
  { label: "PAULHAN (34230)", value: "34230" },
  { label: "PEGAIROLLES DE BUEGES (34380)", value: "34380" },
  { label: "PEGAIROLLES DE L ESCALETTE (34700)", value: "34700" },
  { label: "PERET (34800)", value: "34800" },
  { label: "PEROLS (34470)", value: "34470" },
  { label: "PEZENAS (34120)", value: "34120" },
  { label: "PEZENES LES MINES (34600)", value: "34600" },
  { label: "PIERRERUE (34360)", value: "34360" },
  { label: "PIGNAN (34570)", value: "34570" },
  { label: "PINET (34850)", value: "34850" },
  { label: "PLAISSAN (34230)", value: "34230" },
  { label: "LES PLANS (34700)", value: "34700" },
  { label: "POILHES (34310)", value: "34310" },
  { label: "POMEROLS (34810)", value: "34810" },
  { label: "POPIAN (34230)", value: "34230" },
  { label: "PORTIRAGNES (34420)", value: "34420" },
  { label: "LE POUGET (34230)", value: "34230" },
  { label: "LE POUJOL SUR ORB (34600)", value: "34600" },
  { label: "POUJOLS (34700)", value: "34700" },
  { label: "POUSSAN (34560)", value: "34560" },
  { label: "POUZOLLES (34480)", value: "34480" },
  { label: "POUZOLS (34230)", value: "34230" },
  { label: "LE PRADAL (34600)", value: "34600" },
  { label: "PRADES LE LEZ (34730)", value: "34730" },
  { label: "PRADES SUR VERNAZOBRE (34360)", value: "34360" },
  { label: "PREMIAN (34390)", value: "34390" },
  { label: "LE PUECH (34700)", value: "34700" },
  { label: "PUECHABON (34150)", value: "34150" },
  { label: "PUILACHER (34230)", value: "34230" },
  { label: "PUIMISSON (34480)", value: "34480" },
  { label: "PUISSALICON (34480)", value: "34480" },
  { label: "PUISSERGUIER (34620)", value: "34620" },
  { label: "QUARANTE (34310)", value: "34310" },
  { label: "RESTINCLIERES (34160)", value: "34160" },
  { label: "RIEUSSEC (34220)", value: "34220" },
  { label: "RIOLS (34220)", value: "34220" },
  { label: "LES RIVES (34520)", value: "34520" },
  { label: "ROMIGUIERES (34650)", value: "34650" },
  { label: "ROQUEBRUN (34460)", value: "34460" },
  { label: "ROQUEREDONDE (34650)", value: "34650" },
  { label: "ROQUESSELS (34320)", value: "34320" },
  { label: "ROSIS (34610)", value: "34610" },
  { label: "ROUET (34380)", value: "34380" },
  { label: "ROUJAN (34320)", value: "34320" },
  { label: "ST ANDRE DE BUEGES (34190)", value: "34190" },
  { label: "ST ANDRE DE SANGONIS (34725)", value: "34725" },
  { label: "ST AUNES (34130)", value: "34130" },
  { label: "ST BAUZILLE DE LA SYLVE (34230)", value: "34230" },
  { label: "ST BAUZILLE DE MONTMEL (34160)", value: "34160" },
  { label: "ST BAUZILLE DE PUTOIS (34190)", value: "34190" },
  { label: "ST BRES (34670)", value: "34670" },
  { label: "ST CHINIAN (34360)", value: "34360" },
  { label: "ENTRE VIGNES (34400)", value: "34400" },
  { label: "ENTRE VIGNES (34400)", value: "34400" },
  { label: "ST CLEMENT DE RIVIERE (34980)", value: "34980" },
  { label: "STE CROIX DE QUINTILLARGUES (34270)", value: "34270" },
  { label: "ST DREZERY (34160)", value: "34160" },
  { label: "ST ETIENNE D ALBAGNAN (34390)", value: "34390" },
  { label: "ST ETIENNE DE GOURGAS (34700)", value: "34700" },
  { label: "ST ETIENNE ESTRECHOUX (34260)", value: "34260" },
  { label: "ST FELIX DE L HERAS (34520)", value: "34520" },
  { label: "ST FELIX DE LODEZ (34725)", value: "34725" },
  { label: "ST GELY DU FESC (34980)", value: "34980" },
  { label: "ST GENIES DES MOURGUES (34160)", value: "34160" },
  { label: "ST GENIES DE VARENSAL (34610)", value: "34610" },
  { label: "ST GENIES DE VARENSAL (34610)", value: "34610" },
  { label: "ST GENIES DE FONTEDIT (34480)", value: "34480" },
  { label: "ST GEORGES D ORQUES (34680)", value: "34680" },
  { label: "ST GERVAIS SUR MARE (34610)", value: "34610" },
  { label: "ST GUILHEM LE DESERT (34150)", value: "34150" },
  { label: "ST GUIRAUD (34725)", value: "34725" },
  { label: "ST HILAIRE DE BEAUVOIR (34160)", value: "34160" },
  { label: "ST JEAN DE BUEGES (34380)", value: "34380" },
  { label: "ST JEAN DE CORNIES (34160)", value: "34160" },
  { label: "ST JEAN DE CUCULLES (34270)", value: "34270" },
  { label: "ST JEAN DE FOS (34150)", value: "34150" },
  { label: "ST JEAN DE LA BLAQUIERE (34700)", value: "34700" },
  { label: "ST JEAN DE MINERVOIS (34360)", value: "34360" },
  { label: "ST JEAN DE VEDAS (34430)", value: "34430" },
  { label: "ST JULIEN (34390)", value: "34390" },
  { label: "ST JUST (34400)", value: "34400" },
  { label: "ST MARTIN DE L ARCON (34390)", value: "34390" },
  { label: "ST MARTIN DE LONDRES (34380)", value: "34380" },
  { label: "ST MATHIEU DE TREVIERS (34270)", value: "34270" },
  { label: "ST MAURICE NAVACELLES (34190)", value: "34190" },
  { label: "ST MAURICE NAVACELLES (34520)", value: "34520" },
  { label: "ST MICHEL (34520)", value: "34520" },
  { label: "ST NAZAIRE DE LADAREZ (34490)", value: "34490" },
  { label: "ST NAZAIRE DE PEZAN (34400)", value: "34400" },
  { label: "ST PARGOIRE (34230)", value: "34230" },
  { label: "ST PAUL ET VALMALLE (34570)", value: "34570" },
  { label: "ST PIERRE DE LA FAGE (34520)", value: "34520" },
  { label: "ST PONS DE THOMIERES (34220)", value: "34220" },
  { label: "ST PONS DE MAUCHIENS (34230)", value: "34230" },
  { label: "ST PRIVAT (34700)", value: "34700" },
  { label: "ST SATURNIN DE LUCIAN (34725)", value: "34725" },
  { label: "ST SERIES (34400)", value: "34400" },
  { label: "ST THIBERY (34630)", value: "34630" },
  { label: "ST VINCENT DE BARBEYRARGUES (34730)", value: "34730" },
  { label: "ST VINCENT D OLARGUES (34390)", value: "34390" },
  { label: "SALASC (34800)", value: "34800" },
  { label: "LA SALVETAT SUR AGOUT (34330)", value: "34330" },
  { label: "SATURARGUES (34400)", value: "34400" },
  { label: "SAUSSAN (34570)", value: "34570" },
  { label: "SAUSSINES (34160)", value: "34160" },
  { label: "SAUTEYRARGUES (34270)", value: "34270" },
  { label: "SAUVIAN (34410)", value: "34410" },
  { label: "SERIGNAN (34410)", value: "34410" },
  { label: "SERVIAN (34290)", value: "34290" },
  { label: "SETE (34200)", value: "34200" },
  { label: "SIRAN (34210)", value: "34210" },
  { label: "SORBS (34520)", value: "34520" },
  { label: "SOUBES (34700)", value: "34700" },
  { label: "LE SOULIE (34330)", value: "34330" },
  { label: "SOUMONT (34700)", value: "34700" },
  { label: "SUSSARGUES (34160)", value: "34160" },
  { label: "TAUSSAC LA BILLIERE (34600)", value: "34600" },
  { label: "TEYRAN (34820)", value: "34820" },
  { label: "THEZAN LES BEZIERS (34490)", value: "34490" },
  { label: "TOURBES (34120)", value: "34120" },
  { label: "LA TOUR SUR ORB (34260)", value: "34260" },
  { label: "TRESSAN (34230)", value: "34230" },
  { label: "LE TRIADOU (34270)", value: "34270" },
  { label: "USCLAS D HERAULT (34230)", value: "34230" },
  { label: "USCLAS DU BOSC (34700)", value: "34700" },
  { label: "LA VACQUERIE ET ST MARTIN DE CASTRIES (34520)", value: "34520" },
  { label: "VACQUIERES (34270)", value: "34270" },
  { label: "VAILHAN (34320)", value: "34320" },
  { label: "VAILHAUQUES (34570)", value: "34570" },
  { label: "VALERGUES (34130)", value: "34130" },
  { label: "VALFLAUNES (34270)", value: "34270" },
  { label: "VALMASCLE (34800)", value: "34800" },
  { label: "VALRAS PLAGE (34350)", value: "34350" },
  { label: "VALROS (34290)", value: "34290" },
  { label: "VELIEUX (34220)", value: "34220" },
  { label: "VENDARGUES (34740)", value: "34740" },
  { label: "VENDEMIAN (34230)", value: "34230" },
  { label: "VENDRES (34350)", value: "34350" },
  { label: "VERRERIES DE MOUSSANS (34220)", value: "34220" },
  { label: "VIAS (34450)", value: "34450" },
  { label: "VIC LA GARDIOLE (34110)", value: "34110" },
  { label: "VIEUSSAN (34390)", value: "34390" },
  { label: "VILLEMAGNE L ARGENTIERE (34600)", value: "34600" },
  { label: "VILLENEUVE LES BEZIERS (34420)", value: "34420" },
  { label: "VILLENEUVE LES BEZIERS (34500)", value: "34500" },
  { label: "VILLENEUVE LES MAGUELONE (34750)", value: "34750" },
  { label: "VILLENEUVETTE (34800)", value: "34800" },
  { label: "VILLESPASSANS (34360)", value: "34360" },
  { label: "VILLETELLE (34400)", value: "34400" },
  { label: "VILLEVEYRAC (34560)", value: "34560" },
  { label: "VIOLS EN LAVAL (34380)", value: "34380" },
  { label: "VIOLS LE FORT (34380)", value: "34380" },
  { label: "LA GRANDE MOTTE (34280)", value: "34280" },
  { label: "ACIGNE (35690)", value: "35690" },
  { label: "AMANLIS (35150)", value: "35150" },
  { label: "ANDOUILLE NEUVILLE (35250)", value: "35250" },
  { label: "VAL COUESNON (35460)", value: "35460" },
  { label: "VAL COUESNON (35460)", value: "35460" },
  { label: "VAL COUESNON (35560)", value: "35560" },
  { label: "VAL COUESNON (35560)", value: "35560" },
  { label: "ARBRISSEL (35130)", value: "35130" },
  { label: "ARGENTRE DU PLESSIS (35370)", value: "35370" },
  { label: "AUBIGNE (35250)", value: "35250" },
  { label: "AVAILLES SUR SEICHE (35130)", value: "35130" },
  { label: "BAGUER MORVAN (35120)", value: "35120" },
  { label: "BAGUER PICAN (35120)", value: "35120" },
  { label: "BAIN DE BRETAGNE (35470)", value: "35470" },
  { label: "BAINS SUR OUST (35600)", value: "35600" },
  { label: "BAIS (35680)", value: "35680" },
  { label: "BALAZE (35500)", value: "35500" },
  { label: "BAULON (35580)", value: "35580" },
  { label: "LA BAUSSAINE (35190)", value: "35190" },
  { label: "LA BAZOUGE DU DESERT (35420)", value: "35420" },
  { label: "BAZOUGES LA PEROUSE (35560)", value: "35560" },
  { label: "BEAUCE (35133)", value: "35133" },
  { label: "BECHEREL (35190)", value: "35190" },
  { label: "BEDEE (35137)", value: "35137" },
  { label: "BETTON (35830)", value: "35830" },
  { label: "BILLE (35133)", value: "35133" },
  { label: "BLERUAIS (35750)", value: "35750" },
  { label: "BOISGERVILLY (35360)", value: "35360" },
  { label: "BOISTRUDAN (35150)", value: "35150" },
  { label: "BONNEMAIN (35270)", value: "35270" },
  { label: "LA BOSSE DE BRETAGNE (35320)", value: "35320" },
  { label: "LA BOUEXIERE (35340)", value: "35340" },
  { label: "BOURGBARRE (35230)", value: "35230" },
  { label: "BOURG DES COMPTES (35890)", value: "35890" },
  { label: "LA BOUSSAC (35120)", value: "35120" },
  { label: "BOVEL (35330)", value: "35330" },
  { label: "BREAL SOUS MONTFORT (35310)", value: "35310" },
  { label: "BREAL SOUS VITRE (35370)", value: "35370" },
  { label: "BRECE (35530)", value: "35530" },
  { label: "BRETEIL (35160)", value: "35160" },
  { label: "BRIE (35150)", value: "35150" },
  { label: "BRIELLES (35370)", value: "35370" },
  { label: "BROUALAN (35120)", value: "35120" },
  { label: "BRUC SUR AFF (35550)", value: "35550" },
  { label: "LES BRULAIS (35330)", value: "35330" },
  { label: "BRUZ (35170)", value: "35170" },
  { label: "BRUZ (35170)", value: "35170" },
  { label: "CANCALE (35260)", value: "35260" },
  { label: "CARDROC (35190)", value: "35190" },
  { label: "CESSON SEVIGNE (35510)", value: "35510" },
  { label: "CHAMPEAUX (35500)", value: "35500" },
  { label: "CHANTELOUP (35150)", value: "35150" },
  { label: "CHANTEPIE (35135)", value: "35135" },
  { label: "LA CHAPELLE AUX FILTZMEENS (35190)", value: "35190" },
  { label: "LA CHAPELLE BOUEXIC (35330)", value: "35330" },
  { label: "LA CHAPELLE CHAUSSEE (35630)", value: "35630" },
  { label: "LA CHAPELLE DES FOUGERETZ (35520)", value: "35520" },
  { label: "LA CHAPELLE DU LOU DU LAC (35360)", value: "35360" },
  { label: "LA CHAPELLE DU LOU DU LAC (35360)", value: "35360" },
  { label: "LA CHAPELLE ERBREE (35500)", value: "35500" },
  { label: "LA CHAPELLE FLEURIGNE (35133)", value: "35133" },
  { label: "LA CHAPELLE FLEURIGNE (35133)", value: "35133" },
  { label: "LA CHAPELLE ST AUBERT (35140)", value: "35140" },
  { label: "LA CHAPELLE DE BRAIN (35660)", value: "35660" },
  { label: "LA CHAPELLE DE BRAIN (35660)", value: "35660" },
  { label: "LA CHAPELLE THOUARAULT (35590)", value: "35590" },
  { label: "CHARTRES DE BRETAGNE (35131)", value: "35131" },
  { label: "CHASNE SUR ILLET (35250)", value: "35250" },
  { label: "CHATEAUBOURG (35220)", value: "35220" },
  { label: "CHATEAUBOURG (35220)", value: "35220" },
  { label: "CHATEAUBOURG (35220)", value: "35220" },
  { label: "CHATEAUGIRON (35410)", value: "35410" },
  { label: "CHATEAUGIRON (35410)", value: "35410" },
  { label: "CHATEAUGIRON (35410)", value: "35410" },
  { label: "CHATEAUNEUF D ILLE ET VILAINE (35430)", value: "35430" },
  { label: "LE CHATELLIER (35133)", value: "35133" },
  { label: "CHATILLON EN VENDELAIS (35210)", value: "35210" },
  { label: "CHAUVIGNE (35490)", value: "35490" },
  { label: "CHAVAGNE (35310)", value: "35310" },
  { label: "CHELUN (35640)", value: "35640" },
  { label: "CHERRUEIX (35120)", value: "35120" },
  { label: "CHEVAIGNE (35250)", value: "35250" },
  { label: "CINTRE (35310)", value: "35310" },
  { label: "CLAYES (35590)", value: "35590" },
  { label: "COESMES (35134)", value: "35134" },
  { label: "COMBLESSAC (35330)", value: "35330" },
  { label: "COMBOURG (35270)", value: "35270" },
  { label: "COMBOURTILLE (35210)", value: "35210" },
  { label: "CORNILLE (35500)", value: "35500" },
  { label: "CORPS NUDS (35150)", value: "35150" },
  { label: "LA COUYERE (35320)", value: "35320" },
  { label: "CREVIN (35320)", value: "35320" },
  { label: "LE CROUAIS (35290)", value: "35290" },
  { label: "CUGUEN (35270)", value: "35270" },
  { label: "DINARD (35800)", value: "35800" },
  { label: "DINGE (35440)", value: "35440" },
  { label: "DOL DE BRETAGNE (35120)", value: "35120" },
  { label: "DOMAGNE (35113)", value: "35113" },
  { label: "DOMAGNE (35113)", value: "35113" },
  { label: "DOMALAIN (35680)", value: "35680" },
  { label: "LA DOMINELAIS (35390)", value: "35390" },
  { label: "DOMLOUP (35410)", value: "35410" },
  { label: "DOURDAIN (35450)", value: "35450" },
  { label: "DROUGES (35130)", value: "35130" },
  { label: "EANCE (35640)", value: "35640" },
  { label: "EPINIAC (35120)", value: "35120" },
  { label: "ERBREE (35500)", value: "35500" },
  { label: "ERCE EN LAMEE (35620)", value: "35620" },
  { label: "ERCE PRES LIFFRE (35340)", value: "35340" },
  { label: "ESSE (35150)", value: "35150" },
  { label: "ETRELLES (35370)", value: "35370" },
  { label: "FEINS (35440)", value: "35440" },
  { label: "LE FERRE (35420)", value: "35420" },
  { label: "FORGES LA FORET (35640)", value: "35640" },
  { label: "FOUGERES (35300)", value: "35300" },
  { label: "LA FRESNAIS (35111)", value: "35111" },
  { label: "GAEL (35290)", value: "35290" },
  { label: "GAHARD (35490)", value: "35490" },
  { label: "GENNES SUR SEICHE (35370)", value: "35370" },
  { label: "GEVEZE (35850)", value: "35850" },
  { label: "GOSNE (35140)", value: "35140" },
  { label: "LA GOUESNIERE (35350)", value: "35350" },
  { label: "GOVEN (35580)", value: "35580" },
  { label: "GRAND FOUGERAY (35390)", value: "35390" },
  { label: "LA GUERCHE DE BRETAGNE (35130)", value: "35130" },
  { label: "GUICHEN (35580)", value: "35580" },
  { label: "GUICHEN (35580)", value: "35580" },
  { label: "GUIGNEN (35580)", value: "35580" },
  { label: "GUIPEL (35440)", value: "35440" },
  { label: "HEDE BAZOUGES (35630)", value: "35630" },
  { label: "HEDE BAZOUGES (35630)", value: "35630" },
  { label: "L'HERMITAGE (35590)", value: "35590" },
  { label: "HIREL (35120)", value: "35120" },
  { label: "HIREL (35120)", value: "35120" },
  { label: "IFFENDIC (35750)", value: "35750" },
  { label: "LES IFFS (35630)", value: "35630" },
  { label: "IRODOUER (35850)", value: "35850" },
  { label: "JANZE (35150)", value: "35150" },
  { label: "JAVENE (35133)", value: "35133" },
  { label: "LAIGNELET (35133)", value: "35133" },
  { label: "LAILLE (35890)", value: "35890" },
  { label: "LALLEU (35320)", value: "35320" },
  { label: "LANDAVRAN (35450)", value: "35450" },
  { label: "LANDEAN (35133)", value: "35133" },
  { label: "LANDUJAN (35360)", value: "35360" },
  { label: "LANGAN (35850)", value: "35850" },
  { label: "LANGON (35660)", value: "35660" },
  { label: "LANGOUET (35630)", value: "35630" },
  { label: "LANRIGAN (35270)", value: "35270" },
  { label: "LASSY (35580)", value: "35580" },
  { label: "LECOUSSE (35133)", value: "35133" },
  { label: "LIEURON (35550)", value: "35550" },
  { label: "LIFFRE (35340)", value: "35340" },
  { label: "LILLEMER (35111)", value: "35111" },
  { label: "LIVRE SUR CHANGEON (35450)", value: "35450" },
  { label: "LOHEAC (35550)", value: "35550" },
  { label: "LONGAULNAY (35190)", value: "35190" },
  { label: "LE LOROUX (35133)", value: "35133" },
  { label: "LOURMAIS (35270)", value: "35270" },
  { label: "LOUTEHEL (35330)", value: "35330" },
  { label: "LOUVIGNE DE BAIS (35680)", value: "35680" },
  { label: "LOUVIGNE DU DESERT (35420)", value: "35420" },
  { label: "LUITRE DOMPIERRE (35133)", value: "35133" },
  { label: "LUITRE DOMPIERRE (35210)", value: "35210" },
  { label: "MARCILLE RAOUL (35560)", value: "35560" },
  { label: "MARCILLE ROBERT (35240)", value: "35240" },
  { label: "MARPIRE (35220)", value: "35220" },
  { label: "MARTIGNE FERCHAUD (35640)", value: "35640" },
  { label: "VAL D ANAST (35330)", value: "35330" },
  { label: "VAL D ANAST (35330)", value: "35330" },
  { label: "MAXENT (35380)", value: "35380" },
  { label: "MECE (35450)", value: "35450" },
  { label: "MEDREAC (35360)", value: "35360" },
  { label: "MEILLAC (35270)", value: "35270" },
  { label: "MELESSE (35520)", value: "35520" },
  { label: "MELLE (35420)", value: "35420" },
  { label: "MERNEL (35330)", value: "35330" },
  { label: "GUIPRY MESSAC (35480)", value: "35480" },
  { label: "GUIPRY MESSAC (35480)", value: "35480" },
  { label: "LA MEZIERE (35520)", value: "35520" },
  { label: "MEZIERES SUR COUESNON (35140)", value: "35140" },
  { label: "MINIAC MORVAN (35540)", value: "35540" },
  { label: "MINIAC MORVAN (35540)", value: "35540" },
  { label: "MINIAC SOUS BECHEREL (35190)", value: "35190" },
  { label: "LE MINIHIC SUR RANCE (35870)", value: "35870" },
  { label: "MONDEVERT (35370)", value: "35370" },
  { label: "MONTAUBAN DE BRETAGNE (35360)", value: "35360" },
  { label: "MONTAUBAN DE BRETAGNE (35360)", value: "35360" },
  { label: "MONTAUTOUR (35210)", value: "35210" },
  { label: "MONT DOL (35120)", value: "35120" },
  { label: "MONTERFIL (35160)", value: "35160" },
  { label: "MONTFORT SUR MEU (35160)", value: "35160" },
  { label: "MONTGERMONT (35760)", value: "35760" },
  { label: "MONTHAULT (35420)", value: "35420" },
  { label: "LES PORTES DU COGLAIS (35460)", value: "35460" },
  { label: "LES PORTES DU COGLAIS (35460)", value: "35460" },
  { label: "LES PORTES DU COGLAIS (35460)", value: "35460" },
  { label: "MONTREUIL DES LANDES (35210)", value: "35210" },
  { label: "MONTREUIL LE GAST (35520)", value: "35520" },
  { label: "MONTREUIL SOUS PEROUSE (35500)", value: "35500" },
  { label: "MONTREUIL SUR ILLE (35440)", value: "35440" },
  { label: "MORDELLES (35310)", value: "35310" },
  { label: "MOUAZE (35250)", value: "35250" },
  { label: "MOULINS (35680)", value: "35680" },
  { label: "MOUSSE (35130)", value: "35130" },
  { label: "MOUTIERS (35130)", value: "35130" },
  { label: "MUEL (35290)", value: "35290" },
  { label: "LA NOE BLANCHE (35470)", value: "35470" },
  { label: "LA NOUAYE (35137)", value: "35137" },
  { label: "NOUVOITOU (35410)", value: "35410" },
  { label: "NOYAL SOUS BAZOUGES (35560)", value: "35560" },
  { label: "NOYAL CHATILLON SUR SEICHE (35230)", value: "35230" },
  { label: "NOYAL CHATILLON SUR SEICHE (35230)", value: "35230" },
  { label: "NOYAL SUR VILAINE (35530)", value: "35530" },
  { label: "ORGERES (35230)", value: "35230" },
  { label: "PACE (35740)", value: "35740" },
  { label: "PAIMPONT (35380)", value: "35380" },
  { label: "PANCE (35320)", value: "35320" },
  { label: "PARCE (35210)", value: "35210" },
  { label: "PARIGNE (35133)", value: "35133" },
  { label: "PARTHENAY DE BRETAGNE (35850)", value: "35850" },
  { label: "LE PERTRE (35370)", value: "35370" },
  { label: "LE PETIT FOUGERAY (35320)", value: "35320" },
  { label: "PIPRIAC (35550)", value: "35550" },
  { label: "PIRE CHANCE (35150)", value: "35150" },
  { label: "PIRE CHANCE (35680)", value: "35680" },
  { label: "PLECHATEL (35470)", value: "35470" },
  { label: "PLECHATEL (35470)", value: "35470" },
  { label: "PLEINE FOUGERES (35610)", value: "35610" },
  { label: "PLELAN LE GRAND (35380)", value: "35380" },
  { label: "PLERGUER (35540)", value: "35540" },
  { label: "PLESDER (35720)", value: "35720" },
  { label: "PLEUGUENEUC (35720)", value: "35720" },
  { label: "PLEUMELEUC (35137)", value: "35137" },
  { label: "PLEURTUIT (35730)", value: "35730" },
  { label: "POCE LES BOIS (35500)", value: "35500" },
  { label: "POILLEY (35420)", value: "35420" },
  { label: "POLIGNE (35320)", value: "35320" },
  { label: "PRINCE (35210)", value: "35210" },
  { label: "QUEBRIAC (35190)", value: "35190" },
  { label: "QUEDILLAC (35290)", value: "35290" },
  { label: "RANNEE (35130)", value: "35130" },
  { label: "REDON (35600)", value: "35600" },
  { label: "RENAC (35660)", value: "35660" },
  { label: "RENNES (35000)", value: "35000" },
  { label: "RENNES (35200)", value: "35200" },
  { label: "RENNES (35700)", value: "35700" },
  { label: "RETIERS (35240)", value: "35240" },
  { label: "LE RHEU (35650)", value: "35650" },
  { label: "LE RHEU (35650)", value: "35650" },
  { label: "LA RICHARDAIS (35780)", value: "35780" },
  { label: "RIMOU (35560)", value: "35560" },
  { label: "ROMAGNE (35133)", value: "35133" },
  { label: "ROMAZY (35490)", value: "35490" },
  { label: "ROMILLE (35850)", value: "35850" },
  { label: "ROZ LANDRIEUX (35120)", value: "35120" },
  { label: "ROZ SUR COUESNON (35610)", value: "35610" },
  { label: "SAINS (35610)", value: "35610" },
  { label: "STE ANNE SUR VILAINE (35390)", value: "35390" },
  { label: "ST ARMEL (35230)", value: "35230" },
  { label: "ST AUBIN D AUBIGNE (35250)", value: "35250" },
  { label: "ST AUBIN DES LANDES (35500)", value: "35500" },
  { label: "ST AUBIN DU CORMIER (35140)", value: "35140" },
  { label: "ST BENOIT DES ONDES (35114)", value: "35114" },
  { label: "ST BRIAC SUR MER (35800)", value: "35800" },
  { label: "MAEN ROCH (35460)", value: "35460" },
  { label: "MAEN ROCH (35460)", value: "35460" },
  { label: "ST BRIEUC DES IFFS (35630)", value: "35630" },
  { label: "ST BROLADRE (35120)", value: "35120" },
  { label: "ST CHRISTOPHE DES BOIS (35210)", value: "35210" },
  { label: "ST CHRISTOPHE DE VALAINS (35140)", value: "35140" },
  { label: "STE COLOMBE (35134)", value: "35134" },
  { label: "ST COULOMB (35350)", value: "35350" },
  { label: "ST DIDIER (35220)", value: "35220" },
  { label: "ST DOMINEUC (35190)", value: "35190" },
  { label: "ST ERBLON (35230)", value: "35230" },
  { label: "ST GANTON (35550)", value: "35550" },
  { label: "ST GEORGES DE GREHAIGNE (35610)", value: "35610" },
  { label: "ST GEORGES DE REINTEMBAULT (35420)", value: "35420" },
  { label: "ST GERMAIN DU PINEL (35370)", value: "35370" },
  { label: "ST GERMAIN EN COGLES (35133)", value: "35133" },
  { label: "ST GERMAIN SUR ILLE (35250)", value: "35250" },
  { label: "ST GILLES (35590)", value: "35590" },
  { label: "ST GONDRAN (35630)", value: "35630" },
  { label: "ST GONLAY (35750)", value: "35750" },
  { label: "ST GREGOIRE (35760)", value: "35760" },
  { label: "ST GUINOUX (35430)", value: "35430" },
  { label: "ST HILAIRE DES LANDES (35140)", value: "35140" },
  { label: "ST JACQUES DE LA LANDE (35136)", value: "35136" },
  { label: "RIVES DU COUESNON (35140)", value: "35140" },
  { label: "RIVES DU COUESNON (35140)", value: "35140" },
  { label: "RIVES DU COUESNON (35140)", value: "35140" },
  { label: "RIVES DU COUESNON (35140)", value: "35140" },
  { label: "ST JEAN SUR VILAINE (35220)", value: "35220" },
  { label: "ST JOUAN DES GUERETS (35430)", value: "35430" },
  { label: "ST JUST (35550)", value: "35550" },
  { label: "ST LEGER DES PRES (35270)", value: "35270" },
  { label: "ST LUNAIRE (35800)", value: "35800" },
  { label: "ST MALO (35400)", value: "35400" },
  { label: "ST MALO (35400)", value: "35400" },
  { label: "ST MALO (35400)", value: "35400" },
  { label: "ST MALO (35400)", value: "35400" },
  { label: "ST MALO (35400)", value: "35400" },
  { label: "ST MALO DE PHILY (35480)", value: "35480" },
  { label: "ST MALON SUR MEL (35750)", value: "35750" },
  { label: "ST MARCAN (35120)", value: "35120" },
  { label: "ST MARC LE BLANC (35460)", value: "35460" },
  { label: "ST MARC LE BLANC (35460)", value: "35460" },
  { label: "STE MARIE (35600)", value: "35600" },
  { label: "ST MAUGAN (35750)", value: "35750" },
  { label: "ST MEDARD SUR ILLE (35250)", value: "35250" },
  { label: "ST MEEN LE GRAND (35290)", value: "35290" },
  { label: "ST MELOIR DES ONDES (35350)", value: "35350" },
  { label: "ST M HERVE (35500)", value: "35500" },
  { label: "ST ONEN LA CHAPELLE (35290)", value: "35290" },
  { label: "ST OUEN DES ALLEUX (35140)", value: "35140" },
  { label: "ST PERAN (35380)", value: "35380" },
  { label: "ST PERE MARC EN POULET (35430)", value: "35430" },
  { label: "ST PERN (35190)", value: "35190" },
  { label: "MESNIL ROC H (35720)", value: "35720" },
  { label: "MESNIL ROC H (35720)", value: "35720" },
  { label: "MESNIL ROC H (35720)", value: "35720" },
  { label: "ST REMY DU PLAIN (35560)", value: "35560" },
  { label: "ST SAUVEUR DES LANDES (35133)", value: "35133" },
  { label: "ST SEGLIN (35330)", value: "35330" },
  { label: "ST SENOUX (35580)", value: "35580" },
  { label: "ST SULIAC (35430)", value: "35430" },
  { label: "ST SULPICE LA FORET (35250)", value: "35250" },
  { label: "ST SULPICE DES LANDES (35390)", value: "35390" },
  { label: "ST SYMPHORIEN (35630)", value: "35630" },
  { label: "ST THUAL (35190)", value: "35190" },
  { label: "ST THURIAL (35310)", value: "35310" },
  { label: "ST UNIAC (35360)", value: "35360" },
  { label: "SAULNIERES (35320)", value: "35320" },
  { label: "LE SEL DE BRETAGNE (35320)", value: "35320" },
  { label: "LA SELLE EN LUITRE (35133)", value: "35133" },
  { label: "LA SELLE GUERCHAISE (35130)", value: "35130" },
  { label: "SENS DE BRETAGNE (35490)", value: "35490" },
  { label: "SERVON SUR VILAINE (35530)", value: "35530" },
  { label: "SIXT SUR AFF (35550)", value: "35550" },
  { label: "SOUGEAL (35610)", value: "35610" },
  { label: "TAILLIS (35500)", value: "35500" },
  { label: "TALENSAC (35160)", value: "35160" },
  { label: "TEILLAY (35620)", value: "35620" },
  { label: "LE THEIL DE BRETAGNE (35240)", value: "35240" },
  { label: "THORIGNE FOUILLARD (35235)", value: "35235" },
  { label: "THOURIE (35134)", value: "35134" },
  { label: "LE TIERCENT (35460)", value: "35460" },
  { label: "TINTENIAC (35190)", value: "35190" },
  { label: "TORCE (35370)", value: "35370" },
  { label: "TRANS LA FORET (35610)", value: "35610" },
  { label: "TREFFENDEL (35380)", value: "35380" },
  { label: "TREMEHEUC (35270)", value: "35270" },
  { label: "TRESBOEUF (35320)", value: "35320" },
  { label: "TREVERIEN (35190)", value: "35190" },
  { label: "TRIMER (35190)", value: "35190" },
  { label: "VAL D IZE (35450)", value: "35450" },
  { label: "VERGEAL (35680)", value: "35680" },
  { label: "LE VERGER (35160)", value: "35160" },
  { label: "VERN SUR SEICHE (35770)", value: "35770" },
  { label: "VEZIN LE COQUET (35132)", value: "35132" },
  { label: "VIEUX VIEL (35610)", value: "35610" },
  { label: "VIEUX VY SUR COUESNON (35490)", value: "35490" },
  { label: "VIGNOC (35630)", value: "35630" },
  { label: "VILLAMEE (35420)", value: "35420" },
  { label: "LA VILLE ES NONAIS (35430)", value: "35430" },
  { label: "VISSEICHE (35130)", value: "35130" },
  { label: "VITRE (35500)", value: "35500" },
  { label: "LE VIVIER SUR MER (35960)", value: "35960" },
  { label: "LE TRONCHET (35540)", value: "35540" },
  { label: "PONT PEAN (35131)", value: "35131" },
  { label: "AIGURANDE (36140)", value: "36140" },
  { label: "AIZE (36150)", value: "36150" },
  { label: "AMBRAULT (36120)", value: "36120" },
  { label: "ANJOUIN (36210)", value: "36210" },
  { label: "ARDENTES (36120)", value: "36120" },
  { label: "ARGENTON SUR CREUSE (36200)", value: "36200" },
  { label: "ARGY (36500)", value: "36500" },
  { label: "ARPHEUILLES (36700)", value: "36700" },
  { label: "ARTHON (36330)", value: "36330" },
  { label: "AZAY LE FERRON (36290)", value: "36290" },
  { label: "BAGNEUX (36210)", value: "36210" },
  { label: "BARAIZE (36270)", value: "36270" },
  { label: "BAUDRES (36110)", value: "36110" },
  { label: "BAZAIGES (36270)", value: "36270" },
  { label: "BEAULIEU (36310)", value: "36310" },
  { label: "BELABRE (36370)", value: "36370" },
  { label: "LA BERTHENOUX (36400)", value: "36400" },
  { label: "LE BLANC (36300)", value: "36300" },
  { label: "BOMMIERS (36120)", value: "36120" },
  { label: "BONNEUIL (36310)", value: "36310" },
  { label: "LES BORDES (36100)", value: "36100" },
  { label: "BOUESSE (36200)", value: "36200" },
  { label: "BOUGES LE CHATEAU (36110)", value: "36110" },
  { label: "BRETAGNE (36110)", value: "36110" },
  { label: "BRIANTES (36400)", value: "36400" },
  { label: "BRION (36110)", value: "36110" },
  { label: "BRIVES (36100)", value: "36100" },
  { label: "LA BUXERETTE (36140)", value: "36140" },
  { label: "BUXEUIL (36150)", value: "36150" },
  { label: "BUXIERES D AILLAC (36230)", value: "36230" },
  { label: "BUZANCAIS (36500)", value: "36500" },
  { label: "CEAULMONT (36200)", value: "36200" },
  { label: "CELON (36200)", value: "36200" },
  { label: "CHABRIS (36210)", value: "36210" },
  { label: "CHAILLAC (36310)", value: "36310" },
  { label: "CHALAIS (36370)", value: "36370" },
  { label: "LA CHAMPENOISE (36100)", value: "36100" },
  { label: "CHAMPILLET (36160)", value: "36160" },
  { label: "LA CHAPELLE ORTHEMALE (36500)", value: "36500" },
  { label: "LA CHAPELLE ST LAURIAN (36150)", value: "36150" },
  { label: "CHASSENEUIL (36800)", value: "36800" },
  { label: "CHASSIGNOLLES (36400)", value: "36400" },
  { label: "CHATEAUROUX (36000)", value: "36000" },
  { label: "CHATILLON SUR INDRE (36700)", value: "36700" },
  { label: "LA CHATRE (36400)", value: "36400" },
  { label: "LA CHATRE LANGLIN (36170)", value: "36170" },
  { label: "CHAVIN (36200)", value: "36200" },
  { label: "CHAZELET (36170)", value: "36170" },
  { label: "CHEZELLES (36500)", value: "36500" },
  { label: "CHITRAY (36800)", value: "36800" },
  { label: "CHOUDAY (36100)", value: "36100" },
  { label: "CIRON (36300)", value: "36300" },
  { label: "CIRON (36300)", value: "36300" },
  { label: "CLERE DU BOIS (36700)", value: "36700" },
  { label: "CLION (36700)", value: "36700" },
  { label: "CLUIS (36340)", value: "36340" },
  { label: "COINGS (36130)", value: "36130" },
  { label: "CONCREMIERS (36300)", value: "36300" },
  { label: "CONDE (36100)", value: "36100" },
  { label: "CREVANT (36140)", value: "36140" },
  { label: "CROZON SUR VAUVRE (36140)", value: "36140" },
  { label: "CUZION (36190)", value: "36190" },
  { label: "DEOLS (36130)", value: "36130" },
  { label: "DIORS (36130)", value: "36130" },
  { label: "DIOU (36260)", value: "36260" },
  { label: "DOUADIC (36300)", value: "36300" },
  { label: "DUNET (36310)", value: "36310" },
  { label: "DUN LE POELIER (36210)", value: "36210" },
  { label: "ECUEILLE (36240)", value: "36240" },
  { label: "EGUZON CHANTOME (36270)", value: "36270" },
  { label: "EGUZON CHANTOME (36270)", value: "36270" },
  { label: "ETRECHET (36120)", value: "36120" },
  { label: "FEUSINES (36160)", value: "36160" },
  { label: "FLERE LA RIVIERE (36700)", value: "36700" },
  { label: "FONTENAY (36150)", value: "36150" },
  { label: "FONTGOMBAULT (36220)", value: "36220" },
  { label: "FONTGUENAND (36600)", value: "36600" },
  { label: "FOUGEROLLES (36230)", value: "36230" },
  { label: "FRANCILLON (36110)", value: "36110" },
  { label: "FREDILLE (36180)", value: "36180" },
  { label: "GARGILESSE DAMPIERRE (36190)", value: "36190" },
  { label: "GEHEE (36240)", value: "36240" },
  { label: "GIROUX (36150)", value: "36150" },
  { label: "GOURNAY (36230)", value: "36230" },
  { label: "GUILLY (36150)", value: "36150" },
  { label: "HEUGNES (36180)", value: "36180" },
  { label: "INGRANDES (36300)", value: "36300" },
  { label: "ISSOUDUN (36100)", value: "36100" },
  { label: "JEU LES BOIS (36120)", value: "36120" },
  { label: "JEU MALOCHES (36240)", value: "36240" },
  { label: "LACS (36400)", value: "36400" },
  { label: "LANGE (36600)", value: "36600" },
  { label: "LEVROUX (36110)", value: "36110" },
  { label: "LEVROUX (36110)", value: "36110" },
  { label: "LEVROUX (36110)", value: "36110" },
  { label: "LIGNAC (36370)", value: "36370" },
  { label: "LIGNEROLLES (36160)", value: "36160" },
  { label: "LINGE (36220)", value: "36220" },
  { label: "LINIEZ (36150)", value: "36150" },
  { label: "LIZERAY (36100)", value: "36100" },
  { label: "LOURDOUEIX ST MICHEL (36140)", value: "36140" },
  { label: "LOUROUER ST LAURENT (36400)", value: "36400" },
  { label: "LUANT (36350)", value: "36350" },
  { label: "LUCAY LE LIBRE (36150)", value: "36150" },
  { label: "LUCAY LE MALE (36360)", value: "36360" },
  { label: "LURAIS (36220)", value: "36220" },
  { label: "LUREUIL (36220)", value: "36220" },
  { label: "LUZERET (36800)", value: "36800" },
  { label: "LYE (36600)", value: "36600" },
  { label: "LYS ST GEORGES (36230)", value: "36230" },
  { label: "LE MAGNY (36400)", value: "36400" },
  { label: "MAILLET (36340)", value: "36340" },
  { label: "MALICORNAY (36340)", value: "36340" },
  { label: "MARON (36120)", value: "36120" },
  { label: "MARTIZAY (36220)", value: "36220" },
  { label: "MAUVIERES (36370)", value: "36370" },
  { label: "MENETOU SUR NAHON (36210)", value: "36210" },
  { label: "MENETREOLS SOUS VATAN (36150)", value: "36150" },
  { label: "LE MENOUX (36200)", value: "36200" },
  { label: "MEOBECQ (36500)", value: "36500" },
  { label: "MERIGNY (36220)", value: "36220" },
  { label: "MERS SUR INDRE (36230)", value: "36230" },
  { label: "MEUNET PLANCHES (36100)", value: "36100" },
  { label: "MEUNET SUR VATAN (36150)", value: "36150" },
  { label: "MEZIERES EN BRENNE (36290)", value: "36290" },
  { label: "MIGNE (36800)", value: "36800" },
  { label: "MIGNY (36260)", value: "36260" },
  { label: "MONTCHEVRIER (36140)", value: "36140" },
  { label: "MONTGIVRAY (36400)", value: "36400" },
  { label: "MONTIERCHAUME (36130)", value: "36130" },
  { label: "MONTIPOURET (36230)", value: "36230" },
  { label: "MONTLEVICQ (36400)", value: "36400" },
  { label: "MOSNAY (36200)", value: "36200" },
  { label: "LA MOTTE FEUILLY (36160)", value: "36160" },
  { label: "MOUHERS (36340)", value: "36340" },
  { label: "MOUHET (36170)", value: "36170" },
  { label: "MOULINS SUR CEPHONS (36110)", value: "36110" },
  { label: "MURS (36700)", value: "36700" },
  { label: "NEONS SUR CREUSE (36220)", value: "36220" },
  { label: "NERET (36400)", value: "36400" },
  { label: "NEUILLAY LES BOIS (36500)", value: "36500" },
  { label: "NEUVY PAILLOUX (36100)", value: "36100" },
  { label: "NEUVY ST SEPULCHRE (36230)", value: "36230" },
  { label: "NIHERNE (36250)", value: "36250" },
  { label: "NOHANT VIC (36400)", value: "36400" },
  { label: "NURET LE FERRON (36800)", value: "36800" },
  { label: "OBTERRE (36290)", value: "36290" },
  { label: "ORSENNES (36190)", value: "36190" },
  { label: "ORVILLE (36210)", value: "36210" },
  { label: "OULCHES (36800)", value: "36800" },
  { label: "PALLUAU SUR INDRE (36500)", value: "36500" },
  { label: "PARNAC (36170)", value: "36170" },
  { label: "PAUDY (36260)", value: "36260" },
  { label: "PAULNAY (36290)", value: "36290" },
  { label: "LE PECHEREAU (36200)", value: "36200" },
  { label: "PELLEVOISIN (36180)", value: "36180" },
  { label: "PERASSAY (36160)", value: "36160" },
  { label: "LA PEROUILLE (36350)", value: "36350" },
  { label: "BADECON LE PIN (36200)", value: "36200" },
  { label: "LE POINCONNET (36330)", value: "36330" },
  { label: "POMMIERS (36190)", value: "36190" },
  { label: "LE PONT CHRETIEN CHABENET (36800)", value: "36800" },
  { label: "POULAINES (36210)", value: "36210" },
  { label: "POULIGNY NOTRE DAME (36160)", value: "36160" },
  { label: "POULIGNY ST MARTIN (36160)", value: "36160" },
  { label: "POULIGNY ST PIERRE (36300)", value: "36300" },
  { label: "PREAUX (36240)", value: "36240" },
  { label: "PREUILLY LA VILLE (36220)", value: "36220" },
  { label: "PRISSAC (36370)", value: "36370" },
  { label: "PRUNIERS (36120)", value: "36120" },
  { label: "REBOURSIN (36150)", value: "36150" },
  { label: "REUILLY (36260)", value: "36260" },
  { label: "RIVARENNES (36800)", value: "36800" },
  { label: "ROSNAY (36300)", value: "36300" },
  { label: "ROUSSINES (36170)", value: "36170" },
  { label: "ROUVRES LES BOIS (36110)", value: "36110" },
  { label: "RUFFEC (36300)", value: "36300" },
  { label: "SACIERGES ST MARTIN (36170)", value: "36170" },
  { label: "ST AIGNY (36300)", value: "36300" },
  { label: "ST AOUSTRILLE (36100)", value: "36100" },
  { label: "ST AOUT (36120)", value: "36120" },
  { label: "ST AUBIN (36100)", value: "36100" },
  { label: "ST BENOIT DU SAULT (36170)", value: "36170" },
  { label: "ST CHARTIER (36400)", value: "36400" },
  { label: "ST CHRISTOPHE EN BAZELLE (36210)", value: "36210" },
  { label: "ST CHRISTOPHE EN BOUCHERIE (36400)", value: "36400" },
  { label: "ST CIVRAN (36170)", value: "36170" },
  { label: "ST CYRAN DU JAMBOT (36700)", value: "36700" },
  { label: "ST DENIS DE JOUHET (36230)", value: "36230" },
  { label: "STE FAUSTE (36100)", value: "36100" },
  { label: "ST FLORENTIN (36150)", value: "36150" },
  { label: "ST GAULTIER (36800)", value: "36800" },
  { label: "STE GEMME (36500)", value: "36500" },
  { label: "ST GENOU (36500)", value: "36500" },
  { label: "ST GEORGES SUR ARNON (36100)", value: "36100" },
  { label: "ST GILLES (36170)", value: "36170" },
  { label: "ST HILAIRE SUR BENAIZE (36370)", value: "36370" },
  { label: "ST LACTENCIN (36500)", value: "36500" },
  { label: "STE LIZAIGNE (36260)", value: "36260" },
  { label: "ST MARCEL (36200)", value: "36200" },
  { label: "ST MAUR (36250)", value: "36250" },
  { label: "ST MAUR (36250)", value: "36250" },
  { label: "ST MEDARD (36700)", value: "36700" },
  { label: "ST MICHEL EN BRENNE (36290)", value: "36290" },
  { label: "ST PIERRE DE JARDS (36260)", value: "36260" },
  { label: "ST PLANTAIRE (36190)", value: "36190" },
  { label: "STE SEVERE SUR INDRE (36160)", value: "36160" },
  { label: "ST VALENTIN (36100)", value: "36100" },
  { label: "SARZAY (36230)", value: "36230" },
  { label: "SASSIERGES ST GERMAIN (36120)", value: "36120" },
  { label: "SAULNAY (36290)", value: "36290" },
  { label: "SAUZELLES (36220)", value: "36220" },
  { label: "SAZERAY (36160)", value: "36160" },
  { label: "SEGRY (36100)", value: "36100" },
  { label: "SELLES SUR NAHON (36180)", value: "36180" },
  { label: "SEMBLECAY (36210)", value: "36210" },
  { label: "SOUGE (36500)", value: "36500" },
  { label: "TENDU (36200)", value: "36200" },
  { label: "THENAY (36800)", value: "36800" },
  { label: "THEVET ST JULIEN (36400)", value: "36400" },
  { label: "THIZAY (36100)", value: "36100" },
  { label: "TILLY (36310)", value: "36310" },
  { label: "TOURNON ST MARTIN (36220)", value: "36220" },
  { label: "LE TRANGER (36700)", value: "36700" },
  { label: "TRANZAULT (36230)", value: "36230" },
  { label: "URCIERS (36160)", value: "36160" },
  { label: "VALENCAY (36600)", value: "36600" },
  { label: "VAL FOUZON (36210)", value: "36210" },
  { label: "VAL FOUZON (36210)", value: "36210" },
  { label: "VAL FOUZON (36210)", value: "36210" },
  { label: "VATAN (36150)", value: "36150" },
  { label: "VELLES (36330)", value: "36330" },
  { label: "VENDOEUVRES (36500)", value: "36500" },
  { label: "LA VERNELLE (36600)", value: "36600" },
  { label: "VERNEUIL SUR IGNERAIE (36400)", value: "36400" },
  { label: "VEUIL (36600)", value: "36600" },
  { label: "VICQ EXEMPLET (36400)", value: "36400" },
  { label: "VICQ SUR NAHON (36600)", value: "36600" },
  { label: "VIGOULANT (36160)", value: "36160" },
  { label: "VIGOUX (36170)", value: "36170" },
  { label: "VIJON (36160)", value: "36160" },
  { label: "VILLEDIEU SUR INDRE (36320)", value: "36320" },
  { label: "VILLEGONGIS (36110)", value: "36110" },
  { label: "VILLEGOUIN (36500)", value: "36500" },
  { label: "VILLENTROIS FAVEROLLES EN BERRY (36360)", value: "36360" },
  { label: "VILLENTROIS FAVEROLLES EN BERRY (36600)", value: "36600" },
  { label: "VILLIERS (36290)", value: "36290" },
  { label: "VINEUIL (36110)", value: "36110" },
  { label: "VOUILLON (36100)", value: "36100" },
  { label: "ABILLY (37160)", value: "37160" },
  { label: "AMBILLOU (37340)", value: "37340" },
  { label: "AMBOISE (37400)", value: "37400" },
  { label: "ANCHE (37500)", value: "37500" },
  { label: "ANTOGNY LE TILLAC (37800)", value: "37800" },
  { label: "ARTANNES SUR INDRE (37260)", value: "37260" },
  { label: "ASSAY (37120)", value: "37120" },
  { label: "ATHEE SUR CHER (37270)", value: "37270" },
  { label: "AUTRECHE (37110)", value: "37110" },
  { label: "AUZOUER EN TOURAINE (37110)", value: "37110" },
  { label: "AVOINE (37420)", value: "37420" },
  { label: "AVON LES ROCHES (37220)", value: "37220" },
  { label: "AVRILLE LES PONCEAUX (37340)", value: "37340" },
  { label: "AZAY LE RIDEAU (37190)", value: "37190" },
  { label: "AZAY SUR CHER (37270)", value: "37270" },
  { label: "AZAY SUR INDRE (37310)", value: "37310" },
  { label: "BALLAN MIRE (37510)", value: "37510" },
  { label: "BARROU (37350)", value: "37350" },
  { label: "BEAULIEU LES LOCHES (37600)", value: "37600" },
  { label: "BEAUMONT LOUESTAULT (37360)", value: "37360" },
  { label: "BEAUMONT LOUESTAULT (37370)", value: "37370" },
  { label: "BEAUMONT EN VERON (37420)", value: "37420" },
  { label: "BEAUMONT VILLAGE (37460)", value: "37460" },
  { label: "BENAIS (37140)", value: "37140" },
  { label: "BERTHENAY (37510)", value: "37510" },
  { label: "BETZ LE CHATEAU (37600)", value: "37600" },
  { label: "BLERE (37150)", value: "37150" },
  { label: "BOSSAY SUR CLAISE (37290)", value: "37290" },
  { label: "BOSSEE (37240)", value: "37240" },
  { label: "LE BOULAY (37110)", value: "37110" },
  { label: "BOURGUEIL (37140)", value: "37140" },
  { label: "BOURNAN (37240)", value: "37240" },
  { label: "BOUSSAY (37290)", value: "37290" },
  { label: "BRASLOU (37120)", value: "37120" },
  { label: "BRAYE SOUS FAYE (37120)", value: "37120" },
  { label: "BRAYE SUR MAULNE (37330)", value: "37330" },
  { label: "BRECHES (37330)", value: "37330" },
  { label: "BREHEMONT (37130)", value: "37130" },
  { label: "BRIDORE (37600)", value: "37600" },
  { label: "BRIZAY (37220)", value: "37220" },
  { label: "BUEIL EN TOURAINE (37370)", value: "37370" },
  { label: "CANDES ST MARTIN (37500)", value: "37500" },
  { label: "CANGEY (37530)", value: "37530" },
  { label: "LA CELLE GUENAND (37350)", value: "37350" },
  { label: "LA CELLE ST AVANT (37160)", value: "37160" },
  { label: "CERE LA RONDE (37460)", value: "37460" },
  { label: "CERELLES (37390)", value: "37390" },
  { label: "CHAMBON (37290)", value: "37290" },
  { label: "CHAMBOURG SUR INDRE (37310)", value: "37310" },
  { label: "CHAMBRAY LES TOURS (37170)", value: "37170" },
  { label: "CHAMPIGNY SUR VEUDE (37120)", value: "37120" },
  { label: "CHANCAY (37210)", value: "37210" },
  { label: "CHANCEAUX PRES LOCHES (37600)", value: "37600" },
  { label: "CHANCEAUX SUR CHOISILLE (37390)", value: "37390" },
  { label: "CHANNAY SUR LATHAN (37330)", value: "37330" },
  { label: "LA CHAPELLE AUX NAUX (37130)", value: "37130" },
  { label: "LA CHAPELLE BLANCHE ST MARTIN (37240)", value: "37240" },
  { label: "LA CHAPELLE SUR LOIRE (37140)", value: "37140" },
  { label: "CHARENTILLY (37390)", value: "37390" },
  { label: "CHARGE (37530)", value: "37530" },
  { label: "CHARNIZAY (37290)", value: "37290" },
  { label: "CHATEAU LA VALLIERE (37330)", value: "37330" },
  { label: "CHATEAU RENAULT (37110)", value: "37110" },
  { label: "CHAUMUSSAY (37350)", value: "37350" },
  { label: "CHAVEIGNES (37120)", value: "37120" },
  { label: "CHEDIGNY (37310)", value: "37310" },
  { label: "CHEILLE (37190)", value: "37190" },
  { label: "CHEMILLE SUR DEME (37370)", value: "37370" },
  { label: "CHEMILLE SUR INDROIS (37460)", value: "37460" },
  { label: "CHENONCEAUX (37150)", value: "37150" },
  { label: "CHEZELLES (37220)", value: "37220" },
  { label: "CHINON (37500)", value: "37500" },
  { label: "CHISSEAUX (37150)", value: "37150" },
  { label: "CHOUZE SUR LOIRE (37140)", value: "37140" },
  { label: "CIGOGNE (37310)", value: "37310" },
  { label: "CINAIS (37500)", value: "37500" },
  { label: "CINQ MARS LA PILE (37130)", value: "37130" },
  { label: "CIRAN (37240)", value: "37240" },
  { label: "CIVRAY DE TOURAINE (37150)", value: "37150" },
  { label: "CIVRAY SUR ESVES (37160)", value: "37160" },
  { label: "CLERE LES PINS (37340)", value: "37340" },
  { label: "CONTINVOIR (37340)", value: "37340" },
  { label: "CORMERY (37320)", value: "37320" },
  { label: "COUESMES (37330)", value: "37330" },
  { label: "COURCAY (37310)", value: "37310" },
  { label: "COURCELLES DE TOURAINE (37330)", value: "37330" },
  { label: "COURCOUE (37120)", value: "37120" },
  { label: "COUZIERS (37500)", value: "37500" },
  { label: "CRAVANT LES COTEAUX (37500)", value: "37500" },
  { label: "CRISSAY SUR MANSE (37220)", value: "37220" },
  { label: "LA CROIX EN TOURAINE (37150)", value: "37150" },
  { label: "CROTELLES (37380)", value: "37380" },
  { label: "CROUZILLES (37220)", value: "37220" },
  { label: "CUSSAY (37240)", value: "37240" },
  { label: "DAME MARIE LES BOIS (37110)", value: "37110" },
  { label: "DIERRE (37150)", value: "37150" },
  { label: "DOLUS LE SEC (37310)", value: "37310" },
  { label: "DRACHE (37800)", value: "37800" },
  { label: "DRUYE (37190)", value: "37190" },
  { label: "EPEIGNE LES BOIS (37150)", value: "37150" },
  { label: "EPEIGNE SUR DEME (37370)", value: "37370" },
  { label: "ESVES LE MOUTIER (37240)", value: "37240" },
  { label: "ESVRES (37320)", value: "37320" },
  { label: "FAYE LA VINEUSE (37120)", value: "37120" },
  { label: "LA FERRIERE (37110)", value: "37110" },
  { label: "FERRIERE LARCON (37350)", value: "37350" },
  { label: "FERRIERE SUR BEAULIEU (37600)", value: "37600" },
  { label: "FONDETTES (37230)", value: "37230" },
  { label: "FRANCUEIL (37150)", value: "37150" },
  { label: "GENILLE (37460)", value: "37460" },
  { label: "GIZEUX (37340)", value: "37340" },
  { label: "LE GRAND PRESSIGNY (37350)", value: "37350" },
  { label: "LA GUERCHE (37350)", value: "37350" },
  { label: "DESCARTES (37160)", value: "37160" },
  { label: "LES HERMITES (37110)", value: "37110" },
  { label: "HOMMES (37340)", value: "37340" },
  { label: "HUISMES (37420)", value: "37420" },
  { label: "L'ILE BOUCHARD (37220)", value: "37220" },
  { label: "JAULNAY (37120)", value: "37120" },
  { label: "JOUE LES TOURS (37300)", value: "37300" },
  { label: "LANGEAIS (37130)", value: "37130" },
  { label: "LANGEAIS (37130)", value: "37130" },
  { label: "LARCAY (37270)", value: "37270" },
  { label: "LEMERE (37120)", value: "37120" },
  { label: "LERNE (37500)", value: "37500" },
  { label: "LE LIEGE (37460)", value: "37460" },
  { label: "LIGNIERES DE TOURAINE (37130)", value: "37130" },
  { label: "LIGRE (37500)", value: "37500" },
  { label: "LIGUEIL (37240)", value: "37240" },
  { label: "LIMERAY (37530)", value: "37530" },
  { label: "LOCHES (37600)", value: "37600" },
  { label: "LOCHE SUR INDROIS (37460)", value: "37460" },
  { label: "LOUANS (37320)", value: "37320" },
  { label: "LE LOUROUX (37240)", value: "37240" },
  { label: "LUBLE (37330)", value: "37330" },
  { label: "LUSSAULT SUR LOIRE (37400)", value: "37400" },
  { label: "LUYNES (37230)", value: "37230" },
  { label: "LUZE (37120)", value: "37120" },
  { label: "LUZILLE (37150)", value: "37150" },
  { label: "MAILLE (37800)", value: "37800" },
  { label: "MANTHELAN (37240)", value: "37240" },
  { label: "MARCAY (37500)", value: "37500" },
  { label: "MARCE SUR ESVES (37160)", value: "37160" },
  { label: "MARCILLY SUR MAULNE (37330)", value: "37330" },
  { label: "MARCILLY SUR VIENNE (37800)", value: "37800" },
  { label: "MARIGNY MARMANDE (37120)", value: "37120" },
  { label: "MARRAY (37370)", value: "37370" },
  { label: "MAZIERES DE TOURAINE (37130)", value: "37130" },
  { label: "LA MEMBROLLE SUR CHOISILLE (37390)", value: "37390" },
  { label: "METTRAY (37390)", value: "37390" },
  { label: "MONNAIE (37380)", value: "37380" },
  { label: "MONTBAZON (37250)", value: "37250" },
  { label: "MONTHODON (37110)", value: "37110" },
  { label: "MONTLOUIS SUR LOIRE (37270)", value: "37270" },
  { label: "MONTRESOR (37460)", value: "37460" },
  { label: "MONTREUIL EN TOURAINE (37530)", value: "37530" },
  { label: "MONTS (37260)", value: "37260" },
  { label: "MORAND (37110)", value: "37110" },
  { label: "MOSNES (37530)", value: "37530" },
  { label: "MOUZAY (37600)", value: "37600" },
  { label: "NAZELLES NEGRON (37530)", value: "37530" },
  { label: "NEUIL (37190)", value: "37190" },
  { label: "NEUILLE LE LIERRE (37380)", value: "37380" },
  { label: "NEUILLE PONT PIERRE (37360)", value: "37360" },
  { label: "NEUILLY LE BRIGNON (37160)", value: "37160" },
  { label: "NEUVILLE SUR BRENNE (37110)", value: "37110" },
  { label: "NEUVY LE ROI (37370)", value: "37370" },
  { label: "NOIZAY (37210)", value: "37210" },
  { label: "NOTRE DAME D OE (37390)", value: "37390" },
  { label: "NOUANS LES FONTAINES (37460)", value: "37460" },
  { label: "NOUATRE (37800)", value: "37800" },
  { label: "NOUZILLY (37380)", value: "37380" },
  { label: "NOYANT DE TOURAINE (37800)", value: "37800" },
  { label: "ORBIGNY (37460)", value: "37460" },
  { label: "PANZOULT (37220)", value: "37220" },
  { label: "PARCAY MESLAY (37210)", value: "37210" },
  { label: "PARCAY SUR VIENNE (37220)", value: "37220" },
  { label: "PAULMY (37350)", value: "37350" },
  { label: "PERNAY (37230)", value: "37230" },
  { label: "PERRUSSON (37600)", value: "37600" },
  { label: "LE PETIT PRESSIGNY (37350)", value: "37350" },
  { label: "POCE SUR CISSE (37530)", value: "37530" },
  { label: "PONT DE RUAN (37260)", value: "37260" },
  { label: "PORTS SUR VIENNE (37800)", value: "37800" },
  { label: "POUZAY (37800)", value: "37800" },
  { label: "PREUILLY SUR CLAISE (37290)", value: "37290" },
  { label: "PUSSIGNY (37800)", value: "37800" },
  { label: "RAZINES (37120)", value: "37120" },
  { label: "REIGNAC SUR INDRE (37310)", value: "37310" },
  { label: "RESTIGNE (37140)", value: "37140" },
  { label: "REUGNY (37380)", value: "37380" },
  { label: "LA RICHE (37520)", value: "37520" },
  { label: "RICHELIEU (37120)", value: "37120" },
  { label: "RIGNY USSE (37420)", value: "37420" },
  { label: "RILLE (37340)", value: "37340" },
  { label: "RILLY SUR VIENNE (37220)", value: "37220" },
  { label: "RIVARENNES (37190)", value: "37190" },
  { label: "RIVIERE (37500)", value: "37500" },
  { label: "LA ROCHE CLERMAULT (37500)", value: "37500" },
  { label: "ROCHECORBON (37210)", value: "37210" },
  { label: "ROUZIERS DE TOURAINE (37360)", value: "37360" },
  { label: "SACHE (37190)", value: "37190" },
  { label: "ST ANTOINE DU ROCHER (37360)", value: "37360" },
  { label: "ST AUBIN LE DEPEINT (37370)", value: "37370" },
  { label: "ST AVERTIN (37550)", value: "37550" },
  { label: "ST BENOIT LA FORET (37500)", value: "37500" },
  { label: "ST BRANCHS (37320)", value: "37320" },
  { label: "STE CATHERINE DE FIERBOIS (37800)", value: "37800" },
  { label: "ST CHRISTOPHE SUR LE NAIS (37370)", value: "37370" },
  { label: "ST CYR SUR LOIRE (37540)", value: "37540" },
  { label: "ST EPAIN (37800)", value: "37800" },
  { label: "ST ETIENNE DE CHIGNY (37230)", value: "37230" },
  { label: "ST FLOVIER (37600)", value: "37600" },
  { label: "ST GENOUPH (37510)", value: "37510" },
  { label: "ST GERMAIN SUR VIENNE (37500)", value: "37500" },
  { label: "ST HIPPOLYTE (37600)", value: "37600" },
  { label: "ST JEAN ST GERMAIN (37600)", value: "37600" },
  { label: "ST LAURENT DE LIN (37330)", value: "37330" },
  { label: "ST LAURENT EN GATINES (37380)", value: "37380" },
  { label: "ST MARTIN LE BEAU (37270)", value: "37270" },
  { label: "STE MAURE DE TOURAINE (37800)", value: "37800" },
  { label: "ST NICOLAS DE BOURGUEIL (37140)", value: "37140" },
  { label: "ST NICOLAS DES MOTETS (37110)", value: "37110" },
  { label: "ST OUEN LES VIGNES (37530)", value: "37530" },
  { label: "ST PATERNE RACAN (37370)", value: "37370" },
  { label: "COTEAUX SUR LOIRE (37130)", value: "37130" },
  { label: "COTEAUX SUR LOIRE (37130)", value: "37130" },
  { label: "COTEAUX SUR LOIRE (37140)", value: "37140" },
  { label: "ST PIERRE DES CORPS (37700)", value: "37700" },
  { label: "ST QUENTIN SUR INDROIS (37310)", value: "37310" },
  { label: "ST REGLE (37530)", value: "37530" },
  { label: "ST ROCH (37390)", value: "37390" },
  { label: "ST SENOCH (37600)", value: "37600" },
  { label: "SAUNAY (37110)", value: "37110" },
  { label: "SAVIGNE SUR LATHAN (37340)", value: "37340" },
  { label: "SAVIGNY EN VERON (37420)", value: "37420" },
  { label: "SAVONNIERES (37510)", value: "37510" },
  { label: "SAZILLY (37220)", value: "37220" },
  { label: "SEMBLANCAY (37360)", value: "37360" },
  { label: "SENNEVIERES (37600)", value: "37600" },
  { label: "SEPMES (37800)", value: "37800" },
  { label: "SEUILLY (37500)", value: "37500" },
  { label: "SONZAY (37360)", value: "37360" },
  { label: "SORIGNY (37250)", value: "37250" },
  { label: "SOUVIGNE (37330)", value: "37330" },
  { label: "SOUVIGNY DE TOURAINE (37530)", value: "37530" },
  { label: "SUBLAINES (37310)", value: "37310" },
  { label: "TAUXIGNY ST BAULD (37310)", value: "37310" },
  { label: "TAUXIGNY ST BAULD (37310)", value: "37310" },
  { label: "TAVANT (37220)", value: "37220" },
  { label: "THENEUIL (37220)", value: "37220" },
  { label: "THILOUZE (37260)", value: "37260" },
  { label: "THIZAY (37500)", value: "37500" },
  { label: "TOURNON ST PIERRE (37290)", value: "37290" },
  { label: "LA TOUR ST GELIN (37120)", value: "37120" },
  { label: "TOURS (37000)", value: "37000" },
  { label: "TOURS (37100)", value: "37100" },
  { label: "TOURS (37200)", value: "37200" },
  { label: "TROGUES (37220)", value: "37220" },
  { label: "TRUYES (37320)", value: "37320" },
  { label: "VALLERES (37190)", value: "37190" },
  { label: "VARENNES (37600)", value: "37600" },
  { label: "VEIGNE (37250)", value: "37250" },
  { label: "VERETZ (37270)", value: "37270" },
  { label: "VERNEUIL LE CHATEAU (37120)", value: "37120" },
  { label: "VERNEUIL SUR INDRE (37600)", value: "37600" },
  { label: "VERNOU SUR BRENNE (37210)", value: "37210" },
  { label: "VILLAINES LES ROCHERS (37190)", value: "37190" },
  { label: "VILLANDRY (37510)", value: "37510" },
  { label: "LA VILLE AUX DAMES (37700)", value: "37700" },
  { label: "VILLEBOURG (37370)", value: "37370" },
  { label: "VILLEDOMAIN (37460)", value: "37460" },
  { label: "VILLEDOMER (37110)", value: "37110" },
  { label: "VILLELOIN COULANGE (37460)", value: "37460" },
  { label: "VILLEPERDUE (37260)", value: "37260" },
  { label: "VILLIERS AU BOUIN (37330)", value: "37330" },
  { label: "VOU (37240)", value: "37240" },
  { label: "VOUVRAY (37210)", value: "37210" },
  { label: "YZEURES SUR CREUSE (37290)", value: "37290" },
  { label: "LES ABRETS EN DAUPHINE (38490)", value: "38490" },
  { label: "LES ABRETS EN DAUPHINE (38490)", value: "38490" },
  { label: "LES ABRETS EN DAUPHINE (38490)", value: "38490" },
  { label: "LES ADRETS (38190)", value: "38190" },
  { label: "LES ADRETS (38190)", value: "38190" },
  { label: "AGNIN (38150)", value: "38150" },
  { label: "L'ALBENC (38470)", value: "38470" },
  { label: "ALLEMOND (38114)", value: "38114" },
  { label: "ALLEVARD (38580)", value: "38580" },
  { label: "AMBEL (38970)", value: "38970" },
  { label: "ANJOU (38150)", value: "38150" },
  { label: "ANNOISIN CHATELANS (38460)", value: "38460" },
  { label: "ANTHON (38280)", value: "38280" },
  { label: "AOSTE (38490)", value: "38490" },
  { label: "APPRIEU (38140)", value: "38140" },
  { label: "APPRIEU (38140)", value: "38140" },
  { label: "ARTAS (38440)", value: "38440" },
  { label: "ASSIEU (38150)", value: "38150" },
  { label: "AUBERIVES EN ROYANS (38680)", value: "38680" },
  { label: "AUBERIVES SUR VAREZE (38550)", value: "38550" },
  { label: "AURIS (38142)", value: "38142" },
  { label: "LES AVENIERES VEYRINS THUELLIN (38630)", value: "38630" },
  { label: "LES AVENIERES VEYRINS THUELLIN (38630)", value: "38630" },
  { label: "LES AVENIERES VEYRINS THUELLIN (38630)", value: "38630" },
  { label: "AVIGNONET (38650)", value: "38650" },
  { label: "LA BALME LES GROTTES (38390)", value: "38390" },
  { label: "BARRAUX (38530)", value: "38530" },
  { label: "LA BATIE MONTGASCON (38110)", value: "38110" },
  { label: "BEAUCROISSANT (38140)", value: "38140" },
  { label: "BEAUFIN (38970)", value: "38970" },
  { label: "BEAUFORT (38270)", value: "38270" },
  { label: "BEAULIEU (38470)", value: "38470" },
  { label: "BEAUREPAIRE (38270)", value: "38270" },
  { label: "BEAUVOIR DE MARC (38440)", value: "38440" },
  { label: "BEAUVOIR EN ROYANS (38160)", value: "38160" },
  { label: "BELLEGARDE POUSSIEU (38270)", value: "38270" },
  { label: "BELMONT (38690)", value: "38690" },
  { label: "BERNIN (38190)", value: "38190" },
  { label: "BESSE (38142)", value: "38142" },
  { label: "BESSINS (38160)", value: "38160" },
  { label: "BEVENAIS (38690)", value: "38690" },
  { label: "BILIEU (38850)", value: "38850" },
  { label: "BIOL (38690)", value: "38690" },
  { label: "BIVIERS (38330)", value: "38330" },
  { label: "BIZONNES (38690)", value: "38690" },
  { label: "BLANDIN (38730)", value: "38730" },
  { label: "BONNEFAMILLE (38090)", value: "38090" },
  { label: "BOSSIEU (38260)", value: "38260" },
  { label: "LE BOUCHAGE (38510)", value: "38510" },
  { label: "BOUGE CHAMBALUD (38150)", value: "38150" },
  { label: "LE BOURG D OISANS (38520)", value: "38520" },
  { label: "BOURGOIN JALLIEU (38300)", value: "38300" },
  { label: "BOURGOIN JALLIEU (38300)", value: "38300" },
  { label: "BOUVESSE QUIRIEU (38390)", value: "38390" },
  { label: "BRANGUES (38510)", value: "38510" },
  { label: "BRESSIEUX (38870)", value: "38870" },
  { label: "BRESSON (38320)", value: "38320" },
  { label: "BREZINS (38590)", value: "38590" },
  { label: "BRIE ET ANGONNES (38320)", value: "38320" },
  { label: "BRION (38590)", value: "38590" },
  { label: "LA BUISSE (38500)", value: "38500" },
  { label: "LA BUISSIERE (38530)", value: "38530" },
  { label: "BURCIN (38690)", value: "38690" },
  { label: "CESSIEU (38110)", value: "38110" },
  { label: "CHABONS (38690)", value: "38690" },
  { label: "CHALON (38122)", value: "38122" },
  { label: "CHAMAGNIEU (38460)", value: "38460" },
  { label: "CHAMAGNIEU (38460)", value: "38460" },
  { label: "CHAMPAGNIER (38800)", value: "38800" },
  { label: "CHAMPIER (38260)", value: "38260" },
  { label: "LE CHAMP PRES FROGES (38190)", value: "38190" },
  { label: "CHAMP SUR DRAC (38560)", value: "38560" },
  { label: "CHANAS (38150)", value: "38150" },
  { label: "CHANTEPERIER (38740)", value: "38740" },
  { label: "CHANTEPERIER (38740)", value: "38740" },
  { label: "CHANTESSE (38470)", value: "38470" },
  { label: "CHAPAREILLAN (38530)", value: "38530" },
  { label: "LA CHAPELLE DE LA TOUR (38110)", value: "38110" },
  { label: "LA CHAPELLE DE SURIEU (38150)", value: "38150" },
  { label: "LA CHAPELLE DU BARD (38580)", value: "38580" },
  { label: "CHARANCIEU (38490)", value: "38490" },
  { label: "CHARANTONNAY (38790)", value: "38790" },
  { label: "CHARAVINES (38850)", value: "38850" },
  { label: "CHARETTE (38390)", value: "38390" },
  { label: "CHARNECLES (38140)", value: "38140" },
  { label: "CHARVIEU CHAVAGNEUX (38230)", value: "38230" },
  { label: "CHARVIEU CHAVAGNEUX (38230)", value: "38230" },
  { label: "CHASSELAY (38470)", value: "38470" },
  { label: "CHASSE SUR RHONE (38670)", value: "38670" },
  { label: "CHASSIGNIEU (38730)", value: "38730" },
  { label: "CHATEAU BERNARD (38650)", value: "38650" },
  { label: "CHATEAUVILAIN (38300)", value: "38300" },
  { label: "CHATELUS (38680)", value: "38680" },
  { label: "CHATENAY (38980)", value: "38980" },
  { label: "CHATONNAY (38440)", value: "38440" },
  { label: "CHATTE (38160)", value: "38160" },
  { label: "CHAVANOZ (38230)", value: "38230" },
  { label: "CHELIEU (38730)", value: "38730" },
  { label: "CHEVRIERES (38160)", value: "38160" },
  { label: "LE CHEYLAS (38570)", value: "38570" },
  { label: "CHEYSSIEU (38550)", value: "38550" },
  { label: "CHEZENEUVE (38300)", value: "38300" },
  { label: "CHICHILIANNE (38930)", value: "38930" },
  { label: "CHIMILIN (38490)", value: "38490" },
  { label: "CHIRENS (38850)", value: "38850" },
  { label: "CHOLONGE (38220)", value: "38220" },
  { label: "CHONAS L AMBALLAN (38121)", value: "38121" },
  { label: "CHORANCHE (38680)", value: "38680" },
  { label: "CHOZEAU (38460)", value: "38460" },
  { label: "CHUZELLES (38200)", value: "38200" },
  { label: "CLAIX (38640)", value: "38640" },
  { label: "CLAVANS EN HAUT OISANS (38142)", value: "38142" },
  { label: "CLELLES (38930)", value: "38930" },
  { label: "CLONAS SUR VAREZE (38550)", value: "38550" },
  { label: "ST MARTIN DE LA CLUZE (38650)", value: "38650" },
  { label: "COGNET (38350)", value: "38350" },
  { label: "COGNIN LES GORGES (38470)", value: "38470" },
  { label: "COLOMBE (38690)", value: "38690" },
  { label: "LA COMBE DE LANCEY (38190)", value: "38190" },
  { label: "CORBELIN (38630)", value: "38630" },
  { label: "CORENC (38700)", value: "38700" },
  { label: "CORNILLON EN TRIEVES (38710)", value: "38710" },
  { label: "CORPS (38970)", value: "38970" },
  { label: "CORRENCON EN VERCORS (38250)", value: "38250" },
  { label: "LA COTE ST ANDRE (38260)", value: "38260" },
  { label: "LES COTES D AREY (38138)", value: "38138" },
  { label: "LES COTES DE CORPS (38970)", value: "38970" },
  { label: "COUBLEVIE (38500)", value: "38500" },
  { label: "COUR ET BUIS (38122)", value: "38122" },
  { label: "COURTENAY (38510)", value: "38510" },
  { label: "CRACHIER (38300)", value: "38300" },
  { label: "CRAS (38210)", value: "38210" },
  { label: "CREMIEU (38460)", value: "38460" },
  { label: "CREYS MEPIEU (38510)", value: "38510" },
  { label: "CREYS MEPIEU (38510)", value: "38510" },
  { label: "CREYS MEPIEU (38510)", value: "38510" },
  { label: "CROLLES (38920)", value: "38920" },
  { label: "CULIN (38300)", value: "38300" },
  { label: "DIEMOZ (38790)", value: "38790" },
  { label: "DIZIMIEU (38460)", value: "38460" },
  { label: "DOISSIN (38730)", value: "38730" },
  { label: "DOLOMIEU (38110)", value: "38110" },
  { label: "DOMARIN (38300)", value: "38300" },
  { label: "DOMENE (38420)", value: "38420" },
  { label: "ECHIROLLES (38130)", value: "38130" },
  { label: "ECLOSE BADINIERES (38300)", value: "38300" },
  { label: "ECLOSE BADINIERES (38300)", value: "38300" },
  { label: "ENGINS (38360)", value: "38360" },
  { label: "ENTRAIGUES (38740)", value: "38740" },
  { label: "ENTRE DEUX GUIERS (38380)", value: "38380" },
  { label: "LES EPARRES (38300)", value: "38300" },
  { label: "ESTRABLIN (38780)", value: "38780" },
  { label: "EYBENS (38320)", value: "38320" },
  { label: "EYDOCHE (38690)", value: "38690" },
  { label: "EYZIN PINET (38780)", value: "38780" },
  { label: "FARAMANS (38260)", value: "38260" },
  { label: "FAVERGES DE LA TOUR (38110)", value: "38110" },
  { label: "LE HAUT BREDA (38580)", value: "38580" },
  { label: "LE HAUT BREDA (38580)", value: "38580" },
  { label: "LE HAUT BREDA (38580)", value: "38580" },
  { label: "LA FLACHERE (38530)", value: "38530" },
  { label: "FLACHERES (38690)", value: "38690" },
  { label: "FONTAINE (38600)", value: "38600" },
  { label: "FONTANIL CORNILLON (38120)", value: "38120" },
  { label: "LA FORTERESSE (38590)", value: "38590" },
  { label: "FOUR (38080)", value: "38080" },
  { label: "LE FRENEY D OISANS (38142)", value: "38142" },
  { label: "LA FRETTE (38260)", value: "38260" },
  { label: "FROGES (38190)", value: "38190" },
  { label: "FRONTONAS (38290)", value: "38290" },
  { label: "FRONTONAS (38290)", value: "38290" },
  { label: "LA GARDE (38520)", value: "38520" },
  { label: "GIERES (38610)", value: "38610" },
  { label: "GILLONNAY (38260)", value: "38260" },
  { label: "GONCELIN (38570)", value: "38570" },
  { label: "LE GRAND LEMPS (38690)", value: "38690" },
  { label: "GRANIEU (38490)", value: "38490" },
  { label: "GRENAY (38540)", value: "38540" },
  { label: "GRENOBLE (38000)", value: "38000" },
  { label: "GRENOBLE (38100)", value: "38100" },
  { label: "GRESSE EN VERCORS (38650)", value: "38650" },
  { label: "LE GUA (38450)", value: "38450" },
  { label: "HERBEYS (38320)", value: "38320" },
  { label: "HEYRIEUX (38540)", value: "38540" },
  { label: "HIERES SUR AMBY (38118)", value: "38118" },
  { label: "HUEZ (38750)", value: "38750" },
  { label: "HUEZ (38750)", value: "38750" },
  { label: "HURTIERES (38570)", value: "38570" },
  { label: "L'ISLE D ABEAU (38080)", value: "38080" },
  { label: "IZEAUX (38140)", value: "38140" },
  { label: "IZERON (38160)", value: "38160" },
  { label: "JANNEYRIAS (38280)", value: "38280" },
  { label: "JARCIEU (38270)", value: "38270" },
  { label: "JARDIN (38200)", value: "38200" },
  { label: "JARRIE (38560)", value: "38560" },
  { label: "LAFFREY (38220)", value: "38220" },
  { label: "LALLEY (38930)", value: "38930" },
  { label: "LANS EN VERCORS (38250)", value: "38250" },
  { label: "LAVAL EN BELLEDONNE (38190)", value: "38190" },
  { label: "LAVALDENS (38350)", value: "38350" },
  { label: "LAVARS (38710)", value: "38710" },
  { label: "LENTIOL (38270)", value: "38270" },
  { label: "LEYRIEU (38460)", value: "38460" },
  { label: "LIEUDIEU (38440)", value: "38440" },
  { label: "LIVET ET GAVET (38220)", value: "38220" },
  { label: "LIVET ET GAVET (38220)", value: "38220" },
  { label: "LIVET ET GAVET (38220)", value: "38220" },
  { label: "LIVET ET GAVET (38220)", value: "38220" },
  { label: "LONGECHENAL (38690)", value: "38690" },
  { label: "LUMBIN (38660)", value: "38660" },
  { label: "LUZINAY (38200)", value: "38200" },
  { label: "MALLEVAL EN VERCORS (38470)", value: "38470" },
  { label: "MARCIEU (38350)", value: "38350" },
  { label: "MARCILLOLES (38260)", value: "38260" },
  { label: "MARCOLLIN (38270)", value: "38270" },
  { label: "MARNANS (38980)", value: "38980" },
  { label: "MASSIEU (38620)", value: "38620" },
  { label: "MAUBEC (38300)", value: "38300" },
  { label: "MAYRES SAVEL (38350)", value: "38350" },
  { label: "AUTRANS MEAUDRE EN VERCORS (38112)", value: "38112" },
  { label: "AUTRANS MEAUDRE EN VERCORS (38880)", value: "38880" },
  { label: "MENS (38710)", value: "38710" },
  { label: "MENS (38710)", value: "38710" },
  { label: "MERLAS (38620)", value: "38620" },
  { label: "MEYLAN (38240)", value: "38240" },
  { label: "MEYRIE (38300)", value: "38300" },
  { label: "MEYRIEU LES ETANGS (38440)", value: "38440" },
  { label: "MEYSSIEZ (38440)", value: "38440" },
  { label: "MIRIBEL LANCHATRE (38450)", value: "38450" },
  { label: "MIRIBEL LES ECHELLES (38380)", value: "38380" },
  { label: "MIZOEN (38142)", value: "38142" },
  { label: "MOIDIEU DETOURBE (38440)", value: "38440" },
  { label: "MOIRANS (38430)", value: "38430" },
  { label: "MOISSIEU SUR DOLON (38270)", value: "38270" },
  { label: "MONESTIER D AMBEL (38970)", value: "38970" },
  { label: "MONESTIER DE CLERMONT (38650)", value: "38650" },
  { label: "MONESTIER DU PERCY (38930)", value: "38930" },
  { label: "MONSTEROUX MILIEU (38122)", value: "38122" },
  { label: "MONTAGNE (38160)", value: "38160" },
  { label: "MONTAGNIEU (38110)", value: "38110" },
  { label: "MONTALIEU VERCIEU (38390)", value: "38390" },
  { label: "MONTAUD (38210)", value: "38210" },
  { label: "MONTBONNOT ST MARTIN (38330)", value: "38330" },
  { label: "MONTCARRA (38890)", value: "38890" },
  { label: "MONTCHABOUD (38220)", value: "38220" },
  { label: "LES DEUX ALPES (38520)", value: "38520" },
  { label: "LES DEUX ALPES (38860)", value: "38860" },
  { label: "LES DEUX ALPES (38860)", value: "38860" },
  { label: "LES DEUX ALPES (38860)", value: "38860" },
  { label: "LES DEUX ALPES (38860)", value: "38860" },
  { label: "MONTEYNARD (38770)", value: "38770" },
  { label: "MONTFALCON (38940)", value: "38940" },
  { label: "MONTFERRAT (38620)", value: "38620" },
  { label: "MONTREVEL (38690)", value: "38690" },
  { label: "MONT ST MARTIN (38120)", value: "38120" },
  { label: "MONTSEVEROUX (38122)", value: "38122" },
  { label: "MORAS (38460)", value: "38460" },
  { label: "MORESTEL (38510)", value: "38510" },
  { label: "MORETTE (38210)", value: "38210" },
  { label: "LA MORTE (38350)", value: "38350" },
  { label: "LA MOTTE D AVEILLANS (38770)", value: "38770" },
  { label: "LA MOTTE ST MARTIN (38770)", value: "38770" },
  { label: "MOTTIER (38260)", value: "38260" },
  { label: "LE MOUTARET (38580)", value: "38580" },
  { label: "LA MURE (38350)", value: "38350" },
  { label: "LA MURETTE (38140)", value: "38140" },
  { label: "MURIANETTE (38420)", value: "38420" },
  { label: "MURINAIS (38160)", value: "38160" },
  { label: "NANTES EN RATIER (38350)", value: "38350" },
  { label: "SERRE NERPOL (38470)", value: "38470" },
  { label: "NIVOLAS VERMELLE (38300)", value: "38300" },
  { label: "NOTRE DAME DE COMMIERS (38450)", value: "38450" },
  { label: "NOTRE DAME DE L OSIER (38470)", value: "38470" },
  { label: "NOTRE DAME DE MESAGE (38220)", value: "38220" },
  { label: "NOTRE DAME DE VAULX (38144)", value: "38144" },
  { label: "NOYAREY (38360)", value: "38360" },
  { label: "OPTEVOZ (38460)", value: "38460" },
  { label: "ORIS EN RATTIER (38350)", value: "38350" },
  { label: "ORNACIEUX BALBINS (38260)", value: "38260" },
  { label: "ORNACIEUX BALBINS (38260)", value: "38260" },
  { label: "ORNON (38520)", value: "38520" },
  { label: "OULLES (38520)", value: "38520" },
  { label: "OYEU (38690)", value: "38690" },
  { label: "OYTIER ST OBLAS (38780)", value: "38780" },
  { label: "OZ (38114)", value: "38114" },
  { label: "PACT (38270)", value: "38270" },
  { label: "PAJAY (38260)", value: "38260" },
  { label: "VILLAGES DU LAC DE PALADRU (38730)", value: "38730" },
  { label: "VILLAGES DU LAC DE PALADRU (38850)", value: "38850" },
  { label: "PANOSSAS (38460)", value: "38460" },
  { label: "PARMILIEU (38390)", value: "38390" },
  { label: "LE PASSAGE (38490)", value: "38490" },
  { label: "ARANDON PASSINS (38510)", value: "38510" },
  { label: "ARANDON PASSINS (38510)", value: "38510" },
  { label: "LE PEAGE DE ROUSSILLON (38550)", value: "38550" },
  { label: "PELLAFOL (38970)", value: "38970" },
  { label: "PENOL (38260)", value: "38260" },
  { label: "LE PERCY (38930)", value: "38930" },
  { label: "LA PIERRE (38570)", value: "38570" },
  { label: "PIERRE CHATEL (38119)", value: "38119" },
  { label: "PISIEU (38270)", value: "38270" },
  { label: "PLAN (38590)", value: "38590" },
  { label: "POISAT (38320)", value: "38320" },
  { label: "POLIENAS (38210)", value: "38210" },
  { label: "POMMIER DE BEAUREPAIRE (38260)", value: "38260" },
  { label: "PONSONNAS (38350)", value: "38350" },
  { label: "PONTCHARRA (38530)", value: "38530" },
  { label: "LE PONT DE BEAUVOISIN (38480)", value: "38480" },
  { label: "PONT DE CHERUY (38230)", value: "38230" },
  { label: "LE PONT DE CLAIX (38800)", value: "38800" },
  { label: "PONT EVEQUE (38780)", value: "38780" },
  { label: "PONT EN ROYANS (38680)", value: "38680" },
  { label: "PORCIEU AMBLAGNIEU (38390)", value: "38390" },
  { label: "PREBOIS (38710)", value: "38710" },
  { label: "PRESLES (38680)", value: "38680" },
  { label: "PRESSINS (38480)", value: "38480" },
  { label: "PRIMARETTE (38270)", value: "38270" },
  { label: "PROVEYSIEUX (38120)", value: "38120" },
  { label: "PRUNIERES (38350)", value: "38350" },
  { label: "QUAIX EN CHARTREUSE (38950)", value: "38950" },
  { label: "QUET EN BEAUMONT (38970)", value: "38970" },
  { label: "QUINCIEU (38470)", value: "38470" },
  { label: "REAUMONT (38140)", value: "38140" },
  { label: "RENAGE (38140)", value: "38140" },
  { label: "RENCUREL (38680)", value: "38680" },
  { label: "REVEL (38420)", value: "38420" },
  { label: "REVEL TOURDAN (38270)", value: "38270" },
  { label: "REVENTIN VAUGRIS (38121)", value: "38121" },
  { label: "RIVES (38140)", value: "38140" },
  { label: "LA RIVIERE (38210)", value: "38210" },
  { label: "ROCHE (38090)", value: "38090" },
  { label: "ROCHE (38090)", value: "38090" },
  { label: "LES ROCHES DE CONDRIEU (38370)", value: "38370" },
  { label: "ROCHETOIRIN (38110)", value: "38110" },
  { label: "ROISSARD (38650)", value: "38650" },
  { label: "ROMAGNIEU (38480)", value: "38480" },
  { label: "ROUSSILLON (38150)", value: "38150" },
  { label: "ROVON (38470)", value: "38470" },
  { label: "ROYAS (38440)", value: "38440" },
  { label: "ROYBON (38940)", value: "38940" },
  { label: "RUY MONTCEAU (38300)", value: "38300" },
  { label: "RUY MONTCEAU (38300)", value: "38300" },
  { label: "SABLONS (38550)", value: "38550" },
  { label: "STE AGNES (38190)", value: "38190" },
  { label: "ST AGNIN SUR BION (38300)", value: "38300" },
  { label: "ST ALBAN DE ROCHE (38080)", value: "38080" },
  { label: "ST ALBAN DU RHONE (38370)", value: "38370" },
  { label: "ST ALBIN DE VAULSERRE (38480)", value: "38480" },
  { label: "ST ANDEOL (38650)", value: "38650" },
  { label: "ST ANDRE EN ROYANS (38680)", value: "38680" },
  { label: "ST ANDRE LE GAZ (38490)", value: "38490" },
  { label: "STE ANNE SUR GERVONDE (38440)", value: "38440" },
  { label: "ST ANTOINE L ABBAYE (38160)", value: "38160" },
  { label: "ST ANTOINE L ABBAYE (38160)", value: "38160" },
  { label: "ST APPOLINARD (38160)", value: "38160" },
  { label: "ST AREY (38350)", value: "38350" },
  { label: "ST AUPRE (38960)", value: "38960" },
  { label: "ST BARTHELEMY (38270)", value: "38270" },
  { label: "ST BARTHELEMY DE SECHILIENNE (38220)", value: "38220" },
  { label: "ST BAUDILLE DE LA TOUR (38118)", value: "38118" },
  { label: "ST BAUDILLE ET PIPET (38710)", value: "38710" },
  { label: "ST BLAISE DU BUIS (38140)", value: "38140" },
  { label: "STE BLANDINE (38110)", value: "38110" },
  { label: "ST BONNET DE CHAVAGNE (38840)", value: "38840" },
  { label: "ST BUEIL (38620)", value: "38620" },
  { label: "ST CASSIEN (38500)", value: "38500" },
  { label: "ST CHEF (38890)", value: "38890" },
  { label: "ST CHRISTOPHE EN OISANS (38520)", value: "38520" },
  { label: "ST CHRISTOPHE SUR GUIERS (38380)", value: "38380" },
  { label: "ST CLAIR DE LA TOUR (38110)", value: "38110" },
  { label: "ST CLAIR DU RHONE (38370)", value: "38370" },
  { label: "ST CLAIR SUR GALAURE (38940)", value: "38940" },
  { label: "ST DIDIER DE BIZONNES (38690)", value: "38690" },
  { label: "ST DIDIER DE LA TOUR (38110)", value: "38110" },
  { label: "ST EGREVE (38120)", value: "38120" },
  { label: "ST ETIENNE DE CROSSEY (38960)", value: "38960" },
  { label: "ST ETIENNE DE ST GEOIRS (38590)", value: "38590" },
  { label: "ST GEOIRE EN VALDAINE (38620)", value: "38620" },
  { label: "ST GEOIRS (38590)", value: "38590" },
  { label: "ST GEORGES DE COMMIERS (38450)", value: "38450" },
  { label: "ST GEORGES D ESPERANCHE (38790)", value: "38790" },
  { label: "ST GEORGES D ESPERANCHE (38790)", value: "38790" },
  { label: "ST GERVAIS (38470)", value: "38470" },
  { label: "ST GUILLAUME (38650)", value: "38650" },
  { label: "ST HILAIRE DE BRENS (38460)", value: "38460" },
  { label: "ST HILAIRE DE LA COTE (38260)", value: "38260" },
  { label: "ST HILAIRE DU ROSIER (38840)", value: "38840" },
  { label: "PLATEAU DES PETITES ROCHES (38660)", value: "38660" },
  { label: "PLATEAU DES PETITES ROCHES (38660)", value: "38660" },
  { label: "PLATEAU DES PETITES ROCHES (38660)", value: "38660" },
  { label: "ST HONORE (38350)", value: "38350" },
  { label: "ST ISMIER (38330)", value: "38330" },
  { label: "ST JEAN D AVELANNE (38480)", value: "38480" },
  { label: "ST JEAN DE BOURNAY (38440)", value: "38440" },
  { label: "ST JEAN DE MOIRANS (38430)", value: "38430" },
  { label: "ST JEAN DE SOUDAIN (38110)", value: "38110" },
  { label: "ST JEAN DE VAULX (38220)", value: "38220" },
  { label: "ST JEAN D HERANS (38710)", value: "38710" },
  { label: "ST JEAN LE VIEUX (38420)", value: "38420" },
  { label: "ST JOSEPH DE RIVIERE (38134)", value: "38134" },
  { label: "ST JULIEN DE L HERMS (38122)", value: "38122" },
  { label: "LA SURE EN CHARTREUSE (38134)", value: "38134" },
  { label: "LA SURE EN CHARTREUSE (38340)", value: "38340" },
  { label: "ST JUST CHALEYSSIN (38540)", value: "38540" },
  { label: "ST JUST DE CLAIX (38680)", value: "38680" },
  { label: "ST LATTIER (38840)", value: "38840" },
  { label: "ST LAURENT DU PONT (38380)", value: "38380" },
  { label: "ST LAURENT EN BEAUMONT (38350)", value: "38350" },
  { label: "STE LUCE (38970)", value: "38970" },
  { label: "ST MARCEL BEL ACCUEIL (38080)", value: "38080" },
  { label: "ST MARCELLIN (38160)", value: "38160" },
  { label: "STE MARIE D ALLOIX (38660)", value: "38660" },
  { label: "STE MARIE DU MONT (38660)", value: "38660" },
  { label: "ST MARTIN DE CLELLES (38930)", value: "38930" },
  { label: "ST MARTIN DE VAULSERRE (38480)", value: "38480" },
  { label: "ST MARTIN D HERES (38400)", value: "38400" },
  { label: "ST MARTIN D URIAGE (38410)", value: "38410" },
  { label: "ST MARTIN LE VINOUX (38950)", value: "38950" },
  { label: "ST MAURICE EN TRIEVES (38930)", value: "38930" },
  { label: "ST MAURICE L EXIL (38550)", value: "38550" },
  { label: "ST MAXIMIN (38530)", value: "38530" },
  { label: "ST MICHEL DE ST GEOIRS (38590)", value: "38590" },
  { label: "ST MICHEL EN BEAUMONT (38350)", value: "38350" },
  { label: "ST MICHEL LES PORTES (38650)", value: "38650" },
  { label: "ST MURY MONTEYMOND (38190)", value: "38190" },
  { label: "ST NAZAIRE LES EYMES (38330)", value: "38330" },
  { label: "ST NICOLAS DE MACHERIN (38500)", value: "38500" },
  { label: "ST NIZIER DU MOUCHEROTTE (38250)", value: "38250" },
  { label: "ST ONDRAS (38490)", value: "38490" },
  { label: "ST PAUL DE VARCES (38760)", value: "38760" },
  { label: "ST PAUL D IZEAUX (38140)", value: "38140" },
  { label: "ST PAUL LES MONESTIER (38650)", value: "38650" },
  { label: "CRETS EN BELLEDONNE (38570)", value: "38570" },
  { label: "CRETS EN BELLEDONNE (38830)", value: "38830" },
  { label: "ST PIERRE DE BRESSIEUX (38870)", value: "38870" },
  { label: "ST PIERRE DE CHARTREUSE (38380)", value: "38380" },
  { label: "ST PIERRE DE CHERENNES (38160)", value: "38160" },
  { label: "ST PIERRE DE MEAROZ (38350)", value: "38350" },
  { label: "ST PIERRE DE MESAGE (38220)", value: "38220" },
  { label: "ST PIERRE D ENTREMONT (38380)", value: "38380" },
  { label: "ST PRIM (38370)", value: "38370" },
  { label: "ST QUENTIN FALLAVIER (38070)", value: "38070" },
  { label: "ST QUENTIN FALLAVIER (38070)", value: "38070" },
  { label: "ST QUENTIN SUR ISERE (38210)", value: "38210" },
  { label: "ST ROMAIN DE JALIONAS (38460)", value: "38460" },
  { label: "ST ROMAIN DE SURIEU (38150)", value: "38150" },
  { label: "ST ROMANS (38160)", value: "38160" },
  { label: "ST SAUVEUR (38160)", value: "38160" },
  { label: "ST SAVIN (38300)", value: "38300" },
  { label: "CHATEL EN TRIEVES (38710)", value: "38710" },
  { label: "CHATEL EN TRIEVES (38710)", value: "38710" },
  { label: "ST SIMEON DE BRESSIEUX (38870)", value: "38870" },
  { label: "ST SORLIN DE MORESTEL (38510)", value: "38510" },
  { label: "ST SORLIN DE VIENNE (38200)", value: "38200" },
  { label: "ST SULPICE DES RIVOIRES (38620)", value: "38620" },
  { label: "ST THEOFFREY (38119)", value: "38119" },
  { label: "ST VERAND (38160)", value: "38160" },
  { label: "ST VICTOR DE CESSIEU (38110)", value: "38110" },
  { label: "ST VICTOR DE MORESTEL (38510)", value: "38510" },
  { label: "ST VINCENT DE MERCUZE (38660)", value: "38660" },
  { label: "SALAGNON (38890)", value: "38890" },
  { label: "SALAISE SUR SANNE (38150)", value: "38150" },
  { label: "LA SALETTE FALLAVAUX (38970)", value: "38970" },
  { label: "LA SALLE EN BEAUMONT (38350)", value: "38350" },
  { label: "LE SAPPEY EN CHARTREUSE (38700)", value: "38700" },
  { label: "SARCENAS (38700)", value: "38700" },
  { label: "SARDIEU (38260)", value: "38260" },
  { label: "SASSENAGE (38360)", value: "38360" },
  { label: "SATOLAS ET BONCE (38290)", value: "38290" },
  { label: "SATOLAS ET BONCE (38290)", value: "38290" },
  { label: "SATOLAS ET BONCE (38290)", value: "38290" },
  { label: "SATOLAS ET BONCE (38290)", value: "38290" },
  { label: "SATOLAS ET BONCE (38290)", value: "38290" },
  { label: "SAVAS MEPIN (38440)", value: "38440" },
  { label: "SECHILIENNE (38220)", value: "38220" },
  { label: "PORTE DES BONNEVAUX (38260)", value: "38260" },
  { label: "PORTE DES BONNEVAUX (38260)", value: "38260" },
  { label: "PORTE DES BONNEVAUX (38260)", value: "38260" },
  { label: "PORTE DES BONNEVAUX (38260)", value: "38260" },
  { label: "SEPTEME (38780)", value: "38780" },
  { label: "SEPTEME (38780)", value: "38780" },
  { label: "SEPTEME (38780)", value: "38780" },
  { label: "SEREZIN DE LA TOUR (38300)", value: "38300" },
  { label: "SERMERIEU (38510)", value: "38510" },
  { label: "SERPAIZE (38200)", value: "38200" },
  { label: "SEYSSINET PARISET (38170)", value: "38170" },
  { label: "SEYSSINS (38180)", value: "38180" },
  { label: "SEYSSUEL (38200)", value: "38200" },
  { label: "SICCIEU ST JULIEN ET CARISIEU (38460)", value: "38460" },
  { label: "SIEVOZ (38350)", value: "38350" },
  { label: "SILLANS (38590)", value: "38590" },
  { label: "SINARD (38650)", value: "38650" },
  { label: "SOLEYMIEU (38460)", value: "38460" },
  { label: "LA SONE (38840)", value: "38840" },
  { label: "SONNAY (38150)", value: "38150" },
  { label: "SOUSVILLE (38350)", value: "38350" },
  { label: "SUCCIEU (38300)", value: "38300" },
  { label: "SUSVILLE (38350)", value: "38350" },
  { label: "TECHE (38470)", value: "38470" },
  { label: "TENCIN (38570)", value: "38570" },
  { label: "LA TERRASSE (38660)", value: "38660" },
  { label: "THEYS (38570)", value: "38570" },
  { label: "THODURE (38260)", value: "38260" },
  { label: "TIGNIEU JAMEYZIEU (38230)", value: "38230" },
  { label: "TIGNIEU JAMEYZIEU (38230)", value: "38230" },
  { label: "TORCHEFELON (38690)", value: "38690" },
  { label: "LA TOUR DU PIN (38110)", value: "38110" },
  { label: "LE TOUVET (38660)", value: "38660" },
  { label: "TRAMOLE (38300)", value: "38300" },
  { label: "TREFFORT (38650)", value: "38650" },
  { label: "TREMINIS (38710)", value: "38710" },
  { label: "TREPT (38460)", value: "38460" },
  { label: "LA TRONCHE (38700)", value: "38700" },
  { label: "TULLINS (38210)", value: "38210" },
  { label: "TULLINS (38210)", value: "38210" },
  { label: "VALBONNAIS (38740)", value: "38740" },
  { label: "VALENCIN (38540)", value: "38540" },
  { label: "VALENCOGNE (38730)", value: "38730" },
  { label: "LA VALETTE (38350)", value: "38350" },
  { label: "VALJOUFFREY (38740)", value: "38740" },
  { label: "VARACIEUX (38470)", value: "38470" },
  { label: "VARCES ALLIERES ET RISSET (38760)", value: "38760" },
  { label: "VASSELIN (38890)", value: "38890" },
  { label: "VATILIEU (38470)", value: "38470" },
  { label: "VAUJANY (38114)", value: "38114" },
  { label: "VAULNAVEYS LE BAS (38410)", value: "38410" },
  { label: "VAULNAVEYS LE HAUT (38410)", value: "38410" },
  { label: "VAULNAVEYS LE HAUT (38410)", value: "38410" },
  { label: "VAULX MILIEU (38090)", value: "38090" },
  { label: "VELANNE (38620)", value: "38620" },
  { label: "VENERIEU (38460)", value: "38460" },
  { label: "VENON (38610)", value: "38610" },
  { label: "VERNAS (38460)", value: "38460" },
  { label: "VERNIOZ (38150)", value: "38150" },
  { label: "LA VERPILLIERE (38290)", value: "38290" },
  { label: "LE VERSOUD (38420)", value: "38420" },
  { label: "VERTRIEU (38390)", value: "38390" },
  { label: "VEUREY VOROIZE (38113)", value: "38113" },
  { label: "VEYSSILIEU (38460)", value: "38460" },
  { label: "VEZERONCE CURTIN (38510)", value: "38510" },
  { label: "VEZERONCE CURTIN (38510)", value: "38510" },
  { label: "VIENNE (38200)", value: "38200" },
  { label: "VIF (38450)", value: "38450" },
  { label: "VIGNIEU (38890)", value: "38890" },
  { label: "VILLARD BONNOT (38190)", value: "38190" },
  { label: "VILLARD BONNOT (38190)", value: "38190" },
  { label: "VILLARD BONNOT (38190)", value: "38190" },
  { label: "VILLARD DE LANS (38250)", value: "38250" },
  { label: "VILLARD NOTRE DAME (38520)", value: "38520" },
  { label: "VILLARD RECULAS (38114)", value: "38114" },
  { label: "VILLARD REYMOND (38520)", value: "38520" },
  { label: "VILLARD ST CHRISTOPHE (38119)", value: "38119" },
  { label: "VILLEFONTAINE (38090)", value: "38090" },
  { label: "VILLEMOIRIEU (38460)", value: "38460" },
  { label: "VILLENEUVE DE MARC (38440)", value: "38440" },
  { label: "VILLE SOUS ANJOU (38150)", value: "38150" },
  { label: "VILLETTE D ANTHON (38280)", value: "38280" },
  { label: "VILLETTE D ANTHON (38280)", value: "38280" },
  { label: "VILLETTE D ANTHON (38280)", value: "38280" },
  { label: "VILLETTE DE VIENNE (38200)", value: "38200" },
  { label: "VINAY (38470)", value: "38470" },
  { label: "VAL DE VIRIEU (38730)", value: "38730" },
  { label: "VAL DE VIRIEU (38730)", value: "38730" },
  { label: "VIRIVILLE (38980)", value: "38980" },
  { label: "VIZILLE (38220)", value: "38220" },
  { label: "VOIRON (38500)", value: "38500" },
  { label: "VOISSANT (38620)", value: "38620" },
  { label: "VOREPPE (38340)", value: "38340" },
  { label: "VOUREY (38210)", value: "38210" },
  { label: "CHAMROUSSE (38410)", value: "38410" },
  { label: "ABERGEMENT LA RONCE (39500)", value: "39500" },
  { label: "ABERGEMENT LE GRAND (39600)", value: "39600" },
  { label: "ABERGEMENT LE PETIT (39800)", value: "39800" },
  { label: "ABERGEMENT LES THESY (39110)", value: "39110" },
  { label: "AIGLEPIERRE (39110)", value: "39110" },
  { label: "ALIEZE (39270)", value: "39270" },
  { label: "AMANGE (39700)", value: "39700" },
  { label: "ANDELOT EN MONTAGNE (39110)", value: "39110" },
  { label: "ANDELOT MORVAL (39320)", value: "39320" },
  { label: "ANDELOT MORVAL (39320)", value: "39320" },
  { label: "ANNOIRE (39120)", value: "39120" },
  { label: "ARBOIS (39600)", value: "39600" },
  { label: "ARCHELANGE (39290)", value: "39290" },
  { label: "ARDON (39300)", value: "39300" },
  { label: "ARINTHOD (39240)", value: "39240" },
  { label: "ARINTHOD (39240)", value: "39240" },
  { label: "ARLAY (39140)", value: "39140" },
  { label: "ARLAY (39210)", value: "39210" },
  { label: "AROMAS (39240)", value: "39240" },
  { label: "AROMAS (39240)", value: "39240" },
  { label: "AROMAS (39240)", value: "39240" },
  { label: "LES ARSURES (39600)", value: "39600" },
  { label: "ARSURE ARSURETTE (39250)", value: "39250" },
  { label: "LA CHAILLEUSE (39270)", value: "39270" },
  { label: "LA CHAILLEUSE (39270)", value: "39270" },
  { label: "LA CHAILLEUSE (39270)", value: "39270" },
  { label: "LA CHAILLEUSE (39570)", value: "39570" },
  { label: "ASNANS BEAUVOISIN (39120)", value: "39120" },
  { label: "ASNANS BEAUVOISIN (39120)", value: "39120" },
  { label: "AUDELANGE (39700)", value: "39700" },
  { label: "AUGEA (39190)", value: "39190" },
  { label: "AUGERANS (39380)", value: "39380" },
  { label: "AUGISEY (39270)", value: "39270" },
  { label: "AUMONT (39800)", value: "39800" },
  { label: "AUMUR (39410)", value: "39410" },
  { label: "AUTHUME (39100)", value: "39100" },
  { label: "AUXANGE (39700)", value: "39700" },
  { label: "AVIGNON LES ST CLAUDE (39200)", value: "39200" },
  { label: "BALAISEAUX (39120)", value: "39120" },
  { label: "BALANOD (39160)", value: "39160" },
  { label: "BANS (39380)", value: "39380" },
  { label: "BARESIA SUR L AIN (39130)", value: "39130" },
  { label: "LA BARRE (39700)", value: "39700" },
  { label: "BARRETAINE (39800)", value: "39800" },
  { label: "BAUME LES MESSIEURS (39210)", value: "39210" },
  { label: "BAUME LES MESSIEURS (39570)", value: "39570" },
  { label: "BAVERANS (39100)", value: "39100" },
  { label: "BEAUFORT ORBAGNA (39190)", value: "39190" },
  { label: "BEAUFORT ORBAGNA (39190)", value: "39190" },
  { label: "BEFFIA (39270)", value: "39270" },
  { label: "BELLECOMBE (39310)", value: "39310" },
  { label: "BELLEFONTAINE (39400)", value: "39400" },
  { label: "BELMONT (39380)", value: "39380" },
  { label: "BERSAILLIN (39800)", value: "39800" },
  { label: "BERSAILLIN (39800)", value: "39800" },
  { label: "BERSAILLIN (39800)", value: "39800" },
  { label: "BESAIN (39800)", value: "39800" },
  { label: "BIARNE (39290)", value: "39290" },
  { label: "BIEF DES MAISONS (39150)", value: "39150" },
  { label: "BIEF DU FOURG (39250)", value: "39250" },
  { label: "BIEFMORIN (39800)", value: "39800" },
  { label: "BILLECUL (39250)", value: "39250" },
  { label: "BLETTERANS (39140)", value: "39140" },
  { label: "BLOIS SUR SEILLE (39210)", value: "39210" },
  { label: "BLYE (39130)", value: "39130" },
  { label: "BOIS D AMONT (39220)", value: "39220" },
  { label: "BOIS DE GAND (39230)", value: "39230" },
  { label: "BOISSIA (39130)", value: "39130" },
  { label: "LA BOISSIERE (39240)", value: "39240" },
  { label: "BONLIEU (39130)", value: "39130" },
  { label: "BONNEFONTAINE (39800)", value: "39800" },
  { label: "BORNAY (39570)", value: "39570" },
  { label: "LES BOUCHOUX (39370)", value: "39370" },
  { label: "BOURG DE SIROD (39300)", value: "39300" },
  { label: "BRACON (39110)", value: "39110" },
  { label: "BRAINANS (39800)", value: "39800" },
  { label: "BRANS (39290)", value: "39290" },
  { label: "LA BRETENIERE (39700)", value: "39700" },
  { label: "BRETENIERES (39120)", value: "39120" },
  { label: "BREVANS (39100)", value: "39100" },
  { label: "BRIOD (39570)", value: "39570" },
  { label: "BROISSIA (39320)", value: "39320" },
  { label: "BUVILLY (39800)", value: "39800" },
  { label: "CENSEAU (39250)", value: "39250" },
  { label: "CERNANS (39110)", value: "39110" },
  { label: "CERNIEBAUD (39250)", value: "39250" },
  { label: "CERNON (39240)", value: "39240" },
  { label: "CERNON (39240)", value: "39240" },
  { label: "CESANCEY (39570)", value: "39570" },
  { label: "CHAINEE DES COUPIS (39120)", value: "39120" },
  { label: "LES CHALESMES (39150)", value: "39150" },
  { label: "CHAMBERIA (39270)", value: "39270" },
  { label: "CHAMBLAY (39380)", value: "39380" },
  { label: "CHAMOLE (39800)", value: "39800" },
  { label: "CHAMPAGNE SUR LOUE (39600)", value: "39600" },
  { label: "CHAMPAGNEY (39290)", value: "39290" },
  { label: "CHAMPAGNOLE (39300)", value: "39300" },
  { label: "CHAMPDIVERS (39500)", value: "39500" },
  { label: "CHAMPROUGIER (39230)", value: "39230" },
  { label: "CHAMPVANS (39100)", value: "39100" },
  { label: "CHANCIA (01590)", value: "01590" },
  { label: "LA CHAPELLE SUR FURIEUSE (39110)", value: "39110" },
  { label: "CHAPELLE VOLAND (39140)", value: "39140" },
  { label: "CHAPOIS (39300)", value: "39300" },
  { label: "CHARCHILLA (39260)", value: "39260" },
  { label: "CHARCIER (39130)", value: "39130" },
  { label: "CHARENCY (39250)", value: "39250" },
  { label: "CHAREZIER (39130)", value: "39130" },
  { label: "LA CHARME (39230)", value: "39230" },
  { label: "CHARNOD (39240)", value: "39240" },
  { label: "LA CHASSAGNE (39230)", value: "39230" },
  { label: "CHATEAU CHALON (39210)", value: "39210" },
  { label: "LA CHATELAINE (39600)", value: "39600" },
  { label: "CHATELAY (39380)", value: "39380" },
  { label: "CHATEL DE JOUX (39130)", value: "39130" },
  { label: "LE CHATELEY (39230)", value: "39230" },
  { label: "CHATELNEUF (39300)", value: "39300" },
  { label: "CHATENOIS (39700)", value: "39700" },
  { label: "CHATILLON (39130)", value: "39130" },
  { label: "CHAUMERGY (39230)", value: "39230" },
  { label: "LA CHAUMUSSE (39150)", value: "39150" },
  { label: "CHAUSSENANS (39800)", value: "39800" },
  { label: "CHAUSSIN (39120)", value: "39120" },
  { label: "CHAUX DES CROTENAY (39150)", value: "39150" },
  { label: "NANCHEZ (39150)", value: "39150" },
  { label: "NANCHEZ (39150)", value: "39150" },
  { label: "NANCHEZ (39150)", value: "39150" },
  { label: "NANCHEZ (39150)", value: "39150" },
  { label: "NANCHEZ (39200)", value: "39200" },
  { label: "LA CHAUX DU DOMBIEF (39150)", value: "39150" },
  { label: "LA CHAUX EN BRESSE (39230)", value: "39230" },
  { label: "CHAUX CHAMPAGNY (39110)", value: "39110" },
  { label: "CHAUX CHAMPAGNY (39110)", value: "39110" },
  { label: "CHAVERIA (39270)", value: "39270" },
  { label: "CHEMENOT (39230)", value: "39230" },
  { label: "ST HYMETIERE SUR VALOUSE (39240)", value: "39240" },
  { label: "ST HYMETIERE SUR VALOUSE (39240)", value: "39240" },
  { label: "ST HYMETIERE SUR VALOUSE (39240)", value: "39240" },
  { label: "ST HYMETIERE SUR VALOUSE (39240)", value: "39240" },
  { label: "CHEMIN (39120)", value: "39120" },
  { label: "CHENE BERNARD (39120)", value: "39120" },
  { label: "CHENE SEC (39230)", value: "39230" },
  { label: "CHEVIGNY (39290)", value: "39290" },
  { label: "CHEVREAUX (39190)", value: "39190" },
  { label: "CHEVROTAINE (39130)", value: "39130" },
  { label: "CHILLE (39570)", value: "39570" },
  { label: "CHILLY LE VIGNOBLE (39570)", value: "39570" },
  { label: "CHILLY SUR SALINS (39110)", value: "39110" },
  { label: "CHISSEY SUR LOUE (39380)", value: "39380" },
  { label: "CHOISEY (39100)", value: "39100" },
  { label: "CHOUX (39370)", value: "39370" },
  { label: "CIZE (39300)", value: "39300" },
  { label: "CLAIRVAUX LES LACS (39130)", value: "39130" },
  { label: "CLUCY (39110)", value: "39110" },
  { label: "COGNA (39130)", value: "39130" },
  { label: "COISERETTE (39200)", value: "39200" },
  { label: "COLONNE (39800)", value: "39800" },
  { label: "COMMENAILLES (39140)", value: "39140" },
  { label: "CONDAMINE (39570)", value: "39570" },
  { label: "CONDES (39240)", value: "39240" },
  { label: "CONLIEGE (39570)", value: "39570" },
  { label: "CONTE (39300)", value: "39300" },
  { label: "CORNOD (39240)", value: "39240" },
  { label: "COSGES (39140)", value: "39140" },
  { label: "COURBETTE (39570)", value: "39570" },
  { label: "COURBOUZON (39570)", value: "39570" },
  { label: "COURLANS (39570)", value: "39570" },
  { label: "COURLANS (39570)", value: "39570" },
  { label: "COURLAOUX (39570)", value: "39570" },
  { label: "COURTEFONTAINE (39700)", value: "39700" },
  { label: "COUSANCE (39190)", value: "39190" },
  { label: "COYRIERE (39200)", value: "39200" },
  { label: "COYRON (39260)", value: "39260" },
  { label: "CRAMANS (39600)", value: "39600" },
  { label: "HAUTEROCHE (39210)", value: "39210" },
  { label: "HAUTEROCHE (39570)", value: "39570" },
  { label: "HAUTEROCHE (39570)", value: "39570" },
  { label: "CRANS (39300)", value: "39300" },
  { label: "CRENANS (39260)", value: "39260" },
  { label: "CRESSIA (39270)", value: "39270" },
  { label: "CRISSEY (39100)", value: "39100" },
  { label: "CROTENAY (39300)", value: "39300" },
  { label: "LES CROZETS (39260)", value: "39260" },
  { label: "CUISIA (39190)", value: "39190" },
  { label: "CUVIER (39250)", value: "39250" },
  { label: "DAMMARTIN MARPAIN (39290)", value: "39290" },
  { label: "DAMMARTIN MARPAIN (39290)", value: "39290" },
  { label: "DAMPARIS (39500)", value: "39500" },
  { label: "DAMPIERRE (39350)", value: "39350" },
  { label: "DAMPIERRE (39700)", value: "39700" },
  { label: "DAMPIERRE (39700)", value: "39700" },
  { label: "DARBONNAY (39230)", value: "39230" },
  { label: "DENEZIERES (39130)", value: "39130" },
  { label: "LE DESCHAUX (39120)", value: "39120" },
  { label: "DESNES (39140)", value: "39140" },
  { label: "LES DEUX FAYS (39230)", value: "39230" },
  { label: "DIGNA (39190)", value: "39190" },
  { label: "DOLE (39100)", value: "39100" },
  { label: "DOLE (39100)", value: "39100" },
  { label: "DOLE (39100)", value: "39100" },
  { label: "DOMBLANS (39210)", value: "39210" },
  { label: "DOMBLANS (39230)", value: "39230" },
  { label: "DOMPIERRE SUR MONT (39270)", value: "39270" },
  { label: "DOUCIER (39130)", value: "39130" },
  { label: "DOURNON (39110)", value: "39110" },
  { label: "DOYE (39250)", value: "39250" },
  { label: "DRAMELAY (39240)", value: "39240" },
  { label: "ECLANS NENON (39700)", value: "39700" },
  { label: "ECLANS NENON (39700)", value: "39700" },
  { label: "ECLEUX (39600)", value: "39600" },
  { label: "ECRILLE (39270)", value: "39270" },
  { label: "ENTRE DEUX MONTS (39150)", value: "39150" },
  { label: "VAL D EPY (39160)", value: "39160" },
  { label: "VAL D EPY (39160)", value: "39160" },
  { label: "VAL D EPY (39160)", value: "39160" },
  { label: "VAL D EPY (39160)", value: "39160" },
  { label: "VAL D EPY (39160)", value: "39160" },
  { label: "VAL D EPY (39320)", value: "39320" },
  { label: "VAL D EPY (39320)", value: "39320" },
  { label: "EQUEVILLON (39300)", value: "39300" },
  { label: "LES ESSARDS TAIGNEVAUX (39120)", value: "39120" },
  { label: "ESSERVAL TARTRE (39250)", value: "39250" },
  { label: "ETIVAL (39130)", value: "39130" },
  { label: "ETIVAL (39130)", value: "39130" },
  { label: "L'ETOILE (39570)", value: "39570" },
  { label: "ETREPIGNEY (39700)", value: "39700" },
  { label: "EVANS (39700)", value: "39700" },
  { label: "FALLETANS (39700)", value: "39700" },
  { label: "LA FAVIERE (39250)", value: "39250" },
  { label: "FAY EN MONTAGNE (39800)", value: "39800" },
  { label: "LA FERTE (39600)", value: "39600" },
  { label: "LE FIED (39800)", value: "39800" },
  { label: "FONCINE LE BAS (39520)", value: "39520" },
  { label: "FONCINE LE HAUT (39460)", value: "39460" },
  { label: "FONTAINEBRUX (39140)", value: "39140" },
  { label: "FONTENU (39130)", value: "39130" },
  { label: "FORT DU PLASNE (39150)", value: "39150" },
  { label: "FOUCHERANS (39100)", value: "39100" },
  { label: "FOULENAY (39230)", value: "39230" },
  { label: "FRAISANS (39700)", value: "39700" },
  { label: "FRANCHEVILLE (39230)", value: "39230" },
  { label: "FRAROZ (39250)", value: "39250" },
  { label: "FRASNE LES MEULIERES (39290)", value: "39290" },
  { label: "LA FRASNEE (39130)", value: "39130" },
  { label: "LE FRASNOIS (39130)", value: "39130" },
  { label: "FREBUANS (39570)", value: "39570" },
  { label: "FRONTENAY (39210)", value: "39210" },
  { label: "GATEY (39120)", value: "39120" },
  { label: "GENDREY (39350)", value: "39350" },
  { label: "GENOD (39240)", value: "39240" },
  { label: "GERAISE (39110)", value: "39110" },
  { label: "GERMIGNEY (39380)", value: "39380" },
  { label: "GERUGE (39570)", value: "39570" },
  { label: "GEVINGEY (39570)", value: "39570" },
  { label: "GEVRY (39100)", value: "39100" },
  { label: "GIGNY (39320)", value: "39320" },
  { label: "GILLOIS (39250)", value: "39250" },
  { label: "GIZIA (39190)", value: "39190" },
  { label: "GRANDE RIVIERE CHATEAU (39150)", value: "39150" },
  { label: "GRANDE RIVIERE CHATEAU (39150)", value: "39150" },
  { label: "GRANDE RIVIERE CHATEAU (39150)", value: "39150" },
  { label: "GRANGE DE VAIVRE (39600)", value: "39600" },
  { label: "GRAYE ET CHARNAY (39320)", value: "39320" },
  { label: "GREDISANS (39290)", value: "39290" },
  { label: "GROZON (39800)", value: "39800" },
  { label: "HAUTECOUR (39130)", value: "39130" },
  { label: "LES HAYS (39120)", value: "39120" },
  { label: "IVORY (39110)", value: "39110" },
  { label: "IVREY (39110)", value: "39110" },
  { label: "JEURRE (39360)", value: "39360" },
  { label: "JOUHE (39100)", value: "39100" },
  { label: "LAC DES ROUGES TRUITES (39150)", value: "39150" },
  { label: "LADOYE SUR SEILLE (39210)", value: "39210" },
  { label: "MONTLAINSIA (39320)", value: "39320" },
  { label: "MONTLAINSIA (39320)", value: "39320" },
  { label: "MONTLAINSIA (39320)", value: "39320" },
  { label: "LAJOUX (01410)", value: "01410" },
  { label: "LAJOUX (39310)", value: "39310" },
  { label: "LAMOURA (39310)", value: "39310" },
  { label: "LE LARDERET (39300)", value: "39300" },
  { label: "LARGILLAY MARSONNAY (39130)", value: "39130" },
  { label: "LARNAUD (39140)", value: "39140" },
  { label: "LARRIVOIRE (39360)", value: "39360" },
  { label: "LE LATET (39300)", value: "39300" },
  { label: "LA LATETTE (39250)", value: "39250" },
  { label: "LAVANCIA EPERCY (01590)", value: "01590" },
  { label: "LAVANGEOT (39700)", value: "39700" },
  { label: "LAVANS LES DOLE (39700)", value: "39700" },
  { label: "LAVANS LES ST CLAUDE (39170)", value: "39170" },
  { label: "LAVANS LES ST CLAUDE (39170)", value: "39170" },
  { label: "LAVANS LES ST CLAUDE (39170)", value: "39170" },
  { label: "LAVANS LES ST CLAUDE (39170)", value: "39170" },
  { label: "LAVIGNY (39210)", value: "39210" },
  { label: "LAVIGNY (39210)", value: "39210" },
  { label: "LECT (39260)", value: "39260" },
  { label: "LECT (39260)", value: "39260" },
  { label: "VALZIN EN PETITE MONTAGNE (39240)", value: "39240" },
  { label: "VALZIN EN PETITE MONTAGNE (39240)", value: "39240" },
  { label: "VALZIN EN PETITE MONTAGNE (39240)", value: "39240" },
  { label: "VALZIN EN PETITE MONTAGNE (39240)", value: "39240" },
  { label: "LEMUY (39110)", value: "39110" },
  { label: "LENT (39300)", value: "39300" },
  { label: "LESCHERES (39170)", value: "39170" },
  { label: "LOISIA (39320)", value: "39320" },
  { label: "LOMBARD (39230)", value: "39230" },
  { label: "LONGCHAUMOIS (39400)", value: "39400" },
  { label: "LONGCOCHON (39250)", value: "39250" },
  { label: "LONGWY SUR LE DOUBS (39120)", value: "39120" },
  { label: "LONS LE SAUNIER (39000)", value: "39000" },
  { label: "LOULLE (39300)", value: "39300" },
  { label: "LOUVATANGE (39350)", value: "39350" },
  { label: "LE LOUVEROT (39210)", value: "39210" },
  { label: "LA LOYE (39380)", value: "39380" },
  { label: "MACORNAY (39570)", value: "39570" },
  { label: "MAISOD (39260)", value: "39260" },
  { label: "MALANGE (39700)", value: "39700" },
  { label: "MANTRY (39230)", value: "39230" },
  { label: "MARIGNA SUR VALOUSE (39240)", value: "39240" },
  { label: "MARIGNY (39130)", value: "39130" },
  { label: "MARNEZIA (39270)", value: "39270" },
  { label: "MARNOZ (39110)", value: "39110" },
  { label: "LA MARRE (39210)", value: "39210" },
  { label: "MARTIGNA (39260)", value: "39260" },
  { label: "MATHENAY (39600)", value: "39600" },
  { label: "MAYNAL (39190)", value: "39190" },
  { label: "MENETRU LE VIGNOBLE (39210)", value: "39210" },
  { label: "MENETRUX EN JOUX (39130)", value: "39130" },
  { label: "MENOTEY (39290)", value: "39290" },
  { label: "MERONA (39270)", value: "39270" },
  { label: "MESNAY (39600)", value: "39600" },
  { label: "MESNOIS (39130)", value: "39130" },
  { label: "MESSIA SUR SORNE (39570)", value: "39570" },
  { label: "MEUSSIA (39260)", value: "39260" },
  { label: "MIEGES (39250)", value: "39250" },
  { label: "MIEGES (39250)", value: "39250" },
  { label: "MIEGES (39250)", value: "39250" },
  { label: "MIERY (39800)", value: "39800" },
  { label: "MIGNOVILLARD (39250)", value: "39250" },
  { label: "MIGNOVILLARD (39250)", value: "39250" },
  { label: "MIGNOVILLARD (39250)", value: "39250" },
  { label: "MIGNOVILLARD (39250)", value: "39250" },
  { label: "MOIRANS EN MONTAGNE (39260)", value: "39260" },
  { label: "MOIRON (39570)", value: "39570" },
  { label: "MOISSEY (39290)", value: "39290" },
  { label: "MOLAIN (39800)", value: "39800" },
  { label: "MOLAMBOZ (39600)", value: "39600" },
  { label: "MOLAY (39500)", value: "39500" },
  { label: "CHASSAL MOLINGES (39360)", value: "39360" },
  { label: "CHASSAL MOLINGES (39360)", value: "39360" },
  { label: "MONAY (39230)", value: "39230" },
  { label: "MONNETAY (39320)", value: "39320" },
  { label: "MONNET LA VILLE (39300)", value: "39300" },
  { label: "MONNIERES (39100)", value: "39100" },
  { label: "MONTAGNA LE RECONDUIT (39160)", value: "39160" },
  { label: "MONTAIGU (39570)", value: "39570" },
  { label: "MONTAIN (39210)", value: "39210" },
  { label: "MONTBARREY (39380)", value: "39380" },
  { label: "MONTCUSEL (39260)", value: "39260" },
  { label: "MONTEPLAIN (39700)", value: "39700" },
  { label: "MONTFLEUR (39320)", value: "39320" },
  { label: "MONTHOLIER (39800)", value: "39800" },
  { label: "MONTIGNY LES ARSURES (39600)", value: "39600" },
  { label: "MONTIGNY SUR L AIN (39300)", value: "39300" },
  { label: "MONTMARLON (39110)", value: "39110" },
  { label: "MONTMIREY LA VILLE (39290)", value: "39290" },
  { label: "MONTMIREY LE CHATEAU (39290)", value: "39290" },
  { label: "MONTMOROT (39570)", value: "39570" },
  { label: "MONTMOROT (39570)", value: "39570" },
  { label: "MONTREVEL (39320)", value: "39320" },
  { label: "MONTROND (39300)", value: "39300" },
  { label: "MONT SOUS VAUDREY (39380)", value: "39380" },
  { label: "MONT SUR MONNET (39300)", value: "39300" },
  { label: "MORBIER (39400)", value: "39400" },
  { label: "MORBIER (39400)", value: "39400" },
  { label: "HAUTS DE BIENNE (39400)", value: "39400" },
  { label: "HAUTS DE BIENNE (39400)", value: "39400" },
  { label: "HAUTS DE BIENNE (39400)", value: "39400" },
  { label: "MOUCHARD (39330)", value: "39330" },
  { label: "MOUCHARD (39330)", value: "39330" },
  { label: "MOURNANS CHARBONNY (39250)", value: "39250" },
  { label: "LES MOUSSIERES (39310)", value: "39310" },
  { label: "MOUTONNE (39270)", value: "39270" },
  { label: "MOUTOUX (39300)", value: "39300" },
  { label: "MUTIGNEY (39290)", value: "39290" },
  { label: "LES TROIS CHATEAUX (39160)", value: "39160" },
  { label: "LES TROIS CHATEAUX (39160)", value: "39160" },
  { label: "LES TROIS CHATEAUX (39160)", value: "39160" },
  { label: "LES TROIS CHATEAUX (39160)", value: "39160" },
  { label: "NANCE (39140)", value: "39140" },
  { label: "NANCUISE (39270)", value: "39270" },
  { label: "LES NANS (39300)", value: "39300" },
  { label: "NEUBLANS ABERGEMENT (39120)", value: "39120" },
  { label: "NEUBLANS ABERGEMENT (39120)", value: "39120" },
  { label: "NEUVILLEY (39800)", value: "39800" },
  { label: "NEVY LES DOLE (39380)", value: "39380" },
  { label: "NEVY SUR SEILLE (39210)", value: "39210" },
  { label: "NEY (39300)", value: "39300" },
  { label: "NOGNA (39570)", value: "39570" },
  { label: "NOZEROY (39250)", value: "39250" },
  { label: "OFFLANGES (39290)", value: "39290" },
  { label: "ONGLIERES (39250)", value: "39250" },
  { label: "ONOZ (39270)", value: "39270" },
  { label: "ORCHAMPS (39700)", value: "39700" },
  { label: "ORGELET (39270)", value: "39270" },
  { label: "ORGELET (39270)", value: "39270" },
  { label: "OUGNEY (39350)", value: "39350" },
  { label: "OUNANS (39380)", value: "39380" },
  { label: "OUR (39700)", value: "39700" },
  { label: "OUSSIERES (39800)", value: "39800" },
  { label: "PAGNEY (39350)", value: "39350" },
  { label: "PAGNOZ (39330)", value: "39330" },
  { label: "PANNESSIERES (39570)", value: "39570" },
  { label: "PARCEY (39100)", value: "39100" },
  { label: "LE PASQUIER (39300)", value: "39300" },
  { label: "PASSENANS (39230)", value: "39230" },
  { label: "PATORNAY (39130)", value: "39130" },
  { label: "PEINTRE (39290)", value: "39290" },
  { label: "PERRIGNY (39570)", value: "39570" },
  { label: "PESEUX (39120)", value: "39120" },
  { label: "LA PESSE (39370)", value: "39370" },
  { label: "PETIT NOIR (39120)", value: "39120" },
  { label: "PICARREAU (39800)", value: "39800" },
  { label: "PILLEMOINE (39300)", value: "39300" },
  { label: "PIMORIN (39270)", value: "39270" },
  { label: "LE PIN (39210)", value: "39210" },
  { label: "PLAINOISEAU (39210)", value: "39210" },
  { label: "PLAISIA (39270)", value: "39270" },
  { label: "LES PLANCHES EN MONTAGNE (39150)", value: "39150" },
  { label: "LES PLANCHES EN MONTAGNE (39150)", value: "39150" },
  { label: "LES PLANCHES PRES ARBOIS (39600)", value: "39600" },
  { label: "PLASNE (39210)", value: "39210" },
  { label: "PLASNE (39800)", value: "39800" },
  { label: "PLENISE (39250)", value: "39250" },
  { label: "PLENISETTE (39250)", value: "39250" },
  { label: "PLEURE (39120)", value: "39120" },
  { label: "PLUMONT (39700)", value: "39700" },
  { label: "POIDS DE FIOLE (39570)", value: "39570" },
  { label: "POINTRE (39290)", value: "39290" },
  { label: "POLIGNY (39800)", value: "39800" },
  { label: "PONT DE POITTE (39130)", value: "39130" },
  { label: "PONT D HERY (39110)", value: "39110" },
  { label: "PONT D HERY (39110)", value: "39110" },
  { label: "PONT D HERY (39110)", value: "39110" },
  { label: "PONT DU NAVOY (39300)", value: "39300" },
  { label: "PORT LESNEY (39330)", value: "39330" },
  { label: "PORT LESNEY (39600)", value: "39600" },
  { label: "PREMANON (39220)", value: "39220" },
  { label: "PREMANON (39400)", value: "39400" },
  { label: "PREMANON (39400)", value: "39400" },
  { label: "PRESILLY (39270)", value: "39270" },
  { label: "PRETIN (39110)", value: "39110" },
  { label: "PUBLY (39570)", value: "39570" },
  { label: "PUPILLIN (39600)", value: "39600" },
  { label: "QUINTIGNY (39570)", value: "39570" },
  { label: "RAHON (39120)", value: "39120" },
  { label: "RAINANS (39290)", value: "39290" },
  { label: "RANCHOT (39700)", value: "39700" },
  { label: "RANS (39700)", value: "39700" },
  { label: "RAVILLOLES (39170)", value: "39170" },
  { label: "RECANOZ (39230)", value: "39230" },
  { label: "REITHOUSE (39270)", value: "39270" },
  { label: "RELANS (39140)", value: "39140" },
  { label: "LES REPOTS (39140)", value: "39140" },
  { label: "REVIGNY (39570)", value: "39570" },
  { label: "LA RIXOUSE (39200)", value: "39200" },
  { label: "RIX (39250)", value: "39250" },
  { label: "ROCHEFORT SUR NENON (39700)", value: "39700" },
  { label: "ROGNA (39360)", value: "39360" },
  { label: "ROMAIN (39350)", value: "39350" },
  { label: "ROMANGE (39700)", value: "39700" },
  { label: "ROSAY (39190)", value: "39190" },
  { label: "ROTALIER (39190)", value: "39190" },
  { label: "ROTHONAY (39270)", value: "39270" },
  { label: "ROUFFANGE (39350)", value: "39350" },
  { label: "LES ROUSSES (39220)", value: "39220" },
  { label: "LES ROUSSES (39220)", value: "39220" },
  { label: "LES ROUSSES (39400)", value: "39400" },
  { label: "LES ROUSSES (39400)", value: "39400" },
  { label: "LES ROUSSES (39400)", value: "39400" },
  { label: "RUFFEY SUR SEILLE (39140)", value: "39140" },
  { label: "RYE (39230)", value: "39230" },
  { label: "SAFFLOZ (39130)", value: "39130" },
  { label: "STE AGNES (39190)", value: "39190" },
  { label: "ST AMOUR (39160)", value: "39160" },
  { label: "ST AUBIN (39410)", value: "39410" },
  { label: "ST BARAING (39120)", value: "39120" },
  { label: "ST CLAUDE (39200)", value: "39200" },
  { label: "ST CLAUDE (39200)", value: "39200" },
  { label: "ST CLAUDE (39200)", value: "39200" },
  { label: "ST CLAUDE (39200)", value: "39200" },
  { label: "ST CLAUDE (39200)", value: "39200" },
  { label: "ST CLAUDE (39200)", value: "39200" },
  { label: "ST CYR MONTMALIN (39600)", value: "39600" },
  { label: "ST CYR MONTMALIN (39600)", value: "39600" },
  { label: "ST DIDIER (39570)", value: "39570" },
  { label: "ST GERMAIN EN MONTAGNE (39300)", value: "39300" },
  { label: "VAL SURAN (39320)", value: "39320" },
  { label: "VAL SURAN (39320)", value: "39320" },
  { label: "VAL SURAN (39320)", value: "39320" },
  { label: "VAL SURAN (39320)", value: "39320" },
  { label: "ST LAMAIN (39230)", value: "39230" },
  { label: "ST LAURENT EN GRANDVAUX (39150)", value: "39150" },
  { label: "ST LOTHAIN (39230)", value: "39230" },
  { label: "ST LOUP (39120)", value: "39120" },
  { label: "COTEAUX DU LIZON (39170)", value: "39170" },
  { label: "COTEAUX DU LIZON (39170)", value: "39170" },
  { label: "ST MAUR (39570)", value: "39570" },
  { label: "ST MAURICE CRILLAT (39130)", value: "39130" },
  { label: "ST MAURICE CRILLAT (39130)", value: "39130" },
  { label: "ST PIERRE (39150)", value: "39150" },
  { label: "ST THIEBAUD (39110)", value: "39110" },
  { label: "SAIZENAY (39110)", value: "39110" },
  { label: "SALANS (39700)", value: "39700" },
  { label: "SALIGNEY (39350)", value: "39350" },
  { label: "SALINS LES BAINS (39110)", value: "39110" },
  { label: "SAMPANS (39100)", value: "39100" },
  { label: "SANTANS (39380)", value: "39380" },
  { label: "SAPOIS (39300)", value: "39300" },
  { label: "SARROGNA (39270)", value: "39270" },
  { label: "SARROGNA (39270)", value: "39270" },
  { label: "SARROGNA (39270)", value: "39270" },
  { label: "SARROGNA (39270)", value: "39270" },
  { label: "SAUGEOT (39130)", value: "39130" },
  { label: "SELIGNEY (39120)", value: "39120" },
  { label: "SELLIERES (39230)", value: "39230" },
  { label: "SEPTMONCEL LES MOLUNES (39310)", value: "39310" },
  { label: "SEPTMONCEL LES MOLUNES (39310)", value: "39310" },
  { label: "SERGENAUX (39230)", value: "39230" },
  { label: "SERGENON (39120)", value: "39120" },
  { label: "SERMANGE (39700)", value: "39700" },
  { label: "SERRE LES MOULIERES (39700)", value: "39700" },
  { label: "SIROD (39300)", value: "39300" },
  { label: "SIROD (39300)", value: "39300" },
  { label: "SONGESON (39130)", value: "39130" },
  { label: "SOUCIA (39130)", value: "39130" },
  { label: "SOUVANS (39380)", value: "39380" },
  { label: "SUPT (39300)", value: "39300" },
  { label: "SYAM (39300)", value: "39300" },
  { label: "TASSENIERES (39120)", value: "39120" },
  { label: "TAVAUX (39500)", value: "39500" },
  { label: "TAXENNE (39350)", value: "39350" },
  { label: "THERVAY (39290)", value: "39290" },
  { label: "THESY (39110)", value: "39110" },
  { label: "THOIRETTE COISIA (39240)", value: "39240" },
  { label: "THOIRETTE COISIA (39240)", value: "39240" },
  { label: "THOIRIA (39130)", value: "39130" },
  { label: "THOISSIA (39160)", value: "39160" },
  { label: "TOULOUSE LE CHATEAU (39230)", value: "39230" },
  { label: "LA TOUR DU MEIX (39270)", value: "39270" },
  { label: "TOURMONT (39800)", value: "39800" },
  { label: "TRENAL (39190)", value: "39190" },
  { label: "TRENAL (39570)", value: "39570" },
  { label: "UXELLES (39130)", value: "39130" },
  { label: "VADANS (39600)", value: "39600" },
  { label: "VALEMPOULIERES (39300)", value: "39300" },
  { label: "VANNOZ (39300)", value: "39300" },
  { label: "LE VAUDIOUX (39300)", value: "39300" },
  { label: "VAUDREY (39380)", value: "39380" },
  { label: "VAUX LES ST CLAUDE (39360)", value: "39360" },
  { label: "VAUX SUR POLIGNY (39800)", value: "39800" },
  { label: "VERGES (39570)", value: "39570" },
  { label: "VERIA (39160)", value: "39160" },
  { label: "VERNANTOIS (39570)", value: "39570" },
  { label: "LE VERNOIS (39210)", value: "39210" },
  { label: "VERS EN MONTAGNE (39300)", value: "39300" },
  { label: "VERS SOUS SELLIERES (39230)", value: "39230" },
  { label: "VERTAMBOZ (39130)", value: "39130" },
  { label: "VESCLES (39240)", value: "39240" },
  { label: "VEVY (39570)", value: "39570" },
  { label: "LA VIEILLE LOYE (39380)", value: "39380" },
  { label: "VILLARD ST SAUVEUR (39200)", value: "39200" },
  { label: "VILLARDS D HERIA (39260)", value: "39260" },
  { label: "VILLENEUVE D AVAL (39600)", value: "39600" },
  { label: "VILLENEUVE SOUS PYMONT (39570)", value: "39570" },
  { label: "VILLERSERINE (39800)", value: "39800" },
  { label: "VILLERS FARLAY (39600)", value: "39600" },
  { label: "VILLERS LES BOIS (39120)", value: "39120" },
  { label: "VILLERS LES BOIS (39800)", value: "39800" },
  { label: "VILLERS ROBERT (39120)", value: "39120" },
  { label: "VILLETTE LES ARBOIS (39600)", value: "39600" },
  { label: "VILLETTE LES DOLE (39100)", value: "39100" },
  { label: "VILLEVIEUX (39140)", value: "39140" },
  { label: "LE VILLEY (39230)", value: "39230" },
  { label: "VAL SONNETTE (39190)", value: "39190" },
  { label: "VAL SONNETTE (39190)", value: "39190" },
  { label: "VAL SONNETTE (39190)", value: "39190" },
  { label: "VAL SONNETTE (39190)", value: "39190" },
  { label: "VINCENT FROIDEVILLE (39230)", value: "39230" },
  { label: "VINCENT FROIDEVILLE (39230)", value: "39230" },
  { label: "VIRY (39360)", value: "39360" },
  { label: "VITREUX (39350)", value: "39350" },
  { label: "VOITEUR (39210)", value: "39210" },
  { label: "VOSBLES VALFIN (39240)", value: "39240" },
  { label: "VOSBLES VALFIN (39240)", value: "39240" },
  { label: "VRIANGE (39700)", value: "39700" },
  { label: "VULVOZ (39360)", value: "39360" },
  { label: "ARESCHES (39110)", value: "39110" },
  { label: "AIRE SUR L ADOUR (40800)", value: "40800" },
  { label: "AMOU (40330)", value: "40330" },
  { label: "ANGOUME (40990)", value: "40990" },
  { label: "ANGRESSE (40150)", value: "40150" },
  { label: "ARBOUCAVE (40320)", value: "40320" },
  { label: "ARENGOSSE (40110)", value: "40110" },
  { label: "ARGELOS (40700)", value: "40700" },
  { label: "ARGELOUSE (40430)", value: "40430" },
  { label: "ARSAGUE (40330)", value: "40330" },
  { label: "ARTASSENX (40090)", value: "40090" },
  { label: "ARTHEZ D ARMAGNAC (40190)", value: "40190" },
  { label: "ARUE (40120)", value: "40120" },
  { label: "ARX (40310)", value: "40310" },
  { label: "AUBAGNAN (40700)", value: "40700" },
  { label: "AUDIGNON (40500)", value: "40500" },
  { label: "AUDON (40400)", value: "40400" },
  { label: "AUREILHAN (40200)", value: "40200" },
  { label: "AURICE (40500)", value: "40500" },
  { label: "AZUR (40140)", value: "40140" },
  { label: "BAHUS SOUBIRAN (40320)", value: "40320" },
  { label: "BAIGTS (40380)", value: "40380" },
  { label: "BANOS (40500)", value: "40500" },
  { label: "BASCONS (40090)", value: "40090" },
  { label: "BAS MAUCO (40500)", value: "40500" },
  { label: "BASSERCLES (40700)", value: "40700" },
  { label: "BASTENNES (40360)", value: "40360" },
  { label: "BATS (40320)", value: "40320" },
  { label: "BAUDIGNAN (40310)", value: "40310" },
  { label: "BEGAAR (40400)", value: "40400" },
  { label: "BELHADE (40410)", value: "40410" },
  { label: "BELIS (40120)", value: "40120" },
  { label: "BELUS (40300)", value: "40300" },
  { label: "BENESSE LES DAX (40180)", value: "40180" },
  { label: "BENESSE MAREMNE (40230)", value: "40230" },
  { label: "BENQUET (40280)", value: "40280" },
  { label: "BERGOUEY (40250)", value: "40250" },
  { label: "BETBEZER D ARMAGNAC (40240)", value: "40240" },
  { label: "BEYLONGUE (40370)", value: "40370" },
  { label: "BEYRIES (40700)", value: "40700" },
  { label: "BIARROTTE (40390)", value: "40390" },
  { label: "BIAS (40170)", value: "40170" },
  { label: "BIAUDOS (40390)", value: "40390" },
  { label: "BISCARROSSE (40600)", value: "40600" },
  { label: "BISCARROSSE (40600)", value: "40600" },
  { label: "BONNEGARDE (40330)", value: "40330" },
  { label: "BORDERES ET LAMENSANS (40270)", value: "40270" },
  { label: "BOSTENS (40090)", value: "40090" },
  { label: "BOUGUE (40090)", value: "40090" },
  { label: "BOURDALAT (40190)", value: "40190" },
  { label: "BOURRIOT BERGONCE (40120)", value: "40120" },
  { label: "BRASSEMPOUY (40330)", value: "40330" },
  { label: "BRETAGNE DE MARSAN (40280)", value: "40280" },
  { label: "BROCAS (40420)", value: "40420" },
  { label: "BUANES (40320)", value: "40320" },
  { label: "CACHEN (40120)", value: "40120" },
  { label: "CAGNOTTE (40300)", value: "40300" },
  { label: "CALLEN (40430)", value: "40430" },
  { label: "CAMPAGNE (40090)", value: "40090" },
  { label: "CAMPET ET LAMOLERE (40090)", value: "40090" },
  { label: "CANDRESSE (40180)", value: "40180" },
  { label: "CANENX ET REAUT (40090)", value: "40090" },
  { label: "CAPBRETON (40130)", value: "40130" },
  { label: "CARCARES STE CROIX (40400)", value: "40400" },
  { label: "CARCEN PONSON (40400)", value: "40400" },
  { label: "CASSEN (40380)", value: "40380" },
  { label: "CASTAIGNOS SOUSLENS (40700)", value: "40700" },
  { label: "CASTANDET (40270)", value: "40270" },
  { label: "CASTELNAU CHALOSSE (40360)", value: "40360" },
  { label: "CASTELNAU TURSAN (40320)", value: "40320" },
  { label: "CASTELNER (40700)", value: "40700" },
  { label: "CASTEL SARRAZIN (40330)", value: "40330" },
  { label: "CASTETS (40260)", value: "40260" },
  { label: "CAUNA (40500)", value: "40500" },
  { label: "CAUNEILLE (40300)", value: "40300" },
  { label: "CAUPENNE (40250)", value: "40250" },
  { label: "CAZALIS (40700)", value: "40700" },
  { label: "CAZERES SUR L ADOUR (40270)", value: "40270" },
  { label: "CERE (40090)", value: "40090" },
  { label: "CLASSUN (40320)", value: "40320" },
  { label: "CLEDES (40320)", value: "40320" },
  { label: "CLERMONT (40180)", value: "40180" },
  { label: "COMMENSACQ (40210)", value: "40210" },
  { label: "COUDURES (40500)", value: "40500" },
  { label: "CREON D ARMAGNAC (40240)", value: "40240" },
  { label: "DAX (40100)", value: "40100" },
  { label: "DOAZIT (40700)", value: "40700" },
  { label: "DONZACQ (40360)", value: "40360" },
  { label: "DUHORT BACHEN (40800)", value: "40800" },
  { label: "DUMES (40500)", value: "40500" },
  { label: "ESCALANS (40310)", value: "40310" },
  { label: "ESCOURCE (40210)", value: "40210" },
  { label: "ESTIBEAUX (40290)", value: "40290" },
  { label: "ESTIGARDE (40240)", value: "40240" },
  { label: "EUGENIE LES BAINS (40320)", value: "40320" },
  { label: "EYRES MONCUBE (40500)", value: "40500" },
  { label: "FARGUES (40500)", value: "40500" },
  { label: "LE FRECHE (40190)", value: "40190" },
  { label: "GAAS (40350)", value: "40350" },
  { label: "GABARRET (40310)", value: "40310" },
  { label: "GAILLERES (40090)", value: "40090" },
  { label: "GAMARDE LES BAINS (40380)", value: "40380" },
  { label: "GAREIN (40420)", value: "40420" },
  { label: "GARREY (40180)", value: "40180" },
  { label: "GASTES (40160)", value: "40160" },
  { label: "GAUJACQ (40330)", value: "40330" },
  { label: "GEAUNE (40320)", value: "40320" },
  { label: "GELOUX (40090)", value: "40090" },
  { label: "GIBRET (40380)", value: "40380" },
  { label: "GOOS (40180)", value: "40180" },
  { label: "GOURBERA (40990)", value: "40990" },
  { label: "GOUSSE (40465)", value: "40465" },
  { label: "GOUTS (40400)", value: "40400" },
  { label: "GRENADE SUR L ADOUR (40270)", value: "40270" },
  { label: "HABAS (40290)", value: "40290" },
  { label: "HAGETMAU (40700)", value: "40700" },
  { label: "HASTINGUES (40300)", value: "40300" },
  { label: "HAURIET (40250)", value: "40250" },
  { label: "HAUT MAUCO (40280)", value: "40280" },
  { label: "HERM (40990)", value: "40990" },
  { label: "HERRE (40310)", value: "40310" },
  { label: "HEUGAS (40180)", value: "40180" },
  { label: "HINX (40180)", value: "40180" },
  { label: "HONTANX (40190)", value: "40190" },
  { label: "HORSARRIEU (40700)", value: "40700" },
  { label: "JOSSE (40230)", value: "40230" },
  { label: "LABASTIDE CHALOSSE (40700)", value: "40700" },
  { label: "LABASTIDE D ARMAGNAC (40240)", value: "40240" },
  { label: "LABATUT (40300)", value: "40300" },
  { label: "LABENNE (40530)", value: "40530" },
  { label: "LABOUHEYRE (40210)", value: "40210" },
  { label: "LABRIT (40420)", value: "40420" },
  { label: "LACAJUNTE (40320)", value: "40320" },
  { label: "LACQUY (40120)", value: "40120" },
  { label: "LACRABE (40700)", value: "40700" },
  { label: "LAGLORIEUSE (40090)", value: "40090" },
  { label: "LAGRANGE (40240)", value: "40240" },
  { label: "LAHOSSE (40250)", value: "40250" },
  { label: "LALUQUE (40465)", value: "40465" },
  { label: "LAMOTHE (40250)", value: "40250" },
  { label: "LARBEY (40250)", value: "40250" },
  { label: "LARRIVIERE ST SAVIN (40270)", value: "40270" },
  { label: "LATRILLE (40800)", value: "40800" },
  { label: "LAUREDE (40250)", value: "40250" },
  { label: "LAURET (40320)", value: "40320" },
  { label: "LENCOUACQ (40120)", value: "40120" },
  { label: "LEON (40550)", value: "40550" },
  { label: "LESGOR (40400)", value: "40400" },
  { label: "LESPERON (40260)", value: "40260" },
  { label: "LE LEUY (40250)", value: "40250" },
  { label: "LEVIGNACQ (40170)", value: "40170" },
  { label: "LINXE (40260)", value: "40260" },
  { label: "LIPOSTHEY (40410)", value: "40410" },
  { label: "LIT ET MIXE (40170)", value: "40170" },
  { label: "LOSSE (40240)", value: "40240" },
  { label: "LOUER (40380)", value: "40380" },
  { label: "LOURQUEN (40250)", value: "40250" },
  { label: "LUBBON (40240)", value: "40240" },
  { label: "LUCBARDEZ ET BARGUES (40090)", value: "40090" },
  { label: "LUE (40210)", value: "40210" },
  { label: "RETJONS (40120)", value: "40120" },
  { label: "LUGLON (40630)", value: "40630" },
  { label: "LUSSAGNET (40270)", value: "40270" },
  { label: "LUXEY (40430)", value: "40430" },
  { label: "MAGESCQ (40140)", value: "40140" },
  { label: "MAILLAS (40120)", value: "40120" },
  { label: "MAILLERES (40120)", value: "40120" },
  { label: "MANO (40410)", value: "40410" },
  { label: "MANT (40700)", value: "40700" },
  { label: "MARPAPS (40330)", value: "40330" },
  { label: "MAURIES (40320)", value: "40320" },
  { label: "MAURRIN (40270)", value: "40270" },
  { label: "MAUVEZIN D ARMAGNAC (40240)", value: "40240" },
  { label: "MAYLIS (40250)", value: "40250" },
  { label: "MAZEROLLES (40090)", value: "40090" },
  { label: "MEES (40990)", value: "40990" },
  { label: "MEILHAN (40400)", value: "40400" },
  { label: "MESSANGES (40660)", value: "40660" },
  { label: "MEZOS (40170)", value: "40170" },
  { label: "MIMBASTE (40350)", value: "40350" },
  { label: "MIMIZAN (40200)", value: "40200" },
  { label: "MIRAMONT SENSACQ (40320)", value: "40320" },
  { label: "MISSON (40290)", value: "40290" },
  { label: "MOLIETS ET MAA (40660)", value: "40660" },
  { label: "MOMUY (40700)", value: "40700" },
  { label: "MONGET (40700)", value: "40700" },
  { label: "MONSEGUR (40700)", value: "40700" },
  { label: "MONTAUT (40500)", value: "40500" },
  { label: "MONT DE MARSAN (40000)", value: "40000" },
  { label: "MONTEGUT (40190)", value: "40190" },
  { label: "MONTFORT EN CHALOSSE (40380)", value: "40380" },
  { label: "MONTGAILLARD (40500)", value: "40500" },
  { label: "MONTSOUE (40500)", value: "40500" },
  { label: "MORCENX LA NOUVELLE (40110)", value: "40110" },
  { label: "MORCENX LA NOUVELLE (40110)", value: "40110" },
  { label: "MORCENX LA NOUVELLE (40110)", value: "40110" },
  { label: "MORCENX LA NOUVELLE (40110)", value: "40110" },
  { label: "MORGANX (40700)", value: "40700" },
  { label: "MOUSCARDES (40290)", value: "40290" },
  { label: "MOUSTEY (40410)", value: "40410" },
  { label: "MUGRON (40250)", value: "40250" },
  { label: "NARROSSE (40180)", value: "40180" },
  { label: "NASSIET (40330)", value: "40330" },
  { label: "NERBIS (40250)", value: "40250" },
  { label: "NOUSSE (40380)", value: "40380" },
  { label: "OEYREGAVE (40300)", value: "40300" },
  { label: "OEYRELUY (40180)", value: "40180" },
  { label: "ONARD (40380)", value: "40380" },
  { label: "ONDRES (40440)", value: "40440" },
  { label: "ONESSE LAHARIE (40110)", value: "40110" },
  { label: "ONESSE LAHARIE (40110)", value: "40110" },
  { label: "ORIST (40300)", value: "40300" },
  { label: "ORTHEVIELLE (40300)", value: "40300" },
  { label: "ORX (40230)", value: "40230" },
  { label: "OSSAGES (40290)", value: "40290" },
  { label: "OUSSE SUZAN (40110)", value: "40110" },
  { label: "OZOURT (40380)", value: "40380" },
  { label: "PARENTIS EN BORN (40160)", value: "40160" },
  { label: "PARLEBOSCQ (40310)", value: "40310" },
  { label: "PAYROS CAZAUTETS (40320)", value: "40320" },
  { label: "PECORADE (40320)", value: "40320" },
  { label: "PERQUIE (40190)", value: "40190" },
  { label: "PEY (40300)", value: "40300" },
  { label: "PEYRE (40700)", value: "40700" },
  { label: "PEYREHORADE (40300)", value: "40300" },
  { label: "PHILONDENX (40320)", value: "40320" },
  { label: "PIMBO (40320)", value: "40320" },
  { label: "PISSOS (40410)", value: "40410" },
  { label: "PISSOS (40410)", value: "40410" },
  { label: "POMAREZ (40360)", value: "40360" },
  { label: "PONTENX LES FORGES (40200)", value: "40200" },
  { label: "PONTONX SUR L ADOUR (40465)", value: "40465" },
  { label: "PORT DE LANNE (40300)", value: "40300" },
  { label: "POUDENX (40700)", value: "40700" },
  { label: "POUILLON (40350)", value: "40350" },
  { label: "POUYDESSEAUX (40120)", value: "40120" },
  { label: "POYANNE (40380)", value: "40380" },
  { label: "POYARTIN (40380)", value: "40380" },
  { label: "PRECHACQ LES BAINS (40465)", value: "40465" },
  { label: "PUJO LE PLAN (40190)", value: "40190" },
  { label: "PUYOL CAZALET (40320)", value: "40320" },
  { label: "RENUNG (40270)", value: "40270" },
  { label: "RIMBEZ ET BAUDIETS (40310)", value: "40310" },
  { label: "RION DES LANDES (40370)", value: "40370" },
  { label: "RION DES LANDES (40370)", value: "40370" },
  { label: "RIVIERE SAAS ET GOURBY (40180)", value: "40180" },
  { label: "ROQUEFORT (40120)", value: "40120" },
  { label: "SABRES (40630)", value: "40630" },
  { label: "ST AGNET (40800)", value: "40800" },
  { label: "ST ANDRE DE SEIGNANX (40390)", value: "40390" },
  { label: "ST AUBIN (40250)", value: "40250" },
  { label: "ST AVIT (40090)", value: "40090" },
  { label: "ST BARTHELEMY (40390)", value: "40390" },
  { label: "STE COLOMBE (40700)", value: "40700" },
  { label: "ST CRICQ CHALOSSE (40700)", value: "40700" },
  { label: "ST CRICQ DU GAVE (40300)", value: "40300" },
  { label: "ST CRICQ VILLENEUVE (40190)", value: "40190" },
  { label: "ST ETIENNE D ORTHE (40300)", value: "40300" },
  { label: "STE EULALIE EN BORN (40200)", value: "40200" },
  { label: "STE FOY (40190)", value: "40190" },
  { label: "ST GEIN (40190)", value: "40190" },
  { label: "ST GEOURS D AURIBAT (40380)", value: "40380" },
  { label: "ST GEOURS DE MAREMNE (40230)", value: "40230" },
  { label: "ST GOR (40120)", value: "40120" },
  { label: "ST JEAN DE LIER (40380)", value: "40380" },
  { label: "ST JEAN DE MARSACQ (40230)", value: "40230" },
  { label: "ST JULIEN D ARMAGNAC (40240)", value: "40240" },
  { label: "ST JULIEN EN BORN (40170)", value: "40170" },
  { label: "ST JUSTIN (40240)", value: "40240" },
  { label: "ST LAURENT DE GOSSE (40390)", value: "40390" },
  { label: "ST LON LES MINES (40300)", value: "40300" },
  { label: "ST LOUBOUER (40320)", value: "40320" },
  { label: "STE MARIE DE GOSSE (40390)", value: "40390" },
  { label: "ST MARTIN DE HINX (40390)", value: "40390" },
  { label: "ST MARTIN DE SEIGNANX (40390)", value: "40390" },
  { label: "ST MARTIN D ONEY (40090)", value: "40090" },
  { label: "ST MAURICE SUR ADOUR (40270)", value: "40270" },
  { label: "ST MICHEL ESCALUS (40550)", value: "40550" },
  { label: "ST PANDELON (40180)", value: "40180" },
  { label: "ST PAUL EN BORN (40200)", value: "40200" },
  { label: "ST PAUL LES DAX (40990)", value: "40990" },
  { label: "ST PERDON (40090)", value: "40090" },
  { label: "ST PIERRE DU MONT (40280)", value: "40280" },
  { label: "ST SEVER (40500)", value: "40500" },
  { label: "ST VINCENT DE PAUL (40990)", value: "40990" },
  { label: "ST VINCENT DE TYROSSE (40230)", value: "40230" },
  { label: "ST YAGUEN (40400)", value: "40400" },
  { label: "SAMADET (40320)", value: "40320" },
  { label: "SANGUINET (40460)", value: "40460" },
  { label: "SARBAZAN (40120)", value: "40120" },
  { label: "SARRAZIET (40500)", value: "40500" },
  { label: "SARRON (40800)", value: "40800" },
  { label: "SAUBION (40230)", value: "40230" },
  { label: "SAUBRIGUES (40230)", value: "40230" },
  { label: "SAUBUSSE (40180)", value: "40180" },
  { label: "SAUGNAC ET CAMBRAN (40180)", value: "40180" },
  { label: "SAUGNAC ET MURET (40410)", value: "40410" },
  { label: "SEIGNOSSE (40510)", value: "40510" },
  { label: "LE SEN (40420)", value: "40420" },
  { label: "SERRES GASTON (40700)", value: "40700" },
  { label: "SERRESLOUS ET ARRIBANS (40700)", value: "40700" },
  { label: "SEYRESSE (40180)", value: "40180" },
  { label: "SIEST (40180)", value: "40180" },
  { label: "SOLFERINO (40210)", value: "40210" },
  { label: "SOORTS HOSSEGOR (40150)", value: "40150" },
  { label: "SORBETS (40320)", value: "40320" },
  { label: "SORDE L ABBAYE (40300)", value: "40300" },
  { label: "SORE (40430)", value: "40430" },
  { label: "SORT EN CHALOSSE (40180)", value: "40180" },
  { label: "SOUPROSSE (40250)", value: "40250" },
  { label: "SOUSTONS (40140)", value: "40140" },
  { label: "TALLER (40260)", value: "40260" },
  { label: "TARNOS (40220)", value: "40220" },
  { label: "TARTAS (40400)", value: "40400" },
  { label: "TERCIS LES BAINS (40180)", value: "40180" },
  { label: "TETHIEU (40990)", value: "40990" },
  { label: "TILH (40360)", value: "40360" },
  { label: "TOSSE (40230)", value: "40230" },
  { label: "TOULOUZETTE (40250)", value: "40250" },
  { label: "TRENSACQ (40630)", value: "40630" },
  { label: "UCHACQ ET PARENTIS (40090)", value: "40090" },
  { label: "URGONS (40320)", value: "40320" },
  { label: "UZA (40170)", value: "40170" },
  { label: "VERT (40420)", value: "40420" },
  { label: "VICQ D AURIBAT (40380)", value: "40380" },
  { label: "VIELLE TURSAN (40320)", value: "40320" },
  { label: "VIELLE ST GIRONS (40560)", value: "40560" },
  { label: "VIELLE ST GIRONS (40560)", value: "40560" },
  { label: "VIELLE ST GIRONS (40560)", value: "40560" },
  { label: "VIELLE SOUBIRAN (40240)", value: "40240" },
  { label: "VIEUX BOUCAU LES BAINS (40480)", value: "40480" },
  { label: "LE VIGNAU (40270)", value: "40270" },
  { label: "VILLENAVE (40110)", value: "40110" },
  { label: "VILLENEUVE DE MARSAN (40190)", value: "40190" },
  { label: "YCHOUX (40160)", value: "40160" },
  { label: "YGOS ST SATURNIN (40110)", value: "40110" },
  { label: "YZOSSE (40180)", value: "40180" },
  { label: "AMBLOY (41310)", value: "41310" },
  { label: "ANGE (41400)", value: "41400" },
  { label: "AREINES (41100)", value: "41100" },
  { label: "ARTINS (41800)", value: "41800" },
  { label: "AUTAINVILLE (41240)", value: "41240" },
  { label: "AUTHON (41310)", value: "41310" },
  { label: "AVARAY (41500)", value: "41500" },
  { label: "AVERDON (41330)", value: "41330" },
  { label: "AZE (41100)", value: "41100" },
  { label: "BAILLOU (41170)", value: "41170" },
  { label: "BAUZY (41250)", value: "41250" },
  { label: "BEAUCHENE (41170)", value: "41170" },
  { label: "BILLY (41130)", value: "41130" },
  { label: "BINAS (41240)", value: "41240" },
  { label: "BLOIS (41000)", value: "41000" },
  { label: "BOISSEAU (41290)", value: "41290" },
  { label: "BONNEVEAU (41800)", value: "41800" },
  { label: "BOUFFRY (41270)", value: "41270" },
  { label: "BOURSAY (41270)", value: "41270" },
  { label: "BRACIEUX (41250)", value: "41250" },
  { label: "BREVAINVILLE (41160)", value: "41160" },
  { label: "BRIOU (41370)", value: "41370" },
  { label: "BUSLOUP (41160)", value: "41160" },
  { label: "CANDE SUR BEUVRON (41120)", value: "41120" },
  { label: "CELLE (41360)", value: "41360" },
  { label: "CELLETTES (41120)", value: "41120" },
  { label: "CHAILLES (41120)", value: "41120" },
  { label: "CHAMBORD (41250)", value: "41250" },
  { label: "CHAMPIGNY EN BEAUCE (41330)", value: "41330" },
  { label: "CHAON (41600)", value: "41600" },
  { label: "LA CHAPELLE ENCHERIE (41290)", value: "41290" },
  { label: "LA CHAPELLE MONTMARTIN (41320)", value: "41320" },
  { label: "LA CHAPELLE ST MARTIN EN PLAINE (41500)", value: "41500" },
  { label: "LA CHAPELLE VENDOMOISE (41330)", value: "41330" },
  { label: "LA CHAPELLE VICOMTESSE (41270)", value: "41270" },
  { label: "CHATEAUVIEUX (41110)", value: "41110" },
  { label: "CHATILLON SUR CHER (41130)", value: "41130" },
  { label: "CHATRES SUR CHER (41320)", value: "41320" },
  { label: "CHAUMONT SUR LOIRE (41150)", value: "41150" },
  { label: "CHAUMONT SUR THARONNE (41600)", value: "41600" },
  { label: "LA CHAUSSEE ST VICTOR (41260)", value: "41260" },
  { label: "CHAUVIGNY DU PERCHE (41270)", value: "41270" },
  { label: "CHEMERY (41700)", value: "41700" },
  { label: "CHEVERNY (41700)", value: "41700" },
  { label: "CHISSAY EN TOURAINE (41400)", value: "41400" },
  { label: "CHITENAY (41120)", value: "41120" },
  { label: "CHOUE (41170)", value: "41170" },
  { label: "CHOUSSY (41700)", value: "41700" },
  { label: "VALLOIRE SUR CISSE (41150)", value: "41150" },
  { label: "VALLOIRE SUR CISSE (41150)", value: "41150" },
  { label: "VALLOIRE SUR CISSE (41150)", value: "41150" },
  { label: "CONAN (41290)", value: "41290" },
  { label: "CONCRIERS (41370)", value: "41370" },
  { label: "LE CONTROIS EN SOLOGNE (41120)", value: "41120" },
  { label: "LE CONTROIS EN SOLOGNE (41120)", value: "41120" },
  { label: "LE CONTROIS EN SOLOGNE (41120)", value: "41120" },
  { label: "LE CONTROIS EN SOLOGNE (41400)", value: "41400" },
  { label: "LE CONTROIS EN SOLOGNE (41700)", value: "41700" },
  { label: "CORMENON (41170)", value: "41170" },
  { label: "CORMERAY (41120)", value: "41120" },
  { label: "COUDDES (41700)", value: "41700" },
  { label: "COUFFY (41110)", value: "41110" },
  { label: "COULOMMIERS LA TOUR (41100)", value: "41100" },
  { label: "COURBOUZON (41500)", value: "41500" },
  { label: "COUR CHEVERNY (41700)", value: "41700" },
  { label: "COURMEMIN (41230)", value: "41230" },
  { label: "COUR SUR LOIRE (41500)", value: "41500" },
  { label: "VALLEE DE RONSARD (41800)", value: "41800" },
  { label: "VALLEE DE RONSARD (41800)", value: "41800" },
  { label: "CROUY SUR COSSON (41220)", value: "41220" },
  { label: "CRUCHERAY (41100)", value: "41100" },
  { label: "DANZE (41160)", value: "41160" },
  { label: "DHUIZON (41220)", value: "41220" },
  { label: "DROUE (41270)", value: "41270" },
  { label: "EPIAIS (41290)", value: "41290" },
  { label: "EPUISAY (41360)", value: "41360" },
  { label: "LES ESSARTS (41800)", value: "41800" },
  { label: "FAVEROLLES SUR CHER (41400)", value: "41400" },
  { label: "FAYE (41100)", value: "41100" },
  { label: "LA FERTE BEAUHARNAIS (41210)", value: "41210" },
  { label: "LA FERTE IMBAULT (41300)", value: "41300" },
  { label: "LA FERTE ST CYR (41220)", value: "41220" },
  { label: "FONTAINES EN SOLOGNE (41250)", value: "41250" },
  { label: "FONTAINE LES COTEAUX (41800)", value: "41800" },
  { label: "FONTAINE RAOUL (41270)", value: "41270" },
  { label: "LA FONTENELLE (41270)", value: "41270" },
  { label: "FORTAN (41360)", value: "41360" },
  { label: "FOSSE (41330)", value: "41330" },
  { label: "FRANCAY (41190)", value: "41190" },
  { label: "FRESNES (41700)", value: "41700" },
  { label: "FRETEVAL (41160)", value: "41160" },
  { label: "LE GAULT DU PERCHE (41270)", value: "41270" },
  { label: "GIEVRES (41130)", value: "41130" },
  { label: "GOMBERGEAN (41310)", value: "41310" },
  { label: "GY EN SOLOGNE (41230)", value: "41230" },
  { label: "LES HAYES (41800)", value: "41800" },
  { label: "HERBAULT (41190)", value: "41190" },
  { label: "HOUSSAY (41800)", value: "41800" },
  { label: "HUISSEAU EN BEAUCE (41310)", value: "41310" },
  { label: "HUISSEAU SUR COSSON (41350)", value: "41350" },
  { label: "JOSNES (41370)", value: "41370" },
  { label: "LAMOTTE BEUVRON (41600)", value: "41600" },
  { label: "LANCE (41310)", value: "41310" },
  { label: "LANCOME (41190)", value: "41190" },
  { label: "LANDES LE GAULOIS (41190)", value: "41190" },
  { label: "LANGON SUR CHER (41320)", value: "41320" },
  { label: "LASSAY SUR CROISNE (41230)", value: "41230" },
  { label: "LAVARDIN (41800)", value: "41800" },
  { label: "LESTIOU (41500)", value: "41500" },
  { label: "LIGNIERES (41160)", value: "41160" },
  { label: "LISLE (41100)", value: "41100" },
  { label: "LOREUX (41200)", value: "41200" },
  { label: "LORGES (41370)", value: "41370" },
  { label: "LUNAY (41360)", value: "41360" },
  { label: "LA MADELEINE VILLEFROUIN (41370)", value: "41370" },
  { label: "MARAY (41320)", value: "41320" },
  { label: "MARCHENOIR (41370)", value: "41370" },
  { label: "MARCILLY EN BEAUCE (41100)", value: "41100" },
  { label: "MARCILLY EN GAULT (41210)", value: "41210" },
  { label: "MAREUIL SUR CHER (41110)", value: "41110" },
  { label: "LA MAROLLE EN SOLOGNE (41210)", value: "41210" },
  { label: "MAROLLES (41330)", value: "41330" },
  { label: "MASLIVES (41250)", value: "41250" },
  { label: "MAVES (41500)", value: "41500" },
  { label: "MAZANGE (41100)", value: "41100" },
  { label: "MEHERS (41140)", value: "41140" },
  { label: "MENARS (41500)", value: "41500" },
  { label: "MENNETOU SUR CHER (41320)", value: "41320" },
  { label: "MER (41500)", value: "41500" },
  { label: "MESLAND (41150)", value: "41150" },
  { label: "MESLAY (41100)", value: "41100" },
  { label: "MEUSNES (41130)", value: "41130" },
  { label: "MILLANCAY (41200)", value: "41200" },
  { label: "MOISY (41160)", value: "41160" },
  { label: "VALENCISSE (41190)", value: "41190" },
  { label: "VALENCISSE (41190)", value: "41190" },
  { label: "VALENCISSE (41190)", value: "41190" },
  { label: "MONDOUBLEAU (41170)", value: "41170" },
  { label: "MONTEAUX (41150)", value: "41150" },
  { label: "MONTHOU SUR BIEVRE (41120)", value: "41120" },
  { label: "MONTHOU SUR CHER (41400)", value: "41400" },
  { label: "LES MONTILS (41120)", value: "41120" },
  { label: "MONTLIVAULT (41350)", value: "41350" },
  { label: "MONTOIRE SUR LE LOIR (41800)", value: "41800" },
  { label: "MONTOIRE SUR LE LOIR (41800)", value: "41800" },
  { label: "MONT PRES CHAMBORD (41250)", value: "41250" },
  { label: "MONTRICHARD VAL DE CHER (41400)", value: "41400" },
  { label: "MONTRICHARD VAL DE CHER (41400)", value: "41400" },
  { label: "MONTRIEUX EN SOLOGNE (41210)", value: "41210" },
  { label: "MONTROUVEAU (41800)", value: "41800" },
  { label: "MOREE (41160)", value: "41160" },
  { label: "MUIDES SUR LOIRE (41500)", value: "41500" },
  { label: "MULSANS (41500)", value: "41500" },
  { label: "MUR DE SOLOGNE (41230)", value: "41230" },
  { label: "NAVEIL (41100)", value: "41100" },
  { label: "NEUNG SUR BEUVRON (41210)", value: "41210" },
  { label: "NEUVY (41250)", value: "41250" },
  { label: "NOUAN LE FUZELIER (41600)", value: "41600" },
  { label: "NOURRAY (41310)", value: "41310" },
  { label: "NOYERS SUR CHER (41140)", value: "41140" },
  { label: "OISLY (41700)", value: "41700" },
  { label: "VEUZAIN SUR LOIRE (41150)", value: "41150" },
  { label: "VEUZAIN SUR LOIRE (41150)", value: "41150" },
  { label: "ORCAY (41300)", value: "41300" },
  { label: "OUCQUES LA NOUVELLE (41290)", value: "41290" },
  { label: "OUCQUES LA NOUVELLE (41290)", value: "41290" },
  { label: "OUCQUES LA NOUVELLE (41290)", value: "41290" },
  { label: "OUCQUES LA NOUVELLE (41290)", value: "41290" },
  { label: "OUZOUER LE DOYEN (41160)", value: "41160" },
  { label: "BEAUCE LA ROMAINE (41160)", value: "41160" },
  { label: "BEAUCE LA ROMAINE (41160)", value: "41160" },
  { label: "BEAUCE LA ROMAINE (41240)", value: "41240" },
  { label: "BEAUCE LA ROMAINE (41240)", value: "41240" },
  { label: "BEAUCE LA ROMAINE (41240)", value: "41240" },
  { label: "BEAUCE LA ROMAINE (41240)", value: "41240" },
  { label: "BEAUCE LA ROMAINE (41240)", value: "41240" },
  { label: "PERIGNY (41100)", value: "41100" },
  { label: "PEZOU (41100)", value: "41100" },
  { label: "PIERREFITTE SUR SAULDRE (41300)", value: "41300" },
  { label: "LE PLESSIS DORIN (41170)", value: "41170" },
  { label: "LE PLESSIS L ECHELLE (41370)", value: "41370" },
  { label: "LE POISLAY (41270)", value: "41270" },
  { label: "PONTLEVOY (41400)", value: "41400" },
  { label: "POUILLE (41110)", value: "41110" },
  { label: "PRAY (41190)", value: "41190" },
  { label: "PRUNAY CASSEREAU (41310)", value: "41310" },
  { label: "PRUNIERS EN SOLOGNE (41200)", value: "41200" },
  { label: "RAHART (41160)", value: "41160" },
  { label: "RENAY (41100)", value: "41100" },
  { label: "RHODON (41290)", value: "41290" },
  { label: "RILLY SUR LOIRE (41150)", value: "41150" },
  { label: "ROCE (41100)", value: "41100" },
  { label: "ROCHES (41370)", value: "41370" },
  { label: "LES ROCHES L EVEQUE (41800)", value: "41800" },
  { label: "ROMILLY (41270)", value: "41270" },
  { label: "ROMORANTIN LANTHENAY (41200)", value: "41200" },
  { label: "ROUGEOU (41230)", value: "41230" },
  { label: "RUAN SUR EGVONNE (41270)", value: "41270" },
  { label: "ST AIGNAN (41110)", value: "41110" },
  { label: "ST AMAND LONGPRE (41310)", value: "41310" },
  { label: "STE ANNE (41100)", value: "41100" },
  { label: "ST ARNOULT (41800)", value: "41800" },
  { label: "ST BOHAIRE (41330)", value: "41330" },
  { label: "ST CLAUDE DE DIRAY (41350)", value: "41350" },
  { label: "ST CYR DU GAULT (41190)", value: "41190" },
  { label: "ST DENIS SUR LOIRE (41000)", value: "41000" },
  { label: "ST DYE SUR LOIRE (41500)", value: "41500" },
  { label: "ST ETIENNE DES GUERETS (41190)", value: "41190" },
  { label: "ST FIRMIN DES PRES (41100)", value: "41100" },
  { label: "ST GEORGES SUR CHER (41400)", value: "41400" },
  { label: "ST GERVAIS LA FORET (41350)", value: "41350" },
  { label: "ST GOURGON (41310)", value: "41310" },
  { label: "ST HILAIRE LA GRAVELLE (41160)", value: "41160" },
  { label: "ST JACQUES DES GUERETS (41800)", value: "41800" },
  { label: "ST JEAN FROIDMENTEL (41160)", value: "41160" },
  { label: "ST JULIEN DE CHEDON (41400)", value: "41400" },
  { label: "ST JULIEN SUR CHER (41320)", value: "41320" },
  { label: "ST LAURENT DES BOIS (41240)", value: "41240" },
  { label: "ST LAURENT NOUAN (41220)", value: "41220" },
  { label: "ST LAURENT NOUAN (41220)", value: "41220" },
  { label: "ST LEONARD EN BEAUCE (41370)", value: "41370" },
  { label: "ST LOUP (41320)", value: "41320" },
  { label: "ST LUBIN EN VERGONNOIS (41190)", value: "41190" },
  { label: "ST MARC DU COR (41170)", value: "41170" },
  { label: "ST MARTIN DES BOIS (41800)", value: "41800" },
  { label: "ST OUEN (41100)", value: "41100" },
  { label: "ST RIMAY (41800)", value: "41800" },
  { label: "ST ROMAIN SUR CHER (41140)", value: "41140" },
  { label: "ST SULPICE DE POMMERAY (41000)", value: "41000" },
  { label: "ST VIATRE (41210)", value: "41210" },
  { label: "SALBRIS (41300)", value: "41300" },
  { label: "SAMBIN (41120)", value: "41120" },
  { label: "SANTENAY (41190)", value: "41190" },
  { label: "SARGE SUR BRAYE (41170)", value: "41170" },
  { label: "SASNIERES (41310)", value: "41310" },
  { label: "SASSAY (41700)", value: "41700" },
  { label: "SAVIGNY SUR BRAYE (41360)", value: "41360" },
  { label: "SEIGY (41110)", value: "41110" },
  { label: "SELLES ST DENIS (41300)", value: "41300" },
  { label: "SELLES SUR CHER (41130)", value: "41130" },
  { label: "SELOMMES (41100)", value: "41100" },
  { label: "SERIS (41500)", value: "41500" },
  { label: "SEUR (41120)", value: "41120" },
  { label: "SOINGS EN SOLOGNE (41230)", value: "41230" },
  { label: "COUETRON AU PERCHE (41170)", value: "41170" },
  { label: "COUETRON AU PERCHE (41170)", value: "41170" },
  { label: "COUETRON AU PERCHE (41170)", value: "41170" },
  { label: "COUETRON AU PERCHE (41170)", value: "41170" },
  { label: "COUETRON AU PERCHE (41170)", value: "41170" },
  { label: "SOUESMES (41300)", value: "41300" },
  { label: "SOUGE (41800)", value: "41800" },
  { label: "SOUVIGNY EN SOLOGNE (41600)", value: "41600" },
  { label: "SUEVRES (41500)", value: "41500" },
  { label: "TALCY (41370)", value: "41370" },
  { label: "LE TEMPLE (41170)", value: "41170" },
  { label: "TERNAY (41800)", value: "41800" },
  { label: "THEILLAY (41300)", value: "41300" },
  { label: "THESEE (41140)", value: "41140" },
  { label: "THORE LA ROCHETTE (41100)", value: "41100" },
  { label: "THOURY (41220)", value: "41220" },
  { label: "TOURAILLES (41190)", value: "41190" },
  { label: "TOUR EN SOLOGNE (41250)", value: "41250" },
  { label: "TROO (41800)", value: "41800" },
  { label: "VALAIRE (41120)", value: "41120" },
  { label: "VALLIERES LES GRANDES (41400)", value: "41400" },
  { label: "VEILLEINS (41230)", value: "41230" },
  { label: "VENDOME (41100)", value: "41100" },
  { label: "VERNOU EN SOLOGNE (41230)", value: "41230" },
  { label: "VIEVY LE RAYE (41290)", value: "41290" },
  { label: "VIEVY LE RAYE (41290)", value: "41290" },
  { label: "VIEVY LE RAYE (41290)", value: "41290" },
  { label: "VILLAVARD (41800)", value: "41800" },
  { label: "LA VILLE AUX CLERCS (41160)", value: "41160" },
  { label: "VILLEBAROU (41000)", value: "41000" },
  { label: "VILLEBOUT (41270)", value: "41270" },
  { label: "VILLECHAUVE (41310)", value: "41310" },
  { label: "VILLEDIEU LE CHATEAU (41800)", value: "41800" },
  { label: "VILLEFRANCHE SUR CHER (41200)", value: "41200" },
  { label: "VILLEFRANCOEUR (41330)", value: "41330" },
  { label: "VILLEHERVIERS (41200)", value: "41200" },
  { label: "VILLEMARDY (41100)", value: "41100" },
  { label: "VILLENEUVE FROUVILLE (41290)", value: "41290" },
  { label: "VILLENY (41220)", value: "41220" },
  { label: "VILLEPORCHER (41310)", value: "41310" },
  { label: "VILLERABLE (41100)", value: "41100" },
  { label: "VILLERBON (41000)", value: "41000" },
  { label: "VILLERMAIN (41240)", value: "41240" },
  { label: "VILLEROMAIN (41100)", value: "41100" },
  { label: "VILLETRUN (41100)", value: "41100" },
  { label: "VILLEXANTON (41500)", value: "41500" },
  { label: "VILLIERSFAUX (41100)", value: "41100" },
  { label: "VILLIERS SUR LOIR (41100)", value: "41100" },
  { label: "VINEUIL (41350)", value: "41350" },
  { label: "VOUZON (41600)", value: "41600" },
  { label: "YVOY LE MARRON (41600)", value: "41600" },
  { label: "ABOEN (42380)", value: "42380" },
  { label: "AILLEUX (42130)", value: "42130" },
  { label: "AMBIERLE (42820)", value: "42820" },
  { label: "ANDREZIEUX BOUTHEON (42160)", value: "42160" },
  { label: "APINAC (42550)", value: "42550" },
  { label: "ARCINGES (42460)", value: "42460" },
  { label: "ARCON (42370)", value: "42370" },
  { label: "ARTHUN (42130)", value: "42130" },
  { label: "AVEIZIEUX (42330)", value: "42330" },
  { label: "BALBIGNY (42510)", value: "42510" },
  { label: "BARD (42600)", value: "42600" },
  { label: "BELLEGARDE EN FOREZ (42210)", value: "42210" },
  { label: "BELLEROCHE (42670)", value: "42670" },
  { label: "BELMONT DE LA LOIRE (42670)", value: "42670" },
  { label: "LA BENISSON DIEU (42720)", value: "42720" },
  { label: "LE BESSAT (42660)", value: "42660" },
  { label: "BESSEY (42520)", value: "42520" },
  { label: "BOEN SUR LIGNON (42130)", value: "42130" },
  { label: "BOISSET LES MONTROND (42210)", value: "42210" },
  { label: "BOISSET ST PRIEST (42560)", value: "42560" },
  { label: "BONSON (42160)", value: "42160" },
  { label: "BOURG ARGENTAL (42220)", value: "42220" },
  { label: "BOYER (42460)", value: "42460" },
  { label: "BRIENNON (42720)", value: "42720" },
  { label: "BULLY (42260)", value: "42260" },
  { label: "BURDIGNES (42220)", value: "42220" },
  { label: "BUSSIERES (42510)", value: "42510" },
  { label: "BUSSY ALBIEUX (42260)", value: "42260" },
  { label: "CALOIRE (42240)", value: "42240" },
  { label: "CELLIEU (42320)", value: "42320" },
  { label: "LE CERGNE (42460)", value: "42460" },
  { label: "CERVIERES (42440)", value: "42440" },
  { label: "CEZAY (42130)", value: "42130" },
  { label: "CHAGNON (42800)", value: "42800" },
  { label: "CHALAIN D UZORE (42600)", value: "42600" },
  { label: "CHALAIN LE COMTAL (42600)", value: "42600" },
  { label: "CHALMAZEL JEANSAGNIERE (42920)", value: "42920" },
  { label: "CHALMAZEL JEANSAGNIERE (42920)", value: "42920" },
  { label: "LA CHAMBA (42440)", value: "42440" },
  { label: "CHAMBEON (42110)", value: "42110" },
  { label: "CHAMBLES (42170)", value: "42170" },
  { label: "CHAMBOEUF (42330)", value: "42330" },
  { label: "LE CHAMBON FEUGEROLLES (42500)", value: "42500" },
  { label: "LA CHAMBONIE (42440)", value: "42440" },
  { label: "CHAMPDIEU (42600)", value: "42600" },
  { label: "CHAMPOLY (42430)", value: "42430" },
  { label: "CHANDON (42190)", value: "42190" },
  { label: "CHANGY (42310)", value: "42310" },
  { label: "LA CHAPELLE EN LAFAYE (42380)", value: "42380" },
  { label: "LA CHAPELLE VILLARS (42410)", value: "42410" },
  { label: "CHARLIEU (42190)", value: "42190" },
  { label: "CHATEAUNEUF (42800)", value: "42800" },
  { label: "CHATELNEUF (42940)", value: "42940" },
  { label: "CHATELUS (42140)", value: "42140" },
  { label: "CHAVANAY (42410)", value: "42410" },
  { label: "CHAZELLES SUR LAVIEU (42560)", value: "42560" },
  { label: "CHAZELLES SUR LYON (42140)", value: "42140" },
  { label: "CHENEREILLES (42560)", value: "42560" },
  { label: "CHERIER (42430)", value: "42430" },
  { label: "CHEVRIERES (42140)", value: "42140" },
  { label: "CHIRASSIMONT (42114)", value: "42114" },
  { label: "CHUYER (42410)", value: "42410" },
  { label: "CIVENS (42110)", value: "42110" },
  { label: "CLEPPE (42110)", value: "42110" },
  { label: "COLOMBIER (42220)", value: "42220" },
  { label: "COMBRE (42840)", value: "42840" },
  { label: "COMMELLE VERNAY (42120)", value: "42120" },
  { label: "CORDELLE (42123)", value: "42123" },
  { label: "LE COTEAU (42120)", value: "42120" },
  { label: "LA COTE EN COUZAN (42111)", value: "42111" },
  { label: "COTTANCE (42360)", value: "42360" },
  { label: "COUTOUVRE (42460)", value: "42460" },
  { label: "CRAINTILLEUX (42210)", value: "42210" },
  { label: "CREMEAUX (42260)", value: "42260" },
  { label: "CROIZET SUR GAND (42540)", value: "42540" },
  { label: "LE CROZET (42310)", value: "42310" },
  { label: "CUINZIER (42460)", value: "42460" },
  { label: "CUZIEU (42330)", value: "42330" },
  { label: "DARGOIRE (42800)", value: "42800" },
  { label: "DEBATS RIVIERE D ORPRA (42130)", value: "42130" },
  { label: "DOIZIEUX (42740)", value: "42740" },
  { label: "ECOCHE (42670)", value: "42670" },
  { label: "ECOTAY L OLME (42600)", value: "42600" },
  { label: "EPERCIEUX ST PAUL (42110)", value: "42110" },
  { label: "ESSERTINES EN CHATELNEUF (42600)", value: "42600" },
  { label: "ESSERTINES EN DONZY (42360)", value: "42360" },
  { label: "ESTIVAREILLES (42380)", value: "42380" },
  { label: "L'ETRAT (42580)", value: "42580" },
  { label: "FARNAY (42320)", value: "42320" },
  { label: "FEURS (42110)", value: "42110" },
  { label: "FIRMINY (42700)", value: "42700" },
  { label: "FONTANES (42140)", value: "42140" },
  { label: "LA FOUILLOUSE (42480)", value: "42480" },
  { label: "FOURNEAUX (42470)", value: "42470" },
  { label: "FRAISSES (42490)", value: "42490" },
  { label: "LA GIMOND (42140)", value: "42140" },
  { label: "GRAIX (42220)", value: "42220" },
  { label: "GRAMMOND (42140)", value: "42140" },
  { label: "LA GRAND CROIX (42320)", value: "42320" },
  { label: "LA GRESLE (42460)", value: "42460" },
  { label: "GREZIEUX LE FROMENTAL (42600)", value: "42600" },
  { label: "GREZOLLES (42260)", value: "42260" },
  { label: "GUMIERES (42560)", value: "42560" },
  { label: "L'HOPITAL LE GRAND (42210)", value: "42210" },
  { label: "L'HOPITAL SOUS ROCHEFORT (42130)", value: "42130" },
  { label: "L'HORME (42152)", value: "42152" },
  { label: "JARNOSSE (42460)", value: "42460" },
  { label: "JAS (42110)", value: "42110" },
  { label: "JONZIEUX (42660)", value: "42660" },
  { label: "JURE (42430)", value: "42430" },
  { label: "LAVIEU (42560)", value: "42560" },
  { label: "LAY (42470)", value: "42470" },
  { label: "LEIGNEUX (42130)", value: "42130" },
  { label: "LENTIGNY (42155)", value: "42155" },
  { label: "LERIGNEUX (42600)", value: "42600" },
  { label: "LEZIGNEUX (42600)", value: "42600" },
  { label: "LORETTE (42420)", value: "42420" },
  { label: "LUPE (42520)", value: "42520" },
  { label: "LURE (42260)", value: "42260" },
  { label: "LURIECQ (42380)", value: "42380" },
  { label: "MABLY (42300)", value: "42300" },
  { label: "MABLY (42300)", value: "42300" },
  { label: "MACHEZAL (42114)", value: "42114" },
  { label: "MACLAS (42520)", value: "42520" },
  { label: "MAGNEUX HAUTE RIVE (42600)", value: "42600" },
  { label: "MAIZILLY (42750)", value: "42750" },
  { label: "MALLEVAL (42520)", value: "42520" },
  { label: "MARCENOD (42140)", value: "42140" },
  { label: "MARCILLY LE CHATEL (42130)", value: "42130" },
  { label: "MARCLOPT (42210)", value: "42210" },
  { label: "MARCOUX (42130)", value: "42130" },
  { label: "MARGERIE CHANTAGRET (42560)", value: "42560" },
  { label: "MARINGES (42140)", value: "42140" },
  { label: "MARLHES (42660)", value: "42660" },
  { label: "MAROLS (42560)", value: "42560" },
  { label: "MARS (42750)", value: "42750" },
  { label: "MERLE LEIGNEC (42380)", value: "42380" },
  { label: "MIZERIEUX (42110)", value: "42110" },
  { label: "MONTAGNY (42840)", value: "42840" },
  { label: "MONTARCHER (42380)", value: "42380" },
  { label: "MONTBRISON (42600)", value: "42600" },
  { label: "MONTBRISON (42600)", value: "42600" },
  { label: "MONTCHAL (42360)", value: "42360" },
  { label: "MONTROND LES BAINS (42210)", value: "42210" },
  { label: "MONTVERDUN (42130)", value: "42130" },
  { label: "MORNAND EN FOREZ (42600)", value: "42600" },
  { label: "NANDAX (42720)", value: "42720" },
  { label: "NEAUX (42470)", value: "42470" },
  { label: "NERONDE (42510)", value: "42510" },
  { label: "NERVIEUX (42510)", value: "42510" },
  { label: "NEULISE (42590)", value: "42590" },
  { label: "NOAILLY (42640)", value: "42640" },
  { label: "LES NOES (42370)", value: "42370" },
  { label: "NOIRETABLE (42440)", value: "42440" },
  { label: "NOLLIEUX (42260)", value: "42260" },
  { label: "NOTRE DAME DE BOISSET (42120)", value: "42120" },
  { label: "OUCHES (42155)", value: "42155" },
  { label: "LA PACAUDIERE (42310)", value: "42310" },
  { label: "PALOGNEUX (42990)", value: "42990" },
  { label: "PANISSIERES (42360)", value: "42360" },
  { label: "PARIGNY (42120)", value: "42120" },
  { label: "PAVEZIN (42410)", value: "42410" },
  { label: "PELUSSIN (42410)", value: "42410" },
  { label: "PERIGNEUX (42380)", value: "42380" },
  { label: "PERREUX (42120)", value: "42120" },
  { label: "PINAY (42590)", value: "42590" },
  { label: "PLANFOY (42660)", value: "42660" },
  { label: "POMMIERS EN FOREZ (42260)", value: "42260" },
  { label: "PONCINS (42110)", value: "42110" },
  { label: "POUILLY LES FEURS (42110)", value: "42110" },
  { label: "POUILLY LES NONAINS (42155)", value: "42155" },
  { label: "POUILLY SOUS CHARLIEU (42720)", value: "42720" },
  { label: "PRADINES (42630)", value: "42630" },
  { label: "PRALONG (42600)", value: "42600" },
  { label: "PRECIEUX (42600)", value: "42600" },
  { label: "REGNY (42630)", value: "42630" },
  { label: "RENAISON (42370)", value: "42370" },
  { label: "LA RICAMARIE (42150)", value: "42150" },
  { label: "RIORGES (42153)", value: "42153" },
  { label: "RIVAS (42340)", value: "42340" },
  { label: "RIVE DE GIER (42800)", value: "42800" },
  { label: "ROANNE (42300)", value: "42300" },
  { label: "ROANNE (42300)", value: "42300" },
  { label: "ROCHE (42600)", value: "42600" },
  { label: "ROCHE LA MOLIERE (42230)", value: "42230" },
  { label: "ROISEY (42520)", value: "42520" },
  { label: "ROZIER COTES D AUREC (42380)", value: "42380" },
  { label: "ROZIER EN DONZY (42810)", value: "42810" },
  { label: "SAIL LES BAINS (42310)", value: "42310" },
  { label: "SAIL SOUS COUZAN (42890)", value: "42890" },
  { label: "STE AGATHE EN DONZY (42510)", value: "42510" },
  { label: "STE AGATHE LA BOUTERESSE (42130)", value: "42130" },
  { label: "ST ALBAN LES EAUX (42370)", value: "42370" },
  { label: "ST ANDRE D APCHON (42370)", value: "42370" },
  { label: "ST ANDRE LE PUY (42210)", value: "42210" },
  { label: "ST APPOLINARD (42520)", value: "42520" },
  { label: "ST BARTHELEMY LESTRA (42110)", value: "42110" },
  { label: "ST BONNET DES QUARTS (42310)", value: "42310" },
  { label: "ST BONNET LE CHATEAU (42380)", value: "42380" },
  { label: "ST BONNET LE COURREAU (42940)", value: "42940" },
  { label: "ST BONNET LES OULES (42330)", value: "42330" },
  { label: "ST CHAMOND (42400)", value: "42400" },
  { label: "ST CHAMOND (42400)", value: "42400" },
  { label: "ST CHAMOND (42400)", value: "42400" },
  { label: "ST CHAMOND (42400)", value: "42400" },
  { label: "ST CHRISTO EN JAREZ (42320)", value: "42320" },
  { label: "STE COLOMBE SUR GAND (42540)", value: "42540" },
  { label: "STE CROIX EN JAREZ (42800)", value: "42800" },
  { label: "ST CYPRIEN (42160)", value: "42160" },
  { label: "ST CYR DE FAVIERES (42123)", value: "42123" },
  { label: "ST CYR DE VALORGES (42114)", value: "42114" },
  { label: "ST CYR LES VIGNES (42210)", value: "42210" },
  { label: "ST DENIS DE CABANNE (42750)", value: "42750" },
  { label: "ST DENIS SUR COISE (42140)", value: "42140" },
  { label: "ST DIDIER SUR ROCHEFORT (42111)", value: "42111" },
  { label: "ST ETIENNE (42000)", value: "42000" },
  { label: "ST ETIENNE (42100)", value: "42100" },
  { label: "ST ETIENNE (42100)", value: "42100" },
  { label: "ST ETIENNE (42100)", value: "42100" },
  { label: "ST ETIENNE (42230)", value: "42230" },
  { label: "ST ETIENNE LE MOLARD (42130)", value: "42130" },
  { label: "ST FORGEUX LESPINASSE (42640)", value: "42640" },
  { label: "STE FOY ST SULPICE (42110)", value: "42110" },
  { label: "ST GALMIER (42330)", value: "42330" },
  { label: "ST GENEST LERPT (42530)", value: "42530" },
  { label: "ST GENEST MALIFAUX (42660)", value: "42660" },
  { label: "GENILAC (42800)", value: "42800" },
  { label: "GENILAC (42800)", value: "42800" },
  { label: "ST GEORGES DE BAROILLE (42510)", value: "42510" },
  { label: "ST GEORGES EN COUZAN (42990)", value: "42990" },
  { label: "ST GEORGES HAUTE VILLE (42610)", value: "42610" },
  { label: "ST GERMAIN LA MONTAGNE (42670)", value: "42670" },
  { label: "ST GERMAIN LAVAL (42260)", value: "42260" },
  { label: "ST GERMAIN LESPINASSE (42640)", value: "42640" },
  { label: "ST HAON LE CHATEL (42370)", value: "42370" },
  { label: "ST HAON LE VIEUX (42370)", value: "42370" },
  { label: "ST HEAND (42570)", value: "42570" },
  { label: "ST HILAIRE CUSSON LA VALMITTE (42380)", value: "42380" },
  { label: "ST HILAIRE SOUS CHARLIEU (42190)", value: "42190" },
  { label: "ST JEAN BONNEFONDS (42650)", value: "42650" },
  { label: "ST JEAN LA VETRE (42440)", value: "42440" },
  { label: "ST JEAN ST MAURICE SUR LOIRE (42155)", value: "42155" },
  { label: "ST JEAN ST MAURICE SUR LOIRE (42155)", value: "42155" },
  { label: "ST JEAN SOLEYMIEUX (42560)", value: "42560" },
  { label: "ST JODARD (42590)", value: "42590" },
  { label: "ST JOSEPH (42800)", value: "42800" },
  { label: "ST JULIEN D ODDES (42260)", value: "42260" },
  { label: "VETRE SUR ANZON (42111)", value: "42111" },
  { label: "VETRE SUR ANZON (42440)", value: "42440" },
  { label: "ST JULIEN MOLIN MOLETTE (42220)", value: "42220" },
  { label: "ST JUST EN BAS (42990)", value: "42990" },
  { label: "ST JUST EN CHEVALET (42430)", value: "42430" },
  { label: "ST JUST LA PENDUE (42540)", value: "42540" },
  { label: "ST LAURENT LA CONCHE (42210)", value: "42210" },
  { label: "ST LAURENT ROCHEFORT (42130)", value: "42130" },
  { label: "ST LEGER SUR ROANNE (42155)", value: "42155" },
  { label: "ST MARCEL DE FELINES (42122)", value: "42122" },
  { label: "ST MARCEL D URFE (42430)", value: "42430" },
  { label: "ST MARCELLIN EN FOREZ (42680)", value: "42680" },
  { label: "ST MARTIN D ESTREAUX (42620)", value: "42620" },
  { label: "ST MARTIN LA PLAINE (42800)", value: "42800" },
  { label: "ST MARTIN LA SAUVETE (42260)", value: "42260" },
  { label: "ST MARTIN LESTRA (42110)", value: "42110" },
  { label: "ST MAURICE EN GOURGOIS (42240)", value: "42240" },
  { label: "ST MEDARD EN FOREZ (42330)", value: "42330" },
  { label: "ST MICHEL SUR RHONE (42410)", value: "42410" },
  { label: "ST NIZIER DE FORNAS (42380)", value: "42380" },
  { label: "ST NIZIER SOUS CHARLIEU (42190)", value: "42190" },
  { label: "VEZELIN SUR LOIRE (42260)", value: "42260" },
  { label: "VEZELIN SUR LOIRE (42260)", value: "42260" },
  { label: "VEZELIN SUR LOIRE (42590)", value: "42590" },
  { label: "ST PAUL D UZORE (42600)", value: "42600" },
  { label: "ST PAUL EN CORNILLON (42240)", value: "42240" },
  { label: "ST PAUL EN JAREZ (42740)", value: "42740" },
  { label: "ST PIERRE DE BOEUF (42520)", value: "42520" },
  { label: "ST PIERRE LA NOAILLE (42190)", value: "42190" },
  { label: "ST POLGUES (42260)", value: "42260" },
  { label: "ST PRIEST EN JAREZ (42270)", value: "42270" },
  { label: "ST PRIEST LA PRUGNE (42830)", value: "42830" },
  { label: "ST PRIEST LA ROCHE (42590)", value: "42590" },
  { label: "ST PRIEST LA VETRE (42440)", value: "42440" },
  { label: "ST JUST ST RAMBERT (42170)", value: "42170" },
  { label: "ST JUST ST RAMBERT (42170)", value: "42170" },
  { label: "ST REGIS DU COIN (42660)", value: "42660" },
  { label: "ST RIRAND (42370)", value: "42370" },
  { label: "ST ROMAIN D URFE (42430)", value: "42430" },
  { label: "ST ROMAIN EN JAREZ (42800)", value: "42800" },
  { label: "ST ROMAIN LA MOTTE (42640)", value: "42640" },
  { label: "ST ROMAIN LE PUY (42610)", value: "42610" },
  { label: "ST ROMAIN LES ATHEUX (42660)", value: "42660" },
  { label: "ST SAUVEUR EN RUE (42220)", value: "42220" },
  { label: "ST SIXTE (42130)", value: "42130" },
  { label: "ST SYMPHORIEN DE LAY (42470)", value: "42470" },
  { label: "ST THOMAS LA GARDE (42600)", value: "42600" },
  { label: "ST VICTOR SUR RHINS (42630)", value: "42630" },
  { label: "ST VINCENT DE BOISSET (42120)", value: "42120" },
  { label: "LES SALLES (42440)", value: "42440" },
  { label: "SALT EN DONZY (42110)", value: "42110" },
  { label: "SALVIZINET (42110)", value: "42110" },
  { label: "SAUVAIN (42990)", value: "42990" },
  { label: "SAVIGNEUX (42600)", value: "42600" },
  { label: "SEVELINGES (42460)", value: "42460" },
  { label: "SOLEYMIEUX (42560)", value: "42560" },
  { label: "SORBIERS (42290)", value: "42290" },
  { label: "SOUTERNON (42260)", value: "42260" },
  { label: "SURY LE COMTAL (42450)", value: "42450" },
  { label: "LA TALAUDIERE (42350)", value: "42350" },
  { label: "TARENTAISE (42660)", value: "42660" },
  { label: "TARTARAS (42800)", value: "42800" },
  { label: "LA TERRASSE SUR DORLAY (42740)", value: "42740" },
  { label: "THELIS LA COMBE (42220)", value: "42220" },
  { label: "LA TOUR EN JAREZ (42580)", value: "42580" },
  { label: "LA TOURETTE (42380)", value: "42380" },
  { label: "TRELINS (42130)", value: "42130" },
  { label: "LA TUILIERE (42830)", value: "42830" },
  { label: "UNIAS (42210)", value: "42210" },
  { label: "UNIEUX (42240)", value: "42240" },
  { label: "URBISE (42310)", value: "42310" },
  { label: "USSON EN FOREZ (42550)", value: "42550" },
  { label: "VALEILLE (42110)", value: "42110" },
  { label: "VALFLEURY (42320)", value: "42320" },
  { label: "LA VALLA SUR ROCHEFORT (42111)", value: "42111" },
  { label: "LA VALLA EN GIER (42131)", value: "42131" },
  { label: "VEAUCHE (42340)", value: "42340" },
  { label: "VEAUCHETTE (42340)", value: "42340" },
  { label: "VENDRANGES (42590)", value: "42590" },
  { label: "VERANNE (42520)", value: "42520" },
  { label: "VERIN (42410)", value: "42410" },
  { label: "VERRIERES EN FOREZ (42600)", value: "42600" },
  { label: "LA VERSANNE (42220)", value: "42220" },
  { label: "VILLARS (42390)", value: "42390" },
  { label: "VILLEMONTAIS (42155)", value: "42155" },
  { label: "VILLEREST (42300)", value: "42300" },
  { label: "VILLERS (42460)", value: "42460" },
  { label: "VIOLAY (42780)", value: "42780" },
  { label: "VIRICELLES (42140)", value: "42140" },
  { label: "VIRIGNEUX (42140)", value: "42140" },
  { label: "VIVANS (42310)", value: "42310" },
  { label: "VOUGY (42720)", value: "42720" },
  { label: "CHAUSSETERRE (42430)", value: "42430" },
  { label: "AGNAT (43100)", value: "43100" },
  { label: "AIGUILHE (43000)", value: "43000" },
  { label: "ALLEGRE (43270)", value: "43270" },
  { label: "ALLEYRAC (43150)", value: "43150" },
  { label: "ALLEYRAS (43580)", value: "43580" },
  { label: "ALLY (43380)", value: "43380" },
  { label: "ARAULES (43200)", value: "43200" },
  { label: "ARLEMPDES (43490)", value: "43490" },
  { label: "ARLET (43380)", value: "43380" },
  { label: "ARSAC EN VELAY (43700)", value: "43700" },
  { label: "AUBAZAT (43380)", value: "43380" },
  { label: "AUREC SUR LOIRE (43110)", value: "43110" },
  { label: "VISSAC AUTEYRAC (43300)", value: "43300" },
  { label: "VISSAC AUTEYRAC (43300)", value: "43300" },
  { label: "AUTRAC (43450)", value: "43450" },
  { label: "AUVERS (43300)", value: "43300" },
  { label: "AUZON (43390)", value: "43390" },
  { label: "AZERAT (43390)", value: "43390" },
  { label: "BAINS (43370)", value: "43370" },
  { label: "BARGES (43340)", value: "43340" },
  { label: "BAS EN BASSET (43210)", value: "43210" },
  { label: "BEAULIEU (43800)", value: "43800" },
  { label: "BEAUMONT (43100)", value: "43100" },
  { label: "BEAUNE SUR ARZON (43500)", value: "43500" },
  { label: "BEAUX (43200)", value: "43200" },
  { label: "BEAUZAC (43590)", value: "43590" },
  { label: "BELLEVUE LA MONTAGNE (43350)", value: "43350" },
  { label: "BERBEZIT (43160)", value: "43160" },
  { label: "BESSAMOREL (43200)", value: "43200" },
  { label: "LA BESSEYRE ST MARY (43170)", value: "43170" },
  { label: "BLANZAC (43350)", value: "43350" },
  { label: "BLASSAC (43380)", value: "43380" },
  { label: "BLAVOZY (43700)", value: "43700" },
  { label: "BLESLE (43450)", value: "43450" },
  { label: "BOISSET (43500)", value: "43500" },
  { label: "BONNEVAL (43160)", value: "43160" },
  { label: "BORNE (43350)", value: "43350" },
  { label: "LE BOUCHET ST NICOLAS (43510)", value: "43510" },
  { label: "BOURNONCLE ST PIERRE (43360)", value: "43360" },
  { label: "BOURNONCLE ST PIERRE (43360)", value: "43360" },
  { label: "LE BRIGNON (43370)", value: "43370" },
  { label: "BRIOUDE (43100)", value: "43100" },
  { label: "BRIVES CHARENSAC (43700)", value: "43700" },
  { label: "CAYRES (43510)", value: "43510" },
  { label: "CEAUX D ALLEGRE (43270)", value: "43270" },
  { label: "CERZAT (43380)", value: "43380" },
  { label: "CEYSSAC (43000)", value: "43000" },
  { label: "CHADRAC (43770)", value: "43770" },
  { label: "CHADRON (43150)", value: "43150" },
  { label: "LA CHAISE DIEU (43160)", value: "43160" },
  { label: "CHAMALIERES SUR LOIRE (43800)", value: "43800" },
  { label: "CHAMBEZON (43410)", value: "43410" },
  { label: "LE CHAMBON SUR LIGNON (43400)", value: "43400" },
  { label: "CHAMPAGNAC LE VIEUX (43440)", value: "43440" },
  { label: "CHAMPCLAUSE (43260)", value: "43260" },
  { label: "CHAMPCLAUSE (43430)", value: "43430" },
  { label: "CHANALEILLES (43170)", value: "43170" },
  { label: "CHANIAT (43100)", value: "43100" },
  { label: "CHANTEUGES (43300)", value: "43300" },
  { label: "LA CHAPELLE BERTIN (43270)", value: "43270" },
  { label: "LA CHAPELLE D AUREC (43120)", value: "43120" },
  { label: "LA CHAPELLE GENESTE (43160)", value: "43160" },
  { label: "CHARRAIX (43300)", value: "43300" },
  { label: "CHASPINHAC (43700)", value: "43700" },
  { label: "CHASPUZAC (43320)", value: "43320" },
  { label: "CHASSAGNES (43230)", value: "43230" },
  { label: "CHASSIGNOLLES (43440)", value: "43440" },
  { label: "CHASTEL (43300)", value: "43300" },
  { label: "CHAUDEYROLLES (43430)", value: "43430" },
  { label: "CHAVANIAC LAFAYETTE (43230)", value: "43230" },
  { label: "CHAZELLES (43300)", value: "43300" },
  { label: "CHENEREILLES (43190)", value: "43190" },
  { label: "CHILHAC (43380)", value: "43380" },
  { label: "CHOMELIX (43500)", value: "43500" },
  { label: "LA CHOMETTE (43230)", value: "43230" },
  { label: "CISTRIERES (43160)", value: "43160" },
  { label: "COHADE (43100)", value: "43100" },
  { label: "COLLAT (43230)", value: "43230" },
  { label: "CONNANGLES (43160)", value: "43160" },
  { label: "COSTAROS (43490)", value: "43490" },
  { label: "COUBON (43700)", value: "43700" },
  { label: "COUTEUGES (43230)", value: "43230" },
  { label: "CRAPONNE SUR ARZON (43500)", value: "43500" },
  { label: "CRONCE (43300)", value: "43300" },
  { label: "CUBELLES (43170)", value: "43170" },
  { label: "CUSSAC SUR LOIRE (43370)", value: "43370" },
  { label: "DESGES (43300)", value: "43300" },
  { label: "DOMEYRAT (43230)", value: "43230" },
  { label: "DUNIERES (43220)", value: "43220" },
  { label: "ESPALEM (43450)", value: "43450" },
  { label: "ESPALY ST MARCEL (43000)", value: "43000" },
  { label: "ESPLANTAS VAZEILLES (43170)", value: "43170" },
  { label: "ESPLANTAS VAZEILLES (43580)", value: "43580" },
  { label: "LES ESTABLES (43150)", value: "43150" },
  { label: "FAY SUR LIGNON (43430)", value: "43430" },
  { label: "FELINES (43160)", value: "43160" },
  { label: "FERRUSSAC (43300)", value: "43300" },
  { label: "FIX ST GENEYS (43320)", value: "43320" },
  { label: "FONTANNES (43100)", value: "43100" },
  { label: "FREYCENET LA CUCHE (43150)", value: "43150" },
  { label: "FREYCENET LA TOUR (43150)", value: "43150" },
  { label: "FRUGERES LES MINES (43250)", value: "43250" },
  { label: "FRUGIERES LE PIN (43230)", value: "43230" },
  { label: "GOUDET (43150)", value: "43150" },
  { label: "GRAZAC (43200)", value: "43200" },
  { label: "GRENIER MONTGON (43450)", value: "43450" },
  { label: "GREZES (43170)", value: "43170" },
  { label: "JAVAUGUES (43100)", value: "43100" },
  { label: "JAX (43230)", value: "43230" },
  { label: "JOSAT (43230)", value: "43230" },
  { label: "JULLIANGES (43500)", value: "43500" },
  { label: "LAFARRE (43490)", value: "43490" },
  { label: "LAMOTHE (43100)", value: "43100" },
  { label: "LANDOS (43340)", value: "43340" },
  { label: "LANDOS (43340)", value: "43340" },
  { label: "LANGEAC (43300)", value: "43300" },
  { label: "LANTRIAC (43260)", value: "43260" },
  { label: "LAPTE (43200)", value: "43200" },
  { label: "LAUSSONNE (43150)", value: "43150" },
  { label: "LAVAL SUR DOULON (43440)", value: "43440" },
  { label: "LAVAUDIEU (43100)", value: "43100" },
  { label: "LAVOUTE CHILHAC (43380)", value: "43380" },
  { label: "LAVOUTE SUR LOIRE (43800)", value: "43800" },
  { label: "LEMPDES SUR ALLAGNON (43410)", value: "43410" },
  { label: "LEOTOING (43410)", value: "43410" },
  { label: "LISSAC (43350)", value: "43350" },
  { label: "LORLANGES (43360)", value: "43360" },
  { label: "LOUDES (43320)", value: "43320" },
  { label: "LUBILHAC (43100)", value: "43100" },
  { label: "MALREVERS (43800)", value: "43800" },
  { label: "MALVALETTE (43210)", value: "43210" },
  { label: "MALVIERES (43160)", value: "43160" },
  { label: "LE MAS DE TENCE (43190)", value: "43190" },
  { label: "MAZET ST VOY (43520)", value: "43520" },
  { label: "MAZERAT AUROUZE (43230)", value: "43230" },
  { label: "MAZEYRAT D ALLIER (43300)", value: "43300" },
  { label: "MAZEYRAT D ALLIER (43300)", value: "43300" },
  { label: "MAZEYRAT D ALLIER (43300)", value: "43300" },
  { label: "MERCOEUR (43100)", value: "43100" },
  { label: "MEZERES (43800)", value: "43800" },
  { label: "LE MONASTIER SUR GAZEILLE (43150)", value: "43150" },
  { label: "MONISTROL D ALLIER (43580)", value: "43580" },
  { label: "MONISTROL SUR LOIRE (43120)", value: "43120" },
  { label: "MONLET (43270)", value: "43270" },
  { label: "MONTCLARD (43230)", value: "43230" },
  { label: "LE MONTEIL (43700)", value: "43700" },
  { label: "MONTFAUCON EN VELAY (43290)", value: "43290" },
  { label: "MONTREGARD (43290)", value: "43290" },
  { label: "MONTUSCLAT (43260)", value: "43260" },
  { label: "MOUDEYRES (43150)", value: "43150" },
  { label: "OUIDES (43510)", value: "43510" },
  { label: "PAULHAC (43100)", value: "43100" },
  { label: "PAULHAGUET (43230)", value: "43230" },
  { label: "PEBRAC (43300)", value: "43300" },
  { label: "LE PERTUIS (43200)", value: "43200" },
  { label: "PINOLS (43300)", value: "43300" },
  { label: "POLIGNAC (43000)", value: "43000" },
  { label: "PONT SALOMON (43330)", value: "43330" },
  { label: "PRADELLES (43420)", value: "43420" },
  { label: "PRADES (43300)", value: "43300" },
  { label: "PRESAILLES (43150)", value: "43150" },
  { label: "LE PUY EN VELAY (43000)", value: "43000" },
  { label: "QUEYRIERES (43260)", value: "43260" },
  { label: "RAUCOULES (43290)", value: "43290" },
  { label: "RAURET (43340)", value: "43340" },
  { label: "RETOURNAC (43130)", value: "43130" },
  { label: "RIOTORD (43220)", value: "43220" },
  { label: "ROCHE EN REGNIER (43810)", value: "43810" },
  { label: "ROSIERES (43800)", value: "43800" },
  { label: "ST ANDRE DE CHALENCON (43130)", value: "43130" },
  { label: "ST ARCONS D ALLIER (43300)", value: "43300" },
  { label: "ST ARCONS DE BARGES (43420)", value: "43420" },
  { label: "ST AUSTREMOINE (43380)", value: "43380" },
  { label: "ST BEAUZIRE (43100)", value: "43100" },
  { label: "ST BERAIN (43300)", value: "43300" },
  { label: "ST BONNET LE FROID (43290)", value: "43290" },
  { label: "ST CHRISTOPHE D ALLIER (43340)", value: "43340" },
  { label: "ST CHRISTOPHE SUR DOLAISON (43370)", value: "43370" },
  { label: "ST CIRGUES (43380)", value: "43380" },
  { label: "ST DIDIER EN VELAY (43140)", value: "43140" },
  { label: "ST DIDIER SUR DOULON (43440)", value: "43440" },
  { label: "ST ETIENNE DU VIGAN (43420)", value: "43420" },
  { label: "ST ETIENNE LARDEYROL (43260)", value: "43260" },
  { label: "ST ETIENNE SUR BLESLE (43450)", value: "43450" },
  { label: "STE EUGENIE DE VILLENEUVE (43230)", value: "43230" },
  { label: "ST FERREOL D AUROURE (43330)", value: "43330" },
  { label: "STE FLORINE (43250)", value: "43250" },
  { label: "ST FRONT (43550)", value: "43550" },
  { label: "ST GENEYS PRES ST PAULIEN (43350)", value: "43350" },
  { label: "ST GEORGES D AURAC (43230)", value: "43230" },
  { label: "ST GEORGES LAGRICOL (43500)", value: "43500" },
  { label: "ST GERMAIN LAPRADE (43700)", value: "43700" },
  { label: "ST GERON (43360)", value: "43360" },
  { label: "ST HAON (43340)", value: "43340" },
  { label: "ST HILAIRE (43390)", value: "43390" },
  { label: "ST HOSTIEN (43260)", value: "43260" },
  { label: "ST ILPIZE (43380)", value: "43380" },
  { label: "ST JEAN D AUBRIGOUX (43500)", value: "43500" },
  { label: "ST JEAN DE NAY (43320)", value: "43320" },
  { label: "ST JEAN LACHALM (43510)", value: "43510" },
  { label: "ST JEURES (43200)", value: "43200" },
  { label: "ST JULIEN CHAPTEUIL (43260)", value: "43260" },
  { label: "ST JULIEN D ANCE (43500)", value: "43500" },
  { label: "ST JULIEN DES CHAZES (43300)", value: "43300" },
  { label: "ST JULIEN DU PINET (43200)", value: "43200" },
  { label: "ST JULIEN MOLHESABATE (43220)", value: "43220" },
  { label: "ST JUST MALMONT (43240)", value: "43240" },
  { label: "ST JUST PRES BRIOUDE (43100)", value: "43100" },
  { label: "ST LAURENT CHABREUGES (43100)", value: "43100" },
  { label: "STE MARGUERITE (43230)", value: "43230" },
  { label: "ST MARTIN DE FUGERES (43150)", value: "43150" },
  { label: "ST MAURICE DE LIGNON (43200)", value: "43200" },
  { label: "ST PAL DE CHALENCON (43500)", value: "43500" },
  { label: "ST PAL DE MONS (43620)", value: "43620" },
  { label: "ST PAL DE SENOUIRE (43160)", value: "43160" },
  { label: "ST PAUL DE TARTAS (43420)", value: "43420" },
  { label: "ST PAULIEN (43350)", value: "43350" },
  { label: "ST PIERRE DU CHAMP (43810)", value: "43810" },
  { label: "ST PIERRE EYNAC (43260)", value: "43260" },
  { label: "ST PREJET ARMANDON (43230)", value: "43230" },
  { label: "ST PREJET D ALLIER (43580)", value: "43580" },
  { label: "ST PRIVAT D ALLIER (43580)", value: "43580" },
  { label: "ST PRIVAT D ALLIER (43580)", value: "43580" },
  { label: "ST PRIVAT DU DRAGON (43380)", value: "43380" },
  { label: "ST ROMAIN LACHALM (43620)", value: "43620" },
  { label: "STE SIGOLENE (43600)", value: "43600" },
  { label: "ST VENERAND (43580)", value: "43580" },
  { label: "ST VERT (43440)", value: "43440" },
  { label: "ST VICTOR MALESCOURS (43140)", value: "43140" },
  { label: "ST VICTOR SUR ARLANC (43500)", value: "43500" },
  { label: "ST VIDAL (43320)", value: "43320" },
  { label: "ST VINCENT (43800)", value: "43800" },
  { label: "SALETTES (43150)", value: "43150" },
  { label: "SALZUIT (43230)", value: "43230" },
  { label: "SANSSAC L EGLISE (43320)", value: "43320" },
  { label: "SAUGUES (43170)", value: "43170" },
  { label: "LA SEAUVE SUR SEMENE (43140)", value: "43140" },
  { label: "SEMBADEL (43160)", value: "43160" },
  { label: "SENEUJOLS (43510)", value: "43510" },
  { label: "SIAUGUES STE MARIE (43300)", value: "43300" },
  { label: "SIAUGUES STE MARIE (43300)", value: "43300" },
  { label: "SOLIGNAC SOUS ROCHE (43130)", value: "43130" },
  { label: "SOLIGNAC SUR LOIRE (43370)", value: "43370" },
  { label: "TAILHAC (43300)", value: "43300" },
  { label: "TENCE (43190)", value: "43190" },
  { label: "THORAS (43170)", value: "43170" },
  { label: "THORAS (43580)", value: "43580" },
  { label: "TIRANGES (43530)", value: "43530" },
  { label: "TORSIAC (43450)", value: "43450" },
  { label: "VALPRIVAS (43210)", value: "43210" },
  { label: "VALS LE CHASTEL (43230)", value: "43230" },
  { label: "VALS PRES LE PUY (43750)", value: "43750" },
  { label: "VARENNES ST HONORAT (43270)", value: "43270" },
  { label: "LES VASTRES (43430)", value: "43430" },
  { label: "VAZEILLES LIMANDRE (43320)", value: "43320" },
  { label: "VENTEUGES (43170)", value: "43170" },
  { label: "VERGEZAC (43320)", value: "43320" },
  { label: "VERGONGHEON (43360)", value: "43360" },
  { label: "VERNASSAL (43270)", value: "43270" },
  { label: "LE VERNET (43320)", value: "43320" },
  { label: "VEZEZOUX (43390)", value: "43390" },
  { label: "VIEILLE BRIOUDE (43100)", value: "43100" },
  { label: "VIELPRAT (43490)", value: "43490" },
  { label: "VILLENEUVE D ALLIER (43380)", value: "43380" },
  { label: "LES VILLETTES (43600)", value: "43600" },
  { label: "VOREY (43800)", value: "43800" },
  { label: "YSSINGEAUX (43200)", value: "43200" },
  { label: "ABBARETZ (44170)", value: "44170" },
  { label: "AIGREFEUILLE SUR MAINE (44140)", value: "44140" },
  { label: "ANCENIS ST GEREON (44150)", value: "44150" },
  { label: "ANCENIS ST GEREON (44150)", value: "44150" },
  { label: "CHAUMES EN RETZ (44320)", value: "44320" },
  { label: "CHAUMES EN RETZ (44320)", value: "44320" },
  { label: "CHAUMES EN RETZ (44680)", value: "44680" },
  { label: "ASSERAC (44410)", value: "44410" },
  { label: "ASSERAC (44410)", value: "44410" },
  { label: "AVESSAC (44460)", value: "44460" },
  { label: "BASSE GOULAINE (44115)", value: "44115" },
  { label: "BATZ SUR MER (44740)", value: "44740" },
  { label: "LA BERNERIE EN RETZ (44760)", value: "44760" },
  { label: "BESNE (44160)", value: "44160" },
  { label: "LE BIGNON (44140)", value: "44140" },
  { label: "BLAIN (44130)", value: "44130" },
  { label: "BLAIN (44130)", value: "44130" },
  { label: "BLAIN (44130)", value: "44130" },
  { label: "LA BOISSIERE DU DORE (44430)", value: "44430" },
  { label: "BOUAYE (44830)", value: "44830" },
  { label: "BOUEE (44260)", value: "44260" },
  { label: "BOUGUENAIS (44340)", value: "44340" },
  { label: "BOUGUENAIS (44340)", value: "44340" },
  { label: "VILLENEUVE EN RETZ (44580)", value: "44580" },
  { label: "VILLENEUVE EN RETZ (44580)", value: "44580" },
  { label: "VILLENEUVE EN RETZ (44580)", value: "44580" },
  { label: "BOUSSAY (44190)", value: "44190" },
  { label: "BOUVRON (44130)", value: "44130" },
  { label: "BRAINS (44830)", value: "44830" },
  { label: "CAMPBON (44750)", value: "44750" },
  { label: "CARQUEFOU (44470)", value: "44470" },
  { label: "CASSON (44390)", value: "44390" },
  { label: "LE CELLIER (44850)", value: "44850" },
  { label: "DIVATTE SUR LOIRE (44450)", value: "44450" },
  { label: "DIVATTE SUR LOIRE (44450)", value: "44450" },
  { label: "LA CHAPELLE DES MARAIS (44410)", value: "44410" },
  { label: "LA CHAPELLE GLAIN (44670)", value: "44670" },
  { label: "LA CHAPELLE HEULIN (44330)", value: "44330" },
  { label: "LA CHAPELLE LAUNAY (44260)", value: "44260" },
  { label: "LA CHAPELLE SUR ERDRE (44240)", value: "44240" },
  { label: "CHATEAUBRIANT (44110)", value: "44110" },
  { label: "CHATEAU THEBAUD (44690)", value: "44690" },
  { label: "CHAUVE (44320)", value: "44320" },
  { label: "CHEIX EN RETZ (44640)", value: "44640" },
  { label: "LA CHEVROLIERE (44118)", value: "44118" },
  { label: "LA CHEVROLIERE (44118)", value: "44118" },
  { label: "CLISSON (44190)", value: "44190" },
  { label: "CONQUEREUIL (44290)", value: "44290" },
  { label: "CORDEMAIS (44360)", value: "44360" },
  { label: "CORSEPT (44560)", value: "44560" },
  { label: "COUERON (44220)", value: "44220" },
  { label: "COUERON (44220)", value: "44220" },
  { label: "COUFFE (44521)", value: "44521" },
  { label: "LE CROISIC (44490)", value: "44490" },
  { label: "CROSSAC (44160)", value: "44160" },
  { label: "DERVAL (44590)", value: "44590" },
  { label: "DONGES (44480)", value: "44480" },
  { label: "DREFFEAC (44530)", value: "44530" },
  { label: "ERBRAY (44110)", value: "44110" },
  { label: "LA BAULE ESCOUBLAC (44500)", value: "44500" },
  { label: "FAY DE BRETAGNE (44130)", value: "44130" },
  { label: "FEGREAC (44460)", value: "44460" },
  { label: "FERCE (44660)", value: "44660" },
  { label: "FROSSAY (44320)", value: "44320" },
  { label: "LE GAVRE (44130)", value: "44130" },
  { label: "GETIGNE (44190)", value: "44190" },
  { label: "GORGES (44190)", value: "44190" },
  { label: "GRAND AUVERNE (44520)", value: "44520" },
  { label: "GRANDCHAMP DES FONTAINES (44119)", value: "44119" },
  { label: "GUEMENE PENFAO (44290)", value: "44290" },
  { label: "GUEMENE PENFAO (44290)", value: "44290" },
  { label: "GUEMENE PENFAO (44290)", value: "44290" },
  { label: "GUENROUET (44530)", value: "44530" },
  { label: "GUENROUET (44530)", value: "44530" },
  { label: "GUERANDE (44350)", value: "44350" },
  { label: "GUERANDE (44350)", value: "44350" },
  { label: "GUERANDE (44350)", value: "44350" },
  { label: "GUERANDE (44350)", value: "44350" },
  { label: "LA HAIE FOUASSIERE (44690)", value: "44690" },
  { label: "HAUTE GOULAINE (44115)", value: "44115" },
  { label: "HERBIGNAC (44410)", value: "44410" },
  { label: "HERBIGNAC (44410)", value: "44410" },
  { label: "HERIC (44810)", value: "44810" },
  { label: "INDRE (44610)", value: "44610" },
  { label: "INDRE (44610)", value: "44610" },
  { label: "INDRE (44610)", value: "44610" },
  { label: "ISSE (44520)", value: "44520" },
  { label: "JANS (44170)", value: "44170" },
  { label: "JOUE SUR ERDRE (44440)", value: "44440" },
  { label: "JOUE SUR ERDRE (44440)", value: "44440" },
  { label: "JUIGNE DES MOUTIERS (44670)", value: "44670" },
  { label: "LE LANDREAU (44430)", value: "44430" },
  { label: "LAVAU SUR LOIRE (44260)", value: "44260" },
  { label: "LEGE (44650)", value: "44650" },
  { label: "LIGNE (44850)", value: "44850" },
  { label: "LA LIMOUZINIERE (44310)", value: "44310" },
  { label: "LE LOROUX BOTTEREAU (44430)", value: "44430" },
  { label: "LOUISFERT (44110)", value: "44110" },
  { label: "LUSANGER (44590)", value: "44590" },
  { label: "MACHECOUL ST MEME (44270)", value: "44270" },
  { label: "MACHECOUL ST MEME (44270)", value: "44270" },
  { label: "MAISDON SUR SEVRE (44690)", value: "44690" },
  { label: "MALVILLE (44260)", value: "44260" },
  { label: "LA MARNE (44270)", value: "44270" },
  { label: "MARSAC SUR DON (44170)", value: "44170" },
  { label: "MASSERAC (44290)", value: "44290" },
  { label: "MAUVES SUR LOIRE (44470)", value: "44470" },
  { label: "LA MEILLERAYE DE BRETAGNE (44520)", value: "44520" },
  { label: "MESANGER (44522)", value: "44522" },
  { label: "MESQUER (44420)", value: "44420" },
  { label: "MESQUER (44420)", value: "44420" },
  { label: "MISSILLAC (44780)", value: "44780" },
  { label: "MOISDON LA RIVIERE (44520)", value: "44520" },
  { label: "MONNIERES (44690)", value: "44690" },
  { label: "LA MONTAGNE (44620)", value: "44620" },
  { label: "MONTBERT (44140)", value: "44140" },
  { label: "MONTOIR DE BRETAGNE (44550)", value: "44550" },
  { label: "MONTRELAIS (44370)", value: "44370" },
  { label: "MOUAIS (44590)", value: "44590" },
  { label: "LES MOUTIERS EN RETZ (44760)", value: "44760" },
  { label: "MOUZEIL (44850)", value: "44850" },
  { label: "MOUZILLON (44330)", value: "44330" },
  { label: "NANTES (44000)", value: "44000" },
  { label: "NANTES (44100)", value: "44100" },
  { label: "NANTES (44200)", value: "44200" },
  { label: "NANTES (44300)", value: "44300" },
  { label: "NORT SUR ERDRE (44390)", value: "44390" },
  { label: "NOTRE DAME DES LANDES (44130)", value: "44130" },
  { label: "NOYAL SUR BRUTZ (44110)", value: "44110" },
  { label: "NOZAY (44170)", value: "44170" },
  { label: "ORVAULT (44700)", value: "44700" },
  { label: "OUDON (44521)", value: "44521" },
  { label: "PAIMBOEUF (44560)", value: "44560" },
  { label: "LE PALLET (44330)", value: "44330" },
  { label: "PANNECE (44440)", value: "44440" },
  { label: "PAULX (44270)", value: "44270" },
  { label: "LE PELLERIN (44640)", value: "44640" },
  { label: "PETIT AUVERNE (44670)", value: "44670" },
  { label: "PETIT MARS (44390)", value: "44390" },
  { label: "PIERRIC (44290)", value: "44290" },
  { label: "LE PIN (44540)", value: "44540" },
  { label: "PIRIAC SUR MER (44420)", value: "44420" },
  { label: "LA PLAINE SUR MER (44770)", value: "44770" },
  { label: "LA PLANCHE (44140)", value: "44140" },
  { label: "PLESSE (44630)", value: "44630" },
  { label: "PLESSE (44630)", value: "44630" },
  { label: "PLESSE (44630)", value: "44630" },
  { label: "PONTCHATEAU (44160)", value: "44160" },
  { label: "PONTCHATEAU (44160)", value: "44160" },
  { label: "PONTCHATEAU (44160)", value: "44160" },
  { label: "PONT ST MARTIN (44860)", value: "44860" },
  { label: "PONT ST MARTIN (44860)", value: "44860" },
  { label: "PORNIC (44210)", value: "44210" },
  { label: "PORNIC (44210)", value: "44210" },
  { label: "PORNIC (44210)", value: "44210" },
  { label: "PORNICHET (44380)", value: "44380" },
  { label: "PORT ST PERE (44710)", value: "44710" },
  { label: "POUILLE LES COTEAUX (44522)", value: "44522" },
  { label: "LE POULIGUEN (44510)", value: "44510" },
  { label: "PREFAILLES (44770)", value: "44770" },
  { label: "PRINQUIAU (44260)", value: "44260" },
  { label: "PUCEUL (44390)", value: "44390" },
  { label: "QUILLY (44750)", value: "44750" },
  { label: "LA REGRIPPIERE (44330)", value: "44330" },
  { label: "LA REMAUDIERE (44430)", value: "44430" },
  { label: "REMOUILLE (44140)", value: "44140" },
  { label: "REZE (44400)", value: "44400" },
  { label: "REZE (44400)", value: "44400" },
  { label: "RIAILLE (44440)", value: "44440" },
  { label: "ROUANS (44640)", value: "44640" },
  { label: "ROUGE (44660)", value: "44660" },
  { label: "RUFFIGNE (44660)", value: "44660" },
  { label: "SAFFRE (44390)", value: "44390" },
  { label: "ST AIGNAN GRANDLIEU (44860)", value: "44860" },
  { label: "ST ANDRE DES EAUX (44117)", value: "44117" },
  { label: "STE ANNE SUR BRIVET (44160)", value: "44160" },
  { label: "ST AUBIN DES CHATEAUX (44110)", value: "44110" },
  { label: "ST BREVIN LES PINS (44250)", value: "44250" },
  { label: "ST BREVIN LES PINS (44250)", value: "44250" },
  { label: "ST BREVIN LES PINS (44250)", value: "44250" },
  { label: "ST COLOMBAN (44310)", value: "44310" },
  { label: "ST COLOMBAN (44310)", value: "44310" },
  { label: "CORCOUE SUR LOGNE (44650)", value: "44650" },
  { label: "CORCOUE SUR LOGNE (44650)", value: "44650" },
  { label: "CORCOUE SUR LOGNE (44650)", value: "44650" },
  { label: "ST ETIENNE DE MER MORTE (44270)", value: "44270" },
  { label: "ST ETIENNE DE MONTLUC (44360)", value: "44360" },
  { label: "ST FIACRE SUR MAINE (44690)", value: "44690" },
  { label: "ST GILDAS DES BOIS (44530)", value: "44530" },
  { label: "ST HERBLAIN (44800)", value: "44800" },
  { label: "VAIR SUR LOIRE (44150)", value: "44150" },
  { label: "VAIR SUR LOIRE (44150)", value: "44150" },
  { label: "ST HILAIRE DE CHALEONS (44680)", value: "44680" },
  { label: "ST HILAIRE DE CLISSON (44190)", value: "44190" },
  { label: "ST JEAN DE BOISEAU (44640)", value: "44640" },
  { label: "ST JOACHIM (44720)", value: "44720" },
  { label: "ST JULIEN DE CONCELLES (44450)", value: "44450" },
  { label: "ST JULIEN DE VOUVANTES (44670)", value: "44670" },
  { label: "ST LEGER LES VIGNES (44710)", value: "44710" },
  { label: "STE LUCE SUR LOIRE (44980)", value: "44980" },
  { label: "ST LUMINE DE CLISSON (44190)", value: "44190" },
  { label: "ST LUMINE DE COUTAIS (44310)", value: "44310" },
  { label: "ST LYPHARD (44410)", value: "44410" },
  { label: "ST MALO DE GUERSAC (44550)", value: "44550" },
  { label: "ST MARS DE COUTAIS (44680)", value: "44680" },
  { label: "ST MARS DU DESERT (44850)", value: "44850" },
  { label: "VALLONS DE L ERDRE (44540)", value: "44540" },
  { label: "VALLONS DE L ERDRE (44540)", value: "44540" },
  { label: "VALLONS DE L ERDRE (44540)", value: "44540" },
  { label: "VALLONS DE L ERDRE (44540)", value: "44540" },
  { label: "VALLONS DE L ERDRE (44540)", value: "44540" },
  { label: "VALLONS DE L ERDRE (44540)", value: "44540" },
  { label: "ST MICHEL CHEF CHEF (44730)", value: "44730" },
  { label: "ST MICHEL CHEF CHEF (44730)", value: "44730" },
  { label: "ST MOLF (44350)", value: "44350" },
  { label: "ST NAZAIRE (44600)", value: "44600" },
  { label: "ST NAZAIRE (44600)", value: "44600" },
  { label: "ST NICOLAS DE REDON (44460)", value: "44460" },
  { label: "STE PAZANNE (44680)", value: "44680" },
  { label: "ST PERE EN RETZ (44320)", value: "44320" },
  { label: "ST PHILBERT DE GRAND LIEU (44310)", value: "44310" },
  { label: "STE REINE DE BRETAGNE (44160)", value: "44160" },
  { label: "ST SEBASTIEN SUR LOIRE (44230)", value: "44230" },
  { label: "ST VIAUD (44320)", value: "44320" },
  { label: "ST VINCENT DES LANDES (44590)", value: "44590" },
  { label: "SAUTRON (44880)", value: "44880" },
  { label: "SAVENAY (44260)", value: "44260" },
  { label: "SEVERAC (44530)", value: "44530" },
  { label: "SION LES MINES (44590)", value: "44590" },
  { label: "LES SORINIERES (44840)", value: "44840" },
  { label: "SOUDAN (44110)", value: "44110" },
  { label: "SOULVACHE (44660)", value: "44660" },
  { label: "SUCE SUR ERDRE (44240)", value: "44240" },
  { label: "TEILLE (44440)", value: "44440" },
  { label: "LE TEMPLE DE BRETAGNE (44360)", value: "44360" },
  { label: "THOUARE SUR LOIRE (44470)", value: "44470" },
  { label: "LES TOUCHES (44390)", value: "44390" },
  { label: "TOUVOIS (44650)", value: "44650" },
  { label: "TRANS SUR ERDRE (44440)", value: "44440" },
  { label: "TREFFIEUX (44170)", value: "44170" },
  { label: "TREILLIERES (44119)", value: "44119" },
  { label: "TRIGNAC (44570)", value: "44570" },
  { label: "LA TURBALLE (44420)", value: "44420" },
  { label: "LA TURBALLE (44420)", value: "44420" },
  { label: "VALLET (44330)", value: "44330" },
  { label: "LOIREAUXENCE (44370)", value: "44370" },
  { label: "LOIREAUXENCE (44370)", value: "44370" },
  { label: "LOIREAUXENCE (44370)", value: "44370" },
  { label: "LOIREAUXENCE (44370)", value: "44370" },
  { label: "VAY (44170)", value: "44170" },
  { label: "VERTOU (44120)", value: "44120" },
  { label: "VERTOU (44120)", value: "44120" },
  { label: "VIEILLEVIGNE (44116)", value: "44116" },
  { label: "VIGNEUX DE BRETAGNE (44360)", value: "44360" },
  { label: "VIGNEUX DE BRETAGNE (44360)", value: "44360" },
  { label: "VILLEPOT (44110)", value: "44110" },
  { label: "VUE (44640)", value: "44640" },
  { label: "LA CHEVALLERAIS (44810)", value: "44810" },
  { label: "LA ROCHE BLANCHE (44522)", value: "44522" },
  { label: "GENESTON (44140)", value: "44140" },
  { label: "LA GRIGONNAIS (44170)", value: "44170" },
  { label: "ADON (45230)", value: "45230" },
  { label: "AILLANT SUR MILLERON (45230)", value: "45230" },
  { label: "AMILLY (45200)", value: "45200" },
  { label: "ANDONVILLE (45480)", value: "45480" },
  { label: "ARDON (45160)", value: "45160" },
  { label: "ARTENAY (45410)", value: "45410" },
  { label: "ASCHERES LE MARCHE (45170)", value: "45170" },
  { label: "ASCOUX (45300)", value: "45300" },
  { label: "ATTRAY (45170)", value: "45170" },
  { label: "AUDEVILLE (45300)", value: "45300" },
  { label: "AUGERVILLE LA RIVIERE (45330)", value: "45330" },
  { label: "AULNAY LA RIVIERE (45390)", value: "45390" },
  { label: "AUTRUY SUR JUINE (45480)", value: "45480" },
  { label: "AUTRY LE CHATEL (45500)", value: "45500" },
  { label: "AUVILLIERS EN GATINAIS (45270)", value: "45270" },
  { label: "AUXY (45340)", value: "45340" },
  { label: "BACCON (45130)", value: "45130" },
  { label: "LE BARDON (45130)", value: "45130" },
  { label: "BARVILLE EN GATINAIS (45340)", value: "45340" },
  { label: "BATILLY EN GATINAIS (45340)", value: "45340" },
  { label: "BATILLY EN PUISAYE (45420)", value: "45420" },
  { label: "BAULE (45130)", value: "45130" },
  { label: "BAZOCHES LES GALLERANDES (45480)", value: "45480" },
  { label: "BAZOCHES LES GALLERANDES (45480)", value: "45480" },
  { label: "BAZOCHES SUR LE BETZ (45210)", value: "45210" },
  { label: "BEAUCHAMPS SUR HUILLARD (45270)", value: "45270" },
  { label: "BEAUGENCY (45190)", value: "45190" },
  { label: "BEAULIEU SUR LOIRE (45630)", value: "45630" },
  { label: "BEAUNE LA ROLANDE (45340)", value: "45340" },
  { label: "BELLEGARDE (45270)", value: "45270" },
  { label: "LE BIGNON MIRABEAU (45210)", value: "45210" },
  { label: "BOESSES (45390)", value: "45390" },
  { label: "BOIGNY SUR BIONNE (45760)", value: "45760" },
  { label: "BOISCOMMUN (45340)", value: "45340" },
  { label: "BOISCOMMUN (45340)", value: "45340" },
  { label: "BOISMORAND (45290)", value: "45290" },
  { label: "BOISMORAND (45290)", value: "45290" },
  { label: "BOISSEAUX (45480)", value: "45480" },
  { label: "BONDAROY (45300)", value: "45300" },
  { label: "BONNEE (45460)", value: "45460" },
  { label: "BONNY SUR LOIRE (45420)", value: "45420" },
  { label: "BORDEAUX EN GATINAIS (45340)", value: "45340" },
  { label: "LES BORDES (45460)", value: "45460" },
  { label: "BOU (45430)", value: "45430" },
  { label: "BOUGY LEZ NEUVILLE (45170)", value: "45170" },
  { label: "BOUILLY EN GATINAIS (45300)", value: "45300" },
  { label: "BOULAY LES BARRES (45140)", value: "45140" },
  { label: "BOUZONVILLE AUX BOIS (45300)", value: "45300" },
  { label: "BOUZY LA FORET (45460)", value: "45460" },
  { label: "BOYNES (45300)", value: "45300" },
  { label: "BRAY ST AIGNAN (45460)", value: "45460" },
  { label: "BRAY ST AIGNAN (45460)", value: "45460" },
  { label: "BRETEAU (45250)", value: "45250" },
  { label: "BRIARE (45250)", value: "45250" },
  { label: "BRIARRES SUR ESSONNE (45390)", value: "45390" },
  { label: "BRICY (45310)", value: "45310" },
  { label: "BROMEILLES (45390)", value: "45390" },
  { label: "BUCY LE ROI (45410)", value: "45410" },
  { label: "BUCY ST LIPHARD (45140)", value: "45140" },
  { label: "LA BUSSIERE (45230)", value: "45230" },
  { label: "CEPOY (45120)", value: "45120" },
  { label: "CERCOTTES (45520)", value: "45520" },
  { label: "CERDON (45620)", value: "45620" },
  { label: "CERNOY EN BERRY (45360)", value: "45360" },
  { label: "CESARVILLE DOSSAINVILLE (45300)", value: "45300" },
  { label: "CESARVILLE DOSSAINVILLE (45300)", value: "45300" },
  { label: "CHAILLY EN GATINAIS (45260)", value: "45260" },
  { label: "CHAINGY (45380)", value: "45380" },
  { label: "CHALETTE SUR LOING (45120)", value: "45120" },
  { label: "CHAMBON LA FORET (45340)", value: "45340" },
  { label: "CHAMPOULET (45420)", value: "45420" },
  { label: "CHANTEAU (45400)", value: "45400" },
  { label: "CHANTECOQ (45320)", value: "45320" },
  { label: "LA CHAPELLE ONZERAIN (45310)", value: "45310" },
  { label: "LA CHAPELLE ST MESMIN (45380)", value: "45380" },
  { label: "LA CHAPELLE ST SEPULCRE (45210)", value: "45210" },
  { label: "LA CHAPELLE SUR AVEYRON (45230)", value: "45230" },
  { label: "CHAPELON (45270)", value: "45270" },
  { label: "LE CHARME (45230)", value: "45230" },
  { label: "CHARMONT EN BEAUCE (45480)", value: "45480" },
  { label: "CHARSONVILLE (45130)", value: "45130" },
  { label: "CHATEAUNEUF SUR LOIRE (45110)", value: "45110" },
  { label: "CHATEAU RENARD (45220)", value: "45220" },
  { label: "CHATENOY (45260)", value: "45260" },
  { label: "CHATILLON COLIGNY (45230)", value: "45230" },
  { label: "CHATILLON LE ROI (45480)", value: "45480" },
  { label: "CHATILLON SUR LOIRE (45360)", value: "45360" },
  { label: "CHAUSSY (45480)", value: "45480" },
  { label: "CHECY (45430)", value: "45430" },
  { label: "CHEVANNES (45210)", value: "45210" },
  { label: "CHEVILLON SUR HUILLARD (45700)", value: "45700" },
  { label: "CHEVILLY (45520)", value: "45520" },
  { label: "CHEVRY SOUS LE BIGNON (45210)", value: "45210" },
  { label: "CHILLEURS AUX BOIS (45170)", value: "45170" },
  { label: "LES CHOUX (45290)", value: "45290" },
  { label: "CHUELLES (45220)", value: "45220" },
  { label: "CLERY ST ANDRE (45370)", value: "45370" },
  { label: "COINCES (45310)", value: "45310" },
  { label: "COMBLEUX (45800)", value: "45800" },
  { label: "COMBREUX (45530)", value: "45530" },
  { label: "CONFLANS SUR LOING (45700)", value: "45700" },
  { label: "CORBEILLES (45490)", value: "45490" },
  { label: "CORQUILLEROY (45120)", value: "45120" },
  { label: "CORTRAT (45700)", value: "45700" },
  { label: "COUDROY (45260)", value: "45260" },
  { label: "COULLONS (45720)", value: "45720" },
  { label: "COULMIERS (45130)", value: "45130" },
  { label: "COURCELLES LE ROI (45300)", value: "45300" },
  { label: "COURCY AUX LOGES (45300)", value: "45300" },
  { label: "LA COUR MARIGNY (45260)", value: "45260" },
  { label: "COURTEMAUX (45320)", value: "45320" },
  { label: "COURTEMPIERRE (45490)", value: "45490" },
  { label: "COURTENAY (45320)", value: "45320" },
  { label: "CRAVANT (45190)", value: "45190" },
  { label: "CROTTES EN PITHIVERAIS (45170)", value: "45170" },
  { label: "CROTTES EN PITHIVERAIS (45170)", value: "45170" },
  { label: "DADONVILLE (45300)", value: "45300" },
  { label: "DAMMARIE EN PUISAYE (45420)", value: "45420" },
  { label: "DAMMARIE SUR LOING (45230)", value: "45230" },
  { label: "DAMPIERRE EN BURLY (45570)", value: "45570" },
  { label: "DARVOY (45150)", value: "45150" },
  { label: "DESMONTS (45390)", value: "45390" },
  { label: "DIMANCHEVILLE (45390)", value: "45390" },
  { label: "DONNERY (45450)", value: "45450" },
  { label: "DORDIVES (45680)", value: "45680" },
  { label: "DOUCHY MONTCORBON (45220)", value: "45220" },
  { label: "DOUCHY MONTCORBON (45220)", value: "45220" },
  { label: "DRY (45370)", value: "45370" },
  { label: "ECHILLEUSES (45390)", value: "45390" },
  { label: "EGRY (45340)", value: "45340" },
  { label: "ENGENVILLE (45300)", value: "45300" },
  { label: "EPIEDS EN BEAUCE (45130)", value: "45130" },
  { label: "ERCEVILLE (45480)", value: "45480" },
  { label: "ERVAUVILLE (45320)", value: "45320" },
  { label: "ESCRENNES (45300)", value: "45300" },
  { label: "ESCRIGNELLES (45250)", value: "45250" },
  { label: "ESTOUY (45300)", value: "45300" },
  { label: "FAVERELLES (45420)", value: "45420" },
  { label: "FAY AUX LOGES (45450)", value: "45450" },
  { label: "FEINS EN GATINAIS (45230)", value: "45230" },
  { label: "FEROLLES (45150)", value: "45150" },
  { label: "FERRIERES EN GATINAIS (45210)", value: "45210" },
  { label: "LA FERTE ST AUBIN (45240)", value: "45240" },
  { label: "FLEURY LES AUBRAIS (45400)", value: "45400" },
  { label: "FONTENAY SUR LOING (45210)", value: "45210" },
  { label: "FOUCHEROLLES (45320)", value: "45320" },
  { label: "FREVILLE DU GATINAIS (45270)", value: "45270" },
  { label: "GAUBERTIN (45340)", value: "45340" },
  { label: "GEMIGNY (45310)", value: "45310" },
  { label: "GERMIGNY DES PRES (45110)", value: "45110" },
  { label: "GIDY (45520)", value: "45520" },
  { label: "GIEN (45500)", value: "45500" },
  { label: "GIEN (45500)", value: "45500" },
  { label: "GIROLLES (45120)", value: "45120" },
  { label: "GIVRAINES (45300)", value: "45300" },
  { label: "GONDREVILLE (45490)", value: "45490" },
  { label: "GRANGERMONT (45390)", value: "45390" },
  { label: "GRENEVILLE EN BEAUCE (45480)", value: "45480" },
  { label: "GRENEVILLE EN BEAUCE (45480)", value: "45480" },
  { label: "GRISELLES (45210)", value: "45210" },
  { label: "GUIGNEVILLE (45300)", value: "45300" },
  { label: "GUIGNEVILLE (45300)", value: "45300" },
  { label: "GUILLY (45600)", value: "45600" },
  { label: "GY LES NONAINS (45220)", value: "45220" },
  { label: "HUETRE (45520)", value: "45520" },
  { label: "HUISSEAU SUR MAUVES (45130)", value: "45130" },
  { label: "INGRANNES (45450)", value: "45450" },
  { label: "INGRE (45140)", value: "45140" },
  { label: "INTVILLE LA GUETARD (45300)", value: "45300" },
  { label: "ISDES (45620)", value: "45620" },
  { label: "JARGEAU (45150)", value: "45150" },
  { label: "JOUY EN PITHIVERAIS (45480)", value: "45480" },
  { label: "JOUY LE POTIER (45370)", value: "45370" },
  { label: "JURANVILLE (45340)", value: "45340" },
  { label: "LAAS (45300)", value: "45300" },
  { label: "LADON (45270)", value: "45270" },
  { label: "LAILLY EN VAL (45740)", value: "45740" },
  { label: "LANGESSE (45290)", value: "45290" },
  { label: "LEOUVILLE (45480)", value: "45480" },
  { label: "LIGNY LE RIBAULT (45240)", value: "45240" },
  { label: "LION EN BEAUCE (45410)", value: "45410" },
  { label: "LION EN SULLIAS (45600)", value: "45600" },
  { label: "LOMBREUIL (45700)", value: "45700" },
  { label: "LORCY (45490)", value: "45490" },
  { label: "LORRIS (45260)", value: "45260" },
  { label: "LOURY (45470)", value: "45470" },
  { label: "LOUZOUER (45210)", value: "45210" },
  { label: "LE MALESHERBOIS (45300)", value: "45300" },
  { label: "LE MALESHERBOIS (45330)", value: "45330" },
  { label: "LE MALESHERBOIS (45330)", value: "45330" },
  { label: "LE MALESHERBOIS (45330)", value: "45330" },
  { label: "LE MALESHERBOIS (45330)", value: "45330" },
  { label: "LE MALESHERBOIS (45330)", value: "45330" },
  { label: "LE MALESHERBOIS (45330)", value: "45330" },
  { label: "MARCILLY EN VILLETTE (45240)", value: "45240" },
  { label: "MARDIE (45430)", value: "45430" },
  { label: "MARDIE (45430)", value: "45430" },
  { label: "MAREAU AUX BOIS (45300)", value: "45300" },
  { label: "MAREAU AUX PRES (45370)", value: "45370" },
  { label: "MARIGNY LES USAGES (45760)", value: "45760" },
  { label: "MARSAINVILLIERS (45300)", value: "45300" },
  { label: "MELLEROY (45220)", value: "45220" },
  { label: "MENESTREAU EN VILLETTE (45240)", value: "45240" },
  { label: "MERINVILLE (45210)", value: "45210" },
  { label: "MESSAS (45190)", value: "45190" },
  { label: "MEUNG SUR LOIRE (45130)", value: "45130" },
  { label: "MEZIERES LEZ CLERY (45370)", value: "45370" },
  { label: "MEZIERES EN GATINAIS (45270)", value: "45270" },
  { label: "MIGNERES (45490)", value: "45490" },
  { label: "MIGNERETTE (45490)", value: "45490" },
  { label: "MONTARGIS (45200)", value: "45200" },
  { label: "MONTBARROIS (45340)", value: "45340" },
  { label: "MONTBOUY (45230)", value: "45230" },
  { label: "MONTCRESSON (45700)", value: "45700" },
  { label: "MONTEREAU (45260)", value: "45260" },
  { label: "MONTIGNY (45170)", value: "45170" },
  { label: "MONTLIARD (45340)", value: "45340" },
  { label: "MORMANT SUR VERNISSON (45700)", value: "45700" },
  { label: "MORVILLE EN BEAUCE (45300)", value: "45300" },
  { label: "LE MOULINET SUR SOLIN (45290)", value: "45290" },
  { label: "MOULON (45270)", value: "45270" },
  { label: "NANCRAY SUR RIMARDE (45340)", value: "45340" },
  { label: "NARGIS (45210)", value: "45210" },
  { label: "NESPLOY (45270)", value: "45270" },
  { label: "NEUVILLE AUX BOIS (45170)", value: "45170" },
  { label: "LA NEUVILLE SUR ESSONNE (45390)", value: "45390" },
  { label: "NEUVY EN SULLIAS (45510)", value: "45510" },
  { label: "NEVOY (45500)", value: "45500" },
  { label: "NIBELLE (45340)", value: "45340" },
  { label: "NOGENT SUR VERNISSON (45290)", value: "45290" },
  { label: "NOYERS (45260)", value: "45260" },
  { label: "OISON (45170)", value: "45170" },
  { label: "OLIVET (45160)", value: "45160" },
  { label: "ONDREVILLE SUR ESSONNE (45390)", value: "45390" },
  { label: "ORLEANS (45000)", value: "45000" },
  { label: "ORLEANS (45100)", value: "45100" },
  { label: "ORLEANS (45100)", value: "45100" },
  { label: "ORMES (45140)", value: "45140" },
  { label: "ORVILLE (45390)", value: "45390" },
  { label: "OUSSON SUR LOIRE (45250)", value: "45250" },
  { label: "OUSSOY EN GATINAIS (45290)", value: "45290" },
  { label: "OUTARVILLE (45480)", value: "45480" },
  { label: "OUTARVILLE (45480)", value: "45480" },
  { label: "OUTARVILLE (45480)", value: "45480" },
  { label: "OUTARVILLE (45480)", value: "45480" },
  { label: "OUTARVILLE (45480)", value: "45480" },
  { label: "OUVROUER LES CHAMPS (45150)", value: "45150" },
  { label: "OUZOUER DES CHAMPS (45290)", value: "45290" },
  { label: "OUZOUER SOUS BELLEGARDE (45270)", value: "45270" },
  { label: "OUZOUER SUR LOIRE (45570)", value: "45570" },
  { label: "OUZOUER SUR TREZEE (45250)", value: "45250" },
  { label: "PANNECIERES (45300)", value: "45300" },
  { label: "PANNES (45700)", value: "45700" },
  { label: "PATAY (45310)", value: "45310" },
  { label: "PAUCOURT (45200)", value: "45200" },
  { label: "PERS EN GATINAIS (45210)", value: "45210" },
  { label: "PIERREFITTE ES BOIS (45360)", value: "45360" },
  { label: "PITHIVIERS (45300)", value: "45300" },
  { label: "PITHIVIERS LE VIEIL (45300)", value: "45300" },
  { label: "PITHIVIERS LE VIEIL (45300)", value: "45300" },
  { label: "POILLY LEZ GIEN (45500)", value: "45500" },
  { label: "PREFONTAINES (45490)", value: "45490" },
  { label: "PRESNOY (45260)", value: "45260" },
  { label: "PRESSIGNY LES PINS (45290)", value: "45290" },
  { label: "PUISEAUX (45390)", value: "45390" },
  { label: "QUIERS SUR BEZONDE (45270)", value: "45270" },
  { label: "RAMOULU (45300)", value: "45300" },
  { label: "REBRECHIEN (45470)", value: "45470" },
  { label: "ROUVRAY STE CROIX (45310)", value: "45310" },
  { label: "ROUVRES ST JEAN (45300)", value: "45300" },
  { label: "ROZIERES EN BEAUCE (45130)", value: "45130" },
  { label: "ROZOY LE VIEIL (45210)", value: "45210" },
  { label: "RUAN (45410)", value: "45410" },
  { label: "ST AIGNAN LE JAILLARD (45600)", value: "45600" },
  { label: "ST AY (45130)", value: "45130" },
  { label: "ST BENOIT SUR LOIRE (45730)", value: "45730" },
  { label: "ST BRISSON SUR LOIRE (45500)", value: "45500" },
  { label: "ST CYR EN VAL (45590)", value: "45590" },
  { label: "ST DENIS DE L HOTEL (45550)", value: "45550" },
  { label: "ST DENIS EN VAL (45560)", value: "45560" },
  { label: "ST FIRMIN DES BOIS (45220)", value: "45220" },
  { label: "ST FIRMIN SUR LOIRE (45360)", value: "45360" },
  { label: "ST FLORENT (45600)", value: "45600" },
  { label: "STE GENEVIEVE DES BOIS (45230)", value: "45230" },
  { label: "ST GERMAIN DES PRES (45220)", value: "45220" },
  { label: "ST GONDON (45500)", value: "45500" },
  { label: "ST HILAIRE LES ANDRESIS (45320)", value: "45320" },
  { label: "ST HILAIRE ST MESMIN (45160)", value: "45160" },
  { label: "ST HILAIRE SUR PUISEAUX (45700)", value: "45700" },
  { label: "ST JEAN DE BRAYE (45800)", value: "45800" },
  { label: "ST JEAN DE LA RUELLE (45140)", value: "45140" },
  { label: "ST JEAN LE BLANC (45650)", value: "45650" },
  { label: "ST LOUP DES VIGNES (45340)", value: "45340" },
  { label: "ST LYE LA FORET (45170)", value: "45170" },
  { label: "ST MARTIN D ABBAT (45110)", value: "45110" },
  { label: "ST MARTIN SUR OCRE (45500)", value: "45500" },
  { label: "ST MAURICE SUR AVEYRON (45230)", value: "45230" },
  { label: "ST MAURICE SUR FESSARD (45700)", value: "45700" },
  { label: "ST MICHEL (45340)", value: "45340" },
  { label: "ST PERAVY LA COLOMBE (45310)", value: "45310" },
  { label: "ST PERE SUR LOIRE (45600)", value: "45600" },
  { label: "ST PRYVE ST MESMIN (45750)", value: "45750" },
  { label: "ST SIGISMOND (45310)", value: "45310" },
  { label: "SANDILLON (45640)", value: "45640" },
  { label: "SANTEAU (45170)", value: "45170" },
  { label: "SARAN (45770)", value: "45770" },
  { label: "SCEAUX DU GATINAIS (45490)", value: "45490" },
  { label: "SEICHEBRIERES (45530)", value: "45530" },
  { label: "LA SELLE EN HERMOY (45210)", value: "45210" },
  { label: "LA SELLE SUR LE BIED (45210)", value: "45210" },
  { label: "LA SELLE SUR LE BIED (45210)", value: "45210" },
  { label: "SEMOY (45400)", value: "45400" },
  { label: "SENNELY (45240)", value: "45240" },
  { label: "SERMAISES (45300)", value: "45300" },
  { label: "SIGLOY (45110)", value: "45110" },
  { label: "SOLTERRE (45700)", value: "45700" },
  { label: "SOUGY (45410)", value: "45410" },
  { label: "SULLY LA CHAPELLE (45450)", value: "45450" },
  { label: "SULLY SUR LOIRE (45600)", value: "45600" },
  { label: "SURY AUX BOIS (45530)", value: "45530" },
  { label: "TAVERS (45190)", value: "45190" },
  { label: "THIGNONVILLE (45300)", value: "45300" },
  { label: "THIMORY (45260)", value: "45260" },
  { label: "THORAILLES (45210)", value: "45210" },
  { label: "THOU (45420)", value: "45420" },
  { label: "TIGY (45510)", value: "45510" },
  { label: "TIVERNON (45170)", value: "45170" },
  { label: "TOURNOISIS (45310)", value: "45310" },
  { label: "TRAINOU (45470)", value: "45470" },
  { label: "TREILLES EN GATINAIS (45490)", value: "45490" },
  { label: "TRIGUERES (45220)", value: "45220" },
  { label: "TRINAY (45410)", value: "45410" },
  { label: "VANNES SUR COSSON (45510)", value: "45510" },
  { label: "VARENNES CHANGY (45290)", value: "45290" },
  { label: "VENNECY (45760)", value: "45760" },
  { label: "VIEILLES MAISONS SUR JOUDRY (45260)", value: "45260" },
  { label: "VIENNE EN VAL (45510)", value: "45510" },
  { label: "VIGLAIN (45600)", value: "45600" },
  { label: "VILLAMBLAIN (45310)", value: "45310" },
  { label: "VILLEMANDEUR (45700)", value: "45700" },
  { label: "VILLEMOUTIERS (45270)", value: "45270" },
  { label: "VILLEMURLIN (45600)", value: "45600" },
  { label: "VILLENEUVE SUR CONIE (45310)", value: "45310" },
  { label: "VILLEREAU (45170)", value: "45170" },
  { label: "VILLEVOQUES (45700)", value: "45700" },
  { label: "VILLORCEAU (45190)", value: "45190" },
  { label: "VIMORY (45700)", value: "45700" },
  { label: "VITRY AUX LOGES (45530)", value: "45530" },
  { label: "VRIGNY (45300)", value: "45300" },
  { label: "YEVRE LA VILLE (45300)", value: "45300" },
  { label: "YEVRE LA VILLE (45300)", value: "45300" },
  { label: "ALBAS (46140)", value: "46140" },
  { label: "ALBIAC (46500)", value: "46500" },
  { label: "ALVIGNAC (46500)", value: "46500" },
  { label: "ANGLARS (46120)", value: "46120" },
  { label: "ANGLARS JUILLAC (46140)", value: "46140" },
  { label: "ANGLARS NOZAC (46300)", value: "46300" },
  { label: "ARCAMBAL (46090)", value: "46090" },
  { label: "LES ARQUES (46250)", value: "46250" },
  { label: "ASSIER (46320)", value: "46320" },
  { label: "AUJOLS (46090)", value: "46090" },
  { label: "AUTOIRE (46400)", value: "46400" },
  { label: "AYNAC (46120)", value: "46120" },
  { label: "BACH (46230)", value: "46230" },
  { label: "BAGNAC SUR CELE (46270)", value: "46270" },
  { label: "BALADOU (46600)", value: "46600" },
  { label: "BANNES (46400)", value: "46400" },
  { label: "LE BASTIT (46500)", value: "46500" },
  { label: "BEAUREGARD (46260)", value: "46260" },
  { label: "BEDUER (46100)", value: "46100" },
  { label: "BELAYE (46140)", value: "46140" },
  { label: "BELFORT DU QUERCY (46230)", value: "46230" },
  { label: "BELMONT BRETENOUX (46130)", value: "46130" },
  { label: "BELMONT STE FOI (46230)", value: "46230" },
  { label: "BERGANTY (46090)", value: "46090" },
  { label: "BETAILLE (46110)", value: "46110" },
  { label: "BIARS SUR CERE (46130)", value: "46130" },
  { label: "BIO (46500)", value: "46500" },
  { label: "BLARS (46330)", value: "46330" },
  { label: "BOISSIERES (46150)", value: "46150" },
  { label: "PORTE DU QUERCY (46800)", value: "46800" },
  { label: "PORTE DU QUERCY (46800)", value: "46800" },
  { label: "PORTE DU QUERCY (46800)", value: "46800" },
  { label: "PORTE DU QUERCY (46800)", value: "46800" },
  { label: "LE BOURG (46120)", value: "46120" },
  { label: "BOUSSAC (46100)", value: "46100" },
  { label: "LE BOUYSSOU (46120)", value: "46120" },
  { label: "BOUZIES (46330)", value: "46330" },
  { label: "BRETENOUX (46130)", value: "46130" },
  { label: "BRENGUES (46320)", value: "46320" },
  { label: "CABRERETS (46330)", value: "46330" },
  { label: "CADRIEU (46160)", value: "46160" },
  { label: "CAHORS (46000)", value: "46000" },
  { label: "CAHUS (46130)", value: "46130" },
  { label: "CAILLAC (46140)", value: "46140" },
  { label: "CAJARC (46160)", value: "46160" },
  { label: "CALAMANE (46150)", value: "46150" },
  { label: "CALES (46350)", value: "46350" },
  { label: "CALVIGNAC (46160)", value: "46160" },
  { label: "CAMBAYRAC (46140)", value: "46140" },
  { label: "CAMBES (46100)", value: "46100" },
  { label: "CAMBOULIT (46100)", value: "46100" },
  { label: "CAMBURAT (46100)", value: "46100" },
  { label: "CANIAC DU CAUSSE (46240)", value: "46240" },
  { label: "CAPDENAC (46100)", value: "46100" },
  { label: "CARAYAC (46160)", value: "46160" },
  { label: "CARDAILLAC (46100)", value: "46100" },
  { label: "CARENNAC (46110)", value: "46110" },
  { label: "CARLUCET (46500)", value: "46500" },
  { label: "CARNAC ROUFFIAC (46140)", value: "46140" },
  { label: "CASSAGNES (46700)", value: "46700" },
  { label: "CASTELFRANC (46140)", value: "46140" },
  { label: "CASTELNAU MONTRATIER (46170)", value: "46170" },
  { label: "CASTELNAU MONTRATIER (46170)", value: "46170" },
  { label: "CATUS (46150)", value: "46150" },
  { label: "CAVAGNAC (46110)", value: "46110" },
  { label: "CAZALS (46250)", value: "46250" },
  { label: "CENEVIERES (46330)", value: "46330" },
  { label: "CEZAC (46170)", value: "46170" },
  { label: "CIEURAC (46230)", value: "46230" },
  { label: "CONCORES (46310)", value: "46310" },
  { label: "CONCOTS (46260)", value: "46260" },
  { label: "CONDAT (46110)", value: "46110" },
  { label: "CORN (46100)", value: "46100" },
  { label: "CORNAC (46130)", value: "46130" },
  { label: "COUZOU (46500)", value: "46500" },
  { label: "CRAS (46360)", value: "46360" },
  { label: "CRAYSSAC (46150)", value: "46150" },
  { label: "CREGOLS (46330)", value: "46330" },
  { label: "CREMPS (46230)", value: "46230" },
  { label: "CRESSENSAC SARRAZAC (46600)", value: "46600" },
  { label: "CRESSENSAC SARRAZAC (46600)", value: "46600" },
  { label: "CREYSSE (46600)", value: "46600" },
  { label: "CUZAC (46270)", value: "46270" },
  { label: "CUZANCE (46600)", value: "46600" },
  { label: "DEGAGNAC (46340)", value: "46340" },
  { label: "DOUELLE (46140)", value: "46140" },
  { label: "DURAVEL (46700)", value: "46700" },
  { label: "DURBANS (46320)", value: "46320" },
  { label: "ESCAMPS (46230)", value: "46230" },
  { label: "ESCLAUZELS (46090)", value: "46090" },
  { label: "ESPAGNAC STE EULALIE (46320)", value: "46320" },
  { label: "ESPEDAILLAC (46320)", value: "46320" },
  { label: "ESPERE (46090)", value: "46090" },
  { label: "ESPEYROUX (46120)", value: "46120" },
  { label: "ESTAL (46130)", value: "46130" },
  { label: "FAJOLES (46300)", value: "46300" },
  { label: "FAYCELLES (46100)", value: "46100" },
  { label: "FELZINS (46270)", value: "46270" },
  { label: "FIGEAC (46100)", value: "46100" },
  { label: "ST PAUL FLAUGNAC (46170)", value: "46170" },
  { label: "ST PAUL FLAUGNAC (46170)", value: "46170" },
  { label: "FLAUJAC GARE (46320)", value: "46320" },
  { label: "FLAUJAC POUJOLS (46090)", value: "46090" },
  { label: "FLOIRAC (46600)", value: "46600" },
  { label: "FLORESSAS (46700)", value: "46700" },
  { label: "FONS (46100)", value: "46100" },
  { label: "FONTANES (46230)", value: "46230" },
  { label: "FOURMAGNAC (46100)", value: "46100" },
  { label: "FRANCOULES (46090)", value: "46090" },
  { label: "FRAYSSINET (46310)", value: "46310" },
  { label: "FRAYSSINET LE GELAT (46250)", value: "46250" },
  { label: "FRAYSSINHES (46400)", value: "46400" },
  { label: "FRONTENAC (46160)", value: "46160" },
  { label: "GAGNAC SUR CERE (46130)", value: "46130" },
  { label: "GIGNAC (46600)", value: "46600" },
  { label: "GIGOUZAC (46150)", value: "46150" },
  { label: "GINDOU (46250)", value: "46250" },
  { label: "GINOUILLAC (46300)", value: "46300" },
  { label: "GINTRAC (46130)", value: "46130" },
  { label: "GIRAC (46130)", value: "46130" },
  { label: "GLANES (46130)", value: "46130" },
  { label: "GORSES (46210)", value: "46210" },
  { label: "GOUJOUNAC (46250)", value: "46250" },
  { label: "GOURDON (46300)", value: "46300" },
  { label: "GRAMAT (46500)", value: "46500" },
  { label: "GREALOU (46160)", value: "46160" },
  { label: "GREZELS (46700)", value: "46700" },
  { label: "GREZES (46320)", value: "46320" },
  { label: "ISSENDOLUS (46500)", value: "46500" },
  { label: "ISSEPTS (46320)", value: "46320" },
  { label: "LES JUNIES (46150)", value: "46150" },
  { label: "LABASTIDE DU HAUT MONT (46210)", value: "46210" },
  { label: "LABASTIDE DU VERT (46150)", value: "46150" },
  { label: "LABASTIDE MARNHAC (46090)", value: "46090" },
  { label: "COEUR DE CAUSSE (46240)", value: "46240" },
  { label: "COEUR DE CAUSSE (46240)", value: "46240" },
  { label: "COEUR DE CAUSSE (46240)", value: "46240" },
  { label: "COEUR DE CAUSSE (46240)", value: "46240" },
  { label: "COEUR DE CAUSSE (46240)", value: "46240" },
  { label: "LABATHUDE (46120)", value: "46120" },
  { label: "LABURGADE (46230)", value: "46230" },
  { label: "LACAPELLE CABANAC (46700)", value: "46700" },
  { label: "LACAPELLE MARIVAL (46120)", value: "46120" },
  { label: "LACAVE (46200)", value: "46200" },
  { label: "LACHAPELLE AUZAC (46200)", value: "46200" },
  { label: "LADIRAT (46400)", value: "46400" },
  { label: "LAGARDELLE (46220)", value: "46220" },
  { label: "LALBENQUE (46230)", value: "46230" },
  { label: "LAMAGDELAINE (46090)", value: "46090" },
  { label: "LAMOTHE CASSEL (46240)", value: "46240" },
  { label: "LAMOTHE FENELON (46350)", value: "46350" },
  { label: "LANZAC (46200)", value: "46200" },
  { label: "LARAMIERE (46260)", value: "46260" },
  { label: "LARNAGOL (46160)", value: "46160" },
  { label: "BELLEFONT LA RAUZE (46090)", value: "46090" },
  { label: "BELLEFONT LA RAUZE (46090)", value: "46090" },
  { label: "BELLEFONT LA RAUZE (46090)", value: "46090" },
  { label: "LARROQUE TOIRAC (46160)", value: "46160" },
  { label: "LATOUILLE LENTILLAC (46400)", value: "46400" },
  { label: "LATRONQUIERE (46210)", value: "46210" },
  { label: "LAURESSES (46210)", value: "46210" },
  { label: "LAUZES (46360)", value: "46360" },
  { label: "LAVAL DE CERE (46130)", value: "46130" },
  { label: "LAVERCANTIERE (46340)", value: "46340" },
  { label: "LAVERGNE (46500)", value: "46500" },
  { label: "LENTILLAC DU CAUSSE (46330)", value: "46330" },
  { label: "LENTILLAC ST BLAISE (46100)", value: "46100" },
  { label: "LEOBARD (46300)", value: "46300" },
  { label: "LEYME (46120)", value: "46120" },
  { label: "LHERM (46150)", value: "46150" },
  { label: "LHOSPITALET (46170)", value: "46170" },
  { label: "LIMOGNE EN QUERCY (46260)", value: "46260" },
  { label: "LINAC (46270)", value: "46270" },
  { label: "LISSAC ET MOURET (46100)", value: "46100" },
  { label: "LIVERNON (46320)", value: "46320" },
  { label: "LOUBRESSAC (46130)", value: "46130" },
  { label: "LOUPIAC (46350)", value: "46350" },
  { label: "LUGAGNAC (46260)", value: "46260" },
  { label: "LUNAN (46100)", value: "46100" },
  { label: "LUNEGARDE (46240)", value: "46240" },
  { label: "LUZECH (46140)", value: "46140" },
  { label: "MARCILHAC SUR CELE (46160)", value: "46160" },
  { label: "MARMINIAC (46250)", value: "46250" },
  { label: "MARTEL (46600)", value: "46600" },
  { label: "MASCLAT (46350)", value: "46350" },
  { label: "MAUROUX (46700)", value: "46700" },
  { label: "MAXOU (46090)", value: "46090" },
  { label: "MAYRINHAC LENTOUR (46500)", value: "46500" },
  { label: "MECHMONT (46150)", value: "46150" },
  { label: "MERCUES (46090)", value: "46090" },
  { label: "MEYRONNE (46200)", value: "46200" },
  { label: "MIERS (46500)", value: "46500" },
  { label: "MILHAC (46300)", value: "46300" },
  { label: "MOLIERES (46120)", value: "46120" },
  { label: "MONTAMEL (46310)", value: "46310" },
  { label: "LE MONTAT (46090)", value: "46090" },
  { label: "MONTBRUN (46160)", value: "46160" },
  { label: "MONTCABRIER (46700)", value: "46700" },
  { label: "MONTCLERA (46250)", value: "46250" },
  { label: "MONTCUQ EN QUERCY BLANC (46800)", value: "46800" },
  { label: "MONTCUQ EN QUERCY BLANC (46800)", value: "46800" },
  { label: "MONTCUQ EN QUERCY BLANC (46800)", value: "46800" },
  { label: "MONTCUQ EN QUERCY BLANC (46800)", value: "46800" },
  { label: "MONTCUQ EN QUERCY BLANC (46800)", value: "46800" },
  { label: "MONTDOUMERC (46230)", value: "46230" },
  { label: "MONTET ET BOUXAL (46210)", value: "46210" },
  { label: "MONTFAUCON (46240)", value: "46240" },
  { label: "MONTGESTY (46150)", value: "46150" },
  { label: "MONTLAUZUN (46800)", value: "46800" },
  { label: "MONTREDON (46270)", value: "46270" },
  { label: "MONTVALENT (46600)", value: "46600" },
  { label: "NADAILLAC DE ROUGE (46350)", value: "46350" },
  { label: "NADILLAC (46360)", value: "46360" },
  { label: "NUZEJOULS (46150)", value: "46150" },
  { label: "ORNIAC (46330)", value: "46330" },
  { label: "PADIRAC (46500)", value: "46500" },
  { label: "PARNAC (46140)", value: "46140" },
  { label: "PAYRAC (46350)", value: "46350" },
  { label: "PAYRIGNAC (46300)", value: "46300" },
  { label: "PERN (46170)", value: "46170" },
  { label: "PESCADOIRES (46220)", value: "46220" },
  { label: "PEYRILLES (46310)", value: "46310" },
  { label: "PINSAC (46200)", value: "46200" },
  { label: "PLANIOLES (46100)", value: "46100" },
  { label: "POMAREDE (46250)", value: "46250" },
  { label: "PONTCIRQ (46150)", value: "46150" },
  { label: "PRADINES (46090)", value: "46090" },
  { label: "PRAYSSAC (46220)", value: "46220" },
  { label: "PRENDEIGNES (46270)", value: "46270" },
  { label: "PROMILHANES (46260)", value: "46260" },
  { label: "PRUDHOMAT (46130)", value: "46130" },
  { label: "PUYBRUN (46130)", value: "46130" },
  { label: "PUYJOURDES (46260)", value: "46260" },
  { label: "PUY L EVEQUE (46700)", value: "46700" },
  { label: "LE VIGNON EN QUERCY (46110)", value: "46110" },
  { label: "LE VIGNON EN QUERCY (46600)", value: "46600" },
  { label: "QUISSAC EN QUERCY (46320)", value: "46320" },
  { label: "RAMPOUX (46340)", value: "46340" },
  { label: "REILHAC (46500)", value: "46500" },
  { label: "REILHAGUET (46350)", value: "46350" },
  { label: "REYREVIGNES (46320)", value: "46320" },
  { label: "RIGNAC (46500)", value: "46500" },
  { label: "LE ROC (46200)", value: "46200" },
  { label: "ROCAMADOUR (46500)", value: "46500" },
  { label: "ROUFFILHAC (46300)", value: "46300" },
  { label: "RUDELLE (46120)", value: "46120" },
  { label: "RUEYRES (46120)", value: "46120" },
  { label: "SABADEL LATRONQUIERE (46210)", value: "46210" },
  { label: "SABADEL LAUZES (46360)", value: "46360" },
  { label: "SAIGNES (46500)", value: "46500" },
  { label: "SAILLAC (46260)", value: "46260" },
  { label: "ST BRESSOU (46120)", value: "46120" },
  { label: "ST CAPRAIS (46250)", value: "46250" },
  { label: "ST CERE (46400)", value: "46400" },
  { label: "LES PECHS DU VERS (46360)", value: "46360" },
  { label: "LES PECHS DU VERS (46360)", value: "46360" },
  { label: "ST CHAMARAND (46310)", value: "46310" },
  { label: "ST CHELS (46160)", value: "46160" },
  { label: "ST CIRGUES (46210)", value: "46210" },
  { label: "ST CIRQ LAPOPIE (46330)", value: "46330" },
  { label: "ST CIRQ MADELON (46300)", value: "46300" },
  { label: "ST CIRQ SOUILLAGUET (46300)", value: "46300" },
  { label: "ST CLAIR (46300)", value: "46300" },
  { label: "STE COLOMBE (46120)", value: "46120" },
  { label: "LENDOU EN QUERCY (46800)", value: "46800" },
  { label: "LENDOU EN QUERCY (46800)", value: "46800" },
  { label: "LENDOU EN QUERCY (46800)", value: "46800" },
  { label: "BARGUELONNE EN QUERCY (46800)", value: "46800" },
  { label: "BARGUELONNE EN QUERCY (46800)", value: "46800" },
  { label: "BARGUELONNE EN QUERCY (46800)", value: "46800" },
  { label: "ST DENIS CATUS (46150)", value: "46150" },
  { label: "ST DENIS LES MARTEL (46600)", value: "46600" },
  { label: "ST FELIX (46100)", value: "46100" },
  { label: "ST GERMAIN DU BEL AIR (46310)", value: "46310" },
  { label: "ST GERY VERS (46090)", value: "46090" },
  { label: "ST GERY VERS (46330)", value: "46330" },
  { label: "ST HILAIRE (46210)", value: "46210" },
  { label: "ST JEAN DE LAUR (46260)", value: "46260" },
  { label: "ST JEAN LESPINASSE (46400)", value: "46400" },
  { label: "ST JEAN MIRABEL (46270)", value: "46270" },
  { label: "ST LAURENT LES TOURS (46400)", value: "46400" },
  { label: "ST MARTIN LABOUVAL (46330)", value: "46330" },
  { label: "ST MARTIN LE REDON (46700)", value: "46700" },
  { label: "ST MAURICE EN QUERCY (46120)", value: "46120" },
  { label: "ST MEDARD (46150)", value: "46150" },
  { label: "ST MEDARD DE PRESQUE (46400)", value: "46400" },
  { label: "ST MEDARD NICOURBY (46210)", value: "46210" },
  { label: "ST MICHEL DE BANNIERES (46110)", value: "46110" },
  { label: "ST MICHEL LOUBEJOU (46130)", value: "46130" },
  { label: "ST PAUL DE VERN (46400)", value: "46400" },
  { label: "ST PERDOUX (46100)", value: "46100" },
  { label: "ST PIERRE TOIRAC (46160)", value: "46160" },
  { label: "ST PROJET (46300)", value: "46300" },
  { label: "ST SIMON (46320)", value: "46320" },
  { label: "ST SOZY (46200)", value: "46200" },
  { label: "ST SULPICE (46160)", value: "46160" },
  { label: "ST VINCENT DU PENDIT (46400)", value: "46400" },
  { label: "ST VINCENT RIVE D OLT (46140)", value: "46140" },
  { label: "SALVIAC (46340)", value: "46340" },
  { label: "SAULIAC SUR CELE (46330)", value: "46330" },
  { label: "SAUZET (46140)", value: "46140" },
  { label: "SENAILLAC LATRONQUIERE (46210)", value: "46210" },
  { label: "SENAILLAC LAUZES (46360)", value: "46360" },
  { label: "SENIERGUES (46240)", value: "46240" },
  { label: "SERIGNAC (46700)", value: "46700" },
  { label: "SONAC (46320)", value: "46320" },
  { label: "SOTURAC (46700)", value: "46700" },
  { label: "SOUCIRAC (46300)", value: "46300" },
  { label: "SOUILLAC (46200)", value: "46200" },
  { label: "SOULOMES (46240)", value: "46240" },
  { label: "SOUSCEYRAC EN QUERCY (46190)", value: "46190" },
  { label: "SOUSCEYRAC EN QUERCY (46190)", value: "46190" },
  { label: "SOUSCEYRAC EN QUERCY (46190)", value: "46190" },
  { label: "SOUSCEYRAC EN QUERCY (46190)", value: "46190" },
  { label: "SOUSCEYRAC EN QUERCY (46190)", value: "46190" },
  { label: "STRENQUELS (46110)", value: "46110" },
  { label: "TAURIAC (46130)", value: "46130" },
  { label: "TERROU (46120)", value: "46120" },
  { label: "TEYSSIEU (46190)", value: "46190" },
  { label: "THEDIRAC (46150)", value: "46150" },
  { label: "THEGRA (46500)", value: "46500" },
  { label: "THEMINES (46120)", value: "46120" },
  { label: "THEMINETTES (46120)", value: "46120" },
  { label: "TOUR DE FAURE (46330)", value: "46330" },
  { label: "TOUZAC (46700)", value: "46700" },
  { label: "TRESPOUX RASSIELS (46090)", value: "46090" },
  { label: "USSEL (46240)", value: "46240" },
  { label: "UZECH (46310)", value: "46310" },
  { label: "VARAIRE (46260)", value: "46260" },
  { label: "VAYLATS (46230)", value: "46230" },
  { label: "VAYRAC (46110)", value: "46110" },
  { label: "VIAZAC (46100)", value: "46100" },
  { label: "VIDAILLAC (46260)", value: "46260" },
  { label: "LE VIGAN (46300)", value: "46300" },
  { label: "VILLESEQUE (46090)", value: "46090" },
  { label: "VIRE SUR LOT (46700)", value: "46700" },
  { label: "MAYRAC (46200)", value: "46200" },
  { label: "BESSONIES (46210)", value: "46210" },
  { label: "ST JEAN LAGINESTE (46400)", value: "46400" },
  { label: "ST PIERRE LAFEUILLE (46090)", value: "46090" },
  { label: "AGEN (47000)", value: "47000" },
  { label: "AGME (47350)", value: "47350" },
  { label: "AGNAC (47800)", value: "47800" },
  { label: "AIGUILLON (47190)", value: "47190" },
  { label: "ALLEMANS DU DROPT (47800)", value: "47800" },
  { label: "ALLEZ ET CAZENEUVE (47110)", value: "47110" },
  { label: "ALLONS (47420)", value: "47420" },
  { label: "AMBRUS (47160)", value: "47160" },
  { label: "ANDIRAN (47170)", value: "47170" },
  { label: "ANTAGNAC (47700)", value: "47700" },
  { label: "ANTHE (47370)", value: "47370" },
  { label: "ANZEX (47700)", value: "47700" },
  { label: "ARGENTON (47250)", value: "47250" },
  { label: "ARMILLAC (47800)", value: "47800" },
  { label: "ASTAFFORT (47220)", value: "47220" },
  { label: "AUBIAC (47310)", value: "47310" },
  { label: "AURADOU (47140)", value: "47140" },
  { label: "AURIAC SUR DROPT (47120)", value: "47120" },
  { label: "BAJAMONT (47480)", value: "47480" },
  { label: "BALEYSSAGUES (47120)", value: "47120" },
  { label: "BARBASTE (47230)", value: "47230" },
  { label: "BAZENS (47130)", value: "47130" },
  { label: "BEAUGAS (47290)", value: "47290" },
  { label: "BEAUPUY (47200)", value: "47200" },
  { label: "BEAUVILLE (47470)", value: "47470" },
  { label: "BEAUZIAC (47700)", value: "47700" },
  { label: "BIAS (47300)", value: "47300" },
  { label: "BIRAC SUR TREC (47200)", value: "47200" },
  { label: "BLANQUEFORT SUR BRIOLANCE (47500)", value: "47500" },
  { label: "BLAYMONT (47470)", value: "47470" },
  { label: "BOE (47550)", value: "47550" },
  { label: "BON ENCONTRE (47240)", value: "47240" },
  { label: "BOUDY DE BEAUREGARD (47290)", value: "47290" },
  { label: "BOUGLON (47250)", value: "47250" },
  { label: "BOURGOUGNAGUE (47410)", value: "47410" },
  { label: "BOURLENS (47370)", value: "47370" },
  { label: "BOURNEL (47210)", value: "47210" },
  { label: "BOURRAN (47320)", value: "47320" },
  { label: "BOUSSES (47420)", value: "47420" },
  { label: "BRAX (47310)", value: "47310" },
  { label: "BRUCH (47130)", value: "47130" },
  { label: "BRUGNAC (47260)", value: "47260" },
  { label: "BUZET SUR BAISE (47160)", value: "47160" },
  { label: "CAHUZAC (47330)", value: "47330" },
  { label: "CALIGNAC (47600)", value: "47600" },
  { label: "CALONGES (47430)", value: "47430" },
  { label: "CAMBES (47350)", value: "47350" },
  { label: "CANCON (47290)", value: "47290" },
  { label: "CASSENEUIL (47440)", value: "47440" },
  { label: "CASSIGNAS (47340)", value: "47340" },
  { label: "CASTELCULIER (47240)", value: "47240" },
  { label: "CASTELJALOUX (47700)", value: "47700" },
  { label: "CASTELLA (47340)", value: "47340" },
  { label: "CASTELMORON SUR LOT (47260)", value: "47260" },
  { label: "CASTELNAUD DE GRATECAMBE (47290)", value: "47290" },
  { label: "CASTELNAU SUR GUPIE (47180)", value: "47180" },
  { label: "CASTILLONNES (47330)", value: "47330" },
  { label: "CAUBEYRES (47160)", value: "47160" },
  { label: "CAUBON ST SAUVEUR (47120)", value: "47120" },
  { label: "CAUDECOSTE (47220)", value: "47220" },
  { label: "CAUMONT SUR GARONNE (47430)", value: "47430" },
  { label: "CAUZAC (47470)", value: "47470" },
  { label: "CAVARC (47330)", value: "47330" },
  { label: "CAZIDEROQUE (47370)", value: "47370" },
  { label: "CLAIRAC (47320)", value: "47320" },
  { label: "CLERMONT DESSOUS (47130)", value: "47130" },
  { label: "CLERMONT SOUBIRAN (47270)", value: "47270" },
  { label: "COCUMONT (47250)", value: "47250" },
  { label: "COLAYRAC ST CIRQ (47450)", value: "47450" },
  { label: "CONDEZAYGUES (47500)", value: "47500" },
  { label: "COULX (47260)", value: "47260" },
  { label: "COURBIAC (47370)", value: "47370" },
  { label: "COURS (47360)", value: "47360" },
  { label: "COUTHURES SUR GARONNE (47180)", value: "47180" },
  { label: "LA CROIX BLANCHE (47340)", value: "47340" },
  { label: "CUQ (47220)", value: "47220" },
  { label: "CUZORN (47500)", value: "47500" },
  { label: "DAMAZAN (47160)", value: "47160" },
  { label: "DAUSSE (47140)", value: "47140" },
  { label: "DEVILLAC (47210)", value: "47210" },
  { label: "DOLMAYRAC (47110)", value: "47110" },
  { label: "DONDAS (47470)", value: "47470" },
  { label: "DOUDRAC (47210)", value: "47210" },
  { label: "DOUZAINS (47330)", value: "47330" },
  { label: "DURANCE (47420)", value: "47420" },
  { label: "DURAS (47120)", value: "47120" },
  { label: "ENGAYRAC (47470)", value: "47470" },
  { label: "ESCASSEFORT (47350)", value: "47350" },
  { label: "ESCLOTTES (47120)", value: "47120" },
  { label: "ESPIENS (47600)", value: "47600" },
  { label: "ESTILLAC (47310)", value: "47310" },
  { label: "FALS (47220)", value: "47220" },
  { label: "FARGUES SUR OURBISE (47700)", value: "47700" },
  { label: "FAUGUEROLLES (47400)", value: "47400" },
  { label: "FAUILLET (47400)", value: "47400" },
  { label: "FERRENSAC (47330)", value: "47330" },
  { label: "FEUGAROLLES (47230)", value: "47230" },
  { label: "FIEUX (47600)", value: "47600" },
  { label: "FONGRAVE (47260)", value: "47260" },
  { label: "FOULAYRONNES (47510)", value: "47510" },
  { label: "FOURQUES SUR GARONNE (47200)", value: "47200" },
  { label: "FRANCESCAS (47600)", value: "47600" },
  { label: "FRECHOU (47600)", value: "47600" },
  { label: "FREGIMONT (47360)", value: "47360" },
  { label: "FRESPECH (47140)", value: "47140" },
  { label: "FUMEL (47500)", value: "47500" },
  { label: "GALAPIAN (47190)", value: "47190" },
  { label: "GAUJAC (47200)", value: "47200" },
  { label: "GAVAUDUN (47150)", value: "47150" },
  { label: "GONTAUD DE NOGARET (47400)", value: "47400" },
  { label: "GRANGES SUR LOT (47260)", value: "47260" },
  { label: "GRATELOUP ST GAYRAND (47400)", value: "47400" },
  { label: "GRATELOUP ST GAYRAND (47400)", value: "47400" },
  { label: "GRAYSSAS (47270)", value: "47270" },
  { label: "GREZET CAVAGNAN (47250)", value: "47250" },
  { label: "GUERIN (47250)", value: "47250" },
  { label: "HAUTEFAGE LA TOUR (47340)", value: "47340" },
  { label: "HAUTESVIGNES (47400)", value: "47400" },
  { label: "HOUEILLES (47420)", value: "47420" },
  { label: "JUSIX (47180)", value: "47180" },
  { label: "LABASTIDE CASTEL AMOUROUX (47250)", value: "47250" },
  { label: "LABRETONIE (47350)", value: "47350" },
  { label: "LACAPELLE BIRON (47150)", value: "47150" },
  { label: "LACAUSSADE (47150)", value: "47150" },
  { label: "LACEPEDE (47360)", value: "47360" },
  { label: "LACHAPELLE (47350)", value: "47350" },
  { label: "LAFITTE SUR LOT (47320)", value: "47320" },
  { label: "LAFOX (47240)", value: "47240" },
  { label: "LAGARRIGUE (47190)", value: "47190" },
  { label: "LAGRUERE (47400)", value: "47400" },
  { label: "LAGUPIE (47180)", value: "47180" },
  { label: "LALANDUSSE (47330)", value: "47330" },
  { label: "LAMONTJOIE (47310)", value: "47310" },
  { label: "LANNES (47170)", value: "47170" },
  { label: "LANNES (47170)", value: "47170" },
  { label: "LAPARADE (47260)", value: "47260" },
  { label: "LAPERCHE (47800)", value: "47800" },
  { label: "LAPLUME (47310)", value: "47310" },
  { label: "LAROQUE TIMBAUT (47340)", value: "47340" },
  { label: "LASSERRE (47600)", value: "47600" },
  { label: "LAUGNAC (47360)", value: "47360" },
  { label: "LAUSSOU (47150)", value: "47150" },
  { label: "LAUZUN (47410)", value: "47410" },
  { label: "LAVARDAC (47230)", value: "47230" },
  { label: "LAVERGNE (47800)", value: "47800" },
  { label: "LAYRAC (47390)", value: "47390" },
  { label: "LEDAT (47300)", value: "47300" },
  { label: "LEVIGNAC DE GUYENNE (47120)", value: "47120" },
  { label: "LEYRITZ MONCASSIN (47700)", value: "47700" },
  { label: "LONGUEVILLE (47200)", value: "47200" },
  { label: "LOUBES BERNAC (47120)", value: "47120" },
  { label: "LOUGRATTE (47290)", value: "47290" },
  { label: "LUSIGNAN PETIT (47360)", value: "47360" },
  { label: "MADAILLAN (47360)", value: "47360" },
  { label: "MARCELLUS (47200)", value: "47200" },
  { label: "MARMANDE (47200)", value: "47200" },
  { label: "MARMONT PACHAS (47220)", value: "47220" },
  { label: "LE MAS D AGENAIS (47430)", value: "47430" },
  { label: "MASQUIERES (47370)", value: "47370" },
  { label: "MASSELS (47140)", value: "47140" },
  { label: "MASSOULES (47140)", value: "47140" },
  { label: "MAUVEZIN SUR GUPIE (47200)", value: "47200" },
  { label: "MAZIERES NARESSE (47210)", value: "47210" },
  { label: "MEILHAN SUR GARONNE (47180)", value: "47180" },
  { label: "MEZIN (47170)", value: "47170" },
  { label: "MIRAMONT DE GUYENNE (47800)", value: "47800" },
  { label: "MOIRAX (47310)", value: "47310" },
  { label: "MONBAHUS (47290)", value: "47290" },
  { label: "MONBALEN (47340)", value: "47340" },
  { label: "MONCAUT (47310)", value: "47310" },
  { label: "MONCLAR (47380)", value: "47380" },
  { label: "MONCRABEAU (47600)", value: "47600" },
  { label: "MONFLANQUIN (47150)", value: "47150" },
  { label: "MONTGAILLARD EN ALBRET (47230)", value: "47230" },
  { label: "MONHEURT (47160)", value: "47160" },
  { label: "MONSEGUR (47150)", value: "47150" },
  { label: "MONSEMPRON LIBOS (47500)", value: "47500" },
  { label: "MONTAGNAC SUR AUVIGNON (47600)", value: "47600" },
  { label: "MONTAGNAC SUR LEDE (47150)", value: "47150" },
  { label: "MONTASTRUC (47380)", value: "47380" },
  { label: "MONTAURIOL (47330)", value: "47330" },
  { label: "MONTAUT (47210)", value: "47210" },
  { label: "MONTAYRAL (47500)", value: "47500" },
  { label: "MONTESQUIEU (47130)", value: "47130" },
  { label: "MONTETON (47120)", value: "47120" },
  { label: "MONTIGNAC DE LAUZUN (47800)", value: "47800" },
  { label: "MONTIGNAC TOUPINERIE (47350)", value: "47350" },
  { label: "MONTPEZAT (47360)", value: "47360" },
  { label: "MONTPOUILLAN (47200)", value: "47200" },
  { label: "MONVIEL (47290)", value: "47290" },
  { label: "MOULINET (47290)", value: "47290" },
  { label: "MOUSTIER (47800)", value: "47800" },
  { label: "NERAC (47600)", value: "47600" },
  { label: "NICOLE (47190)", value: "47190" },
  { label: "NOMDIEU (47600)", value: "47600" },
  { label: "PAILLOLES (47440)", value: "47440" },
  { label: "PARDAILLAN (47120)", value: "47120" },
  { label: "PARRANQUET (47210)", value: "47210" },
  { label: "LE PASSAGE (47520)", value: "47520" },
  { label: "PAULHIAC (47150)", value: "47150" },
  { label: "PENNE D AGENAIS (47140)", value: "47140" },
  { label: "PEYRIERE (47350)", value: "47350" },
  { label: "PINDERES (47700)", value: "47700" },
  { label: "PINEL HAUTERIVE (47380)", value: "47380" },
  { label: "PINEL HAUTERIVE (47380)", value: "47380" },
  { label: "POMPIEY (47230)", value: "47230" },
  { label: "POMPOGNE (47420)", value: "47420" },
  { label: "PONT DU CASSE (47480)", value: "47480" },
  { label: "PORT STE MARIE (47130)", value: "47130" },
  { label: "POUDENAS (47170)", value: "47170" },
  { label: "POUSSIGNAC (47700)", value: "47700" },
  { label: "PRAYSSAS (47360)", value: "47360" },
  { label: "PUCH D AGENAIS (47160)", value: "47160" },
  { label: "PUJOLS (47300)", value: "47300" },
  { label: "PUYMICLAN (47350)", value: "47350" },
  { label: "PUYMIROL (47270)", value: "47270" },
  { label: "PUYSSERAMPION (47800)", value: "47800" },
  { label: "RAYET (47210)", value: "47210" },
  { label: "RAZIMET (47160)", value: "47160" },
  { label: "REAUP LISSE (47170)", value: "47170" },
  { label: "REAUP LISSE (47170)", value: "47170" },
  { label: "LA REUNION (47700)", value: "47700" },
  { label: "RIVES (47210)", value: "47210" },
  { label: "ROMESTAING (47250)", value: "47250" },
  { label: "ROQUEFORT (47310)", value: "47310" },
  { label: "ROUMAGNE (47800)", value: "47800" },
  { label: "RUFFIAC (47700)", value: "47700" },
  { label: "ST ANTOINE DE FICALBA (47340)", value: "47340" },
  { label: "ST ASTIER (47120)", value: "47120" },
  { label: "ST AUBIN (47150)", value: "47150" },
  { label: "ST AVIT (47350)", value: "47350" },
  { label: "ST BARTHELEMY D AGENAIS (47350)", value: "47350" },
  { label: "STE BAZEILLE (47180)", value: "47180" },
  { label: "ST CAPRAIS DE LERM (47270)", value: "47270" },
  { label: "ST COLOMB DE LAUZUN (47410)", value: "47410" },
  { label: "STE COLOMBE DE DURAS (47120)", value: "47120" },
  { label: "STE COLOMBE DE VILLENEUVE (47300)", value: "47300" },
  { label: "STE COLOMBE EN BRUILHOIS (47310)", value: "47310" },
  { label: "ST ETIENNE DE FOUGERES (47380)", value: "47380" },
  { label: "ST ETIENNE DE VILLEREAL (47210)", value: "47210" },
  { label: "ST EUTROPE DE BORN (47210)", value: "47210" },
  { label: "ST FRONT SUR LEMANCE (47500)", value: "47500" },
  { label: "STE GEMME MARTAILLAC (47250)", value: "47250" },
  { label: "ST GERAUD (47120)", value: "47120" },
  { label: "ST HILAIRE DE LUSIGNAN (47450)", value: "47450" },
  { label: "ST JEAN DE DURAS (47120)", value: "47120" },
  { label: "ST JEAN DE THURAC (47270)", value: "47270" },
  { label: "ST LAURENT (47130)", value: "47130" },
  { label: "ST LEGER (47160)", value: "47160" },
  { label: "ST LEON (47160)", value: "47160" },
  { label: "STE LIVRADE SUR LOT (47110)", value: "47110" },
  { label: "STE MARTHE (47430)", value: "47430" },
  { label: "ST MARTIN CURTON (47700)", value: "47700" },
  { label: "ST MARTIN DE BEAUVILLE (47270)", value: "47270" },
  { label: "ST MARTIN DE VILLEREAL (47210)", value: "47210" },
  { label: "ST MARTIN PETIT (47180)", value: "47180" },
  { label: "STE MAURE DE PEYRIAC (47170)", value: "47170" },
  { label: "ST MAURICE DE LESTAPEL (47290)", value: "47290" },
  { label: "ST MAURIN (47270)", value: "47270" },
  { label: "ST NICOLAS DE LA BALERME (47220)", value: "47220" },
  { label: "ST PARDOUX DU BREUIL (47200)", value: "47200" },
  { label: "ST PARDOUX ISAAC (47800)", value: "47800" },
  { label: "ST PASTOUR (47290)", value: "47290" },
  { label: "ST PE ST SIMON (47170)", value: "47170" },
  { label: "ST PIERRE DE BUZET (47160)", value: "47160" },
  { label: "ST PIERRE DE CLAIRAC (47270)", value: "47270" },
  { label: "ST PIERRE SUR DROPT (47120)", value: "47120" },
  { label: "ST QUENTIN DU DROPT (47330)", value: "47330" },
  { label: "ST ROBERT (47340)", value: "47340" },
  { label: "ST ROMAIN LE NOBLE (47270)", value: "47270" },
  { label: "ST SALVY (47360)", value: "47360" },
  { label: "ST SARDOS (47360)", value: "47360" },
  { label: "ST SAUVEUR DE MEILHAN (47180)", value: "47180" },
  { label: "ST SERNIN (47120)", value: "47120" },
  { label: "ST SIXTE (47220)", value: "47220" },
  { label: "ST SYLVESTRE SUR LOT (47140)", value: "47140" },
  { label: "ST URCISSE (47270)", value: "47270" },
  { label: "ST VINCENT DE LAMONTJOIE (47310)", value: "47310" },
  { label: "ST VITE (47500)", value: "47500" },
  { label: "SALLES (47150)", value: "47150" },
  { label: "SAMAZAN (47250)", value: "47250" },
  { label: "SAUMEJAN (47420)", value: "47420" },
  { label: "SAUMONT (47600)", value: "47600" },
  { label: "SAUVAGNAS (47340)", value: "47340" },
  { label: "LA SAUVETAT DE SAVERES (47270)", value: "47270" },
  { label: "LA SAUVETAT DU DROPT (47800)", value: "47800" },
  { label: "LA SAUVETAT SUR LEDE (47150)", value: "47150" },
  { label: "SAUVETERRE LA LEMANCE (47500)", value: "47500" },
  { label: "SAUVETERRE ST DENIS (47220)", value: "47220" },
  { label: "SAVIGNAC DE DURAS (47120)", value: "47120" },
  { label: "SAVIGNAC SUR LEYZE (47150)", value: "47150" },
  { label: "SEGALAS (47410)", value: "47410" },
  { label: "SEMBAS (47360)", value: "47360" },
  { label: "SENESTIS (47430)", value: "47430" },
  { label: "SERIGNAC PEBOUDOU (47410)", value: "47410" },
  { label: "SERIGNAC SUR GARONNE (47310)", value: "47310" },
  { label: "SEYCHES (47350)", value: "47350" },
  { label: "SOS (47170)", value: "47170" },
  { label: "SOS (47170)", value: "47170" },
  { label: "SOS (47170)", value: "47170" },
  { label: "SOUMENSAC (47120)", value: "47120" },
  { label: "TAILLEBOURG (47200)", value: "47200" },
  { label: "TAYRAC (47270)", value: "47270" },
  { label: "LE TEMPLE SUR LOT (47110)", value: "47110" },
  { label: "THEZAC (47370)", value: "47370" },
  { label: "THOUARS SUR GARONNE (47230)", value: "47230" },
  { label: "TOMBEBOEUF (47380)", value: "47380" },
  { label: "TONNEINS (47400)", value: "47400" },
  { label: "TOURLIAC (47210)", value: "47210" },
  { label: "TOURNON D AGENAIS (47370)", value: "47370" },
  { label: "TOURTRES (47380)", value: "47380" },
  { label: "TREMONS (47140)", value: "47140" },
  { label: "TRENTELS (47140)", value: "47140" },
  { label: "VARES (47400)", value: "47400" },
  { label: "VERTEUIL D AGENAIS (47260)", value: "47260" },
  { label: "VIANNE (47230)", value: "47230" },
  { label: "VILLEBRAMAR (47380)", value: "47380" },
  { label: "VILLEFRANCHE DU QUEYRAN (47160)", value: "47160" },
  { label: "VILLENEUVE DE DURAS (47120)", value: "47120" },
  { label: "VILLENEUVE SUR LOT (47300)", value: "47300" },
  { label: "VILLEREAL (47210)", value: "47210" },
  { label: "VILLETON (47400)", value: "47400" },
  { label: "VIRAZEIL (47200)", value: "47200" },
  { label: "XAINTRAILLES (47230)", value: "47230" },
  { label: "ST GEORGES (47370)", value: "47370" },
  { label: "ALBARET LE COMTAL (48310)", value: "48310" },
  { label: "ALBARET STE MARIE (48200)", value: "48200" },
  { label: "ALBARET STE MARIE (48200)", value: "48200" },
  { label: "ALLENC (48190)", value: "48190" },
  { label: "ALTIER (48800)", value: "48800" },
  { label: "ANTRENAS (48100)", value: "48100" },
  { label: "ARZENC D APCHER (48310)", value: "48310" },
  { label: "ARZENC DE RANDON (48170)", value: "48170" },
  { label: "PEYRE EN AUBRAC (48130)", value: "48130" },
  { label: "PEYRE EN AUBRAC (48130)", value: "48130" },
  { label: "PEYRE EN AUBRAC (48130)", value: "48130" },
  { label: "PEYRE EN AUBRAC (48130)", value: "48130" },
  { label: "PEYRE EN AUBRAC (48130)", value: "48130" },
  { label: "PEYRE EN AUBRAC (48130)", value: "48130" },
  { label: "AUROUX (48600)", value: "48600" },
  { label: "LES MONTS VERTS (48200)", value: "48200" },
  { label: "LES MONTS VERTS (48200)", value: "48200" },
  { label: "LES MONTS VERTS (48200)", value: "48200" },
  { label: "BADAROUX (48000)", value: "48000" },
  { label: "PIED DE BORNE (48800)", value: "48800" },
  { label: "BALSIEGES (48000)", value: "48000" },
  { label: "BANASSAC CANILHAC (48500)", value: "48500" },
  { label: "BANASSAC CANILHAC (48500)", value: "48500" },
  { label: "BARJAC (48000)", value: "48000" },
  { label: "BARRE DES CEVENNES (48400)", value: "48400" },
  { label: "BASSURELS (48400)", value: "48400" },
  { label: "LA BASTIDE PUYLAURENT (48250)", value: "48250" },
  { label: "LES BESSONS (48200)", value: "48200" },
  { label: "BLAVIGNAC (48200)", value: "48200" },
  { label: "MONT LOZERE ET GOULET (48170)", value: "48170" },
  { label: "MONT LOZERE ET GOULET (48190)", value: "48190" },
  { label: "MONT LOZERE ET GOULET (48190)", value: "48190" },
  { label: "MONT LOZERE ET GOULET (48190)", value: "48190" },
  { label: "MONT LOZERE ET GOULET (48190)", value: "48190" },
  { label: "MONT LOZERE ET GOULET (48250)", value: "48250" },
  { label: "LES BONDONS (48400)", value: "48400" },
  { label: "LE BORN (48000)", value: "48000" },
  { label: "BRENOUX (48000)", value: "48000" },
  { label: "BRION (48310)", value: "48310" },
  { label: "BRION (48310)", value: "48310" },
  { label: "LE BUISSON (48100)", value: "48100" },
  { label: "LA CANOURGUE (48500)", value: "48500" },
  { label: "LA CANOURGUE (48500)", value: "48500" },
  { label: "LA CANOURGUE (48500)", value: "48500" },
  { label: "LA CANOURGUE (48500)", value: "48500" },
  { label: "CASSAGNAS (48400)", value: "48400" },
  { label: "CHADENET (48190)", value: "48190" },
  { label: "BEL AIR VAL D ANCE (48600)", value: "48600" },
  { label: "BEL AIR VAL D ANCE (48600)", value: "48600" },
  { label: "CHANAC (48230)", value: "48230" },
  { label: "CHANAC (48230)", value: "48230" },
  { label: "CHASTANIER (48300)", value: "48300" },
  { label: "CHASTEL NOUVEL (48000)", value: "48000" },
  { label: "CHATEAUNEUF DE RANDON (48170)", value: "48170" },
  { label: "CHAUCHAILLES (48310)", value: "48310" },
  { label: "CHAUDEYRAC (48170)", value: "48170" },
  { label: "CHAULHAC (48140)", value: "48140" },
  { label: "CHEYLARD L EVEQUE (48300)", value: "48300" },
  { label: "BEDOUES COCURES (48400)", value: "48400" },
  { label: "BEDOUES COCURES (48400)", value: "48400" },
  { label: "LE COLLET DE DEZE (48160)", value: "48160" },
  { label: "CUBIERES (48190)", value: "48190" },
  { label: "CUBIERETTES (48190)", value: "48190" },
  { label: "CULTURES (48230)", value: "48230" },
  { label: "ESCLANEDES (48230)", value: "48230" },
  { label: "LA FAGE MONTIVERNOUX (48310)", value: "48310" },
  { label: "LA FAGE ST JULIEN (48200)", value: "48200" },
  { label: "FLORAC TROIS RIVIERES (48400)", value: "48400" },
  { label: "FLORAC TROIS RIVIERES (48400)", value: "48400" },
  { label: "FONTANS (48700)", value: "48700" },
  { label: "FOURNELS (48310)", value: "48310" },
  { label: "FRAISSINET DE FOURQUES (48400)", value: "48400" },
  { label: "GABRIAC (48110)", value: "48110" },
  { label: "GABRIAS (48100)", value: "48100" },
  { label: "GATUZIERES (48150)", value: "48150" },
  { label: "GRANDRIEU (48600)", value: "48600" },
  { label: "GRANDVALS (48260)", value: "48260" },
  { label: "GREZES (48100)", value: "48100" },
  { label: "LES HERMAUX (48340)", value: "48340" },
  { label: "HURES LA PARADE (48150)", value: "48150" },
  { label: "ISPAGNAC (48320)", value: "48320" },
  { label: "JULIANGES (48140)", value: "48140" },
  { label: "LAJO (48120)", value: "48120" },
  { label: "LANGOGNE (48300)", value: "48300" },
  { label: "LANUEJOLS (48000)", value: "48000" },
  { label: "LAUBERT (48170)", value: "48170" },
  { label: "LES LAUBIES (48700)", value: "48700" },
  { label: "LAVAL DU TARN (48500)", value: "48500" },
  { label: "LUC (48250)", value: "48250" },
  { label: "PRINSUEJOLS MALBOUZON (48100)", value: "48100" },
  { label: "PRINSUEJOLS MALBOUZON (48270)", value: "48270" },
  { label: "LA MALENE (48210)", value: "48210" },
  { label: "LE MALZIEU FORAIN (48140)", value: "48140" },
  { label: "LE MALZIEU VILLE (48140)", value: "48140" },
  { label: "MARCHASTEL (48260)", value: "48260" },
  { label: "MARVEJOLS (48100)", value: "48100" },
  { label: "MASSEGROS CAUSSES GORGES (48210)", value: "48210" },
  { label: "MASSEGROS CAUSSES GORGES (48500)", value: "48500" },
  { label: "MASSEGROS CAUSSES GORGES (48500)", value: "48500" },
  { label: "MASSEGROS CAUSSES GORGES (48500)", value: "48500" },
  { label: "MASSEGROS CAUSSES GORGES (48500)", value: "48500" },
  { label: "MENDE (48000)", value: "48000" },
  { label: "MEYRUEIS (48150)", value: "48150" },
  { label: "MOISSAC VALLEE FRANCAISE (48110)", value: "48110" },
  { label: "MOLEZON (48110)", value: "48110" },
  { label: "BOURGS SUR COLAGNE (48100)", value: "48100" },
  { label: "BOURGS SUR COLAGNE (48100)", value: "48100" },
  { label: "BOURGS SUR COLAGNE (48100)", value: "48100" },
  { label: "MONTBEL (48170)", value: "48170" },
  { label: "MONTRODAT (48100)", value: "48100" },
  { label: "NASBINALS (48260)", value: "48260" },
  { label: "NAUSSAC FONTANES (48300)", value: "48300" },
  { label: "NAUSSAC FONTANES (48300)", value: "48300" },
  { label: "NOALHAC (48310)", value: "48310" },
  { label: "PALHERS (48100)", value: "48100" },
  { label: "LA PANOUSE (48600)", value: "48600" },
  { label: "PAULHAC EN MARGERIDE (48140)", value: "48140" },
  { label: "PELOUSE (48000)", value: "48000" },
  { label: "PIERREFICHE (48300)", value: "48300" },
  { label: "LE POMPIDOU (48110)", value: "48110" },
  { label: "PONT DE MONTVERT SUD MONT LOZERE (48220)", value: "48220" },
  { label: "PONT DE MONTVERT SUD MONT LOZERE (48220)", value: "48220" },
  { label: "PONT DE MONTVERT SUD MONT LOZERE (48220)", value: "48220" },
  { label: "POURCHARESSES (48800)", value: "48800" },
  { label: "PREVENCHERES (48800)", value: "48800" },
  { label: "PRUNIERES (48200)", value: "48200" },
  { label: "RECOULES D AUBRAC (48260)", value: "48260" },
  { label: "RECOULES DE FUMAS (48100)", value: "48100" },
  { label: "LACHAMP RIBENNES (48100)", value: "48100" },
  { label: "LACHAMP RIBENNES (48700)", value: "48700" },
  { label: "MONTS DE RANDON (48000)", value: "48000" },
  { label: "MONTS DE RANDON (48700)", value: "48700" },
  { label: "MONTS DE RANDON (48700)", value: "48700" },
  { label: "MONTS DE RANDON (48700)", value: "48700" },
  { label: "MONTS DE RANDON (48700)", value: "48700" },
  { label: "RIMEIZE (48200)", value: "48200" },
  { label: "ROCLES (48300)", value: "48300" },
  { label: "ROUSSES (48400)", value: "48400" },
  { label: "LE ROZIER (48150)", value: "48150" },
  { label: "ST ALBAN SUR LIMAGNOLE (48120)", value: "48120" },
  { label: "ST ANDRE CAPCEZE (48800)", value: "48800" },
  { label: "ST ANDRE DE LANCIZE (48240)", value: "48240" },
  { label: "ST BAUZILE (48000)", value: "48000" },
  { label: "ST BONNET DE CHIRAC (48100)", value: "48100" },
  { label: "ST BONNET LAVAL (48600)", value: "48600" },
  { label: "ST BONNET LAVAL (48600)", value: "48600" },
  { label: "ST BONNET LAVAL (48600)", value: "48600" },
  { label: "ST CHELY D APCHER (48200)", value: "48200" },
  { label: "MAS ST CHELY (48210)", value: "48210" },
  { label: "STE CROIX VALLEE FRANCAISE (48110)", value: "48110" },
  { label: "ST DENIS EN MARGERIDE (48700)", value: "48700" },
  { label: "GORGES DU TARN CAUSSES (48210)", value: "48210" },
  { label: "GORGES DU TARN CAUSSES (48210)", value: "48210" },
  { label: "GORGES DU TARN CAUSSES (48210)", value: "48210" },
  { label: "GORGES DU TARN CAUSSES (48320)", value: "48320" },
  { label: "GORGES DU TARN CAUSSES (48320)", value: "48320" },
  { label: "ST ETIENNE DU VALDONNEZ (48000)", value: "48000" },
  { label: "ST ETIENNE VALLEE FRANCAISE (48330)", value: "48330" },
  { label: "STE EULALIE (48120)", value: "48120" },
  { label: "ST FLOUR DE MERCOIRE (48300)", value: "48300" },
  { label: "ST FREZAL D ALBUGES (48170)", value: "48170" },
  { label: "VENTALON EN CEVENNES (48160)", value: "48160" },
  { label: "VENTALON EN CEVENNES (48240)", value: "48240" },
  { label: "ST GAL (48700)", value: "48700" },
  { label: "ST GERMAIN DE CALBERTE (48370)", value: "48370" },
  { label: "ST GERMAIN DU TEIL (48340)", value: "48340" },
  { label: "STE HELENE (48190)", value: "48190" },
  { label: "ST HILAIRE DE LAVIT (48160)", value: "48160" },
  { label: "ST JEAN LA FOUILLOUSE (48170)", value: "48170" },
  { label: "ST JUERY (48310)", value: "48310" },
  { label: "ST JULIEN DES POINTS (48160)", value: "48160" },
  { label: "ST LAURENT DE MURET (48100)", value: "48100" },
  { label: "CANS ET CEVENNES (48400)", value: "48400" },
  { label: "CANS ET CEVENNES (48400)", value: "48400" },
  { label: "ST LAURENT DE VEYRES (48310)", value: "48310" },
  { label: "ST LEGER DE PEYRE (48100)", value: "48100" },
  { label: "ST LEGER DU MALZIEU (48140)", value: "48140" },
  { label: "ST MARTIN DE BOUBAUX (48160)", value: "48160" },
  { label: "ST MARTIN DE LANSUSCLE (48110)", value: "48110" },
  { label: "ST MICHEL DE DEZE (48160)", value: "48160" },
  { label: "ST PAUL LE FROID (48600)", value: "48600" },
  { label: "ST PIERRE DE NOGARET (48340)", value: "48340" },
  { label: "ST PIERRE DES TRIPIERS (48150)", value: "48150" },
  { label: "ST PIERRE LE VIEUX (48200)", value: "48200" },
  { label: "ST PRIVAT DE VALLONGUE (48240)", value: "48240" },
  { label: "ST PRIVAT DU FAU (48140)", value: "48140" },
  { label: "ST SATURNIN (48500)", value: "48500" },
  { label: "ST SAUVEUR DE GINESTOUX (48170)", value: "48170" },
  { label: "LES SALELLES (48230)", value: "48230" },
  { label: "LES SALCES (48100)", value: "48100" },
  { label: "SERVERETTE (48700)", value: "48700" },
  { label: "TERMES (48310)", value: "48310" },
  { label: "LA TIEULE (48500)", value: "48500" },
  { label: "TRELANS (48340)", value: "48340" },
  { label: "VEBRON (48400)", value: "48400" },
  { label: "VEBRON (48400)", value: "48400" },
  { label: "VIALAS (48220)", value: "48220" },
  { label: "VILLEFORT (48800)", value: "48800" },
  { label: "VILLEFORT (48800)", value: "48800" },
  { label: "ALLONNES (49650)", value: "49650" },
  { label: "TUFFALUN (49700)", value: "49700" },
  { label: "TUFFALUN (49700)", value: "49700" },
  { label: "TUFFALUN (49700)", value: "49700" },
  { label: "ANGERS (49000)", value: "49000" },
  { label: "ANGERS (49100)", value: "49100" },
  { label: "ANGRIE (49440)", value: "49440" },
  { label: "ANTOIGNE (49260)", value: "49260" },
  { label: "ARMAILLE (49420)", value: "49420" },
  { label: "ARTANNES SUR THOUET (49260)", value: "49260" },
  { label: "AUBIGNE SUR LAYON (49540)", value: "49540" },
  { label: "AVRILLE (49240)", value: "49240" },
  { label: "BARACE (49430)", value: "49430" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BAUGE EN ANJOU (49150)", value: "49150" },
  { label: "BEAUCOUZE (49070)", value: "49070" },
  { label: "BEAUFORT EN ANJOU (49250)", value: "49250" },
  { label: "BEAUFORT EN ANJOU (49250)", value: "49250" },
  { label: "BEAULIEU SUR LAYON (49750)", value: "49750" },
  { label: "BEAUPREAU EN MAUGES (49110)", value: "49110" },
  { label: "BEAUPREAU EN MAUGES (49450)", value: "49450" },
  { label: "BEAUPREAU EN MAUGES (49510)", value: "49510" },
  { label: "BEAUPREAU EN MAUGES (49510)", value: "49510" },
  { label: "BEAUPREAU EN MAUGES (49510)", value: "49510" },
  { label: "BEAUPREAU EN MAUGES (49600)", value: "49600" },
  { label: "BEAUPREAU EN MAUGES (49600)", value: "49600" },
  { label: "BEAUPREAU EN MAUGES (49600)", value: "49600" },
  { label: "BEAUPREAU EN MAUGES (49600)", value: "49600" },
  { label: "BEAUPREAU EN MAUGES (49600)", value: "49600" },
  { label: "BECON LES GRANITS (49370)", value: "49370" },
  { label: "BEGROLLES EN MAUGES (49122)", value: "49122" },
  { label: "BEHUARD (49170)", value: "49170" },
  { label: "BLAISON ST SULPICE (49320)", value: "49320" },
  { label: "BLAISON ST SULPICE (49320)", value: "49320" },
  { label: "BLAISON ST SULPICE (49320)", value: "49320" },
  { label: "BLOU (49160)", value: "49160" },
  { label: "BOUCHEMAINE (49080)", value: "49080" },
  { label: "BOUILLE MENARD (49520)", value: "49520" },
  { label: "BOURG L EVEQUE (49520)", value: "49520" },
  { label: "BRAIN SUR ALLONNES (49650)", value: "49650" },
  { label: "LA BREILLE LES PINS (49390)", value: "49390" },
  { label: "BRIOLLAY (49125)", value: "49125" },
  { label: "BRISSAC LOIRE AUBANCE (49250)", value: "49250" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BRISSAC LOIRE AUBANCE (49320)", value: "49320" },
  { label: "BROSSAY (49700)", value: "49700" },
  { label: "CANDE (49440)", value: "49440" },
  { label: "CANTENAY EPINARD (49460)", value: "49460" },
  { label: "CARBAY (49420)", value: "49420" },
  { label: "CERNUSSON (49310)", value: "49310" },
  { label: "LES CERQUEUX (49360)", value: "49360" },
  { label: "BELLEVIGNE LES CHATEAUX (49260)", value: "49260" },
  { label: "BELLEVIGNE LES CHATEAUX (49260)", value: "49260" },
  { label: "BELLEVIGNE LES CHATEAUX (49400)", value: "49400" },
  { label: "CHALLAIN LA POTHERIE (49440)", value: "49440" },
  { label: "CHALONNES SUR LOIRE (49290)", value: "49290" },
  { label: "CHAMBELLAY (49220)", value: "49220" },
  { label: "CHENILLE CHAMPTEUSSE (49220)", value: "49220" },
  { label: "CHENILLE CHAMPTEUSSE (49220)", value: "49220" },
  { label: "CHAMPTOCE SUR LOIRE (49123)", value: "49123" },
  { label: "OREE D ANJOU (49270)", value: "49270" },
  { label: "OREE D ANJOU (49270)", value: "49270" },
  { label: "OREE D ANJOU (49270)", value: "49270" },
  { label: "OREE D ANJOU (49270)", value: "49270" },
  { label: "OREE D ANJOU (49270)", value: "49270" },
  { label: "OREE D ANJOU (49270)", value: "49270" },
  { label: "OREE D ANJOU (49530)", value: "49530" },
  { label: "OREE D ANJOU (49530)", value: "49530" },
  { label: "OREE D ANJOU (49530)", value: "49530" },
  { label: "CHANTELOUP LES BOIS (49340)", value: "49340" },
  { label: "LA CHAPELLE ST LAUD (49140)", value: "49140" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "LES HAUTS D ANJOU (49330)", value: "49330" },
  { label: "CHAUDEFONDS SUR LAYON (49290)", value: "49290" },
  { label: "TERRANJOU (49380)", value: "49380" },
  { label: "TERRANJOU (49380)", value: "49380" },
  { label: "TERRANJOU (49540)", value: "49540" },
  { label: "CHAZE SUR ARGOS (49500)", value: "49500" },
  { label: "CHEFFES (49125)", value: "49125" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49120)", value: "49120" },
  { label: "CHEMILLE EN ANJOU (49310)", value: "49310" },
  { label: "CHEMILLE EN ANJOU (49670)", value: "49670" },
  { label: "CHEMILLE EN ANJOU (49670)", value: "49670" },
  { label: "CHEMILLE EN ANJOU (49750)", value: "49750" },
  { label: "CHOLET (49300)", value: "49300" },
  { label: "CHOLET (49300)", value: "49300" },
  { label: "CIZAY LA MADELEINE (49700)", value: "49700" },
  { label: "CLERE SUR LAYON (49560)", value: "49560" },
  { label: "CORNILLE LES CAVES (49140)", value: "49140" },
  { label: "CORON (49690)", value: "49690" },
  { label: "CORZE (49140)", value: "49140" },
  { label: "LE COUDRAY MACOUARD (49260)", value: "49260" },
  { label: "COURCHAMPS (49260)", value: "49260" },
  { label: "COURLEON (49390)", value: "49390" },
  { label: "DENEE (49190)", value: "49190" },
  { label: "DENEZE SOUS DOUE (49700)", value: "49700" },
  { label: "DISTRE (49400)", value: "49400" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DOUE EN ANJOU (49700)", value: "49700" },
  { label: "DURTAL (49430)", value: "49430" },
  { label: "ECOUFLANT (49000)", value: "49000" },
  { label: "ECUILLE (49460)", value: "49460" },
  { label: "EPIEDS (49260)", value: "49260" },
  { label: "ETRICHE (49330)", value: "49330" },
  { label: "FENEU (49460)", value: "49460" },
  { label: "LES BOIS D ANJOU (49250)", value: "49250" },
  { label: "LES BOIS D ANJOU (49250)", value: "49250" },
  { label: "LES BOIS D ANJOU (49250)", value: "49250" },
  { label: "FONTEVRAUD L ABBAYE (49590)", value: "49590" },
  { label: "GREZ NEUVILLE (49220)", value: "49220" },
  { label: "INGRANDES LE FRESNE SUR LOIRE (49123)", value: "49123" },
  { label: "INGRANDES LE FRESNE SUR LOIRE (49123)", value: "49123" },
  { label: "INGRANDES LE FRESNE SUR LOIRE (49123)", value: "49123" },
  { label: "LA JAILLE YVON (49220)", value: "49220" },
  { label: "JARZE VILLAGES (49140)", value: "49140" },
  { label: "JARZE VILLAGES (49140)", value: "49140" },
  { label: "JARZE VILLAGES (49140)", value: "49140" },
  { label: "JARZE VILLAGES (49140)", value: "49140" },
  { label: "LES GARENNES SUR LOIRE (49320)", value: "49320" },
  { label: "LES GARENNES SUR LOIRE (49610)", value: "49610" },
  { label: "JUVARDEIL (49330)", value: "49330" },
  { label: "LA LANDE CHASLES (49150)", value: "49150" },
  { label: "HUILLE LEZIGNE (49430)", value: "49430" },
  { label: "HUILLE LEZIGNE (49430)", value: "49430" },
  { label: "LE LION D ANGERS (49220)", value: "49220" },
  { label: "LE LION D ANGERS (49220)", value: "49220" },
  { label: "LOIRE (49440)", value: "49440" },
  { label: "LONGUE JUMELLES (49160)", value: "49160" },
  { label: "LONGUE JUMELLES (49160)", value: "49160" },
  { label: "LOURESSE ROCHEMENIER (49700)", value: "49700" },
  { label: "VAL D ERDRE AUXENCE (49370)", value: "49370" },
  { label: "VAL D ERDRE AUXENCE (49370)", value: "49370" },
  { label: "VAL D ERDRE AUXENCE (49440)", value: "49440" },
  { label: "MARCE (49140)", value: "49140" },
  { label: "MAULEVRIER (49360)", value: "49360" },
  { label: "LE MAY SUR EVRE (49122)", value: "49122" },
  { label: "MAZE MILON (49140)", value: "49140" },
  { label: "MAZE MILON (49630)", value: "49630" },
  { label: "MAZIERES EN MAUGES (49280)", value: "49280" },
  { label: "LONGUENEE EN ANJOU (49220)", value: "49220" },
  { label: "LONGUENEE EN ANJOU (49770)", value: "49770" },
  { label: "LONGUENEE EN ANJOU (49770)", value: "49770" },
  { label: "LONGUENEE EN ANJOU (49770)", value: "49770" },
  { label: "LA MENITRE (49250)", value: "49250" },
  { label: "MIRE (49330)", value: "49330" },
  { label: "MONTIGNE LES RAIRIES (49430)", value: "49430" },
  { label: "MONTILLIERS (49310)", value: "49310" },
  { label: "MONTREUIL JUIGNE (49460)", value: "49460" },
  { label: "MONTREUIL JUIGNE (49460)", value: "49460" },
  { label: "MONTREUIL BELLAY (49260)", value: "49260" },
  { label: "MONTREUIL BELLAY (49260)", value: "49260" },
  { label: "MONTREUIL SUR LOIR (49140)", value: "49140" },
  { label: "MONTREUIL SUR MAINE (49220)", value: "49220" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49110)", value: "49110" },
  { label: "MONTREVAULT SUR EVRE (49270)", value: "49270" },
  { label: "MONTREVAULT SUR EVRE (49600)", value: "49600" },
  { label: "MONTREVAULT SUR EVRE (49600)", value: "49600" },
  { label: "MONTREVAULT SUR EVRE (49600)", value: "49600" },
  { label: "MONTSOREAU (49730)", value: "49730" },
  { label: "MORANNES SUR SARTHE DAUMERAY (49640)", value: "49640" },
  { label: "MORANNES SUR SARTHE DAUMERAY (49640)", value: "49640" },
  { label: "MORANNES SUR SARTHE DAUMERAY (49640)", value: "49640" },
  { label: "MOULIHERNE (49390)", value: "49390" },
  { label: "MOZE SUR LOUET (49610)", value: "49610" },
  { label: "MURS ERIGNE (49610)", value: "49610" },
  { label: "NEUILLE (49680)", value: "49680" },
  { label: "NOYANT VILLAGES (49390)", value: "49390" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NOYANT VILLAGES (49490)", value: "49490" },
  { label: "NUAILLE (49340)", value: "49340" },
  { label: "PARNAY (49730)", value: "49730" },
  { label: "PASSAVANT SUR LAYON (49560)", value: "49560" },
  { label: "LA PELLERINE (49490)", value: "49490" },
  { label: "LA PLAINE (49360)", value: "49360" },
  { label: "LE PLESSIS GRAMMOIRE (49124)", value: "49124" },
  { label: "MAUGES SUR LOIRE (49110)", value: "49110" },
  { label: "MAUGES SUR LOIRE (49290)", value: "49290" },
  { label: "MAUGES SUR LOIRE (49290)", value: "49290" },
  { label: "MAUGES SUR LOIRE (49410)", value: "49410" },
  { label: "MAUGES SUR LOIRE (49410)", value: "49410" },
  { label: "MAUGES SUR LOIRE (49410)", value: "49410" },
  { label: "MAUGES SUR LOIRE (49410)", value: "49410" },
  { label: "MAUGES SUR LOIRE (49410)", value: "49410" },
  { label: "MAUGES SUR LOIRE (49410)", value: "49410" },
  { label: "MAUGES SUR LOIRE (49570)", value: "49570" },
  { label: "MAUGES SUR LOIRE (49620)", value: "49620" },
  { label: "LES PONTS DE CE (49130)", value: "49130" },
  { label: "LES PONTS DE CE (49130)", value: "49130" },
  { label: "LA POSSONNIERE (49170)", value: "49170" },
  { label: "OMBREE D ANJOU (49420)", value: "49420" },
  { label: "OMBREE D ANJOU (49420)", value: "49420" },
  { label: "OMBREE D ANJOU (49420)", value: "49420" },
  { label: "OMBREE D ANJOU (49420)", value: "49420" },
  { label: "OMBREE D ANJOU (49420)", value: "49420" },
  { label: "OMBREE D ANJOU (49420)", value: "49420" },
  { label: "OMBREE D ANJOU (49520)", value: "49520" },
  { label: "OMBREE D ANJOU (49520)", value: "49520" },
  { label: "OMBREE D ANJOU (49520)", value: "49520" },
  { label: "OMBREE D ANJOU (49520)", value: "49520" },
  { label: "OMBREE D ANJOU (49520)", value: "49520" },
  { label: "LE PUY NOTRE DAME (49260)", value: "49260" },
  { label: "LES RAIRIES (49430)", value: "49430" },
  { label: "ROCHEFORT SUR LOIRE (49190)", value: "49190" },
  { label: "LA ROMAGNE (49740)", value: "49740" },
  { label: "GENNES VAL DE LOIRE (49160)", value: "49160" },
  { label: "GENNES VAL DE LOIRE (49320)", value: "49320" },
  { label: "GENNES VAL DE LOIRE (49350)", value: "49350" },
  { label: "GENNES VAL DE LOIRE (49350)", value: "49350" },
  { label: "GENNES VAL DE LOIRE (49350)", value: "49350" },
  { label: "GENNES VAL DE LOIRE (49350)", value: "49350" },
  { label: "GENNES VAL DE LOIRE (49350)", value: "49350" },
  { label: "GENNES VAL DE LOIRE (49350)", value: "49350" },
  { label: "ROU MARSON (49400)", value: "49400" },
  { label: "ST AUGUSTIN DES BOIS (49170)", value: "49170" },
  { label: "ST BARTHELEMY D ANJOU (49124)", value: "49124" },
  { label: "ST CHRISTOPHE DU BOIS (49280)", value: "49280" },
  { label: "ST CLEMENT DE LA PLACE (49370)", value: "49370" },
  { label: "ST CLEMENT DES LEVEES (49350)", value: "49350" },
  { label: "STE GEMMES SUR LOIRE (49130)", value: "49130" },
  { label: "ST GEORGES SUR LOIRE (49170)", value: "49170" },
  { label: "ST GERMAIN DES PRES (49170)", value: "49170" },
  { label: "ST JEAN DE LA CROIX (49130)", value: "49130" },
  { label: "ST JUST SUR DIVE (49260)", value: "49260" },
  { label: "VAL DU LAYON (49190)", value: "49190" },
  { label: "VAL DU LAYON (49750)", value: "49750" },
  { label: "ST LAMBERT LA POTHERIE (49070)", value: "49070" },
  { label: "ST LEGER DE LINIERES (49070)", value: "49070" },
  { label: "ST LEGER DE LINIERES (49170)", value: "49170" },
  { label: "ST LEGER SOUS CHOLET (49280)", value: "49280" },
  { label: "SEVREMOINE (49230)", value: "49230" },
  { label: "SEVREMOINE (49230)", value: "49230" },
  { label: "SEVREMOINE (49230)", value: "49230" },
  { label: "SEVREMOINE (49230)", value: "49230" },
  { label: "SEVREMOINE (49450)", value: "49450" },
  { label: "SEVREMOINE (49450)", value: "49450" },
  { label: "SEVREMOINE (49450)", value: "49450" },
  { label: "SEVREMOINE (49450)", value: "49450" },
  { label: "SEVREMOINE (49660)", value: "49660" },
  { label: "SEVREMOINE (49710)", value: "49710" },
  { label: "ST MACAIRE DU BOIS (49260)", value: "49260" },
  { label: "ST MARTIN DU FOUILLOUX (49170)", value: "49170" },
  { label: "LOIRE AUTHION (49140)", value: "49140" },
  { label: "LOIRE AUTHION (49250)", value: "49250" },
  { label: "LOIRE AUTHION (49630)", value: "49630" },
  { label: "LOIRE AUTHION (49800)", value: "49800" },
  { label: "LOIRE AUTHION (49800)", value: "49800" },
  { label: "LOIRE AUTHION (49800)", value: "49800" },
  { label: "LOIRE AUTHION (49800)", value: "49800" },
  { label: "ST MELAINE SUR AUBANCE (49610)", value: "49610" },
  { label: "ST PAUL DU BOIS (49310)", value: "49310" },
  { label: "ST PHILBERT DU PEUPLE (49160)", value: "49160" },
  { label: "VERRIERES EN ANJOU (49112)", value: "49112" },
  { label: "VERRIERES EN ANJOU (49480)", value: "49480" },
  { label: "SARRIGNE (49800)", value: "49800" },
  { label: "SAUMUR (49400)", value: "49400" },
  { label: "SAUMUR (49400)", value: "49400" },
  { label: "SAUMUR (49400)", value: "49400" },
  { label: "SAUMUR (49400)", value: "49400" },
  { label: "SAUMUR (49400)", value: "49400" },
  { label: "SAVENNIERES (49170)", value: "49170" },
  { label: "SAVENNIERES (49170)", value: "49170" },
  { label: "SCEAUX D ANJOU (49330)", value: "49330" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49500)", value: "49500" },
  { label: "SEGRE EN ANJOU BLEU (49520)", value: "49520" },
  { label: "SEGRE EN ANJOU BLEU (49520)", value: "49520" },
  { label: "SEGRE EN ANJOU BLEU (49520)", value: "49520" },
  { label: "LA SEGUINIERE (49280)", value: "49280" },
  { label: "SEICHES SUR LE LOIR (49140)", value: "49140" },
  { label: "SEICHES SUR LE LOIR (49140)", value: "49140" },
  { label: "SERMAISE (49140)", value: "49140" },
  { label: "SOMLOIRE (49360)", value: "49360" },
  { label: "SOULAINES SUR AUBANCE (49610)", value: "49610" },
  { label: "SOULAIRE ET BOURG (49460)", value: "49460" },
  { label: "SOUZAY CHAMPIGNY (49400)", value: "49400" },
  { label: "LA TESSOUALLE (49280)", value: "49280" },
  { label: "THORIGNE D ANJOU (49220)", value: "49220" },
  { label: "BELLEVIGNE EN LAYON (49380)", value: "49380" },
  { label: "BELLEVIGNE EN LAYON (49380)", value: "49380" },
  { label: "BELLEVIGNE EN LAYON (49380)", value: "49380" },
  { label: "BELLEVIGNE EN LAYON (49380)", value: "49380" },
  { label: "BELLEVIGNE EN LAYON (49750)", value: "49750" },
  { label: "TIERCE (49125)", value: "49125" },
  { label: "TOUTLEMONDE (49360)", value: "49360" },
  { label: "TRELAZE (49800)", value: "49800" },
  { label: "TREMENTINES (49340)", value: "49340" },
  { label: "TURQUANT (49730)", value: "49730" },
  { label: "LES ULMES (49700)", value: "49700" },
  { label: "VARENNES SUR LOIRE (49730)", value: "49730" },
  { label: "VARRAINS (49400)", value: "49400" },
  { label: "VAUDELNAY (49260)", value: "49260" },
  { label: "ERDRE EN ANJOU (49220)", value: "49220" },
  { label: "ERDRE EN ANJOU (49220)", value: "49220" },
  { label: "ERDRE EN ANJOU (49220)", value: "49220" },
  { label: "ERDRE EN ANJOU (49370)", value: "49370" },
  { label: "VERNANTES (49390)", value: "49390" },
  { label: "VERNOIL LE FOURRIER (49390)", value: "49390" },
  { label: "VERRIE (49400)", value: "49400" },
  { label: "VEZINS (49340)", value: "49340" },
  { label: "LYS HAUT LAYON (49310)", value: "49310" },
  { label: "LYS HAUT LAYON (49310)", value: "49310" },
  { label: "LYS HAUT LAYON (49310)", value: "49310" },
  { label: "LYS HAUT LAYON (49310)", value: "49310" },
  { label: "LYS HAUT LAYON (49310)", value: "49310" },
  { label: "LYS HAUT LAYON (49310)", value: "49310" },
  { label: "LYS HAUT LAYON (49540)", value: "49540" },
  { label: "LYS HAUT LAYON (49540)", value: "49540" },
  { label: "LYS HAUT LAYON (49560)", value: "49560" },
  { label: "VILLEBERNIER (49400)", value: "49400" },
  { label: "RIVES DU LOIR EN ANJOU (49140)", value: "49140" },
  { label: "RIVES DU LOIR EN ANJOU (49140)", value: "49140" },
  { label: "VIVY (49680)", value: "49680" },
  { label: "YZERNAY (49360)", value: "49360" },
  { label: "AGNEAUX (50180)", value: "50180" },
  { label: "AGON COUTAINVILLE (50230)", value: "50230" },
  { label: "AIREL (50680)", value: "50680" },
  { label: "AMIGNY (50620)", value: "50620" },
  { label: "ANCTOVILLE SUR BOSCQ (50400)", value: "50400" },
  { label: "ANNEVILLE EN SAIRE (50760)", value: "50760" },
  { label: "APPEVILLE (50500)", value: "50500" },
  { label: "AUCEY LA PLAINE (50170)", value: "50170" },
  { label: "AUDOUVILLE LA HUBERT (50480)", value: "50480" },
  { label: "AUMEVILLE LESTRE (50630)", value: "50630" },
  { label: "AUVERS (50500)", value: "50500" },
  { label: "AUXAIS (50500)", value: "50500" },
  { label: "AVRANCHES (50300)", value: "50300" },
  { label: "AVRANCHES (50300)", value: "50300" },
  { label: "AZEVILLE (50310)", value: "50310" },
  { label: "BACILLY (50530)", value: "50530" },
  { label: "LA BALEINE (50450)", value: "50450" },
  { label: "BARENTON (50720)", value: "50720" },
  { label: "BARFLEUR (50760)", value: "50760" },
  { label: "BARNEVILLE CARTERET (50270)", value: "50270" },
  { label: "LA BARRE DE SEMILLY (50810)", value: "50810" },
  { label: "BAUBIGNY (50270)", value: "50270" },
  { label: "BAUDRE (50000)", value: "50000" },
  { label: "BAUPTE (50500)", value: "50500" },
  { label: "BEAUCHAMPS (50320)", value: "50320" },
  { label: "BEAUCOUDRAY (50420)", value: "50420" },
  { label: "BEAUFICEL (50150)", value: "50150" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50440)", value: "50440" },
  { label: "LA HAGUE (50460)", value: "50460" },
  { label: "LA HAGUE (50460)", value: "50460" },
  { label: "LA HAGUE (50690)", value: "50690" },
  { label: "BEAUVOIR (50170)", value: "50170" },
  { label: "BELVAL (50210)", value: "50210" },
  { label: "BENOITVILLE (50340)", value: "50340" },
  { label: "BERIGNY (50810)", value: "50810" },
  { label: "BESLON (50800)", value: "50800" },
  { label: "BESNEVILLE (50390)", value: "50390" },
  { label: "BEUVRIGNY (50420)", value: "50420" },
  { label: "BEUZEVILLE LA BASTILLE (50360)", value: "50360" },
  { label: "BIEVILLE (50160)", value: "50160" },
  { label: "BINIVILLE (50390)", value: "50390" },
  { label: "BLAINVILLE SUR MER (50560)", value: "50560" },
  { label: "BLOSVILLE (50480)", value: "50480" },
  { label: "LA BLOUTIERE (50800)", value: "50800" },
  { label: "BOISYVON (50800)", value: "50800" },
  { label: "LA BONNEVILLE (50360)", value: "50360" },
  { label: "JULLOUVILLE (50610)", value: "50610" },
  { label: "JULLOUVILLE (50610)", value: "50610" },
  { label: "BOURGUENOLLES (50800)", value: "50800" },
  { label: "BOUTTEVILLE (50480)", value: "50480" },
  { label: "BRAINVILLE (50200)", value: "50200" },
  { label: "BRECEY (50370)", value: "50370" },
  { label: "BREHAL (50290)", value: "50290" },
  { label: "BREHAL (50290)", value: "50290" },
  { label: "BRETTEVILLE (50110)", value: "50110" },
  { label: "BRETTEVILLE SUR AY (50430)", value: "50430" },
  { label: "BREUVILLE (50260)", value: "50260" },
  { label: "BREVILLE SUR MER (50290)", value: "50290" },
  { label: "BRICQUEBEC EN COTENTIN (50260)", value: "50260" },
  { label: "BRICQUEBEC EN COTENTIN (50260)", value: "50260" },
  { label: "BRICQUEBEC EN COTENTIN (50260)", value: "50260" },
  { label: "BRICQUEBEC EN COTENTIN (50260)", value: "50260" },
  { label: "BRICQUEBEC EN COTENTIN (50260)", value: "50260" },
  { label: "BRICQUEBEC EN COTENTIN (50260)", value: "50260" },
  { label: "BRICQUEBOSQ (50340)", value: "50340" },
  { label: "BRICQUEVILLE LA BLOUETTE (50200)", value: "50200" },
  { label: "BRICQUEVILLE SUR MER (50290)", value: "50290" },
  { label: "BRILLEVAST (50330)", value: "50330" },
  { label: "BRIX (50700)", value: "50700" },
  { label: "BROUAINS (50150)", value: "50150" },
  { label: "BUAIS LES MONTS (50640)", value: "50640" },
  { label: "BUAIS LES MONTS (50640)", value: "50640" },
  { label: "CAMBERNON (50200)", value: "50200" },
  { label: "CAMETOURS (50570)", value: "50570" },
  { label: "CAMPROND (50210)", value: "50210" },
  { label: "CANISY (50750)", value: "50750" },
  { label: "CANISY (50750)", value: "50750" },
  { label: "CANTELOUP (50330)", value: "50330" },
  { label: "CANVILLE LA ROCQUE (50580)", value: "50580" },
  { label: "CARANTILLY (50570)", value: "50570" },
  { label: "CARENTAN LES MARAIS (50480)", value: "50480" },
  { label: "CARENTAN LES MARAIS (50480)", value: "50480" },
  { label: "CARENTAN LES MARAIS (50480)", value: "50480" },
  { label: "CARENTAN LES MARAIS (50480)", value: "50480" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50500)", value: "50500" },
  { label: "CARENTAN LES MARAIS (50620)", value: "50620" },
  { label: "CARNEVILLE (50330)", value: "50330" },
  { label: "CAROLLES (50740)", value: "50740" },
  { label: "CATTEVILLE (50390)", value: "50390" },
  { label: "CAVIGNY (50620)", value: "50620" },
  { label: "CEAUX (50220)", value: "50220" },
  { label: "CERENCES (50510)", value: "50510" },
  { label: "CERISY LA FORET (50680)", value: "50680" },
  { label: "CERISY LA SALLE (50210)", value: "50210" },
  { label: "LA CHAISE BAUDOUIN (50370)", value: "50370" },
  { label: "LE GRIPPON (50320)", value: "50320" },
  { label: "LE GRIPPON (50320)", value: "50320" },
  { label: "CHAMPEAUX (50530)", value: "50530" },
  { label: "CHAMPREPUS (50800)", value: "50800" },
  { label: "CHANTELOUP (50510)", value: "50510" },
  { label: "LA CHAPELLE CECELIN (50800)", value: "50800" },
  { label: "LA CHAPELLE UREE (50370)", value: "50370" },
  { label: "CHAVOY (50870)", value: "50870" },
  { label: "CHERBOURG EN COTENTIN (50100)", value: "50100" },
  { label: "CHERBOURG EN COTENTIN (50110)", value: "50110" },
  { label: "CHERBOURG EN COTENTIN (50120)", value: "50120" },
  { label: "CHERBOURG EN COTENTIN (50130)", value: "50130" },
  { label: "CHERBOURG EN COTENTIN (50130)", value: "50130" },
  { label: "CHERBOURG EN COTENTIN (50460)", value: "50460" },
  { label: "CHERBOURG EN COTENTIN (50470)", value: "50470" },
  { label: "CHERENCE LE HERON (50800)", value: "50800" },
  { label: "CLITOURPS (50330)", value: "50330" },
  { label: "LA COLOMBE (50800)", value: "50800" },
  { label: "COLOMBY (50700)", value: "50700" },
  { label: "CONDE SUR VIRE (50420)", value: "50420" },
  { label: "CONDE SUR VIRE (50420)", value: "50420" },
  { label: "CONDE SUR VIRE (50890)", value: "50890" },
  { label: "VICQ SUR MER (50330)", value: "50330" },
  { label: "VICQ SUR MER (50330)", value: "50330" },
  { label: "VICQ SUR MER (50330)", value: "50330" },
  { label: "VICQ SUR MER (50330)", value: "50330" },
  { label: "VICQ SUR MER (50330)", value: "50330" },
  { label: "VICQ SUR MER (50330)", value: "50330" },
  { label: "COUDEVILLE SUR MER (50290)", value: "50290" },
  { label: "COULOUVRAY BOISBENATRE (50670)", value: "50670" },
  { label: "COURCY (50200)", value: "50200" },
  { label: "COURTILS (50220)", value: "50220" },
  { label: "COUTANCES (50200)", value: "50200" },
  { label: "COUVAINS (50680)", value: "50680" },
  { label: "COUVILLE (50690)", value: "50690" },
  { label: "CRASVILLE (50630)", value: "50630" },
  { label: "CREANCES (50710)", value: "50710" },
  { label: "LES CRESNAYS (50370)", value: "50370" },
  { label: "CROLLON (50220)", value: "50220" },
  { label: "CROSVILLE SUR DOUVE (50360)", value: "50360" },
  { label: "CUVES (50670)", value: "50670" },
  { label: "DANGY (50750)", value: "50750" },
  { label: "LE DEZERT (50620)", value: "50620" },
  { label: "DIGOSVILLE (50110)", value: "50110" },
  { label: "DOMJEAN (50420)", value: "50420" },
  { label: "DONVILLE LES BAINS (50350)", value: "50350" },
  { label: "DOVILLE (50250)", value: "50250" },
  { label: "DRAGEY RONTHON (50530)", value: "50530" },
  { label: "DRAGEY RONTHON (50530)", value: "50530" },
  { label: "DUCEY LES CHERIS (50220)", value: "50220" },
  { label: "DUCEY LES CHERIS (50220)", value: "50220" },
  { label: "ECAUSSEVILLE (50310)", value: "50310" },
  { label: "EMONDEVILLE (50310)", value: "50310" },
  { label: "EQUILLY (50320)", value: "50320" },
  { label: "EROUDEVILLE (50310)", value: "50310" },
  { label: "L'ETANG BERTRAND (50260)", value: "50260" },
  { label: "ETIENVILLE (50360)", value: "50360" },
  { label: "FERMANVILLE (50840)", value: "50840" },
  { label: "FEUGERES (50190)", value: "50190" },
  { label: "LA FEUILLIE (50190)", value: "50190" },
  { label: "FIERVILLE LES MINES (50580)", value: "50580" },
  { label: "FLAMANVILLE (50340)", value: "50340" },
  { label: "FLEURY (50800)", value: "50800" },
  { label: "FLOTTEMANVILLE (50700)", value: "50700" },
  { label: "FOLLIGNY (50320)", value: "50320" },
  { label: "FOLLIGNY (50320)", value: "50320" },
  { label: "FOLLIGNY (50320)", value: "50320" },
  { label: "FONTENAY SUR MER (50310)", value: "50310" },
  { label: "FOURNEAUX (50420)", value: "50420" },
  { label: "LE FRESNE PORET (50850)", value: "50850" },
  { label: "FRESVILLE (50310)", value: "50310" },
  { label: "GATHEMO (50150)", value: "50150" },
  { label: "GATTEVILLE LE PHARE (50760)", value: "50760" },
  { label: "GAVRAY SUR SIENNE (50450)", value: "50450" },
  { label: "GAVRAY SUR SIENNE (50450)", value: "50450" },
  { label: "GAVRAY SUR SIENNE (50450)", value: "50450" },
  { label: "GAVRAY SUR SIENNE (50450)", value: "50450" },
  { label: "GAVRAY SUR SIENNE (50450)", value: "50450" },
  { label: "GAVRAY SUR SIENNE (50450)", value: "50450" },
  { label: "GEFFOSSES (50560)", value: "50560" },
  { label: "GENETS (50530)", value: "50530" },
  { label: "GER (50850)", value: "50850" },
  { label: "LA GODEFROY (50300)", value: "50300" },
  { label: "GOLLEVILLE (50390)", value: "50390" },
  { label: "GONFREVILLE (50190)", value: "50190" },
  { label: "GONNEVILLE LE THEIL (50330)", value: "50330" },
  { label: "GONNEVILLE LE THEIL (50330)", value: "50330" },
  { label: "GORGES (50190)", value: "50190" },
  { label: "GOUVETS (50420)", value: "50420" },
  { label: "GOUVILLE SUR MER (50200)", value: "50200" },
  { label: "GOUVILLE SUR MER (50200)", value: "50200" },
  { label: "GOUVILLE SUR MER (50200)", value: "50200" },
  { label: "GOUVILLE SUR MER (50560)", value: "50560" },
  { label: "GOUVILLE SUR MER (50560)", value: "50560" },
  { label: "GRAIGNES MESNIL ANGOT (50620)", value: "50620" },
  { label: "GRAIGNES MESNIL ANGOT (50620)", value: "50620" },
  { label: "LE GRAND CELLAND (50370)", value: "50370" },
  { label: "GRANVILLE (50400)", value: "50400" },
  { label: "GRANVILLE (50400)", value: "50400" },
  { label: "GRATOT (50200)", value: "50200" },
  { label: "GRIMESNIL (50450)", value: "50450" },
  { label: "GROSVILLE (50340)", value: "50340" },
  { label: "LE GUISLAIN (50410)", value: "50410" },
  { label: "LE HAM (50310)", value: "50310" },
  { label: "HAMBYE (50450)", value: "50450" },
  { label: "HAMELIN (50730)", value: "50730" },
  { label: "HARDINVAST (50690)", value: "50690" },
  { label: "HAUTEVILLE SUR MER (50590)", value: "50590" },
  { label: "HAUTEVILLE LA GUICHARD (50570)", value: "50570" },
  { label: "HAUTTEVILLE BOCAGE (50390)", value: "50390" },
  { label: "LA HAYE BELLEFOND (50410)", value: "50410" },
  { label: "LA HAYE D ECTOT (50270)", value: "50270" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50250)", value: "50250" },
  { label: "LA HAYE (50580)", value: "50580" },
  { label: "LA HAYE PESNEL (50320)", value: "50320" },
  { label: "HEAUVILLE (50340)", value: "50340" },
  { label: "THEREVAL (50180)", value: "50180" },
  { label: "THEREVAL (50570)", value: "50570" },
  { label: "HELLEVILLE (50340)", value: "50340" },
  { label: "HEMEVEZ (50700)", value: "50700" },
  { label: "HEUGUEVILLE SUR SIENNE (50200)", value: "50200" },
  { label: "HIESVILLE (50480)", value: "50480" },
  { label: "HOCQUIGNY (50320)", value: "50320" },
  { label: "HUBERVILLE (50700)", value: "50700" },
  { label: "HUDIMESNIL (50510)", value: "50510" },
  { label: "HUISNES SUR MER (50170)", value: "50170" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "ISIGNY LE BUAT (50540)", value: "50540" },
  { label: "JOGANVILLE (50310)", value: "50310" },
  { label: "JUILLEY (50220)", value: "50220" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "JUVIGNY LES VALLEES (50520)", value: "50520" },
  { label: "LAMBERVILLE (50160)", value: "50160" },
  { label: "LA LANDE D AIROU (50800)", value: "50800" },
  { label: "LAPENTY (50600)", value: "50600" },
  { label: "LAULNE (50430)", value: "50430" },
  { label: "LENGRONNE (50450)", value: "50450" },
  { label: "LESSAY (50430)", value: "50430" },
  { label: "LESSAY (50430)", value: "50430" },
  { label: "LESTRE (50310)", value: "50310" },
  { label: "LIESVILLE SUR DOUVE (50480)", value: "50480" },
  { label: "LIEUSAINT (50700)", value: "50700" },
  { label: "LINGEARD (50670)", value: "50670" },
  { label: "TOURNEVILLE SUR MER (50660)", value: "50660" },
  { label: "TOURNEVILLE SUR MER (50660)", value: "50660" },
  { label: "MONTSENELLE (50250)", value: "50250" },
  { label: "MONTSENELLE (50250)", value: "50250" },
  { label: "MONTSENELLE (50250)", value: "50250" },
  { label: "MONTSENELLE (50250)", value: "50250" },
  { label: "MONTSENELLE (50250)", value: "50250" },
  { label: "LES LOGES MARCHIS (50600)", value: "50600" },
  { label: "LES LOGES SUR BRECEY (50370)", value: "50370" },
  { label: "LOLIF (50530)", value: "50530" },
  { label: "LONGUEVILLE (50290)", value: "50290" },
  { label: "LE LOREUR (50510)", value: "50510" },
  { label: "LE LOREY (50570)", value: "50570" },
  { label: "LA LUCERNE D OUTREMER (50320)", value: "50320" },
  { label: "LE LUOT (50870)", value: "50870" },
  { label: "LA LUZERNE (50680)", value: "50680" },
  { label: "MAGNEVILLE (50260)", value: "50260" },
  { label: "MARCEY LES GREVES (50300)", value: "50300" },
  { label: "MARCHESIEUX (50190)", value: "50190" },
  { label: "MARCILLY (50220)", value: "50220" },
  { label: "MARGUERAY (50410)", value: "50410" },
  { label: "MARIGNY LE LOZON (50570)", value: "50570" },
  { label: "MARIGNY LE LOZON (50570)", value: "50570" },
  { label: "MARTINVAST (50690)", value: "50690" },
  { label: "MAUPERTUIS (50410)", value: "50410" },
  { label: "MAUPERTUS SUR MER (50330)", value: "50330" },
  { label: "LA MEAUFFE (50880)", value: "50880" },
  { label: "MEAUTIS (50500)", value: "50500" },
  { label: "LE MESNIL (50580)", value: "50580" },
  { label: "LE MESNIL ADELEE (50520)", value: "50520" },
  { label: "LE MESNIL AMEY (50570)", value: "50570" },
  { label: "LE MESNIL AUBERT (50510)", value: "50510" },
  { label: "LE MESNIL AU VAL (50110)", value: "50110" },
  { label: "LE MESNIL EURY (50570)", value: "50570" },
  { label: "LE MESNIL GARNIER (50450)", value: "50450" },
  { label: "LE MESNIL GILBERT (50670)", value: "50670" },
  { label: "LE MESNILLARD (50600)", value: "50600" },
  { label: "LE MESNIL OZENNE (50220)", value: "50220" },
  { label: "LE MESNIL ROUXELIN (50000)", value: "50000" },
  { label: "LE MESNIL VENERON (50620)", value: "50620" },
  { label: "LE MESNIL VILLEMAN (50450)", value: "50450" },
  { label: "LA MEURDRAQUIERE (50510)", value: "50510" },
  { label: "MILLIERES (50190)", value: "50190" },
  { label: "LES MOITIERS D ALLONNE (50270)", value: "50270" },
  { label: "MONTABOT (50410)", value: "50410" },
  { label: "MONTAIGU LA BRISETTE (50700)", value: "50700" },
  { label: "MONTAIGU LES BOIS (50450)", value: "50450" },
  { label: "MONTBRAY (50410)", value: "50410" },
  { label: "MONTCUIT (50490)", value: "50490" },
  { label: "MONTEBOURG (50310)", value: "50310" },
  { label: "MONTFARVILLE (50760)", value: "50760" },
  { label: "MONTHUCHON (50200)", value: "50200" },
  { label: "MONTJOIE ST MARTIN (50240)", value: "50240" },
  { label: "MONTMARTIN SUR MER (50590)", value: "50590" },
  { label: "MONTPINCHON (50210)", value: "50210" },
  { label: "MONTRABOT (50810)", value: "50810" },
  { label: "MONTREUIL SUR LOZON (50570)", value: "50570" },
  { label: "LE MONT ST MICHEL (50170)", value: "50170" },
  { label: "MOON SUR ELLE (50680)", value: "50680" },
  { label: "MORIGNY (50410)", value: "50410" },
  { label: "MORTAIN BOCAGE (50140)", value: "50140" },
  { label: "MORTAIN BOCAGE (50140)", value: "50140" },
  { label: "MORTAIN BOCAGE (50140)", value: "50140" },
  { label: "MORTAIN BOCAGE (50140)", value: "50140" },
  { label: "MORTAIN BOCAGE (50140)", value: "50140" },
  { label: "MORVILLE (50700)", value: "50700" },
  { label: "LA MOUCHE (50320)", value: "50320" },
  { label: "MOULINES (50600)", value: "50600" },
  { label: "MOYON VILLAGES (50420)", value: "50420" },
  { label: "MOYON VILLAGES (50860)", value: "50860" },
  { label: "MOYON VILLAGES (50860)", value: "50860" },
  { label: "MUNEVILLE LE BINGARD (50490)", value: "50490" },
  { label: "MUNEVILLE SUR MER (50290)", value: "50290" },
  { label: "NAY (50190)", value: "50190" },
  { label: "NEGREVILLE (50260)", value: "50260" },
  { label: "NEHOU (50390)", value: "50390" },
  { label: "LE NEUFBOURG (50140)", value: "50140" },
  { label: "NEUFMESNIL (50250)", value: "50250" },
  { label: "NEUVILLE AU PLAIN (50480)", value: "50480" },
  { label: "NEUVILLE EN BEAUMONT (50250)", value: "50250" },
  { label: "NICORPS (50200)", value: "50200" },
  { label: "NOTRE DAME DE CENILLY (50210)", value: "50210" },
  { label: "NOTRE DAME DE LIVOYE (50370)", value: "50370" },
  { label: "NOUAINVILLE (50690)", value: "50690" },
  { label: "OCTEVILLE L AVENEL (50630)", value: "50630" },
  { label: "ORGLANDES (50390)", value: "50390" },
  { label: "ORVAL SUR SIENNE (50660)", value: "50660" },
  { label: "ORVAL SUR SIENNE (50660)", value: "50660" },
  { label: "OUVILLE (50210)", value: "50210" },
  { label: "OZEVILLE (50310)", value: "50310" },
  { label: "GRANDPARIGNY (50600)", value: "50600" },
  { label: "GRANDPARIGNY (50600)", value: "50600" },
  { label: "GRANDPARIGNY (50600)", value: "50600" },
  { label: "GRANDPARIGNY (50600)", value: "50600" },
  { label: "PERCY EN NORMANDIE (50410)", value: "50410" },
  { label: "PERCY EN NORMANDIE (50410)", value: "50410" },
  { label: "PERIERS (50190)", value: "50190" },
  { label: "LA PERNELLE (50630)", value: "50630" },
  { label: "PERRIERS EN BEAUFICEL (50150)", value: "50150" },
  { label: "LE PERRON (50160)", value: "50160" },
  { label: "LE PETIT CELLAND (50370)", value: "50370" },
  { label: "PICAUVILLE (50250)", value: "50250" },
  { label: "PICAUVILLE (50250)", value: "50250" },
  { label: "PICAUVILLE (50250)", value: "50250" },
  { label: "PICAUVILLE (50360)", value: "50360" },
  { label: "PICAUVILLE (50360)", value: "50360" },
  { label: "PICAUVILLE (50480)", value: "50480" },
  { label: "PICAUVILLE (50480)", value: "50480" },
  { label: "PIERREVILLE (50340)", value: "50340" },
  { label: "LES PIEUX (50340)", value: "50340" },
  { label: "PIROU (50770)", value: "50770" },
  { label: "LE PLESSIS LASTELLE (50250)", value: "50250" },
  { label: "POILLEY (50220)", value: "50220" },
  { label: "PONTAUBAULT (50220)", value: "50220" },
  { label: "PONT HEBERT (50620)", value: "50620" },
  { label: "PONT HEBERT (50880)", value: "50880" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTORSON (50170)", value: "50170" },
  { label: "PONTS (50300)", value: "50300" },
  { label: "PORT BAIL SUR MER (50580)", value: "50580" },
  { label: "PORT BAIL SUR MER (50580)", value: "50580" },
  { label: "PORT BAIL SUR MER (50580)", value: "50580" },
  { label: "PRECEY (50220)", value: "50220" },
  { label: "QUETTEHOU (50630)", value: "50630" },
  { label: "QUETTEHOU (50630)", value: "50630" },
  { label: "QUETTREVILLE SUR SIENNE (50210)", value: "50210" },
  { label: "QUETTREVILLE SUR SIENNE (50660)", value: "50660" },
  { label: "QUETTREVILLE SUR SIENNE (50660)", value: "50660" },
  { label: "QUETTREVILLE SUR SIENNE (50660)", value: "50660" },
  { label: "QUETTREVILLE SUR SIENNE (50660)", value: "50660" },
  { label: "QUETTREVILLE SUR SIENNE (50660)", value: "50660" },
  { label: "QUIBOU (50750)", value: "50750" },
  { label: "QUINEVILLE (50310)", value: "50310" },
  { label: "RAIDS (50500)", value: "50500" },
  { label: "RAMPAN (50000)", value: "50000" },
  { label: "RAUVILLE LA BIGOT (50260)", value: "50260" },
  { label: "RAUVILLE LA PLACE (50390)", value: "50390" },
  { label: "REFFUVEILLE (50520)", value: "50520" },
  { label: "REGNEVILLE SUR MER (50590)", value: "50590" },
  { label: "REIGNEVILLE BOCAGE (50390)", value: "50390" },
  { label: "REMILLY LES MARAIS (50570)", value: "50570" },
  { label: "REMILLY LES MARAIS (50570)", value: "50570" },
  { label: "REMILLY LES MARAIS (50620)", value: "50620" },
  { label: "REVILLE (50760)", value: "50760" },
  { label: "ROCHEVILLE (50260)", value: "50260" },
  { label: "ROMAGNY FONTENAY (50140)", value: "50140" },
  { label: "ROMAGNY FONTENAY (50140)", value: "50140" },
  { label: "RONCEY (50210)", value: "50210" },
  { label: "LE ROZEL (50340)", value: "50340" },
  { label: "SACEY (50170)", value: "50170" },
  { label: "ST AMAND VILLAGES (50160)", value: "50160" },
  { label: "ST AMAND VILLAGES (50160)", value: "50160" },
  { label: "ST AMAND VILLAGES (50160)", value: "50160" },
  { label: "ST AMAND VILLAGES (50160)", value: "50160" },
  { label: "ST ANDRE DE BOHON (50500)", value: "50500" },
  { label: "ST ANDRE DE L EPINE (50680)", value: "50680" },
  { label: "ST AUBIN DES PREAUX (50380)", value: "50380" },
  { label: "ST AUBIN DE TERREGATTE (50240)", value: "50240" },
  { label: "ST BARTHELEMY (50140)", value: "50140" },
  { label: "ST BRICE (50300)", value: "50300" },
  { label: "ST BRICE DE LANDELLES (50730)", value: "50730" },
  { label: "STE CECILE (50800)", value: "50800" },
  { label: "ST CHRISTOPHE DU FOC (50340)", value: "50340" },
  { label: "ST CLAIR SUR L ELLE (50680)", value: "50680" },
  { label: "ST CLEMENT RANCOUDRAY (50140)", value: "50140" },
  { label: "ST CLEMENT RANCOUDRAY (50140)", value: "50140" },
  { label: "STE COLOMBE (50390)", value: "50390" },
  { label: "ST CYR (50310)", value: "50310" },
  { label: "ST CYR DU BAILLEUL (50720)", value: "50720" },
  { label: "ST DENIS LE GAST (50450)", value: "50450" },
  { label: "ST DENIS LE VETU (50210)", value: "50210" },
  { label: "ST FLOXEL (50310)", value: "50310" },
  { label: "ST FROMOND (50620)", value: "50620" },
  { label: "STE GENEVIEVE (50760)", value: "50760" },
  { label: "ST GEORGES DE LA RIVIERE (50270)", value: "50270" },
  { label: "ST GEORGES DE LIVOYE (50370)", value: "50370" },
  { label: "ST GEORGES D ELLE (50680)", value: "50680" },
  { label: "ST GEORGES DE ROUELLEY (50720)", value: "50720" },
  { label: "ST GEORGES MONTCOCQ (50000)", value: "50000" },
  { label: "ST GERMAIN D ELLE (50810)", value: "50810" },
  { label: "ST GERMAIN DE TOURNEBUT (50700)", value: "50700" },
  { label: "ST GERMAIN DE VARREVILLE (50480)", value: "50480" },
  { label: "ST GERMAIN LE GAILLARD (50340)", value: "50340" },
  { label: "ST GERMAIN SUR AY (50430)", value: "50430" },
  { label: "ST GERMAIN SUR SEVES (50190)", value: "50190" },
  { label: "ST GILLES (50180)", value: "50180" },
  { label: "ST HILAIRE DU HARCOUET (50600)", value: "50600" },
  { label: "ST HILAIRE DU HARCOUET (50600)", value: "50600" },
  { label: "ST HILAIRE DU HARCOUET (50730)", value: "50730" },
  { label: "ST JACQUES DE NEHOU (50390)", value: "50390" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JAMES (50240)", value: "50240" },
  { label: "ST JEAN DE DAYE (50620)", value: "50620" },
  { label: "ST JEAN DE LA HAIZE (50300)", value: "50300" },
  { label: "ST JEAN DE LA RIVIERE (50270)", value: "50270" },
  { label: "ST JEAN DE SAVIGNY (50680)", value: "50680" },
  { label: "ST JEAN D ELLE (50810)", value: "50810" },
  { label: "ST JEAN D ELLE (50810)", value: "50810" },
  { label: "ST JEAN D ELLE (50810)", value: "50810" },
  { label: "ST JEAN D ELLE (50810)", value: "50810" },
  { label: "ST JEAN D ELLE (50810)", value: "50810" },
  { label: "ST JEAN DES CHAMPS (50320)", value: "50320" },
  { label: "ST JEAN DES CHAMPS (50320)", value: "50320" },
  { label: "ST JEAN DES CHAMPS (50320)", value: "50320" },
  { label: "ST JEAN DU CORAIL DES BOIS (50370)", value: "50370" },
  { label: "ST JEAN LE THOMAS (50530)", value: "50530" },
  { label: "ST JOSEPH (50700)", value: "50700" },
  { label: "ST LAURENT DE CUVES (50670)", value: "50670" },
  { label: "ST LAURENT DE TERREGATTE (50240)", value: "50240" },
  { label: "ST LO (50000)", value: "50000" },
  { label: "ST LOUET SUR VIRE (50420)", value: "50420" },
  { label: "ST LOUP (50300)", value: "50300" },
  { label: "ST MALO DE LA LANDE (50200)", value: "50200" },
  { label: "ST MARCOUF (50310)", value: "50310" },
  { label: "STE MARIE DU MONT (50480)", value: "50480" },
  { label: "ST MARTIN D AUBIGNY (50190)", value: "50190" },
  { label: "ST MARTIN D AUDOUVILLE (50310)", value: "50310" },
  { label: "ST MARTIN DE BONFOSSE (50750)", value: "50750" },
  { label: "ST MARTIN DE CENILLY (50210)", value: "50210" },
  { label: "CHAULIEU (50150)", value: "50150" },
  { label: "CHAULIEU (50150)", value: "50150" },
  { label: "ST MARTIN DE VARREVILLE (50480)", value: "50480" },
  { label: "ST MARTIN LE BOUILLANT (50800)", value: "50800" },
  { label: "ST MARTIN LE GREARD (50690)", value: "50690" },
  { label: "ST MAUR DES BOIS (50800)", value: "50800" },
  { label: "ST MAURICE EN COTENTIN (50270)", value: "50270" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "STE MERE EGLISE (50480)", value: "50480" },
  { label: "ST MICHEL DE MONTJOIE (50670)", value: "50670" },
  { label: "ST NICOLAS DE PIERREPONT (50250)", value: "50250" },
  { label: "ST NICOLAS DES BOIS (50370)", value: "50370" },
  { label: "ST OVIN (50300)", value: "50300" },
  { label: "ST OVIN (50300)", value: "50300" },
  { label: "ST PAIR SUR MER (50380)", value: "50380" },
  { label: "ST PATRICE DE CLAIDS (50190)", value: "50190" },
  { label: "LE PARC (50870)", value: "50870" },
  { label: "LE PARC (50870)", value: "50870" },
  { label: "LE PARC (50870)", value: "50870" },
  { label: "ST PIERRE D ARTHEGLISE (50270)", value: "50270" },
  { label: "ST PIERRE DE COUTANCES (50200)", value: "50200" },
  { label: "ST PIERRE DE SEMILLY (50810)", value: "50810" },
  { label: "ST PIERRE EGLISE (50330)", value: "50330" },
  { label: "ST PIERRE LANGERS (50530)", value: "50530" },
  { label: "ST PLANCHERS (50400)", value: "50400" },
  { label: "ST POIS (50670)", value: "50670" },
  { label: "ST QUENTIN SUR LE HOMME (50220)", value: "50220" },
  { label: "BOURGVALLEES (50750)", value: "50750" },
  { label: "BOURGVALLEES (50750)", value: "50750" },
  { label: "BOURGVALLEES (50750)", value: "50750" },
  { label: "BOURGVALLEES (50750)", value: "50750" },
  { label: "BOURGVALLEES (50750)", value: "50750" },
  { label: "BOURGVALLEES (50750)", value: "50750" },
  { label: "ST SAUVEUR DE PIERREPONT (50250)", value: "50250" },
  { label: "ST SAUVEUR LA POMMERAYE (50510)", value: "50510" },
  { label: "ST SAUVEUR VILLAGES (50200)", value: "50200" },
  { label: "ST SAUVEUR VILLAGES (50490)", value: "50490" },
  { label: "ST SAUVEUR VILLAGES (50490)", value: "50490" },
  { label: "ST SAUVEUR VILLAGES (50490)", value: "50490" },
  { label: "ST SAUVEUR VILLAGES (50490)", value: "50490" },
  { label: "ST SAUVEUR VILLAGES (50490)", value: "50490" },
  { label: "ST SAUVEUR VILLAGES (50490)", value: "50490" },
  { label: "ST SAUVEUR LE VICOMTE (50390)", value: "50390" },
  { label: "ST SEBASTIEN DE RAIDS (50190)", value: "50190" },
  { label: "ST SENIER DE BEUVRON (50240)", value: "50240" },
  { label: "ST SENIER SOUS AVRANCHES (50300)", value: "50300" },
  { label: "STE SUZANNE SUR VIRE (50750)", value: "50750" },
  { label: "ST VAAST LA HOUGUE (50550)", value: "50550" },
  { label: "ST VAAST LA HOUGUE (50550)", value: "50550" },
  { label: "ST VIGOR DES MONTS (50420)", value: "50420" },
  { label: "TERRE ET MARAIS (50500)", value: "50500" },
  { label: "TERRE ET MARAIS (50500)", value: "50500" },
  { label: "SARTILLY BAIE BOCAGE (50530)", value: "50530" },
  { label: "SARTILLY BAIE BOCAGE (50530)", value: "50530" },
  { label: "SARTILLY BAIE BOCAGE (50530)", value: "50530" },
  { label: "SARTILLY BAIE BOCAGE (50530)", value: "50530" },
  { label: "SARTILLY BAIE BOCAGE (50530)", value: "50530" },
  { label: "SAUSSEMESNIL (50700)", value: "50700" },
  { label: "SAUSSEY (50200)", value: "50200" },
  { label: "SAVIGNY (50210)", value: "50210" },
  { label: "SAVIGNY LE VIEUX (50640)", value: "50640" },
  { label: "SEBEVILLE (50480)", value: "50480" },
  { label: "SENOVILLE (50270)", value: "50270" },
  { label: "SERVON (50170)", value: "50170" },
  { label: "SIDEVILLE (50690)", value: "50690" },
  { label: "SIOUVILLE HAGUE (50340)", value: "50340" },
  { label: "SORTOSVILLE EN BEAUMONT (50270)", value: "50270" },
  { label: "SORTOSVILLE (50310)", value: "50310" },
  { label: "SOTTEVAST (50260)", value: "50260" },
  { label: "SOTTEVILLE (50340)", value: "50340" },
  { label: "SOURDEVAL (50150)", value: "50150" },
  { label: "SOURDEVAL (50150)", value: "50150" },
  { label: "SUBLIGNY (50870)", value: "50870" },
  { label: "SURTAINVILLE (50270)", value: "50270" },
  { label: "TAILLEPIED (50390)", value: "50390" },
  { label: "TAMERVILLE (50700)", value: "50700" },
  { label: "TANIS (50170)", value: "50170" },
  { label: "LE TANU (50320)", value: "50320" },
  { label: "LE TANU (50320)", value: "50320" },
  { label: "LE TEILLEUL (50640)", value: "50640" },
  { label: "LE TEILLEUL (50640)", value: "50640" },
  { label: "LE TEILLEUL (50640)", value: "50640" },
  { label: "LE TEILLEUL (50640)", value: "50640" },
  { label: "LE TEILLEUL (50640)", value: "50640" },
  { label: "TESSY BOCAGE (50420)", value: "50420" },
  { label: "TESSY BOCAGE (50420)", value: "50420" },
  { label: "TESSY BOCAGE (50420)", value: "50420" },
  { label: "TESSY BOCAGE (50420)", value: "50420" },
  { label: "TEURTHEVILLE BOCAGE (50630)", value: "50630" },
  { label: "TEURTHEVILLE HAGUE (50690)", value: "50690" },
  { label: "THEVILLE (50330)", value: "50330" },
  { label: "TIREPIED SUR SEE (50300)", value: "50300" },
  { label: "TIREPIED SUR SEE (50870)", value: "50870" },
  { label: "TIREPIED SUR SEE (50870)", value: "50870" },
  { label: "TOCQUEVILLE (50330)", value: "50330" },
  { label: "TOLLEVAST (50470)", value: "50470" },
  { label: "TORIGNY LES VILLES (50160)", value: "50160" },
  { label: "TORIGNY LES VILLES (50160)", value: "50160" },
  { label: "TORIGNY LES VILLES (50160)", value: "50160" },
  { label: "TORIGNY LES VILLES (50160)", value: "50160" },
  { label: "TOURVILLE SUR SIENNE (50200)", value: "50200" },
  { label: "TREAUVILLE (50340)", value: "50340" },
  { label: "TRIBEHOU (50620)", value: "50620" },
  { label: "LA TRINITE (50800)", value: "50800" },
  { label: "TURQUEVILLE (50480)", value: "50480" },
  { label: "URVILLE (50700)", value: "50700" },
  { label: "VAINS (50300)", value: "50300" },
  { label: "VALCANVILLE (50760)", value: "50760" },
  { label: "VALOGNES (50700)", value: "50700" },
  { label: "LE VAL ST PERE (50300)", value: "50300" },
  { label: "VARENGUEBEC (50250)", value: "50250" },
  { label: "VAROUVILLE (50330)", value: "50330" },
  { label: "LE VAST (50630)", value: "50630" },
  { label: "VAUDREVILLE (50310)", value: "50310" },
  { label: "LA VENDELEE (50200)", value: "50200" },
  { label: "VER (50450)", value: "50450" },
  { label: "VERNIX (50370)", value: "50370" },
  { label: "VESLY (50430)", value: "50430" },
  { label: "VESLY (50430)", value: "50430" },
  { label: "LE VICEL (50760)", value: "50760" },
  { label: "VIDECOSVILLE (50630)", value: "50630" },
  { label: "VILLEBAUDON (50410)", value: "50410" },
  { label: "VILLEDIEU LES POELES ROUFFIGNY (50800)", value: "50800" },
  { label: "VILLEDIEU LES POELES ROUFFIGNY (50800)", value: "50800" },
  { label: "VILLIERS FOSSARD (50680)", value: "50680" },
  { label: "VIRANDEVILLE (50690)", value: "50690" },
  { label: "YQUELON (50400)", value: "50400" },
  { label: "YVETOT BOCAGE (50700)", value: "50700" },
  { label: "ABLANCOURT (51240)", value: "51240" },
  { label: "ST MARTIN D ABLOIS (51530)", value: "51530" },
  { label: "AIGNY (51150)", value: "51150" },
  { label: "ALLEMANCHE LAUNAY ET SOYER (51260)", value: "51260" },
  { label: "ALLEMANT (51120)", value: "51120" },
  { label: "ALLIANCELLES (51250)", value: "51250" },
  { label: "AMBONNAY (51150)", value: "51150" },
  { label: "AMBRIERES (51290)", value: "51290" },
  { label: "ANGLURE (51260)", value: "51260" },
  { label: "ANGLUZELLES ET COURCELLES (51230)", value: "51230" },
  { label: "ANTHENAY (51700)", value: "51700" },
  { label: "AOUGNY (51170)", value: "51170" },
  { label: "ARCIS LE PONSART (51170)", value: "51170" },
  { label: "ARGERS (51800)", value: "51800" },
  { label: "ARRIGNY (51290)", value: "51290" },
  { label: "ARZILLIERES NEUVILLE (51290)", value: "51290" },
  { label: "ARZILLIERES NEUVILLE (51290)", value: "51290" },
  { label: "ATHIS (51150)", value: "51150" },
  { label: "AUBERIVE (51600)", value: "51600" },
  { label: "AUBILLY (51170)", value: "51170" },
  { label: "AULNAY L AITRE (51240)", value: "51240" },
  { label: "AULNAY SUR MARNE (51150)", value: "51150" },
  { label: "AUMENANCOURT (51110)", value: "51110" },
  { label: "AUVE (51800)", value: "51800" },
  { label: "AVENAY VAL D OR (51160)", value: "51160" },
  { label: "AVIZE (51190)", value: "51190" },
  { label: "AY CHAMPAGNE (51150)", value: "51150" },
  { label: "AY CHAMPAGNE (51160)", value: "51160" },
  { label: "AY CHAMPAGNE (51160)", value: "51160" },
  { label: "BACONNES (51400)", value: "51400" },
  { label: "BAGNEUX (51260)", value: "51260" },
  { label: "LE BAIZIL (51270)", value: "51270" },
  { label: "BANNAY (51270)", value: "51270" },
  { label: "BANNES (51230)", value: "51230" },
  { label: "BARBONNE FAYEL (51120)", value: "51120" },
  { label: "BASLIEUX LES FISMES (51170)", value: "51170" },
  { label: "BASLIEUX SOUS CHATILLON (51700)", value: "51700" },
  { label: "BASSU (51300)", value: "51300" },
  { label: "BASSUET (51300)", value: "51300" },
  { label: "BAUDEMENT (51260)", value: "51260" },
  { label: "BAYE (51270)", value: "51270" },
  { label: "BAZANCOURT (51110)", value: "51110" },
  { label: "BEAUMONT SUR VESLE (51360)", value: "51360" },
  { label: "BEAUNAY (51270)", value: "51270" },
  { label: "BEINE NAUROY (51490)", value: "51490" },
  { label: "BELVAL EN ARGONNE (51330)", value: "51330" },
  { label: "BELVAL SOUS CHATILLON (51480)", value: "51480" },
  { label: "BERGERES LES VERTUS (51130)", value: "51130" },
  { label: "BERGERES SOUS MONTMIRAIL (51210)", value: "51210" },
  { label: "BERMERICOURT (51220)", value: "51220" },
  { label: "BERRU (51420)", value: "51420" },
  { label: "BERZIEUX (51800)", value: "51800" },
  { label: "BETHENIVILLE (51490)", value: "51490" },
  { label: "BETHENY (51450)", value: "51450" },
  { label: "BETHON (51260)", value: "51260" },
  { label: "BETTANCOURT LA LONGUE (51330)", value: "51330" },
  { label: "BEZANNES (51430)", value: "51430" },
  { label: "BIGNICOURT SUR MARNE (51300)", value: "51300" },
  { label: "BIGNICOURT SUR SAULX (51340)", value: "51340" },
  { label: "BILLY LE GRAND (51400)", value: "51400" },
  { label: "BINARVILLE (51800)", value: "51800" },
  { label: "BLACY (51300)", value: "51300" },
  { label: "BLAISE SOUS ARZILLIERES (51300)", value: "51300" },
  { label: "BLESME (51340)", value: "51340" },
  { label: "BLIGNY (51170)", value: "51170" },
  { label: "BOISSY LE REPOS (51210)", value: "51210" },
  { label: "BOUCHY ST GENEST (51310)", value: "51310" },
  { label: "BOUILLY (51390)", value: "51390" },
  { label: "BOULEUSE (51170)", value: "51170" },
  { label: "BOULT SUR SUIPPE (51110)", value: "51110" },
  { label: "BOURGOGNE FRESNE (51110)", value: "51110" },
  { label: "BOURGOGNE FRESNE (51110)", value: "51110" },
  { label: "BOURSAULT (51480)", value: "51480" },
  { label: "BOUVANCOURT (51140)", value: "51140" },
  { label: "BOUY (51400)", value: "51400" },
  { label: "BOUZY (51150)", value: "51150" },
  { label: "BRANDONVILLERS (51290)", value: "51290" },
  { label: "BRANSCOURT (51140)", value: "51140" },
  { label: "BRAUX STE COHIERE (51800)", value: "51800" },
  { label: "BRAUX ST REMY (51800)", value: "51800" },
  { label: "BREBAN (51320)", value: "51320" },
  { label: "LE BREUIL (51210)", value: "51210" },
  { label: "BREUIL SUR VESLE (51140)", value: "51140" },
  { label: "BREUVERY SUR COOLE (51240)", value: "51240" },
  { label: "BRIMONT (51220)", value: "51220" },
  { label: "BROUILLET (51170)", value: "51170" },
  { label: "BROUSSY LE GRAND (51230)", value: "51230" },
  { label: "BROUSSY LE PETIT (51230)", value: "51230" },
  { label: "BROYES (51120)", value: "51120" },
  { label: "BRUGNY VAUDANCOURT (51530)", value: "51530" },
  { label: "BRUSSON (51300)", value: "51300" },
  { label: "LE BUISSON (51300)", value: "51300" },
  { label: "BUSSY LE CHATEAU (51600)", value: "51600" },
  { label: "BUSSY LE REPOS (51330)", value: "51330" },
  { label: "BUSSY LETTREE (51320)", value: "51320" },
  { label: "LA CAURE (51270)", value: "51270" },
  { label: "CAUREL (51110)", value: "51110" },
  { label: "CAUROY LES HERMONVILLE (51220)", value: "51220" },
  { label: "LA CELLE SOUS CHANTEMERLE (51260)", value: "51260" },
  { label: "CERNAY EN DORMOIS (51800)", value: "51800" },
  { label: "CERNAY LES REIMS (51420)", value: "51420" },
  { label: "CERNON (51240)", value: "51240" },
  { label: "CHAINTRIX BIERGES (51130)", value: "51130" },
  { label: "CHALONS EN CHAMPAGNE (51000)", value: "51000" },
  { label: "CHALONS SUR VESLE (51140)", value: "51140" },
  { label: "CHALTRAIT (51130)", value: "51130" },
  { label: "CHAMBRECY (51170)", value: "51170" },
  { label: "CHAMERY (51500)", value: "51500" },
  { label: "CHAMPAUBERT (51270)", value: "51270" },
  { label: "CHAMPFLEURY (51500)", value: "51500" },
  { label: "CHAMPGUYON (51310)", value: "51310" },
  { label: "CHAMPIGNEUL CHAMPAGNE (51150)", value: "51150" },
  { label: "CHAMPIGNY (51370)", value: "51370" },
  { label: "CHAMPILLON (51160)", value: "51160" },
  { label: "CHAMPLAT ET BOUJACOURT (51480)", value: "51480" },
  { label: "CHAMPVOISY (51700)", value: "51700" },
  { label: "CHANGY (51300)", value: "51300" },
  { label: "CHANTEMERLE (51260)", value: "51260" },
  { label: "CHAPELAINE (51290)", value: "51290" },
  { label: "LA CHAPELLE FELCOURT (51800)", value: "51800" },
  { label: "LA CHAPELLE LASSON (51260)", value: "51260" },
  { label: "LA CHAPELLE SOUS ORBAIS (51270)", value: "51270" },
  { label: "CHARLEVILLE (51120)", value: "51120" },
  { label: "CHARMONT (51330)", value: "51330" },
  { label: "LES CHARMONTOIS (51330)", value: "51330" },
  { label: "LE CHATELIER (51330)", value: "51330" },
  { label: "CHATELRAOULD ST LOUVENT (51300)", value: "51300" },
  { label: "CHATILLON SUR BROUE (51290)", value: "51290" },
  { label: "CHATILLON SUR MARNE (51700)", value: "51700" },
  { label: "CHATILLON SUR MORIN (51310)", value: "51310" },
  { label: "CHATRICES (51800)", value: "51800" },
  { label: "CHAUDEFONTAINE (51800)", value: "51800" },
  { label: "CHAUMUZY (51170)", value: "51170" },
  { label: "LA CHAUSSEE SUR MARNE (51240)", value: "51240" },
  { label: "CHAVOT COURCOURT (51530)", value: "51530" },
  { label: "LE CHEMIN (51800)", value: "51800" },
  { label: "CHEMINON (51250)", value: "51250" },
  { label: "CHENAY (51140)", value: "51140" },
  { label: "CHENIERS (51510)", value: "51510" },
  { label: "LA CHEPPE (51600)", value: "51600" },
  { label: "CHEPPES LA PRAIRIE (51240)", value: "51240" },
  { label: "CHEPY (51240)", value: "51240" },
  { label: "CHERVILLE (51150)", value: "51150" },
  { label: "CHICHEY (51120)", value: "51120" },
  { label: "CHIGNY LES ROSES (51500)", value: "51500" },
  { label: "CHOUILLY (51530)", value: "51530" },
  { label: "CLAMANGES (51130)", value: "51130" },
  { label: "CLESLES (51260)", value: "51260" },
  { label: "CLOYES SUR MARNE (51300)", value: "51300" },
  { label: "COIZARD JOCHES (51270)", value: "51270" },
  { label: "VAL DES MARAIS (51130)", value: "51130" },
  { label: "VAL DES MARAIS (51130)", value: "51130" },
  { label: "VAL DES MARAIS (51130)", value: "51130" },
  { label: "VAL DES MARAIS (51130)", value: "51130" },
  { label: "COMPERTRIX (51510)", value: "51510" },
  { label: "CONDE SUR MARNE (51150)", value: "51150" },
  { label: "CONFLANS SUR SEINE (51260)", value: "51260" },
  { label: "CONGY (51270)", value: "51270" },
  { label: "CONNANTRAY VAUREFROY (51230)", value: "51230" },
  { label: "CONNANTRE (51230)", value: "51230" },
  { label: "CONTAULT (51330)", value: "51330" },
  { label: "COOLE (51320)", value: "51320" },
  { label: "COOLUS (51510)", value: "51510" },
  { label: "CORBEIL (51320)", value: "51320" },
  { label: "CORFELIX (51210)", value: "51210" },
  { label: "CORMICY (51220)", value: "51220" },
  { label: "CORMICY (51220)", value: "51220" },
  { label: "CORMONTREUIL (51350)", value: "51350" },
  { label: "CORMOYEUX (51480)", value: "51480" },
  { label: "CORRIBERT (51270)", value: "51270" },
  { label: "CORROBERT (51210)", value: "51210" },
  { label: "CORROY (51230)", value: "51230" },
  { label: "COULOMMES LA MONTAGNE (51390)", value: "51390" },
  { label: "COUPETZ (51240)", value: "51240" },
  { label: "COUPEVILLE (51240)", value: "51240" },
  { label: "COURCELLES SAPICOURT (51140)", value: "51140" },
  { label: "COURCEMAIN (51260)", value: "51260" },
  { label: "COURCY (51220)", value: "51220" },
  { label: "COURDEMANGES (51300)", value: "51300" },
  { label: "COURGIVAUX (51310)", value: "51310" },
  { label: "COURJEONNET (51270)", value: "51270" },
  { label: "COURLANDON (51170)", value: "51170" },
  { label: "COURMAS (51390)", value: "51390" },
  { label: "COURTAGNON (51480)", value: "51480" },
  { label: "COURTEMONT (51800)", value: "51800" },
  { label: "COURTHIEZY (51700)", value: "51700" },
  { label: "COURTISOLS (51460)", value: "51460" },
  { label: "COURVILLE (51170)", value: "51170" },
  { label: "COUVROT (51300)", value: "51300" },
  { label: "CRAMANT (51530)", value: "51530" },
  { label: "LA CROIX EN CHAMPAGNE (51600)", value: "51600" },
  { label: "CRUGNY (51170)", value: "51170" },
  { label: "CUCHERY (51480)", value: "51480" },
  { label: "CUIS (51530)", value: "51530" },
  { label: "CUISLES (51700)", value: "51700" },
  { label: "CUMIERES (51480)", value: "51480" },
  { label: "CUPERLY (51400)", value: "51400" },
  { label: "DAMERY (51480)", value: "51480" },
  { label: "DAMPIERRE AU TEMPLE (51400)", value: "51400" },
  { label: "DAMPIERRE LE CHATEAU (51330)", value: "51330" },
  { label: "DAMPIERRE SUR MOIVRE (51240)", value: "51240" },
  { label: "DIZY (51530)", value: "51530" },
  { label: "DOMMARTIN DAMPIERRE (51800)", value: "51800" },
  { label: "DOMMARTIN LETTREE (51320)", value: "51320" },
  { label: "DOMMARTIN SOUS HANS (51800)", value: "51800" },
  { label: "DOMMARTIN VARIMONT (51330)", value: "51330" },
  { label: "DOMPREMY (51300)", value: "51300" },
  { label: "DONTRIEN (51490)", value: "51490" },
  { label: "DORMANS (51700)", value: "51700" },
  { label: "DORMANS (51700)", value: "51700" },
  { label: "VAL DE VIERE (51340)", value: "51340" },
  { label: "DROSNAY (51290)", value: "51290" },
  { label: "DROUILLY (51300)", value: "51300" },
  { label: "ECLAIRES (51800)", value: "51800" },
  { label: "ECOLLEMONT (51290)", value: "51290" },
  { label: "ECRIENNES (51300)", value: "51300" },
  { label: "ECUEIL (51500)", value: "51500" },
  { label: "ECURY LE REPOS (51230)", value: "51230" },
  { label: "ECURY SUR COOLE (51240)", value: "51240" },
  { label: "ELISE DAUCOURT (51800)", value: "51800" },
  { label: "EPENSE (51330)", value: "51330" },
  { label: "EPERNAY (51200)", value: "51200" },
  { label: "L'EPINE (51460)", value: "51460" },
  { label: "EPOYE (51490)", value: "51490" },
  { label: "ESCARDES (51310)", value: "51310" },
  { label: "ESCLAVOLLES LUREY (51260)", value: "51260" },
  { label: "LES ESSARTS LES SEZANNE (51120)", value: "51120" },
  { label: "LES ESSARTS LE VICOMTE (51310)", value: "51310" },
  { label: "ESTERNAY (51310)", value: "51310" },
  { label: "ETOGES (51270)", value: "51270" },
  { label: "ETRECHY (51130)", value: "51130" },
  { label: "ETREPY (51340)", value: "51340" },
  { label: "EUVY (51230)", value: "51230" },
  { label: "FAGNIERES (51510)", value: "51510" },
  { label: "FAUX FRESNAY (51230)", value: "51230" },
  { label: "FAUX VESIGNEUL (51320)", value: "51320" },
  { label: "FAUX VESIGNEUL (51320)", value: "51320" },
  { label: "FAVEROLLES ET COEMY (51170)", value: "51170" },
  { label: "FAVRESSE (51300)", value: "51300" },
  { label: "FEREBRIANGES (51270)", value: "51270" },
  { label: "FERE CHAMPENOISE (51230)", value: "51230" },
  { label: "FERE CHAMPENOISE (51230)", value: "51230" },
  { label: "FESTIGNY (51700)", value: "51700" },
  { label: "FISMES (51170)", value: "51170" },
  { label: "FLAVIGNY (51190)", value: "51190" },
  { label: "FLEURY LA RIVIERE (51480)", value: "51480" },
  { label: "FLORENT EN ARGONNE (51800)", value: "51800" },
  { label: "FONTAINE DENIS NUISY (51120)", value: "51120" },
  { label: "FONTAINE EN DORMOIS (51800)", value: "51800" },
  { label: "FONTAINE SUR AY (51160)", value: "51160" },
  { label: "LA FORESTIERE (51120)", value: "51120" },
  { label: "FRANCHEVILLE (51240)", value: "51240" },
  { label: "LE FRESNE (51240)", value: "51240" },
  { label: "FRIGNICOURT (51300)", value: "51300" },
  { label: "FROMENTIERES (51210)", value: "51210" },
  { label: "LE GAULT SOIGNY (51210)", value: "51210" },
  { label: "GAYE (51120)", value: "51120" },
  { label: "GERMAINE (51160)", value: "51160" },
  { label: "GERMIGNY (51390)", value: "51390" },
  { label: "GERMINON (51130)", value: "51130" },
  { label: "GIFFAUMONT CHAMPAUBERT (51290)", value: "51290" },
  { label: "GIGNY BUSSY (51290)", value: "51290" },
  { label: "GIVRY EN ARGONNE (51330)", value: "51330" },
  { label: "GIVRY LES LOISY (51130)", value: "51130" },
  { label: "GIZAUCOURT (51800)", value: "51800" },
  { label: "GLANNES (51300)", value: "51300" },
  { label: "GOURGANCON (51230)", value: "51230" },
  { label: "STE MARIE DU LAC NUISEMENT (51290)", value: "51290" },
  { label: "LES GRANDES LOGES (51400)", value: "51400" },
  { label: "GRANGES SUR AUBE (51260)", value: "51260" },
  { label: "GRATREUIL (51800)", value: "51800" },
  { label: "GRAUVES (51190)", value: "51190" },
  { label: "GUEUX (51390)", value: "51390" },
  { label: "HANS (51800)", value: "51800" },
  { label: "HAUSSIGNEMONT (51300)", value: "51300" },
  { label: "HAUSSIMONT (51320)", value: "51320" },
  { label: "HAUTEVILLE (51290)", value: "51290" },
  { label: "HAUTVILLERS (51160)", value: "51160" },
  { label: "HEILTZ LE HUTIER (51300)", value: "51300" },
  { label: "HEILTZ LE MAURUPT (51340)", value: "51340" },
  { label: "HEILTZ L EVEQUE (51340)", value: "51340" },
  { label: "HERMONVILLE (51220)", value: "51220" },
  { label: "HERPONT (51460)", value: "51460" },
  { label: "HEUTREGIVILLE (51110)", value: "51110" },
  { label: "HOURGES (51140)", value: "51140" },
  { label: "HUIRON (51300)", value: "51300" },
  { label: "HUMBAUVILLE (51320)", value: "51320" },
  { label: "IGNY COMBLIZY (51700)", value: "51700" },
  { label: "ISLES SUR SUIPPE (51110)", value: "51110" },
  { label: "ISLE SUR MARNE (51290)", value: "51290" },
  { label: "ISSE (51150)", value: "51150" },
  { label: "LES ISTRES ET BURY (51190)", value: "51190" },
  { label: "JALONS (51150)", value: "51150" },
  { label: "JANVILLIERS (51210)", value: "51210" },
  { label: "JANVRY (51390)", value: "51390" },
  { label: "JOISELLE (51310)", value: "51310" },
  { label: "JONCHERY SUR SUIPPE (51600)", value: "51600" },
  { label: "JONCHERY SUR VESLE (51140)", value: "51140" },
  { label: "JONQUERY (51700)", value: "51700" },
  { label: "JOUY LES REIMS (51390)", value: "51390" },
  { label: "JUSSECOURT MINECOURT (51340)", value: "51340" },
  { label: "JUVIGNY (51150)", value: "51150" },
  { label: "LACHY (51120)", value: "51120" },
  { label: "LAGERY (51170)", value: "51170" },
  { label: "LANDRICOURT (51290)", value: "51290" },
  { label: "LARZICOURT (51290)", value: "51290" },
  { label: "LAVAL SUR TOURBE (51600)", value: "51600" },
  { label: "LAVANNES (51110)", value: "51110" },
  { label: "LENHARREE (51230)", value: "51230" },
  { label: "LEUVRIGNY (51700)", value: "51700" },
  { label: "LHERY (51170)", value: "51170" },
  { label: "LIGNON (51290)", value: "51290" },
  { label: "LINTHELLES (51230)", value: "51230" },
  { label: "LINTHES (51230)", value: "51230" },
  { label: "LISSE EN CHAMPAGNE (51300)", value: "51300" },
  { label: "LIVRY LOUVERCY (51400)", value: "51400" },
  { label: "LOISY EN BRIE (51130)", value: "51130" },
  { label: "LOISY SUR MARNE (51300)", value: "51300" },
  { label: "LOIVRE (51220)", value: "51220" },
  { label: "LUDES (51500)", value: "51500" },
  { label: "LUXEMONT ET VILLOTTE (51300)", value: "51300" },
  { label: "MAFFRECOURT (51800)", value: "51800" },
  { label: "MAGNEUX (51170)", value: "51170" },
  { label: "MAILLY CHAMPAGNE (51500)", value: "51500" },
  { label: "MAIRY SUR MARNE (51240)", value: "51240" },
  { label: "MAISONS EN CHAMPAGNE (51300)", value: "51300" },
  { label: "MALMY (51800)", value: "51800" },
  { label: "MANCY (51530)", value: "51530" },
  { label: "MARCILLY SUR SEINE (51260)", value: "51260" },
  { label: "MARDEUIL (51530)", value: "51530" },
  { label: "MAREUIL EN BRIE (51270)", value: "51270" },
  { label: "MAREUIL LE PORT (51700)", value: "51700" },
  { label: "MAREUIL LE PORT (51700)", value: "51700" },
  { label: "MARFAUX (51170)", value: "51170" },
  { label: "MARGERIE HANCOURT (51290)", value: "51290" },
  { label: "MARGNY (51210)", value: "51210" },
  { label: "MARIGNY (51230)", value: "51230" },
  { label: "MAROLLES (51300)", value: "51300" },
  { label: "MARSANGIS (51260)", value: "51260" },
  { label: "MARSON (51240)", value: "51240" },
  { label: "MASSIGES (51800)", value: "51800" },
  { label: "MATIGNICOURT GONCOURT (51300)", value: "51300" },
  { label: "MATOUGUES (51510)", value: "51510" },
  { label: "MAURUPT LE MONTOIS (51340)", value: "51340" },
  { label: "MECRINGES (51210)", value: "51210" },
  { label: "LE MEIX ST EPOING (51120)", value: "51120" },
  { label: "LE MEIX TIERCELIN (51320)", value: "51320" },
  { label: "MERFY (51220)", value: "51220" },
  { label: "MERLAUT (51300)", value: "51300" },
  { label: "MERY PREMECY (51390)", value: "51390" },
  { label: "LES MESNEUX (51370)", value: "51370" },
  { label: "LE MESNIL SUR OGER (51190)", value: "51190" },
  { label: "MINAUCOURT LE MESNIL LES HURLUS (51800)", value: "51800" },
  { label: "MOEURS VERDEY (51120)", value: "51120" },
  { label: "MOIREMONT (51800)", value: "51800" },
  { label: "MOIVRE (51240)", value: "51240" },
  { label: "MONCETZ LONGEVAS (51470)", value: "51470" },
  { label: "MONCETZ L ABBAYE (51290)", value: "51290" },
  { label: "MONDEMENT MONTGIVROUX (51120)", value: "51120" },
  { label: "MONTBRE (51500)", value: "51500" },
  { label: "MONTGENOST (51260)", value: "51260" },
  { label: "MONTEPREUX (51320)", value: "51320" },
  { label: "MONTHELON (51530)", value: "51530" },
  { label: "MONTIGNY SUR VESLE (51140)", value: "51140" },
  { label: "MONTMIRAIL (51210)", value: "51210" },
  { label: "MONTMIRAIL (51210)", value: "51210" },
  { label: "MONTMORT LUCY (51270)", value: "51270" },
  { label: "MONTMORT LUCY (51270)", value: "51270" },
  { label: "MONT SUR COURVILLE (51170)", value: "51170" },
  { label: "MORANGIS (51530)", value: "51530" },
  { label: "MORSAINS (51210)", value: "51210" },
  { label: "MOSLINS (51530)", value: "51530" },
  { label: "MOURMELON LE GRAND (51400)", value: "51400" },
  { label: "MOURMELON LE PETIT (51400)", value: "51400" },
  { label: "MOUSSY (51530)", value: "51530" },
  { label: "MUIZON (51140)", value: "51140" },
  { label: "MUTIGNY (51160)", value: "51160" },
  { label: "NANTEUIL LA FORET (51480)", value: "51480" },
  { label: "NESLE LA REPOSTE (51120)", value: "51120" },
  { label: "NESLE LE REPONS (51700)", value: "51700" },
  { label: "LA NEUVILLE AUX BOIS (51330)", value: "51330" },
  { label: "LA NEUVILLE AUX LARRIS (51480)", value: "51480" },
  { label: "LA NEUVILLE AU PONT (51800)", value: "51800" },
  { label: "NEUVY (51310)", value: "51310" },
  { label: "NOGENT L ABBESSE (51420)", value: "51420" },
  { label: "NOIRLIEU (51330)", value: "51330" },
  { label: "NORROIS (51300)", value: "51300" },
  { label: "LA NOUE (51310)", value: "51310" },
  { label: "NUISEMENT SUR COOLE (51240)", value: "51240" },
  { label: "OEUILLY (51480)", value: "51480" },
  { label: "OGNES (51230)", value: "51230" },
  { label: "OIRY (51530)", value: "51530" },
  { label: "OLIZY (51700)", value: "51700" },
  { label: "OMEY (51240)", value: "51240" },
  { label: "ORBAIS L ABBAYE (51270)", value: "51270" },
  { label: "ORCONTE (51300)", value: "51300" },
  { label: "ORMES (51370)", value: "51370" },
  { label: "OUTINES (51290)", value: "51290" },
  { label: "OUTREPONT (51300)", value: "51300" },
  { label: "OYES (51120)", value: "51120" },
  { label: "PARGNY LES REIMS (51390)", value: "51390" },
  { label: "PARGNY SUR SAULX (51340)", value: "51340" },
  { label: "PASSAVANT EN ARGONNE (51800)", value: "51800" },
  { label: "PASSY GRIGNY (51700)", value: "51700" },
  { label: "PEAS (51120)", value: "51120" },
  { label: "LES PETITES LOGES (51400)", value: "51400" },
  { label: "PEVY (51140)", value: "51140" },
  { label: "PIERRE MORAINS (51130)", value: "51130" },
  { label: "PIERRY (51530)", value: "51530" },
  { label: "PLEURS (51230)", value: "51230" },
  { label: "PLICHANCOURT (51300)", value: "51300" },
  { label: "PLIVOT (51150)", value: "51150" },
  { label: "POCANCY (51130)", value: "51130" },
  { label: "POGNY (51240)", value: "51240" },
  { label: "POILLY (51170)", value: "51170" },
  { label: "POIX (51460)", value: "51460" },
  { label: "POMACLE (51110)", value: "51110" },
  { label: "PONTFAVERGER MORONVILLIERS (51490)", value: "51490" },
  { label: "PONTHION (51300)", value: "51300" },
  { label: "POSSESSE (51330)", value: "51330" },
  { label: "POTANGIS (51260)", value: "51260" },
  { label: "POUILLON (51220)", value: "51220" },
  { label: "POURCY (51480)", value: "51480" },
  { label: "PRINGY (51300)", value: "51300" },
  { label: "PROSNES (51400)", value: "51400" },
  { label: "PROUILLY (51140)", value: "51140" },
  { label: "PRUNAY (51360)", value: "51360" },
  { label: "PUISIEULX (51500)", value: "51500" },
  { label: "QUEUDES (51120)", value: "51120" },
  { label: "RAPSECOURT (51330)", value: "51330" },
  { label: "RECY (51520)", value: "51520" },
  { label: "REIMS (51100)", value: "51100" },
  { label: "REIMS (51100)", value: "51100" },
  { label: "REIMS LA BRULEE (51300)", value: "51300" },
  { label: "REMICOURT (51330)", value: "51330" },
  { label: "COEUR DE LA VALLEE (51480)", value: "51480" },
  { label: "COEUR DE LA VALLEE (51700)", value: "51700" },
  { label: "COEUR DE LA VALLEE (51700)", value: "51700" },
  { label: "REUVES (51120)", value: "51120" },
  { label: "REVEILLON (51310)", value: "51310" },
  { label: "RIEUX (51210)", value: "51210" },
  { label: "RILLY LA MONTAGNE (51500)", value: "51500" },
  { label: "LES RIVIERES HENRUEL (51300)", value: "51300" },
  { label: "ROMAIN (51140)", value: "51140" },
  { label: "ROMERY (51480)", value: "51480" },
  { label: "ROMIGNY (51170)", value: "51170" },
  { label: "ROSNAY (51390)", value: "51390" },
  { label: "ROUFFY (51130)", value: "51130" },
  { label: "ROUVROY RIPONT (51800)", value: "51800" },
  { label: "SACY (51500)", value: "51500" },
  { label: "ST AMAND SUR FION (51300)", value: "51300" },
  { label: "ST BON (51310)", value: "51310" },
  { label: "ST BRICE COURCELLES (51370)", value: "51370" },
  { label: "ST CHERON (51290)", value: "51290" },
  { label: "ST ETIENNE AU TEMPLE (51460)", value: "51460" },
  { label: "ST ETIENNE SUR SUIPPE (51110)", value: "51110" },
  { label: "ST EULIEN (52100)", value: "52100" },
  { label: "ST EUPHRAISE ET CLAIRIZET (51390)", value: "51390" },
  { label: "STE GEMME (51700)", value: "51700" },
  { label: "ST GERMAIN LA VILLE (51240)", value: "51240" },
  { label: "ST GIBRIEN (51510)", value: "51510" },
  { label: "ST GILLES (51170)", value: "51170" },
  { label: "ST HILAIRE AU TEMPLE (51400)", value: "51400" },
  { label: "ST HILAIRE LE GRAND (51600)", value: "51600" },
  { label: "ST HILAIRE LE PETIT (51490)", value: "51490" },
  { label: "ST IMOGES (51160)", value: "51160" },
  { label: "ST JEAN DEVANT POSSESSE (51330)", value: "51330" },
  { label: "ST JEAN SUR MOIVRE (51240)", value: "51240" },
  { label: "ST JEAN SUR TOURBE (51600)", value: "51600" },
  { label: "ST JUST SAUVAGE (51260)", value: "51260" },
  { label: "ST LEONARD (51500)", value: "51500" },
  { label: "ST LOUP (51120)", value: "51120" },
  { label: "ST LUMIER EN CHAMPAGNE (51300)", value: "51300" },
  { label: "ST LUMIER LA POPULEUSE (51340)", value: "51340" },
  { label: "ST MARD SUR AUVE (51800)", value: "51800" },
  { label: "ST MARD LES ROUFFY (51130)", value: "51130" },
  { label: "ST MARD SUR LE MONT (51330)", value: "51330" },
  { label: "STE MARIE A PY (51600)", value: "51600" },
  { label: "ST MARTIN AUX CHAMPS (51240)", value: "51240" },
  { label: "ST MARTIN L HEUREUX (51490)", value: "51490" },
  { label: "ST MARTIN SUR LE PRE (51520)", value: "51520" },
  { label: "ST MASMES (51490)", value: "51490" },
  { label: "ST MEMMIE (51470)", value: "51470" },
  { label: "STE MENEHOULD (51800)", value: "51800" },
  { label: "ST OUEN DOMPROT (51320)", value: "51320" },
  { label: "ST PIERRE (51510)", value: "51510" },
  { label: "ST QUENTIN LES MARAIS (51300)", value: "51300" },
  { label: "ST QUENTIN LE VERGER (51120)", value: "51120" },
  { label: "ST QUENTIN SUR COOLE (51240)", value: "51240" },
  { label: "ST REMY EN BOUZEMONT ST GENEST ISSON (51290)", value: "51290" },
  { label: "ST REMY SOUS BROYES (51120)", value: "51120" },
  { label: "ST REMY SUR BUSSY (51600)", value: "51600" },
  { label: "ST SATURNIN (51260)", value: "51260" },
  { label: "ST SOUPLET SUR PY (51600)", value: "51600" },
  { label: "ST THIERRY (51220)", value: "51220" },
  { label: "ST THOMAS EN ARGONNE (51800)", value: "51800" },
  { label: "ST UTIN (51290)", value: "51290" },
  { label: "ST VRAIN (51340)", value: "51340" },
  { label: "SAPIGNICOURT (52100)", value: "52100" },
  { label: "SARCY (51170)", value: "51170" },
  { label: "SARON SUR AUBE (51260)", value: "51260" },
  { label: "SARRY (51520)", value: "51520" },
  { label: "SAUDOY (51120)", value: "51120" },
  { label: "SAVIGNY SUR ARDRES (51170)", value: "51170" },
  { label: "SCRUPT (51340)", value: "51340" },
  { label: "SELLES (51490)", value: "51490" },
  { label: "SEPT SAULX (51400)", value: "51400" },
  { label: "SERMAIZE LES BAINS (51250)", value: "51250" },
  { label: "SERMIERS (51500)", value: "51500" },
  { label: "SERVON MELZICOURT (51800)", value: "51800" },
  { label: "SERZY ET PRIN (51170)", value: "51170" },
  { label: "SEZANNE (51120)", value: "51120" },
  { label: "SILLERY (51500)", value: "51500" },
  { label: "SIVRY ANTE (51800)", value: "51800" },
  { label: "SOGNY AUX MOULINS (51520)", value: "51520" },
  { label: "SOGNY EN L ANGLE (51340)", value: "51340" },
  { label: "SOIZY AUX BOIS (51120)", value: "51120" },
  { label: "SOMME BIONNE (51800)", value: "51800" },
  { label: "SOMMEPY TAHURE (51600)", value: "51600" },
  { label: "SOMMESOUS (51320)", value: "51320" },
  { label: "SOMME SUIPPE (51600)", value: "51600" },
  { label: "SOMME TOURBE (51600)", value: "51600" },
  { label: "SOMME VESLE (51460)", value: "51460" },
  { label: "SOMME YEVRE (51330)", value: "51330" },
  { label: "SOMPUIS (51320)", value: "51320" },
  { label: "SOMSOIS (51290)", value: "51290" },
  { label: "SONGY (51240)", value: "51240" },
  { label: "SOUAIN PERTHES LES HURLUS (51600)", value: "51600" },
  { label: "SOUDE (51320)", value: "51320" },
  { label: "SOUDRON (51320)", value: "51320" },
  { label: "SOULANGES (51300)", value: "51300" },
  { label: "SOULIERES (51130)", value: "51130" },
  { label: "SUIPPES (51600)", value: "51600" },
  { label: "SUIZY LE FRANC (51270)", value: "51270" },
  { label: "TAISSY (51500)", value: "51500" },
  { label: "TALUS ST PRIX (51270)", value: "51270" },
  { label: "VAL DE LIVRE (51150)", value: "51150" },
  { label: "VAL DE LIVRE (51150)", value: "51150" },
  { label: "THAAS (51230)", value: "51230" },
  { label: "THIBIE (51510)", value: "51510" },
  { label: "THIEBLEMONT FAREMONT (51300)", value: "51300" },
  { label: "THIL (51220)", value: "51220" },
  { label: "THILLOIS (51370)", value: "51370" },
  { label: "LE THOULT TROSNAY (51210)", value: "51210" },
  { label: "VAL DE VESLE (51360)", value: "51360" },
  { label: "TILLOY ET BELLAY (51460)", value: "51460" },
  { label: "TINQUEUX (51430)", value: "51430" },
  { label: "TOGNY AUX BOEUFS (51240)", value: "51240" },
  { label: "TOURS SUR MARNE (51150)", value: "51150" },
  { label: "TRAMERY (51170)", value: "51170" },
  { label: "TRECON (51130)", value: "51130" },
  { label: "TREFOLS (51210)", value: "51210" },
  { label: "TREPAIL (51380)", value: "51380" },
  { label: "TRESLON (51140)", value: "51140" },
  { label: "TRIGNY (51140)", value: "51140" },
  { label: "TROIS FONTAINES L ABBAYE (51340)", value: "51340" },
  { label: "TROIS PUITS (51500)", value: "51500" },
  { label: "TROISSY (51700)", value: "51700" },
  { label: "UNCHAIR (51170)", value: "51170" },
  { label: "VADENAY (51400)", value: "51400" },
  { label: "VALMY (51800)", value: "51800" },
  { label: "VANAULT LE CHATEL (51330)", value: "51330" },
  { label: "VANAULT LES DAMES (51340)", value: "51340" },
  { label: "VANDEUIL (51140)", value: "51140" },
  { label: "VANDIERES (51700)", value: "51700" },
  { label: "VASSIMONT ET CHAPELAINE (51320)", value: "51320" },
  { label: "VATRY (51320)", value: "51320" },
  { label: "VAUCHAMPS (51210)", value: "51210" },
  { label: "VAUCIENNES (51480)", value: "51480" },
  { label: "VAUCLERC (51300)", value: "51300" },
  { label: "VAUDEMANGE (51380)", value: "51380" },
  { label: "VAUDESINCOURT (51600)", value: "51600" },
  { label: "VAVRAY LE GRAND (51300)", value: "51300" },
  { label: "VAVRAY LE PETIT (51300)", value: "51300" },
  { label: "VELYE (51130)", value: "51130" },
  { label: "VENTELAY (51140)", value: "51140" },
  { label: "VENTEUIL (51480)", value: "51480" },
  { label: "VERDON (51210)", value: "51210" },
  { label: "VERNANCOURT (51330)", value: "51330" },
  { label: "VERNEUIL (51700)", value: "51700" },
  { label: "VERRIERES (51800)", value: "51800" },
  { label: "VERT TOULON (51130)", value: "51130" },
  { label: "VERT TOULON (51130)", value: "51130" },
  { label: "BLANCS COTEAUX (51130)", value: "51130" },
  { label: "BLANCS COTEAUX (51130)", value: "51130" },
  { label: "BLANCS COTEAUX (51130)", value: "51130" },
  { label: "BLANCS COTEAUX (51190)", value: "51190" },
  { label: "VERZENAY (51360)", value: "51360" },
  { label: "VERZY (51380)", value: "51380" },
  { label: "VESIGNEUL SUR MARNE (51240)", value: "51240" },
  { label: "LA VEUVE (51520)", value: "51520" },
  { label: "LE VEZIER (51210)", value: "51210" },
  { label: "LE VIEIL DAMPIERRE (51330)", value: "51330" },
  { label: "VIENNE LA VILLE (51800)", value: "51800" },
  { label: "VIENNE LE CHATEAU (51800)", value: "51800" },
  { label: "VILLE DOMMANGE (51390)", value: "51390" },
  { label: "VILLE EN SELVE (51500)", value: "51500" },
  { label: "VILLE EN TARDENOIS (51170)", value: "51170" },
  { label: "VILLENEUVE LA LIONNE (51310)", value: "51310" },
  { label: "LA VILLENEUVE LES CHARLEVILLE (51120)", value: "51120" },
  { label: "VILLENEUVE RENNEVILLE CHEVIGNY (51130)", value: "51130" },
  { label: "VILLENEUVE ST VISTRE ET VILLEVOTTE (51120)", value: "51120" },
  { label: "VILLERS ALLERAND (51500)", value: "51500" },
  { label: "VILLERS AUX BOIS (51130)", value: "51130" },
  { label: "VILLERS AUX NOEUDS (51500)", value: "51500" },
  { label: "VILLERS EN ARGONNE (51800)", value: "51800" },
  { label: "VILLERS FRANQUEUX (51220)", value: "51220" },
  { label: "VILLERS LE CHATEAU (51510)", value: "51510" },
  { label: "VILLERS LE SEC (51250)", value: "51250" },
  { label: "VILLERS MARMERY (51380)", value: "51380" },
  { label: "VILLESENEUX (51130)", value: "51130" },
  { label: "LA VILLE SOUS ORBAIS (51270)", value: "51270" },
  { label: "VILLE SUR TOURBE (51800)", value: "51800" },
  { label: "VILLEVENARD (51270)", value: "51270" },
  { label: "VILLIERS AUX CORNEILLES (51260)", value: "51260" },
  { label: "VINAY (51530)", value: "51530" },
  { label: "VINCELLES (51700)", value: "51700" },
  { label: "VINDEY (51120)", value: "51120" },
  { label: "VIRGINY (51800)", value: "51800" },
  { label: "VITRY EN PERTHOIS (51300)", value: "51300" },
  { label: "VITRY LA VILLE (51240)", value: "51240" },
  { label: "VITRY LA VILLE (51240)", value: "51240" },
  { label: "VITRY LE FRANCOIS (51300)", value: "51300" },
  { label: "VOILEMONT (51800)", value: "51800" },
  { label: "VOUARCES (51260)", value: "51260" },
  { label: "VOUILLERS (51340)", value: "51340" },
  { label: "VOUZY (51130)", value: "51130" },
  { label: "VRAUX (51150)", value: "51150" },
  { label: "VRIGNY (51390)", value: "51390" },
  { label: "VROIL (51330)", value: "51330" },
  { label: "WARGEMOULIN HURLUS (51800)", value: "51800" },
  { label: "WARMERIVILLE (51110)", value: "51110" },
  { label: "WITRY LES REIMS (51420)", value: "51420" },
  { label: "MAGENTA (51530)", value: "51530" },
  { label: "AGEVILLE (52340)", value: "52340" },
  { label: "AIGREMONT (52400)", value: "52400" },
  { label: "AILLIANVILLE (52700)", value: "52700" },
  { label: "AINGOULAINCOURT (52230)", value: "52230" },
  { label: "AIZANVILLE (52120)", value: "52120" },
  { label: "ALLICHAMPS (52130)", value: "52130" },
  { label: "AMBONVILLE (52110)", value: "52110" },
  { label: "ANDELOT BLANCHEVILLE (52700)", value: "52700" },
  { label: "ANDELOT BLANCHEVILLE (52700)", value: "52700" },
  { label: "ANDILLY EN BASSIGNY (52360)", value: "52360" },
  { label: "ANNEVILLE LA PRAIRIE (52310)", value: "52310" },
  { label: "ANNONVILLE (52230)", value: "52230" },
  { label: "ANROSEY (52500)", value: "52500" },
  { label: "APREY (52250)", value: "52250" },
  { label: "ARBIGNY SOUS VARENNES (52500)", value: "52500" },
  { label: "ARBOT (52160)", value: "52160" },
  { label: "ARC EN BARROIS (52210)", value: "52210" },
  { label: "ARNANCOURT (52110)", value: "52110" },
  { label: "ATTANCOURT (52130)", value: "52130" },
  { label: "AUBEPIERRE SUR AUBE (52210)", value: "52210" },
  { label: "AUBERIVE (52160)", value: "52160" },
  { label: "AUDELONCOURT (52240)", value: "52240" },
  { label: "AUJEURRES (52190)", value: "52190" },
  { label: "AULNOY SUR AUBE (52160)", value: "52160" },
  { label: "AUTIGNY LE GRAND (52300)", value: "52300" },
  { label: "AUTIGNY LE PETIT (52300)", value: "52300" },
  { label: "AUTREVILLE SUR LA RENNE (52120)", value: "52120" },
  { label: "AUTREVILLE SUR LA RENNE (52120)", value: "52120" },
  { label: "AUTREVILLE SUR LA RENNE (52330)", value: "52330" },
  { label: "AUTREVILLE SUR LA RENNE (52330)", value: "52330" },
  { label: "AVRECOURT (52140)", value: "52140" },
  { label: "BAILLY AUX FORGES (52130)", value: "52130" },
  { label: "BAISSEY (52250)", value: "52250" },
  { label: "BANNES (52360)", value: "52360" },
  { label: "BASSONCOURT (52240)", value: "52240" },
  { label: "BAUDRECOURT (52110)", value: "52110" },
  { label: "BAY SUR AUBE (52160)", value: "52160" },
  { label: "BEAUCHEMIN (52260)", value: "52260" },
  { label: "BELMONT (52500)", value: "52500" },
  { label: "ROCHES BETTAINCOURT (52270)", value: "52270" },
  { label: "ROCHES BETTAINCOURT (52270)", value: "52270" },
  { label: "ROCHES BETTAINCOURT (52270)", value: "52270" },
  { label: "BETTANCOURT LA FERREE (52100)", value: "52100" },
  { label: "BEURVILLE (52110)", value: "52110" },
  { label: "BIESLES (52340)", value: "52340" },
  { label: "BIESLES (52340)", value: "52340" },
  { label: "BIZE (52500)", value: "52500" },
  { label: "BLAISY (52330)", value: "52330" },
  { label: "BLECOURT (52300)", value: "52300" },
  { label: "BLESSONVILLE (52120)", value: "52120" },
  { label: "BLUMERAY (52110)", value: "52110" },
  { label: "BOLOGNE (52310)", value: "52310" },
  { label: "BOLOGNE (52310)", value: "52310" },
  { label: "BOLOGNE (52310)", value: "52310" },
  { label: "BONNECOURT (52360)", value: "52360" },
  { label: "BOURBONNE LES BAINS (52400)", value: "52400" },
  { label: "BOURBONNE LES BAINS (52400)", value: "52400" },
  { label: "BOURBONNE LES BAINS (52400)", value: "52400" },
  { label: "BOURDONS SUR ROGNON (52700)", value: "52700" },
  { label: "BOURG (52200)", value: "52200" },
  { label: "BOURG STE MARIE (52150)", value: "52150" },
  { label: "BOURMONT ENTRE MEUSE ET MOUZON (52150)", value: "52150" },
  { label: "BOURMONT ENTRE MEUSE ET MOUZON (52150)", value: "52150" },
  { label: "BOURMONT ENTRE MEUSE ET MOUZON (52150)", value: "52150" },
  { label: "BOURMONT ENTRE MEUSE ET MOUZON (52150)", value: "52150" },
  { label: "BOUZANCOURT (52110)", value: "52110" },
  { label: "BRACHAY (52110)", value: "52110" },
  { label: "BRAINVILLE SUR MEUSE (52150)", value: "52150" },
  { label: "BRAUX LE CHATEL (52120)", value: "52120" },
  { label: "BRENNES (52200)", value: "52200" },
  { label: "BRETHENAY (52000)", value: "52000" },
  { label: "BREUVANNES EN BASSIGNY (52240)", value: "52240" },
  { label: "BREUVANNES EN BASSIGNY (52240)", value: "52240" },
  { label: "BREUVANNES EN BASSIGNY (52240)", value: "52240" },
  { label: "BRIAUCOURT (52700)", value: "52700" },
  { label: "BRICON (52120)", value: "52120" },
  { label: "BROUSSEVAL (52130)", value: "52130" },
  { label: "BUGNIERES (52210)", value: "52210" },
  { label: "CHAMPSEVRAINE (52500)", value: "52500" },
  { label: "CHAMPSEVRAINE (52500)", value: "52500" },
  { label: "CHAMPSEVRAINE (52500)", value: "52500" },
  { label: "BUSSON (52700)", value: "52700" },
  { label: "BUXIERES LES CLEFMONT (52240)", value: "52240" },
  { label: "BUXIERES LES VILLIERS (52000)", value: "52000" },
  { label: "CEFFONDS (52220)", value: "52220" },
  { label: "CEFFONDS (52220)", value: "52220" },
  { label: "CEFFONDS (52220)", value: "52220" },
  { label: "CELLES EN BASSIGNY (52360)", value: "52360" },
  { label: "CELSOY (52600)", value: "52600" },
  { label: "CERISIERES (52320)", value: "52320" },
  { label: "CHALANCEY (52160)", value: "52160" },
  { label: "CHALINDREY (52600)", value: "52600" },
  { label: "VALS DES TILLES (52160)", value: "52160" },
  { label: "VALS DES TILLES (52160)", value: "52160" },
  { label: "VALS DES TILLES (52160)", value: "52160" },
  { label: "VALS DES TILLES (52160)", value: "52160" },
  { label: "VALS DES TILLES (52160)", value: "52160" },
  { label: "VALS DES TILLES (52160)", value: "52160" },
  { label: "CHALVRAINES (52700)", value: "52700" },
  { label: "CHAMBRONCOURT (52700)", value: "52700" },
  { label: "CHAMOUILLEY (52410)", value: "52410" },
  { label: "CHAMPIGNEULLES EN BASSIGNY (52150)", value: "52150" },
  { label: "CHAMPIGNY LES LANGRES (52200)", value: "52200" },
  { label: "CHAMPIGNY SOUS VARENNES (52400)", value: "52400" },
  { label: "CHANCENAY (52100)", value: "52100" },
  { label: "CHANGEY (52360)", value: "52360" },
  { label: "CHANOY (52260)", value: "52260" },
  { label: "CHANTRAINES (52700)", value: "52700" },
  { label: "CHARMES (52360)", value: "52360" },
  { label: "CHARMES EN L ANGLE (52110)", value: "52110" },
  { label: "CHARMES LA GRANDE (52110)", value: "52110" },
  { label: "CHASSIGNY (52190)", value: "52190" },
  { label: "CHATEAUVILLAIN (52120)", value: "52120" },
  { label: "CHATEAUVILLAIN (52120)", value: "52120" },
  { label: "CHATEAUVILLAIN (52120)", value: "52120" },
  { label: "CHATEAUVILLAIN (52120)", value: "52120" },
  { label: "CHATENAY MACHERON (52200)", value: "52200" },
  { label: "CHATENAY VAUDIN (52360)", value: "52360" },
  { label: "CHATONRUPT SOMMERMONT (52300)", value: "52300" },
  { label: "CHATONRUPT SOMMERMONT (52300)", value: "52300" },
  { label: "CHAUDENAY (52600)", value: "52600" },
  { label: "CHAUFFOURT (52140)", value: "52140" },
  { label: "CHAUMONT (52000)", value: "52000" },
  { label: "CHAUMONT (52000)", value: "52000" },
  { label: "CHAUMONT LA VILLE (52150)", value: "52150" },
  { label: "CHEVILLON (52170)", value: "52170" },
  { label: "CHEVILLON (52170)", value: "52170" },
  { label: "CHEVILLON (52170)", value: "52170" },
  { label: "CHEZEAUX (52400)", value: "52400" },
  { label: "CHAMARANDES CHOIGNES (52000)", value: "52000" },
  { label: "CHAMARANDES CHOIGNES (52000)", value: "52000" },
  { label: "CHAMARANDES CHOIGNES (52000)", value: "52000" },
  { label: "CHOILLEY DARDENAY (52190)", value: "52190" },
  { label: "CHOILLEY DARDENAY (52190)", value: "52190" },
  { label: "CHOISEUL (52240)", value: "52240" },
  { label: "CIREY LES MAREILLES (52700)", value: "52700" },
  { label: "CIREY SUR BLAISE (52110)", value: "52110" },
  { label: "CIRFONTAINES EN AZOIS (52370)", value: "52370" },
  { label: "CIRFONTAINES EN ORNOIS (52230)", value: "52230" },
  { label: "CLEFMONT (52240)", value: "52240" },
  { label: "CLINCHAMP (52700)", value: "52700" },
  { label: "COHONS (52600)", value: "52600" },
  { label: "COIFFY LE BAS (52400)", value: "52400" },
  { label: "COIFFY LE HAUT (52400)", value: "52400" },
  { label: "COLMIER LE BAS (52160)", value: "52160" },
  { label: "COLMIER LE HAUT (52160)", value: "52160" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "COLOMBEY LES DEUX EGLISES (52330)", value: "52330" },
  { label: "CONDES (52000)", value: "52000" },
  { label: "CONSIGNY (52700)", value: "52700" },
  { label: "COUBLANC (52500)", value: "52500" },
  { label: "COUPRAY (52210)", value: "52210" },
  { label: "COURCELLES EN MONTAGNE (52200)", value: "52200" },
  { label: "COURCELLES SUR BLAISE (52110)", value: "52110" },
  { label: "COUR L EVEQUE (52210)", value: "52210" },
  { label: "CULMONT (52600)", value: "52600" },
  { label: "CUREL (52300)", value: "52300" },
  { label: "CURMONT (52330)", value: "52330" },
  { label: "CUSEY (52190)", value: "52190" },
  { label: "CUSEY (52190)", value: "52190" },
  { label: "CUVES (52240)", value: "52240" },
  { label: "DAILLANCOURT (52110)", value: "52110" },
  { label: "DAILLECOURT (52240)", value: "52240" },
  { label: "DAMMARTIN SUR MEUSE (52140)", value: "52140" },
  { label: "DAMPIERRE (52360)", value: "52360" },
  { label: "DAMREMONT (52400)", value: "52400" },
  { label: "DANCEVOIR (52210)", value: "52210" },
  { label: "DARMANNES (52700)", value: "52700" },
  { label: "DINTEVILLE (52120)", value: "52120" },
  { label: "DOMBLAIN (52130)", value: "52130" },
  { label: "DOMMARIEN (52190)", value: "52190" },
  { label: "DOMMARTIN LE FRANC (52110)", value: "52110" },
  { label: "DOMMARTIN LE ST PERE (52110)", value: "52110" },
  { label: "DOMREMY LANDEVILLE (52270)", value: "52270" },
  { label: "DOMREMY LANDEVILLE (52270)", value: "52270" },
  { label: "DONCOURT SUR MEUSE (52150)", value: "52150" },
  { label: "DONJEUX (52300)", value: "52300" },
  { label: "DOULAINCOURT SAUCOURT (52270)", value: "52270" },
  { label: "DOULAINCOURT SAUCOURT (52270)", value: "52270" },
  { label: "DOULEVANT LE CHATEAU (52110)", value: "52110" },
  { label: "DOULEVANT LE CHATEAU (52110)", value: "52110" },
  { label: "DOULEVANT LE PETIT (52130)", value: "52130" },
  { label: "ECHENAY (52230)", value: "52230" },
  { label: "ECLARON BRAUCOURT STE LIVIERE (52290)", value: "52290" },
  { label: "ECLARON BRAUCOURT STE LIVIERE (52290)", value: "52290" },
  { label: "ECLARON BRAUCOURT STE LIVIERE (52290)", value: "52290" },
  { label: "ECOT LA COMBE (52700)", value: "52700" },
  { label: "EFFINCOURT (52300)", value: "52300" },
  { label: "ENFONVELLE (52400)", value: "52400" },
  { label: "EPIZON (52230)", value: "52230" },
  { label: "EPIZON (52230)", value: "52230" },
  { label: "EPIZON (52270)", value: "52270" },
  { label: "EPIZON (52270)", value: "52270" },
  { label: "LE VAL D ESNOMS (52190)", value: "52190" },
  { label: "LE VAL D ESNOMS (52190)", value: "52190" },
  { label: "LE VAL D ESNOMS (52190)", value: "52190" },
  { label: "LE VAL D ESNOMS (52190)", value: "52190" },
  { label: "ESNOUVEAUX (52340)", value: "52340" },
  { label: "EUFFIGNEIX (52000)", value: "52000" },
  { label: "EURVILLE BIENVILLE (52410)", value: "52410" },
  { label: "EURVILLE BIENVILLE (52410)", value: "52410" },
  { label: "FARINCOURT (52500)", value: "52500" },
  { label: "FAVEROLLES (52260)", value: "52260" },
  { label: "FAYL BILLOT (52500)", value: "52500" },
  { label: "FAYL BILLOT (52500)", value: "52500" },
  { label: "FAYL BILLOT (52500)", value: "52500" },
  { label: "FAYS (52130)", value: "52130" },
  { label: "FERRIERE ET LAFOLIE (52300)", value: "52300" },
  { label: "FLAGEY (52250)", value: "52250" },
  { label: "FLAMMERECOURT (52110)", value: "52110" },
  { label: "FONTAINES SUR MARNE (52170)", value: "52170" },
  { label: "FORCEY (52700)", value: "52700" },
  { label: "FOULAIN (52000)", value: "52000" },
  { label: "FOULAIN (52800)", value: "52800" },
  { label: "FRAMPAS (52220)", value: "52220" },
  { label: "FRECOURT (52360)", value: "52360" },
  { label: "FRESNES SUR APANCE (52400)", value: "52400" },
  { label: "FRONCLES (52320)", value: "52320" },
  { label: "FRONCLES (52320)", value: "52320" },
  { label: "FRONCLES (52320)", value: "52320" },
  { label: "FRONVILLE (52300)", value: "52300" },
  { label: "GENEVRIERES (52500)", value: "52500" },
  { label: "LA GENEVROYE (52320)", value: "52320" },
  { label: "GERMAINES (52160)", value: "52160" },
  { label: "GERMAINVILLIERS (52150)", value: "52150" },
  { label: "GERMAY (52230)", value: "52230" },
  { label: "GERMISAY (52230)", value: "52230" },
  { label: "GIEY SUR AUJON (52210)", value: "52210" },
  { label: "GILLANCOURT (52330)", value: "52330" },
  { label: "GILLAUME (52230)", value: "52230" },
  { label: "GILLEY (52500)", value: "52500" },
  { label: "GRAFFIGNY CHEMIN (52150)", value: "52150" },
  { label: "GRANDCHAMP (52600)", value: "52600" },
  { label: "GRENANT (52500)", value: "52500" },
  { label: "GUDMONT VILLIERS (52320)", value: "52320" },
  { label: "GUDMONT VILLIERS (52320)", value: "52320" },
  { label: "GUINDRECOURT AUX ORMES (52300)", value: "52300" },
  { label: "GUINDRECOURT SUR BLAISE (52330)", value: "52330" },
  { label: "GUYONVELLE (52400)", value: "52400" },
  { label: "HACOURT (52150)", value: "52150" },
  { label: "HALLIGNICOURT (52100)", value: "52100" },
  { label: "HARREVILLE LES CHANTEURS (52150)", value: "52150" },
  { label: "HEUILLEY LE GRAND (52600)", value: "52600" },
  { label: "HAUTE AMANCE (52600)", value: "52600" },
  { label: "HAUTE AMANCE (52600)", value: "52600" },
  { label: "HAUTE AMANCE (52600)", value: "52600" },
  { label: "HAUTE AMANCE (52600)", value: "52600" },
  { label: "HAUTE AMANCE (52600)", value: "52600" },
  { label: "HUILLIECOURT (52150)", value: "52150" },
  { label: "HUMBECOURT (52290)", value: "52290" },
  { label: "HUMBERVILLE (52700)", value: "52700" },
  { label: "HUMES JORQUENAY (52200)", value: "52200" },
  { label: "HUMES JORQUENAY (52200)", value: "52200" },
  { label: "ILLOUD (52150)", value: "52150" },
  { label: "IS EN BASSIGNY (52140)", value: "52140" },
  { label: "ISOMES (52190)", value: "52190" },
  { label: "JOINVILLE (52300)", value: "52300" },
  { label: "JONCHERY (52000)", value: "52000" },
  { label: "JONCHERY (52000)", value: "52000" },
  { label: "JONCHERY (52000)", value: "52000" },
  { label: "JUZENNECOURT (52330)", value: "52330" },
  { label: "LACHAPELLE EN BLAISY (52330)", value: "52330" },
  { label: "LAFAUCHE (52700)", value: "52700" },
  { label: "LAFERTE SUR AMANCE (52500)", value: "52500" },
  { label: "LAFERTE SUR AUBE (52120)", value: "52120" },
  { label: "LAMANCINE (52310)", value: "52310" },
  { label: "LANEUVELLE (52400)", value: "52400" },
  { label: "BAYARD SUR MARNE (52170)", value: "52170" },
  { label: "BAYARD SUR MARNE (52170)", value: "52170" },
  { label: "BAYARD SUR MARNE (52170)", value: "52170" },
  { label: "BAYARD SUR MARNE (52170)", value: "52170" },
  { label: "LANEUVILLE A REMY (52220)", value: "52220" },
  { label: "LANEUVILLE AU PONT (52100)", value: "52100" },
  { label: "LANGRES (52200)", value: "52200" },
  { label: "LANGRES (52200)", value: "52200" },
  { label: "LANQUES SUR ROGNON (52800)", value: "52800" },
  { label: "LANTY SUR AUBE (52120)", value: "52120" },
  { label: "LARIVIERE ARNONCOURT (52400)", value: "52400" },
  { label: "LARIVIERE ARNONCOURT (52400)", value: "52400" },
  { label: "LATRECEY ORMOY SUR AUBE (52120)", value: "52120" },
  { label: "LATRECEY ORMOY SUR AUBE (52120)", value: "52120" },
  { label: "LAVERNOY (52140)", value: "52140" },
  { label: "LAVILLE AUX BOIS (52000)", value: "52000" },
  { label: "LAVILLENEUVE (52140)", value: "52140" },
  { label: "LAVILLENEUVE AU ROI (52330)", value: "52330" },
  { label: "LECEY (52360)", value: "52360" },
  { label: "LEFFONDS (52210)", value: "52210" },
  { label: "LESCHERES SUR LE BLAISERON (52110)", value: "52110" },
  { label: "LEUCHEY (52190)", value: "52190" },
  { label: "LEURVILLE (52700)", value: "52700" },
  { label: "LEVECOURT (52150)", value: "52150" },
  { label: "LEZEVILLE (52230)", value: "52230" },
  { label: "LEZEVILLE (52230)", value: "52230" },
  { label: "LEZEVILLE (52230)", value: "52230" },
  { label: "LIFFOL LE PETIT (52700)", value: "52700" },
  { label: "LES LOGES (52500)", value: "52500" },
  { label: "LONGCHAMP (52240)", value: "52240" },
  { label: "LONGEAU PERCEY (52250)", value: "52250" },
  { label: "LONGEAU PERCEY (52250)", value: "52250" },
  { label: "LONGEAU PERCEY (52250)", value: "52250" },
  { label: "LOUVEMONT (52130)", value: "52130" },
  { label: "LOUVIERES (52800)", value: "52800" },
  { label: "LUZY SUR MARNE (52000)", value: "52000" },
  { label: "MAATZ (52500)", value: "52500" },
  { label: "MAGNEUX (52130)", value: "52130" },
  { label: "MAISONCELLES (52240)", value: "52240" },
  { label: "MAIZIERES (52300)", value: "52300" },
  { label: "MAIZIERES SUR AMANCE (52500)", value: "52500" },
  { label: "MALAINCOURT SUR MEUSE (52150)", value: "52150" },
  { label: "MANDRES LA COTE (52800)", value: "52800" },
  { label: "MANOIS (52700)", value: "52700" },
  { label: "MARAC (52260)", value: "52260" },
  { label: "MARANVILLE (52370)", value: "52370" },
  { label: "MARBEVILLE (52320)", value: "52320" },
  { label: "MARCILLY EN BASSIGNY (52360)", value: "52360" },
  { label: "MARDOR (52200)", value: "52200" },
  { label: "MAREILLES (52700)", value: "52700" },
  { label: "MARNAY SUR MARNE (52800)", value: "52800" },
  { label: "MATHONS (52300)", value: "52300" },
  { label: "MELAY (52400)", value: "52400" },
  { label: "MENNOUVEAUX (52240)", value: "52240" },
  { label: "MERREY (52240)", value: "52240" },
  { label: "MERTRUD (52110)", value: "52110" },
  { label: "MEURES (52310)", value: "52310" },
  { label: "MILLIERES (52240)", value: "52240" },
  { label: "MIRBEL (52320)", value: "52320" },
  { label: "MOESLAINS (52100)", value: "52100" },
  { label: "MONTCHARVOT (52400)", value: "52400" },
  { label: "MONTHERIES (52330)", value: "52330" },
  { label: "LA PORTE DU DER (52220)", value: "52220" },
  { label: "LA PORTE DU DER (52220)", value: "52220" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52140)", value: "52140" },
  { label: "VAL DE MEUSE (52240)", value: "52240" },
  { label: "MONTOT SUR ROGNON (52700)", value: "52700" },
  { label: "MONTREUIL SUR BLAISE (52130)", value: "52130" },
  { label: "MONTREUIL SUR THONNANCE (52230)", value: "52230" },
  { label: "MORANCOURT (52110)", value: "52110" },
  { label: "MORIONVILLIERS (52700)", value: "52700" },
  { label: "MOUILLERON (52160)", value: "52160" },
  { label: "MUSSEY SUR MARNE (52300)", value: "52300" },
  { label: "NARCY (52170)", value: "52170" },
  { label: "NEUILLY L EVEQUE (52360)", value: "52360" },
  { label: "NEUILLY SUR SUIZE (52000)", value: "52000" },
  { label: "NEUVELLE LES VOISEY (52400)", value: "52400" },
  { label: "NINVILLE (52800)", value: "52800" },
  { label: "NOGENT (52800)", value: "52800" },
  { label: "NOGENT (52800)", value: "52800" },
  { label: "NOGENT (52800)", value: "52800" },
  { label: "NOGENT (52800)", value: "52800" },
  { label: "NOIDANT CHATENOY (52600)", value: "52600" },
  { label: "NOIDANT LE ROCHEUX (52200)", value: "52200" },
  { label: "NOMECOURT (52300)", value: "52300" },
  { label: "NONCOURT SUR LE RONGEANT (52230)", value: "52230" },
  { label: "NOYERS (52240)", value: "52240" },
  { label: "NULLY (52110)", value: "52110" },
  { label: "OCCEY (52190)", value: "52190" },
  { label: "ORBIGNY AU MONT (52360)", value: "52360" },
  { label: "ORBIGNY AU VAL (52360)", value: "52360" },
  { label: "ORCEVAUX (52250)", value: "52250" },
  { label: "ORGES (52120)", value: "52120" },
  { label: "ORMANCEY (52200)", value: "52200" },
  { label: "ORMOY LES SEXFONTAINES (52310)", value: "52310" },
  { label: "ORQUEVAUX (52700)", value: "52700" },
  { label: "OSNE LE VAL (52300)", value: "52300" },
  { label: "OUDINCOURT (52310)", value: "52310" },
  { label: "OUTREMECOURT (52150)", value: "52150" },
  { label: "OZIERES (52700)", value: "52700" },
  { label: "LE PAILLY (52600)", value: "52600" },
  { label: "PALAISEUL (52600)", value: "52600" },
  { label: "PANSEY (52230)", value: "52230" },
  { label: "PARNOY EN BASSIGNY (52400)", value: "52400" },
  { label: "PARNOY EN BASSIGNY (52400)", value: "52400" },
  { label: "PARNOY EN BASSIGNY (52400)", value: "52400" },
  { label: "PAROY SUR SAULX (52300)", value: "52300" },
  { label: "PEIGNEY (52200)", value: "52200" },
  { label: "PERRANCEY LES VIEUX MOULINS (52200)", value: "52200" },
  { label: "PERRANCEY LES VIEUX MOULINS (52200)", value: "52200" },
  { label: "PERROGNEY LES FONTAINES (52160)", value: "52160" },
  { label: "PERROGNEY LES FONTAINES (52160)", value: "52160" },
  { label: "PERRUSSE (52240)", value: "52240" },
  { label: "PERTHES (52100)", value: "52100" },
  { label: "PIERREMONT SUR AMANCE (52500)", value: "52500" },
  { label: "PIERREMONT SUR AMANCE (52500)", value: "52500" },
  { label: "PIERREMONT SUR AMANCE (52500)", value: "52500" },
  { label: "PISSELOUP (52500)", value: "52500" },
  { label: "PLANRUPT (52220)", value: "52220" },
  { label: "PLESNOY (52360)", value: "52360" },
  { label: "POINSENOT (52160)", value: "52160" },
  { label: "POINSON LES FAYL (52500)", value: "52500" },
  { label: "POINSON LES GRANCEY (52160)", value: "52160" },
  { label: "POINSON LES NOGENT (52800)", value: "52800" },
  { label: "POISEUL (52360)", value: "52360" },
  { label: "POISSONS (52230)", value: "52230" },
  { label: "PONT LA VILLE (52120)", value: "52120" },
  { label: "LE CHATELET SUR MEUSE (52400)", value: "52400" },
  { label: "LE CHATELET SUR MEUSE (52400)", value: "52400" },
  { label: "LE CHATELET SUR MEUSE (52400)", value: "52400" },
  { label: "POULANGY (52800)", value: "52800" },
  { label: "PRASLAY (52160)", value: "52160" },
  { label: "LE MONTSAUGEONNAIS (52190)", value: "52190" },
  { label: "LE MONTSAUGEONNAIS (52190)", value: "52190" },
  { label: "LE MONTSAUGEONNAIS (52190)", value: "52190" },
  { label: "PRESSIGNY (52500)", value: "52500" },
  { label: "PREZ SOUS LAFAUCHE (52700)", value: "52700" },
  { label: "RIVES DERVOISES (52220)", value: "52220" },
  { label: "RIVES DERVOISES (52220)", value: "52220" },
  { label: "RIVES DERVOISES (52220)", value: "52220" },
  { label: "RIVES DERVOISES (52220)", value: "52220" },
  { label: "RACHECOURT SUZEMONT (52130)", value: "52130" },
  { label: "RACHECOURT SUR MARNE (52170)", value: "52170" },
  { label: "RANCONNIERES (52140)", value: "52140" },
  { label: "RANGECOURT (52140)", value: "52140" },
  { label: "RENNEPONT (52370)", value: "52370" },
  { label: "REYNEL (52700)", value: "52700" },
  { label: "RIAUCOURT (52000)", value: "52000" },
  { label: "RICHEBOURG (52120)", value: "52120" },
  { label: "RIMAUCOURT (52700)", value: "52700" },
  { label: "RIVIERES LE BOIS (52600)", value: "52600" },
  { label: "RIVIERE LES FOSSES (52190)", value: "52190" },
  { label: "RIZAUCOURT BUCHEY (52330)", value: "52330" },
  { label: "RIZAUCOURT BUCHEY (52330)", value: "52330" },
  { label: "ROCHEFORT SUR LA COTE (52700)", value: "52700" },
  { label: "ROCHES SUR MARNE (52410)", value: "52410" },
  { label: "ROCHETAILLEE (52210)", value: "52210" },
  { label: "ROCHETAILLEE (52210)", value: "52210" },
  { label: "ROLAMPONT (52260)", value: "52260" },
  { label: "ROLAMPONT (52260)", value: "52260" },
  { label: "ROLAMPONT (52260)", value: "52260" },
  { label: "ROLAMPONT (52260)", value: "52260" },
  { label: "ROMAIN SUR MEUSE (52150)", value: "52150" },
  { label: "ROUECOURT (52320)", value: "52320" },
  { label: "ROUELLES (52160)", value: "52160" },
  { label: "ROUGEUX (52500)", value: "52500" },
  { label: "ROUVRES SUR AUBE (52160)", value: "52160" },
  { label: "ROUVROY SUR MARNE (52300)", value: "52300" },
  { label: "RUPT (52300)", value: "52300" },
  { label: "SAILLY (52230)", value: "52230" },
  { label: "ST BLIN (52700)", value: "52700" },
  { label: "ST BROINGT LE BOIS (52190)", value: "52190" },
  { label: "ST BROINGT LES FOSSES (52190)", value: "52190" },
  { label: "ST CIERGUES (52200)", value: "52200" },
  { label: "ST DIZIER (52100)", value: "52100" },
  { label: "SAINTS GEOSMES (52200)", value: "52200" },
  { label: "SAINTS GEOSMES (52200)", value: "52200" },
  { label: "ST LOUP SUR AUJON (52210)", value: "52210" },
  { label: "ST LOUP SUR AUJON (52210)", value: "52210" },
  { label: "ST LOUP SUR AUJON (52210)", value: "52210" },
  { label: "ST MARTIN LES LANGRES (52200)", value: "52200" },
  { label: "ST MAURICE (52200)", value: "52200" },
  { label: "ST THIEBAULT (52150)", value: "52150" },
  { label: "ST URBAIN MACONCOURT (52300)", value: "52300" },
  { label: "ST URBAIN MACONCOURT (52300)", value: "52300" },
  { label: "ST VALLIER SUR MARNE (52200)", value: "52200" },
  { label: "SARCEY (52800)", value: "52800" },
  { label: "SARREY (52140)", value: "52140" },
  { label: "SAUDRON (52230)", value: "52230" },
  { label: "SAULLES (52500)", value: "52500" },
  { label: "SAULXURES (52140)", value: "52140" },
  { label: "SAVIGNY (52500)", value: "52500" },
  { label: "SEMILLY (52700)", value: "52700" },
  { label: "SEMOUTIERS MONTSAON (52000)", value: "52000" },
  { label: "SEMOUTIERS MONTSAON (52000)", value: "52000" },
  { label: "SERQUEUX (52400)", value: "52400" },
  { label: "SEXFONTAINES (52330)", value: "52330" },
  { label: "SIGNEVILLE (52700)", value: "52700" },
  { label: "SILVAROUVRES (52120)", value: "52120" },
  { label: "SOMMANCOURT (52130)", value: "52130" },
  { label: "SOMMERECOURT (52150)", value: "52150" },
  { label: "SOMMEVOIRE (52220)", value: "52220" },
  { label: "SOMMEVOIRE (52220)", value: "52220" },
  { label: "SONCOURT SUR MARNE (52320)", value: "52320" },
  { label: "SOULAUCOURT SUR MOUZON (52150)", value: "52150" },
  { label: "SOYERS (52400)", value: "52400" },
  { label: "SUZANNECOURT (52300)", value: "52300" },
  { label: "TERNAT (52210)", value: "52210" },
  { label: "THILLEUX (52220)", value: "52220" },
  { label: "THIVET (52800)", value: "52800" },
  { label: "THOL LES MILLIERES (52240)", value: "52240" },
  { label: "THONNANCE LES JOINVILLE (52300)", value: "52300" },
  { label: "THONNANCE LES MOULINS (52230)", value: "52230" },
  { label: "THONNANCE LES MOULINS (52230)", value: "52230" },
  { label: "THONNANCE LES MOULINS (52230)", value: "52230" },
  { label: "THONNANCE LES MOULINS (52230)", value: "52230" },
  { label: "TORCENAY (52600)", value: "52600" },
  { label: "TORNAY (52500)", value: "52500" },
  { label: "TREIX (52000)", value: "52000" },
  { label: "TREMILLY (52110)", value: "52110" },
  { label: "TROISFONTAINES LA VILLE (52130)", value: "52130" },
  { label: "TROISFONTAINES LA VILLE (52130)", value: "52130" },
  { label: "TROISFONTAINES LA VILLE (52130)", value: "52130" },
  { label: "TROISFONTAINES LA VILLE (52130)", value: "52130" },
  { label: "VAILLANT (52160)", value: "52160" },
  { label: "VALCOURT (52100)", value: "52100" },
  { label: "VALLERET (52130)", value: "52130" },
  { label: "VALLEROY (52500)", value: "52500" },
  { label: "VARENNES SUR AMANCE (52400)", value: "52400" },
  { label: "VAUDRECOURT (52150)", value: "52150" },
  { label: "VAUDREMONT (52330)", value: "52330" },
  { label: "VAUXBONS (52200)", value: "52200" },
  { label: "VAUX SUR BLAISE (52130)", value: "52130" },
  { label: "VAUX SUR ST URBAIN (52300)", value: "52300" },
  { label: "VECQUEVILLE (52300)", value: "52300" },
  { label: "VELLES (52500)", value: "52500" },
  { label: "VERBIESLES (52000)", value: "52000" },
  { label: "VERSEILLES LE BAS (52250)", value: "52250" },
  { label: "VERSEILLES LE HAUT (52250)", value: "52250" },
  { label: "VESAIGNES SOUS LAFAUCHE (52700)", value: "52700" },
  { label: "VESAIGNES SUR MARNE (52800)", value: "52800" },
  { label: "VESVRES SOUS CHALANCEY (52190)", value: "52190" },
  { label: "VICQ (52400)", value: "52400" },
  { label: "VIEVILLE (52310)", value: "52310" },
  { label: "VIGNES LA COTE (52700)", value: "52700" },
  { label: "VIGNORY (52320)", value: "52320" },
  { label: "VILLARS EN AZOIS (52120)", value: "52120" },
  { label: "VILLARS SANTENOGE (52160)", value: "52160" },
  { label: "VILLARS SANTENOGE (52160)", value: "52160" },
  { label: "VILLARS SANTENOGE (52160)", value: "52160" },
  { label: "VILLE EN BLAISOIS (52130)", value: "52130" },
  { label: "VILLEGUSIEN LE LAC (52190)", value: "52190" },
  { label: "VILLEGUSIEN LE LAC (52190)", value: "52190" },
  { label: "VILLEGUSIEN LE LAC (52190)", value: "52190" },
  { label: "VILLEGUSIEN LE LAC (52190)", value: "52190" },
  { label: "VILLEGUSIEN LE LAC (52600)", value: "52600" },
  { label: "VILLIERS EN LIEU (52100)", value: "52100" },
  { label: "VILLIERS LE SEC (52000)", value: "52000" },
  { label: "VILLIERS LES APREY (52190)", value: "52190" },
  { label: "VILLIERS SUR SUIZE (52210)", value: "52210" },
  { label: "VIOLOT (52600)", value: "52600" },
  { label: "VITRY EN MONTAGNE (52160)", value: "52160" },
  { label: "VITRY LES NOGENT (52800)", value: "52800" },
  { label: "VIVEY (52160)", value: "52160" },
  { label: "VOILLECOMTE (52130)", value: "52130" },
  { label: "VOISEY (52400)", value: "52400" },
  { label: "VOISEY (52400)", value: "52400" },
  { label: "VOISINES (52200)", value: "52200" },
  { label: "VONCOURT (52500)", value: "52500" },
  { label: "VOUECOURT (52320)", value: "52320" },
  { label: "VRAINCOURT (52310)", value: "52310" },
  { label: "VRONCOURT LA COTE (52240)", value: "52240" },
  { label: "WASSY (52130)", value: "52130" },
  { label: "AHUILLE (53940)", value: "53940" },
  { label: "ALEXAIN (53240)", value: "53240" },
  { label: "AMBRIERES LES VALLEES (53300)", value: "53300" },
  { label: "AMBRIERES LES VALLEES (53300)", value: "53300" },
  { label: "ANDOUILLE (53240)", value: "53240" },
  { label: "ARGENTRE (53210)", value: "53210" },
  { label: "ARON (53440)", value: "53440" },
  { label: "ARQUENAY (53170)", value: "53170" },
  { label: "ASSE LE BERENGER (53600)", value: "53600" },
  { label: "ASTILLE (53230)", value: "53230" },
  { label: "ATHEE (53400)", value: "53400" },
  { label: "AVERTON (53700)", value: "53700" },
  { label: "LA BACONNIERE (53240)", value: "53240" },
  { label: "BAIS (53160)", value: "53160" },
  { label: "VAL DU MAINE (53340)", value: "53340" },
  { label: "VAL DU MAINE (53340)", value: "53340" },
  { label: "BALLOTS (53350)", value: "53350" },
  { label: "BANNES (53340)", value: "53340" },
  { label: "LA BAZOGE MONTPINCON (53440)", value: "53440" },
  { label: "LA BAZOUGE DE CHEMERE (53170)", value: "53170" },
  { label: "LA BAZOUGE DES ALLEUX (53470)", value: "53470" },
  { label: "BAZOUGERS (53170)", value: "53170" },
  { label: "BEAULIEU SUR OUDON (53320)", value: "53320" },
  { label: "BEAUMONT PIED DE BOEUF (53290)", value: "53290" },
  { label: "BELGEARD (53440)", value: "53440" },
  { label: "BIERNE LES VILLAGES (53290)", value: "53290" },
  { label: "BIERNE LES VILLAGES (53290)", value: "53290" },
  { label: "BIERNE LES VILLAGES (53290)", value: "53290" },
  { label: "BIERNE LES VILLAGES (53290)", value: "53290" },
  { label: "LE BIGNON DU MAINE (53170)", value: "53170" },
  { label: "LA BIGOTTIERE (53240)", value: "53240" },
  { label: "LA BOISSIERE (53800)", value: "53800" },
  { label: "BONCHAMP LES LAVAL (53960)", value: "53960" },
  { label: "BOUCHAMPS LES CRAON (53800)", value: "53800" },
  { label: "BOUERE (53290)", value: "53290" },
  { label: "BOUESSAY (53290)", value: "53290" },
  { label: "BOULAY LES IFS (53370)", value: "53370" },
  { label: "LE BOURGNEUF LA FORET (53410)", value: "53410" },
  { label: "BOURGON (53410)", value: "53410" },
  { label: "BRAINS SUR LES MARCHES (53350)", value: "53350" },
  { label: "BRECE (53120)", value: "53120" },
  { label: "BREE (53150)", value: "53150" },
  { label: "LA BRULATTE (53410)", value: "53410" },
  { label: "LE BURET (53170)", value: "53170" },
  { label: "CARELLES (53120)", value: "53120" },
  { label: "CHAILLAND (53420)", value: "53420" },
  { label: "CHALONS DU MAINE (53470)", value: "53470" },
  { label: "CHAMPEON (53640)", value: "53640" },
  { label: "CHAMPFREMONT (53370)", value: "53370" },
  { label: "CHAMPGENETEUX (53160)", value: "53160" },
  { label: "CHANGE (53810)", value: "53810" },
  { label: "CHANTRIGNE (53300)", value: "53300" },
  { label: "LA CHAPELLE ANTHENAISE (53950)", value: "53950" },
  { label: "LA CHAPELLE AU RIBOUL (53440)", value: "53440" },
  { label: "LA CHAPELLE CRAONNAISE (53230)", value: "53230" },
  { label: "LA CHAPELLE RAINSOUIN (53150)", value: "53150" },
  { label: "CHARCHIGNE (53250)", value: "53250" },
  { label: "CHATEAU GONTIER SUR MAYENNE (53200)", value: "53200" },
  { label: "CHATEAU GONTIER SUR MAYENNE (53200)", value: "53200" },
  { label: "CHATEAU GONTIER SUR MAYENNE (53200)", value: "53200" },
  { label: "CHATEAU GONTIER SUR MAYENNE (53200)", value: "53200" },
  { label: "CHATELAIN (53200)", value: "53200" },
  { label: "CHATILLON SUR COLMONT (53100)", value: "53100" },
  { label: "CHEMAZE (53200)", value: "53200" },
  { label: "CHEMERE LE ROI (53340)", value: "53340" },
  { label: "CHERANCE (53400)", value: "53400" },
  { label: "CHEVAIGNE DU MAINE (53250)", value: "53250" },
  { label: "COLOMBIERS DU PLESSIS (53120)", value: "53120" },
  { label: "COMMER (53470)", value: "53470" },
  { label: "CONGRIER (53800)", value: "53800" },
  { label: "CONTEST (53100)", value: "53100" },
  { label: "COSMES (53230)", value: "53230" },
  { label: "COSSE EN CHAMPAGNE (53340)", value: "53340" },
  { label: "COSSE LE VIVIEN (53230)", value: "53230" },
  { label: "COUDRAY (53200)", value: "53200" },
  { label: "COUESMES VAUCE (53300)", value: "53300" },
  { label: "COUESMES VAUCE (53300)", value: "53300" },
  { label: "COUPTRAIN (53250)", value: "53250" },
  { label: "COURBEVEILLE (53230)", value: "53230" },
  { label: "COURCITE (53700)", value: "53700" },
  { label: "CRAON (53400)", value: "53400" },
  { label: "CRENNES SUR FRAUBEE (53700)", value: "53700" },
  { label: "LA CROIXILLE (53380)", value: "53380" },
  { label: "LA CROPTE (53170)", value: "53170" },
  { label: "CUILLE (53540)", value: "53540" },
  { label: "DAON (53200)", value: "53200" },
  { label: "DENAZE (53400)", value: "53400" },
  { label: "DESERTINES (53190)", value: "53190" },
  { label: "LA DOREE (53190)", value: "53190" },
  { label: "ENTRAMMES (53260)", value: "53260" },
  { label: "ERNEE (53500)", value: "53500" },
  { label: "EVRON (53150)", value: "53150" },
  { label: "EVRON (53600)", value: "53600" },
  { label: "EVRON (53600)", value: "53600" },
  { label: "FONTAINE COUVERTE (53350)", value: "53350" },
  { label: "FORCE (53260)", value: "53260" },
  { label: "FOUGEROLLES DU PLESSIS (53190)", value: "53190" },
  { label: "FROMENTIERES (53200)", value: "53200" },
  { label: "GASTINES (53540)", value: "53540" },
  { label: "LE GENEST ST ISLE (53940)", value: "53940" },
  { label: "LE GENEST ST ISLE (53940)", value: "53940" },
  { label: "GENNES LONGUEFUYE (53200)", value: "53200" },
  { label: "GENNES LONGUEFUYE (53200)", value: "53200" },
  { label: "GESNES (53150)", value: "53150" },
  { label: "GESVRES (53370)", value: "53370" },
  { label: "GORRON (53120)", value: "53120" },
  { label: "LA GRAVELLE (53410)", value: "53410" },
  { label: "GRAZAY (53440)", value: "53440" },
  { label: "GREZ EN BOUERE (53290)", value: "53290" },
  { label: "LA HAIE TRAVERSAINE (53300)", value: "53300" },
  { label: "LE HAM (53250)", value: "53250" },
  { label: "HAMBERS (53160)", value: "53160" },
  { label: "HARDANGES (53640)", value: "53640" },
  { label: "HERCE (53120)", value: "53120" },
  { label: "LE HORPS (53640)", value: "53640" },
  { label: "HOUSSAY (53360)", value: "53360" },
  { label: "LE HOUSSEAU BRETIGNOLLES (53110)", value: "53110" },
  { label: "LE HOUSSEAU BRETIGNOLLES (53110)", value: "53110" },
  { label: "L'HUISSERIE (53970)", value: "53970" },
  { label: "IZE (53160)", value: "53160" },
  { label: "JAVRON LES CHAPELLES (53250)", value: "53250" },
  { label: "JAVRON LES CHAPELLES (53250)", value: "53250" },
  { label: "JUBLAINS (53160)", value: "53160" },
  { label: "JUVIGNE (53380)", value: "53380" },
  { label: "PREE D ANJOU (53200)", value: "53200" },
  { label: "PREE D ANJOU (53200)", value: "53200" },
  { label: "LANDIVY (53190)", value: "53190" },
  { label: "LARCHAMP (53220)", value: "53220" },
  { label: "LASSAY LES CHATEAUX (53110)", value: "53110" },
  { label: "LASSAY LES CHATEAUX (53110)", value: "53110" },
  { label: "LASSAY LES CHATEAUX (53110)", value: "53110" },
  { label: "LASSAY LES CHATEAUX (53110)", value: "53110" },
  { label: "LAUBRIERES (53540)", value: "53540" },
  { label: "LAUNAY VILLIERS (53410)", value: "53410" },
  { label: "LAVAL (53000)", value: "53000" },
  { label: "LESBOIS (53120)", value: "53120" },
  { label: "LEVARE (53120)", value: "53120" },
  { label: "LIGNIERES ORGERES (53140)", value: "53140" },
  { label: "LIGNIERES ORGERES (53140)", value: "53140" },
  { label: "LIVET (53150)", value: "53150" },
  { label: "LIVRE LA TOUCHE (53400)", value: "53400" },
  { label: "LA ROCHE NEUVILLE (53200)", value: "53200" },
  { label: "LA ROCHE NEUVILLE (53360)", value: "53360" },
  { label: "LOIRON RUILLE (53320)", value: "53320" },
  { label: "LOIRON RUILLE (53320)", value: "53320" },
  { label: "LOUPFOUGERES (53700)", value: "53700" },
  { label: "LOUVERNE (53950)", value: "53950" },
  { label: "LOUVIGNE (53210)", value: "53210" },
  { label: "MADRE (53250)", value: "53250" },
  { label: "MAISONCELLES DU MAINE (53170)", value: "53170" },
  { label: "MARCILLE LA VILLE (53440)", value: "53440" },
  { label: "MARIGNE PEUTON (53200)", value: "53200" },
  { label: "MARTIGNE SUR MAYENNE (53470)", value: "53470" },
  { label: "MAYENNE (53100)", value: "53100" },
  { label: "MEE (53400)", value: "53400" },
  { label: "MENIL (53200)", value: "53200" },
  { label: "MERAL (53230)", value: "53230" },
  { label: "MESLAY DU MAINE (53170)", value: "53170" },
  { label: "MEZANGERS (53600)", value: "53600" },
  { label: "MONTAUDIN (53220)", value: "53220" },
  { label: "MONTENAY (53500)", value: "53500" },
  { label: "MONTFLOURS (53240)", value: "53240" },
  { label: "MONTIGNE LE BRILLANT (53970)", value: "53970" },
  { label: "MONTJEAN (53320)", value: "53320" },
  { label: "MONTREUIL POULAY (53640)", value: "53640" },
  { label: "MONTREUIL POULAY (53640)", value: "53640" },
  { label: "MONTSURS (53150)", value: "53150" },
  { label: "MONTSURS (53150)", value: "53150" },
  { label: "MONTSURS (53150)", value: "53150" },
  { label: "MONTSURS (53150)", value: "53150" },
  { label: "MONTSURS (53150)", value: "53150" },
  { label: "MOULAY (53100)", value: "53100" },
  { label: "NEAU (53150)", value: "53150" },
  { label: "NEUILLY LE VENDIN (53250)", value: "53250" },
  { label: "NIAFLES (53400)", value: "53400" },
  { label: "NUILLE SUR VICOIN (53970)", value: "53970" },
  { label: "OLIVET (53410)", value: "53410" },
  { label: "OISSEAU (53300)", value: "53300" },
  { label: "ORIGNE (53360)", value: "53360" },
  { label: "LA PALLU (53140)", value: "53140" },
  { label: "PARIGNE SUR BRAYE (53100)", value: "53100" },
  { label: "PARNE SUR ROC (53260)", value: "53260" },
  { label: "LE PAS (53300)", value: "53300" },
  { label: "LA PELLERINE (53220)", value: "53220" },
  { label: "PEUTON (53360)", value: "53360" },
  { label: "PLACE (53240)", value: "53240" },
  { label: "POMMERIEUX (53400)", value: "53400" },
  { label: "PONTMAIN (53220)", value: "53220" },
  { label: "PORT BRILLET (53410)", value: "53410" },
  { label: "PREAUX (53340)", value: "53340" },
  { label: "PRE EN PAIL ST SAMSON (53140)", value: "53140" },
  { label: "PRE EN PAIL ST SAMSON (53140)", value: "53140" },
  { label: "QUELAINES ST GAULT (53360)", value: "53360" },
  { label: "QUELAINES ST GAULT (53360)", value: "53360" },
  { label: "RAVIGNY (53370)", value: "53370" },
  { label: "RENAZE (53800)", value: "53800" },
  { label: "RENNES EN GRENOUILLES (53110)", value: "53110" },
  { label: "LE RIBAY (53640)", value: "53640" },
  { label: "LA ROE (53350)", value: "53350" },
  { label: "LA ROUAUDIERE (53390)", value: "53390" },
  { label: "RUILLE FROID FONDS (53170)", value: "53170" },
  { label: "SACE (53470)", value: "53470" },
  { label: "ST AIGNAN DE COUPTRAIN (53250)", value: "53250" },
  { label: "ST AIGNAN SUR ROE (53390)", value: "53390" },
  { label: "ST AUBIN DU DESERT (53700)", value: "53700" },
  { label: "ST AUBIN FOSSE LOUVAIN (53120)", value: "53120" },
  { label: "ST BAUDELLE (53100)", value: "53100" },
  { label: "ST BERTHEVIN (53940)", value: "53940" },
  { label: "ST BERTHEVIN LA TANNIERE (53220)", value: "53220" },
  { label: "ST BRICE (53290)", value: "53290" },
  { label: "ST CALAIS DU DESERT (53140)", value: "53140" },
  { label: "ST CHARLES LA FORET (53170)", value: "53170" },
  { label: "ST CYR EN PAIL (53140)", value: "53140" },
  { label: "ST CYR LE GRAVELAIS (53320)", value: "53320" },
  { label: "ST DENIS D ANJOU (53290)", value: "53290" },
  { label: "ST DENIS DE GASTINES (53500)", value: "53500" },
  { label: "ST DENIS DU MAINE (53170)", value: "53170" },
  { label: "ST ELLIER DU MAINE (53220)", value: "53220" },
  { label: "ST ERBLON (53390)", value: "53390" },
  { label: "ST FRAIMBAULT DE PRIERES (53300)", value: "53300" },
  { label: "STE GEMMES LE ROBERT (53600)", value: "53600" },
  { label: "ST GEORGES BUTTAVENT (53100)", value: "53100" },
  { label: "ST GEORGES BUTTAVENT (53100)", value: "53100" },
  { label: "ST GEORGES LE FLECHARD (53480)", value: "53480" },
  { label: "ST GEORGES SUR ERVE (53600)", value: "53600" },
  { label: "ST GERMAIN D ANXURE (53240)", value: "53240" },
  { label: "ST GERMAIN DE COULAMER (53700)", value: "53700" },
  { label: "ST GERMAIN LE FOUILLOUX (53240)", value: "53240" },
  { label: "ST GERMAIN LE GUILLAUME (53240)", value: "53240" },
  { label: "ST HILAIRE DU MAINE (53380)", value: "53380" },
  { label: "BLANDOUET ST JEAN (53270)", value: "53270" },
  { label: "BLANDOUET ST JEAN (53270)", value: "53270" },
  { label: "ST JEAN SUR MAYENNE (53240)", value: "53240" },
  { label: "ST JULIEN DU TERROUX (53110)", value: "53110" },
  { label: "ST LEGER (53480)", value: "53480" },
  { label: "ST LOUP DU DORAT (53290)", value: "53290" },
  { label: "ST LOUP DU GAST (53300)", value: "53300" },
  { label: "STE MARIE DU BOIS (53110)", value: "53110" },
  { label: "ST MARS DU DESERT (53700)", value: "53700" },
  { label: "ST MARS SUR COLMONT (53300)", value: "53300" },
  { label: "ST MARS SUR LA FUTAIE (53220)", value: "53220" },
  { label: "ST MARTIN DU LIMET (53800)", value: "53800" },
  { label: "ST MICHEL DE LA ROE (53350)", value: "53350" },
  { label: "ST OUEN DES TOITS (53410)", value: "53410" },
  { label: "ST PIERRE DES LANDES (53500)", value: "53500" },
  { label: "ST PIERRE DES NIDS (53370)", value: "53370" },
  { label: "ST PIERRE LA COUR (53410)", value: "53410" },
  { label: "ST PIERRE SUR ERVE (53270)", value: "53270" },
  { label: "VIMARTIN SUR ORTHE (53160)", value: "53160" },
  { label: "VIMARTIN SUR ORTHE (53160)", value: "53160" },
  { label: "VIMARTIN SUR ORTHE (53160)", value: "53160" },
  { label: "ST POIX (53540)", value: "53540" },
  { label: "ST QUENTIN LES ANGES (53400)", value: "53400" },
  { label: "ST SATURNIN DU LIMET (53800)", value: "53800" },
  { label: "STE SUZANNE ET CHAMMES (53270)", value: "53270" },
  { label: "STE SUZANNE ET CHAMMES (53270)", value: "53270" },
  { label: "ST THOMAS DE COURCERIERS (53160)", value: "53160" },
  { label: "SAULGES (53340)", value: "53340" },
  { label: "LA SELLE CRAONNAISE (53800)", value: "53800" },
  { label: "SENONNES (53390)", value: "53390" },
  { label: "SIMPLE (53360)", value: "53360" },
  { label: "SOUCE (53300)", value: "53300" },
  { label: "SOULGE SUR OUETTE (53210)", value: "53210" },
  { label: "SOULGE SUR OUETTE (53210)", value: "53210" },
  { label: "THUBOEUF (53110)", value: "53110" },
  { label: "THORIGNE EN CHARNIE (53270)", value: "53270" },
  { label: "TORCE VIVIERS EN CHARNIE (53270)", value: "53270" },
  { label: "TORCE VIVIERS EN CHARNIE (53270)", value: "53270" },
  { label: "TRANS (53160)", value: "53160" },
  { label: "VAIGES (53480)", value: "53480" },
  { label: "VAUTORTE (53500)", value: "53500" },
  { label: "VIEUVY (53120)", value: "53120" },
  { label: "VILLAINES LA JUHEL (53700)", value: "53700" },
  { label: "VILLEPAIL (53250)", value: "53250" },
  { label: "VILLIERS CHARLEMAGNE (53170)", value: "53170" },
  { label: "VOUTRE (53600)", value: "53600" },
  { label: "ABAUCOURT (54610)", value: "54610" },
  { label: "ABBEVILLE LES CONFLANS (54800)", value: "54800" },
  { label: "ABONCOURT (54115)", value: "54115" },
  { label: "AFFLEVILLE (54800)", value: "54800" },
  { label: "AFFRACOURT (54740)", value: "54740" },
  { label: "AGINCOURT (54770)", value: "54770" },
  { label: "AINGERAY (54460)", value: "54460" },
  { label: "ALLAIN (54170)", value: "54170" },
  { label: "ALLAMONT (54800)", value: "54800" },
  { label: "ALLAMPS (54112)", value: "54112" },
  { label: "ALLONDRELLE LA MALMAISON (54260)", value: "54260" },
  { label: "AMANCE (54770)", value: "54770" },
  { label: "AMENONCOURT (54450)", value: "54450" },
  { label: "ANCERVILLER (54450)", value: "54450" },
  { label: "ANDERNY (54560)", value: "54560" },
  { label: "ANDILLY (54200)", value: "54200" },
  { label: "ANGOMONT (54540)", value: "54540" },
  { label: "ANOUX (54150)", value: "54150" },
  { label: "ANSAUVILLE (54470)", value: "54470" },
  { label: "ANTHELUPT (54110)", value: "54110" },
  { label: "ARMAUCOURT (54760)", value: "54760" },
  { label: "ARNAVILLE (54530)", value: "54530" },
  { label: "ARRACOURT (54370)", value: "54370" },
  { label: "ARRAYE ET HAN (54760)", value: "54760" },
  { label: "ART SUR MEURTHE (54510)", value: "54510" },
  { label: "ART SUR MEURTHE (54510)", value: "54510" },
  { label: "ATHIENVILLE (54370)", value: "54370" },
  { label: "ATTON (54700)", value: "54700" },
  { label: "AUBOUE (54580)", value: "54580" },
  { label: "AUDUN LE ROMAN (54560)", value: "54560" },
  { label: "AUTREPIERRE (54450)", value: "54450" },
  { label: "AUTREVILLE SUR MOSELLE (54380)", value: "54380" },
  { label: "AUTREY (54160)", value: "54160" },
  { label: "AVILLERS (54490)", value: "54490" },
  { label: "AVRAINVILLE (54385)", value: "54385" },
  { label: "AVRICOURT (54450)", value: "54450" },
  { label: "AVRIL (54150)", value: "54150" },
  { label: "AZELOT (54210)", value: "54210" },
  { label: "AZERAILLES (54122)", value: "54122" },
  { label: "BACCARAT (54120)", value: "54120" },
  { label: "BACCARAT (54120)", value: "54120" },
  { label: "BACCARAT (54120)", value: "54120" },
  { label: "BADONVILLER (54540)", value: "54540" },
  { label: "BAGNEUX (54170)", value: "54170" },
  { label: "BAINVILLE AUX MIROIRS (54290)", value: "54290" },
  { label: "BAINVILLE SUR MADON (54550)", value: "54550" },
  { label: "BARBAS (54450)", value: "54450" },
  { label: "BARBONVILLE (54360)", value: "54360" },
  { label: "BARISEY AU PLAIN (54170)", value: "54170" },
  { label: "BARISEY LA COTE (54170)", value: "54170" },
  { label: "LES BAROCHES (54150)", value: "54150" },
  { label: "LES BAROCHES (54150)", value: "54150" },
  { label: "BASLIEUX (54620)", value: "54620" },
  { label: "BATHELEMONT (54370)", value: "54370" },
  { label: "BATILLY (54980)", value: "54980" },
  { label: "BATTIGNY (54115)", value: "54115" },
  { label: "BAUZEMONT (54370)", value: "54370" },
  { label: "BAYON (54290)", value: "54290" },
  { label: "BAYONVILLE SUR MAD (54890)", value: "54890" },
  { label: "BAZAILLES (54620)", value: "54620" },
  { label: "BEAUMONT (54470)", value: "54470" },
  { label: "BECHAMPS (54800)", value: "54800" },
  { label: "BELLEAU (54610)", value: "54610" },
  { label: "BELLEAU (54610)", value: "54610" },
  { label: "BELLEAU (54610)", value: "54610" },
  { label: "BELLEAU (54610)", value: "54610" },
  { label: "BELLEAU (54610)", value: "54610" },
  { label: "BELLEVILLE (54940)", value: "54940" },
  { label: "BENAMENIL (54450)", value: "54450" },
  { label: "BENNEY (54740)", value: "54740" },
  { label: "BERNECOURT (54470)", value: "54470" },
  { label: "BERTRAMBOIS (54480)", value: "54480" },
  { label: "BERTRICHAMPS (54120)", value: "54120" },
  { label: "BETTAINVILLERS (54640)", value: "54640" },
  { label: "BEUVEILLE (54620)", value: "54620" },
  { label: "BEUVEZIN (54115)", value: "54115" },
  { label: "BEUVILLERS (54560)", value: "54560" },
  { label: "BEY SUR SEILLE (54760)", value: "54760" },
  { label: "BEZANGE LA GRANDE (54370)", value: "54370" },
  { label: "BEZAUMONT (54380)", value: "54380" },
  { label: "BICQUELEY (54200)", value: "54200" },
  { label: "BIENVILLE LA PETITE (54300)", value: "54300" },
  { label: "BIONVILLE (54540)", value: "54540" },
  { label: "BLAINVILLE SUR L EAU (54360)", value: "54360" },
  { label: "BLAMONT (54450)", value: "54450" },
  { label: "BLEMEREY (54450)", value: "54450" },
  { label: "BLENOD LES PONT A MOUSSON (54700)", value: "54700" },
  { label: "BLENOD LES TOUL (54113)", value: "54113" },
  { label: "BOISMONT (54620)", value: "54620" },
  { label: "BONCOURT (54800)", value: "54800" },
  { label: "BONVILLER (54300)", value: "54300" },
  { label: "MONT BONVILLERS (54111)", value: "54111" },
  { label: "BORVILLE (54290)", value: "54290" },
  { label: "BOUCQ (54200)", value: "54200" },
  { label: "BOUILLONVILLE (54470)", value: "54470" },
  { label: "BOUVRON (54200)", value: "54200" },
  { label: "BOUXIERES AUX CHENES (54770)", value: "54770" },
  { label: "BOUXIERES AUX CHENES (54770)", value: "54770" },
  { label: "BOUXIERES AUX DAMES (54136)", value: "54136" },
  { label: "BOUXIERES SOUS FROIDMONT (54700)", value: "54700" },
  { label: "BOUZANVILLE (54930)", value: "54930" },
  { label: "BRAINVILLE (54800)", value: "54800" },
  { label: "BRALLEVILLE (54740)", value: "54740" },
  { label: "BRATTE (54610)", value: "54610" },
  { label: "BREHAIN LA VILLE (54190)", value: "54190" },
  { label: "BREMENIL (54540)", value: "54540" },
  { label: "BREMONCOURT (54290)", value: "54290" },
  { label: "VAL DE BRIEY (54150)", value: "54150" },
  { label: "VAL DE BRIEY (54150)", value: "54150" },
  { label: "VAL DE BRIEY (54790)", value: "54790" },
  { label: "BRIN SUR SEILLE (54280)", value: "54280" },
  { label: "BROUVILLE (54120)", value: "54120" },
  { label: "BRULEY (54200)", value: "54200" },
  { label: "BRUVILLE (54800)", value: "54800" },
  { label: "BUISSONCOURT (54110)", value: "54110" },
  { label: "BULLIGNY (54113)", value: "54113" },
  { label: "BURES (54370)", value: "54370" },
  { label: "BURIVILLE (54450)", value: "54450" },
  { label: "BURTHECOURT AUX CHENES (54210)", value: "54210" },
  { label: "CEINTREY (54134)", value: "54134" },
  { label: "CERVILLE (54420)", value: "54420" },
  { label: "CHALIGNY (54230)", value: "54230" },
  { label: "CHAMBLEY BUSSIERES (54890)", value: "54890" },
  { label: "CHAMPENOUX (54280)", value: "54280" },
  { label: "CHAMPEY SUR MOSELLE (54700)", value: "54700" },
  { label: "CHAMPIGNEULLES (54250)", value: "54250" },
  { label: "CHANTEHEUX (54300)", value: "54300" },
  { label: "CHAOUILLEY (54330)", value: "54330" },
  { label: "CHARENCY VEZIN (54260)", value: "54260" },
  { label: "CHAREY (54470)", value: "54470" },
  { label: "CHARMES LA COTE (54113)", value: "54113" },
  { label: "CHARMOIS (54360)", value: "54360" },
  { label: "CHAUDENEY SUR MOSELLE (54200)", value: "54200" },
  { label: "CHAVIGNY (54230)", value: "54230" },
  { label: "CHAZELLES SUR ALBE (54450)", value: "54450" },
  { label: "CHENEVIERES (54122)", value: "54122" },
  { label: "CHENICOURT (54610)", value: "54610" },
  { label: "CHENIERES (54720)", value: "54720" },
  { label: "CHOLOY MENILLOT (54200)", value: "54200" },
  { label: "CHOLOY MENILLOT (54200)", value: "54200" },
  { label: "CIREY SUR VEZOUZE (54480)", value: "54480" },
  { label: "CLAYEURES (54290)", value: "54290" },
  { label: "CLEMERY (54610)", value: "54610" },
  { label: "CLEREY SUR BRENON (54330)", value: "54330" },
  { label: "COINCOURT (54370)", value: "54370" },
  { label: "COLMEY (54260)", value: "54260" },
  { label: "COLMEY (54260)", value: "54260" },
  { label: "COLOMBEY LES BELLES (54170)", value: "54170" },
  { label: "CONFLANS EN JARNISY (54800)", value: "54800" },
  { label: "CONS LA GRANDVILLE (54870)", value: "54870" },
  { label: "COSNES ET ROMAIN (54400)", value: "54400" },
  { label: "COSNES ET ROMAIN (54400)", value: "54400" },
  { label: "COURBESSEAUX (54110)", value: "54110" },
  { label: "COURCELLES (54930)", value: "54930" },
  { label: "COYVILLER (54210)", value: "54210" },
  { label: "CRANTENOY (54740)", value: "54740" },
  { label: "CREPEY (54170)", value: "54170" },
  { label: "CREVECHAMPS (54290)", value: "54290" },
  { label: "CREVIC (54110)", value: "54110" },
  { label: "CREZILLES (54113)", value: "54113" },
  { label: "CRION (54300)", value: "54300" },
  { label: "CROISMARE (54300)", value: "54300" },
  { label: "CRUSNES (54680)", value: "54680" },
  { label: "CUSTINES (54670)", value: "54670" },
  { label: "CUTRY (54720)", value: "54720" },
  { label: "DAMELEVIERES (54360)", value: "54360" },
  { label: "DAMPVITOUX (54470)", value: "54470" },
  { label: "DENEUVRE (54120)", value: "54120" },
  { label: "DEUXVILLE (54370)", value: "54370" },
  { label: "DIARVILLE (54930)", value: "54930" },
  { label: "DIEULOUARD (54380)", value: "54380" },
  { label: "DOLCOURT (54170)", value: "54170" },
  { label: "DOMBASLE SUR MEURTHE (54110)", value: "54110" },
  { label: "DOMEVRE EN HAYE (54385)", value: "54385" },
  { label: "DOMEVRE SUR VEZOUZE (54450)", value: "54450" },
  { label: "DOMGERMAIN (54119)", value: "54119" },
  { label: "DOMJEVIN (54450)", value: "54450" },
  { label: "DOMMARIE EULMONT (54115)", value: "54115" },
  { label: "DOMMARTEMONT (54130)", value: "54130" },
  { label: "DOMMARTIN LA CHAUSSEE (54470)", value: "54470" },
  { label: "DOMMARTIN LES TOUL (54200)", value: "54200" },
  { label: "DOMMARTIN SOUS AMANCE (54770)", value: "54770" },
  { label: "DOMPRIX (54490)", value: "54490" },
  { label: "DOMPRIX (54490)", value: "54490" },
  { label: "DOMPTAIL EN L AIR (54290)", value: "54290" },
  { label: "DONCOURT LES CONFLANS (54800)", value: "54800" },
  { label: "DONCOURT LES LONGUYON (54620)", value: "54620" },
  { label: "DROUVILLE (54370)", value: "54370" },
  { label: "ECROUVES (54200)", value: "54200" },
  { label: "ECROUVES (54200)", value: "54200" },
  { label: "EINVAUX (54360)", value: "54360" },
  { label: "EINVILLE AU JARD (54370)", value: "54370" },
  { label: "EMBERMENIL (54370)", value: "54370" },
  { label: "EPIEZ SUR CHIERS (54260)", value: "54260" },
  { label: "EPLY (54610)", value: "54610" },
  { label: "ERBEVILLER SUR AMEZULE (54280)", value: "54280" },
  { label: "ERROUVILLE (54680)", value: "54680" },
  { label: "ESSEY ET MAIZERAIS (54470)", value: "54470" },
  { label: "ESSEY LA COTE (54830)", value: "54830" },
  { label: "ESSEY LES NANCY (54270)", value: "54270" },
  { label: "ETREVAL (54330)", value: "54330" },
  { label: "EULMONT (54690)", value: "54690" },
  { label: "EUVEZIN (54470)", value: "54470" },
  { label: "FAULX (54760)", value: "54760" },
  { label: "FAVIERES (54115)", value: "54115" },
  { label: "FECOCOURT (54115)", value: "54115" },
  { label: "FENNEVILLER (54540)", value: "54540" },
  { label: "FERRIERES (54210)", value: "54210" },
  { label: "FEY EN HAYE (54470)", value: "54470" },
  { label: "FILLIERES (54560)", value: "54560" },
  { label: "FLAINVAL (54110)", value: "54110" },
  { label: "FLAVIGNY SUR MOSELLE (54630)", value: "54630" },
  { label: "FLEVILLE DEVANT NANCY (54710)", value: "54710" },
  { label: "FLEVILLE LIXIERES (54150)", value: "54150" },
  { label: "FLIN (54122)", value: "54122" },
  { label: "FLIREY (54470)", value: "54470" },
  { label: "FONTENOY LA JOUTE (54122)", value: "54122" },
  { label: "FONTENOY SUR MOSELLE (54840)", value: "54840" },
  { label: "FORCELLES ST GORGON (54330)", value: "54330" },
  { label: "FORCELLES SOUS GUGNEY (54930)", value: "54930" },
  { label: "FOUG (54570)", value: "54570" },
  { label: "FRAIMBOIS (54300)", value: "54300" },
  { label: "FRAISNES EN SAINTOIS (54930)", value: "54930" },
  { label: "FRANCHEVILLE (54200)", value: "54200" },
  { label: "FRANCONVILLE (54830)", value: "54830" },
  { label: "FREMENIL (54450)", value: "54450" },
  { label: "FREMONVILLE (54450)", value: "54450" },
  { label: "FRESNOIS LA MONTAGNE (54260)", value: "54260" },
  { label: "FRIAUVILLE (54800)", value: "54800" },
  { label: "FROLOIS (54160)", value: "54160" },
  { label: "FROUARD (54390)", value: "54390" },
  { label: "FROVILLE (54290)", value: "54290" },
  { label: "GELACOURT (54120)", value: "54120" },
  { label: "GELAUCOURT (54115)", value: "54115" },
  { label: "GELLENONCOURT (54110)", value: "54110" },
  { label: "GEMONVILLE (54115)", value: "54115" },
  { label: "GERBECOURT ET HAPLEMONT (54740)", value: "54740" },
  { label: "GERBEVILLER (54830)", value: "54830" },
  { label: "GERMINY (54170)", value: "54170" },
  { label: "GERMONVILLE (54740)", value: "54740" },
  { label: "GEZONCOURT (54380)", value: "54380" },
  { label: "GIBEAUMEIX (54112)", value: "54112" },
  { label: "GIRAUMONT (54780)", value: "54780" },
  { label: "GIRIVILLER (54830)", value: "54830" },
  { label: "GLONVILLE (54122)", value: "54122" },
  { label: "GOGNEY (54450)", value: "54450" },
  { label: "GONDRECOURT AIX (54800)", value: "54800" },
  { label: "GONDREVILLE (54840)", value: "54840" },
  { label: "GONDREXON (54450)", value: "54450" },
  { label: "GORCY (54730)", value: "54730" },
  { label: "GOVILLER (54330)", value: "54330" },
  { label: "GRAND FAILLY (54260)", value: "54260" },
  { label: "GRAND FAILLY (54260)", value: "54260" },
  { label: "GRIMONVILLER (54115)", value: "54115" },
  { label: "GRIPPORT (54290)", value: "54290" },
  { label: "GRISCOURT (54380)", value: "54380" },
  { label: "GROSROUVRES (54470)", value: "54470" },
  { label: "GUGNEY (54930)", value: "54930" },
  { label: "GYE (54113)", value: "54113" },
  { label: "HABLAINVILLE (54120)", value: "54120" },
  { label: "HAGEVILLE (54470)", value: "54470" },
  { label: "HAIGNEVILLE (54290)", value: "54290" },
  { label: "HALLOVILLE (54450)", value: "54450" },
  { label: "HAMMEVILLE (54330)", value: "54330" },
  { label: "HAMONVILLE (54470)", value: "54470" },
  { label: "HANNONVILLE SUZEMONT (54800)", value: "54800" },
  { label: "HARAUCOURT (54110)", value: "54110" },
  { label: "HARBOUEY (54450)", value: "54450" },
  { label: "HAROUE (54740)", value: "54740" },
  { label: "HATRIZE (54800)", value: "54800" },
  { label: "HAUCOURT MOULAINE (54860)", value: "54860" },
  { label: "HAUCOURT MOULAINE (54860)", value: "54860" },
  { label: "HAUDONVILLE (54830)", value: "54830" },
  { label: "HAUSSONVILLE (54290)", value: "54290" },
  { label: "HEILLECOURT (54180)", value: "54180" },
  { label: "HENAMENIL (54370)", value: "54370" },
  { label: "HERBEVILLER (54450)", value: "54450" },
  { label: "HERIMENIL (54300)", value: "54300" },
  { label: "HERSERANGE (54440)", value: "54440" },
  { label: "HOEVILLE (54370)", value: "54370" },
  { label: "HOMECOURT (54310)", value: "54310" },
  { label: "HOUDELMONT (54330)", value: "54330" },
  { label: "HOUDEMONT (54180)", value: "54180" },
  { label: "HOUDREVILLE (54330)", value: "54330" },
  { label: "HOUSSEVILLE (54930)", value: "54930" },
  { label: "HUDIVILLER (54110)", value: "54110" },
  { label: "HUSSIGNY GODBRANGE (54590)", value: "54590" },
  { label: "IGNEY (54450)", value: "54450" },
  { label: "JAILLON (54200)", value: "54200" },
  { label: "JARNY (54800)", value: "54800" },
  { label: "JARNY (54800)", value: "54800" },
  { label: "JARVILLE LA MALGRANGE (54140)", value: "54140" },
  { label: "JAULNY (54470)", value: "54470" },
  { label: "JEANDELAINCOURT (54114)", value: "54114" },
  { label: "JEANDELIZE (54800)", value: "54800" },
  { label: "JEVONCOURT (54740)", value: "54740" },
  { label: "JEZAINVILLE (54700)", value: "54700" },
  { label: "JOEUF (54240)", value: "54240" },
  { label: "JOLIVET (54300)", value: "54300" },
  { label: "JOPPECOURT (54620)", value: "54620" },
  { label: "JOUAVILLE (54800)", value: "54800" },
  { label: "JOUDREVILLE (54490)", value: "54490" },
  { label: "JUVRECOURT (54370)", value: "54370" },
  { label: "LABRY (54800)", value: "54800" },
  { label: "LACHAPELLE (54120)", value: "54120" },
  { label: "LAGNEY (54200)", value: "54200" },
  { label: "LAITRE SOUS AMANCE (54770)", value: "54770" },
  { label: "LAIX (54720)", value: "54720" },
  { label: "LALOEUF (54115)", value: "54115" },
  { label: "LAMATH (54300)", value: "54300" },
  { label: "LANDECOURT (54360)", value: "54360" },
  { label: "LANDREMONT (54380)", value: "54380" },
  { label: "LANDRES (54970)", value: "54970" },
  { label: "LANEUVELOTTE (54280)", value: "54280" },
  { label: "LANEUVEVILLE AUX BOIS (54370)", value: "54370" },
  { label: "LANEUVEVILLE DERRIERE FOUG (54570)", value: "54570" },
  { label: "LANEUVEVILLE DEVANT BAYON (54740)", value: "54740" },
  { label: "LANEUVEVILLE DEVANT NANCY (54410)", value: "54410" },
  { label: "LANEUVEVILLE DEVANT NANCY (54410)", value: "54410" },
  { label: "LANFROICOURT (54760)", value: "54760" },
  { label: "LANTEFONTAINE (54150)", value: "54150" },
  { label: "LANTEFONTAINE (54150)", value: "54150" },
  { label: "LARONXE (54950)", value: "54950" },
  { label: "LAXOU (54520)", value: "54520" },
  { label: "LAXOU (54520)", value: "54520" },
  { label: "LAY ST CHRISTOPHE (54690)", value: "54690" },
  { label: "LAY ST REMY (54570)", value: "54570" },
  { label: "LEBEUVILLE (54740)", value: "54740" },
  { label: "LEINTREY (54450)", value: "54450" },
  { label: "LEMAINVILLE (54740)", value: "54740" },
  { label: "LEMENIL MITRY (54740)", value: "54740" },
  { label: "LENONCOURT (54110)", value: "54110" },
  { label: "LESMENILS (54700)", value: "54700" },
  { label: "LETRICOURT (54610)", value: "54610" },
  { label: "LEXY (54720)", value: "54720" },
  { label: "LEYR (54760)", value: "54760" },
  { label: "LIMEY REMENAUVILLE (54470)", value: "54470" },
  { label: "LIRONVILLE (54470)", value: "54470" },
  { label: "LIVERDUN (54460)", value: "54460" },
  { label: "LOISY (54700)", value: "54700" },
  { label: "LONGLAVILLE (54810)", value: "54810" },
  { label: "LONGUYON (54260)", value: "54260" },
  { label: "LONGUYON (54260)", value: "54260" },
  { label: "LONGUYON (54260)", value: "54260" },
  { label: "LONGWY (54400)", value: "54400" },
  { label: "LOREY (54290)", value: "54290" },
  { label: "LOROMONTZEY (54290)", value: "54290" },
  { label: "LUBEY (54150)", value: "54150" },
  { label: "LUCEY (54200)", value: "54200" },
  { label: "LUDRES (54710)", value: "54710" },
  { label: "LUNEVILLE (54300)", value: "54300" },
  { label: "LUPCOURT (54210)", value: "54210" },
  { label: "MAGNIERES (54129)", value: "54129" },
  { label: "MAIDIERES (54700)", value: "54700" },
  { label: "MAILLY SUR SEILLE (54610)", value: "54610" },
  { label: "MAIRY MAINVILLE (54150)", value: "54150" },
  { label: "MAIRY MAINVILLE (54150)", value: "54150" },
  { label: "MAIXE (54370)", value: "54370" },
  { label: "MAIZIERES (54550)", value: "54550" },
  { label: "MALAVILLERS (54560)", value: "54560" },
  { label: "MALLELOY (54670)", value: "54670" },
  { label: "MALZEVILLE (54220)", value: "54220" },
  { label: "MAMEY (54470)", value: "54470" },
  { label: "MANDRES AUX QUATRE TOURS (54470)", value: "54470" },
  { label: "MANGONVILLE (54290)", value: "54290" },
  { label: "MANONCOURT EN VERMOIS (54210)", value: "54210" },
  { label: "MANONCOURT EN WOEVRE (54385)", value: "54385" },
  { label: "MANONVILLE (54385)", value: "54385" },
  { label: "MANONVILLER (54300)", value: "54300" },
  { label: "MARAINVILLER (54300)", value: "54300" },
  { label: "MARBACHE (54820)", value: "54820" },
  { label: "MARON (54230)", value: "54230" },
  { label: "MARS LA TOUR (54800)", value: "54800" },
  { label: "MARTHEMONT (54330)", value: "54330" },
  { label: "MARTINCOURT (54380)", value: "54380" },
  { label: "MATTEXEY (54830)", value: "54830" },
  { label: "MAXEVILLE (54320)", value: "54320" },
  { label: "MAXEVILLE (54320)", value: "54320" },
  { label: "MAZERULLES (54280)", value: "54280" },
  { label: "MEHONCOURT (54360)", value: "54360" },
  { label: "MENIL LA TOUR (54200)", value: "54200" },
  { label: "MERCY LE BAS (54960)", value: "54960" },
  { label: "MERCY LE HAUT (54560)", value: "54560" },
  { label: "MERCY LE HAUT (54560)", value: "54560" },
  { label: "MEREVILLE (54850)", value: "54850" },
  { label: "MERVILLER (54120)", value: "54120" },
  { label: "MESSEIN (54850)", value: "54850" },
  { label: "MEXY (54135)", value: "54135" },
  { label: "MIGNEVILLE (54540)", value: "54540" },
  { label: "MILLERY (54670)", value: "54670" },
  { label: "MINORVILLE (54385)", value: "54385" },
  { label: "MOINEVILLE (54580)", value: "54580" },
  { label: "MOIVRONS (54760)", value: "54760" },
  { label: "MONCEL LES LUNEVILLE (54300)", value: "54300" },
  { label: "MONCEL SUR SEILLE (54280)", value: "54280" },
  { label: "MONTAUVILLE (54700)", value: "54700" },
  { label: "MONTENOY (54760)", value: "54760" },
  { label: "MONTIGNY (54540)", value: "54540" },
  { label: "MONTIGNY SUR CHIERS (54870)", value: "54870" },
  { label: "MONTIGNY SUR CHIERS (54870)", value: "54870" },
  { label: "MONT L ETROIT (54170)", value: "54170" },
  { label: "MONT LE VIGNOBLE (54113)", value: "54113" },
  { label: "MONTREUX (54450)", value: "54450" },
  { label: "MONT ST MARTIN (54350)", value: "54350" },
  { label: "MONT SUR MEURTHE (54360)", value: "54360" },
  { label: "MORFONTAINE (54920)", value: "54920" },
  { label: "MORIVILLER (54830)", value: "54830" },
  { label: "MORVILLE SUR SEILLE (54700)", value: "54700" },
  { label: "MOUACOURT (54370)", value: "54370" },
  { label: "MOUAVILLE (54800)", value: "54800" },
  { label: "MOUSSON (54700)", value: "54700" },
  { label: "MOUTIERS (54660)", value: "54660" },
  { label: "MOUTROT (54113)", value: "54113" },
  { label: "MOYEN (54118)", value: "54118" },
  { label: "MURVILLE (54490)", value: "54490" },
  { label: "NANCY (54000)", value: "54000" },
  { label: "NANCY (54100)", value: "54100" },
  { label: "NEUFMAISONS (54540)", value: "54540" },
  { label: "NEUVES MAISONS (54230)", value: "54230" },
  { label: "NEUVILLER LES BADONVILLER (54540)", value: "54540" },
  { label: "NEUVILLER SUR MOSELLE (54290)", value: "54290" },
  { label: "NOMENY (54610)", value: "54610" },
  { label: "NONHIGNY (54450)", value: "54450" },
  { label: "NORROY LE SEC (54150)", value: "54150" },
  { label: "NORROY LES PONT A MOUSSON (54700)", value: "54700" },
  { label: "NOVIANT AUX PRES (54385)", value: "54385" },
  { label: "OCHEY (54170)", value: "54170" },
  { label: "OGEVILLER (54450)", value: "54450" },
  { label: "OGNEVILLE (54330)", value: "54330" },
  { label: "OLLEY (54800)", value: "54800" },
  { label: "OMELMONT (54330)", value: "54330" },
  { label: "ONVILLE (54890)", value: "54890" },
  { label: "ORMES ET VILLE (54740)", value: "54740" },
  { label: "OTHE (54260)", value: "54260" },
  { label: "OZERAILLES (54150)", value: "54150" },
  { label: "PAGNEY DERRIERE BARINE (54200)", value: "54200" },
  { label: "PAGNY SUR MOSELLE (54530)", value: "54530" },
  { label: "PANNES (54470)", value: "54470" },
  { label: "PAREY ST CESAIRE (54330)", value: "54330" },
  { label: "PARROY (54370)", value: "54370" },
  { label: "PARUX (54480)", value: "54480" },
  { label: "PETIT FAILLY (54260)", value: "54260" },
  { label: "PETITMONT (54480)", value: "54480" },
  { label: "PETTONVILLE (54120)", value: "54120" },
  { label: "PEXONNE (54540)", value: "54540" },
  { label: "PHLIN (54610)", value: "54610" },
  { label: "PIENNES (54490)", value: "54490" },
  { label: "PIERRE LA TREICHE (54200)", value: "54200" },
  { label: "PIERRE PERCEE (54540)", value: "54540" },
  { label: "PIERREPONT (54620)", value: "54620" },
  { label: "PIERREVILLE (54160)", value: "54160" },
  { label: "POMPEY (54340)", value: "54340" },
  { label: "PONT A MOUSSON (54700)", value: "54700" },
  { label: "PONT ST VINCENT (54550)", value: "54550" },
  { label: "PORT SUR SEILLE (54700)", value: "54700" },
  { label: "PRAYE (54116)", value: "54116" },
  { label: "PRENY (54530)", value: "54530" },
  { label: "PREUTIN HIGNY (54490)", value: "54490" },
  { label: "PULLIGNY (54160)", value: "54160" },
  { label: "PULNEY (54115)", value: "54115" },
  { label: "PULNOY (54425)", value: "54425" },
  { label: "PUXE (54800)", value: "54800" },
  { label: "PUXIEUX (54800)", value: "54800" },
  { label: "QUEVILLONCOURT (54330)", value: "54330" },
  { label: "RAON LES LEAU (54540)", value: "54540" },
  { label: "RAUCOURT (54610)", value: "54610" },
  { label: "RAVILLE SUR SANON (54370)", value: "54370" },
  { label: "RECHICOURT LA PETITE (54370)", value: "54370" },
  { label: "RECLONVILLE (54450)", value: "54450" },
  { label: "REHAINVILLER (54300)", value: "54300" },
  { label: "REHERREY (54120)", value: "54120" },
  { label: "REHON (54430)", value: "54430" },
  { label: "REHON (54430)", value: "54430" },
  { label: "REILLON (54450)", value: "54450" },
  { label: "REMBERCOURT SUR MAD (54470)", value: "54470" },
  { label: "REMENOVILLE (54830)", value: "54830" },
  { label: "REMEREVILLE (54110)", value: "54110" },
  { label: "REMONCOURT (54370)", value: "54370" },
  { label: "REPAIX (54450)", value: "54450" },
  { label: "RICHARDMENIL (54630)", value: "54630" },
  { label: "ROGEVILLE (54380)", value: "54380" },
  { label: "ROMAIN (54360)", value: "54360" },
  { label: "ROSIERES AUX SALINES (54110)", value: "54110" },
  { label: "ROSIERES EN HAYE (54385)", value: "54385" },
  { label: "ROUVES (54610)", value: "54610" },
  { label: "ROVILLE DEVANT BAYON (54290)", value: "54290" },
  { label: "ROYAUMEIX (54200)", value: "54200" },
  { label: "ROZELIEURES (54290)", value: "54290" },
  { label: "SAFFAIS (54210)", value: "54210" },
  { label: "ST AIL (54580)", value: "54580" },
  { label: "ST AIL (54580)", value: "54580" },
  { label: "ST BAUSSANT (54470)", value: "54470" },
  { label: "ST BOINGT (54290)", value: "54290" },
  { label: "ST CLEMENT (54950)", value: "54950" },
  { label: "ST FIRMIN (54930)", value: "54930" },
  { label: "STE GENEVIEVE (54700)", value: "54700" },
  { label: "ST GERMAIN (54290)", value: "54290" },
  { label: "ST JEAN LES LONGUYON (54260)", value: "54260" },
  { label: "ST JEAN LES LONGUYON (54260)", value: "54260" },
  { label: "ST JULIEN LES GORZE (54470)", value: "54470" },
  { label: "ST MARCEL (54800)", value: "54800" },
  { label: "ST MARD (54290)", value: "54290" },
  { label: "ST MARTIN (54450)", value: "54450" },
  { label: "ST MAURICE AUX FORGES (54540)", value: "54540" },
  { label: "ST MAX (54130)", value: "54130" },
  { label: "ST NICOLAS DE PORT (54210)", value: "54210" },
  { label: "STE POLE (54540)", value: "54540" },
  { label: "ST PANCRE (54730)", value: "54730" },
  { label: "ST REMIMONT (54740)", value: "54740" },
  { label: "ST REMY AUX BOIS (54290)", value: "54290" },
  { label: "ST SAUVEUR (54480)", value: "54480" },
  { label: "ST SUPPLET (54620)", value: "54620" },
  { label: "SAIZERAIS (54380)", value: "54380" },
  { label: "SANCY (54560)", value: "54560" },
  { label: "SANZEY (54200)", value: "54200" },
  { label: "SAULNES (54650)", value: "54650" },
  { label: "SAULXEROTTE (54115)", value: "54115" },
  { label: "SAULXURES LES NANCY (54420)", value: "54420" },
  { label: "SAULXURES LES VANNES (54170)", value: "54170" },
  { label: "SAXON SION (54330)", value: "54330" },
  { label: "SEICHAMPS (54280)", value: "54280" },
  { label: "SEICHEPREY (54470)", value: "54470" },
  { label: "SELAINCOURT (54170)", value: "54170" },
  { label: "SERANVILLE (54830)", value: "54830" },
  { label: "SERRES (54370)", value: "54370" },
  { label: "SERROUVILLE (54560)", value: "54560" },
  { label: "SEXEY AUX FORGES (54550)", value: "54550" },
  { label: "SIONVILLER (54300)", value: "54300" },
  { label: "SIVRY (54610)", value: "54610" },
  { label: "SOMMERVILLER (54110)", value: "54110" },
  { label: "SORNEVILLE (54280)", value: "54280" },
  { label: "SPONVILLE (54800)", value: "54800" },
  { label: "TANCONVILLE (54480)", value: "54480" },
  { label: "TANTONVILLE (54116)", value: "54116" },
  { label: "TELLANCOURT (54260)", value: "54260" },
  { label: "THELOD (54330)", value: "54330" },
  { label: "THEY SOUS VAUDEMONT (54930)", value: "54930" },
  { label: "THEZEY ST MARTIN (54610)", value: "54610" },
  { label: "THIAUCOURT REGNIEVILLE (54470)", value: "54470" },
  { label: "THIAVILLE SUR MEURTHE (54120)", value: "54120" },
  { label: "THIEBAUMENIL (54300)", value: "54300" },
  { label: "THIL (54880)", value: "54880" },
  { label: "THOREY LYAUTEY (54115)", value: "54115" },
  { label: "THUILLEY AUX GROSEILLES (54170)", value: "54170" },
  { label: "THUMEREVILLE (54800)", value: "54800" },
  { label: "TIERCELET (54190)", value: "54190" },
  { label: "TOMBLAINE (54510)", value: "54510" },
  { label: "TONNOY (54210)", value: "54210" },
  { label: "TOUL (54200)", value: "54200" },
  { label: "TRAMONT EMY (54115)", value: "54115" },
  { label: "TRAMONT LASSUS (54115)", value: "54115" },
  { label: "TRAMONT ST ANDRE (54115)", value: "54115" },
  { label: "TREMBLECOURT (54385)", value: "54385" },
  { label: "TRIEUX (54750)", value: "54750" },
  { label: "TRONDES (54570)", value: "54570" },
  { label: "TRONVILLE (54800)", value: "54800" },
  { label: "TUCQUEGNIEUX (54640)", value: "54640" },
  { label: "UGNY (54870)", value: "54870" },
  { label: "URUFFE (54112)", value: "54112" },
  { label: "VACQUEVILLE (54540)", value: "54540" },
  { label: "VAL ET CHATILLON (54480)", value: "54480" },
  { label: "VALHEY (54370)", value: "54370" },
  { label: "VALLEROY (54910)", value: "54910" },
  { label: "VALLOIS (54830)", value: "54830" },
  { label: "VANDELAINVILLE (54890)", value: "54890" },
  { label: "VANDELEVILLE (54115)", value: "54115" },
  { label: "VANDIERES (54121)", value: "54121" },
  { label: "VANDOEUVRE LES NANCY (54500)", value: "54500" },
  { label: "VANNES LE CHATEL (54112)", value: "54112" },
  { label: "VARANGEVILLE (54110)", value: "54110" },
  { label: "VATHIMENIL (54122)", value: "54122" },
  { label: "VAUCOURT (54370)", value: "54370" },
  { label: "VAUDEMONT (54330)", value: "54330" },
  { label: "VAUDEVILLE (54740)", value: "54740" },
  { label: "VAUDIGNY (54740)", value: "54740" },
  { label: "VAXAINVILLE (54120)", value: "54120" },
  { label: "VEHO (54450)", value: "54450" },
  { label: "BOIS DE HAYE (54840)", value: "54840" },
  { label: "BOIS DE HAYE (54840)", value: "54840" },
  { label: "VELAINE SOUS AMANCE (54280)", value: "54280" },
  { label: "VELLE SUR MOSELLE (54290)", value: "54290" },
  { label: "VENEY (54540)", value: "54540" },
  { label: "VENNEZEY (54830)", value: "54830" },
  { label: "VERDENAL (54450)", value: "54450" },
  { label: "VEZELISE (54330)", value: "54330" },
  { label: "VIEVILLE EN HAYE (54470)", value: "54470" },
  { label: "VIGNEULLES (54360)", value: "54360" },
  { label: "VILCEY SUR TREY (54700)", value: "54700" },
  { label: "VILLACOURT (54290)", value: "54290" },
  { label: "VILLE AU MONTOIS (54620)", value: "54620" },
  { label: "VILLE AU VAL (54380)", value: "54380" },
  { label: "VILLECEY SUR MAD (54890)", value: "54890" },
  { label: "VILLE EN VERMOIS (54210)", value: "54210" },
  { label: "VILLE HOUDLEMONT (54730)", value: "54730" },
  { label: "VILLERS EN HAYE (54380)", value: "54380" },
  { label: "VILLERS LA CHEVRE (54870)", value: "54870" },
  { label: "VILLERS LA MONTAGNE (54920)", value: "54920" },
  { label: "VILLERS LE ROND (54260)", value: "54260" },
  { label: "VILLERS LES MOIVRONS (54760)", value: "54760" },
  { label: "VILLERS LES NANCY (54600)", value: "54600" },
  { label: "VILLERS SOUS PRENY (54700)", value: "54700" },
  { label: "VILLERUPT (54190)", value: "54190" },
  { label: "VILLERUPT (54190)", value: "54190" },
  { label: "VILLE SUR YRON (54800)", value: "54800" },
  { label: "VILLETTE (54260)", value: "54260" },
  { label: "VILLEY LE SEC (54840)", value: "54840" },
  { label: "VILLEY ST ETIENNE (54200)", value: "54200" },
  { label: "VIRECOURT (54290)", value: "54290" },
  { label: "VITERNE (54123)", value: "54123" },
  { label: "VITREY (54330)", value: "54330" },
  { label: "VITRIMONT (54300)", value: "54300" },
  { label: "VITTONVILLE (54700)", value: "54700" },
  { label: "VIVIERS SUR CHIERS (54260)", value: "54260" },
  { label: "VIVIERS SUR CHIERS (54260)", value: "54260" },
  { label: "VIVIERS SUR CHIERS (54260)", value: "54260" },
  { label: "VOINEMONT (54134)", value: "54134" },
  { label: "VRONCOURT (54330)", value: "54330" },
  { label: "WAVILLE (54890)", value: "54890" },
  { label: "XAMMES (54470)", value: "54470" },
  { label: "XERMAMENIL (54300)", value: "54300" },
  { label: "XEUILLEY (54990)", value: "54990" },
  { label: "XIROCOURT (54740)", value: "54740" },
  { label: "XIVRY CIRCOURT (54490)", value: "54490" },
  { label: "XONVILLE (54800)", value: "54800" },
  { label: "XOUSSE (54370)", value: "54370" },
  { label: "XURES (54370)", value: "54370" },
  { label: "HAN DEVANT PIERREPONT (54620)", value: "54620" },
  { label: "ABAINVILLE (55130)", value: "55130" },
  { label: "ABAUCOURT HAUTECOURT (55400)", value: "55400" },
  { label: "ABAUCOURT HAUTECOURT (55400)", value: "55400" },
  { label: "AINCREVILLE (55110)", value: "55110" },
  { label: "AMANTY (55130)", value: "55130" },
  { label: "AMBLY SUR MEUSE (55300)", value: "55300" },
  { label: "AMEL SUR L ETANG (55230)", value: "55230" },
  { label: "ANCEMONT (55320)", value: "55320" },
  { label: "ANCERVILLE (55170)", value: "55170" },
  { label: "ANDERNAY (55800)", value: "55800" },
  { label: "APREMONT LA FORET (55300)", value: "55300" },
  { label: "APREMONT LA FORET (55300)", value: "55300" },
  { label: "APREMONT LA FORET (55300)", value: "55300" },
  { label: "APREMONT LA FORET (55300)", value: "55300" },
  { label: "ARRANCY SUR CRUSNES (55230)", value: "55230" },
  { label: "AUBREVILLE (55120)", value: "55120" },
  { label: "AULNOIS EN PERTHOIS (55170)", value: "55170" },
  { label: "AUTRECOURT SUR AIRE (55120)", value: "55120" },
  { label: "AUTREVILLE ST LAMBERT (55700)", value: "55700" },
  { label: "AVILLERS STE CROIX (55210)", value: "55210" },
  { label: "AVIOTH (55600)", value: "55600" },
  { label: "AVOCOURT (55270)", value: "55270" },
  { label: "AZANNES ET SOUMAZANNES (55150)", value: "55150" },
  { label: "BAALON (55700)", value: "55700" },
  { label: "BADONVILLIERS GERAUVILLIERS (55130)", value: "55130" },
  { label: "BADONVILLIERS GERAUVILLIERS (55130)", value: "55130" },
  { label: "BANNONCOURT (55300)", value: "55300" },
  { label: "BANTHEVILLE (55110)", value: "55110" },
  { label: "BAR LE DUC (55000)", value: "55000" },
  { label: "BAUDONVILLIERS (55170)", value: "55170" },
  { label: "BAUDREMONT (55260)", value: "55260" },
  { label: "BAULNY (55270)", value: "55270" },
  { label: "BAZEILLES SUR OTHAIN (55600)", value: "55600" },
  { label: "BAZINCOURT SUR SAULX (55170)", value: "55170" },
  { label: "BEAUCLAIR (55700)", value: "55700" },
  { label: "BEAUFORT EN ARGONNE (55700)", value: "55700" },
  { label: "BEAULIEU EN ARGONNE (55250)", value: "55250" },
  { label: "BEAUMONT EN VERDUNOIS (55100)", value: "55100" },
  { label: "BEAUSITE (55250)", value: "55250" },
  { label: "BEAUSITE (55250)", value: "55250" },
  { label: "BEAUSITE (55250)", value: "55250" },
  { label: "BEAUSITE (55250)", value: "55250" },
  { label: "BEHONNE (55000)", value: "55000" },
  { label: "BELLERAY (55100)", value: "55100" },
  { label: "BELLEVILLE SUR MEUSE (55430)", value: "55430" },
  { label: "BELRAIN (55260)", value: "55260" },
  { label: "BELRUPT EN VERDUNOIS (55100)", value: "55100" },
  { label: "BENEY EN WOEVRE (55210)", value: "55210" },
  { label: "BETHELAINVILLE (55100)", value: "55100" },
  { label: "BETHINCOURT (55270)", value: "55270" },
  { label: "BEUREY SUR SAULX (55000)", value: "55000" },
  { label: "BEZONVAUX (55100)", value: "55100" },
  { label: "BIENCOURT SUR ORGE (55290)", value: "55290" },
  { label: "BILLY SOUS MANGIENNES (55230)", value: "55230" },
  { label: "BISLEE (55300)", value: "55300" },
  { label: "BLANZEE (55400)", value: "55400" },
  { label: "BOINVILLE EN WOEVRE (55400)", value: "55400" },
  { label: "BONCOURT SUR MEUSE (55200)", value: "55200" },
  { label: "BONNET (55130)", value: "55130" },
  { label: "BONZEE (55160)", value: "55160" },
  { label: "BONZEE (55160)", value: "55160" },
  { label: "BONZEE (55160)", value: "55160" },
  { label: "LE BOUCHON SUR SAULX (55500)", value: "55500" },
  { label: "BOUCONVILLE SUR MADT (55300)", value: "55300" },
  { label: "BOULIGNY (55240)", value: "55240" },
  { label: "BOUQUEMONT (55300)", value: "55300" },
  { label: "BOUREUILLES (55270)", value: "55270" },
  { label: "BOVEE SUR BARBOURE (55190)", value: "55190" },
  { label: "BOVIOLLES (55500)", value: "55500" },
  { label: "BRABANT EN ARGONNE (55120)", value: "55120" },
  { label: "BRABANT LE ROI (55800)", value: "55800" },
  { label: "BRABANT SUR MEUSE (55100)", value: "55100" },
  { label: "BRANDEVILLE (55150)", value: "55150" },
  { label: "BRAQUIS (55400)", value: "55400" },
  { label: "BRAS SUR MEUSE (55100)", value: "55100" },
  { label: "BRAUVILLIERS (55170)", value: "55170" },
  { label: "BREHEVILLE (55150)", value: "55150" },
  { label: "BREUX (55600)", value: "55600" },
  { label: "BRIEULLES SUR MEUSE (55110)", value: "55110" },
  { label: "BRILLON EN BARROIS (55000)", value: "55000" },
  { label: "BRIXEY AUX CHANOINES (55140)", value: "55140" },
  { label: "BRIZEAUX (55250)", value: "55250" },
  { label: "BROCOURT EN ARGONNE (55120)", value: "55120" },
  { label: "BROUENNES (55700)", value: "55700" },
  { label: "BROUSSEY EN BLOIS (55190)", value: "55190" },
  { label: "BROUSSEY RAULECOURT (55200)", value: "55200" },
  { label: "BROUSSEY RAULECOURT (55200)", value: "55200" },
  { label: "BURE (55290)", value: "55290" },
  { label: "BUREY EN VAUX (55140)", value: "55140" },
  { label: "BUREY LA COTE (55140)", value: "55140" },
  { label: "BUXIERES SOUS LES COTES (55300)", value: "55300" },
  { label: "BUXIERES SOUS LES COTES (55300)", value: "55300" },
  { label: "BUXIERES SOUS LES COTES (55300)", value: "55300" },
  { label: "BUZY DARMONT (55400)", value: "55400" },
  { label: "BUZY DARMONT (55400)", value: "55400" },
  { label: "CESSE (55700)", value: "55700" },
  { label: "CHAILLON (55210)", value: "55210" },
  { label: "CHALAINES (55140)", value: "55140" },
  { label: "CHAMPNEUVILLE (55100)", value: "55100" },
  { label: "CHAMPOUGNY (55140)", value: "55140" },
  { label: "CHARDOGNE (55000)", value: "55000" },
  { label: "CHARNY SUR MEUSE (55100)", value: "55100" },
  { label: "CHARPENTRY (55270)", value: "55270" },
  { label: "CHASSEY BEAUPRE (55130)", value: "55130" },
  { label: "CHATILLON SOUS LES COTES (55400)", value: "55400" },
  { label: "CHATTANCOURT (55100)", value: "55100" },
  { label: "CHAUMONT DEVANT DAMVILLERS (55150)", value: "55150" },
  { label: "CHAUMONT SUR AIRE (55260)", value: "55260" },
  { label: "CHAUVENCY LE CHATEAU (55600)", value: "55600" },
  { label: "CHAUVENCY ST HUBERT (55600)", value: "55600" },
  { label: "CHAUVONCOURT (55300)", value: "55300" },
  { label: "CHEPPY (55270)", value: "55270" },
  { label: "CHONVILLE MALAUMONT (55200)", value: "55200" },
  { label: "CHONVILLE MALAUMONT (55200)", value: "55200" },
  { label: "CIERGES SOUS MONTFAUCON (55270)", value: "55270" },
  { label: "LE CLAON (55120)", value: "55120" },
  { label: "CLERMONT EN ARGONNE (55120)", value: "55120" },
  { label: "CLERMONT EN ARGONNE (55120)", value: "55120" },
  { label: "CLERMONT EN ARGONNE (55120)", value: "55120" },
  { label: "CLERMONT EN ARGONNE (55120)", value: "55120" },
  { label: "CLERY LE GRAND (55110)", value: "55110" },
  { label: "CLERY LE PETIT (55110)", value: "55110" },
  { label: "COMBLES EN BARROIS (55000)", value: "55000" },
  { label: "COMBRES SOUS LES COTES (55160)", value: "55160" },
  { label: "COMMERCY (55200)", value: "55200" },
  { label: "LES HAUTS DE CHEE (55000)", value: "55000" },
  { label: "LES HAUTS DE CHEE (55000)", value: "55000" },
  { label: "LES HAUTS DE CHEE (55000)", value: "55000" },
  { label: "LES HAUTS DE CHEE (55000)", value: "55000" },
  { label: "LES HAUTS DE CHEE (55000)", value: "55000" },
  { label: "CONSENVOYE (55110)", value: "55110" },
  { label: "CONTRISSON (55800)", value: "55800" },
  { label: "COURCELLES EN BARROIS (55260)", value: "55260" },
  { label: "COURCELLES SUR AIRE (55260)", value: "55260" },
  { label: "COUROUVRE (55260)", value: "55260" },
  { label: "COUSANCES LES FORGES (55170)", value: "55170" },
  { label: "COUVERTPUIS (55290)", value: "55290" },
  { label: "COUVONGES (55800)", value: "55800" },
  { label: "CUISY (55270)", value: "55270" },
  { label: "CULEY (55000)", value: "55000" },
  { label: "CUMIERES LE MORT HOMME (55100)", value: "55100" },
  { label: "CUNEL (55110)", value: "55110" },
  { label: "DAGONVILLE (55500)", value: "55500" },
  { label: "DAINVILLE BERTHELEVILLE (55130)", value: "55130" },
  { label: "DAMLOUP (55400)", value: "55400" },
  { label: "DAMMARIE SUR SAULX (55500)", value: "55500" },
  { label: "DAMVILLERS (55150)", value: "55150" },
  { label: "DANNEVOUX (55110)", value: "55110" },
  { label: "DELOUZE ROSIERES (55130)", value: "55130" },
  { label: "DELOUZE ROSIERES (55130)", value: "55130" },
  { label: "DELUT (55150)", value: "55150" },
  { label: "DEMANGE BAUDIGNECOURT (55130)", value: "55130" },
  { label: "DEMANGE BAUDIGNECOURT (55130)", value: "55130" },
  { label: "DIEPPE SOUS DOUAUMONT (55400)", value: "55400" },
  { label: "DIEUE SUR MEUSE (55320)", value: "55320" },
  { label: "DOMBASLE EN ARGONNE (55120)", value: "55120" },
  { label: "DOMBRAS (55150)", value: "55150" },
  { label: "DOMMARTIN LA MONTAGNE (55160)", value: "55160" },
  { label: "DOMMARY BARONCOURT (55240)", value: "55240" },
  { label: "DOMPCEVRIN (55300)", value: "55300" },
  { label: "DOMPIERRE AUX BOIS (55300)", value: "55300" },
  { label: "DOMREMY LA CANNE (55240)", value: "55240" },
  { label: "DONCOURT AUX TEMPLIERS (55160)", value: "55160" },
  { label: "DOULCON (55110)", value: "55110" },
  { label: "DUGNY SUR MEUSE (55100)", value: "55100" },
  { label: "DUN SUR MEUSE (55110)", value: "55110" },
  { label: "DUZEY (55230)", value: "55230" },
  { label: "ECOUVIEZ (55600)", value: "55600" },
  { label: "ECUREY EN VERDUNOIS (55150)", value: "55150" },
  { label: "EIX (55400)", value: "55400" },
  { label: "LES EPARGES (55160)", value: "55160" },
  { label: "EPIEZ SUR MEUSE (55140)", value: "55140" },
  { label: "EPINONVILLE (55270)", value: "55270" },
  { label: "ERIZE LA BRULEE (55260)", value: "55260" },
  { label: "ERIZE LA PETITE (55260)", value: "55260" },
  { label: "ERIZE ST DIZIER (55000)", value: "55000" },
  { label: "ERNEVILLE AUX BOIS (55500)", value: "55500" },
  { label: "ERNEVILLE AUX BOIS (55500)", value: "55500" },
  { label: "ERNEVILLE AUX BOIS (55500)", value: "55500" },
  { label: "ESNES EN ARGONNE (55100)", value: "55100" },
  { label: "ETAIN (55400)", value: "55400" },
  { label: "ETON (55240)", value: "55240" },
  { label: "ETRAYE (55150)", value: "55150" },
  { label: "EUVILLE (55200)", value: "55200" },
  { label: "EUVILLE (55200)", value: "55200" },
  { label: "EUVILLE (55200)", value: "55200" },
  { label: "EUVILLE (55200)", value: "55200" },
  { label: "EVRES (55250)", value: "55250" },
  { label: "FAINS VEEL (55000)", value: "55000" },
  { label: "FAINS VEEL (55000)", value: "55000" },
  { label: "FLASSIGNY (55600)", value: "55600" },
  { label: "FLEURY DEVANT DOUAUMONT (55100)", value: "55100" },
  { label: "FOAMEIX ORNEL (55400)", value: "55400" },
  { label: "FOAMEIX ORNEL (55400)", value: "55400" },
  { label: "FONTAINES ST CLAIR (55110)", value: "55110" },
  { label: "FORGES SUR MEUSE (55110)", value: "55110" },
  { label: "FOUCAUCOURT SUR THABAS (55250)", value: "55250" },
  { label: "FOUCHERES AUX BOIS (55500)", value: "55500" },
  { label: "FREMEREVILLE SOUS LES COTES (55200)", value: "55200" },
  { label: "FRESNES AU MONT (55260)", value: "55260" },
  { label: "FRESNES EN WOEVRE (55160)", value: "55160" },
  { label: "FROIDOS (55120)", value: "55120" },
  { label: "FROMEREVILLE LES VALLONS (55100)", value: "55100" },
  { label: "FROMEZEY (55400)", value: "55400" },
  { label: "FUTEAU (55120)", value: "55120" },
  { label: "GENICOURT SUR MEUSE (55320)", value: "55320" },
  { label: "GERCOURT ET DRILLANCOURT (55110)", value: "55110" },
  { label: "GERY (55000)", value: "55000" },
  { label: "GESNES EN ARGONNE (55110)", value: "55110" },
  { label: "GIMECOURT (55260)", value: "55260" },
  { label: "GINCREY (55400)", value: "55400" },
  { label: "GIRAUVOISIN (55200)", value: "55200" },
  { label: "GIVRAUVAL (55500)", value: "55500" },
  { label: "GONDRECOURT LE CHATEAU (55130)", value: "55130" },
  { label: "GONDRECOURT LE CHATEAU (55130)", value: "55130" },
  { label: "GONDRECOURT LE CHATEAU (55130)", value: "55130" },
  { label: "GOURAINCOURT (55230)", value: "55230" },
  { label: "GOUSSAINCOURT (55140)", value: "55140" },
  { label: "GREMILLY (55150)", value: "55150" },
  { label: "GRIMAUCOURT EN WOEVRE (55400)", value: "55400" },
  { label: "GRIMAUCOURT PRES SAMPIGNY (55500)", value: "55500" },
  { label: "GUERPONT (55000)", value: "55000" },
  { label: "GUSSAINVILLE (55400)", value: "55400" },
  { label: "HAIRONVILLE (55000)", value: "55000" },
  { label: "HALLES SOUS LES COTES (55700)", value: "55700" },
  { label: "HAN LES JUVIGNY (55600)", value: "55600" },
  { label: "HANNONVILLE SOUS LES COTES (55210)", value: "55210" },
  { label: "HAN SUR MEUSE (55300)", value: "55300" },
  { label: "HAN SUR MEUSE (55300)", value: "55300" },
  { label: "HAN SUR MEUSE (55300)", value: "55300" },
  { label: "HARVILLE (55160)", value: "55160" },
  { label: "HAUDAINVILLE (55100)", value: "55100" },
  { label: "HAUDIOMONT (55160)", value: "55160" },
  { label: "HAUMONT PRES SAMOGNEUX (55100)", value: "55100" },
  { label: "HEIPPES (55220)", value: "55220" },
  { label: "HENNEMONT (55160)", value: "55160" },
  { label: "HERBEUVILLE (55210)", value: "55210" },
  { label: "HERMEVILLE EN WOEVRE (55400)", value: "55400" },
  { label: "HEUDICOURT SOUS LES COTES (55210)", value: "55210" },
  { label: "HEVILLIERS (55290)", value: "55290" },
  { label: "HORVILLE EN ORNOIS (55130)", value: "55130" },
  { label: "HOUDELAINCOURT (55130)", value: "55130" },
  { label: "INOR (55700)", value: "55700" },
  { label: "IPPECOURT (55220)", value: "55220" },
  { label: "IRE LE SEC (55600)", value: "55600" },
  { label: "LES ISLETTES (55120)", value: "55120" },
  { label: "LES TROIS DOMAINES (55220)", value: "55220" },
  { label: "LES TROIS DOMAINES (55220)", value: "55220" },
  { label: "LES TROIS DOMAINES (55220)", value: "55220" },
  { label: "LES TROIS DOMAINES (55220)", value: "55220" },
  { label: "JAMETZ (55600)", value: "55600" },
  { label: "JONVILLE EN WOEVRE (55160)", value: "55160" },
  { label: "JOUY EN ARGONNE (55120)", value: "55120" },
  { label: "GEVILLE (55200)", value: "55200" },
  { label: "GEVILLE (55200)", value: "55200" },
  { label: "GEVILLE (55200)", value: "55200" },
  { label: "JULVECOURT (55120)", value: "55120" },
  { label: "JUVIGNY EN PERTHOIS (55170)", value: "55170" },
  { label: "JUVIGNY SUR LOISON (55600)", value: "55600" },
  { label: "KOEUR LA GRANDE (55300)", value: "55300" },
  { label: "KOEUR LA PETITE (55300)", value: "55300" },
  { label: "LABEUVILLE (55160)", value: "55160" },
  { label: "LACHALADE (55120)", value: "55120" },
  { label: "LACHAUSSEE (55210)", value: "55210" },
  { label: "LACHAUSSEE (55210)", value: "55210" },
  { label: "LACHAUSSEE (55210)", value: "55210" },
  { label: "LACROIX SUR MEUSE (55300)", value: "55300" },
  { label: "LAHAYMEIX (55260)", value: "55260" },
  { label: "LAHAYVILLE (55300)", value: "55300" },
  { label: "LAHEYCOURT (55800)", value: "55800" },
  { label: "LAIMONT (55800)", value: "55800" },
  { label: "LAMORVILLE (55300)", value: "55300" },
  { label: "LAMORVILLE (55300)", value: "55300" },
  { label: "LAMORVILLE (55300)", value: "55300" },
  { label: "LAMORVILLE (55300)", value: "55300" },
  { label: "LAMOUILLY (55700)", value: "55700" },
  { label: "LANDRECOURT LEMPIRE (55100)", value: "55100" },
  { label: "LANDRECOURT LEMPIRE (55100)", value: "55100" },
  { label: "LANEUVILLE AU RUPT (55190)", value: "55190" },
  { label: "LANEUVILLE SUR MEUSE (55700)", value: "55700" },
  { label: "LANHERES (55400)", value: "55400" },
  { label: "LATOUR EN WOEVRE (55160)", value: "55160" },
  { label: "LAVALLEE (55260)", value: "55260" },
  { label: "LAVINCOURT (55170)", value: "55170" },
  { label: "LAVOYE (55120)", value: "55120" },
  { label: "LEMMES (55220)", value: "55220" },
  { label: "LEROUVILLE (55200)", value: "55200" },
  { label: "LEVONCOURT (55260)", value: "55260" },
  { label: "LIGNIERES SUR AIRE (55260)", value: "55260" },
  { label: "LIGNY EN BARROIS (55500)", value: "55500" },
  { label: "LINY DEVANT DUN (55110)", value: "55110" },
  { label: "LION DEVANT DUN (55110)", value: "55110" },
  { label: "LISLE EN BARROIS (55250)", value: "55250" },
  { label: "L'ISLE EN RIGAULT (55000)", value: "55000" },
  { label: "LISSEY (55150)", value: "55150" },
  { label: "LOISEY (55000)", value: "55000" },
  { label: "LOISON (55230)", value: "55230" },
  { label: "LONGEAUX (55500)", value: "55500" },
  { label: "LONGCHAMPS SUR AIRE (55260)", value: "55260" },
  { label: "LONGEVILLE EN BARROIS (55000)", value: "55000" },
  { label: "LOUPMONT (55300)", value: "55300" },
  { label: "LOUPPY LE CHATEAU (55800)", value: "55800" },
  { label: "LOUPPY SUR LOISON (55600)", value: "55600" },
  { label: "LOUVEMONT COTE DU POIVRE (55100)", value: "55100" },
  { label: "LUZY ST MARTIN (55700)", value: "55700" },
  { label: "MAIZERAY (55160)", value: "55160" },
  { label: "MAIZEY (55300)", value: "55300" },
  { label: "MALANCOURT (55270)", value: "55270" },
  { label: "MANDRES EN BARROIS (55290)", value: "55290" },
  { label: "MANGIENNES (55150)", value: "55150" },
  { label: "MANHEULLES (55160)", value: "55160" },
  { label: "MARCHEVILLE EN WOEVRE (55160)", value: "55160" },
  { label: "MARRE (55100)", value: "55100" },
  { label: "MARSON SUR BARBOURE (55190)", value: "55190" },
  { label: "MARTINCOURT SUR MEUSE (55700)", value: "55700" },
  { label: "MARVILLE (55600)", value: "55600" },
  { label: "MAUCOURT SUR ORNE (55400)", value: "55400" },
  { label: "MAULAN (55500)", value: "55500" },
  { label: "MAUVAGES (55190)", value: "55190" },
  { label: "MAXEY SUR VAISE (55140)", value: "55140" },
  { label: "MECRIN (55300)", value: "55300" },
  { label: "MELIGNY LE GRAND (55190)", value: "55190" },
  { label: "MELIGNY LE PETIT (55190)", value: "55190" },
  { label: "MENAUCOURT (55500)", value: "55500" },
  { label: "MENIL AUX BOIS (55260)", value: "55260" },
  { label: "MENIL LA HORGNE (55190)", value: "55190" },
  { label: "MENIL SUR SAULX (55500)", value: "55500" },
  { label: "MERLES SUR LOISON (55150)", value: "55150" },
  { label: "MILLY SUR BRADON (55110)", value: "55110" },
  { label: "MOGEVILLE (55400)", value: "55400" },
  { label: "MOGNEVILLE (55800)", value: "55800" },
  { label: "MOIREY FLABAS CREPION (55150)", value: "55150" },
  { label: "MOIREY FLABAS CREPION (55150)", value: "55150" },
  { label: "MOIREY FLABAS CREPION (55150)", value: "55150" },
  { label: "MONTBLAINVILLE (55270)", value: "55270" },
  { label: "MONTBRAS (55140)", value: "55140" },
  { label: "MONT DEVANT SASSEY (55110)", value: "55110" },
  { label: "MONTFAUCON D ARGONNE (55270)", value: "55270" },
  { label: "LES MONTHAIRONS (55320)", value: "55320" },
  { label: "MONTIERS SUR SAULX (55290)", value: "55290" },
  { label: "MONTIGNY DEVANT SASSEY (55110)", value: "55110" },
  { label: "MONTIGNY LES VAUCOULEURS (55140)", value: "55140" },
  { label: "MONTMEDY (55600)", value: "55600" },
  { label: "MONTPLONNE (55000)", value: "55000" },
  { label: "MONTSEC (55300)", value: "55300" },
  { label: "MONTZEVILLE (55100)", value: "55100" },
  { label: "MORANVILLE (55400)", value: "55400" },
  { label: "MORGEMOULIN (55400)", value: "55400" },
  { label: "CHANTERAINE (55500)", value: "55500" },
  { label: "CHANTERAINE (55500)", value: "55500" },
  { label: "CHANTERAINE (55500)", value: "55500" },
  { label: "MORLEY (55290)", value: "55290" },
  { label: "MOUILLY (55320)", value: "55320" },
  { label: "MOULAINVILLE (55400)", value: "55400" },
  { label: "MOULINS ST HUBERT (55700)", value: "55700" },
  { label: "MOULOTTE (55160)", value: "55160" },
  { label: "MOUZAY (55700)", value: "55700" },
  { label: "MURVAUX (55110)", value: "55110" },
  { label: "VAL D ORNAIN (55000)", value: "55000" },
  { label: "VAL D ORNAIN (55000)", value: "55000" },
  { label: "VAL D ORNAIN (55000)", value: "55000" },
  { label: "MUZERAY (55230)", value: "55230" },
  { label: "NAIVES EN BLOIS (55190)", value: "55190" },
  { label: "NAIVES ROSIERES (55000)", value: "55000" },
  { label: "NAIVES ROSIERES (55000)", value: "55000" },
  { label: "NAIX AUX FORGES (55500)", value: "55500" },
  { label: "NANCOIS LE GRAND (55500)", value: "55500" },
  { label: "NANCOIS SUR ORNAIN (55500)", value: "55500" },
  { label: "NANT LE GRAND (55500)", value: "55500" },
  { label: "NANT LE PETIT (55500)", value: "55500" },
  { label: "NANTILLOIS (55270)", value: "55270" },
  { label: "NANTOIS (55500)", value: "55500" },
  { label: "NEPVANT (55700)", value: "55700" },
  { label: "NETTANCOURT (55800)", value: "55800" },
  { label: "LE NEUFOUR (55120)", value: "55120" },
  { label: "NEUVILLE EN VERDUNOIS (55260)", value: "55260" },
  { label: "NEUVILLE LES VAUCOULEURS (55140)", value: "55140" },
  { label: "NEUVILLE SUR ORNAIN (55800)", value: "55800" },
  { label: "NEUVILLY EN ARGONNE (55120)", value: "55120" },
  { label: "NICEY SUR AIRE (55260)", value: "55260" },
  { label: "NIXEVILLE BLERCOURT (55120)", value: "55120" },
  { label: "NIXEVILLE BLERCOURT (55120)", value: "55120" },
  { label: "NONSARD LAMARCHE (55210)", value: "55210" },
  { label: "NONSARD LAMARCHE (55210)", value: "55210" },
  { label: "NOUILLONPONT (55230)", value: "55230" },
  { label: "NOYERS AUZECOURT (55800)", value: "55800" },
  { label: "NOYERS AUZECOURT (55800)", value: "55800" },
  { label: "NUBECOURT (55250)", value: "55250" },
  { label: "NUBECOURT (55250)", value: "55250" },
  { label: "NUBECOURT (55250)", value: "55250" },
  { label: "OLIZY SUR CHIERS (55700)", value: "55700" },
  { label: "ORNES (55150)", value: "55150" },
  { label: "OSCHES (55220)", value: "55220" },
  { label: "OURCHES SUR MEUSE (55190)", value: "55190" },
  { label: "PAGNY LA BLANCHE COTE (55140)", value: "55140" },
  { label: "PAGNY SUR MEUSE (55190)", value: "55190" },
  { label: "PAREID (55160)", value: "55160" },
  { label: "PARFONDRUPT (55400)", value: "55400" },
  { label: "LES PAROCHES (55300)", value: "55300" },
  { label: "PEUVILLERS (55150)", value: "55150" },
  { label: "PIERREFITTE SUR AIRE (55260)", value: "55260" },
  { label: "PILLON (55230)", value: "55230" },
  { label: "PINTHEVILLE (55160)", value: "55160" },
  { label: "PONT SUR MEUSE (55200)", value: "55200" },
  { label: "POUILLY SUR MEUSE (55700)", value: "55700" },
  { label: "PRETZ EN ARGONNE (55250)", value: "55250" },
  { label: "QUINCY LANDZECOURT (55600)", value: "55600" },
  { label: "RAMBLUZIN ET BENOITE VAUX (55220)", value: "55220" },
  { label: "RAMBUCOURT (55300)", value: "55300" },
  { label: "RANCOURT SUR ORNAIN (55800)", value: "55800" },
  { label: "RANZIERES (55300)", value: "55300" },
  { label: "RARECOURT (55120)", value: "55120" },
  { label: "RECICOURT (55120)", value: "55120" },
  { label: "RECOURT LE CREUX (55220)", value: "55220" },
  { label: "REFFROY (55190)", value: "55190" },
  { label: "REGNEVILLE SUR MEUSE (55110)", value: "55110" },
  { label: "REMBERCOURT SOMMAISNE (55250)", value: "55250" },
  { label: "REMBERCOURT SOMMAISNE (55250)", value: "55250" },
  { label: "REMENNECOURT (55800)", value: "55800" },
  { label: "REMOIVILLE (55600)", value: "55600" },
  { label: "RESSON (55000)", value: "55000" },
  { label: "REVIGNY SUR ORNAIN (55800)", value: "55800" },
  { label: "REVILLE AUX BOIS (55150)", value: "55150" },
  { label: "RIAVILLE (55160)", value: "55160" },
  { label: "RIBEAUCOURT (55290)", value: "55290" },
  { label: "RICHECOURT (55300)", value: "55300" },
  { label: "RIGNY LA SALLE (55140)", value: "55140" },
  { label: "RIGNY ST MARTIN (55140)", value: "55140" },
  { label: "ROBERT ESPAGNE (55000)", value: "55000" },
  { label: "LES ROISES (55130)", value: "55130" },
  { label: "ROMAGNE SOUS LES COTES (55150)", value: "55150" },
  { label: "ROMAGNE SOUS MONTFAUCON (55110)", value: "55110" },
  { label: "RONVAUX (55160)", value: "55160" },
  { label: "RAIVAL (55260)", value: "55260" },
  { label: "RAIVAL (55260)", value: "55260" },
  { label: "ROUVRES EN WOEVRE (55400)", value: "55400" },
  { label: "ROUVROIS SUR MEUSE (55300)", value: "55300" },
  { label: "ROUVROIS SUR OTHAIN (55230)", value: "55230" },
  { label: "RUMONT (55000)", value: "55000" },
  { label: "RUPT AUX NONAINS (55170)", value: "55170" },
  { label: "RUPT DEVANT ST MIHIEL (55260)", value: "55260" },
  { label: "RUPT EN WOEVRE (55320)", value: "55320" },
  { label: "RUPT SUR OTHAIN (55150)", value: "55150" },
  { label: "ST AMAND SUR ORNAIN (55500)", value: "55500" },
  { label: "ST ANDRE EN BARROIS (55220)", value: "55220" },
  { label: "ST AUBIN SUR AIRE (55500)", value: "55500" },
  { label: "ST GERMAIN SUR MEUSE (55140)", value: "55140" },
  { label: "ST HILAIRE EN WOEVRE (55160)", value: "55160" },
  { label: "ST HILAIRE EN WOEVRE (55160)", value: "55160" },
  { label: "ST HILAIRE EN WOEVRE (55160)", value: "55160" },
  { label: "ST JEAN LES BUZY (55400)", value: "55400" },
  { label: "ST JOIRE (55130)", value: "55130" },
  { label: "ST JULIEN SOUS LES COTES (55200)", value: "55200" },
  { label: "ST LAURENT SUR OTHAIN (55150)", value: "55150" },
  { label: "ST MAURICE SOUS LES COTES (55210)", value: "55210" },
  { label: "ST MIHIEL (55300)", value: "55300" },
  { label: "ST PIERREVILLERS (55230)", value: "55230" },
  { label: "ST REMY LA CALONNE (55160)", value: "55160" },
  { label: "SALMAGNE (55000)", value: "55000" },
  { label: "SAMPIGNY (55300)", value: "55300" },
  { label: "SAMOGNEUX (55100)", value: "55100" },
  { label: "SASSEY SUR MEUSE (55110)", value: "55110" },
  { label: "SAUDRUPT (55000)", value: "55000" },
  { label: "SAULMORY VILLEFRANCHE (55110)", value: "55110" },
  { label: "SAULVAUX (55500)", value: "55500" },
  { label: "SAULVAUX (55500)", value: "55500" },
  { label: "SAULVAUX (55500)", value: "55500" },
  { label: "SAULX LES CHAMPLON (55160)", value: "55160" },
  { label: "SAULX LES CHAMPLON (55160)", value: "55160" },
  { label: "SAUVIGNY (55140)", value: "55140" },
  { label: "SAUVOY (55190)", value: "55190" },
  { label: "SAVONNIERES DEVANT BAR (55000)", value: "55000" },
  { label: "SAVONNIERES EN PERTHOIS (55170)", value: "55170" },
  { label: "SEIGNEULLES (55000)", value: "55000" },
  { label: "SENON (55230)", value: "55230" },
  { label: "SENONCOURT LES MAUJOUY (55220)", value: "55220" },
  { label: "SEPTSARGES (55270)", value: "55270" },
  { label: "SEPVIGNY (55140)", value: "55140" },
  { label: "SEUZEY (55300)", value: "55300" },
  { label: "SILMONT (55000)", value: "55000" },
  { label: "SIVRY LA PERCHE (55100)", value: "55100" },
  { label: "SIVRY SUR MEUSE (55110)", value: "55110" },
  { label: "SOMMEDIEUE (55320)", value: "55320" },
  { label: "SOMMEILLES (55800)", value: "55800" },
  { label: "SOMMELONNE (55170)", value: "55170" },
  { label: "SORBEY (55230)", value: "55230" },
  { label: "SORCY ST MARTIN (55190)", value: "55190" },
  { label: "LES SOUHESMES RAMPONT (55220)", value: "55220" },
  { label: "LES SOUHESMES RAMPONT (55220)", value: "55220" },
  { label: "SOUILLY (55220)", value: "55220" },
  { label: "SPINCOURT (55230)", value: "55230" },
  { label: "SPINCOURT (55230)", value: "55230" },
  { label: "SPINCOURT (55230)", value: "55230" },
  { label: "SPINCOURT (55230)", value: "55230" },
  { label: "SPINCOURT (55230)", value: "55230" },
  { label: "STAINVILLE (55500)", value: "55500" },
  { label: "STENAY (55700)", value: "55700" },
  { label: "TAILLANCOURT (55140)", value: "55140" },
  { label: "TANNOIS (55000)", value: "55000" },
  { label: "THIERVILLE SUR MEUSE (55840)", value: "55840" },
  { label: "THILLOMBOIS (55260)", value: "55260" },
  { label: "THILLOT (55210)", value: "55210" },
  { label: "THONNE LA LONG (55600)", value: "55600" },
  { label: "THONNE LE THIL (55600)", value: "55600" },
  { label: "THONNE LES PRES (55600)", value: "55600" },
  { label: "THONNELLE (55600)", value: "55600" },
  { label: "TILLY SUR MEUSE (55220)", value: "55220" },
  { label: "TREMONT SUR SAULX (55000)", value: "55000" },
  { label: "TRESAUVAUX (55160)", value: "55160" },
  { label: "TREVERAY (55130)", value: "55130" },
  { label: "SEUIL D ARGONNE (55250)", value: "55250" },
  { label: "SEUIL D ARGONNE (55250)", value: "55250" },
  { label: "SEUIL D ARGONNE (55250)", value: "55250" },
  { label: "COUSANCES LES TRICONVILLE (55500)", value: "55500" },
  { label: "COUSANCES LES TRICONVILLE (55500)", value: "55500" },
  { label: "TRONVILLE EN BARROIS (55310)", value: "55310" },
  { label: "TROUSSEY (55190)", value: "55190" },
  { label: "TROYON (55300)", value: "55300" },
  { label: "UGNY SUR MEUSE (55140)", value: "55140" },
  { label: "VACHERAUVILLE (55100)", value: "55100" },
  { label: "VADELAINCOURT (55220)", value: "55220" },
  { label: "VADONVILLE (55200)", value: "55200" },
  { label: "VARENNES EN ARGONNE (55270)", value: "55270" },
  { label: "VARNEVILLE (55300)", value: "55300" },
  { label: "VALBOIS (55300)", value: "55300" },
  { label: "VALBOIS (55300)", value: "55300" },
  { label: "VALBOIS (55300)", value: "55300" },
  { label: "VASSINCOURT (55800)", value: "55800" },
  { label: "VAUBECOURT (55250)", value: "55250" },
  { label: "VAUCOULEURS (55140)", value: "55140" },
  { label: "VAUDEVILLE LE HAUT (55130)", value: "55130" },
  { label: "VAUDONCOURT (55230)", value: "55230" },
  { label: "VAUQUOIS (55270)", value: "55270" },
  { label: "DOUAUMONT VAUX (55100)", value: "55100" },
  { label: "DOUAUMONT VAUX (55400)", value: "55400" },
  { label: "VAUX LES PALAMEIX (55300)", value: "55300" },
  { label: "VAVINCOURT (55000)", value: "55000" },
  { label: "VELAINES (55500)", value: "55500" },
  { label: "VELOSNES (55600)", value: "55600" },
  { label: "VERDUN (55100)", value: "55100" },
  { label: "VERNEUIL GRAND (55600)", value: "55600" },
  { label: "VERNEUIL PETIT (55600)", value: "55600" },
  { label: "VERY (55270)", value: "55270" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEULLES LES HATTONCHATEL (55210)", value: "55210" },
  { label: "VIGNEUL SOUS MONTMEDY (55600)", value: "55600" },
  { label: "VIGNOT (55200)", value: "55200" },
  { label: "VILLECLOYE (55600)", value: "55600" },
  { label: "VILLE DEVANT BELRAIN (55260)", value: "55260" },
  { label: "VILLE DEVANT CHAUMONT (55150)", value: "55150" },
  { label: "VILLE EN WOEVRE (55160)", value: "55160" },
  { label: "VILLEROY SUR MEHOLLE (55190)", value: "55190" },
  { label: "VILLERS AUX VENTS (55800)", value: "55800" },
  { label: "VILLERS DEVANT DUN (55110)", value: "55110" },
  { label: "VILLERS LE SEC (55500)", value: "55500" },
  { label: "VILLERS LES MANGIENNES (55150)", value: "55150" },
  { label: "VILLERS SOUS PAREID (55160)", value: "55160" },
  { label: "VILLERS SUR MEUSE (55220)", value: "55220" },
  { label: "VILLE SUR COUSANCES (55120)", value: "55120" },
  { label: "VILLE SUR SAULX (55000)", value: "55000" },
  { label: "VILLOTTE DEVANT LOUPPY (55250)", value: "55250" },
  { label: "VILLOTTE SUR AIRE (55260)", value: "55260" },
  { label: "VILOSNES HARAUMONT (55110)", value: "55110" },
  { label: "VILOSNES HARAUMONT (55110)", value: "55110" },
  { label: "VITTARVILLE (55150)", value: "55150" },
  { label: "VOID VACON (55190)", value: "55190" },
  { label: "VOID VACON (55190)", value: "55190" },
  { label: "VOUTHON BAS (55130)", value: "55130" },
  { label: "VOUTHON HAUT (55130)", value: "55130" },
  { label: "WALY (55250)", value: "55250" },
  { label: "WARCQ (55400)", value: "55400" },
  { label: "WATRONVILLE (55160)", value: "55160" },
  { label: "WAVRILLE (55150)", value: "55150" },
  { label: "WILLERONCOURT (55500)", value: "55500" },
  { label: "WISEPPE (55700)", value: "55700" },
  { label: "WOEL (55210)", value: "55210" },
  { label: "WOIMBEY (55300)", value: "55300" },
  { label: "XIVRAY ET MARVOISIN (55300)", value: "55300" },
  { label: "ALLAIRE (56350)", value: "56350" },
  { label: "AMBON (56190)", value: "56190" },
  { label: "ARRADON (56610)", value: "56610" },
  { label: "ARZAL (56190)", value: "56190" },
  { label: "ARZON (56640)", value: "56640" },
  { label: "ARZON (56640)", value: "56640" },
  { label: "AUGAN (56800)", value: "56800" },
  { label: "AURAY (56400)", value: "56400" },
  { label: "BADEN (56870)", value: "56870" },
  { label: "BANGOR (56360)", value: "56360" },
  { label: "BAUD (56150)", value: "56150" },
  { label: "BEGANNE (56350)", value: "56350" },
  { label: "BEIGNON (56380)", value: "56380" },
  { label: "BELZ (56550)", value: "56550" },
  { label: "BERNE (56240)", value: "56240" },
  { label: "BERRIC (56230)", value: "56230" },
  { label: "BIGNAN (56500)", value: "56500" },
  { label: "BILLIERS (56190)", value: "56190" },
  { label: "BILLIO (56420)", value: "56420" },
  { label: "BOHAL (56140)", value: "56140" },
  { label: "BRANDERION (56700)", value: "56700" },
  { label: "BRANDIVY (56390)", value: "56390" },
  { label: "BRECH (56400)", value: "56400" },
  { label: "BREHAN (56580)", value: "56580" },
  { label: "BRIGNAC (56430)", value: "56430" },
  { label: "BUBRY (56310)", value: "56310" },
  { label: "BULEON (56420)", value: "56420" },
  { label: "CADEN (56220)", value: "56220" },
  { label: "CALAN (56240)", value: "56240" },
  { label: "CAMOEL (56130)", value: "56130" },
  { label: "CAMORS (56330)", value: "56330" },
  { label: "CAMPENEAC (56800)", value: "56800" },
  { label: "CARENTOIR (56910)", value: "56910" },
  { label: "CARENTOIR (56910)", value: "56910" },
  { label: "CARNAC (56340)", value: "56340" },
  { label: "CARO (56140)", value: "56140" },
  { label: "CAUDAN (56850)", value: "56850" },
  { label: "LA CHAPELLE NEUVE (56500)", value: "56500" },
  { label: "CLEGUER (56620)", value: "56620" },
  { label: "CLEGUEREC (56480)", value: "56480" },
  { label: "COLPO (56390)", value: "56390" },
  { label: "CONCORET (56430)", value: "56430" },
  { label: "COURNON (56200)", value: "56200" },
  { label: "LE COURS (56230)", value: "56230" },
  { label: "CRACH (56950)", value: "56950" },
  { label: "CREDIN (56580)", value: "56580" },
  { label: "LE CROISTY (56540)", value: "56540" },
  { label: "LA CROIX HELLEAN (56120)", value: "56120" },
  { label: "CRUGUEL (56420)", value: "56420" },
  { label: "DAMGAN (56750)", value: "56750" },
  { label: "ELVEN (56250)", value: "56250" },
  { label: "ERDEVEN (56410)", value: "56410" },
  { label: "ETEL (56410)", value: "56410" },
  { label: "EVRIGUET (56490)", value: "56490" },
  { label: "LE FAOUET (56320)", value: "56320" },
  { label: "FEREL (56130)", value: "56130" },
  { label: "LES FOUGERETS (56200)", value: "56200" },
  { label: "LA GACILLY (56200)", value: "56200" },
  { label: "LA GACILLY (56200)", value: "56200" },
  { label: "LA GACILLY (56200)", value: "56200" },
  { label: "GAVRES (56680)", value: "56680" },
  { label: "GESTEL (56530)", value: "56530" },
  { label: "GOURHEL (56800)", value: "56800" },
  { label: "GOURIN (56110)", value: "56110" },
  { label: "GRAND CHAMP (56390)", value: "56390" },
  { label: "LA GREE ST LAURENT (56120)", value: "56120" },
  { label: "GROIX (56590)", value: "56590" },
  { label: "GUEGON (56120)", value: "56120" },
  { label: "GUEHENNO (56420)", value: "56420" },
  { label: "GUELTAS (56920)", value: "56920" },
  { label: "GUEMENE SUR SCORFF (56160)", value: "56160" },
  { label: "GUENIN (56150)", value: "56150" },
  { label: "GUER (56380)", value: "56380" },
  { label: "GUER (56380)", value: "56380" },
  { label: "GUERN (56310)", value: "56310" },
  { label: "LE GUERNO (56190)", value: "56190" },
  { label: "GUIDEL (56520)", value: "56520" },
  { label: "GUILLAC (56800)", value: "56800" },
  { label: "GUILLIERS (56490)", value: "56490" },
  { label: "GUISCRIFF (56560)", value: "56560" },
  { label: "HELLEAN (56120)", value: "56120" },
  { label: "HENNEBONT (56700)", value: "56700" },
  { label: "LE HEZO (56450)", value: "56450" },
  { label: "HOEDIC (56170)", value: "56170" },
  { label: "ILE D HOUAT (56170)", value: "56170" },
  { label: "ILE AUX MOINES (56780)", value: "56780" },
  { label: "ILE D ARZ (56840)", value: "56840" },
  { label: "INGUINIEL (56240)", value: "56240" },
  { label: "INZINZAC LOCHRIST (56650)", value: "56650" },
  { label: "JOSSELIN (56120)", value: "56120" },
  { label: "KERFOURN (56920)", value: "56920" },
  { label: "KERGRIST (56300)", value: "56300" },
  { label: "KERVIGNAC (56700)", value: "56700" },
  { label: "LANDAUL (56690)", value: "56690" },
  { label: "LANDEVANT (56690)", value: "56690" },
  { label: "LANESTER (56600)", value: "56600" },
  { label: "LANGOELAN (56160)", value: "56160" },
  { label: "LANGONNET (56630)", value: "56630" },
  { label: "LANGUIDIC (56440)", value: "56440" },
  { label: "FORGES DE LANOUEE (56120)", value: "56120" },
  { label: "FORGES DE LANOUEE (56120)", value: "56120" },
  { label: "LANTILLAC (56120)", value: "56120" },
  { label: "LANVAUDAN (56240)", value: "56240" },
  { label: "LANVENEGEN (56320)", value: "56320" },
  { label: "LARMOR BADEN (56870)", value: "56870" },
  { label: "LARMOR PLAGE (56260)", value: "56260" },
  { label: "LARRE (56230)", value: "56230" },
  { label: "LAUZACH (56190)", value: "56190" },
  { label: "LIGNOL (56160)", value: "56160" },
  { label: "LIMERZEL (56220)", value: "56220" },
  { label: "LIZIO (56460)", value: "56460" },
  { label: "LOCMALO (56160)", value: "56160" },
  { label: "LOCMARIA (56360)", value: "56360" },
  { label: "LOCMARIA GRAND CHAMP (56390)", value: "56390" },
  { label: "LOCMARIAQUER (56740)", value: "56740" },
  { label: "LOCMINE (56500)", value: "56500" },
  { label: "LOCMIQUELIC (56570)", value: "56570" },
  { label: "LOCOAL MENDON (56550)", value: "56550" },
  { label: "LOCQUELTAS (56390)", value: "56390" },
  { label: "LORIENT (56100)", value: "56100" },
  { label: "LOYAT (56800)", value: "56800" },
  { label: "MALANSAC (56220)", value: "56220" },
  { label: "MALESTROIT (56140)", value: "56140" },
  { label: "MALGUENAC (56300)", value: "56300" },
  { label: "MARZAN (56130)", value: "56130" },
  { label: "MAURON (56430)", value: "56430" },
  { label: "MELRAND (56310)", value: "56310" },
  { label: "MENEAC (56490)", value: "56490" },
  { label: "MERLEVENEZ (56700)", value: "56700" },
  { label: "MESLAN (56320)", value: "56320" },
  { label: "MEUCON (56890)", value: "56890" },
  { label: "MISSIRIAC (56140)", value: "56140" },
  { label: "MOHON (56490)", value: "56490" },
  { label: "MOLAC (56230)", value: "56230" },
  { label: "MONTENEUF (56380)", value: "56380" },
  { label: "MONTERBLANC (56250)", value: "56250" },
  { label: "MONTERTELOT (56800)", value: "56800" },
  { label: "MOREAC (56500)", value: "56500" },
  { label: "MOUSTOIR AC (56500)", value: "56500" },
  { label: "MUZILLAC (56190)", value: "56190" },
  { label: "EVELLYS (56500)", value: "56500" },
  { label: "EVELLYS (56500)", value: "56500" },
  { label: "EVELLYS (56500)", value: "56500" },
  { label: "NEANT SUR YVEL (56430)", value: "56430" },
  { label: "NEULLIAC (56300)", value: "56300" },
  { label: "NIVILLAC (56130)", value: "56130" },
  { label: "NOSTANG (56690)", value: "56690" },
  { label: "NOYAL MUZILLAC (56190)", value: "56190" },
  { label: "NOYAL PONTIVY (56920)", value: "56920" },
  { label: "LE PALAIS (56360)", value: "56360" },
  { label: "PEAULE (56130)", value: "56130" },
  { label: "PEILLAC (56220)", value: "56220" },
  { label: "PENESTIN (56760)", value: "56760" },
  { label: "PERSQUEN (56160)", value: "56160" },
  { label: "PLAUDREN (56420)", value: "56420" },
  { label: "PLESCOP (56890)", value: "56890" },
  { label: "PLEUCADEUC (56140)", value: "56140" },
  { label: "PLEUGRIFFET (56120)", value: "56120" },
  { label: "PLOEMEL (56400)", value: "56400" },
  { label: "PLOEMEUR (56270)", value: "56270" },
  { label: "PLOERDUT (56160)", value: "56160" },
  { label: "PLOEREN (56880)", value: "56880" },
  { label: "PLOERMEL (56800)", value: "56800" },
  { label: "PLOERMEL (56800)", value: "56800" },
  { label: "PLOUAY (56240)", value: "56240" },
  { label: "PLOUGOUMELEN (56400)", value: "56400" },
  { label: "PLOUHARNEL (56340)", value: "56340" },
  { label: "PLOUHINEC (56680)", value: "56680" },
  { label: "PLOURAY (56770)", value: "56770" },
  { label: "PLUHERLIN (56220)", value: "56220" },
  { label: "PLUMELEC (56420)", value: "56420" },
  { label: "PLUMELIAU BIEUZY (56310)", value: "56310" },
  { label: "PLUMELIAU BIEUZY (56930)", value: "56930" },
  { label: "PLUMELIN (56500)", value: "56500" },
  { label: "PLUMERGAT (56400)", value: "56400" },
  { label: "PLUNERET (56400)", value: "56400" },
  { label: "PLUVIGNER (56330)", value: "56330" },
  { label: "PLUVIGNER (56330)", value: "56330" },
  { label: "PONTIVY (56300)", value: "56300" },
  { label: "PONT SCORFF (56620)", value: "56620" },
  { label: "PORCARO (56380)", value: "56380" },
  { label: "PORT LOUIS (56290)", value: "56290" },
  { label: "PRIZIAC (56320)", value: "56320" },
  { label: "QUESTEMBERT (56230)", value: "56230" },
  { label: "QUEVEN (56530)", value: "56530" },
  { label: "QUIBERON (56170)", value: "56170" },
  { label: "QUISTINIC (56310)", value: "56310" },
  { label: "RADENAC (56500)", value: "56500" },
  { label: "REGUINY (56500)", value: "56500" },
  { label: "REMINIAC (56140)", value: "56140" },
  { label: "RIANTEC (56670)", value: "56670" },
  { label: "RIEUX (56350)", value: "56350" },
  { label: "LA ROCHE BERNARD (56130)", value: "56130" },
  { label: "ROCHEFORT EN TERRE (56220)", value: "56220" },
  { label: "VAL D OUST (56460)", value: "56460" },
  { label: "VAL D OUST (56460)", value: "56460" },
  { label: "VAL D OUST (56800)", value: "56800" },
  { label: "ROHAN (56580)", value: "56580" },
  { label: "ROHAN (56580)", value: "56580" },
  { label: "ROHAN (56580)", value: "56580" },
  { label: "ROUDOUALLEC (56110)", value: "56110" },
  { label: "RUFFIAC (56140)", value: "56140" },
  { label: "LE SAINT (56110)", value: "56110" },
  { label: "ST ABRAHAM (56140)", value: "56140" },
  { label: "ST AIGNAN (56480)", value: "56480" },
  { label: "ST ALLOUESTRE (56500)", value: "56500" },
  { label: "ST ARMEL (56450)", value: "56450" },
  { label: "ST AVE (56890)", value: "56890" },
  { label: "ST BARTHELEMY (56150)", value: "56150" },
  { label: "ST BRIEUC DE MAURON (56430)", value: "56430" },
  { label: "STE BRIGITTE (56480)", value: "56480" },
  { label: "ST CARADEC TREGOMEL (56540)", value: "56540" },
  { label: "ST CONGARD (56140)", value: "56140" },
  { label: "ST DOLAY (56130)", value: "56130" },
  { label: "ST GERAND CROIXANVEC (56920)", value: "56920" },
  { label: "ST GERAND CROIXANVEC (56920)", value: "56920" },
  { label: "ST GILDAS DE RHUYS (56730)", value: "56730" },
  { label: "ST GONNERY (56920)", value: "56920" },
  { label: "ST GORGON (56350)", value: "56350" },
  { label: "ST GRAVE (56220)", value: "56220" },
  { label: "ST GUYOMARD (56460)", value: "56460" },
  { label: "STE HELENE (56700)", value: "56700" },
  { label: "ST JACUT LES PINS (56220)", value: "56220" },
  { label: "ST JEAN BREVELAY (56660)", value: "56660" },
  { label: "ST JEAN LA POTERIE (56350)", value: "56350" },
  { label: "ST LAURENT SUR OUST (56140)", value: "56140" },
  { label: "ST LERY (56430)", value: "56430" },
  { label: "ST MALO DE BEIGNON (56380)", value: "56380" },
  { label: "ST MALO DES TROIS FONTAINES (56490)", value: "56490" },
  { label: "ST MARCEL (56140)", value: "56140" },
  { label: "ST MARTIN SUR OUST (56200)", value: "56200" },
  { label: "ST NICOLAS DU TERTRE (56910)", value: "56910" },
  { label: "ST NOLFF (56250)", value: "56250" },
  { label: "ST PERREUX (56350)", value: "56350" },
  { label: "ST PHILIBERT (56470)", value: "56470" },
  { label: "ST PIERRE QUIBERON (56510)", value: "56510" },
  { label: "ST SERVANT (56120)", value: "56120" },
  { label: "ST THURIAU (56300)", value: "56300" },
  { label: "ST TUGDUAL (56540)", value: "56540" },
  { label: "ST VINCENT SUR OUST (56350)", value: "56350" },
  { label: "SARZEAU (56370)", value: "56370" },
  { label: "SAUZON (56360)", value: "56360" },
  { label: "SEGLIEN (56160)", value: "56160" },
  { label: "SENE (56860)", value: "56860" },
  { label: "SERENT (56460)", value: "56460" },
  { label: "SILFIAC (56480)", value: "56480" },
  { label: "LE SOURN (56300)", value: "56300" },
  { label: "SULNIAC (56250)", value: "56250" },
  { label: "SURZUR (56450)", value: "56450" },
  { label: "TAUPONT (56800)", value: "56800" },
  { label: "THEHILLAC (56130)", value: "56130" },
  { label: "THEIX NOYALO (56450)", value: "56450" },
  { label: "THEIX NOYALO (56450)", value: "56450" },
  { label: "LE TOUR DU PARC (56370)", value: "56370" },
  { label: "TREAL (56140)", value: "56140" },
  { label: "TREDION (56250)", value: "56250" },
  { label: "TREFFLEAN (56250)", value: "56250" },
  { label: "TREHORENTEUC (56430)", value: "56430" },
  { label: "LA TRINITE PORHOET (56490)", value: "56490" },
  { label: "LA TRINITE SUR MER (56470)", value: "56470" },
  { label: "LA TRINITE SURZUR (56190)", value: "56190" },
  { label: "VANNES (56000)", value: "56000" },
  { label: "LA VRAIE CROIX (56250)", value: "56250" },
  { label: "LE BONO (56400)", value: "56400" },
  { label: "STE ANNE D AURAY (56400)", value: "56400" },
  { label: "KERNASCLEDEN (56540)", value: "56540" },
  { label: "ABONCOURT (57920)", value: "57920" },
  { label: "ABONCOURT SUR SEILLE (57590)", value: "57590" },
  { label: "ABRESCHVILLER (57560)", value: "57560" },
  { label: "ACHAIN (57340)", value: "57340" },
  { label: "ACHEN (57412)", value: "57412" },
  { label: "ADAINCOURT (57580)", value: "57580" },
  { label: "ADELANGE (57380)", value: "57380" },
  { label: "AJONCOURT (57590)", value: "57590" },
  { label: "ALAINCOURT LA COTE (57590)", value: "57590" },
  { label: "ALBESTROFF (57670)", value: "57670" },
  { label: "ALGRANGE (57440)", value: "57440" },
  { label: "ALSTING (57515)", value: "57515" },
  { label: "ALTRIPPE (57660)", value: "57660" },
  { label: "ALTVILLER (57730)", value: "57730" },
  { label: "ALZING (57320)", value: "57320" },
  { label: "AMANVILLERS (57865)", value: "57865" },
  { label: "AMELECOURT (57170)", value: "57170" },
  { label: "AMNEVILLE (57360)", value: "57360" },
  { label: "AMNEVILLE (57360)", value: "57360" },
  { label: "ANCERVILLE (57580)", value: "57580" },
  { label: "ANCY DORNOT (57130)", value: "57130" },
  { label: "ANCY DORNOT (57130)", value: "57130" },
  { label: "ANGEVILLERS (57440)", value: "57440" },
  { label: "ANTILLY (57640)", value: "57640" },
  { label: "ANZELING (57320)", value: "57320" },
  { label: "APACH (57480)", value: "57480" },
  { label: "ARRAINCOURT (57380)", value: "57380" },
  { label: "ARGANCY (57640)", value: "57640" },
  { label: "ARRIANCE (57580)", value: "57580" },
  { label: "ARRY (57680)", value: "57680" },
  { label: "ARS LAQUENEXY (57530)", value: "57530" },
  { label: "ARS SUR MOSELLE (57130)", value: "57130" },
  { label: "ARZVILLER (57405)", value: "57405" },
  { label: "ASPACH (57790)", value: "57790" },
  { label: "ASSENONCOURT (57260)", value: "57260" },
  { label: "ATTILLONCOURT (57170)", value: "57170" },
  { label: "AUBE (57580)", value: "57580" },
  { label: "AUDUN LE TICHE (57390)", value: "57390" },
  { label: "AUGNY (57685)", value: "57685" },
  { label: "AULNOIS SUR SEILLE (57590)", value: "57590" },
  { label: "AUMETZ (57710)", value: "57710" },
  { label: "AVRICOURT (57810)", value: "57810" },
  { label: "AY SUR MOSELLE (57300)", value: "57300" },
  { label: "AZOUDANGE (57810)", value: "57810" },
  { label: "BACOURT (57590)", value: "57590" },
  { label: "BAERENTHAL (57230)", value: "57230" },
  { label: "BAMBIDERSTROFF (57690)", value: "57690" },
  { label: "BANNAY (57220)", value: "57220" },
  { label: "LE BAN ST MARTIN (57050)", value: "57050" },
  { label: "BARCHAIN (57830)", value: "57830" },
  { label: "BARONVILLE (57340)", value: "57340" },
  { label: "BARST (57450)", value: "57450" },
  { label: "BASSING (57260)", value: "57260" },
  { label: "BAUDRECOURT (57580)", value: "57580" },
  { label: "BAZONCOURT (57530)", value: "57530" },
  { label: "BEBING (57830)", value: "57830" },
  { label: "BECHY (57580)", value: "57580" },
  { label: "BEHREN LES FORBACH (57460)", value: "57460" },
  { label: "BELLANGE (57340)", value: "57340" },
  { label: "BENESTROFF (57670)", value: "57670" },
  { label: "BENING LES ST AVOLD (57800)", value: "57800" },
  { label: "BERG SUR MOSELLE (57570)", value: "57570" },
  { label: "BERIG VINTRANGE (57660)", value: "57660" },
  { label: "BERLING (57370)", value: "57370" },
  { label: "BERMERING (57340)", value: "57340" },
  { label: "BERTHELMING (57930)", value: "57930" },
  { label: "BERTRANGE (57310)", value: "57310" },
  { label: "BERVILLER EN MOSELLE (57550)", value: "57550" },
  { label: "BETTANGE (57220)", value: "57220" },
  { label: "BETTBORN (57930)", value: "57930" },
  { label: "BETTELAINVILLE (57640)", value: "57640" },
  { label: "BETTING (57800)", value: "57800" },
  { label: "BETTVILLER (57410)", value: "57410" },
  { label: "BEUX (57580)", value: "57580" },
  { label: "BEYREN LES SIERCK (57570)", value: "57570" },
  { label: "BEZANGE LA PETITE (57630)", value: "57630" },
  { label: "BIBICHE (57320)", value: "57320" },
  { label: "BICKENHOLTZ (57635)", value: "57635" },
  { label: "BIDESTROFF (57260)", value: "57260" },
  { label: "BIDING (57660)", value: "57660" },
  { label: "BINING (57410)", value: "57410" },
  { label: "BIONCOURT (57170)", value: "57170" },
  { label: "BIONVILLE SUR NIED (57220)", value: "57220" },
  { label: "BELLES FORETS (57930)", value: "57930" },
  { label: "BELLES FORETS (57930)", value: "57930" },
  { label: "BELLES FORETS (57930)", value: "57930" },
  { label: "BISTEN EN LORRAINE (57220)", value: "57220" },
  { label: "BISTROFF (57660)", value: "57660" },
  { label: "BITCHE (57230)", value: "57230" },
  { label: "BLANCHE EGLISE (57260)", value: "57260" },
  { label: "BLIESBRUCK (57200)", value: "57200" },
  { label: "BLIES EBERSING (57200)", value: "57200" },
  { label: "BLIES GUERSVILLER (57200)", value: "57200" },
  { label: "BOUCHEPORN (57220)", value: "57220" },
  { label: "BOULANGE (57655)", value: "57655" },
  { label: "BOULAY MOSELLE (57220)", value: "57220" },
  { label: "BOULAY MOSELLE (57220)", value: "57220" },
  { label: "BOURGALTROFF (57260)", value: "57260" },
  { label: "BOURDONNAY (57810)", value: "57810" },
  { label: "BOURSCHEID (57370)", value: "57370" },
  { label: "BOUSBACH (57460)", value: "57460" },
  { label: "BOUSSE (57310)", value: "57310" },
  { label: "BOUSSEVILLER (57230)", value: "57230" },
  { label: "BOUST (57570)", value: "57570" },
  { label: "BOUSTROFF (57380)", value: "57380" },
  { label: "BOUZONVILLE (57320)", value: "57320" },
  { label: "BREHAIN (57340)", value: "57340" },
  { label: "BREIDENBACH (57720)", value: "57720" },
  { label: "BREISTROFF LA GRANDE (57570)", value: "57570" },
  { label: "BRETTNACH (57320)", value: "57320" },
  { label: "BRONVAUX (57535)", value: "57535" },
  { label: "BROUCK (57220)", value: "57220" },
  { label: "BROUDERDORFF (57565)", value: "57565" },
  { label: "BROUVILLER (57635)", value: "57635" },
  { label: "BRULANGE (57340)", value: "57340" },
  { label: "BUCHY (57420)", value: "57420" },
  { label: "BUDING (57920)", value: "57920" },
  { label: "BUDLING (57970)", value: "57970" },
  { label: "BUHL LORRAINE (57400)", value: "57400" },
  { label: "BURLIONCOURT (57170)", value: "57170" },
  { label: "BURTONCOURT (57220)", value: "57220" },
  { label: "CAPPEL (57450)", value: "57450" },
  { label: "CARLING (57490)", value: "57490" },
  { label: "CATTENOM (57570)", value: "57570" },
  { label: "CHAILLY LES ENNERY (57365)", value: "57365" },
  { label: "CHAMBREY (57170)", value: "57170" },
  { label: "CHANVILLE (57580)", value: "57580" },
  { label: "CHARLEVILLE SOUS BOIS (57220)", value: "57220" },
  { label: "CHARLY ORADOUR (57640)", value: "57640" },
  { label: "CHATEAU BREHAIN (57340)", value: "57340" },
  { label: "CHATEAU ROUGE (57320)", value: "57320" },
  { label: "CHATEAU SALINS (57170)", value: "57170" },
  { label: "CHATEAU SALINS (57170)", value: "57170" },
  { label: "CHATEAU VOUE (57170)", value: "57170" },
  { label: "CHATEAU VOUE (57170)", value: "57170" },
  { label: "CHATEL ST GERMAIN (57160)", value: "57160" },
  { label: "CHEMERY LES DEUX (57320)", value: "57320" },
  { label: "CHEMINOT (57420)", value: "57420" },
  { label: "CHENOIS (57580)", value: "57580" },
  { label: "CHERISEY (57420)", value: "57420" },
  { label: "CHESNY (57245)", value: "57245" },
  { label: "CHICOURT (57590)", value: "57590" },
  { label: "CHIEULLES (57070)", value: "57070" },
  { label: "CLOUANGE (57185)", value: "57185" },
  { label: "COCHEREN (57800)", value: "57800" },
  { label: "COCHEREN (57800)", value: "57800" },
  { label: "COINCY (57530)", value: "57530" },
  { label: "COIN LES CUVRY (57420)", value: "57420" },
  { label: "COIN SUR SEILLE (57420)", value: "57420" },
  { label: "COLLIGNY MAIZERY (57530)", value: "57530" },
  { label: "COLLIGNY MAIZERY (57530)", value: "57530" },
  { label: "COLMEN (57320)", value: "57320" },
  { label: "CONDE NORTHEN (57220)", value: "57220" },
  { label: "CONDE NORTHEN (57220)", value: "57220" },
  { label: "CONTHIL (57340)", value: "57340" },
  { label: "CONTZ LES BAINS (57480)", value: "57480" },
  { label: "CORNY SUR MOSELLE (57680)", value: "57680" },
  { label: "COUME (57220)", value: "57220" },
  { label: "COURCELLES CHAUSSY (57530)", value: "57530" },
  { label: "COURCELLES CHAUSSY (57530)", value: "57530" },
  { label: "COURCELLES SUR NIED (57530)", value: "57530" },
  { label: "CRAINCOURT (57590)", value: "57590" },
  { label: "CREHANGE (57690)", value: "57690" },
  { label: "CREUTZWALD (57150)", value: "57150" },
  { label: "CUTTING (57260)", value: "57260" },
  { label: "CUVRY (57420)", value: "57420" },
  { label: "DABO (57850)", value: "57850" },
  { label: "DABO (57850)", value: "57850" },
  { label: "DALEM (57550)", value: "57550" },
  { label: "DALHAIN (57340)", value: "57340" },
  { label: "DALSTEIN (57320)", value: "57320" },
  { label: "DANNE ET QUATRE VENTS (57370)", value: "57370" },
  { label: "DANNELBOURG (57820)", value: "57820" },
  { label: "DELME (57590)", value: "57590" },
  { label: "DENTING (57220)", value: "57220" },
  { label: "DESSELING (57260)", value: "57260" },
  { label: "DESTRY (57340)", value: "57340" },
  { label: "DIANE CAPELLE (57830)", value: "57830" },
  { label: "DIEBLING (57980)", value: "57980" },
  { label: "DIEUZE (57260)", value: "57260" },
  { label: "DIFFEMBACH LES HELLIMER (57660)", value: "57660" },
  { label: "DISTROFF (57925)", value: "57925" },
  { label: "DOLVING (57400)", value: "57400" },
  { label: "DOMNOM LES DIEUZE (57260)", value: "57260" },
  { label: "DONJEUX (57590)", value: "57590" },
  { label: "DONNELAY (57810)", value: "57810" },
  { label: "EBERSVILLER (57320)", value: "57320" },
  { label: "EBLANGE (57220)", value: "57220" },
  { label: "EGUELSHARDT (57230)", value: "57230" },
  { label: "EINCHEVILLE (57340)", value: "57340" },
  { label: "ELVANGE (57690)", value: "57690" },
  { label: "ELZANGE (57970)", value: "57970" },
  { label: "ENCHENBERG (57415)", value: "57415" },
  { label: "ENNERY (57365)", value: "57365" },
  { label: "ENTRANGE (57330)", value: "57330" },
  { label: "EPPING (57720)", value: "57720" },
  { label: "ERCHING (57720)", value: "57720" },
  { label: "ERNESTVILLER (57510)", value: "57510" },
  { label: "ERSTROFF (57660)", value: "57660" },
  { label: "ESCHERANGE (57330)", value: "57330" },
  { label: "LES ETANGS (57530)", value: "57530" },
  { label: "ETTING (57412)", value: "57412" },
  { label: "ETZLING (57460)", value: "57460" },
  { label: "EVRANGE (57570)", value: "57570" },
  { label: "FAILLY (57640)", value: "57640" },
  { label: "FAILLY (57640)", value: "57640" },
  { label: "FALCK (57550)", value: "57550" },
  { label: "FAMECK (57290)", value: "57290" },
  { label: "FAMECK (57290)", value: "57290" },
  { label: "FAMECK (57290)", value: "57290" },
  { label: "FAREBERSVILLER (57450)", value: "57450" },
  { label: "FARSCHVILLER (57450)", value: "57450" },
  { label: "FAULQUEMONT (57380)", value: "57380" },
  { label: "FAULQUEMONT (57380)", value: "57380" },
  { label: "FENETRANGE (57930)", value: "57930" },
  { label: "FEVES (57280)", value: "57280" },
  { label: "FEY (57420)", value: "57420" },
  { label: "FILSTROFF (57320)", value: "57320" },
  { label: "FIXEM (57570)", value: "57570" },
  { label: "FLASTROFF (57320)", value: "57320" },
  { label: "FLEISHEIM (57635)", value: "57635" },
  { label: "FLETRANGE (57690)", value: "57690" },
  { label: "FLEURY (57420)", value: "57420" },
  { label: "FLEVY (57365)", value: "57365" },
  { label: "FLOCOURT (57580)", value: "57580" },
  { label: "FLORANGE (57190)", value: "57190" },
  { label: "FLORANGE (57190)", value: "57190" },
  { label: "FOLKLING (57600)", value: "57600" },
  { label: "FOLSCHVILLER (57730)", value: "57730" },
  { label: "FONTENY (57590)", value: "57590" },
  { label: "FONTOY (57650)", value: "57650" },
  { label: "FORBACH (57600)", value: "57600" },
  { label: "FORBACH (57600)", value: "57600" },
  { label: "FORBACH (57600)", value: "57600" },
  { label: "FOSSIEUX (57590)", value: "57590" },
  { label: "FOULCREY (57830)", value: "57830" },
  { label: "FOULIGNY (57220)", value: "57220" },
  { label: "FOVILLE (57420)", value: "57420" },
  { label: "FRANCALTROFF (57670)", value: "57670" },
  { label: "FRAQUELFING (57790)", value: "57790" },
  { label: "FRAUENBERG (57200)", value: "57200" },
  { label: "FREISTROFF (57320)", value: "57320" },
  { label: "FREMERY (57590)", value: "57590" },
  { label: "FREMESTROFF (57660)", value: "57660" },
  { label: "FRESNES EN SAULNOIS (57170)", value: "57170" },
  { label: "FREYBOUSE (57660)", value: "57660" },
  { label: "FREYMING MERLEBACH (57800)", value: "57800" },
  { label: "FREYMING MERLEBACH (57800)", value: "57800" },
  { label: "FRIBOURG (57810)", value: "57810" },
  { label: "GANDRANGE (57175)", value: "57175" },
  { label: "GARREBOURG (57820)", value: "57820" },
  { label: "GAVISSE (57570)", value: "57570" },
  { label: "GELUCOURT (57260)", value: "57260" },
  { label: "GERBECOURT (57170)", value: "57170" },
  { label: "GIVRYCOURT (57670)", value: "57670" },
  { label: "GLATIGNY (57530)", value: "57530" },
  { label: "GOETZENBRUCK (57620)", value: "57620" },
  { label: "GOETZENBRUCK (57620)", value: "57620" },
  { label: "GOIN (57420)", value: "57420" },
  { label: "GOMELANGE (57220)", value: "57220" },
  { label: "GOMELANGE (57220)", value: "57220" },
  { label: "GONDREXANGE (57815)", value: "57815" },
  { label: "GORZE (57680)", value: "57680" },
  { label: "GOSSELMING (57930)", value: "57930" },
  { label: "GRAVELOTTE (57130)", value: "57130" },
  { label: "GREMECEY (57170)", value: "57170" },
  { label: "GRENING (57660)", value: "57660" },
  { label: "GRINDORFF BIZING (57480)", value: "57480" },
  { label: "GROSBLIEDERSTROFF (57520)", value: "57520" },
  { label: "GROS REDERCHING (57410)", value: "57410" },
  { label: "GROSTENQUIN (57660)", value: "57660" },
  { label: "GRUNDVILLER (57510)", value: "57510" },
  { label: "GUEBENHOUSE (57510)", value: "57510" },
  { label: "GUEBESTROFF (57260)", value: "57260" },
  { label: "GUEBLANGE LES DIEUZE (57260)", value: "57260" },
  { label: "LE VAL DE GUEBLANGE (57430)", value: "57430" },
  { label: "GUEBLING (57260)", value: "57260" },
  { label: "GUENANGE (57310)", value: "57310" },
  { label: "VAL DE BRIDE (57260)", value: "57260" },
  { label: "VAL DE BRIDE (57260)", value: "57260" },
  { label: "GUENVILLER (57470)", value: "57470" },
  { label: "GUERMANGE (57260)", value: "57260" },
  { label: "GUERSTLING (57320)", value: "57320" },
  { label: "GUERTING (57880)", value: "57880" },
  { label: "GUESSLING HEMERING (57380)", value: "57380" },
  { label: "GUINGLANGE (57690)", value: "57690" },
  { label: "GUINKIRCHEN (57220)", value: "57220" },
  { label: "GUINZELING (57670)", value: "57670" },
  { label: "GUNTZVILLER (57405)", value: "57405" },
  { label: "HABOUDANGE (57340)", value: "57340" },
  { label: "HAGEN (57570)", value: "57570" },
  { label: "HAGONDANGE (57300)", value: "57300" },
  { label: "HALLERING (57690)", value: "57690" },
  { label: "HALSTROFF (57480)", value: "57480" },
  { label: "BASSE HAM (57970)", value: "57970" },
  { label: "HAM SOUS VARSBERG (57880)", value: "57880" },
  { label: "HAMBACH (57910)", value: "57910" },
  { label: "HAMPONT (57170)", value: "57170" },
  { label: "HANGVILLER (57370)", value: "57370" },
  { label: "HANNOCOURT (57590)", value: "57590" },
  { label: "HAN SUR NIED (57580)", value: "57580" },
  { label: "HANVILLER (57230)", value: "57230" },
  { label: "HARAUCOURT SUR SEILLE (57630)", value: "57630" },
  { label: "HARGARTEN AUX MINES (57550)", value: "57550" },
  { label: "HARPRICH (57340)", value: "57340" },
  { label: "HARREBERG (57870)", value: "57870" },
  { label: "HARTZVILLER (57870)", value: "57870" },
  { label: "HASELBOURG (57850)", value: "57850" },
  { label: "HASPELSCHIEDT (57230)", value: "57230" },
  { label: "HATTIGNY (57790)", value: "57790" },
  { label: "HAUCONCOURT (57280)", value: "57280" },
  { label: "HAUT CLOCHER (57400)", value: "57400" },
  { label: "HAVANGE (57650)", value: "57650" },
  { label: "HAYANGE (57700)", value: "57700" },
  { label: "HAYANGE (57700)", value: "57700" },
  { label: "HAYANGE (57700)", value: "57700" },
  { label: "HAYES (57530)", value: "57530" },
  { label: "HAZEMBOURG (57430)", value: "57430" },
  { label: "HEINING LES BOUZONVILLE (57320)", value: "57320" },
  { label: "HELLERING LES FENETRANGE (57930)", value: "57930" },
  { label: "HELLIMER (57660)", value: "57660" },
  { label: "HELSTROFF (57220)", value: "57220" },
  { label: "HEMILLY (57690)", value: "57690" },
  { label: "HEMING (57830)", value: "57830" },
  { label: "HENRIDORFF (57820)", value: "57820" },
  { label: "HENRIVILLE (57450)", value: "57450" },
  { label: "HERANGE (57635)", value: "57635" },
  { label: "HERMELANGE (57790)", value: "57790" },
  { label: "HERNY (57580)", value: "57580" },
  { label: "HERTZING (57830)", value: "57830" },
  { label: "HESSE (57400)", value: "57400" },
  { label: "HESTROFF (57320)", value: "57320" },
  { label: "HETTANGE GRANDE (57330)", value: "57330" },
  { label: "HILBESHEIM (57400)", value: "57400" },
  { label: "HILSPRICH (57510)", value: "57510" },
  { label: "HINCKANGE (57220)", value: "57220" },
  { label: "HOLACOURT (57380)", value: "57380" },
  { label: "HOLLING (57220)", value: "57220" },
  { label: "HOLVING (57510)", value: "57510" },
  { label: "HOMBOURG BUDANGE (57920)", value: "57920" },
  { label: "HOMBOURG HAUT (57470)", value: "57470" },
  { label: "HOMMARTING (57405)", value: "57405" },
  { label: "HOMMERT (57870)", value: "57870" },
  { label: "HONSKIRCH (57670)", value: "57670" },
  { label: "L'HOPITAL (57490)", value: "57490" },
  { label: "HOSTE (57510)", value: "57510" },
  { label: "HOTTVILLER (57720)", value: "57720" },
  { label: "HULTEHOUSE (57820)", value: "57820" },
  { label: "HUNDLING (57990)", value: "57990" },
  { label: "HUNTING (57480)", value: "57480" },
  { label: "IBIGNY (57830)", value: "57830" },
  { label: "ILLANGE (57970)", value: "57970" },
  { label: "IMLING (57400)", value: "57400" },
  { label: "INGLANGE (57970)", value: "57970" },
  { label: "INSMING (57670)", value: "57670" },
  { label: "INSVILLER (57670)", value: "57670" },
  { label: "IPPLING (57990)", value: "57990" },
  { label: "JALLAUCOURT (57590)", value: "57590" },
  { label: "JOUY AUX ARCHES (57130)", value: "57130" },
  { label: "JURY (57245)", value: "57245" },
  { label: "JUSSY (57130)", value: "57130" },
  { label: "JUVELIZE (57630)", value: "57630" },
  { label: "JUVILLE (57590)", value: "57590" },
  { label: "KALHAUSEN (57412)", value: "57412" },
  { label: "KANFEN (57330)", value: "57330" },
  { label: "KAPPELKINGER (57430)", value: "57430" },
  { label: "KEDANGE SUR CANNER (57920)", value: "57920" },
  { label: "KEMPLICH (57920)", value: "57920" },
  { label: "KERBACH (57460)", value: "57460" },
  { label: "KERLING LES SIERCK (57480)", value: "57480" },
  { label: "KERPRICH AUX BOIS (57830)", value: "57830" },
  { label: "KIRSCH LES SIERCK (57480)", value: "57480" },
  { label: "KIRSCHNAUMEN (57480)", value: "57480" },
  { label: "KIRVILLER (57430)", value: "57430" },
  { label: "KLANG (57920)", value: "57920" },
  { label: "KNUTANGE (57240)", value: "57240" },
  { label: "KOENIGSMACKER (57970)", value: "57970" },
  { label: "HAUTE KONTZ (57480)", value: "57480" },
  { label: "KUNTZIG (57970)", value: "57970" },
  { label: "LACHAMBRE (57730)", value: "57730" },
  { label: "LAFRIMBOLLE (57560)", value: "57560" },
  { label: "LAGARDE (57810)", value: "57810" },
  { label: "LAMBACH (57410)", value: "57410" },
  { label: "LANDANGE (57830)", value: "57830" },
  { label: "LANDROFF (57340)", value: "57340" },
  { label: "LANEUVEVILLE LES LORQUIN (57790)", value: "57790" },
  { label: "LANEUVEVILLE EN SAULNOIS (57590)", value: "57590" },
  { label: "LANGATTE (57400)", value: "57400" },
  { label: "LANGUIMBERG (57810)", value: "57810" },
  { label: "LANING (57660)", value: "57660" },
  { label: "LAQUENEXY (57530)", value: "57530" },
  { label: "LAUDREFANG (57385)", value: "57385" },
  { label: "LAUMESFELD (57480)", value: "57480" },
  { label: "LAUNSTROFF (57480)", value: "57480" },
  { label: "LELLING (57660)", value: "57660" },
  { label: "LEMBERG (57620)", value: "57620" },
  { label: "LEMONCOURT (57590)", value: "57590" },
  { label: "LEMUD (57580)", value: "57580" },
  { label: "LENGELSHEIM (57720)", value: "57720" },
  { label: "LENING (57670)", value: "57670" },
  { label: "LESSE (57580)", value: "57580" },
  { label: "LESSY (57160)", value: "57160" },
  { label: "LEY (57810)", value: "57810" },
  { label: "LEYVILLER (57660)", value: "57660" },
  { label: "LEZEY (57630)", value: "57630" },
  { label: "LIDREZING (57340)", value: "57340" },
  { label: "LIEDERSCHIEDT (57230)", value: "57230" },
  { label: "LIEHON (57420)", value: "57420" },
  { label: "LINDRE BASSE (57260)", value: "57260" },
  { label: "LINDRE HAUTE (57260)", value: "57260" },
  { label: "LIOCOURT (57590)", value: "57590" },
  { label: "LIXHEIM (57635)", value: "57635" },
  { label: "LIXING LES ROUHLING (57520)", value: "57520" },
  { label: "LIXING LES ST AVOLD (57660)", value: "57660" },
  { label: "LHOR (57670)", value: "57670" },
  { label: "LOMMERANGE (57650)", value: "57650" },
  { label: "LONGEVILLE LES METZ (57050)", value: "57050" },
  { label: "LONGEVILLE LES ST AVOLD (57740)", value: "57740" },
  { label: "LORQUIN (57790)", value: "57790" },
  { label: "LORRY LES METZ (57050)", value: "57050" },
  { label: "LORRY MARDIGNY (57420)", value: "57420" },
  { label: "LOSTROFF (57670)", value: "57670" },
  { label: "LOUDREFING (57670)", value: "57670" },
  { label: "LOUPERSHOUSE (57510)", value: "57510" },
  { label: "LOUTZVILLER (57720)", value: "57720" },
  { label: "LOUVIGNY (57420)", value: "57420" },
  { label: "LUBECOURT (57170)", value: "57170" },
  { label: "LUCY (57590)", value: "57590" },
  { label: "LUPPY (57580)", value: "57580" },
  { label: "LUTTANGE (57935)", value: "57935" },
  { label: "LUTZELBOURG (57820)", value: "57820" },
  { label: "MACHEREN (57730)", value: "57730" },
  { label: "MACHEREN (57730)", value: "57730" },
  { label: "MAINVILLERS (57380)", value: "57380" },
  { label: "MAIZEROY (57530)", value: "57530" },
  { label: "MAIZIERES LES METZ (57280)", value: "57280" },
  { label: "MAIZIERES LES VIC (57810)", value: "57810" },
  { label: "MALAUCOURT SUR SEILLE (57590)", value: "57590" },
  { label: "MALLING (57480)", value: "57480" },
  { label: "MALROY (57640)", value: "57640" },
  { label: "MANDEREN RITZING (57480)", value: "57480" },
  { label: "MANDEREN RITZING (57480)", value: "57480" },
  { label: "MANHOUE (57590)", value: "57590" },
  { label: "MANOM (57100)", value: "57100" },
  { label: "MANY (57380)", value: "57380" },
  { label: "MARANGE SILVANGE (57535)", value: "57535" },
  { label: "MARANGE ZONDRANGE (57690)", value: "57690" },
  { label: "MARIEULLES (57420)", value: "57420" },
  { label: "MARIMONT LES BENESTROFF (57670)", value: "57670" },
  { label: "MARLY (57155)", value: "57155" },
  { label: "MARSAL (57630)", value: "57630" },
  { label: "MARSILLY (57530)", value: "57530" },
  { label: "MARTHILLE (57340)", value: "57340" },
  { label: "LA MAXE (57140)", value: "57140" },
  { label: "MAXSTADT (57660)", value: "57660" },
  { label: "MECLEUVES (57245)", value: "57245" },
  { label: "MEGANGE (57220)", value: "57220" },
  { label: "MEISENTHAL (57960)", value: "57960" },
  { label: "MENSKIRCH (57320)", value: "57320" },
  { label: "MERSCHWEILLER (57480)", value: "57480" },
  { label: "MERTEN (57550)", value: "57550" },
  { label: "METAIRIES ST QUIRIN (57560)", value: "57560" },
  { label: "METTING (57370)", value: "57370" },
  { label: "METZ (57000)", value: "57000" },
  { label: "METZ (57050)", value: "57050" },
  { label: "METZ (57070)", value: "57070" },
  { label: "METZERESCHE (57920)", value: "57920" },
  { label: "METZERVISSE (57940)", value: "57940" },
  { label: "METZING (57980)", value: "57980" },
  { label: "MEY (57070)", value: "57070" },
  { label: "MITTELBRONN (57370)", value: "57370" },
  { label: "MITTERSHEIM (57930)", value: "57930" },
  { label: "MOLRING (57670)", value: "57670" },
  { label: "MOMERSTROFF (57220)", value: "57220" },
  { label: "MONCHEUX (57420)", value: "57420" },
  { label: "MONCOURT (57810)", value: "57810" },
  { label: "MONDELANGE (57300)", value: "57300" },
  { label: "MONDORFF (57570)", value: "57570" },
  { label: "MONNEREN (57920)", value: "57920" },
  { label: "MONTBRONN (57415)", value: "57415" },
  { label: "MONTDIDIER (57670)", value: "57670" },
  { label: "MONTENACH (57480)", value: "57480" },
  { label: "MONTIGNY LES METZ (57950)", value: "57950" },
  { label: "MONTOIS LA MONTAGNE (57860)", value: "57860" },
  { label: "OGY MONTOY FLANVILLE (57530)", value: "57530" },
  { label: "OGY MONTOY FLANVILLE (57645)", value: "57645" },
  { label: "MORHANGE (57340)", value: "57340" },
  { label: "MORSBACH (57600)", value: "57600" },
  { label: "MORVILLE LES VIC (57170)", value: "57170" },
  { label: "MORVILLE SUR NIED (57590)", value: "57590" },
  { label: "MOULINS LES METZ (57160)", value: "57160" },
  { label: "MOULINS LES METZ (57160)", value: "57160" },
  { label: "MOUSSEY (57770)", value: "57770" },
  { label: "MOUTERHOUSE (57620)", value: "57620" },
  { label: "MOYENVIC (57630)", value: "57630" },
  { label: "MOYEUVRE GRANDE (57250)", value: "57250" },
  { label: "MOYEUVRE GRANDE (57250)", value: "57250" },
  { label: "MOYEUVRE PETITE (57250)", value: "57250" },
  { label: "MULCEY (57260)", value: "57260" },
  { label: "MUNSTER (57670)", value: "57670" },
  { label: "NARBEFONTAINE (57220)", value: "57220" },
  { label: "NEBING (57670)", value: "57670" },
  { label: "NELLING (57670)", value: "57670" },
  { label: "NEUFCHEF (57700)", value: "57700" },
  { label: "NEUFGRANGE (57910)", value: "57910" },
  { label: "NEUFMOULINS (57830)", value: "57830" },
  { label: "NEUFVILLAGE (57670)", value: "57670" },
  { label: "NEUNKIRCHEN LES BOUZONVILLE (57320)", value: "57320" },
  { label: "NIDERHOFF (57560)", value: "57560" },
  { label: "NIDERVILLER (57565)", value: "57565" },
  { label: "NIEDERSTINZEL (57930)", value: "57930" },
  { label: "NIEDERVISSE (57220)", value: "57220" },
  { label: "NILVANGE (57240)", value: "57240" },
  { label: "NITTING (57790)", value: "57790" },
  { label: "NOISSEVILLE (57645)", value: "57645" },
  { label: "NORROY LE VENEUR (57140)", value: "57140" },
  { label: "NOUILLY (57645)", value: "57645" },
  { label: "NOUSSEVILLER LES BITCHE (57720)", value: "57720" },
  { label: "NOUSSEVILLER ST NABOR (57990)", value: "57990" },
  { label: "NOVEANT SUR MOSELLE (57680)", value: "57680" },
  { label: "OBERDORFF (57320)", value: "57320" },
  { label: "OBERGAILBACH (57720)", value: "57720" },
  { label: "OBERSTINZEL (57930)", value: "57930" },
  { label: "OBERVISSE (57220)", value: "57220" },
  { label: "OBRECK (57170)", value: "57170" },
  { label: "OETING (57600)", value: "57600" },
  { label: "OMMERAY (57810)", value: "57810" },
  { label: "ORIOCOURT (57590)", value: "57590" },
  { label: "ORMERSVILLER (57720)", value: "57720" },
  { label: "ORNY (57420)", value: "57420" },
  { label: "ORON (57590)", value: "57590" },
  { label: "OTTANGE (57840)", value: "57840" },
  { label: "OTTONVILLE (57220)", value: "57220" },
  { label: "OUDRENNE (57970)", value: "57970" },
  { label: "PAGNY LES GOIN (57420)", value: "57420" },
  { label: "PANGE (57530)", value: "57530" },
  { label: "PELTRE (57245)", value: "57245" },
  { label: "PETIT REDERCHING (57410)", value: "57410" },
  { label: "PETIT TENQUIN (57660)", value: "57660" },
  { label: "PETITE ROSSELLE (57540)", value: "57540" },
  { label: "PETTONCOURT (57170)", value: "57170" },
  { label: "PEVANGE (57340)", value: "57340" },
  { label: "PHALSBOURG (57370)", value: "57370" },
  { label: "PHILIPPSBOURG (57230)", value: "57230" },
  { label: "PIBLANGE (57220)", value: "57220" },
  { label: "PIBLANGE (57220)", value: "57220" },
  { label: "PIERREVILLERS (57120)", value: "57120" },
  { label: "PLAINE DE WALSCH (57870)", value: "57870" },
  { label: "PLAPPEVILLE (57050)", value: "57050" },
  { label: "PLESNOIS (57140)", value: "57140" },
  { label: "POMMERIEUX (57420)", value: "57420" },
  { label: "PONTOY (57420)", value: "57420" },
  { label: "PONTPIERRE (57380)", value: "57380" },
  { label: "PORCELETTE (57890)", value: "57890" },
  { label: "POSTROFF (57930)", value: "57930" },
  { label: "POUILLY (57420)", value: "57420" },
  { label: "POURNOY LA CHETIVE (57420)", value: "57420" },
  { label: "POURNOY LA GRASSE (57420)", value: "57420" },
  { label: "PREVOCOURT (57590)", value: "57590" },
  { label: "PUTTELANGE AUX LACS (57510)", value: "57510" },
  { label: "PUTTELANGE LES THIONVILLE (57570)", value: "57570" },
  { label: "PUTTIGNY (57170)", value: "57170" },
  { label: "PUZIEUX (57590)", value: "57590" },
  { label: "RACRANGE (57340)", value: "57340" },
  { label: "RAHLING (57410)", value: "57410" },
  { label: "RANGUEVAUX (57700)", value: "57700" },
  { label: "RAVILLE (57530)", value: "57530" },
  { label: "RECHICOURT LE CHATEAU (57810)", value: "57810" },
  { label: "REDANGE (57390)", value: "57390" },
  { label: "REDING (57445)", value: "57445" },
  { label: "REMELFANG (57320)", value: "57320" },
  { label: "REMELFING (57200)", value: "57200" },
  { label: "REMELING (57480)", value: "57480" },
  { label: "REMERING (57550)", value: "57550" },
  { label: "REMERING LES PUTTELANGE (57510)", value: "57510" },
  { label: "REMILLY (57580)", value: "57580" },
  { label: "RENING (57670)", value: "57670" },
  { label: "BASSE RENTGEN (57570)", value: "57570" },
  { label: "RETONFEY (57645)", value: "57645" },
  { label: "RETTEL (57480)", value: "57480" },
  { label: "REYERSVILLER (57230)", value: "57230" },
  { label: "REZONVILLE VIONVILLE (57130)", value: "57130" },
  { label: "REZONVILLE VIONVILLE (57130)", value: "57130" },
  { label: "RHODES (57810)", value: "57810" },
  { label: "RICHE (57340)", value: "57340" },
  { label: "RICHELING (57510)", value: "57510" },
  { label: "RICHEMONT (57270)", value: "57270" },
  { label: "RICHEVAL (57830)", value: "57830" },
  { label: "RIMLING (57720)", value: "57720" },
  { label: "ROCHONVILLERS (57840)", value: "57840" },
  { label: "RODALBE (57340)", value: "57340" },
  { label: "RODEMACK (57570)", value: "57570" },
  { label: "ROHRBACH LES BITCHE (57410)", value: "57410" },
  { label: "ROLBING (57720)", value: "57720" },
  { label: "ROMBAS (57120)", value: "57120" },
  { label: "ROMELFING (57930)", value: "57930" },
  { label: "RONCOURT (57860)", value: "57860" },
  { label: "ROPPEVILLER (57230)", value: "57230" },
  { label: "RORBACH LES DIEUZE (57260)", value: "57260" },
  { label: "ROSBRUCK (57800)", value: "57800" },
  { label: "ROSSELANGE (57780)", value: "57780" },
  { label: "ROUHLING (57520)", value: "57520" },
  { label: "ROUPELDANGE (57220)", value: "57220" },
  { label: "ROUSSY LE VILLAGE (57330)", value: "57330" },
  { label: "ROZERIEULLES (57160)", value: "57160" },
  { label: "RURANGE LES THIONVILLE (57310)", value: "57310" },
  { label: "RUSSANGE (57390)", value: "57390" },
  { label: "RUSTROFF (57480)", value: "57480" },
  { label: "SAILLY ACHATEL (57420)", value: "57420" },
  { label: "ST AVOLD (57500)", value: "57500" },
  { label: "STE BARBE (57640)", value: "57640" },
  { label: "ST EPVRE (57580)", value: "57580" },
  { label: "ST FRANCOIS LACROIX (57320)", value: "57320" },
  { label: "ST GEORGES (57830)", value: "57830" },
  { label: "ST HUBERT (57640)", value: "57640" },
  { label: "ST JEAN DE BASSEL (57930)", value: "57930" },
  { label: "ST JEAN KOURTZERODE (57370)", value: "57370" },
  { label: "ST JEAN ROHRBACH (57510)", value: "57510" },
  { label: "ST JULIEN LES METZ (57070)", value: "57070" },
  { label: "ST JURE (57420)", value: "57420" },
  { label: "ST LOUIS (57820)", value: "57820" },
  { label: "ST LOUIS LES BITCHE (57620)", value: "57620" },
  { label: "STE MARIE AUX CHENES (57255)", value: "57255" },
  { label: "ST MEDARD (57260)", value: "57260" },
  { label: "ST PRIVAT LA MONTAGNE (57855)", value: "57855" },
  { label: "ST QUIRIN (57560)", value: "57560" },
  { label: "STE RUFFINE (57130)", value: "57130" },
  { label: "SALONNES (57170)", value: "57170" },
  { label: "SANRY LES VIGY (57640)", value: "57640" },
  { label: "SANRY SUR NIED (57530)", value: "57530" },
  { label: "SARRALBE (57430)", value: "57430" },
  { label: "SARRALTROFF (57400)", value: "57400" },
  { label: "SARREBOURG (57400)", value: "57400" },
  { label: "SARREGUEMINES (57200)", value: "57200" },
  { label: "SARREGUEMINES (57200)", value: "57200" },
  { label: "SARREINSMING (57905)", value: "57905" },
  { label: "SAULNY (57140)", value: "57140" },
  { label: "SCHALBACH (57370)", value: "57370" },
  { label: "SCHMITTVILLER (57412)", value: "57412" },
  { label: "SCHNECKENBUSCH (57400)", value: "57400" },
  { label: "SCHOENECK (57350)", value: "57350" },
  { label: "SCHORBACH (57230)", value: "57230" },
  { label: "SCHWERDORFF (57320)", value: "57320" },
  { label: "SCHWEYEN (57720)", value: "57720" },
  { label: "SCY CHAZELLES (57160)", value: "57160" },
  { label: "SECOURT (57420)", value: "57420" },
  { label: "SEINGBOUSE (57455)", value: "57455" },
  { label: "SEMECOURT (57280)", value: "57280" },
  { label: "SEREMANGE ERZANGE (57290)", value: "57290" },
  { label: "SERVIGNY LES RAVILLE (57530)", value: "57530" },
  { label: "SERVIGNY LES STE BARBE (57640)", value: "57640" },
  { label: "SIERCK LES BAINS (57480)", value: "57480" },
  { label: "SIERSTHAL (57410)", value: "57410" },
  { label: "SILLEGNY (57420)", value: "57420" },
  { label: "SILLY EN SAULNOIS (57420)", value: "57420" },
  { label: "SILLY SUR NIED (57530)", value: "57530" },
  { label: "SOLGNE (57420)", value: "57420" },
  { label: "SORBEY (57580)", value: "57580" },
  { label: "SOTZELING (57170)", value: "57170" },
  { label: "SOUCHT (57960)", value: "57960" },
  { label: "SPICHEREN (57350)", value: "57350" },
  { label: "SPICHEREN (57350)", value: "57350" },
  { label: "STIRING WENDEL (57350)", value: "57350" },
  { label: "STIRING WENDEL (57350)", value: "57350" },
  { label: "STIRING WENDEL (57350)", value: "57350" },
  { label: "STURZELBRONN (57230)", value: "57230" },
  { label: "SUISSE (57340)", value: "57340" },
  { label: "TALANGE (57525)", value: "57525" },
  { label: "TARQUIMPOL (57260)", value: "57260" },
  { label: "TENTELING (57980)", value: "57980" },
  { label: "TERVILLE (57180)", value: "57180" },
  { label: "TETERCHEN (57220)", value: "57220" },
  { label: "TETING SUR NIED (57385)", value: "57385" },
  { label: "THEDING (57450)", value: "57450" },
  { label: "THICOURT (57380)", value: "57380" },
  { label: "THIMONVILLE (57580)", value: "57580" },
  { label: "THIONVILLE (57100)", value: "57100" },
  { label: "THIONVILLE (57100)", value: "57100" },
  { label: "THIONVILLE (57100)", value: "57100" },
  { label: "THIONVILLE (57100)", value: "57100" },
  { label: "THONVILLE (57380)", value: "57380" },
  { label: "TINCRY (57590)", value: "57590" },
  { label: "TORCHEVILLE (57670)", value: "57670" },
  { label: "TRAGNY (57580)", value: "57580" },
  { label: "TREMERY (57300)", value: "57300" },
  { label: "TRESSANGE (57710)", value: "57710" },
  { label: "TRESSANGE (57710)", value: "57710" },
  { label: "TRITTELING REDLACH (57385)", value: "57385" },
  { label: "TROISFONTAINES (57870)", value: "57870" },
  { label: "TROISFONTAINES (57870)", value: "57870" },
  { label: "TROMBORN (57320)", value: "57320" },
  { label: "TURQUESTEIN BLANCRUPT (57560)", value: "57560" },
  { label: "UCKANGE (57270)", value: "57270" },
  { label: "VAHL EBERSING (57660)", value: "57660" },
  { label: "VAHL LES BENESTROFF (57670)", value: "57670" },
  { label: "VAHL LES FAULQUEMONT (57380)", value: "57380" },
  { label: "VALLERANGE (57340)", value: "57340" },
  { label: "VALMESTROFF (57970)", value: "57970" },
  { label: "VALMONT (57730)", value: "57730" },
  { label: "VALMUNSTER (57220)", value: "57220" },
  { label: "VANNECOURT (57340)", value: "57340" },
  { label: "VANTOUX (57070)", value: "57070" },
  { label: "VANY (57070)", value: "57070" },
  { label: "VARIZE VAUDONCOURT (57220)", value: "57220" },
  { label: "VARIZE VAUDONCOURT (57220)", value: "57220" },
  { label: "VARSBERG (57880)", value: "57880" },
  { label: "VASPERVILLER (57560)", value: "57560" },
  { label: "VATIMONT (57580)", value: "57580" },
  { label: "VAUDRECHING (57320)", value: "57320" },
  { label: "VAUX (57130)", value: "57130" },
  { label: "VAXY (57170)", value: "57170" },
  { label: "VECKERSVILLER (57370)", value: "57370" },
  { label: "VECKRING (57920)", value: "57920" },
  { label: "VELVING (57220)", value: "57220" },
  { label: "VERGAVILLE (57260)", value: "57260" },
  { label: "VERNEVILLE (57130)", value: "57130" },
  { label: "VERNY (57420)", value: "57420" },
  { label: "VESCHEIM (57370)", value: "57370" },
  { label: "VIBERSVILLER (57670)", value: "57670" },
  { label: "VIC SUR SEILLE (57630)", value: "57630" },
  { label: "VIEUX LIXHEIM (57635)", value: "57635" },
  { label: "HAUTE VIGNEULLES (57690)", value: "57690" },
  { label: "VIGNY (57420)", value: "57420" },
  { label: "VIGY (57640)", value: "57640" },
  { label: "VILLER (57340)", value: "57340" },
  { label: "VILLERS STONCOURT (57530)", value: "57530" },
  { label: "VILLERS SUR NIED (57340)", value: "57340" },
  { label: "VILLING (57550)", value: "57550" },
  { label: "VILSBERG (57370)", value: "57370" },
  { label: "VIRMING (57340)", value: "57340" },
  { label: "VITRY SUR ORNE (57185)", value: "57185" },
  { label: "VITTERSBOURG (57670)", value: "57670" },
  { label: "VITTONCOURT (57580)", value: "57580" },
  { label: "VIVIERS (57590)", value: "57590" },
  { label: "VOIMHAUT (57580)", value: "57580" },
  { label: "VOLMERANGE LES BOULAY (57220)", value: "57220" },
  { label: "VOLMERANGE LES MINES (57330)", value: "57330" },
  { label: "VOLMUNSTER (57720)", value: "57720" },
  { label: "VOLSTROFF (57940)", value: "57940" },
  { label: "VOYER (57560)", value: "57560" },
  { label: "VRY (57640)", value: "57640" },
  { label: "VULMONT (57420)", value: "57420" },
  { label: "WALDHOUSE (57720)", value: "57720" },
  { label: "WALDWEISTROFF (57320)", value: "57320" },
  { label: "WALDWISSE (57480)", value: "57480" },
  { label: "WALSCHBRONN (57720)", value: "57720" },
  { label: "WALSCHEID (57870)", value: "57870" },
  { label: "WALTEMBOURG (57370)", value: "57370" },
  { label: "WIESVILLER (57200)", value: "57200" },
  { label: "WILLERWALD (57430)", value: "57430" },
  { label: "WINTERSBOURG (57635)", value: "57635" },
  { label: "WITTRING (57905)", value: "57905" },
  { label: "VOELFLING LES BOUZONVILLE (57320)", value: "57320" },
  { label: "WOELFLING LES SARREGUEMINES (57200)", value: "57200" },
  { label: "WOIPPY (57140)", value: "57140" },
  { label: "WOUSTVILLER (57915)", value: "57915" },
  { label: "WUISSE (57170)", value: "57170" },
  { label: "XANREY (57630)", value: "57630" },
  { label: "XOCOURT (57590)", value: "57590" },
  { label: "XOUAXANGE (57830)", value: "57830" },
  { label: "YUTZ (57970)", value: "57970" },
  { label: "ZARBELING (57340)", value: "57340" },
  { label: "ZETTING (57905)", value: "57905" },
  { label: "ZILLING (57370)", value: "57370" },
  { label: "ZIMMING (57690)", value: "57690" },
  { label: "ZOMMANGE (57260)", value: "57260" },
  { label: "ZOUFFTGEN (57330)", value: "57330" },
  { label: "DIESEN (57890)", value: "57890" },
  { label: "STUCKANGE (57970)", value: "57970" },
  { label: "ACHUN (58110)", value: "58110" },
  { label: "ALLIGNY COSNE (58200)", value: "58200" },
  { label: "ALLIGNY EN MORVAN (58230)", value: "58230" },
  { label: "ALLUY (58110)", value: "58110" },
  { label: "AMAZY (58190)", value: "58190" },
  { label: "ANLEZY (58270)", value: "58270" },
  { label: "ANNAY (58450)", value: "58450" },
  { label: "ANTHIEN (58800)", value: "58800" },
  { label: "ARBOURSE (58350)", value: "58350" },
  { label: "ARLEUF (58430)", value: "58430" },
  { label: "ARMES (58500)", value: "58500" },
  { label: "ARQUIAN (58310)", value: "58310" },
  { label: "ARTHEL (58700)", value: "58700" },
  { label: "ARZEMBOUY (58700)", value: "58700" },
  { label: "ASNAN (58420)", value: "58420" },
  { label: "ASNOIS (58190)", value: "58190" },
  { label: "AUNAY EN BAZOIS (58110)", value: "58110" },
  { label: "AUTHIOU (58700)", value: "58700" },
  { label: "AVREE (58170)", value: "58170" },
  { label: "AVRIL SUR LOIRE (58300)", value: "58300" },
  { label: "AZY LE VIF (58240)", value: "58240" },
  { label: "BAZOCHES (58190)", value: "58190" },
  { label: "BAZOLLES (58110)", value: "58110" },
  { label: "BAZOLLES (58110)", value: "58110" },
  { label: "BEARD (58160)", value: "58160" },
  { label: "BEAULIEU (58420)", value: "58420" },
  { label: "BEAULIEU (58420)", value: "58420" },
  { label: "BEAULIEU (58420)", value: "58420" },
  { label: "BEAUMONT LA FERRIERE (58700)", value: "58700" },
  { label: "BEAUMONT SARDOLLES (58270)", value: "58270" },
  { label: "BEUVRON (58210)", value: "58210" },
  { label: "BICHES (58110)", value: "58110" },
  { label: "BILLY CHEVANNES (58270)", value: "58270" },
  { label: "BILLY SUR OISY (58500)", value: "58500" },
  { label: "BITRY (58310)", value: "58310" },
  { label: "BLISMES (58120)", value: "58120" },
  { label: "BONA (58330)", value: "58330" },
  { label: "BOUHY (58310)", value: "58310" },
  { label: "BRASSY (58140)", value: "58140" },
  { label: "BREUGNON (58460)", value: "58460" },
  { label: "BREVES (58530)", value: "58530" },
  { label: "BRINAY (58110)", value: "58110" },
  { label: "BRINON SUR BEUVRON (58420)", value: "58420" },
  { label: "BULCY (58400)", value: "58400" },
  { label: "BUSSY LA PESLE (58420)", value: "58420" },
  { label: "LA CELLE SUR LOIRE (58440)", value: "58440" },
  { label: "LA CELLE SUR NIEVRE (58700)", value: "58700" },
  { label: "CERCY LA TOUR (58340)", value: "58340" },
  { label: "CERVON (58800)", value: "58800" },
  { label: "CESSY LES BOIS (58220)", value: "58220" },
  { label: "CHALAUX (58140)", value: "58140" },
  { label: "CHALLEMENT (58420)", value: "58420" },
  { label: "CHALLUY (58000)", value: "58000" },
  { label: "CHALLUY (58000)", value: "58000" },
  { label: "CHAMPALLEMENT (58420)", value: "58420" },
  { label: "CHAMPLEMY (58210)", value: "58210" },
  { label: "CHAMPLIN (58700)", value: "58700" },
  { label: "CHAMPVERT (58300)", value: "58300" },
  { label: "CHAMPVOUX (58400)", value: "58400" },
  { label: "CHANTENAY ST IMBERT (58240)", value: "58240" },
  { label: "CHANTENAY ST IMBERT (58240)", value: "58240" },
  { label: "LA CHAPELLE ST ANDRE (58210)", value: "58210" },
  { label: "LA CHARITE SUR LOIRE (58400)", value: "58400" },
  { label: "CHARRIN (58300)", value: "58300" },
  { label: "CHASNAY (58350)", value: "58350" },
  { label: "CHATEAU CHINON VILLE (58120)", value: "58120" },
  { label: "CHATEAU CHINON CAMPAGNE (58120)", value: "58120" },
  { label: "CHATEAUNEUF VAL DE BARGIS (58350)", value: "58350" },
  { label: "CHATILLON EN BAZOIS (58110)", value: "58110" },
  { label: "CHATIN (58120)", value: "58120" },
  { label: "CHAULGNES (58400)", value: "58400" },
  { label: "CHAUMARD (58120)", value: "58120" },
  { label: "CHAUMOT (58800)", value: "58800" },
  { label: "CHAZEUIL (58700)", value: "58700" },
  { label: "CHEVANNES CHANGY (58420)", value: "58420" },
  { label: "CHEVENON (58160)", value: "58160" },
  { label: "CHEVROCHES (58500)", value: "58500" },
  { label: "CHIDDES (58170)", value: "58170" },
  { label: "CHITRY LES MINES (58800)", value: "58800" },
  { label: "CHOUGNY (58110)", value: "58110" },
  { label: "CIEZ (58220)", value: "58220" },
  { label: "CIZELY (58270)", value: "58270" },
  { label: "CLAMECY (58500)", value: "58500" },
  { label: "CLAMECY (58500)", value: "58500" },
  { label: "CLAMECY (58500)", value: "58500" },
  { label: "LA COLLANCELLE (58800)", value: "58800" },
  { label: "COLMERY (58350)", value: "58350" },
  { label: "CORANCY (58120)", value: "58120" },
  { label: "CORBIGNY (58800)", value: "58800" },
  { label: "CORVOL D EMBERNARD (58210)", value: "58210" },
  { label: "CORVOL L ORGUEILLEUX (58460)", value: "58460" },
  { label: "COSNE COURS SUR LOIRE (58200)", value: "58200" },
  { label: "COSNE COURS SUR LOIRE (58200)", value: "58200" },
  { label: "COSSAYE (58300)", value: "58300" },
  { label: "COULANGES LES NEVERS (58660)", value: "58660" },
  { label: "COULOUTRE (58220)", value: "58220" },
  { label: "COURCELLES (58210)", value: "58210" },
  { label: "CRUX LA VILLE (58330)", value: "58330" },
  { label: "CUNCY LES VARZY (58210)", value: "58210" },
  { label: "DAMPIERRE SOUS BOUHY (58310)", value: "58310" },
  { label: "DECIZE (58300)", value: "58300" },
  { label: "DEVAY (58300)", value: "58300" },
  { label: "DIENNES AUBIGNY (58340)", value: "58340" },
  { label: "DIROL (58190)", value: "58190" },
  { label: "DOMMARTIN (58120)", value: "58120" },
  { label: "DOMPIERRE SUR NIEVRE (58350)", value: "58350" },
  { label: "DONZY (58220)", value: "58220" },
  { label: "DORNECY (58530)", value: "58530" },
  { label: "DORNES (58390)", value: "58390" },
  { label: "DRUY PARIGNY (58160)", value: "58160" },
  { label: "DUN LES PLACES (58230)", value: "58230" },
  { label: "DUN SUR GRANDRY (58110)", value: "58110" },
  { label: "EMPURY (58140)", value: "58140" },
  { label: "ENTRAINS SUR NOHAIN (58410)", value: "58410" },
  { label: "EPIRY (58800)", value: "58800" },
  { label: "FACHIN (58430)", value: "58430" },
  { label: "LA FERMETE (58160)", value: "58160" },
  { label: "FERTREVE (58270)", value: "58270" },
  { label: "FLETY (58170)", value: "58170" },
  { label: "FLEURY SUR LOIRE (58240)", value: "58240" },
  { label: "FLEZ CUZY (58190)", value: "58190" },
  { label: "FOURCHAMBAULT (58600)", value: "58600" },
  { label: "FOURS (58250)", value: "58250" },
  { label: "FRASNAY REUGNY (58270)", value: "58270" },
  { label: "GACOGNE (58140)", value: "58140" },
  { label: "GARCHIZY (58600)", value: "58600" },
  { label: "GARCHY (58150)", value: "58150" },
  { label: "GARCHY (58150)", value: "58150" },
  { label: "GERMENAY (58800)", value: "58800" },
  { label: "GERMIGNY SUR LOIRE (58320)", value: "58320" },
  { label: "GIEN SUR CURE (58230)", value: "58230" },
  { label: "GIMOUILLE (58470)", value: "58470" },
  { label: "GIRY (58700)", value: "58700" },
  { label: "GLUX EN GLENNE (58370)", value: "58370" },
  { label: "GOULOUX (58230)", value: "58230" },
  { label: "GRENOIS (58420)", value: "58420" },
  { label: "GUERIGNY (58130)", value: "58130" },
  { label: "GUIPY (58420)", value: "58420" },
  { label: "HERY (58800)", value: "58800" },
  { label: "IMPHY (58160)", value: "58160" },
  { label: "ISENAY (58290)", value: "58290" },
  { label: "JAILLY (58330)", value: "58330" },
  { label: "LAMENAY SUR LOIRE (58300)", value: "58300" },
  { label: "LANGERON (58240)", value: "58240" },
  { label: "LANTY (58250)", value: "58250" },
  { label: "LAROCHEMILLAY (58370)", value: "58370" },
  { label: "LAVAULT DE FRETOY (58230)", value: "58230" },
  { label: "LIMANTON (58290)", value: "58290" },
  { label: "LIMON (58270)", value: "58270" },
  { label: "LIVRY (58240)", value: "58240" },
  { label: "LORMES (58140)", value: "58140" },
  { label: "LUCENAY LES AIX (58380)", value: "58380" },
  { label: "LURCY LE BOURG (58700)", value: "58700" },
  { label: "LUTHENAY UXELOUP (58240)", value: "58240" },
  { label: "LUZY (58170)", value: "58170" },
  { label: "LYS (58190)", value: "58190" },
  { label: "LA MACHINE (58260)", value: "58260" },
  { label: "MAGNY COURS (58470)", value: "58470" },
  { label: "MAGNY LORMES (58800)", value: "58800" },
  { label: "LA MAISON DIEU (58190)", value: "58190" },
  { label: "LA MARCHE (58400)", value: "58400" },
  { label: "MARCY (58210)", value: "58210" },
  { label: "MARIGNY L EGLISE (58140)", value: "58140" },
  { label: "MARS SUR ALLIER (58240)", value: "58240" },
  { label: "MARIGNY SUR YONNE (58800)", value: "58800" },
  { label: "MARZY (58180)", value: "58180" },
  { label: "MARZY (58180)", value: "58180" },
  { label: "MAUX (58290)", value: "58290" },
  { label: "MENESTREAU (58410)", value: "58410" },
  { label: "MENOU (58210)", value: "58210" },
  { label: "MESVES SUR LOIRE (58400)", value: "58400" },
  { label: "METZ LE COMTE (58190)", value: "58190" },
  { label: "MHERE (58140)", value: "58140" },
  { label: "MILLAY (58170)", value: "58170" },
  { label: "MOISSY MOULINOT (58190)", value: "58190" },
  { label: "MONCEAUX LE COMTE (58190)", value: "58190" },
  { label: "MONTAPAS (58110)", value: "58110" },
  { label: "MONTAMBERT (58250)", value: "58250" },
  { label: "MONTARON (58250)", value: "58250" },
  { label: "MONTENOISON (58700)", value: "58700" },
  { label: "MONT ET MARRE (58110)", value: "58110" },
  { label: "MONTIGNY AUX AMOGNES (58130)", value: "58130" },
  { label: "MONTIGNY EN MORVAN (58120)", value: "58120" },
  { label: "MONTIGNY SUR CANNE (58340)", value: "58340" },
  { label: "MONTREUILLON (58800)", value: "58800" },
  { label: "MONTSAUCHE LES SETTONS (58230)", value: "58230" },
  { label: "MONTSAUCHE LES SETTONS (58230)", value: "58230" },
  { label: "MORACHES (58420)", value: "58420" },
  { label: "MOULINS ENGILBERT (58290)", value: "58290" },
  { label: "MOURON SUR YONNE (58800)", value: "58800" },
  { label: "MOUSSY (58700)", value: "58700" },
  { label: "MOUX EN MORVAN (58230)", value: "58230" },
  { label: "MURLIN (58700)", value: "58700" },
  { label: "MYENNES (58440)", value: "58440" },
  { label: "NANNAY (58350)", value: "58350" },
  { label: "NARCY (58400)", value: "58400" },
  { label: "NEUFFONTAINES (58190)", value: "58190" },
  { label: "NEUILLY (58420)", value: "58420" },
  { label: "NEUVILLE LES DECIZE (58300)", value: "58300" },
  { label: "NEUVY SUR LOIRE (58450)", value: "58450" },
  { label: "NEVERS (58000)", value: "58000" },
  { label: "LA NOCLE MAULAIX (58250)", value: "58250" },
  { label: "NOLAY (58700)", value: "58700" },
  { label: "NUARS (58190)", value: "58190" },
  { label: "OISY (58500)", value: "58500" },
  { label: "ONLAY (58370)", value: "58370" },
  { label: "OUAGNE (58500)", value: "58500" },
  { label: "OUDAN (58210)", value: "58210" },
  { label: "OUGNY (58110)", value: "58110" },
  { label: "OULON (58700)", value: "58700" },
  { label: "VAUX D AMOGNES (58130)", value: "58130" },
  { label: "VAUX D AMOGNES (58130)", value: "58130" },
  { label: "OUROUX EN MORVAN (58230)", value: "58230" },
  { label: "PARIGNY LA ROSE (58210)", value: "58210" },
  { label: "PARIGNY LES VAUX (58320)", value: "58320" },
  { label: "PAZY (58800)", value: "58800" },
  { label: "PERROY (58220)", value: "58220" },
  { label: "PLANCHEZ (58230)", value: "58230" },
  { label: "POIL (58170)", value: "58170" },
  { label: "POISEUX (58130)", value: "58130" },
  { label: "POUGNY (58200)", value: "58200" },
  { label: "POUGUES LES EAUX (58320)", value: "58320" },
  { label: "POUILLY SUR LOIRE (58150)", value: "58150" },
  { label: "POUQUES LORMES (58140)", value: "58140" },
  { label: "POUSSEAUX (58500)", value: "58500" },
  { label: "PREMERY (58700)", value: "58700" },
  { label: "PREPORCHE (58360)", value: "58360" },
  { label: "RAVEAU (58400)", value: "58400" },
  { label: "REMILLY (58250)", value: "58250" },
  { label: "RIX (58500)", value: "58500" },
  { label: "ROUY (58110)", value: "58110" },
  { label: "RUAGES (58190)", value: "58190" },
  { label: "SAINCAIZE MEAUCE (58470)", value: "58470" },
  { label: "ST AGNAN (58230)", value: "58230" },
  { label: "ST AMAND EN PUISAYE (58310)", value: "58310" },
  { label: "ST ANDELAIN (58150)", value: "58150" },
  { label: "ST ANDRE EN MORVAN (58140)", value: "58140" },
  { label: "ST AUBIN DES CHAUMES (58190)", value: "58190" },
  { label: "ST AUBIN LES FORGES (58130)", value: "58130" },
  { label: "ST BENIN D AZY (58270)", value: "58270" },
  { label: "ST BENIN DES BOIS (58330)", value: "58330" },
  { label: "ST BONNOT (58700)", value: "58700" },
  { label: "ST BRISSON (58230)", value: "58230" },
  { label: "STE COLOMBE DES BOIS (58220)", value: "58220" },
  { label: "ST DIDIER (58190)", value: "58190" },
  { label: "ST ELOI (58000)", value: "58000" },
  { label: "ST FIRMIN (58270)", value: "58270" },
  { label: "ST FRANCHY (58330)", value: "58330" },
  { label: "ST GERMAIN CHASSENAY (58300)", value: "58300" },
  { label: "ST GERMAIN DES BOIS (58210)", value: "58210" },
  { label: "ST GRATIEN SAVIGNY (58340)", value: "58340" },
  { label: "ST HILAIRE EN MORVAN (58120)", value: "58120" },
  { label: "ST HILAIRE FONTAINE (58300)", value: "58300" },
  { label: "ST HONORE LES BAINS (58360)", value: "58360" },
  { label: "ST JEAN AUX AMOGNES (58270)", value: "58270" },
  { label: "ST LAURENT L ABBAYE (58150)", value: "58150" },
  { label: "ST LEGER DE FOUGERET (58120)", value: "58120" },
  { label: "ST LEGER DES VIGNES (58300)", value: "58300" },
  { label: "ST LOUP DES BOIS (58200)", value: "58200" },
  { label: "ST MALO EN DONZIOIS (58350)", value: "58350" },
  { label: "STE MARIE (58330)", value: "58330" },
  { label: "ST MARTIN D HEUILLE (58130)", value: "58130" },
  { label: "ST MARTIN DU PUY (58140)", value: "58140" },
  { label: "ST MARTIN SUR NOHAIN (58150)", value: "58150" },
  { label: "ST MAURICE (58330)", value: "58330" },
  { label: "ST OUEN SUR LOIRE (58160)", value: "58160" },
  { label: "ST PARIZE EN VIRY (58300)", value: "58300" },
  { label: "ST PARIZE LE CHATEL (58490)", value: "58490" },
  { label: "ST PARIZE LE CHATEL (58490)", value: "58490" },
  { label: "ST PERE (58200)", value: "58200" },
  { label: "ST PEREUSE (58110)", value: "58110" },
  { label: "ST PIERRE DU MONT (58210)", value: "58210" },
  { label: "ST PIERRE LE MOUTIER (58240)", value: "58240" },
  { label: "ST QUENTIN SUR NOHAIN (58150)", value: "58150" },
  { label: "ST REVERIEN (58420)", value: "58420" },
  { label: "ST SAULGE (58330)", value: "58330" },
  { label: "ST SEINE (58250)", value: "58250" },
  { label: "ST SULPICE (58270)", value: "58270" },
  { label: "ST VERAIN (58310)", value: "58310" },
  { label: "SAIZY (58190)", value: "58190" },
  { label: "SARDY LES EPIRY (58800)", value: "58800" },
  { label: "SAUVIGNY LES BOIS (58160)", value: "58160" },
  { label: "SAUVIGNY LES BOIS (58160)", value: "58160" },
  { label: "SAVIGNY POIL FOL (58170)", value: "58170" },
  { label: "SAXI BOURDON (58330)", value: "58330" },
  { label: "SEMELAY (58360)", value: "58360" },
  { label: "SERMAGES (58290)", value: "58290" },
  { label: "SERMOISE SUR LOIRE (58000)", value: "58000" },
  { label: "SICHAMPS (58700)", value: "58700" },
  { label: "SOUGY SUR LOIRE (58300)", value: "58300" },
  { label: "SUILLY LA TOUR (58150)", value: "58150" },
  { label: "SURGY (58500)", value: "58500" },
  { label: "TACONNAY (58420)", value: "58420" },
  { label: "TALON (58190)", value: "58190" },
  { label: "TAMNAY EN BAZOIS (58110)", value: "58110" },
  { label: "TANNAY (58190)", value: "58190" },
  { label: "TAZILLY (58170)", value: "58170" },
  { label: "TEIGNY (58190)", value: "58190" },
  { label: "TERNANT (58250)", value: "58250" },
  { label: "THAIX (58250)", value: "58250" },
  { label: "THIANGES (58260)", value: "58260" },
  { label: "TINTURY (58110)", value: "58110" },
  { label: "TOURY LURCY (58300)", value: "58300" },
  { label: "TOURY SUR JOUR (58240)", value: "58240" },
  { label: "TRACY SUR LOIRE (58150)", value: "58150" },
  { label: "TRACY SUR LOIRE (58150)", value: "58150" },
  { label: "TRESNAY (58240)", value: "58240" },
  { label: "TROIS VEVRES (58260)", value: "58260" },
  { label: "TRONSANGES (58400)", value: "58400" },
  { label: "TRUCY L ORGUEILLEUX (58460)", value: "58460" },
  { label: "URZY (58130)", value: "58130" },
  { label: "VANDENESSE (58290)", value: "58290" },
  { label: "VARENNES LES NARCY (58400)", value: "58400" },
  { label: "VARENNES VAUZELLES (58640)", value: "58640" },
  { label: "VARZY (58210)", value: "58210" },
  { label: "VAUCLAIX (58140)", value: "58140" },
  { label: "VERNEUIL (58300)", value: "58300" },
  { label: "VIELMANAY (58150)", value: "58150" },
  { label: "VIGNOL (58190)", value: "58190" },
  { label: "VILLAPOURCON (58370)", value: "58370" },
  { label: "VILLIERS LE SEC (58210)", value: "58210" },
  { label: "VILLE LANGY (58270)", value: "58270" },
  { label: "VILLIERS SUR YONNE (58500)", value: "58500" },
  { label: "VITRY LACHE (58420)", value: "58420" },
  { label: "ABANCOURT (59268)", value: "59268" },
  { label: "ABSCON (59215)", value: "59215" },
  { label: "AIBES (59149)", value: "59149" },
  { label: "AIX EN PEVELE (59310)", value: "59310" },
  { label: "ALLENNES LES MARAIS (59251)", value: "59251" },
  { label: "AMFROIPRET (59144)", value: "59144" },
  { label: "ANHIERS (59194)", value: "59194" },
  { label: "ANICHE (59580)", value: "59580" },
  { label: "VILLENEUVE D ASCQ (59491)", value: "59491" },
  { label: "VILLENEUVE D ASCQ (59493)", value: "59493" },
  { label: "VILLENEUVE D ASCQ (59650)", value: "59650" },
  { label: "ANNEUX (59400)", value: "59400" },
  { label: "ANNOEULLIN (59112)", value: "59112" },
  { label: "ANOR (59186)", value: "59186" },
  { label: "ANSTAING (59152)", value: "59152" },
  { label: "ANZIN (59410)", value: "59410" },
  { label: "ARLEUX (59151)", value: "59151" },
  { label: "ARMBOUTS CAPPEL (59380)", value: "59380" },
  { label: "ARMENTIERES (59280)", value: "59280" },
  { label: "ARMENTIERES (59280)", value: "59280" },
  { label: "ARNEKE (59285)", value: "59285" },
  { label: "ARTRES (59269)", value: "59269" },
  { label: "ASSEVENT (59600)", value: "59600" },
  { label: "ATTICHES (59551)", value: "59551" },
  { label: "AUBENCHEUL AU BAC (59265)", value: "59265" },
  { label: "AUBERCHICOURT (59165)", value: "59165" },
  { label: "AUBERS (59249)", value: "59249" },
  { label: "AUBIGNY AU BAC (59265)", value: "59265" },
  { label: "AUBRY DU HAINAUT (59494)", value: "59494" },
  { label: "AUBY (59950)", value: "59950" },
  { label: "AUCHY LEZ ORCHIES (59310)", value: "59310" },
  { label: "AUDIGNIES (59570)", value: "59570" },
  { label: "AULNOY LEZ VALENCIENNES (59300)", value: "59300" },
  { label: "AULNOYE AYMERIES (59620)", value: "59620" },
  { label: "AVELIN (59710)", value: "59710" },
  { label: "AVESNELLES (59440)", value: "59440" },
  { label: "AVESNES SUR HELPE (59440)", value: "59440" },
  { label: "AVESNES LES AUBERT (59129)", value: "59129" },
  { label: "AVESNES LE SEC (59296)", value: "59296" },
  { label: "AWOINGT (59400)", value: "59400" },
  { label: "BACHANT (59138)", value: "59138" },
  { label: "BACHY (59830)", value: "59830" },
  { label: "BAILLEUL (59270)", value: "59270" },
  { label: "BAILLEUL (59270)", value: "59270" },
  { label: "BAISIEUX (59780)", value: "59780" },
  { label: "BAIVES (59132)", value: "59132" },
  { label: "BAMBECQUE (59470)", value: "59470" },
  { label: "BANTEUX (59266)", value: "59266" },
  { label: "BANTIGNY (59554)", value: "59554" },
  { label: "BANTOUZELLE (59266)", value: "59266" },
  { label: "BAS LIEU (59440)", value: "59440" },
  { label: "LA BASSEE (59480)", value: "59480" },
  { label: "BAUVIN (59221)", value: "59221" },
  { label: "BAVAY (59570)", value: "59570" },
  { label: "BAVAY (59570)", value: "59570" },
  { label: "BAVINCHOVE (59670)", value: "59670" },
  { label: "BAZUEL (59360)", value: "59360" },
  { label: "BEAUCAMPS LIGNY (59134)", value: "59134" },
  { label: "BEAUDIGNIES (59530)", value: "59530" },
  { label: "BEAUFORT (59330)", value: "59330" },
  { label: "BEAUMONT EN CAMBRESIS (59540)", value: "59540" },
  { label: "BEAURAIN (59730)", value: "59730" },
  { label: "BEAUREPAIRE SUR SAMBRE (59550)", value: "59550" },
  { label: "BEAURIEUX (59740)", value: "59740" },
  { label: "BEAUVOIS EN CAMBRESIS (59157)", value: "59157" },
  { label: "BELLAING (59135)", value: "59135" },
  { label: "BELLIGNIES (59570)", value: "59570" },
  { label: "BERELLES (59740)", value: "59740" },
  { label: "BERGUES (59380)", value: "59380" },
  { label: "BERLAIMONT (59145)", value: "59145" },
  { label: "BERMERAIN (59213)", value: "59213" },
  { label: "BERMERIES (59570)", value: "59570" },
  { label: "BERSEE (59235)", value: "59235" },
  { label: "BERSILLIES (59600)", value: "59600" },
  { label: "BERTHEN (59270)", value: "59270" },
  { label: "BERTHEN (59270)", value: "59270" },
  { label: "BERTRY (59980)", value: "59980" },
  { label: "BETHENCOURT (59540)", value: "59540" },
  { label: "BETTIGNIES (59600)", value: "59600" },
  { label: "BETTRECHIES (59570)", value: "59570" },
  { label: "BEUGNIES (59216)", value: "59216" },
  { label: "BEUVRAGES (59192)", value: "59192" },
  { label: "BEUVRY LA FORET (59310)", value: "59310" },
  { label: "BEVILLERS (59217)", value: "59217" },
  { label: "BIERNE (59380)", value: "59380" },
  { label: "BISSEZEELE (59380)", value: "59380" },
  { label: "BLARINGHEM (59173)", value: "59173" },
  { label: "BLECOURT (59268)", value: "59268" },
  { label: "BOESCHEPE (59299)", value: "59299" },
  { label: "BOESEGHEM (59189)", value: "59189" },
  { label: "BOIS GRENIER (59280)", value: "59280" },
  { label: "BOLLEZEELE (59470)", value: "59470" },
  { label: "BONDUES (59910)", value: "59910" },
  { label: "BORRE (59190)", value: "59190" },
  { label: "BOUCHAIN (59111)", value: "59111" },
  { label: "BOUCHAIN (59111)", value: "59111" },
  { label: "BOULOGNE SUR HELPE (59440)", value: "59440" },
  { label: "BOURBOURG (59630)", value: "59630" },
  { label: "BOURGHELLES (59830)", value: "59830" },
  { label: "BOURSIES (59400)", value: "59400" },
  { label: "BOUSBECQUE (59166)", value: "59166" },
  { label: "BOUSIES (59222)", value: "59222" },
  { label: "BOUSIGNIES (59178)", value: "59178" },
  { label: "BOUSIGNIES SUR ROC (59149)", value: "59149" },
  { label: "BOUSSIERES EN CAMBRESIS (59217)", value: "59217" },
  { label: "BOUSSIERES SUR SAMBRE (59330)", value: "59330" },
  { label: "BOUSSOIS (59168)", value: "59168" },
  { label: "BOUVIGNIES (59870)", value: "59870" },
  { label: "BOUVINES (59830)", value: "59830" },
  { label: "BRAY DUNES (59123)", value: "59123" },
  { label: "BRIASTRE (59730)", value: "59730" },
  { label: "BRILLON (59178)", value: "59178" },
  { label: "BROUCKERQUE (59630)", value: "59630" },
  { label: "BROXEELE (59470)", value: "59470" },
  { label: "BRUAY SUR L ESCAUT (59860)", value: "59860" },
  { label: "BRUILLE LEZ MARCHIENNES (59490)", value: "59490" },
  { label: "BRUILLE ST AMAND (59199)", value: "59199" },
  { label: "BRUNEMONT (59151)", value: "59151" },
  { label: "BRY (59144)", value: "59144" },
  { label: "BUGNICOURT (59151)", value: "59151" },
  { label: "BUSIGNY (59137)", value: "59137" },
  { label: "BUYSSCHEURE (59285)", value: "59285" },
  { label: "CAESTRE (59190)", value: "59190" },
  { label: "CAGNONCLES (59161)", value: "59161" },
  { label: "CAMBRAI (59400)", value: "59400" },
  { label: "CAMBRAI (59400)", value: "59400" },
  { label: "CAMPHIN EN CAREMBAULT (59133)", value: "59133" },
  { label: "CAMPHIN EN PEVELE (59780)", value: "59780" },
  { label: "CANTAING SUR ESCAUT (59267)", value: "59267" },
  { label: "CANTIN (59169)", value: "59169" },
  { label: "CAPELLE (59213)", value: "59213" },
  { label: "CAPINGHEM (59160)", value: "59160" },
  { label: "CAPPELLE EN PEVELE (59242)", value: "59242" },
  { label: "CAPPELLE BROUCK (59630)", value: "59630" },
  { label: "CAPPELLE LA GRANDE (59180)", value: "59180" },
  { label: "CARNIERES (59217)", value: "59217" },
  { label: "CARNIN (59112)", value: "59112" },
  { label: "CARTIGNIES (59244)", value: "59244" },
  { label: "CASSEL (59670)", value: "59670" },
  { label: "LE CATEAU CAMBRESIS (59360)", value: "59360" },
  { label: "CATILLON SUR SAMBRE (59360)", value: "59360" },
  { label: "CATTENIERES (59217)", value: "59217" },
  { label: "CAUDRY (59540)", value: "59540" },
  { label: "CAUDRY (59540)", value: "59540" },
  { label: "CAULLERY (59191)", value: "59191" },
  { label: "CAUROIR (59400)", value: "59400" },
  { label: "CERFONTAINE (59680)", value: "59680" },
  { label: "LA CHAPELLE D ARMENTIERES (59930)", value: "59930" },
  { label: "CHATEAU L ABBAYE (59230)", value: "59230" },
  { label: "CHEMY (59147)", value: "59147" },
  { label: "CHERENG (59152)", value: "59152" },
  { label: "CHOISIES (59740)", value: "59740" },
  { label: "CLAIRFAYTS (59740)", value: "59740" },
  { label: "CLARY (59225)", value: "59225" },
  { label: "COBRIEUX (59830)", value: "59830" },
  { label: "COLLERET (59680)", value: "59680" },
  { label: "COMINES (59560)", value: "59560" },
  { label: "CONDE SUR L ESCAUT (59163)", value: "59163" },
  { label: "COUDEKERQUE BRANCHE (59210)", value: "59210" },
  { label: "COURCHELETTES (59552)", value: "59552" },
  { label: "COUSOLRE (59149)", value: "59149" },
  { label: "COUTICHES (59310)", value: "59310" },
  { label: "CRAYWICK (59279)", value: "59279" },
  { label: "CRESPIN (59154)", value: "59154" },
  { label: "CRESPIN (59154)", value: "59154" },
  { label: "CREVECOEUR SUR L ESCAUT (59258)", value: "59258" },
  { label: "CROCHTE (59380)", value: "59380" },
  { label: "CROIX (59170)", value: "59170" },
  { label: "CROIX CALUYAU (59222)", value: "59222" },
  { label: "CUINCY (59553)", value: "59553" },
  { label: "CURGIES (59990)", value: "59990" },
  { label: "CUVILLERS (59268)", value: "59268" },
  { label: "CYSOING (59830)", value: "59830" },
  { label: "DAMOUSIES (59680)", value: "59680" },
  { label: "DECHY (59187)", value: "59187" },
  { label: "DEHERIES (59127)", value: "59127" },
  { label: "DENAIN (59220)", value: "59220" },
  { label: "DEULEMONT (59890)", value: "59890" },
  { label: "DIMECHAUX (59740)", value: "59740" },
  { label: "DIMONT (59216)", value: "59216" },
  { label: "DOIGNIES (59400)", value: "59400" },
  { label: "DOMPIERRE SUR HELPE (59440)", value: "59440" },
  { label: "DOUAI (59500)", value: "59500" },
  { label: "DOUAI (59500)", value: "59500" },
  { label: "DOUAI (59500)", value: "59500" },
  { label: "DOUCHY LES MINES (59282)", value: "59282" },
  { label: "LE DOULIEU (59940)", value: "59940" },
  { label: "DOURLERS (59440)", value: "59440" },
  { label: "DRINCHAM (59630)", value: "59630" },
  { label: "DUNKERQUE (59140)", value: "59140" },
  { label: "DUNKERQUE (59240)", value: "59240" },
  { label: "DUNKERQUE (59240)", value: "59240" },
  { label: "DUNKERQUE (59240)", value: "59240" },
  { label: "DUNKERQUE (59279)", value: "59279" },
  { label: "DUNKERQUE (59430)", value: "59430" },
  { label: "DUNKERQUE (59430)", value: "59430" },
  { label: "DUNKERQUE (59640)", value: "59640" },
  { label: "DUNKERQUE (59640)", value: "59640" },
  { label: "EBBLINGHEM (59173)", value: "59173" },
  { label: "ECAILLON (59176)", value: "59176" },
  { label: "ECCLES (59740)", value: "59740" },
  { label: "ECLAIBES (59330)", value: "59330" },
  { label: "ECUELIN (59620)", value: "59620" },
  { label: "EECKE (59114)", value: "59114" },
  { label: "ELESMES (59600)", value: "59600" },
  { label: "ELINCOURT (59127)", value: "59127" },
  { label: "EMERCHICOURT (59580)", value: "59580" },
  { label: "EMMERIN (59320)", value: "59320" },
  { label: "ENGLEFONTAINE (59530)", value: "59530" },
  { label: "ENGLOS (59320)", value: "59320" },
  { label: "ENNETIERES EN WEPPES (59320)", value: "59320" },
  { label: "ENNEVELIN (59710)", value: "59710" },
  { label: "EPPE SAUVAGE (59132)", value: "59132" },
  { label: "ERCHIN (59169)", value: "59169" },
  { label: "ERINGHEM (59470)", value: "59470" },
  { label: "ERQUINGHEM LE SEC (59320)", value: "59320" },
  { label: "ERQUINGHEM LYS (59193)", value: "59193" },
  { label: "ERRE (59171)", value: "59171" },
  { label: "ESCARMAIN (59213)", value: "59213" },
  { label: "ESCAUDAIN (59124)", value: "59124" },
  { label: "ESCAUDOEUVRES (59161)", value: "59161" },
  { label: "ESCAUTPONT (59278)", value: "59278" },
  { label: "ESCOBECQUES (59320)", value: "59320" },
  { label: "ESNES (59127)", value: "59127" },
  { label: "ESQUELBECQ (59470)", value: "59470" },
  { label: "ESQUERCHIN (59553)", value: "59553" },
  { label: "ESTAIRES (59940)", value: "59940" },
  { label: "ESTOURMEL (59400)", value: "59400" },
  { label: "ESTREES (59151)", value: "59151" },
  { label: "ESTREUX (59990)", value: "59990" },
  { label: "ESWARS (59161)", value: "59161" },
  { label: "ETH (59144)", value: "59144" },
  { label: "ETROEUNGT (59219)", value: "59219" },
  { label: "ESTRUN (59295)", value: "59295" },
  { label: "FACHES THUMESNIL (59155)", value: "59155" },
  { label: "FAMARS (59300)", value: "59300" },
  { label: "FAUMONT (59310)", value: "59310" },
  { label: "LE FAVRIL (59550)", value: "59550" },
  { label: "FECHAIN (59247)", value: "59247" },
  { label: "FEIGNIES (59750)", value: "59750" },
  { label: "FEIGNIES (59750)", value: "59750" },
  { label: "FELLERIES (59740)", value: "59740" },
  { label: "FENAIN (59179)", value: "59179" },
  { label: "FERIN (59169)", value: "59169" },
  { label: "FERON (59610)", value: "59610" },
  { label: "FERRIERE LA GRANDE (59680)", value: "59680" },
  { label: "FERRIERE LA PETITE (59680)", value: "59680" },
  { label: "LA FLAMENGRIE (59570)", value: "59570" },
  { label: "FLAUMONT WAUDRECHIES (59440)", value: "59440" },
  { label: "FLERS EN ESCREBIEUX (59128)", value: "59128" },
  { label: "FLESQUIERES (59267)", value: "59267" },
  { label: "FLETRE (59270)", value: "59270" },
  { label: "FLINES LES MORTAGNE (59158)", value: "59158" },
  { label: "FLINES LEZ RACHES (59148)", value: "59148" },
  { label: "FLOURSIES (59440)", value: "59440" },
  { label: "FLOYON (59219)", value: "59219" },
  { label: "FONTAINE AU BOIS (59550)", value: "59550" },
  { label: "FONTAINE AU PIRE (59157)", value: "59157" },
  { label: "FONTAINE NOTRE DAME (59400)", value: "59400" },
  { label: "FOREST EN CAMBRESIS (59222)", value: "59222" },
  { label: "FOREST SUR MARQUE (59510)", value: "59510" },
  { label: "FOURMIES (59610)", value: "59610" },
  { label: "FOURNES EN WEPPES (59134)", value: "59134" },
  { label: "FRASNOY (59530)", value: "59530" },
  { label: "FRELINGHIEN (59236)", value: "59236" },
  { label: "FRESNES SUR ESCAUT (59970)", value: "59970" },
  { label: "FRESSAIN (59234)", value: "59234" },
  { label: "FRESSIES (59268)", value: "59268" },
  { label: "FRETIN (59273)", value: "59273" },
  { label: "FROMELLES (59249)", value: "59249" },
  { label: "GENECH (59242)", value: "59242" },
  { label: "GHISSIGNIES (59530)", value: "59530" },
  { label: "GHYVELDE (59122)", value: "59122" },
  { label: "GHYVELDE (59254)", value: "59254" },
  { label: "GLAGEON (59132)", value: "59132" },
  { label: "GODEWAERSVELDE (59270)", value: "59270" },
  { label: "GOEULZIN (59169)", value: "59169" },
  { label: "GOGNIES CHAUSSEE (59600)", value: "59600" },
  { label: "GOMMEGNIES (59144)", value: "59144" },
  { label: "GONDECOURT (59147)", value: "59147" },
  { label: "GONNELIEU (59231)", value: "59231" },
  { label: "LA GORGUE (59253)", value: "59253" },
  { label: "GOUZEAUCOURT (59231)", value: "59231" },
  { label: "GRAND FAYT (59244)", value: "59244" },
  { label: "GRANDE SYNTHE (59760)", value: "59760" },
  { label: "GRAND FORT PHILIPPE (59153)", value: "59153" },
  { label: "GRAVELINES (59820)", value: "59820" },
  { label: "LA GROISE (59360)", value: "59360" },
  { label: "GRUSON (59152)", value: "59152" },
  { label: "GUESNAIN (59287)", value: "59287" },
  { label: "GUSSIGNIES (59570)", value: "59570" },
  { label: "HALLENNES LEZ HAUBOURDIN (59320)", value: "59320" },
  { label: "HALLUIN (59250)", value: "59250" },
  { label: "HAMEL (59151)", value: "59151" },
  { label: "HANTAY (59496)", value: "59496" },
  { label: "HARDIFORT (59670)", value: "59670" },
  { label: "HARGNIES (59138)", value: "59138" },
  { label: "HASNON (59178)", value: "59178" },
  { label: "HASPRES (59198)", value: "59198" },
  { label: "HAUBOURDIN (59320)", value: "59320" },
  { label: "HAUCOURT EN CAMBRESIS (59191)", value: "59191" },
  { label: "HAULCHIN (59121)", value: "59121" },
  { label: "HAUSSY (59294)", value: "59294" },
  { label: "HAUT LIEU (59440)", value: "59440" },
  { label: "HAUTMONT (59330)", value: "59330" },
  { label: "HAVELUY (59255)", value: "59255" },
  { label: "HAVERSKERQUE (59660)", value: "59660" },
  { label: "HAYNECOURT (59268)", value: "59268" },
  { label: "HAZEBROUCK (59190)", value: "59190" },
  { label: "HECQ (59530)", value: "59530" },
  { label: "HELESMES (59171)", value: "59171" },
  { label: "HEM (59510)", value: "59510" },
  { label: "HEM LENGLET (59247)", value: "59247" },
  { label: "HERGNIES (59199)", value: "59199" },
  { label: "HERIN (59195)", value: "59195" },
  { label: "HERLIES (59134)", value: "59134" },
  { label: "HERRIN (59147)", value: "59147" },
  { label: "HERZEELE (59470)", value: "59470" },
  { label: "HESTRUD (59740)", value: "59740" },
  { label: "HOLQUE (59143)", value: "59143" },
  { label: "HONDEGHEM (59190)", value: "59190" },
  { label: "HONDSCHOOTE (59122)", value: "59122" },
  { label: "HON HERGIES (59570)", value: "59570" },
  { label: "HONNECHY (59980)", value: "59980" },
  { label: "HONNECOURT SUR ESCAUT (59266)", value: "59266" },
  { label: "HORDAIN (59111)", value: "59111" },
  { label: "HORNAING (59171)", value: "59171" },
  { label: "HOUDAIN LEZ BAVAY (59570)", value: "59570" },
  { label: "HOUPLIN ANCOISNE (59263)", value: "59263" },
  { label: "HOUPLINES (59116)", value: "59116" },
  { label: "HOUTKERQUE (59470)", value: "59470" },
  { label: "HOYMILLE (59492)", value: "59492" },
  { label: "ILLIES (59480)", value: "59480" },
  { label: "INCHY (59540)", value: "59540" },
  { label: "IWUY (59141)", value: "59141" },
  { label: "JENLAIN (59144)", value: "59144" },
  { label: "JEUMONT (59460)", value: "59460" },
  { label: "JOLIMETZ (59530)", value: "59530" },
  { label: "KILLEM (59122)", value: "59122" },
  { label: "LALLAING (59167)", value: "59167" },
  { label: "LAMBERSART (59130)", value: "59130" },
  { label: "LAMBRES LEZ DOUAI (59552)", value: "59552" },
  { label: "LANDAS (59310)", value: "59310" },
  { label: "LANDRECIES (59550)", value: "59550" },
  { label: "LANNOY (59390)", value: "59390" },
  { label: "LAROUILLIES (59219)", value: "59219" },
  { label: "LAUWIN PLANQUE (59553)", value: "59553" },
  { label: "LECELLES (59226)", value: "59226" },
  { label: "LECLUSE (59259)", value: "59259" },
  { label: "LEDERZEELE (59143)", value: "59143" },
  { label: "LEDRINGHEM (59470)", value: "59470" },
  { label: "LEERS (59115)", value: "59115" },
  { label: "LEFFRINCKOUCKE (59495)", value: "59495" },
  { label: "LESDAIN (59258)", value: "59258" },
  { label: "LEZ FONTAINE (59740)", value: "59740" },
  { label: "LESQUIN (59810)", value: "59810" },
  { label: "LEVAL (59620)", value: "59620" },
  { label: "LEWARDE (59287)", value: "59287" },
  { label: "LEZENNES (59260)", value: "59260" },
  { label: "LIESSIES (59740)", value: "59740" },
  { label: "LIEU ST AMAND (59111)", value: "59111" },
  { label: "LIGNY EN CAMBRESIS (59191)", value: "59191" },
  { label: "LILLE (59000)", value: "59000" },
  { label: "LILLE (59160)", value: "59160" },
  { label: "LILLE (59260)", value: "59260" },
  { label: "LILLE (59777)", value: "59777" },
  { label: "LILLE (59800)", value: "59800" },
  { label: "LIMONT FONTAINE (59330)", value: "59330" },
  { label: "LINSELLES (59126)", value: "59126" },
  { label: "LOCQUIGNOL (59530)", value: "59530" },
  { label: "LOFFRE (59182)", value: "59182" },
  { label: "LOMPRET (59840)", value: "59840" },
  { label: "LA LONGUEVILLE (59570)", value: "59570" },
  { label: "LOOBERGHE (59630)", value: "59630" },
  { label: "LOON PLAGE (59279)", value: "59279" },
  { label: "LOOS (59120)", value: "59120" },
  { label: "LOURCHES (59156)", value: "59156" },
  { label: "LOUVIGNIES QUESNOY (59530)", value: "59530" },
  { label: "LOUVIL (59830)", value: "59830" },
  { label: "LOUVROIL (59720)", value: "59720" },
  { label: "LYNDE (59173)", value: "59173" },
  { label: "LYS LEZ LANNOY (59390)", value: "59390" },
  { label: "LA MADELEINE (59110)", value: "59110" },
  { label: "MAING (59233)", value: "59233" },
  { label: "MAIRIEUX (59600)", value: "59600" },
  { label: "LE MAISNIL (59134)", value: "59134" },
  { label: "MALINCOURT (59127)", value: "59127" },
  { label: "MARBAIX (59440)", value: "59440" },
  { label: "MARCHIENNES (59870)", value: "59870" },
  { label: "MARCOING (59159)", value: "59159" },
  { label: "MARCQ EN BAROEUL (59700)", value: "59700" },
  { label: "MARCQ EN OSTREVENT (59252)", value: "59252" },
  { label: "MARESCHES (59990)", value: "59990" },
  { label: "MARETZ (59238)", value: "59238" },
  { label: "MARLY (59770)", value: "59770" },
  { label: "MAROILLES (59550)", value: "59550" },
  { label: "MARPENT (59164)", value: "59164" },
  { label: "MARQUETTE LEZ LILLE (59520)", value: "59520" },
  { label: "MARQUETTE EN OSTREVANT (59252)", value: "59252" },
  { label: "MARQUILLIES (59274)", value: "59274" },
  { label: "MASNIERES (59241)", value: "59241" },
  { label: "MASNY (59176)", value: "59176" },
  { label: "MASTAING (59172)", value: "59172" },
  { label: "MAUBEUGE (59600)", value: "59600" },
  { label: "MAUBEUGE (59600)", value: "59600" },
  { label: "MAULDE (59158)", value: "59158" },
  { label: "MAUROIS (59980)", value: "59980" },
  { label: "MAZINGHIEN (59360)", value: "59360" },
  { label: "MECQUIGNIES (59570)", value: "59570" },
  { label: "MERCKEGHEM (59470)", value: "59470" },
  { label: "MERIGNIES (59710)", value: "59710" },
  { label: "MERRIS (59270)", value: "59270" },
  { label: "MERVILLE (59660)", value: "59660" },
  { label: "METEREN (59270)", value: "59270" },
  { label: "MILLAM (59143)", value: "59143" },
  { label: "MILLONFOSSE (59178)", value: "59178" },
  { label: "MOEUVRES (59400)", value: "59400" },
  { label: "MONCEAU ST WAAST (59620)", value: "59620" },
  { label: "MONCHAUX SUR ECAILLON (59224)", value: "59224" },
  { label: "MONCHEAUX (59283)", value: "59283" },
  { label: "MONCHECOURT (59234)", value: "59234" },
  { label: "MONS EN BAROEUL (59370)", value: "59370" },
  { label: "MONS EN PEVELE (59246)", value: "59246" },
  { label: "MONTAY (59360)", value: "59360" },
  { label: "MONTIGNY EN CAMBRESIS (59225)", value: "59225" },
  { label: "MONTIGNY EN OSTREVENT (59182)", value: "59182" },
  { label: "MONTRECOURT (59227)", value: "59227" },
  { label: "MORBECQUE (59190)", value: "59190" },
  { label: "MORTAGNE DU NORD (59158)", value: "59158" },
  { label: "MOUCHIN (59310)", value: "59310" },
  { label: "MOUSTIER EN FAGNE (59132)", value: "59132" },
  { label: "MOUVAUX (59420)", value: "59420" },
  { label: "NAVES (59161)", value: "59161" },
  { label: "NEUF BERQUIN (59940)", value: "59940" },
  { label: "NEUF MESNIL (59330)", value: "59330" },
  { label: "NEUVILLE EN AVESNOIS (59218)", value: "59218" },
  { label: "NEUVILLE EN FERRAIN (59960)", value: "59960" },
  { label: "LA NEUVILLE (59239)", value: "59239" },
  { label: "NEUVILLE ST REMY (59554)", value: "59554" },
  { label: "NEUVILLE SUR ESCAUT (59293)", value: "59293" },
  { label: "NEUVILLY (59360)", value: "59360" },
  { label: "NIEPPE (59850)", value: "59850" },
  { label: "NIERGNIES (59400)", value: "59400" },
  { label: "NIEURLET (59143)", value: "59143" },
  { label: "NIVELLE (59230)", value: "59230" },
  { label: "NOMAIN (59310)", value: "59310" },
  { label: "NOORDPEENE (59670)", value: "59670" },
  { label: "NOYELLES LES SECLIN (59139)", value: "59139" },
  { label: "NOYELLES SUR ESCAUT (59159)", value: "59159" },
  { label: "NOYELLES SUR SAMBRE (59550)", value: "59550" },
  { label: "NOYELLES SUR SELLE (59282)", value: "59282" },
  { label: "OBIES (59570)", value: "59570" },
  { label: "OBRECHIES (59680)", value: "59680" },
  { label: "OCHTEZEELE (59670)", value: "59670" },
  { label: "ODOMEZ (59970)", value: "59970" },
  { label: "OHAIN (59132)", value: "59132" },
  { label: "OISY (59195)", value: "59195" },
  { label: "ONNAING (59264)", value: "59264" },
  { label: "OOST CAPPEL (59122)", value: "59122" },
  { label: "ORCHIES (59310)", value: "59310" },
  { label: "ORS (59360)", value: "59360" },
  { label: "ORSINVAL (59530)", value: "59530" },
  { label: "OSTRICOURT (59162)", value: "59162" },
  { label: "OUDEZEELE (59670)", value: "59670" },
  { label: "OXELAERE (59670)", value: "59670" },
  { label: "PAILLENCOURT (59295)", value: "59295" },
  { label: "PECQUENCOURT (59146)", value: "59146" },
  { label: "PERENCHIES (59840)", value: "59840" },
  { label: "PERONNE EN MELANTOIS (59273)", value: "59273" },
  { label: "PETITE FORET (59494)", value: "59494" },
  { label: "PETIT FAYT (59244)", value: "59244" },
  { label: "PHALEMPIN (59133)", value: "59133" },
  { label: "PITGAM (59284)", value: "59284" },
  { label: "POIX DU NORD (59218)", value: "59218" },
  { label: "POMMEREUIL (59360)", value: "59360" },
  { label: "PONT A MARCQ (59710)", value: "59710" },
  { label: "PONT SUR SAMBRE (59138)", value: "59138" },
  { label: "POTELLE (59530)", value: "59530" },
  { label: "PRADELLES (59190)", value: "59190" },
  { label: "PREMESQUES (59840)", value: "59840" },
  { label: "PREMESQUES (59840)", value: "59840" },
  { label: "PRESEAU (59990)", value: "59990" },
  { label: "PREUX AU BOIS (59288)", value: "59288" },
  { label: "PREUX AU SART (59144)", value: "59144" },
  { label: "PRISCHES (59550)", value: "59550" },
  { label: "PROUVY (59121)", value: "59121" },
  { label: "PROVILLE (59267)", value: "59267" },
  { label: "PROVIN (59185)", value: "59185" },
  { label: "QUAEDYPRE (59380)", value: "59380" },
  { label: "QUAROUBLE (59243)", value: "59243" },
  { label: "QUERENAING (59269)", value: "59269" },
  { label: "LE QUESNOY (59530)", value: "59530" },
  { label: "QUESNOY SUR DEULE (59890)", value: "59890" },
  { label: "QUIEVELON (59680)", value: "59680" },
  { label: "QUIEVRECHAIN (59920)", value: "59920" },
  { label: "QUIEVRECHAIN (59920)", value: "59920" },
  { label: "QUIEVY (59214)", value: "59214" },
  { label: "RACHES (59194)", value: "59194" },
  { label: "RADINGHEM EN WEPPES (59320)", value: "59320" },
  { label: "RAILLENCOURT STE OLLE (59554)", value: "59554" },
  { label: "RAIMBEAUCOURT (59283)", value: "59283" },
  { label: "RAINSARS (59177)", value: "59177" },
  { label: "RAISMES (59590)", value: "59590" },
  { label: "RAMILLIES (59161)", value: "59161" },
  { label: "RAMOUSIES (59177)", value: "59177" },
  { label: "RAUCOURT AU BOIS (59530)", value: "59530" },
  { label: "RECQUIGNIES (59245)", value: "59245" },
  { label: "REJET DE BEAULIEU (59360)", value: "59360" },
  { label: "RENESCURE (59173)", value: "59173" },
  { label: "REUMONT (59980)", value: "59980" },
  { label: "REXPOEDE (59122)", value: "59122" },
  { label: "RIBECOURT LA TOUR (59159)", value: "59159" },
  { label: "RIEULAY (59870)", value: "59870" },
  { label: "RIEUX EN CAMBRESIS (59277)", value: "59277" },
  { label: "ROBERSART (59550)", value: "59550" },
  { label: "ROEULX (59172)", value: "59172" },
  { label: "ROMBIES ET MARCHIPONT (59990)", value: "59990" },
  { label: "ROMERIES (59730)", value: "59730" },
  { label: "RONCHIN (59790)", value: "59790" },
  { label: "RONCQ (59223)", value: "59223" },
  { label: "ROOST WARENDIN (59286)", value: "59286" },
  { label: "ROSULT (59230)", value: "59230" },
  { label: "ROUBAIX (59100)", value: "59100" },
  { label: "ROUCOURT (59169)", value: "59169" },
  { label: "ROUSIES (59131)", value: "59131" },
  { label: "ROUVIGNIES (59220)", value: "59220" },
  { label: "RUBROUCK (59285)", value: "59285" },
  { label: "LES RUES DES VIGNES (59258)", value: "59258" },
  { label: "RUESNES (59530)", value: "59530" },
  { label: "RUMEGIES (59226)", value: "59226" },
  { label: "RUMILLY EN CAMBRESIS (59281)", value: "59281" },
  { label: "SAILLY LEZ CAMBRAI (59554)", value: "59554" },
  { label: "SAILLY LEZ LANNOY (59390)", value: "59390" },
  { label: "SAINGHIN EN MELANTOIS (59262)", value: "59262" },
  { label: "SAINGHIN EN WEPPES (59184)", value: "59184" },
  { label: "SAINS DU NORD (59177)", value: "59177" },
  { label: "ST AMAND LES EAUX (59230)", value: "59230" },
  { label: "ST ANDRE LEZ LILLE (59350)", value: "59350" },
  { label: "ST AUBERT (59188)", value: "59188" },
  { label: "ST AUBIN (59440)", value: "59440" },
  { label: "ST AYBERT (59163)", value: "59163" },
  { label: "ST BENIN (59360)", value: "59360" },
  { label: "ST GEORGES SUR L AA (59820)", value: "59820" },
  { label: "ST HILAIRE LEZ CAMBRAI (59292)", value: "59292" },
  { label: "ST HILAIRE SUR HELPE (59440)", value: "59440" },
  { label: "ST JANS CAPPEL (59270)", value: "59270" },
  { label: "STE MARIE CAPPEL (59670)", value: "59670" },
  { label: "ST MARTIN SUR ECAILLON (59213)", value: "59213" },
  { label: "ST MOMELIN (59143)", value: "59143" },
  { label: "ST PIERRE BROUCK (59630)", value: "59630" },
  { label: "ST PYTHON (59730)", value: "59730" },
  { label: "ST REMY CHAUSSEE (59620)", value: "59620" },
  { label: "ST REMY DU NORD (59330)", value: "59330" },
  { label: "ST SAULVE (59880)", value: "59880" },
  { label: "ST SOUPLET (59360)", value: "59360" },
  { label: "ST SOUPLET (59360)", value: "59360" },
  { label: "ST SYLVESTRE CAPPEL (59114)", value: "59114" },
  { label: "ST VAAST EN CAMBRESIS (59188)", value: "59188" },
  { label: "ST WAAST (59570)", value: "59570" },
  { label: "SALESCHES (59218)", value: "59218" },
  { label: "SALOME (59496)", value: "59496" },
  { label: "SAMEON (59310)", value: "59310" },
  { label: "SANCOURT (59268)", value: "59268" },
  { label: "SANTES (59211)", value: "59211" },
  { label: "SARS ET ROSIERES (59230)", value: "59230" },
  { label: "SARS POTERIES (59216)", value: "59216" },
  { label: "SASSEGNIES (59145)", value: "59145" },
  { label: "SAULTAIN (59990)", value: "59990" },
  { label: "SAULZOIR (59227)", value: "59227" },
  { label: "SEBOURG (59990)", value: "59990" },
  { label: "SECLIN (59113)", value: "59113" },
  { label: "SEMERIES (59440)", value: "59440" },
  { label: "SEMOUSIES (59440)", value: "59440" },
  { label: "LA SENTINELLE (59174)", value: "59174" },
  { label: "SEPMERIES (59269)", value: "59269" },
  { label: "SEQUEDIN (59320)", value: "59320" },
  { label: "SERANVILLERS FORENVILLE (59400)", value: "59400" },
  { label: "SERCUS (59173)", value: "59173" },
  { label: "SIN LE NOBLE (59450)", value: "59450" },
  { label: "SOCX (59380)", value: "59380" },
  { label: "SOLESMES (59730)", value: "59730" },
  { label: "SOLRE LE CHATEAU (59740)", value: "59740" },
  { label: "SOLRINNES (59740)", value: "59740" },
  { label: "SOMAIN (59490)", value: "59490" },
  { label: "SOMMAING (59213)", value: "59213" },
  { label: "SPYCKER (59380)", value: "59380" },
  { label: "STAPLE (59190)", value: "59190" },
  { label: "STEENBECQUE (59189)", value: "59189" },
  { label: "STEENE (59380)", value: "59380" },
  { label: "STEENVOORDE (59114)", value: "59114" },
  { label: "STEENWERCK (59181)", value: "59181" },
  { label: "STEENWERCK (59181)", value: "59181" },
  { label: "STRAZEELE (59270)", value: "59270" },
  { label: "TAISNIERES EN THIERACHE (59550)", value: "59550" },
  { label: "TAISNIERES SUR HON (59570)", value: "59570" },
  { label: "TEMPLEMARS (59175)", value: "59175" },
  { label: "TEMPLEUVE EN PEVELE (59242)", value: "59242" },
  { label: "TERDEGHEM (59114)", value: "59114" },
  { label: "TETEGHEM COUDEKERQUE VILLAGE (59229)", value: "59229" },
  { label: "TETEGHEM COUDEKERQUE VILLAGE (59380)", value: "59380" },
  { label: "THIANT (59224)", value: "59224" },
  { label: "THIENNES (59189)", value: "59189" },
  { label: "THIVENCELLE (59163)", value: "59163" },
  { label: "THUMERIES (59239)", value: "59239" },
  { label: "THUN L EVEQUE (59141)", value: "59141" },
  { label: "THUN ST AMAND (59158)", value: "59158" },
  { label: "THUN ST MARTIN (59141)", value: "59141" },
  { label: "TILLOY LEZ MARCHIENNES (59870)", value: "59870" },
  { label: "TILLOY LEZ CAMBRAI (59554)", value: "59554" },
  { label: "TOUFFLERS (59390)", value: "59390" },
  { label: "TOURCOING (59200)", value: "59200" },
  { label: "TOURMIGNIES (59551)", value: "59551" },
  { label: "TRELON (59132)", value: "59132" },
  { label: "TRESSIN (59152)", value: "59152" },
  { label: "TRITH ST LEGER (59125)", value: "59125" },
  { label: "TROISVILLES (59980)", value: "59980" },
  { label: "UXEM (59229)", value: "59229" },
  { label: "VALENCIENNES (59300)", value: "59300" },
  { label: "VENDEGIES AU BOIS (59218)", value: "59218" },
  { label: "VENDEGIES SUR ECAILLON (59213)", value: "59213" },
  { label: "VENDEVILLE (59175)", value: "59175" },
  { label: "VERCHAIN MAUGRE (59227)", value: "59227" },
  { label: "VERLINGHEM (59237)", value: "59237" },
  { label: "VERTAIN (59730)", value: "59730" },
  { label: "VICQ (59970)", value: "59970" },
  { label: "VIESLY (59271)", value: "59271" },
  { label: "VIEUX BERQUIN (59232)", value: "59232" },
  { label: "VIEUX CONDE (59690)", value: "59690" },
  { label: "VIEUX MESNIL (59138)", value: "59138" },
  { label: "VIEUX RENG (59600)", value: "59600" },
  { label: "VILLEREAU (59530)", value: "59530" },
  { label: "VILLEREAU (59530)", value: "59530" },
  { label: "VILLERS AU TERTRE (59234)", value: "59234" },
  { label: "VILLERS EN CAUCHIES (59188)", value: "59188" },
  { label: "VILLERS GUISLAIN (59297)", value: "59297" },
  { label: "VILLERS OUTREAUX (59142)", value: "59142" },
  { label: "VILLERS PLOUICH (59231)", value: "59231" },
  { label: "VILLERS POL (59530)", value: "59530" },
  { label: "VILLERS SIRE NICOLE (59600)", value: "59600" },
  { label: "VOLCKERINCKHOVE (59470)", value: "59470" },
  { label: "VRED (59870)", value: "59870" },
  { label: "WAHAGNIES (59261)", value: "59261" },
  { label: "WALINCOURT SELVIGNY (59127)", value: "59127" },
  { label: "WALINCOURT SELVIGNY (59127)", value: "59127" },
  { label: "WALLERS (59135)", value: "59135" },
  { label: "WALLERS (59135)", value: "59135" },
  { label: "WALLERS EN FAGNE (59132)", value: "59132" },
  { label: "WALLON CAPPEL (59190)", value: "59190" },
  { label: "WAMBAIX (59400)", value: "59400" },
  { label: "WAMBRECHIES (59118)", value: "59118" },
  { label: "WANDIGNIES HAMAGE (59870)", value: "59870" },
  { label: "WANNEHAIN (59830)", value: "59830" },
  { label: "WARGNIES LE GRAND (59144)", value: "59144" },
  { label: "WARGNIES LE PETIT (59144)", value: "59144" },
  { label: "WARHEM (59380)", value: "59380" },
  { label: "WARLAING (59870)", value: "59870" },
  { label: "WARNETON (59560)", value: "59560" },
  { label: "WASNES AU BAC (59252)", value: "59252" },
  { label: "WASQUEHAL (59290)", value: "59290" },
  { label: "WATTEN (59143)", value: "59143" },
  { label: "WATTIGNIES (59139)", value: "59139" },
  { label: "WATTIGNIES LA VICTOIRE (59680)", value: "59680" },
  { label: "WATTRELOS (59150)", value: "59150" },
  { label: "WAVRECHAIN SOUS DENAIN (59220)", value: "59220" },
  { label: "WAVRECHAIN SOUS FAULX (59111)", value: "59111" },
  { label: "WAVRIN (59136)", value: "59136" },
  { label: "WAZIERS (59119)", value: "59119" },
  { label: "WEMAERS CAPPEL (59670)", value: "59670" },
  { label: "WERVICQ SUD (59117)", value: "59117" },
  { label: "WEST CAPPEL (59380)", value: "59380" },
  { label: "WICRES (59134)", value: "59134" },
  { label: "WIGNEHIES (59212)", value: "59212" },
  { label: "WILLEMS (59780)", value: "59780" },
  { label: "WILLIES (59740)", value: "59740" },
  { label: "WINNEZEELE (59670)", value: "59670" },
  { label: "WORMHOUT (59470)", value: "59470" },
  { label: "WULVERDINGHE (59143)", value: "59143" },
  { label: "WYLDER (59380)", value: "59380" },
  { label: "ZEGERSCAPPEL (59470)", value: "59470" },
  { label: "ZERMEZEELE (59670)", value: "59670" },
  { label: "ZUYDCOOTE (59123)", value: "59123" },
  { label: "ZUYTPEENE (59670)", value: "59670" },
  { label: "DON (59272)", value: "59272" },
  { label: "ABANCOURT (60220)", value: "60220" },
  { label: "ABBECOURT (60430)", value: "60430" },
  { label: "ABBEVILLE ST LUCIEN (60480)", value: "60480" },
  { label: "ACHY (60690)", value: "60690" },
  { label: "ACY EN MULTIEN (60620)", value: "60620" },
  { label: "LES AGEUX (60700)", value: "60700" },
  { label: "AGNETZ (60600)", value: "60600" },
  { label: "AIRION (60600)", value: "60600" },
  { label: "ALLONNE (60000)", value: "60000" },
  { label: "AMBLAINVILLE (60110)", value: "60110" },
  { label: "AMY (60310)", value: "60310" },
  { label: "ANDEVILLE (60570)", value: "60570" },
  { label: "ANGICOURT (60940)", value: "60940" },
  { label: "ANGIVILLERS (60130)", value: "60130" },
  { label: "ANGY (60250)", value: "60250" },
  { label: "ANSACQ (60250)", value: "60250" },
  { label: "ANSAUVILLERS (60120)", value: "60120" },
  { label: "ANTHEUIL PORTES (60162)", value: "60162" },
  { label: "ANTILLY (60620)", value: "60620" },
  { label: "APPILLY (60400)", value: "60400" },
  { label: "APREMONT (60300)", value: "60300" },
  { label: "ARMANCOURT (60880)", value: "60880" },
  { label: "ARSY (60190)", value: "60190" },
  { label: "ATTICHY (60350)", value: "60350" },
  { label: "AUCHY LA MONTAGNE (60360)", value: "60360" },
  { label: "AUGER ST VINCENT (60800)", value: "60800" },
  { label: "AUMONT EN HALATTE (60300)", value: "60300" },
  { label: "AUNEUIL (60390)", value: "60390" },
  { label: "AUNEUIL (60390)", value: "60390" },
  { label: "AUTEUIL (60390)", value: "60390" },
  { label: "AUTHEUIL EN VALOIS (60890)", value: "60890" },
  { label: "AUTRECHES (60350)", value: "60350" },
  { label: "AVILLY ST LEONARD (60300)", value: "60300" },
  { label: "AVRECHY (60130)", value: "60130" },
  { label: "AVRICOURT (60310)", value: "60310" },
  { label: "AVRIGNY (60190)", value: "60190" },
  { label: "BABOEUF (60400)", value: "60400" },
  { label: "BACOUEL (60120)", value: "60120" },
  { label: "BAILLEUL LE SOC (60190)", value: "60190" },
  { label: "BAILLEUL SUR THERAIN (60930)", value: "60930" },
  { label: "BAILLEVAL (60140)", value: "60140" },
  { label: "BAILLY (60170)", value: "60170" },
  { label: "BALAGNY SUR THERAIN (60250)", value: "60250" },
  { label: "BARBERY (60810)", value: "60810" },
  { label: "BARGNY (60620)", value: "60620" },
  { label: "BARON (60300)", value: "60300" },
  { label: "BAUGY (60113)", value: "60113" },
  { label: "BAUGY (60190)", value: "60190" },
  { label: "BAZANCOURT (60380)", value: "60380" },
  { label: "BAZICOURT (60700)", value: "60700" },
  { label: "BEAUDEDUIT (60210)", value: "60210" },
  { label: "BEAUGIES SOUS BOIS (60640)", value: "60640" },
  { label: "BEAULIEU LES FONTAINES (60310)", value: "60310" },
  { label: "BEAUMONT LES NONAINS (60390)", value: "60390" },
  { label: "BEAURAINS LES NOYON (60400)", value: "60400" },
  { label: "BEAUREPAIRE (60700)", value: "60700" },
  { label: "BEAUVAIS (60000)", value: "60000" },
  { label: "BEAUVOIR (60120)", value: "60120" },
  { label: "BEHERICOURT (60400)", value: "60400" },
  { label: "BELLE EGLISE (60540)", value: "60540" },
  { label: "BELLOY (60490)", value: "60490" },
  { label: "BERLANCOURT (60640)", value: "60640" },
  { label: "BERNEUIL EN BRAY (60390)", value: "60390" },
  { label: "BERNEUIL SUR AISNE (60350)", value: "60350" },
  { label: "BERTHECOURT (60370)", value: "60370" },
  { label: "BETHANCOURT EN VALOIS (60129)", value: "60129" },
  { label: "BETHISY ST MARTIN (60320)", value: "60320" },
  { label: "BETHISY ST PIERRE (60320)", value: "60320" },
  { label: "BETZ (60620)", value: "60620" },
  { label: "BIENVILLE (60280)", value: "60280" },
  { label: "BIERMONT (60490)", value: "60490" },
  { label: "BITRY (60350)", value: "60350" },
  { label: "BLACOURT (60650)", value: "60650" },
  { label: "BLAINCOURT LES PRECY (60460)", value: "60460" },
  { label: "BLANCFOSSE (60120)", value: "60120" },
  { label: "BLARGIES (60220)", value: "60220" },
  { label: "BLICOURT (60860)", value: "60860" },
  { label: "BLINCOURT (60190)", value: "60190" },
  { label: "BOISSY FRESNOY (60440)", value: "60440" },
  { label: "BONLIER (60510)", value: "60510" },
  { label: "BONNEUIL LES EAUX (60120)", value: "60120" },
  { label: "BONNEUIL EN VALOIS (60123)", value: "60123" },
  { label: "BONNIERES (60112)", value: "60112" },
  { label: "BONVILLERS (60120)", value: "60120" },
  { label: "BORAN SUR OISE (60820)", value: "60820" },
  { label: "BOREST (60300)", value: "60300" },
  { label: "BORNEL (60540)", value: "60540" },
  { label: "BORNEL (60540)", value: "60540" },
  { label: "BORNEL (60540)", value: "60540" },
  { label: "BOUBIERS (60240)", value: "60240" },
  { label: "BOUCONVILLERS (60240)", value: "60240" },
  { label: "BOUILLANCY (60620)", value: "60620" },
  { label: "BOULLARRE (60620)", value: "60620" },
  { label: "BOULOGNE LA GRASSE (60490)", value: "60490" },
  { label: "BOURSONNE (60141)", value: "60141" },
  { label: "BOURY EN VEXIN (60240)", value: "60240" },
  { label: "BOUTENCOURT (60590)", value: "60590" },
  { label: "BOUVRESSE (60220)", value: "60220" },
  { label: "BRAISNES SUR ARONDE (60113)", value: "60113" },
  { label: "BRASSEUSE (60810)", value: "60810" },
  { label: "BREGY (60440)", value: "60440" },
  { label: "BRENOUILLE (60870)", value: "60870" },
  { label: "BRESLES (60510)", value: "60510" },
  { label: "BRETEUIL (60120)", value: "60120" },
  { label: "BRETIGNY (60400)", value: "60400" },
  { label: "BREUIL LE SEC (60840)", value: "60840" },
  { label: "BREUIL LE VERT (60600)", value: "60600" },
  { label: "BRIOT (60210)", value: "60210" },
  { label: "BROMBOS (60210)", value: "60210" },
  { label: "BROQUIERS (60220)", value: "60220" },
  { label: "BROYES (60120)", value: "60120" },
  { label: "BRUNVILLERS LA MOTTE (60130)", value: "60130" },
  { label: "BUCAMPS (60480)", value: "60480" },
  { label: "BUICOURT (60380)", value: "60380" },
  { label: "BULLES (60130)", value: "60130" },
  { label: "BURY (60250)", value: "60250" },
  { label: "BUSSY (60400)", value: "60400" },
  { label: "CAISNES (60400)", value: "60400" },
  { label: "CAMBRONNE LES RIBECOURT (60170)", value: "60170" },
  { label: "CAMBRONNE LES CLERMONT (60290)", value: "60290" },
  { label: "CAMPAGNE (60640)", value: "60640" },
  { label: "CAMPEAUX (60220)", value: "60220" },
  { label: "CAMPREMY (60480)", value: "60480" },
  { label: "CANDOR (60310)", value: "60310" },
  { label: "CANLY (60680)", value: "60680" },
  { label: "CANNECTANCOURT (60310)", value: "60310" },
  { label: "CANNY SUR MATZ (60310)", value: "60310" },
  { label: "CANNY SUR THERAIN (60220)", value: "60220" },
  { label: "CARLEPONT (60170)", value: "60170" },
  { label: "CATENOY (60840)", value: "60840" },
  { label: "CATHEUX (60360)", value: "60360" },
  { label: "CATIGNY (60640)", value: "60640" },
  { label: "CATILLON FUMECHON (60130)", value: "60130" },
  { label: "CAUFFRY (60290)", value: "60290" },
  { label: "CAUVIGNY (60730)", value: "60730" },
  { label: "CEMPUIS (60210)", value: "60210" },
  { label: "CERNOY (60190)", value: "60190" },
  { label: "CHAMANT (60300)", value: "60300" },
  { label: "CHAMBLY (60230)", value: "60230" },
  { label: "CHAMBORS (60240)", value: "60240" },
  { label: "CHANTILLY (60500)", value: "60500" },
  { label: "LA CHAPELLE EN SERVAL (60520)", value: "60520" },
  { label: "CHAUMONT EN VEXIN (60240)", value: "60240" },
  { label: "CHAVENCON (60240)", value: "60240" },
  { label: "CHELLES (60350)", value: "60350" },
  { label: "CHEPOIX (60120)", value: "60120" },
  { label: "CHEVINCOURT (60150)", value: "60150" },
  { label: "CHEVREVILLE (60440)", value: "60440" },
  { label: "CHEVRIERES (60710)", value: "60710" },
  { label: "CHIRY OURSCAMP (60138)", value: "60138" },
  { label: "CHOISY AU BAC (60750)", value: "60750" },
  { label: "CHOISY LA VICTOIRE (60190)", value: "60190" },
  { label: "CHOQUEUSE LES BENARDS (60360)", value: "60360" },
  { label: "CINQUEUX (60940)", value: "60940" },
  { label: "CIRES LES MELLO (60660)", value: "60660" },
  { label: "CLAIROIX (60280)", value: "60280" },
  { label: "CLERMONT (60600)", value: "60600" },
  { label: "COIVREL (60420)", value: "60420" },
  { label: "COMPIEGNE (60200)", value: "60200" },
  { label: "CONCHY LES POTS (60490)", value: "60490" },
  { label: "CONTEVILLE (60360)", value: "60360" },
  { label: "CORBEIL CERF (60110)", value: "60110" },
  { label: "CORMEILLES (60120)", value: "60120" },
  { label: "LE COUDRAY ST GERMER (60850)", value: "60850" },
  { label: "LE COUDRAY SUR THELLE (60430)", value: "60430" },
  { label: "COUDUN (60150)", value: "60150" },
  { label: "COULOISY (60350)", value: "60350" },
  { label: "COURCELLES EPAYELLES (60420)", value: "60420" },
  { label: "COURCELLES LES GISORS (60240)", value: "60240" },
  { label: "COURTEUIL (60300)", value: "60300" },
  { label: "COURTIEUX (60350)", value: "60350" },
  { label: "COYE LA FORET (60580)", value: "60580" },
  { label: "CRAMOISY (60660)", value: "60660" },
  { label: "CRAPEAUMESNIL (60310)", value: "60310" },
  { label: "CREIL (60100)", value: "60100" },
  { label: "CREPY EN VALOIS (60800)", value: "60800" },
  { label: "CRESSONSACQ (60190)", value: "60190" },
  { label: "CREVECOEUR LE GRAND (60360)", value: "60360" },
  { label: "CREVECOEUR LE PETIT (60420)", value: "60420" },
  { label: "CRILLON (60112)", value: "60112" },
  { label: "CRISOLLES (60400)", value: "60400" },
  { label: "LE CROCQ (60120)", value: "60120" },
  { label: "CROISSY SUR CELLE (60120)", value: "60120" },
  { label: "CROUTOY (60350)", value: "60350" },
  { label: "CROUY EN THELLE (60530)", value: "60530" },
  { label: "CUIGNIERES (60130)", value: "60130" },
  { label: "CUIGY EN BRAY (60850)", value: "60850" },
  { label: "CUISE LA MOTTE (60350)", value: "60350" },
  { label: "CUTS (60400)", value: "60400" },
  { label: "CUVERGNON (60620)", value: "60620" },
  { label: "CUVILLY (60490)", value: "60490" },
  { label: "CUY (60310)", value: "60310" },
  { label: "DAMERAUCOURT (60210)", value: "60210" },
  { label: "DARGIES (60210)", value: "60210" },
  { label: "DELINCOURT (60240)", value: "60240" },
  { label: "LA DRENNE (60790)", value: "60790" },
  { label: "LA DRENNE (60790)", value: "60790" },
  { label: "LA DRENNE (60790)", value: "60790" },
  { label: "DIEUDONNE (60530)", value: "60530" },
  { label: "DIVES (60310)", value: "60310" },
  { label: "DOMELIERS (60360)", value: "60360" },
  { label: "DOMFRONT (60420)", value: "60420" },
  { label: "DOMPIERRE (60420)", value: "60420" },
  { label: "DUVY (60800)", value: "60800" },
  { label: "ECUVILLY (60310)", value: "60310" },
  { label: "ELENCOURT (60210)", value: "60210" },
  { label: "ELINCOURT STE MARGUERITE (60157)", value: "60157" },
  { label: "EMEVILLE (60123)", value: "60123" },
  { label: "ENENCOURT LEAGE (60590)", value: "60590" },
  { label: "LA CORNE EN VEXIN (60240)", value: "60240" },
  { label: "LA CORNE EN VEXIN (60240)", value: "60240" },
  { label: "LA CORNE EN VEXIN (60240)", value: "60240" },
  { label: "EPINEUSE (60190)", value: "60190" },
  { label: "ERAGNY SUR EPTE (60590)", value: "60590" },
  { label: "ERCUIS (60530)", value: "60530" },
  { label: "ERMENONVILLE (60950)", value: "60950" },
  { label: "ERNEMONT BOUTAVENT (60380)", value: "60380" },
  { label: "ERQUERY (60600)", value: "60600" },
  { label: "ERQUINVILLERS (60130)", value: "60130" },
  { label: "ESCAMES (60380)", value: "60380" },
  { label: "ESCHES (60110)", value: "60110" },
  { label: "ESCLES ST PIERRE (60220)", value: "60220" },
  { label: "ESPAUBOURG (60650)", value: "60650" },
  { label: "ESQUENNOY (60120)", value: "60120" },
  { label: "ESSUILES (60510)", value: "60510" },
  { label: "ESTREES ST DENIS (60190)", value: "60190" },
  { label: "ETAVIGNY (60620)", value: "60620" },
  { label: "ETOUY (60600)", value: "60600" },
  { label: "EVE (60330)", value: "60330" },
  { label: "EVRICOURT (60310)", value: "60310" },
  { label: "FAY LES ETANGS (60240)", value: "60240" },
  { label: "LE FAYEL (60680)", value: "60680" },
  { label: "LE FAY ST QUENTIN (60510)", value: "60510" },
  { label: "FEIGNEUX (60800)", value: "60800" },
  { label: "FERRIERES (60420)", value: "60420" },
  { label: "FEUQUIERES (60960)", value: "60960" },
  { label: "FITZ JAMES (60600)", value: "60600" },
  { label: "FLAVACOURT (60590)", value: "60590" },
  { label: "FLAVY LE MELDEUX (60640)", value: "60640" },
  { label: "FLECHY (60120)", value: "60120" },
  { label: "FLEURINES (60700)", value: "60700" },
  { label: "FLEURY (60240)", value: "60240" },
  { label: "FONTAINE BONNELEAU (60360)", value: "60360" },
  { label: "FONTAINE CHAALIS (60300)", value: "60300" },
  { label: "FONTAINE LAVAGANNE (60690)", value: "60690" },
  { label: "FONTAINE ST LUCIEN (60480)", value: "60480" },
  { label: "FONTENAY TORCY (60380)", value: "60380" },
  { label: "FORMERIE (60220)", value: "60220" },
  { label: "FORMERIE (60220)", value: "60220" },
  { label: "FOUILLEUSE (60190)", value: "60190" },
  { label: "FOUILLOY (60220)", value: "60220" },
  { label: "FOULANGUES (60250)", value: "60250" },
  { label: "FOUQUENIES (60000)", value: "60000" },
  { label: "FOUQUEROLLES (60510)", value: "60510" },
  { label: "FOURNIVAL (60130)", value: "60130" },
  { label: "FRANCASTEL (60480)", value: "60480" },
  { label: "FRANCIERES (60190)", value: "60190" },
  { label: "FRENICHES (60640)", value: "60640" },
  { label: "MONTCHEVREUIL (60240)", value: "60240" },
  { label: "MONTCHEVREUIL (60240)", value: "60240" },
  { label: "FRESNE LEGUILLON (60240)", value: "60240" },
  { label: "FRESNIERES (60310)", value: "60310" },
  { label: "FRESNOY EN THELLE (60530)", value: "60530" },
  { label: "FRESNOY LA RIVIERE (60127)", value: "60127" },
  { label: "FRESNOY LE LUAT (60800)", value: "60800" },
  { label: "LE FRESTOY VAUX (60420)", value: "60420" },
  { label: "FRETOY LE CHATEAU (60640)", value: "60640" },
  { label: "FROCOURT (60000)", value: "60000" },
  { label: "FROISSY (60480)", value: "60480" },
  { label: "LE GALLET (60360)", value: "60360" },
  { label: "GANNES (60120)", value: "60120" },
  { label: "GAUDECHART (60210)", value: "60210" },
  { label: "GENVRY (60400)", value: "60400" },
  { label: "GERBEROY (60380)", value: "60380" },
  { label: "GILOCOURT (60129)", value: "60129" },
  { label: "GIRAUMONT (60150)", value: "60150" },
  { label: "GLAIGNES (60129)", value: "60129" },
  { label: "GLATIGNY (60650)", value: "60650" },
  { label: "GODENVILLERS (60420)", value: "60420" },
  { label: "GOINCOURT (60000)", value: "60000" },
  { label: "GOLANCOURT (60640)", value: "60640" },
  { label: "GONDREVILLE (60117)", value: "60117" },
  { label: "GOURCHELLES (60220)", value: "60220" },
  { label: "GOURNAY SUR ARONDE (60190)", value: "60190" },
  { label: "GOUVIEUX (60270)", value: "60270" },
  { label: "GOUY LES GROSEILLERS (60120)", value: "60120" },
  { label: "GRANDFRESNOY (60680)", value: "60680" },
  { label: "GRANDVILLERS AUX BOIS (60190)", value: "60190" },
  { label: "GRANDVILLIERS (60210)", value: "60210" },
  { label: "GRANDRU (60400)", value: "60400" },
  { label: "GREMEVILLERS (60380)", value: "60380" },
  { label: "GREZ (60210)", value: "60210" },
  { label: "GUIGNECOURT (60480)", value: "60480" },
  { label: "GUISCARD (60640)", value: "60640" },
  { label: "GURY (60310)", value: "60310" },
  { label: "HADANCOURT LE HAUT CLOCHER (60240)", value: "60240" },
  { label: "HAINVILLERS (60490)", value: "60490" },
  { label: "HALLOY (60210)", value: "60210" },
  { label: "HANNACHES (60650)", value: "60650" },
  { label: "LE HAMEL (60210)", value: "60210" },
  { label: "HANVOILE (60650)", value: "60650" },
  { label: "HARDIVILLERS (60120)", value: "60120" },
  { label: "HAUCOURT (60112)", value: "60112" },
  { label: "HAUDIVILLERS (60510)", value: "60510" },
  { label: "HAUTBOS (60210)", value: "60210" },
  { label: "HAUTE EPINE (60690)", value: "60690" },
  { label: "HAUTEFONTAINE (60350)", value: "60350" },
  { label: "HECOURT (60380)", value: "60380" },
  { label: "HEILLES (60250)", value: "60250" },
  { label: "HEMEVILLERS (60190)", value: "60190" },
  { label: "HENONVILLE (60119)", value: "60119" },
  { label: "HERCHIES (60112)", value: "60112" },
  { label: "LA HERELLE (60120)", value: "60120" },
  { label: "HERICOURT SUR THERAIN (60380)", value: "60380" },
  { label: "HERMES (60370)", value: "60370" },
  { label: "HETOMESNIL (60360)", value: "60360" },
  { label: "HODENC EN BRAY (60650)", value: "60650" },
  { label: "HODENC L EVEQUE (60430)", value: "60430" },
  { label: "HONDAINVILLE (60250)", value: "60250" },
  { label: "HOUDANCOURT (60710)", value: "60710" },
  { label: "LA HOUSSOYE (60390)", value: "60390" },
  { label: "IVORS (60141)", value: "60141" },
  { label: "IVRY LE TEMPLE (60173)", value: "60173" },
  { label: "JAMERICOURT (60240)", value: "60240" },
  { label: "JANVILLE (60150)", value: "60150" },
  { label: "JAULZY (60350)", value: "60350" },
  { label: "JAUX (60880)", value: "60880" },
  { label: "JONQUIERES (60680)", value: "60680" },
  { label: "JOUY SOUS THELLE (60240)", value: "60240" },
  { label: "JUVIGNIES (60112)", value: "60112" },
  { label: "LABERLIERE (60310)", value: "60310" },
  { label: "LABOISSIERE EN THELLE (60570)", value: "60570" },
  { label: "LABOSSE (60590)", value: "60590" },
  { label: "LABRUYERE (60140)", value: "60140" },
  { label: "LACHAPELLE AUX POTS (60650)", value: "60650" },
  { label: "LACHAPELLE ST PIERRE (60730)", value: "60730" },
  { label: "LACHAPELLE SOUS GERBEROY (60380)", value: "60380" },
  { label: "LACHAUSSEE DU BOIS D ECU (60480)", value: "60480" },
  { label: "LACHELLE (60190)", value: "60190" },
  { label: "LACROIX ST OUEN (60610)", value: "60610" },
  { label: "LAFRAYE (60510)", value: "60510" },
  { label: "LAGNY (60310)", value: "60310" },
  { label: "LAGNY LE SEC (60330)", value: "60330" },
  { label: "LAIGNEVILLE (60290)", value: "60290" },
  { label: "LALANDE EN SON (60590)", value: "60590" },
  { label: "LALANDELLE (60850)", value: "60850" },
  { label: "LAMECOURT (60600)", value: "60600" },
  { label: "LAMORLAYE (60260)", value: "60260" },
  { label: "LANNOY CUILLERE (60220)", value: "60220" },
  { label: "LARBROYE (60400)", value: "60400" },
  { label: "LASSIGNY (60310)", value: "60310" },
  { label: "LATAULE (60490)", value: "60490" },
  { label: "LATTAINVILLE (60240)", value: "60240" },
  { label: "LAVACQUERIE (60120)", value: "60120" },
  { label: "LAVERRIERE (60210)", value: "60210" },
  { label: "LAVERSINES (60510)", value: "60510" },
  { label: "LAVILLETERTRE (60240)", value: "60240" },
  { label: "LEGLANTIERS (60420)", value: "60420" },
  { label: "LEVIGNEN (60800)", value: "60800" },
  { label: "LHERAULE (60650)", value: "60650" },
  { label: "LIANCOURT (60140)", value: "60140" },
  { label: "LIANCOURT ST PIERRE (60240)", value: "60240" },
  { label: "LIBERMONT (60640)", value: "60640" },
  { label: "LIERVILLE (60240)", value: "60240" },
  { label: "LIEUVILLERS (60130)", value: "60130" },
  { label: "LIHUS (60360)", value: "60360" },
  { label: "LITZ (60510)", value: "60510" },
  { label: "LOCONVILLE (60240)", value: "60240" },
  { label: "LONGUEIL ANNEL (60150)", value: "60150" },
  { label: "LONGUEIL STE MARIE (60126)", value: "60126" },
  { label: "LORMAISON (60110)", value: "60110" },
  { label: "LOUEUSE (60380)", value: "60380" },
  { label: "LUCHY (60360)", value: "60360" },
  { label: "MACHEMONT (60150)", value: "60150" },
  { label: "MAIGNELAY MONTIGNY (60420)", value: "60420" },
  { label: "MAIMBEVILLE (60600)", value: "60600" },
  { label: "MAISONCELLE ST PIERRE (60112)", value: "60112" },
  { label: "MAISONCELLE TUILERIE (60480)", value: "60480" },
  { label: "MAREST SUR MATZ (60490)", value: "60490" },
  { label: "MAREUIL LA MOTTE (60490)", value: "60490" },
  { label: "MAREUIL SUR OURCQ (60890)", value: "60890" },
  { label: "MARGNY AUX CERISES (60310)", value: "60310" },
  { label: "MARGNY LES COMPIEGNE (60280)", value: "60280" },
  { label: "MARGNY SUR MATZ (60490)", value: "60490" },
  { label: "MAROLLES (60890)", value: "60890" },
  { label: "MARQUEGLISE (60490)", value: "60490" },
  { label: "MARSEILLE EN BEAUVAISIS (60690)", value: "60690" },
  { label: "MARTINCOURT (60112)", value: "60112" },
  { label: "MAUCOURT (60640)", value: "60640" },
  { label: "MAULERS (60480)", value: "60480" },
  { label: "MAYSEL (60660)", value: "60660" },
  { label: "MELICOCQ (60150)", value: "60150" },
  { label: "MELLO (60660)", value: "60660" },
  { label: "MENEVILLERS (60420)", value: "60420" },
  { label: "MERU (60110)", value: "60110" },
  { label: "MERY LA BATAILLE (60420)", value: "60420" },
  { label: "LE MESNIL CONTEVILLE (60210)", value: "60210" },
  { label: "LE MESNIL EN THELLE (60530)", value: "60530" },
  { label: "LE MESNIL ST FIRMIN (60120)", value: "60120" },
  { label: "LE MESNIL SUR BULLES (60130)", value: "60130" },
  { label: "LE MESNIL THERIBUS (60240)", value: "60240" },
  { label: "LE MEUX (60880)", value: "60880" },
  { label: "MILLY SUR THERAIN (60112)", value: "60112" },
  { label: "MOGNEVILLE (60140)", value: "60140" },
  { label: "MOLIENS (60220)", value: "60220" },
  { label: "MONCEAUX (60940)", value: "60940" },
  { label: "MONCEAUX L ABBAYE (60220)", value: "60220" },
  { label: "MONCHY HUMIERES (60113)", value: "60113" },
  { label: "MONCHY ST ELOI (60290)", value: "60290" },
  { label: "MONDESCOURT (60400)", value: "60400" },
  { label: "MONNEVILLE (60240)", value: "60240" },
  { label: "MONTAGNY EN VEXIN (60240)", value: "60240" },
  { label: "MONTAGNY STE FELICITE (60950)", value: "60950" },
  { label: "MONTATAIRE (60160)", value: "60160" },
  { label: "MONTEPILLOY (60810)", value: "60810" },
  { label: "MONTGERAIN (60420)", value: "60420" },
  { label: "MONTIERS (60190)", value: "60190" },
  { label: "MONTJAVOULT (60240)", value: "60240" },
  { label: "MONT L EVEQUE (60300)", value: "60300" },
  { label: "MONTLOGNON (60300)", value: "60300" },
  { label: "MONTMACQ (60150)", value: "60150" },
  { label: "MONTMARTIN (60190)", value: "60190" },
  { label: "MONTREUIL SUR BRECHE (60480)", value: "60480" },
  { label: "MONTREUIL SUR THERAIN (60134)", value: "60134" },
  { label: "MONTS (60119)", value: "60119" },
  { label: "LE MONT ST ADRIEN (60650)", value: "60650" },
  { label: "MORANGLES (60530)", value: "60530" },
  { label: "MORIENVAL (60127)", value: "60127" },
  { label: "MORLINCOURT (60400)", value: "60400" },
  { label: "MORTEFONTAINE (60128)", value: "60128" },
  { label: "MORTEFONTAINE EN THELLE (60570)", value: "60570" },
  { label: "MORTEMER (60490)", value: "60490" },
  { label: "MORVILLERS (60380)", value: "60380" },
  { label: "MORY MONTCRUX (60120)", value: "60120" },
  { label: "MOUCHY LE CHATEL (60250)", value: "60250" },
  { label: "MOULIN SOUS TOUVENT (60350)", value: "60350" },
  { label: "MOUY (60250)", value: "60250" },
  { label: "MOYENNEVILLE (60190)", value: "60190" },
  { label: "MOYVILLERS (60190)", value: "60190" },
  { label: "MUIDORGE (60480)", value: "60480" },
  { label: "MUIRANCOURT (60640)", value: "60640" },
  { label: "MUREAUMONT (60220)", value: "60220" },
  { label: "NAMPCEL (60400)", value: "60400" },
  { label: "NANTEUIL LE HAUDOUIN (60440)", value: "60440" },
  { label: "NERY (60320)", value: "60320" },
  { label: "NEUFCHELLES (60890)", value: "60890" },
  { label: "NEUFVY SUR ARONDE (60190)", value: "60190" },
  { label: "NEUILLY EN THELLE (60530)", value: "60530" },
  { label: "NEUILLY SOUS CLERMONT (60290)", value: "60290" },
  { label: "NEUVILLE BOSC (60119)", value: "60119" },
  { label: "LA NEUVILLE EN HEZ (60510)", value: "60510" },
  { label: "LA NEUVILLE ROY (60190)", value: "60190" },
  { label: "LA NEUVILLE ST PIERRE (60480)", value: "60480" },
  { label: "LA NEUVILLE SUR OUDEUIL (60690)", value: "60690" },
  { label: "LA NEUVILLE SUR RESSONS (60490)", value: "60490" },
  { label: "LA NEUVILLE VAULT (60112)", value: "60112" },
  { label: "NIVILLERS (60510)", value: "60510" },
  { label: "NOAILLES (60430)", value: "60430" },
  { label: "NOGENT SUR OISE (60180)", value: "60180" },
  { label: "NOINTEL (60840)", value: "60840" },
  { label: "NOIREMONT (60480)", value: "60480" },
  { label: "NOROY (60130)", value: "60130" },
  { label: "NOURARD LE FRANC (60130)", value: "60130" },
  { label: "NOVILLERS (60730)", value: "60730" },
  { label: "NOYERS ST MARTIN (60480)", value: "60480" },
  { label: "NOYON (60400)", value: "60400" },
  { label: "OFFOY (60210)", value: "60210" },
  { label: "OGNES (60440)", value: "60440" },
  { label: "OGNOLLES (60310)", value: "60310" },
  { label: "OMECOURT (60220)", value: "60220" },
  { label: "ONS EN BRAY (60650)", value: "60650" },
  { label: "ORMOY LE DAVIEN (60620)", value: "60620" },
  { label: "ORMOY VILLERS (60800)", value: "60800" },
  { label: "OROER (60510)", value: "60510" },
  { label: "ORROUY (60129)", value: "60129" },
  { label: "ORRY LA VILLE (60560)", value: "60560" },
  { label: "ORVILLERS SOREL (60490)", value: "60490" },
  { label: "OUDEUIL (60860)", value: "60860" },
  { label: "OURSEL MAISON (60480)", value: "60480" },
  { label: "PAILLART (60120)", value: "60120" },
  { label: "PARNES (60240)", value: "60240" },
  { label: "PASSEL (60400)", value: "60400" },
  { label: "PEROY LES GOMBRIES (60440)", value: "60440" },
  { label: "PIERREFITTE EN BEAUVAISIS (60112)", value: "60112" },
  { label: "PIERREFONDS (60350)", value: "60350" },
  { label: "PIMPREZ (60170)", value: "60170" },
  { label: "PISSELEU (60860)", value: "60860" },
  { label: "PLAILLY (60128)", value: "60128" },
  { label: "PLAINVAL (60130)", value: "60130" },
  { label: "PLAINVILLE (60120)", value: "60120" },
  { label: "LE PLESSIER SUR BULLES (60130)", value: "60130" },
  { label: "LE PLESSIER SUR ST JUST (60130)", value: "60130" },
  { label: "PLESSIS DE ROYE (60310)", value: "60310" },
  { label: "LE PLESSIS BELLEVILLE (60330)", value: "60330" },
  { label: "LE PLESSIS BRION (60150)", value: "60150" },
  { label: "LE PLESSIS PATTE D OIE (60640)", value: "60640" },
  { label: "LE PLOYRON (60420)", value: "60420" },
  { label: "PONCHON (60430)", value: "60430" },
  { label: "PONTARME (60520)", value: "60520" },
  { label: "PONT L EVEQUE (60400)", value: "60400" },
  { label: "PONTOISE LES NOYON (60400)", value: "60400" },
  { label: "PONTPOINT (60700)", value: "60700" },
  { label: "PONT STE MAXENCE (60700)", value: "60700" },
  { label: "PORCHEUX (60390)", value: "60390" },
  { label: "PORQUERICOURT (60400)", value: "60400" },
  { label: "POUILLY (60790)", value: "60790" },
  { label: "PRECY SUR OISE (60460)", value: "60460" },
  { label: "PREVILLERS (60360)", value: "60360" },
  { label: "PRONLEROY (60190)", value: "60190" },
  { label: "PUISEUX EN BRAY (60850)", value: "60850" },
  { label: "PUISEUX LE HAUBERGER (60540)", value: "60540" },
  { label: "PUITS LA VALLEE (60480)", value: "60480" },
  { label: "QUESMY (60640)", value: "60640" },
  { label: "LE QUESNEL AUBRY (60480)", value: "60480" },
  { label: "QUINCAMPOIX FLEUZY (60220)", value: "60220" },
  { label: "QUINQUEMPOIX (60130)", value: "60130" },
  { label: "RAINVILLERS (60155)", value: "60155" },
  { label: "RANTIGNY (60290)", value: "60290" },
  { label: "RARAY (60810)", value: "60810" },
  { label: "RAVENEL (60130)", value: "60130" },
  { label: "REEZ FOSSE MARTIN (60620)", value: "60620" },
  { label: "REILLY (60240)", value: "60240" },
  { label: "REMECOURT (60600)", value: "60600" },
  { label: "REMERANGLES (60510)", value: "60510" },
  { label: "REMY (60190)", value: "60190" },
  { label: "RESSONS SUR MATZ (60490)", value: "60490" },
  { label: "RETHONDES (60153)", value: "60153" },
  { label: "REUIL SUR BRECHE (60480)", value: "60480" },
  { label: "RHUIS (60410)", value: "60410" },
  { label: "RIBECOURT DRESLINCOURT (60170)", value: "60170" },
  { label: "RIBECOURT DRESLINCOURT (60170)", value: "60170" },
  { label: "RICQUEBOURG (60490)", value: "60490" },
  { label: "RIEUX (60870)", value: "60870" },
  { label: "RIVECOURT (60126)", value: "60126" },
  { label: "ROBERVAL (60410)", value: "60410" },
  { label: "ROCHY CONDE (60510)", value: "60510" },
  { label: "ROCQUEMONT (60800)", value: "60800" },
  { label: "ROCQUENCOURT (60120)", value: "60120" },
  { label: "ROMESCAMPS (60220)", value: "60220" },
  { label: "ROSIERES (60440)", value: "60440" },
  { label: "ROSOY (60140)", value: "60140" },
  { label: "ROSOY EN MULTIEN (60620)", value: "60620" },
  { label: "ROTANGY (60360)", value: "60360" },
  { label: "ROTHOIS (60690)", value: "60690" },
  { label: "ROUSSELOY (60660)", value: "60660" },
  { label: "ROUVILLE (60800)", value: "60800" },
  { label: "ROUVILLERS (60190)", value: "60190" },
  { label: "ROUVRES EN MULTIEN (60620)", value: "60620" },
  { label: "ROUVROY LES MERLES (60120)", value: "60120" },
  { label: "ROYAUCOURT (60420)", value: "60420" },
  { label: "ROY BOISSY (60690)", value: "60690" },
  { label: "ROYE SUR MATZ (60310)", value: "60310" },
  { label: "LA RUE ST PIERRE (60510)", value: "60510" },
  { label: "RULLY (60810)", value: "60810" },
  { label: "RUSSY BEMONT (60117)", value: "60117" },
  { label: "SACY LE GRAND (60700)", value: "60700" },
  { label: "SACY LE PETIT (60190)", value: "60190" },
  { label: "SAINS MORAINVILLERS (60420)", value: "60420" },
  { label: "ST ANDRE FARIVILLERS (60480)", value: "60480" },
  { label: "ST ARNOULT (60220)", value: "60220" },
  { label: "ST AUBIN EN BRAY (60650)", value: "60650" },
  { label: "ST AUBIN EN BRAY (60650)", value: "60650" },
  { label: "ST AUBIN SOUS ERQUERY (60600)", value: "60600" },
  { label: "ST CREPIN AUX BOIS (60170)", value: "60170" },
  { label: "ST CREPIN IBOUVILLERS (60149)", value: "60149" },
  { label: "ST CREPIN IBOUVILLERS (60790)", value: "60790" },
  { label: "ST DENISCOURT (60380)", value: "60380" },
  { label: "ST ETIENNE ROILAYE (60350)", value: "60350" },
  { label: "STE EUSOYE (60480)", value: "60480" },
  { label: "ST FELIX (60370)", value: "60370" },
  { label: "STE GENEVIEVE (60730)", value: "60730" },
  { label: "ST GERMAIN LA POTERIE (60650)", value: "60650" },
  { label: "ST GERMER DE FLY (60850)", value: "60850" },
  { label: "SAINTINES (60410)", value: "60410" },
  { label: "ST JEAN AUX BOIS (60350)", value: "60350" },
  { label: "ST JUST EN CHAUSSEE (60130)", value: "60130" },
  { label: "ST LEGER AUX BOIS (60170)", value: "60170" },
  { label: "ST LEGER EN BRAY (60155)", value: "60155" },
  { label: "ST LEU D ESSERENT (60340)", value: "60340" },
  { label: "ST MARTIN AUX BOIS (60420)", value: "60420" },
  { label: "ST MARTIN LE NOEUD (60000)", value: "60000" },
  { label: "ST MARTIN LONGUEAU (60700)", value: "60700" },
  { label: "ST MAUR (60210)", value: "60210" },
  { label: "ST MAXIMIN (60740)", value: "60740" },
  { label: "ST OMER EN CHAUSSEE (60860)", value: "60860" },
  { label: "ST PAUL (60650)", value: "60650" },
  { label: "ST PIERRE ES CHAMPS (60850)", value: "60850" },
  { label: "ST PIERRE LES BITRY (60350)", value: "60350" },
  { label: "ST QUENTIN DES PRES (60380)", value: "60380" },
  { label: "ST REMY EN L EAU (60130)", value: "60130" },
  { label: "ST SAMSON LA POTERIE (60220)", value: "60220" },
  { label: "ST SAUVEUR (60320)", value: "60320" },
  { label: "ST SULPICE (60430)", value: "60430" },
  { label: "ST THIBAULT (60210)", value: "60210" },
  { label: "ST VAAST DE LONGMONT (60410)", value: "60410" },
  { label: "ST VAAST LES MELLO (60660)", value: "60660" },
  { label: "ST VALERY (60220)", value: "60220" },
  { label: "SALENCY (60400)", value: "60400" },
  { label: "SARCUS (60210)", value: "60210" },
  { label: "SARNOIS (60210)", value: "60210" },
  { label: "LE SAULCHOY (60360)", value: "60360" },
  { label: "SAVIGNIES (60650)", value: "60650" },
  { label: "SEMPIGNY (60400)", value: "60400" },
  { label: "SENANTES (60650)", value: "60650" },
  { label: "SENLIS (60300)", value: "60300" },
  { label: "SENOTS (60240)", value: "60240" },
  { label: "SERANS (60240)", value: "60240" },
  { label: "SEREVILLERS (60120)", value: "60120" },
  { label: "SERIFONTAINE (60590)", value: "60590" },
  { label: "SERMAIZE (60400)", value: "60400" },
  { label: "SERY MAGNEVAL (60800)", value: "60800" },
  { label: "SILLY LE LONG (60330)", value: "60330" },
  { label: "SILLY TILLARD (60430)", value: "60430" },
  { label: "SOLENTE (60310)", value: "60310" },
  { label: "SOMMEREUX (60210)", value: "60210" },
  { label: "SONGEONS (60380)", value: "60380" },
  { label: "SULLY (60380)", value: "60380" },
  { label: "SUZOY (60400)", value: "60400" },
  { label: "TALMONTIERS (60590)", value: "60590" },
  { label: "TARTIGNY (60120)", value: "60120" },
  { label: "THERDONNE (60510)", value: "60510" },
  { label: "THERINES (60380)", value: "60380" },
  { label: "THIBIVILLERS (60240)", value: "60240" },
  { label: "THIERS SUR THEVE (60520)", value: "60520" },
  { label: "THIESCOURT (60310)", value: "60310" },
  { label: "THIEULOY ST ANTOINE (60210)", value: "60210" },
  { label: "THIEUX (60480)", value: "60480" },
  { label: "THIVERNY (60160)", value: "60160" },
  { label: "THOUROTTE (60150)", value: "60150" },
  { label: "THURY EN VALOIS (60890)", value: "60890" },
  { label: "THURY SOUS CLERMONT (60250)", value: "60250" },
  { label: "TILLE (60000)", value: "60000" },
  { label: "TOURLY (60240)", value: "60240" },
  { label: "TRACY LE MONT (60170)", value: "60170" },
  { label: "TRACY LE MONT (60170)", value: "60170" },
  { label: "TRACY LE VAL (60170)", value: "60170" },
  { label: "TRICOT (60420)", value: "60420" },
  { label: "TRIE CHATEAU (60590)", value: "60590" },
  { label: "TRIE CHATEAU (60590)", value: "60590" },
  { label: "TRIE LA VILLE (60590)", value: "60590" },
  { label: "TROISSEREUX (60112)", value: "60112" },
  { label: "TROSLY BREUIL (60350)", value: "60350" },
  { label: "TROUSSENCOURT (60120)", value: "60120" },
  { label: "TRUMILLY (60800)", value: "60800" },
  { label: "ULLY ST GEORGES (60730)", value: "60730" },
  { label: "VALDAMPIERRE (60790)", value: "60790" },
  { label: "VALESCOURT (60130)", value: "60130" },
  { label: "VANDELICOURT (60490)", value: "60490" },
  { label: "VARESNES (60400)", value: "60400" },
  { label: "VARINFROY (60890)", value: "60890" },
  { label: "VAUCHELLES (60400)", value: "60400" },
  { label: "VAUCIENNES (60117)", value: "60117" },
  { label: "VAUDANCOURT (60240)", value: "60240" },
  { label: "LE VAUMAIN (60590)", value: "60590" },
  { label: "VAUMOISE (60117)", value: "60117" },
  { label: "LE VAUROUX (60390)", value: "60390" },
  { label: "VELENNES (60510)", value: "60510" },
  { label: "VENDEUIL CAPLY (60120)", value: "60120" },
  { label: "VENETTE (60280)", value: "60280" },
  { label: "VER SUR LAUNETTE (60950)", value: "60950" },
  { label: "VERBERIE (60410)", value: "60410" },
  { label: "VERDEREL LES SAUQUEUSE (60112)", value: "60112" },
  { label: "VERDEREL LES SAUQUEUSE (60112)", value: "60112" },
  { label: "VERDERONNE (60140)", value: "60140" },
  { label: "VERNEUIL EN HALATTE (60550)", value: "60550" },
  { label: "VERSIGNY (60440)", value: "60440" },
  { label: "VEZ (60117)", value: "60117" },
  { label: "VIEFVILLERS (60360)", value: "60360" },
  { label: "VIEUX MOULIN (60350)", value: "60350" },
  { label: "VIGNEMONT (60162)", value: "60162" },
  { label: "VILLE (60400)", value: "60400" },
  { label: "VILLEMBRAY (60650)", value: "60650" },
  { label: "VILLENEUVE LES SABLONS (60175)", value: "60175" },
  { label: "LA VILLENEUVE SOUS THURY (60890)", value: "60890" },
  { label: "VILLENEUVE SUR VERBERIE (60410)", value: "60410" },
  { label: "VILLERS ST BARTHELEMY (60650)", value: "60650" },
  { label: "VILLERS ST FRAMBOURG OGNON (60810)", value: "60810" },
  { label: "VILLERS ST FRAMBOURG OGNON (60810)", value: "60810" },
  { label: "VILLERS ST GENEST (60620)", value: "60620" },
  { label: "VILLERS ST PAUL (60870)", value: "60870" },
  { label: "VILLERS ST SEPULCRE (60134)", value: "60134" },
  { label: "VILLERS SOUS ST LEU (60340)", value: "60340" },
  { label: "VILLERS SUR AUCHY (60650)", value: "60650" },
  { label: "VILLERS SUR BONNIERES (60860)", value: "60860" },
  { label: "VILLERS SUR COUDUN (60150)", value: "60150" },
  { label: "VILLERS VERMONT (60380)", value: "60380" },
  { label: "VILLERS VICOMTE (60120)", value: "60120" },
  { label: "VILLESELVE (60640)", value: "60640" },
  { label: "LES HAUTS TALICAN (60390)", value: "60390" },
  { label: "LES HAUTS TALICAN (60390)", value: "60390" },
  { label: "VINEUIL ST FIRMIN (60500)", value: "60500" },
  { label: "VROCOURT (60112)", value: "60112" },
  { label: "WACQUEMOULIN (60420)", value: "60420" },
  { label: "WAMBEZ (60380)", value: "60380" },
  { label: "WARLUIS (60430)", value: "60430" },
  { label: "WAVIGNIES (60130)", value: "60130" },
  { label: "WELLES PERENNES (60420)", value: "60420" },
  { label: "AUX MARAIS (60000)", value: "60000" },
  { label: "ALENCON (61000)", value: "61000" },
  { label: "ALMENECHES (61570)", value: "61570" },
  { label: "APPENAI SOUS BELLEME (61130)", value: "61130" },
  { label: "ARGENTAN (61200)", value: "61200" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61100)", value: "61100" },
  { label: "ATHIS VAL DE ROUVRE (61430)", value: "61430" },
  { label: "AUBE (61270)", value: "61270" },
  { label: "AUBRY LE PANTHOU (61120)", value: "61120" },
  { label: "AUBUSSON (61100)", value: "61100" },
  { label: "AUGUAISE (61270)", value: "61270" },
  { label: "AUNAY LES BOIS (61500)", value: "61500" },
  { label: "AUNOU LE FAUCON (61200)", value: "61200" },
  { label: "AUNOU SUR ORNE (61500)", value: "61500" },
  { label: "LES AUTHIEUX DU PUITS (61240)", value: "61240" },
  { label: "AVERNES ST GOURGON (61470)", value: "61470" },
  { label: "AVOINE (61150)", value: "61150" },
  { label: "AVRILLY (61700)", value: "61700" },
  { label: "BAILLEUL (61160)", value: "61160" },
  { label: "BANVOU (61450)", value: "61450" },
  { label: "BARVILLE (61170)", value: "61170" },
  { label: "BAZOCHES AU HOULME (61210)", value: "61210" },
  { label: "BAZOCHES SUR HOENE (61560)", value: "61560" },
  { label: "LA BAZOQUE (61100)", value: "61100" },
  { label: "BEAUFAI (61270)", value: "61270" },
  { label: "BEAULIEU (61190)", value: "61190" },
  { label: "BEAUVAIN (61600)", value: "61600" },
  { label: "BELFONDS (61500)", value: "61500" },
  { label: "BELLAVILLIERS (61360)", value: "61360" },
  { label: "BELLEME (61130)", value: "61130" },
  { label: "LA BELLIERE (61570)", value: "61570" },
  { label: "BELLOU EN HOULME (61220)", value: "61220" },
  { label: "BELLOU LE TRICHARD (61130)", value: "61130" },
  { label: "BERD HUIS (61340)", value: "61340" },
  { label: "BERJOU (61430)", value: "61430" },
  { label: "BIZOU (61290)", value: "61290" },
  { label: "BOECE (61560)", value: "61560" },
  { label: "BOISSEI LA LANDE (61570)", value: "61570" },
  { label: "COUR MAUGIS SUR HUISNE (61110)", value: "61110" },
  { label: "COUR MAUGIS SUR HUISNE (61110)", value: "61110" },
  { label: "COUR MAUGIS SUR HUISNE (61110)", value: "61110" },
  { label: "COUR MAUGIS SUR HUISNE (61340)", value: "61340" },
  { label: "BOITRON (61500)", value: "61500" },
  { label: "BONNEFOI (61270)", value: "61270" },
  { label: "BONSMOULINS (61380)", value: "61380" },
  { label: "LE BOSC RENOULT (61470)", value: "61470" },
  { label: "BOUCE (61570)", value: "61570" },
  { label: "LE BOUILLON (61500)", value: "61500" },
  { label: "BRETHEL (61270)", value: "61270" },
  { label: "BRETONCELLES (61110)", value: "61110" },
  { label: "BRIEUX (61160)", value: "61160" },
  { label: "BRIOUZE (61220)", value: "61220" },
  { label: "BRULLEMAIL (61390)", value: "61390" },
  { label: "BURE (61170)", value: "61170" },
  { label: "BURES (61170)", value: "61170" },
  { label: "BURSARD (61500)", value: "61500" },
  { label: "CAHAN (61430)", value: "61430" },
  { label: "CALIGNY (61100)", value: "61100" },
  { label: "CAMEMBERT (61120)", value: "61120" },
  { label: "CANAPVILLE (61120)", value: "61120" },
  { label: "CARROUGES (61320)", value: "61320" },
  { label: "CEAUCE (61330)", value: "61330" },
  { label: "LE CERCUEIL (61500)", value: "61500" },
  { label: "CERISE (61000)", value: "61000" },
  { label: "CERISY BELLE ETOILE (61100)", value: "61100" },
  { label: "CETON (61260)", value: "61260" },
  { label: "CHAHAINS (61320)", value: "61320" },
  { label: "CHAILLOUE (61240)", value: "61240" },
  { label: "CHAILLOUE (61500)", value: "61500" },
  { label: "CHAILLOUE (61500)", value: "61500" },
  { label: "LE CHALANGE (61390)", value: "61390" },
  { label: "CHAMPCERIE (61210)", value: "61210" },
  { label: "LE CHAMP DE LA PIERRE (61320)", value: "61320" },
  { label: "LES CHAMPEAUX (61120)", value: "61120" },
  { label: "CHAMPEAUX SUR SARTHE (61560)", value: "61560" },
  { label: "CHAMP HAUT (61240)", value: "61240" },
  { label: "CHAMPOSOULT (61120)", value: "61120" },
  { label: "CHAMPSECRET (61700)", value: "61700" },
  { label: "CHANDAI (61300)", value: "61300" },
  { label: "CHANU (61800)", value: "61800" },
  { label: "LA CHAPELLE AU MOINE (61100)", value: "61100" },
  { label: "LA CHAPELLE BICHE (61100)", value: "61100" },
  { label: "RIVES D ANDAINE (61140)", value: "61140" },
  { label: "RIVES D ANDAINE (61140)", value: "61140" },
  { label: "RIVES D ANDAINE (61410)", value: "61410" },
  { label: "RIVES D ANDAINE (61410)", value: "61410" },
  { label: "LA CHAPELLE MONTLIGEON (61400)", value: "61400" },
  { label: "LA CHAPELLE PRES SEES (61500)", value: "61500" },
  { label: "LA CHAPELLE SOUEF (61130)", value: "61130" },
  { label: "LA CHAPELLE VIEL (61270)", value: "61270" },
  { label: "LE CHATEAU D ALMENECHES (61570)", value: "61570" },
  { label: "LE CHATELLIER (61450)", value: "61450" },
  { label: "CHAUMONT (61230)", value: "61230" },
  { label: "LA CHAUX (61600)", value: "61600" },
  { label: "CHEMILLI (61360)", value: "61360" },
  { label: "CIRAL (61320)", value: "61320" },
  { label: "CISAI ST AUBIN (61230)", value: "61230" },
  { label: "COLOMBIERS (61250)", value: "61250" },
  { label: "COMBLOT (61400)", value: "61400" },
  { label: "COMMEAUX (61200)", value: "61200" },
  { label: "SABLONS SUR HUISNE (61110)", value: "61110" },
  { label: "SABLONS SUR HUISNE (61110)", value: "61110" },
  { label: "SABLONS SUR HUISNE (61110)", value: "61110" },
  { label: "CONDE SUR SARTHE (61250)", value: "61250" },
  { label: "CORBON (61400)", value: "61400" },
  { label: "COUDEHARD (61160)", value: "61160" },
  { label: "COULIMER (61360)", value: "61360" },
  { label: "COULMER (61230)", value: "61230" },
  { label: "COULONCES (61160)", value: "61160" },
  { label: "LA COULONCHE (61220)", value: "61220" },
  { label: "COULONGES SUR SARTHE (61170)", value: "61170" },
  { label: "COURGEON (61400)", value: "61400" },
  { label: "COURGEOUT (61560)", value: "61560" },
  { label: "COURTOMER (61390)", value: "61390" },
  { label: "CRAMENIL (61220)", value: "61220" },
  { label: "CROISILLES (61230)", value: "61230" },
  { label: "CROUTTES (61120)", value: "61120" },
  { label: "CRULAI (61300)", value: "61300" },
  { label: "CUISSAI (61250)", value: "61250" },
  { label: "DAME MARIE (61130)", value: "61130" },
  { label: "DAMIGNY (61250)", value: "61250" },
  { label: "DOMFRONT EN POIRAIE (61700)", value: "61700" },
  { label: "DOMFRONT EN POIRAIE (61700)", value: "61700" },
  { label: "DOMFRONT EN POIRAIE (61700)", value: "61700" },
  { label: "DOMPIERRE (61700)", value: "61700" },
  { label: "DURCET (61100)", value: "61100" },
  { label: "ECHALOU (61440)", value: "61440" },
  { label: "ECHAUFFOUR (61370)", value: "61370" },
  { label: "ECORCEI (61270)", value: "61270" },
  { label: "ECORCHES (61160)", value: "61160" },
  { label: "ECOUCHE LES VALLEES (61150)", value: "61150" },
  { label: "ECOUCHE LES VALLEES (61150)", value: "61150" },
  { label: "ECOUCHE LES VALLEES (61150)", value: "61150" },
  { label: "ECOUCHE LES VALLEES (61150)", value: "61150" },
  { label: "ECOUCHE LES VALLEES (61150)", value: "61150" },
  { label: "ECOUCHE LES VALLEES (61150)", value: "61150" },
  { label: "ECOUCHE LES VALLEES (61200)", value: "61200" },
  { label: "ESSAY (61500)", value: "61500" },
  { label: "FAVEROLLES (61600)", value: "61600" },
  { label: "FAY (61390)", value: "61390" },
  { label: "FEINGS (61400)", value: "61400" },
  { label: "LA FERRIERE AU DOYEN (61380)", value: "61380" },
  { label: "LA FERRIERE AUX ETANGS (61450)", value: "61450" },
  { label: "LA FERRIERE BECHET (61500)", value: "61500" },
  { label: "LA FERRIERE BOCHARD (61420)", value: "61420" },
  { label: "FERRIERES LA VERRERIE (61390)", value: "61390" },
  { label: "LA FERTE EN OUCHE (61470)", value: "61470" },
  { label: "LA FERTE EN OUCHE (61470)", value: "61470" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE EN OUCHE (61550)", value: "61550" },
  { label: "LA FERTE MACE (61410)", value: "61410" },
  { label: "LA FERTE MACE (61600)", value: "61600" },
  { label: "FLERS (61100)", value: "61100" },
  { label: "FLEURE (61200)", value: "61200" },
  { label: "FONTAINE LES BASSETS (61160)", value: "61160" },
  { label: "FRANCHEVILLE (61570)", value: "61570" },
  { label: "LA FRESNAIE FAYEL (61230)", value: "61230" },
  { label: "FRESNAY LE SAMSON (61120)", value: "61120" },
  { label: "GACE (61230)", value: "61230" },
  { label: "GANDELAIN (61420)", value: "61420" },
  { label: "GAPREE (61390)", value: "61390" },
  { label: "LES GENETTES (61270)", value: "61270" },
  { label: "LA GENEVRAIE (61240)", value: "61240" },
  { label: "GIEL COURTEILLES (61210)", value: "61210" },
  { label: "GINAI (61310)", value: "61310" },
  { label: "GODISSON (61240)", value: "61240" },
  { label: "LA GONFRIERE (61550)", value: "61550" },
  { label: "MONTS SUR ORNE (61150)", value: "61150" },
  { label: "MONTS SUR ORNE (61150)", value: "61150" },
  { label: "MONTS SUR ORNE (61150)", value: "61150" },
  { label: "LE GRAIS (61600)", value: "61600" },
  { label: "BELFORET EN PERCHE (61130)", value: "61130" },
  { label: "BELFORET EN PERCHE (61130)", value: "61130" },
  { label: "BELFORET EN PERCHE (61130)", value: "61130" },
  { label: "BELFORET EN PERCHE (61130)", value: "61130" },
  { label: "BELFORET EN PERCHE (61360)", value: "61360" },
  { label: "BELFORET EN PERCHE (61400)", value: "61400" },
  { label: "GUEPREI (61160)", value: "61160" },
  { label: "GUERQUESALLES (61120)", value: "61120" },
  { label: "HABLOVILLE (61210)", value: "61210" },
  { label: "HAUTERIVE (61250)", value: "61250" },
  { label: "HELOUP (61250)", value: "61250" },
  { label: "L'HOME CHAMONDOT (61290)", value: "61290" },
  { label: "IGE (61130)", value: "61130" },
  { label: "IRAI (61190)", value: "61190" },
  { label: "JOUE DU BOIS (61320)", value: "61320" },
  { label: "JOUE DU PLAIN (61150)", value: "61150" },
  { label: "JUVIGNY VAL D ANDAINE (61140)", value: "61140" },
  { label: "JUVIGNY VAL D ANDAINE (61140)", value: "61140" },
  { label: "JUVIGNY VAL D ANDAINE (61330)", value: "61330" },
  { label: "JUVIGNY VAL D ANDAINE (61330)", value: "61330" },
  { label: "JUVIGNY VAL D ANDAINE (61330)", value: "61330" },
  { label: "JUVIGNY VAL D ANDAINE (61330)", value: "61330" },
  { label: "JUVIGNY VAL D ANDAINE (61330)", value: "61330" },
  { label: "JUVIGNY SUR ORNE (61200)", value: "61200" },
  { label: "LALACELLE (61320)", value: "61320" },
  { label: "L'AIGLE (61300)", value: "61300" },
  { label: "LALEU (61170)", value: "61170" },
  { label: "LA LANDE DE GOULT (61320)", value: "61320" },
  { label: "LA LANDE DE LOUGE (61210)", value: "61210" },
  { label: "LA LANDE PATRY (61100)", value: "61100" },
  { label: "LA LANDE ST SIMEON (61100)", value: "61100" },
  { label: "LANDIGOU (61100)", value: "61100" },
  { label: "LANDISACQ (61100)", value: "61100" },
  { label: "LARRE (61250)", value: "61250" },
  { label: "LIGNERES (61240)", value: "61240" },
  { label: "LIGNOU (61220)", value: "61220" },
  { label: "L'OREE D ECOUVES (61320)", value: "61320" },
  { label: "L'OREE D ECOUVES (61320)", value: "61320" },
  { label: "L'OREE D ECOUVES (61420)", value: "61420" },
  { label: "L'OREE D ECOUVES (61420)", value: "61420" },
  { label: "LOISAIL (61400)", value: "61400" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONGNY LES VILLAGES (61290)", value: "61290" },
  { label: "LONLAY L ABBAYE (61700)", value: "61700" },
  { label: "LONLAY LE TESSON (61600)", value: "61600" },
  { label: "LONRAI (61250)", value: "61250" },
  { label: "LOUGE SUR MAIRE (61150)", value: "61150" },
  { label: "LOUVIERES EN AUGE (61160)", value: "61160" },
  { label: "MACE (61500)", value: "61500" },
  { label: "LA MADELEINE BOUVET (61110)", value: "61110" },
  { label: "LE MAGE (61290)", value: "61290" },
  { label: "MAGNY LE DESERT (61600)", value: "61600" },
  { label: "MAHERU (61380)", value: "61380" },
  { label: "MANTILLY (61350)", value: "61350" },
  { label: "MARCHEMAISONS (61170)", value: "61170" },
  { label: "MARDILLY (61230)", value: "61230" },
  { label: "MAUVES SUR HUISNE (61400)", value: "61400" },
  { label: "MEDAVY (61570)", value: "61570" },
  { label: "MEHOUDIN (61410)", value: "61410" },
  { label: "LE MELE SUR SARTHE (61170)", value: "61170" },
  { label: "LE MENIL BERARD (61270)", value: "61270" },
  { label: "LE MENIL DE BRIOUZE (61220)", value: "61220" },
  { label: "LE MENIL BROUT (61250)", value: "61250" },
  { label: "LE MENIL CIBOULT (61800)", value: "61800" },
  { label: "MENIL ERREUX (61250)", value: "61250" },
  { label: "MENIL FROGER (61240)", value: "61240" },
  { label: "MENIL GONDOUIN (61210)", value: "61210" },
  { label: "LE MENIL GUYON (61170)", value: "61170" },
  { label: "MENIL HERMEI (61210)", value: "61210" },
  { label: "MENIL HUBERT EN EXMES (61230)", value: "61230" },
  { label: "MENIL HUBERT SUR ORNE (61430)", value: "61430" },
  { label: "LE MENIL SCELLEUR (61320)", value: "61320" },
  { label: "LE MENIL VICOMTE (61240)", value: "61240" },
  { label: "MENIL VIN (61210)", value: "61210" },
  { label: "LES MENUS (61290)", value: "61290" },
  { label: "LE MERLERAULT (61240)", value: "61240" },
  { label: "MERRI (61160)", value: "61160" },
  { label: "LA MESNIERE (61560)", value: "61560" },
  { label: "MESSEI (61440)", value: "61440" },
  { label: "MIEUXCE (61250)", value: "61250" },
  { label: "MONCY (61800)", value: "61800" },
  { label: "MONTABARD (61160)", value: "61160" },
  { label: "MONTCHEVREL (61170)", value: "61170" },
  { label: "MONTGAUDRY (61360)", value: "61360" },
  { label: "MONTILLY SUR NOIREAU (61100)", value: "61100" },
  { label: "MONTMERREI (61570)", value: "61570" },
  { label: "MONT ORMEL (61160)", value: "61160" },
  { label: "MONTREUIL AU HOULME (61210)", value: "61210" },
  { label: "MONTREUIL LA CAMBE (61160)", value: "61160" },
  { label: "MONTSECRET CLAIREFOUGERE (61800)", value: "61800" },
  { label: "MONTSECRET CLAIREFOUGERE (61800)", value: "61800" },
  { label: "MORTAGNE AU PERCHE (61400)", value: "61400" },
  { label: "MORTREE (61500)", value: "61500" },
  { label: "MORTREE (61570)", value: "61570" },
  { label: "LA MOTTE FOUQUET (61600)", value: "61600" },
  { label: "MOULINS LA MARCHE (61380)", value: "61380" },
  { label: "MOULINS SUR ORNE (61200)", value: "61200" },
  { label: "MOUTIERS AU PERCHE (61110)", value: "61110" },
  { label: "NEAUPHE SOUS ESSAI (61500)", value: "61500" },
  { label: "NEAUPHE SUR DIVE (61160)", value: "61160" },
  { label: "NECY (61160)", value: "61160" },
  { label: "NEUILLY LE BISSON (61250)", value: "61250" },
  { label: "NEUVILLE SUR TOUQUES (61120)", value: "61120" },
  { label: "NEUVY AU HOULME (61210)", value: "61210" },
  { label: "PERCHE EN NOCE (61340)", value: "61340" },
  { label: "PERCHE EN NOCE (61340)", value: "61340" },
  { label: "PERCHE EN NOCE (61340)", value: "61340" },
  { label: "PERCHE EN NOCE (61340)", value: "61340" },
  { label: "PERCHE EN NOCE (61340)", value: "61340" },
  { label: "PERCHE EN NOCE (61340)", value: "61340" },
  { label: "NONANT LE PIN (61240)", value: "61240" },
  { label: "OCCAGNES (61200)", value: "61200" },
  { label: "OMMOY (61160)", value: "61160" },
  { label: "ORGERES (61230)", value: "61230" },
  { label: "ORIGNY LE ROUX (61130)", value: "61130" },
  { label: "PACE (61250)", value: "61250" },
  { label: "PARFONDEVAL (61400)", value: "61400" },
  { label: "LE PAS ST L HOMER (61290)", value: "61290" },
  { label: "PASSAIS VILLAGES (61350)", value: "61350" },
  { label: "PASSAIS VILLAGES (61350)", value: "61350" },
  { label: "PASSAIS VILLAGES (61350)", value: "61350" },
  { label: "PERROU (61700)", value: "61700" },
  { label: "PERVENCHERES (61360)", value: "61360" },
  { label: "LE PIN AU HARAS (61310)", value: "61310" },
  { label: "LE PIN LA GARENNE (61400)", value: "61400" },
  { label: "PLANCHES (61370)", value: "61370" },
  { label: "LE PLANTIS (61170)", value: "61170" },
  { label: "POINTEL (61220)", value: "61220" },
  { label: "PONTCHARDON (61120)", value: "61120" },
  { label: "POUVRAI (61130)", value: "61130" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "PUTANGES LE LAC (61210)", value: "61210" },
  { label: "ECOUVES (61250)", value: "61250" },
  { label: "ECOUVES (61250)", value: "61250" },
  { label: "ECOUVES (61250)", value: "61250" },
  { label: "RAI (61270)", value: "61270" },
  { label: "RANES (61150)", value: "61150" },
  { label: "REMALARD EN PERCHE (61110)", value: "61110" },
  { label: "REMALARD EN PERCHE (61110)", value: "61110" },
  { label: "REMALARD EN PERCHE (61110)", value: "61110" },
  { label: "LE RENOUARD (61120)", value: "61120" },
  { label: "RESENLIEU (61230)", value: "61230" },
  { label: "REVEILLON (61400)", value: "61400" },
  { label: "RI (61210)", value: "61210" },
  { label: "LA ROCHE MABILE (61420)", value: "61420" },
  { label: "ROIVILLE (61120)", value: "61120" },
  { label: "RONAI (61160)", value: "61160" },
  { label: "ROUPERROUX (61320)", value: "61320" },
  { label: "SAI (61200)", value: "61200" },
  { label: "ST AGNAN SUR SARTHE (61170)", value: "61170" },
  { label: "ST ANDRE DE BRIOUZE (61220)", value: "61220" },
  { label: "ST ANDRE DE MESSEI (61440)", value: "61440" },
  { label: "ST AQUILIN DE CORBION (61380)", value: "61380" },
  { label: "ST AUBIN D APPENAI (61170)", value: "61170" },
  { label: "ST AUBIN DE BONNEVAL (61470)", value: "61470" },
  { label: "ST AUBIN DE COURTERAIE (61560)", value: "61560" },
  { label: "ST BOMER LES FORGES (61700)", value: "61700" },
  { label: "ST BRICE (61700)", value: "61700" },
  { label: "ST BRICE SOUS RANES (61150)", value: "61150" },
  { label: "ST CENERI LE GEREI (61250)", value: "61250" },
  { label: "STE CERONNE LES MORTAGNE (61380)", value: "61380" },
  { label: "ST CHRISTOPHE DE CHAULIEU (61800)", value: "61800" },
  { label: "BOISCHAMPRE (61570)", value: "61570" },
  { label: "BOISCHAMPRE (61570)", value: "61570" },
  { label: "BOISCHAMPRE (61570)", value: "61570" },
  { label: "BOISCHAMPRE (61570)", value: "61570" },
  { label: "ST CLAIR DE HALOUZE (61490)", value: "61490" },
  { label: "ST CYR LA ROSIERE (61130)", value: "61130" },
  { label: "ST DENIS SUR HUISNE (61400)", value: "61400" },
  { label: "ST DENIS SUR SARTHON (61420)", value: "61420" },
  { label: "ST ELLIER LES BOIS (61320)", value: "61320" },
  { label: "ST EVROULT DE MONTFORT (61230)", value: "61230" },
  { label: "ST EVROULT NOTRE DAME DU BOIS (61550)", value: "61550" },
  { label: "ST FRAIMBAULT (61350)", value: "61350" },
  { label: "ST FULGENT DES ORMES (61130)", value: "61130" },
  { label: "STE GAUBURGE STE COLOMBE (61370)", value: "61370" },
  { label: "ST GEORGES D ANNEBECQ (61600)", value: "61600" },
  { label: "ST GEORGES DES GROSEILLERS (61100)", value: "61100" },
  { label: "ST GERMAIN D AUNAY (61470)", value: "61470" },
  { label: "ST GERMAIN DE CLAIREFEUILLE (61240)", value: "61240" },
  { label: "ST GERMAIN DE LA COUDRE (61130)", value: "61130" },
  { label: "ST GERMAIN DES GROIS (61110)", value: "61110" },
  { label: "ST GERMAIN DE MARTIGNY (61560)", value: "61560" },
  { label: "ST GERMAIN DU CORBEIS (61000)", value: "61000" },
  { label: "ST GERMAIN LE VIEUX (61390)", value: "61390" },
  { label: "ST GERVAIS DES SABLONS (61160)", value: "61160" },
  { label: "ST GERVAIS DU PERRON (61500)", value: "61500" },
  { label: "ST GILLES DES MARAIS (61700)", value: "61700" },
  { label: "ST HILAIRE DE BRIOUZE (61220)", value: "61220" },
  { label: "ST HILAIRE LE CHATEL (61400)", value: "61400" },
  { label: "ST HILAIRE SUR ERRE (61340)", value: "61340" },
  { label: "ST HILAIRE SUR RISLE (61270)", value: "61270" },
  { label: "STE HONORINE LA CHARDONNE (61430)", value: "61430" },
  { label: "STE HONORINE LA GUILLAUME (61210)", value: "61210" },
  { label: "ST JOUIN DE BLAVOU (61360)", value: "61360" },
  { label: "ST JULIEN SUR SARTHE (61170)", value: "61170" },
  { label: "ST LAMBERT SUR DIVE (61160)", value: "61160" },
  { label: "ST LANGIS LES MORTAGNE (61400)", value: "61400" },
  { label: "ST LEGER SUR SARTHE (61170)", value: "61170" },
  { label: "ST LEONARD DES PARCS (61390)", value: "61390" },
  { label: "ST MARD DE RENO (61400)", value: "61400" },
  { label: "STE MARGUERITE DE CARROUGES (61320)", value: "61320" },
  { label: "STE MARIE LA ROBERT (61320)", value: "61320" },
  { label: "ST MARS D EGRENNE (61350)", value: "61350" },
  { label: "LES ASPRES (61270)", value: "61270" },
  { label: "ST MARTIN D ECUBLEI (61300)", value: "61300" },
  { label: "ST MARTIN DES LANDES (61320)", value: "61320" },
  { label: "ST MARTIN DES PEZERITS (61380)", value: "61380" },
  { label: "ST MARTIN DU VIEUX BELLEME (61130)", value: "61130" },
  { label: "ST MARTIN L AIGUILLON (61320)", value: "61320" },
  { label: "CHARENCEY (61190)", value: "61190" },
  { label: "CHARENCEY (61190)", value: "61190" },
  { label: "CHARENCEY (61190)", value: "61190" },
  { label: "ST MICHEL TUBOEUF (61300)", value: "61300" },
  { label: "ST NICOLAS DES BOIS (61250)", value: "61250" },
  { label: "ST NICOLAS DE SOMMAIRE (61550)", value: "61550" },
  { label: "STE OPPORTUNE (61100)", value: "61100" },
  { label: "ST OUEN DE SECHEROUVRE (61560)", value: "61560" },
  { label: "ST OUEN LE BRISOULT (61410)", value: "61410" },
  { label: "ST OUEN SUR ITON (61300)", value: "61300" },
  { label: "ST PATRICE DU DESERT (61600)", value: "61600" },
  { label: "ST PAUL (61100)", value: "61100" },
  { label: "ST PHILBERT SUR ORNE (61430)", value: "61430" },
  { label: "ST PIERRE D ENTREMONT (61800)", value: "61800" },
  { label: "ST PIERRE DES LOGES (61370)", value: "61370" },
  { label: "ST PIERRE DU REGARD (61790)", value: "61790" },
  { label: "ST PIERRE LA BRUYERE (61340)", value: "61340" },
  { label: "ST QUENTIN DE BLAVOU (61360)", value: "61360" },
  { label: "ST QUENTIN LES CHARDONNETS (61800)", value: "61800" },
  { label: "ST ROCH SUR EGRENNE (61350)", value: "61350" },
  { label: "ST SAUVEUR DE CARROUGES (61320)", value: "61320" },
  { label: "STE SCOLASSE SUR SARTHE (61170)", value: "61170" },
  { label: "ST SULPICE SUR RISLE (61300)", value: "61300" },
  { label: "ST SYMPHORIEN DES BRUYERES (61300)", value: "61300" },
  { label: "SAIRES LA VERRERIE (61220)", value: "61220" },
  { label: "SAP EN AUGE (61120)", value: "61120" },
  { label: "SAP EN AUGE (61470)", value: "61470" },
  { label: "LE SAP ANDRE (61230)", value: "61230" },
  { label: "SARCEAUX (61200)", value: "61200" },
  { label: "LES MONTS D ANDAINE (61600)", value: "61600" },
  { label: "LES MONTS D ANDAINE (61600)", value: "61600" },
  { label: "SEES (61500)", value: "61500" },
  { label: "LA SELLE LA FORGE (61100)", value: "61100" },
  { label: "SEMALLE (61250)", value: "61250" },
  { label: "SEVIGNY (61200)", value: "61200" },
  { label: "SEVRAI (61150)", value: "61150" },
  { label: "GOUFFERN EN AUGE (61160)", value: "61160" },
  { label: "GOUFFERN EN AUGE (61160)", value: "61160" },
  { label: "GOUFFERN EN AUGE (61160)", value: "61160" },
  { label: "GOUFFERN EN AUGE (61160)", value: "61160" },
  { label: "GOUFFERN EN AUGE (61200)", value: "61200" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "GOUFFERN EN AUGE (61310)", value: "61310" },
  { label: "SOLIGNY LA TRAPPE (61380)", value: "61380" },
  { label: "SURE (61360)", value: "61360" },
  { label: "TANQUES (61150)", value: "61150" },
  { label: "TANVILLE (61500)", value: "61500" },
  { label: "TELLIERES LE PLESSIS (61390)", value: "61390" },
  { label: "TESSE FROULAY (61410)", value: "61410" },
  { label: "BAGNOLES DE L ORNE NORMANDIE (61140)", value: "61140" },
  { label: "BAGNOLES DE L ORNE NORMANDIE (61600)", value: "61600" },
  { label: "VAL AU PERCHE (61130)", value: "61130" },
  { label: "VAL AU PERCHE (61260)", value: "61260" },
  { label: "VAL AU PERCHE (61260)", value: "61260" },
  { label: "VAL AU PERCHE (61260)", value: "61260" },
  { label: "VAL AU PERCHE (61260)", value: "61260" },
  { label: "VAL AU PERCHE (61340)", value: "61340" },
  { label: "TICHEVILLE (61120)", value: "61120" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TINCHEBRAY BOCAGE (61800)", value: "61800" },
  { label: "TORCHAMP (61330)", value: "61330" },
  { label: "TOUQUETTES (61550)", value: "61550" },
  { label: "TOURNAI SUR DIVE (61160)", value: "61160" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TOUROUVRE AU PERCHE (61190)", value: "61190" },
  { label: "TREMONT (61390)", value: "61390" },
  { label: "LA TRINITE DES LAITIERS (61230)", value: "61230" },
  { label: "TRUN (61160)", value: "61160" },
  { label: "VALFRAMBERT (61250)", value: "61250" },
  { label: "VAUNOISE (61130)", value: "61130" },
  { label: "LES VENTES DE BOURSE (61170)", value: "61170" },
  { label: "LA VENTROUZE (61190)", value: "61190" },
  { label: "VERRIERES (61110)", value: "61110" },
  { label: "VIDAI (61360)", value: "61360" },
  { label: "VIEUX PONT (61150)", value: "61150" },
  { label: "VILLEDIEU LES BAILLEUL (61160)", value: "61160" },
  { label: "VILLIERS SOUS MORTAGNE (61400)", value: "61400" },
  { label: "VIMOUTIERS (61120)", value: "61120" },
  { label: "VITRAI SOUS LAIGLE (61300)", value: "61300" },
  { label: "LES YVETEAUX (61210)", value: "61210" },
  { label: "ABLAIN ST NAZAIRE (62153)", value: "62153" },
  { label: "ABLAINZEVELLE (62116)", value: "62116" },
  { label: "ACHEVILLE (62320)", value: "62320" },
  { label: "ACHICOURT (62217)", value: "62217" },
  { label: "ACHIET LE GRAND (62121)", value: "62121" },
  { label: "ACHIET LE PETIT (62121)", value: "62121" },
  { label: "ACQ (62144)", value: "62144" },
  { label: "ACQUIN WESTBECOURT (62380)", value: "62380" },
  { label: "ACQUIN WESTBECOURT (62380)", value: "62380" },
  { label: "ADINFER (62116)", value: "62116" },
  { label: "AFFRINGUES (62380)", value: "62380" },
  { label: "AGNEZ LES DUISANS (62161)", value: "62161" },
  { label: "AGNIERES (62690)", value: "62690" },
  { label: "AGNY (62217)", value: "62217" },
  { label: "AIRE SUR LA LYS (62120)", value: "62120" },
  { label: "AIRON NOTRE DAME (62180)", value: "62180" },
  { label: "AIRON ST VAAST (62180)", value: "62180" },
  { label: "AIX EN ERGNY (62650)", value: "62650" },
  { label: "AIX EN ISSART (62170)", value: "62170" },
  { label: "AIX NOULETTE (62160)", value: "62160" },
  { label: "ALEMBON (62850)", value: "62850" },
  { label: "ALETTE (62650)", value: "62650" },
  { label: "ALINCTHUN (62142)", value: "62142" },
  { label: "ALLOUAGNE (62157)", value: "62157" },
  { label: "ALQUINES (62850)", value: "62850" },
  { label: "AMBLETEUSE (62164)", value: "62164" },
  { label: "AMBRICOURT (62310)", value: "62310" },
  { label: "AMBRINES (62127)", value: "62127" },
  { label: "AMES (62190)", value: "62190" },
  { label: "AMETTES (62260)", value: "62260" },
  { label: "AMPLIER (62760)", value: "62760" },
  { label: "ANDRES (62340)", value: "62340" },
  { label: "ANGRES (62143)", value: "62143" },
  { label: "ANNAY (62880)", value: "62880" },
  { label: "ANNEQUIN (62149)", value: "62149" },
  { label: "ANNEZIN (62232)", value: "62232" },
  { label: "ANVIN (62134)", value: "62134" },
  { label: "ANZIN ST AUBIN (62223)", value: "62223" },
  { label: "ARDRES (62610)", value: "62610" },
  { label: "ARDRES (62610)", value: "62610" },
  { label: "ARDRES (62610)", value: "62610" },
  { label: "ARLEUX EN GOHELLE (62580)", value: "62580" },
  { label: "ARQUES (62510)", value: "62510" },
  { label: "ARRAS (62000)", value: "62000" },
  { label: "ATHIES (62223)", value: "62223" },
  { label: "LES ATTAQUES (62730)", value: "62730" },
  { label: "ATTIN (62170)", value: "62170" },
  { label: "AUBIGNY EN ARTOIS (62690)", value: "62690" },
  { label: "AUBIN ST VAAST (62140)", value: "62140" },
  { label: "AUBROMETZ (62390)", value: "62390" },
  { label: "AUCHEL (62260)", value: "62260" },
  { label: "AUCHY AU BOIS (62190)", value: "62190" },
  { label: "AUCHY LES HESDIN (62770)", value: "62770" },
  { label: "AUCHY LES MINES (62138)", value: "62138" },
  { label: "AUDEMBERT (62250)", value: "62250" },
  { label: "AUDINCTHUN (62560)", value: "62560" },
  { label: "AUDINGHEN (62179)", value: "62179" },
  { label: "AUDREHEM (62890)", value: "62890" },
  { label: "AUDRESSELLES (62164)", value: "62164" },
  { label: "AUDRUICQ (62370)", value: "62370" },
  { label: "AUMERVAL (62550)", value: "62550" },
  { label: "AUTINGUES (62610)", value: "62610" },
  { label: "AUXI LE CHATEAU (62390)", value: "62390" },
  { label: "AVERDOINGT (62127)", value: "62127" },
  { label: "AVESNES (62650)", value: "62650" },
  { label: "AVESNES LE COMTE (62810)", value: "62810" },
  { label: "AVESNES LES BAPAUME (62450)", value: "62450" },
  { label: "AVION (62210)", value: "62210" },
  { label: "AVONDANCE (62310)", value: "62310" },
  { label: "AVROULT (62560)", value: "62560" },
  { label: "AYETTE (62116)", value: "62116" },
  { label: "AZINCOURT (62310)", value: "62310" },
  { label: "BAILLEUL AUX CORNAILLES (62127)", value: "62127" },
  { label: "BAILLEUL LES PERNES (62550)", value: "62550" },
  { label: "BAILLEULMONT (62123)", value: "62123" },
  { label: "BAILLEUL SIR BERTHOULT (62580)", value: "62580" },
  { label: "BAILLEULVAL (62123)", value: "62123" },
  { label: "BAINCTHUN (62360)", value: "62360" },
  { label: "BAINGHEN (62850)", value: "62850" },
  { label: "BAJUS (62150)", value: "62150" },
  { label: "BALINGHEM (62610)", value: "62610" },
  { label: "BANCOURT (62450)", value: "62450" },
  { label: "BAPAUME (62450)", value: "62450" },
  { label: "BARALLE (62860)", value: "62860" },
  { label: "BARASTRE (62124)", value: "62124" },
  { label: "BARLIN (62620)", value: "62620" },
  { label: "BARLY (62810)", value: "62810" },
  { label: "BASSEUX (62123)", value: "62123" },
  { label: "BAVINCOURT (62158)", value: "62158" },
  { label: "BAVINCOURT (62158)", value: "62158" },
  { label: "BAYENGHEM LES EPERLECQUES (62910)", value: "62910" },
  { label: "BAYENGHEM LES SENINGHEM (62380)", value: "62380" },
  { label: "BAZINGHEN (62250)", value: "62250" },
  { label: "BEALENCOURT (62770)", value: "62770" },
  { label: "BEAUDRICOURT (62810)", value: "62810" },
  { label: "BEAUFORT BLAVINCOURT (62810)", value: "62810" },
  { label: "BEAULENCOURT (62450)", value: "62450" },
  { label: "BEAUMERIE ST MARTIN (62170)", value: "62170" },
  { label: "BEAUMETZ LES AIRE (62960)", value: "62960" },
  { label: "BEAUMETZ LES CAMBRAI (62124)", value: "62124" },
  { label: "BEAUMETZ LES LOGES (62123)", value: "62123" },
  { label: "BEAURAINS (62217)", value: "62217" },
  { label: "BEAURAINVILLE (62990)", value: "62990" },
  { label: "BEAUVOIS (62130)", value: "62130" },
  { label: "BECOURT (62240)", value: "62240" },
  { label: "BEHAGNIES (62121)", value: "62121" },
  { label: "BELLEBRUNE (62142)", value: "62142" },
  { label: "BELLE ET HOULLEFORT (62142)", value: "62142" },
  { label: "BELLONNE (62490)", value: "62490" },
  { label: "BENIFONTAINE (62410)", value: "62410" },
  { label: "BERCK (62600)", value: "62600" },
  { label: "BERGUENEUSE (62134)", value: "62134" },
  { label: "BERLENCOURT LE CAUROY (62810)", value: "62810" },
  { label: "BERLES AU BOIS (62123)", value: "62123" },
  { label: "BERLES MONCHEL (62690)", value: "62690" },
  { label: "BERMICOURT (62130)", value: "62130" },
  { label: "BERNEVILLE (62123)", value: "62123" },
  { label: "BERNIEULLES (62170)", value: "62170" },
  { label: "BERTINCOURT (62124)", value: "62124" },
  { label: "BETHONSART (62690)", value: "62690" },
  { label: "BETHUNE (62400)", value: "62400" },
  { label: "BEUGIN (62150)", value: "62150" },
  { label: "BEUGNATRE (62450)", value: "62450" },
  { label: "BEUGNY (62124)", value: "62124" },
  { label: "BEUSSENT (62170)", value: "62170" },
  { label: "BEUTIN (62170)", value: "62170" },
  { label: "BEUVREQUEN (62250)", value: "62250" },
  { label: "BEUVRY (62660)", value: "62660" },
  { label: "BEZINGHEM (62650)", value: "62650" },
  { label: "BIACHE ST VAAST (62118)", value: "62118" },
  { label: "BIEFVILLERS LES BAPAUME (62450)", value: "62450" },
  { label: "BIENVILLERS AU BOIS (62111)", value: "62111" },
  { label: "BIHUCOURT (62121)", value: "62121" },
  { label: "BILLY BERCLAU (62138)", value: "62138" },
  { label: "BILLY MONTIGNY (62420)", value: "62420" },
  { label: "BIMONT (62650)", value: "62650" },
  { label: "BLAIRVILLE (62173)", value: "62173" },
  { label: "BLANGERVAL BLANGERMONT (62270)", value: "62270" },
  { label: "BLANGERVAL BLANGERMONT (62270)", value: "62270" },
  { label: "BLANGY SUR TERNOISE (62770)", value: "62770" },
  { label: "BLENDECQUES (62575)", value: "62575" },
  { label: "BLEQUIN (62380)", value: "62380" },
  { label: "BLESSY (62120)", value: "62120" },
  { label: "BLINGEL (62770)", value: "62770" },
  { label: "BOFFLES (62390)", value: "62390" },
  { label: "BOIRY BECQUERELLE (62128)", value: "62128" },
  { label: "BOIRY NOTRE DAME (62156)", value: "62156" },
  { label: "BOIRY ST MARTIN (62175)", value: "62175" },
  { label: "BOIRY STE RICTRUDE (62175)", value: "62175" },
  { label: "BOIS BERNARD (62320)", value: "62320" },
  { label: "BOISDINGHEM (62500)", value: "62500" },
  { label: "BOISJEAN (62170)", value: "62170" },
  { label: "BOISLEUX AU MONT (62175)", value: "62175" },
  { label: "BOISLEUX ST MARC (62175)", value: "62175" },
  { label: "BOMY (62960)", value: "62960" },
  { label: "BONNIERES (62270)", value: "62270" },
  { label: "BONNIERES (62270)", value: "62270" },
  { label: "BONNINGUES LES ARDRES (62890)", value: "62890" },
  { label: "BONNINGUES LES CALAIS (62340)", value: "62340" },
  { label: "BOUBERS LES HESMOND (62990)", value: "62990" },
  { label: "BOUBERS SUR CANCHE (62270)", value: "62270" },
  { label: "BOULOGNE SUR MER (62200)", value: "62200" },
  { label: "BOUQUEHAULT (62340)", value: "62340" },
  { label: "BOURECQ (62190)", value: "62190" },
  { label: "BOURET SUR CANCHE (62270)", value: "62270" },
  { label: "BOURLON (62860)", value: "62860" },
  { label: "BOURNONVILLE (62240)", value: "62240" },
  { label: "BOURS (62550)", value: "62550" },
  { label: "BOURSIN (62132)", value: "62132" },
  { label: "BOURTHES (62650)", value: "62650" },
  { label: "BOUVELINGHEM (62380)", value: "62380" },
  { label: "BOUVIGNY BOYEFFLES (62172)", value: "62172" },
  { label: "BOYAVAL (62134)", value: "62134" },
  { label: "BOYELLES (62128)", value: "62128" },
  { label: "BREBIERES (62117)", value: "62117" },
  { label: "BREMES (62610)", value: "62610" },
  { label: "BREVILLERS (62140)", value: "62140" },
  { label: "BREXENT ENOCQ (62170)", value: "62170" },
  { label: "BRIMEUX (62170)", value: "62170" },
  { label: "BRUAY LA BUISSIERE (62700)", value: "62700" },
  { label: "BRUAY LA BUISSIERE (62700)", value: "62700" },
  { label: "BRUNEMBERT (62240)", value: "62240" },
  { label: "BRIAS (62130)", value: "62130" },
  { label: "BUCQUOY (62116)", value: "62116" },
  { label: "BUIRE AU BOIS (62390)", value: "62390" },
  { label: "BUIRE LE SEC (62870)", value: "62870" },
  { label: "BUISSY (62860)", value: "62860" },
  { label: "BULLECOURT (62128)", value: "62128" },
  { label: "BULLY LES MINES (62160)", value: "62160" },
  { label: "BUNEVILLE (62130)", value: "62130" },
  { label: "BURBURE (62151)", value: "62151" },
  { label: "BUS (62124)", value: "62124" },
  { label: "BUSNES (62350)", value: "62350" },
  { label: "CAFFIERS (62132)", value: "62132" },
  { label: "CAGNICOURT (62182)", value: "62182" },
  { label: "CALAIS (62100)", value: "62100" },
  { label: "CALONNE RICOUART (62470)", value: "62470" },
  { label: "CALONNE SUR LA LYS (62350)", value: "62350" },
  { label: "LA CALOTTERIE (62170)", value: "62170" },
  { label: "CAMBLAIN CHATELAIN (62470)", value: "62470" },
  { label: "CAMBLIGNEUL (62690)", value: "62690" },
  { label: "CAMBLAIN L ABBE (62690)", value: "62690" },
  { label: "CAMBRIN (62149)", value: "62149" },
  { label: "CAMIERS (62176)", value: "62176" },
  { label: "CAMIERS (62176)", value: "62176" },
  { label: "CAMPAGNE LES BOULONNAIS (62650)", value: "62650" },
  { label: "CAMPAGNE LES GUINES (62340)", value: "62340" },
  { label: "CAMPAGNE LES HESDIN (62870)", value: "62870" },
  { label: "CAMPAGNE LES WARDRECQUES (62120)", value: "62120" },
  { label: "CAMPIGNEULLES LES GRANDES (62170)", value: "62170" },
  { label: "CAMPIGNEULLES LES PETITES (62170)", value: "62170" },
  { label: "CANETTEMONT (62270)", value: "62270" },
  { label: "CANLERS (62310)", value: "62310" },
  { label: "CAPELLE FERMONT (62690)", value: "62690" },
  { label: "CAPELLE LES HESDIN (62140)", value: "62140" },
  { label: "CARENCY (62144)", value: "62144" },
  { label: "CARLY (62830)", value: "62830" },
  { label: "CARVIN (62220)", value: "62220" },
  { label: "LA CAUCHIE (62158)", value: "62158" },
  { label: "CAUCHY A LA TOUR (62260)", value: "62260" },
  { label: "CAUCOURT (62150)", value: "62150" },
  { label: "CAUMONT (62140)", value: "62140" },
  { label: "CAVRON ST MARTIN (62140)", value: "62140" },
  { label: "CHELERS (62127)", value: "62127" },
  { label: "CHERIENNES (62140)", value: "62140" },
  { label: "CHERISY (62128)", value: "62128" },
  { label: "CHOCQUES (62920)", value: "62920" },
  { label: "CLAIRMARAIS (62500)", value: "62500" },
  { label: "CLENLEU (62650)", value: "62650" },
  { label: "CLERQUES (62890)", value: "62890" },
  { label: "CLETY (62380)", value: "62380" },
  { label: "COLEMBERT (62142)", value: "62142" },
  { label: "COLLINE BEAUMONT (62180)", value: "62180" },
  { label: "LA COMTE (62150)", value: "62150" },
  { label: "CONCHIL LE TEMPLE (62180)", value: "62180" },
  { label: "CONCHY SUR CANCHE (62270)", value: "62270" },
  { label: "CONDETTE (62360)", value: "62360" },
  { label: "CONTES (62990)", value: "62990" },
  { label: "CONTEVILLE LES BOULOGNE (62126)", value: "62126" },
  { label: "CONTEVILLE EN TERNOIS (62130)", value: "62130" },
  { label: "COQUELLES (62231)", value: "62231" },
  { label: "CORBEHEM (62112)", value: "62112" },
  { label: "CORMONT (62630)", value: "62630" },
  { label: "COUIN (62760)", value: "62760" },
  { label: "COULLEMONT (62158)", value: "62158" },
  { label: "COULOGNE (62137)", value: "62137" },
  { label: "COULOMBY (62380)", value: "62380" },
  { label: "COUPELLE NEUVE (62310)", value: "62310" },
  { label: "COUPELLE VIEILLE (62310)", value: "62310" },
  { label: "COURCELLES LE COMTE (62121)", value: "62121" },
  { label: "COURCELLES LES LENS (62970)", value: "62970" },
  { label: "COURRIERES (62710)", value: "62710" },
  { label: "COURSET (62240)", value: "62240" },
  { label: "LA COUTURE (62136)", value: "62136" },
  { label: "COUTURELLE (62158)", value: "62158" },
  { label: "COYECQUES (62560)", value: "62560" },
  { label: "CREMAREST (62240)", value: "62240" },
  { label: "CREPY (62310)", value: "62310" },
  { label: "CREQUY (62310)", value: "62310" },
  { label: "CROISETTE (62130)", value: "62130" },
  { label: "CROISILLES (62128)", value: "62128" },
  { label: "CROIX EN TERNOIS (62130)", value: "62130" },
  { label: "CUCQ (62780)", value: "62780" },
  { label: "CUCQ (62780)", value: "62780" },
  { label: "CUCQ (62780)", value: "62780" },
  { label: "CUINCHY (62149)", value: "62149" },
  { label: "DAINVILLE (62000)", value: "62000" },
  { label: "DANNES (62187)", value: "62187" },
  { label: "DELETTES (62129)", value: "62129" },
  { label: "DENIER (62810)", value: "62810" },
  { label: "DENNEBROEUCQ (62560)", value: "62560" },
  { label: "DESVRES (62240)", value: "62240" },
  { label: "DIEVAL (62460)", value: "62460" },
  { label: "DIVION (62460)", value: "62460" },
  { label: "DOHEM (62380)", value: "62380" },
  { label: "DOUCHY LES AYETTE (62116)", value: "62116" },
  { label: "DOUDEAUVILLE (62830)", value: "62830" },
  { label: "DOURGES (62119)", value: "62119" },
  { label: "DOURIEZ (62870)", value: "62870" },
  { label: "DOUVRIN (62138)", value: "62138" },
  { label: "DROCOURT (62320)", value: "62320" },
  { label: "DROUVIN LE MARAIS (62131)", value: "62131" },
  { label: "DUISANS (62161)", value: "62161" },
  { label: "DURY (62156)", value: "62156" },
  { label: "ECHINGHEN (62360)", value: "62360" },
  { label: "ECLIMEUX (62770)", value: "62770" },
  { label: "ECOIVRES (62270)", value: "62270" },
  { label: "ECOURT ST QUENTIN (62860)", value: "62860" },
  { label: "ECOUST ST MEIN (62128)", value: "62128" },
  { label: "ECQUEDECQUES (62190)", value: "62190" },
  { label: "ECQUES (62129)", value: "62129" },
  { label: "ECUIRES (62170)", value: "62170" },
  { label: "ECURIE (62223)", value: "62223" },
  { label: "ELEU DIT LEAUWETTE (62300)", value: "62300" },
  { label: "ELNES (62380)", value: "62380" },
  { label: "EMBRY (62990)", value: "62990" },
  { label: "ENQUIN LEZ GUINEGATTE (62145)", value: "62145" },
  { label: "ENQUIN LEZ GUINEGATTE (62145)", value: "62145" },
  { label: "ENQUIN SUR BAILLONS (62650)", value: "62650" },
  { label: "EPERLECQUES (62910)", value: "62910" },
  { label: "EPINOY (62860)", value: "62860" },
  { label: "EPS (62134)", value: "62134" },
  { label: "EQUIHEN PLAGE (62224)", value: "62224" },
  { label: "EQUIRRE (62134)", value: "62134" },
  { label: "ERGNY (62650)", value: "62650" },
  { label: "ERIN (62134)", value: "62134" },
  { label: "ERNY ST JULIEN (62960)", value: "62960" },
  { label: "ERVILLERS (62121)", value: "62121" },
  { label: "ESCALLES (62179)", value: "62179" },
  { label: "ESCOEUILLES (62850)", value: "62850" },
  { label: "ESQUERDES (62380)", value: "62380" },
  { label: "ESSARS (62400)", value: "62400" },
  { label: "ESTEVELLES (62880)", value: "62880" },
  { label: "ESTREE (62170)", value: "62170" },
  { label: "ESTREE BLANCHE (62145)", value: "62145" },
  { label: "ESTREE CAUCHY (62690)", value: "62690" },
  { label: "ESTREELLES (62170)", value: "62170" },
  { label: "ESTREE WAMIN (62810)", value: "62810" },
  { label: "ETAING (62156)", value: "62156" },
  { label: "ETAPLES (62630)", value: "62630" },
  { label: "ETERPIGNY (62156)", value: "62156" },
  { label: "ETRUN (62161)", value: "62161" },
  { label: "EVIN MALMAISON (62141)", value: "62141" },
  { label: "FAMECHON (62760)", value: "62760" },
  { label: "FAMPOUX (62118)", value: "62118" },
  { label: "FARBUS (62580)", value: "62580" },
  { label: "FAUQUEMBERGUES (62560)", value: "62560" },
  { label: "FAVREUIL (62450)", value: "62450" },
  { label: "FEBVIN PALFART (62960)", value: "62960" },
  { label: "FERFAY (62260)", value: "62260" },
  { label: "FERQUES (62250)", value: "62250" },
  { label: "FESTUBERT (62149)", value: "62149" },
  { label: "FEUCHY (62223)", value: "62223" },
  { label: "FICHEUX (62173)", value: "62173" },
  { label: "FIEFS (62134)", value: "62134" },
  { label: "FIENNES (62132)", value: "62132" },
  { label: "FILLIEVRES (62770)", value: "62770" },
  { label: "FLECHIN (62960)", value: "62960" },
  { label: "FLERS (62270)", value: "62270" },
  { label: "FLEURBAIX (62840)", value: "62840" },
  { label: "FLEURY (62134)", value: "62134" },
  { label: "FLORINGHEM (62550)", value: "62550" },
  { label: "FONCQUEVILLERS (62111)", value: "62111" },
  { label: "FONTAINE LES BOULANS (62134)", value: "62134" },
  { label: "FONTAINE LES CROISILLES (62128)", value: "62128" },
  { label: "FONTAINE LES HERMANS (62550)", value: "62550" },
  { label: "FONTAINE L ETALON (62390)", value: "62390" },
  { label: "FORTEL EN ARTOIS (62270)", value: "62270" },
  { label: "FOSSEUX (62810)", value: "62810" },
  { label: "FOUFFLIN RICAMETZ (62130)", value: "62130" },
  { label: "FOUQUEREUIL (62232)", value: "62232" },
  { label: "FOUQUIERES LES BETHUNE (62232)", value: "62232" },
  { label: "FOUQUIERES LES LENS (62740)", value: "62740" },
  { label: "FRAMECOURT (62130)", value: "62130" },
  { label: "FREMICOURT (62450)", value: "62450" },
  { label: "FRENCQ (62630)", value: "62630" },
  { label: "FRESNES LES MONTAUBAN (62490)", value: "62490" },
  { label: "FRESNICOURT LE DOLMEN (62150)", value: "62150" },
  { label: "FRESNOY (62770)", value: "62770" },
  { label: "FRESNOY EN GOHELLE (62580)", value: "62580" },
  { label: "FRESSIN (62140)", value: "62140" },
  { label: "FRETHUN (62185)", value: "62185" },
  { label: "FREVENT (62270)", value: "62270" },
  { label: "FREVILLERS (62127)", value: "62127" },
  { label: "FREVIN CAPELLE (62690)", value: "62690" },
  { label: "FRUGES (62310)", value: "62310" },
  { label: "GALAMETZ (62770)", value: "62770" },
  { label: "GAUCHIN LEGAL (62150)", value: "62150" },
  { label: "GAUCHIN VERLOINGT (62130)", value: "62130" },
  { label: "GAUDIEMPRE (62760)", value: "62760" },
  { label: "GAVRELLE (62580)", value: "62580" },
  { label: "GENNES IVERGNY (62390)", value: "62390" },
  { label: "GIVENCHY EN GOHELLE (62580)", value: "62580" },
  { label: "GIVENCHY LE NOBLE (62810)", value: "62810" },
  { label: "GIVENCHY LES LA BASSEE (62149)", value: "62149" },
  { label: "GOMIECOURT (62121)", value: "62121" },
  { label: "GOMMECOURT (62111)", value: "62111" },
  { label: "GONNEHEM (62920)", value: "62920" },
  { label: "GOSNAY (62199)", value: "62199" },
  { label: "GOUVES (62123)", value: "62123" },
  { label: "GOUY EN ARTOIS (62123)", value: "62123" },
  { label: "GOUY SERVINS (62530)", value: "62530" },
  { label: "GOUY EN TERNOIS (62127)", value: "62127" },
  { label: "GOUY ST ANDRE (62870)", value: "62870" },
  { label: "GOUY SOUS BELLONNE (62112)", value: "62112" },
  { label: "GRAINCOURT LES HAVRINCOURT (62147)", value: "62147" },
  { label: "GRAND RULLECOURT (62810)", value: "62810" },
  { label: "GRENAY (62160)", value: "62160" },
  { label: "GREVILLERS (62450)", value: "62450" },
  { label: "GRIGNY (62140)", value: "62140" },
  { label: "GRINCOURT LES PAS (62760)", value: "62760" },
  { label: "GROFFLIERS (62600)", value: "62600" },
  { label: "GUARBECQUE (62330)", value: "62330" },
  { label: "GUEMAPPE (62128)", value: "62128" },
  { label: "GUEMPS (62370)", value: "62370" },
  { label: "GUIGNY (62140)", value: "62140" },
  { label: "GUINECOURT (62130)", value: "62130" },
  { label: "GUINES (62340)", value: "62340" },
  { label: "GUISY (62140)", value: "62140" },
  { label: "HABARCQ (62123)", value: "62123" },
  { label: "HAILLICOURT (62940)", value: "62940" },
  { label: "HAISNES (62138)", value: "62138" },
  { label: "HALINGHEN (62830)", value: "62830" },
  { label: "HALLINES (62570)", value: "62570" },
  { label: "HALLOY (62760)", value: "62760" },
  { label: "HAMBLAIN LES PRES (62118)", value: "62118" },
  { label: "HAMELINCOURT (62121)", value: "62121" },
  { label: "HAM EN ARTOIS (62190)", value: "62190" },
  { label: "HAMES BOUCRES (62340)", value: "62340" },
  { label: "HANNESCAMPS (62111)", value: "62111" },
  { label: "HAPLINCOURT (62124)", value: "62124" },
  { label: "HARAVESNES (62390)", value: "62390" },
  { label: "HARDINGHEN (62132)", value: "62132" },
  { label: "HARNES (62440)", value: "62440" },
  { label: "HAUCOURT (62156)", value: "62156" },
  { label: "HAUTE AVESNES (62144)", value: "62144" },
  { label: "HAUTECLOQUE (62130)", value: "62130" },
  { label: "HAUTEVILLE (62810)", value: "62810" },
  { label: "HAUT LOQUIN (62850)", value: "62850" },
  { label: "HAVRINCOURT (62147)", value: "62147" },
  { label: "HEBUTERNE (62111)", value: "62111" },
  { label: "HELFAUT (62570)", value: "62570" },
  { label: "HENDECOURT LES CAGNICOURT (62182)", value: "62182" },
  { label: "HENDECOURT LES RANSART (62175)", value: "62175" },
  { label: "HENINEL (62128)", value: "62128" },
  { label: "HENIN BEAUMONT (62110)", value: "62110" },
  { label: "HENIN SUR COJEUL (62128)", value: "62128" },
  { label: "HENNEVEUX (62142)", value: "62142" },
  { label: "HENU (62760)", value: "62760" },
  { label: "HERBINGHEN (62850)", value: "62850" },
  { label: "HERICOURT (62130)", value: "62130" },
  { label: "LA HERLIERE (62158)", value: "62158" },
  { label: "HERLINCOURT (62130)", value: "62130" },
  { label: "HERLIN LE SEC (62130)", value: "62130" },
  { label: "HERLY (62650)", value: "62650" },
  { label: "HERMAVILLE (62690)", value: "62690" },
  { label: "HERMELINGHEN (62132)", value: "62132" },
  { label: "HERMIES (62147)", value: "62147" },
  { label: "HERMIN (62150)", value: "62150" },
  { label: "HERNICOURT (62130)", value: "62130" },
  { label: "HERSIN COUPIGNY (62530)", value: "62530" },
  { label: "HERVELINGHEN (62179)", value: "62179" },
  { label: "HESDIGNEUL LES BETHUNE (62196)", value: "62196" },
  { label: "HESDIGNEUL LES BOULOGNE (62360)", value: "62360" },
  { label: "HESDIN (62140)", value: "62140" },
  { label: "HESDIN L ABBE (62360)", value: "62360" },
  { label: "HESMOND (62990)", value: "62990" },
  { label: "HESTRUS (62550)", value: "62550" },
  { label: "HEUCHIN (62134)", value: "62134" },
  { label: "HEURINGHEM (62575)", value: "62575" },
  { label: "HEZECQUES (62310)", value: "62310" },
  { label: "HINGES (62232)", value: "62232" },
  { label: "HOCQUINGHEN (62850)", value: "62850" },
  { label: "HOUCHIN (62620)", value: "62620" },
  { label: "HOUDAIN (62150)", value: "62150" },
  { label: "HOULLE (62910)", value: "62910" },
  { label: "HOUVIN HOUVIGNEUL (62270)", value: "62270" },
  { label: "HUBERSENT (62630)", value: "62630" },
  { label: "HUBY ST LEU (62140)", value: "62140" },
  { label: "HUCLIER (62130)", value: "62130" },
  { label: "HUCQUELIERS (62650)", value: "62650" },
  { label: "HULLUCH (62410)", value: "62410" },
  { label: "HUMBERCAMPS (62158)", value: "62158" },
  { label: "HUMBERT (62650)", value: "62650" },
  { label: "HUMEROEUILLE (62130)", value: "62130" },
  { label: "HUMIERES (62130)", value: "62130" },
  { label: "INCHY EN ARTOIS (62860)", value: "62860" },
  { label: "INCOURT (62770)", value: "62770" },
  { label: "BELLINGHEM (62129)", value: "62129" },
  { label: "BELLINGHEM (62129)", value: "62129" },
  { label: "INXENT (62170)", value: "62170" },
  { label: "ISBERGUES (62330)", value: "62330" },
  { label: "ISBERGUES (62330)", value: "62330" },
  { label: "ISBERGUES (62330)", value: "62330" },
  { label: "ISQUES (62360)", value: "62360" },
  { label: "IVERGNY (62810)", value: "62810" },
  { label: "IZEL LES EQUERCHIN (62490)", value: "62490" },
  { label: "IZEL LES HAMEAU (62690)", value: "62690" },
  { label: "JOURNY (62850)", value: "62850" },
  { label: "LABEUVRIERE (62122)", value: "62122" },
  { label: "LABOURSE (62113)", value: "62113" },
  { label: "LABROYE (62140)", value: "62140" },
  { label: "LACRES (62830)", value: "62830" },
  { label: "LAGNICOURT MARCEL (62159)", value: "62159" },
  { label: "LAIRES (62960)", value: "62960" },
  { label: "LAMBRES (62120)", value: "62120" },
  { label: "LANDRETHUN LE NORD (62250)", value: "62250" },
  { label: "LANDRETHUN LES ARDRES (62610)", value: "62610" },
  { label: "LAPUGNOY (62122)", value: "62122" },
  { label: "LATTRE ST QUENTIN (62810)", value: "62810" },
  { label: "LAVENTIE (62840)", value: "62840" },
  { label: "LEBIEZ (62990)", value: "62990" },
  { label: "LEBUCQUIERE (62124)", value: "62124" },
  { label: "LECHELLE (62124)", value: "62124" },
  { label: "LEDINGHEM (62380)", value: "62380" },
  { label: "LEFAUX (62630)", value: "62630" },
  { label: "LEFOREST (62790)", value: "62790" },
  { label: "LENS (62300)", value: "62300" },
  { label: "LEPINE (62170)", value: "62170" },
  { label: "LESPESSES (62190)", value: "62190" },
  { label: "LESPINOY (62990)", value: "62990" },
  { label: "LESTREM (62136)", value: "62136" },
  { label: "LEUBRINGHEN (62250)", value: "62250" },
  { label: "LEULINGHEM (62500)", value: "62500" },
  { label: "LEULINGHEN BERNES (62250)", value: "62250" },
  { label: "LICQUES (62850)", value: "62850" },
  { label: "LIENCOURT (62810)", value: "62810" },
  { label: "LIERES (62190)", value: "62190" },
  { label: "LIETTRES (62145)", value: "62145" },
  { label: "LIEVIN (62800)", value: "62800" },
  { label: "LIGNEREUIL (62810)", value: "62810" },
  { label: "LIGNY LES AIRE (62960)", value: "62960" },
  { label: "LIGNY SUR CANCHE (62270)", value: "62270" },
  { label: "LIGNY ST FLOCHEL (62127)", value: "62127" },
  { label: "LIGNY THILLOY (62450)", value: "62450" },
  { label: "LILLERS (62190)", value: "62190" },
  { label: "LINGHEM (62120)", value: "62120" },
  { label: "LINZEUX (62270)", value: "62270" },
  { label: "LISBOURG (62134)", value: "62134" },
  { label: "LOCON (62400)", value: "62400" },
  { label: "LA LOGE (62140)", value: "62140" },
  { label: "LOISON SUR CREQUOISE (62990)", value: "62990" },
  { label: "LOISON SOUS LENS (62218)", value: "62218" },
  { label: "LONGFOSSE (62240)", value: "62240" },
  { label: "LONGUENESSE (62219)", value: "62219" },
  { label: "LONGUEVILLE (62142)", value: "62142" },
  { label: "LONGVILLIERS (62630)", value: "62630" },
  { label: "LOOS EN GOHELLE (62750)", value: "62750" },
  { label: "LORGIES (62840)", value: "62840" },
  { label: "LOTTINGHEN (62240)", value: "62240" },
  { label: "LOUCHES (62610)", value: "62610" },
  { label: "LOZINGHEM (62540)", value: "62540" },
  { label: "LUGY (62310)", value: "62310" },
  { label: "LUMBRES (62380)", value: "62380" },
  { label: "LA MADELAINE SOUS MONTREUIL (62170)", value: "62170" },
  { label: "MAGNICOURT EN COMTE (62127)", value: "62127" },
  { label: "MAGNICOURT SUR CANCHE (62270)", value: "62270" },
  { label: "MAINTENAY (62870)", value: "62870" },
  { label: "MAISNIL (62130)", value: "62130" },
  { label: "MAISNIL LES RUITZ (62620)", value: "62620" },
  { label: "MAISONCELLE (62310)", value: "62310" },
  { label: "MAIZIERES (62127)", value: "62127" },
  { label: "MAMETZ (62120)", value: "62120" },
  { label: "MANIN (62810)", value: "62810" },
  { label: "MANINGHEM (62650)", value: "62650" },
  { label: "MANINGHEN HENNE (62250)", value: "62250" },
  { label: "MARANT (62170)", value: "62170" },
  { label: "MARCK (62730)", value: "62730" },
  { label: "MARCONNE (62140)", value: "62140" },
  { label: "MARCONNELLE (62140)", value: "62140" },
  { label: "MARENLA (62990)", value: "62990" },
  { label: "MARESQUEL ECQUEMICOURT (62990)", value: "62990" },
  { label: "MAREST (62550)", value: "62550" },
  { label: "MARESVILLE (62630)", value: "62630" },
  { label: "MARLES LES MINES (62540)", value: "62540" },
  { label: "MARLES SUR CANCHE (62170)", value: "62170" },
  { label: "MAROEUIL (62161)", value: "62161" },
  { label: "MARQUAY (62127)", value: "62127" },
  { label: "MARQUION (62860)", value: "62860" },
  { label: "MARQUISE (62250)", value: "62250" },
  { label: "MARTINPUICH (62450)", value: "62450" },
  { label: "MATRINGHEM (62310)", value: "62310" },
  { label: "MAZINGARBE (62670)", value: "62670" },
  { label: "MAZINGHEM (62120)", value: "62120" },
  { label: "MENCAS (62310)", value: "62310" },
  { label: "MENNEVILLE (62240)", value: "62240" },
  { label: "MENTQUE NORTBECOURT (62890)", value: "62890" },
  { label: "MERCATEL (62217)", value: "62217" },
  { label: "MERCK ST LIEVIN (62560)", value: "62560" },
  { label: "MERICOURT (62680)", value: "62680" },
  { label: "MERLIMONT (62155)", value: "62155" },
  { label: "METZ EN COUTURE (62124)", value: "62124" },
  { label: "MEURCHIN (62410)", value: "62410" },
  { label: "MINGOVAL (62690)", value: "62690" },
  { label: "MONCHEAUX LES FREVENT (62270)", value: "62270" },
  { label: "MONCHEL SUR CANCHE (62270)", value: "62270" },
  { label: "MONCHIET (62123)", value: "62123" },
  { label: "MONCHY AU BOIS (62111)", value: "62111" },
  { label: "MONCHY BRETON (62127)", value: "62127" },
  { label: "MONCHY CAYEUX (62134)", value: "62134" },
  { label: "MONCHY LE PREUX (62118)", value: "62118" },
  { label: "MONDICOURT (62760)", value: "62760" },
  { label: "MONT BERNANCHON (62350)", value: "62350" },
  { label: "MONTCAVREL (62170)", value: "62170" },
  { label: "MONTENESCOURT (62123)", value: "62123" },
  { label: "MONTIGNY EN GOHELLE (62640)", value: "62640" },
  { label: "MONTREUIL SUR MER (62170)", value: "62170" },
  { label: "MONT ST ELOI (62144)", value: "62144" },
  { label: "MONTS EN TERNOIS (62130)", value: "62130" },
  { label: "MORCHIES (62124)", value: "62124" },
  { label: "MORINGHEM (62910)", value: "62910" },
  { label: "MORVAL (62450)", value: "62450" },
  { label: "MORY (62159)", value: "62159" },
  { label: "MOULLE (62910)", value: "62910" },
  { label: "MOURIEZ (62140)", value: "62140" },
  { label: "MOYENNEVILLE (62121)", value: "62121" },
  { label: "MUNCQ NIEURLET (62890)", value: "62890" },
  { label: "NABRINGHEN (62142)", value: "62142" },
  { label: "NEDON (62550)", value: "62550" },
  { label: "NEDONCHEL (62550)", value: "62550" },
  { label: "NEMPONT ST FIRMIN (62180)", value: "62180" },
  { label: "NESLES (62152)", value: "62152" },
  { label: "NEUFCHATEL HARDELOT (62152)", value: "62152" },
  { label: "NEUFCHATEL HARDELOT (62152)", value: "62152" },
  { label: "NEULETTE (62770)", value: "62770" },
  { label: "NEUVE CHAPELLE (62840)", value: "62840" },
  { label: "NEUVILLE AU CORNET (62130)", value: "62130" },
  { label: "NEUVILLE BOURJONVAL (62124)", value: "62124" },
  { label: "NEUVILLE ST VAAST (62580)", value: "62580" },
  { label: "NEUVILLE SOUS MONTREUIL (62170)", value: "62170" },
  { label: "NEUVILLE VITASSE (62217)", value: "62217" },
  { label: "NEUVIREUIL (62580)", value: "62580" },
  { label: "NIELLES LES BLEQUIN (62380)", value: "62380" },
  { label: "NIELLES LES ARDRES (62610)", value: "62610" },
  { label: "NIELLES LES CALAIS (62185)", value: "62185" },
  { label: "NOEUX LES AUXI (62390)", value: "62390" },
  { label: "NOEUX LES MINES (62290)", value: "62290" },
  { label: "NORDAUSQUES (62890)", value: "62890" },
  { label: "NOREUIL (62128)", value: "62128" },
  { label: "NORRENT FONTES (62120)", value: "62120" },
  { label: "NORTKERQUE (62370)", value: "62370" },
  { label: "NORT LEULINGHEM (62890)", value: "62890" },
  { label: "NOUVELLE EGLISE (62370)", value: "62370" },
  { label: "NOYELLES GODAULT (62950)", value: "62950" },
  { label: "NOYELLES LES HUMIERES (62770)", value: "62770" },
  { label: "NOYELLES LES VERMELLES (62980)", value: "62980" },
  { label: "NOYELLES SOUS BELLONNE (62490)", value: "62490" },
  { label: "NOYELLES SOUS LENS (62221)", value: "62221" },
  { label: "NOYELLETTE (62123)", value: "62123" },
  { label: "NOYELLE VION (62810)", value: "62810" },
  { label: "NUNCQ HAUTECOTE (62270)", value: "62270" },
  { label: "NUNCQ HAUTECOTE (62270)", value: "62270" },
  { label: "OBLINGHEM (62920)", value: "62920" },
  { label: "OEUF EN TERNOIS (62130)", value: "62130" },
  { label: "OFFEKERQUE (62370)", value: "62370" },
  { label: "OFFIN (62990)", value: "62990" },
  { label: "OFFRETHUN (62250)", value: "62250" },
  { label: "OIGNIES (62590)", value: "62590" },
  { label: "OISY LE VERGER (62860)", value: "62860" },
  { label: "OPPY (62580)", value: "62580" },
  { label: "ORVILLE (62760)", value: "62760" },
  { label: "OSTREVILLE (62130)", value: "62130" },
  { label: "OURTON (62460)", value: "62460" },
  { label: "OUTREAU (62230)", value: "62230" },
  { label: "OUVE WIRQUIN (62380)", value: "62380" },
  { label: "OYE PLAGE (62215)", value: "62215" },
  { label: "PALLUEL (62860)", value: "62860" },
  { label: "LE PARCQ (62770)", value: "62770" },
  { label: "PARENTY (62650)", value: "62650" },
  { label: "PAS EN ARTOIS (62760)", value: "62760" },
  { label: "PELVES (62118)", value: "62118" },
  { label: "PENIN (62127)", value: "62127" },
  { label: "PERNES (62550)", value: "62550" },
  { label: "PERNES LES BOULOGNE (62126)", value: "62126" },
  { label: "PEUPLINGUES (62231)", value: "62231" },
  { label: "PIERREMONT (62130)", value: "62130" },
  { label: "PIHEM (62570)", value: "62570" },
  { label: "PIHEN LES GUINES (62340)", value: "62340" },
  { label: "PITTEFAUX (62126)", value: "62126" },
  { label: "PLANQUES (62310)", value: "62310" },
  { label: "PLOUVAIN (62118)", value: "62118" },
  { label: "BOUIN PLUMOISON (62140)", value: "62140" },
  { label: "BOUIN PLUMOISON (62140)", value: "62140" },
  { label: "POLINCOVE (62370)", value: "62370" },
  { label: "POMMERA (62760)", value: "62760" },
  { label: "POMMIER (62111)", value: "62111" },
  { label: "LE PONCHEL (62390)", value: "62390" },
  { label: "PONT A VENDIN (62880)", value: "62880" },
  { label: "LE PORTEL (62480)", value: "62480" },
  { label: "PREDEFIN (62134)", value: "62134" },
  { label: "PRESSY (62550)", value: "62550" },
  { label: "PREURES (62650)", value: "62650" },
  { label: "PRONVILLE EN ARTOIS (62860)", value: "62860" },
  { label: "PUISIEUX (62116)", value: "62116" },
  { label: "QUEANT (62860)", value: "62860" },
  { label: "QUELMES (62500)", value: "62500" },
  { label: "QUERCAMPS (62380)", value: "62380" },
  { label: "QUERNES (62120)", value: "62120" },
  { label: "LE QUESNOY EN ARTOIS (62140)", value: "62140" },
  { label: "QUESQUES (62240)", value: "62240" },
  { label: "QUESTRECQUES (62830)", value: "62830" },
  { label: "QUIERY LA MOTTE (62490)", value: "62490" },
  { label: "QUIESTEDE (62120)", value: "62120" },
  { label: "QUILEN (62650)", value: "62650" },
  { label: "QUOEUX HAUT MAINIL (62390)", value: "62390" },
  { label: "QUOEUX HAUT MAINIL (62390)", value: "62390" },
  { label: "RACQUINGHEM (62120)", value: "62120" },
  { label: "RADINGHEM (62310)", value: "62310" },
  { label: "RAMECOURT (62130)", value: "62130" },
  { label: "RANG DU FLIERS (62180)", value: "62180" },
  { label: "RANSART (62173)", value: "62173" },
  { label: "RAYE SUR AUTHIE (62140)", value: "62140" },
  { label: "ST AUGUSTIN (62120)", value: "62120" },
  { label: "ST AUGUSTIN (62129)", value: "62129" },
  { label: "REBERGUES (62850)", value: "62850" },
  { label: "REBREUVE RANCHICOURT (62150)", value: "62150" },
  { label: "REBREUVE RANCHICOURT (62150)", value: "62150" },
  { label: "REBREUVE SUR CANCHE (62270)", value: "62270" },
  { label: "REBREUVIETTE (62270)", value: "62270" },
  { label: "RECLINGHEM (62560)", value: "62560" },
  { label: "RECOURT (62860)", value: "62860" },
  { label: "RECQUES SUR COURSE (62170)", value: "62170" },
  { label: "RECQUES SUR HEM (62890)", value: "62890" },
  { label: "REGNAUVILLE (62140)", value: "62140" },
  { label: "RELY (62120)", value: "62120" },
  { label: "REMILLY WIRQUIN (62380)", value: "62380" },
  { label: "REMY (62156)", value: "62156" },
  { label: "RENTY (62560)", value: "62560" },
  { label: "RETY (62720)", value: "62720" },
  { label: "RICHEBOURG (62136)", value: "62136" },
  { label: "RIENCOURT LES BAPAUME (62450)", value: "62450" },
  { label: "RIENCOURT LES CAGNICOURT (62182)", value: "62182" },
  { label: "RIMBOVAL (62990)", value: "62990" },
  { label: "RINXENT (62720)", value: "62720" },
  { label: "RIVIERE (62173)", value: "62173" },
  { label: "ROBECQ (62350)", value: "62350" },
  { label: "ROCLINCOURT (62223)", value: "62223" },
  { label: "ROCQUIGNY (62450)", value: "62450" },
  { label: "RODELINGHEM (62610)", value: "62610" },
  { label: "ROELLECOURT (62130)", value: "62130" },
  { label: "ROEUX (62118)", value: "62118" },
  { label: "ROLLANCOURT (62770)", value: "62770" },
  { label: "ROMBLY (62120)", value: "62120" },
  { label: "ROQUETOIRE (62120)", value: "62120" },
  { label: "ROUGEFAY (62390)", value: "62390" },
  { label: "ROUSSENT (62870)", value: "62870" },
  { label: "ROUVROY (62320)", value: "62320" },
  { label: "ROYON (62990)", value: "62990" },
  { label: "RUISSEAUVILLE (62310)", value: "62310" },
  { label: "RUITZ (62620)", value: "62620" },
  { label: "RUMAUCOURT (62860)", value: "62860" },
  { label: "RUMILLY (62650)", value: "62650" },
  { label: "RUMINGHEM (62370)", value: "62370" },
  { label: "RUYAULCOURT (62124)", value: "62124" },
  { label: "SACHIN (62550)", value: "62550" },
  { label: "SAILLY AU BOIS (62111)", value: "62111" },
  { label: "SAILLY EN OSTREVENT (62490)", value: "62490" },
  { label: "SAILLY LABOURSE (62113)", value: "62113" },
  { label: "SAILLY SUR LA LYS (62840)", value: "62840" },
  { label: "SAINS EN GOHELLE (62114)", value: "62114" },
  { label: "SAINS LES FRESSIN (62310)", value: "62310" },
  { label: "SAINS LES MARQUION (62860)", value: "62860" },
  { label: "SAINS LES PERNES (62550)", value: "62550" },
  { label: "ST AMAND (62760)", value: "62760" },
  { label: "ST AUBIN (62170)", value: "62170" },
  { label: "STE AUSTREBERTHE (62140)", value: "62140" },
  { label: "STE CATHERINE (62223)", value: "62223" },
  { label: "ST DENOEUX (62990)", value: "62990" },
  { label: "ST ETIENNE AU MONT (62360)", value: "62360" },
  { label: "ST ETIENNE AU MONT (62360)", value: "62360" },
  { label: "ST FLORIS (62350)", value: "62350" },
  { label: "ST FOLQUIN (62370)", value: "62370" },
  { label: "ST GEORGES (62770)", value: "62770" },
  { label: "ST HILAIRE COTTES (62120)", value: "62120" },
  { label: "ST INGLEVERT (62250)", value: "62250" },
  { label: "ST JOSSE (62170)", value: "62170" },
  { label: "ST LAURENT BLANGY (62223)", value: "62223" },
  { label: "ST LEGER (62128)", value: "62128" },
  { label: "ST LEONARD (62360)", value: "62360" },
  { label: "STE MARIE KERQUE (62370)", value: "62370" },
  { label: "ST MARTIN LEZ TATINGHEM (62500)", value: "62500" },
  { label: "ST MARTIN LEZ TATINGHEM (62500)", value: "62500" },
  { label: "ST MARTIN BOULOGNE (62280)", value: "62280" },
  { label: "ST MARTIN CHOQUEL (62240)", value: "62240" },
  { label: "ST MARTIN D HARDINGHEM (62560)", value: "62560" },
  { label: "ST MARTIN SUR COJEUL (62128)", value: "62128" },
  { label: "ST MICHEL SOUS BOIS (62650)", value: "62650" },
  { label: "ST MICHEL SUR TERNOISE (62130)", value: "62130" },
  { label: "ST NICOLAS (62223)", value: "62223" },
  { label: "ST OMER (62500)", value: "62500" },
  { label: "ST OMER CAPELLE (62162)", value: "62162" },
  { label: "ST POL SUR TERNOISE (62130)", value: "62130" },
  { label: "ST REMY AU BOIS (62870)", value: "62870" },
  { label: "ST TRICAT (62185)", value: "62185" },
  { label: "ST VENANT (62350)", value: "62350" },
  { label: "SALLAUMINES (62430)", value: "62430" },
  { label: "SALPERWICK (62500)", value: "62500" },
  { label: "SAMER (62830)", value: "62830" },
  { label: "SANGATTE (62231)", value: "62231" },
  { label: "SANGATTE (62231)", value: "62231" },
  { label: "SANGHEN (62850)", value: "62850" },
  { label: "SAPIGNIES (62121)", value: "62121" },
  { label: "LE SARS (62450)", value: "62450" },
  { label: "SARS LE BOIS (62810)", value: "62810" },
  { label: "SARTON (62760)", value: "62760" },
  { label: "SAUCHY CAUCHY (62860)", value: "62860" },
  { label: "SAUCHY LESTREE (62860)", value: "62860" },
  { label: "SAUDEMONT (62860)", value: "62860" },
  { label: "SAULCHOY (62870)", value: "62870" },
  { label: "SAULTY (62158)", value: "62158" },
  { label: "SAVY BERLETTE (62690)", value: "62690" },
  { label: "SELLES (62240)", value: "62240" },
  { label: "SEMPY (62170)", value: "62170" },
  { label: "SENINGHEM (62380)", value: "62380" },
  { label: "SENLECQUES (62240)", value: "62240" },
  { label: "SENLIS (62310)", value: "62310" },
  { label: "SERICOURT (62270)", value: "62270" },
  { label: "SERQUES (62910)", value: "62910" },
  { label: "SERVINS (62530)", value: "62530" },
  { label: "SETQUES (62380)", value: "62380" },
  { label: "SIBIVILLE (62270)", value: "62270" },
  { label: "SIMENCOURT (62123)", value: "62123" },
  { label: "SIRACOURT (62130)", value: "62130" },
  { label: "SOMBRIN (62810)", value: "62810" },
  { label: "SORRUS (62170)", value: "62170" },
  { label: "SOUASTRE (62111)", value: "62111" },
  { label: "SOUCHEZ (62153)", value: "62153" },
  { label: "LE SOUICH (62810)", value: "62810" },
  { label: "SURQUES (62850)", value: "62850" },
  { label: "SUS ST LEGER (62810)", value: "62810" },
  { label: "TANGRY (62550)", value: "62550" },
  { label: "TARDINGHEN (62179)", value: "62179" },
  { label: "TENEUR (62134)", value: "62134" },
  { label: "TERNAS (62127)", value: "62127" },
  { label: "THELUS (62580)", value: "62580" },
  { label: "THEROUANNE (62129)", value: "62129" },
  { label: "THIEMBRONNE (62560)", value: "62560" },
  { label: "LA THIEULOYE (62130)", value: "62130" },
  { label: "THIEVRES (62760)", value: "62760" },
  { label: "TIGNY NOYELLE (62180)", value: "62180" },
  { label: "TILLOY LES HERMAVILLE (62690)", value: "62690" },
  { label: "TILLOY LES MOFFLAINES (62217)", value: "62217" },
  { label: "TILLY CAPELLE (62134)", value: "62134" },
  { label: "TILQUES (62500)", value: "62500" },
  { label: "TINCQUES (62127)", value: "62127" },
  { label: "TINGRY (62830)", value: "62830" },
  { label: "TOLLENT (62390)", value: "62390" },
  { label: "TORCY (62310)", value: "62310" },
  { label: "TORTEFONTAINE (62140)", value: "62140" },
  { label: "TORTEQUESNE (62490)", value: "62490" },
  { label: "LE TOUQUET PARIS PLAGE (62520)", value: "62520" },
  { label: "TOURNEHEM SUR LA HEM (62890)", value: "62890" },
  { label: "TRAMECOURT (62310)", value: "62310" },
  { label: "LE TRANSLOY (62450)", value: "62450" },
  { label: "TRESCAULT (62147)", value: "62147" },
  { label: "TROISVAUX (62130)", value: "62130" },
  { label: "TUBERSENT (62630)", value: "62630" },
  { label: "VACQUERIE LE BOUCQ (62270)", value: "62270" },
  { label: "VACQUERIETTE ERQUIERES (62140)", value: "62140" },
  { label: "VACQUERIETTE ERQUIERES (62140)", value: "62140" },
  { label: "VALHUON (62550)", value: "62550" },
  { label: "VAUDRICOURT (62131)", value: "62131" },
  { label: "VAUDRINGHEM (62380)", value: "62380" },
  { label: "VAULX (62390)", value: "62390" },
  { label: "VAULX VRAUCOURT (62159)", value: "62159" },
  { label: "VELU (62124)", value: "62124" },
  { label: "VENDIN LES BETHUNE (62232)", value: "62232" },
  { label: "VENDIN LE VIEIL (62880)", value: "62880" },
  { label: "VERCHIN (62310)", value: "62310" },
  { label: "VERCHOCQ (62560)", value: "62560" },
  { label: "VERLINCTHUN (62830)", value: "62830" },
  { label: "VERMELLES (62980)", value: "62980" },
  { label: "VERQUIGNEUL (62113)", value: "62113" },
  { label: "VERQUIN (62131)", value: "62131" },
  { label: "VERTON (62180)", value: "62180" },
  { label: "VIEIL HESDIN (62770)", value: "62770" },
  { label: "VIEILLE CHAPELLE (62136)", value: "62136" },
  { label: "VIEILLE EGLISE (62162)", value: "62162" },
  { label: "VIEIL MOUTIER (62240)", value: "62240" },
  { label: "VILLERS AU BOIS (62144)", value: "62144" },
  { label: "VILLERS AU FLOS (62450)", value: "62450" },
  { label: "VILLERS BRULIN (62690)", value: "62690" },
  { label: "VILLERS CHATEL (62690)", value: "62690" },
  { label: "VILLERS LES CAGNICOURT (62182)", value: "62182" },
  { label: "VILLERS L HOPITAL (62390)", value: "62390" },
  { label: "VILLERS SIR SIMON (62127)", value: "62127" },
  { label: "VIMY (62580)", value: "62580" },
  { label: "VINCLY (62310)", value: "62310" },
  { label: "VIOLAINES (62138)", value: "62138" },
  { label: "VIS EN ARTOIS (62156)", value: "62156" },
  { label: "VITRY EN ARTOIS (62490)", value: "62490" },
  { label: "WABEN (62180)", value: "62180" },
  { label: "WACQUINGHEN (62250)", value: "62250" },
  { label: "WAIL (62770)", value: "62770" },
  { label: "WAILLY (62217)", value: "62217" },
  { label: "WAILLY BEAUCAMP (62170)", value: "62170" },
  { label: "WAMBERCOURT (62140)", value: "62140" },
  { label: "WAMIN (62770)", value: "62770" },
  { label: "WANCOURT (62128)", value: "62128" },
  { label: "WANQUETIN (62123)", value: "62123" },
  { label: "WARDRECQUES (62120)", value: "62120" },
  { label: "WARLENCOURT EAUCOURT (62450)", value: "62450" },
  { label: "WARLINCOURT LES PAS (62760)", value: "62760" },
  { label: "WARLUS (62123)", value: "62123" },
  { label: "WARLUZEL (62810)", value: "62810" },
  { label: "LE WAST (62142)", value: "62142" },
  { label: "BEAUVOIR WAVANS (62390)", value: "62390" },
  { label: "WAVRANS SUR L AA (62380)", value: "62380" },
  { label: "WAVRANS SUR TERNOISE (62130)", value: "62130" },
  { label: "WESTREHEM (62960)", value: "62960" },
  { label: "WICQUINGHEM (62650)", value: "62650" },
  { label: "WIDEHEM (62630)", value: "62630" },
  { label: "WIERRE AU BOIS (62830)", value: "62830" },
  { label: "WIERRE EFFROY (62720)", value: "62720" },
  { label: "WILLEMAN (62770)", value: "62770" },
  { label: "WILLENCOURT (62390)", value: "62390" },
  { label: "WILLERVAL (62580)", value: "62580" },
  { label: "WIMEREUX (62930)", value: "62930" },
  { label: "WIMILLE (62126)", value: "62126" },
  { label: "WINGLES (62410)", value: "62410" },
  { label: "WIRWIGNES (62240)", value: "62240" },
  { label: "WISMES (62380)", value: "62380" },
  { label: "WISQUES (62219)", value: "62219" },
  { label: "WISSANT (62179)", value: "62179" },
  { label: "WITTERNESSE (62120)", value: "62120" },
  { label: "WITTES (62120)", value: "62120" },
  { label: "WIZERNES (62570)", value: "62570" },
  { label: "ZOTEUX (62650)", value: "62650" },
  { label: "ZOUAFQUES (62890)", value: "62890" },
  { label: "ZOUAFQUES (62890)", value: "62890" },
  { label: "ZUDAUSQUES (62500)", value: "62500" },
  { label: "ZUTKERQUE (62370)", value: "62370" },
  { label: "LIBERCOURT (62820)", value: "62820" },
  { label: "LA CAPELLE LES BOULOGNE (62360)", value: "62360" },
  { label: "YTRES (62124)", value: "62124" },
  { label: "AIGUEPERSE (63260)", value: "63260" },
  { label: "AIX LA FAYETTE (63980)", value: "63980" },
  { label: "AMBERT (63600)", value: "63600" },
  { label: "LES ANCIZES COMPS (63770)", value: "63770" },
  { label: "ANTOINGT (63340)", value: "63340" },
  { label: "ANZAT LE LUGUET (63420)", value: "63420" },
  { label: "APCHAT (63420)", value: "63420" },
  { label: "ARCONSAT (63250)", value: "63250" },
  { label: "ARDES (63420)", value: "63420" },
  { label: "ARLANC (63220)", value: "63220" },
  { label: "ARS LES FAVETS (63700)", value: "63700" },
  { label: "ARTONNE (63460)", value: "63460" },
  { label: "AUBIAT (63260)", value: "63260" },
  { label: "AUBIAT (63260)", value: "63260" },
  { label: "AUBIERE (63170)", value: "63170" },
  { label: "AUBUSSON D AUVERGNE (63120)", value: "63120" },
  { label: "AUGEROLLES (63930)", value: "63930" },
  { label: "AUGNAT (63340)", value: "63340" },
  { label: "AULNAT (63510)", value: "63510" },
  { label: "AURIERES (63210)", value: "63210" },
  { label: "AUTHEZAT (63114)", value: "63114" },
  { label: "AUZAT LA COMBELLE (63570)", value: "63570" },
  { label: "AUZAT LA COMBELLE (63570)", value: "63570" },
  { label: "AUZELLES (63590)", value: "63590" },
  { label: "AVEZE (63690)", value: "63690" },
  { label: "AYAT SUR SIOULE (63390)", value: "63390" },
  { label: "AYDAT (63970)", value: "63970" },
  { label: "BAFFIE (63600)", value: "63600" },
  { label: "BAGNOLS (63810)", value: "63810" },
  { label: "BANSAT (63570)", value: "63570" },
  { label: "BAS ET LEZAT (63310)", value: "63310" },
  { label: "BEAULIEU (63570)", value: "63570" },
  { label: "BEAUMONT (63110)", value: "63110" },
  { label: "BEAUMONT LES RANDAN (63310)", value: "63310" },
  { label: "BEAUREGARD L EVEQUE (63116)", value: "63116" },
  { label: "BEAUREGARD VENDON (63460)", value: "63460" },
  { label: "BERGONNE (63500)", value: "63500" },
  { label: "BERTIGNAT (63480)", value: "63480" },
  { label: "BESSE ET ST ANASTAISE (63610)", value: "63610" },
  { label: "BESSE ET ST ANASTAISE (63610)", value: "63610" },
  { label: "BESSE ET ST ANASTAISE (63610)", value: "63610" },
  { label: "BEURIERES (63220)", value: "63220" },
  { label: "BILLOM (63160)", value: "63160" },
  { label: "BIOLLET (63640)", value: "63640" },
  { label: "BLANZAT (63112)", value: "63112" },
  { label: "BLOT L EGLISE (63440)", value: "63440" },
  { label: "BONGHEAT (63160)", value: "63160" },
  { label: "BORT L ETANG (63190)", value: "63190" },
  { label: "BOUDES (63340)", value: "63340" },
  { label: "LA BOURBOULE (63150)", value: "63150" },
  { label: "BOURG LASTIC (63760)", value: "63760" },
  { label: "BOUZEL (63910)", value: "63910" },
  { label: "BRASSAC LES MINES (63570)", value: "63570" },
  { label: "BRENAT (63500)", value: "63500" },
  { label: "LE BREUIL SUR COUZE (63340)", value: "63340" },
  { label: "BRIFFONS (63820)", value: "63820" },
  { label: "LE BROC (63500)", value: "63500" },
  { label: "BROMONT LAMOTHE (63230)", value: "63230" },
  { label: "BROUSSE (63490)", value: "63490" },
  { label: "LE BRUGERON (63880)", value: "63880" },
  { label: "BULHON (63350)", value: "63350" },
  { label: "BUSSEOL (63270)", value: "63270" },
  { label: "BUSSIERES (63330)", value: "63330" },
  { label: "BUSSIERES ET PRUNS (63260)", value: "63260" },
  { label: "BUXIERES SOUS MONTAIGUT (63700)", value: "63700" },
  { label: "CEBAZAT (63118)", value: "63118" },
  { label: "LA CELLE (63620)", value: "63620" },
  { label: "CEILLOUX (63520)", value: "63520" },
  { label: "CELLES SUR DUROLLE (63250)", value: "63250" },
  { label: "CELLES SUR DUROLLE (63250)", value: "63250" },
  { label: "LA CELLETTE (63330)", value: "63330" },
  { label: "LE CENDRE (63670)", value: "63670" },
  { label: "CEYRAT (63122)", value: "63122" },
  { label: "CEYRAT (63122)", value: "63122" },
  { label: "CEYSSAT (63210)", value: "63210" },
  { label: "CHABRELOCHE (63250)", value: "63250" },
  { label: "CHADELEUF (63320)", value: "63320" },
  { label: "CHALUS (63340)", value: "63340" },
  { label: "CHAMALIERES (63400)", value: "63400" },
  { label: "CHAMBON SUR DOLORE (63980)", value: "63980" },
  { label: "CHAMBON SUR LAC (63790)", value: "63790" },
  { label: "CHAMPAGNAT LE JEUNE (63580)", value: "63580" },
  { label: "CHAMPEIX (63320)", value: "63320" },
  { label: "CHAMPETIERES (63600)", value: "63600" },
  { label: "CHAMPS (63440)", value: "63440" },
  { label: "CHANAT LA MOUTEYRE (63530)", value: "63530" },
  { label: "CHANONAT (63450)", value: "63450" },
  { label: "CHAPDES BEAUFORT (63230)", value: "63230" },
  { label: "LA CHAPELLE AGNON (63590)", value: "63590" },
  { label: "LA CHAPELLE MARCOUSSE (63420)", value: "63420" },
  { label: "LA CHAPELLE SUR USSON (63580)", value: "63580" },
  { label: "CHAPPES (63720)", value: "63720" },
  { label: "CHAPTUZAT (63260)", value: "63260" },
  { label: "CHARBONNIER LES MINES (63340)", value: "63340" },
  { label: "CHARBONNIERES LES VARENNES (63410)", value: "63410" },
  { label: "CHARBONNIERES LES VARENNES (63410)", value: "63410" },
  { label: "CHARBONNIERES LES VIEILLES (63410)", value: "63410" },
  { label: "CHARENSAT (63640)", value: "63640" },
  { label: "CHARNAT (63290)", value: "63290" },
  { label: "CHAS (63160)", value: "63160" },
  { label: "CHASSAGNE (63320)", value: "63320" },
  { label: "CHASTREIX (63680)", value: "63680" },
  { label: "CHATEAUGAY (63119)", value: "63119" },
  { label: "CHATEAUNEUF LES BAINS (63390)", value: "63390" },
  { label: "CHATEAU SUR CHER (63330)", value: "63330" },
  { label: "CHATELDON (63290)", value: "63290" },
  { label: "CHATEL GUYON (63140)", value: "63140" },
  { label: "CHATEL GUYON (63140)", value: "63140" },
  { label: "LA CHAULME (63660)", value: "63660" },
  { label: "CHAUMONT LE BOURG (63220)", value: "63220" },
  { label: "CHAURIAT (63117)", value: "63117" },
  { label: "CHAVAROUX (63720)", value: "63720" },
  { label: "LE CHEIX (63200)", value: "63200" },
  { label: "CHIDRAC (63320)", value: "63320" },
  { label: "CISTERNES LA FORET (63740)", value: "63740" },
  { label: "CLEMENSAT (63320)", value: "63320" },
  { label: "CLERLANDE (63720)", value: "63720" },
  { label: "CLERMONT FERRAND (63000)", value: "63000" },
  { label: "CLERMONT FERRAND (63100)", value: "63100" },
  { label: "COLLANGES (63340)", value: "63340" },
  { label: "COMBRAILLES (63380)", value: "63380" },
  { label: "COMBRONDE (63460)", value: "63460" },
  { label: "COMPAINS (63610)", value: "63610" },
  { label: "CONDAT EN COMBRAILLE (63380)", value: "63380" },
  { label: "CONDAT LES MONTBOISSIER (63490)", value: "63490" },
  { label: "CORENT (63730)", value: "63730" },
  { label: "COUDES (63114)", value: "63114" },
  { label: "COURGOUL (63320)", value: "63320" },
  { label: "COURNOLS (63450)", value: "63450" },
  { label: "COURNON D AUVERGNE (63800)", value: "63800" },
  { label: "COURPIERE (63120)", value: "63120" },
  { label: "LE CREST (63450)", value: "63450" },
  { label: "CREVANT LAVEINE (63350)", value: "63350" },
  { label: "CROS (63810)", value: "63810" },
  { label: "LA CROUZILLE (63700)", value: "63700" },
  { label: "CULHAT (63350)", value: "63350" },
  { label: "CUNLHAT (63590)", value: "63590" },
  { label: "DAUZAT SUR VODABLE (63340)", value: "63340" },
  { label: "DAVAYAT (63200)", value: "63200" },
  { label: "DOMAIZE (63520)", value: "63520" },
  { label: "DORANGES (63220)", value: "63220" },
  { label: "DORAT (63300)", value: "63300" },
  { label: "DORE L EGLISE (63220)", value: "63220" },
  { label: "DURMIGNAT (63700)", value: "63700" },
  { label: "DURTOL (63830)", value: "63830" },
  { label: "ECHANDELYS (63980)", value: "63980" },
  { label: "EFFIAT (63260)", value: "63260" },
  { label: "EGLISENEUVE D ENTRAIGUES (63850)", value: "63850" },
  { label: "EGLISENEUVE DES LIARDS (63490)", value: "63490" },
  { label: "EGLISENEUVE PRES BILLOM (63160)", value: "63160" },
  { label: "EGLISOLLES (63840)", value: "63840" },
  { label: "ENNEZAT (63720)", value: "63720" },
  { label: "ENTRAIGUES (63720)", value: "63720" },
  { label: "ENVAL (63530)", value: "63530" },
  { label: "ESCOUTOUX (63300)", value: "63300" },
  { label: "ESPINASSE (63390)", value: "63390" },
  { label: "ESPINCHAL (63850)", value: "63850" },
  { label: "ESPIRAT (63160)", value: "63160" },
  { label: "ESTANDEUIL (63520)", value: "63520" },
  { label: "ESTEIL (63570)", value: "63570" },
  { label: "FAYET LE CHATEAU (63160)", value: "63160" },
  { label: "FAYET RONAYE (63630)", value: "63630" },
  { label: "FERNOEL (63620)", value: "63620" },
  { label: "AULHAT FLAT (63500)", value: "63500" },
  { label: "AULHAT FLAT (63500)", value: "63500" },
  { label: "LA FORIE (63600)", value: "63600" },
  { label: "FOURNOLS (63980)", value: "63980" },
  { label: "GELLES (63740)", value: "63740" },
  { label: "GERZAT (63360)", value: "63360" },
  { label: "GIAT (63620)", value: "63620" },
  { label: "GIGNAT (63340)", value: "63340" },
  { label: "GIMEAUX (63200)", value: "63200" },
  { label: "GLAINE MONTAIGUT (63160)", value: "63160" },
  { label: "LA GODIVELLE (63850)", value: "63850" },
  { label: "LA GOUTELLE (63230)", value: "63230" },
  { label: "GOUTTIERES (63390)", value: "63390" },
  { label: "GRANDEYROLLES (63320)", value: "63320" },
  { label: "GRANDRIF (63600)", value: "63600" },
  { label: "GRANDVAL (63890)", value: "63890" },
  { label: "HERMENT (63470)", value: "63470" },
  { label: "HEUME L EGLISE (63210)", value: "63210" },
  { label: "ISSERTEAUX (63270)", value: "63270" },
  { label: "ISSOIRE (63500)", value: "63500" },
  { label: "JOB (63990)", value: "63990" },
  { label: "JOZE (63350)", value: "63350" },
  { label: "JOZERAND (63460)", value: "63460" },
  { label: "JUMEAUX (63570)", value: "63570" },
  { label: "LABESSETTE (63690)", value: "63690" },
  { label: "LACHAUX (63290)", value: "63290" },
  { label: "LAMONTGIE (63570)", value: "63570" },
  { label: "LANDOGNE (63380)", value: "63380" },
  { label: "LAPEYROUSE (63700)", value: "63700" },
  { label: "LAPS (63270)", value: "63270" },
  { label: "LAQUEUILLE (63820)", value: "63820" },
  { label: "LARODDE (63690)", value: "63690" },
  { label: "LASTIC (63760)", value: "63760" },
  { label: "LA TOUR D AUVERGNE (63680)", value: "63680" },
  { label: "LEMPDES (63370)", value: "63370" },
  { label: "LEMPTY (63190)", value: "63190" },
  { label: "LEZOUX (63190)", value: "63190" },
  { label: "LIMONS (63290)", value: "63290" },
  { label: "LISSEUIL (63440)", value: "63440" },
  { label: "LOUBEYRAT (63410)", value: "63410" },
  { label: "LUDESSE (63320)", value: "63320" },
  { label: "LUSSAT (63360)", value: "63360" },
  { label: "LUZILLAT (63350)", value: "63350" },
  { label: "MADRIAT (63340)", value: "63340" },
  { label: "MALAUZAT (63200)", value: "63200" },
  { label: "MALINTRAT (63510)", value: "63510" },
  { label: "MANGLIEU (63270)", value: "63270" },
  { label: "MANZAT (63410)", value: "63410" },
  { label: "MARAT (63480)", value: "63480" },
  { label: "MARCILLAT (63440)", value: "63440" },
  { label: "MAREUGHEOL (63340)", value: "63340" },
  { label: "MARINGUES (63350)", value: "63350" },
  { label: "MARSAC EN LIVRADOIS (63940)", value: "63940" },
  { label: "MARSAT (63200)", value: "63200" },
  { label: "LES MARTRES D ARTIERE (63430)", value: "63430" },
  { label: "LES MARTRES DE VEYRE (63730)", value: "63730" },
  { label: "MARTRES SUR MORGE (63720)", value: "63720" },
  { label: "MAUZUN (63160)", value: "63160" },
  { label: "MAYRES (63220)", value: "63220" },
  { label: "MAZAYE (63230)", value: "63230" },
  { label: "MAZOIRES (63420)", value: "63420" },
  { label: "MEDEYROLLES (63220)", value: "63220" },
  { label: "MEILHAUD (63320)", value: "63320" },
  { label: "MENAT (63560)", value: "63560" },
  { label: "MENETROL (63200)", value: "63200" },
  { label: "MESSEIX (63750)", value: "63750" },
  { label: "MESSEIX (63750)", value: "63750" },
  { label: "MESSEIX (63750)", value: "63750" },
  { label: "MUR SUR ALLIER (63111)", value: "63111" },
  { label: "MUR SUR ALLIER (63115)", value: "63115" },
  { label: "MIREFLEURS (63730)", value: "63730" },
  { label: "MIREMONT (63380)", value: "63380" },
  { label: "MOISSAT (63190)", value: "63190" },
  { label: "LE MONESTIER (63890)", value: "63890" },
  { label: "LA MONNERIE LE MONTEL (63650)", value: "63650" },
  { label: "MONS (63310)", value: "63310" },
  { label: "MONTAIGUT EN COMBRAILLE (63700)", value: "63700" },
  { label: "MONTAIGUT LE BLANC (63320)", value: "63320" },
  { label: "MONTCEL (63460)", value: "63460" },
  { label: "MONT DORE (63240)", value: "63240" },
  { label: "MONTEL DE GELAT (63380)", value: "63380" },
  { label: "MONTFERMY (63230)", value: "63230" },
  { label: "MONTMORIN (63160)", value: "63160" },
  { label: "MONTPENSIER (63260)", value: "63260" },
  { label: "MONTPEYROUX (63114)", value: "63114" },
  { label: "MORIAT (63340)", value: "63340" },
  { label: "MOUREUILLE (63700)", value: "63700" },
  { label: "CHAMBARON SUR MORGE (63200)", value: "63200" },
  { label: "CHAMBARON SUR MORGE (63200)", value: "63200" },
  { label: "MOZAC (63200)", value: "63200" },
  { label: "MURAT LE QUAIRE (63150)", value: "63150" },
  { label: "MUROL (63790)", value: "63790" },
  { label: "NEBOUZAT (63210)", value: "63210" },
  { label: "NERONDE SUR DORE (63120)", value: "63120" },
  { label: "NESCHERS (63320)", value: "63320" },
  { label: "NEUF EGLISE (63560)", value: "63560" },
  { label: "NEUVILLE (63160)", value: "63160" },
  { label: "NOALHAT (63290)", value: "63290" },
  { label: "NOHANENT (63830)", value: "63830" },
  { label: "NONETTE ORSONNETTE (63340)", value: "63340" },
  { label: "NONETTE ORSONNETTE (63340)", value: "63340" },
  { label: "NOVACELLES (63220)", value: "63220" },
  { label: "OLBY (63210)", value: "63210" },
  { label: "OLLIERGUES (63880)", value: "63880" },
  { label: "OLLOIX (63450)", value: "63450" },
  { label: "OLMET (63880)", value: "63880" },
  { label: "ORBEIL (63500)", value: "63500" },
  { label: "ORCET (63670)", value: "63670" },
  { label: "ORCINES (63870)", value: "63870" },
  { label: "ORCIVAL (63210)", value: "63210" },
  { label: "ORLEAT (63190)", value: "63190" },
  { label: "PALLADUC (63550)", value: "63550" },
  { label: "PARDINES (63500)", value: "63500" },
  { label: "PARENT (63270)", value: "63270" },
  { label: "PARENTIGNAT (63500)", value: "63500" },
  { label: "PASLIERES (63290)", value: "63290" },
  { label: "PERIGNAT LES SARLIEVE (63170)", value: "63170" },
  { label: "PERIGNAT SUR ALLIER (63800)", value: "63800" },
  { label: "PERPEZAT (63210)", value: "63210" },
  { label: "PERRIER (63500)", value: "63500" },
  { label: "PESCHADOIRES (63920)", value: "63920" },
  { label: "PESCHADOIRES (63920)", value: "63920" },
  { label: "PESLIERES (63580)", value: "63580" },
  { label: "PESSAT VILLENEUVE (63200)", value: "63200" },
  { label: "PICHERANDE (63113)", value: "63113" },
  { label: "PIGNOLS (63270)", value: "63270" },
  { label: "PIONSAT (63330)", value: "63330" },
  { label: "PLAUZAT (63730)", value: "63730" },
  { label: "PONTAUMUR (63380)", value: "63380" },
  { label: "PONT DU CHATEAU (63430)", value: "63430" },
  { label: "PONTGIBAUD (63230)", value: "63230" },
  { label: "POUZOL (63440)", value: "63440" },
  { label: "LES PRADEAUX (63500)", value: "63500" },
  { label: "PROMPSAT (63200)", value: "63200" },
  { label: "PRONDINES (63470)", value: "63470" },
  { label: "PULVERIERES (63230)", value: "63230" },
  { label: "PUY GUILLAUME (63290)", value: "63290" },
  { label: "PUY ST GULMIER (63470)", value: "63470" },
  { label: "LE QUARTIER (63330)", value: "63330" },
  { label: "QUEUILLE (63780)", value: "63780" },
  { label: "RANDAN (63310)", value: "63310" },
  { label: "RAVEL (63190)", value: "63190" },
  { label: "REIGNAT (63160)", value: "63160" },
  { label: "LA RENAUDIE (63930)", value: "63930" },
  { label: "RENTIERES (63420)", value: "63420" },
  { label: "RIOM (63200)", value: "63200" },
  { label: "RIS (63290)", value: "63290" },
  { label: "LA ROCHE BLANCHE (63670)", value: "63670" },
  { label: "ROCHE CHARLES LA MAYRAND (63420)", value: "63420" },
  { label: "ROCHE CHARLES LA MAYRAND (63420)", value: "63420" },
  { label: "ROCHE D AGOUX (63330)", value: "63330" },
  { label: "ROCHEFORT MONTAGNE (63210)", value: "63210" },
  { label: "LA ROCHE NOIRE (63800)", value: "63800" },
  { label: "ROMAGNAT (63540)", value: "63540" },
  { label: "ROMAGNAT (63540)", value: "63540" },
  { label: "ROMAGNAT (63540)", value: "63540" },
  { label: "ROYAT (63130)", value: "63130" },
  { label: "SAILLANT (63840)", value: "63840" },
  { label: "STE AGATHE (63120)", value: "63120" },
  { label: "ST AGOULIN (63260)", value: "63260" },
  { label: "ST ALYRE D ARLANC (63220)", value: "63220" },
  { label: "ST ALYRE ES MONTAGNE (63420)", value: "63420" },
  { label: "ST AMANT ROCHE SAVINE (63890)", value: "63890" },
  { label: "ST AMANT TALLENDE (63450)", value: "63450" },
  { label: "ST ANDRE LE COQ (63310)", value: "63310" },
  { label: "ST ANGEL (63410)", value: "63410" },
  { label: "ST ANTHEME (63660)", value: "63660" },
  { label: "ST AVIT (63380)", value: "63380" },
  { label: "ST BABEL (63500)", value: "63500" },
  { label: "ST BEAUZIRE (63360)", value: "63360" },
  { label: "ST BONNET LE BOURG (63630)", value: "63630" },
  { label: "ST BONNET LE CHASTEL (63630)", value: "63630" },
  { label: "ST BONNET LES ALLIER (63800)", value: "63800" },
  { label: "ST BONNET PRES ORCIVAL (63210)", value: "63210" },
  { label: "ST BONNET PRES RIOM (63200)", value: "63200" },
  { label: "STE CATHERINE (63580)", value: "63580" },
  { label: "STE CHRISTINE (63390)", value: "63390" },
  { label: "ST CIRGUES SUR COUZE (63320)", value: "63320" },
  { label: "ST CLEMENT DE VALORGUE (63660)", value: "63660" },
  { label: "ST CLEMENT DE REGNAT (63310)", value: "63310" },
  { label: "ST DENIS COMBARNAZAT (63310)", value: "63310" },
  { label: "ST DIER D AUVERGNE (63520)", value: "63520" },
  { label: "ST DIERY (63320)", value: "63320" },
  { label: "ST DIERY (63320)", value: "63320" },
  { label: "ST DONAT (63680)", value: "63680" },
  { label: "ST ELOY LA GLACIERE (63890)", value: "63890" },
  { label: "ST ELOY LES MINES (63700)", value: "63700" },
  { label: "ST ETIENNE DES CHAMPS (63380)", value: "63380" },
  { label: "ST ETIENNE SUR USSON (63580)", value: "63580" },
  { label: "ST FERREOL DES COTES (63600)", value: "63600" },
  { label: "ST FLORET (63320)", value: "63320" },
  { label: "ST FLOUR L ETANG (63520)", value: "63520" },
  { label: "ST GAL SUR SIOULE (63440)", value: "63440" },
  { label: "ST GENES CHAMPANELLE (63122)", value: "63122" },
  { label: "ST GENES CHAMPANELLE (63122)", value: "63122" },
  { label: "ST GENES CHAMPANELLE (63122)", value: "63122" },
  { label: "ST GENES CHAMPANELLE (63122)", value: "63122" },
  { label: "ST GENES CHAMPANELLE (63122)", value: "63122" },
  { label: "ST GENES CHAMPESPE (63850)", value: "63850" },
  { label: "ST GENES DU RETZ (63260)", value: "63260" },
  { label: "ST GENES LA TOURETTE (63580)", value: "63580" },
  { label: "ST GEORGES DE MONS (63780)", value: "63780" },
  { label: "ST GEORGES SUR ALLIER (63800)", value: "63800" },
  { label: "ST GERMAIN PRES HERMENT (63470)", value: "63470" },
  { label: "ST GERMAIN LEMBRON (63340)", value: "63340" },
  { label: "ST GERMAIN L HERM (63630)", value: "63630" },
  { label: "ST GERVAIS D AUVERGNE (63390)", value: "63390" },
  { label: "ST GERVAIS SOUS MEYMONT (63880)", value: "63880" },
  { label: "ST GERVAZY (63340)", value: "63340" },
  { label: "ST HERENT (63340)", value: "63340" },
  { label: "ST HILAIRE LA CROIX (63440)", value: "63440" },
  { label: "ST HILAIRE LES MONGES (63380)", value: "63380" },
  { label: "ST HILAIRE (63330)", value: "63330" },
  { label: "ST IGNAT (63720)", value: "63720" },
  { label: "ST JACQUES D AMBUR (63230)", value: "63230" },
  { label: "ST JEAN D HEURS (63190)", value: "63190" },
  { label: "ST JEAN DES OLLIERES (63520)", value: "63520" },
  { label: "ST JEAN EN VAL (63490)", value: "63490" },
  { label: "ST JEAN ST GERVAIS (63570)", value: "63570" },
  { label: "ST JULIEN DE COPPEL (63160)", value: "63160" },
  { label: "ST JULIEN LA GENESTE (63390)", value: "63390" },
  { label: "ST JULIEN PUY LAVEZE (63820)", value: "63820" },
  { label: "ST JUST (63600)", value: "63600" },
  { label: "ST LAURE (63350)", value: "63350" },
  { label: "ST MAIGNER (63330)", value: "63330" },
  { label: "ST MARTIN DES OLMES (63600)", value: "63600" },
  { label: "ST MARTIN DES PLAINS (63570)", value: "63570" },
  { label: "ST MARTIN D OLLIERES (63580)", value: "63580" },
  { label: "ST MAURICE PRES PIONSAT (63330)", value: "63330" },
  { label: "ST MAURICE (63270)", value: "63270" },
  { label: "ST MYON (63460)", value: "63460" },
  { label: "ST NECTAIRE (63710)", value: "63710" },
  { label: "ST OURS (63230)", value: "63230" },
  { label: "ST PARDOUX (63440)", value: "63440" },
  { label: "ST PIERRE COLAMINE (63610)", value: "63610" },
  { label: "ST PIERRE LA BOURLHONNE (63480)", value: "63480" },
  { label: "ST PIERRE LE CHASTEL (63230)", value: "63230" },
  { label: "ST PIERRE ROCHE (63210)", value: "63210" },
  { label: "ST PRIEST BRAMEFANT (63310)", value: "63310" },
  { label: "ST PRIEST DES CHAMPS (63640)", value: "63640" },
  { label: "ST QUENTIN SUR SAUXILLANGES (63490)", value: "63490" },
  { label: "ST QUINTIN SUR SIOULE (63440)", value: "63440" },
  { label: "ST REMY DE BLOT (63440)", value: "63440" },
  { label: "ST REMY DE CHARGNAT (63500)", value: "63500" },
  { label: "ST REMY SUR DUROLLE (63550)", value: "63550" },
  { label: "ST ROMAIN (63660)", value: "63660" },
  { label: "ST SANDOUX (63450)", value: "63450" },
  { label: "ST SATURNIN (63450)", value: "63450" },
  { label: "ST SAUVES D AUVERGNE (63950)", value: "63950" },
  { label: "ST SAUVEUR LA SAGNE (63220)", value: "63220" },
  { label: "ST SULPICE (63760)", value: "63760" },
  { label: "ST SYLVESTRE PRAGOULIN (63310)", value: "63310" },
  { label: "ST VICTOR LA RIVIERE (63790)", value: "63790" },
  { label: "ST VICTOR MONTVIANEIX (63550)", value: "63550" },
  { label: "ST VINCENT (63320)", value: "63320" },
  { label: "ST YVOINE (63500)", value: "63500" },
  { label: "SALLEDES (63270)", value: "63270" },
  { label: "SARDON (63260)", value: "63260" },
  { label: "SAULZET LE FROID (63970)", value: "63970" },
  { label: "SAURET BESSERVE (63390)", value: "63390" },
  { label: "SAURIER (63320)", value: "63320" },
  { label: "SAUVAGNAT (63470)", value: "63470" },
  { label: "SAUVAGNAT STE MARTHE (63500)", value: "63500" },
  { label: "SAUVESSANGES (63840)", value: "63840" },
  { label: "LA SAUVETAT (63730)", value: "63730" },
  { label: "SAUVIAT (63120)", value: "63120" },
  { label: "SAUXILLANGES (63490)", value: "63490" },
  { label: "SAVENNES (63750)", value: "63750" },
  { label: "SAYAT (63530)", value: "63530" },
  { label: "SERMENTIZON (63120)", value: "63120" },
  { label: "SERVANT (63560)", value: "63560" },
  { label: "SEYCHALLES (63190)", value: "63190" },
  { label: "SINGLES (63690)", value: "63690" },
  { label: "SOLIGNAT (63500)", value: "63500" },
  { label: "SUGERES (63490)", value: "63490" },
  { label: "SURAT (63720)", value: "63720" },
  { label: "TALLENDE (63450)", value: "63450" },
  { label: "TAUVES (63690)", value: "63690" },
  { label: "TEILHEDE (63460)", value: "63460" },
  { label: "TEILHET (63560)", value: "63560" },
  { label: "TERNANT LES EAUX (63340)", value: "63340" },
  { label: "THIERS (63300)", value: "63300" },
  { label: "THIOLIERES (63600)", value: "63600" },
  { label: "THURET (63260)", value: "63260" },
  { label: "TORTEBESSE (63470)", value: "63470" },
  { label: "TOURS SUR MEYMONT (63590)", value: "63590" },
  { label: "TOURZEL RONZIERES (63320)", value: "63320" },
  { label: "TRALAIGUES (63380)", value: "63380" },
  { label: "TREMOUILLE ST LOUP (63810)", value: "63810" },
  { label: "TREZIOUX (63520)", value: "63520" },
  { label: "USSON (63490)", value: "63490" },
  { label: "VALBELEIX (63610)", value: "63610" },
  { label: "VALCIVIERES (63600)", value: "63600" },
  { label: "VALZ SOUS CHATEAUNEUF (63580)", value: "63580" },
  { label: "VARENNES SUR MORGE (63720)", value: "63720" },
  { label: "VARENNES SUR USSON (63500)", value: "63500" },
  { label: "VASSEL (63910)", value: "63910" },
  { label: "VENSAT (63260)", value: "63260" },
  { label: "VERGHEAS (63330)", value: "63330" },
  { label: "LE VERNET CHAMEANE (63580)", value: "63580" },
  { label: "LE VERNET CHAMEANE (63580)", value: "63580" },
  { label: "LE VERNET STE MARGUERITE (63710)", value: "63710" },
  { label: "VERNEUGHEOL (63470)", value: "63470" },
  { label: "VERNINES (63210)", value: "63210" },
  { label: "VERRIERES (63320)", value: "63320" },
  { label: "VERTAIZON (63910)", value: "63910" },
  { label: "VERTOLAYE (63480)", value: "63480" },
  { label: "VEYRE MONTON (63960)", value: "63960" },
  { label: "VICHEL (63340)", value: "63340" },
  { label: "VIC LE COMTE (63270)", value: "63270" },
  { label: "VIC LE COMTE (63270)", value: "63270" },
  { label: "VILLENEUVE (63340)", value: "63340" },
  { label: "VILLENEUVE LES CERFS (63310)", value: "63310" },
  { label: "VILLOSSANGES (63380)", value: "63380" },
  { label: "VINZELLES (63350)", value: "63350" },
  { label: "VIRLET (63330)", value: "63330" },
  { label: "VISCOMTAT (63250)", value: "63250" },
  { label: "VITRAC (63410)", value: "63410" },
  { label: "VIVEROLS (63840)", value: "63840" },
  { label: "VODABLE (63500)", value: "63500" },
  { label: "VOINGT (63620)", value: "63620" },
  { label: "VOLLORE MONTAGNE (63120)", value: "63120" },
  { label: "VOLLORE VILLE (63120)", value: "63120" },
  { label: "VOLVIC (63530)", value: "63530" },
  { label: "YOUX (63700)", value: "63700" },
  { label: "YOUX (63700)", value: "63700" },
  { label: "YRONDE ET BURON (63270)", value: "63270" },
  { label: "YSSAC LA TOURETTE (63200)", value: "63200" },
  { label: "AAST (64460)", value: "64460" },
  { label: "ABERE (64160)", value: "64160" },
  { label: "ABIDOS (64150)", value: "64150" },
  { label: "ABITAIN (64390)", value: "64390" },
  { label: "ABOS (64360)", value: "64360" },
  { label: "ACCOUS (64490)", value: "64490" },
  { label: "AGNOS (64400)", value: "64400" },
  { label: "AHAXE ALCIETTE BASCASSAN (64220)", value: "64220" },
  { label: "AHETZE (64210)", value: "64210" },
  { label: "AICIRITS CAMOU SUHAST (64120)", value: "64120" },
  { label: "AICIRITS CAMOU SUHAST (64120)", value: "64120" },
  { label: "AINCILLE (64220)", value: "64220" },
  { label: "AINHARP (64130)", value: "64130" },
  { label: "AINHICE MONGELOS (64220)", value: "64220" },
  { label: "AINHOA (64250)", value: "64250" },
  { label: "ALCAY ALCABEHETY SUNHARETTE (64470)", value: "64470" },
  { label: "ALDUDES (64430)", value: "64430" },
  { label: "ALOS SIBAS ABENSE (64470)", value: "64470" },
  { label: "AMENDEUIX ONEIX (64120)", value: "64120" },
  { label: "AMOROTS SUCCOS (64120)", value: "64120" },
  { label: "ANDOINS (64420)", value: "64420" },
  { label: "ANDREIN (64390)", value: "64390" },
  { label: "ANGAIS (64510)", value: "64510" },
  { label: "ANGLET (64600)", value: "64600" },
  { label: "ANGOUS (64190)", value: "64190" },
  { label: "ANHAUX (64220)", value: "64220" },
  { label: "ANOS (64160)", value: "64160" },
  { label: "ANOYE (64350)", value: "64350" },
  { label: "ARAMITS (64570)", value: "64570" },
  { label: "ARANCOU (64270)", value: "64270" },
  { label: "ARAUJUZON (64190)", value: "64190" },
  { label: "ARAUX (64190)", value: "64190" },
  { label: "ARBERATS SILLEGUE (64120)", value: "64120" },
  { label: "ARBONNE (64210)", value: "64210" },
  { label: "ARBOUET SUSSAUTE (64120)", value: "64120" },
  { label: "ARBUS (64230)", value: "64230" },
  { label: "ARCANGUES (64200)", value: "64200" },
  { label: "AREN (64400)", value: "64400" },
  { label: "ARETTE (64570)", value: "64570" },
  { label: "ARESSY (64320)", value: "64320" },
  { label: "ARGAGNON (64300)", value: "64300" },
  { label: "ARGELOS (64450)", value: "64450" },
  { label: "ARGET (64410)", value: "64410" },
  { label: "ARHANSUS (64120)", value: "64120" },
  { label: "ARMENDARITS (64640)", value: "64640" },
  { label: "ARNEGUY (64220)", value: "64220" },
  { label: "ARNOS (64370)", value: "64370" },
  { label: "AROUE ITHOROTS OLHAIBY (64120)", value: "64120" },
  { label: "AROUE ITHOROTS OLHAIBY (64120)", value: "64120" },
  { label: "ARRAST LARREBIEU (64130)", value: "64130" },
  { label: "ARRAUTE CHARRITTE (64120)", value: "64120" },
  { label: "ARRICAU BORDES (64350)", value: "64350" },
  { label: "ARRIEN (64420)", value: "64420" },
  { label: "ARROS DE NAY (64800)", value: "64800" },
  { label: "ARROSES (64350)", value: "64350" },
  { label: "ARTHEZ DE BEARN (64370)", value: "64370" },
  { label: "ARTHEZ D ASSON (64800)", value: "64800" },
  { label: "ARTIGUELOUTAN (64420)", value: "64420" },
  { label: "ARTIGUELOUVE (64230)", value: "64230" },
  { label: "ARTIX (64170)", value: "64170" },
  { label: "ARUDY (64260)", value: "64260" },
  { label: "ARZACQ ARRAZIGUET (64410)", value: "64410" },
  { label: "ASASP ARROS (64660)", value: "64660" },
  { label: "ASASP ARROS (64660)", value: "64660" },
  { label: "ASCAIN (64310)", value: "64310" },
  { label: "ASCARAT (64220)", value: "64220" },
  { label: "ASSAT (64510)", value: "64510" },
  { label: "ASSON (64800)", value: "64800" },
  { label: "ASTE BEON (64260)", value: "64260" },
  { label: "ASTIS (64450)", value: "64450" },
  { label: "ATHOS ASPIS (64390)", value: "64390" },
  { label: "AUBERTIN (64290)", value: "64290" },
  { label: "AUBIN (64230)", value: "64230" },
  { label: "AUBOUS (64330)", value: "64330" },
  { label: "AUDAUX (64190)", value: "64190" },
  { label: "AUGA (64450)", value: "64450" },
  { label: "AURIAC (64450)", value: "64450" },
  { label: "AURIONS IDERNES (64350)", value: "64350" },
  { label: "AUSSEVIELLE (64230)", value: "64230" },
  { label: "AUSSURUCQ (64130)", value: "64130" },
  { label: "AUTERRIVE (64270)", value: "64270" },
  { label: "AUTEVIELLE ST MARTIN BIDEREN (64390)", value: "64390" },
  { label: "AYDIE (64330)", value: "64330" },
  { label: "AYDIUS (64490)", value: "64490" },
  { label: "AYHERRE (64240)", value: "64240" },
  { label: "BAIGTS DE BEARN (64300)", value: "64300" },
  { label: "BALANSUN (64300)", value: "64300" },
  { label: "BALEIX (64460)", value: "64460" },
  { label: "BALIRACQ MAUMUSSON (64330)", value: "64330" },
  { label: "BALIROS (64510)", value: "64510" },
  { label: "BANCA (64430)", value: "64430" },
  { label: "BARCUS (64130)", value: "64130" },
  { label: "BARDOS (64520)", value: "64520" },
  { label: "BARINQUE (64160)", value: "64160" },
  { label: "BARRAUTE CAMU (64390)", value: "64390" },
  { label: "BARZUN (64530)", value: "64530" },
  { label: "BASSILLON VAUZE (64350)", value: "64350" },
  { label: "BASTANES (64190)", value: "64190" },
  { label: "BASSUSSARRY (64200)", value: "64200" },
  { label: "BAUDREIX (64800)", value: "64800" },
  { label: "BAYONNE (64100)", value: "64100" },
  { label: "BEDEILLE (64460)", value: "64460" },
  { label: "BEDOUS (64490)", value: "64490" },
  { label: "BEGUIOS (64120)", value: "64120" },
  { label: "BEHASQUE LAPISTE (64120)", value: "64120" },
  { label: "BEHORLEGUY (64220)", value: "64220" },
  { label: "BELLOCQ (64270)", value: "64270" },
  { label: "BENEJACQ (64800)", value: "64800" },
  { label: "BEOST (64440)", value: "64440" },
  { label: "BENTAYOU SEREE (64460)", value: "64460" },
  { label: "BERENX (64300)", value: "64300" },
  { label: "BERGOUEY VIELLENAVE (64270)", value: "64270" },
  { label: "BERGOUEY VIELLENAVE (64270)", value: "64270" },
  { label: "BERNADETS (64160)", value: "64160" },
  { label: "BERROGAIN LARUNS (64130)", value: "64130" },
  { label: "BESCAT (64260)", value: "64260" },
  { label: "BESINGRAND (64150)", value: "64150" },
  { label: "BETRACQ (64350)", value: "64350" },
  { label: "BEUSTE (64800)", value: "64800" },
  { label: "BEYRIE SUR JOYEUSE (64120)", value: "64120" },
  { label: "BEYRIE EN BEARN (64230)", value: "64230" },
  { label: "BIARRITZ (64200)", value: "64200" },
  { label: "BIDACHE (64520)", value: "64520" },
  { label: "BIDARRAY (64780)", value: "64780" },
  { label: "BIDART (64210)", value: "64210" },
  { label: "BIDOS (64400)", value: "64400" },
  { label: "BIELLE (64260)", value: "64260" },
  { label: "BILHERES (64260)", value: "64260" },
  { label: "BILLERE (64140)", value: "64140" },
  { label: "BIRIATOU (64700)", value: "64700" },
  { label: "BIRON (64300)", value: "64300" },
  { label: "BIZANOS (64320)", value: "64320" },
  { label: "BOEIL BEZING (64510)", value: "64510" },
  { label: "BONLOC (64240)", value: "64240" },
  { label: "BONNUT (64300)", value: "64300" },
  { label: "BORCE (64490)", value: "64490" },
  { label: "BORDERES (64800)", value: "64800" },
  { label: "BORDES (64510)", value: "64510" },
  { label: "BOSDARROS (64290)", value: "64290" },
  { label: "BOUCAU (64340)", value: "64340" },
  { label: "BOUEILH BOUEILHO LASQUE (64330)", value: "64330" },
  { label: "BOUGARBER (64230)", value: "64230" },
  { label: "BOUILLON (64410)", value: "64410" },
  { label: "BOUMOURT (64370)", value: "64370" },
  { label: "BOURDETTES (64800)", value: "64800" },
  { label: "BOURNOS (64450)", value: "64450" },
  { label: "BRISCOUS (64240)", value: "64240" },
  { label: "BRUGES CAPBIS MIFAGET (64800)", value: "64800" },
  { label: "BRUGES CAPBIS MIFAGET (64800)", value: "64800" },
  { label: "BRUGES CAPBIS MIFAGET (64800)", value: "64800" },
  { label: "BUGNEIN (64190)", value: "64190" },
  { label: "BUNUS (64120)", value: "64120" },
  { label: "BURGARONNE (64390)", value: "64390" },
  { label: "BUROS (64160)", value: "64160" },
  { label: "BUROSSE MENDOUSSE (64330)", value: "64330" },
  { label: "BUSSUNARITS SARRASQUETTE (64220)", value: "64220" },
  { label: "BUSTINCE IRIBERRY (64220)", value: "64220" },
  { label: "BUZIET (64680)", value: "64680" },
  { label: "BUZY (64260)", value: "64260" },
  { label: "CABIDOS (64410)", value: "64410" },
  { label: "CADILLON (64330)", value: "64330" },
  { label: "CAMBO LES BAINS (64250)", value: "64250" },
  { label: "CAME (64520)", value: "64520" },
  { label: "CAMOU CIHIGUE (64470)", value: "64470" },
  { label: "CARDESSE (64360)", value: "64360" },
  { label: "CARO (64220)", value: "64220" },
  { label: "CARRERE (64160)", value: "64160" },
  { label: "CARRESSE CASSABER (64270)", value: "64270" },
  { label: "CARRESSE CASSABER (64270)", value: "64270" },
  { label: "CASTAGNEDE (64270)", value: "64270" },
  { label: "CASTEIDE CAMI (64170)", value: "64170" },
  { label: "CASTEIDE CANDAU (64370)", value: "64370" },
  { label: "CASTEIDE DOAT (64460)", value: "64460" },
  { label: "CASTERA LOUBIX (64460)", value: "64460" },
  { label: "CASTET (64260)", value: "64260" },
  { label: "CASTETBON (64190)", value: "64190" },
  { label: "CASTETIS (64300)", value: "64300" },
  { label: "CASTETNAU CAMBLONG (64190)", value: "64190" },
  { label: "CASTETNER (64300)", value: "64300" },
  { label: "CASTETPUGON (64330)", value: "64330" },
  { label: "CASTILLON D ARTHEZ (64370)", value: "64370" },
  { label: "CASTILLON DE LEMBEYE (64350)", value: "64350" },
  { label: "CAUBIOS LOOS (64230)", value: "64230" },
  { label: "CESCAU (64170)", value: "64170" },
  { label: "CETTE EYGUN (64490)", value: "64490" },
  { label: "CHARRE (64190)", value: "64190" },
  { label: "CHARRITTE DE BAS (64130)", value: "64130" },
  { label: "CHERAUTE (64130)", value: "64130" },
  { label: "CIBOURE (64500)", value: "64500" },
  { label: "CLARACQ (64330)", value: "64330" },
  { label: "COARRAZE (64800)", value: "64800" },
  { label: "CONCHEZ DE BEARN (64330)", value: "64330" },
  { label: "CORBERE ABERES (64350)", value: "64350" },
  { label: "COSLEDAA LUBE BOAST (64160)", value: "64160" },
  { label: "COUBLUCQ (64410)", value: "64410" },
  { label: "CROUSEILLES (64350)", value: "64350" },
  { label: "CUQUERON (64360)", value: "64360" },
  { label: "DENGUIN (64230)", value: "64230" },
  { label: "DIUSSE (64330)", value: "64330" },
  { label: "DOAZON (64370)", value: "64370" },
  { label: "DOGNEN (64190)", value: "64190" },
  { label: "DOMEZAIN BERRAUTE (64120)", value: "64120" },
  { label: "DOUMY (64450)", value: "64450" },
  { label: "EAUX BONNES (64440)", value: "64440" },
  { label: "EAUX BONNES (64440)", value: "64440" },
  { label: "ESCOS (64270)", value: "64270" },
  { label: "ESCOT (64490)", value: "64490" },
  { label: "ESCOU (64870)", value: "64870" },
  { label: "ESCOUBES (64160)", value: "64160" },
  { label: "ESCOUT (64870)", value: "64870" },
  { label: "ESCURES (64350)", value: "64350" },
  { label: "ESLOURENTIES DABAN (64420)", value: "64420" },
  { label: "ESPECHEDE (64160)", value: "64160" },
  { label: "ESPELETTE (64250)", value: "64250" },
  { label: "ESPES UNDUREIN (64130)", value: "64130" },
  { label: "ESPIUTE (64390)", value: "64390" },
  { label: "ESPOEY (64420)", value: "64420" },
  { label: "ESQUIULE (64400)", value: "64400" },
  { label: "ESTERENCUBY (64220)", value: "64220" },
  { label: "ESTIALESCQ (64290)", value: "64290" },
  { label: "ESTOS (64400)", value: "64400" },
  { label: "ETCHARRY (64120)", value: "64120" },
  { label: "ETCHEBAR (64470)", value: "64470" },
  { label: "ETSAUT (64490)", value: "64490" },
  { label: "EYSUS (64400)", value: "64400" },
  { label: "ANCE FEAS (64570)", value: "64570" },
  { label: "ANCE FEAS (64570)", value: "64570" },
  { label: "FICHOUS RIUMAYOU (64410)", value: "64410" },
  { label: "GABASTON (64160)", value: "64160" },
  { label: "GABAT (64120)", value: "64120" },
  { label: "GAMARTHE (64220)", value: "64220" },
  { label: "GAN (64290)", value: "64290" },
  { label: "GARINDEIN (64130)", value: "64130" },
  { label: "GARLEDE MONDEBAT (64450)", value: "64450" },
  { label: "GARLIN (64330)", value: "64330" },
  { label: "GAROS (64410)", value: "64410" },
  { label: "GARRIS (64120)", value: "64120" },
  { label: "GAYON (64350)", value: "64350" },
  { label: "GELOS (64110)", value: "64110" },
  { label: "GER (64530)", value: "64530" },
  { label: "GERDEREST (64160)", value: "64160" },
  { label: "GERE BELESTEN (64260)", value: "64260" },
  { label: "GERONCE (64400)", value: "64400" },
  { label: "GESTAS (64190)", value: "64190" },
  { label: "GEUS D ARZACQ (64370)", value: "64370" },
  { label: "GEUS D OLORON (64400)", value: "64400" },
  { label: "GOES (64400)", value: "64400" },
  { label: "GOMER (64420)", value: "64420" },
  { label: "GOTEIN LIBARRENX (64130)", value: "64130" },
  { label: "GUETHARY (64210)", value: "64210" },
  { label: "GUICHE (64520)", value: "64520" },
  { label: "GUINARTHE PARENTIES (64390)", value: "64390" },
  { label: "GURMENCON (64400)", value: "64400" },
  { label: "GURS (64190)", value: "64190" },
  { label: "HAGETAUBIN (64370)", value: "64370" },
  { label: "HALSOU (64480)", value: "64480" },
  { label: "HASPARREN (64240)", value: "64240" },
  { label: "HAUT DE BOSDARROS (64800)", value: "64800" },
  { label: "HAUX (64470)", value: "64470" },
  { label: "HELETTE (64640)", value: "64640" },
  { label: "HENDAYE (64700)", value: "64700" },
  { label: "HERRERE (64680)", value: "64680" },
  { label: "HIGUERES SOUYE (64160)", value: "64160" },
  { label: "L'HOPITAL D ORION (64270)", value: "64270" },
  { label: "L'HOPITAL ST BLAISE (64130)", value: "64130" },
  { label: "HOSTA (64120)", value: "64120" },
  { label: "HOURS (64420)", value: "64420" },
  { label: "IBARROLLE (64120)", value: "64120" },
  { label: "IDAUX MENDY (64130)", value: "64130" },
  { label: "IDRON (64320)", value: "64320" },
  { label: "IGON (64800)", value: "64800" },
  { label: "IHOLDY (64640)", value: "64640" },
  { label: "ILHARRE (64120)", value: "64120" },
  { label: "IRISSARRY (64780)", value: "64780" },
  { label: "IROULEGUY (64220)", value: "64220" },
  { label: "ISPOURE (64220)", value: "64220" },
  { label: "ISSOR (64570)", value: "64570" },
  { label: "ISTURITS (64240)", value: "64240" },
  { label: "ITXASSOU (64250)", value: "64250" },
  { label: "IZESTE (64260)", value: "64260" },
  { label: "JASSES (64190)", value: "64190" },
  { label: "JATXOU (64480)", value: "64480" },
  { label: "JAXU (64220)", value: "64220" },
  { label: "JURANCON (64110)", value: "64110" },
  { label: "JUXUE (64120)", value: "64120" },
  { label: "LAA MONDRANS (64300)", value: "64300" },
  { label: "LAAS (64390)", value: "64390" },
  { label: "LABASTIDE CEZERACQ (64170)", value: "64170" },
  { label: "LA BASTIDE CLAIRENCE (64240)", value: "64240" },
  { label: "LABASTIDE MONREJEAU (64170)", value: "64170" },
  { label: "LABASTIDE VILLEFRANCHE (64270)", value: "64270" },
  { label: "LABATMALE (64530)", value: "64530" },
  { label: "LABATUT FIGUIERES (64460)", value: "64460" },
  { label: "LABETS BISCAY (64120)", value: "64120" },
  { label: "LABEYRIE (64300)", value: "64300" },
  { label: "LACADEE (64300)", value: "64300" },
  { label: "LACARRE (64220)", value: "64220" },
  { label: "LACARRY ARHAN CHARRITTE DE HAUT (64470)", value: "64470" },
  { label: "LACOMMANDE (64360)", value: "64360" },
  { label: "LACQ (64170)", value: "64170" },
  { label: "LACQ (64170)", value: "64170" },
  { label: "LACQ (64370)", value: "64370" },
  { label: "LAGOR (64150)", value: "64150" },
  { label: "LAGOS (64800)", value: "64800" },
  { label: "LAGUINGE RESTOUE (64470)", value: "64470" },
  { label: "LAHONCE (64990)", value: "64990" },
  { label: "LAHONTAN (64270)", value: "64270" },
  { label: "LAHOURCADE (64150)", value: "64150" },
  { label: "LALONGUE (64350)", value: "64350" },
  { label: "LALONQUETTE (64450)", value: "64450" },
  { label: "LAMAYOU (64460)", value: "64460" },
  { label: "LANNE EN BARETOUS (64570)", value: "64570" },
  { label: "LANNECAUBE (64350)", value: "64350" },
  { label: "LANNEPLAA (64300)", value: "64300" },
  { label: "LANTABAT (64640)", value: "64640" },
  { label: "LARCEVEAU ARROS CIBITS (64120)", value: "64120" },
  { label: "LAROIN (64110)", value: "64110" },
  { label: "LARRAU (64560)", value: "64560" },
  { label: "LARRESSORE (64480)", value: "64480" },
  { label: "LARREULE (64410)", value: "64410" },
  { label: "LARRIBAR SORHAPURU (64120)", value: "64120" },
  { label: "LARUNS (64440)", value: "64440" },
  { label: "LARUNS (64440)", value: "64440" },
  { label: "LARUNS (64440)", value: "64440" },
  { label: "LASCLAVERIES (64450)", value: "64450" },
  { label: "LASSE (64220)", value: "64220" },
  { label: "LASSERRE (64350)", value: "64350" },
  { label: "LASSEUBE (64290)", value: "64290" },
  { label: "LASSEUBETAT (64290)", value: "64290" },
  { label: "LAY LAMIDOU (64190)", value: "64190" },
  { label: "LECUMBERRY (64220)", value: "64220" },
  { label: "LEDEUIX (64400)", value: "64400" },
  { label: "LEE (64320)", value: "64320" },
  { label: "LEES ATHAS (64490)", value: "64490" },
  { label: "LEMBEYE (64350)", value: "64350" },
  { label: "LEME (64450)", value: "64450" },
  { label: "LEREN (64270)", value: "64270" },
  { label: "LESCAR (64230)", value: "64230" },
  { label: "LESCUN (64490)", value: "64490" },
  { label: "LESPIELLE (64350)", value: "64350" },
  { label: "LESPOURCY (64160)", value: "64160" },
  { label: "LESTELLE BETHARRAM (64800)", value: "64800" },
  { label: "LICHANS SUNHAR (64470)", value: "64470" },
  { label: "LICHOS (64130)", value: "64130" },
  { label: "LICQ ATHEREY (64560)", value: "64560" },
  { label: "LIMENDOUS (64420)", value: "64420" },
  { label: "LIVRON (64530)", value: "64530" },
  { label: "LOHITZUN OYHERCQ (64120)", value: "64120" },
  { label: "LOMBIA (64160)", value: "64160" },
  { label: "LONCON (64410)", value: "64410" },
  { label: "LONS (64140)", value: "64140" },
  { label: "LOUBIENG (64300)", value: "64300" },
  { label: "LOUHOSSOA (64250)", value: "64250" },
  { label: "LOURDIOS ICHERE (64570)", value: "64570" },
  { label: "LOURENTIES (64420)", value: "64420" },
  { label: "LOUVIE JUZON (64260)", value: "64260" },
  { label: "LOUVIE SOUBIRON (64440)", value: "64440" },
  { label: "LOUVIGNY (64410)", value: "64410" },
  { label: "LUC ARMAU (64350)", value: "64350" },
  { label: "LUCARRE (64350)", value: "64350" },
  { label: "LUCGARIER (64420)", value: "64420" },
  { label: "LUCQ DE BEARN (64360)", value: "64360" },
  { label: "LURBE ST CHRISTAU (64660)", value: "64660" },
  { label: "LUSSAGNET LUSSON (64160)", value: "64160" },
  { label: "LUXE SUMBERRAUTE (64120)", value: "64120" },
  { label: "LYS (64260)", value: "64260" },
  { label: "MACAYE (64240)", value: "64240" },
  { label: "MALAUSSANNE (64410)", value: "64410" },
  { label: "MASCARAAS HARON (64330)", value: "64330" },
  { label: "MASLACQ (64300)", value: "64300" },
  { label: "MASPARRAUTE (64120)", value: "64120" },
  { label: "MASPIE LALONQUERE JUILLACQ (64350)", value: "64350" },
  { label: "MAUCOR (64160)", value: "64160" },
  { label: "MAULEON LICHARRE (64130)", value: "64130" },
  { label: "MAURE (64460)", value: "64460" },
  { label: "MAZERES LEZONS (64110)", value: "64110" },
  { label: "MAZEROLLES (64230)", value: "64230" },
  { label: "MEHARIN (64120)", value: "64120" },
  { label: "MEILLON (64510)", value: "64510" },
  { label: "MENDIONDE (64240)", value: "64240" },
  { label: "MENDITTE (64130)", value: "64130" },
  { label: "MENDIVE (64220)", value: "64220" },
  { label: "MERACQ (64410)", value: "64410" },
  { label: "MERITEIN (64190)", value: "64190" },
  { label: "MESPLEDE (64370)", value: "64370" },
  { label: "MIALOS (64410)", value: "64410" },
  { label: "MIOSSENS LANUSSE (64450)", value: "64450" },
  { label: "MIREPEIX (64800)", value: "64800" },
  { label: "MOMAS (64230)", value: "64230" },
  { label: "MOMY (64350)", value: "64350" },
  { label: "MONASSUT AUDIRACQ (64160)", value: "64160" },
  { label: "MONCAUP (64350)", value: "64350" },
  { label: "MONCAYOLLE LARRORY MENDIBIEU (64130)", value: "64130" },
  { label: "MONCLA (64330)", value: "64330" },
  { label: "MONEIN (64360)", value: "64360" },
  { label: "MONPEZAT (64350)", value: "64350" },
  { label: "MONSEGUR (64460)", value: "64460" },
  { label: "MONT (64300)", value: "64300" },
  { label: "MONT (64300)", value: "64300" },
  { label: "MONT (64300)", value: "64300" },
  { label: "MONT (64300)", value: "64300" },
  { label: "MONTAGUT (64410)", value: "64410" },
  { label: "MONTANER (64460)", value: "64460" },
  { label: "MONTARDON (64121)", value: "64121" },
  { label: "MONTAUT (64800)", value: "64800" },
  { label: "MONT DISSE (64330)", value: "64330" },
  { label: "MONTFORT (64190)", value: "64190" },
  { label: "MONTORY (64470)", value: "64470" },
  { label: "MORLAAS (64160)", value: "64160" },
  { label: "MORLANNE (64370)", value: "64370" },
  { label: "MOUGUERRE (64990)", value: "64990" },
  { label: "MOUHOUS (64330)", value: "64330" },
  { label: "MOUMOUR (64400)", value: "64400" },
  { label: "MOURENX (64150)", value: "64150" },
  { label: "MUSCULDY (64130)", value: "64130" },
  { label: "NABAS (64190)", value: "64190" },
  { label: "NARCASTET (64510)", value: "64510" },
  { label: "NARP (64190)", value: "64190" },
  { label: "NAVAILLES ANGOS (64450)", value: "64450" },
  { label: "NAVARRENX (64190)", value: "64190" },
  { label: "NAY (64800)", value: "64800" },
  { label: "NOGUERES (64150)", value: "64150" },
  { label: "NOUSTY (64420)", value: "64420" },
  { label: "OGENNE CAMPTORT (64190)", value: "64190" },
  { label: "OGEU LES BAINS (64680)", value: "64680" },
  { label: "OLORON STE MARIE (64400)", value: "64400" },
  { label: "ORAAS (64390)", value: "64390" },
  { label: "ORDIARP (64130)", value: "64130" },
  { label: "OREGUE (64120)", value: "64120" },
  { label: "ORIN (64400)", value: "64400" },
  { label: "ORION (64390)", value: "64390" },
  { label: "ORRIULE (64390)", value: "64390" },
  { label: "ORSANCO (64120)", value: "64120" },
  { label: "ORTHEZ (64300)", value: "64300" },
  { label: "ORTHEZ (64300)", value: "64300" },
  { label: "OS MARSILLON (64150)", value: "64150" },
  { label: "OSSAS SUHARE (64470)", value: "64470" },
  { label: "OSSE EN ASPE (64490)", value: "64490" },
  { label: "OSSENX (64190)", value: "64190" },
  { label: "OSSERAIN RIVAREYTE (64390)", value: "64390" },
  { label: "OSSES (64780)", value: "64780" },
  { label: "OSTABAT ASME (64120)", value: "64120" },
  { label: "OUILLON (64160)", value: "64160" },
  { label: "OUSSE (64320)", value: "64320" },
  { label: "OZENX MONTESTRUCQ (64300)", value: "64300" },
  { label: "OZENX MONTESTRUCQ (64300)", value: "64300" },
  { label: "PAGOLLE (64120)", value: "64120" },
  { label: "PARBAYSE (64360)", value: "64360" },
  { label: "PARDIES (64150)", value: "64150" },
  { label: "PARDIES PIETAT (64800)", value: "64800" },
  { label: "PAU (64000)", value: "64000" },
  { label: "PEYRELONGUE ABOS (64350)", value: "64350" },
  { label: "PIETS PLASENCE MOUSTROU (64410)", value: "64410" },
  { label: "POEY DE LESCAR (64230)", value: "64230" },
  { label: "POEY D OLORON (64400)", value: "64400" },
  { label: "POMPS (64370)", value: "64370" },
  { label: "PONSON DEBAT POUTS (64460)", value: "64460" },
  { label: "PONSON DESSUS (64460)", value: "64460" },
  { label: "PONTACQ (64530)", value: "64530" },
  { label: "PONTIACQ VIELLEPINTE (64460)", value: "64460" },
  { label: "PORTET (64330)", value: "64330" },
  { label: "POULIACQ (64410)", value: "64410" },
  { label: "POURSIUGUES BOUCOUE (64410)", value: "64410" },
  { label: "PRECHACQ JOSBAIG (64190)", value: "64190" },
  { label: "PRECHACQ NAVARRENX (64190)", value: "64190" },
  { label: "PRECILHON (64400)", value: "64400" },
  { label: "PUYOO (64270)", value: "64270" },
  { label: "RAMOUS (64270)", value: "64270" },
  { label: "REBENACQ (64260)", value: "64260" },
  { label: "RIBARROUY (64330)", value: "64330" },
  { label: "RIUPEYROUS (64160)", value: "64160" },
  { label: "RIVEHAUTE (64190)", value: "64190" },
  { label: "RONTIGNON (64110)", value: "64110" },
  { label: "ROQUIAGUE (64130)", value: "64130" },
  { label: "ST ABIT (64800)", value: "64800" },
  { label: "ST ARMOU (64160)", value: "64160" },
  { label: "ST BOES (64300)", value: "64300" },
  { label: "ST CASTIN (64160)", value: "64160" },
  { label: "STE COLOME (64260)", value: "64260" },
  { label: "ST DOS (64270)", value: "64270" },
  { label: "STE ENGRACE (64560)", value: "64560" },
  { label: "ST ESTEBEN (64640)", value: "64640" },
  { label: "ST ETIENNE DE BAIGORRY (64430)", value: "64430" },
  { label: "ST FAUST (64110)", value: "64110" },
  { label: "ST GIRONS EN BEARN (64300)", value: "64300" },
  { label: "ST GLADIE ARRIVE MUNEIN (64390)", value: "64390" },
  { label: "ST GOIN (64400)", value: "64400" },
  { label: "ST JAMMES (64160)", value: "64160" },
  { label: "ST JEAN DE LUZ (64500)", value: "64500" },
  { label: "ST JEAN LE VIEUX (64220)", value: "64220" },
  { label: "ST JEAN PIED DE PORT (64220)", value: "64220" },
  { label: "ST JEAN POUDGE (64330)", value: "64330" },
  { label: "ST JUST IBARRE (64120)", value: "64120" },
  { label: "ST LAURENT BRETAGNE (64160)", value: "64160" },
  { label: "ST MARTIN D ARBEROUE (64640)", value: "64640" },
  { label: "ST MARTIN D ARROSSA (64780)", value: "64780" },
  { label: "ST MARTIN D ARROSSA (64780)", value: "64780" },
  { label: "ST MEDARD (64370)", value: "64370" },
  { label: "ST MICHEL (64220)", value: "64220" },
  { label: "ST PALAIS (64120)", value: "64120" },
  { label: "ST PE DE LEREN (64270)", value: "64270" },
  { label: "ST PEE SUR NIVELLE (64310)", value: "64310" },
  { label: "ST PIERRE D IRUBE (64990)", value: "64990" },
  { label: "ST VINCENT (64800)", value: "64800" },
  { label: "SALIES DE BEARN (64270)", value: "64270" },
  { label: "SALLES MONGISCARD (64300)", value: "64300" },
  { label: "SALLESPISSE (64300)", value: "64300" },
  { label: "SAMES (64520)", value: "64520" },
  { label: "SAMSONS LION (64350)", value: "64350" },
  { label: "SARE (64310)", value: "64310" },
  { label: "SARPOURENX (64300)", value: "64300" },
  { label: "SARRANCE (64490)", value: "64490" },
  { label: "SAUBOLE (64420)", value: "64420" },
  { label: "SAUCEDE (64400)", value: "64400" },
  { label: "SAUGUIS ST ETIENNE (64470)", value: "64470" },
  { label: "SAULT DE NAVAILLES (64300)", value: "64300" },
  { label: "SAUVAGNON (64230)", value: "64230" },
  { label: "SAUVELADE (64150)", value: "64150" },
  { label: "SAUVETERRE DE BEARN (64390)", value: "64390" },
  { label: "SEBY (64410)", value: "64410" },
  { label: "SEDZE MAUBECQ (64160)", value: "64160" },
  { label: "SEDZERE (64160)", value: "64160" },
  { label: "SEMEACQ BLACHON (64350)", value: "64350" },
  { label: "SENDETS (64320)", value: "64320" },
  { label: "SERRES CASTET (64121)", value: "64121" },
  { label: "SERRES MORLAAS (64160)", value: "64160" },
  { label: "SERRES STE MARIE (64170)", value: "64170" },
  { label: "SEVIGNACQ MEYRACQ (64260)", value: "64260" },
  { label: "SEVIGNACQ (64160)", value: "64160" },
  { label: "SIMACOURBE (64350)", value: "64350" },
  { label: "SIROS (64230)", value: "64230" },
  { label: "SOUMOULOU (64420)", value: "64420" },
  { label: "SOURAIDE (64250)", value: "64250" },
  { label: "SUHESCUN (64780)", value: "64780" },
  { label: "SUS (64190)", value: "64190" },
  { label: "SUSMIOU (64190)", value: "64190" },
  { label: "TABAILLE USQUAIN (64190)", value: "64190" },
  { label: "TADOUSSE USSAU (64330)", value: "64330" },
  { label: "TARDETS SORHOLUS (64470)", value: "64470" },
  { label: "TARON SADIRAC VIELLENAVE (64330)", value: "64330" },
  { label: "TARSACQ (64360)", value: "64360" },
  { label: "THEZE (64450)", value: "64450" },
  { label: "TROIS VILLES (64470)", value: "64470" },
  { label: "UHART CIZE (64220)", value: "64220" },
  { label: "UHART MIXE (64120)", value: "64120" },
  { label: "URCUIT (64990)", value: "64990" },
  { label: "URDOS (64490)", value: "64490" },
  { label: "UREPEL (64430)", value: "64430" },
  { label: "UROST (64160)", value: "64160" },
  { label: "URRUGNE (64122)", value: "64122" },
  { label: "URRUGNE (64700)", value: "64700" },
  { label: "URT (64240)", value: "64240" },
  { label: "USTARITZ (64480)", value: "64480" },
  { label: "UZAN (64370)", value: "64370" },
  { label: "UZEIN (64230)", value: "64230" },
  { label: "UZOS (64110)", value: "64110" },
  { label: "VERDETS (64400)", value: "64400" },
  { label: "VIALER (64330)", value: "64330" },
  { label: "VIELLENAVE D ARTHEZ (64170)", value: "64170" },
  { label: "VIELLENAVE DE NAVARRENX (64190)", value: "64190" },
  { label: "VIELLESEGURE (64150)", value: "64150" },
  { label: "VIGNES (64410)", value: "64410" },
  { label: "VILLEFRANQUE (64990)", value: "64990" },
  { label: "VIODOS ABENSE DE BAS (64130)", value: "64130" },
  { label: "VIVEN (64450)", value: "64450" },
  { label: "ADAST (65260)", value: "65260" },
  { label: "ADE (65100)", value: "65100" },
  { label: "ADERVIELLE POUCHERGUES (65240)", value: "65240" },
  { label: "ADERVIELLE POUCHERGUES (65240)", value: "65240" },
  { label: "AGOS VIDALOS (65400)", value: "65400" },
  { label: "ALLIER (65360)", value: "65360" },
  { label: "ANCIZAN (65440)", value: "65440" },
  { label: "ANDREST (65390)", value: "65390" },
  { label: "ANERES (65150)", value: "65150" },
  { label: "ANGOS (65690)", value: "65690" },
  { label: "LES ANGLES (65100)", value: "65100" },
  { label: "ANLA (65370)", value: "65370" },
  { label: "ANSOST (65140)", value: "65140" },
  { label: "ANTICHAN (65370)", value: "65370" },
  { label: "ANTIN (65220)", value: "65220" },
  { label: "ANTIST (65200)", value: "65200" },
  { label: "ARAGNOUET (65170)", value: "65170" },
  { label: "ARBEOST (65560)", value: "65560" },
  { label: "ARCIZAC ADOUR (65360)", value: "65360" },
  { label: "ARCIZAC EZ ANGLES (65100)", value: "65100" },
  { label: "ARCIZANS AVANT (65400)", value: "65400" },
  { label: "ARCIZANS DESSUS (65400)", value: "65400" },
  { label: "ARDENGOST (65240)", value: "65240" },
  { label: "ARGELES BAGNERES (65200)", value: "65200" },
  { label: "ARGELES GAZOST (65400)", value: "65400" },
  { label: "ARIES ESPENAN (65230)", value: "65230" },
  { label: "ARNE (65670)", value: "65670" },
  { label: "ARRAS EN LAVEDAN (65400)", value: "65400" },
  { label: "ARREAU (65240)", value: "65240" },
  { label: "ARRENS MARSOUS (65400)", value: "65400" },
  { label: "ARRENS MARSOUS (65400)", value: "65400" },
  { label: "ARRODETS EZ ANGLES (65100)", value: "65100" },
  { label: "ARRODETS (65130)", value: "65130" },
  { label: "ARTAGNAN (65500)", value: "65500" },
  { label: "ARTALENS SOUIN (65400)", value: "65400" },
  { label: "ARTIGUEMY (65130)", value: "65130" },
  { label: "ARTIGUES (65100)", value: "65100" },
  { label: "ASPIN AURE (65240)", value: "65240" },
  { label: "ASPIN EN LAVEDAN (65100)", value: "65100" },
  { label: "ASQUE (65130)", value: "65130" },
  { label: "ASTE (65200)", value: "65200" },
  { label: "ASTUGUE (65200)", value: "65200" },
  { label: "AUBAREDE (65350)", value: "65350" },
  { label: "AUCUN (65400)", value: "65400" },
  { label: "AULON (65240)", value: "65240" },
  { label: "AUREILHAN (65800)", value: "65800" },
  { label: "AURENSAN (65390)", value: "65390" },
  { label: "AURIEBAT (65700)", value: "65700" },
  { label: "AVAJAN (65240)", value: "65240" },
  { label: "AVENTIGNAN (65660)", value: "65660" },
  { label: "AVERAN (65380)", value: "65380" },
  { label: "AVEUX (65370)", value: "65370" },
  { label: "AVEZAC PRAT LAHITTE (65130)", value: "65130" },
  { label: "AVEZAC PRAT LAHITTE (65130)", value: "65130" },
  { label: "AYROS ARBOUIX (65400)", value: "65400" },
  { label: "AYZAC OST (65400)", value: "65400" },
  { label: "AZEREIX (65380)", value: "65380" },
  { label: "AZET (65170)", value: "65170" },
  { label: "BAGNERES DE BIGORRE (65200)", value: "65200" },
  { label: "BAGNERES DE BIGORRE (65200)", value: "65200" },
  { label: "BAGNERES DE BIGORRE (65710)", value: "65710" },
  { label: "BANIOS (65200)", value: "65200" },
  { label: "BARBACHEN (65140)", value: "65140" },
  { label: "BARBAZAN DEBAT (65690)", value: "65690" },
  { label: "BARBAZAN DESSUS (65360)", value: "65360" },
  { label: "BAREILLES (65240)", value: "65240" },
  { label: "BARLEST (65100)", value: "65100" },
  { label: "BARRANCOUEU (65240)", value: "65240" },
  { label: "BARRY (65380)", value: "65380" },
  { label: "BARTHE (65230)", value: "65230" },
  { label: "LA BARTHE DE NESTE (65250)", value: "65250" },
  { label: "BARTRES (65100)", value: "65100" },
  { label: "BATSERE (65130)", value: "65130" },
  { label: "BAZET (65460)", value: "65460" },
  { label: "BAZILLAC (65140)", value: "65140" },
  { label: "BAZORDAN (65670)", value: "65670" },
  { label: "BAZUS AURE (65170)", value: "65170" },
  { label: "BAZUS NESTE (65250)", value: "65250" },
  { label: "BEAUCENS (65400)", value: "65400" },
  { label: "BEAUDEAN (65710)", value: "65710" },
  { label: "BEGOLE (65190)", value: "65190" },
  { label: "BENAC (65380)", value: "65380" },
  { label: "BENQUE MOLERE (65130)", value: "65130" },
  { label: "BENQUE MOLERE (65130)", value: "65130" },
  { label: "BERBERUST LIAS (65100)", value: "65100" },
  { label: "BERNAC DEBAT (65360)", value: "65360" },
  { label: "BERNAC DESSUS (65360)", value: "65360" },
  { label: "BERNADETS DEBAT (65220)", value: "65220" },
  { label: "BERNADETS DESSUS (65190)", value: "65190" },
  { label: "BERTREN (65370)", value: "65370" },
  { label: "BETBEZE (65230)", value: "65230" },
  { label: "BETPOUEY (65120)", value: "65120" },
  { label: "BETPOUY (65230)", value: "65230" },
  { label: "BETTES (65130)", value: "65130" },
  { label: "BEYREDE JUMET CAMOUS (65410)", value: "65410" },
  { label: "BEYREDE JUMET CAMOUS (65410)", value: "65410" },
  { label: "BIZE (65150)", value: "65150" },
  { label: "BIZOUS (65150)", value: "65150" },
  { label: "BONNEFONT (65220)", value: "65220" },
  { label: "BONNEMAZON (65130)", value: "65130" },
  { label: "BONREPOS (65330)", value: "65330" },
  { label: "BOO SILHEN (65400)", value: "65400" },
  { label: "BORDERES LOURON (65590)", value: "65590" },
  { label: "BORDERES LOURON (65590)", value: "65590" },
  { label: "BORDERES SUR L ECHEZ (65320)", value: "65320" },
  { label: "BORDES (65190)", value: "65190" },
  { label: "BOUILH DEVANT (65140)", value: "65140" },
  { label: "BOUILH PEREUILH (65350)", value: "65350" },
  { label: "BOULIN (65350)", value: "65350" },
  { label: "BOURG DE BIGORRE (65130)", value: "65130" },
  { label: "BOURISP (65170)", value: "65170" },
  { label: "BOURREAC (65100)", value: "65100" },
  { label: "BOURS (65460)", value: "65460" },
  { label: "BRAMEVAQUE (65370)", value: "65370" },
  { label: "BUGARD (65220)", value: "65220" },
  { label: "BULAN (65130)", value: "65130" },
  { label: "BUN (65400)", value: "65400" },
  { label: "BURG (65190)", value: "65190" },
  { label: "BUZON (65140)", value: "65140" },
  { label: "CABANAC (65350)", value: "65350" },
  { label: "CADEAC (65240)", value: "65240" },
  { label: "CADEILHAN TRACHERE (65170)", value: "65170" },
  { label: "CAHARET (65190)", value: "65190" },
  { label: "CAIXON (65500)", value: "65500" },
  { label: "CALAVANTE (65190)", value: "65190" },
  { label: "CAMALES (65500)", value: "65500" },
  { label: "CAMPAN (65710)", value: "65710" },
  { label: "CAMPAN (65710)", value: "65710" },
  { label: "CAMPAN (65710)", value: "65710" },
  { label: "CAMPAN (65710)", value: "65710" },
  { label: "CAMPAN (65710)", value: "65710" },
  { label: "CAMPARAN (65170)", value: "65170" },
  { label: "CAMPISTROUS (65300)", value: "65300" },
  { label: "CAMPUZAN (65230)", value: "65230" },
  { label: "CAPVERN (65130)", value: "65130" },
  { label: "CASTELBAJAC (65330)", value: "65330" },
  { label: "CASTELNAU MAGNOAC (65230)", value: "65230" },
  { label: "CASTELNAU RIVIERE BASSE (65700)", value: "65700" },
  { label: "CASTELVIEILH (65350)", value: "65350" },
  { label: "CASTERA LANUSSE (65190)", value: "65190" },
  { label: "CASTERA LOU (65350)", value: "65350" },
  { label: "CASTERETS (65230)", value: "65230" },
  { label: "CASTILLON (65130)", value: "65130" },
  { label: "CAUBOUS (65230)", value: "65230" },
  { label: "CAUSSADE RIVIERE (65700)", value: "65700" },
  { label: "CAUTERETS (65110)", value: "65110" },
  { label: "CAZARILH (65370)", value: "65370" },
  { label: "CAZAUX DEBAT (65590)", value: "65590" },
  { label: "CAZAUX FRECHET ANERAN CAMORS (65240)", value: "65240" },
  { label: "CAZAUX FRECHET ANERAN CAMORS (65240)", value: "65240" },
  { label: "CAZAUX FRECHET ANERAN CAMORS (65240)", value: "65240" },
  { label: "CHELLE DEBAT (65350)", value: "65350" },
  { label: "CHELLE SPOU (65130)", value: "65130" },
  { label: "CHEUST (65100)", value: "65100" },
  { label: "CHEZE (65120)", value: "65120" },
  { label: "CHIS (65800)", value: "65800" },
  { label: "CIEUTAT (65200)", value: "65200" },
  { label: "CIZOS (65230)", value: "65230" },
  { label: "CLARAC (65190)", value: "65190" },
  { label: "CLARENS (65300)", value: "65300" },
  { label: "COLLONGUES (65350)", value: "65350" },
  { label: "COUSSAN (65350)", value: "65350" },
  { label: "CRECHETS (65370)", value: "65370" },
  { label: "DEVEZE (65230)", value: "65230" },
  { label: "DOURS (65350)", value: "65350" },
  { label: "ENS (65170)", value: "65170" },
  { label: "ESBAREICH (65370)", value: "65370" },
  { label: "ESCALA (65250)", value: "65250" },
  { label: "ESCAUNETS (65500)", value: "65500" },
  { label: "ESCONDEAUX (65140)", value: "65140" },
  { label: "ESCONNETS (65130)", value: "65130" },
  { label: "ESCOTS (65130)", value: "65130" },
  { label: "ESCOUBES POUTS (65100)", value: "65100" },
  { label: "ESPARROS (65130)", value: "65130" },
  { label: "ESPECHE (65130)", value: "65130" },
  { label: "ESPIEILH (65130)", value: "65130" },
  { label: "ESQUIEZE SERE (65120)", value: "65120" },
  { label: "ESTAING (65400)", value: "65400" },
  { label: "ESTAMPURES (65220)", value: "65220" },
  { label: "ESTARVIELLE (65240)", value: "65240" },
  { label: "ESTENSAN (65170)", value: "65170" },
  { label: "ESTERRE (65120)", value: "65120" },
  { label: "ESTIRAC (65700)", value: "65700" },
  { label: "FERRERE (65370)", value: "65370" },
  { label: "FERRIERES (65560)", value: "65560" },
  { label: "FONTRAILLES (65220)", value: "65220" },
  { label: "FRECHEDE (65220)", value: "65220" },
  { label: "FRECHENDETS (65130)", value: "65130" },
  { label: "FRECHET AURE (65240)", value: "65240" },
  { label: "FRECHOU FRECHET (65190)", value: "65190" },
  { label: "GAILLAGOS (65400)", value: "65400" },
  { label: "GALAN (65330)", value: "65330" },
  { label: "GALEZ (65330)", value: "65330" },
  { label: "GARDERES (65320)", value: "65320" },
  { label: "GAUDENT (65370)", value: "65370" },
  { label: "GAUSSAN (65670)", value: "65670" },
  { label: "GAYAN (65320)", value: "65320" },
  { label: "GAZAVE (65250)", value: "65250" },
  { label: "GAZOST (65100)", value: "65100" },
  { label: "GAVARNIE GEDRE (65120)", value: "65120" },
  { label: "GAVARNIE GEDRE (65120)", value: "65120" },
  { label: "GEMBRIE (65370)", value: "65370" },
  { label: "GENEREST (65150)", value: "65150" },
  { label: "GENOS (65240)", value: "65240" },
  { label: "GENSAC (65140)", value: "65140" },
  { label: "GER (65100)", value: "65100" },
  { label: "GERDE (65200)", value: "65200" },
  { label: "GERM (65240)", value: "65240" },
  { label: "GERMS SUR L OUSSOUET (65200)", value: "65200" },
  { label: "GEU (65100)", value: "65100" },
  { label: "GEZ (65400)", value: "65400" },
  { label: "GEZ EZ ANGLES (65100)", value: "65100" },
  { label: "GONEZ (65350)", value: "65350" },
  { label: "GOUAUX (65240)", value: "65240" },
  { label: "GOUDON (65190)", value: "65190" },
  { label: "GOURGUE (65130)", value: "65130" },
  { label: "GRAILHEN (65170)", value: "65170" },
  { label: "GREZIAN (65240)", value: "65240" },
  { label: "GRUST (65120)", value: "65120" },
  { label: "GUCHAN (65170)", value: "65170" },
  { label: "GUCHEN (65240)", value: "65240" },
  { label: "GUIZERIX (65230)", value: "65230" },
  { label: "HACHAN (65230)", value: "65230" },
  { label: "HAGEDET (65700)", value: "65700" },
  { label: "HAUBAN (65200)", value: "65200" },
  { label: "HAUTAGET (65150)", value: "65150" },
  { label: "HECHES (65250)", value: "65250" },
  { label: "HECHES (65250)", value: "65250" },
  { label: "HERES (65700)", value: "65700" },
  { label: "HIBARETTE (65380)", value: "65380" },
  { label: "HIIS (65200)", value: "65200" },
  { label: "HITTE (65190)", value: "65190" },
  { label: "HORGUES (65310)", value: "65310" },
  { label: "HOUEYDETS (65330)", value: "65330" },
  { label: "HOURC (65350)", value: "65350" },
  { label: "IBOS (65420)", value: "65420" },
  { label: "ILHET (65410)", value: "65410" },
  { label: "ILHEU (65370)", value: "65370" },
  { label: "IZAOURT (65370)", value: "65370" },
  { label: "IZAUX (65250)", value: "65250" },
  { label: "JACQUE (65350)", value: "65350" },
  { label: "JARRET (65100)", value: "65100" },
  { label: "JEZEAU (65240)", value: "65240" },
  { label: "JUILLAN (65290)", value: "65290" },
  { label: "JULOS (65100)", value: "65100" },
  { label: "JUNCALAS (65100)", value: "65100" },
  { label: "LABASSERE (65200)", value: "65200" },
  { label: "LABASTIDE (65130)", value: "65130" },
  { label: "LABATUT RIVIERE (65700)", value: "65700" },
  { label: "LABORDE (65130)", value: "65130" },
  { label: "LACASSAGNE (65140)", value: "65140" },
  { label: "LAFITOLE (65700)", value: "65700" },
  { label: "LAGARDE (65320)", value: "65320" },
  { label: "LAGRANGE (65300)", value: "65300" },
  { label: "ARRAYOU LAHITTE (65100)", value: "65100" },
  { label: "LAHITTE TOUPIERE (65700)", value: "65700" },
  { label: "LALANNE (65230)", value: "65230" },
  { label: "LALANNE TRIE (65220)", value: "65220" },
  { label: "LALOUBERE (65310)", value: "65310" },
  { label: "LAMARQUE PONTACQ (65380)", value: "65380" },
  { label: "LAMARQUE RUSTAING (65220)", value: "65220" },
  { label: "LAMEAC (65140)", value: "65140" },
  { label: "LANCON (65240)", value: "65240" },
  { label: "LANESPEDE (65190)", value: "65190" },
  { label: "LANNE (65380)", value: "65380" },
  { label: "LANNEMEZAN (65300)", value: "65300" },
  { label: "LANSAC (65350)", value: "65350" },
  { label: "LAPEYRE (65220)", value: "65220" },
  { label: "LARAN (65670)", value: "65670" },
  { label: "LARREULE (65700)", value: "65700" },
  { label: "LARROQUE (65230)", value: "65230" },
  { label: "LASCAZERES (65700)", value: "65700" },
  { label: "LASLADES (65350)", value: "65350" },
  { label: "LASSALES (65670)", value: "65670" },
  { label: "LAU BALAGNAS (65400)", value: "65400" },
  { label: "LAYRISSE (65380)", value: "65380" },
  { label: "LESCURRY (65140)", value: "65140" },
  { label: "LESPOUEY (65190)", value: "65190" },
  { label: "LEZIGNAN (65100)", value: "65100" },
  { label: "LHEZ (65190)", value: "65190" },
  { label: "LIAC (65140)", value: "65140" },
  { label: "LIBAROS (65330)", value: "65330" },
  { label: "LIES (65200)", value: "65200" },
  { label: "LIZOS (65350)", value: "65350" },
  { label: "LOMBRES (65150)", value: "65150" },
  { label: "LOMNE (65130)", value: "65130" },
  { label: "LORTET (65250)", value: "65250" },
  { label: "LOUBAJAC (65100)", value: "65100" },
  { label: "LOUCRUP (65200)", value: "65200" },
  { label: "LOUDENVIELLE (65240)", value: "65240" },
  { label: "LOUDENVIELLE (65510)", value: "65510" },
  { label: "LOUDERVIELLE (65240)", value: "65240" },
  { label: "LOUEY (65290)", value: "65290" },
  { label: "LOUIT (65350)", value: "65350" },
  { label: "LOURDES (65100)", value: "65100" },
  { label: "LOURES BAROUSSE (65370)", value: "65370" },
  { label: "LUBRET ST LUC (65220)", value: "65220" },
  { label: "LUBY BETMONT (65220)", value: "65220" },
  { label: "LUC (65190)", value: "65190" },
  { label: "LUGAGNAN (65100)", value: "65100" },
  { label: "LUQUET (65320)", value: "65320" },
  { label: "LUSTAR (65220)", value: "65220" },
  { label: "LUTILHOUS (65300)", value: "65300" },
  { label: "LUZ ST SAUVEUR (65120)", value: "65120" },
  { label: "LUZ ST SAUVEUR (65120)", value: "65120" },
  { label: "MADIRAN (65700)", value: "65700" },
  { label: "MANSAN (65140)", value: "65140" },
  { label: "MARQUERIE (65350)", value: "65350" },
  { label: "MARSAC (65500)", value: "65500" },
  { label: "MARSAS (65200)", value: "65200" },
  { label: "MARSEILLAN (65350)", value: "65350" },
  { label: "MASCARAS (65190)", value: "65190" },
  { label: "MAUBOURGUET (65700)", value: "65700" },
  { label: "MAULEON BAROUSSE (65370)", value: "65370" },
  { label: "MAUVEZIN (65130)", value: "65130" },
  { label: "MAZERES DE NESTE (65150)", value: "65150" },
  { label: "MAZEROLLES (65220)", value: "65220" },
  { label: "MAZOUAU (65250)", value: "65250" },
  { label: "MERILHEU (65200)", value: "65200" },
  { label: "MINGOT (65140)", value: "65140" },
  { label: "MOMERES (65360)", value: "65360" },
  { label: "MONFAUCON (65140)", value: "65140" },
  { label: "MONLEON MAGNOAC (65670)", value: "65670" },
  { label: "MONLONG (65670)", value: "65670" },
  { label: "MONT (65240)", value: "65240" },
  { label: "MONTASTRUC (65330)", value: "65330" },
  { label: "MONTEGUT (65150)", value: "65150" },
  { label: "MONTGAILLARD (65200)", value: "65200" },
  { label: "MONTIGNAC (65690)", value: "65690" },
  { label: "MONTOUSSE (65250)", value: "65250" },
  { label: "MONTSERIE (65150)", value: "65150" },
  { label: "MOULEDOUS (65190)", value: "65190" },
  { label: "MOUMOULOUS (65140)", value: "65140" },
  { label: "MUN (65350)", value: "65350" },
  { label: "NESTIER (65150)", value: "65150" },
  { label: "NEUILH (65200)", value: "65200" },
  { label: "NISTOS (65150)", value: "65150" },
  { label: "NOUILHAN (65500)", value: "65500" },
  { label: "ODOS (65310)", value: "65310" },
  { label: "OLEAC DEBAT (65350)", value: "65350" },
  { label: "OLEAC DESSUS (65190)", value: "65190" },
  { label: "OMEX (65100)", value: "65100" },
  { label: "ORDIZAN (65200)", value: "65200" },
  { label: "ORGAN (65230)", value: "65230" },
  { label: "ORIEUX (65190)", value: "65190" },
  { label: "ORIGNAC (65200)", value: "65200" },
  { label: "ORINCLES (65380)", value: "65380" },
  { label: "ORLEIX (65800)", value: "65800" },
  { label: "OROIX (65320)", value: "65320" },
  { label: "OSMETS (65350)", value: "65350" },
  { label: "OSSEN (65100)", value: "65100" },
  { label: "OSSUN (65380)", value: "65380" },
  { label: "OSSUN EZ ANGLES (65100)", value: "65100" },
  { label: "OUEILLOUX (65190)", value: "65190" },
  { label: "OURDE (65370)", value: "65370" },
  { label: "OURDIS COTDOUSSAN (65100)", value: "65100" },
  { label: "OURDON (65100)", value: "65100" },
  { label: "OURSBELILLE (65490)", value: "65490" },
  { label: "OUSTE (65100)", value: "65100" },
  { label: "OUZOUS (65400)", value: "65400" },
  { label: "OZON (65190)", value: "65190" },
  { label: "PAILHAC (65240)", value: "65240" },
  { label: "PAREAC (65100)", value: "65100" },
  { label: "PERE (65130)", value: "65130" },
  { label: "PEYRAUBE (65190)", value: "65190" },
  { label: "PEYRET ST ANDRE (65230)", value: "65230" },
  { label: "PEYRIGUERE (65350)", value: "65350" },
  { label: "PEYROUSE (65270)", value: "65270" },
  { label: "PEYRUN (65140)", value: "65140" },
  { label: "PIERREFITTE NESTALAS (65260)", value: "65260" },
  { label: "PINAS (65300)", value: "65300" },
  { label: "PINTAC (65320)", value: "65320" },
  { label: "POUEYFERRE (65100)", value: "65100" },
  { label: "POUMAROUS (65190)", value: "65190" },
  { label: "POUY (65230)", value: "65230" },
  { label: "POUYASTRUC (65350)", value: "65350" },
  { label: "POUZAC (65200)", value: "65200" },
  { label: "PRECHAC (65400)", value: "65400" },
  { label: "PUJO (65500)", value: "65500" },
  { label: "PUNTOUS (65230)", value: "65230" },
  { label: "PUYDARRIEUX (65220)", value: "65220" },
  { label: "RABASTENS DE BIGORRE (65140)", value: "65140" },
  { label: "RECURT (65330)", value: "65330" },
  { label: "REJAUMONT (65300)", value: "65300" },
  { label: "RICAUD (65190)", value: "65190" },
  { label: "RIS (65590)", value: "65590" },
  { label: "SABALOS (65350)", value: "65350" },
  { label: "SABARROS (65330)", value: "65330" },
  { label: "SACOUE (65370)", value: "65370" },
  { label: "SADOURNIN (65220)", value: "65220" },
  { label: "SAILHAN (65170)", value: "65170" },
  { label: "ST ARROMAN (65250)", value: "65250" },
  { label: "ST CREAC (65100)", value: "65100" },
  { label: "ST LANNE (65700)", value: "65700" },
  { label: "ST LARY SOULAN (65170)", value: "65170" },
  { label: "ST LAURENT DE NESTE (65150)", value: "65150" },
  { label: "ST LEZER (65500)", value: "65500" },
  { label: "STE MARIE (65370)", value: "65370" },
  { label: "ST MARTIN (65360)", value: "65360" },
  { label: "ST PASTOUS (65400)", value: "65400" },
  { label: "ST PAUL (65150)", value: "65150" },
  { label: "ST PE DE BIGORRE (65270)", value: "65270" },
  { label: "ST SAVIN (65400)", value: "65400" },
  { label: "ST SEVER DE RUSTAN (65140)", value: "65140" },
  { label: "SALECHAN (65370)", value: "65370" },
  { label: "SALIGOS (65120)", value: "65120" },
  { label: "SALIGOS (65120)", value: "65120" },
  { label: "SALLES (65400)", value: "65400" },
  { label: "SALLES ADOUR (65360)", value: "65360" },
  { label: "SAMURAN (65370)", value: "65370" },
  { label: "SANOUS (65500)", value: "65500" },
  { label: "SARIAC MAGNOAC (65230)", value: "65230" },
  { label: "SARLABOUS (65130)", value: "65130" },
  { label: "SARNIGUET (65390)", value: "65390" },
  { label: "SARP (65370)", value: "65370" },
  { label: "SARRANCOLIN (65410)", value: "65410" },
  { label: "SARRIAC BIGORRE (65140)", value: "65140" },
  { label: "SARROUILLES (65600)", value: "65600" },
  { label: "SASSIS (65120)", value: "65120" },
  { label: "SAUVETERRE (65700)", value: "65700" },
  { label: "SAZOS (65120)", value: "65120" },
  { label: "SEGALAS (65140)", value: "65140" },
  { label: "SEGUS (65100)", value: "65100" },
  { label: "SEICH (65150)", value: "65150" },
  { label: "SEMEAC (65600)", value: "65600" },
  { label: "SENAC (65140)", value: "65140" },
  { label: "SENTOUS (65330)", value: "65330" },
  { label: "SERE EN LAVEDAN (65400)", value: "65400" },
  { label: "SERE LANSO (65100)", value: "65100" },
  { label: "SERON (65320)", value: "65320" },
  { label: "SERE RUSTAING (65220)", value: "65220" },
  { label: "SERS (65120)", value: "65120" },
  { label: "SIARROUY (65500)", value: "65500" },
  { label: "SINZOS (65190)", value: "65190" },
  { label: "SIRADAN (65370)", value: "65370" },
  { label: "SIREIX (65400)", value: "65400" },
  { label: "SOMBRUN (65700)", value: "65700" },
  { label: "SOREAC (65350)", value: "65350" },
  { label: "SOST (65370)", value: "65370" },
  { label: "SOUBLECAUSE (65700)", value: "65700" },
  { label: "SOUES (65430)", value: "65430" },
  { label: "SOULOM (65260)", value: "65260" },
  { label: "SOUYEAUX (65350)", value: "65350" },
  { label: "TAJAN (65300)", value: "65300" },
  { label: "TALAZAC (65500)", value: "65500" },
  { label: "TARASTEIX (65320)", value: "65320" },
  { label: "TARBES (65000)", value: "65000" },
  { label: "THEBE (65370)", value: "65370" },
  { label: "THERMES MAGNOAC (65230)", value: "65230" },
  { label: "THUY (65350)", value: "65350" },
  { label: "TIBIRAN JAUNAC (65150)", value: "65150" },
  { label: "TILHOUSE (65130)", value: "65130" },
  { label: "TOSTAT (65140)", value: "65140" },
  { label: "TOURNAY (65190)", value: "65190" },
  { label: "TOURNOUS DARRE (65220)", value: "65220" },
  { label: "TOURNOUS DEVANT (65330)", value: "65330" },
  { label: "TRAMEZAIGUES (65170)", value: "65170" },
  { label: "TREBONS (65200)", value: "65200" },
  { label: "TRIE SUR BAISE (65220)", value: "65220" },
  { label: "TROUBAT (65370)", value: "65370" },
  { label: "TROULEY LABARTHE (65140)", value: "65140" },
  { label: "TUZAGUET (65150)", value: "65150" },
  { label: "UGLAS (65300)", value: "65300" },
  { label: "UGNOUAS (65140)", value: "65140" },
  { label: "UZ (65400)", value: "65400" },
  { label: "UZER (65200)", value: "65200" },
  { label: "VIC EN BIGORRE (65500)", value: "65500" },
  { label: "VIDOU (65220)", value: "65220" },
  { label: "VIDOUZE (65700)", value: "65700" },
  { label: "VIELLA (65120)", value: "65120" },
  { label: "VIELLE ADOUR (65360)", value: "65360" },
  { label: "VIELLE AURE (65170)", value: "65170" },
  { label: "VIELLE LOURON (65240)", value: "65240" },
  { label: "VIER BORDES (65400)", value: "65400" },
  { label: "VIEUZOS (65230)", value: "65230" },
  { label: "VIEY (65120)", value: "65120" },
  { label: "VIGER (65100)", value: "65100" },
  { label: "VIGNEC (65170)", value: "65170" },
  { label: "VILLEFRANQUE (65700)", value: "65700" },
  { label: "VILLELONGUE (65260)", value: "65260" },
  { label: "VILLEMBITS (65220)", value: "65220" },
  { label: "VILLEMUR (65230)", value: "65230" },
  { label: "VILLENAVE PRES BEARN (65500)", value: "65500" },
  { label: "VILLENAVE PRES MARSAC (65500)", value: "65500" },
  { label: "VISCOS (65120)", value: "65120" },
  { label: "VISKER (65200)", value: "65200" },
  { label: "BAREGES (65120)", value: "65120" },
  { label: "CANTAOUS (65150)", value: "65150" },
  { label: "L'ALBERE (66480)", value: "66480" },
  { label: "ALENYA (66200)", value: "66200" },
  { label: "AMELIE LES BAINS PALALDA (66110)", value: "66110" },
  { label: "LES ANGLES (66210)", value: "66210" },
  { label: "ANGOUSTRINE VILLENEUVE DES ESCALDES (66760)", value: "66760" },
  { label: "ANGOUSTRINE VILLENEUVE DES ESCALDES (66760)", value: "66760" },
  { label: "ANSIGNAN (66220)", value: "66220" },
  { label: "ARBOUSSOLS (66320)", value: "66320" },
  { label: "ARGELES SUR MER (66700)", value: "66700" },
  { label: "ARGELES SUR MER (66700)", value: "66700" },
  { label: "ARLES SUR TECH (66150)", value: "66150" },
  { label: "AYGUATEBIA TALAU (66360)", value: "66360" },
  { label: "AYGUATEBIA TALAU (66360)", value: "66360" },
  { label: "BAGES (66670)", value: "66670" },
  { label: "BAHO (66540)", value: "66540" },
  { label: "BAILLESTAVY (66320)", value: "66320" },
  { label: "BAIXAS (66390)", value: "66390" },
  { label: "BANYULS DELS ASPRES (66300)", value: "66300" },
  { label: "BANYULS SUR MER (66650)", value: "66650" },
  { label: "LE BARCARES (66420)", value: "66420" },
  { label: "LE BARCARES (66420)", value: "66420" },
  { label: "LA BASTIDE (66110)", value: "66110" },
  { label: "BELESTA (66720)", value: "66720" },
  { label: "BOLQUERE (66210)", value: "66210" },
  { label: "BOLQUERE (66210)", value: "66210" },
  { label: "BOMPAS (66430)", value: "66430" },
  { label: "BOULE D AMONT (66130)", value: "66130" },
  { label: "BOULETERNERE (66130)", value: "66130" },
  { label: "LE BOULOU (66160)", value: "66160" },
  { label: "BOURG MADAME (66760)", value: "66760" },
  { label: "BOURG MADAME (66760)", value: "66760" },
  { label: "BROUILLA (66620)", value: "66620" },
  { label: "LA CABANASSE (66210)", value: "66210" },
  { label: "CABESTANY (66330)", value: "66330" },
  { label: "CAIXAS (66300)", value: "66300" },
  { label: "CALCE (66600)", value: "66600" },
  { label: "CALMEILLES (66400)", value: "66400" },
  { label: "CAMELAS (66300)", value: "66300" },
  { label: "CAMPOME (66500)", value: "66500" },
  { label: "CAMPOUSSY (66730)", value: "66730" },
  { label: "CANAVEILLES (66360)", value: "66360" },
  { label: "CANET EN ROUSSILLON (66140)", value: "66140" },
  { label: "CANET EN ROUSSILLON (66140)", value: "66140" },
  { label: "CANOHES (66680)", value: "66680" },
  { label: "CARAMANY (66720)", value: "66720" },
  { label: "CASEFABRE (66130)", value: "66130" },
  { label: "CASES DE PENE (66600)", value: "66600" },
  { label: "CASSAGNES (66720)", value: "66720" },
  { label: "CASTEIL (66820)", value: "66820" },
  { label: "CASTELNOU (66300)", value: "66300" },
  { label: "CATLLAR (66500)", value: "66500" },
  { label: "CAUDIES DE FENOUILLEDES (66220)", value: "66220" },
  { label: "CAUDIES DE CONFLENT (66360)", value: "66360" },
  { label: "CERBERE (66290)", value: "66290" },
  { label: "CERET (66400)", value: "66400" },
  { label: "CLAIRA (66530)", value: "66530" },
  { label: "CLARA VILLERACH (66500)", value: "66500" },
  { label: "CODALET (66500)", value: "66500" },
  { label: "COLLIOURE (66190)", value: "66190" },
  { label: "CONAT (66500)", value: "66500" },
  { label: "CORBERE (66130)", value: "66130" },
  { label: "CORBERE LES CABANES (66130)", value: "66130" },
  { label: "CORNEILLA DE CONFLENT (66820)", value: "66820" },
  { label: "CORNEILLA LA RIVIERE (66550)", value: "66550" },
  { label: "CORNEILLA DEL VERCOL (66200)", value: "66200" },
  { label: "CORSAVY (66150)", value: "66150" },
  { label: "COUSTOUGES (66260)", value: "66260" },
  { label: "DORRES (66760)", value: "66760" },
  { label: "LES CLUSES (66480)", value: "66480" },
  { label: "EGAT (66120)", value: "66120" },
  { label: "ELNE (66200)", value: "66200" },
  { label: "ENVEITG (66760)", value: "66760" },
  { label: "ERR (66800)", value: "66800" },
  { label: "ESCARO (66360)", value: "66360" },
  { label: "ESPIRA DE L AGLY (66600)", value: "66600" },
  { label: "ESPIRA DE CONFLENT (66320)", value: "66320" },
  { label: "ESTAGEL (66310)", value: "66310" },
  { label: "ESTAVAR (66800)", value: "66800" },
  { label: "ESTOHER (66320)", value: "66320" },
  { label: "EUS (66500)", value: "66500" },
  { label: "EYNE (66800)", value: "66800" },
  { label: "FEILLUNS (66730)", value: "66730" },
  { label: "FENOUILLET (66220)", value: "66220" },
  { label: "FILLOLS (66820)", value: "66820" },
  { label: "FINESTRET (66320)", value: "66320" },
  { label: "FONTPEDROUSE (66360)", value: "66360" },
  { label: "FONTRABIOUSE (66210)", value: "66210" },
  { label: "FORMIGUERES (66210)", value: "66210" },
  { label: "FOSSE (66220)", value: "66220" },
  { label: "FOURQUES (66300)", value: "66300" },
  { label: "FUILLA (66820)", value: "66820" },
  { label: "GLORIANES (66320)", value: "66320" },
  { label: "ILLE SUR TET (66130)", value: "66130" },
  { label: "JOCH (66320)", value: "66320" },
  { label: "JUJOLS (66360)", value: "66360" },
  { label: "LAMANERE (66230)", value: "66230" },
  { label: "LANSAC (66720)", value: "66720" },
  { label: "LAROQUE DES ALBERES (66740)", value: "66740" },
  { label: "LATOUR BAS ELNE (66200)", value: "66200" },
  { label: "LATOUR DE CAROL (66760)", value: "66760" },
  { label: "LATOUR DE FRANCE (66720)", value: "66720" },
  { label: "LESQUERDE (66220)", value: "66220" },
  { label: "LA LLAGONNE (66210)", value: "66210" },
  { label: "LLAURO (66300)", value: "66300" },
  { label: "LLO (66800)", value: "66800" },
  { label: "LLUPIA (66300)", value: "66300" },
  { label: "MANTET (66360)", value: "66360" },
  { label: "MARQUIXANES (66320)", value: "66320" },
  { label: "LOS MASOS (66500)", value: "66500" },
  { label: "MATEMALE (66210)", value: "66210" },
  { label: "MAUREILLAS LAS ILLAS (66480)", value: "66480" },
  { label: "MAUREILLAS LAS ILLAS (66480)", value: "66480" },
  { label: "MAUREILLAS LAS ILLAS (66480)", value: "66480" },
  { label: "MAURY (66460)", value: "66460" },
  { label: "MILLAS (66170)", value: "66170" },
  { label: "MOLITG LES BAINS (66500)", value: "66500" },
  { label: "MONTALBA LE CHATEAU (66130)", value: "66130" },
  { label: "MONTAURIOL (66300)", value: "66300" },
  { label: "MONTBOLO (66110)", value: "66110" },
  { label: "MONTESCOT (66200)", value: "66200" },
  { label: "MONTESQUIEU DES ALBERES (66740)", value: "66740" },
  { label: "MONTFERRER (66150)", value: "66150" },
  { label: "MONT LOUIS (66210)", value: "66210" },
  { label: "MONTNER (66720)", value: "66720" },
  { label: "MOSSET (66500)", value: "66500" },
  { label: "NAHUJA (66340)", value: "66340" },
  { label: "NEFIACH (66170)", value: "66170" },
  { label: "NOHEDES (66500)", value: "66500" },
  { label: "NYER (66360)", value: "66360" },
  { label: "FONT ROMEU ODEILLO VIA (66120)", value: "66120" },
  { label: "FONT ROMEU ODEILLO VIA (66120)", value: "66120" },
  { label: "OLETTE (66360)", value: "66360" },
  { label: "OMS (66400)", value: "66400" },
  { label: "OPOUL PERILLOS (66600)", value: "66600" },
  { label: "OPOUL PERILLOS (66600)", value: "66600" },
  { label: "OREILLA (66360)", value: "66360" },
  { label: "ORTAFFA (66560)", value: "66560" },
  { label: "OSSEJA (66340)", value: "66340" },
  { label: "PALAU DE CERDAGNE (66340)", value: "66340" },
  { label: "PALAU DEL VIDRE (66690)", value: "66690" },
  { label: "PASSA (66300)", value: "66300" },
  { label: "PERPIGNAN (66000)", value: "66000" },
  { label: "PERPIGNAN (66100)", value: "66100" },
  { label: "LE PERTHUS (66480)", value: "66480" },
  { label: "PEYRESTORTES (66600)", value: "66600" },
  { label: "PEZILLA DE CONFLENT (66730)", value: "66730" },
  { label: "PEZILLA LA RIVIERE (66370)", value: "66370" },
  { label: "PIA (66380)", value: "66380" },
  { label: "PLANES (66210)", value: "66210" },
  { label: "PLANEZES (66720)", value: "66720" },
  { label: "POLLESTRES (66450)", value: "66450" },
  { label: "PONTEILLA (66300)", value: "66300" },
  { label: "PORTA (66760)", value: "66760" },
  { label: "PORTE PUYMORENS (66760)", value: "66760" },
  { label: "PORT VENDRES (66660)", value: "66660" },
  { label: "PRADES (66500)", value: "66500" },
  { label: "PRATS DE MOLLO LA PRESTE (66230)", value: "66230" },
  { label: "PRATS DE MOLLO LA PRESTE (66230)", value: "66230" },
  { label: "PRATS DE SOURNIA (66730)", value: "66730" },
  { label: "PRUGNANES (66220)", value: "66220" },
  { label: "PRUNET ET BELPUIG (66130)", value: "66130" },
  { label: "PUYVALADOR (66210)", value: "66210" },
  { label: "PY (66360)", value: "66360" },
  { label: "RABOUILLET (66730)", value: "66730" },
  { label: "RAILLEU (66360)", value: "66360" },
  { label: "RASIGUERES (66720)", value: "66720" },
  { label: "REAL (66210)", value: "66210" },
  { label: "REYNES (66400)", value: "66400" },
  { label: "RIA SIRACH (66500)", value: "66500" },
  { label: "RIGARDA (66320)", value: "66320" },
  { label: "RIVESALTES (66600)", value: "66600" },
  { label: "RODES (66320)", value: "66320" },
  { label: "SAHORRE (66360)", value: "66360" },
  { label: "SAILLAGOUSE (66800)", value: "66800" },
  { label: "ST ANDRE (66690)", value: "66690" },
  { label: "ST ARNAC (66220)", value: "66220" },
  { label: "STE COLOMBE DE LA COMMANDERIE (66300)", value: "66300" },
  { label: "ST CYPRIEN (66750)", value: "66750" },
  { label: "ST CYPRIEN (66750)", value: "66750" },
  { label: "ST ESTEVE (66240)", value: "66240" },
  { label: "ST FELIU D AMONT (66170)", value: "66170" },
  { label: "ST FELIU D AVALL (66170)", value: "66170" },
  { label: "ST GENIS DES FONTAINES (66740)", value: "66740" },
  { label: "ST HIPPOLYTE (66510)", value: "66510" },
  { label: "ST JEAN LASSEILLE (66300)", value: "66300" },
  { label: "ST JEAN PLA DE CORTS (66490)", value: "66490" },
  { label: "ST LAURENT DE CERDANS (66260)", value: "66260" },
  { label: "ST LAURENT DE LA SALANQUE (66250)", value: "66250" },
  { label: "STE LEOCADIE (66800)", value: "66800" },
  { label: "STE MARIE LA MER (66470)", value: "66470" },
  { label: "ST MARSAL (66110)", value: "66110" },
  { label: "ST MARTIN DE FENOUILLET (66220)", value: "66220" },
  { label: "ST MICHEL DE LLOTES (66130)", value: "66130" },
  { label: "ST NAZAIRE (66570)", value: "66570" },
  { label: "ST PAUL DE FENOUILLET (66220)", value: "66220" },
  { label: "ST PIERRE DELS FORCATS (66210)", value: "66210" },
  { label: "SALEILLES (66280)", value: "66280" },
  { label: "SALSES LE CHATEAU (66600)", value: "66600" },
  { label: "SANSA (66360)", value: "66360" },
  { label: "SAUTO (66210)", value: "66210" },
  { label: "SERDINYA (66360)", value: "66360" },
  { label: "SERRALONGUE (66230)", value: "66230" },
  { label: "LE SOLER (66270)", value: "66270" },
  { label: "SOREDE (66690)", value: "66690" },
  { label: "SOUANYAS (66360)", value: "66360" },
  { label: "SOURNIA (66730)", value: "66730" },
  { label: "TAILLET (66400)", value: "66400" },
  { label: "TARERACH (66320)", value: "66320" },
  { label: "TARGASONNE (66120)", value: "66120" },
  { label: "TAULIS (66110)", value: "66110" },
  { label: "TAURINYA (66500)", value: "66500" },
  { label: "TAUTAVEL (66720)", value: "66720" },
  { label: "LE TECH (66230)", value: "66230" },
  { label: "TERRATS (66300)", value: "66300" },
  { label: "THEZA (66200)", value: "66200" },
  { label: "THUES ENTRE VALLS (66360)", value: "66360" },
  { label: "THUIR (66300)", value: "66300" },
  { label: "TORDERES (66300)", value: "66300" },
  { label: "TORREILLES (66440)", value: "66440" },
  { label: "TOULOUGES (66350)", value: "66350" },
  { label: "TRESSERRE (66300)", value: "66300" },
  { label: "TREVILLACH (66130)", value: "66130" },
  { label: "TRILLA (66220)", value: "66220" },
  { label: "TROUILLAS (66300)", value: "66300" },
  { label: "UR (66760)", value: "66760" },
  { label: "URBANYA (66500)", value: "66500" },
  { label: "VALCEBOLLERE (66340)", value: "66340" },
  { label: "VALMANYA (66320)", value: "66320" },
  { label: "VERNET LES BAINS (66820)", value: "66820" },
  { label: "VILLEFRANCHE DE CONFLENT (66500)", value: "66500" },
  { label: "VILLELONGUE DE LA SALANQUE (66410)", value: "66410" },
  { label: "VILLELONGUE DELS MONTS (66740)", value: "66740" },
  { label: "VILLEMOLAQUE (66300)", value: "66300" },
  { label: "VILLENEUVE DE LA RAHO (66180)", value: "66180" },
  { label: "VILLENEUVE LA RIVIERE (66610)", value: "66610" },
  { label: "VINCA (66320)", value: "66320" },
  { label: "VINGRAU (66600)", value: "66600" },
  { label: "VIRA (66220)", value: "66220" },
  { label: "VIVES (66490)", value: "66490" },
  { label: "LE VIVIER (66730)", value: "66730" },
  { label: "ACHENHEIM (67204)", value: "67204" },
  { label: "ADAMSWILLER (67320)", value: "67320" },
  { label: "ALBE (67220)", value: "67220" },
  { label: "SOMMERAU (67310)", value: "67310" },
  { label: "SOMMERAU (67440)", value: "67440" },
  { label: "SOMMERAU (67440)", value: "67440" },
  { label: "SOMMERAU (67440)", value: "67440" },
  { label: "ALTECKENDORF (67270)", value: "67270" },
  { label: "ALTENHEIM (67490)", value: "67490" },
  { label: "ALTORF (67120)", value: "67120" },
  { label: "ALTWILLER (67260)", value: "67260" },
  { label: "ANDLAU (67140)", value: "67140" },
  { label: "ARTOLSHEIM (67390)", value: "67390" },
  { label: "ASCHBACH (67250)", value: "67250" },
  { label: "ASSWILLER (67320)", value: "67320" },
  { label: "AVOLSHEIM (67120)", value: "67120" },
  { label: "BAERENDORF (67320)", value: "67320" },
  { label: "BALBRONN (67310)", value: "67310" },
  { label: "BALDENHEIM (67600)", value: "67600" },
  { label: "BAREMBACH (67130)", value: "67130" },
  { label: "BARR (67140)", value: "67140" },
  { label: "BASSEMBERG (67220)", value: "67220" },
  { label: "BATZENDORF (67500)", value: "67500" },
  { label: "BEINHEIM (67930)", value: "67930" },
  { label: "BELLEFOSSE (67130)", value: "67130" },
  { label: "BELMONT (67130)", value: "67130" },
  { label: "BENFELD (67230)", value: "67230" },
  { label: "BERG (67320)", value: "67320" },
  { label: "BERGBIETEN (67310)", value: "67310" },
  { label: "BERNARDSWILLER (67210)", value: "67210" },
  { label: "BERNARDVILLE (67140)", value: "67140" },
  { label: "BERNOLSHEIM (67170)", value: "67170" },
  { label: "BERSTETT (67370)", value: "67370" },
  { label: "BERSTETT (67370)", value: "67370" },
  { label: "BERSTETT (67370)", value: "67370" },
  { label: "BERSTETT (67370)", value: "67370" },
  { label: "BERSTHEIM (67170)", value: "67170" },
  { label: "BETTWILLER (67320)", value: "67320" },
  { label: "BIBLISHEIM (67360)", value: "67360" },
  { label: "BIETLENHEIM (67720)", value: "67720" },
  { label: "BILWISHEIM (67170)", value: "67170" },
  { label: "BINDERNHEIM (67600)", value: "67600" },
  { label: "BISCHHEIM (67800)", value: "67800" },
  { label: "BISCHHOLTZ (67340)", value: "67340" },
  { label: "BISCHOFFSHEIM (67870)", value: "67870" },
  { label: "BISCHWILLER (67240)", value: "67240" },
  { label: "BISSERT (67260)", value: "67260" },
  { label: "BITSCHHOFFEN (67350)", value: "67350" },
  { label: "BLAESHEIM (67113)", value: "67113" },
  { label: "BLANCHERUPT (67130)", value: "67130" },
  { label: "BLIENSCHWILLER (67650)", value: "67650" },
  { label: "BOERSCH (67530)", value: "67530" },
  { label: "BOERSCH (67530)", value: "67530" },
  { label: "BOESENBIESEN (67390)", value: "67390" },
  { label: "BOLSENHEIM (67150)", value: "67150" },
  { label: "BOOFZHEIM (67860)", value: "67860" },
  { label: "BOOTZHEIM (67390)", value: "67390" },
  { label: "BOSSELSHAUSEN (67330)", value: "67330" },
  { label: "BOSSENDORF (67270)", value: "67270" },
  { label: "BOURG BRUCHE (67420)", value: "67420" },
  { label: "BOURG BRUCHE (67420)", value: "67420" },
  { label: "BOURGHEIM (67140)", value: "67140" },
  { label: "BOUXWILLER (67330)", value: "67330" },
  { label: "BOUXWILLER (67330)", value: "67330" },
  { label: "BOUXWILLER (67330)", value: "67330" },
  { label: "BOUXWILLER (67330)", value: "67330" },
  { label: "BREITENAU (67220)", value: "67220" },
  { label: "BREITENBACH (67220)", value: "67220" },
  { label: "BREUSCHWICKERSHEIM (67112)", value: "67112" },
  { label: "LA BROQUE (67130)", value: "67130" },
  { label: "LA BROQUE (67130)", value: "67130" },
  { label: "LA BROQUE (67130)", value: "67130" },
  { label: "LA BROQUE (67570)", value: "67570" },
  { label: "LA BROQUE (67570)", value: "67570" },
  { label: "BRUMATH (67170)", value: "67170" },
  { label: "BUSWILLER (67350)", value: "67350" },
  { label: "BUHL (67470)", value: "67470" },
  { label: "BURBACH (67260)", value: "67260" },
  { label: "BUST (67320)", value: "67320" },
  { label: "BUTTEN (67430)", value: "67430" },
  { label: "CHATENOIS (67730)", value: "67730" },
  { label: "CLEEBOURG (67160)", value: "67160" },
  { label: "CLEEBOURG (67160)", value: "67160" },
  { label: "CLIMBACH (67510)", value: "67510" },
  { label: "COLROY LA ROCHE (67420)", value: "67420" },
  { label: "COSSWILLER (67310)", value: "67310" },
  { label: "CRASTATT (67310)", value: "67310" },
  { label: "CROETTWILLER (67470)", value: "67470" },
  { label: "DACHSTEIN (67120)", value: "67120" },
  { label: "DAHLENHEIM (67310)", value: "67310" },
  { label: "DALHUNDEN (67770)", value: "67770" },
  { label: "DAMBACH (67110)", value: "67110" },
  { label: "DAMBACH (67110)", value: "67110" },
  { label: "DAMBACH LA VILLE (67650)", value: "67650" },
  { label: "DANGOLSHEIM (67310)", value: "67310" },
  { label: "DAUBENSAND (67150)", value: "67150" },
  { label: "DAUENDORF (67350)", value: "67350" },
  { label: "DAUENDORF (67350)", value: "67350" },
  { label: "DEHLINGEN (67430)", value: "67430" },
  { label: "DETTWILLER (67490)", value: "67490" },
  { label: "DIEBOLSHEIM (67230)", value: "67230" },
  { label: "DIEDENDORF (67260)", value: "67260" },
  { label: "DIEFFENBACH AU VAL (67220)", value: "67220" },
  { label: "DIEFFENBACH LES WOERTH (67360)", value: "67360" },
  { label: "DIEFFENTHAL (67650)", value: "67650" },
  { label: "DIEMERINGEN (67430)", value: "67430" },
  { label: "DIMBSTHAL (67440)", value: "67440" },
  { label: "DINGSHEIM (67370)", value: "67370" },
  { label: "DINSHEIM SUR BRUCHE (67190)", value: "67190" },
  { label: "DOMFESSEL (67430)", value: "67430" },
  { label: "DONNENHEIM (67170)", value: "67170" },
  { label: "DORLISHEIM (67120)", value: "67120" },
  { label: "DOSSENHEIM KOCHERSBERG (67117)", value: "67117" },
  { label: "DOSSENHEIM SUR ZINSEL (67330)", value: "67330" },
  { label: "DRACHENBRONN BIRLENBACH (67160)", value: "67160" },
  { label: "DRACHENBRONN BIRLENBACH (67160)", value: "67160" },
  { label: "DRULINGEN (67320)", value: "67320" },
  { label: "DRUSENHEIM (67410)", value: "67410" },
  { label: "DUNTZENHEIM (67270)", value: "67270" },
  { label: "DUPPIGHEIM (67120)", value: "67120" },
  { label: "DURNINGEN (67270)", value: "67270" },
  { label: "DURRENBACH (67360)", value: "67360" },
  { label: "DURSTEL (67320)", value: "67320" },
  { label: "DUTTLENHEIM (67120)", value: "67120" },
  { label: "EBERBACH SELTZ (67470)", value: "67470" },
  { label: "EBERSHEIM (67600)", value: "67600" },
  { label: "EBERSMUNSTER (67600)", value: "67600" },
  { label: "ECKARTSWILLER (67700)", value: "67700" },
  { label: "ECKBOLSHEIM (67201)", value: "67201" },
  { label: "ECKWERSHEIM (67550)", value: "67550" },
  { label: "EICHHOFFEN (67140)", value: "67140" },
  { label: "ELSENHEIM (67390)", value: "67390" },
  { label: "WANGENBOURG ENGENTHAL (67710)", value: "67710" },
  { label: "WANGENBOURG ENGENTHAL (67710)", value: "67710" },
  { label: "WANGENBOURG ENGENTHAL (67710)", value: "67710" },
  { label: "WANGENBOURG ENGENTHAL (67710)", value: "67710" },
  { label: "WANGENBOURG ENGENTHAL (67710)", value: "67710" },
  { label: "ENGWILLER (67350)", value: "67350" },
  { label: "ENTZHEIM (67960)", value: "67960" },
  { label: "EPFIG (67680)", value: "67680" },
  { label: "ERCKARTSWILLER (67290)", value: "67290" },
  { label: "ERGERSHEIM (67120)", value: "67120" },
  { label: "ERNOLSHEIM BRUCHE (67120)", value: "67120" },
  { label: "ERNOLSHEIM LES SAVERNE (67330)", value: "67330" },
  { label: "ERSTEIN (67150)", value: "67150" },
  { label: "ERSTEIN (67150)", value: "67150" },
  { label: "ESCHAU (67114)", value: "67114" },
  { label: "ESCHBACH (67360)", value: "67360" },
  { label: "ESCHBOURG (67320)", value: "67320" },
  { label: "ESCHBOURG (67320)", value: "67320" },
  { label: "ESCHWILLER (67320)", value: "67320" },
  { label: "ETTENDORF (67350)", value: "67350" },
  { label: "EYWILLER (67320)", value: "67320" },
  { label: "FEGERSHEIM (67640)", value: "67640" },
  { label: "FEGERSHEIM (67640)", value: "67640" },
  { label: "FESSENHEIM LE BAS (67117)", value: "67117" },
  { label: "FLEXBOURG (67310)", value: "67310" },
  { label: "FORSTFELD (67480)", value: "67480" },
  { label: "FORSTHEIM (67580)", value: "67580" },
  { label: "FORT LOUIS (67480)", value: "67480" },
  { label: "FOUCHY (67220)", value: "67220" },
  { label: "FOUDAY (67130)", value: "67130" },
  { label: "FRIEDOLSHEIM (67490)", value: "67490" },
  { label: "FRIESENHEIM (67860)", value: "67860" },
  { label: "FROESCHWILLER (67360)", value: "67360" },
  { label: "FROHMUHL (67290)", value: "67290" },
  { label: "FURCHHAUSEN (67700)", value: "67700" },
  { label: "FURDENHEIM (67117)", value: "67117" },
  { label: "GAMBSHEIM (67760)", value: "67760" },
  { label: "GEISPOLSHEIM (67118)", value: "67118" },
  { label: "GEISWILLER ZOEBERSDORF (67270)", value: "67270" },
  { label: "GEISWILLER ZOEBERSDORF (67270)", value: "67270" },
  { label: "GERSTHEIM (67150)", value: "67150" },
  { label: "GERTWILLER (67140)", value: "67140" },
  { label: "GEUDERTHEIM (67170)", value: "67170" },
  { label: "GOERLINGEN (67320)", value: "67320" },
  { label: "GOERSDORF (67360)", value: "67360" },
  { label: "GOERSDORF (67360)", value: "67360" },
  { label: "GOTTENHOUSE (67700)", value: "67700" },
  { label: "GOTTESHEIM (67490)", value: "67490" },
  { label: "GOUGENHEIM (67270)", value: "67270" },
  { label: "GOXWILLER (67210)", value: "67210" },
  { label: "GRANDFONTAINE (67130)", value: "67130" },
  { label: "GRASSENDORF (67350)", value: "67350" },
  { label: "GRENDELBRUCH (67190)", value: "67190" },
  { label: "GRESSWILLER (67190)", value: "67190" },
  { label: "GRIES (67240)", value: "67240" },
  { label: "GRIESHEIM PRES MOLSHEIM (67870)", value: "67870" },
  { label: "GRIESHEIM SUR SOUFFEL (67370)", value: "67370" },
  { label: "GUMBRECHTSHOFFEN (67110)", value: "67110" },
  { label: "GUNDERSHOFFEN (67110)", value: "67110" },
  { label: "GUNDERSHOFFEN (67110)", value: "67110" },
  { label: "GUNDERSHOFFEN (67110)", value: "67110" },
  { label: "GUNDERSHOFFEN (67110)", value: "67110" },
  { label: "GUNSTETT (67360)", value: "67360" },
  { label: "GUNGWILLER (67320)", value: "67320" },
  { label: "HAEGEN (67700)", value: "67700" },
  { label: "HAGUENAU (67500)", value: "67500" },
  { label: "HAGUENAU (67500)", value: "67500" },
  { label: "HAGUENAU (67500)", value: "67500" },
  { label: "HANDSCHUHEIM (67117)", value: "67117" },
  { label: "HANGENBIETEN (67980)", value: "67980" },
  { label: "HARSKIRCHEN (67260)", value: "67260" },
  { label: "HATTEN (67690)", value: "67690" },
  { label: "HATTMATT (67330)", value: "67330" },
  { label: "HEGENEY (67360)", value: "67360" },
  { label: "HEIDOLSHEIM (67390)", value: "67390" },
  { label: "HEILIGENBERG (67190)", value: "67190" },
  { label: "HEILIGENSTEIN (67140)", value: "67140" },
  { label: "HENGWILLER (67440)", value: "67440" },
  { label: "HERBITZHEIM (67260)", value: "67260" },
  { label: "HERBSHEIM (67230)", value: "67230" },
  { label: "HERRLISHEIM (67850)", value: "67850" },
  { label: "HESSENHEIM (67390)", value: "67390" },
  { label: "HILSENHEIM (67600)", value: "67600" },
  { label: "HINDISHEIM (67150)", value: "67150" },
  { label: "HINSBOURG (67290)", value: "67290" },
  { label: "HINSINGEN (67260)", value: "67260" },
  { label: "HIPSHEIM (67150)", value: "67150" },
  { label: "HIRSCHLAND (67320)", value: "67320" },
  { label: "HOCHFELDEN (67270)", value: "67270" },
  { label: "HOCHFELDEN (67270)", value: "67270" },
  { label: "HOCHSTETT (67170)", value: "67170" },
  { label: "HOENHEIM (67800)", value: "67800" },
  { label: "HOERDT (67720)", value: "67720" },
  { label: "HOFFEN (67250)", value: "67250" },
  { label: "HOFFEN (67250)", value: "67250" },
  { label: "HOFFEN (67250)", value: "67250" },
  { label: "HOHENGOEFT (67310)", value: "67310" },
  { label: "HOHFRANKENHEIM (67270)", value: "67270" },
  { label: "LE HOHWALD (67140)", value: "67140" },
  { label: "HOLTZHEIM (67810)", value: "67810" },
  { label: "HUNSPACH (67250)", value: "67250" },
  { label: "HURTIGHEIM (67117)", value: "67117" },
  { label: "HUTTENDORF (67270)", value: "67270" },
  { label: "HUTTENHEIM (67230)", value: "67230" },
  { label: "ICHTRATZHEIM (67640)", value: "67640" },
  { label: "ILLKIRCH GRAFFENSTADEN (67400)", value: "67400" },
  { label: "INGENHEIM (67270)", value: "67270" },
  { label: "INGOLSHEIM (67250)", value: "67250" },
  { label: "INGWILLER (67340)", value: "67340" },
  { label: "INNENHEIM (67880)", value: "67880" },
  { label: "ISSENHAUSEN (67330)", value: "67330" },
  { label: "ITTENHEIM (67117)", value: "67117" },
  { label: "ITTERSWILLER (67140)", value: "67140" },
  { label: "NEUGARTHEIM ITTLENHEIM (67370)", value: "67370" },
  { label: "NEUGARTHEIM ITTLENHEIM (67370)", value: "67370" },
  { label: "NEUGARTHEIM ITTLENHEIM (67370)", value: "67370" },
  { label: "JETTERSWILLER (67440)", value: "67440" },
  { label: "KALTENHOUSE (67240)", value: "67240" },
  { label: "KAUFFENHEIM (67480)", value: "67480" },
  { label: "KEFFENACH (67250)", value: "67250" },
  { label: "KERTZFELD (67230)", value: "67230" },
  { label: "KESKASTEL (67260)", value: "67260" },
  { label: "KESSELDORF (67930)", value: "67930" },
  { label: "KIENHEIM (67270)", value: "67270" },
  { label: "KILSTETT (67840)", value: "67840" },
  { label: "KINDWILLER (67350)", value: "67350" },
  { label: "KINTZHEIM (67600)", value: "67600" },
  { label: "KIRCHHEIM (67520)", value: "67520" },
  { label: "KIRRBERG (67320)", value: "67320" },
  { label: "KIRRWILLER (67330)", value: "67330" },
  { label: "KLEINGOEFT (67440)", value: "67440" },
  { label: "KNOERSHEIM (67310)", value: "67310" },
  { label: "KOGENHEIM (67230)", value: "67230" },
  { label: "KOLBSHEIM (67120)", value: "67120" },
  { label: "KRAUTERGERSHEIM (67880)", value: "67880" },
  { label: "KRAUTWILLER (67170)", value: "67170" },
  { label: "KRIEGSHEIM (67170)", value: "67170" },
  { label: "KURTZENHOUSE (67240)", value: "67240" },
  { label: "KUTTOLSHEIM (67520)", value: "67520" },
  { label: "KUTZENHAUSEN (67250)", value: "67250" },
  { label: "KUTZENHAUSEN (67250)", value: "67250" },
  { label: "LALAYE (67220)", value: "67220" },
  { label: "LALAYE (67220)", value: "67220" },
  { label: "LAMPERTHEIM (67450)", value: "67450" },
  { label: "LAMPERTSLOCH (67250)", value: "67250" },
  { label: "LANDERSHEIM (67700)", value: "67700" },
  { label: "LANGENSOULTZBACH (67360)", value: "67360" },
  { label: "LAUBACH (67580)", value: "67580" },
  { label: "LAUTERBOURG (67630)", value: "67630" },
  { label: "LEMBACH (67510)", value: "67510" },
  { label: "LEMBACH (67510)", value: "67510" },
  { label: "LEUTENHEIM (67480)", value: "67480" },
  { label: "LICHTENBERG (67340)", value: "67340" },
  { label: "LIMERSHEIM (67150)", value: "67150" },
  { label: "LINGOLSHEIM (67380)", value: "67380" },
  { label: "LIPSHEIM (67640)", value: "67640" },
  { label: "LITTENHEIM (67490)", value: "67490" },
  { label: "LIXHAUSEN (67270)", value: "67270" },
  { label: "LOBSANN (67250)", value: "67250" },
  { label: "LOCHWILLER (67440)", value: "67440" },
  { label: "LOHR (67290)", value: "67290" },
  { label: "LORENTZEN (67430)", value: "67430" },
  { label: "LUPSTEIN (67490)", value: "67490" },
  { label: "LUTZELHOUSE (67130)", value: "67130" },
  { label: "MACKENHEIM (67390)", value: "67390" },
  { label: "MACKWILLER (67430)", value: "67430" },
  { label: "MAENNOLSHEIM (67700)", value: "67700" },
  { label: "MAISONSGOUTTE (67220)", value: "67220" },
  { label: "MAISONSGOUTTE (67220)", value: "67220" },
  { label: "MARCKOLSHEIM (67390)", value: "67390" },
  { label: "MARLENHEIM (67520)", value: "67520" },
  { label: "MARMOUTIER (67440)", value: "67440" },
  { label: "MATZENHEIM (67150)", value: "67150" },
  { label: "MATZENHEIM (67150)", value: "67150" },
  { label: "MEISTRATZHEIM (67210)", value: "67210" },
  { label: "MELSHEIM (67270)", value: "67270" },
  { label: "MEMMELSHOFFEN (67250)", value: "67250" },
  { label: "MENCHHOFFEN (67340)", value: "67340" },
  { label: "MERKWILLER PECHELBRONN (67250)", value: "67250" },
  { label: "MERKWILLER PECHELBRONN (67250)", value: "67250" },
  { label: "MERTZWILLER (67580)", value: "67580" },
  { label: "MIETESHEIM (67580)", value: "67580" },
  { label: "MINVERSHEIM (67270)", value: "67270" },
  { label: "MITTELBERGHEIM (67140)", value: "67140" },
  { label: "MITTELHAUSBERGEN (67206)", value: "67206" },
  { label: "MITTELSCHAEFFOLSHEIM (67170)", value: "67170" },
  { label: "MOLLKIRCH (67190)", value: "67190" },
  { label: "MOLSHEIM (67120)", value: "67120" },
  { label: "MOMMENHEIM (67670)", value: "67670" },
  { label: "MONSWILLER (67700)", value: "67700" },
  { label: "MORSBRONN LES BAINS (67360)", value: "67360" },
  { label: "MORSCHWILLER (67350)", value: "67350" },
  { label: "MOTHERN (67470)", value: "67470" },
  { label: "MUHLBACH SUR BRUCHE (67130)", value: "67130" },
  { label: "MULHAUSEN (67350)", value: "67350" },
  { label: "MUNCHHAUSEN (67470)", value: "67470" },
  { label: "MUNDOLSHEIM (67450)", value: "67450" },
  { label: "MUSSIG (67600)", value: "67600" },
  { label: "MUTTERSHOLTZ (67600)", value: "67600" },
  { label: "MUTTERSHOLTZ (67600)", value: "67600" },
  { label: "MUTZENHOUSE (67270)", value: "67270" },
  { label: "MUTZIG (67190)", value: "67190" },
  { label: "NATZWILLER (67130)", value: "67130" },
  { label: "NEEWILLER PRES LAUTERBOURG (67630)", value: "67630" },
  { label: "NEUBOIS (67220)", value: "67220" },
  { label: "NEUHAEUSEL (67480)", value: "67480" },
  { label: "NEUVE EGLISE (67220)", value: "67220" },
  { label: "NEUVILLER LA ROCHE (67130)", value: "67130" },
  { label: "NEUVILLER LA ROCHE (67130)", value: "67130" },
  { label: "NEUWILLER LES SAVERNE (67330)", value: "67330" },
  { label: "NIEDERBRONN LES BAINS (67110)", value: "67110" },
  { label: "NIEDERHASLACH (67280)", value: "67280" },
  { label: "NIEDERHAUSBERGEN (67207)", value: "67207" },
  { label: "NIEDERLAUTERBACH (67630)", value: "67630" },
  { label: "NIEDERMODERN (67350)", value: "67350" },
  { label: "NIEDERNAI (67210)", value: "67210" },
  { label: "NIEDERROEDERN (67470)", value: "67470" },
  { label: "NIEDERSCHAEFFOLSHEIM (67500)", value: "67500" },
  { label: "NIEDERSOULTZBACH (67330)", value: "67330" },
  { label: "NIEDERSTEINBACH (67510)", value: "67510" },
  { label: "NORDHEIM (67520)", value: "67520" },
  { label: "NORDHOUSE (67150)", value: "67150" },
  { label: "NOTHALTEN (67680)", value: "67680" },
  { label: "OBENHEIM (67230)", value: "67230" },
  { label: "BETSCHDORF (67660)", value: "67660" },
  { label: "BETSCHDORF (67660)", value: "67660" },
  { label: "BETSCHDORF (67660)", value: "67660" },
  { label: "BETSCHDORF (67660)", value: "67660" },
  { label: "OBERBRONN (67110)", value: "67110" },
  { label: "OBERDORF SPACHBACH (67360)", value: "67360" },
  { label: "OBERHASLACH (67280)", value: "67280" },
  { label: "OBERHAUSBERGEN (67205)", value: "67205" },
  { label: "OBERHOFFEN LES WISSEMBOURG (67160)", value: "67160" },
  { label: "OBERHOFFEN SUR MODER (67240)", value: "67240" },
  { label: "OBERHOFFEN SUR MODER (67240)", value: "67240" },
  { label: "OBERLAUTERBACH (67160)", value: "67160" },
  { label: "OBERMODERN ZUTZENDORF (67330)", value: "67330" },
  { label: "OBERMODERN ZUTZENDORF (67330)", value: "67330" },
  { label: "OBERMODERN ZUTZENDORF (67330)", value: "67330" },
  { label: "OBERNAI (67210)", value: "67210" },
  { label: "OBERROEDERN (67250)", value: "67250" },
  { label: "OBERSCHAEFFOLSHEIM (67203)", value: "67203" },
  { label: "SEEBACH (67160)", value: "67160" },
  { label: "SEEBACH (67160)", value: "67160" },
  { label: "SEEBACH (67160)", value: "67160" },
  { label: "OBERSOULTZBACH (67330)", value: "67330" },
  { label: "OBERSTEINBACH (67510)", value: "67510" },
  { label: "ODRATZHEIM (67520)", value: "67520" },
  { label: "OERMINGEN (67970)", value: "67970" },
  { label: "OFFENDORF (67850)", value: "67850" },
  { label: "OFFWILLER (67340)", value: "67340" },
  { label: "OHLUNGEN (67170)", value: "67170" },
  { label: "OHLUNGEN (67590)", value: "67590" },
  { label: "OHNENHEIM (67390)", value: "67390" },
  { label: "OLWISHEIM (67170)", value: "67170" },
  { label: "ORSCHWILLER (67600)", value: "67600" },
  { label: "OSTHOFFEN (67990)", value: "67990" },
  { label: "OSTHOUSE (67150)", value: "67150" },
  { label: "OSTWALD (67540)", value: "67540" },
  { label: "OTTERSTHAL (67700)", value: "67700" },
  { label: "OTTERSWILLER (67700)", value: "67700" },
  { label: "OTTROTT (67530)", value: "67530" },
  { label: "OTTWILLER (67320)", value: "67320" },
  { label: "PETERSBACH (67290)", value: "67290" },
  { label: "LA PETITE PIERRE (67290)", value: "67290" },
  { label: "VAL DE MODER (67350)", value: "67350" },
  { label: "VAL DE MODER (67350)", value: "67350" },
  { label: "VAL DE MODER (67350)", value: "67350" },
  { label: "VAL DE MODER (67350)", value: "67350" },
  { label: "PFALZWEYER (67320)", value: "67320" },
  { label: "PFULGRIESHEIM (67370)", value: "67370" },
  { label: "PLAINE (67420)", value: "67420" },
  { label: "PLAINE (67420)", value: "67420" },
  { label: "PLAINE (67420)", value: "67420" },
  { label: "PLOBSHEIM (67115)", value: "67115" },
  { label: "PREUSCHDORF (67250)", value: "67250" },
  { label: "PRINTZHEIM (67490)", value: "67490" },
  { label: "PUBERG (67290)", value: "67290" },
  { label: "QUATZENHEIM (67117)", value: "67117" },
  { label: "RANGEN (67310)", value: "67310" },
  { label: "RANRUPT (67420)", value: "67420" },
  { label: "RATZWILLER (67430)", value: "67430" },
  { label: "RAUWILLER (67320)", value: "67320" },
  { label: "REICHSFELD (67140)", value: "67140" },
  { label: "REICHSHOFFEN (67110)", value: "67110" },
  { label: "REICHSHOFFEN (67110)", value: "67110" },
  { label: "REICHSTETT (67116)", value: "67116" },
  { label: "REINHARDSMUNSTER (67440)", value: "67440" },
  { label: "REIPERTSWILLER (67340)", value: "67340" },
  { label: "RETSCHWILLER (67250)", value: "67250" },
  { label: "REUTENBOURG (67440)", value: "67440" },
  { label: "REXINGEN (67320)", value: "67320" },
  { label: "RHINAU (67860)", value: "67860" },
  { label: "RICHTOLSHEIM (67390)", value: "67390" },
  { label: "RIEDSELTZ (67160)", value: "67160" },
  { label: "RIMSDORF (67260)", value: "67260" },
  { label: "RINGENDORF (67350)", value: "67350" },
  { label: "RITTERSHOFFEN (67690)", value: "67690" },
  { label: "ROESCHWOOG (67480)", value: "67480" },
  { label: "ROHR (67270)", value: "67270" },
  { label: "ROHRWILLER (67410)", value: "67410" },
  { label: "ROMANSWILLER (67310)", value: "67310" },
  { label: "ROPPENHEIM (67480)", value: "67480" },
  { label: "ROSENWILLER (67560)", value: "67560" },
  { label: "ROSHEIM (67560)", value: "67560" },
  { label: "ROSSFELD (67230)", value: "67230" },
  { label: "ROSTEIG (67290)", value: "67290" },
  { label: "ROTHAU (67570)", value: "67570" },
  { label: "ROTHBACH (67340)", value: "67340" },
  { label: "ROTT (67160)", value: "67160" },
  { label: "ROTTELSHEIM (67170)", value: "67170" },
  { label: "ROUNTZENHEIM AUENHEIM (67480)", value: "67480" },
  { label: "ROUNTZENHEIM AUENHEIM (67480)", value: "67480" },
  { label: "RUSS (67130)", value: "67130" },
  { label: "RUSS (67130)", value: "67130" },
  { label: "RUSS (67130)", value: "67130" },
  { label: "SAALES (67420)", value: "67420" },
  { label: "SAASENHEIM (67390)", value: "67390" },
  { label: "SAESSOLSHEIM (67270)", value: "67270" },
  { label: "ST BLAISE LA ROCHE (67420)", value: "67420" },
  { label: "ST JEAN SAVERNE (67700)", value: "67700" },
  { label: "ST MARTIN (67220)", value: "67220" },
  { label: "ST MAURICE (67220)", value: "67220" },
  { label: "ST NABOR (67530)", value: "67530" },
  { label: "ST PIERRE (67140)", value: "67140" },
  { label: "ST PIERRE BOIS (67220)", value: "67220" },
  { label: "ST PIERRE BOIS (67220)", value: "67220" },
  { label: "SALMBACH (67160)", value: "67160" },
  { label: "SAND (67230)", value: "67230" },
  { label: "SARRE UNION (67260)", value: "67260" },
  { label: "SARREWERDEN (67260)", value: "67260" },
  { label: "SARREWERDEN (67260)", value: "67260" },
  { label: "SARREWERDEN (67260)", value: "67260" },
  { label: "SAULXURES (67420)", value: "67420" },
  { label: "SAVERNE (67700)", value: "67700" },
  { label: "SAVERNE (67700)", value: "67700" },
  { label: "SAVERNE (67700)", value: "67700" },
  { label: "SCHAEFFERSHEIM (67150)", value: "67150" },
  { label: "SCHAFFHOUSE PRES SELTZ (67470)", value: "67470" },
  { label: "SCHALKENDORF (67350)", value: "67350" },
  { label: "SCHARRACHBERGHEIM IRMSTETT (67310)", value: "67310" },
  { label: "SCHARRACHBERGHEIM IRMSTETT (67310)", value: "67310" },
  { label: "SCHEIBENHARD (67630)", value: "67630" },
  { label: "SCHERLENHEIM (67270)", value: "67270" },
  { label: "SCHERWILLER (67750)", value: "67750" },
  { label: "SCHERWILLER (67750)", value: "67750" },
  { label: "SCHILLERSDORF (67340)", value: "67340" },
  { label: "SCHILTIGHEIM (67300)", value: "67300" },
  { label: "SCHIRMECK (67130)", value: "67130" },
  { label: "SCHIRMECK (67130)", value: "67130" },
  { label: "SCHIRRHEIN (67240)", value: "67240" },
  { label: "SCHIRRHOFFEN (67240)", value: "67240" },
  { label: "SCHLEITHAL (67160)", value: "67160" },
  { label: "SCHNERSHEIM (67370)", value: "67370" },
  { label: "SCHNERSHEIM (67370)", value: "67370" },
  { label: "SCHNERSHEIM (67370)", value: "67370" },
  { label: "SCHOENAU (67390)", value: "67390" },
  { label: "SCHOENBOURG (67320)", value: "67320" },
  { label: "SCHOENENBOURG (67250)", value: "67250" },
  { label: "SCHOPPERTEN (67260)", value: "67260" },
  { label: "SCHWEIGHOUSE SUR MODER (67590)", value: "67590" },
  { label: "SCHWENHEIM (67440)", value: "67440" },
  { label: "SCHWINDRATZHEIM (67270)", value: "67270" },
  { label: "SCHWOBSHEIM (67390)", value: "67390" },
  { label: "SELESTAT (67600)", value: "67600" },
  { label: "SELTZ (67470)", value: "67470" },
  { label: "SERMERSHEIM (67230)", value: "67230" },
  { label: "SESSENHEIM (67770)", value: "67770" },
  { label: "SIEGEN (67160)", value: "67160" },
  { label: "SIEWILLER (67320)", value: "67320" },
  { label: "SILTZHEIM (67260)", value: "67260" },
  { label: "SOLBACH (67130)", value: "67130" },
  { label: "SOUFFELWEYERSHEIM (67460)", value: "67460" },
  { label: "SOUFFLENHEIM (67620)", value: "67620" },
  { label: "SOULTZ LES BAINS (67120)", value: "67120" },
  { label: "SOULTZ SOUS FORETS (67250)", value: "67250" },
  { label: "SOULTZ SOUS FORETS (67250)", value: "67250" },
  { label: "SPARSBACH (67340)", value: "67340" },
  { label: "STATTMATTEN (67770)", value: "67770" },
  { label: "STEIGE (67220)", value: "67220" },
  { label: "STEINBOURG (67790)", value: "67790" },
  { label: "STEINSELTZ (67160)", value: "67160" },
  { label: "STILL (67190)", value: "67190" },
  { label: "STOTZHEIM (67140)", value: "67140" },
  { label: "STRASBOURG (67000)", value: "67000" },
  { label: "STRASBOURG (67100)", value: "67100" },
  { label: "STRASBOURG (67200)", value: "67200" },
  { label: "STRUTH (67290)", value: "67290" },
  { label: "STUNDWILLER (67250)", value: "67250" },
  { label: "STUTZHEIM OFFENHEIM (67370)", value: "67370" },
  { label: "STUTZHEIM OFFENHEIM (67370)", value: "67370" },
  { label: "SUNDHOUSE (67920)", value: "67920" },
  { label: "SURBOURG (67250)", value: "67250" },
  { label: "THAL DRULINGEN (67320)", value: "67320" },
  { label: "THAL MARMOUTIER (67440)", value: "67440" },
  { label: "THAL MARMOUTIER (67440)", value: "67440" },
  { label: "THAL MARMOUTIER (67440)", value: "67440" },
  { label: "THANVILLE (67220)", value: "67220" },
  { label: "TIEFFENBACH (67290)", value: "67290" },
  { label: "TRAENHEIM (67310)", value: "67310" },
  { label: "TRIEMBACH AU VAL (67220)", value: "67220" },
  { label: "TRIMBACH (67470)", value: "67470" },
  { label: "TRUCHTERSHEIM (67370)", value: "67370" },
  { label: "TRUCHTERSHEIM (67370)", value: "67370" },
  { label: "TRUCHTERSHEIM (67370)", value: "67370" },
  { label: "UHLWILLER (67350)", value: "67350" },
  { label: "UHLWILLER (67350)", value: "67350" },
  { label: "UHRWILLER (67350)", value: "67350" },
  { label: "URBEIS (67220)", value: "67220" },
  { label: "URMATT (67280)", value: "67280" },
  { label: "UTTENHEIM (67150)", value: "67150" },
  { label: "UTTENHOFFEN (67110)", value: "67110" },
  { label: "UTTWILLER (67330)", value: "67330" },
  { label: "VALFF (67210)", value: "67210" },
  { label: "LA VANCELLE (67730)", value: "67730" },
  { label: "VENDENHEIM (67550)", value: "67550" },
  { label: "VILLE (67220)", value: "67220" },
  { label: "VOELLERDINGEN (67430)", value: "67430" },
  { label: "VOLKSBERG (67290)", value: "67290" },
  { label: "WAHLENHEIM (67170)", value: "67170" },
  { label: "WALBOURG (67360)", value: "67360" },
  { label: "WALDERSBACH (67130)", value: "67130" },
  { label: "WALDHAMBACH (67430)", value: "67430" },
  { label: "WALDOLWISHEIM (67700)", value: "67700" },
  { label: "WALTENHEIM SUR ZORN (67670)", value: "67670" },
  { label: "WANGEN (67520)", value: "67520" },
  { label: "LA WANTZENAU (67610)", value: "67610" },
  { label: "WASSELONNE (67310)", value: "67310" },
  { label: "WASSELONNE (67310)", value: "67310" },
  { label: "WEINBOURG (67340)", value: "67340" },
  { label: "WEISLINGEN (67290)", value: "67290" },
  { label: "WEITBRUCH (67500)", value: "67500" },
  { label: "WEITERSWILLER (67340)", value: "67340" },
  { label: "WESTHOFFEN (67310)", value: "67310" },
  { label: "WESTHOUSE (67230)", value: "67230" },
  { label: "WESTHOUSE MARMOUTIER (67440)", value: "67440" },
  { label: "WEYER (67320)", value: "67320" },
  { label: "WEYERSHEIM (67720)", value: "67720" },
  { label: "WICKERSHEIM WILSHAUSEN (67270)", value: "67270" },
  { label: "WICKERSHEIM WILSHAUSEN (67270)", value: "67270" },
  { label: "WILDERSBACH (67130)", value: "67130" },
  { label: "WILLGOTTHEIM (67370)", value: "67370" },
  { label: "WILLGOTTHEIM (67370)", value: "67370" },
  { label: "WILWISHEIM (67270)", value: "67270" },
  { label: "WIMMENAU (67290)", value: "67290" },
  { label: "WINDSTEIN (67110)", value: "67110" },
  { label: "WINDSTEIN (67110)", value: "67110" },
  { label: "WINGEN (67510)", value: "67510" },
  { label: "WINGEN (67510)", value: "67510" },
  { label: "WINGEN SUR MODER (67290)", value: "67290" },
  { label: "WINGERSHEIM LES QUATRE BANS (67170)", value: "67170" },
  { label: "WINGERSHEIM LES QUATRE BANS (67170)", value: "67170" },
  { label: "WINGERSHEIM LES QUATRE BANS (67170)", value: "67170" },
  { label: "WINGERSHEIM LES QUATRE BANS (67270)", value: "67270" },
  { label: "WINTERSHOUSE (67590)", value: "67590" },
  { label: "WINTZENBACH (67470)", value: "67470" },
  { label: "WINTZENHEIM KOCHERSBERG (67370)", value: "67370" },
  { label: "WISCHES (67130)", value: "67130" },
  { label: "WISCHES (67130)", value: "67130" },
  { label: "WISCHES (67130)", value: "67130" },
  { label: "WISSEMBOURG (67160)", value: "67160" },
  { label: "WISSEMBOURG (67160)", value: "67160" },
  { label: "WISSEMBOURG (67160)", value: "67160" },
  { label: "WITTERNHEIM (67230)", value: "67230" },
  { label: "WITTERSHEIM (67670)", value: "67670" },
  { label: "WITTISHEIM (67820)", value: "67820" },
  { label: "WIWERSHEIM (67370)", value: "67370" },
  { label: "WOERTH (67360)", value: "67360" },
  { label: "WOLFISHEIM (67202)", value: "67202" },
  { label: "WOLFSKIRCHEN (67260)", value: "67260" },
  { label: "WOLSCHHEIM (67700)", value: "67700" },
  { label: "WOLXHEIM (67120)", value: "67120" },
  { label: "WOLXHEIM (67120)", value: "67120" },
  { label: "ZEHNACKER (67310)", value: "67310" },
  { label: "ZEINHEIM (67310)", value: "67310" },
  { label: "ZELLWILLER (67140)", value: "67140" },
  { label: "ZINSWILLER (67110)", value: "67110" },
  { label: "ZITTERSHEIM (67290)", value: "67290" },
  { label: "ALGOLSHEIM (68600)", value: "68600" },
  { label: "ALTENACH (68210)", value: "68210" },
  { label: "ALTKIRCH (68130)", value: "68130" },
  { label: "AMMERSCHWIHR (68410)", value: "68410" },
  { label: "AMMERSCHWIHR (68770)", value: "68770" },
  { label: "BERNWILLER (68210)", value: "68210" },
  { label: "BERNWILLER (68210)", value: "68210" },
  { label: "ANDOLSHEIM (68280)", value: "68280" },
  { label: "APPENWIHR (68280)", value: "68280" },
  { label: "ARTZENHEIM (68320)", value: "68320" },
  { label: "ASPACH (68130)", value: "68130" },
  { label: "ASPACH LE BAS (68700)", value: "68700" },
  { label: "ASPACH MICHELBACH (68700)", value: "68700" },
  { label: "ASPACH MICHELBACH (68700)", value: "68700" },
  { label: "ATTENSCHWILLER (68220)", value: "68220" },
  { label: "AUBURE (68150)", value: "68150" },
  { label: "BALDERSHEIM (68390)", value: "68390" },
  { label: "BALGAU (68740)", value: "68740" },
  { label: "BALLERSDORF (68210)", value: "68210" },
  { label: "BALSCHWILLER (68210)", value: "68210" },
  { label: "BALSCHWILLER (68210)", value: "68210" },
  { label: "BALTZENHEIM (68320)", value: "68320" },
  { label: "BANTZENHEIM (68490)", value: "68490" },
  { label: "BARTENHEIM (68870)", value: "68870" },
  { label: "BARTENHEIM (68870)", value: "68870" },
  { label: "BATTENHEIM (68390)", value: "68390" },
  { label: "BEBLENHEIM (68980)", value: "68980" },
  { label: "BELLEMAGNY (68210)", value: "68210" },
  { label: "BENDORF (68480)", value: "68480" },
  { label: "BENNWIHR (68126)", value: "68126" },
  { label: "BENNWIHR (68630)", value: "68630" },
  { label: "BERENTZWILLER (68130)", value: "68130" },
  { label: "BERGHEIM (68750)", value: "68750" },
  { label: "BERGHOLTZ (68500)", value: "68500" },
  { label: "BERGHOLTZZELL (68500)", value: "68500" },
  { label: "BERRWILLER (68500)", value: "68500" },
  { label: "BETTENDORF (68560)", value: "68560" },
  { label: "BETTLACH (68480)", value: "68480" },
  { label: "BIEDERTHAL (68480)", value: "68480" },
  { label: "BIESHEIM (68600)", value: "68600" },
  { label: "BILTZHEIM (68127)", value: "68127" },
  { label: "BISCHWIHR (68320)", value: "68320" },
  { label: "BISEL (68580)", value: "68580" },
  { label: "BITSCHWILLER LES THANN (68620)", value: "68620" },
  { label: "BLODELSHEIM (68740)", value: "68740" },
  { label: "BLOTZHEIM (68730)", value: "68730" },
  { label: "BOLLWILLER (68540)", value: "68540" },
  { label: "LE BONHOMME (68650)", value: "68650" },
  { label: "BOURBACH LE BAS (68290)", value: "68290" },
  { label: "BOURBACH LE HAUT (68290)", value: "68290" },
  { label: "BOUXWILLER (68480)", value: "68480" },
  { label: "BRECHAUMONT (68210)", value: "68210" },
  { label: "BREITENBACH HAUT RHIN (68380)", value: "68380" },
  { label: "BRETTEN (68780)", value: "68780" },
  { label: "BRINCKHEIM (68870)", value: "68870" },
  { label: "BRUEBACH (68440)", value: "68440" },
  { label: "BRUNSTATT DIDENHEIM (68350)", value: "68350" },
  { label: "BRUNSTATT DIDENHEIM (68350)", value: "68350" },
  { label: "BUETHWILLER (68210)", value: "68210" },
  { label: "BUHL (68530)", value: "68530" },
  { label: "BURNHAUPT LE BAS (68520)", value: "68520" },
  { label: "BURNHAUPT LE HAUT (68520)", value: "68520" },
  { label: "BUSCHWILLER (68220)", value: "68220" },
  { label: "CARSPACH (68130)", value: "68130" },
  { label: "CERNAY (68700)", value: "68700" },
  { label: "CHALAMPE (68490)", value: "68490" },
  { label: "CHAVANNES SUR L ETANG (68210)", value: "68210" },
  { label: "COLMAR (68000)", value: "68000" },
  { label: "COURTAVON (68480)", value: "68480" },
  { label: "DANNEMARIE (68210)", value: "68210" },
  { label: "DESSENHEIM (68600)", value: "68600" },
  { label: "DIEFMATTEN (68780)", value: "68780" },
  { label: "DIETWILLER (68440)", value: "68440" },
  { label: "DOLLEREN (68290)", value: "68290" },
  { label: "DURLINSDORF (68480)", value: "68480" },
  { label: "DURMENACH (68480)", value: "68480" },
  { label: "DURRENENTZEN (68320)", value: "68320" },
  { label: "EGLINGEN (68720)", value: "68720" },
  { label: "EGUISHEIM (68420)", value: "68420" },
  { label: "ELBACH (68210)", value: "68210" },
  { label: "EMLINGEN (68130)", value: "68130" },
  { label: "ST BERNARD (68720)", value: "68720" },
  { label: "ST BERNARD (68720)", value: "68720" },
  { label: "ENSISHEIM (68190)", value: "68190" },
  { label: "ESCHBACH AU VAL (68140)", value: "68140" },
  { label: "ESCHENTZWILLER (68440)", value: "68440" },
  { label: "ETEIMBES (68210)", value: "68210" },
  { label: "FALKWILLER (68210)", value: "68210" },
  { label: "FELDBACH (68640)", value: "68640" },
  { label: "FELDKIRCH (68540)", value: "68540" },
  { label: "FELLERING (68470)", value: "68470" },
  { label: "FELLERING (68470)", value: "68470" },
  { label: "FERRETTE (68480)", value: "68480" },
  { label: "FESSENHEIM (68740)", value: "68740" },
  { label: "FISLIS (68480)", value: "68480" },
  { label: "FLAXLANDEN (68720)", value: "68720" },
  { label: "FOLGENSBOURG (68220)", value: "68220" },
  { label: "FORTSCHWIHR (68320)", value: "68320" },
  { label: "FRANKEN (68130)", value: "68130" },
  { label: "FRELAND (68240)", value: "68240" },
  { label: "FRIESEN (68580)", value: "68580" },
  { label: "FROENINGEN (68720)", value: "68720" },
  { label: "FULLEREN (68210)", value: "68210" },
  { label: "GALFINGUE (68990)", value: "68990" },
  { label: "GEISHOUSE (68690)", value: "68690" },
  { label: "GEISPITZEN (68510)", value: "68510" },
  { label: "GEISWASSER (68600)", value: "68600" },
  { label: "GILDWILLER (68210)", value: "68210" },
  { label: "GOLDBACH ALTENBACH (68760)", value: "68760" },
  { label: "GOLDBACH ALTENBACH (68760)", value: "68760" },
  { label: "GOMMERSDORF (68210)", value: "68210" },
  { label: "GRIESBACH AU VAL (68140)", value: "68140" },
  { label: "GRUSSENHEIM (68320)", value: "68320" },
  { label: "GUEBERSCHWIHR (68420)", value: "68420" },
  { label: "GUEBWILLER (68500)", value: "68500" },
  { label: "GUEMAR (68970)", value: "68970" },
  { label: "GUEVENATTEN (68210)", value: "68210" },
  { label: "GUEWENHEIM (68116)", value: "68116" },
  { label: "GUNDOLSHEIM (68250)", value: "68250" },
  { label: "GUNSBACH (68140)", value: "68140" },
  { label: "HABSHEIM (68440)", value: "68440" },
  { label: "HAGENBACH (68210)", value: "68210" },
  { label: "HAGENTHAL LE BAS (68220)", value: "68220" },
  { label: "HAGENTHAL LE HAUT (68220)", value: "68220" },
  { label: "HARTMANNSWILLER (68500)", value: "68500" },
  { label: "HATTSTATT (68420)", value: "68420" },
  { label: "HAUSGAUEN (68130)", value: "68130" },
  { label: "HECKEN (68210)", value: "68210" },
  { label: "HEGENHEIM (68220)", value: "68220" },
  { label: "HEIDWILLER (68720)", value: "68720" },
  { label: "HEIMERSDORF (68560)", value: "68560" },
  { label: "HEIMSBRUNN (68990)", value: "68990" },
  { label: "HEITEREN (68600)", value: "68600" },
  { label: "HEIWILLER (68130)", value: "68130" },
  { label: "HELFRANTZKIRCH (68510)", value: "68510" },
  { label: "HERRLISHEIM PRES COLMAR (68420)", value: "68420" },
  { label: "HESINGUE (68220)", value: "68220" },
  { label: "HETTENSCHLAG (68600)", value: "68600" },
  { label: "HINDLINGEN (68580)", value: "68580" },
  { label: "HIRSINGUE (68560)", value: "68560" },
  { label: "HIRTZBACH (68118)", value: "68118" },
  { label: "HIRTZFELDEN (68740)", value: "68740" },
  { label: "HOCHSTATT (68720)", value: "68720" },
  { label: "HOHROD (68140)", value: "68140" },
  { label: "PORTE DU RIED (68320)", value: "68320" },
  { label: "PORTE DU RIED (68320)", value: "68320" },
  { label: "HOMBOURG (68490)", value: "68490" },
  { label: "HORBOURG WIHR (68180)", value: "68180" },
  { label: "HORBOURG WIHR (68180)", value: "68180" },
  { label: "HOUSSEN (68125)", value: "68125" },
  { label: "HUNAWIHR (68150)", value: "68150" },
  { label: "HUNDSBACH (68130)", value: "68130" },
  { label: "HUNINGUE (68330)", value: "68330" },
  { label: "HUSSEREN LES CHATEAUX (68420)", value: "68420" },
  { label: "HUSSEREN WESSERLING (68470)", value: "68470" },
  { label: "ILLFURTH (68720)", value: "68720" },
  { label: "ILLHAEUSERN (68970)", value: "68970" },
  { label: "ILLZACH (68110)", value: "68110" },
  { label: "ILLZACH (68110)", value: "68110" },
  { label: "INGERSHEIM (68040)", value: "68040" },
  { label: "ISSENHEIM (68500)", value: "68500" },
  { label: "JEBSHEIM (68320)", value: "68320" },
  { label: "JETTINGEN (68130)", value: "68130" },
  { label: "JUNGHOLTZ (68500)", value: "68500" },
  { label: "KAPPELEN (68510)", value: "68510" },
  { label: "KATZENTHAL (68230)", value: "68230" },
  { label: "KAYSERSBERG VIGNOBLE (68240)", value: "68240" },
  { label: "KAYSERSBERG VIGNOBLE (68240)", value: "68240" },
  { label: "KAYSERSBERG VIGNOBLE (68240)", value: "68240" },
  { label: "KEMBS (68680)", value: "68680" },
  { label: "KEMBS (68680)", value: "68680" },
  { label: "KIFFIS (68480)", value: "68480" },
  { label: "KINGERSHEIM (68260)", value: "68260" },
  { label: "KIRCHBERG (68290)", value: "68290" },
  { label: "KNOERINGUE (68220)", value: "68220" },
  { label: "KOESTLACH (68480)", value: "68480" },
  { label: "KOETZINGUE (68510)", value: "68510" },
  { label: "KRUTH (68820)", value: "68820" },
  { label: "KUNHEIM (68320)", value: "68320" },
  { label: "LABAROCHE (68910)", value: "68910" },
  { label: "LANDSER (68440)", value: "68440" },
  { label: "LAPOUTROIE (68650)", value: "68650" },
  { label: "LARGITZEN (68580)", value: "68580" },
  { label: "LAUTENBACH (68610)", value: "68610" },
  { label: "LAUTENBACH (68610)", value: "68610" },
  { label: "LAUTENBACH (68610)", value: "68610" },
  { label: "LAUTENBACHZELL (68610)", value: "68610" },
  { label: "LAUTENBACHZELL (68610)", value: "68610" },
  { label: "LAUW (68290)", value: "68290" },
  { label: "LEIMBACH (68800)", value: "68800" },
  { label: "LEVONCOURT (68480)", value: "68480" },
  { label: "LEYMEN (68220)", value: "68220" },
  { label: "LIEBENSWILLER (68220)", value: "68220" },
  { label: "LIEBSDORF (68480)", value: "68480" },
  { label: "LIEPVRE (68660)", value: "68660" },
  { label: "LIGSDORF (68480)", value: "68480" },
  { label: "LINSDORF (68480)", value: "68480" },
  { label: "LINTHAL (68610)", value: "68610" },
  { label: "LOGELHEIM (68280)", value: "68280" },
  { label: "LUCELLE (68480)", value: "68480" },
  { label: "LUEMSCHWILLER (68720)", value: "68720" },
  { label: "VALDIEU LUTRAN (68210)", value: "68210" },
  { label: "VALDIEU LUTRAN (68210)", value: "68210" },
  { label: "LUTTENBACH PRES MUNSTER (68140)", value: "68140" },
  { label: "LUTTER (68480)", value: "68480" },
  { label: "LUTTERBACH (68460)", value: "68460" },
  { label: "MAGNY (68210)", value: "68210" },
  { label: "MAGSTATT LE BAS (68510)", value: "68510" },
  { label: "MAGSTATT LE HAUT (68510)", value: "68510" },
  { label: "MALMERSPACH (68550)", value: "68550" },
  { label: "MANSPACH (68210)", value: "68210" },
  { label: "MASEVAUX NIEDERBRUCK (68290)", value: "68290" },
  { label: "MASEVAUX NIEDERBRUCK (68290)", value: "68290" },
  { label: "MERTZEN (68210)", value: "68210" },
  { label: "MERXHEIM (68500)", value: "68500" },
  { label: "METZERAL (68380)", value: "68380" },
  { label: "MEYENHEIM (68890)", value: "68890" },
  { label: "MICHELBACH LE BAS (68730)", value: "68730" },
  { label: "MICHELBACH LE HAUT (68220)", value: "68220" },
  { label: "MITTELWIHR (68630)", value: "68630" },
  { label: "MITTLACH (68380)", value: "68380" },
  { label: "MITZACH (68470)", value: "68470" },
  { label: "MOERNACH (68480)", value: "68480" },
  { label: "MOLLAU (68470)", value: "68470" },
  { label: "MONTREUX JEUNE (68210)", value: "68210" },
  { label: "MONTREUX VIEUX (68210)", value: "68210" },
  { label: "MOOSLARGUE (68580)", value: "68580" },
  { label: "MOOSLARGUE (68580)", value: "68580" },
  { label: "MOOSCH (68690)", value: "68690" },
  { label: "MORSCHWILLER LE BAS (68790)", value: "68790" },
  { label: "LE HAUT SOULTZBACH (68780)", value: "68780" },
  { label: "LE HAUT SOULTZBACH (68780)", value: "68780" },
  { label: "MUESPACH (68640)", value: "68640" },
  { label: "MUESPACH (68640)", value: "68640" },
  { label: "MUESPACH LE HAUT (68640)", value: "68640" },
  { label: "MUHLBACH SUR MUNSTER (68380)", value: "68380" },
  { label: "MULHOUSE (68100)", value: "68100" },
  { label: "MULHOUSE (68200)", value: "68200" },
  { label: "MULHOUSE (68200)", value: "68200" },
  { label: "MULHOUSE (68200)", value: "68200" },
  { label: "MUNCHHOUSE (68740)", value: "68740" },
  { label: "MUNSTER (68140)", value: "68140" },
  { label: "MUNTZENHEIM (68320)", value: "68320" },
  { label: "MUNWILLER (68250)", value: "68250" },
  { label: "MURBACH (68530)", value: "68530" },
  { label: "NAMBSHEIM (68740)", value: "68740" },
  { label: "NEUF BRISACH (68600)", value: "68600" },
  { label: "NEUWILLER (68220)", value: "68220" },
  { label: "NIEDERENTZEN (68127)", value: "68127" },
  { label: "NIEDERHERGHEIM (68127)", value: "68127" },
  { label: "NIEDERMORSCHWIHR (68230)", value: "68230" },
  { label: "NIFFER (68680)", value: "68680" },
  { label: "OBERBRUCK (68290)", value: "68290" },
  { label: "ILLTAL (68960)", value: "68960" },
  { label: "ILLTAL (68960)", value: "68960" },
  { label: "ILLTAL (68960)", value: "68960" },
  { label: "OBERENTZEN (68127)", value: "68127" },
  { label: "OBERHERGHEIM (68127)", value: "68127" },
  { label: "OBERLARG (68480)", value: "68480" },
  { label: "OBERMORSCHWIHR (68420)", value: "68420" },
  { label: "OBERMORSCHWILLER (68130)", value: "68130" },
  { label: "OBERSAASHEIM (68600)", value: "68600" },
  { label: "ODEREN (68830)", value: "68830" },
  { label: "OLTINGUE (68480)", value: "68480" },
  { label: "ORBEY (68370)", value: "68370" },
  { label: "ORSCHWIHR (68500)", value: "68500" },
  { label: "OSENBACH (68570)", value: "68570" },
  { label: "OSTHEIM (68150)", value: "68150" },
  { label: "OTTMARSHEIM (68490)", value: "68490" },
  { label: "PETIT LANDAU (68490)", value: "68490" },
  { label: "PFAFFENHEIM (68250)", value: "68250" },
  { label: "PFASTATT (68120)", value: "68120" },
  { label: "PFETTERHOUSE (68480)", value: "68480" },
  { label: "PULVERSHEIM (68840)", value: "68840" },
  { label: "RAEDERSDORF (68480)", value: "68480" },
  { label: "RAEDERSHEIM (68190)", value: "68190" },
  { label: "RAMMERSMATT (68800)", value: "68800" },
  { label: "RANSPACH (68470)", value: "68470" },
  { label: "RANSPACH LE BAS (68730)", value: "68730" },
  { label: "RANSPACH LE HAUT (68220)", value: "68220" },
  { label: "RANTZWILLER (68510)", value: "68510" },
  { label: "REGUISHEIM (68890)", value: "68890" },
  { label: "REININGUE (68950)", value: "68950" },
  { label: "RETZWILLER (68210)", value: "68210" },
  { label: "RIBEAUVILLE (68150)", value: "68150" },
  { label: "RICHWILLER (68120)", value: "68120" },
  { label: "RIEDISHEIM (68400)", value: "68400" },
  { label: "RIESPACH (68640)", value: "68640" },
  { label: "RIMBACH PRES GUEBWILLER (68500)", value: "68500" },
  { label: "RIMBACH PRES MASEVAUX (68290)", value: "68290" },
  { label: "RIMBACHZELL (68500)", value: "68500" },
  { label: "RIQUEWIHR (68340)", value: "68340" },
  { label: "RIXHEIM (68170)", value: "68170" },
  { label: "RODEREN (68800)", value: "68800" },
  { label: "RODERN (68590)", value: "68590" },
  { label: "ROGGENHOUSE (68740)", value: "68740" },
  { label: "ROMAGNY (68210)", value: "68210" },
  { label: "ROMBACH LE FRANC (68660)", value: "68660" },
  { label: "ROPPENTZWILLER (68480)", value: "68480" },
  { label: "RORSCHWIHR (68590)", value: "68590" },
  { label: "ROSENAU (68128)", value: "68128" },
  { label: "ROUFFACH (68250)", value: "68250" },
  { label: "RUEDERBACH (68560)", value: "68560" },
  { label: "RUELISHEIM (68270)", value: "68270" },
  { label: "RUSTENHART (68740)", value: "68740" },
  { label: "RUMERSHEIM LE HAUT (68740)", value: "68740" },
  { label: "ST AMARIN (68550)", value: "68550" },
  { label: "ST COSME (68210)", value: "68210" },
  { label: "STE CROIX AUX MINES (68160)", value: "68160" },
  { label: "STE CROIX EN PLAINE (68127)", value: "68127" },
  { label: "ST HIPPOLYTE (68590)", value: "68590" },
  { label: "ST LOUIS (68300)", value: "68300" },
  { label: "ST LOUIS (68300)", value: "68300" },
  { label: "ST LOUIS (68300)", value: "68300" },
  { label: "STE MARIE AUX MINES (68160)", value: "68160" },
  { label: "STE MARIE AUX MINES (68160)", value: "68160" },
  { label: "ST ULRICH (68210)", value: "68210" },
  { label: "SAUSHEIM (68390)", value: "68390" },
  { label: "SCHLIERBACH (68440)", value: "68440" },
  { label: "SCHWEIGHOUSE THANN (68520)", value: "68520" },
  { label: "SCHWOBEN (68130)", value: "68130" },
  { label: "SENTHEIM (68780)", value: "68780" },
  { label: "SEPPOIS LE BAS (68580)", value: "68580" },
  { label: "SEPPOIS LE HAUT (68580)", value: "68580" },
  { label: "SEWEN (68290)", value: "68290" },
  { label: "SICKERT (68290)", value: "68290" },
  { label: "SIERENTZ (68510)", value: "68510" },
  { label: "SONDERNACH (68380)", value: "68380" },
  { label: "SONDERSDORF (68480)", value: "68480" },
  { label: "SOPPE LE BAS (68780)", value: "68780" },
  { label: "SOULTZ HAUT RHIN (68360)", value: "68360" },
  { label: "SOULTZBACH LES BAINS (68230)", value: "68230" },
  { label: "SOULTZEREN (68140)", value: "68140" },
  { label: "SOULTZMATT (68570)", value: "68570" },
  { label: "SOULTZMATT (68570)", value: "68570" },
  { label: "SPECHBACH (68720)", value: "68720" },
  { label: "SPECHBACH (68720)", value: "68720" },
  { label: "STAFFELFELDEN (68850)", value: "68850" },
  { label: "STEINBACH (68700)", value: "68700" },
  { label: "STEINBRUNN LE BAS (68440)", value: "68440" },
  { label: "STEINBRUNN LE HAUT (68440)", value: "68440" },
  { label: "STEINSOULTZ (68640)", value: "68640" },
  { label: "STERNENBERG (68780)", value: "68780" },
  { label: "STETTEN (68510)", value: "68510" },
  { label: "STORCKENSOHN (68470)", value: "68470" },
  { label: "STOSSWIHR (68140)", value: "68140" },
  { label: "STRUETH (68580)", value: "68580" },
  { label: "SUNDHOFFEN (68280)", value: "68280" },
  { label: "TAGOLSHEIM (68720)", value: "68720" },
  { label: "TAGSDORF (68130)", value: "68130" },
  { label: "THANN (68800)", value: "68800" },
  { label: "THANNENKIRCH (68590)", value: "68590" },
  { label: "TRAUBACH LE BAS (68210)", value: "68210" },
  { label: "TRAUBACH LE HAUT (68210)", value: "68210" },
  { label: "TURCKHEIM (68230)", value: "68230" },
  { label: "UEBERSTRASS (68580)", value: "68580" },
  { label: "UFFHEIM (68510)", value: "68510" },
  { label: "UFFHOLTZ (68700)", value: "68700" },
  { label: "UNGERSHEIM (68190)", value: "68190" },
  { label: "URBES (68121)", value: "68121" },
  { label: "URSCHENHEIM (68320)", value: "68320" },
  { label: "VIEUX FERRETTE (68480)", value: "68480" },
  { label: "VIEUX THANN (68800)", value: "68800" },
  { label: "VILLAGE NEUF (68128)", value: "68128" },
  { label: "VOEGTLINSHOFFEN (68420)", value: "68420" },
  { label: "VOGELGRUN (68600)", value: "68600" },
  { label: "VOLGELSHEIM (68600)", value: "68600" },
  { label: "WAHLBACH (68130)", value: "68130" },
  { label: "WALBACH (68230)", value: "68230" },
  { label: "WALDIGHOFEN (68640)", value: "68640" },
  { label: "WALHEIM (68130)", value: "68130" },
  { label: "WALTENHEIM (68510)", value: "68510" },
  { label: "WASSERBOURG (68230)", value: "68230" },
  { label: "WATTWILLER (68700)", value: "68700" },
  { label: "WECKOLSHEIM (68600)", value: "68600" },
  { label: "WEGSCHEID (68290)", value: "68290" },
  { label: "WENTZWILLER (68220)", value: "68220" },
  { label: "WERENTZHOUSE (68480)", value: "68480" },
  { label: "WESTHALTEN (68250)", value: "68250" },
  { label: "WETTOLSHEIM (68920)", value: "68920" },
  { label: "WICKERSCHWIHR (68320)", value: "68320" },
  { label: "WIDENSOLEN (68320)", value: "68320" },
  { label: "WIHR AU VAL (68230)", value: "68230" },
  { label: "WILDENSTEIN (68820)", value: "68820" },
  { label: "WILLER (68960)", value: "68960" },
  { label: "WILLER SUR THUR (68760)", value: "68760" },
  { label: "WINKEL (68480)", value: "68480" },
  { label: "WINTZENHEIM (68124)", value: "68124" },
  { label: "WINTZENHEIM (68920)", value: "68920" },
  { label: "WITTELSHEIM (68310)", value: "68310" },
  { label: "WITTENHEIM (68270)", value: "68270" },
  { label: "WITTERSDORF (68130)", value: "68130" },
  { label: "WOLFERSDORF (68210)", value: "68210" },
  { label: "WOLFGANTZEN (68600)", value: "68600" },
  { label: "WOLSCHWILLER (68480)", value: "68480" },
  { label: "WUENHEIM (68500)", value: "68500" },
  { label: "ZAESSINGUE (68130)", value: "68130" },
  { label: "ZELLENBERG (68340)", value: "68340" },
  { label: "ZILLISHEIM (68720)", value: "68720" },
  { label: "ZIMMERBACH (68230)", value: "68230" },
  { label: "ZIMMERSHEIM (68440)", value: "68440" },
  { label: "AFFOUX (69170)", value: "69170" },
  { label: "AIGUEPERSE (69790)", value: "69790" },
  { label: "ALBIGNY SUR SAONE (69250)", value: "69250" },
  { label: "ALIX (69380)", value: "69380" },
  { label: "AMBERIEUX (69480)", value: "69480" },
  { label: "AMPLEPUIS (69550)", value: "69550" },
  { label: "AMPLEPUIS (69550)", value: "69550" },
  { label: "AMPUIS (69420)", value: "69420" },
  { label: "ANCY (69490)", value: "69490" },
  { label: "ANSE (69480)", value: "69480" },
  { label: "L'ARBRESLE (69210)", value: "69210" },
  { label: "LES ARDILLATS (69430)", value: "69430" },
  { label: "ARNAS (69400)", value: "69400" },
  { label: "AVEIZE (69610)", value: "69610" },
  { label: "AZOLETTE (69790)", value: "69790" },
  { label: "BAGNOLS (69620)", value: "69620" },
  { label: "BEAUJEU (69430)", value: "69430" },
  { label: "BEAUJEU (69430)", value: "69430" },
  { label: "BELLEVILLE EN BEAUJOLAIS (69220)", value: "69220" },
  { label: "BELLEVILLE EN BEAUJOLAIS (69220)", value: "69220" },
  { label: "BELMONT D AZERGUES (69380)", value: "69380" },
  { label: "BESSENAY (69690)", value: "69690" },
  { label: "BESSENAY (69690)", value: "69690" },
  { label: "BESSENAY (69690)", value: "69690" },
  { label: "BESSENAY (69690)", value: "69690" },
  { label: "BESSENAY (69690)", value: "69690" },
  { label: "BESSENAY (69690)", value: "69690" },
  { label: "BIBOST (69690)", value: "69690" },
  { label: "BIBOST (69690)", value: "69690" },
  { label: "BLACE (69460)", value: "69460" },
  { label: "VAL D OINGT (69620)", value: "69620" },
  { label: "VAL D OINGT (69620)", value: "69620" },
  { label: "VAL D OINGT (69620)", value: "69620" },
  { label: "LE BREUIL (69620)", value: "69620" },
  { label: "BRIGNAIS (69530)", value: "69530" },
  { label: "BRIGNAIS (69530)", value: "69530" },
  { label: "BRINDAS (69126)", value: "69126" },
  { label: "BRON (69500)", value: "69500" },
  { label: "BRULLIOLES (69690)", value: "69690" },
  { label: "BRUSSIEU (69690)", value: "69690" },
  { label: "BULLY (69210)", value: "69210" },
  { label: "CAILLOUX SUR FONTAINES (69270)", value: "69270" },
  { label: "CALUIRE ET CUIRE (69300)", value: "69300" },
  { label: "CENVES (69840)", value: "69840" },
  { label: "CERCIE (69220)", value: "69220" },
  { label: "CHAMBOST ALLIERES (69870)", value: "69870" },
  { label: "CHAMBOST LONGESSAIGNE (69770)", value: "69770" },
  { label: "CHAMELET (69620)", value: "69620" },
  { label: "CHAMPAGNE AU MONT D OR (69410)", value: "69410" },
  { label: "LA CHAPELLE SUR COISE (69590)", value: "69590" },
  { label: "CHAPONOST (69630)", value: "69630" },
  { label: "CHARBONNIERES LES BAINS (69260)", value: "69260" },
  { label: "CHARENTAY (69220)", value: "69220" },
  { label: "CHARLY (69390)", value: "69390" },
  { label: "CHARNAY (69380)", value: "69380" },
  { label: "CHASSELAY (69380)", value: "69380" },
  { label: "CHATILLON (69380)", value: "69380" },
  { label: "CHAUSSAN (69440)", value: "69440" },
  { label: "CHAUSSAN (69440)", value: "69440" },
  { label: "CHAZAY D AZERGUES (69380)", value: "69380" },
  { label: "CHENAS (69840)", value: "69840" },
  { label: "CHENELETTE (69430)", value: "69430" },
  { label: "LES CHERES (69380)", value: "69380" },
  { label: "CHESSY (69380)", value: "69380" },
  { label: "CHEVINAY (69210)", value: "69210" },
  { label: "CHIROUBLES (69115)", value: "69115" },
  { label: "CIVRIEUX D AZERGUES (69380)", value: "69380" },
  { label: "CLAVEISOLLES (69870)", value: "69870" },
  { label: "COGNY (69640)", value: "69640" },
  { label: "COISE (69590)", value: "69590" },
  { label: "COLLONGES AU MONT D OR (69660)", value: "69660" },
  { label: "CONDRIEU (69420)", value: "69420" },
  { label: "CORCELLES EN BEAUJOLAIS (69220)", value: "69220" },
  { label: "COURS (69470)", value: "69470" },
  { label: "COURS (69470)", value: "69470" },
  { label: "COURS (69470)", value: "69470" },
  { label: "COURS (69470)", value: "69470" },
  { label: "COURZIEU (69690)", value: "69690" },
  { label: "COUZON AU MONT D OR (69270)", value: "69270" },
  { label: "CRAPONNE (69290)", value: "69290" },
  { label: "CUBLIZE (69550)", value: "69550" },
  { label: "CURIS AU MONT D OR (69250)", value: "69250" },
  { label: "DARDILLY (69570)", value: "69570" },
  { label: "DENICE (69640)", value: "69640" },
  { label: "DIEME (69170)", value: "69170" },
  { label: "DOMMARTIN (69380)", value: "69380" },
  { label: "DRACE (69220)", value: "69220" },
  { label: "DUERNE (69850)", value: "69850" },
  { label: "ECHALAS (69700)", value: "69700" },
  { label: "ECHALAS (69700)", value: "69700" },
  { label: "ECULLY (69130)", value: "69130" },
  { label: "EMERINGES (69840)", value: "69840" },
  { label: "EVEUX (69210)", value: "69210" },
  { label: "FLEURIE (69820)", value: "69820" },
  { label: "FLEURIEU SUR SAONE (69250)", value: "69250" },
  { label: "FLEURIEUX SUR L ARBRESLE (69210)", value: "69210" },
  { label: "FONTAINES ST MARTIN (69270)", value: "69270" },
  { label: "FONTAINES SUR SAONE (69270)", value: "69270" },
  { label: "FRANCHEVILLE (69340)", value: "69340" },
  { label: "FRONTENAS (69620)", value: "69620" },
  { label: "GIVORS (69700)", value: "69700" },
  { label: "GIVORS (69700)", value: "69700" },
  { label: "GLEIZE (69400)", value: "69400" },
  { label: "GRANDRIS (69870)", value: "69870" },
  { label: "GREZIEU LA VARENNE (69290)", value: "69290" },
  { label: "GREZIEU LE MARCHE (69610)", value: "69610" },
  { label: "GRIGNY (69520)", value: "69520" },
  { label: "LES HAIES (69420)", value: "69420" },
  { label: "LES HALLES (69610)", value: "69610" },
  { label: "HAUTE RIVOIRE (69610)", value: "69610" },
  { label: "IRIGNY (69540)", value: "69540" },
  { label: "JOUX (69170)", value: "69170" },
  { label: "JULIENAS (69840)", value: "69840" },
  { label: "JULLIE (69840)", value: "69840" },
  { label: "LACENAS (69640)", value: "69640" },
  { label: "LACHASSAGNE (69480)", value: "69480" },
  { label: "LAMURE SUR AZERGUES (69870)", value: "69870" },
  { label: "LANCIE (69220)", value: "69220" },
  { label: "LANTIGNIE (69430)", value: "69430" },
  { label: "LARAJASSE (69590)", value: "69590" },
  { label: "LARAJASSE (69590)", value: "69590" },
  { label: "LEGNY (69620)", value: "69620" },
  { label: "LENTILLY (69210)", value: "69210" },
  { label: "LETRA (69620)", value: "69620" },
  { label: "LETRA (69620)", value: "69620" },
  { label: "LIMAS (69400)", value: "69400" },
  { label: "LIMONEST (69760)", value: "69760" },
  { label: "LISSIEU (69380)", value: "69380" },
  { label: "LOIRE SUR RHONE (69700)", value: "69700" },
  { label: "LONGES (69420)", value: "69420" },
  { label: "LONGESSAIGNE (69770)", value: "69770" },
  { label: "LOZANNE (69380)", value: "69380" },
  { label: "LUCENAY (69480)", value: "69480" },
  { label: "MARCHAMPT (69430)", value: "69430" },
  { label: "MARCILLY D AZERGUES (69380)", value: "69380" },
  { label: "MARCY (69480)", value: "69480" },
  { label: "MARCY L ETOILE (69280)", value: "69280" },
  { label: "MEAUX LA MONTAGNE (69550)", value: "69550" },
  { label: "MESSIMY (69510)", value: "69510" },
  { label: "MEYS (69610)", value: "69610" },
  { label: "MILLERY (69390)", value: "69390" },
  { label: "MOIRE (69620)", value: "69620" },
  { label: "DEUX GROSNES (69430)", value: "69430" },
  { label: "DEUX GROSNES (69860)", value: "69860" },
  { label: "DEUX GROSNES (69860)", value: "69860" },
  { label: "DEUX GROSNES (69860)", value: "69860" },
  { label: "DEUX GROSNES (69860)", value: "69860" },
  { label: "DEUX GROSNES (69860)", value: "69860" },
  { label: "DEUX GROSNES (69860)", value: "69860" },
  { label: "MONTAGNY (69700)", value: "69700" },
  { label: "MONTAGNY (69700)", value: "69700" },
  { label: "MONTMELAS ST SORLIN (69640)", value: "69640" },
  { label: "MONTROMANT (69610)", value: "69610" },
  { label: "MONTROTTIER (69770)", value: "69770" },
  { label: "MORANCE (69480)", value: "69480" },
  { label: "MORANCE (69480)", value: "69480" },
  { label: "MORNANT (69440)", value: "69440" },
  { label: "MORNANT (69440)", value: "69440" },
  { label: "LA MULATIERE (69350)", value: "69350" },
  { label: "NEUVILLE SUR SAONE (69250)", value: "69250" },
  { label: "ODENAS (69460)", value: "69460" },
  { label: "ORLIENAS (69530)", value: "69530" },
  { label: "ORLIENAS (69530)", value: "69530" },
  { label: "OULLINS PIERRE BENITE (69310)", value: "69310" },
  { label: "OULLINS PIERRE BENITE (69600)", value: "69600" },
  { label: "LE PERREON (69460)", value: "69460" },
  { label: "POLEYMIEUX AU MONT D OR (69250)", value: "69250" },
  { label: "POLLIONNAY (69290)", value: "69290" },
  { label: "POMEYS (69590)", value: "69590" },
  { label: "POMMIERS (69480)", value: "69480" },
  { label: "VINDRY SUR TURDINE (69490)", value: "69490" },
  { label: "VINDRY SUR TURDINE (69490)", value: "69490" },
  { label: "VINDRY SUR TURDINE (69490)", value: "69490" },
  { label: "VINDRY SUR TURDINE (69490)", value: "69490" },
  { label: "PORTE DES PIERRES DOREES (69400)", value: "69400" },
  { label: "PORTE DES PIERRES DOREES (69400)", value: "69400" },
  { label: "PORTE DES PIERRES DOREES (69400)", value: "69400" },
  { label: "PORTE DES PIERRES DOREES (69640)", value: "69640" },
  { label: "POULE LES ECHARMEAUX (69870)", value: "69870" },
  { label: "POULE LES ECHARMEAUX (69870)", value: "69870" },
  { label: "PROPIERES (69790)", value: "69790" },
  { label: "QUINCIE EN BEAUJOLAIS (69430)", value: "69430" },
  { label: "QUINCIEUX (69650)", value: "69650" },
  { label: "QUINCIEUX (69650)", value: "69650" },
  { label: "RANCHAL (69470)", value: "69470" },
  { label: "REGNIE DURETTE (69430)", value: "69430" },
  { label: "REGNIE DURETTE (69430)", value: "69430" },
  { label: "RIVERIE (69440)", value: "69440" },
  { label: "RIVOLET (69640)", value: "69640" },
  { label: "RIVOLET (69640)", value: "69640" },
  { label: "ROCHETAILLEE SUR SAONE (69270)", value: "69270" },
  { label: "RONNO (69550)", value: "69550" },
  { label: "RONTALON (69510)", value: "69510" },
  { label: "SAIN BEL (69210)", value: "69210" },
  { label: "SALLES ARBUISSONNAS EN BEAUJOLAIS (69460)", value: "69460" },
  { label: "SALLES ARBUISSONNAS EN BEAUJOLAIS (69460)", value: "69460" },
  { label: "SARCEY (69490)", value: "69490" },
  { label: "LES SAUVAGES (69170)", value: "69170" },
  { label: "SAVIGNY (69210)", value: "69210" },
  { label: "SOUCIEU EN JARREST (69510)", value: "69510" },
  { label: "SOURCIEUX LES MINES (69210)", value: "69210" },
  { label: "SOUZY (69610)", value: "69610" },
  { label: "BEAUVALLON (69700)", value: "69700" },
  { label: "BEAUVALLON (69700)", value: "69700" },
  { label: "BEAUVALLON (69700)", value: "69700" },
  { label: "ST ANDRE LA COTE (69440)", value: "69440" },
  { label: "ST APPOLINAIRE (69170)", value: "69170" },
  { label: "ST BONNET DES BRUYERES (69790)", value: "69790" },
  { label: "ST BONNET LE TRONCY (69870)", value: "69870" },
  { label: "STE CATHERINE (69440)", value: "69440" },
  { label: "STE CATHERINE (69440)", value: "69440" },
  { label: "STE CATHERINE (69440)", value: "69440" },
  { label: "ST CLEMENT DE VERS (69790)", value: "69790" },
  { label: "ST CLEMENT LES PLACES (69930)", value: "69930" },
  { label: "ST CLEMENT SUR VALSONNE (69170)", value: "69170" },
  { label: "STE COLOMBE (69560)", value: "69560" },
  { label: "STE CONSORCE (69280)", value: "69280" },
  { label: "ST CYR AU MONT D OR (69450)", value: "69450" },
  { label: "ST CYR LE CHATOUX (69870)", value: "69870" },
  { label: "ST CYR SUR LE RHONE (69560)", value: "69560" },
  { label: "ST DIDIER AU MONT D OR (69370)", value: "69370" },
  { label: "ST DIDIER SUR BEAUJEU (69430)", value: "69430" },
  { label: "ST ETIENNE DES OULLIERES (69460)", value: "69460" },
  { label: "ST ETIENNE LA VARENNE (69460)", value: "69460" },
  { label: "ST FONS (69190)", value: "69190" },
  { label: "ST FORGEUX (69490)", value: "69490" },
  { label: "STE FOY L ARGENTIERE (69610)", value: "69610" },
  { label: "STE FOY LES LYON (69110)", value: "69110" },
  { label: "ST GENIS L ARGENTIERE (69610)", value: "69610" },
  { label: "ST GENIS LAVAL (69230)", value: "69230" },
  { label: "ST GENIS LES OLLIERES (69290)", value: "69290" },
  { label: "ST GEORGES DE RENEINS (69830)", value: "69830" },
  { label: "ST GERMAIN AU MONT D OR (69650)", value: "69650" },
  { label: "ST GERMAIN NUELLES (69210)", value: "69210" },
  { label: "ST GERMAIN NUELLES (69210)", value: "69210" },
  { label: "ST IGNY DE VERS (69790)", value: "69790" },
  { label: "ST JEAN DES VIGNES (69380)", value: "69380" },
  { label: "ST JEAN LA BUSSIERE (69550)", value: "69550" },
  { label: "ST JULIEN (69640)", value: "69640" },
  { label: "ST JULIEN (69640)", value: "69640" },
  { label: "ST JULIEN SUR BIBOST (69690)", value: "69690" },
  { label: "ST JUST D AVRAY (69870)", value: "69870" },
  { label: "ST LAGER (69220)", value: "69220" },
  { label: "ST LAURENT D AGNY (69440)", value: "69440" },
  { label: "ST LAURENT D AGNY (69440)", value: "69440" },
  { label: "ST LAURENT DE CHAMOUSSET (69930)", value: "69930" },
  { label: "ST MARCEL L ECLAIRE (69170)", value: "69170" },
  { label: "ST MARTIN EN HAUT (69850)", value: "69850" },
  { label: "ST MARTIN EN HAUT (69850)", value: "69850" },
  { label: "CHABANIERE (69440)", value: "69440" },
  { label: "CHABANIERE (69440)", value: "69440" },
  { label: "CHABANIERE (69440)", value: "69440" },
  { label: "CHABANIERE (69440)", value: "69440" },
  { label: "CHABANIERE (69440)", value: "69440" },
  { label: "ST NIZIER D AZERGUES (69870)", value: "69870" },
  { label: "STE PAULE (69620)", value: "69620" },
  { label: "ST PIERRE LA PALUD (69210)", value: "69210" },
  { label: "ST ROMAIN AU MONT D OR (69270)", value: "69270" },
  { label: "ST ROMAIN DE POPEY (69490)", value: "69490" },
  { label: "ST ROMAIN EN GAL (69560)", value: "69560" },
  { label: "ST ROMAIN EN GAL (69560)", value: "69560" },
  { label: "ST ROMAIN EN GIER (69700)", value: "69700" },
  { label: "ST SYMPHORIEN SUR COISE (69590)", value: "69590" },
  { label: "ST VERAND (69620)", value: "69620" },
  { label: "ST VINCENT DE REINS (69240)", value: "69240" },
  { label: "TALUYERS (69440)", value: "69440" },
  { label: "TALUYERS (69440)", value: "69440" },
  { label: "TAPONAS (69220)", value: "69220" },
  { label: "TARARE (69170)", value: "69170" },
  { label: "TASSIN LA DEMI LUNE (69160)", value: "69160" },
  { label: "TERNAND (69620)", value: "69620" },
  { label: "THEIZE (69620)", value: "69620" },
  { label: "THIZY LES BOURGS (69240)", value: "69240" },
  { label: "THIZY LES BOURGS (69240)", value: "69240" },
  { label: "THIZY LES BOURGS (69240)", value: "69240" },
  { label: "THIZY LES BOURGS (69240)", value: "69240" },
  { label: "THIZY LES BOURGS (69240)", value: "69240" },
  { label: "THURINS (69510)", value: "69510" },
  { label: "LA TOUR DE SALVAGNY (69890)", value: "69890" },
  { label: "TREVES (69420)", value: "69420" },
  { label: "TUPIN ET SEMONS (69420)", value: "69420" },
  { label: "VALSONNE (69170)", value: "69170" },
  { label: "VAUGNERAY (69670)", value: "69670" },
  { label: "VAUGNERAY (69670)", value: "69670" },
  { label: "VAULX EN VELIN (69120)", value: "69120" },
  { label: "VAUX EN BEAUJOLAIS (69460)", value: "69460" },
  { label: "VAUXRENARD (69820)", value: "69820" },
  { label: "VENISSIEUX (69200)", value: "69200" },
  { label: "VERNAISON (69390)", value: "69390" },
  { label: "VERNAY (69430)", value: "69430" },
  { label: "VILLECHENEVE (69770)", value: "69770" },
  { label: "VILLEFRANCHE SUR SAONE (69400)", value: "69400" },
  { label: "VILLE SUR JARNIOUX (69640)", value: "69640" },
  { label: "VILLEURBANNE (69100)", value: "69100" },
  { label: "VILLIE MORGON (69910)", value: "69910" },
  { label: "VOURLES (69390)", value: "69390" },
  { label: "VOURLES (69390)", value: "69390" },
  { label: "YZERON (69510)", value: "69510" },
  { label: "CHAPONNAY (69970)", value: "69970" },
  { label: "CHASSIEU (69680)", value: "69680" },
  { label: "COMMUNAY (69360)", value: "69360" },
  { label: "CORBAS (69960)", value: "69960" },
  { label: "DECINES CHARPIEU (69150)", value: "69150" },
  { label: "DECINES CHARPIEU (69150)", value: "69150" },
  { label: "FEYZIN (69320)", value: "69320" },
  { label: "GENAS (69740)", value: "69740" },
  { label: "GENAY (69730)", value: "69730" },
  { label: "JONAGE (69330)", value: "69330" },
  { label: "JONS (69330)", value: "69330" },
  { label: "MARENNES (69970)", value: "69970" },
  { label: "MEYZIEU (69330)", value: "69330" },
  { label: "MIONS (69780)", value: "69780" },
  { label: "MONTANAY (69250)", value: "69250" },
  { label: "PUSIGNAN (69330)", value: "69330" },
  { label: "RILLIEUX LA PAPE (69140)", value: "69140" },
  { label: "RILLIEUX LA PAPE (69140)", value: "69140" },
  { label: "ST BONNET DE MURE (69720)", value: "69720" },
  { label: "ST LAURENT DE MURE (69720)", value: "69720" },
  { label: "ST PIERRE DE CHANDIEU (69780)", value: "69780" },
  { label: "ST PRIEST (69800)", value: "69800" },
  { label: "ST SYMPHORIEN D OZON (69360)", value: "69360" },
  { label: "SATHONAY CAMP (69580)", value: "69580" },
  { label: "SATHONAY VILLAGE (69580)", value: "69580" },
  { label: "SEREZIN DU RHONE (69360)", value: "69360" },
  { label: "SIMANDRES (69360)", value: "69360" },
  { label: "SOLAIZE (69360)", value: "69360" },
  { label: "TERNAY (69360)", value: "69360" },
  { label: "TOUSSIEU (69780)", value: "69780" },
  { label: "COLOMBIER SAUGNIEU (69124)", value: "69124" },
  { label: "COLOMBIER SAUGNIEU (69124)", value: "69124" },
  { label: "COLOMBIER SAUGNIEU (69125)", value: "69125" },
  { label: "COLOMBIER SAUGNIEU (69125)", value: "69125" },
  { label: "LYON 01 (69001)", value: "69001" },
  { label: "LYON 02 (69002)", value: "69002" },
  { label: "LYON 03 (69003)", value: "69003" },
  { label: "LYON 04 (69004)", value: "69004" },
  { label: "LYON 05 (69005)", value: "69005" },
  { label: "LYON 06 (69006)", value: "69006" },
  { label: "LYON 07 (69007)", value: "69007" },
  { label: "LYON 08 (69008)", value: "69008" },
  { label: "LYON 09 (69009)", value: "69009" },
  { label: "ABELCOURT (70300)", value: "70300" },
  { label: "ABONCOURT GESINCOURT (70500)", value: "70500" },
  { label: "ACHEY (70180)", value: "70180" },
  { label: "ADELANS ET LE VAL DE BITHAINE (70200)", value: "70200" },
  { label: "AILLEVANS (70110)", value: "70110" },
  { label: "AILLEVILLERS ET LYAUMONT (70320)", value: "70320" },
  { label: "AILLONCOURT (70300)", value: "70300" },
  { label: "AINVELLE (70800)", value: "70800" },
  { label: "AISEY ET RICHECOURT (70500)", value: "70500" },
  { label: "ALAINCOURT (70210)", value: "70210" },
  { label: "AMAGE (70280)", value: "70280" },
  { label: "AMANCE (70160)", value: "70160" },
  { label: "AMBIEVILLERS (70210)", value: "70210" },
  { label: "AMBLANS ET VELOTTE (70200)", value: "70200" },
  { label: "AMONCOURT (70170)", value: "70170" },
  { label: "AMONT ET EFFRENEY (70310)", value: "70310" },
  { label: "ANCHENONCOURT ET CHAZEL (70210)", value: "70210" },
  { label: "ANCIER (70100)", value: "70100" },
  { label: "ANDELARRE (70000)", value: "70000" },
  { label: "ANDELARROT (70000)", value: "70000" },
  { label: "ANDORNAY (70200)", value: "70200" },
  { label: "ANGIREY (70700)", value: "70700" },
  { label: "ANJEUX (70800)", value: "70800" },
  { label: "APREMONT (70100)", value: "70100" },
  { label: "ARBECEY (70120)", value: "70120" },
  { label: "ARC LES GRAY (70100)", value: "70100" },
  { label: "ARGILLIERES (70600)", value: "70600" },
  { label: "AROZ (70360)", value: "70360" },
  { label: "ARPENANS (70200)", value: "70200" },
  { label: "ARSANS (70100)", value: "70100" },
  { label: "ATHESANS ETROITEFONTAINE (70110)", value: "70110" },
  { label: "ATHESANS ETROITEFONTAINE (70110)", value: "70110" },
  { label: "ATTRICOURT (70100)", value: "70100" },
  { label: "AUGICOURT (70500)", value: "70500" },
  { label: "AULX LES CROMARY (70190)", value: "70190" },
  { label: "AUTET (70180)", value: "70180" },
  { label: "AUTHOISON (70190)", value: "70190" },
  { label: "AUTOREILLE (70700)", value: "70700" },
  { label: "AUTREY LES CERRE (70110)", value: "70110" },
  { label: "AUTREY LES GRAY (70100)", value: "70100" },
  { label: "AUTREY LE VAY (70110)", value: "70110" },
  { label: "AUVET ET LA CHAPELOTTE (70100)", value: "70100" },
  { label: "AUXON (70000)", value: "70000" },
  { label: "AVRIGNEY VIREY (70150)", value: "70150" },
  { label: "AVRIGNEY VIREY (70150)", value: "70150" },
  { label: "LES AYNANS (70200)", value: "70200" },
  { label: "BAIGNES (70000)", value: "70000" },
  { label: "BARD LES PESMES (70140)", value: "70140" },
  { label: "BARGES (70500)", value: "70500" },
  { label: "LA BARRE (70190)", value: "70190" },
  { label: "LA BASSE VAIVRE (70210)", value: "70210" },
  { label: "BASSIGNEY (70800)", value: "70800" },
  { label: "LES BATIES (70130)", value: "70130" },
  { label: "BATTRANS (70100)", value: "70100" },
  { label: "BAUDONCOURT (70300)", value: "70300" },
  { label: "BAULAY (70160)", value: "70160" },
  { label: "BAY (70150)", value: "70150" },
  { label: "BEAUJEU ST VALLIER PIERREJUX QUITTEUR (70100)", value: "70100" },
  { label: "BEAUJEU ST VALLIER PIERREJUX QUITTEUR (70100)", value: "70100" },
  { label: "BEAUMOTTE AUBERTANS (70190)", value: "70190" },
  { label: "BEAUMOTTE AUBERTANS (70190)", value: "70190" },
  { label: "BEAUMOTTE LES PIN (70150)", value: "70150" },
  { label: "BELFAHY (70290)", value: "70290" },
  { label: "BELMONT (70270)", value: "70270" },
  { label: "BELONCHAMP (70270)", value: "70270" },
  { label: "BELVERNE (70400)", value: "70400" },
  { label: "BESNANS (70230)", value: "70230" },
  { label: "BETAUCOURT (70500)", value: "70500" },
  { label: "BETONCOURT LES BROTTE (70300)", value: "70300" },
  { label: "BETONCOURT ST PANCRAS (70210)", value: "70210" },
  { label: "BETONCOURT SUR MANCE (70500)", value: "70500" },
  { label: "BEULOTTE ST LAURENT (70310)", value: "70310" },
  { label: "BEVEUGE (70110)", value: "70110" },
  { label: "BLONDEFONTAINE (70500)", value: "70500" },
  { label: "BONBOILLON (70150)", value: "70150" },
  { label: "BONNEVENT VELLOREILLE (70700)", value: "70700" },
  { label: "BOREY (70110)", value: "70110" },
  { label: "BOUGEY (70500)", value: "70500" },
  { label: "BOUGNON (70170)", value: "70170" },
  { label: "BOUHANS ET FEURG (70100)", value: "70100" },
  { label: "BOUHANS LES LURE (70200)", value: "70200" },
  { label: "BOUHANS LES MONTBOZON (70230)", value: "70230" },
  { label: "BOULIGNEY (70800)", value: "70800" },
  { label: "BOULOT (70190)", value: "70190" },
  { label: "BOULT (70190)", value: "70190" },
  { label: "BOURBEVELLE (70500)", value: "70500" },
  { label: "BOURGUIGNON LES CONFLANS (70800)", value: "70800" },
  { label: "BOURGUIGNON LES LA CHARITE (70190)", value: "70190" },
  { label: "BOURGUIGNON LES MOREY (70120)", value: "70120" },
  { label: "BOURSIERES (70000)", value: "70000" },
  { label: "BOUSSERAUCOURT (70500)", value: "70500" },
  { label: "BRESILLEY (70140)", value: "70140" },
  { label: "BREUCHES (70300)", value: "70300" },
  { label: "BREUCHOTTE (70280)", value: "70280" },
  { label: "BREUREY LES FAVERNEY (70160)", value: "70160" },
  { label: "BREVILLIERS (70400)", value: "70400" },
  { label: "BRIAUCOURT (70800)", value: "70800" },
  { label: "BROTTE LES LUXEUIL (70300)", value: "70300" },
  { label: "BROTTE LES RAY (70180)", value: "70180" },
  { label: "BROYE LES LOUPS ET VERFONTAINE (70100)", value: "70100" },
  { label: "BROYE AUBIGNEY MONTSEUGNY (70140)", value: "70140" },
  { label: "BROYE AUBIGNEY MONTSEUGNY (70140)", value: "70140" },
  { label: "BROYE AUBIGNEY MONTSEUGNY (70140)", value: "70140" },
  { label: "BRUSSEY (70150)", value: "70150" },
  { label: "LA BRUYERE (70280)", value: "70280" },
  { label: "BUCEY LES GY (70700)", value: "70700" },
  { label: "BUCEY LES TRAVES (70360)", value: "70360" },
  { label: "BUFFIGNECOURT (70500)", value: "70500" },
  { label: "BUSSIERES (70190)", value: "70190" },
  { label: "BUTHIERS (70190)", value: "70190" },
  { label: "CALMOUTIER (70240)", value: "70240" },
  { label: "CEMBOING (70500)", value: "70500" },
  { label: "CENANS (70230)", value: "70230" },
  { label: "CENDRECOURT (70500)", value: "70500" },
  { label: "CERRE LES NOROY (70000)", value: "70000" },
  { label: "CHAGEY (70400)", value: "70400" },
  { label: "CHALONVILLARS (70400)", value: "70400" },
  { label: "CHAMBORNAY LES BELLEVAUX (70190)", value: "70190" },
  { label: "CHAMBORNAY LES PIN (70150)", value: "70150" },
  { label: "CHAMPAGNEY (70290)", value: "70290" },
  { label: "CHAMPEY (70400)", value: "70400" },
  { label: "CHAMPLITTE (70600)", value: "70600" },
  { label: "CHAMPLITTE (70600)", value: "70600" },
  { label: "CHAMPLITTE (70600)", value: "70600" },
  { label: "CHAMPLITTE (70600)", value: "70600" },
  { label: "CHAMPLITTE (70600)", value: "70600" },
  { label: "CHAMPLITTE (70600)", value: "70600" },
  { label: "CHAMPTONNAY (70100)", value: "70100" },
  { label: "CHAMPVANS (70100)", value: "70100" },
  { label: "CHANCEY (70140)", value: "70140" },
  { label: "CHANTES (70360)", value: "70360" },
  { label: "LA CHAPELLE LES LUXEUIL (70300)", value: "70300" },
  { label: "LA CHAPELLE ST QUILLAIN (70700)", value: "70700" },
  { label: "CHARCENNE (70700)", value: "70700" },
  { label: "CHARGEY LES GRAY (70100)", value: "70100" },
  { label: "CHARGEY LES PORT (70170)", value: "70170" },
  { label: "CHARIEZ (70000)", value: "70000" },
  { label: "CHARMES ST VALBERT (70120)", value: "70120" },
  { label: "CHARMOILLE (70000)", value: "70000" },
  { label: "CHASSEY LES MONTBOZON (70230)", value: "70230" },
  { label: "CHASSEY LES SCEY (70360)", value: "70360" },
  { label: "CHATENEY (70240)", value: "70240" },
  { label: "CHATENOIS (70240)", value: "70240" },
  { label: "CHAUMERCENNE (70140)", value: "70140" },
  { label: "CHAUVIREY LE CHATEL (70500)", value: "70500" },
  { label: "CHAUVIREY LE VIEIL (70500)", value: "70500" },
  { label: "CHAUX LA LOTIERE (70190)", value: "70190" },
  { label: "CHAUX LES PORT (70170)", value: "70170" },
  { label: "CHAVANNE (70400)", value: "70400" },
  { label: "CHEMILLY (70360)", value: "70360" },
  { label: "CHENEBIER (70400)", value: "70400" },
  { label: "CHENEVREY ET MOROGNE (70150)", value: "70150" },
  { label: "CHEVIGNEY (70140)", value: "70140" },
  { label: "CHOYE (70700)", value: "70700" },
  { label: "CINTREY (70120)", value: "70120" },
  { label: "CIREY (70190)", value: "70190" },
  { label: "CITERS (70300)", value: "70300" },
  { label: "CITEY (70700)", value: "70700" },
  { label: "CLAIREGOUTTE (70200)", value: "70200" },
  { label: "CLANS (70000)", value: "70000" },
  { label: "COGNIERES (70230)", value: "70230" },
  { label: "COISEVAUX (70400)", value: "70400" },
  { label: "COLOMBE LES VESOUL (70000)", value: "70000" },
  { label: "COLOMBIER (70000)", value: "70000" },
  { label: "COLOMBOTTE (70240)", value: "70240" },
  { label: "COMBEAUFONTAINE (70120)", value: "70120" },
  { label: "COMBERJON (70000)", value: "70000" },
  { label: "CONFLANDEY (70170)", value: "70170" },
  { label: "CONFLANS SUR LANTERNE (70800)", value: "70800" },
  { label: "CONFRACOURT (70120)", value: "70120" },
  { label: "CONTREGLISE (70160)", value: "70160" },
  { label: "CORBENAY (70320)", value: "70320" },
  { label: "LA CORBIERE (70300)", value: "70300" },
  { label: "CORDONNET (70190)", value: "70190" },
  { label: "CORNOT (70120)", value: "70120" },
  { label: "CORRAVILLERS (70310)", value: "70310" },
  { label: "CORRE (70500)", value: "70500" },
  { label: "LA COTE (70200)", value: "70200" },
  { label: "COULEVON (70000)", value: "70000" },
  { label: "COURCHATON (70110)", value: "70110" },
  { label: "COURCUIRE (70150)", value: "70150" },
  { label: "COURMONT (70400)", value: "70400" },
  { label: "COURTESOULT ET GATEY (70600)", value: "70600" },
  { label: "COUTHENANS (70400)", value: "70400" },
  { label: "CRESANCEY (70100)", value: "70100" },
  { label: "LA CREUSE (70240)", value: "70240" },
  { label: "CREVANS ET LA CHAPELLE LES GRANGES (70400)", value: "70400" },
  { label: "CREVENEY (70240)", value: "70240" },
  { label: "CROMARY (70190)", value: "70190" },
  { label: "CUBRY LES FAVERNEY (70160)", value: "70160" },
  { label: "CUGNEY (70700)", value: "70700" },
  { label: "CULT (70150)", value: "70150" },
  { label: "CUVE (70800)", value: "70800" },
  { label: "DAMBENOIT LES COLOMBE (70200)", value: "70200" },
  { label: "DAMBENOIT LES COLOMBE (70200)", value: "70200" },
  { label: "DAMPIERRE LES CONFLANS (70800)", value: "70800" },
  { label: "DAMPIERRE SUR LINOTTE (70230)", value: "70230" },
  { label: "DAMPIERRE SUR LINOTTE (70230)", value: "70230" },
  { label: "DAMPIERRE SUR LINOTTE (70230)", value: "70230" },
  { label: "DAMPIERRE SUR SALON (70180)", value: "70180" },
  { label: "DAMPVALLEY LES COLOMBE (70000)", value: "70000" },
  { label: "DAMPVALLEY ST PANCRAS (70210)", value: "70210" },
  { label: "DELAIN (70180)", value: "70180" },
  { label: "DEMANGEVELLE (70210)", value: "70210" },
  { label: "LA DEMIE (70000)", value: "70000" },
  { label: "DENEVRE (70180)", value: "70180" },
  { label: "ECHAVANNE (70400)", value: "70400" },
  { label: "ECHENANS SOUS MONT VAUDOIS (70400)", value: "70400" },
  { label: "ECHENOZ LA MELINE (70000)", value: "70000" },
  { label: "ECHENOZ LE SEC (70000)", value: "70000" },
  { label: "ECROMAGNY (70270)", value: "70270" },
  { label: "ECUELLE (70600)", value: "70600" },
  { label: "EHUNS (70300)", value: "70300" },
  { label: "EQUEVILLEY (70160)", value: "70160" },
  { label: "ERREVET (70400)", value: "70400" },
  { label: "ESBOZ BREST (70300)", value: "70300" },
  { label: "ESMOULIERES (70310)", value: "70310" },
  { label: "ESMOULINS (70100)", value: "70100" },
  { label: "ESPRELS (70110)", value: "70110" },
  { label: "ESSERTENNE ET CECEY (70100)", value: "70100" },
  { label: "ETOBON (70400)", value: "70400" },
  { label: "ETRELLES ET LA MONTBLEUSE (70700)", value: "70700" },
  { label: "ETUZ (70150)", value: "70150" },
  { label: "FAHY LES AUTREY (70100)", value: "70100" },
  { label: "FALLON (70110)", value: "70110" },
  { label: "FAUCOGNEY ET LA MER (70310)", value: "70310" },
  { label: "FAVERNEY (70160)", value: "70160" },
  { label: "FAVERNEY (70160)", value: "70160" },
  { label: "FAYMONT (70200)", value: "70200" },
  { label: "FEDRY (70120)", value: "70120" },
  { label: "FERRIERES LES RAY (70130)", value: "70130" },
  { label: "FERRIERES LES SCEY (70360)", value: "70360" },
  { label: "LES FESSEY (70310)", value: "70310" },
  { label: "FILAIN (70230)", value: "70230" },
  { label: "FLAGY (70000)", value: "70000" },
  { label: "FLEUREY LES FAVERNEY (70160)", value: "70160" },
  { label: "FLEUREY LES LAVONCOURT (70120)", value: "70120" },
  { label: "FLEUREY LES ST LOUP (70800)", value: "70800" },
  { label: "FONDREMAND (70190)", value: "70190" },
  { label: "FONTAINE LES LUXEUIL (70800)", value: "70800" },
  { label: "FONTENOIS LA VILLE (70210)", value: "70210" },
  { label: "FONTENOIS LES MONTBOZON (70230)", value: "70230" },
  { label: "FOUCHECOURT (70160)", value: "70160" },
  { label: "FOUGEROLLES ST VALBERT (70220)", value: "70220" },
  { label: "FOUGEROLLES ST VALBERT (70300)", value: "70300" },
  { label: "FOUVENT ST ANDOCHE (70600)", value: "70600" },
  { label: "FOUVENT ST ANDOCHE (70600)", value: "70600" },
  { label: "FOUVENT ST ANDOCHE (70600)", value: "70600" },
  { label: "FRAHIER ET CHATEBIER (70400)", value: "70400" },
  { label: "FRANCALMONT (70800)", value: "70800" },
  { label: "FRANCHEVELLE (70200)", value: "70200" },
  { label: "FRANCOURT (70180)", value: "70180" },
  { label: "FRAMONT (70600)", value: "70600" },
  { label: "FRAMONT (70600)", value: "70600" },
  { label: "FRASNE LE CHATEAU (70700)", value: "70700" },
  { label: "FREDERIC FONTAINE (70200)", value: "70200" },
  { label: "FRESNE ST MAMES (70130)", value: "70130" },
  { label: "FRESSE (70270)", value: "70270" },
  { label: "FRETIGNEY ET VELLOREILLE (70130)", value: "70130" },
  { label: "FROIDECONCHE (70300)", value: "70300" },
  { label: "FROIDETERRE (70200)", value: "70200" },
  { label: "FROTEY LES LURE (70200)", value: "70200" },
  { label: "FROTEY LES VESOUL (70000)", value: "70000" },
  { label: "GENEVREUILLE (70240)", value: "70240" },
  { label: "GENEVREY (70240)", value: "70240" },
  { label: "GEORFANS (70110)", value: "70110" },
  { label: "GERMIGNEY (70100)", value: "70100" },
  { label: "GEVIGNEY ET MERCEY (70500)", value: "70500" },
  { label: "GEZIER ET FONTENELAY (70700)", value: "70700" },
  { label: "GIREFONTAINE (70210)", value: "70210" },
  { label: "GOUHENANS (70110)", value: "70110" },
  { label: "GOURGEON (70120)", value: "70120" },
  { label: "GRAMMONT (70110)", value: "70110" },
  { label: "GRANDECOURT (70120)", value: "70120" },
  { label: "GRANDVELLE ET LE PERRENOT (70190)", value: "70190" },
  { label: "GRANGES LA VILLE (70400)", value: "70400" },
  { label: "GRANGES LE BOURG (70400)", value: "70400" },
  { label: "GRATTERY (70170)", value: "70170" },
  { label: "GRAY (70100)", value: "70100" },
  { label: "GRAY LA VILLE (70100)", value: "70100" },
  { label: "GY (70700)", value: "70700" },
  { label: "HAUT DU THEM CHATEAU LAMBERT (70440)", value: "70440" },
  { label: "HAUT DU THEM CHATEAU LAMBERT (70440)", value: "70440" },
  { label: "HAUTEVELLE (70800)", value: "70800" },
  { label: "HERICOURT (70400)", value: "70400" },
  { label: "HERICOURT (70400)", value: "70400" },
  { label: "HERICOURT (70400)", value: "70400" },
  { label: "HERICOURT (70400)", value: "70400" },
  { label: "HUGIER (70150)", value: "70150" },
  { label: "HURECOURT (70210)", value: "70210" },
  { label: "HYET (70190)", value: "70190" },
  { label: "IGNY (70700)", value: "70700" },
  { label: "JASNEY (70800)", value: "70800" },
  { label: "JONVELLE (70500)", value: "70500" },
  { label: "JUSSEY (70500)", value: "70500" },
  { label: "JUSSEY (70500)", value: "70500" },
  { label: "LAMBREY (70500)", value: "70500" },
  { label: "LANTENOT (70200)", value: "70200" },
  { label: "LA LANTERNE ET LES ARMONTS (70270)", value: "70270" },
  { label: "LARIANS ET MUNANS (70230)", value: "70230" },
  { label: "LARRET (70600)", value: "70600" },
  { label: "LAVIGNEY (70120)", value: "70120" },
  { label: "LAVONCOURT (70120)", value: "70120" },
  { label: "LIEFFRANS (70190)", value: "70190" },
  { label: "LIEUCOURT (70140)", value: "70140" },
  { label: "LIEVANS (70240)", value: "70240" },
  { label: "LINEXERT (70200)", value: "70200" },
  { label: "LOEUILLEY (70100)", value: "70100" },
  { label: "LOMONT (70200)", value: "70200" },
  { label: "LONGEVELLE (70110)", value: "70110" },
  { label: "LA LONGINE (70310)", value: "70310" },
  { label: "LOULANS VERCHAMP (70230)", value: "70230" },
  { label: "LOULANS VERCHAMP (70230)", value: "70230" },
  { label: "LURE (70200)", value: "70200" },
  { label: "LUXEUIL LES BAINS (70300)", value: "70300" },
  { label: "LUZE (70400)", value: "70400" },
  { label: "LYOFFANS (70200)", value: "70200" },
  { label: "MAGNIVRAY (70300)", value: "70300" },
  { label: "MAGNONCOURT (70800)", value: "70800" },
  { label: "LE MAGNORAY (70000)", value: "70000" },
  { label: "LES MAGNY (70110)", value: "70110" },
  { label: "MAGNY DANIGON (70200)", value: "70200" },
  { label: "MAGNY JOBERT (70200)", value: "70200" },
  { label: "MAGNY LES JUSSEY (70500)", value: "70500" },
  { label: "MAGNY VERNOIS (70200)", value: "70200" },
  { label: "MAILLERONCOURT CHARETTE (70240)", value: "70240" },
  { label: "MAILLERONCOURT ST PANCRAS (70210)", value: "70210" },
  { label: "MAILLEY ET CHAZELOT (70000)", value: "70000" },
  { label: "MAIZIERES (70190)", value: "70190" },
  { label: "LA MALACHERE (70190)", value: "70190" },
  { label: "MALANS (70140)", value: "70140" },
  { label: "MALBOUHANS (70200)", value: "70200" },
  { label: "MALVILLERS (70120)", value: "70120" },
  { label: "MANDREVILLARS (70400)", value: "70400" },
  { label: "MANTOCHE (70100)", value: "70100" },
  { label: "MARAST (70110)", value: "70110" },
  { label: "MARNAY (70150)", value: "70150" },
  { label: "MAUSSANS (70230)", value: "70230" },
  { label: "MELECEY (70110)", value: "70110" },
  { label: "MELIN (70120)", value: "70120" },
  { label: "MELINCOURT (70210)", value: "70210" },
  { label: "MELISEY (70270)", value: "70270" },
  { label: "MEMBREY (70180)", value: "70180" },
  { label: "MENOUX (70160)", value: "70160" },
  { label: "MERCEY SUR SAONE (70130)", value: "70130" },
  { label: "MERSUAY (70160)", value: "70160" },
  { label: "MEURCOURT (70300)", value: "70300" },
  { label: "MIGNAVILLERS (70400)", value: "70400" },
  { label: "MOFFANS ET VACHERESSE (70200)", value: "70200" },
  { label: "MOIMAY (70110)", value: "70110" },
  { label: "MOLAY (70120)", value: "70120" },
  { label: "MOLLANS (70240)", value: "70240" },
  { label: "LA MONTAGNE (70310)", value: "70310" },
  { label: "MONTAGNEY (70140)", value: "70140" },
  { label: "MONTARLOT LES RIOZ (70190)", value: "70190" },
  { label: "MONTBOILLON (70700)", value: "70700" },
  { label: "MONTBOZON (70230)", value: "70230" },
  { label: "MONTCEY (70000)", value: "70000" },
  { label: "MONTCOURT (70500)", value: "70500" },
  { label: "MONTDORE (70210)", value: "70210" },
  { label: "MONTESSAUX (70270)", value: "70270" },
  { label: "MONTIGNY LES CHERLIEU (70500)", value: "70500" },
  { label: "MONTIGNY LES VESOUL (70000)", value: "70000" },
  { label: "MONTJUSTIN ET VELOTTE (70110)", value: "70110" },
  { label: "VILLERS CHEMIN ET MONT LES ETRELLES (70700)", value: "70700" },
  { label: "MONT LE VERNOIS (70000)", value: "70000" },
  { label: "MONTOT (70180)", value: "70180" },
  { label: "MONT ST LEGER (70120)", value: "70120" },
  { label: "MONTUREUX ET PRANTIGNY (70100)", value: "70100" },
  { label: "MONTUREUX LES BAULAY (70500)", value: "70500" },
  { label: "LA ROCHE MOREY (70120)", value: "70120" },
  { label: "LA ROCHE MOREY (70120)", value: "70120" },
  { label: "LA ROCHE MOREY (70120)", value: "70120" },
  { label: "LA ROCHE MOREY (70120)", value: "70120" },
  { label: "MOTEY BESUCHE (70140)", value: "70140" },
  { label: "NANTILLY (70100)", value: "70100" },
  { label: "NAVENNE (70000)", value: "70000" },
  { label: "NEUREY EN VAUX (70160)", value: "70160" },
  { label: "NEUREY LES LA DEMIE (70000)", value: "70000" },
  { label: "NEUVELLE LES CROMARY (70190)", value: "70190" },
  { label: "NEUVELLE LES LA CHARITE (70130)", value: "70130" },
  { label: "LA NEUVELLE LES LURE (70200)", value: "70200" },
  { label: "LA NEUVELLE LES SCEY (70360)", value: "70360" },
  { label: "NOIDANS LE FERROUX (70130)", value: "70130" },
  { label: "NOIDANS LES VESOUL (70000)", value: "70000" },
  { label: "NOIRON (70100)", value: "70100" },
  { label: "NOROY LE BOURG (70000)", value: "70000" },
  { label: "OIGNEY (70120)", value: "70120" },
  { label: "OISELAY ET GRACHAUX (70700)", value: "70700" },
  { label: "ONAY (70100)", value: "70100" },
  { label: "OPPENANS (70110)", value: "70110" },
  { label: "ORICOURT (70110)", value: "70110" },
  { label: "ORMENANS (70230)", value: "70230" },
  { label: "ORMOICHE (70300)", value: "70300" },
  { label: "ORMOY (70500)", value: "70500" },
  { label: "OUGE (70500)", value: "70500" },
  { label: "OVANCHES (70360)", value: "70360" },
  { label: "OYRIERES (70600)", value: "70600" },
  { label: "PALANTE (70200)", value: "70200" },
  { label: "PASSAVANT LA ROCHERE (70210)", value: "70210" },
  { label: "PENNESIERES (70190)", value: "70190" },
  { label: "PERCEY LE GRAND (70600)", value: "70600" },
  { label: "PERROUSE (70190)", value: "70190" },
  { label: "PESMES (70140)", value: "70140" },
  { label: "PIERRECOURT (70600)", value: "70600" },
  { label: "PIN (70150)", value: "70150" },
  { label: "LA PISSEURE (70800)", value: "70800" },
  { label: "PLAINEMONT (70800)", value: "70800" },
  { label: "PLANCHER BAS (70290)", value: "70290" },
  { label: "PLANCHER LES MINES (70290)", value: "70290" },
  { label: "POLAINCOURT ET CLAIREFONTAINE (70210)", value: "70210" },
  { label: "POMOY (70240)", value: "70240" },
  { label: "PONTCEY (70360)", value: "70360" },
  { label: "LA ROMAINE (70130)", value: "70130" },
  { label: "LA ROMAINE (70130)", value: "70130" },
  { label: "LA ROMAINE (70130)", value: "70130" },
  { label: "PONT DU BOIS (70210)", value: "70210" },
  { label: "PONT SUR L OGNON (70110)", value: "70110" },
  { label: "PORT SUR SAONE (70170)", value: "70170" },
  { label: "POYANS (70100)", value: "70100" },
  { label: "PREIGNEY (70120)", value: "70120" },
  { label: "LA PROISELIERE ET LANGLE (70310)", value: "70310" },
  { label: "PROVENCHERE (70170)", value: "70170" },
  { label: "PURGEROT (70160)", value: "70160" },
  { label: "PUSEY (70000)", value: "70000" },
  { label: "PUSY ET EPENOUX (70000)", value: "70000" },
  { label: "LA QUARTE (70120)", value: "70120" },
  { label: "QUENOCHE (70190)", value: "70190" },
  { label: "QUERS (70200)", value: "70200" },
  { label: "QUINCEY (70000)", value: "70000" },
  { label: "RADDON ET CHAPENDU (70280)", value: "70280" },
  { label: "RAINCOURT (70500)", value: "70500" },
  { label: "RANZEVELLE (70500)", value: "70500" },
  { label: "RAY SUR SAONE (70130)", value: "70130" },
  { label: "RAZE (70000)", value: "70000" },
  { label: "RECOLOGNE (70130)", value: "70130" },
  { label: "RECOLOGNE LES RIOZ (70190)", value: "70190" },
  { label: "RENAUCOURT (70120)", value: "70120" },
  { label: "LA GRANDE RESIE (70140)", value: "70140" },
  { label: "LA RESIE ST MARTIN (70140)", value: "70140" },
  { label: "RIGNOVELLE (70200)", value: "70200" },
  { label: "RIGNY (70100)", value: "70100" },
  { label: "RIOZ (70190)", value: "70190" },
  { label: "RIOZ (70190)", value: "70190" },
  { label: "ROCHE ET RAUCOURT (70180)", value: "70180" },
  { label: "ROCHE LINOTTE ET SORANS CORDIERS (70230)", value: "70230" },
  { label: "LA ROCHELLE (70120)", value: "70120" },
  { label: "RONCHAMP (70250)", value: "70250" },
  { label: "ROSEY (70000)", value: "70000" },
  { label: "LA ROSIERE (70310)", value: "70310" },
  { label: "ROSIERES SUR MANCE (70500)", value: "70500" },
  { label: "ROYE (70200)", value: "70200" },
  { label: "RUHANS (70190)", value: "70190" },
  { label: "RUPT SUR SAONE (70360)", value: "70360" },
  { label: "ST BARTHELEMY (70270)", value: "70270" },
  { label: "ST BRESSON (70280)", value: "70280" },
  { label: "ST BROING (70100)", value: "70100" },
  { label: "ST FERJEUX (70110)", value: "70110" },
  { label: "ST GAND (70130)", value: "70130" },
  { label: "ST GERMAIN (70200)", value: "70200" },
  { label: "ST LOUP NANTOUARD (70100)", value: "70100" },
  { label: "ST LOUP NANTOUARD (70100)", value: "70100" },
  { label: "ST LOUP SUR SEMOUSE (70800)", value: "70800" },
  { label: "ST MARCEL (70500)", value: "70500" },
  { label: "STE MARIE EN CHANOIS (70310)", value: "70310" },
  { label: "STE MARIE EN CHAUX (70300)", value: "70300" },
  { label: "STE REINE (70700)", value: "70700" },
  { label: "ST REMY EN COMTE (70160)", value: "70160" },
  { label: "ST SAUVEUR (70300)", value: "70300" },
  { label: "ST SULPICE (70110)", value: "70110" },
  { label: "SAPONCOURT (70210)", value: "70210" },
  { label: "SAULNOT (70400)", value: "70400" },
  { label: "SAULNOT (70400)", value: "70400" },
  { label: "SAULNOT (70400)", value: "70400" },
  { label: "SAULX (70240)", value: "70240" },
  { label: "SAUVIGNEY LES GRAY (70100)", value: "70100" },
  { label: "SAUVIGNEY LES PESMES (70140)", value: "70140" },
  { label: "SAVOYEUX (70130)", value: "70130" },
  { label: "SCEY SUR SAONE ET ST ALBIN (70360)", value: "70360" },
  { label: "SCYE (70170)", value: "70170" },
  { label: "SECENANS (70400)", value: "70400" },
  { label: "SELLES (70210)", value: "70210" },
  { label: "SEMMADON (70120)", value: "70120" },
  { label: "SENARGENT MIGNAFANS (70110)", value: "70110" },
  { label: "SENARGENT MIGNAFANS (70110)", value: "70110" },
  { label: "SENONCOURT (70160)", value: "70160" },
  { label: "SERVANCE MIELLIN (70440)", value: "70440" },
  { label: "SERVANCE MIELLIN (70440)", value: "70440" },
  { label: "SERVIGNEY (70240)", value: "70240" },
  { label: "SEVEUX MOTEY (70130)", value: "70130" },
  { label: "SEVEUX MOTEY (70130)", value: "70130" },
  { label: "SOING CUBRY CHARENTENAY (70130)", value: "70130" },
  { label: "SOING CUBRY CHARENTENAY (70130)", value: "70130" },
  { label: "SOING CUBRY CHARENTENAY (70130)", value: "70130" },
  { label: "SORANS LES BREUREY (70190)", value: "70190" },
  { label: "SORNAY (70150)", value: "70150" },
  { label: "TARTECOURT (70500)", value: "70500" },
  { label: "TERNUAY MELAY ET ST HILAIRE (70270)", value: "70270" },
  { label: "THEULEY (70120)", value: "70120" },
  { label: "THIEFFRANS (70230)", value: "70230" },
  { label: "THIENANS (70230)", value: "70230" },
  { label: "TINCEY ET PONTREBEAU (70120)", value: "70120" },
  { label: "TRAITIEFONTAINE (70190)", value: "70190" },
  { label: "TRAVES (70360)", value: "70360" },
  { label: "LE TREMBLOIS (70100)", value: "70100" },
  { label: "TREMOINS (70400)", value: "70400" },
  { label: "TRESILLEY (70190)", value: "70190" },
  { label: "TROMAREY (70150)", value: "70150" },
  { label: "VADANS (70140)", value: "70140" },
  { label: "VAITE (70180)", value: "70180" },
  { label: "LA VAIVRE (70320)", value: "70320" },
  { label: "VAIVRE ET MONTOILLE (70000)", value: "70000" },
  { label: "VALAY (70140)", value: "70140" },
  { label: "LE VAL DE GOUHENANS (70200)", value: "70200" },
  { label: "VALLEROIS LE BOIS (70000)", value: "70000" },
  { label: "VALLEROIS LORIOZ (70000)", value: "70000" },
  { label: "LE VAL ST ELOI (70160)", value: "70160" },
  { label: "VANDELANS (70190)", value: "70190" },
  { label: "VANNE (70130)", value: "70130" },
  { label: "VANTOUX ET LONGEVELLE (70700)", value: "70700" },
  { label: "VAROGNE (70240)", value: "70240" },
  { label: "VARS (70600)", value: "70600" },
  { label: "VAUCHOUX (70170)", value: "70170" },
  { label: "VAUCONCOURT NERVEZAIN (70120)", value: "70120" },
  { label: "VAUVILLERS (70210)", value: "70210" },
  { label: "VAUX LE MONCELOT (70700)", value: "70700" },
  { label: "VELESMES ECHEVANNE (70100)", value: "70100" },
  { label: "VELESMES ECHEVANNE (70100)", value: "70100" },
  { label: "VELET (70100)", value: "70100" },
  { label: "VELLECHEVREUX ET COURBENANS (70110)", value: "70110" },
  { label: "VELLECLAIRE (70700)", value: "70700" },
  { label: "VELLEFAUX (70000)", value: "70000" },
  { label: "VELLEFREY ET VELLEFRANGE (70700)", value: "70700" },
  { label: "VELLEFRIE (70240)", value: "70240" },
  { label: "VELLEGUINDRY ET LEVRECEY (70000)", value: "70000" },
  { label: "VELLE LE CHATEL (70000)", value: "70000" },
  { label: "VELLEMINFROY (70240)", value: "70240" },
  { label: "VELLEMOZ (70700)", value: "70700" },
  { label: "VELLEXON QUEUTREY ET VAUDEY (70130)", value: "70130" },
  { label: "VELLOREILLE LES CHOYE (70700)", value: "70700" },
  { label: "VELORCEY (70300)", value: "70300" },
  { label: "VENERE (70100)", value: "70100" },
  { label: "LA VERGENNE (70200)", value: "70200" },
  { label: "VENISEY (70500)", value: "70500" },
  { label: "VEREUX (70180)", value: "70180" },
  { label: "VERLANS (70400)", value: "70400" },
  { label: "VERNOIS SUR MANCE (70500)", value: "70500" },
  { label: "LA VERNOTTE (70130)", value: "70130" },
  { label: "VESOUL (70000)", value: "70000" },
  { label: "VILLAFANS (70110)", value: "70110" },
  { label: "VILLARGENT (70110)", value: "70110" },
  { label: "VILLARS LE PAUTEL (70500)", value: "70500" },
  { label: "LA VILLEDIEU EN FONTENETTE (70160)", value: "70160" },
  { label: "VILLEFRANCON (70700)", value: "70700" },
  { label: "LA VILLENEUVE BELLENOYE LA MAIZE (70240)", value: "70240" },
  { label: "VILLEPAROIS (70000)", value: "70000" },
  { label: "VILLERS BOUTON (70190)", value: "70190" },
  { label: "VILLERSEXEL (70110)", value: "70110" },
  { label: "VILLERS LA VILLE (70110)", value: "70110" },
  { label: "VILLERS LE SEC (70000)", value: "70000" },
  { label: "VILLERS LES LUXEUIL (70300)", value: "70300" },
  { label: "VILLERS PATER (70190)", value: "70190" },
  { label: "VILLERS SUR PORT (70170)", value: "70170" },
  { label: "VILLERS SUR SAULNOT (70400)", value: "70400" },
  { label: "VILLERS VAUDEY (70120)", value: "70120" },
  { label: "VILORY (70240)", value: "70240" },
  { label: "VISONCOURT (70300)", value: "70300" },
  { label: "VITREY SUR MANCE (70500)", value: "70500" },
  { label: "LA VOIVRE (70310)", value: "70310" },
  { label: "VOLON (70180)", value: "70180" },
  { label: "VORAY SUR L OGNON (70190)", value: "70190" },
  { label: "VOUGECOURT (70500)", value: "70500" },
  { label: "VOUHENANS (70200)", value: "70200" },
  { label: "VREGILLE (70150)", value: "70150" },
  { label: "VYANS LE VAL (70400)", value: "70400" },
  { label: "VY LE FERROUX (70130)", value: "70130" },
  { label: "VY LES LURE (70200)", value: "70200" },
  { label: "VY LES RUPT (70120)", value: "70120" },
  { label: "VY LES FILAIN (70230)", value: "70230" },
  { label: "L'ABERGEMENT DE CUISERY (71290)", value: "71290" },
  { label: "L'ABERGEMENT STE COLOMBE (71370)", value: "71370" },
  { label: "ALLEREY SUR SAONE (71350)", value: "71350" },
  { label: "ALLERIOT (71380)", value: "71380" },
  { label: "ALUZE (71510)", value: "71510" },
  { label: "AMANZE (71800)", value: "71800" },
  { label: "AMEUGNY (71460)", value: "71460" },
  { label: "ANGLURE SOUS DUN (71170)", value: "71170" },
  { label: "ANOST (71550)", value: "71550" },
  { label: "ANTULLY (71400)", value: "71400" },
  { label: "ANZY LE DUC (71110)", value: "71110" },
  { label: "ARTAIX (71110)", value: "71110" },
  { label: "AUTHUMES (71270)", value: "71270" },
  { label: "AUTUN (71400)", value: "71400" },
  { label: "AUTUN (71400)", value: "71400" },
  { label: "AUXY (71400)", value: "71400" },
  { label: "AZE (71260)", value: "71260" },
  { label: "BALLORE (71220)", value: "71220" },
  { label: "BANTANGES (71500)", value: "71500" },
  { label: "BARIZEY (71640)", value: "71640" },
  { label: "BARNAY (71540)", value: "71540" },
  { label: "BARON (71120)", value: "71120" },
  { label: "BAUDEMONT (71800)", value: "71800" },
  { label: "BAUDRIERES (71370)", value: "71370" },
  { label: "BAUGY (71110)", value: "71110" },
  { label: "BEAUBERY (71220)", value: "71220" },
  { label: "BEAUMONT SUR GROSNE (71240)", value: "71240" },
  { label: "BEAUREPAIRE EN BRESSE (71580)", value: "71580" },
  { label: "BEAUVERNOIS (71270)", value: "71270" },
  { label: "BELLEVESVRE (71270)", value: "71270" },
  { label: "BERGESSERIN (71250)", value: "71250" },
  { label: "BERGESSERIN (71250)", value: "71250" },
  { label: "BERZE LE CHATEL (71960)", value: "71960" },
  { label: "BERZE LA VILLE (71960)", value: "71960" },
  { label: "BEY (71620)", value: "71620" },
  { label: "BISSEY SOUS CRUCHAUD (71390)", value: "71390" },
  { label: "BISSY LA MACONNAISE (71260)", value: "71260" },
  { label: "BISSY SOUS UXELLES (71460)", value: "71460" },
  { label: "BISSY SUR FLEY (71460)", value: "71460" },
  { label: "LES BIZOTS (71710)", value: "71710" },
  { label: "BLANOT (71250)", value: "71250" },
  { label: "BLANZY (71450)", value: "71450" },
  { label: "BOIS STE MARIE (71800)", value: "71800" },
  { label: "BONNAY ST YTHAIRE (71460)", value: "71460" },
  { label: "BONNAY ST YTHAIRE (71460)", value: "71460" },
  { label: "LES BORDES (71350)", value: "71350" },
  { label: "BOSJEAN (71330)", value: "71330" },
  { label: "BOUHANS (71330)", value: "71330" },
  { label: "LA BOULAYE (71320)", value: "71320" },
  { label: "BOURBON LANCY (71140)", value: "71140" },
  { label: "BOURG LE COMTE (71110)", value: "71110" },
  { label: "BOURGVILAIN (71520)", value: "71520" },
  { label: "BOUZERON (71150)", value: "71150" },
  { label: "BOYER (71700)", value: "71700" },
  { label: "BRAGNY SUR SAONE (71350)", value: "71350" },
  { label: "BRANGES (71500)", value: "71500" },
  { label: "BRAY (71250)", value: "71250" },
  { label: "BRESSE SUR GROSNE (71460)", value: "71460" },
  { label: "LE BREUIL (71670)", value: "71670" },
  { label: "BRIANT (71110)", value: "71110" },
  { label: "BRIENNE (71290)", value: "71290" },
  { label: "BRION (71190)", value: "71190" },
  { label: "BROYE (71190)", value: "71190" },
  { label: "BRUAILLES (71500)", value: "71500" },
  { label: "BUFFIERES (71250)", value: "71250" },
  { label: "BURGY (71260)", value: "71260" },
  { label: "BURNAND (71460)", value: "71460" },
  { label: "BURZY (71460)", value: "71460" },
  { label: "BUSSIERES (71960)", value: "71960" },
  { label: "BUXY (71390)", value: "71390" },
  { label: "CERON (71110)", value: "71110" },
  { label: "CERSOT (71390)", value: "71390" },
  { label: "CHAGNY (71150)", value: "71150" },
  { label: "CHAINTRE (71570)", value: "71570" },
  { label: "CHALMOUX (71140)", value: "71140" },
  { label: "CHALON SUR SAONE (71100)", value: "71100" },
  { label: "CHAMBILLY (71110)", value: "71110" },
  { label: "CHAMILLY (71510)", value: "71510" },
  { label: "CHAMPAGNAT (71480)", value: "71480" },
  { label: "CHAMPAGNY SOUS UXELLES (71460)", value: "71460" },
  { label: "CHAMPFORGEUIL (71530)", value: "71530" },
  { label: "CHAMPLECY (71120)", value: "71120" },
  { label: "CHANES (71570)", value: "71570" },
  { label: "CHANGE (21340)", value: "21340" },
  { label: "CHANGY (71120)", value: "71120" },
  { label: "CHAPAIZE (71460)", value: "71460" },
  { label: "LA CHAPELLE AU MANS (71130)", value: "71130" },
  { label: "LA CHAPELLE DE BRAGNY (71240)", value: "71240" },
  { label: "LA CHAPELLE DE GUINCHAY (71570)", value: "71570" },
  { label: "LA CHAPELLE DE GUINCHAY (71570)", value: "71570" },
  { label: "LA CHAPELLE DE GUINCHAY (71570)", value: "71570" },
  { label: "LA CHAPELLE DU MONT DE FRANCE (71520)", value: "71520" },
  { label: "LA CHAPELLE NAUDE (71500)", value: "71500" },
  { label: "LA CHAPELLE ST SAUVEUR (71310)", value: "71310" },
  { label: "LA CHAPELLE SOUS BRANCION (71700)", value: "71700" },
  { label: "LA CHAPELLE SOUS DUN (71800)", value: "71800" },
  { label: "LA CHAPELLE SOUS UCHON (71190)", value: "71190" },
  { label: "LA CHAPELLE THECLE (71470)", value: "71470" },
  { label: "CHARBONNAT (71320)", value: "71320" },
  { label: "CHARBONNIERES (71260)", value: "71260" },
  { label: "CHARDONNAY (71700)", value: "71700" },
  { label: "CHARETTE VARENNES (71270)", value: "71270" },
  { label: "CHARETTE VARENNES (71270)", value: "71270" },
  { label: "LA CHARMEE (71100)", value: "71100" },
  { label: "CHARMOY (71710)", value: "71710" },
  { label: "CHARNAY LES CHALON (71350)", value: "71350" },
  { label: "CHARNAY LES MACON (71850)", value: "71850" },
  { label: "CHAROLLES (71120)", value: "71120" },
  { label: "CHARRECEY (71510)", value: "71510" },
  { label: "CHASSELAS (71570)", value: "71570" },
  { label: "CHASSEY LE CAMP (71150)", value: "71150" },
  { label: "CHASSIGNY SOUS DUN (71170)", value: "71170" },
  { label: "CHASSY (71130)", value: "71130" },
  { label: "CHATEAU (71250)", value: "71250" },
  { label: "CHATEAUNEUF (71740)", value: "71740" },
  { label: "CHATEL MORON (71510)", value: "71510" },
  { label: "CHATENAY (71800)", value: "71800" },
  { label: "CHATENOY EN BRESSE (71380)", value: "71380" },
  { label: "CHATENOY LE ROYAL (71880)", value: "71880" },
  { label: "CHAUDENAY (71150)", value: "71150" },
  { label: "CHAUFFAILLES (71170)", value: "71170" },
  { label: "LA CHAUX (71310)", value: "71310" },
  { label: "CHEILLY LES MARANGES (71150)", value: "71150" },
  { label: "CHENAY LE CHATEL (71340)", value: "71340" },
  { label: "CHENOVES (71390)", value: "71390" },
  { label: "CHERIZET (71250)", value: "71250" },
  { label: "CHEVAGNY LES CHEVRIERES (71960)", value: "71960" },
  { label: "CHEVAGNY SUR GUYE (71220)", value: "71220" },
  { label: "CHIDDES (71220)", value: "71220" },
  { label: "CHISSEY EN MORVAN (71540)", value: "71540" },
  { label: "CHISSEY LES MACON (71460)", value: "71460" },
  { label: "CIEL (71350)", value: "71350" },
  { label: "CIRY LE NOBLE (71420)", value: "71420" },
  { label: "LA CLAYETTE (71800)", value: "71800" },
  { label: "NAVOUR SUR GROSNE (71520)", value: "71520" },
  { label: "NAVOUR SUR GROSNE (71520)", value: "71520" },
  { label: "NAVOUR SUR GROSNE (71520)", value: "71520" },
  { label: "CLESSE (71260)", value: "71260" },
  { label: "CLESSY (71130)", value: "71130" },
  { label: "CLUNY (71250)", value: "71250" },
  { label: "COLLONGE EN CHAROLLAIS (71460)", value: "71460" },
  { label: "COLLONGE LA MADELEINE (71360)", value: "71360" },
  { label: "COLOMBIER EN BRIONNAIS (71800)", value: "71800" },
  { label: "LA COMELLE (71990)", value: "71990" },
  { label: "CONDAL (71480)", value: "71480" },
  { label: "CORDESSE (71540)", value: "71540" },
  { label: "CORMATIN (71460)", value: "71460" },
  { label: "CORTAMBERT (71250)", value: "71250" },
  { label: "CORTEVAIX (71460)", value: "71460" },
  { label: "COUBLANC (71170)", value: "71170" },
  { label: "COUCHES (71490)", value: "71490" },
  { label: "CRECHES SUR SAONE (71680)", value: "71680" },
  { label: "CREOT (71490)", value: "71490" },
  { label: "CRESSY SUR SOMME (71760)", value: "71760" },
  { label: "LE CREUSOT (71200)", value: "71200" },
  { label: "CRISSEY (71530)", value: "71530" },
  { label: "CRONAT (71140)", value: "71140" },
  { label: "CRUZILLE (71260)", value: "71260" },
  { label: "CUISEAUX (71480)", value: "71480" },
  { label: "CUISERY (71290)", value: "71290" },
  { label: "CULLES LES ROCHES (71460)", value: "71460" },
  { label: "CURBIGNY (71800)", value: "71800" },
  { label: "CURDIN (71130)", value: "71130" },
  { label: "CURGY (71400)", value: "71400" },
  { label: "CURTIL SOUS BUFFIERES (71520)", value: "71520" },
  { label: "CURTIL SOUS BURNAND (71460)", value: "71460" },
  { label: "CUSSY EN MORVAN (71550)", value: "71550" },
  { label: "CUZY (71320)", value: "71320" },
  { label: "DAMEREY (71620)", value: "71620" },
  { label: "DAMPIERRE EN BRESSE (71310)", value: "71310" },
  { label: "DAVAYE (71960)", value: "71960" },
  { label: "DEMIGNY (71150)", value: "71150" },
  { label: "DENNEVY (71510)", value: "71510" },
  { label: "DETTEY (71190)", value: "71190" },
  { label: "DEVROUZE (71330)", value: "71330" },
  { label: "DEZIZE LES MARANGES (71150)", value: "71150" },
  { label: "DICONNE (71330)", value: "71330" },
  { label: "DIGOIN (71160)", value: "71160" },
  { label: "DIGOIN (71160)", value: "71160" },
  { label: "DOMMARTIN LES CUISEAUX (71480)", value: "71480" },
  { label: "DOMPIERRE LES ORMES (71520)", value: "71520" },
  { label: "DOMPIERRE LES ORMES (71520)", value: "71520" },
  { label: "DOMPIERRE SOUS SANVIGNES (71420)", value: "71420" },
  { label: "DONZY LE PERTUIS (71250)", value: "71250" },
  { label: "DRACY LE FORT (71640)", value: "71640" },
  { label: "DRACY LES COUCHES (71490)", value: "71490" },
  { label: "DRACY ST LOUP (71400)", value: "71400" },
  { label: "DYO (71800)", value: "71800" },
  { label: "ECUELLES (71350)", value: "71350" },
  { label: "ECUISSES (71210)", value: "71210" },
  { label: "EPERTULLY (71360)", value: "71360" },
  { label: "EPERVANS (71380)", value: "71380" },
  { label: "EPINAC (71360)", value: "71360" },
  { label: "ESSERTENNE (71510)", value: "71510" },
  { label: "ETANG SUR ARROUX (71190)", value: "71190" },
  { label: "ETRIGNY (71240)", value: "71240" },
  { label: "ETRIGNY (71240)", value: "71240" },
  { label: "FARGES LES CHALON (71150)", value: "71150" },
  { label: "FARGES LES MACON (71700)", value: "71700" },
  { label: "LE FAY (71580)", value: "71580" },
  { label: "FLACEY EN BRESSE (71580)", value: "71580" },
  { label: "FLAGY (71250)", value: "71250" },
  { label: "FLEURY LA MONTAGNE (71340)", value: "71340" },
  { label: "FLEY (71390)", value: "71390" },
  { label: "FONTAINES (71150)", value: "71150" },
  { label: "FONTENAY (71120)", value: "71120" },
  { label: "FRAGNES LA LOYERE (71530)", value: "71530" },
  { label: "FRAGNES LA LOYERE (71530)", value: "71530" },
  { label: "FRANGY EN BRESSE (71330)", value: "71330" },
  { label: "LA FRETTE (71440)", value: "71440" },
  { label: "FRETTERANS (71270)", value: "71270" },
  { label: "FRONTENARD (71270)", value: "71270" },
  { label: "FRONTENAUD (71580)", value: "71580" },
  { label: "FUISSE (71960)", value: "71960" },
  { label: "GENELARD (71420)", value: "71420" },
  { label: "LA GENETE (71290)", value: "71290" },
  { label: "GENOUILLY (71460)", value: "71460" },
  { label: "GERGY (71590)", value: "71590" },
  { label: "GERMAGNY (71460)", value: "71460" },
  { label: "GERMOLLES SUR GROSNE (71520)", value: "71520" },
  { label: "GIBLES (71800)", value: "71800" },
  { label: "GIGNY SUR SAONE (71240)", value: "71240" },
  { label: "GILLY SUR LOIRE (71160)", value: "71160" },
  { label: "GIVRY (71640)", value: "71640" },
  { label: "GOURDON (71300)", value: "71300" },
  { label: "LA GRANDE VERRIERE (71990)", value: "71990" },
  { label: "GRANDVAUX (71430)", value: "71430" },
  { label: "GRANGES (71390)", value: "71390" },
  { label: "GREVILLY (71700)", value: "71700" },
  { label: "GRURY (71760)", value: "71760" },
  { label: "GUERFAND (71620)", value: "71620" },
  { label: "LES GUERREAUX (71160)", value: "71160" },
  { label: "GUEUGNON (71130)", value: "71130" },
  { label: "LA GUICHE (71220)", value: "71220" },
  { label: "HAUTEFOND (71600)", value: "71600" },
  { label: "L'HOPITAL LE MERCIER (71600)", value: "71600" },
  { label: "HUILLY SUR SEILLE (71290)", value: "71290" },
  { label: "HURIGNY (71870)", value: "71870" },
  { label: "IGE (71960)", value: "71960" },
  { label: "IGORNAY (71540)", value: "71540" },
  { label: "IGUERANDE (71340)", value: "71340" },
  { label: "ISSY L EVEQUE (71760)", value: "71760" },
  { label: "JALOGNY (71250)", value: "71250" },
  { label: "JAMBLES (71640)", value: "71640" },
  { label: "JONCY (71460)", value: "71460" },
  { label: "JOUDES (71480)", value: "71480" },
  { label: "JOUVENCON (71290)", value: "71290" },
  { label: "JUGY (71240)", value: "71240" },
  { label: "JUIF (71440)", value: "71440" },
  { label: "JULLY LES BUXY (71390)", value: "71390" },
  { label: "LACROST (71700)", value: "71700" },
  { label: "LAIVES (71240)", value: "71240" },
  { label: "LAIZE (71870)", value: "71870" },
  { label: "LAIZY (71190)", value: "71190" },
  { label: "LALHEUE (71240)", value: "71240" },
  { label: "LANS (71380)", value: "71380" },
  { label: "LAYS SUR LE DOUBS (71270)", value: "71270" },
  { label: "LESME (71140)", value: "71140" },
  { label: "LESSARD EN BRESSE (71440)", value: "71440" },
  { label: "LESSARD LE NATIONAL (71530)", value: "71530" },
  { label: "LEYNES (71570)", value: "71570" },
  { label: "LIGNY EN BRIONNAIS (71110)", value: "71110" },
  { label: "LOISY (71290)", value: "71290" },
  { label: "LONGEPIERRE (71270)", value: "71270" },
  { label: "LOUHANS (71500)", value: "71500" },
  { label: "LOUHANS (71500)", value: "71500" },
  { label: "LOURNAND (71250)", value: "71250" },
  { label: "LUCENAY L EVEQUE (71540)", value: "71540" },
  { label: "LUGNY (71260)", value: "71260" },
  { label: "LUGNY LES CHAROLLES (71120)", value: "71120" },
  { label: "LUX (71100)", value: "71100" },
  { label: "MACON (71000)", value: "71000" },
  { label: "MACON (71000)", value: "71000" },
  { label: "MACON (71000)", value: "71000" },
  { label: "MACON (71000)", value: "71000" },
  { label: "MACON (71000)", value: "71000" },
  { label: "MAILLY (71340)", value: "71340" },
  { label: "MALAY (71460)", value: "71460" },
  { label: "MALTAT (71140)", value: "71140" },
  { label: "MANCEY (71240)", value: "71240" },
  { label: "MARCIGNY (71110)", value: "71110" },
  { label: "MARCILLY LA GUEURCE (71120)", value: "71120" },
  { label: "MARCILLY LES BUXY (71390)", value: "71390" },
  { label: "MARIGNY (71300)", value: "71300" },
  { label: "LE ROUSSET MARIZY (71220)", value: "71220" },
  { label: "LE ROUSSET MARIZY (71220)", value: "71220" },
  { label: "MARLY SOUS ISSY (71760)", value: "71760" },
  { label: "MARLY SUR ARROUX (71420)", value: "71420" },
  { label: "MARMAGNE (71710)", value: "71710" },
  { label: "MARNAY (71240)", value: "71240" },
  { label: "MARTAILLY LES BRANCION (71700)", value: "71700" },
  { label: "MARTIGNY LE COMTE (71220)", value: "71220" },
  { label: "MARY (71300)", value: "71300" },
  { label: "MASSILLY (71250)", value: "71250" },
  { label: "MATOUR (71520)", value: "71520" },
  { label: "MAZILLE (71250)", value: "71250" },
  { label: "MELAY (71340)", value: "71340" },
  { label: "MELLECEY (71640)", value: "71640" },
  { label: "MELLECEY (71640)", value: "71640" },
  { label: "MENETREUIL (71470)", value: "71470" },
  { label: "MERCUREY (71640)", value: "71640" },
  { label: "MERCUREY (71640)", value: "71640" },
  { label: "MERVANS (71310)", value: "71310" },
  { label: "MESSEY SUR GROSNE (71390)", value: "71390" },
  { label: "MESVRES (71190)", value: "71190" },
  { label: "MILLY LAMARTINE (71960)", value: "71960" },
  { label: "LE MIROIR (71480)", value: "71480" },
  { label: "MONT (71140)", value: "71140" },
  { label: "MONTAGNY LES BUXY (71390)", value: "71390" },
  { label: "MONTAGNY PRES LOUHANS (71500)", value: "71500" },
  { label: "MONTBELLET (71260)", value: "71260" },
  { label: "MONTCEAU LES MINES (71300)", value: "71300" },
  { label: "MONTCEAUX L ETOILE (71110)", value: "71110" },
  { label: "MONTCEAUX RAGNY (71240)", value: "71240" },
  { label: "MONTCENIS (71710)", value: "71710" },
  { label: "MONTCHANIN (71210)", value: "71210" },
  { label: "MONTCONY (71500)", value: "71500" },
  { label: "MONTCOY (71620)", value: "71620" },
  { label: "MONTHELON (71400)", value: "71400" },
  { label: "MONTJAY (71310)", value: "71310" },
  { label: "MONT LES SEURRE (71270)", value: "71270" },
  { label: "MONTMELARD (71520)", value: "71520" },
  { label: "MONTMORT (71320)", value: "71320" },
  { label: "MONTPONT EN BRESSE (71470)", value: "71470" },
  { label: "MONTRET (71440)", value: "71440" },
  { label: "MONT ST VINCENT (71300)", value: "71300" },
  { label: "MOREY (71510)", value: "71510" },
  { label: "MORLET (71360)", value: "71360" },
  { label: "MORNAY (71220)", value: "71220" },
  { label: "MOROGES (71390)", value: "71390" },
  { label: "LA MOTTE ST JEAN (71160)", value: "71160" },
  { label: "MOUTHIER EN BRESSE (71270)", value: "71270" },
  { label: "MUSSY SOUS DUN (71170)", value: "71170" },
  { label: "NANTON (71240)", value: "71240" },
  { label: "NAVILLY (71270)", value: "71270" },
  { label: "NEUVY GRANDCHAMP (71130)", value: "71130" },
  { label: "NOCHIZE (71600)", value: "71600" },
  { label: "ORMES (71290)", value: "71290" },
  { label: "OSLON (71380)", value: "71380" },
  { label: "OUDRY (71420)", value: "71420" },
  { label: "OUROUX SOUS LE BOIS STE MARIE (71800)", value: "71800" },
  { label: "OUROUX SUR SAONE (71370)", value: "71370" },
  { label: "OYE (71800)", value: "71800" },
  { label: "OZENAY (71700)", value: "71700" },
  { label: "OZOLLES (71120)", value: "71120" },
  { label: "PALINGES (71430)", value: "71430" },
  { label: "PALLEAU (71350)", value: "71350" },
  { label: "PARAY LE MONIAL (71600)", value: "71600" },
  { label: "PARIS L HOPITAL (71150)", value: "71150" },
  { label: "PASSY (71220)", value: "71220" },
  { label: "PERONNE (71260)", value: "71260" },
  { label: "PERRECY LES FORGES (71420)", value: "71420" },
  { label: "PERREUIL (71510)", value: "71510" },
  { label: "PERRIGNY SUR LOIRE (71160)", value: "71160" },
  { label: "LA PETITE VERRIERE (71400)", value: "71400" },
  { label: "PIERRECLOS (71960)", value: "71960" },
  { label: "PIERRE DE BRESSE (71270)", value: "71270" },
  { label: "PIERRE DE BRESSE (71270)", value: "71270" },
  { label: "LE PLANOIS (71330)", value: "71330" },
  { label: "PLOTTES (71700)", value: "71700" },
  { label: "POISSON (71600)", value: "71600" },
  { label: "PONTOUX (71270)", value: "71270" },
  { label: "POUILLOUX (71230)", value: "71230" },
  { label: "POURLANS (71270)", value: "71270" },
  { label: "PRESSY SOUS DONDIN (71220)", value: "71220" },
  { label: "PRETY (71290)", value: "71290" },
  { label: "PRISSE (71960)", value: "71960" },
  { label: "PRIZY (71800)", value: "71800" },
  { label: "PRUZILLY (71570)", value: "71570" },
  { label: "LE PULEY (71460)", value: "71460" },
  { label: "LA RACINEUSE (71310)", value: "71310" },
  { label: "RANCY (71290)", value: "71290" },
  { label: "RATENELLE (71290)", value: "71290" },
  { label: "RATTE (71500)", value: "71500" },
  { label: "RECLESNE (71540)", value: "71540" },
  { label: "REMIGNY (71150)", value: "71150" },
  { label: "RIGNY SUR ARROUX (71160)", value: "71160" },
  { label: "LA ROCHE VINEUSE (71960)", value: "71960" },
  { label: "ROMANECHE THORINS (71570)", value: "71570" },
  { label: "ROMENAY (71470)", value: "71470" },
  { label: "ROSEY (71390)", value: "71390" },
  { label: "ROUSSILLON EN MORVAN (71550)", value: "71550" },
  { label: "ROYER (71700)", value: "71700" },
  { label: "RULLY (71150)", value: "71150" },
  { label: "SAGY (71580)", value: "71580" },
  { label: "SAILLENARD (71580)", value: "71580" },
  { label: "SAILLY (71250)", value: "71250" },
  { label: "ST AGNAN (71160)", value: "71160" },
  { label: "ST ALBAIN (71260)", value: "71260" },
  { label: "ST AMBREUIL (71240)", value: "71240" },
  { label: "ST AMOUR BELLEVUE (71570)", value: "71570" },
  { label: "ST ANDRE EN BRESSE (71440)", value: "71440" },
  { label: "ST ANDRE LE DESERT (71220)", value: "71220" },
  { label: "ST AUBIN EN CHAROLLAIS (71430)", value: "71430" },
  { label: "ST AUBIN SUR LOIRE (71140)", value: "71140" },
  { label: "ST BERAIN SOUS SANVIGNES (71300)", value: "71300" },
  { label: "ST BERAIN SUR DHEUNE (71510)", value: "71510" },
  { label: "ST BOIL (71390)", value: "71390" },
  { label: "ST BONNET DE CRAY (71340)", value: "71340" },
  { label: "ST BONNET DE JOUX (71220)", value: "71220" },
  { label: "ST BONNET DE VIEILLE VIGNE (71430)", value: "71430" },
  { label: "ST BONNET EN BRESSE (71310)", value: "71310" },
  { label: "STE CECILE (71250)", value: "71250" },
  { label: "ST CHRISTOPHE EN BRESSE (71370)", value: "71370" },
  { label: "ST CHRISTOPHE EN BRIONNAIS (71800)", value: "71800" },
  { label: "ST CLEMENT SUR GUYE (71460)", value: "71460" },
  { label: "STE CROIX EN BRESSE (71470)", value: "71470" },
  { label: "ST CYR (71240)", value: "71240" },
  { label: "ST DENIS DE VAUX (71640)", value: "71640" },
  { label: "ST DESERT (71390)", value: "71390" },
  { label: "ST DIDIER EN BRESSE (71620)", value: "71620" },
  { label: "ST DIDIER EN BRIONNAIS (71110)", value: "71110" },
  { label: "ST DIDIER SUR ARROUX (71190)", value: "71190" },
  { label: "ST EDMOND (71740)", value: "71740" },
  { label: "ST EMILAND (71490)", value: "71490" },
  { label: "ST ETIENNE EN BRESSE (71370)", value: "71370" },
  { label: "ST EUGENE (71320)", value: "71320" },
  { label: "ST EUSEBE (71210)", value: "71210" },
  { label: "ST FIRMIN (71670)", value: "71670" },
  { label: "ST FORGEOT (71400)", value: "71400" },
  { label: "STE FOY (71110)", value: "71110" },
  { label: "ST GENGOUX DE SCISSE (71260)", value: "71260" },
  { label: "ST GENGOUX LE NATIONAL (71460)", value: "71460" },
  { label: "ST GERMAIN DU BOIS (71330)", value: "71330" },
  { label: "ST GERMAIN DU PLAIN (71370)", value: "71370" },
  { label: "ST GERMAIN EN BRIONNAIS (71800)", value: "71800" },
  { label: "ST GERMAIN LES BUXY (71390)", value: "71390" },
  { label: "ST GERVAIS EN VALLIERE (71350)", value: "71350" },
  { label: "ST GERVAIS SUR COUCHES (71490)", value: "71490" },
  { label: "ST GILLES (71510)", value: "71510" },
  { label: "STE HELENE (71390)", value: "71390" },
  { label: "ST HURUGE (71460)", value: "71460" },
  { label: "ST IGNY DE ROCHE (71170)", value: "71170" },
  { label: "ST JEAN DE VAUX (71640)", value: "71640" },
  { label: "ST JEAN DE TREZY (71490)", value: "71490" },
  { label: "ST JULIEN DE CIVRY (71800)", value: "71800" },
  { label: "ST JULIEN DE JONZY (71110)", value: "71110" },
  { label: "ST JULIEN SUR DHEUNE (71210)", value: "71210" },
  { label: "ST LAURENT D ANDENAY (71210)", value: "71210" },
  { label: "ST LAURENT EN BRIONNAIS (71800)", value: "71800" },
  { label: "ST LEGER DU BOIS (71360)", value: "71360" },
  { label: "ST LEGER LES PARAY (71600)", value: "71600" },
  { label: "ST LEGER SOUS BEUVRAY (71990)", value: "71990" },
  { label: "ST LEGER SOUS LA BUSSIERE (71520)", value: "71520" },
  { label: "ST LEGER SUR DHEUNE (71510)", value: "71510" },
  { label: "ST LOUP GEANGES (71350)", value: "71350" },
  { label: "ST LOUP GEANGES (71350)", value: "71350" },
  { label: "ST LOUP DE VARENNES (71240)", value: "71240" },
  { label: "ST MARCEL (71380)", value: "71380" },
  { label: "ST MARCELIN DE CRAY (71460)", value: "71460" },
  { label: "ST MARD DE VAUX (71640)", value: "71640" },
  { label: "ST MARTIN BELLE ROCHE (71118)", value: "71118" },
  { label: "ST MARTIN D AUXY (71390)", value: "71390" },
  { label: "ST MARTIN DE COMMUNE (71490)", value: "71490" },
  { label: "ST MARTIN DE LIXY (71740)", value: "71740" },
  { label: "ST MARTIN DE SALENCEY (71220)", value: "71220" },
  { label: "ST MARTIN DU LAC (71110)", value: "71110" },
  { label: "ST MARTIN DU MONT (71580)", value: "71580" },
  { label: "ST MARTIN DU TARTRE (71460)", value: "71460" },
  { label: "ST MARTIN EN BRESSE (71620)", value: "71620" },
  { label: "ST MARTIN EN GATINOIS (71350)", value: "71350" },
  { label: "ST MARTIN LA PATROUILLE (71460)", value: "71460" },
  { label: "ST MARTIN SOUS MONTAIGU (71640)", value: "71640" },
  { label: "ST MAURICE DE SATONNAY (71260)", value: "71260" },
  { label: "ST MAURICE DES CHAMPS (71460)", value: "71460" },
  { label: "ST MAURICE EN RIVIERE (71620)", value: "71620" },
  { label: "ST MAURICE LES CHATEAUNEUF (71740)", value: "71740" },
  { label: "ST MAURICE LES COUCHES (71490)", value: "71490" },
  { label: "ST MICAUD (71460)", value: "71460" },
  { label: "ST NIZIER SUR ARROUX (71190)", value: "71190" },
  { label: "ST PIERRE DE VARENNES (71670)", value: "71670" },
  { label: "ST PIERRE LE VIEUX (71520)", value: "71520" },
  { label: "ST POINT (71520)", value: "71520" },
  { label: "ST PRIVE (71390)", value: "71390" },
  { label: "ST PRIX (71990)", value: "71990" },
  { label: "ST RACHO (71800)", value: "71800" },
  { label: "STE RADEGONDE (71320)", value: "71320" },
  { label: "ST REMY (71100)", value: "71100" },
  { label: "ST ROMAIN SOUS GOURDON (71230)", value: "71230" },
  { label: "ST ROMAIN SOUS VERSIGNY (71420)", value: "71420" },
  { label: "ST SERNIN DU BOIS (71200)", value: "71200" },
  { label: "ST SERNIN DU PLAIN (71510)", value: "71510" },
  { label: "ST SYMPHORIEN D ANCELLES (71570)", value: "71570" },
  { label: "ST SYMPHORIEN D ANCELLES (71570)", value: "71570" },
  { label: "ST SYMPHORIEN DE MARMAGNE (71710)", value: "71710" },
  { label: "ST SYMPHORIEN DES BOIS (71800)", value: "71800" },
  { label: "ST USUGE (71500)", value: "71500" },
  { label: "ST VALLERIN (71390)", value: "71390" },
  { label: "ST VALLIER (71230)", value: "71230" },
  { label: "ST VALLIER (71230)", value: "71230" },
  { label: "ST VERAND (71570)", value: "71570" },
  { label: "ST VINCENT DES PRES (71250)", value: "71250" },
  { label: "ST VINCENT EN BRESSE (71440)", value: "71440" },
  { label: "ST VINCENT BRAGNY (71430)", value: "71430" },
  { label: "ST VINCENT BRAGNY (71430)", value: "71430" },
  { label: "ST YAN (71600)", value: "71600" },
  { label: "SAISY (71360)", value: "71360" },
  { label: "LA SALLE (71260)", value: "71260" },
  { label: "SALORNAY SUR GUYE (71250)", value: "71250" },
  { label: "SAMPIGNY LES MARANGES (71150)", value: "71150" },
  { label: "SANCE (71000)", value: "71000" },
  { label: "SANTILLY (71460)", value: "71460" },
  { label: "SANVIGNES LES MINES (71410)", value: "71410" },
  { label: "SARRY (71110)", value: "71110" },
  { label: "SASSANGY (71390)", value: "71390" },
  { label: "SASSENAY (71530)", value: "71530" },
  { label: "SAULES (71390)", value: "71390" },
  { label: "SAUNIERES (71350)", value: "71350" },
  { label: "SAVIANGES (71460)", value: "71460" },
  { label: "SAVIGNY EN REVERMONT (71580)", value: "71580" },
  { label: "SAVIGNY SUR GROSNE (71460)", value: "71460" },
  { label: "SAVIGNY SUR SEILLE (71440)", value: "71440" },
  { label: "LA CELLE EN MORVAN (71400)", value: "71400" },
  { label: "SEMUR EN BRIONNAIS (71110)", value: "71110" },
  { label: "SENNECEY LE GRAND (71240)", value: "71240" },
  { label: "SENOZAN (71260)", value: "71260" },
  { label: "SENS SUR SEILLE (71330)", value: "71330" },
  { label: "SERCY (71460)", value: "71460" },
  { label: "SERLEY (71310)", value: "71310" },
  { label: "SERMESSE (71350)", value: "71350" },
  { label: "SERRIERES (71960)", value: "71960" },
  { label: "SERRIGNY EN BRESSE (71310)", value: "71310" },
  { label: "SEVREY (71100)", value: "71100" },
  { label: "SIGY LE CHATEL (71250)", value: "71250" },
  { label: "SIMANDRE (71290)", value: "71290" },
  { label: "SIMARD (71330)", value: "71330" },
  { label: "SIVIGNON (71220)", value: "71220" },
  { label: "SOLOGNY (71960)", value: "71960" },
  { label: "SOLUTRE POUILLY (71960)", value: "71960" },
  { label: "SOMMANT (71540)", value: "71540" },
  { label: "SORNAY (71500)", value: "71500" },
  { label: "SUIN (71220)", value: "71220" },
  { label: "SULLY (71360)", value: "71360" },
  { label: "LA TAGNIERE (71190)", value: "71190" },
  { label: "TAIZE (71250)", value: "71250" },
  { label: "TAIZE (71250)", value: "71250" },
  { label: "TANCON (71740)", value: "71740" },
  { label: "LE TARTRE (71330)", value: "71330" },
  { label: "TAVERNAY (71400)", value: "71400" },
  { label: "THIL SUR ARROUX (71190)", value: "71190" },
  { label: "THUREY (71440)", value: "71440" },
  { label: "TINTRY (71490)", value: "71490" },
  { label: "TORCY (71210)", value: "71210" },
  { label: "TORPES (71270)", value: "71270" },
  { label: "TOULON SUR ARROUX (71320)", value: "71320" },
  { label: "TOURNUS (71700)", value: "71700" },
  { label: "TOUTENANT (71350)", value: "71350" },
  { label: "TRAMAYES (71520)", value: "71520" },
  { label: "TRAMBLY (71520)", value: "71520" },
  { label: "TRIVY (71520)", value: "71520" },
  { label: "TRONCHY (71440)", value: "71440" },
  { label: "LA TRUCHERE (71290)", value: "71290" },
  { label: "UCHIZY (71700)", value: "71700" },
  { label: "UCHON (71190)", value: "71190" },
  { label: "UXEAU (71130)", value: "71130" },
  { label: "VAREILLES (71800)", value: "71800" },
  { label: "VARENNE L ARCONCE (71110)", value: "71110" },
  { label: "VARENNES LE GRAND (71240)", value: "71240" },
  { label: "VARENNES LES MACON (71000)", value: "71000" },
  { label: "VARENNE ST GERMAIN (71600)", value: "71600" },
  { label: "VARENNE ST GERMAIN (71600)", value: "71600" },
  { label: "VARENNES ST SAUVEUR (71480)", value: "71480" },
  { label: "VARENNES SOUS DUN (71800)", value: "71800" },
  { label: "VAUBAN (71800)", value: "71800" },
  { label: "VAUDEBARRIER (71120)", value: "71120" },
  { label: "VAUX EN PRE (71460)", value: "71460" },
  { label: "VENDENESSE LES CHAROLLES (71120)", value: "71120" },
  { label: "VENDENESSE SUR ARROUX (71130)", value: "71130" },
  { label: "VERDUN SUR LE DOUBS (71350)", value: "71350" },
  { label: "VERGISSON (71960)", value: "71960" },
  { label: "VERISSEY (71440)", value: "71440" },
  { label: "VERJUX (71590)", value: "71590" },
  { label: "VEROSVRES (71220)", value: "71220" },
  { label: "VERS (71240)", value: "71240" },
  { label: "VERSAUGUES (71110)", value: "71110" },
  { label: "VERZE (71960)", value: "71960" },
  { label: "LE VILLARS (71700)", value: "71700" },
  { label: "VILLEGAUDIN (71620)", value: "71620" },
  { label: "CLUX VILLENEUVE (71270)", value: "71270" },
  { label: "CLUX VILLENEUVE (71270)", value: "71270" },
  { label: "VILLENEUVE EN MONTAGNE (71390)", value: "71390" },
  { label: "VINCELLES (71500)", value: "71500" },
  { label: "VINDECY (71110)", value: "71110" },
  { label: "LA VINEUSE SUR FREGANDE (71250)", value: "71250" },
  { label: "LA VINEUSE SUR FREGANDE (71250)", value: "71250" },
  { label: "LA VINEUSE SUR FREGANDE (71250)", value: "71250" },
  { label: "LA VINEUSE SUR FREGANDE (71250)", value: "71250" },
  { label: "VINZELLES (71680)", value: "71680" },
  { label: "VIRE (71260)", value: "71260" },
  { label: "VIRE (71260)", value: "71260" },
  { label: "VIREY LE GRAND (71530)", value: "71530" },
  { label: "VIRY (71120)", value: "71120" },
  { label: "VITRY EN CHAROLLAIS (71600)", value: "71600" },
  { label: "VITRY SUR LOIRE (71140)", value: "71140" },
  { label: "VOLESVRES (71600)", value: "71600" },
  { label: "FLEURVILLE (71260)", value: "71260" },
  { label: "AIGNE (72650)", value: "72650" },
  { label: "AILLIERES BEAUVOIR (72600)", value: "72600" },
  { label: "ALLONNES (72700)", value: "72700" },
  { label: "AMNE (72540)", value: "72540" },
  { label: "ANCINNES (72610)", value: "72610" },
  { label: "ARCONNAY (72610)", value: "72610" },
  { label: "ARDENAY SUR MERIZE (72370)", value: "72370" },
  { label: "ARNAGE (72230)", value: "72230" },
  { label: "ARTHEZE (72270)", value: "72270" },
  { label: "ASNIERES SUR VEGRE (72430)", value: "72430" },
  { label: "ASSE LE BOISNE (72130)", value: "72130" },
  { label: "ASSE LE RIBOUL (72170)", value: "72170" },
  { label: "AUBIGNE RACAN (72800)", value: "72800" },
  { label: "LES AULNEAUX (72600)", value: "72600" },
  { label: "AUVERS LE HAMON (72300)", value: "72300" },
  { label: "AUVERS SOUS MONTFAUCON (72540)", value: "72540" },
  { label: "AVESNES EN SAOSNOIS (72260)", value: "72260" },
  { label: "AVESSE (72350)", value: "72350" },
  { label: "AVEZE (72400)", value: "72400" },
  { label: "AVOISE (72430)", value: "72430" },
  { label: "LE BAILLEUL (72200)", value: "72200" },
  { label: "BALLON ST MARS (72290)", value: "72290" },
  { label: "BALLON ST MARS (72290)", value: "72290" },
  { label: "LA BAZOGE (72650)", value: "72650" },
  { label: "BAZOUGES CRE SUR LOIR (72200)", value: "72200" },
  { label: "BAZOUGES CRE SUR LOIR (72200)", value: "72200" },
  { label: "BEAUFAY (72110)", value: "72110" },
  { label: "BEAUMONT SUR DEME (72340)", value: "72340" },
  { label: "BEAUMONT PIED DE BOEUF (72500)", value: "72500" },
  { label: "BEAUMONT SUR SARTHE (72170)", value: "72170" },
  { label: "BEILLE (72160)", value: "72160" },
  { label: "BERFAY (72320)", value: "72320" },
  { label: "BERUS (72610)", value: "72610" },
  { label: "BESSE SUR BRAYE (72310)", value: "72310" },
  { label: "BETHON (72610)", value: "72610" },
  { label: "BLEVES (72600)", value: "72600" },
  { label: "BOESSE LE SEC (72400)", value: "72400" },
  { label: "BONNETABLE (72110)", value: "72110" },
  { label: "LA BOSSE (72400)", value: "72400" },
  { label: "BOUER (72390)", value: "72390" },
  { label: "BOULOIRE (72440)", value: "72440" },
  { label: "BOURG LE ROI (72610)", value: "72610" },
  { label: "BOUSSE (72270)", value: "72270" },
  { label: "BRAINS SUR GEE (72550)", value: "72550" },
  { label: "LE BREIL SUR MERIZE (72370)", value: "72370" },
  { label: "BRETTE LES PINS (72250)", value: "72250" },
  { label: "BRIOSNE LES SABLES (72110)", value: "72110" },
  { label: "LA BRUERE SUR LOIR (72500)", value: "72500" },
  { label: "BRULON (72350)", value: "72350" },
  { label: "CERANS FOULLETOURTE (72330)", value: "72330" },
  { label: "CHAHAIGNES (72340)", value: "72340" },
  { label: "CHALLES (72250)", value: "72250" },
  { label: "CHAMPAGNE (72470)", value: "72470" },
  { label: "CHAMPFLEUR (72610)", value: "72610" },
  { label: "CHAMPROND (72320)", value: "72320" },
  { label: "CHANGE (72560)", value: "72560" },
  { label: "CHANTENAY VILLEDIEU (72430)", value: "72430" },
  { label: "LA CHAPELLE AUX CHOUX (72800)", value: "72800" },
  { label: "LA CHAPELLE D ALIGNE (72300)", value: "72300" },
  { label: "LA CHAPELLE DU BOIS (72400)", value: "72400" },
  { label: "LA CHAPELLE HUON (72310)", value: "72310" },
  { label: "LA CHAPELLE ST AUBIN (72650)", value: "72650" },
  { label: "LA CHAPELLE ST FRAY (72240)", value: "72240" },
  { label: "LA CHAPELLE ST REMY (72160)", value: "72160" },
  { label: "LA CHARTRE SUR LE LOIR (72340)", value: "72340" },
  { label: "CHASSILLE (72540)", value: "72540" },
  { label: "MONTVAL SUR LOIR (72500)", value: "72500" },
  { label: "MONTVAL SUR LOIR (72500)", value: "72500" },
  { label: "MONTVAL SUR LOIR (72500)", value: "72500" },
  { label: "MONTVAL SUR LOIR (72500)", value: "72500" },
  { label: "CHATEAU L HERMITAGE (72510)", value: "72510" },
  { label: "CHAUFOUR NOTRE DAME (72550)", value: "72550" },
  { label: "CHEMIRE EN CHARNIE (72540)", value: "72540" },
  { label: "CHEMIRE LE GAUDIN (72210)", value: "72210" },
  { label: "CHENAY (72610)", value: "72610" },
  { label: "CHENU (72500)", value: "72500" },
  { label: "CHERANCE (72170)", value: "72170" },
  { label: "CHERISAY (72610)", value: "72610" },
  { label: "CHERRE AU (72400)", value: "72400" },
  { label: "CHERRE AU (72400)", value: "72400" },
  { label: "CHEVILLE (72350)", value: "72350" },
  { label: "CLERMONT CREANS (72200)", value: "72200" },
  { label: "COGNERS (72310)", value: "72310" },
  { label: "COMMERVEIL (72600)", value: "72600" },
  { label: "CONFLANS SUR ANILLE (72120)", value: "72120" },
  { label: "CONGE SUR ORNE (72290)", value: "72290" },
  { label: "CONLIE (72240)", value: "72240" },
  { label: "CONNERRE (72160)", value: "72160" },
  { label: "CONTILLY (72600)", value: "72600" },
  { label: "CORMES (72400)", value: "72400" },
  { label: "COUDRECIEUX (72440)", value: "72440" },
  { label: "COULAINES (72190)", value: "72190" },
  { label: "COULANS SUR GEE (72550)", value: "72550" },
  { label: "COULONGE (72800)", value: "72800" },
  { label: "COURCEBOEUFS (72290)", value: "72290" },
  { label: "COURCELLES LA FORET (72270)", value: "72270" },
  { label: "COURCEMONT (72110)", value: "72110" },
  { label: "COURCIVAL (72110)", value: "72110" },
  { label: "COURDEMANCHE (72150)", value: "72150" },
  { label: "COURDEMANCHE (72150)", value: "72150" },
  { label: "COURGAINS (72260)", value: "72260" },
  { label: "COURGENARD (72320)", value: "72320" },
  { label: "COURTILLERS (72300)", value: "72300" },
  { label: "CRANNES EN CHAMPAGNE (72540)", value: "72540" },
  { label: "CRISSE (72140)", value: "72140" },
  { label: "CROSMIERES (72200)", value: "72200" },
  { label: "CURES (72240)", value: "72240" },
  { label: "DANGEUL (72260)", value: "72260" },
  { label: "DEGRE (72550)", value: "72550" },
  { label: "DEHAULT (72400)", value: "72400" },
  { label: "DISSAY SOUS COURCILLON (72500)", value: "72500" },
  { label: "DOLLON (72390)", value: "72390" },
  { label: "DOMFRONT EN CHAMPAGNE (72240)", value: "72240" },
  { label: "DOUCELLES (72170)", value: "72170" },
  { label: "DOUILLET (72130)", value: "72130" },
  { label: "DUNEAU (72160)", value: "72160" },
  { label: "DUREIL (72270)", value: "72270" },
  { label: "ECOMMOY (72220)", value: "72220" },
  { label: "ECORPAIN (72120)", value: "72120" },
  { label: "EPINEU LE CHEVREUIL (72540)", value: "72540" },
  { label: "ETIVAL LES LE MANS (72700)", value: "72700" },
  { label: "VAL D ETANGSON (72120)", value: "72120" },
  { label: "VAL D ETANGSON (72120)", value: "72120" },
  { label: "FATINES (72470)", value: "72470" },
  { label: "FAY (72550)", value: "72550" },
  { label: "FERCE SUR SARTHE (72430)", value: "72430" },
  { label: "LA FERTE BERNARD (72400)", value: "72400" },
  { label: "FILLE (72210)", value: "72210" },
  { label: "FLEE (72500)", value: "72500" },
  { label: "LA FONTAINE ST MARTIN (72330)", value: "72330" },
  { label: "FONTENAY SUR VEGRE (72350)", value: "72350" },
  { label: "VILLENEUVE EN PERSEIGNE (72600)", value: "72600" },
  { label: "VILLENEUVE EN PERSEIGNE (72600)", value: "72600" },
  { label: "VILLENEUVE EN PERSEIGNE (72600)", value: "72600" },
  { label: "VILLENEUVE EN PERSEIGNE (72600)", value: "72600" },
  { label: "VILLENEUVE EN PERSEIGNE (72610)", value: "72610" },
  { label: "VILLENEUVE EN PERSEIGNE (72610)", value: "72610" },
  { label: "VILLENEUVE EN PERSEIGNE (72610)", value: "72610" },
  { label: "FRESNAY SUR SARTHE (72130)", value: "72130" },
  { label: "FRESNAY SUR SARTHE (72130)", value: "72130" },
  { label: "FRESNAY SUR SARTHE (72130)", value: "72130" },
  { label: "FRESNAY SUR SARTHE (72130)", value: "72130" },
  { label: "FYE (72610)", value: "72610" },
  { label: "GESNES LE GANDELIN (72130)", value: "72130" },
  { label: "GRANDCHAMP (72610)", value: "72610" },
  { label: "LE GRAND LUCE (72150)", value: "72150" },
  { label: "GREEZ SUR ROC (72320)", value: "72320" },
  { label: "LE GREZ (72140)", value: "72140" },
  { label: "GUECELARD (72230)", value: "72230" },
  { label: "LA GUIERCHE (72380)", value: "72380" },
  { label: "JAUZE (72110)", value: "72110" },
  { label: "JOUE EN CHARNIE (72540)", value: "72540" },
  { label: "JOUE L ABBE (72380)", value: "72380" },
  { label: "JUIGNE SUR SARTHE (72300)", value: "72300" },
  { label: "JUILLE (72170)", value: "72170" },
  { label: "JUPILLES (72500)", value: "72500" },
  { label: "LA FLECHE (72200)", value: "72200" },
  { label: "LAIGNE EN BELIN (72220)", value: "72220" },
  { label: "LAMNAY (72320)", value: "72320" },
  { label: "LAVARDIN (72240)", value: "72240" },
  { label: "LAVARE (72390)", value: "72390" },
  { label: "LAVERNAT (72500)", value: "72500" },
  { label: "LHOMME (72340)", value: "72340" },
  { label: "LIGRON (72270)", value: "72270" },
  { label: "LIVET EN SAOSNOIS (72610)", value: "72610" },
  { label: "LOMBRON (72450)", value: "72450" },
  { label: "LONGNES (72540)", value: "72540" },
  { label: "LOUAILLES (72300)", value: "72300" },
  { label: "LOUE (72540)", value: "72540" },
  { label: "LOUPLANDE (72210)", value: "72210" },
  { label: "LOUVIGNY (72600)", value: "72600" },
  { label: "LOUZES (72600)", value: "72600" },
  { label: "LE LUART (72390)", value: "72390" },
  { label: "LUCEAU (72500)", value: "72500" },
  { label: "LUCE SOUS BALLON (72290)", value: "72290" },
  { label: "LUCHE PRINGE (72800)", value: "72800" },
  { label: "LE LUDE (72800)", value: "72800" },
  { label: "LE LUDE (72800)", value: "72800" },
  { label: "MAIGNE (72210)", value: "72210" },
  { label: "MAISONCELLES (72440)", value: "72440" },
  { label: "MALICORNE SUR SARTHE (72270)", value: "72270" },
  { label: "MAMERS (72600)", value: "72600" },
  { label: "LE MANS (72000)", value: "72000" },
  { label: "LE MANS (72100)", value: "72100" },
  { label: "MANSIGNE (72510)", value: "72510" },
  { label: "MARCON (72340)", value: "72340" },
  { label: "MAREIL EN CHAMPAGNE (72540)", value: "72540" },
  { label: "MAREIL SUR LOIR (72200)", value: "72200" },
  { label: "MARESCHE (72170)", value: "72170" },
  { label: "MARIGNE LAILLE (72220)", value: "72220" },
  { label: "MAROLLETTE (72600)", value: "72600" },
  { label: "MAROLLES LES BRAULTS (72260)", value: "72260" },
  { label: "MAROLLES LES BRAULTS (72260)", value: "72260" },
  { label: "MAROLLES LES ST CALAIS (72120)", value: "72120" },
  { label: "MAYET (72360)", value: "72360" },
  { label: "LES MEES (72260)", value: "72260" },
  { label: "MELLERAY (72320)", value: "72320" },
  { label: "MEURCE (72170)", value: "72170" },
  { label: "MEZERAY (72270)", value: "72270" },
  { label: "MEZIERES SUR PONTHOUIN (72290)", value: "72290" },
  { label: "MEZIERES SOUS LAVARDIN (72240)", value: "72240" },
  { label: "LA MILESSE (72650)", value: "72650" },
  { label: "MOITRON SUR SARTHE (72170)", value: "72170" },
  { label: "MOITRON SUR SARTHE (72170)", value: "72170" },
  { label: "MONCE EN BELIN (72230)", value: "72230" },
  { label: "MONCE EN SAOSNOIS (72260)", value: "72260" },
  { label: "MONHOUDOU (72260)", value: "72260" },
  { label: "MONTAILLE (72120)", value: "72120" },
  { label: "MONTBIZOT (72380)", value: "72380" },
  { label: "MONTMIRAIL (72320)", value: "72320" },
  { label: "MONTREUIL LE CHETIF (72130)", value: "72130" },
  { label: "MONTREUIL LE HENRI (72150)", value: "72150" },
  { label: "MONT ST JEAN (72140)", value: "72140" },
  { label: "MOULINS LE CARBONNEL (72130)", value: "72130" },
  { label: "MULSANNE (72230)", value: "72230" },
  { label: "NAUVAY (72260)", value: "72260" },
  { label: "NEUFCHATEL EN SAOSNOIS (72600)", value: "72600" },
  { label: "NEUVILLALAIS (72240)", value: "72240" },
  { label: "NEUVILLE SUR SARTHE (72190)", value: "72190" },
  { label: "NEUVILLETTE EN CHARNIE (72140)", value: "72140" },
  { label: "BERNAY NEUVY EN CHAMPAGNE (72240)", value: "72240" },
  { label: "BERNAY NEUVY EN CHAMPAGNE (72240)", value: "72240" },
  { label: "NOGENT LE BERNARD (72110)", value: "72110" },
  { label: "NOGENT SUR LOIR (72500)", value: "72500" },
  { label: "NOUANS (72260)", value: "72260" },
  { label: "NOYEN SUR SARTHE (72430)", value: "72430" },
  { label: "NUILLE LE JALAIS (72370)", value: "72370" },
  { label: "OISSEAU LE PETIT (72610)", value: "72610" },
  { label: "OIZE (72330)", value: "72330" },
  { label: "PANON (72600)", value: "72600" },
  { label: "PARCE SUR SARTHE (72300)", value: "72300" },
  { label: "PARENNES (72140)", value: "72140" },
  { label: "PARIGNE LE POLIN (72330)", value: "72330" },
  { label: "PARIGNE L EVEQUE (72250)", value: "72250" },
  { label: "NOTRE DAME DU PE (72300)", value: "72300" },
  { label: "PERAY (72260)", value: "72260" },
  { label: "PEZE LE ROBERT (72140)", value: "72140" },
  { label: "PIACE (72170)", value: "72170" },
  { label: "PINCE (72300)", value: "72300" },
  { label: "PIRMIL (72430)", value: "72430" },
  { label: "PIZIEUX (72600)", value: "72600" },
  { label: "POILLE SUR VEGRE (72350)", value: "72350" },
  { label: "MONTFORT LE GESNOIS (72450)", value: "72450" },
  { label: "PONTVALLAIN (72510)", value: "72510" },
  { label: "PRECIGNE (72300)", value: "72300" },
  { label: "PREVAL (72400)", value: "72400" },
  { label: "PREVELLES (72110)", value: "72110" },
  { label: "PRUILLE LE CHETIF (72700)", value: "72700" },
  { label: "PRUILLE L EGUILLE (72150)", value: "72150" },
  { label: "LA QUINTE (72550)", value: "72550" },
  { label: "RAHAY (72120)", value: "72120" },
  { label: "RENE (72260)", value: "72260" },
  { label: "REQUEIL (72510)", value: "72510" },
  { label: "ROEZE SUR SARTHE (72210)", value: "72210" },
  { label: "ROUESSE FONTAINE (72610)", value: "72610" },
  { label: "ROUESSE VASSE (72140)", value: "72140" },
  { label: "ROUEZ (72140)", value: "72140" },
  { label: "ROUILLON (72700)", value: "72700" },
  { label: "ROUPERROUX LE COQUET (72110)", value: "72110" },
  { label: "RUAUDIN (72230)", value: "72230" },
  { label: "RUILLE EN CHAMPAGNE (72240)", value: "72240" },
  { label: "LOIR EN VALLEE (72310)", value: "72310" },
  { label: "LOIR EN VALLEE (72310)", value: "72310" },
  { label: "LOIR EN VALLEE (72340)", value: "72340" },
  { label: "LOIR EN VALLEE (72340)", value: "72340" },
  { label: "LOIR EN VALLEE (72340)", value: "72340" },
  { label: "SABLE SUR SARTHE (72300)", value: "72300" },
  { label: "ST AIGNAN (72110)", value: "72110" },
  { label: "ST AUBIN DE LOCQUENAY (72130)", value: "72130" },
  { label: "ST AUBIN DES COUDRAIS (72400)", value: "72400" },
  { label: "ST BIEZ EN BELIN (72220)", value: "72220" },
  { label: "ST CALAIS (72120)", value: "72120" },
  { label: "ST CALEZ EN SAOSNOIS (72600)", value: "72600" },
  { label: "ST CELERIN (72110)", value: "72110" },
  { label: "STE CEROTTE (72120)", value: "72120" },
  { label: "ST CHRISTOPHE DU JAMBET (72170)", value: "72170" },
  { label: "ST CHRISTOPHE EN CHAMPAGNE (72540)", value: "72540" },
  { label: "ST CORNEILLE (72460)", value: "72460" },
  { label: "ST COSME EN VAIRAIS (72110)", value: "72110" },
  { label: "ST DENIS DES COUDRAIS (72110)", value: "72110" },
  { label: "ST DENIS D ORQUES (72350)", value: "72350" },
  { label: "ST GEORGES DE LA COUEE (72150)", value: "72150" },
  { label: "ST GEORGES DU BOIS (72700)", value: "72700" },
  { label: "ST GEORGES DU ROSAY (72110)", value: "72110" },
  { label: "ST GEORGES LE GAULTIER (72130)", value: "72130" },
  { label: "ST GERMAIN D ARCE (72800)", value: "72800" },
  { label: "ST GERVAIS DE VIC (72120)", value: "72120" },
  { label: "ST GERVAIS EN BELIN (72220)", value: "72220" },
  { label: "STE JAMME SUR SARTHE (72380)", value: "72380" },
  { label: "STE JAMME SUR SARTHE (72380)", value: "72380" },
  { label: "ST JEAN D ASSE (72380)", value: "72380" },
  { label: "ST JEAN DE LA MOTTE (72510)", value: "72510" },
  { label: "ST JEAN DES ECHELLES (72320)", value: "72320" },
  { label: "ST JEAN DU BOIS (72430)", value: "72430" },
  { label: "ST LEONARD DES BOIS (72130)", value: "72130" },
  { label: "ST LONGIS (72600)", value: "72600" },
  { label: "ST MAIXENT (72320)", value: "72320" },
  { label: "ST MARCEAU (72170)", value: "72170" },
  { label: "ST MARS DE LOCQUENAY (72440)", value: "72440" },
  { label: "ST MARS D OUTILLE (72220)", value: "72220" },
  { label: "ST MARS LA BRIERE (72470)", value: "72470" },
  { label: "ST MARTIN DES MONTS (72400)", value: "72400" },
  { label: "ST MICHEL DE CHAVAIGNES (72440)", value: "72440" },
  { label: "ST OUEN DE MIMBRE (72130)", value: "72130" },
  { label: "ST OUEN EN BELIN (72220)", value: "72220" },
  { label: "ST OUEN EN CHAMPAGNE (72350)", value: "72350" },
  { label: "ST PATERNE LE CHEVAIN (72610)", value: "72610" },
  { label: "ST PATERNE LE CHEVAIN (72610)", value: "72610" },
  { label: "ST PAUL LE GAULTIER (72130)", value: "72130" },
  { label: "ST PAVACE (72190)", value: "72190" },
  { label: "ST PIERRE DE CHEVILLE (72500)", value: "72500" },
  { label: "ST PIERRE DES BOIS (72430)", value: "72430" },
  { label: "ST PIERRE DES ORMES (72600)", value: "72600" },
  { label: "ST PIERRE DU LOROUER (72150)", value: "72150" },
  { label: "ST REMY DE SILLE (72140)", value: "72140" },
  { label: "ST REMY DES MONTS (72600)", value: "72600" },
  { label: "ST REMY DU VAL (72600)", value: "72600" },
  { label: "STE SABINE SUR LONGEVE (72380)", value: "72380" },
  { label: "ST SATURNIN (72650)", value: "72650" },
  { label: "ST SYMPHORIEN (72240)", value: "72240" },
  { label: "ST ULPHACE (72320)", value: "72320" },
  { label: "ST VICTEUR (72130)", value: "72130" },
  { label: "ST VINCENT DES PRES (72600)", value: "72600" },
  { label: "ST VINCENT DU LOROUER (72150)", value: "72150" },
  { label: "SAOSNES (72600)", value: "72600" },
  { label: "SARCE (72360)", value: "72360" },
  { label: "SARGE LES LE MANS (72190)", value: "72190" },
  { label: "SAVIGNE L EVEQUE (72460)", value: "72460" },
  { label: "SAVIGNE SOUS LE LUDE (72800)", value: "72800" },
  { label: "SCEAUX SUR HUISNE (72160)", value: "72160" },
  { label: "SEGRIE (72170)", value: "72170" },
  { label: "SEMUR EN VALLON (72390)", value: "72390" },
  { label: "SILLE LE GUILLAUME (72140)", value: "72140" },
  { label: "SILLE LE PHILIPPE (72460)", value: "72460" },
  { label: "SOLESMES (72300)", value: "72300" },
  { label: "SOUGE LE GANELON (72130)", value: "72130" },
  { label: "SOUILLE (72380)", value: "72380" },
  { label: "SOULIGNE FLACE (72210)", value: "72210" },
  { label: "SOULIGNE SOUS BALLON (72290)", value: "72290" },
  { label: "SOULITRE (72370)", value: "72370" },
  { label: "SOUVIGNE SUR MEME (72400)", value: "72400" },
  { label: "SOUVIGNE SUR SARTHE (72300)", value: "72300" },
  { label: "SPAY (72700)", value: "72700" },
  { label: "SURFONDS (72370)", value: "72370" },
  { label: "LA SUZE SUR SARTHE (72210)", value: "72210" },
  { label: "TASSE (72430)", value: "72430" },
  { label: "TASSILLE (72540)", value: "72540" },
  { label: "TEILLE (72290)", value: "72290" },
  { label: "TELOCHE (72220)", value: "72220" },
  { label: "TENNIE (72240)", value: "72240" },
  { label: "TERREHAULT (72110)", value: "72110" },
  { label: "THELIGNY (72320)", value: "72320" },
  { label: "THOIGNE (72260)", value: "72260" },
  { label: "THOIRE SOUS CONTENSOR (72610)", value: "72610" },
  { label: "THOIRE SUR DINAN (72500)", value: "72500" },
  { label: "THOREE LES PINS (72800)", value: "72800" },
  { label: "THORIGNE SUR DUE (72160)", value: "72160" },
  { label: "TORCE EN VALLEE (72110)", value: "72110" },
  { label: "TRANGE (72650)", value: "72650" },
  { label: "TRESSON (72440)", value: "72440" },
  { label: "LE TRONCHET (72170)", value: "72170" },
  { label: "TUFFE VAL DE LA CHERONNE (72160)", value: "72160" },
  { label: "TUFFE VAL DE LA CHERONNE (72160)", value: "72160" },
  { label: "VAAS (72500)", value: "72500" },
  { label: "VALENNES (72320)", value: "72320" },
  { label: "VALLON SUR GEE (72540)", value: "72540" },
  { label: "VANCE (72310)", value: "72310" },
  { label: "VERNEIL LE CHETIF (72360)", value: "72360" },
  { label: "VERNIE (72170)", value: "72170" },
  { label: "VEZOT (72600)", value: "72600" },
  { label: "VIBRAYE (72320)", value: "72320" },
  { label: "VILLAINES LA CARELLE (72600)", value: "72600" },
  { label: "VILLAINES LA GONAIS (72400)", value: "72400" },
  { label: "VILLAINES SOUS LUCE (72150)", value: "72150" },
  { label: "VILLAINES SOUS MALICORNE (72270)", value: "72270" },
  { label: "VION (72300)", value: "72300" },
  { label: "VIRE EN CHAMPAGNE (72350)", value: "72350" },
  { label: "VIVOIN (72170)", value: "72170" },
  { label: "VOIVRES LES LE MANS (72210)", value: "72210" },
  { label: "VOLNAY (72440)", value: "72440" },
  { label: "VOUVRAY SUR HUISNE (72160)", value: "72160" },
  { label: "YVRE LE POLIN (72330)", value: "72330" },
  { label: "YVRE L EVEQUE (72530)", value: "72530" },
  { label: "AIGUEBELETTE LE LAC (73610)", value: "73610" },
  { label: "GRAND AIGUEBLANCHE (73260)", value: "73260" },
  { label: "GRAND AIGUEBLANCHE (73260)", value: "73260" },
  { label: "GRAND AIGUEBLANCHE (73260)", value: "73260" },
  { label: "AILLON LE JEUNE (73340)", value: "73340" },
  { label: "AILLON LE VIEUX (73340)", value: "73340" },
  { label: "AIME LA PLAGNE (73210)", value: "73210" },
  { label: "AIME LA PLAGNE (73210)", value: "73210" },
  { label: "AIME LA PLAGNE (73210)", value: "73210" },
  { label: "AIME LA PLAGNE (73210)", value: "73210" },
  { label: "AIME LA PLAGNE (73210)", value: "73210" },
  { label: "AIME LA PLAGNE (73210)", value: "73210" },
  { label: "AITON (73220)", value: "73220" },
  { label: "AIX LES BAINS (73100)", value: "73100" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ENTRELACS (73410)", value: "73410" },
  { label: "ALBERTVILLE (73200)", value: "73200" },
  { label: "ALBIEZ LE JEUNE (73300)", value: "73300" },
  { label: "ALBIEZ MONTROND (73300)", value: "73300" },
  { label: "ALLONDAZ (73200)", value: "73200" },
  { label: "LES ALLUES (73550)", value: "73550" },
  { label: "LES ALLUES (73550)", value: "73550" },
  { label: "APREMONT (73190)", value: "73190" },
  { label: "ARBIN (73800)", value: "73800" },
  { label: "ARGENTINE (73220)", value: "73220" },
  { label: "ARITH (73340)", value: "73340" },
  { label: "ARVILLARD (73110)", value: "73110" },
  { label: "ATTIGNAT ONCIN (73610)", value: "73610" },
  { label: "AUSSOIS (73500)", value: "73500" },
  { label: "LES AVANCHERS VALMOREL (73260)", value: "73260" },
  { label: "AVRESSIEUX (73240)", value: "73240" },
  { label: "AVRIEUX (73500)", value: "73500" },
  { label: "AYN (73470)", value: "73470" },
  { label: "LA BALME (73170)", value: "73170" },
  { label: "BARBERAZ (73000)", value: "73000" },
  { label: "BARBY (73230)", value: "73230" },
  { label: "BASSENS (73000)", value: "73000" },
  { label: "LA BATHIE (73540)", value: "73540" },
  { label: "LA BAUCHE (73360)", value: "73360" },
  { label: "BEAUFORT (73270)", value: "73270" },
  { label: "BEAUFORT (73270)", value: "73270" },
  { label: "BELLECOMBE EN BAUGES (73340)", value: "73340" },
  { label: "BELMONT TRAMONET (73330)", value: "73330" },
  { label: "BESSANS (73480)", value: "73480" },
  { label: "BETTON BETTONET (73390)", value: "73390" },
  { label: "BILLIEME (73170)", value: "73170" },
  { label: "LA BIOLLE (73410)", value: "73410" },
  { label: "BONNEVAL SUR ARC (73480)", value: "73480" },
  { label: "BONVILLARD (73460)", value: "73460" },
  { label: "BONVILLARET (73220)", value: "73220" },
  { label: "BOURDEAU (73370)", value: "73370" },
  { label: "LE BOURGET DU LAC (73370)", value: "73370" },
  { label: "BOURGET EN HUILE (73110)", value: "73110" },
  { label: "BOURGNEUF (73390)", value: "73390" },
  { label: "BOURG ST MAURICE (73700)", value: "73700" },
  { label: "BOURG ST MAURICE (73700)", value: "73700" },
  { label: "BOURG ST MAURICE (73700)", value: "73700" },
  { label: "BOZEL (73350)", value: "73350" },
  { label: "BRIDES LES BAINS (73570)", value: "73570" },
  { label: "LA BRIDOIRE (73520)", value: "73520" },
  { label: "BRISON ST INNOCENT (73100)", value: "73100" },
  { label: "CESARCHES (73200)", value: "73200" },
  { label: "CEVINS (73730)", value: "73730" },
  { label: "CHALLES LES EAUX (73190)", value: "73190" },
  { label: "CHAMBERY (73000)", value: "73000" },
  { label: "LA CHAMBRE (73130)", value: "73130" },
  { label: "CHAMOUSSET (73390)", value: "73390" },
  { label: "CHAMOUX SUR GELON (73390)", value: "73390" },
  { label: "CHAMPAGNEUX (73240)", value: "73240" },
  { label: "CHAMPAGNY EN VANOISE (73350)", value: "73350" },
  { label: "CHAMP LAURENT (73390)", value: "73390" },
  { label: "CHANAZ (73310)", value: "73310" },
  { label: "LA CHAPELLE (73660)", value: "73660" },
  { label: "LA CHAPELLE BLANCHE (73110)", value: "73110" },
  { label: "LA CHAPELLE DU MONT DU CHAT (73370)", value: "73370" },
  { label: "LES CHAPELLES (73700)", value: "73700" },
  { label: "LA CHAPELLE ST MARTIN (73170)", value: "73170" },
  { label: "CHATEAUNEUF (73390)", value: "73390" },
  { label: "LE CHATELARD (73630)", value: "73630" },
  { label: "LA CHAVANNE (73800)", value: "73800" },
  { label: "LES CHAVANNES EN MAURIENNE (73660)", value: "73660" },
  { label: "CHIGNIN (73800)", value: "73800" },
  { label: "CHINDRIEUX (73310)", value: "73310" },
  { label: "CLERY (73460)", value: "73460" },
  { label: "COGNIN (73160)", value: "73160" },
  { label: "COHENNOZ (73400)", value: "73400" },
  { label: "COHENNOZ (73590)", value: "73590" },
  { label: "COISE ST JEAN PIED GAUTHIER (73800)", value: "73800" },
  { label: "LA COMPOTE (73630)", value: "73630" },
  { label: "CONJUX (73310)", value: "73310" },
  { label: "CORBEL (73160)", value: "73160" },
  { label: "CREST VOLAND (73590)", value: "73590" },
  { label: "LA CROIX DE LA ROCHETTE (73110)", value: "73110" },
  { label: "CRUET (73800)", value: "73800" },
  { label: "CURIENNE (73190)", value: "73190" },
  { label: "LES DESERTS (73230)", value: "73230" },
  { label: "LES DESERTS (73230)", value: "73230" },
  { label: "DETRIER (73110)", value: "73110" },
  { label: "DOMESSIN (73330)", value: "73330" },
  { label: "DOUCY EN BAUGES (73630)", value: "73630" },
  { label: "DRUMETTAZ CLARAFOND (73420)", value: "73420" },
  { label: "DULLIN (73610)", value: "73610" },
  { label: "LES ECHELLES (73360)", value: "73360" },
  { label: "ECOLE (73630)", value: "73630" },
  { label: "ENTREMONT LE VIEUX (73670)", value: "73670" },
  { label: "EPIERRE (73220)", value: "73220" },
  { label: "ESSERTS BLAY (73540)", value: "73540" },
  { label: "FEISSONS SUR SALINS (73350)", value: "73350" },
  { label: "FLUMET (73590)", value: "73590" },
  { label: "FONTCOUVERTE LA TOUSSUIRE (73300)", value: "73300" },
  { label: "FONTCOUVERTE LA TOUSSUIRE (73300)", value: "73300" },
  { label: "FOURNEAUX (73500)", value: "73500" },
  { label: "FRENEY (73500)", value: "73500" },
  { label: "FRETERIVE (73250)", value: "73250" },
  { label: "FRONTENEX (73460)", value: "73460" },
  { label: "GERBAIX (73470)", value: "73470" },
  { label: "LA GIETTAZ (73590)", value: "73590" },
  { label: "GILLY SUR ISERE (73200)", value: "73200" },
  { label: "GRESY SUR AIX (73100)", value: "73100" },
  { label: "GRESY SUR ISERE (73460)", value: "73460" },
  { label: "GRIGNON (73200)", value: "73200" },
  { label: "HAUTECOUR (73600)", value: "73600" },
  { label: "HAUTELUCE (73620)", value: "73620" },
  { label: "HAUTEVILLE (73390)", value: "73390" },
  { label: "LA TOUR EN MAURIENNE (73300)", value: "73300" },
  { label: "LA TOUR EN MAURIENNE (73300)", value: "73300" },
  { label: "LA TOUR EN MAURIENNE (73300)", value: "73300" },
  { label: "LA TOUR EN MAURIENNE (73300)", value: "73300" },
  { label: "JACOB BELLECOMBETTE (73000)", value: "73000" },
  { label: "JARRIER (73300)", value: "73300" },
  { label: "JARSY (73630)", value: "73630" },
  { label: "JONGIEUX (73170)", value: "73170" },
  { label: "LAISSAUD (73800)", value: "73800" },
  { label: "LANDRY (73210)", value: "73210" },
  { label: "LEPIN LE LAC (73610)", value: "73610" },
  { label: "LESCHERAINES (73340)", value: "73340" },
  { label: "LOISIEUX (73170)", value: "73170" },
  { label: "LUCEY (73170)", value: "73170" },
  { label: "LA PLAGNE TARENTAISE (73210)", value: "73210" },
  { label: "LA PLAGNE TARENTAISE (73210)", value: "73210" },
  { label: "LA PLAGNE TARENTAISE (73210)", value: "73210" },
  { label: "LA PLAGNE TARENTAISE (73210)", value: "73210" },
  { label: "LA PLAGNE TARENTAISE (73210)", value: "73210" },
  { label: "PORTE DE SAVOIE (73800)", value: "73800" },
  { label: "PORTE DE SAVOIE (73800)", value: "73800" },
  { label: "MARCIEUX (73470)", value: "73470" },
  { label: "MARTHOD (73400)", value: "73400" },
  { label: "MERCURY (73200)", value: "73200" },
  { label: "MERY (73420)", value: "73420" },
  { label: "MEYRIEUX TROUET (73170)", value: "73170" },
  { label: "MODANE (73500)", value: "73500" },
  { label: "LES MOLLETTES (73800)", value: "73800" },
  { label: "MONTAGNOLE (73000)", value: "73000" },
  { label: "MONTAGNY (73350)", value: "73350" },
  { label: "MONTAILLEUR (73460)", value: "73460" },
  { label: "MONTCEL (73100)", value: "73100" },
  { label: "MONTENDRY (73390)", value: "73390" },
  { label: "MONTGILBERT (73220)", value: "73220" },
  { label: "MONTHION (73200)", value: "73200" },
  { label: "MONTMELIAN (73800)", value: "73800" },
  { label: "MONTRICHER ALBANNE (73870)", value: "73870" },
  { label: "MONTRICHER ALBANNE (73870)", value: "73870" },
  { label: "MONTSAPEY (73220)", value: "73220" },
  { label: "MONTVALEZAN (73700)", value: "73700" },
  { label: "MONTVERNIER (73300)", value: "73300" },
  { label: "LA MOTTE EN BAUGES (73340)", value: "73340" },
  { label: "LA MOTTE SERVOLEX (73290)", value: "73290" },
  { label: "MOTZ (73310)", value: "73310" },
  { label: "MOUTIERS (73600)", value: "73600" },
  { label: "MOUXY (73100)", value: "73100" },
  { label: "MYANS (73800)", value: "73800" },
  { label: "NANCES (73470)", value: "73470" },
  { label: "NOTRE DAME DE BELLECOMBE (73590)", value: "73590" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "LA LECHERE (73260)", value: "73260" },
  { label: "NOTRE DAME DES MILLIERES (73460)", value: "73460" },
  { label: "NOTRE DAME DU CRUET (73130)", value: "73130" },
  { label: "NOTRE DAME DU PRE (73600)", value: "73600" },
  { label: "NOVALAISE (73470)", value: "73470" },
  { label: "LE NOYER (73340)", value: "73340" },
  { label: "ONTEX (73310)", value: "73310" },
  { label: "ORELLE (73140)", value: "73140" },
  { label: "PALLUD (73200)", value: "73200" },
  { label: "PEISEY NANCROIX (73210)", value: "73210" },
  { label: "PLANAISE (73800)", value: "73800" },
  { label: "PLANAY (73350)", value: "73350" },
  { label: "PLANCHERINE (73200)", value: "73200" },
  { label: "LE PONT DE BEAUVOISIN (73330)", value: "73330" },
  { label: "LE PONTET (73110)", value: "73110" },
  { label: "PRALOGNAN LA VANOISE (73710)", value: "73710" },
  { label: "PRESLE (73110)", value: "73110" },
  { label: "PUGNY CHATENOD (73100)", value: "73100" },
  { label: "PUYGROS (73190)", value: "73190" },
  { label: "QUEIGE (73720)", value: "73720" },
  { label: "VAL D ARC (73220)", value: "73220" },
  { label: "VAL D ARC (73220)", value: "73220" },
  { label: "LA RAVOIRE (73490)", value: "73490" },
  { label: "ROCHEFORT (73240)", value: "73240" },
  { label: "VALGELON LA ROCHETTE (73110)", value: "73110" },
  { label: "VALGELON LA ROCHETTE (73110)", value: "73110" },
  { label: "ROGNAIX (73730)", value: "73730" },
  { label: "ROTHERENS (73110)", value: "73110" },
  { label: "RUFFIEUX (73310)", value: "73310" },
  { label: "ST ALBAN DE MONTBEL (73610)", value: "73610" },
  { label: "ST ALBAN D HURTIERES (73220)", value: "73220" },
  { label: "ST ALBAN DES VILLARDS (73130)", value: "73130" },
  { label: "ST ALBAN LEYSSE (73230)", value: "73230" },
  { label: "ST ANDRE (73500)", value: "73500" },
  { label: "ST AVRE (73130)", value: "73130" },
  { label: "ST BALDOPH (73190)", value: "73190" },
  { label: "ST BERON (73520)", value: "73520" },
  { label: "COURCHEVEL (73120)", value: "73120" },
  { label: "COURCHEVEL (73120)", value: "73120" },
  { label: "ST CASSIN (73160)", value: "73160" },
  { label: "ST CHRISTOPHE (73360)", value: "73360" },
  { label: "ST COLOMBAN DES VILLARDS (73130)", value: "73130" },
  { label: "ST ETIENNE DE CUINES (73130)", value: "73130" },
  { label: "STE FOY TARENTAISE (73640)", value: "73640" },
  { label: "ST FRANC (73360)", value: "73360" },
  { label: "ST FRANCOIS DE SALES (73340)", value: "73340" },
  { label: "ST FRANCOIS LONGCHAMP (73130)", value: "73130" },
  { label: "ST FRANCOIS LONGCHAMP (73130)", value: "73130" },
  { label: "ST FRANCOIS LONGCHAMP (73130)", value: "73130" },
  { label: "ST GENIX LES VILLAGES (73240)", value: "73240" },
  { label: "ST GENIX LES VILLAGES (73240)", value: "73240" },
  { label: "ST GENIX LES VILLAGES (73240)", value: "73240" },
  { label: "ST GEORGES D HURTIERES (73220)", value: "73220" },
  { label: "STE HELENE DU LAC (73800)", value: "73800" },
  { label: "STE HELENE SUR ISERE (73460)", value: "73460" },
  { label: "ST JEAN D ARVES (73530)", value: "73530" },
  { label: "ST JEAN D ARVEY (73230)", value: "73230" },
  { label: "ST JEAN DE CHEVELU (73170)", value: "73170" },
  { label: "ST JEAN DE COUZ (73160)", value: "73160" },
  { label: "ST JEAN DE LA PORTE (73250)", value: "73250" },
  { label: "ST JEAN DE MAURIENNE (73300)", value: "73300" },
  { label: "ST JEOIRE PRIEURE (73190)", value: "73190" },
  { label: "ST JULIEN MONT DENIS (73870)", value: "73870" },
  { label: "ST LEGER (73220)", value: "73220" },
  { label: "ST MARCEL (73600)", value: "73600" },
  { label: "STE MARIE D ALVEY (73240)", value: "73240" },
  { label: "STE MARIE DE CUINES (73130)", value: "73130" },
  { label: "ST MARTIN D ARC (73140)", value: "73140" },
  { label: "LES BELLEVILLE (73440)", value: "73440" },
  { label: "LES BELLEVILLE (73440)", value: "73440" },
  { label: "LES BELLEVILLE (73440)", value: "73440" },
  { label: "LES BELLEVILLE (73440)", value: "73440" },
  { label: "LES BELLEVILLE (73600)", value: "73600" },
  { label: "ST MARTIN DE LA PORTE (73140)", value: "73140" },
  { label: "ST MARTIN SUR LA CHAMBRE (73130)", value: "73130" },
  { label: "ST MICHEL DE MAURIENNE (73140)", value: "73140" },
  { label: "ST MICHEL DE MAURIENNE (73140)", value: "73140" },
  { label: "ST MICHEL DE MAURIENNE (73140)", value: "73140" },
  { label: "ST NICOLAS LA CHAPELLE (73590)", value: "73590" },
  { label: "ST OFFENGE (73100)", value: "73100" },
  { label: "ST OFFENGE (73100)", value: "73100" },
  { label: "ST OURS (73410)", value: "73410" },
  { label: "ST PANCRACE (73300)", value: "73300" },
  { label: "ST PAUL SUR ISERE (73730)", value: "73730" },
  { label: "ST PAUL SUR YENNE (73170)", value: "73170" },
  { label: "ST PIERRE D ALBIGNY (73250)", value: "73250" },
  { label: "ST PIERRE D ALVEY (73170)", value: "73170" },
  { label: "ST PIERRE DE BELLEVILLE (73220)", value: "73220" },
  { label: "ST PIERRE DE CURTILLE (73310)", value: "73310" },
  { label: "ST PIERRE D ENTREMONT (73670)", value: "73670" },
  { label: "ST PIERRE DE GENEBROZ (73360)", value: "73360" },
  { label: "ST PIERRE DE SOUCY (73800)", value: "73800" },
  { label: "STE REINE (73630)", value: "73630" },
  { label: "ST REMY DE MAURIENNE (73660)", value: "73660" },
  { label: "ST SORLIN D ARVES (73530)", value: "73530" },
  { label: "ST SULPICE (73160)", value: "73160" },
  { label: "ST THIBAUD DE COUZ (73160)", value: "73160" },
  { label: "ST VITAL (73460)", value: "73460" },
  { label: "SALINS FONTAINE (73600)", value: "73600" },
  { label: "SALINS FONTAINE (73600)", value: "73600" },
  { label: "SEEZ (73700)", value: "73700" },
  { label: "SERRIERES EN CHAUTAGNE (73310)", value: "73310" },
  { label: "SONNAZ (73000)", value: "73000" },
  { label: "LA TABLE (73110)", value: "73110" },
  { label: "VAL CENIS (73480)", value: "73480" },
  { label: "VAL CENIS (73480)", value: "73480" },
  { label: "VAL CENIS (73500)", value: "73500" },
  { label: "VAL CENIS (73500)", value: "73500" },
  { label: "VAL CENIS (73500)", value: "73500" },
  { label: "THENESOL (73200)", value: "73200" },
  { label: "THOIRY (73230)", value: "73230" },
  { label: "LA THUILE (73190)", value: "73190" },
  { label: "TIGNES (73320)", value: "73320" },
  { label: "TOURNON (73460)", value: "73460" },
  { label: "TOURS EN SAVOIE (73790)", value: "73790" },
  { label: "TRAIZE (73170)", value: "73170" },
  { label: "TRESSERVE (73100)", value: "73100" },
  { label: "TREVIGNIN (73100)", value: "73100" },
  { label: "LA TRINITE (73110)", value: "73110" },
  { label: "UGINE (73400)", value: "73400" },
  { label: "VAL D ISERE (73150)", value: "73150" },
  { label: "VALLOIRE (73450)", value: "73450" },
  { label: "VALMEINIER (73450)", value: "73450" },
  { label: "VENTHON (73200)", value: "73200" },
  { label: "VEREL DE MONTBEL (73330)", value: "73330" },
  { label: "VEREL PRAGONDRAN (73230)", value: "73230" },
  { label: "LE VERNEIL (73110)", value: "73110" },
  { label: "VERRENS ARVEY (73460)", value: "73460" },
  { label: "VERTHEMEX (73170)", value: "73170" },
  { label: "VILLARD D HERY (73800)", value: "73800" },
  { label: "VILLARD LEGER (73390)", value: "73390" },
  { label: "VILLARD SALLET (73110)", value: "73110" },
  { label: "VILLARD SUR DORON (73270)", value: "73270" },
  { label: "VILLAREMBERT (73300)", value: "73300" },
  { label: "VILLAREMBERT (73300)", value: "73300" },
  { label: "VILLARGONDRAN (73300)", value: "73300" },
  { label: "VILLARODIN BOURGET (73500)", value: "73500" },
  { label: "VILLAROGER (73640)", value: "73640" },
  { label: "VILLAROUX (73110)", value: "73110" },
  { label: "VIMINES (73160)", value: "73160" },
  { label: "VIONS (73310)", value: "73310" },
  { label: "VIVIERS DU LAC (73420)", value: "73420" },
  { label: "VOGLANS (73420)", value: "73420" },
  { label: "YENNE (73170)", value: "73170" },
  { label: "ABONDANCE (74360)", value: "74360" },
  { label: "ALBY SUR CHERAN (74540)", value: "74540" },
  { label: "ALEX (74290)", value: "74290" },
  { label: "ALLEVES (74540)", value: "74540" },
  { label: "ALLINGES (74200)", value: "74200" },
  { label: "ALLONZIER LA CAILLE (74350)", value: "74350" },
  { label: "ALLONZIER LA CAILLE (74350)", value: "74350" },
  { label: "AMANCY (74800)", value: "74800" },
  { label: "AMBILLY (74100)", value: "74100" },
  { label: "ANDILLY (74350)", value: "74350" },
  { label: "ANDILLY (74350)", value: "74350" },
  { label: "ANNECY (74000)", value: "74000" },
  { label: "ANNECY (74370)", value: "74370" },
  { label: "ANNECY (74370)", value: "74370" },
  { label: "ANNECY (74600)", value: "74600" },
  { label: "ANNECY (74600)", value: "74600" },
  { label: "ANNECY (74600)", value: "74600" },
  { label: "ANNECY (74940)", value: "74940" },
  { label: "ANNECY (74960)", value: "74960" },
  { label: "ANNECY (74960)", value: "74960" },
  { label: "ANNEMASSE (74100)", value: "74100" },
  { label: "ANTHY SUR LEMAN (74200)", value: "74200" },
  { label: "ARACHES LA FRASSE (74300)", value: "74300" },
  { label: "ARACHES LA FRASSE (74300)", value: "74300" },
  { label: "ARACHES LA FRASSE (74300)", value: "74300" },
  { label: "ARBUSIGNY (74930)", value: "74930" },
  { label: "ARCHAMPS (74160)", value: "74160" },
  { label: "ARENTHON (74800)", value: "74800" },
  { label: "ARGONAY (74370)", value: "74370" },
  { label: "ARMOY (74200)", value: "74200" },
  { label: "ARTHAZ PONT NOTRE DAME (74380)", value: "74380" },
  { label: "AYSE (74130)", value: "74130" },
  { label: "BALLAISON (74140)", value: "74140" },
  { label: "LA BALME DE SILLINGY (74330)", value: "74330" },
  { label: "LA BALME DE THUY (74230)", value: "74230" },
  { label: "BASSY (74910)", value: "74910" },
  { label: "LA BAUME (74430)", value: "74430" },
  { label: "BEAUMONT (74160)", value: "74160" },
  { label: "BELLEVAUX (74470)", value: "74470" },
  { label: "BERNEX (74500)", value: "74500" },
  { label: "LE BIOT (74430)", value: "74430" },
  { label: "BLOYE (74150)", value: "74150" },
  { label: "BLUFFY (74290)", value: "74290" },
  { label: "BOEGE (74420)", value: "74420" },
  { label: "BOGEVE (74250)", value: "74250" },
  { label: "BONNE (74380)", value: "74380" },
  { label: "BONNE (74380)", value: "74380" },
  { label: "BONNEVAUX (74360)", value: "74360" },
  { label: "BONNEVILLE (74130)", value: "74130" },
  { label: "BONS EN CHABLAIS (74890)", value: "74890" },
  { label: "BOSSEY (74160)", value: "74160" },
  { label: "LE BOUCHET (74230)", value: "74230" },
  { label: "BOUSSY (74150)", value: "74150" },
  { label: "BRENTHONNE (74890)", value: "74890" },
  { label: "BRIZON (74130)", value: "74130" },
  { label: "BURDIGNIN (74420)", value: "74420" },
  { label: "CERCIER (74350)", value: "74350" },
  { label: "CERNEX (74350)", value: "74350" },
  { label: "CERVENS (74550)", value: "74550" },
  { label: "CHAINAZ LES FRASSES (74540)", value: "74540" },
  { label: "CHALLONGES (74910)", value: "74910" },
  { label: "CHAMONIX MONT BLANC (74400)", value: "74400" },
  { label: "CHAMONIX MONT BLANC (74400)", value: "74400" },
  { label: "CHAMONIX MONT BLANC (74400)", value: "74400" },
  { label: "CHAMONIX MONT BLANC (74400)", value: "74400" },
  { label: "CHAMPANGES (74500)", value: "74500" },
  { label: "LA CHAPELLE D ABONDANCE (74360)", value: "74360" },
  { label: "LA CHAPELLE RAMBAUD (74800)", value: "74800" },
  { label: "LA CHAPELLE ST MAURICE (74410)", value: "74410" },
  { label: "CHAPEIRY (74540)", value: "74540" },
  { label: "CHARVONNEX (74370)", value: "74370" },
  { label: "CHATEL (74390)", value: "74390" },
  { label: "CHATILLON SUR CLUSES (74300)", value: "74300" },
  { label: "CHAUMONT (74270)", value: "74270" },
  { label: "CHAVANNAZ (74270)", value: "74270" },
  { label: "CHAVANOD (74650)", value: "74650" },
  { label: "CHENE EN SEMINE (74270)", value: "74270" },
  { label: "CHENEX (74520)", value: "74520" },
  { label: "CHENS SUR LEMAN (74140)", value: "74140" },
  { label: "CHESSENAZ (74270)", value: "74270" },
  { label: "CHEVALINE (74210)", value: "74210" },
  { label: "CHEVENOZ (74500)", value: "74500" },
  { label: "CHEVRIER (74520)", value: "74520" },
  { label: "CHILLY (74270)", value: "74270" },
  { label: "CHOISY (74330)", value: "74330" },
  { label: "CLARAFOND ARCINE (74270)", value: "74270" },
  { label: "CLARAFOND ARCINE (74270)", value: "74270" },
  { label: "CLERMONT (74270)", value: "74270" },
  { label: "LES CLEFS (74230)", value: "74230" },
  { label: "LA CLUSAZ (74220)", value: "74220" },
  { label: "CLUSES (74300)", value: "74300" },
  { label: "COLLONGES SOUS SALEVE (74160)", value: "74160" },
  { label: "COMBLOUX (74920)", value: "74920" },
  { label: "LES CONTAMINES MONTJOIE (74170)", value: "74170" },
  { label: "CONTAMINE SARZIN (74270)", value: "74270" },
  { label: "CONTAMINE SUR ARVE (74130)", value: "74130" },
  { label: "COPPONEX (74350)", value: "74350" },
  { label: "CORDON (74700)", value: "74700" },
  { label: "CORNIER (74800)", value: "74800" },
  { label: "LA COTE D ARBROZ (74110)", value: "74110" },
  { label: "CRANVES SALES (74380)", value: "74380" },
  { label: "CREMPIGNY BONNEGUETE (74150)", value: "74150" },
  { label: "CREMPIGNY BONNEGUETE (74150)", value: "74150" },
  { label: "CRUSEILLES (74350)", value: "74350" },
  { label: "CUSY (74540)", value: "74540" },
  { label: "CUVAT (74350)", value: "74350" },
  { label: "DEMI QUARTIER (74120)", value: "74120" },
  { label: "DESINGY (74270)", value: "74270" },
  { label: "DINGY EN VUACHE (74520)", value: "74520" },
  { label: "DINGY ST CLAIR (74230)", value: "74230" },
  { label: "DOMANCY (74700)", value: "74700" },
  { label: "DOUSSARD (74210)", value: "74210" },
  { label: "DOUVAINE (74140)", value: "74140" },
  { label: "DRAILLANT (74550)", value: "74550" },
  { label: "DROISY (74270)", value: "74270" },
  { label: "DUINGT (74410)", value: "74410" },
  { label: "ELOISE (01200)", value: "01200" },
  { label: "ENTREVERNES (74410)", value: "74410" },
  { label: "EPAGNY METZ TESSY (74330)", value: "74330" },
  { label: "EPAGNY METZ TESSY (74370)", value: "74370" },
  { label: "ESSERT ROMAND (74110)", value: "74110" },
  { label: "ETAUX (74800)", value: "74800" },
  { label: "ETERCY (74150)", value: "74150" },
  { label: "ETREMBIERES (74100)", value: "74100" },
  { label: "ETREMBIERES (74100)", value: "74100" },
  { label: "EVIAN LES BAINS (74500)", value: "74500" },
  { label: "EXCENEVEX (74140)", value: "74140" },
  { label: "FAUCIGNY (74130)", value: "74130" },
  { label: "FAVERGES SEYTHENEX (74210)", value: "74210" },
  { label: "FAVERGES SEYTHENEX (74210)", value: "74210" },
  { label: "FEIGERES (74160)", value: "74160" },
  { label: "FESSY (74890)", value: "74890" },
  { label: "FETERNES (74500)", value: "74500" },
  { label: "FILLINGES (74250)", value: "74250" },
  { label: "LA FORCLAZ (74200)", value: "74200" },
  { label: "FRANCLENS (74910)", value: "74910" },
  { label: "FRANGY (74270)", value: "74270" },
  { label: "GAILLARD (74240)", value: "74240" },
  { label: "LES GETS (74260)", value: "74260" },
  { label: "GIEZ (74210)", value: "74210" },
  { label: "LE GRAND BORNAND (74450)", value: "74450" },
  { label: "GROISY (74570)", value: "74570" },
  { label: "GRUFFY (74540)", value: "74540" },
  { label: "HABERE LULLIN (74420)", value: "74420" },
  { label: "HABERE POCHE (74420)", value: "74420" },
  { label: "HAUTEVILLE SUR FIER (74150)", value: "74150" },
  { label: "HERY SUR ALBY (74540)", value: "74540" },
  { label: "LES HOUCHES (74310)", value: "74310" },
  { label: "JONZIER EPAGNY (74520)", value: "74520" },
  { label: "JUVIGNY (74100)", value: "74100" },
  { label: "LARRINGES (74500)", value: "74500" },
  { label: "LATHUILE (74210)", value: "74210" },
  { label: "LESCHAUX (74320)", value: "74320" },
  { label: "LOISIN (74140)", value: "74140" },
  { label: "LORNAY (74150)", value: "74150" },
  { label: "LOVAGNY (74330)", value: "74330" },
  { label: "LUCINGES (74380)", value: "74380" },
  { label: "LUGRIN (74500)", value: "74500" },
  { label: "LULLIN (74470)", value: "74470" },
  { label: "LULLY (74890)", value: "74890" },
  { label: "LYAUD (74200)", value: "74200" },
  { label: "MACHILLY (74140)", value: "74140" },
  { label: "MAGLAND (74300)", value: "74300" },
  { label: "MAGLAND (74300)", value: "74300" },
  { label: "MANIGOD (74230)", value: "74230" },
  { label: "MARCELLAZ ALBANAIS (74150)", value: "74150" },
  { label: "MARCELLAZ (74250)", value: "74250" },
  { label: "MARGENCEL (74200)", value: "74200" },
  { label: "MARIGNIER (74970)", value: "74970" },
  { label: "MARIGNY ST MARCEL (74150)", value: "74150" },
  { label: "MARIN (74200)", value: "74200" },
  { label: "VAL DE CHAISE (74210)", value: "74210" },
  { label: "VAL DE CHAISE (74210)", value: "74210" },
  { label: "MARLIOZ (74270)", value: "74270" },
  { label: "MARNAZ (74460)", value: "74460" },
  { label: "MASSINGY (74150)", value: "74150" },
  { label: "MASSONGY (74140)", value: "74140" },
  { label: "MAXILLY SUR LEMAN (74500)", value: "74500" },
  { label: "MEGEVE (74120)", value: "74120" },
  { label: "MEGEVETTE (74490)", value: "74490" },
  { label: "MEILLERIE (74500)", value: "74500" },
  { label: "MENTHON ST BERNARD (74290)", value: "74290" },
  { label: "MENTHONNEX EN BORNES (74350)", value: "74350" },
  { label: "MENTHONNEX SOUS CLERMONT (74270)", value: "74270" },
  { label: "MESIGNY (74330)", value: "74330" },
  { label: "MESSERY (74140)", value: "74140" },
  { label: "MIEUSSY (74440)", value: "74440" },
  { label: "MIEUSSY (74440)", value: "74440" },
  { label: "MINZIER (74270)", value: "74270" },
  { label: "MONNETIER MORNEX (74560)", value: "74560" },
  { label: "MONNETIER MORNEX (74560)", value: "74560" },
  { label: "MONTAGNY LES LANCHES (74600)", value: "74600" },
  { label: "MONTRIOND (74110)", value: "74110" },
  { label: "MONT SAXONNEX (74130)", value: "74130" },
  { label: "MORILLON (74440)", value: "74440" },
  { label: "MORZINE (74110)", value: "74110" },
  { label: "MORZINE (74110)", value: "74110" },
  { label: "MOYE (74150)", value: "74150" },
  { label: "LA MURAZ (74560)", value: "74560" },
  { label: "MURES (74540)", value: "74540" },
  { label: "MUSIEGES (74270)", value: "74270" },
  { label: "NANCY SUR CLUSES (74300)", value: "74300" },
  { label: "NANGY (74380)", value: "74380" },
  { label: "NAVES PARMELAN (74370)", value: "74370" },
  { label: "NERNIER (74140)", value: "74140" },
  { label: "NEUVECELLE (74500)", value: "74500" },
  { label: "NEYDENS (74160)", value: "74160" },
  { label: "NONGLARD (74330)", value: "74330" },
  { label: "NOVEL (74500)", value: "74500" },
  { label: "ONNION (74490)", value: "74490" },
  { label: "ORCIER (74550)", value: "74550" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PASSY (74190)", value: "74190" },
  { label: "PEILLONNEX (74250)", value: "74250" },
  { label: "PERRIGNIER (74550)", value: "74550" },
  { label: "PERS JUSSY (74930)", value: "74930" },
  { label: "GLIERES VAL DE BORNE (74130)", value: "74130" },
  { label: "GLIERES VAL DE BORNE (74130)", value: "74130" },
  { label: "POISY (74330)", value: "74330" },
  { label: "PRAZ SUR ARLY (74120)", value: "74120" },
  { label: "PRESILLY (74160)", value: "74160" },
  { label: "PUBLIER (74500)", value: "74500" },
  { label: "PUBLIER (74500)", value: "74500" },
  { label: "QUINTAL (74600)", value: "74600" },
  { label: "REIGNIER ESERY (74930)", value: "74930" },
  { label: "REIGNIER ESERY (74930)", value: "74930" },
  { label: "LE REPOSOIR (74950)", value: "74950" },
  { label: "REYVROZ (74200)", value: "74200" },
  { label: "LA RIVIERE ENVERSE (74440)", value: "74440" },
  { label: "LA ROCHE SUR FORON (74800)", value: "74800" },
  { label: "RUMILLY (74150)", value: "74150" },
  { label: "ST ANDRE DE BOEGE (74420)", value: "74420" },
  { label: "ST BLAISE (74350)", value: "74350" },
  { label: "ST CERGUES (74140)", value: "74140" },
  { label: "ST EUSEBE (74150)", value: "74150" },
  { label: "ST EUSTACHE (74410)", value: "74410" },
  { label: "ST FELIX (74540)", value: "74540" },
  { label: "ST FERREOL (74210)", value: "74210" },
  { label: "ST GERMAIN SUR RHONE (74910)", value: "74910" },
  { label: "ST GERVAIS LES BAINS (74170)", value: "74170" },
  { label: "ST GERVAIS LES BAINS (74170)", value: "74170" },
  { label: "ST GERVAIS LES BAINS (74170)", value: "74170" },
  { label: "ST GINGOLPH (74500)", value: "74500" },
  { label: "ST JEAN D AULPS (74430)", value: "74430" },
  { label: "ST JEAN DE SIXT (74450)", value: "74450" },
  { label: "ST JEAN DE THOLOME (74250)", value: "74250" },
  { label: "ST JEOIRE (74490)", value: "74490" },
  { label: "ST JORIOZ (74410)", value: "74410" },
  { label: "ST JULIEN EN GENEVOIS (74160)", value: "74160" },
  { label: "ST LAURENT (74800)", value: "74800" },
  { label: "ST PAUL EN CHABLAIS (74500)", value: "74500" },
  { label: "ST PIERRE EN FAUCIGNY (74800)", value: "74800" },
  { label: "ST SIGISMOND (74300)", value: "74300" },
  { label: "ST SIXT (74800)", value: "74800" },
  { label: "ST SYLVESTRE (74540)", value: "74540" },
  { label: "SALES (74150)", value: "74150" },
  { label: "SALLANCHES (74700)", value: "74700" },
  { label: "SALLANCHES (74700)", value: "74700" },
  { label: "SALLANCHES (74700)", value: "74700" },
  { label: "SALLENOVES (74270)", value: "74270" },
  { label: "SAMOENS (74340)", value: "74340" },
  { label: "LE SAPPEY (74350)", value: "74350" },
  { label: "SAVIGNY (74520)", value: "74520" },
  { label: "SAXEL (74420)", value: "74420" },
  { label: "SCIENTRIER (74930)", value: "74930" },
  { label: "SCIEZ (74140)", value: "74140" },
  { label: "SCIONZIER (74950)", value: "74950" },
  { label: "SERRAVAL (74230)", value: "74230" },
  { label: "SERVOZ (74310)", value: "74310" },
  { label: "SEVRIER (74320)", value: "74320" },
  { label: "SEYSSEL (74910)", value: "74910" },
  { label: "SEYTROUX (74430)", value: "74430" },
  { label: "SILLINGY (74330)", value: "74330" },
  { label: "SIXT FER A CHEVAL (74740)", value: "74740" },
  { label: "TALLOIRES MONTMIN (74210)", value: "74210" },
  { label: "TALLOIRES MONTMIN (74290)", value: "74290" },
  { label: "TANINGES (74440)", value: "74440" },
  { label: "TANINGES (74440)", value: "74440" },
  { label: "THYEZ (74300)", value: "74300" },
  { label: "THOLLON LES MEMISES (74500)", value: "74500" },
  { label: "THONES (74230)", value: "74230" },
  { label: "THONON LES BAINS (74200)", value: "74200" },
  { label: "THONON LES BAINS (74200)", value: "74200" },
  { label: "FILLIERE (74370)", value: "74370" },
  { label: "FILLIERE (74370)", value: "74370" },
  { label: "FILLIERE (74570)", value: "74570" },
  { label: "FILLIERE (74570)", value: "74570" },
  { label: "FILLIERE (74570)", value: "74570" },
  { label: "THUSY (74150)", value: "74150" },
  { label: "LA TOUR (74250)", value: "74250" },
  { label: "USINENS (74910)", value: "74910" },
  { label: "VACHERESSE (74360)", value: "74360" },
  { label: "VAILLY (74470)", value: "74470" },
  { label: "VALLEIRY (74520)", value: "74520" },
  { label: "VALLIERES SUR FIER (74150)", value: "74150" },
  { label: "VALLIERES SUR FIER (74150)", value: "74150" },
  { label: "VALLIERES SUR FIER (74150)", value: "74150" },
  { label: "VALLIERES SUR FIER (74150)", value: "74150" },
  { label: "VALLORCINE (74660)", value: "74660" },
  { label: "VANZY (74270)", value: "74270" },
  { label: "VAULX (74150)", value: "74150" },
  { label: "VEIGY FONCENEX (74140)", value: "74140" },
  { label: "VERCHAIX (74440)", value: "74440" },
  { label: "LA VERNAZ (74200)", value: "74200" },
  { label: "VERS (74160)", value: "74160" },
  { label: "VERSONNEX (74150)", value: "74150" },
  { label: "VETRAZ MONTHOUX (74100)", value: "74100" },
  { label: "VEYRIER DU LAC (74290)", value: "74290" },
  { label: "VILLARD (74420)", value: "74420" },
  { label: "LES VILLARDS SUR THONES (74230)", value: "74230" },
  { label: "VILLAZ (74370)", value: "74370" },
  { label: "VILLE EN SALLAZ (74250)", value: "74250" },
  { label: "VILLE LA GRAND (74100)", value: "74100" },
  { label: "VILLY LE BOUVERET (74350)", value: "74350" },
  { label: "VILLY LE PELLOUX (74350)", value: "74350" },
  { label: "VINZIER (74500)", value: "74500" },
  { label: "VIRY (74580)", value: "74580" },
  { label: "VIUZ LA CHIESAZ (74540)", value: "74540" },
  { label: "VIUZ EN SALLAZ (74250)", value: "74250" },
  { label: "VOUGY (74130)", value: "74130" },
  { label: "VOVRAY EN BORNES (74350)", value: "74350" },
  { label: "VULBENS (74520)", value: "74520" },
  { label: "YVOIRE (74140)", value: "74140" },
  { label: "PARIS 01 (75001)", value: "75001" },
  { label: "PARIS 02 (75002)", value: "75002" },
  { label: "PARIS 03 (75003)", value: "75003" },
  { label: "PARIS 04 (75004)", value: "75004" },
  { label: "PARIS 05 (75005)", value: "75005" },
  { label: "PARIS 06 (75006)", value: "75006" },
  { label: "PARIS 07 (75007)", value: "75007" },
  { label: "PARIS 08 (75008)", value: "75008" },
  { label: "PARIS 09 (75009)", value: "75009" },
  { label: "PARIS 10 (75010)", value: "75010" },
  { label: "PARIS 11 (75011)", value: "75011" },
  { label: "PARIS 12 (75012)", value: "75012" },
  { label: "PARIS 13 (75013)", value: "75013" },
  { label: "PARIS 14 (75014)", value: "75014" },
  { label: "PARIS 15 (75015)", value: "75015" },
  { label: "PARIS 16 (75016)", value: "75016" },
  { label: "PARIS 16 (75116)", value: "75116" },
  { label: "PARIS 17 (75017)", value: "75017" },
  { label: "PARIS 18 (75018)", value: "75018" },
  { label: "PARIS 19 (75019)", value: "75019" },
  { label: "PARIS 20 (75020)", value: "75020" },
  { label: "ALLOUVILLE BELLEFOSSE (76190)", value: "76190" },
  { label: "ALVIMARE (76640)", value: "76640" },
  { label: "AMBRUMESNIL (76550)", value: "76550" },
  { label: "AMFREVILLE LA MI VOIE (76920)", value: "76920" },
  { label: "AMFREVILLE LES CHAMPS (76560)", value: "76560" },
  { label: "ANCEAUMEVILLE (76710)", value: "76710" },
  { label: "ANCOURT (76370)", value: "76370" },
  { label: "ANCOURTEVILLE SUR HERICOURT (76560)", value: "76560" },
  { label: "ANCRETIEVILLE ST VICTOR (76760)", value: "76760" },
  { label: "ANCRETTEVILLE SUR MER (76540)", value: "76540" },
  { label: "ANGERVILLE BAILLEUL (76110)", value: "76110" },
  { label: "ANGERVILLE LA MARTEL (76540)", value: "76540" },
  { label: "ANGERVILLE L ORCHER (76280)", value: "76280" },
  { label: "ANGIENS (76740)", value: "76740" },
  { label: "ANGLESQUEVILLE LA BRAS LONG (76740)", value: "76740" },
  { label: "ANGLESQUEVILLE L ESNEVAL (76280)", value: "76280" },
  { label: "VAL DE SAANE (76890)", value: "76890" },
  { label: "ANNEVILLE SUR SCIE (76590)", value: "76590" },
  { label: "ANNEVILLE AMBOURVILLE (76480)", value: "76480" },
  { label: "ANNEVILLE AMBOURVILLE (76480)", value: "76480" },
  { label: "ANNOUVILLE VILMESNIL (76110)", value: "76110" },
  { label: "ANQUETIERVILLE (76490)", value: "76490" },
  { label: "ANVEVILLE (76560)", value: "76560" },
  { label: "ARDOUVAL (76680)", value: "76680" },
  { label: "ARGUEIL (76780)", value: "76780" },
  { label: "ARQUES LA BATAILLE (76880)", value: "76880" },
  { label: "AUBEGUIMONT (76390)", value: "76390" },
  { label: "AUBERMESNIL AUX ERABLES (76340)", value: "76340" },
  { label: "AUBERMESNIL BEAUMAIS (76550)", value: "76550" },
  { label: "AUBERVILLE LA MANUEL (76450)", value: "76450" },
  { label: "AUBERVILLE LA RENAULT (76110)", value: "76110" },
  { label: "VAL DE SCIE (76720)", value: "76720" },
  { label: "VAL DE SCIE (76720)", value: "76720" },
  { label: "VAL DE SCIE (76850)", value: "76850" },
  { label: "AUMALE (76390)", value: "76390" },
  { label: "AUPPEGARD (76730)", value: "76730" },
  { label: "AUTHIEUX RATIEVILLE (76690)", value: "76690" },
  { label: "LES AUTHIEUX SUR LE PORT ST OUEN (76520)", value: "76520" },
  { label: "AUTIGNY (76740)", value: "76740" },
  { label: "LES HAUTS DE CAUX (76190)", value: "76190" },
  { label: "LES HAUTS DE CAUX (76190)", value: "76190" },
  { label: "AUVILLIERS (76270)", value: "76270" },
  { label: "AUZEBOSC (76190)", value: "76190" },
  { label: "AUZOUVILLE L ESNEVAL (76760)", value: "76760" },
  { label: "AUZOUVILLE SUR RY (76116)", value: "76116" },
  { label: "AUZOUVILLE SUR SAANE (76730)", value: "76730" },
  { label: "AVESNES EN BRAY (76220)", value: "76220" },
  { label: "AVESNES EN VAL (76630)", value: "76630" },
  { label: "AVREMESNIL (76730)", value: "76730" },
  { label: "BACQUEVILLE EN CAUX (76730)", value: "76730" },
  { label: "BAILLEUL NEUVILLE (76660)", value: "76660" },
  { label: "BAILLOLET (76660)", value: "76660" },
  { label: "BAILLY EN RIVIERE (76630)", value: "76630" },
  { label: "BAONS LE COMTE (76190)", value: "76190" },
  { label: "BARDOUVILLE (76480)", value: "76480" },
  { label: "BARENTIN (76360)", value: "76360" },
  { label: "BAROMESNIL (76260)", value: "76260" },
  { label: "BAZINVAL (76340)", value: "76340" },
  { label: "BEAUBEC LA ROSIERE (76440)", value: "76440" },
  { label: "BEAUMONT LE HARENG (76850)", value: "76850" },
  { label: "BEAUVAL EN CAUX (76890)", value: "76890" },
  { label: "BEAUREPAIRE (76280)", value: "76280" },
  { label: "BEAUSSAULT (76870)", value: "76870" },
  { label: "BEAUTOT (76890)", value: "76890" },
  { label: "BEAUVOIR EN LYONS (76220)", value: "76220" },
  { label: "BEC DE MORTAGNE (76110)", value: "76110" },
  { label: "BELBEUF (76240)", value: "76240" },
  { label: "BELLENCOMBRE (76680)", value: "76680" },
  { label: "BELLENGREVILLE (76630)", value: "76630" },
  { label: "BELLEVILLE EN CAUX (76890)", value: "76890" },
  { label: "LA BELLIERE (76440)", value: "76440" },
  { label: "BELMESNIL (76590)", value: "76590" },
  { label: "BENARVILLE (76110)", value: "76110" },
  { label: "BENESVILLE (76560)", value: "76560" },
  { label: "BENOUVILLE (76790)", value: "76790" },
  { label: "BERNIERES (76210)", value: "76210" },
  { label: "BERTHEAUVILLE (76450)", value: "76450" },
  { label: "BERTREVILLE (76450)", value: "76450" },
  { label: "BERTREVILLE ST OUEN (76590)", value: "76590" },
  { label: "BERTRIMONT (76890)", value: "76890" },
  { label: "BERVILLE EN CAUX (76560)", value: "76560" },
  { label: "BERVILLE SUR SEINE (76480)", value: "76480" },
  { label: "BEUZEVILLE LA GRENIER (76210)", value: "76210" },
  { label: "BEUZEVILLE LA GUERARD (76450)", value: "76450" },
  { label: "BEUZEVILLETTE (76210)", value: "76210" },
  { label: "BEZANCOURT (76220)", value: "76220" },
  { label: "BIERVILLE (76750)", value: "76750" },
  { label: "BIHOREL (76420)", value: "76420" },
  { label: "BIVILLE LA BAIGNARDE (76890)", value: "76890" },
  { label: "BIVILLE LA RIVIERE (76730)", value: "76730" },
  { label: "BLACQUEVILLE (76190)", value: "76190" },
  { label: "BLAINVILLE CREVON (76116)", value: "76116" },
  { label: "BLANGY SUR BRESLE (76340)", value: "76340" },
  { label: "BONSECOURS (76240)", value: "76240" },
  { label: "BLOSSEVILLE (76460)", value: "76460" },
  { label: "LE BOCASSE (76690)", value: "76690" },
  { label: "BOIS D ENNEBOURG (76160)", value: "76160" },
  { label: "BOIS GUILBERT (76750)", value: "76750" },
  { label: "BOIS GUILLAUME (76230)", value: "76230" },
  { label: "BOIS HEROULT (76750)", value: "76750" },
  { label: "BOIS HIMONT (76190)", value: "76190" },
  { label: "BOIS L EVEQUE (76160)", value: "76160" },
  { label: "LE BOIS ROBERT (76590)", value: "76590" },
  { label: "BOISSAY (76750)", value: "76750" },
  { label: "BOLBEC (76210)", value: "76210" },
  { label: "BOLLEVILLE (76210)", value: "76210" },
  { label: "BOOS (76520)", value: "76520" },
  { label: "BORDEAUX ST CLAIR (76790)", value: "76790" },
  { label: "BORNAMBUSC (76110)", value: "76110" },
  { label: "BOSC BERENGER (76680)", value: "76680" },
  { label: "BOSC BORDEL (76750)", value: "76750" },
  { label: "BOSC EDELINE (76750)", value: "76750" },
  { label: "CALLENGEVILLE (76270)", value: "76270" },
  { label: "CALLENGEVILLE (76270)", value: "76270" },
  { label: "BOSC GUERARD ST ADRIEN (76710)", value: "76710" },
  { label: "BOSC HYONS (76220)", value: "76220" },
  { label: "BOSC LE HARD (76850)", value: "76850" },
  { label: "BOSC MESNIL (76680)", value: "76680" },
  { label: "BOSVILLE (76450)", value: "76450" },
  { label: "BOUDEVILLE (76560)", value: "76560" },
  { label: "BOUELLES (76270)", value: "76270" },
  { label: "LA BOUILLE (76530)", value: "76530" },
  { label: "BOURDAINVILLE (76760)", value: "76760" },
  { label: "LE BOURG DUN (76740)", value: "76740" },
  { label: "BOURVILLE (76740)", value: "76740" },
  { label: "BOUVILLE (76360)", value: "76360" },
  { label: "BRACHY (76730)", value: "76730" },
  { label: "BRACQUETUIT (76850)", value: "76850" },
  { label: "BRADIANCOURT (76680)", value: "76680" },
  { label: "BRAMETOT (76740)", value: "76740" },
  { label: "BREAUTE (76110)", value: "76110" },
  { label: "BREMONTIER MERVAL (76220)", value: "76220" },
  { label: "BRETTEVILLE DU GRAND CAUX (76110)", value: "76110" },
  { label: "BRETTEVILLE ST LAURENT (76560)", value: "76560" },
  { label: "BUCHY (76750)", value: "76750" },
  { label: "BUCHY (76750)", value: "76750" },
  { label: "BUCHY (76750)", value: "76750" },
  { label: "BULLY (76270)", value: "76270" },
  { label: "BURES EN BRAY (76660)", value: "76660" },
  { label: "BUTOT (76890)", value: "76890" },
  { label: "CAILLEVILLE (76460)", value: "76460" },
  { label: "CAILLY (76690)", value: "76690" },
  { label: "CALLEVILLE LES DEUX EGLISES (76890)", value: "76890" },
  { label: "CAMPNEUSEVILLE (76340)", value: "76340" },
  { label: "CANEHAN (76260)", value: "76260" },
  { label: "CANOUVILLE (76450)", value: "76450" },
  { label: "CANTELEU (76380)", value: "76380" },
  { label: "CANTELEU (76380)", value: "76380" },
  { label: "CANTELEU (76380)", value: "76380" },
  { label: "CANVILLE LES DEUX EGLISES (76560)", value: "76560" },
  { label: "CANY BARVILLE (76450)", value: "76450" },
  { label: "CARVILLE LA FOLLETIERE (76190)", value: "76190" },
  { label: "CARVILLE POT DE FER (76560)", value: "76560" },
  { label: "LE CATELIER (76590)", value: "76590" },
  { label: "CATENAY (76116)", value: "76116" },
  { label: "RIVES EN SEINE (76490)", value: "76490" },
  { label: "RIVES EN SEINE (76490)", value: "76490" },
  { label: "RIVES EN SEINE (76490)", value: "76490" },
  { label: "CAUDEBEC LES ELBEUF (76320)", value: "76320" },
  { label: "LE CAULE STE BEUVE (76390)", value: "76390" },
  { label: "CAUVILLE SUR MER (76930)", value: "76930" },
  { label: "LES CENT ACRES (76590)", value: "76590" },
  { label: "LA CERLANGUE (76430)", value: "76430" },
  { label: "LA CHAPELLE DU BOURGAY (76590)", value: "76590" },
  { label: "LA CHAPELLE ST OUEN (76780)", value: "76780" },
  { label: "LA CHAPELLE SUR DUN (76740)", value: "76740" },
  { label: "LA CHAUSSEE (76590)", value: "76590" },
  { label: "CIDEVILLE (76570)", value: "76570" },
  { label: "CLAIS (76660)", value: "76660" },
  { label: "CLASVILLE (76450)", value: "76450" },
  { label: "CLAVILLE MOTTEVILLE (76690)", value: "76690" },
  { label: "CLEON (76410)", value: "76410" },
  { label: "CLERES (76690)", value: "76690" },
  { label: "CLEUVILLE (76450)", value: "76450" },
  { label: "CLEVILLE (76640)", value: "76640" },
  { label: "CLIPONVILLE (76640)", value: "76640" },
  { label: "COLLEVILLE (76400)", value: "76400" },
  { label: "COLMESNIL MANNEVILLE (76550)", value: "76550" },
  { label: "COMPAINVILLE (76440)", value: "76440" },
  { label: "CONTEVILLE (76390)", value: "76390" },
  { label: "CONTREMOULINS (76400)", value: "76400" },
  { label: "COTTEVRARD (76850)", value: "76850" },
  { label: "CRASVILLE LA MALLET (76450)", value: "76450" },
  { label: "CRASVILLE LA ROCQUEFORT (76740)", value: "76740" },
  { label: "CRIEL SUR MER (76910)", value: "76910" },
  { label: "CRIEL SUR MER (76910)", value: "76910" },
  { label: "LA CRIQUE (76850)", value: "76850" },
  { label: "CRIQUEBEUF EN CAUX (76111)", value: "76111" },
  { label: "CRIQUETOT LE MAUCONDUIT (76540)", value: "76540" },
  { label: "CRIQUETOT L ESNEVAL (76280)", value: "76280" },
  { label: "CRIQUETOT SUR LONGUEVILLE (76590)", value: "76590" },
  { label: "CRIQUETOT SUR OUVILLE (76760)", value: "76760" },
  { label: "CRIQUIERS (76390)", value: "76390" },
  { label: "CRITOT (76680)", value: "76680" },
  { label: "CROISY SUR ANDELLE (76780)", value: "76780" },
  { label: "CROIXDALLE (76660)", value: "76660" },
  { label: "CROIX MARE (76190)", value: "76190" },
  { label: "CROPUS (76720)", value: "76720" },
  { label: "CROSVILLE SUR SCIE (76590)", value: "76590" },
  { label: "CUVERVILLE (76280)", value: "76280" },
  { label: "CUVERVILLE SUR YERES (76260)", value: "76260" },
  { label: "CUY ST FIACRE (76220)", value: "76220" },
  { label: "DAMPIERRE EN BRAY (76220)", value: "76220" },
  { label: "DAMPIERRE ST NICOLAS (76510)", value: "76510" },
  { label: "DANCOURT (76340)", value: "76340" },
  { label: "DARNETAL (76160)", value: "76160" },
  { label: "DAUBEUF SERVILLE (76110)", value: "76110" },
  { label: "DENESTANVILLE (76590)", value: "76590" },
  { label: "DEVILLE LES ROUEN (76250)", value: "76250" },
  { label: "DIEPPE (76200)", value: "76200" },
  { label: "DIEPPE (76370)", value: "76370" },
  { label: "DIEPPE (76370)", value: "76370" },
  { label: "DOUDEAUVILLE (76220)", value: "76220" },
  { label: "DOUDEVILLE (76560)", value: "76560" },
  { label: "DOUVREND (76630)", value: "76630" },
  { label: "DROSAY (76460)", value: "76460" },
  { label: "DUCLAIR (76480)", value: "76480" },
  { label: "ECALLES ALIX (76190)", value: "76190" },
  { label: "ECRAINVILLE (76110)", value: "76110" },
  { label: "ECRETTEVILLE LES BAONS (76190)", value: "76190" },
  { label: "ECRETTEVILLE SUR MER (76540)", value: "76540" },
  { label: "ECTOT L AUBER (76760)", value: "76760" },
  { label: "ECTOT LES BAONS (76970)", value: "76970" },
  { label: "ELBEUF EN BRAY (76220)", value: "76220" },
  { label: "ELBEUF SUR ANDELLE (76780)", value: "76780" },
  { label: "ELBEUF (76500)", value: "76500" },
  { label: "ELETOT (76540)", value: "76540" },
  { label: "ELLECOURT (76390)", value: "76390" },
  { label: "EMANVILLE (76570)", value: "76570" },
  { label: "ENVERMEU (76630)", value: "76630" },
  { label: "ENVRONVILLE (76640)", value: "76640" },
  { label: "EPINAY SUR DUCLAIR (76480)", value: "76480" },
  { label: "EPOUVILLE (76133)", value: "76133" },
  { label: "EPRETOT (76430)", value: "76430" },
  { label: "EPREVILLE (76400)", value: "76400" },
  { label: "ERMENOUVILLE (76740)", value: "76740" },
  { label: "ERNEMONT LA VILLETTE (76220)", value: "76220" },
  { label: "ERNEMONT SUR BUCHY (76750)", value: "76750" },
  { label: "ESCLAVELLES (76270)", value: "76270" },
  { label: "ESLETTES (76710)", value: "76710" },
  { label: "ESTEVILLE (76690)", value: "76690" },
  { label: "ETAIMPUIS (76850)", value: "76850" },
  { label: "ETAINHUS (76430)", value: "76430" },
  { label: "ETALLEVILLE (76560)", value: "76560" },
  { label: "ETALONDES (76260)", value: "76260" },
  { label: "ETOUTTEVILLE (76190)", value: "76190" },
  { label: "ETRETAT (76790)", value: "76790" },
  { label: "EU (76260)", value: "76260" },
  { label: "FALLENCOURT (76340)", value: "76340" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "TERRES DE CAUX (76640)", value: "76640" },
  { label: "FECAMP (76400)", value: "76400" },
  { label: "FERRIERES EN BRAY (76220)", value: "76220" },
  { label: "LA FERTE ST SAMSON (76440)", value: "76440" },
  { label: "FESQUES (76270)", value: "76270" },
  { label: "LA FEUILLIE (76220)", value: "76220" },
  { label: "FLAMANVILLE (76970)", value: "76970" },
  { label: "FLAMETS FRETILS (76270)", value: "76270" },
  { label: "FLOCQUES (76260)", value: "76260" },
  { label: "FONGUEUSEMARE (76280)", value: "76280" },
  { label: "FONTAINE EN BRAY (76440)", value: "76440" },
  { label: "FONTAINE LA MALLET (76290)", value: "76290" },
  { label: "FONTAINE LE BOURG (76690)", value: "76690" },
  { label: "FONTAINE LE DUN (76740)", value: "76740" },
  { label: "FONTAINE SOUS PREAUX (76160)", value: "76160" },
  { label: "LA FONTELAYE (76890)", value: "76890" },
  { label: "FONTENAY (76290)", value: "76290" },
  { label: "FORGES LES EAUX (76440)", value: "76440" },
  { label: "FORGES LES EAUX (76440)", value: "76440" },
  { label: "FOUCARMONT (76340)", value: "76340" },
  { label: "FOUCART (76640)", value: "76640" },
  { label: "FREAUVILLE (76660)", value: "76660" },
  { label: "LA FRENAYE (76170)", value: "76170" },
  { label: "FRENEUSE (76410)", value: "76410" },
  { label: "FRESLES (76270)", value: "76270" },
  { label: "FRESNAY LE LONG (76850)", value: "76850" },
  { label: "FRESNE LE PLAN (76520)", value: "76520" },
  { label: "FRESNOY FOLNY (76660)", value: "76660" },
  { label: "FRESQUIENNES (76570)", value: "76570" },
  { label: "FREULLEVILLE (76510)", value: "76510" },
  { label: "ST MARTIN DE L IF (76190)", value: "76190" },
  { label: "ST MARTIN DE L IF (76190)", value: "76190" },
  { label: "ST MARTIN DE L IF (76190)", value: "76190" },
  { label: "ST MARTIN DE L IF (76190)", value: "76190" },
  { label: "FRICHEMESNIL (76690)", value: "76690" },
  { label: "FROBERVILLE (76400)", value: "76400" },
  { label: "FRY (76780)", value: "76780" },
  { label: "FULTOT (76560)", value: "76560" },
  { label: "LA GAILLARDE (76740)", value: "76740" },
  { label: "GAILLEFONTAINE (76870)", value: "76870" },
  { label: "GAINNEVILLE (76700)", value: "76700" },
  { label: "GANCOURT ST ETIENNE (76220)", value: "76220" },
  { label: "GANZEVILLE (76400)", value: "76400" },
  { label: "GERPONVILLE (76540)", value: "76540" },
  { label: "GERVILLE (76790)", value: "76790" },
  { label: "GODERVILLE (76110)", value: "76110" },
  { label: "GOMMERVILLE (76430)", value: "76430" },
  { label: "GONFREVILLE CAILLOT (76110)", value: "76110" },
  { label: "GONFREVILLE L ORCHER (76700)", value: "76700" },
  { label: "GONFREVILLE L ORCHER (76700)", value: "76700" },
  { label: "GONFREVILLE L ORCHER (76700)", value: "76700" },
  { label: "GONNETOT (76730)", value: "76730" },
  { label: "GONNEVILLE LA MALLET (76280)", value: "76280" },
  { label: "GONNEVILLE SUR SCIE (76590)", value: "76590" },
  { label: "GONZEVILLE (76560)", value: "76560" },
  { label: "GOUPILLIERES (76570)", value: "76570" },
  { label: "GOURNAY EN BRAY (76220)", value: "76220" },
  { label: "GOUY (76520)", value: "76520" },
  { label: "GRAIMBOUVILLE (76430)", value: "76430" },
  { label: "GRAINVILLE LA TEINTURIERE (76450)", value: "76450" },
  { label: "GRAINVILLE SUR RY (76116)", value: "76116" },
  { label: "GRAINVILLE YMAUVILLE (76110)", value: "76110" },
  { label: "GRAND CAMP (76170)", value: "76170" },
  { label: "GRAND COURONNE (76530)", value: "76530" },
  { label: "GRANDCOURT (76660)", value: "76660" },
  { label: "LES GRANDES VENTES (76950)", value: "76950" },
  { label: "LE GRAND QUEVILLY (76120)", value: "76120" },
  { label: "GRAVAL (76270)", value: "76270" },
  { label: "GREGES (76370)", value: "76370" },
  { label: "GREMONVILLE (76970)", value: "76970" },
  { label: "GREUVILLE (76810)", value: "76810" },
  { label: "GRIGNEUSEVILLE (76850)", value: "76850" },
  { label: "GRUCHET LE VALASSE (76210)", value: "76210" },
  { label: "GRUCHET ST SIMEON (76810)", value: "76810" },
  { label: "GRUGNY (76690)", value: "76690" },
  { label: "GRUMESNIL (76440)", value: "76440" },
  { label: "GUERVILLE (76340)", value: "76340" },
  { label: "GUEURES (76730)", value: "76730" },
  { label: "GUEUTTEVILLE (76890)", value: "76890" },
  { label: "GUEUTTEVILLE LES GRES (76460)", value: "76460" },
  { label: "LA HALLOTIERE (76780)", value: "76780" },
  { label: "LE HANOUARD (76450)", value: "76450" },
  { label: "HARCANVILLE (76560)", value: "76560" },
  { label: "HARFLEUR (76700)", value: "76700" },
  { label: "HATTENVILLE (76640)", value: "76640" },
  { label: "HAUCOURT (76440)", value: "76440" },
  { label: "HAUDRICOURT (76390)", value: "76390" },
  { label: "HAUSSEZ (76440)", value: "76440" },
  { label: "HAUTOT L AUVRAY (76450)", value: "76450" },
  { label: "HAUTOT LE VATOIS (76190)", value: "76190" },
  { label: "HAUTOT ST SULPICE (76190)", value: "76190" },
  { label: "HAUTOT SUR MER (76550)", value: "76550" },
  { label: "HAUTOT SUR MER (76550)", value: "76550" },
  { label: "HAUTOT SUR SEINE (76113)", value: "76113" },
  { label: "LE HAVRE (76600)", value: "76600" },
  { label: "LE HAVRE (76610)", value: "76610" },
  { label: "LE HAVRE (76610)", value: "76610" },
  { label: "LE HAVRE (76620)", value: "76620" },
  { label: "LA HAYE (76780)", value: "76780" },
  { label: "HEBERVILLE (76740)", value: "76740" },
  { label: "HENOUVILLE (76840)", value: "76840" },
  { label: "HERICOURT EN CAUX (76560)", value: "76560" },
  { label: "HERMANVILLE (76730)", value: "76730" },
  { label: "HERMEVILLE (76280)", value: "76280" },
  { label: "LE HERON (76780)", value: "76780" },
  { label: "HERONCHELLES (76750)", value: "76750" },
  { label: "HEUGLEVILLE SUR SCIE (76720)", value: "76720" },
  { label: "HEUQUEVILLE (76280)", value: "76280" },
  { label: "HEURTEAUVILLE (76940)", value: "76940" },
  { label: "HODENG AU BOSC (76340)", value: "76340" },
  { label: "HODENG HODENGER (76780)", value: "76780" },
  { label: "HOUDETOT (76740)", value: "76740" },
  { label: "LE HOULME (76770)", value: "76770" },
  { label: "HOUPPEVILLE (76770)", value: "76770" },
  { label: "HOUQUETOT (76110)", value: "76110" },
  { label: "LA HOUSSAYE BERANGER (76690)", value: "76690" },
  { label: "HUGLEVILLE EN CAUX (76570)", value: "76570" },
  { label: "LES IFS (76630)", value: "76630" },
  { label: "ILLOIS (76390)", value: "76390" },
  { label: "IMBLEVILLE (76890)", value: "76890" },
  { label: "INCHEVILLE (76117)", value: "76117" },
  { label: "INGOUVILLE (76460)", value: "76460" },
  { label: "ISNEAUVILLE (76230)", value: "76230" },
  { label: "JUMIEGES (76480)", value: "76480" },
  { label: "LAMBERVILLE (76730)", value: "76730" },
  { label: "LAMMERVILLE (76730)", value: "76730" },
  { label: "LANDES VIEILLES ET NEUVES (76390)", value: "76390" },
  { label: "LANQUETOT (76210)", value: "76210" },
  { label: "LESTANVILLE (76730)", value: "76730" },
  { label: "LILLEBONNE (76170)", value: "76170" },
  { label: "LIMESY (76570)", value: "76570" },
  { label: "LIMPIVILLE (76540)", value: "76540" },
  { label: "LINDEBEUF (76760)", value: "76760" },
  { label: "LINTOT (76210)", value: "76210" },
  { label: "LINTOT LES BOIS (76590)", value: "76590" },
  { label: "LES LOGES (76790)", value: "76790" },
  { label: "LA LONDE (76500)", value: "76500" },
  { label: "LONDINIERES (76660)", value: "76660" },
  { label: "LONGMESNIL (76440)", value: "76440" },
  { label: "LONGROY (76260)", value: "76260" },
  { label: "LONGUEIL (76860)", value: "76860" },
  { label: "LONGUERUE (76750)", value: "76750" },
  { label: "LONGUEVILLE SUR SCIE (76590)", value: "76590" },
  { label: "LOUVETOT (76490)", value: "76490" },
  { label: "LUCY (76270)", value: "76270" },
  { label: "LUNERAY (76810)", value: "76810" },
  { label: "ARELAUNE EN SEINE (76940)", value: "76940" },
  { label: "ARELAUNE EN SEINE (76940)", value: "76940" },
  { label: "MALAUNAY (76770)", value: "76770" },
  { label: "MALLEVILLE LES GRES (76450)", value: "76450" },
  { label: "MANEGLISE (76133)", value: "76133" },
  { label: "MANEHOUVILLE (76590)", value: "76590" },
  { label: "MANIQUERVILLE (76400)", value: "76400" },
  { label: "MANNEVILLE ES PLAINS (76460)", value: "76460" },
  { label: "MANNEVILLE LA GOUPIL (76110)", value: "76110" },
  { label: "MANNEVILLETTE (76290)", value: "76290" },
  { label: "MAROMME (76150)", value: "76150" },
  { label: "MAROMME (76150)", value: "76150" },
  { label: "MARQUES (76390)", value: "76390" },
  { label: "MARTAINVILLE EPREVILLE (76116)", value: "76116" },
  { label: "MARTIGNY (76880)", value: "76880" },
  { label: "MARTIN EGLISE (76370)", value: "76370" },
  { label: "MASSY (76270)", value: "76270" },
  { label: "MATHONVILLE (76680)", value: "76680" },
  { label: "MAUCOMBLE (76680)", value: "76680" },
  { label: "MAULEVRIER STE GERTRUDE (76490)", value: "76490" },
  { label: "MAUNY (76530)", value: "76530" },
  { label: "MAUQUENCHY (76440)", value: "76440" },
  { label: "MELAMARE (76170)", value: "76170" },
  { label: "MELLEVILLE (76260)", value: "76260" },
  { label: "MENERVAL (76220)", value: "76220" },
  { label: "MENONVAL (76270)", value: "76270" },
  { label: "MENTHEVILLE (76110)", value: "76110" },
  { label: "MESANGUEVILLE (76780)", value: "76780" },
  { label: "MESNIERES EN BRAY (76270)", value: "76270" },
  { label: "LE MESNIL DURDENT (76460)", value: "76460" },
  { label: "LE MESNIL ESNARD (76240)", value: "76240" },
  { label: "MESNIL FOLLEMPRISE (76660)", value: "76660" },
  { label: "LE MESNIL LIEUBRAY (76780)", value: "76780" },
  { label: "MESNIL MAUGER (76440)", value: "76440" },
  { label: "MESNIL PANNEVILLE (76570)", value: "76570" },
  { label: "MESNIL RAOUL (76520)", value: "76520" },
  { label: "LE MESNIL REAUME (76260)", value: "76260" },
  { label: "LE MESNIL SOUS JUMIEGES (76480)", value: "76480" },
  { label: "MEULERS (76510)", value: "76510" },
  { label: "MILLEBOSC (76260)", value: "76260" },
  { label: "MIRVILLE (76210)", value: "76210" },
  { label: "MOLAGNIES (76220)", value: "76220" },
  { label: "MONCHAUX SORENG (76340)", value: "76340" },
  { label: "MONCHY SUR EU (76260)", value: "76260" },
  { label: "MONT CAUVAIRE (76690)", value: "76690" },
  { label: "MONTEROLIER (76680)", value: "76680" },
  { label: "MONTIGNY (76380)", value: "76380" },
  { label: "MONTIVILLIERS (76290)", value: "76290" },
  { label: "MONTMAIN (76520)", value: "76520" },
  { label: "MONTREUIL EN CAUX (76850)", value: "76850" },
  { label: "MONTROTY (76220)", value: "76220" },
  { label: "MONT ST AIGNAN (76130)", value: "76130" },
  { label: "MONTVILLE (76710)", value: "76710" },
  { label: "MORGNY LA POMMERAYE (76750)", value: "76750" },
  { label: "MORTEMER (76270)", value: "76270" },
  { label: "MORVILLE SUR ANDELLE (76780)", value: "76780" },
  { label: "MOTTEVILLE (76970)", value: "76970" },
  { label: "MOULINEAUX (76530)", value: "76530" },
  { label: "MUCHEDENT (76590)", value: "76590" },
  { label: "NESLE HODENG (76270)", value: "76270" },
  { label: "NESLE NORMANDEUSE (76340)", value: "76340" },
  { label: "NEUFBOSC (76680)", value: "76680" },
  { label: "NEUFCHATEL EN BRAY (76270)", value: "76270" },
  { label: "NEUF MARCHE (76220)", value: "76220" },
  { label: "LA NEUVILLE CHANT D OISEL (76520)", value: "76520" },
  { label: "NEUVILLE FERRIERES (76270)", value: "76270" },
  { label: "NEVILLE (76460)", value: "76460" },
  { label: "NOINTOT (76210)", value: "76210" },
  { label: "NOLLEVAL (76780)", value: "76780" },
  { label: "NORMANVILLE (76640)", value: "76640" },
  { label: "NORVILLE (76330)", value: "76330" },
  { label: "NOTRE DAME D ALIERMONT (76510)", value: "76510" },
  { label: "NOTRE DAME DE BLIQUETUIT (76940)", value: "76940" },
  { label: "NOTRE DAME DE BONDEVILLE (76960)", value: "76960" },
  { label: "FRANQUEVILLE ST PIERRE (76520)", value: "76520" },
  { label: "PORT JEROME SUR SEINE (76170)", value: "76170" },
  { label: "PORT JEROME SUR SEINE (76170)", value: "76170" },
  { label: "PORT JEROME SUR SEINE (76170)", value: "76170" },
  { label: "PORT JEROME SUR SEINE (76330)", value: "76330" },
  { label: "NOTRE DAME DU BEC (76133)", value: "76133" },
  { label: "NOTRE DAME DU PARC (76590)", value: "76590" },
  { label: "NULLEMONT (76390)", value: "76390" },
  { label: "OCQUEVILLE (76450)", value: "76450" },
  { label: "OCTEVILLE SUR MER (76930)", value: "76930" },
  { label: "OFFRANVILLE (76550)", value: "76550" },
  { label: "OHERVILLE (76560)", value: "76560" },
  { label: "OISSEL (76350)", value: "76350" },
  { label: "OMONVILLE (76730)", value: "76730" },
  { label: "ORIVAL (76500)", value: "76500" },
  { label: "OSMOY ST VALERY (76660)", value: "76660" },
  { label: "OUAINVILLE (76450)", value: "76450" },
  { label: "OUDALLE (76430)", value: "76430" },
  { label: "OURVILLE EN CAUX (76450)", value: "76450" },
  { label: "OUVILLE L ABBAYE (76760)", value: "76760" },
  { label: "OUVILLE LA RIVIERE (76860)", value: "76860" },
  { label: "PALUEL (76450)", value: "76450" },
  { label: "PARC D ANXTOT (76210)", value: "76210" },
  { label: "PAVILLY (76570)", value: "76570" },
  { label: "PETIT COURONNE (76650)", value: "76650" },
  { label: "LE PETIT QUEVILLY (76140)", value: "76140" },
  { label: "PETIVILLE (76330)", value: "76330" },
  { label: "PIERRECOURT (76340)", value: "76340" },
  { label: "PIERREFIQUES (76280)", value: "76280" },
  { label: "PIERREVAL (76750)", value: "76750" },
  { label: "PISSY POVILLE (76360)", value: "76360" },
  { label: "PLEINE SEVE (76460)", value: "76460" },
  { label: "POMMEREUX (76440)", value: "76440" },
  { label: "POMMEREVAL (76680)", value: "76680" },
  { label: "PONTS ET MARAIS (76260)", value: "76260" },
  { label: "LA POTERIE CAP D ANTIFER (76280)", value: "76280" },
  { label: "PREAUX (76160)", value: "76160" },
  { label: "PRETOT VICQUEMARE (76560)", value: "76560" },
  { label: "PREUSEVILLE (76660)", value: "76660" },
  { label: "PUISENVAL (76660)", value: "76660" },
  { label: "QUEVILLON (76840)", value: "76840" },
  { label: "QUEVREVILLE LA POTERIE (76520)", value: "76520" },
  { label: "QUIBERVILLE SUR MER (76860)", value: "76860" },
  { label: "QUIEVRECOURT (76270)", value: "76270" },
  { label: "QUINCAMPOIX (76230)", value: "76230" },
  { label: "RAFFETOT (76210)", value: "76210" },
  { label: "RAINFREVILLE (76730)", value: "76730" },
  { label: "REALCAMP (76340)", value: "76340" },
  { label: "REBETS (76750)", value: "76750" },
  { label: "LA REMUEE (76430)", value: "76430" },
  { label: "RETONVAL (76340)", value: "76340" },
  { label: "REUVILLE (76560)", value: "76560" },
  { label: "RICARVILLE DU VAL (76510)", value: "76510" },
  { label: "RICHEMONT (76390)", value: "76390" },
  { label: "RIEUX (76340)", value: "76340" },
  { label: "RIVILLE (76540)", value: "76540" },
  { label: "ROBERTOT (76560)", value: "76560" },
  { label: "ROCQUEFORT (76640)", value: "76640" },
  { label: "ROCQUEMONT (76680)", value: "76680" },
  { label: "ROGERVILLE (76700)", value: "76700" },
  { label: "ROLLEVILLE (76133)", value: "76133" },
  { label: "RONCHEROLLES EN BRAY (76440)", value: "76440" },
  { label: "RONCHEROLLES SUR LE VIVIER (76160)", value: "76160" },
  { label: "RONCHOIS (76390)", value: "76390" },
  { label: "ROSAY (76680)", value: "76680" },
  { label: "ROUEN (76000)", value: "76000" },
  { label: "ROUEN (76100)", value: "76100" },
  { label: "ROUMARE (76480)", value: "76480" },
  { label: "ROUTES (76560)", value: "76560" },
  { label: "ROUVILLE (76210)", value: "76210" },
  { label: "ROUVRAY CATILLON (76440)", value: "76440" },
  { label: "ROUXMESNIL BOUTEILLES (76370)", value: "76370" },
  { label: "ROYVILLE (76730)", value: "76730" },
  { label: "LA RUE ST PIERRE (76690)", value: "76690" },
  { label: "RY (76116)", value: "76116" },
  { label: "SAANE ST JUST (76730)", value: "76730" },
  { label: "SAHURS (76113)", value: "76113" },
  { label: "SAINNEVILLE (76430)", value: "76430" },
  { label: "STE ADRESSE (76310)", value: "76310" },
  { label: "STE AGATHE D ALIERMONT (76660)", value: "76660" },
  { label: "ST AIGNAN SUR RY (76116)", value: "76116" },
  { label: "ST ANDRE SUR CAILLY (76690)", value: "76690" },
  { label: "ST ANTOINE LA FORET (76170)", value: "76170" },
  { label: "ST ARNOULT (76490)", value: "76490" },
  { label: "ST AUBIN CELLOVILLE (76520)", value: "76520" },
  { label: "ST AUBIN DE CRETOT (76190)", value: "76190" },
  { label: "ST AUBIN EPINAY (76160)", value: "76160" },
  { label: "ST AUBIN LES ELBEUF (76410)", value: "76410" },
  { label: "ST AUBIN LE CAUF (76510)", value: "76510" },
  { label: "ST AUBIN ROUTOT (76430)", value: "76430" },
  { label: "ST AUBIN SUR MER (76740)", value: "76740" },
  { label: "ST AUBIN SUR SCIE (76550)", value: "76550" },
  { label: "STE AUSTREBERTHE (76570)", value: "76570" },
  { label: "STE BEUVE EN RIVIERE (76270)", value: "76270" },
  { label: "ST CLAIR SUR LES MONTS (76190)", value: "76190" },
  { label: "STE COLOMBE (76460)", value: "76460" },
  { label: "ST CRESPIN (76590)", value: "76590" },
  { label: "STE CROIX SUR BUCHY (76750)", value: "76750" },
  { label: "ST DENIS D ACLON (76860)", value: "76860" },
  { label: "ST DENIS LE THIBOULT (76116)", value: "76116" },
  { label: "ST DENIS SUR SCIE (76890)", value: "76890" },
  { label: "ST ETIENNE DU ROUVRAY (76800)", value: "76800" },
  { label: "ST EUSTACHE LA FORET (76210)", value: "76210" },
  { label: "STE FOY (76590)", value: "76590" },
  { label: "STE GENEVIEVE (76440)", value: "76440" },
  { label: "ST GEORGES SUR FONTAINE (76690)", value: "76690" },
  { label: "ST GERMAIN DES ESSOURTS (76750)", value: "76750" },
  { label: "ST GERMAIN D ETABLES (76590)", value: "76590" },
  { label: "ST GERMAIN SOUS CAILLY (76690)", value: "76690" },
  { label: "ST GERMAIN SUR EAULNE (76270)", value: "76270" },
  { label: "ST GILLES DE CRETOT (76490)", value: "76490" },
  { label: "ST GILLES DE LA NEUVILLE (76430)", value: "76430" },
  { label: "STE HELENE BONDEVILLE (76400)", value: "76400" },
  { label: "ST HELLIER (76680)", value: "76680" },
  { label: "ST HONORE (76590)", value: "76590" },
  { label: "ST JACQUES D ALIERMONT (76510)", value: "76510" },
  { label: "ST JACQUES SUR DARNETAL (76160)", value: "76160" },
  { label: "ST JEAN DE FOLLEVILLE (76170)", value: "76170" },
  { label: "ST JEAN DE LA NEUVILLE (76210)", value: "76210" },
  { label: "ST JEAN DU CARDONNAY (76150)", value: "76150" },
  { label: "ST JOUIN BRUNEVAL (76280)", value: "76280" },
  { label: "ST LAURENT DE BREVEDENT (76700)", value: "76700" },
  { label: "ST LAURENT EN CAUX (76560)", value: "76560" },
  { label: "ST LEGER AUX BOIS (76340)", value: "76340" },
  { label: "ST LEGER DU BOURG DENIS (76160)", value: "76160" },
  { label: "ST LEONARD (76400)", value: "76400" },
  { label: "ST LUCIEN (76780)", value: "76780" },
  { label: "ST MACLOU DE FOLLEVILLE (76890)", value: "76890" },
  { label: "ST MACLOU LA BRIERE (76110)", value: "76110" },
  { label: "ST MARDS (76730)", value: "76730" },
  { label: "STE MARGUERITE SUR MER (76119)", value: "76119" },
  { label: "MORIENNE (76390)", value: "76390" },
  { label: "STE MARGUERITE SUR DUCLAIR (76480)", value: "76480" },
  { label: "STE MARIE AU BOSC (76280)", value: "76280" },
  { label: "STE MARIE DES CHAMPS (76190)", value: "76190" },
  { label: "ST MARTIN AUX ARBRES (76760)", value: "76760" },
  { label: "ST MARTIN AU BOSC (76340)", value: "76340" },
  { label: "ST MARTIN AUX BUNEAUX (76450)", value: "76450" },
  { label: "ST MARTIN AUX BUNEAUX (76540)", value: "76540" },
  { label: "ST MARTIN DE BOSCHERVILLE (76840)", value: "76840" },
  { label: "ST MARTIN DU BEC (76133)", value: "76133" },
  { label: "ST MARTIN DU MANOIR (76290)", value: "76290" },
  { label: "ST MARTIN DU VIVIER (76160)", value: "76160" },
  { label: "PETIT CAUX (76370)", value: "76370" },
  { label: "PETIT CAUX (76370)", value: "76370" },
  { label: "PETIT CAUX (76370)", value: "76370" },
  { label: "PETIT CAUX (76370)", value: "76370" },
  { label: "PETIT CAUX (76370)", value: "76370" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76630)", value: "76630" },
  { label: "PETIT CAUX (76910)", value: "76910" },
  { label: "ST MARTIN LE GAILLARD (76260)", value: "76260" },
  { label: "ST MARTIN L HORTIER (76270)", value: "76270" },
  { label: "ST MARTIN OSMONVILLE (76680)", value: "76680" },
  { label: "ST MAURICE D ETELAN (76330)", value: "76330" },
  { label: "ST MICHEL D HALESCOURT (76440)", value: "76440" },
  { label: "ST NICOLAS D ALIERMONT (76510)", value: "76510" },
  { label: "ST NICOLAS DE LA HAIE (76490)", value: "76490" },
  { label: "ST NICOLAS DE LA TAILLE (76170)", value: "76170" },
  { label: "ST OUEN DU BREUIL (76890)", value: "76890" },
  { label: "ST OUEN LE MAUGER (76730)", value: "76730" },
  { label: "ST OUEN SOUS BAILLY (76630)", value: "76630" },
  { label: "ST PAER (76480)", value: "76480" },
  { label: "ST PIERRE BENOUVILLE (76890)", value: "76890" },
  { label: "ST PIERRE DE MANNEVILLE (76113)", value: "76113" },
  { label: "ST PIERRE DES JONQUIERES (76660)", value: "76660" },
  { label: "ST PIERRE DE VARENGEVILLE (76480)", value: "76480" },
  { label: "ST PIERRE EN PORT (76540)", value: "76540" },
  { label: "ST PIERRE EN VAL (76260)", value: "76260" },
  { label: "ST PIERRE LES ELBEUF (76320)", value: "76320" },
  { label: "ST PIERRE LE VIEUX (76740)", value: "76740" },
  { label: "ST PIERRE LE VIGER (76740)", value: "76740" },
  { label: "ST REMY BOSCROCOURT (76260)", value: "76260" },
  { label: "ST RIQUIER EN RIVIERE (76340)", value: "76340" },
  { label: "ST RIQUIER ES PLAINS (76460)", value: "76460" },
  { label: "ST ROMAIN DE COLBOSC (76430)", value: "76430" },
  { label: "ST SAENS (76680)", value: "76680" },
  { label: "ST SAIRE (76270)", value: "76270" },
  { label: "ST SAUVEUR D EMALLEVILLE (76110)", value: "76110" },
  { label: "ST SYLVAIN (76460)", value: "76460" },
  { label: "ST VAAST D EQUIQUEVILLE (76510)", value: "76510" },
  { label: "ST VAAST DIEPPEDALLE (76450)", value: "76450" },
  { label: "ST VAAST DU VAL (76890)", value: "76890" },
  { label: "ST VALERY EN CAUX (76460)", value: "76460" },
  { label: "ST VICTOR L ABBAYE (76890)", value: "76890" },
  { label: "ST VIGOR D YMONVILLE (76430)", value: "76430" },
  { label: "ST VINCENT CRAMESNIL (76430)", value: "76430" },
  { label: "SANDOUVILLE (76430)", value: "76430" },
  { label: "SASSETOT LE MALGARDE (76730)", value: "76730" },
  { label: "SASSETOT LE MAUCONDUIT (76540)", value: "76540" },
  { label: "SASSETOT LE MAUCONDUIT (76540)", value: "76540" },
  { label: "SASSETOT LE MAUCONDUIT (76540)", value: "76540" },
  { label: "SASSEVILLE (76450)", value: "76450" },
  { label: "SAUCHAY (76630)", value: "76630" },
  { label: "SAUMONT LA POTERIE (76440)", value: "76440" },
  { label: "SAUQUEVILLE (76550)", value: "76550" },
  { label: "SAUSSAY (76760)", value: "76760" },
  { label: "SAUSSEUZEMARE EN CAUX (76110)", value: "76110" },
  { label: "SENNEVILLE SUR FECAMP (76400)", value: "76400" },
  { label: "SEPT MEULES (76260)", value: "76260" },
  { label: "SERQUEUX (76440)", value: "76440" },
  { label: "SERVAVILLE SALMONVILLE (76116)", value: "76116" },
  { label: "SIERVILLE (76690)", value: "76690" },
  { label: "SIGY EN BRAY (76780)", value: "76780" },
  { label: "SMERMESNIL (76660)", value: "76660" },
  { label: "SOMMERY (76440)", value: "76440" },
  { label: "SOMMESNIL (76560)", value: "76560" },
  { label: "SORQUAINVILLE (76540)", value: "76540" },
  { label: "SOTTEVILLE LES ROUEN (76300)", value: "76300" },
  { label: "SOTTEVILLE SOUS LE VAL (76410)", value: "76410" },
  { label: "SOTTEVILLE SUR MER (76740)", value: "76740" },
  { label: "TANCARVILLE (76430)", value: "76430" },
  { label: "THEROULDEVILLE (76540)", value: "76540" },
  { label: "THEUVILLE AUX MAILLOTS (76540)", value: "76540" },
  { label: "THIERGEVILLE (76540)", value: "76540" },
  { label: "THIETREVILLE (76540)", value: "76540" },
  { label: "THIL MANNEVILLE (76730)", value: "76730" },
  { label: "LE THIL RIBERPRE (76440)", value: "76440" },
  { label: "THIOUVILLE (76450)", value: "76450" },
  { label: "LE TILLEUL (76790)", value: "76790" },
  { label: "TOCQUEVILLE EN CAUX (76730)", value: "76730" },
  { label: "TOCQUEVILLE LES MURS (76110)", value: "76110" },
  { label: "TORCY LE GRAND (76590)", value: "76590" },
  { label: "TORCY LE PETIT (76590)", value: "76590" },
  { label: "LE TORP MESNIL (76560)", value: "76560" },
  { label: "TOTES (76890)", value: "76890" },
  { label: "TOUFFREVILLE LA CORBELINE (76190)", value: "76190" },
  { label: "TOUFFREVILLE SUR EU (76910)", value: "76910" },
  { label: "TOURVILLE LA RIVIERE (76410)", value: "76410" },
  { label: "TOURVILLE LES IFS (76400)", value: "76400" },
  { label: "TOURVILLE SUR ARQUES (76550)", value: "76550" },
  { label: "TOUSSAINT (76400)", value: "76400" },
  { label: "LE TRAIT (76580)", value: "76580" },
  { label: "TREMAUVILLE (76640)", value: "76640" },
  { label: "LE TREPORT (76470)", value: "76470" },
  { label: "LA TRINITE DU MONT (76170)", value: "76170" },
  { label: "LES TROIS PIERRES (76430)", value: "76430" },
  { label: "TROUVILLE (76210)", value: "76210" },
  { label: "TURRETOT (76280)", value: "76280" },
  { label: "VAL DE LA HAYE (76380)", value: "76380" },
  { label: "VALLIQUERVILLE (76190)", value: "76190" },
  { label: "VALMONT (76540)", value: "76540" },
  { label: "VARENGEVILLE SUR MER (76119)", value: "76119" },
  { label: "VARNEVILLE BRETTEVILLE (76890)", value: "76890" },
  { label: "VASSONVILLE (76890)", value: "76890" },
  { label: "VATIERVILLE (76270)", value: "76270" },
  { label: "VATTETOT SOUS BEAUMONT (76110)", value: "76110" },
  { label: "VATTETOT SUR MER (76111)", value: "76111" },
  { label: "VATTEVILLE LA RUE (76940)", value: "76940" },
  { label: "LA VAUPALIERE (76150)", value: "76150" },
  { label: "VEAUVILLE LES QUELLES (76560)", value: "76560" },
  { label: "VENESTANVILLE (76730)", value: "76730" },
  { label: "BUTOT VENESVILLE (76450)", value: "76450" },
  { label: "BUTOT VENESVILLE (76450)", value: "76450" },
  { label: "VENTES ST REMY (76680)", value: "76680" },
  { label: "VERGETOT (76280)", value: "76280" },
  { label: "VEULES LES ROSES (76980)", value: "76980" },
  { label: "VEULETTES SUR MER (76450)", value: "76450" },
  { label: "VIBEUF (76760)", value: "76760" },
  { label: "VIEUX MANOIR (76750)", value: "76750" },
  { label: "VIEUX ROUEN SUR BRESLE (76390)", value: "76390" },
  { label: "LA VIEUX RUE (76160)", value: "76160" },
  { label: "VILLAINVILLE (76280)", value: "76280" },
  { label: "VILLERS ECALLES (76360)", value: "76360" },
  { label: "VILLERS SOUS FOUCARMONT (76340)", value: "76340" },
  { label: "VILLY SUR YERES (76260)", value: "76260" },
  { label: "VINNEMERVILLE (76540)", value: "76540" },
  { label: "VIRVILLE (76110)", value: "76110" },
  { label: "VITTEFLEUR (76450)", value: "76450" },
  { label: "WANCHY CAPVAL (76660)", value: "76660" },
  { label: "YAINVILLE (76480)", value: "76480" },
  { label: "YEBLERON (76640)", value: "76640" },
  { label: "YERVILLE (76760)", value: "76760" },
  { label: "YMARE (76520)", value: "76520" },
  { label: "YPORT (76111)", value: "76111" },
  { label: "YPREVILLE BIVILLE (76540)", value: "76540" },
  { label: "YPREVILLE BIVILLE (76540)", value: "76540" },
  { label: "YQUEBEUF (76690)", value: "76690" },
  { label: "YVECRIQUE (76560)", value: "76560" },
  { label: "YVETOT (76190)", value: "76190" },
  { label: "YVILLE SUR SEINE (76530)", value: "76530" },
  { label: "ACHERES LA FORET (77760)", value: "77760" },
  { label: "AMILLIS (77120)", value: "77120" },
  { label: "AMPONVILLE (77760)", value: "77760" },
  { label: "ANDREZEL (77390)", value: "77390" },
  { label: "ANNET SUR MARNE (77410)", value: "77410" },
  { label: "ARBONNE LA FORET (77630)", value: "77630" },
  { label: "ARGENTIERES (77390)", value: "77390" },
  { label: "ARMENTIERES EN BRIE (77440)", value: "77440" },
  { label: "ARVILLE (77890)", value: "77890" },
  { label: "AUBEPIERRE OZOUER LE REPOS (77720)", value: "77720" },
  { label: "AUBEPIERRE OZOUER LE REPOS (77720)", value: "77720" },
  { label: "AUFFERVILLE (77570)", value: "77570" },
  { label: "AUGERS EN BRIE (77560)", value: "77560" },
  { label: "AULNOY (77120)", value: "77120" },
  { label: "AVON (77210)", value: "77210" },
  { label: "BABY (77480)", value: "77480" },
  { label: "BAGNEAUX SUR LOING (77167)", value: "77167" },
  { label: "BAILLY ROMAINVILLIERS (77700)", value: "77700" },
  { label: "BALLOY (77118)", value: "77118" },
  { label: "BANNOST VILLEGAGNON (77970)", value: "77970" },
  { label: "BANNOST VILLEGAGNON (77970)", value: "77970" },
  { label: "BARBEY (77130)", value: "77130" },
  { label: "BARBIZON (77630)", value: "77630" },
  { label: "BARCY (77910)", value: "77910" },
  { label: "BASSEVELLE (77750)", value: "77750" },
  { label: "BAZOCHES LES BRAY (77118)", value: "77118" },
  { label: "BEAUCHERY ST MARTIN (77560)", value: "77560" },
  { label: "BEAUCHERY ST MARTIN (77560)", value: "77560" },
  { label: "BEAUMONT DU GATINAIS (77890)", value: "77890" },
  { label: "BEAUVOIR (77390)", value: "77390" },
  { label: "BELLOT (77510)", value: "77510" },
  { label: "BERNAY VILBERT (77540)", value: "77540" },
  { label: "BERNAY VILBERT (77540)", value: "77540" },
  { label: "BETON BAZOCHES (77320)", value: "77320" },
  { label: "BEZALLES (77970)", value: "77970" },
  { label: "BLANDY (77115)", value: "77115" },
  { label: "BLENNES (77940)", value: "77940" },
  { label: "BOISDON (77970)", value: "77970" },
  { label: "BOIS LE ROI (77590)", value: "77590" },
  { label: "BOISSETTES (77350)", value: "77350" },
  { label: "BOISSISE LA BERTRAND (77350)", value: "77350" },
  { label: "BOISSISE LE ROI (77310)", value: "77310" },
  { label: "BOISSY AUX CAILLES (77760)", value: "77760" },
  { label: "BOISSY LE CHATEL (77169)", value: "77169" },
  { label: "BOITRON (77750)", value: "77750" },
  { label: "BOMBON (77720)", value: "77720" },
  { label: "BOUGLIGNY (77570)", value: "77570" },
  { label: "BOULANCOURT (77760)", value: "77760" },
  { label: "BOULEURS (77580)", value: "77580" },
  { label: "BOURRON MARLOTTE (77780)", value: "77780" },
  { label: "BOUTIGNY (77470)", value: "77470" },
  { label: "BRANSLES (77620)", value: "77620" },
  { label: "BRAY SUR SEINE (77480)", value: "77480" },
  { label: "BREAU (77720)", value: "77720" },
  { label: "BRIE COMTE ROBERT (77170)", value: "77170" },
  { label: "LA BROSSE MONTCEAUX (77940)", value: "77940" },
  { label: "BROU SUR CHANTEREINE (77177)", value: "77177" },
  { label: "BURCY (77760)", value: "77760" },
  { label: "BUSSIERES (77750)", value: "77750" },
  { label: "BUSSY ST GEORGES (77600)", value: "77600" },
  { label: "BUSSY ST MARTIN (77600)", value: "77600" },
  { label: "BUSSY ST MARTIN (77600)", value: "77600" },
  { label: "BUTHIERS (77760)", value: "77760" },
  { label: "CANNES ECLUSE (77130)", value: "77130" },
  { label: "CARNETIN (77400)", value: "77400" },
  { label: "LA CELLE SUR MORIN (77515)", value: "77515" },
  { label: "CELY (77930)", value: "77930" },
  { label: "CERNEUX (77320)", value: "77320" },
  { label: "CESSON (77240)", value: "77240" },
  { label: "CESSOY EN MONTOIS (77520)", value: "77520" },
  { label: "CHAILLY EN BIERE (77930)", value: "77930" },
  { label: "CHAILLY EN BRIE (77120)", value: "77120" },
  { label: "CHAINTREAUX (77460)", value: "77460" },
  { label: "CHALAUTRE LA GRANDE (77171)", value: "77171" },
  { label: "CHALAUTRE LA PETITE (77160)", value: "77160" },
  { label: "CHALIFERT (77144)", value: "77144" },
  { label: "CHALMAISON (77650)", value: "77650" },
  { label: "CHAMBRY (77910)", value: "77910" },
  { label: "CHAMIGNY (77260)", value: "77260" },
  { label: "CHAMPAGNE SUR SEINE (77430)", value: "77430" },
  { label: "CHAMPCENEST (77560)", value: "77560" },
  { label: "CHAMPDEUIL (77390)", value: "77390" },
  { label: "CHAMPEAUX (77720)", value: "77720" },
  { label: "CHAMPS SUR MARNE (77420)", value: "77420" },
  { label: "CHANGIS SUR MARNE (77660)", value: "77660" },
  { label: "CHANTELOUP EN BRIE (77600)", value: "77600" },
  { label: "LA CHAPELLE GAUTHIER (77720)", value: "77720" },
  { label: "LA CHAPELLE IGER (77540)", value: "77540" },
  { label: "LA CHAPELLE LA REINE (77760)", value: "77760" },
  { label: "LA CHAPELLE RABLAIS (77370)", value: "77370" },
  { label: "LA CHAPELLE ST SULPICE (77160)", value: "77160" },
  { label: "LES CHAPELLES BOURBON (77610)", value: "77610" },
  { label: "LA CHAPELLE MOUTILS (77320)", value: "77320" },
  { label: "LA CHAPELLE MOUTILS (77320)", value: "77320" },
  { label: "CHARMENTRAY (77410)", value: "77410" },
  { label: "CHARNY (77410)", value: "77410" },
  { label: "CHARTRETTES (77590)", value: "77590" },
  { label: "CHARTRONGES (77320)", value: "77320" },
  { label: "CHATEAUBLEAU (77370)", value: "77370" },
  { label: "CHATEAU LANDON (77570)", value: "77570" },
  { label: "LE CHATELET EN BRIE (77820)", value: "77820" },
  { label: "CHATENAY SUR SEINE (77126)", value: "77126" },
  { label: "CHATENOY (77167)", value: "77167" },
  { label: "CHATILLON LA BORDE (77820)", value: "77820" },
  { label: "CHATRES (77610)", value: "77610" },
  { label: "CHAUFFRY (77169)", value: "77169" },
  { label: "CHAUMES EN BRIE (77390)", value: "77390" },
  { label: "CHELLES (77500)", value: "77500" },
  { label: "CHELLES (77500)", value: "77500" },
  { label: "CHENOISE CUCHARMOY (77160)", value: "77160" },
  { label: "CHENOISE CUCHARMOY (77160)", value: "77160" },
  { label: "CHENOU (77570)", value: "77570" },
  { label: "CHESSY (77700)", value: "77700" },
  { label: "CHEVRAINVILLIERS (77760)", value: "77760" },
  { label: "CHEVRU (77320)", value: "77320" },
  { label: "CHEVRY COSSIGNY (77173)", value: "77173" },
  { label: "CHEVRY COSSIGNY (77173)", value: "77173" },
  { label: "CHEVRY EN SEREINE (77710)", value: "77710" },
  { label: "CHOISY EN BRIE (77320)", value: "77320" },
  { label: "CITRY (77730)", value: "77730" },
  { label: "CLAYE SOUILLY (77410)", value: "77410" },
  { label: "CLAYE SOUILLY (77410)", value: "77410" },
  { label: "CLOS FONTAINE (77370)", value: "77370" },
  { label: "COCHEREL (77440)", value: "77440" },
  { label: "COLLEGIEN (77090)", value: "77090" },
  { label: "COMBS LA VILLE (77380)", value: "77380" },
  { label: "COMPANS (77290)", value: "77290" },
  { label: "CONCHES SUR GONDOIRE (77600)", value: "77600" },
  { label: "CONDE STE LIBIAIRE (77450)", value: "77450" },
  { label: "CONGIS SUR THEROUANNE (77440)", value: "77440" },
  { label: "COUBERT (77170)", value: "77170" },
  { label: "COUILLY PONT AUX DAMES (77860)", value: "77860" },
  { label: "COULOMBS EN VALOIS (77840)", value: "77840" },
  { label: "COULOMMES (77580)", value: "77580" },
  { label: "COULOMMIERS (77120)", value: "77120" },
  { label: "COUPVRAY (77700)", value: "77700" },
  { label: "COURCELLES EN BASSEE (77126)", value: "77126" },
  { label: "COURCHAMP (77560)", value: "77560" },
  { label: "COURPALAY (77540)", value: "77540" },
  { label: "COURQUETAINE (77390)", value: "77390" },
  { label: "COURTACON (77560)", value: "77560" },
  { label: "COURTOMER (77390)", value: "77390" },
  { label: "COURTRY (77181)", value: "77181" },
  { label: "COUTENCON (77154)", value: "77154" },
  { label: "COUTEVROULT (77580)", value: "77580" },
  { label: "CRECY LA CHAPELLE (77580)", value: "77580" },
  { label: "CRECY LA CHAPELLE (77580)", value: "77580" },
  { label: "CREGY LES MEAUX (77124)", value: "77124" },
  { label: "CREVECOEUR EN BRIE (77610)", value: "77610" },
  { label: "CRISENOY (77390)", value: "77390" },
  { label: "CROISSY BEAUBOURG (77183)", value: "77183" },
  { label: "CROISSY BEAUBOURG (77183)", value: "77183" },
  { label: "LA CROIX EN BRIE (77370)", value: "77370" },
  { label: "CROUY SUR OURCQ (77840)", value: "77840" },
  { label: "CUISY (77165)", value: "77165" },
  { label: "DAGNY (77320)", value: "77320" },
  { label: "DAMMARIE LES LYS (77190)", value: "77190" },
  { label: "DAMMARTIN EN GOELE (77230)", value: "77230" },
  { label: "DAMMARTIN SUR TIGEAUX (77163)", value: "77163" },
  { label: "DAMPMART (77400)", value: "77400" },
  { label: "DARVAULT (77140)", value: "77140" },
  { label: "DHUISY (77440)", value: "77440" },
  { label: "DIANT (77940)", value: "77940" },
  { label: "DONNEMARIE DONTILLY (77520)", value: "77520" },
  { label: "DORMELLES (77130)", value: "77130" },
  { label: "DOUE (77510)", value: "77510" },
  { label: "DOUY LA RAMEE (77139)", value: "77139" },
  { label: "ECHOUBOULAINS (77830)", value: "77830" },
  { label: "LES ECRENNES (77820)", value: "77820" },
  { label: "EGLIGNY (77126)", value: "77126" },
  { label: "EGREVILLE (77620)", value: "77620" },
  { label: "EMERAINVILLE (77184)", value: "77184" },
  { label: "EMERAINVILLE (77184)", value: "77184" },
  { label: "ESBLY (77450)", value: "77450" },
  { label: "ESMANS (77940)", value: "77940" },
  { label: "ETREPILLY (77139)", value: "77139" },
  { label: "EVERLY (77157)", value: "77157" },
  { label: "EVRY GREGY SUR YERRE (77166)", value: "77166" },
  { label: "EVRY GREGY SUR YERRE (77166)", value: "77166" },
  { label: "FAREMOUTIERS (77515)", value: "77515" },
  { label: "FAVIERES (77220)", value: "77220" },
  { label: "FAY LES NEMOURS (77167)", value: "77167" },
  { label: "FERICY (77133)", value: "77133" },
  { label: "FEROLLES ATTILLY (77150)", value: "77150" },
  { label: "FERRIERES EN BRIE (77164)", value: "77164" },
  { label: "LA FERTE GAUCHER (77320)", value: "77320" },
  { label: "LA FERTE SOUS JOUARRE (77260)", value: "77260" },
  { label: "FLAGY (77940)", value: "77940" },
  { label: "FLEURY EN BIERE (77930)", value: "77930" },
  { label: "FONTAINEBLEAU (77300)", value: "77300" },
  { label: "FONTAINE FOURCHES (77480)", value: "77480" },
  { label: "FONTAINE LE PORT (77590)", value: "77590" },
  { label: "FONTAINS (77370)", value: "77370" },
  { label: "FONTENAILLES (77370)", value: "77370" },
  { label: "FONTENAY TRESIGNY (77610)", value: "77610" },
  { label: "FORFRY (77165)", value: "77165" },
  { label: "FORGES (77130)", value: "77130" },
  { label: "FOUJU (77390)", value: "77390" },
  { label: "FRESNES SUR MARNE (77410)", value: "77410" },
  { label: "FRETOY (77320)", value: "77320" },
  { label: "FROMONT (77760)", value: "77760" },
  { label: "FUBLAINES (77470)", value: "77470" },
  { label: "GARENTREVILLE (77890)", value: "77890" },
  { label: "GASTINS (77370)", value: "77370" },
  { label: "LA GENEVRAYE (77690)", value: "77690" },
  { label: "GERMIGNY L EVEQUE (77910)", value: "77910" },
  { label: "GERMIGNY SOUS COULOMBS (77840)", value: "77840" },
  { label: "GESVRES LE CHAPITRE (77165)", value: "77165" },
  { label: "GIREMOUTIERS (77120)", value: "77120" },
  { label: "GIRONVILLE (77890)", value: "77890" },
  { label: "GOUAIX (77114)", value: "77114" },
  { label: "GOUVERNES (77400)", value: "77400" },
  { label: "LA GRANDE PAROISSE (77130)", value: "77130" },
  { label: "GRANDPUITS BAILLY CARROIS (77720)", value: "77720" },
  { label: "GRANDPUITS BAILLY CARROIS (77720)", value: "77720" },
  { label: "GRAVON (77118)", value: "77118" },
  { label: "GRESSY (77410)", value: "77410" },
  { label: "GRETZ ARMAINVILLIERS (77220)", value: "77220" },
  { label: "GREZ SUR LOING (77880)", value: "77880" },
  { label: "GRISY SUISNES (77166)", value: "77166" },
  { label: "GRISY SUISNES (77166)", value: "77166" },
  { label: "GRISY SUR SEINE (77480)", value: "77480" },
  { label: "GUERARD (77580)", value: "77580" },
  { label: "GUERCHEVILLE (77760)", value: "77760" },
  { label: "GUERMANTES (77600)", value: "77600" },
  { label: "GUIGNES (77390)", value: "77390" },
  { label: "GURCY LE CHATEL (77520)", value: "77520" },
  { label: "GURCY LE CHATEL (77520)", value: "77520" },
  { label: "HAUTEFEUILLE (77515)", value: "77515" },
  { label: "LA HAUTE MAISON (77580)", value: "77580" },
  { label: "HERICY (77850)", value: "77850" },
  { label: "HERME (77114)", value: "77114" },
  { label: "HONDEVILLIERS (77510)", value: "77510" },
  { label: "LA HOUSSAYE EN BRIE (77610)", value: "77610" },
  { label: "ICHY (77890)", value: "77890" },
  { label: "ISLES LES MELDEUSES (77440)", value: "77440" },
  { label: "ISLES LES VILLENOY (77450)", value: "77450" },
  { label: "IVERNY (77165)", value: "77165" },
  { label: "JABLINES (77450)", value: "77450" },
  { label: "JAIGNES (77440)", value: "77440" },
  { label: "JAULNES (77480)", value: "77480" },
  { label: "JOSSIGNY (77600)", value: "77600" },
  { label: "JOUARRE (77640)", value: "77640" },
  { label: "JOUY LE CHATEL (77970)", value: "77970" },
  { label: "JOUY SUR MORIN (77320)", value: "77320" },
  { label: "JUILLY (77230)", value: "77230" },
  { label: "JUTIGNY (77650)", value: "77650" },
  { label: "LAGNY SUR MARNE (77400)", value: "77400" },
  { label: "LARCHANT (77760)", value: "77760" },
  { label: "LAVAL EN BRIE (77148)", value: "77148" },
  { label: "LECHELLE (77171)", value: "77171" },
  { label: "LESCHEROLLES (77320)", value: "77320" },
  { label: "LESCHES (77450)", value: "77450" },
  { label: "LESIGNY (77150)", value: "77150" },
  { label: "LEUDON EN BRIE (77320)", value: "77320" },
  { label: "LIEUSAINT (77127)", value: "77127" },
  { label: "LIMOGES FOURCHES (77550)", value: "77550" },
  { label: "LISSY (77550)", value: "77550" },
  { label: "LIVERDY EN BRIE (77220)", value: "77220" },
  { label: "LIVRY SUR SEINE (77000)", value: "77000" },
  { label: "LIZINES (77650)", value: "77650" },
  { label: "LIZY SUR OURCQ (77440)", value: "77440" },
  { label: "LOGNES (77185)", value: "77185" },
  { label: "LONGPERRIER (77230)", value: "77230" },
  { label: "LONGUEVILLE (77650)", value: "77650" },
  { label: "LORREZ LE BOCAGE PREAUX (77710)", value: "77710" },
  { label: "LORREZ LE BOCAGE PREAUX (77710)", value: "77710" },
  { label: "LOUAN VILLEGRUIS FONTAINE (77560)", value: "77560" },
  { label: "LOUAN VILLEGRUIS FONTAINE (77560)", value: "77560" },
  { label: "LOUAN VILLEGRUIS FONTAINE (77560)", value: "77560" },
  { label: "LUISETAINES (77520)", value: "77520" },
  { label: "LUMIGNY NESLES ORMEAUX (77540)", value: "77540" },
  { label: "LUMIGNY NESLES ORMEAUX (77540)", value: "77540" },
  { label: "LUMIGNY NESLES ORMEAUX (77540)", value: "77540" },
  { label: "LUZANCY (77138)", value: "77138" },
  { label: "MACHAULT (77133)", value: "77133" },
  { label: "LA MADELEINE SUR LOING (77570)", value: "77570" },
  { label: "MAGNY LE HONGRE (77700)", value: "77700" },
  { label: "MAINCY (77950)", value: "77950" },
  { label: "MAISONCELLES EN BRIE (77580)", value: "77580" },
  { label: "MAISONCELLES EN GATINAIS (77570)", value: "77570" },
  { label: "MAISON ROUGE (77370)", value: "77370" },
  { label: "MARCHEMORET (77230)", value: "77230" },
  { label: "MARCILLY (77139)", value: "77139" },
  { label: "LES MARETS (77560)", value: "77560" },
  { label: "MAREUIL LES MEAUX (77100)", value: "77100" },
  { label: "MARLES EN BRIE (77610)", value: "77610" },
  { label: "MAROLLES EN BRIE (77120)", value: "77120" },
  { label: "MAROLLES SUR SEINE (77130)", value: "77130" },
  { label: "MARY SUR MARNE (77440)", value: "77440" },
  { label: "MAUPERTHUIS (77120)", value: "77120" },
  { label: "MAUREGARD (77990)", value: "77990" },
  { label: "MAY EN MULTIEN (77145)", value: "77145" },
  { label: "MEAUX (77100)", value: "77100" },
  { label: "LE MEE SUR SEINE (77350)", value: "77350" },
  { label: "MEIGNEUX (77520)", value: "77520" },
  { label: "MEILLERAY (77320)", value: "77320" },
  { label: "MELUN (77000)", value: "77000" },
  { label: "MELZ SUR SEINE (77171)", value: "77171" },
  { label: "MERY SUR MARNE (77730)", value: "77730" },
  { label: "LE MESNIL AMELOT (77990)", value: "77990" },
  { label: "MESSY (77410)", value: "77410" },
  { label: "MISY SUR YONNE (77130)", value: "77130" },
  { label: "MITRY MORY (77290)", value: "77290" },
  { label: "MOISENAY (77950)", value: "77950" },
  { label: "MOISSY CRAMAYEL (77550)", value: "77550" },
  { label: "MONDREVILLE (77570)", value: "77570" },
  { label: "MONS EN MONTOIS (77520)", value: "77520" },
  { label: "MONTCEAUX LES MEAUX (77470)", value: "77470" },
  { label: "MONTCEAUX LES PROVINS (77151)", value: "77151" },
  { label: "MONTCOURT FROMONVILLE (77140)", value: "77140" },
  { label: "MONTDAUPHIN (77320)", value: "77320" },
  { label: "MONTENILS (77320)", value: "77320" },
  { label: "MONTEREAU FAULT YONNE (77130)", value: "77130" },
  { label: "MONTEREAU SUR LE JARD (77950)", value: "77950" },
  { label: "MONTEVRAIN (77144)", value: "77144" },
  { label: "MONTGE EN GOELE (77230)", value: "77230" },
  { label: "MONTHYON (77122)", value: "77122" },
  { label: "MONTIGNY LE GUESDIER (77480)", value: "77480" },
  { label: "MONTIGNY LENCOUP (77520)", value: "77520" },
  { label: "MONTIGNY SUR LOING (77690)", value: "77690" },
  { label: "MONTMACHOUX (77940)", value: "77940" },
  { label: "MONTOLIVET (77320)", value: "77320" },
  { label: "MONTRY (77450)", value: "77450" },
  { label: "MORET LOING ET ORVANNE (77250)", value: "77250" },
  { label: "MORET LOING ET ORVANNE (77250)", value: "77250" },
  { label: "MORET LOING ET ORVANNE (77250)", value: "77250" },
  { label: "MORET LOING ET ORVANNE (77250)", value: "77250" },
  { label: "MORET LOING ET ORVANNE (77250)", value: "77250" },
  { label: "MORMANT (77720)", value: "77720" },
  { label: "MORTCERF (77163)", value: "77163" },
  { label: "MORTERY (77160)", value: "77160" },
  { label: "MOUROUX (77120)", value: "77120" },
  { label: "MOUSSEAUX LES BRAY (77480)", value: "77480" },
  { label: "MOUSSY LE NEUF (77230)", value: "77230" },
  { label: "MOUSSY LE VIEUX (77230)", value: "77230" },
  { label: "MOUY SUR SEINE (77480)", value: "77480" },
  { label: "NANDY (77176)", value: "77176" },
  { label: "NANGIS (77370)", value: "77370" },
  { label: "NANTEAU SUR ESSONNE (77760)", value: "77760" },
  { label: "NANTEAU SUR LUNAIN (77710)", value: "77710" },
  { label: "NANTEUIL LES MEAUX (77100)", value: "77100" },
  { label: "NANTEUIL SUR MARNE (77730)", value: "77730" },
  { label: "NANTOUILLET (77230)", value: "77230" },
  { label: "NEMOURS (77140)", value: "77140" },
  { label: "CHAUCONIN NEUFMONTIERS (77124)", value: "77124" },
  { label: "CHAUCONIN NEUFMONTIERS (77124)", value: "77124" },
  { label: "CHAUCONIN NEUFMONTIERS (77124)", value: "77124" },
  { label: "NEUFMOUTIERS EN BRIE (77610)", value: "77610" },
  { label: "NOISIEL (77186)", value: "77186" },
  { label: "NOISY RUDIGNON (77940)", value: "77940" },
  { label: "NOISY SUR ECOLE (77123)", value: "77123" },
  { label: "NONVILLE (77140)", value: "77140" },
  { label: "NOYEN SUR SEINE (77114)", value: "77114" },
  { label: "OBSONVILLE (77890)", value: "77890" },
  { label: "OCQUERRE (77440)", value: "77440" },
  { label: "OISSERY (77178)", value: "77178" },
  { label: "ORLY SUR MORIN (77750)", value: "77750" },
  { label: "LES ORMES SUR VOULZIE (77134)", value: "77134" },
  { label: "ORMESSON (77167)", value: "77167" },
  { label: "OTHIS (77280)", value: "77280" },
  { label: "OZOIR LA FERRIERE (77330)", value: "77330" },
  { label: "OZOUER LE VOULGIS (77390)", value: "77390" },
  { label: "PALEY (77710)", value: "77710" },
  { label: "PAMFOU (77830)", value: "77830" },
  { label: "PAROY (77520)", value: "77520" },
  { label: "PASSY SUR SEINE (77480)", value: "77480" },
  { label: "PECY (77970)", value: "77970" },
  { label: "PENCHARD (77124)", value: "77124" },
  { label: "PERTHES (77930)", value: "77930" },
  { label: "PEZARCHES (77131)", value: "77131" },
  { label: "PIERRE LEVEE (77580)", value: "77580" },
  { label: "LE PIN (77181)", value: "77181" },
  { label: "LE PLESSIS AUX BOIS (77165)", value: "77165" },
  { label: "LE PLESSIS FEU AUSSOUX (77540)", value: "77540" },
  { label: "LE PLESSIS L EVEQUE (77165)", value: "77165" },
  { label: "LE PLESSIS PLACY (77440)", value: "77440" },
  { label: "POIGNY (77160)", value: "77160" },
  { label: "POINCY (77470)", value: "77470" },
  { label: "POLIGNY (77167)", value: "77167" },
  { label: "POMMEUSE (77515)", value: "77515" },
  { label: "POMPONNE (77400)", value: "77400" },
  { label: "POMPONNE (77400)", value: "77400" },
  { label: "PONTAULT COMBAULT (77340)", value: "77340" },
  { label: "PONTAULT COMBAULT (77340)", value: "77340" },
  { label: "PONTCARRE (77135)", value: "77135" },
  { label: "PRECY SUR MARNE (77410)", value: "77410" },
  { label: "PRESLES EN BRIE (77220)", value: "77220" },
  { label: "PRINGY (77310)", value: "77310" },
  { label: "PROVINS (77160)", value: "77160" },
  { label: "PUISIEUX (77139)", value: "77139" },
  { label: "QUIERS (77720)", value: "77720" },
  { label: "QUINCY VOISINS (77860)", value: "77860" },
  { label: "RAMPILLON (77370)", value: "77370" },
  { label: "REAU (77550)", value: "77550" },
  { label: "REBAIS (77510)", value: "77510" },
  { label: "RECLOSES (77760)", value: "77760" },
  { label: "REMAUVILLE (77710)", value: "77710" },
  { label: "REUIL EN BRIE (77260)", value: "77260" },
  { label: "LA ROCHETTE (77000)", value: "77000" },
  { label: "ROISSY EN BRIE (77680)", value: "77680" },
  { label: "ROUILLY (77160)", value: "77160" },
  { label: "ROUVRES (77230)", value: "77230" },
  { label: "ROZAY EN BRIE (77540)", value: "77540" },
  { label: "RUBELLES (77950)", value: "77950" },
  { label: "RUMONT (77760)", value: "77760" },
  { label: "RUPEREUX (77560)", value: "77560" },
  { label: "SAACY SUR MARNE (77730)", value: "77730" },
  { label: "SABLONNIERES (77510)", value: "77510" },
  { label: "ST AUGUSTIN (77515)", value: "77515" },
  { label: "STE AULDE (77260)", value: "77260" },
  { label: "ST BARTHELEMY (77320)", value: "77320" },
  { label: "ST BRICE (77160)", value: "77160" },
  { label: "STE COLOMBE (77650)", value: "77650" },
  { label: "ST CYR SUR MORIN (77750)", value: "77750" },
  { label: "ST DENIS LES REBAIS (77510)", value: "77510" },
  { label: "ST FARGEAU PONTHIERRY (77310)", value: "77310" },
  { label: "ST FARGEAU PONTHIERRY (77310)", value: "77310" },
  { label: "ST FIACRE (77470)", value: "77470" },
  { label: "ST GERMAIN LAVAL (77130)", value: "77130" },
  { label: "ST GERMAIN LAXIS (77950)", value: "77950" },
  { label: "ST GERMAIN SOUS DOUE (77169)", value: "77169" },
  { label: "ST GERMAIN SUR ECOLE (77930)", value: "77930" },
  { label: "ST GERMAIN SUR MORIN (77860)", value: "77860" },
  { label: "ST HILLIERS (77160)", value: "77160" },
  { label: "ST JEAN LES DEUX JUMEAUX (77660)", value: "77660" },
  { label: "ST JUST EN BRIE (77370)", value: "77370" },
  { label: "ST LEGER (77510)", value: "77510" },
  { label: "ST LOUP DE NAUD (77650)", value: "77650" },
  { label: "ST MAMMES (77670)", value: "77670" },
  { label: "ST MARD (77230)", value: "77230" },
  { label: "ST MARS VIEUX MAISONS (77320)", value: "77320" },
  { label: "ST MARS VIEUX MAISONS (77320)", value: "77320" },
  { label: "ST MARTIN DES CHAMPS (77320)", value: "77320" },
  { label: "ST MARTIN DU BOSCHET (77320)", value: "77320" },
  { label: "ST MARTIN EN BIERE (77630)", value: "77630" },
  { label: "ST MERY (77720)", value: "77720" },
  { label: "ST MESMES (77410)", value: "77410" },
  { label: "ST OUEN EN BRIE (77720)", value: "77720" },
  { label: "ST OUEN SUR MORIN (77750)", value: "77750" },
  { label: "ST PATHUS (77178)", value: "77178" },
  { label: "ST PIERRE LES NEMOURS (77140)", value: "77140" },
  { label: "ST REMY DE LA VANNE (77320)", value: "77320" },
  { label: "BEAUTHEIL SAINTS (77120)", value: "77120" },
  { label: "BEAUTHEIL SAINTS (77120)", value: "77120" },
  { label: "ST SAUVEUR LES BRAY (77480)", value: "77480" },
  { label: "ST SAUVEUR SUR ECOLE (77930)", value: "77930" },
  { label: "ST SIMEON (77169)", value: "77169" },
  { label: "ST SOUPPLETS (77165)", value: "77165" },
  { label: "ST THIBAULT DES VIGNES (77400)", value: "77400" },
  { label: "SALINS (77148)", value: "77148" },
  { label: "SAMMERON (77260)", value: "77260" },
  { label: "SAMOIS SUR SEINE (77920)", value: "77920" },
  { label: "SAMOREAU (77210)", value: "77210" },
  { label: "SANCY (77580)", value: "77580" },
  { label: "SANCY LES PROVINS (77320)", value: "77320" },
  { label: "SAVIGNY LE TEMPLE (77176)", value: "77176" },
  { label: "SAVINS (77650)", value: "77650" },
  { label: "SEINE PORT (77240)", value: "77240" },
  { label: "SEPT SORTS (77260)", value: "77260" },
  { label: "SERRIS (77700)", value: "77700" },
  { label: "SERVON (77170)", value: "77170" },
  { label: "SIGNY SIGNETS (77640)", value: "77640" },
  { label: "SIGY (77520)", value: "77520" },
  { label: "SIVRY COURTRY (77115)", value: "77115" },
  { label: "SOGNOLLES EN MONTOIS (77520)", value: "77520" },
  { label: "SOIGNOLLES EN BRIE (77111)", value: "77111" },
  { label: "SOISY BOUY (77650)", value: "77650" },
  { label: "SOLERS (77111)", value: "77111" },
  { label: "SOUPPES SUR LOING (77460)", value: "77460" },
  { label: "SOURDUN (77171)", value: "77171" },
  { label: "TANCROU (77440)", value: "77440" },
  { label: "THENISY (77520)", value: "77520" },
  { label: "THIEUX (77230)", value: "77230" },
  { label: "THOMERY (77810)", value: "77810" },
  { label: "THORIGNY SUR MARNE (77400)", value: "77400" },
  { label: "THOURY FEROTTES (77940)", value: "77940" },
  { label: "TIGEAUX (77163)", value: "77163" },
  { label: "LA TOMBE (77130)", value: "77130" },
  { label: "TORCY (77200)", value: "77200" },
  { label: "TOUQUIN (77131)", value: "77131" },
  { label: "TOURNAN EN BRIE (77220)", value: "77220" },
  { label: "TOUSSON (77123)", value: "77123" },
  { label: "LA TRETOIRE (77510)", value: "77510" },
  { label: "TREUZY LEVELAY (77710)", value: "77710" },
  { label: "TRILBARDOU (77450)", value: "77450" },
  { label: "TRILPORT (77470)", value: "77470" },
  { label: "TROCY EN MULTIEN (77440)", value: "77440" },
  { label: "URY (77760)", value: "77760" },
  { label: "USSY SUR MARNE (77260)", value: "77260" },
  { label: "VAIRES SUR MARNE (77360)", value: "77360" },
  { label: "VALENCE EN BRIE (77830)", value: "77830" },
  { label: "VANVILLE (77370)", value: "77370" },
  { label: "VARENNES SUR SEINE (77130)", value: "77130" },
  { label: "VARREDDES (77910)", value: "77910" },
  { label: "VAUCOURTOIS (77580)", value: "77580" },
  { label: "LE VAUDOUE (77123)", value: "77123" },
  { label: "VAUDOY EN BRIE (77141)", value: "77141" },
  { label: "VAUX LE PENIL (77000)", value: "77000" },
  { label: "VAUX SUR LUNAIN (77710)", value: "77710" },
  { label: "VENDREST (77440)", value: "77440" },
  { label: "VERDELOT (77510)", value: "77510" },
  { label: "VERNEUIL L ETANG (77390)", value: "77390" },
  { label: "VERNOU LA CELLE SUR SEINE (77670)", value: "77670" },
  { label: "VERNOU LA CELLE SUR SEINE (77670)", value: "77670" },
  { label: "VERT ST DENIS (77240)", value: "77240" },
  { label: "VIEUX CHAMPAGNE (77370)", value: "77370" },
  { label: "VIGNELY (77450)", value: "77450" },
  { label: "VILLEBEON (77710)", value: "77710" },
  { label: "VILLECERF (77250)", value: "77250" },
  { label: "VILLEMARECHAL (77710)", value: "77710" },
  { label: "VILLEMARECHAL (77710)", value: "77710" },
  { label: "VILLEMAREUIL (77470)", value: "77470" },
  { label: "VILLEMER (77250)", value: "77250" },
  { label: "VILLENAUXE LA PETITE (77480)", value: "77480" },
  { label: "VILLENEUVE LE COMTE (77174)", value: "77174" },
  { label: "VILLENEUVE LES BORDES (77154)", value: "77154" },
  { label: "VILLENEUVE ST DENIS (77174)", value: "77174" },
  { label: "VILLENEUVE SOUS DAMMARTIN (77230)", value: "77230" },
  { label: "VILLENEUVE SUR BELLOT (77510)", value: "77510" },
  { label: "VILLENOY (77124)", value: "77124" },
  { label: "VILLEPARISIS (77270)", value: "77270" },
  { label: "VILLEROY (77410)", value: "77410" },
  { label: "VILLE ST JACQUES (77130)", value: "77130" },
  { label: "VILLEVAUDE (77410)", value: "77410" },
  { label: "VILLEVAUDE (77410)", value: "77410" },
  { label: "VILLEVAUDE (77410)", value: "77410" },
  { label: "VILLIERS EN BIERE (77190)", value: "77190" },
  { label: "VILLIERS ST GEORGES (77560)", value: "77560" },
  { label: "VILLIERS SOUS GREZ (77760)", value: "77760" },
  { label: "VILLIERS SUR MORIN (77580)", value: "77580" },
  { label: "VILLIERS SUR SEINE (77114)", value: "77114" },
  { label: "VILLUIS (77480)", value: "77480" },
  { label: "VIMPELLES (77520)", value: "77520" },
  { label: "VINANTES (77230)", value: "77230" },
  { label: "VINCY MANOEUVRE (77139)", value: "77139" },
  { label: "VOINSLES (77540)", value: "77540" },
  { label: "VOISENON (77950)", value: "77950" },
  { label: "VOULANGIS (77580)", value: "77580" },
  { label: "VOULTON (77560)", value: "77560" },
  { label: "VOULX (77940)", value: "77940" },
  { label: "VULAINES LES PROVINS (77160)", value: "77160" },
  { label: "VULAINES SUR SEINE (77870)", value: "77870" },
  { label: "YEBLES (77390)", value: "77390" },
  { label: "ABLIS (78660)", value: "78660" },
  { label: "ACHERES (78260)", value: "78260" },
  { label: "ADAINVILLE (78113)", value: "78113" },
  { label: "AIGREMONT (78240)", value: "78240" },
  { label: "ALLAINVILLE (78660)", value: "78660" },
  { label: "LES ALLUETS LE ROI (78580)", value: "78580" },
  { label: "ANDELU (78770)", value: "78770" },
  { label: "ANDRESY (78570)", value: "78570" },
  { label: "ANDRESY (78570)", value: "78570" },
  { label: "ARNOUVILLE LES MANTES (78790)", value: "78790" },
  { label: "AUBERGENVILLE (78410)", value: "78410" },
  { label: "AUBERGENVILLE (78410)", value: "78410" },
  { label: "AUFFARGIS (78610)", value: "78610" },
  { label: "AUFFREVILLE BRASSEUIL (78930)", value: "78930" },
  { label: "AULNAY SUR MAULDRE (78126)", value: "78126" },
  { label: "AUTEUIL (78770)", value: "78770" },
  { label: "AUTOUILLET (78770)", value: "78770" },
  { label: "BAILLY (78870)", value: "78870" },
  { label: "BAZAINVILLE (78550)", value: "78550" },
  { label: "BAZEMONT (78580)", value: "78580" },
  { label: "BAZOCHES SUR GUYONNE (78490)", value: "78490" },
  { label: "BEHOUST (78910)", value: "78910" },
  { label: "BENNECOURT (78270)", value: "78270" },
  { label: "BEYNES (78650)", value: "78650" },
  { label: "BEYNES (78650)", value: "78650" },
  { label: "BEYNES (78650)", value: "78650" },
  { label: "BLARU (78270)", value: "78270" },
  { label: "BOINVILLE EN MANTOIS (78930)", value: "78930" },
  { label: "BOINVILLE LE GAILLARD (78660)", value: "78660" },
  { label: "BOINVILLIERS (78200)", value: "78200" },
  { label: "BOIS D ARCY (78390)", value: "78390" },
  { label: "BOISSETS (78910)", value: "78910" },
  { label: "LA BOISSIERE ECOLE (78125)", value: "78125" },
  { label: "BOISSY MAUVOISIN (78200)", value: "78200" },
  { label: "BOISSY SANS AVOIR (78490)", value: "78490" },
  { label: "BONNELLES (78830)", value: "78830" },
  { label: "BONNIERES SUR SEINE (78270)", value: "78270" },
  { label: "BOUAFLE (78410)", value: "78410" },
  { label: "BOUGIVAL (78380)", value: "78380" },
  { label: "BOURDONNE (78113)", value: "78113" },
  { label: "BREUIL BOIS ROBERT (78930)", value: "78930" },
  { label: "BREVAL (78980)", value: "78980" },
  { label: "LES BREVIAIRES (78610)", value: "78610" },
  { label: "BRUEIL EN VEXIN (78440)", value: "78440" },
  { label: "BUC (78530)", value: "78530" },
  { label: "BUCHELAY (78200)", value: "78200" },
  { label: "BULLION (78830)", value: "78830" },
  { label: "CARRIERES SOUS POISSY (78955)", value: "78955" },
  { label: "CARRIERES SOUS POISSY (78955)", value: "78955" },
  { label: "CARRIERES SUR SEINE (78420)", value: "78420" },
  { label: "LA CELLE LES BORDES (78720)", value: "78720" },
  { label: "LA CELLE ST CLOUD (78170)", value: "78170" },
  { label: "CERNAY LA VILLE (78720)", value: "78720" },
  { label: "CHAMBOURCY (78240)", value: "78240" },
  { label: "CHANTELOUP LES VIGNES (78570)", value: "78570" },
  { label: "CHAPET (78130)", value: "78130" },
  { label: "CHATEAUFORT (78117)", value: "78117" },
  { label: "CHATOU (78400)", value: "78400" },
  { label: "CHAUFOUR LES BONNIERES (78270)", value: "78270" },
  { label: "CHAVENAY (78450)", value: "78450" },
  { label: "LE CHESNAY ROCQUENCOURT (78150)", value: "78150" },
  { label: "LE CHESNAY ROCQUENCOURT (78150)", value: "78150" },
  { label: "LE CHESNAY ROCQUENCOURT (78150)", value: "78150" },
  { label: "CHEVREUSE (78460)", value: "78460" },
  { label: "CHOISEL (78460)", value: "78460" },
  { label: "CIVRY LA FORET (78910)", value: "78910" },
  { label: "CLAIREFONTAINE EN YVELINES (78120)", value: "78120" },
  { label: "LES CLAYES SOUS BOIS (78340)", value: "78340" },
  { label: "COIGNIERES (78310)", value: "78310" },
  { label: "CONDE SUR VESGRE (78113)", value: "78113" },
  { label: "CONFLANS STE HONORINE (78700)", value: "78700" },
  { label: "COURGENT (78790)", value: "78790" },
  { label: "CRAVENT (78270)", value: "78270" },
  { label: "CRESPIERES (78121)", value: "78121" },
  { label: "CROISSY SUR SEINE (78290)", value: "78290" },
  { label: "DAMMARTIN EN SERVE (78111)", value: "78111" },
  { label: "DAMPIERRE EN YVELINES (78720)", value: "78720" },
  { label: "DAMPIERRE EN YVELINES (78720)", value: "78720" },
  { label: "DANNEMARIE (78550)", value: "78550" },
  { label: "DAVRON (78810)", value: "78810" },
  { label: "DROCOURT (78440)", value: "78440" },
  { label: "ECQUEVILLY (78920)", value: "78920" },
  { label: "ELANCOURT (78990)", value: "78990" },
  { label: "EMANCE (78125)", value: "78125" },
  { label: "EPONE (78680)", value: "78680" },
  { label: "LES ESSARTS LE ROI (78690)", value: "78690" },
  { label: "LES ESSARTS LE ROI (78690)", value: "78690" },
  { label: "L'ETANG LA VILLE (78620)", value: "78620" },
  { label: "EVECQUEMONT (78740)", value: "78740" },
  { label: "LA FALAISE (78410)", value: "78410" },
  { label: "FAVRIEUX (78200)", value: "78200" },
  { label: "FEUCHEROLLES (78810)", value: "78810" },
  { label: "FLACOURT (78200)", value: "78200" },
  { label: "FLEXANVILLE (78910)", value: "78910" },
  { label: "FLINS NEUVE EGLISE (78790)", value: "78790" },
  { label: "FLINS SUR SEINE (78410)", value: "78410" },
  { label: "FOLLAINVILLE DENNEMONT (78520)", value: "78520" },
  { label: "FONTENAY LE FLEURY (78330)", value: "78330" },
  { label: "FONTENAY MAUVOISIN (78200)", value: "78200" },
  { label: "FONTENAY ST PERE (78440)", value: "78440" },
  { label: "FRENEUSE (78840)", value: "78840" },
  { label: "GAILLON SUR MONTCIENT (78250)", value: "78250" },
  { label: "GALLUIS (78490)", value: "78490" },
  { label: "GAMBAIS (78950)", value: "78950" },
  { label: "GAMBAISEUIL (78490)", value: "78490" },
  { label: "GARANCIERES (78890)", value: "78890" },
  { label: "GARGENVILLE (78440)", value: "78440" },
  { label: "GAZERAN (78125)", value: "78125" },
  { label: "GOMMECOURT (78270)", value: "78270" },
  { label: "GOUPILLIERES (78770)", value: "78770" },
  { label: "GOUSSONVILLE (78930)", value: "78930" },
  { label: "GRANDCHAMP (78113)", value: "78113" },
  { label: "GRESSEY (78550)", value: "78550" },
  { label: "GROSROUVRE (78490)", value: "78490" },
  { label: "GUERNES (78520)", value: "78520" },
  { label: "GUERVILLE (78930)", value: "78930" },
  { label: "GUITRANCOURT (78440)", value: "78440" },
  { label: "GUYANCOURT (78280)", value: "78280" },
  { label: "GUYANCOURT (78280)", value: "78280" },
  { label: "GUYANCOURT (78280)", value: "78280" },
  { label: "HARDRICOURT (78250)", value: "78250" },
  { label: "HARGEVILLE (78790)", value: "78790" },
  { label: "LA HAUTEVILLE (78113)", value: "78113" },
  { label: "HERBEVILLE (78580)", value: "78580" },
  { label: "HERMERAY (78125)", value: "78125" },
  { label: "HOUDAN (78550)", value: "78550" },
  { label: "HOUILLES (78800)", value: "78800" },
  { label: "ISSOU (78440)", value: "78440" },
  { label: "JAMBVILLE (78440)", value: "78440" },
  { label: "NOTRE DAME DE LA MER (78270)", value: "78270" },
  { label: "NOTRE DAME DE LA MER (78270)", value: "78270" },
  { label: "JOUARS PONTCHARTRAIN (78760)", value: "78760" },
  { label: "JOUY EN JOSAS (78350)", value: "78350" },
  { label: "JOUY MAUVOISIN (78200)", value: "78200" },
  { label: "JUMEAUVILLE (78580)", value: "78580" },
  { label: "JUZIERS (78820)", value: "78820" },
  { label: "LAINVILLE EN VEXIN (78440)", value: "78440" },
  { label: "LEVIS ST NOM (78320)", value: "78320" },
  { label: "LIMAY (78520)", value: "78520" },
  { label: "LIMETZ VILLEZ (78270)", value: "78270" },
  { label: "LES LOGES EN JOSAS (78350)", value: "78350" },
  { label: "LOMMOYE (78270)", value: "78270" },
  { label: "LONGNES (78980)", value: "78980" },
  { label: "LONGVILLIERS (78730)", value: "78730" },
  { label: "LOUVECIENNES (78430)", value: "78430" },
  { label: "MAGNANVILLE (78200)", value: "78200" },
  { label: "MAGNY LES HAMEAUX (78114)", value: "78114" },
  { label: "MAGNY LES HAMEAUX (78114)", value: "78114" },
  { label: "MAISONS LAFFITTE (78600)", value: "78600" },
  { label: "MANTES LA JOLIE (78200)", value: "78200" },
  { label: "MANTES LA VILLE (78711)", value: "78711" },
  { label: "MARCQ (78770)", value: "78770" },
  { label: "MAREIL LE GUYON (78490)", value: "78490" },
  { label: "MAREIL MARLY (78750)", value: "78750" },
  { label: "MAREIL SUR MAULDRE (78124)", value: "78124" },
  { label: "MARLY LE ROI (78160)", value: "78160" },
  { label: "MARLY LE ROI (78160)", value: "78160" },
  { label: "MAULE (78580)", value: "78580" },
  { label: "MAULETTE (78550)", value: "78550" },
  { label: "MAULETTE (78550)", value: "78550" },
  { label: "MAURECOURT (78780)", value: "78780" },
  { label: "MAUREPAS (78310)", value: "78310" },
  { label: "MEDAN (78670)", value: "78670" },
  { label: "MENERVILLE (78200)", value: "78200" },
  { label: "MERE (78490)", value: "78490" },
  { label: "MERICOURT (78270)", value: "78270" },
  { label: "LE MESNIL LE ROI (78600)", value: "78600" },
  { label: "LE MESNIL LE ROI (78600)", value: "78600" },
  { label: "LE MESNIL ST DENIS (78320)", value: "78320" },
  { label: "LES MESNULS (78490)", value: "78490" },
  { label: "MEULAN EN YVELINES (78250)", value: "78250" },
  { label: "MEZIERES SUR SEINE (78970)", value: "78970" },
  { label: "MEZY SUR SEINE (78250)", value: "78250" },
  { label: "MILLEMONT (78940)", value: "78940" },
  { label: "MILON LA CHAPELLE (78470)", value: "78470" },
  { label: "MITTAINVILLE (78125)", value: "78125" },
  { label: "MOISSON (78840)", value: "78840" },
  { label: "MONDREVILLE (78980)", value: "78980" },
  { label: "MONTAINVILLE (78124)", value: "78124" },
  { label: "MONTALET LE BOIS (78440)", value: "78440" },
  { label: "MONTCHAUVET (78790)", value: "78790" },
  { label: "MONTESSON (78360)", value: "78360" },
  { label: "MONTFORT L AMAURY (78490)", value: "78490" },
  { label: "MONTIGNY LE BRETONNEUX (78180)", value: "78180" },
  { label: "MORAINVILLIERS (78630)", value: "78630" },
  { label: "MOUSSEAUX SUR SEINE (78270)", value: "78270" },
  { label: "MULCENT (78790)", value: "78790" },
  { label: "LES MUREAUX (78130)", value: "78130" },
  { label: "NEAUPHLE LE CHATEAU (78640)", value: "78640" },
  { label: "NEAUPHLE LE VIEUX (78640)", value: "78640" },
  { label: "NEAUPHLETTE (78980)", value: "78980" },
  { label: "NEZEL (78410)", value: "78410" },
  { label: "NOISY LE ROI (78590)", value: "78590" },
  { label: "OINVILLE SUR MONTCIENT (78250)", value: "78250" },
  { label: "ORCEMONT (78125)", value: "78125" },
  { label: "ORGERUS (78910)", value: "78910" },
  { label: "ORGEVAL (78630)", value: "78630" },
  { label: "ORPHIN (78125)", value: "78125" },
  { label: "ORSONVILLE (78660)", value: "78660" },
  { label: "ORVILLIERS (78910)", value: "78910" },
  { label: "OSMOY (78910)", value: "78910" },
  { label: "PARAY DOUAVILLE (78660)", value: "78660" },
  { label: "LE PECQ (78230)", value: "78230" },
  { label: "PERDREAUVILLE (78200)", value: "78200" },
  { label: "LE PERRAY EN YVELINES (78610)", value: "78610" },
  { label: "PLAISIR (78370)", value: "78370" },
  { label: "POIGNY LA FORET (78125)", value: "78125" },
  { label: "POISSY (78300)", value: "78300" },
  { label: "POISSY (78300)", value: "78300" },
  { label: "PONTHEVRARD (78730)", value: "78730" },
  { label: "PORCHEVILLE (78440)", value: "78440" },
  { label: "LE PORT MARLY (78560)", value: "78560" },
  { label: "PRUNAY LE TEMPLE (78910)", value: "78910" },
  { label: "PRUNAY EN YVELINES (78660)", value: "78660" },
  { label: "PRUNAY EN YVELINES (78660)", value: "78660" },
  { label: "LA QUEUE LES YVELINES (78940)", value: "78940" },
  { label: "RAIZEUX (78125)", value: "78125" },
  { label: "RAMBOUILLET (78120)", value: "78120" },
  { label: "RENNEMOULIN (78590)", value: "78590" },
  { label: "RICHEBOURG (78550)", value: "78550" },
  { label: "ROCHEFORT EN YVELINES (78730)", value: "78730" },
  { label: "ROLLEBOISE (78270)", value: "78270" },
  { label: "ROSAY (78790)", value: "78790" },
  { label: "ROSNY SUR SEINE (78710)", value: "78710" },
  { label: "SAILLY (78440)", value: "78440" },
  { label: "ST ARNOULT EN YVELINES (78730)", value: "78730" },
  { label: "ST CYR L ECOLE (78210)", value: "78210" },
  { label: "ST FORGET (78720)", value: "78720" },
  { label: "ST GERMAIN DE LA GRANGE (78640)", value: "78640" },
  { label: "ST GERMAIN EN LAYE (78100)", value: "78100" },
  { label: "ST GERMAIN EN LAYE (78112)", value: "78112" },
  { label: "ST HILARION (78125)", value: "78125" },
  { label: "ST ILLIERS LA VILLE (78980)", value: "78980" },
  { label: "ST ILLIERS LE BOIS (78980)", value: "78980" },
  { label: "ST LAMBERT (78470)", value: "78470" },
  { label: "ST LEGER EN YVELINES (78610)", value: "78610" },
  { label: "ST MARTIN DE BRETHENCOURT (78660)", value: "78660" },
  { label: "ST MARTIN DES CHAMPS (78790)", value: "78790" },
  { label: "ST MARTIN LA GARENNE (78520)", value: "78520" },
  { label: "STE MESME (78730)", value: "78730" },
  { label: "ST NOM LA BRETECHE (78860)", value: "78860" },
  { label: "ST NOM LA BRETECHE (78860)", value: "78860" },
  { label: "ST REMY LES CHEVREUSE (78470)", value: "78470" },
  { label: "ST REMY LES CHEVREUSE (78470)", value: "78470" },
  { label: "ST REMY L HONORE (78690)", value: "78690" },
  { label: "SARTROUVILLE (78500)", value: "78500" },
  { label: "SAULX MARCHAIS (78650)", value: "78650" },
  { label: "SENLISSE (78720)", value: "78720" },
  { label: "SEPTEUIL (78790)", value: "78790" },
  { label: "SOINDRES (78200)", value: "78200" },
  { label: "SONCHAMP (78120)", value: "78120" },
  { label: "TACOIGNIERES (78910)", value: "78910" },
  { label: "LE TARTRE GAUDRAN (78113)", value: "78113" },
  { label: "LE TERTRE ST DENIS (78980)", value: "78980" },
  { label: "TESSANCOURT SUR AUBETTE (78250)", value: "78250" },
  { label: "THIVERVAL GRIGNON (78850)", value: "78850" },
  { label: "THOIRY (78770)", value: "78770" },
  { label: "TILLY (78790)", value: "78790" },
  { label: "TOUSSUS LE NOBLE (78117)", value: "78117" },
  { label: "TRAPPES (78190)", value: "78190" },
  { label: "LE TREMBLAY SUR MAULDRE (78490)", value: "78490" },
  { label: "TRIEL SUR SEINE (78510)", value: "78510" },
  { label: "TRIEL SUR SEINE (78510)", value: "78510" },
  { label: "TRIEL SUR SEINE (78510)", value: "78510" },
  { label: "VAUX SUR SEINE (78740)", value: "78740" },
  { label: "VELIZY VILLACOUBLAY (78140)", value: "78140" },
  { label: "VERNEUIL SUR SEINE (78480)", value: "78480" },
  { label: "VERNOUILLET (78540)", value: "78540" },
  { label: "VERNOUILLET (78540)", value: "78540" },
  { label: "LA VERRIERE (78320)", value: "78320" },
  { label: "VERSAILLES (78000)", value: "78000" },
  { label: "VERT (78930)", value: "78930" },
  { label: "LE VESINET (78110)", value: "78110" },
  { label: "VICQ (78490)", value: "78490" },
  { label: "VIEILLE EGLISE EN YVELINES (78125)", value: "78125" },
  { label: "LA VILLENEUVE EN CHEVRIE (78270)", value: "78270" },
  { label: "VILLENNES SUR SEINE (78670)", value: "78670" },
  { label: "VILLEPREUX (78450)", value: "78450" },
  { label: "VILLETTE (78930)", value: "78930" },
  { label: "VILLIERS LE MAHIEU (78770)", value: "78770" },
  { label: "VILLIERS ST FREDERIC (78640)", value: "78640" },
  { label: "VIROFLAY (78220)", value: "78220" },
  { label: "VOISINS LE BRETONNEUX (78960)", value: "78960" },
  { label: "L'ABSIE (79240)", value: "79240" },
  { label: "ADILLY (79200)", value: "79200" },
  { label: "AIFFRES (79230)", value: "79230" },
  { label: "AIRVAULT (79600)", value: "79600" },
  { label: "AIRVAULT (79600)", value: "79600" },
  { label: "AIRVAULT (79600)", value: "79600" },
  { label: "AIRVAULT (79600)", value: "79600" },
  { label: "ALLONNE (79130)", value: "79130" },
  { label: "AMAILLOUX (79350)", value: "79350" },
  { label: "AMURE (79210)", value: "79210" },
  { label: "ARCAIS (79210)", value: "79210" },
  { label: "ARDIN (79160)", value: "79160" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79150)", value: "79150" },
  { label: "ARGENTONNAY (79300)", value: "79300" },
  { label: "LORETZ D ARGENTON (79290)", value: "79290" },
  { label: "LORETZ D ARGENTON (79290)", value: "79290" },
  { label: "LORETZ D ARGENTON (79290)", value: "79290" },
  { label: "ASNIERES EN POITOU (79170)", value: "79170" },
  { label: "ASSAIS LES JUMEAUX (79600)", value: "79600" },
  { label: "ASSAIS LES JUMEAUX (79600)", value: "79600" },
  { label: "AUBIGNE (79110)", value: "79110" },
  { label: "AUBIGNY (79390)", value: "79390" },
  { label: "AUGE (79400)", value: "79400" },
  { label: "AVAILLES THOUARSAIS (79600)", value: "79600" },
  { label: "AVON (79800)", value: "79800" },
  { label: "AZAY LE BRULE (79400)", value: "79400" },
  { label: "AZAY SUR THOUET (79130)", value: "79130" },
  { label: "BEAULIEU SOUS PARTHENAY (79420)", value: "79420" },
  { label: "BEAUSSAIS VITRE (79370)", value: "79370" },
  { label: "BEAUSSAIS VITRE (79370)", value: "79370" },
  { label: "BEAUVOIR SUR NIORT (79360)", value: "79360" },
  { label: "BEAUVOIR SUR NIORT (79360)", value: "79360" },
  { label: "BEAUVOIR SUR NIORT (79360)", value: "79360" },
  { label: "BECELEUF (79160)", value: "79160" },
  { label: "BESSINES (79000)", value: "79000" },
  { label: "BOISME (79300)", value: "79300" },
  { label: "LA BOISSIERE EN GATINE (79310)", value: "79310" },
  { label: "BOUGON (79800)", value: "79800" },
  { label: "LE BOURDET (79210)", value: "79210" },
  { label: "BOUSSAIS (79600)", value: "79600" },
  { label: "LA CRECHE (79260)", value: "79260" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRESSUIRE (79300)", value: "79300" },
  { label: "BRETIGNOLLES (79140)", value: "79140" },
  { label: "BRIEUIL SUR CHIZE (79170)", value: "79170" },
  { label: "BRION PRES THOUET (79290)", value: "79290" },
  { label: "BRIOUX SUR BOUTONNE (79170)", value: "79170" },
  { label: "BRULAIN (79230)", value: "79230" },
  { label: "LE BUSSEAU (79240)", value: "79240" },
  { label: "CAUNAY (79190)", value: "79190" },
  { label: "CELLES SUR BELLE (79370)", value: "79370" },
  { label: "CELLES SUR BELLE (79370)", value: "79370" },
  { label: "CELLES SUR BELLE (79370)", value: "79370" },
  { label: "CELLES SUR BELLE (79370)", value: "79370" },
  { label: "CERIZAY (79140)", value: "79140" },
  { label: "VAL EN VIGNES (79150)", value: "79150" },
  { label: "VAL EN VIGNES (79290)", value: "79290" },
  { label: "VAL EN VIGNES (79290)", value: "79290" },
  { label: "VAL EN VIGNES (79290)", value: "79290" },
  { label: "FONTIVILLIE (79110)", value: "79110" },
  { label: "FONTIVILLIE (79500)", value: "79500" },
  { label: "CHAMPDENIERS (79220)", value: "79220" },
  { label: "CHAMPDENIERS (79220)", value: "79220" },
  { label: "CHAMPDENIERS (79220)", value: "79220" },
  { label: "CHANTELOUP (79320)", value: "79320" },
  { label: "LA CHAPELLE BATON (79220)", value: "79220" },
  { label: "LA CHAPELLE BERTRAND (79200)", value: "79200" },
  { label: "LA CHAPELLE POUILLOUX (79190)", value: "79190" },
  { label: "LA CHAPELLE ST LAURENT (79430)", value: "79430" },
  { label: "BEUGNON THIREUIL (79130)", value: "79130" },
  { label: "BEUGNON THIREUIL (79160)", value: "79160" },
  { label: "PLAINE D ARGENSON (79360)", value: "79360" },
  { label: "PLAINE D ARGENSON (79360)", value: "79360" },
  { label: "PLAINE D ARGENSON (79360)", value: "79360" },
  { label: "PLAINE D ARGENSON (79360)", value: "79360" },
  { label: "PLAINE D ARGENSON (79360)", value: "79360" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "MAULEON (79700)", value: "79700" },
  { label: "CHATILLON SUR THOUET (79200)", value: "79200" },
  { label: "CHAURAY (79180)", value: "79180" },
  { label: "CHEF BOUTONNE (79110)", value: "79110" },
  { label: "CHEF BOUTONNE (79110)", value: "79110" },
  { label: "CHEF BOUTONNE (79110)", value: "79110" },
  { label: "CHEF BOUTONNE (79110)", value: "79110" },
  { label: "CHENAY (79120)", value: "79120" },
  { label: "CHERIGNE (79170)", value: "79170" },
  { label: "CHERVEUX (79410)", value: "79410" },
  { label: "CHEY (79120)", value: "79120" },
  { label: "CHICHE (79350)", value: "79350" },
  { label: "LE CHILLOU (79600)", value: "79600" },
  { label: "CHIZE (79170)", value: "79170" },
  { label: "CHIZE (79170)", value: "79170" },
  { label: "CIRIERES (79140)", value: "79140" },
  { label: "CLAVE (79420)", value: "79420" },
  { label: "CLESSE (79350)", value: "79350" },
  { label: "CLUSSAIS LA POMMERAIE (79190)", value: "79190" },
  { label: "COMBRAND (79140)", value: "79140" },
  { label: "COULON (79510)", value: "79510" },
  { label: "COULONGES SUR L AUTIZE (79160)", value: "79160" },
  { label: "COULONGES THOUARSAIS (79330)", value: "79330" },
  { label: "COURLAY (79440)", value: "79440" },
  { label: "COURS (79220)", value: "79220" },
  { label: "LES CHATELIERS (79340)", value: "79340" },
  { label: "LES CHATELIERS (79340)", value: "79340" },
  { label: "COUTURE D ARGENSON (79110)", value: "79110" },
  { label: "DOUX (79390)", value: "79390" },
  { label: "ECHIRE (79410)", value: "79410" },
  { label: "ENSIGNE (79170)", value: "79170" },
  { label: "EPANNES (79270)", value: "79270" },
  { label: "EXIREUIL (79400)", value: "79400" },
  { label: "EXOUDUN (79800)", value: "79800" },
  { label: "FAYE L ABBESSE (79350)", value: "79350" },
  { label: "FAYE SUR ARDIN (79160)", value: "79160" },
  { label: "FENERY (79450)", value: "79450" },
  { label: "FENIOUX (79160)", value: "79160" },
  { label: "LA FERRIERE EN PARTHENAY (79390)", value: "79390" },
  { label: "FOMPERRON (79340)", value: "79340" },
  { label: "FONTENILLE ST MARTIN D ENTRAIGUES (79110)", value: "79110" },
  { label: "FONTENILLE ST MARTIN D ENTRAIGUES (79110)", value: "79110" },
  { label: "LA FORET SUR SEVRE (79380)", value: "79380" },
  { label: "LA FORET SUR SEVRE (79380)", value: "79380" },
  { label: "LA FORET SUR SEVRE (79380)", value: "79380" },
  { label: "LA FORET SUR SEVRE (79380)", value: "79380" },
  { label: "LES FORGES (79340)", value: "79340" },
  { label: "FORS (79230)", value: "79230" },
  { label: "LES FOSSES (79360)", value: "79360" },
  { label: "LA FOYE MONJAULT (79360)", value: "79360" },
  { label: "FRANCOIS (79260)", value: "79260" },
  { label: "FRESSINES (79370)", value: "79370" },
  { label: "FRONTENAY ROHAN ROHAN (79270)", value: "79270" },
  { label: "GEAY (79330)", value: "79330" },
  { label: "GENNETON (79150)", value: "79150" },
  { label: "GERMOND ROUVRE (79220)", value: "79220" },
  { label: "GERMOND ROUVRE (79220)", value: "79220" },
  { label: "GLENAY (79330)", value: "79330" },
  { label: "GOURGE (79200)", value: "79200" },
  { label: "ALLOINAY (79110)", value: "79110" },
  { label: "ALLOINAY (79110)", value: "79110" },
  { label: "ALLOINAY (79190)", value: "79190" },
  { label: "GRANZAY GRIPT (79360)", value: "79360" },
  { label: "GRANZAY GRIPT (79360)", value: "79360" },
  { label: "LES GROSEILLERS (79220)", value: "79220" },
  { label: "VALDELAUME (79110)", value: "79110" },
  { label: "VALDELAUME (79110)", value: "79110" },
  { label: "VALDELAUME (79110)", value: "79110" },
  { label: "VALDELAUME (79110)", value: "79110" },
  { label: "IRAIS (79600)", value: "79600" },
  { label: "JUILLE (79170)", value: "79170" },
  { label: "JUSCORPS (79230)", value: "79230" },
  { label: "LAGEON (79200)", value: "79200" },
  { label: "LARGEASSE (79240)", value: "79240" },
  { label: "LEZAY (79120)", value: "79120" },
  { label: "LHOUMOIS (79390)", value: "79390" },
  { label: "LIMALONGES (79190)", value: "79190" },
  { label: "LORIGNE (79190)", value: "79190" },
  { label: "LOUBIGNE (79110)", value: "79110" },
  { label: "LOUBILLE (79110)", value: "79110" },
  { label: "LOUIN (79600)", value: "79600" },
  { label: "LOUZY (79100)", value: "79100" },
  { label: "LUCHE SUR BRIOUX (79170)", value: "79170" },
  { label: "LUCHE THOUARSAIS (79330)", value: "79330" },
  { label: "LUSSERAY (79170)", value: "79170" },
  { label: "LUZAY (79100)", value: "79100" },
  { label: "MAGNE (79460)", value: "79460" },
  { label: "MAIRE LEVESCAULT (79190)", value: "79190" },
  { label: "MAISONNAY (79500)", value: "79500" },
  { label: "MAISONTIERS (79600)", value: "79600" },
  { label: "MARIGNY (79360)", value: "79360" },
  { label: "MARNES (79600)", value: "79600" },
  { label: "MAUZE SUR LE MIGNON (79210)", value: "79210" },
  { label: "MAZIERES EN GATINE (79310)", value: "79310" },
  { label: "MELLE (79500)", value: "79500" },
  { label: "MELLE (79500)", value: "79500" },
  { label: "MELLE (79500)", value: "79500" },
  { label: "MELLE (79500)", value: "79500" },
  { label: "MELLE (79500)", value: "79500" },
  { label: "MELLE (79500)", value: "79500" },
  { label: "MELLERAN (79190)", value: "79190" },
  { label: "MENIGOUTE (79340)", value: "79340" },
  { label: "MESSE (79120)", value: "79120" },
  { label: "MONCOUTANT SUR SEVRE (79240)", value: "79240" },
  { label: "MONCOUTANT SUR SEVRE (79320)", value: "79320" },
  { label: "MONCOUTANT SUR SEVRE (79320)", value: "79320" },
  { label: "MONCOUTANT SUR SEVRE (79320)", value: "79320" },
  { label: "MONCOUTANT SUR SEVRE (79320)", value: "79320" },
  { label: "MONCOUTANT SUR SEVRE (79380)", value: "79380" },
  { label: "MONTALEMBERT (79190)", value: "79190" },
  { label: "MONTRAVERS (79140)", value: "79140" },
  { label: "LA MOTHE ST HERAY (79800)", value: "79800" },
  { label: "AIGONDIGNE (79370)", value: "79370" },
  { label: "AIGONDIGNE (79370)", value: "79370" },
  { label: "AIGONDIGNE (79370)", value: "79370" },
  { label: "AIGONDIGNE (79370)", value: "79370" },
  { label: "NANTEUIL (79400)", value: "79400" },
  { label: "NEUVY BOUIN (79130)", value: "79130" },
  { label: "NIORT (79000)", value: "79000" },
  { label: "NIORT (79000)", value: "79000" },
  { label: "NIORT (79000)", value: "79000" },
  { label: "NIORT (79000)", value: "79000" },
  { label: "NIORT (79000)", value: "79000" },
  { label: "NUEIL LES AUBIERS (79250)", value: "79250" },
  { label: "NUEIL LES AUBIERS (79250)", value: "79250" },
  { label: "PLAINE ET VALLEES (79100)", value: "79100" },
  { label: "PLAINE ET VALLEES (79100)", value: "79100" },
  { label: "PLAINE ET VALLEES (79100)", value: "79100" },
  { label: "PLAINE ET VALLEES (79100)", value: "79100" },
  { label: "PLAINE ET VALLEES (79100)", value: "79100" },
  { label: "PLAINE ET VALLEES (79100)", value: "79100" },
  { label: "PLAINE ET VALLEES (79600)", value: "79600" },
  { label: "OROUX (79390)", value: "79390" },
  { label: "PAIZAY LE CHAPT (79170)", value: "79170" },
  { label: "PAMPLIE (79220)", value: "79220" },
  { label: "PAMPROUX (79800)", value: "79800" },
  { label: "PARTHENAY (79200)", value: "79200" },
  { label: "PAS DE JEU (79100)", value: "79100" },
  { label: "PERIGNE (79170)", value: "79170" },
  { label: "PERS (79190)", value: "79190" },
  { label: "LA PETITE BOISSIERE (79700)", value: "79700" },
  { label: "LA PEYRATTE (79200)", value: "79200" },
  { label: "PIERREFITTE (79330)", value: "79330" },
  { label: "LE PIN (79140)", value: "79140" },
  { label: "PLIBOUX (79190)", value: "79190" },
  { label: "POMPAIRE (79200)", value: "79200" },
  { label: "POUGNE HERISSON (79130)", value: "79130" },
  { label: "PRAHECQ (79230)", value: "79230" },
  { label: "PRAILLES LA COUARDE (79370)", value: "79370" },
  { label: "PRAILLES LA COUARDE (79800)", value: "79800" },
  { label: "PRESSIGNY (79390)", value: "79390" },
  { label: "PRIN DEYRANCON (79210)", value: "79210" },
  { label: "PUIHARDY (79160)", value: "79160" },
  { label: "REFFANNES (79420)", value: "79420" },
  { label: "LE RETAIL (79130)", value: "79130" },
  { label: "LA ROCHENARD (79270)", value: "79270" },
  { label: "ROM (79120)", value: "79120" },
  { label: "ROMANS (79260)", value: "79260" },
  { label: "ST AMAND SUR SEVRE (79700)", value: "79700" },
  { label: "ST ANDRE SUR SEVRE (79380)", value: "79380" },
  { label: "ST AUBIN DU PLAIN (79300)", value: "79300" },
  { label: "ST AUBIN LE CLOUD (79450)", value: "79450" },
  { label: "ST CHRISTOPHE SUR ROC (79220)", value: "79220" },
  { label: "VOULMENTIN (79150)", value: "79150" },
  { label: "VOULMENTIN (79150)", value: "79150" },
  { label: "ST COUTANT (79120)", value: "79120" },
  { label: "ST CYR LA LANDE (79100)", value: "79100" },
  { label: "STE EANNE (79800)", value: "79800" },
  { label: "ST GELAIS (79410)", value: "79410" },
  { label: "STE GEMME (79330)", value: "79330" },
  { label: "MARCILLE (79500)", value: "79500" },
  { label: "MARCILLE (79500)", value: "79500" },
  { label: "ST GENEROUX (79600)", value: "79600" },
  { label: "ST GEORGES DE NOISNE (79400)", value: "79400" },
  { label: "ST GEORGES DE REX (79210)", value: "79210" },
  { label: "ST GERMAIN DE LONGUE CHAUME (79200)", value: "79200" },
  { label: "ST GERMIER (79340)", value: "79340" },
  { label: "ST HILAIRE LA PALUD (79210)", value: "79210" },
  { label: "ST JACQUES DE THOUARS (79100)", value: "79100" },
  { label: "ST JEAN DE THOUARS (79100)", value: "79100" },
  { label: "ST LAURS (79160)", value: "79160" },
  { label: "ST LEGER DE MONTBRUN (79100)", value: "79100" },
  { label: "ST LIN (79420)", value: "79420" },
  { label: "ST LOUP LAMAIRE (79600)", value: "79600" },
  { label: "ST LOUP LAMAIRE (79600)", value: "79600" },
  { label: "ST MAIXENT DE BEUGNE (79160)", value: "79160" },
  { label: "ST MAIXENT L ECOLE (79400)", value: "79400" },
  { label: "ST MARC LA LANDE (79310)", value: "79310" },
  { label: "ST MARTIN DE BERNEGOUE (79230)", value: "79230" },
  { label: "ST MARTIN DE MACON (79100)", value: "79100" },
  { label: "ST MARTIN DE ST MAIXENT (79400)", value: "79400" },
  { label: "ST MARTIN DE SANZAY (79290)", value: "79290" },
  { label: "ST MARTIN DU FOUILLOUX (79420)", value: "79420" },
  { label: "ST MAURICE ETUSSON (79150)", value: "79150" },
  { label: "ST MAURICE ETUSSON (79150)", value: "79150" },
  { label: "ST MAXIRE (79410)", value: "79410" },
  { label: "STE NEOMAYE (79260)", value: "79260" },
  { label: "STE OUENNE (79220)", value: "79220" },
  { label: "ST PARDOUX SOUTIERS (79310)", value: "79310" },
  { label: "ST PARDOUX SOUTIERS (79310)", value: "79310" },
  { label: "ST PAUL EN GATINE (79240)", value: "79240" },
  { label: "ST PIERRE DES ECHAUBROGNES (79700)", value: "79700" },
  { label: "ST POMPAIN (79160)", value: "79160" },
  { label: "ST REMY (79410)", value: "79410" },
  { label: "ST ROMANS DES CHAMPS (79230)", value: "79230" },
  { label: "ST ROMANS LES MELLE (79500)", value: "79500" },
  { label: "STE SOLINE (79120)", value: "79120" },
  { label: "ST SYMPHORIEN (79270)", value: "79270" },
  { label: "ST VARENT (79330)", value: "79330" },
  { label: "STE VERGE (79100)", value: "79100" },
  { label: "ST VINCENT LA CHATRE (79500)", value: "79500" },
  { label: "SAIVRES (79400)", value: "79400" },
  { label: "SALLES (79800)", value: "79800" },
  { label: "SANSAIS (79270)", value: "79270" },
  { label: "SAURAIS (79200)", value: "79200" },
  { label: "SAUZE VAUSSAIS (79190)", value: "79190" },
  { label: "SCIECQ (79000)", value: "79000" },
  { label: "SCILLE (79240)", value: "79240" },
  { label: "SECONDIGNE SUR BELLE (79170)", value: "79170" },
  { label: "SECONDIGNY (79130)", value: "79130" },
  { label: "SELIGNE (79170)", value: "79170" },
  { label: "SEPVRET (79120)", value: "79120" },
  { label: "SOUDAN (79800)", value: "79800" },
  { label: "SOUVIGNE (79800)", value: "79800" },
  { label: "SURIN (79220)", value: "79220" },
  { label: "LE TALLUD (79200)", value: "79200" },
  { label: "THENEZAY (79390)", value: "79390" },
  { label: "THOUARS (79100)", value: "79100" },
  { label: "THOUARS (79100)", value: "79100" },
  { label: "THOUARS (79100)", value: "79100" },
  { label: "THOUARS (79100)", value: "79100" },
  { label: "THOUARS (79100)", value: "79100" },
  { label: "TOURTENAY (79100)", value: "79100" },
  { label: "TRAYES (79240)", value: "79240" },
  { label: "VAL DU MIGNON (79210)", value: "79210" },
  { label: "VAL DU MIGNON (79210)", value: "79210" },
  { label: "VAL DU MIGNON (79360)", value: "79360" },
  { label: "VALLANS (79270)", value: "79270" },
  { label: "VANCAIS (79120)", value: "79120" },
  { label: "LE VANNEAU IRLEAU (79270)", value: "79270" },
  { label: "VANZAY (79120)", value: "79120" },
  { label: "VASLES (79340)", value: "79340" },
  { label: "VAUSSEROUX (79420)", value: "79420" },
  { label: "VAUTEBIS (79420)", value: "79420" },
  { label: "VERNOUX EN GATINE (79240)", value: "79240" },
  { label: "VERNOUX SUR BOUTONNE (79170)", value: "79170" },
  { label: "VERRUYES (79310)", value: "79310" },
  { label: "LE VERT (79170)", value: "79170" },
  { label: "VIENNAY (79200)", value: "79200" },
  { label: "VILLEFOLLET (79170)", value: "79170" },
  { label: "VILLEMAIN (79110)", value: "79110" },
  { label: "VILLIERS EN BOIS (79360)", value: "79360" },
  { label: "VILLIERS EN PLAINE (79160)", value: "79160" },
  { label: "VILLIERS SUR CHIZE (79170)", value: "79170" },
  { label: "VOUHE (79310)", value: "79310" },
  { label: "VOUILLE (79230)", value: "79230" },
  { label: "XAINTRAY (79220)", value: "79220" },
  { label: "ABBEVILLE (80100)", value: "80100" },
  { label: "ABLAINCOURT PRESSOIR (80320)", value: "80320" },
  { label: "ACHEUX EN AMIENOIS (80560)", value: "80560" },
  { label: "ACHEUX EN VIMEU (80210)", value: "80210" },
  { label: "AGENVILLE (80370)", value: "80370" },
  { label: "AGENVILLERS (80150)", value: "80150" },
  { label: "AIGNEVILLE (80210)", value: "80210" },
  { label: "AILLY LE HAUT CLOCHER (80690)", value: "80690" },
  { label: "AILLY SUR NOYE (80250)", value: "80250" },
  { label: "AILLY SUR SOMME (80470)", value: "80470" },
  { label: "AIRAINES (80270)", value: "80270" },
  { label: "AIRAINES (80270)", value: "80270" },
  { label: "AIZECOURT LE BAS (80240)", value: "80240" },
  { label: "AIZECOURT LE HAUT (80200)", value: "80200" },
  { label: "ALBERT (80300)", value: "80300" },
  { label: "ALLAINES (80200)", value: "80200" },
  { label: "ALLENAY (80130)", value: "80130" },
  { label: "ALLERY (80270)", value: "80270" },
  { label: "ALLONVILLE (80260)", value: "80260" },
  { label: "AMIENS (80000)", value: "80000" },
  { label: "AMIENS (80080)", value: "80080" },
  { label: "AMIENS (80090)", value: "80090" },
  { label: "ANDAINVILLE (80140)", value: "80140" },
  { label: "ANDECHY (80700)", value: "80700" },
  { label: "ARGOEUVES (80470)", value: "80470" },
  { label: "ARGOULES (80120)", value: "80120" },
  { label: "ARGUEL (80140)", value: "80140" },
  { label: "ARMANCOURT (80700)", value: "80700" },
  { label: "ARQUEVES (80560)", value: "80560" },
  { label: "ARREST (80820)", value: "80820" },
  { label: "ARRY (80120)", value: "80120" },
  { label: "ARVILLERS (80910)", value: "80910" },
  { label: "ASSAINVILLERS (80500)", value: "80500" },
  { label: "ASSEVILLERS (80200)", value: "80200" },
  { label: "ATHIES (80200)", value: "80200" },
  { label: "AUBERCOURT (80110)", value: "80110" },
  { label: "AUBIGNY (80800)", value: "80800" },
  { label: "AUBVILLERS (80110)", value: "80110" },
  { label: "AUCHONVILLERS (80560)", value: "80560" },
  { label: "AULT (80460)", value: "80460" },
  { label: "AUMATRE (80140)", value: "80140" },
  { label: "AUMONT (80640)", value: "80640" },
  { label: "AUTHEUX (80600)", value: "80600" },
  { label: "AUTHIE (80560)", value: "80560" },
  { label: "AUTHIEULE (80600)", value: "80600" },
  { label: "AUTHUILLE (80300)", value: "80300" },
  { label: "AVELESGES (80270)", value: "80270" },
  { label: "AVELUY (80300)", value: "80300" },
  { label: "AVESNES CHAUSSOY (80140)", value: "80140" },
  { label: "AYENCOURT (80500)", value: "80500" },
  { label: "BACOUEL SUR SELLE (80480)", value: "80480" },
  { label: "BAILLEUL (80490)", value: "80490" },
  { label: "BAIZIEUX (80300)", value: "80300" },
  { label: "BALATRE (80700)", value: "80700" },
  { label: "BARLEUX (80200)", value: "80200" },
  { label: "BARLY (80600)", value: "80600" },
  { label: "BAVELINCOURT (80260)", value: "80260" },
  { label: "BAYENCOURT (80560)", value: "80560" },
  { label: "BAYONVILLERS (80170)", value: "80170" },
  { label: "BAZENTIN (80300)", value: "80300" },
  { label: "BEALCOURT (80370)", value: "80370" },
  { label: "BEAUCAMPS LE JEUNE (80430)", value: "80430" },
  { label: "BEAUCAMPS LE VIEUX (80430)", value: "80430" },
  { label: "BEAUCHAMPS (80770)", value: "80770" },
  { label: "BEAUCOURT EN SANTERRE (80110)", value: "80110" },
  { label: "BEAUCOURT SUR L ANCRE (80300)", value: "80300" },
  { label: "BEAUCOURT SUR L HALLUE (80260)", value: "80260" },
  { label: "BEAUFORT EN SANTERRE (80170)", value: "80170" },
  { label: "BEAUMETZ (80370)", value: "80370" },
  { label: "BEAUMONT HAMEL (80300)", value: "80300" },
  { label: "BEAUQUESNE (80600)", value: "80600" },
  { label: "BEAUVAL (80630)", value: "80630" },
  { label: "BECORDEL BECOURT (80300)", value: "80300" },
  { label: "BECQUIGNY (80500)", value: "80500" },
  { label: "BEHEN (80870)", value: "80870" },
  { label: "BEHENCOURT (80260)", value: "80260" },
  { label: "BELLANCOURT (80132)", value: "80132" },
  { label: "BELLEUSE (80160)", value: "80160" },
  { label: "BELLOY EN SANTERRE (80200)", value: "80200" },
  { label: "BELLOY ST LEONARD (80270)", value: "80270" },
  { label: "BELLOY SUR SOMME (80310)", value: "80310" },
  { label: "BERGICOURT (80290)", value: "80290" },
  { label: "BERMESNIL (80140)", value: "80140" },
  { label: "BERMESNIL (80140)", value: "80140" },
  { label: "BERNATRE (80370)", value: "80370" },
  { label: "BERNAVILLE (80370)", value: "80370" },
  { label: "BERNAVILLE (80370)", value: "80370" },
  { label: "BERNAY EN PONTHIEU (80120)", value: "80120" },
  { label: "BERNES (80240)", value: "80240" },
  { label: "BERNEUIL (80620)", value: "80620" },
  { label: "BERNY EN SANTERRE (80200)", value: "80200" },
  { label: "BERTANGLES (80260)", value: "80260" },
  { label: "BERTEAUCOURT LES DAMES (80850)", value: "80850" },
  { label: "BERTEAUCOURT LES THENNES (80110)", value: "80110" },
  { label: "BERTRANCOURT (80560)", value: "80560" },
  { label: "BETHENCOURT SUR MER (80130)", value: "80130" },
  { label: "BETHENCOURT SUR SOMME (80190)", value: "80190" },
  { label: "BETTEMBOS (80290)", value: "80290" },
  { label: "BETTENCOURT RIVIERE (80270)", value: "80270" },
  { label: "BETTENCOURT ST OUEN (80610)", value: "80610" },
  { label: "BEUVRAIGNES (80700)", value: "80700" },
  { label: "BIACHES (80200)", value: "80200" },
  { label: "BIARRE (80190)", value: "80190" },
  { label: "BIENCOURT (80140)", value: "80140" },
  { label: "BILLANCOURT (80190)", value: "80190" },
  { label: "BLANGY SOUS POIX (80290)", value: "80290" },
  { label: "BLANGY TRONVILLE (80440)", value: "80440" },
  { label: "BOISBERGUES (80600)", value: "80600" },
  { label: "LE BOISLE (80150)", value: "80150" },
  { label: "BOISMONT (80230)", value: "80230" },
  { label: "BONNAY (80800)", value: "80800" },
  { label: "BONNEVILLE (80670)", value: "80670" },
  { label: "BOSQUEL (80160)", value: "80160" },
  { label: "BOUCHAVESNES BERGEN (80200)", value: "80200" },
  { label: "BOUCHOIR (80910)", value: "80910" },
  { label: "BOUCHON (80830)", value: "80830" },
  { label: "BOUFFLERS (80150)", value: "80150" },
  { label: "BOUGAINVILLE (80540)", value: "80540" },
  { label: "BOUILLANCOURT EN SERY (80220)", value: "80220" },
  { label: "BOUILLANCOURT LA BATAILLE (80500)", value: "80500" },
  { label: "BOUQUEMAISON (80600)", value: "80600" },
  { label: "BOURDON (80310)", value: "80310" },
  { label: "BOURSEVILLE (80130)", value: "80130" },
  { label: "BOUSSICOURT (80500)", value: "80500" },
  { label: "BOUTTENCOURT (80220)", value: "80220" },
  { label: "BOUVAINCOURT SUR BRESLE (80220)", value: "80220" },
  { label: "BOUVINCOURT EN VERMANDOIS (80200)", value: "80200" },
  { label: "BOUZINCOURT (80300)", value: "80300" },
  { label: "BOVELLES (80540)", value: "80540" },
  { label: "BOVES (80440)", value: "80440" },
  { label: "BRACHES (80110)", value: "80110" },
  { label: "BRAILLY CORNEHOTTE (80150)", value: "80150" },
  { label: "BRASSY (80160)", value: "80160" },
  { label: "BRAY LES MAREUIL (80580)", value: "80580" },
  { label: "BRAY SUR SOMME (80340)", value: "80340" },
  { label: "BREILLY (80470)", value: "80470" },
  { label: "BRESLE (80300)", value: "80300" },
  { label: "BREUIL (80400)", value: "80400" },
  { label: "BREVILLERS (80600)", value: "80600" },
  { label: "BRIE (80200)", value: "80200" },
  { label: "BRIQUEMESNIL FLOXICOURT (80540)", value: "80540" },
  { label: "BROCOURT (80430)", value: "80430" },
  { label: "BROUCHY (80400)", value: "80400" },
  { label: "BRUCAMPS (80690)", value: "80690" },
  { label: "BRUTELLES (80230)", value: "80230" },
  { label: "BUIGNY L ABBE (80132)", value: "80132" },
  { label: "BUIGNY LES GAMACHES (80220)", value: "80220" },
  { label: "BUIGNY ST MACLOU (80132)", value: "80132" },
  { label: "BUIRE COURCELLES (80200)", value: "80200" },
  { label: "BUIRE SUR L ANCRE (80300)", value: "80300" },
  { label: "BUS LA MESIERE (80700)", value: "80700" },
  { label: "BUS LES ARTOIS (80560)", value: "80560" },
  { label: "BUSSU (80200)", value: "80200" },
  { label: "BUSSUS BUSSUEL (80135)", value: "80135" },
  { label: "BUSSY LES DAOURS (80800)", value: "80800" },
  { label: "BUSSY LES POIX (80290)", value: "80290" },
  { label: "BUVERCHY (80400)", value: "80400" },
  { label: "CACHY (80800)", value: "80800" },
  { label: "CAGNY (80330)", value: "80330" },
  { label: "CAHON (80132)", value: "80132" },
  { label: "CAIX (80170)", value: "80170" },
  { label: "CAMBRON (80132)", value: "80132" },
  { label: "CAMON (80450)", value: "80450" },
  { label: "CAMPS EN AMIENOIS (80540)", value: "80540" },
  { label: "CANAPLES (80670)", value: "80670" },
  { label: "CANCHY (80150)", value: "80150" },
  { label: "CANDAS (80750)", value: "80750" },
  { label: "CANNESSIERES (80140)", value: "80140" },
  { label: "CANTIGNY (80500)", value: "80500" },
  { label: "CAOURS (80132)", value: "80132" },
  { label: "CAPPY (80340)", value: "80340" },
  { label: "CARDONNETTE (80260)", value: "80260" },
  { label: "LE CARDONNOIS (80500)", value: "80500" },
  { label: "CARREPUIS (80700)", value: "80700" },
  { label: "CARTIGNY (80200)", value: "80200" },
  { label: "CAULIERES (80290)", value: "80290" },
  { label: "CAVILLON (80310)", value: "80310" },
  { label: "CAYEUX EN SANTERRE (80800)", value: "80800" },
  { label: "CAYEUX SUR MER (80410)", value: "80410" },
  { label: "CERISY BULEUX (80140)", value: "80140" },
  { label: "CERISY (80800)", value: "80800" },
  { label: "CHAMPIEN (80700)", value: "80700" },
  { label: "CHAULNES (80320)", value: "80320" },
  { label: "LA CHAUSSEE TIRANCOURT (80310)", value: "80310" },
  { label: "CHAUSSOY EPAGNY (80250)", value: "80250" },
  { label: "LA CHAVATTE (80700)", value: "80700" },
  { label: "CHEPY (80210)", value: "80210" },
  { label: "CHILLY (80170)", value: "80170" },
  { label: "CHIPILLY (80800)", value: "80800" },
  { label: "CHIRMONT (80250)", value: "80250" },
  { label: "CHUIGNES (80340)", value: "80340" },
  { label: "CHUIGNOLLES (80340)", value: "80340" },
  { label: "CITERNE (80490)", value: "80490" },
  { label: "CIZANCOURT (80200)", value: "80200" },
  { label: "CLAIRY SAULCHOIX (80540)", value: "80540" },
  { label: "CLERY SUR SOMME (80200)", value: "80200" },
  { label: "COCQUEREL (80510)", value: "80510" },
  { label: "COIGNEUX (80560)", value: "80560" },
  { label: "COISY (80260)", value: "80260" },
  { label: "COLINCAMPS (80560)", value: "80560" },
  { label: "COMBLES (80360)", value: "80360" },
  { label: "CONDE FOLIE (80890)", value: "80890" },
  { label: "CONTALMAISON (80300)", value: "80300" },
  { label: "CONTAY (80560)", value: "80560" },
  { label: "CONTEVILLE (80370)", value: "80370" },
  { label: "CONTRE (80160)", value: "80160" },
  { label: "CONTY (80160)", value: "80160" },
  { label: "CONTY (80160)", value: "80160" },
  { label: "CORBIE (80800)", value: "80800" },
  { label: "COTTENCHY (80440)", value: "80440" },
  { label: "COULLEMELLE (80250)", value: "80250" },
  { label: "COULONVILLERS (80135)", value: "80135" },
  { label: "COURCELETTE (80300)", value: "80300" },
  { label: "COURCELLES AU BOIS (80560)", value: "80560" },
  { label: "COURCELLES SOUS MOYENCOURT (80290)", value: "80290" },
  { label: "COURCELLES SOUS THOIX (80160)", value: "80160" },
  { label: "COURTEMANCHE (80500)", value: "80500" },
  { label: "CRAMONT (80370)", value: "80370" },
  { label: "CRECY EN PONTHIEU (80150)", value: "80150" },
  { label: "CRECY EN PONTHIEU (80150)", value: "80150" },
  { label: "CREMERY (80700)", value: "80700" },
  { label: "CRESSY OMENCOURT (80190)", value: "80190" },
  { label: "CREUSE (80480)", value: "80480" },
  { label: "CROIX MOLIGNEAUX (80400)", value: "80400" },
  { label: "CROIXRAULT (80290)", value: "80290" },
  { label: "LE CROTOY (80550)", value: "80550" },
  { label: "CROUY ST PIERRE (80310)", value: "80310" },
  { label: "CROUY ST PIERRE (80310)", value: "80310" },
  { label: "CURCHY (80190)", value: "80190" },
  { label: "CURCHY (80190)", value: "80190" },
  { label: "CURLU (80360)", value: "80360" },
  { label: "DAMERY (80700)", value: "80700" },
  { label: "DANCOURT POPINCOURT (80700)", value: "80700" },
  { label: "DAOURS (80800)", value: "80800" },
  { label: "DARGNIES (80570)", value: "80570" },
  { label: "DAVENESCOURT (80500)", value: "80500" },
  { label: "DEMUIN (80110)", value: "80110" },
  { label: "DERNANCOURT (80300)", value: "80300" },
  { label: "DEVISE (80200)", value: "80200" },
  { label: "DOINGT (80200)", value: "80200" },
  { label: "DOMART EN PONTHIEU (80620)", value: "80620" },
  { label: "DOMART SUR LA LUCE (80110)", value: "80110" },
  { label: "DOMESMONT (80370)", value: "80370" },
  { label: "DOMINOIS (80120)", value: "80120" },
  { label: "DOMLEGER LONGVILLERS (80370)", value: "80370" },
  { label: "DOMLEGER LONGVILLERS (80370)", value: "80370" },
  { label: "DOMMARTIN (80440)", value: "80440" },
  { label: "DOMPIERRE BECQUINCOURT (80980)", value: "80980" },
  { label: "DOMPIERRE SUR AUTHIE (80150)", value: "80150" },
  { label: "DOMQUEUR (80620)", value: "80620" },
  { label: "DOMVAST (80150)", value: "80150" },
  { label: "DOUDELAINVILLE (80140)", value: "80140" },
  { label: "DOUILLY (80400)", value: "80400" },
  { label: "DOULLENS (80600)", value: "80600" },
  { label: "DREUIL LES AMIENS (80470)", value: "80470" },
  { label: "DRIENCOURT (80240)", value: "80240" },
  { label: "DROMESNIL (80640)", value: "80640" },
  { label: "DRUCAT (80132)", value: "80132" },
  { label: "DURY (80480)", value: "80480" },
  { label: "EAUCOURT SUR SOMME (80580)", value: "80580" },
  { label: "L'ECHELLE ST AURIN (80700)", value: "80700" },
  { label: "ECLUSIER VAUX (80340)", value: "80340" },
  { label: "EMBREVILLE (80570)", value: "80570" },
  { label: "ENGLEBELMER (80300)", value: "80300" },
  { label: "ENNEMAIN (80200)", value: "80200" },
  { label: "EPAGNE EPAGNETTE (80580)", value: "80580" },
  { label: "EPAUMESNIL (80140)", value: "80140" },
  { label: "EPECAMPS (80370)", value: "80370" },
  { label: "EPEHY (80740)", value: "80740" },
  { label: "EPENANCOURT (80190)", value: "80190" },
  { label: "EPLESSIER (80290)", value: "80290" },
  { label: "EPPEVILLE (80400)", value: "80400" },
  { label: "EQUANCOURT (80360)", value: "80360" },
  { label: "EQUENNES ERAMECOURT (80290)", value: "80290" },
  { label: "EQUENNES ERAMECOURT (80290)", value: "80290" },
  { label: "ERCHES (80500)", value: "80500" },
  { label: "ERCHEU (80400)", value: "80400" },
  { label: "ERCOURT (80210)", value: "80210" },
  { label: "ERGNIES (80690)", value: "80690" },
  { label: "ERONDELLE (80580)", value: "80580" },
  { label: "ESCLAINVILLERS (80250)", value: "80250" },
  { label: "ESMERY HALLON (80400)", value: "80400" },
  { label: "ESSERTAUX (80160)", value: "80160" },
  { label: "ESTREBOEUF (80230)", value: "80230" },
  { label: "ESTREES DENIECOURT (80200)", value: "80200" },
  { label: "ESTREES LES CRECY (80150)", value: "80150" },
  { label: "ESTREES SUR NOYE (80250)", value: "80250" },
  { label: "ETALON (80190)", value: "80190" },
  { label: "ETELFAY (80500)", value: "80500" },
  { label: "ETERPIGNY (80200)", value: "80200" },
  { label: "ETINEHEM MERICOURT (80340)", value: "80340" },
  { label: "ETINEHEM MERICOURT (80340)", value: "80340" },
  { label: "L'ETOILE (80830)", value: "80830" },
  { label: "ETREJUST (80140)", value: "80140" },
  { label: "ETRICOURT MANANCOURT (80360)", value: "80360" },
  { label: "LA FALOISE (80250)", value: "80250" },
  { label: "FALVY (80190)", value: "80190" },
  { label: "FAMECHON (80290)", value: "80290" },
  { label: "FAVEROLLES (80500)", value: "80500" },
  { label: "FAVIERES (80120)", value: "80120" },
  { label: "FAY (80200)", value: "80200" },
  { label: "FERRIERES (80470)", value: "80470" },
  { label: "FESCAMPS (80500)", value: "80500" },
  { label: "FEUILLERES (80200)", value: "80200" },
  { label: "FEUQUIERES EN VIMEU (80210)", value: "80210" },
  { label: "FIENVILLERS (80750)", value: "80750" },
  { label: "FIGNIERES (80500)", value: "80500" },
  { label: "FINS (80360)", value: "80360" },
  { label: "FLAUCOURT (80200)", value: "80200" },
  { label: "FLERS (80360)", value: "80360" },
  { label: "FLERS SUR NOYE (80160)", value: "80160" },
  { label: "FLESSELLES (80260)", value: "80260" },
  { label: "FLEURY (80160)", value: "80160" },
  { label: "FLIXECOURT (80420)", value: "80420" },
  { label: "FLUY (80540)", value: "80540" },
  { label: "FOLIES (80170)", value: "80170" },
  { label: "FOLLEVILLE (80250)", value: "80250" },
  { label: "FONCHES FONCHETTE (80700)", value: "80700" },
  { label: "FONTAINE LE SEC (80140)", value: "80140" },
  { label: "FONTAINE LES CAPPY (80340)", value: "80340" },
  { label: "FONTAINE SOUS MONTDIDIER (80500)", value: "80500" },
  { label: "FONTAINE SUR MAYE (80150)", value: "80150" },
  { label: "FONTAINE SUR SOMME (80510)", value: "80510" },
  { label: "FORCEVILLE (80560)", value: "80560" },
  { label: "FORCEVILLE EN VIMEU (80140)", value: "80140" },
  { label: "FOREST L ABBAYE (80150)", value: "80150" },
  { label: "FOREST MONTIERS (80120)", value: "80120" },
  { label: "FORT MAHON PLAGE (80120)", value: "80120" },
  { label: "FOSSEMANANT (80160)", value: "80160" },
  { label: "FOUCAUCOURT EN SANTERRE (80340)", value: "80340" },
  { label: "FOUCAUCOURT HORS NESLE (80140)", value: "80140" },
  { label: "FOUENCAMPS (80440)", value: "80440" },
  { label: "FOUILLOY (80800)", value: "80800" },
  { label: "FOUQUESCOURT (80170)", value: "80170" },
  { label: "FOURCIGNY (80290)", value: "80290" },
  { label: "FOURDRINOY (80310)", value: "80310" },
  { label: "FRAMERVILLE RAINECOURT (80131)", value: "80131" },
  { label: "FRAMICOURT (80140)", value: "80140" },
  { label: "FRANCIERES (80690)", value: "80690" },
  { label: "FRANLEU (80210)", value: "80210" },
  { label: "FRANQUEVILLE (80620)", value: "80620" },
  { label: "FRANSART (80700)", value: "80700" },
  { label: "FRANSU (80620)", value: "80620" },
  { label: "FRANSURES (80160)", value: "80160" },
  { label: "FRANVILLERS (80800)", value: "80800" },
  { label: "FRECHENCOURT (80260)", value: "80260" },
  { label: "FREMONTIERS (80160)", value: "80160" },
  { label: "FRESNES MAZANCOURT (80320)", value: "80320" },
  { label: "FRESNES TILLOLOY (80140)", value: "80140" },
  { label: "FRESNEVILLE (80140)", value: "80140" },
  { label: "FRESNOY ANDAINVILLE (80140)", value: "80140" },
  { label: "FRESNOY AU VAL (80290)", value: "80290" },
  { label: "FRESNOY EN CHAUSSEE (80110)", value: "80110" },
  { label: "FRESNOY LES ROYE (80700)", value: "80700" },
  { label: "FRESSENNEVILLE (80390)", value: "80390" },
  { label: "FRETTECUISSE (80140)", value: "80140" },
  { label: "FRETTEMEULE (80220)", value: "80220" },
  { label: "FRIAUCOURT (80460)", value: "80460" },
  { label: "FRICAMPS (80290)", value: "80290" },
  { label: "FRICOURT (80300)", value: "80300" },
  { label: "FRISE (80340)", value: "80340" },
  { label: "FRIVILLE ESCARBOTIN (80130)", value: "80130" },
  { label: "FROHEN SUR AUTHIE (80370)", value: "80370" },
  { label: "FROHEN SUR AUTHIE (80370)", value: "80370" },
  { label: "FROYELLES (80150)", value: "80150" },
  { label: "FRUCOURT (80490)", value: "80490" },
  { label: "GAMACHES (80220)", value: "80220" },
  { label: "GAPENNES (80150)", value: "80150" },
  { label: "GAUVILLE (80290)", value: "80290" },
  { label: "GENTELLES (80800)", value: "80800" },
  { label: "GEZAINCOURT (80600)", value: "80600" },
  { label: "GINCHY (80360)", value: "80360" },
  { label: "GLISY (80440)", value: "80440" },
  { label: "GORENFLOS (80690)", value: "80690" },
  { label: "GORGES (80370)", value: "80370" },
  { label: "GOYENCOURT (80700)", value: "80700" },
  { label: "GRANDCOURT (80300)", value: "80300" },
  { label: "GRAND LAVIERS (80132)", value: "80132" },
  { label: "GRATIBUS (80500)", value: "80500" },
  { label: "GRATTEPANCHE (80680)", value: "80680" },
  { label: "GREBAULT MESNIL (80140)", value: "80140" },
  { label: "GRIVESNES (80250)", value: "80250" },
  { label: "GRIVILLERS (80700)", value: "80700" },
  { label: "GROUCHES LUCHUEL (80600)", value: "80600" },
  { label: "GRUNY (80700)", value: "80700" },
  { label: "GUERBIGNY (80500)", value: "80500" },
  { label: "GUESCHART (80150)", value: "80150" },
  { label: "GUEUDECOURT (80360)", value: "80360" },
  { label: "GUIGNEMICOURT (80540)", value: "80540" },
  { label: "GUILLAUCOURT (80170)", value: "80170" },
  { label: "GUILLEMONT (80360)", value: "80360" },
  { label: "GUIZANCOURT (80290)", value: "80290" },
  { label: "GUYENCOURT SUR NOYE (80250)", value: "80250" },
  { label: "GUYENCOURT SAULCOURT (80240)", value: "80240" },
  { label: "HAILLES (80440)", value: "80440" },
  { label: "HALLENCOURT (80490)", value: "80490" },
  { label: "HALLENCOURT (80490)", value: "80490" },
  { label: "HALLENCOURT (80490)", value: "80490" },
  { label: "HALLIVILLERS (80250)", value: "80250" },
  { label: "HALLOY LES PERNOIS (80670)", value: "80670" },
  { label: "HALLU (80320)", value: "80320" },
  { label: "HAM (80400)", value: "80400" },
  { label: "LE HAMEL (80800)", value: "80800" },
  { label: "HAMELET (80800)", value: "80800" },
  { label: "HANCOURT (80240)", value: "80240" },
  { label: "HANGARD (80110)", value: "80110" },
  { label: "HANGEST EN SANTERRE (80134)", value: "80134" },
  { label: "HANGEST SUR SOMME (80310)", value: "80310" },
  { label: "HARBONNIERES (80131)", value: "80131" },
  { label: "HARDECOURT AUX BOIS (80360)", value: "80360" },
  { label: "HARPONVILLE (80560)", value: "80560" },
  { label: "HATTENCOURT (80700)", value: "80700" },
  { label: "HAUTVILLERS OUVILLE (80132)", value: "80132" },
  { label: "HAVERNAS (80670)", value: "80670" },
  { label: "HEBECOURT (80680)", value: "80680" },
  { label: "HEDAUVILLE (80560)", value: "80560" },
  { label: "HEILLY (80800)", value: "80800" },
  { label: "HEM HARDINVAL (80600)", value: "80600" },
  { label: "HEM MONACU (80360)", value: "80360" },
  { label: "HENENCOURT (80300)", value: "80300" },
  { label: "HERBECOURT (80200)", value: "80200" },
  { label: "HERISSART (80260)", value: "80260" },
  { label: "HERLEVILLE (80340)", value: "80340" },
  { label: "HERLY (80190)", value: "80190" },
  { label: "HERVILLY (80240)", value: "80240" },
  { label: "HESBECOURT (80240)", value: "80240" },
  { label: "HESCAMPS (80290)", value: "80290" },
  { label: "HESCAMPS (80290)", value: "80290" },
  { label: "HESCAMPS (80290)", value: "80290" },
  { label: "HESCAMPS (80290)", value: "80290" },
  { label: "HEUCOURT CROQUOISON (80270)", value: "80270" },
  { label: "HEUDICOURT (80122)", value: "80122" },
  { label: "HEUZECOURT (80370)", value: "80370" },
  { label: "HIERMONT (80370)", value: "80370" },
  { label: "HOMBLEUX (80400)", value: "80400" },
  { label: "HOMBLEUX (80400)", value: "80400" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HORNOY LE BOURG (80640)", value: "80640" },
  { label: "HUCHENNEVILLE (80132)", value: "80132" },
  { label: "HUMBERCOURT (80600)", value: "80600" },
  { label: "HUPPY (80140)", value: "80140" },
  { label: "IGNAUCOURT (80800)", value: "80800" },
  { label: "INVAL BOIRON (80430)", value: "80430" },
  { label: "IRLES (80300)", value: "80300" },
  { label: "JUMEL (80250)", value: "80250" },
  { label: "LABOISSIERE EN SANTERRE (80500)", value: "80500" },
  { label: "LACHAPELLE (80290)", value: "80290" },
  { label: "LAFRESGUIMONT ST MARTIN (80430)", value: "80430" },
  { label: "LAFRESGUIMONT ST MARTIN (80430)", value: "80430" },
  { label: "LAFRESGUIMONT ST MARTIN (80430)", value: "80430" },
  { label: "LAFRESGUIMONT ST MARTIN (80430)", value: "80430" },
  { label: "LAHOUSSOYE (80800)", value: "80800" },
  { label: "LALEU (80270)", value: "80270" },
  { label: "LAMARONDE (80290)", value: "80290" },
  { label: "LAMOTTE BREBIERE (80450)", value: "80450" },
  { label: "LAMOTTE BULEUX (80150)", value: "80150" },
  { label: "LAMOTTE WARFUSEE (80800)", value: "80800" },
  { label: "LAMOTTE WARFUSEE (80800)", value: "80800" },
  { label: "LANCHERES (80230)", value: "80230" },
  { label: "LANGUEVOISIN QUIQUERY (80190)", value: "80190" },
  { label: "LANCHES ST HILAIRE (80620)", value: "80620" },
  { label: "LAUCOURT (80700)", value: "80700" },
  { label: "LAVIEVILLE (80300)", value: "80300" },
  { label: "LAWARDE MAUGER L HORTOY (80250)", value: "80250" },
  { label: "LEALVILLERS (80560)", value: "80560" },
  { label: "LESBOEUFS (80360)", value: "80360" },
  { label: "LIANCOURT FOSSE (80700)", value: "80700" },
  { label: "LICOURT (80320)", value: "80320" },
  { label: "LIERAMONT (80240)", value: "80240" },
  { label: "LIERCOURT (80580)", value: "80580" },
  { label: "LIGESCOURT (80150)", value: "80150" },
  { label: "LIGNIERES (80500)", value: "80500" },
  { label: "LIGNIERES CHATELAIN (80290)", value: "80290" },
  { label: "LIGNIERES EN VIMEU (80140)", value: "80140" },
  { label: "LIHONS (80320)", value: "80320" },
  { label: "LIMEUX (80490)", value: "80490" },
  { label: "LIOMER (80430)", value: "80430" },
  { label: "O DE SELLE (80160)", value: "80160" },
  { label: "O DE SELLE (80160)", value: "80160" },
  { label: "O DE SELLE (80160)", value: "80160" },
  { label: "LONG (80510)", value: "80510" },
  { label: "LONGAVESNES (80240)", value: "80240" },
  { label: "LONGPRE LES CORPS SAINTS (80510)", value: "80510" },
  { label: "LONGUEAU (80330)", value: "80330" },
  { label: "LONGUEVAL (80360)", value: "80360" },
  { label: "LONGUEVILLETTE (80600)", value: "80600" },
  { label: "LOUVENCOURT (80560)", value: "80560" },
  { label: "LOUVRECHY (80250)", value: "80250" },
  { label: "LUCHEUX (80600)", value: "80600" },
  { label: "MACHIEL (80150)", value: "80150" },
  { label: "MACHY (80150)", value: "80150" },
  { label: "MAILLY MAILLET (80560)", value: "80560" },
  { label: "MAILLY RAINEVAL (80110)", value: "80110" },
  { label: "MAISNIERES (80220)", value: "80220" },
  { label: "MAISON PONTHIEU (80150)", value: "80150" },
  { label: "MAISON ROLAND (80135)", value: "80135" },
  { label: "MAIZICOURT (80370)", value: "80370" },
  { label: "MALPART (80250)", value: "80250" },
  { label: "CARNOY MAMETZ (80300)", value: "80300" },
  { label: "CARNOY MAMETZ (80300)", value: "80300" },
  { label: "MARCELCAVE (80800)", value: "80800" },
  { label: "MARCHE ALLOUARDE (80700)", value: "80700" },
  { label: "MARCHELEPOT MISERY (80200)", value: "80200" },
  { label: "MARCHELEPOT MISERY (80200)", value: "80200" },
  { label: "MARESTMONTIERS (80500)", value: "80500" },
  { label: "MAREUIL CAUBERT (80132)", value: "80132" },
  { label: "MARICOURT (80360)", value: "80360" },
  { label: "MARIEUX (80560)", value: "80560" },
  { label: "MARLERS (80290)", value: "80290" },
  { label: "MARQUAIX (80240)", value: "80240" },
  { label: "MARQUIVILLERS (80700)", value: "80700" },
  { label: "MARTAINNEVILLE (80140)", value: "80140" },
  { label: "MATIGNY (80400)", value: "80400" },
  { label: "MAUCOURT (80170)", value: "80170" },
  { label: "MAUREPAS (80360)", value: "80360" },
  { label: "LE MAZIS (80430)", value: "80430" },
  { label: "MEAULTE (80300)", value: "80300" },
  { label: "MEHARICOURT (80170)", value: "80170" },
  { label: "MEIGNEUX (80290)", value: "80290" },
  { label: "LE MEILLARD (80370)", value: "80370" },
  { label: "MENESLIES (80520)", value: "80520" },
  { label: "MEREAUCOURT (80290)", value: "80290" },
  { label: "MERELESSART (80490)", value: "80490" },
  { label: "MERICOURT L ABBE (80800)", value: "80800" },
  { label: "MERICOURT EN VIMEU (80640)", value: "80640" },
  { label: "MERS LES BAINS (80350)", value: "80350" },
  { label: "LE MESGE (80310)", value: "80310" },
  { label: "MESNIL BRUNTEL (80200)", value: "80200" },
  { label: "MESNIL DOMQUEUR (80620)", value: "80620" },
  { label: "MESNIL EN ARROUAISE (80360)", value: "80360" },
  { label: "MESNIL MARTINSART (80300)", value: "80300" },
  { label: "MESNIL ST GEORGES (80500)", value: "80500" },
  { label: "MESNIL ST NICAISE (80190)", value: "80190" },
  { label: "METIGNY (80270)", value: "80270" },
  { label: "MEZEROLLES (80600)", value: "80600" },
  { label: "MEZIERES EN SANTERRE (80110)", value: "80110" },
  { label: "MIANNAY (80132)", value: "80132" },
  { label: "MILLENCOURT (80300)", value: "80300" },
  { label: "MILLENCOURT EN PONTHIEU (80135)", value: "80135" },
  { label: "MIRAUMONT (80300)", value: "80300" },
  { label: "MIRVAUX (80260)", value: "80260" },
  { label: "MOISLAINS (80200)", value: "80200" },
  { label: "MOLLIENS AU BOIS (80260)", value: "80260" },
  { label: "MOLLIENS DREUIL (80540)", value: "80540" },
  { label: "MOLLIENS DREUIL (80540)", value: "80540" },
  { label: "MONCHY LAGACHE (80200)", value: "80200" },
  { label: "MONCHY LAGACHE (80200)", value: "80200" },
  { label: "MONS BOUBERT (80210)", value: "80210" },
  { label: "ESTREES MONS (80200)", value: "80200" },
  { label: "ESTREES MONS (80200)", value: "80200" },
  { label: "MONSURES (80160)", value: "80160" },
  { label: "MONTAGNE FAYEL (80540)", value: "80540" },
  { label: "MONTAUBAN DE PICARDIE (80300)", value: "80300" },
  { label: "MONTDIDIER (80500)", value: "80500" },
  { label: "MONTIGNY SUR L HALLUE (80260)", value: "80260" },
  { label: "MONTIGNY LES JONGLEURS (80370)", value: "80370" },
  { label: "MONTONVILLERS (80260)", value: "80260" },
  { label: "FIEFFES MONTRELET (80670)", value: "80670" },
  { label: "FIEFFES MONTRELET (80670)", value: "80670" },
  { label: "MORCHAIN (80190)", value: "80190" },
  { label: "MORCOURT (80340)", value: "80340" },
  { label: "MOREUIL (80110)", value: "80110" },
  { label: "MORISEL (80110)", value: "80110" },
  { label: "MORLANCOURT (80300)", value: "80300" },
  { label: "MORVILLERS ST SATURNIN (80290)", value: "80290" },
  { label: "MORVILLERS ST SATURNIN (80290)", value: "80290" },
  { label: "MOUFLERS (80690)", value: "80690" },
  { label: "MOUFLIERES (80140)", value: "80140" },
  { label: "MOYENCOURT (80400)", value: "80400" },
  { label: "MOYENCOURT LES POIX (80290)", value: "80290" },
  { label: "MOYENNEVILLE (80870)", value: "80870" },
  { label: "MUILLE VILLETTE (80400)", value: "80400" },
  { label: "NAMPONT (80120)", value: "80120" },
  { label: "NAMPS MAISNIL (80290)", value: "80290" },
  { label: "NAMPS MAISNIL (80290)", value: "80290" },
  { label: "NAMPS MAISNIL (80290)", value: "80290" },
  { label: "NAMPS MAISNIL (80290)", value: "80290" },
  { label: "NAMPTY (80160)", value: "80160" },
  { label: "NAOURS (80260)", value: "80260" },
  { label: "NESLE (80190)", value: "80190" },
  { label: "NESLE L HOPITAL (80140)", value: "80140" },
  { label: "NESLETTE (80140)", value: "80140" },
  { label: "NEUFMOULIN (80132)", value: "80132" },
  { label: "NEUILLY LE DIEN (80150)", value: "80150" },
  { label: "NEUILLY L HOPITAL (80132)", value: "80132" },
  { label: "NEUVILLE AU BOIS (80140)", value: "80140" },
  { label: "NEUVILLE COPPEGUEULE (80430)", value: "80430" },
  { label: "LA NEUVILLE LES BRAY (80340)", value: "80340" },
  { label: "LA NEUVILLE SIRE BERNARD (80110)", value: "80110" },
  { label: "NEUVILLETTE (80600)", value: "80600" },
  { label: "NIBAS (80390)", value: "80390" },
  { label: "NOUVION (80860)", value: "80860" },
  { label: "NOYELLES EN CHAUSSEE (80150)", value: "80150" },
  { label: "NOYELLES SUR MER (80860)", value: "80860" },
  { label: "NURLU (80240)", value: "80240" },
  { label: "OCCOCHES (80600)", value: "80600" },
  { label: "OCHANCOURT (80210)", value: "80210" },
  { label: "OFFIGNIES (80290)", value: "80290" },
  { label: "OFFOY (80400)", value: "80400" },
  { label: "OISEMONT (80140)", value: "80140" },
  { label: "OISSY (80540)", value: "80540" },
  { label: "ONEUX (80135)", value: "80135" },
  { label: "ORESMAUX (80160)", value: "80160" },
  { label: "OUST MAREST (80460)", value: "80460" },
  { label: "OUTREBOIS (80600)", value: "80600" },
  { label: "OVILLERS LA BOISSELLE (80300)", value: "80300" },
  { label: "PARGNY (80190)", value: "80190" },
  { label: "PARVILLERS LE QUESNOY (80700)", value: "80700" },
  { label: "PARVILLERS LE QUESNOY (80700)", value: "80700" },
  { label: "PENDE (80230)", value: "80230" },
  { label: "PERNOIS (80670)", value: "80670" },
  { label: "PERONNE (80200)", value: "80200" },
  { label: "HYPERCOURT (80320)", value: "80320" },
  { label: "HYPERCOURT (80320)", value: "80320" },
  { label: "HYPERCOURT (80320)", value: "80320" },
  { label: "PICQUIGNY (80310)", value: "80310" },
  { label: "PIENNES ONVILLERS (80500)", value: "80500" },
  { label: "PIENNES ONVILLERS (80500)", value: "80500" },
  { label: "PIERREGOT (80260)", value: "80260" },
  { label: "TROIS RIVIERES (80500)", value: "80500" },
  { label: "TROIS RIVIERES (80500)", value: "80500" },
  { label: "TROIS RIVIERES (80500)", value: "80500" },
  { label: "PISSY (80540)", value: "80540" },
  { label: "PLACHY BUYON (80160)", value: "80160" },
  { label: "LE PLESSIER ROZAINVILLERS (80110)", value: "80110" },
  { label: "POEUILLY (80240)", value: "80240" },
  { label: "POIX DE PICARDIE (80290)", value: "80290" },
  { label: "POIX DE PICARDIE (80290)", value: "80290" },
  { label: "PONCHES ESTRUVAL (80150)", value: "80150" },
  { label: "PONT DE METZ (80480)", value: "80480" },
  { label: "PONTHOILE (80860)", value: "80860" },
  { label: "PONT NOYELLES (80115)", value: "80115" },
  { label: "PONT REMY (80580)", value: "80580" },
  { label: "PORT LE GRAND (80132)", value: "80132" },
  { label: "POTTE (80190)", value: "80190" },
  { label: "POULAINVILLE (80260)", value: "80260" },
  { label: "POZIERES (80300)", value: "80300" },
  { label: "PROUVILLE (80370)", value: "80370" },
  { label: "PROUZEL (80160)", value: "80160" },
  { label: "PROYART (80340)", value: "80340" },
  { label: "PUCHEVILLERS (80560)", value: "80560" },
  { label: "PUNCHY (80320)", value: "80320" },
  { label: "PUZEAUX (80320)", value: "80320" },
  { label: "PYS (80300)", value: "80300" },
  { label: "QUEND (80120)", value: "80120" },
  { label: "QUERRIEU (80115)", value: "80115" },
  { label: "LE QUESNE (80430)", value: "80430" },
  { label: "LE QUESNEL (80118)", value: "80118" },
  { label: "QUESNOY LE MONTANT (80132)", value: "80132" },
  { label: "QUESNOY SUR AIRAINES (80270)", value: "80270" },
  { label: "QUEVAUVILLERS (80710)", value: "80710" },
  { label: "QUIRY LE SEC (80250)", value: "80250" },
  { label: "QUIVIERES (80400)", value: "80400" },
  { label: "RAINCHEVAL (80600)", value: "80600" },
  { label: "RAINNEVILLE (80260)", value: "80260" },
  { label: "RAMBURELLES (80140)", value: "80140" },
  { label: "RAMBURES (80140)", value: "80140" },
  { label: "RANCOURT (80360)", value: "80360" },
  { label: "REGNIERE ECLUSE (80120)", value: "80120" },
  { label: "REMAISNIL (80600)", value: "80600" },
  { label: "REMAUGIES (80500)", value: "80500" },
  { label: "REMIENCOURT (80250)", value: "80250" },
  { label: "RETHONVILLERS (80700)", value: "80700" },
  { label: "REVELLES (80540)", value: "80540" },
  { label: "RIBEAUCOURT (80620)", value: "80620" },
  { label: "RIBEMONT SUR ANCRE (80800)", value: "80800" },
  { label: "RIENCOURT (80310)", value: "80310" },
  { label: "RIVERY (80136)", value: "80136" },
  { label: "ROGY (80160)", value: "80160" },
  { label: "ROIGLISE (80700)", value: "80700" },
  { label: "ROISEL (80240)", value: "80240" },
  { label: "ROLLOT (80500)", value: "80500" },
  { label: "RONSSOY (80740)", value: "80740" },
  { label: "ROSIERES EN SANTERRE (80170)", value: "80170" },
  { label: "ROUVREL (80250)", value: "80250" },
  { label: "ROUVROY EN SANTERRE (80170)", value: "80170" },
  { label: "ROUY LE GRAND (80190)", value: "80190" },
  { label: "ROUY LE PETIT (80190)", value: "80190" },
  { label: "ROYE (80700)", value: "80700" },
  { label: "RUBEMPRE (80260)", value: "80260" },
  { label: "RUBESCOURT (80500)", value: "80500" },
  { label: "RUE (80120)", value: "80120" },
  { label: "RUMIGNY (80680)", value: "80680" },
  { label: "SAIGNEVILLE (80230)", value: "80230" },
  { label: "SAILLY FLIBEAUCOURT (80970)", value: "80970" },
  { label: "SAILLY LAURETTE (80800)", value: "80800" },
  { label: "SAILLY LE SEC (80800)", value: "80800" },
  { label: "SAILLY SAILLISEL (80360)", value: "80360" },
  { label: "SAINS EN AMIENOIS (80680)", value: "80680" },
  { label: "ST ACHEUL (80370)", value: "80370" },
  { label: "ST AUBIN MONTENOY (80540)", value: "80540" },
  { label: "ST AUBIN RIVIERE (80430)", value: "80430" },
  { label: "ST BLIMONT (80960)", value: "80960" },
  { label: "ST CHRIST BRIOST (80200)", value: "80200" },
  { label: "ST FUSCIEN (80680)", value: "80680" },
  { label: "ST GERMAIN SUR BRESLE (80430)", value: "80430" },
  { label: "ST GERMAIN SUR BRESLE (80430)", value: "80430" },
  { label: "ST GRATIEN (80260)", value: "80260" },
  { label: "ST LEGER LES AUTHIE (80560)", value: "80560" },
  { label: "ST LEGER LES DOMART (80780)", value: "80780" },
  { label: "ST LEGER SUR BRESLE (80140)", value: "80140" },
  { label: "ST MARD (80700)", value: "80700" },
  { label: "ST MAULVIS (80140)", value: "80140" },
  { label: "ST MAXENT (80140)", value: "80140" },
  { label: "ST OUEN (80610)", value: "80610" },
  { label: "ST QUENTIN EN TOURMONT (80120)", value: "80120" },
  { label: "ST QUENTIN LA MOTTE CROIX AU BAILLY (80880)", value: "80880" },
  { label: "ST RIQUIER (80135)", value: "80135" },
  { label: "ST SAUFLIEU (80160)", value: "80160" },
  { label: "ST SAUVEUR (80470)", value: "80470" },
  { label: "STE SEGREE (80290)", value: "80290" },
  { label: "ST VALERY SUR SOMME (80230)", value: "80230" },
  { label: "ST VAAST EN CHAUSSEE (80310)", value: "80310" },
  { label: "SAISSEVAL (80540)", value: "80540" },
  { label: "SALEUX (80480)", value: "80480" },
  { label: "SALOUEL (80480)", value: "80480" },
  { label: "SANCOURT (80400)", value: "80400" },
  { label: "SAULCHOY SOUS POIX (80290)", value: "80290" },
  { label: "SAUVILLERS MONGIVAL (80110)", value: "80110" },
  { label: "SAVEUSE (80470)", value: "80470" },
  { label: "SENARPONT (80140)", value: "80140" },
  { label: "SENLIS LE SEC (80300)", value: "80300" },
  { label: "SENTELIE (80160)", value: "80160" },
  { label: "SEUX (80540)", value: "80540" },
  { label: "SOREL EN VIMEU (80490)", value: "80490" },
  { label: "SOREL (80240)", value: "80240" },
  { label: "SOUES (80310)", value: "80310" },
  { label: "SOURDON (80250)", value: "80250" },
  { label: "SOYECOURT (80200)", value: "80200" },
  { label: "SURCAMPS (80620)", value: "80620" },
  { label: "SUZANNE (80340)", value: "80340" },
  { label: "TAILLY (80270)", value: "80270" },
  { label: "TALMAS (80260)", value: "80260" },
  { label: "TEMPLEUX LA FOSSE (80240)", value: "80240" },
  { label: "TEMPLEUX LE GUERARD (80240)", value: "80240" },
  { label: "TERRAMESNIL (80600)", value: "80600" },
  { label: "TERTRY (80200)", value: "80200" },
  { label: "THENNES (80110)", value: "80110" },
  { label: "THEZY GLIMONT (80440)", value: "80440" },
  { label: "THIEPVAL (80300)", value: "80300" },
  { label: "THIEULLOY L ABBAYE (80640)", value: "80640" },
  { label: "THIEULLOY LA VILLE (80290)", value: "80290" },
  { label: "THIEVRES (80560)", value: "80560" },
  { label: "THOIX (80160)", value: "80160" },
  { label: "THORY (80250)", value: "80250" },
  { label: "TILLOLOY (80700)", value: "80700" },
  { label: "TILLOY FLORIVILLE (80220)", value: "80220" },
  { label: "TINCOURT BOUCLY (80240)", value: "80240" },
  { label: "LE TITRE (80132)", value: "80132" },
  { label: "TOEUFLES (80870)", value: "80870" },
  { label: "TOURS EN VIMEU (80210)", value: "80210" },
  { label: "TOUTENCOURT (80560)", value: "80560" },
  { label: "LE TRANSLAY (80140)", value: "80140" },
  { label: "TREUX (80300)", value: "80300" },
  { label: "TULLY (80130)", value: "80130" },
  { label: "UGNY L EQUIPEE (80400)", value: "80400" },
  { label: "VADENCOURT (80560)", value: "80560" },
  { label: "VAIRE SOUS CORBIE (80800)", value: "80800" },
  { label: "VALINES (80210)", value: "80210" },
  { label: "VARENNES (80560)", value: "80560" },
  { label: "VAUCHELLES LES AUTHIE (80560)", value: "80560" },
  { label: "VAUCHELLES LES DOMART (80620)", value: "80620" },
  { label: "VAUCHELLES LES QUESNOY (80132)", value: "80132" },
  { label: "VAUDRICOURT (80230)", value: "80230" },
  { label: "VAUVILLERS (80131)", value: "80131" },
  { label: "VAUX EN AMIENOIS (80260)", value: "80260" },
  { label: "VAUX MARQUENNEVILLE (80140)", value: "80140" },
  { label: "VAUX SUR SOMME (80800)", value: "80800" },
  { label: "VECQUEMONT (80800)", value: "80800" },
  { label: "VELENNES (80160)", value: "80160" },
  { label: "VERCOURT (80120)", value: "80120" },
  { label: "VERGIES (80270)", value: "80270" },
  { label: "VERMANDOVILLERS (80320)", value: "80320" },
  { label: "VERPILLIERES (80700)", value: "80700" },
  { label: "VERS SUR SELLE (80480)", value: "80480" },
  { label: "LA VICOGNE (80260)", value: "80260" },
  { label: "VIGNACOURT (80650)", value: "80650" },
  { label: "VILLECOURT (80190)", value: "80190" },
  { label: "VILLE LE MARCLET (80420)", value: "80420" },
  { label: "VILLEROY (80140)", value: "80140" },
  { label: "VILLERS AUX ERABLES (80110)", value: "80110" },
  { label: "VILLERS BOCAGE (80260)", value: "80260" },
  { label: "VILLERS BRETONNEUX (80800)", value: "80800" },
  { label: "VILLERS CAMPSART (80140)", value: "80140" },
  { label: "VILLERS CARBONNEL (80200)", value: "80200" },
  { label: "VILLERS FAUCON (80240)", value: "80240" },
  { label: "VILLERS LES ROYE (80700)", value: "80700" },
  { label: "VILLERS SOUS AILLY (80690)", value: "80690" },
  { label: "VILLERS TOURNELLE (80500)", value: "80500" },
  { label: "VILLERS SUR AUTHIE (80120)", value: "80120" },
  { label: "VILLE SUR ANCRE (80300)", value: "80300" },
  { label: "VIRONCHAUX (80150)", value: "80150" },
  { label: "VISMES (80140)", value: "80140" },
  { label: "VITZ SUR AUTHIE (80150)", value: "80150" },
  { label: "VOYENNES (80400)", value: "80400" },
  { label: "VRAIGNES EN VERMANDOIS (80240)", value: "80240" },
  { label: "VRAIGNES LES HORNOY (80640)", value: "80640" },
  { label: "VRELY (80170)", value: "80170" },
  { label: "VRON (80120)", value: "80120" },
  { label: "WARGNIES (80670)", value: "80670" },
  { label: "WARLOY BAILLON (80300)", value: "80300" },
  { label: "WARLUS (80270)", value: "80270" },
  { label: "WARSY (80500)", value: "80500" },
  { label: "WARVILLERS (80170)", value: "80170" },
  { label: "WIENCOURT L EQUIPEE (80170)", value: "80170" },
  { label: "WIRY AU MONT (80270)", value: "80270" },
  { label: "WOIGNARUE (80460)", value: "80460" },
  { label: "WOINCOURT (80520)", value: "80520" },
  { label: "WOIREL (80140)", value: "80140" },
  { label: "Y (80190)", value: "80190" },
  { label: "YAUCOURT BUSSUS (80135)", value: "80135" },
  { label: "YVRENCH (80150)", value: "80150" },
  { label: "YVRENCHEUX (80150)", value: "80150" },
  { label: "YZENGREMER (80520)", value: "80520" },
  { label: "YZEUX (80310)", value: "80310" },
  { label: "YONVAL (80132)", value: "80132" },
  { label: "AGUTS (81470)", value: "81470" },
  { label: "AIGUEFONDE (81200)", value: "81200" },
  { label: "ALBAN (81250)", value: "81250" },
  { label: "ALBI (81000)", value: "81000" },
  { label: "ALBINE (81240)", value: "81240" },
  { label: "ALGANS (81470)", value: "81470" },
  { label: "ALOS (81140)", value: "81140" },
  { label: "ALMAYRAC (81190)", value: "81190" },
  { label: "AMARENS (81170)", value: "81170" },
  { label: "AMBIALET (81430)", value: "81430" },
  { label: "AMBRES (81500)", value: "81500" },
  { label: "ANDILLAC (81140)", value: "81140" },
  { label: "ANDOUQUE (81350)", value: "81350" },
  { label: "ANGLES (81260)", value: "81260" },
  { label: "APPELLE (81700)", value: "81700" },
  { label: "ARFONS (81110)", value: "81110" },
  { label: "ARIFAT (81360)", value: "81360" },
  { label: "ARTHES (81160)", value: "81160" },
  { label: "ASSAC (81340)", value: "81340" },
  { label: "AUSSAC (81600)", value: "81600" },
  { label: "AUSSILLON (81200)", value: "81200" },
  { label: "BANNIERES (81500)", value: "81500" },
  { label: "BARRE (81320)", value: "81320" },
  { label: "BEAUVAIS SUR TESCOU (81630)", value: "81630" },
  { label: "BELCASTEL (81500)", value: "81500" },
  { label: "BELLEGARDE MARSAL (81430)", value: "81430" },
  { label: "BELLEGARDE MARSAL (81430)", value: "81430" },
  { label: "BELLESERRE (81540)", value: "81540" },
  { label: "BERLATS (81260)", value: "81260" },
  { label: "BERNAC (81150)", value: "81150" },
  { label: "BERTRE (81700)", value: "81700" },
  { label: "LE BEZ (81260)", value: "81260" },
  { label: "BLAN (81700)", value: "81700" },
  { label: "BLAYE LES MINES (81400)", value: "81400" },
  { label: "BOISSEZON (81490)", value: "81490" },
  { label: "BOURNAZEL (81170)", value: "81170" },
  { label: "BOUT DU PONT DE LARN (81660)", value: "81660" },
  { label: "BRASSAC (81260)", value: "81260" },
  { label: "BRENS (81600)", value: "81600" },
  { label: "BRIATEXTE (81390)", value: "81390" },
  { label: "BROUSSE (81440)", value: "81440" },
  { label: "BROZE (81600)", value: "81600" },
  { label: "BURLATS (81100)", value: "81100" },
  { label: "BUSQUE (81300)", value: "81300" },
  { label: "CABANES (81500)", value: "81500" },
  { label: "LES CABANNES (81170)", value: "81170" },
  { label: "CADALEN (81600)", value: "81600" },
  { label: "CADIX (81340)", value: "81340" },
  { label: "CAGNAC LES MINES (81130)", value: "81130" },
  { label: "CAHUZAC (81540)", value: "81540" },
  { label: "CAMBON LES LAVAUR (81470)", value: "81470" },
  { label: "CAHUZAC SUR VERE (81140)", value: "81140" },
  { label: "CAMBON (81990)", value: "81990" },
  { label: "CAMBOUNES (81260)", value: "81260" },
  { label: "CAMBOUNET SUR LE SOR (81580)", value: "81580" },
  { label: "LES CAMMAZES (81540)", value: "81540" },
  { label: "CAMPAGNAC (81140)", value: "81140" },
  { label: "CARBES (81570)", value: "81570" },
  { label: "CARLUS (81990)", value: "81990" },
  { label: "CARMAUX (81400)", value: "81400" },
  { label: "CASTANET (81150)", value: "81150" },
  { label: "FONTRIEU (81260)", value: "81260" },
  { label: "FONTRIEU (81260)", value: "81260" },
  { label: "FONTRIEU (81260)", value: "81260" },
  { label: "CASTELNAU DE LEVIS (81150)", value: "81150" },
  { label: "CASTELNAU DE MONTMIRAL (81140)", value: "81140" },
  { label: "CASTRES (81100)", value: "81100" },
  { label: "CAUCALIERES (81200)", value: "81200" },
  { label: "CESTAYROLS (81150)", value: "81150" },
  { label: "COMBEFA (81640)", value: "81640" },
  { label: "CORDES SUR CIEL (81170)", value: "81170" },
  { label: "COUFOULEUX (81800)", value: "81800" },
  { label: "COURRIS (81340)", value: "81340" },
  { label: "CRESPIN (81350)", value: "81350" },
  { label: "CRESPINET (81350)", value: "81350" },
  { label: "CUNAC (81990)", value: "81990" },
  { label: "CUQ (81570)", value: "81570" },
  { label: "CUQ TOULZA (81470)", value: "81470" },
  { label: "CURVALLE (81250)", value: "81250" },
  { label: "DAMIATTE (81220)", value: "81220" },
  { label: "DENAT (81120)", value: "81120" },
  { label: "DONNAZAC (81170)", value: "81170" },
  { label: "DOURGNE (81110)", value: "81110" },
  { label: "LE DOURN (81340)", value: "81340" },
  { label: "DURFORT (81540)", value: "81540" },
  { label: "ESCOUSSENS (81290)", value: "81290" },
  { label: "LACAPELLE ESCROUX (81530)", value: "81530" },
  { label: "ESPERAUSSES (81260)", value: "81260" },
  { label: "FAYSSAC (81150)", value: "81150" },
  { label: "FAUCH (81120)", value: "81120" },
  { label: "FAUSSERGUES (81340)", value: "81340" },
  { label: "FENOLS (81600)", value: "81600" },
  { label: "FIAC (81500)", value: "81500" },
  { label: "FLORENTIN (81150)", value: "81150" },
  { label: "FRAISSINES (81340)", value: "81340" },
  { label: "FRAUSSEILLES (81170)", value: "81170" },
  { label: "LE FRAYSSE (81430)", value: "81430" },
  { label: "FREJAIROLLES (81990)", value: "81990" },
  { label: "FREJEVILLE (81570)", value: "81570" },
  { label: "GAILLAC (81600)", value: "81600" },
  { label: "GARREVAQUES (81700)", value: "81700" },
  { label: "LE GARRIC (81450)", value: "81450" },
  { label: "GARRIGUES (81500)", value: "81500" },
  { label: "GIJOUNET (81530)", value: "81530" },
  { label: "GIROUSSENS (81500)", value: "81500" },
  { label: "GRAULHET (81300)", value: "81300" },
  { label: "GRAZAC (81800)", value: "81800" },
  { label: "ITZAC (81170)", value: "81170" },
  { label: "JONQUIERES (81440)", value: "81440" },
  { label: "JOUQUEVIEL (81190)", value: "81190" },
  { label: "LABARTHE BLEYS (81170)", value: "81170" },
  { label: "LABASTIDE DE LEVIS (81150)", value: "81150" },
  { label: "LABASTIDE GABAUSSE (81400)", value: "81400" },
  { label: "LABASTIDE ROUAIROUX (81270)", value: "81270" },
  { label: "LABASTIDE ST GEORGES (81500)", value: "81500" },
  { label: "LABESSIERE CANDEIL (81300)", value: "81300" },
  { label: "LABOULBENE (81100)", value: "81100" },
  { label: "LABOUTARIE (81120)", value: "81120" },
  { label: "LABRUGUIERE (81290)", value: "81290" },
  { label: "LACABAREDE (81240)", value: "81240" },
  { label: "LACAPELLE PINET (81340)", value: "81340" },
  { label: "LACAPELLE SEGALAR (81170)", value: "81170" },
  { label: "LACAUNE (81230)", value: "81230" },
  { label: "LACAZE (81330)", value: "81330" },
  { label: "LACOUGOTTE CADOUL (81500)", value: "81500" },
  { label: "LACROISILLE (81470)", value: "81470" },
  { label: "LACROUZETTE (81210)", value: "81210" },
  { label: "LAGARDIOLLE (81110)", value: "81110" },
  { label: "LAGARRIGUE (81090)", value: "81090" },
  { label: "LAGRAVE (81150)", value: "81150" },
  { label: "GUITALENS L ALBAREDE (81220)", value: "81220" },
  { label: "GUITALENS L ALBAREDE (81220)", value: "81220" },
  { label: "LAMILLARIE (81120)", value: "81120" },
  { label: "LAMONTELARIE (81260)", value: "81260" },
  { label: "LAPARROUQUIAL (81640)", value: "81640" },
  { label: "LARROQUE (81140)", value: "81140" },
  { label: "LASFAILLADES (81260)", value: "81260" },
  { label: "LASGRAISSES (81300)", value: "81300" },
  { label: "LAUTREC (81440)", value: "81440" },
  { label: "LAVAUR (81500)", value: "81500" },
  { label: "LEDAS ET PENTHIES (81340)", value: "81340" },
  { label: "LEMPAUT (81700)", value: "81700" },
  { label: "LESCOUT (81110)", value: "81110" },
  { label: "LESCURE D ALBIGEOIS (81380)", value: "81380" },
  { label: "LISLE SUR TARN (81310)", value: "81310" },
  { label: "LIVERS CAZELLES (81170)", value: "81170" },
  { label: "LOMBERS (81120)", value: "81120" },
  { label: "LOUBERS (81170)", value: "81170" },
  { label: "LOUPIAC (81800)", value: "81800" },
  { label: "LUGAN (81500)", value: "81500" },
  { label: "MAGRIN (81220)", value: "81220" },
  { label: "MAILHOC (81130)", value: "81130" },
  { label: "MARNAVES (81170)", value: "81170" },
  { label: "MARSSAC SUR TARN (81150)", value: "81150" },
  { label: "MARZENS (81500)", value: "81500" },
  { label: "LE MASNAU MASSUGUIES (81530)", value: "81530" },
  { label: "MASSAC SERAN (81500)", value: "81500" },
  { label: "MASSAGUEL (81110)", value: "81110" },
  { label: "MASSALS (81250)", value: "81250" },
  { label: "MAURENS SCOPONT (81470)", value: "81470" },
  { label: "MAZAMET (81200)", value: "81200" },
  { label: "MEZENS (81800)", value: "81800" },
  { label: "MILHARS (81170)", value: "81170" },
  { label: "MILHAVET (81130)", value: "81130" },
  { label: "MIOLLES (81250)", value: "81250" },
  { label: "MIRANDOL BOURGNOUNAC (81190)", value: "81190" },
  { label: "MISSECLE (81300)", value: "81300" },
  { label: "MONESTIES (81640)", value: "81640" },
  { label: "MONTANS (81600)", value: "81600" },
  { label: "MONTAURIOL (81190)", value: "81190" },
  { label: "MONTCABRIER (81500)", value: "81500" },
  { label: "MONTDRAGON (81440)", value: "81440" },
  { label: "MONTDURAUSSE (81630)", value: "81630" },
  { label: "MONTELS (81140)", value: "81140" },
  { label: "MONTFA (81210)", value: "81210" },
  { label: "MONTGAILLARD (81630)", value: "81630" },
  { label: "MONTGEY (81470)", value: "81470" },
  { label: "MONTIRAT (81190)", value: "81190" },
  { label: "MONTPINIER (81440)", value: "81440" },
  { label: "MONTREDON LABESSONNIE (81360)", value: "81360" },
  { label: "MONT ROC (81120)", value: "81120" },
  { label: "MONTROSIER (81170)", value: "81170" },
  { label: "MONTVALEN (81630)", value: "81630" },
  { label: "MOULARES (81190)", value: "81190" },
  { label: "MOULAYRES (81300)", value: "81300" },
  { label: "MOULIN MAGE (81320)", value: "81320" },
  { label: "MOUZENS (81470)", value: "81470" },
  { label: "MOUZIEYS TEULET (81430)", value: "81430" },
  { label: "MOUZIEYS PANENS (81170)", value: "81170" },
  { label: "MURAT SUR VEBRE (81320)", value: "81320" },
  { label: "NAGES (81320)", value: "81320" },
  { label: "NAVES (81710)", value: "81710" },
  { label: "NOAILHAC (81490)", value: "81490" },
  { label: "NOAILLES (81170)", value: "81170" },
  { label: "ORBAN (81120)", value: "81120" },
  { label: "PADIES (81340)", value: "81340" },
  { label: "PALLEVILLE (81700)", value: "81700" },
  { label: "PAMPELONNE (81190)", value: "81190" },
  { label: "PARISOT (81310)", value: "81310" },
  { label: "PAULINET (81250)", value: "81250" },
  { label: "PAYRIN AUGMONTEL (81660)", value: "81660" },
  { label: "PECHAUDIER (81470)", value: "81470" },
  { label: "PENNE (81140)", value: "81140" },
  { label: "PEYREGOUX (81440)", value: "81440" },
  { label: "PEYROLE (81310)", value: "81310" },
  { label: "PONT DE LARN (81660)", value: "81660" },
  { label: "POUDIS (81700)", value: "81700" },
  { label: "POULAN POUZOLS (81120)", value: "81120" },
  { label: "PRADES (81220)", value: "81220" },
  { label: "PRATVIEL (81500)", value: "81500" },
  { label: "PUECHOURSI (81470)", value: "81470" },
  { label: "PUYBEGON (81390)", value: "81390" },
  { label: "PUYCALVEL (81440)", value: "81440" },
  { label: "PUYCELSI (81140)", value: "81140" },
  { label: "PUYGOUZON (81120)", value: "81120" },
  { label: "PUYGOUZON (81990)", value: "81990" },
  { label: "PUYLAURENS (81700)", value: "81700" },
  { label: "RABASTENS (81800)", value: "81800" },
  { label: "RAYSSAC (81330)", value: "81330" },
  { label: "REALMONT (81120)", value: "81120" },
  { label: "LE RIALET (81240)", value: "81240" },
  { label: "LE RIOLS (81170)", value: "81170" },
  { label: "RIVIERES (81600)", value: "81600" },
  { label: "ROQUECOURBE (81210)", value: "81210" },
  { label: "ROQUEMAURE (81800)", value: "81800" },
  { label: "ROQUEVIDAL (81470)", value: "81470" },
  { label: "ROSIERES (81400)", value: "81400" },
  { label: "ROUAIROUX (81240)", value: "81240" },
  { label: "ROUFFIAC (81150)", value: "81150" },
  { label: "TERRE DE BANCALIE (81120)", value: "81120" },
  { label: "TERRE DE BANCALIE (81120)", value: "81120" },
  { label: "TERRE DE BANCALIE (81120)", value: "81120" },
  { label: "TERRE DE BANCALIE (81120)", value: "81120" },
  { label: "TERRE DE BANCALIE (81120)", value: "81120" },
  { label: "TERRE DE BANCALIE (81120)", value: "81120" },
  { label: "ROUSSAYROLLES (81140)", value: "81140" },
  { label: "ST AFFRIQUE LES MONTAGNES (81290)", value: "81290" },
  { label: "ST AGNAN (81500)", value: "81500" },
  { label: "ST AMANCET (81110)", value: "81110" },
  { label: "ST AMANS SOULT (81240)", value: "81240" },
  { label: "ST AMANS VALTORET (81240)", value: "81240" },
  { label: "ST ANDRE (81250)", value: "81250" },
  { label: "ST AVIT (81110)", value: "81110" },
  { label: "ST BEAUZILE (81140)", value: "81140" },
  { label: "ST BENOIT DE CARMAUX (81400)", value: "81400" },
  { label: "ST CHRISTOPHE (81190)", value: "81190" },
  { label: "ST CHRISTOPHE (81190)", value: "81190" },
  { label: "STE CECILE DU CAYROU (81140)", value: "81140" },
  { label: "ST CIRGUE (81340)", value: "81340" },
  { label: "ST GAUZENS (81390)", value: "81390" },
  { label: "STE GEMME (81190)", value: "81190" },
  { label: "ST GENEST DE CONTEST (81440)", value: "81440" },
  { label: "ST GERMAIN DES PRES (81700)", value: "81700" },
  { label: "ST GERMIER (81210)", value: "81210" },
  { label: "ST GREGOIRE (81350)", value: "81350" },
  { label: "ST JEAN DE MARCEL (81350)", value: "81350" },
  { label: "ST JEAN DE RIVES (81500)", value: "81500" },
  { label: "ST JEAN DE VALS (81210)", value: "81210" },
  { label: "ST JUERY (81160)", value: "81160" },
  { label: "ST JULIEN DU PUY (81440)", value: "81440" },
  { label: "ST JULIEN GAULENE (81340)", value: "81340" },
  { label: "ST LIEUX LES LAVAUR (81500)", value: "81500" },
  { label: "ST MARCEL CAMPES (81170)", value: "81170" },
  { label: "ST MARCEL CAMPES (81170)", value: "81170" },
  { label: "ST MARTIN LAGUEPIE (81170)", value: "81170" },
  { label: "ST MICHEL LABADIE (81340)", value: "81340" },
  { label: "ST MICHEL DE VAX (81140)", value: "81140" },
  { label: "ST PAUL CAP DE JOUX (81220)", value: "81220" },
  { label: "ST PIERRE DE TRIVISY (81330)", value: "81330" },
  { label: "ST SALVI DE CARCAVES (81530)", value: "81530" },
  { label: "ST SALVY DE LA BALME (81490)", value: "81490" },
  { label: "ST SERNIN LES LAVAUR (81700)", value: "81700" },
  { label: "ST SULPICE LA POINTE (81370)", value: "81370" },
  { label: "ST URCISSE (81630)", value: "81630" },
  { label: "SAIX (81710)", value: "81710" },
  { label: "SALIES (81990)", value: "81990" },
  { label: "SALLES (81640)", value: "81640" },
  { label: "SALVAGNAC (81630)", value: "81630" },
  { label: "SAUSSENAC (81350)", value: "81350" },
  { label: "SAUVETERRE (81240)", value: "81240" },
  { label: "LA SAUZIERE ST JEAN (81630)", value: "81630" },
  { label: "LE SEGUR (81640)", value: "81640" },
  { label: "SEMALENS (81570)", value: "81570" },
  { label: "SENAUX (81530)", value: "81530" },
  { label: "SENOUILLAC (81600)", value: "81600" },
  { label: "LE SEQUESTRE (81990)", value: "81990" },
  { label: "SERENAC (81350)", value: "81350" },
  { label: "SERVIES (81220)", value: "81220" },
  { label: "SIEURAC (81120)", value: "81120" },
  { label: "SOREZE (81540)", value: "81540" },
  { label: "SOUAL (81580)", value: "81580" },
  { label: "SOUEL (81170)", value: "81170" },
  { label: "TAIX (81130)", value: "81130" },
  { label: "TANUS (81190)", value: "81190" },
  { label: "TAURIAC (81630)", value: "81630" },
  { label: "TECOU (81600)", value: "81600" },
  { label: "TEILLET (81120)", value: "81120" },
  { label: "TERSSAC (81150)", value: "81150" },
  { label: "TEULAT (81500)", value: "81500" },
  { label: "TEYSSODE (81220)", value: "81220" },
  { label: "TONNAC (81170)", value: "81170" },
  { label: "TREBAN (81190)", value: "81190" },
  { label: "TREBAS (81340)", value: "81340" },
  { label: "TREVIEN (81190)", value: "81190" },
  { label: "VABRE (81330)", value: "81330" },
  { label: "VALDERIES (81350)", value: "81350" },
  { label: "VALDURENQUE (81090)", value: "81090" },
  { label: "VALENCE D ALBIGEOIS (81340)", value: "81340" },
  { label: "VAOUR (81140)", value: "81140" },
  { label: "VEILHES (81500)", value: "81500" },
  { label: "VENES (81440)", value: "81440" },
  { label: "VERDALLE (81110)", value: "81110" },
  { label: "LE VERDIER (81140)", value: "81140" },
  { label: "VIANE (81530)", value: "81530" },
  { label: "VIELMUR SUR AGOUT (81570)", value: "81570" },
  { label: "VIEUX (81140)", value: "81140" },
  { label: "VILLEFRANCHE D ALBIGEOIS (81430)", value: "81430" },
  { label: "VILLENEUVE LES LAVAUR (81500)", value: "81500" },
  { label: "VILLENEUVE SUR VERE (81130)", value: "81130" },
  { label: "VINDRAC ALAYRAC (81170)", value: "81170" },
  { label: "LE VINTROU (81240)", value: "81240" },
  { label: "VIRAC (81640)", value: "81640" },
  { label: "VITERBE (81220)", value: "81220" },
  { label: "VIVIERS LES LAVAUR (81500)", value: "81500" },
  { label: "VIVIERS LES MONTAGNES (81290)", value: "81290" },
  { label: "STE CROIX (81150)", value: "81150" },
  { label: "ALBEFEUILLE LAGARDE (82290)", value: "82290" },
  { label: "ALBIAS (82350)", value: "82350" },
  { label: "ANGEVILLE (82210)", value: "82210" },
  { label: "ASQUES (82120)", value: "82120" },
  { label: "AUCAMVILLE (82600)", value: "82600" },
  { label: "AUTERIVE (82500)", value: "82500" },
  { label: "AUTY (82220)", value: "82220" },
  { label: "AUVILLAR (82340)", value: "82340" },
  { label: "BALIGNAC (82120)", value: "82120" },
  { label: "BARDIGUES (82340)", value: "82340" },
  { label: "BARRY D ISLEMADE (82290)", value: "82290" },
  { label: "LES BARTHES (82100)", value: "82100" },
  { label: "BEAUMONT DE LOMAGNE (82500)", value: "82500" },
  { label: "BEAUPUY (82600)", value: "82600" },
  { label: "BELBEZE EN LOMAGNE (82500)", value: "82500" },
  { label: "BELVEZE (82150)", value: "82150" },
  { label: "BESSENS (82170)", value: "82170" },
  { label: "BIOULE (82800)", value: "82800" },
  { label: "BOUDOU (82200)", value: "82200" },
  { label: "BOUILLAC (82600)", value: "82600" },
  { label: "BOULOC EN QUERCY (82110)", value: "82110" },
  { label: "BOURG DE VISA (82190)", value: "82190" },
  { label: "BOURRET (82700)", value: "82700" },
  { label: "BRASSAC (82190)", value: "82190" },
  { label: "BRESSOLS (82710)", value: "82710" },
  { label: "BRUNIQUEL (82800)", value: "82800" },
  { label: "CAMPSAS (82370)", value: "82370" },
  { label: "CANALS (82170)", value: "82170" },
  { label: "CASTANET (82160)", value: "82160" },
  { label: "CASTELFERRUS (82100)", value: "82100" },
  { label: "CASTELMAYRAN (82210)", value: "82210" },
  { label: "CASTELSAGRAT (82400)", value: "82400" },
  { label: "CASTELSARRASIN (82100)", value: "82100" },
  { label: "CASTERA BOUZET (82120)", value: "82120" },
  { label: "CAUMONT (82210)", value: "82210" },
  { label: "LE CAUSE (82500)", value: "82500" },
  { label: "CAUSSADE (82300)", value: "82300" },
  { label: "CAYLUS (82160)", value: "82160" },
  { label: "CAYRAC (82440)", value: "82440" },
  { label: "CAYRIECH (82240)", value: "82240" },
  { label: "CAZALS (82140)", value: "82140" },
  { label: "CAZES MONDENARD (82110)", value: "82110" },
  { label: "COMBEROUGER (82600)", value: "82600" },
  { label: "CORBARIEU (82370)", value: "82370" },
  { label: "CORDES TOLOSANNES (82700)", value: "82700" },
  { label: "COUTURES (82210)", value: "82210" },
  { label: "CUMONT (82500)", value: "82500" },
  { label: "DIEUPENTALE (82170)", value: "82170" },
  { label: "DONZAC (82340)", value: "82340" },
  { label: "DUNES (82340)", value: "82340" },
  { label: "DURFORT LACAPELETTE (82390)", value: "82390" },
  { label: "ESCATALENS (82700)", value: "82700" },
  { label: "ESCAZEAUX (82500)", value: "82500" },
  { label: "ESPALAIS (82400)", value: "82400" },
  { label: "ESPARSAC (82500)", value: "82500" },
  { label: "ESPINAS (82160)", value: "82160" },
  { label: "FABAS (82170)", value: "82170" },
  { label: "FAJOLLES (82210)", value: "82210" },
  { label: "FAUDOAS (82500)", value: "82500" },
  { label: "FAUROUX (82190)", value: "82190" },
  { label: "FENEYROLS (82140)", value: "82140" },
  { label: "FINHAN (82700)", value: "82700" },
  { label: "GARGANVILLAR (82100)", value: "82100" },
  { label: "GARIES (82500)", value: "82500" },
  { label: "GASQUES (82400)", value: "82400" },
  { label: "GENEBRIERES (82230)", value: "82230" },
  { label: "GENSAC (82120)", value: "82120" },
  { label: "GIMAT (82500)", value: "82500" },
  { label: "GINALS (82330)", value: "82330" },
  { label: "GLATENS (82500)", value: "82500" },
  { label: "GOAS (82500)", value: "82500" },
  { label: "GOLFECH (82400)", value: "82400" },
  { label: "GOUDOURVILLE (82400)", value: "82400" },
  { label: "GRAMONT (82120)", value: "82120" },
  { label: "GRISOLLES (82170)", value: "82170" },
  { label: "L'HONOR DE COS (82130)", value: "82130" },
  { label: "LABARTHE (82220)", value: "82220" },
  { label: "LABASTIDE DE PENNE (82240)", value: "82240" },
  { label: "LABASTIDE ST PIERRE (82370)", value: "82370" },
  { label: "LABASTIDE DU TEMPLE (82100)", value: "82100" },
  { label: "LABOURGADE (82100)", value: "82100" },
  { label: "LACAPELLE LIVRON (82160)", value: "82160" },
  { label: "LACHAPELLE (82120)", value: "82120" },
  { label: "LACOUR (82190)", value: "82190" },
  { label: "LACOURT ST PIERRE (82290)", value: "82290" },
  { label: "LAFITTE (82100)", value: "82100" },
  { label: "LAFRANCAISE (82130)", value: "82130" },
  { label: "LAGUEPIE (82250)", value: "82250" },
  { label: "LAMAGISTERE (82360)", value: "82360" },
  { label: "LAMOTHE CAPDEVILLE (82130)", value: "82130" },
  { label: "LAMOTHE CUMONT (82500)", value: "82500" },
  { label: "LAPENCHE (82240)", value: "82240" },
  { label: "LARRAZET (82500)", value: "82500" },
  { label: "LAUZERTE (82110)", value: "82110" },
  { label: "LAVAURETTE (82240)", value: "82240" },
  { label: "LA VILLE DIEU DU TEMPLE (82290)", value: "82290" },
  { label: "LAVIT (82120)", value: "82120" },
  { label: "LEOJAC (82230)", value: "82230" },
  { label: "LIZAC (82200)", value: "82200" },
  { label: "LOZE (82160)", value: "82160" },
  { label: "MALAUSE (82200)", value: "82200" },
  { label: "MANSONVILLE (82120)", value: "82120" },
  { label: "MARIGNAC (82500)", value: "82500" },
  { label: "MARSAC (82120)", value: "82120" },
  { label: "MAS GRENIER (82600)", value: "82600" },
  { label: "MAUBEC (82500)", value: "82500" },
  { label: "MAUMUSSON (82120)", value: "82120" },
  { label: "MEAUZAC (82290)", value: "82290" },
  { label: "MERLES (82210)", value: "82210" },
  { label: "MIRABEL (82440)", value: "82440" },
  { label: "MIRAMONT DE QUERCY (82190)", value: "82190" },
  { label: "MOISSAC (82200)", value: "82200" },
  { label: "MOLIERES (82220)", value: "82220" },
  { label: "MONBEQUI (82170)", value: "82170" },
  { label: "MONCLAR DE QUERCY (82230)", value: "82230" },
  { label: "MONTAGUDET (82110)", value: "82110" },
  { label: "MONTAIGU DE QUERCY (82150)", value: "82150" },
  { label: "MONTAIN (82100)", value: "82100" },
  { label: "MONTALZAT (82270)", value: "82270" },
  { label: "MONTASTRUC (82130)", value: "82130" },
  { label: "MONTAUBAN (82000)", value: "82000" },
  { label: "MONTBARLA (82110)", value: "82110" },
  { label: "MONTBARTIER (82700)", value: "82700" },
  { label: "MONTBETON (82290)", value: "82290" },
  { label: "MONTECH (82700)", value: "82700" },
  { label: "MONTEILS (82300)", value: "82300" },
  { label: "MONTESQUIEU (82200)", value: "82200" },
  { label: "MONTFERMIER (82270)", value: "82270" },
  { label: "MONTGAILLARD (82120)", value: "82120" },
  { label: "MONTJOI (82400)", value: "82400" },
  { label: "MONTPEZAT DE QUERCY (82270)", value: "82270" },
  { label: "MONTRICOUX (82800)", value: "82800" },
  { label: "MOUILLAC (82160)", value: "82160" },
  { label: "NEGREPELISSE (82800)", value: "82800" },
  { label: "NOHIC (82370)", value: "82370" },
  { label: "ORGUEIL (82370)", value: "82370" },
  { label: "PARISOT (82160)", value: "82160" },
  { label: "PERVILLE (82400)", value: "82400" },
  { label: "LE PIN (82340)", value: "82340" },
  { label: "PIQUECOS (82130)", value: "82130" },
  { label: "POMMEVIC (82400)", value: "82400" },
  { label: "POMPIGNAN (82170)", value: "82170" },
  { label: "POUPAS (82120)", value: "82120" },
  { label: "PUYCORNET (82220)", value: "82220" },
  { label: "PUYGAILLARD DE QUERCY (82800)", value: "82800" },
  { label: "PUYGAILLARD DE LOMAGNE (82120)", value: "82120" },
  { label: "PUYLAGARDE (82160)", value: "82160" },
  { label: "PUYLAROQUE (82240)", value: "82240" },
  { label: "REALVILLE (82440)", value: "82440" },
  { label: "REYNIES (82370)", value: "82370" },
  { label: "ROQUECOR (82150)", value: "82150" },
  { label: "ST AIGNAN (82100)", value: "82100" },
  { label: "ST AMANS DU PECH (82150)", value: "82150" },
  { label: "ST AMANS DE PELLAGAL (82110)", value: "82110" },
  { label: "ST ANTONIN NOBLE VAL (82140)", value: "82140" },
  { label: "ST ARROUMEX (82210)", value: "82210" },
  { label: "ST BEAUZEIL (82150)", value: "82150" },
  { label: "ST CIRICE (82340)", value: "82340" },
  { label: "ST CIRQ (82300)", value: "82300" },
  { label: "ST CLAIR (82400)", value: "82400" },
  { label: "ST ETIENNE DE TULMONT (82410)", value: "82410" },
  { label: "ST GEORGES (82240)", value: "82240" },
  { label: "ST JEAN DU BOUZET (82120)", value: "82120" },
  { label: "STE JULIETTE (82110)", value: "82110" },
  { label: "ST LOUP (82340)", value: "82340" },
  { label: "ST MICHEL (82340)", value: "82340" },
  { label: "ST NAUPHARY (82370)", value: "82370" },
  { label: "ST NAZAIRE DE VALENTANE (82190)", value: "82190" },
  { label: "ST NICOLAS DE LA GRAVE (82210)", value: "82210" },
  { label: "ST PAUL D ESPIS (82400)", value: "82400" },
  { label: "ST PORQUIER (82700)", value: "82700" },
  { label: "ST PROJET (82160)", value: "82160" },
  { label: "ST SARDOS (82600)", value: "82600" },
  { label: "ST VINCENT D AUTEJAC (82300)", value: "82300" },
  { label: "ST VINCENT LESPINASSE (82400)", value: "82400" },
  { label: "LA SALVETAT BELMONTET (82230)", value: "82230" },
  { label: "SAUVETERRE (82110)", value: "82110" },
  { label: "SAVENES (82600)", value: "82600" },
  { label: "SEPTFONDS (82240)", value: "82240" },
  { label: "SERIGNAC (82500)", value: "82500" },
  { label: "SISTELS (82340)", value: "82340" },
  { label: "TOUFFAILLES (82190)", value: "82190" },
  { label: "TREJOULS (82110)", value: "82110" },
  { label: "VAISSAC (82800)", value: "82800" },
  { label: "VALEILLES (82150)", value: "82150" },
  { label: "VALENCE (82400)", value: "82400" },
  { label: "VAREN (82330)", value: "82330" },
  { label: "VARENNES (82370)", value: "82370" },
  { label: "VAZERAC (82220)", value: "82220" },
  { label: "VERDUN SUR GARONNE (82600)", value: "82600" },
  { label: "VERFEIL (82330)", value: "82330" },
  { label: "VERLHAC TESCOU (82230)", value: "82230" },
  { label: "VIGUERON (82500)", value: "82500" },
  { label: "VILLEBRUMIER (82370)", value: "82370" },
  { label: "VILLEMADE (82130)", value: "82130" },
  { label: "LES ADRETS DE L ESTEREL (83600)", value: "83600" },
  { label: "AIGUINES (83630)", value: "83630" },
  { label: "AMPUS (83111)", value: "83111" },
  { label: "LES ARCS (83460)", value: "83460" },
  { label: "ARTIGNOSC SUR VERDON (83630)", value: "83630" },
  { label: "ARTIGUES (83560)", value: "83560" },
  { label: "AUPS (83630)", value: "83630" },
  { label: "BAGNOLS EN FORET (83600)", value: "83600" },
  { label: "BANDOL (83150)", value: "83150" },
  { label: "BARGEME (83840)", value: "83840" },
  { label: "BARGEMON (83830)", value: "83830" },
  { label: "BARJOLS (83670)", value: "83670" },
  { label: "LA BASTIDE (83840)", value: "83840" },
  { label: "BAUDINARD SUR VERDON (83630)", value: "83630" },
  { label: "BAUDUEN (83630)", value: "83630" },
  { label: "LE BEAUSSET (83330)", value: "83330" },
  { label: "BELGENTIER (83210)", value: "83210" },
  { label: "BESSE SUR ISSOLE (83890)", value: "83890" },
  { label: "BORMES LES MIMOSAS (83230)", value: "83230" },
  { label: "LE BOURGUET (83840)", value: "83840" },
  { label: "BRAS (83149)", value: "83149" },
  { label: "BRENON (83840)", value: "83840" },
  { label: "BRIGNOLES (83170)", value: "83170" },
  { label: "BRUE AURIAC (83119)", value: "83119" },
  { label: "CABASSE (83340)", value: "83340" },
  { label: "LA CADIERE D AZUR (83740)", value: "83740" },
  { label: "LA CADIERE D AZUR (83740)", value: "83740" },
  { label: "CALLAS (83830)", value: "83830" },
  { label: "CALLIAN (83440)", value: "83440" },
  { label: "CAMPS LA SOURCE (83170)", value: "83170" },
  { label: "LE CANNET DES MAURES (83340)", value: "83340" },
  { label: "CARCES (83570)", value: "83570" },
  { label: "CARNOULES (83660)", value: "83660" },
  { label: "CARQUEIRANNE (83320)", value: "83320" },
  { label: "LE CASTELLET (83330)", value: "83330" },
  { label: "LE CASTELLET (83330)", value: "83330" },
  { label: "LE CASTELLET (83330)", value: "83330" },
  { label: "LE CASTELLET (83330)", value: "83330" },
  { label: "LE CASTELLET (83330)", value: "83330" },
  { label: "CAVALAIRE SUR MER (83240)", value: "83240" },
  { label: "LA CELLE (83170)", value: "83170" },
  { label: "CHATEAUDOUBLE (83300)", value: "83300" },
  { label: "CHATEAUDOUBLE (83300)", value: "83300" },
  { label: "CHATEAUVERT (83670)", value: "83670" },
  { label: "CHATEAUVIEUX (83840)", value: "83840" },
  { label: "CLAVIERS (83830)", value: "83830" },
  { label: "COGOLIN (83310)", value: "83310" },
  { label: "COLLOBRIERES (83610)", value: "83610" },
  { label: "COMPS SUR ARTUBY (83840)", value: "83840" },
  { label: "CORRENS (83570)", value: "83570" },
  { label: "COTIGNAC (83570)", value: "83570" },
  { label: "LA CRAU (83260)", value: "83260" },
  { label: "LA CRAU (83260)", value: "83260" },
  { label: "LA CROIX VALMER (83420)", value: "83420" },
  { label: "CUERS (83390)", value: "83390" },
  { label: "DRAGUIGNAN (83300)", value: "83300" },
  { label: "ENTRECASTEAUX (83570)", value: "83570" },
  { label: "ESPARRON (83560)", value: "83560" },
  { label: "EVENOS (83330)", value: "83330" },
  { label: "LA FARLEDE (83210)", value: "83210" },
  { label: "FAYENCE (83440)", value: "83440" },
  { label: "FIGANIERES (83830)", value: "83830" },
  { label: "FLASSANS SUR ISSOLE (83340)", value: "83340" },
  { label: "FLAYOSC (83780)", value: "83780" },
  { label: "FORCALQUEIRET (83136)", value: "83136" },
  { label: "FOX AMPHOUX (83670)", value: "83670" },
  { label: "FREJUS (83370)", value: "83370" },
  { label: "FREJUS (83600)", value: "83600" },
  { label: "LA GARDE (83130)", value: "83130" },
  { label: "LA GARDE FREINET (83680)", value: "83680" },
  { label: "GAREOULT (83136)", value: "83136" },
  { label: "GASSIN (83580)", value: "83580" },
  { label: "GINASSERVIS (83560)", value: "83560" },
  { label: "GONFARON (83590)", value: "83590" },
  { label: "GRIMAUD (83310)", value: "83310" },
  { label: "GRIMAUD (83310)", value: "83310" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "HYERES (83400)", value: "83400" },
  { label: "LE LAVANDOU (83980)", value: "83980" },
  { label: "LE LAVANDOU (83980)", value: "83980" },
  { label: "LA LONDE LES MAURES (83250)", value: "83250" },
  { label: "LORGUES (83510)", value: "83510" },
  { label: "LE LUC (83340)", value: "83340" },
  { label: "LA MARTRE (83840)", value: "83840" },
  { label: "LES MAYONS (83340)", value: "83340" },
  { label: "MAZAUGUES (83136)", value: "83136" },
  { label: "MEOUNES LES MONTRIEUX (83136)", value: "83136" },
  { label: "MOISSAC BELLEVUE (83630)", value: "83630" },
  { label: "LA MOLE (83310)", value: "83310" },
  { label: "MONS (83440)", value: "83440" },
  { label: "MONTAUROUX (83440)", value: "83440" },
  { label: "MONTFERRAT (83131)", value: "83131" },
  { label: "MONTFORT SUR ARGENS (83570)", value: "83570" },
  { label: "MONTMEYAN (83670)", value: "83670" },
  { label: "LA MOTTE (83920)", value: "83920" },
  { label: "LE MUY (83490)", value: "83490" },
  { label: "NANS LES PINS (83860)", value: "83860" },
  { label: "NEOULES (83136)", value: "83136" },
  { label: "OLLIERES (83470)", value: "83470" },
  { label: "OLLIOULES (83190)", value: "83190" },
  { label: "PIERREFEU DU VAR (83390)", value: "83390" },
  { label: "PIGNANS (83790)", value: "83790" },
  { label: "PLAN D AUPS STE BAUME (83640)", value: "83640" },
  { label: "LE PLAN DE LA TOUR (83120)", value: "83120" },
  { label: "PONTEVES (83670)", value: "83670" },
  { label: "POURCIEUX (83470)", value: "83470" },
  { label: "POURRIERES (83910)", value: "83910" },
  { label: "LE PRADET (83220)", value: "83220" },
  { label: "PUGET SUR ARGENS (83480)", value: "83480" },
  { label: "PUGET VILLE (83390)", value: "83390" },
  { label: "RAMATUELLE (83350)", value: "83350" },
  { label: "REGUSSE (83630)", value: "83630" },
  { label: "LE REVEST LES EAUX (83200)", value: "83200" },
  { label: "RIANS (83560)", value: "83560" },
  { label: "RIBOUX (13780)", value: "13780" },
  { label: "ROCBARON (83136)", value: "83136" },
  { label: "ROQUEBRUNE SUR ARGENS (83380)", value: "83380" },
  { label: "ROQUEBRUNE SUR ARGENS (83520)", value: "83520" },
  { label: "LA ROQUEBRUSSANNE (83136)", value: "83136" },
  { label: "LA ROQUE ESCLAPON (83840)", value: "83840" },
  { label: "ROUGIERS (83170)", value: "83170" },
  { label: "STE ANASTASIE SUR ISSOLE (83136)", value: "83136" },
  { label: "ST CYR SUR MER (83270)", value: "83270" },
  { label: "ST CYR SUR MER (83270)", value: "83270" },
  { label: "ST CYR SUR MER (83270)", value: "83270" },
  { label: "ST CYR SUR MER (83270)", value: "83270" },
  { label: "ST JULIEN (83560)", value: "83560" },
  { label: "ST MARTIN DE PALLIERES (83560)", value: "83560" },
  { label: "STE MAXIME (83120)", value: "83120" },
  { label: "ST MAXIMIN LA STE BAUME (83470)", value: "83470" },
  { label: "ST PAUL EN FORET (83440)", value: "83440" },
  { label: "ST RAPHAEL (83530)", value: "83530" },
  { label: "ST RAPHAEL (83530)", value: "83530" },
  { label: "ST RAPHAEL (83530)", value: "83530" },
  { label: "ST RAPHAEL (83530)", value: "83530" },
  { label: "ST RAPHAEL (83700)", value: "83700" },
  { label: "ST RAPHAEL (83700)", value: "83700" },
  { label: "ST TROPEZ (83990)", value: "83990" },
  { label: "ST ZACHARIE (83640)", value: "83640" },
  { label: "SALERNES (83690)", value: "83690" },
  { label: "LES SALLES SUR VERDON (83630)", value: "83630" },
  { label: "SANARY SUR MER (83110)", value: "83110" },
  { label: "SEILLANS (83440)", value: "83440" },
  { label: "SEILLANS (83440)", value: "83440" },
  { label: "SEILLONS SOURCE D ARGENS (83470)", value: "83470" },
  { label: "LA SEYNE SUR MER (83500)", value: "83500" },
  { label: "LA SEYNE SUR MER (83500)", value: "83500" },
  { label: "LA SEYNE SUR MER (83500)", value: "83500" },
  { label: "SIGNES (83870)", value: "83870" },
  { label: "SILLANS LA CASCADE (83690)", value: "83690" },
  { label: "SIX FOURS LES PLAGES (83140)", value: "83140" },
  { label: "SIX FOURS LES PLAGES (83140)", value: "83140" },
  { label: "SIX FOURS LES PLAGES (83140)", value: "83140" },
  { label: "SOLLIES PONT (83210)", value: "83210" },
  { label: "SOLLIES TOUCAS (83210)", value: "83210" },
  { label: "SOLLIES VILLE (83210)", value: "83210" },
  { label: "TANNERON (83440)", value: "83440" },
  { label: "TARADEAU (83460)", value: "83460" },
  { label: "TAVERNES (83670)", value: "83670" },
  { label: "LE THORONET (83340)", value: "83340" },
  { label: "TOULON (83000)", value: "83000" },
  { label: "TOULON (83100)", value: "83100" },
  { label: "TOULON (83200)", value: "83200" },
  { label: "TOURRETTES (83440)", value: "83440" },
  { label: "TOURTOUR (83690)", value: "83690" },
  { label: "TOURVES (83170)", value: "83170" },
  { label: "TRANS EN PROVENCE (83720)", value: "83720" },
  { label: "TRIGANCE (83840)", value: "83840" },
  { label: "LE VAL (83143)", value: "83143" },
  { label: "LA VALETTE DU VAR (83160)", value: "83160" },
  { label: "VARAGES (83670)", value: "83670" },
  { label: "LA VERDIERE (83560)", value: "83560" },
  { label: "VERIGNON (83630)", value: "83630" },
  { label: "VIDAUBAN (83550)", value: "83550" },
  { label: "VILLECROZE (83690)", value: "83690" },
  { label: "VINON SUR VERDON (83560)", value: "83560" },
  { label: "VINS SUR CARAMY (83170)", value: "83170" },
  { label: "RAYOL CANADEL SUR MER (83820)", value: "83820" },
  { label: "RAYOL CANADEL SUR MER (83820)", value: "83820" },
  { label: "ST MANDRIER SUR MER (83430)", value: "83430" },
  { label: "ST ANTONIN DU VAR (83510)", value: "83510" },
  { label: "ALTHEN DES PALUDS (84210)", value: "84210" },
  { label: "ANSOUIS (84240)", value: "84240" },
  { label: "APT (84400)", value: "84400" },
  { label: "AUBIGNAN (84810)", value: "84810" },
  { label: "AUREL (84390)", value: "84390" },
  { label: "AURIBEAU (84400)", value: "84400" },
  { label: "AVIGNON (84000)", value: "84000" },
  { label: "AVIGNON (84140)", value: "84140" },
  { label: "LE BARROUX (84330)", value: "84330" },
  { label: "LA BASTIDE DES JOURDANS (84240)", value: "84240" },
  { label: "LA BASTIDONNE (84120)", value: "84120" },
  { label: "LE BEAUCET (84210)", value: "84210" },
  { label: "BEAUMES DE VENISE (84190)", value: "84190" },
  { label: "BEAUMETTES (84220)", value: "84220" },
  { label: "BEAUMONT DE PERTUIS (84120)", value: "84120" },
  { label: "BEAUMONT DU VENTOUX (84340)", value: "84340" },
  { label: "BEDARRIDES (84370)", value: "84370" },
  { label: "BEDOIN (84410)", value: "84410" },
  { label: "BLAUVAC (84570)", value: "84570" },
  { label: "BOLLENE (84500)", value: "84500" },
  { label: "BONNIEUX (84480)", value: "84480" },
  { label: "BRANTES (84390)", value: "84390" },
  { label: "BUISSON (84110)", value: "84110" },
  { label: "BUOUX (84480)", value: "84480" },
  { label: "CABRIERES D AIGUES (84240)", value: "84240" },
  { label: "CABRIERES D AVIGNON (84220)", value: "84220" },
  { label: "CADENET (84160)", value: "84160" },
  { label: "CADEROUSSE (84860)", value: "84860" },
  { label: "CAIRANNE (84290)", value: "84290" },
  { label: "CAMARET SUR AIGUES (84850)", value: "84850" },
  { label: "CAROMB (84330)", value: "84330" },
  { label: "CARPENTRAS (84200)", value: "84200" },
  { label: "CASENEUVE (84750)", value: "84750" },
  { label: "CASTELLET EN LUBERON (84400)", value: "84400" },
  { label: "CAUMONT SUR DURANCE (84510)", value: "84510" },
  { label: "CAVAILLON (84300)", value: "84300" },
  { label: "CAVAILLON (84300)", value: "84300" },
  { label: "CHATEAUNEUF DE GADAGNE (84470)", value: "84470" },
  { label: "CHATEAUNEUF DU PAPE (84230)", value: "84230" },
  { label: "CHEVAL BLANC (84460)", value: "84460" },
  { label: "COURTHEZON (84350)", value: "84350" },
  { label: "CRESTET (84110)", value: "84110" },
  { label: "CRILLON LE BRAVE (84410)", value: "84410" },
  { label: "CUCURON (84160)", value: "84160" },
  { label: "ENTRAIGUES SUR LA SORGUE (84320)", value: "84320" },
  { label: "ENTRECHAUX (84340)", value: "84340" },
  { label: "FAUCON (84110)", value: "84110" },
  { label: "FLASSAN (84410)", value: "84410" },
  { label: "GARGAS (84400)", value: "84400" },
  { label: "GIGNAC (84400)", value: "84400" },
  { label: "GIGONDAS (84190)", value: "84190" },
  { label: "GORDES (84220)", value: "84220" },
  { label: "GOULT (84220)", value: "84220" },
  { label: "GRAMBOIS (84240)", value: "84240" },
  { label: "GRILLON (84600)", value: "84600" },
  { label: "L'ISLE SUR LA SORGUE (84800)", value: "84800" },
  { label: "JONQUERETTES (84450)", value: "84450" },
  { label: "JONQUIERES (84150)", value: "84150" },
  { label: "JOUCAS (84220)", value: "84220" },
  { label: "LACOSTE (84480)", value: "84480" },
  { label: "LAFARE (84190)", value: "84190" },
  { label: "LAGARDE D APT (84400)", value: "84400" },
  { label: "LAGARDE PAREOL (84290)", value: "84290" },
  { label: "LAGNES (84800)", value: "84800" },
  { label: "LAMOTTE DU RHONE (84840)", value: "84840" },
  { label: "LAPALUD (84840)", value: "84840" },
  { label: "LAURIS (84360)", value: "84360" },
  { label: "LIOUX (84220)", value: "84220" },
  { label: "LORIOL DU COMTAT (84870)", value: "84870" },
  { label: "LOURMARIN (84160)", value: "84160" },
  { label: "MALAUCENE (84340)", value: "84340" },
  { label: "MALEMORT DU COMTAT (84570)", value: "84570" },
  { label: "MAUBEC (84660)", value: "84660" },
  { label: "MAZAN (84380)", value: "84380" },
  { label: "MENERBES (84560)", value: "84560" },
  { label: "MERINDOL (84360)", value: "84360" },
  { label: "METHAMIS (84570)", value: "84570" },
  { label: "MIRABEAU (84120)", value: "84120" },
  { label: "MODENE (84330)", value: "84330" },
  { label: "MONDRAGON (84430)", value: "84430" },
  { label: "MONIEUX (84390)", value: "84390" },
  { label: "MONTEUX (84170)", value: "84170" },
  { label: "MORIERES LES AVIGNON (84310)", value: "84310" },
  { label: "MORMOIRON (84570)", value: "84570" },
  { label: "MORNAS (84550)", value: "84550" },
  { label: "LA MOTTE D AIGUES (84240)", value: "84240" },
  { label: "MURS (84220)", value: "84220" },
  { label: "OPPEDE (84580)", value: "84580" },
  { label: "ORANGE (84100)", value: "84100" },
  { label: "PERNES LES FONTAINES (84210)", value: "84210" },
  { label: "PERTUIS (84120)", value: "84120" },
  { label: "PEYPIN D AIGUES (84240)", value: "84240" },
  { label: "PIOLENC (84420)", value: "84420" },
  { label: "LE PONTET (84130)", value: "84130" },
  { label: "PUGET (84360)", value: "84360" },
  { label: "PUYMERAS (84110)", value: "84110" },
  { label: "PUYVERT (84160)", value: "84160" },
  { label: "RASTEAU (84110)", value: "84110" },
  { label: "RICHERENCHES (84600)", value: "84600" },
  { label: "ROAIX (84110)", value: "84110" },
  { label: "ROBION (84440)", value: "84440" },
  { label: "LA ROQUE ALRIC (84190)", value: "84190" },
  { label: "LA ROQUE SUR PERNES (84210)", value: "84210" },
  { label: "ROUSSILLON (84220)", value: "84220" },
  { label: "RUSTREL (84400)", value: "84400" },
  { label: "SABLET (84110)", value: "84110" },
  { label: "SAIGNON (84400)", value: "84400" },
  { label: "STE CECILE LES VIGNES (84290)", value: "84290" },
  { label: "ST CHRISTOL (84390)", value: "84390" },
  { label: "ST DIDIER (84210)", value: "84210" },
  { label: "ST HIPPOLYTE LE GRAVEYRON (84330)", value: "84330" },
  { label: "ST LEGER DU VENTOUX (84390)", value: "84390" },
  { label: "ST MARCELLIN LES VAISON (84110)", value: "84110" },
  { label: "ST MARTIN DE CASTILLON (84750)", value: "84750" },
  { label: "ST MARTIN DE LA BRASQUE (84760)", value: "84760" },
  { label: "ST PANTALEON (84220)", value: "84220" },
  { label: "ST PIERRE DE VASSOLS (84330)", value: "84330" },
  { label: "ST ROMAIN EN VIENNOIS (84110)", value: "84110" },
  { label: "ST ROMAN DE MALEGARDE (84290)", value: "84290" },
  { label: "ST SATURNIN LES APT (84490)", value: "84490" },
  { label: "ST SATURNIN LES AVIGNON (84450)", value: "84450" },
  { label: "ST TRINIT (84390)", value: "84390" },
  { label: "SANNES (84240)", value: "84240" },
  { label: "SARRIANS (84260)", value: "84260" },
  { label: "SAULT (84390)", value: "84390" },
  { label: "SAUMANE DE VAUCLUSE (84800)", value: "84800" },
  { label: "SAVOILLAN (84390)", value: "84390" },
  { label: "SEGURET (84110)", value: "84110" },
  { label: "SERIGNAN DU COMTAT (84830)", value: "84830" },
  { label: "SIVERGUES (84400)", value: "84400" },
  { label: "SORGUES (84700)", value: "84700" },
  { label: "SUZETTE (84190)", value: "84190" },
  { label: "TAILLADES (84300)", value: "84300" },
  { label: "LE THOR (84250)", value: "84250" },
  { label: "LA TOUR D AIGUES (84240)", value: "84240" },
  { label: "TRAVAILLAN (84850)", value: "84850" },
  { label: "UCHAUX (84100)", value: "84100" },
  { label: "VACQUEYRAS (84190)", value: "84190" },
  { label: "VAISON LA ROMAINE (84110)", value: "84110" },
  { label: "VALREAS (84600)", value: "84600" },
  { label: "FONTAINE DE VAUCLUSE (84800)", value: "84800" },
  { label: "VAUGINES (84160)", value: "84160" },
  { label: "VEDENE (84270)", value: "84270" },
  { label: "VELLERON (84740)", value: "84740" },
  { label: "VENASQUE (84210)", value: "84210" },
  { label: "VIENS (84750)", value: "84750" },
  { label: "VILLARS (84400)", value: "84400" },
  { label: "VILLEDIEU (84110)", value: "84110" },
  { label: "VILLELAURE (84530)", value: "84530" },
  { label: "VILLES SUR AUZON (84570)", value: "84570" },
  { label: "VIOLES (84150)", value: "84150" },
  { label: "VISAN (84820)", value: "84820" },
  { label: "VITROLLES EN LUBERON (84240)", value: "84240" },
  { label: "L'AIGUILLON LA PRESQU ILE (85460)", value: "85460" },
  { label: "L'AIGUILLON LA PRESQU ILE (85460)", value: "85460" },
  { label: "L'AIGUILLON SUR VIE (85220)", value: "85220" },
  { label: "AIZENAY (85190)", value: "85190" },
  { label: "ANGLES (85750)", value: "85750" },
  { label: "ANTIGNY (85120)", value: "85120" },
  { label: "APREMONT (85220)", value: "85220" },
  { label: "AUBIGNY LES CLOUZEAUX (85430)", value: "85430" },
  { label: "AUBIGNY LES CLOUZEAUX (85430)", value: "85430" },
  { label: "AUCHAY SUR VENDEE (85200)", value: "85200" },
  { label: "AUCHAY SUR VENDEE (85200)", value: "85200" },
  { label: "AVRILLE (85440)", value: "85440" },
  { label: "BARBATRE (85630)", value: "85630" },
  { label: "LA BARRE DE MONTS (85550)", value: "85550" },
  { label: "LA BARRE DE MONTS (85550)", value: "85550" },
  { label: "BAZOGES EN PAILLERS (85130)", value: "85130" },
  { label: "BAZOGES EN PAREDS (85390)", value: "85390" },
  { label: "BEAUFOU (85170)", value: "85170" },
  { label: "BEAULIEU SOUS LA ROCHE (85190)", value: "85190" },
  { label: "BEAUREPAIRE (85500)", value: "85500" },
  { label: "BEAUVOIR SUR MER (85230)", value: "85230" },
  { label: "BELLEVIGNY (85170)", value: "85170" },
  { label: "BELLEVIGNY (85170)", value: "85170" },
  { label: "BENET (85490)", value: "85490" },
  { label: "BENET (85490)", value: "85490" },
  { label: "BENET (85490)", value: "85490" },
  { label: "LA BERNARDIERE (85610)", value: "85610" },
  { label: "LE BERNARD (85560)", value: "85560" },
  { label: "BESSAY (85320)", value: "85320" },
  { label: "BOIS DE CENE (85710)", value: "85710" },
  { label: "LA BOISSIERE DE MONTAIGU (85600)", value: "85600" },
  { label: "LA BOISSIERE DES LANDES (85430)", value: "85430" },
  { label: "BOUILLE COURDAULT (85420)", value: "85420" },
  { label: "BOUIN (85230)", value: "85230" },
  { label: "LE BOUPERE (85510)", value: "85510" },
  { label: "BOURNEAU (85200)", value: "85200" },
  { label: "BOURNEZEAU (85480)", value: "85480" },
  { label: "BOURNEZEAU (85480)", value: "85480" },
  { label: "BRETIGNOLLES SUR MER (85470)", value: "85470" },
  { label: "LA BRETONNIERE LA CLAYE (85320)", value: "85320" },
  { label: "LA BRETONNIERE LA CLAYE (85320)", value: "85320" },
  { label: "LES BROUZILS (85260)", value: "85260" },
  { label: "LA BRUFFIERE (85530)", value: "85530" },
  { label: "LA CAILLERE ST HILAIRE (85410)", value: "85410" },
  { label: "LA CAILLERE ST HILAIRE (85410)", value: "85410" },
  { label: "CHAILLE LES MARAIS (85450)", value: "85450" },
  { label: "CHAILLE LES MARAIS (85450)", value: "85450" },
  { label: "CHAILLE LES MARAIS (85450)", value: "85450" },
  { label: "LA CHAIZE GIRAUD (85220)", value: "85220" },
  { label: "LA CHAIZE LE VICOMTE (85310)", value: "85310" },
  { label: "LA CHAIZE LE VICOMTE (85310)", value: "85310" },
  { label: "CHALLANS (85300)", value: "85300" },
  { label: "CHAMPAGNE LES MARAIS (85450)", value: "85450" },
  { label: "LE CHAMP ST PERE (85540)", value: "85540" },
  { label: "CHANTONNAY (85110)", value: "85110" },
  { label: "CHANTONNAY (85110)", value: "85110" },
  { label: "LA CHAPELLE HERMIER (85220)", value: "85220" },
  { label: "LA CHAPELLE PALLUAU (85670)", value: "85670" },
  { label: "LA CHAPELLE THEMER (85210)", value: "85210" },
  { label: "CHASNAIS (85400)", value: "85400" },
  { label: "LA CHATAIGNERAIE (85120)", value: "85120" },
  { label: "CHATEAU GUIBERT (85320)", value: "85320" },
  { label: "CHATEAUNEUF (85710)", value: "85710" },
  { label: "CHAUCHE (85140)", value: "85140" },
  { label: "CHAVAGNES EN PAILLERS (85250)", value: "85250" },
  { label: "CHAVAGNES LES REDOUX (85390)", value: "85390" },
  { label: "CHEFFOIS (85390)", value: "85390" },
  { label: "COEX (85220)", value: "85220" },
  { label: "COMMEQUIERS (85220)", value: "85220" },
  { label: "LA COPECHAGNIERE (85260)", value: "85260" },
  { label: "CORPE (85320)", value: "85320" },
  { label: "LA COUTURE (85320)", value: "85320" },
  { label: "CUGAND (85610)", value: "85610" },
  { label: "CURZON (85540)", value: "85540" },
  { label: "DAMVIX (85420)", value: "85420" },
  { label: "DOIX LES FONTAINES (85200)", value: "85200" },
  { label: "DOIX LES FONTAINES (85200)", value: "85200" },
  { label: "DOMPIERRE SUR YON (85170)", value: "85170" },
  { label: "LES EPESSES (85590)", value: "85590" },
  { label: "L'EPINE (85740)", value: "85740" },
  { label: "ESSARTS EN BOCAGE (85140)", value: "85140" },
  { label: "ESSARTS EN BOCAGE (85140)", value: "85140" },
  { label: "FALLERON (85670)", value: "85670" },
  { label: "FAYMOREAU (85240)", value: "85240" },
  { label: "LE FENOUILLER (85800)", value: "85800" },
  { label: "LA FERRIERE (85280)", value: "85280" },
  { label: "SEVREMONT (85700)", value: "85700" },
  { label: "SEVREMONT (85700)", value: "85700" },
  { label: "SEVREMONT (85700)", value: "85700" },
  { label: "SEVREMONT (85700)", value: "85700" },
  { label: "FONTENAY LE COMTE (85200)", value: "85200" },
  { label: "FONTENAY LE COMTE (85200)", value: "85200" },
  { label: "FOUGERE (85480)", value: "85480" },
  { label: "FOUSSAIS PAYRE (85240)", value: "85240" },
  { label: "FROIDFOND (85300)", value: "85300" },
  { label: "LA GARNACHE (85710)", value: "85710" },
  { label: "LA GAUBRETIERE (85130)", value: "85130" },
  { label: "LA GENETOUZE (85190)", value: "85190" },
  { label: "LE GIROUARD (85150)", value: "85150" },
  { label: "GIVRAND (85800)", value: "85800" },
  { label: "LE GIVRE (85540)", value: "85540" },
  { label: "GRAND LANDES (85670)", value: "85670" },
  { label: "GROSBREUIL (85440)", value: "85440" },
  { label: "GRUES (85580)", value: "85580" },
  { label: "LE GUE DE VELLUIRE (85770)", value: "85770" },
  { label: "LA GUERINIERE (85680)", value: "85680" },
  { label: "L'HERBERGEMENT (85260)", value: "85260" },
  { label: "LES HERBIERS (85500)", value: "85500" },
  { label: "L'HERMENAULT (85570)", value: "85570" },
  { label: "L'ILE D ELLE (85770)", value: "85770" },
  { label: "L'ILE D OLONNE (85340)", value: "85340" },
  { label: "L'ILE D YEU (85350)", value: "85350" },
  { label: "L'ILE D YEU (85350)", value: "85350" },
  { label: "JARD SUR MER (85520)", value: "85520" },
  { label: "LA JAUDONNIERE (85110)", value: "85110" },
  { label: "LA JONCHERE (85540)", value: "85540" },
  { label: "LAIROUX (85400)", value: "85400" },
  { label: "LANDERONDE (85150)", value: "85150" },
  { label: "LES LANDES GENUSSON (85130)", value: "85130" },
  { label: "LANDEVIEILLE (85220)", value: "85220" },
  { label: "LE LANGON (85370)", value: "85370" },
  { label: "LIEZ (85420)", value: "85420" },
  { label: "LOGE FOUGEREUSE (85120)", value: "85120" },
  { label: "LONGEVES (85200)", value: "85200" },
  { label: "LONGEVILLE SUR MER (85560)", value: "85560" },
  { label: "LUCON (85400)", value: "85400" },
  { label: "LES LUCS SUR BOULOGNE (85170)", value: "85170" },
  { label: "MACHE (85190)", value: "85190" },
  { label: "LES MAGNILS REIGNIERS (85400)", value: "85400" },
  { label: "MAILLE (85420)", value: "85420" },
  { label: "MAILLEZAIS (85420)", value: "85420" },
  { label: "MALLIEVRE (85590)", value: "85590" },
  { label: "MAREUIL SUR LAY DISSAIS (85320)", value: "85320" },
  { label: "MAREUIL SUR LAY DISSAIS (85320)", value: "85320" },
  { label: "MARILLET (85240)", value: "85240" },
  { label: "MARSAIS STE RADEGONDE (85570)", value: "85570" },
  { label: "MARTINET (85150)", value: "85150" },
  { label: "LE MAZEAU (85420)", value: "85420" },
  { label: "LA MEILLERAIE TILLAY (85700)", value: "85700" },
  { label: "MENOMBLET (85700)", value: "85700" },
  { label: "LA MERLATIERE (85140)", value: "85140" },
  { label: "MERVENT (85200)", value: "85200" },
  { label: "MESNARD LA BAROTIERE (85500)", value: "85500" },
  { label: "MONSIREIGNE (85110)", value: "85110" },
  { label: "MONTAIGU VENDEE (85600)", value: "85600" },
  { label: "MONTAIGU VENDEE (85600)", value: "85600" },
  { label: "MONTAIGU VENDEE (85600)", value: "85600" },
  { label: "MONTAIGU VENDEE (85600)", value: "85600" },
  { label: "MONTAIGU VENDEE (85600)", value: "85600" },
  { label: "MONTOURNAIS (85700)", value: "85700" },
  { label: "MONTREUIL (85200)", value: "85200" },
  { label: "MOREILLES (85450)", value: "85450" },
  { label: "MORTAGNE SUR SEVRE (85290)", value: "85290" },
  { label: "LES ACHARDS (85150)", value: "85150" },
  { label: "LES ACHARDS (85150)", value: "85150" },
  { label: "MOUCHAMPS (85640)", value: "85640" },
  { label: "MOUILLERON ST GERMAIN (85390)", value: "85390" },
  { label: "MOUILLERON ST GERMAIN (85390)", value: "85390" },
  { label: "MOUILLERON LE CAPTIF (85000)", value: "85000" },
  { label: "MOUTIERS LES MAUXFAITS (85540)", value: "85540" },
  { label: "MOUTIERS SUR LE LAY (85320)", value: "85320" },
  { label: "MOUZEUIL ST MARTIN (85370)", value: "85370" },
  { label: "NALLIERS (85370)", value: "85370" },
  { label: "NESMY (85310)", value: "85310" },
  { label: "NIEUL LE DOLENT (85430)", value: "85430" },
  { label: "RIVES D AUTISE (85240)", value: "85240" },
  { label: "RIVES D AUTISE (85420)", value: "85420" },
  { label: "NOIRMOUTIER EN L ILE (85330)", value: "85330" },
  { label: "NOTRE DAME DE MONTS (85690)", value: "85690" },
  { label: "L'OIE (85140)", value: "85140" },
  { label: "L'ORBRIE (85200)", value: "85200" },
  { label: "PALLUAU (85670)", value: "85670" },
  { label: "PEAULT (85320)", value: "85320" },
  { label: "LE PERRIER (85300)", value: "85300" },
  { label: "PETOSSE (85570)", value: "85570" },
  { label: "LES PINEAUX (85320)", value: "85320" },
  { label: "PISSOTTE (85200)", value: "85200" },
  { label: "LES VELLUIRE SUR VENDEE (85770)", value: "85770" },
  { label: "LES VELLUIRE SUR VENDEE (85770)", value: "85770" },
  { label: "LE POIRE SUR VIE (85170)", value: "85170" },
  { label: "POIROUX (85440)", value: "85440" },
  { label: "POUILLE (85570)", value: "85570" },
  { label: "POUZAUGES (85700)", value: "85700" },
  { label: "PUY DE SERRE (85240)", value: "85240" },
  { label: "PUYRAVAULT (85450)", value: "85450" },
  { label: "LA RABATELIERE (85250)", value: "85250" },
  { label: "REAUMUR (85700)", value: "85700" },
  { label: "LA REORTHE (85210)", value: "85210" },
  { label: "NOTRE DAME DE RIEZ (85270)", value: "85270" },
  { label: "ROCHESERVIERE (85620)", value: "85620" },
  { label: "LA ROCHE SUR YON (85000)", value: "85000" },
  { label: "ROCHETREJOUX (85510)", value: "85510" },
  { label: "ROSNAY (85320)", value: "85320" },
  { label: "LES SABLES D OLONNE (85100)", value: "85100" },
  { label: "LES SABLES D OLONNE (85180)", value: "85180" },
  { label: "LES SABLES D OLONNE (85340)", value: "85340" },
  { label: "ST ANDRE GOULE D OIE (85250)", value: "85250" },
  { label: "MONTREVERD (85260)", value: "85260" },
  { label: "MONTREVERD (85260)", value: "85260" },
  { label: "MONTREVERD (85260)", value: "85260" },
  { label: "ST AUBIN DES ORMEAUX (85130)", value: "85130" },
  { label: "ST AUBIN LA PLAINE (85210)", value: "85210" },
  { label: "ST AVAUGOURD DES LANDES (85540)", value: "85540" },
  { label: "ST BENOIST SUR MER (85540)", value: "85540" },
  { label: "STE CECILE (85110)", value: "85110" },
  { label: "ST CHRISTOPHE DU LIGNERON (85670)", value: "85670" },
  { label: "ST CYR DES GATS (85410)", value: "85410" },
  { label: "ST CYR EN TALMONDAIS (85540)", value: "85540" },
  { label: "ST DENIS DU PAYRE (85580)", value: "85580" },
  { label: "ST DENIS LA CHEVASSE (85170)", value: "85170" },
  { label: "ST ETIENNE DE BRILLOUET (85210)", value: "85210" },
  { label: "ST ETIENNE DU BOIS (85670)", value: "85670" },
  { label: "STE FLAIVE DES LOUPS (85150)", value: "85150" },
  { label: "STE FLORENCE (85140)", value: "85140" },
  { label: "RIVES DE L YON (85310)", value: "85310" },
  { label: "RIVES DE L YON (85310)", value: "85310" },
  { label: "STE FOY (85150)", value: "85150" },
  { label: "ST FULGENT (85250)", value: "85250" },
  { label: "STE GEMME LA PLAINE (85400)", value: "85400" },
  { label: "ST GEORGES DE POINTINDOUX (85150)", value: "85150" },
  { label: "ST GERMAIN DE PRINCAY (85110)", value: "85110" },
  { label: "ST GERVAIS (85230)", value: "85230" },
  { label: "ST GILLES CROIX DE VIE (85800)", value: "85800" },
  { label: "STE HERMINE (85210)", value: "85210" },
  { label: "ST HILAIRE DE RIEZ (85270)", value: "85270" },
  { label: "ST HILAIRE DE RIEZ (85270)", value: "85270" },
  { label: "ST HILAIRE DES LOGES (85240)", value: "85240" },
  { label: "ST HILAIRE DE VOUST (85120)", value: "85120" },
  { label: "ST HILAIRE LA FORET (85440)", value: "85440" },
  { label: "ST HILAIRE LE VOUHIS (85480)", value: "85480" },
  { label: "ST JEAN DE BEUGNE (85210)", value: "85210" },
  { label: "ST JEAN DE MONTS (85160)", value: "85160" },
  { label: "ST JUIRE CHAMPGILLON (85210)", value: "85210" },
  { label: "ST JULIEN DES LANDES (85150)", value: "85150" },
  { label: "ST LAURENT DE LA SALLE (85410)", value: "85410" },
  { label: "ST LAURENT SUR SEVRE (85290)", value: "85290" },
  { label: "ST MAIXENT SUR VIE (85220)", value: "85220" },
  { label: "ST MALO DU BOIS (85590)", value: "85590" },
  { label: "ST MARS LA REORTHE (85590)", value: "85590" },
  { label: "BREM SUR MER (85470)", value: "85470" },
  { label: "BREM SUR MER (85470)", value: "85470" },
  { label: "ST MARTIN DE FRAIGNEAU (85200)", value: "85200" },
  { label: "ST MARTIN DES FONTAINES (85570)", value: "85570" },
  { label: "ST MARTIN DES NOYERS (85140)", value: "85140" },
  { label: "ST MARTIN DES TILLEULS (85130)", value: "85130" },
  { label: "ST MARTIN LARS EN STE HERMINE (85210)", value: "85210" },
  { label: "ST MATHURIN (85150)", value: "85150" },
  { label: "ST MAURICE DES NOUES (85120)", value: "85120" },
  { label: "ST MAURICE LE GIRARD (85390)", value: "85390" },
  { label: "ST MESMIN (85700)", value: "85700" },
  { label: "ST MICHEL EN L HERM (85580)", value: "85580" },
  { label: "ST MICHEL LE CLOUCQ (85200)", value: "85200" },
  { label: "ST PAUL EN PAREDS (85500)", value: "85500" },
  { label: "ST PAUL MONT PENIT (85670)", value: "85670" },
  { label: "STE PEXINE (85320)", value: "85320" },
  { label: "ST PHILBERT DE BOUAINE (85660)", value: "85660" },
  { label: "ST PIERRE DU CHEMIN (85120)", value: "85120" },
  { label: "ST PIERRE LE VIEUX (85420)", value: "85420" },
  { label: "ST PROUANT (85110)", value: "85110" },
  { label: "STE RADEGONDE DES NOYERS (85450)", value: "85450" },
  { label: "ST REVEREND (85220)", value: "85220" },
  { label: "ST SIGISMOND (85420)", value: "85420" },
  { label: "ST URBAIN (85230)", value: "85230" },
  { label: "ST VALERIEN (85570)", value: "85570" },
  { label: "ST VINCENT STERLANGES (85110)", value: "85110" },
  { label: "ST VINCENT SUR GRAON (85540)", value: "85540" },
  { label: "ST VINCENT SUR GRAON (85540)", value: "85540" },
  { label: "ST VINCENT SUR JARD (85520)", value: "85520" },
  { label: "SALLERTAINE (85300)", value: "85300" },
  { label: "SERIGNE (85200)", value: "85200" },
  { label: "SIGOURNAIS (85110)", value: "85110" },
  { label: "SOULLANS (85300)", value: "85300" },
  { label: "LE TABLIER (85310)", value: "85310" },
  { label: "LA TAILLEE (85450)", value: "85450" },
  { label: "TALLUD STE GEMME (85390)", value: "85390" },
  { label: "TALMONT ST HILAIRE (85440)", value: "85440" },
  { label: "TALMONT ST HILAIRE (85440)", value: "85440" },
  { label: "TERVAL (85120)", value: "85120" },
  { label: "TERVAL (85120)", value: "85120" },
  { label: "TERVAL (85120)", value: "85120" },
  { label: "THIRE (85210)", value: "85210" },
  { label: "THORIGNY (85480)", value: "85480" },
  { label: "RIVES DU FOUGERAIS (85410)", value: "85410" },
  { label: "RIVES DU FOUGERAIS (85410)", value: "85410" },
  { label: "RIVES DU FOUGERAIS (85410)", value: "85410" },
  { label: "TIFFAUGES (85130)", value: "85130" },
  { label: "LA TRANCHE SUR MER (85360)", value: "85360" },
  { label: "TREIZE SEPTIERS (85600)", value: "85600" },
  { label: "TREIZE VENTS (85590)", value: "85590" },
  { label: "TRIAIZE (85580)", value: "85580" },
  { label: "VAIRE (85150)", value: "85150" },
  { label: "VENANSAULT (85190)", value: "85190" },
  { label: "VENDRENNES (85250)", value: "85250" },
  { label: "CHANVERRIE (85130)", value: "85130" },
  { label: "CHANVERRIE (85500)", value: "85500" },
  { label: "VIX (85770)", value: "85770" },
  { label: "VOUILLE LES MARAIS (85450)", value: "85450" },
  { label: "VOUVANT (85120)", value: "85120" },
  { label: "XANTON CHASSENON (85240)", value: "85240" },
  { label: "ADRIERS (86430)", value: "86430" },
  { label: "AMBERRE (86110)", value: "86110" },
  { label: "ANCHE (86700)", value: "86700" },
  { label: "ANGLES SUR L ANGLIN (86260)", value: "86260" },
  { label: "ANGLIERS (86330)", value: "86330" },
  { label: "ANTIGNY (86310)", value: "86310" },
  { label: "ANTRAN (86100)", value: "86100" },
  { label: "ARCAY (86200)", value: "86200" },
  { label: "ARCHIGNY (86210)", value: "86210" },
  { label: "ASLONNES (86340)", value: "86340" },
  { label: "ASNIERES SUR BLOUR (86430)", value: "86430" },
  { label: "ASNOIS (86250)", value: "86250" },
  { label: "AULNAY (86330)", value: "86330" },
  { label: "AVAILLES EN CHATELLERAULT (86530)", value: "86530" },
  { label: "AVAILLES LIMOUZINE (86460)", value: "86460" },
  { label: "AVANTON (86170)", value: "86170" },
  { label: "AYRON (86190)", value: "86190" },
  { label: "BASSES (86200)", value: "86200" },
  { label: "BEAUMONT ST CYR (86130)", value: "86130" },
  { label: "BEAUMONT ST CYR (86490)", value: "86490" },
  { label: "BEAUMONT ST CYR (86490)", value: "86490" },
  { label: "BELLEFONDS (86210)", value: "86210" },
  { label: "BERRIE (86120)", value: "86120" },
  { label: "BERTHEGON (86420)", value: "86420" },
  { label: "BERUGES (86190)", value: "86190" },
  { label: "BETHINES (86310)", value: "86310" },
  { label: "BEUXES (86120)", value: "86120" },
  { label: "BIARD (86580)", value: "86580" },
  { label: "BIGNOUX (86800)", value: "86800" },
  { label: "BLANZAY (86400)", value: "86400" },
  { label: "BONNES (86300)", value: "86300" },
  { label: "BONNEUIL MATOURS (86210)", value: "86210" },
  { label: "BOURESSE (86410)", value: "86410" },
  { label: "BOURG ARCHAMBAULT (86390)", value: "86390" },
  { label: "BOURNAND (86120)", value: "86120" },
  { label: "BRIGUEIL LE CHANTRE (86290)", value: "86290" },
  { label: "BRION (86160)", value: "86160" },
  { label: "BRUX (86510)", value: "86510" },
  { label: "LA BUSSIERE (86310)", value: "86310" },
  { label: "BUXEROLLES (86180)", value: "86180" },
  { label: "BUXEUIL (37160)", value: "37160" },
  { label: "CEAUX EN LOUDUN (86200)", value: "86200" },
  { label: "CELLE LEVESCAULT (86600)", value: "86600" },
  { label: "CENON SUR VIENNE (86530)", value: "86530" },
  { label: "CERNAY (86140)", value: "86140" },
  { label: "CHABOURNAY (86380)", value: "86380" },
  { label: "CHALAIS (86200)", value: "86200" },
  { label: "CHALANDRAY (86190)", value: "86190" },
  { label: "CHAMPAGNE LE SEC (86510)", value: "86510" },
  { label: "CHAMPAGNE ST HILAIRE (86160)", value: "86160" },
  { label: "CHAMPIGNY EN ROCHEREAU (86170)", value: "86170" },
  { label: "CHAMPIGNY EN ROCHEREAU (86170)", value: "86170" },
  { label: "CHAMPNIERS (86400)", value: "86400" },
  { label: "LA CHAPELLE BATON (86250)", value: "86250" },
  { label: "LA CHAPELLE MOULIERE (86210)", value: "86210" },
  { label: "CHAPELLE VIVIERS (86300)", value: "86300" },
  { label: "CHARROUX (86250)", value: "86250" },
  { label: "CHASSENEUIL DU POITOU (86360)", value: "86360" },
  { label: "CHATAIN (86250)", value: "86250" },
  { label: "CHATEAU GARNIER (86350)", value: "86350" },
  { label: "CHATEAU LARCHER (86370)", value: "86370" },
  { label: "CHATELLERAULT (86100)", value: "86100" },
  { label: "CHATELLERAULT (86100)", value: "86100" },
  { label: "CHAUNAY (86510)", value: "86510" },
  { label: "LA CHAUSSEE (86330)", value: "86330" },
  { label: "CHAUVIGNY (86300)", value: "86300" },
  { label: "CHAUVIGNY (86300)", value: "86300" },
  { label: "CHENEVELLES (86450)", value: "86450" },
  { label: "CHERVES (86170)", value: "86170" },
  { label: "CHIRE EN MONTREUIL (86190)", value: "86190" },
  { label: "CHOUPPES (86110)", value: "86110" },
  { label: "CISSE (86170)", value: "86170" },
  { label: "CIVAUX (86320)", value: "86320" },
  { label: "CIVRAY (86400)", value: "86400" },
  { label: "LA ROCHE RIGAULT (86200)", value: "86200" },
  { label: "LA ROCHE RIGAULT (86200)", value: "86200" },
  { label: "LA ROCHE RIGAULT (86200)", value: "86200" },
  { label: "CLOUE (86600)", value: "86600" },
  { label: "COLOMBIERS (86490)", value: "86490" },
  { label: "VALENCE EN POITOU (86700)", value: "86700" },
  { label: "VALENCE EN POITOU (86700)", value: "86700" },
  { label: "VALENCE EN POITOU (86700)", value: "86700" },
  { label: "VALENCE EN POITOU (86700)", value: "86700" },
  { label: "VALENCE EN POITOU (86700)", value: "86700" },
  { label: "COULOMBIERS (86600)", value: "86600" },
  { label: "COULONGES LES HEROLLES (86290)", value: "86290" },
  { label: "COUSSAY (86110)", value: "86110" },
  { label: "COUSSAY LES BOIS (86270)", value: "86270" },
  { label: "CRAON (86110)", value: "86110" },
  { label: "CROUTELLE (86240)", value: "86240" },
  { label: "CUHON (86110)", value: "86110" },
  { label: "CURCAY SUR DIVE (86120)", value: "86120" },
  { label: "CURZAY SUR VONNE (86600)", value: "86600" },
  { label: "DANGE ST ROMAIN (86220)", value: "86220" },
  { label: "DERCE (86420)", value: "86420" },
  { label: "DIENNE (86410)", value: "86410" },
  { label: "DISSAY (86130)", value: "86130" },
  { label: "DOUSSAY (86140)", value: "86140" },
  { label: "LA FERRIERE AIROUX (86160)", value: "86160" },
  { label: "FLEIX (86300)", value: "86300" },
  { label: "FLEURE (86340)", value: "86340" },
  { label: "FONTAINE LE COMTE (86240)", value: "86240" },
  { label: "FROZES (86190)", value: "86190" },
  { label: "GENCAY (86160)", value: "86160" },
  { label: "GENOUILLE (86250)", value: "86250" },
  { label: "GIZAY (86340)", value: "86340" },
  { label: "GLENOUZE (86200)", value: "86200" },
  { label: "GOUEX (86320)", value: "86320" },
  { label: "LA GRIMAUDIERE (86110)", value: "86110" },
  { label: "LA GRIMAUDIERE (86330)", value: "86330" },
  { label: "LA GRIMAUDIERE (86330)", value: "86330" },
  { label: "GUESNES (86420)", value: "86420" },
  { label: "HAIMS (86310)", value: "86310" },
  { label: "INGRANDES (86220)", value: "86220" },
  { label: "L'ISLE JOURDAIN (86150)", value: "86150" },
  { label: "ITEUIL (86240)", value: "86240" },
  { label: "JARDRES (86800)", value: "86800" },
  { label: "JAUNAY MARIGNY (86130)", value: "86130" },
  { label: "JAUNAY MARIGNY (86380)", value: "86380" },
  { label: "JAZENEUIL (86600)", value: "86600" },
  { label: "JOUHET (86500)", value: "86500" },
  { label: "JOURNET (86290)", value: "86290" },
  { label: "JOUSSE (86350)", value: "86350" },
  { label: "LATHUS ST REMY (86390)", value: "86390" },
  { label: "LATHUS ST REMY (86390)", value: "86390" },
  { label: "LATILLE (86190)", value: "86190" },
  { label: "LAUTHIERS (86300)", value: "86300" },
  { label: "BOIVRE LA VALLEE (86470)", value: "86470" },
  { label: "BOIVRE LA VALLEE (86470)", value: "86470" },
  { label: "BOIVRE LA VALLEE (86470)", value: "86470" },
  { label: "BOIVRE LA VALLEE (86470)", value: "86470" },
  { label: "LAVOUX (86800)", value: "86800" },
  { label: "LEIGNE LES BOIS (86450)", value: "86450" },
  { label: "LEIGNES SUR FONTAINE (86300)", value: "86300" },
  { label: "LEIGNE SUR USSEAU (86230)", value: "86230" },
  { label: "LENCLOITRE (86140)", value: "86140" },
  { label: "LESIGNY (86270)", value: "86270" },
  { label: "LEUGNY (86220)", value: "86220" },
  { label: "LHOMMAIZE (86410)", value: "86410" },
  { label: "LIGLET (86290)", value: "86290" },
  { label: "LIGUGE (86240)", value: "86240" },
  { label: "LINAZAY (86400)", value: "86400" },
  { label: "LINIERS (86800)", value: "86800" },
  { label: "LIZANT (86400)", value: "86400" },
  { label: "LOUDUN (86200)", value: "86200" },
  { label: "LOUDUN (86200)", value: "86200" },
  { label: "LUCHAPT (86430)", value: "86430" },
  { label: "LUSIGNAN (86600)", value: "86600" },
  { label: "LUSSAC LES CHATEAUX (86320)", value: "86320" },
  { label: "MAGNE (86160)", value: "86160" },
  { label: "MAILLE (86190)", value: "86190" },
  { label: "MAIRE (86270)", value: "86270" },
  { label: "MAISONNEUVE (86170)", value: "86170" },
  { label: "MARCAY (86370)", value: "86370" },
  { label: "MARIGNY CHEMEREAU (86370)", value: "86370" },
  { label: "MARNAY (86160)", value: "86160" },
  { label: "MARTAIZE (86330)", value: "86330" },
  { label: "MASSOGNES (86170)", value: "86170" },
  { label: "MAULAY (86200)", value: "86200" },
  { label: "MAUPREVOIR (86460)", value: "86460" },
  { label: "MAZEROLLES (86320)", value: "86320" },
  { label: "MAZEUIL (86110)", value: "86110" },
  { label: "MESSEME (86200)", value: "86200" },
  { label: "MIGNALOUX BEAUVOIR (86550)", value: "86550" },
  { label: "MIGNE AUXANCES (86440)", value: "86440" },
  { label: "MILLAC (86150)", value: "86150" },
  { label: "MIREBEAU (86110)", value: "86110" },
  { label: "MONCONTOUR (86330)", value: "86330" },
  { label: "MONCONTOUR (86330)", value: "86330" },
  { label: "MONCONTOUR (86330)", value: "86330" },
  { label: "MONCONTOUR (86330)", value: "86330" },
  { label: "MONDION (86230)", value: "86230" },
  { label: "MONTAMISE (86360)", value: "86360" },
  { label: "MONTHOIRON (86210)", value: "86210" },
  { label: "MONTMORILLON (86500)", value: "86500" },
  { label: "MONTS SUR GUESNES (86420)", value: "86420" },
  { label: "MORTON (86120)", value: "86120" },
  { label: "MOULISMES (86500)", value: "86500" },
  { label: "MOUSSAC (86150)", value: "86150" },
  { label: "MOUTERRE SUR BLOURDE (86430)", value: "86430" },
  { label: "MOUTERRE SILLY (86200)", value: "86200" },
  { label: "NAINTRE (86530)", value: "86530" },
  { label: "NALLIERS (86310)", value: "86310" },
  { label: "NERIGNAC (86150)", value: "86150" },
  { label: "NEUVILLE DE POITOU (86170)", value: "86170" },
  { label: "NIEUIL L ESPOIR (86340)", value: "86340" },
  { label: "NOUAILLE MAUPERTUIS (86340)", value: "86340" },
  { label: "NUEIL SOUS FAYE (86200)", value: "86200" },
  { label: "ORCHES (86230)", value: "86230" },
  { label: "LES ORMES (86220)", value: "86220" },
  { label: "OUZILLY (86380)", value: "86380" },
  { label: "OYRE (86220)", value: "86220" },
  { label: "PAIZAY LE SEC (86300)", value: "86300" },
  { label: "PAYROUX (86350)", value: "86350" },
  { label: "PERSAC (86320)", value: "86320" },
  { label: "PINDRAY (86500)", value: "86500" },
  { label: "PLAISANCE (86500)", value: "86500" },
  { label: "PLEUMARTIN (86450)", value: "86450" },
  { label: "POITIERS (86000)", value: "86000" },
  { label: "PORT DE PILES (86220)", value: "86220" },
  { label: "POUANCAY (86120)", value: "86120" },
  { label: "POUANT (86200)", value: "86200" },
  { label: "POUILLE (86800)", value: "86800" },
  { label: "PRESSAC (86460)", value: "86460" },
  { label: "PRINCAY (86420)", value: "86420" },
  { label: "LA PUYE (86260)", value: "86260" },
  { label: "QUEAUX (86150)", value: "86150" },
  { label: "QUINCAY (86190)", value: "86190" },
  { label: "RANTON (86200)", value: "86200" },
  { label: "RASLAY (86120)", value: "86120" },
  { label: "LA ROCHE POSAY (86270)", value: "86270" },
  { label: "ROCHES PREMARIE ANDILLE (86340)", value: "86340" },
  { label: "ROIFFE (86120)", value: "86120" },
  { label: "ROMAGNE (86700)", value: "86700" },
  { label: "ROUILLE (86480)", value: "86480" },
  { label: "ST BENOIT (86280)", value: "86280" },
  { label: "ST CHRISTOPHE (86230)", value: "86230" },
  { label: "ST CLAIR (86330)", value: "86330" },
  { label: "ST GAUDENT (86400)", value: "86400" },
  { label: "ST GENEST D AMBIERE (86140)", value: "86140" },
  { label: "ST GEORGES LES BAILLARGEAUX (86130)", value: "86130" },
  { label: "ST GERMAIN (86310)", value: "86310" },
  { label: "ST GERVAIS LES TROIS CLOCHERS (86230)", value: "86230" },
  { label: "ST JEAN DE SAUVES (86330)", value: "86330" },
  { label: "ST JEAN DE SAUVES (86330)", value: "86330" },
  { label: "ST JULIEN L ARS (86800)", value: "86800" },
  { label: "ST LAON (86200)", value: "86200" },
  { label: "ST LAURENT DE JOURDES (86410)", value: "86410" },
  { label: "ST LEGER DE MONTBRILLAIS (86120)", value: "86120" },
  { label: "ST LEOMER (86290)", value: "86290" },
  { label: "VALDIVIENNE (86300)", value: "86300" },
  { label: "VALDIVIENNE (86300)", value: "86300" },
  { label: "VALDIVIENNE (86300)", value: "86300" },
  { label: "VALDIVIENNE (86300)", value: "86300" },
  { label: "VALDIVIENNE (86300)", value: "86300" },
  { label: "ST MARTIN L ARS (86350)", value: "86350" },
  { label: "ST MAURICE LA CLOUERE (86160)", value: "86160" },
  { label: "ST PIERRE DE MAILLE (86260)", value: "86260" },
  { label: "ST PIERRE D EXIDEUIL (86400)", value: "86400" },
  { label: "STE RADEGONDE (86300)", value: "86300" },
  { label: "ST REMY SUR CREUSE (86220)", value: "86220" },
  { label: "ST ROMAIN (86250)", value: "86250" },
  { label: "ST SAUVANT (86600)", value: "86600" },
  { label: "SENILLE ST SAUVEUR (86100)", value: "86100" },
  { label: "SENILLE ST SAUVEUR (86100)", value: "86100" },
  { label: "ST SAVIN (86310)", value: "86310" },
  { label: "VAL DE COMPORTE (86400)", value: "86400" },
  { label: "VAL DE COMPORTE (86400)", value: "86400" },
  { label: "ST SECONDIN (86350)", value: "86350" },
  { label: "SAIRES (86420)", value: "86420" },
  { label: "SAIX (86120)", value: "86120" },
  { label: "SAMMARCOLLES (86200)", value: "86200" },
  { label: "SANXAY (86600)", value: "86600" },
  { label: "SAULGE (86500)", value: "86500" },
  { label: "SAVIGNE (86400)", value: "86400" },
  { label: "SAVIGNY LEVESCAULT (86800)", value: "86800" },
  { label: "SAVIGNY SOUS FAYE (86140)", value: "86140" },
  { label: "SCORBE CLAIRVAUX (86140)", value: "86140" },
  { label: "SERIGNY (86230)", value: "86230" },
  { label: "SEVRES ANXAUMONT (86800)", value: "86800" },
  { label: "SILLARS (86320)", value: "86320" },
  { label: "SMARVES (86240)", value: "86240" },
  { label: "SOMMIERES DU CLAIN (86160)", value: "86160" },
  { label: "SOSSAIS (86230)", value: "86230" },
  { label: "SURIN (86250)", value: "86250" },
  { label: "TERCE (86800)", value: "86800" },
  { label: "TERNAY (86120)", value: "86120" },
  { label: "THOLLET (86290)", value: "86290" },
  { label: "THURAGEAU (86110)", value: "86110" },
  { label: "THURE (86540)", value: "86540" },
  { label: "LA TRIMOUILLE (86290)", value: "86290" },
  { label: "LES TROIS MOUTIERS (86120)", value: "86120" },
  { label: "USSEAU (86230)", value: "86230" },
  { label: "USSON DU POITOU (86350)", value: "86350" },
  { label: "VAUX SUR VIENNE (86220)", value: "86220" },
  { label: "VELLECHES (86230)", value: "86230" },
  { label: "ST MARTIN LA PALLU (86110)", value: "86110" },
  { label: "ST MARTIN LA PALLU (86170)", value: "86170" },
  { label: "ST MARTIN LA PALLU (86170)", value: "86170" },
  { label: "ST MARTIN LA PALLU (86380)", value: "86380" },
  { label: "ST MARTIN LA PALLU (86380)", value: "86380" },
  { label: "VERNON (86340)", value: "86340" },
  { label: "VERRIERES (86410)", value: "86410" },
  { label: "VERRUE (86420)", value: "86420" },
  { label: "VEZIERES (86120)", value: "86120" },
  { label: "VICQ SUR GARTEMPE (86260)", value: "86260" },
  { label: "LE VIGEANT (86150)", value: "86150" },
  { label: "LA VILLEDIEU DU CLAIN (86340)", value: "86340" },
  { label: "VILLEMORT (86310)", value: "86310" },
  { label: "VILLIERS (86190)", value: "86190" },
  { label: "VIVONNE (86370)", value: "86370" },
  { label: "VOUILLE (86190)", value: "86190" },
  { label: "VOULEME (86400)", value: "86400" },
  { label: "VOULON (86700)", value: "86700" },
  { label: "VOUNEUIL SOUS BIARD (86580)", value: "86580" },
  { label: "VOUNEUIL SOUS BIARD (86580)", value: "86580" },
  { label: "VOUNEUIL SUR VIENNE (86210)", value: "86210" },
  { label: "VOUZAILLES (86170)", value: "86170" },
  { label: "YVERSAY (86170)", value: "86170" },
  { label: "AIXE SUR VIENNE (87700)", value: "87700" },
  { label: "AMBAZAC (87240)", value: "87240" },
  { label: "ARNAC LA POSTE (87160)", value: "87160" },
  { label: "AUGNE (87120)", value: "87120" },
  { label: "AUREIL (87220)", value: "87220" },
  { label: "AZAT LE RIS (87360)", value: "87360" },
  { label: "BALLEDENT (87290)", value: "87290" },
  { label: "LA BAZEUGE (87210)", value: "87210" },
  { label: "BEAUMONT DU LAC (87120)", value: "87120" },
  { label: "BELLAC (87300)", value: "87300" },
  { label: "BERNEUIL (87300)", value: "87300" },
  { label: "BERSAC SUR RIVALIER (87370)", value: "87370" },
  { label: "BESSINES SUR GARTEMPE (87250)", value: "87250" },
  { label: "BESSINES SUR GARTEMPE (87250)", value: "87250" },
  { label: "BEYNAC (87700)", value: "87700" },
  { label: "LES BILLANGES (87340)", value: "87340" },
  { label: "BLANZAC (87300)", value: "87300" },
  { label: "BLOND (87300)", value: "87300" },
  { label: "BOISSEUIL (87220)", value: "87220" },
  { label: "BONNAC LA COTE (87270)", value: "87270" },
  { label: "BOSMIE L AIGUILLE (87110)", value: "87110" },
  { label: "BREUILAUFA (87300)", value: "87300" },
  { label: "LE BUIS (87140)", value: "87140" },
  { label: "BUJALEUF (87460)", value: "87460" },
  { label: "BURGNAC (87800)", value: "87800" },
  { label: "BUSSIERE GALANT (87230)", value: "87230" },
  { label: "BUSSIERE GALANT (87230)", value: "87230" },
  { label: "VAL D OIRE ET GARTEMPE (87320)", value: "87320" },
  { label: "VAL D OIRE ET GARTEMPE (87320)", value: "87320" },
  { label: "VAL D OIRE ET GARTEMPE (87320)", value: "87320" },
  { label: "VAL D OIRE ET GARTEMPE (87330)", value: "87330" },
  { label: "LES CARS (87230)", value: "87230" },
  { label: "CHAILLAC SUR VIENNE (87200)", value: "87200" },
  { label: "LE CHALARD (87500)", value: "87500" },
  { label: "CHALUS (87230)", value: "87230" },
  { label: "CHAMBORET (87140)", value: "87140" },
  { label: "CHAMPAGNAC LA RIVIERE (87150)", value: "87150" },
  { label: "CHAMPNETERY (87400)", value: "87400" },
  { label: "CHAMPSAC (87230)", value: "87230" },
  { label: "LA CHAPELLE MONTBRANDEIX (87440)", value: "87440" },
  { label: "CHAPTELAT (87270)", value: "87270" },
  { label: "CHATEAU CHERVIX (87380)", value: "87380" },
  { label: "CHATEAUNEUF LA FORET (87130)", value: "87130" },
  { label: "CHATEAUPONSAC (87290)", value: "87290" },
  { label: "LE CHATENET EN DOGNON (87400)", value: "87400" },
  { label: "CHEISSOUX (87460)", value: "87460" },
  { label: "CHERONNAC (87600)", value: "87600" },
  { label: "CIEUX (87520)", value: "87520" },
  { label: "COGNAC LA FORET (87310)", value: "87310" },
  { label: "COMPREIGNAC (87140)", value: "87140" },
  { label: "CONDAT SUR VIENNE (87920)", value: "87920" },
  { label: "COUSSAC BONNEVAL (87500)", value: "87500" },
  { label: "COUZEIX (87270)", value: "87270" },
  { label: "LA CROISILLE SUR BRIANCE (87130)", value: "87130" },
  { label: "LA CROIX SUR GARTEMPE (87210)", value: "87210" },
  { label: "CROMAC (87160)", value: "87160" },
  { label: "CUSSAC (87150)", value: "87150" },
  { label: "DINSAC (87210)", value: "87210" },
  { label: "DOMPIERRE LES EGLISES (87190)", value: "87190" },
  { label: "DOMPS (87120)", value: "87120" },
  { label: "LE DORAT (87210)", value: "87210" },
  { label: "DOURNAZAC (87230)", value: "87230" },
  { label: "DROUX (87190)", value: "87190" },
  { label: "EYBOULEUF (87400)", value: "87400" },
  { label: "EYJEAUX (87220)", value: "87220" },
  { label: "EYMOUTIERS (87120)", value: "87120" },
  { label: "FEYTIAT (87220)", value: "87220" },
  { label: "FLAVIGNAC (87230)", value: "87230" },
  { label: "FOLLES (87250)", value: "87250" },
  { label: "FROMENTAL (87250)", value: "87250" },
  { label: "GAJOUBERT (87330)", value: "87330" },
  { label: "LA GENEYTOUSE (87400)", value: "87400" },
  { label: "GLANDON (87500)", value: "87500" },
  { label: "GLANGES (87380)", value: "87380" },
  { label: "GORRE (87310)", value: "87310" },
  { label: "LES GRANDS CHEZEAUX (87160)", value: "87160" },
  { label: "ISLE (87170)", value: "87170" },
  { label: "JABREILLES LES BORDES (87370)", value: "87370" },
  { label: "JANAILHAC (87800)", value: "87800" },
  { label: "JAVERDAT (87520)", value: "87520" },
  { label: "LA JONCHERE ST MAURICE (87340)", value: "87340" },
  { label: "JOUAC (87890)", value: "87890" },
  { label: "JOURGNAC (87800)", value: "87800" },
  { label: "LADIGNAC LE LONG (87500)", value: "87500" },
  { label: "LAURIERE (87370)", value: "87370" },
  { label: "LAVIGNAC (87230)", value: "87230" },
  { label: "LIMOGES (87000)", value: "87000" },
  { label: "LIMOGES (87100)", value: "87100" },
  { label: "LIMOGES (87100)", value: "87100" },
  { label: "LIMOGES (87280)", value: "87280" },
  { label: "LIMOGES (87280)", value: "87280" },
  { label: "LINARDS (87130)", value: "87130" },
  { label: "LUSSAC LES EGLISES (87360)", value: "87360" },
  { label: "MAGNAC BOURG (87380)", value: "87380" },
  { label: "MAGNAC LAVAL (87190)", value: "87190" },
  { label: "MAILHAC SUR BENAIZE (87160)", value: "87160" },
  { label: "MAISONNAIS SUR TARDOIRE (87440)", value: "87440" },
  { label: "MARVAL (87440)", value: "87440" },
  { label: "MARVAL (87440)", value: "87440" },
  { label: "MASLEON (87130)", value: "87130" },
  { label: "MEILHAC (87800)", value: "87800" },
  { label: "MEUZAC (87380)", value: "87380" },
  { label: "LA MEYZE (87800)", value: "87800" },
  { label: "VAL D ISSOIRE (87330)", value: "87330" },
  { label: "VAL D ISSOIRE (87330)", value: "87330" },
  { label: "MOISSANNES (87400)", value: "87400" },
  { label: "MONTROL SENARD (87330)", value: "87330" },
  { label: "MORTEMART (87330)", value: "87330" },
  { label: "NANTIAT (87140)", value: "87140" },
  { label: "NEDDE (87120)", value: "87120" },
  { label: "NEUVIC ENTIER (87130)", value: "87130" },
  { label: "NEXON (87800)", value: "87800" },
  { label: "NIEUL (87510)", value: "87510" },
  { label: "NOUIC (87330)", value: "87330" },
  { label: "ORADOUR ST GENEST (87210)", value: "87210" },
  { label: "ORADOUR SUR GLANE (87520)", value: "87520" },
  { label: "ORADOUR SUR VAYRES (87150)", value: "87150" },
  { label: "PAGEAS (87230)", value: "87230" },
  { label: "LE PALAIS SUR VIENNE (87410)", value: "87410" },
  { label: "PANAZOL (87350)", value: "87350" },
  { label: "PENSOL (87440)", value: "87440" },
  { label: "PEYRAT DE BELLAC (87300)", value: "87300" },
  { label: "PEYRAT LE CHATEAU (87470)", value: "87470" },
  { label: "PEYRILHAC (87510)", value: "87510" },
  { label: "PIERRE BUFFIERE (87260)", value: "87260" },
  { label: "LA PORCHERIE (87380)", value: "87380" },
  { label: "RANCON (87290)", value: "87290" },
  { label: "RAZES (87640)", value: "87640" },
  { label: "REMPNAT (87120)", value: "87120" },
  { label: "RILHAC LASTOURS (87800)", value: "87800" },
  { label: "RILHAC RANCON (87570)", value: "87570" },
  { label: "ROCHECHOUART (87600)", value: "87600" },
  { label: "LA ROCHE L ABEILLE (87800)", value: "87800" },
  { label: "ST PARDOUX LE LAC (87140)", value: "87140" },
  { label: "ST PARDOUX LE LAC (87140)", value: "87140" },
  { label: "ST PARDOUX LE LAC (87250)", value: "87250" },
  { label: "ROYERES (87400)", value: "87400" },
  { label: "ROZIERS ST GEORGES (87130)", value: "87130" },
  { label: "SAILLAT SUR VIENNE (87720)", value: "87720" },
  { label: "ST AMAND LE PETIT (87120)", value: "87120" },
  { label: "ST AMAND MAGNAZEIX (87290)", value: "87290" },
  { label: "STE ANNE ST PRIEST (87120)", value: "87120" },
  { label: "ST AUVENT (87310)", value: "87310" },
  { label: "ST BAZILE (87150)", value: "87150" },
  { label: "ST BONNET BRIANCE (87260)", value: "87260" },
  { label: "ST BONNET DE BELLAC (87300)", value: "87300" },
  { label: "ST BRICE SUR VIENNE (87200)", value: "87200" },
  { label: "ST CYR (87310)", value: "87310" },
  { label: "ST DENIS DES MURS (87400)", value: "87400" },
  { label: "ST GENCE (87510)", value: "87510" },
  { label: "ST GENEST SUR ROSELLE (87260)", value: "87260" },
  { label: "ST GEORGES LES LANDES (87160)", value: "87160" },
  { label: "ST GERMAIN LES BELLES (87380)", value: "87380" },
  { label: "ST GILLES LES FORETS (87130)", value: "87130" },
  { label: "ST HILAIRE BONNEVAL (87260)", value: "87260" },
  { label: "ST HILAIRE LA TREILLE (87190)", value: "87190" },
  { label: "ST HILAIRE LES PLACES (87800)", value: "87800" },
  { label: "ST JEAN LIGOURE (87260)", value: "87260" },
  { label: "ST JOUVENT (87510)", value: "87510" },
  { label: "ST JULIEN LE PETIT (87460)", value: "87460" },
  { label: "ST JUNIEN (87200)", value: "87200" },
  { label: "ST JUNIEN LES COMBES (87300)", value: "87300" },
  { label: "ST JUST LE MARTEL (87590)", value: "87590" },
  { label: "ST LAURENT LES EGLISES (87240)", value: "87240" },
  { label: "ST LAURENT SUR GORRE (87310)", value: "87310" },
  { label: "ST LEGER LA MONTAGNE (87340)", value: "87340" },
  { label: "ST LEGER MAGNAZEIX (87190)", value: "87190" },
  { label: "ST LEONARD DE NOBLAT (87400)", value: "87400" },
  { label: "STE MARIE DE VAUX (87420)", value: "87420" },
  { label: "ST MARTIAL SUR ISOP (87330)", value: "87330" },
  { label: "ST MARTIN DE JUSSAC (87200)", value: "87200" },
  { label: "ST MARTIN LE MAULT (87360)", value: "87360" },
  { label: "ST MARTIN LE VIEUX (87700)", value: "87700" },
  { label: "ST MARTIN TERRESSUS (87400)", value: "87400" },
  { label: "ST MATHIEU (87440)", value: "87440" },
  { label: "ST MAURICE LES BROUSSES (87800)", value: "87800" },
  { label: "ST MEARD (87130)", value: "87130" },
  { label: "ST OUEN SUR GARTEMPE (87300)", value: "87300" },
  { label: "ST PAUL (87260)", value: "87260" },
  { label: "ST PRIEST LIGOURE (87800)", value: "87800" },
  { label: "ST PRIEST SOUS AIXE (87700)", value: "87700" },
  { label: "ST PRIEST TAURION (87480)", value: "87480" },
  { label: "ST SORNIN LA MARCHE (87210)", value: "87210" },
  { label: "ST SORNIN LEULAC (87290)", value: "87290" },
  { label: "ST SORNIN LEULAC (87290)", value: "87290" },
  { label: "ST SULPICE LAURIERE (87370)", value: "87370" },
  { label: "ST SULPICE LES FEUILLES (87160)", value: "87160" },
  { label: "ST SYLVESTRE (87240)", value: "87240" },
  { label: "ST VICTURNIEN (87420)", value: "87420" },
  { label: "ST VITTE SUR BRIANCE (87380)", value: "87380" },
  { label: "ST YRIEIX LA PERCHE (87500)", value: "87500" },
  { label: "ST YRIEIX SOUS AIXE (87700)", value: "87700" },
  { label: "LES SALLES LAVAUGUYON (87440)", value: "87440" },
  { label: "SAUVIAT SUR VIGE (87400)", value: "87400" },
  { label: "SEREILHAC (87620)", value: "87620" },
  { label: "SOLIGNAC (87110)", value: "87110" },
  { label: "SURDOUX (87130)", value: "87130" },
  { label: "SUSSAC (87130)", value: "87130" },
  { label: "TERSANNES (87360)", value: "87360" },
  { label: "THOURON (87140)", value: "87140" },
  { label: "VAULRY (87140)", value: "87140" },
  { label: "VAYRES (87600)", value: "87600" },
  { label: "VERNEUIL MOUSTIERS (87360)", value: "87360" },
  { label: "VERNEUIL SUR VIENNE (87430)", value: "87430" },
  { label: "VEYRAC (87520)", value: "87520" },
  { label: "VEYRAC (87520)", value: "87520" },
  { label: "VICQ SUR BREUILH (87260)", value: "87260" },
  { label: "VIDEIX (87600)", value: "87600" },
  { label: "LE VIGEN (87110)", value: "87110" },
  { label: "VILLEFAVARD (87190)", value: "87190" },
  { label: "LES ABLEUVENETTES (88270)", value: "88270" },
  { label: "AHEVILLE (88500)", value: "88500" },
  { label: "AINGEVILLE (88140)", value: "88140" },
  { label: "AINVELLE (88320)", value: "88320" },
  { label: "ALLARMONT (88110)", value: "88110" },
  { label: "AMBACOURT (88500)", value: "88500" },
  { label: "AMEUVELLE (88410)", value: "88410" },
  { label: "ANGLEMONT (88700)", value: "88700" },
  { label: "ANOULD (88650)", value: "88650" },
  { label: "AOUZE (88170)", value: "88170" },
  { label: "ARCHES (88380)", value: "88380" },
  { label: "ARCHETTES (88380)", value: "88380" },
  { label: "AROFFE (88170)", value: "88170" },
  { label: "ARRENTES DE CORCIEUX (88430)", value: "88430" },
  { label: "ATTIGNEVILLE (88300)", value: "88300" },
  { label: "ATTIGNY (88260)", value: "88260" },
  { label: "AULNOIS (88300)", value: "88300" },
  { label: "AUTIGNY LA TOUR (88300)", value: "88300" },
  { label: "AUTREVILLE (88300)", value: "88300" },
  { label: "AUTREY (88700)", value: "88700" },
  { label: "AUZAINVILLIERS (88140)", value: "88140" },
  { label: "AVILLERS (88500)", value: "88500" },
  { label: "AVRAINVILLE (88130)", value: "88130" },
  { label: "AVRANVILLE (88630)", value: "88630" },
  { label: "AYDOILLES (88600)", value: "88600" },
  { label: "BADMENIL AUX BOIS (88330)", value: "88330" },
  { label: "LA BAFFE (88460)", value: "88460" },
  { label: "LA VOGE LES BAINS (88240)", value: "88240" },
  { label: "LA VOGE LES BAINS (88240)", value: "88240" },
  { label: "LA VOGE LES BAINS (88240)", value: "88240" },
  { label: "LA VOGE LES BAINS (88240)", value: "88240" },
  { label: "LA VOGE LES BAINS (88240)", value: "88240" },
  { label: "BAINVILLE AUX SAULES (88270)", value: "88270" },
  { label: "BALLEVILLE (88170)", value: "88170" },
  { label: "BAN DE LAVELINE (88520)", value: "88520" },
  { label: "BAN DE SAPT (88210)", value: "88210" },
  { label: "BARBEY SEROUX (88640)", value: "88640" },
  { label: "BARVILLE (88300)", value: "88300" },
  { label: "BASSE SUR LE RUPT (88120)", value: "88120" },
  { label: "BATTEXEY (88130)", value: "88130" },
  { label: "BAUDRICOURT (88500)", value: "88500" },
  { label: "BAYECOURT (88150)", value: "88150" },
  { label: "BAZEGNEY (88270)", value: "88270" },
  { label: "BAZIEN (88700)", value: "88700" },
  { label: "BAZOILLES ET MENIL (88500)", value: "88500" },
  { label: "BAZOILLES SUR MEUSE (88300)", value: "88300" },
  { label: "BEAUFREMONT (88300)", value: "88300" },
  { label: "BEAUMENIL (88600)", value: "88600" },
  { label: "BEGNECOURT (88270)", value: "88270" },
  { label: "BELLEFONTAINE (88370)", value: "88370" },
  { label: "BELMONT LES DARNEY (88260)", value: "88260" },
  { label: "BELMONT SUR BUTTANT (88600)", value: "88600" },
  { label: "BELMONT SUR VAIR (88800)", value: "88800" },
  { label: "BELRUPT (88260)", value: "88260" },
  { label: "BELVAL (88210)", value: "88210" },
  { label: "BERTRIMOUTIER (88520)", value: "88520" },
  { label: "BETTEGNEY ST BRICE (88450)", value: "88450" },
  { label: "BETTONCOURT (88500)", value: "88500" },
  { label: "LE BEULAY (88490)", value: "88490" },
  { label: "BIECOURT (88170)", value: "88170" },
  { label: "BIFFONTAINE (88430)", value: "88430" },
  { label: "BLEMEREY (88500)", value: "88500" },
  { label: "BLEURVILLE (88410)", value: "88410" },
  { label: "BLEVAINCOURT (88320)", value: "88320" },
  { label: "BOCQUEGNEY (88270)", value: "88270" },
  { label: "BOIS DE CHAMP (88600)", value: "88600" },
  { label: "BONVILLET (88260)", value: "88260" },
  { label: "BOULAINCOURT (88500)", value: "88500" },
  { label: "LA BOURGONCE (88470)", value: "88470" },
  { label: "BOUXIERES AUX BOIS (88270)", value: "88270" },
  { label: "BOUXURULLES (88130)", value: "88130" },
  { label: "BOUZEMONT (88270)", value: "88270" },
  { label: "BRANTIGNY (88130)", value: "88130" },
  { label: "BRECHAINVILLE (88350)", value: "88350" },
  { label: "LA BRESSE (88250)", value: "88250" },
  { label: "BROUVELIEURES (88600)", value: "88600" },
  { label: "BRU (88700)", value: "88700" },
  { label: "BRUYERES (88600)", value: "88600" },
  { label: "BULGNEVILLE (88140)", value: "88140" },
  { label: "BULT (88700)", value: "88700" },
  { label: "BUSSANG (88540)", value: "88540" },
  { label: "CELLES SUR PLAINE (88110)", value: "88110" },
  { label: "CERTILLEUX (88300)", value: "88300" },
  { label: "CHAMAGNE (88130)", value: "88130" },
  { label: "CHAMPDRAY (88640)", value: "88640" },
  { label: "CHAMP LE DUC (88600)", value: "88600" },
  { label: "CHANTRAINE (88000)", value: "88000" },
  { label: "LA CHAPELLE AUX BOIS (88240)", value: "88240" },
  { label: "LA CHAPELLE AUX BOIS (88240)", value: "88240" },
  { label: "LA CHAPELLE DEVANT BRUYERES (88600)", value: "88600" },
  { label: "CHARMES (88130)", value: "88130" },
  { label: "CHARMOIS DEVANT BRUYERES (88460)", value: "88460" },
  { label: "CHARMOIS L ORGUEILLEUX (88270)", value: "88270" },
  { label: "CHATAS (88210)", value: "88210" },
  { label: "CHATEL SUR MOSELLE (88330)", value: "88330" },
  { label: "CHATENOIS (88170)", value: "88170" },
  { label: "CHATENOIS (88170)", value: "88170" },
  { label: "CHATILLON SUR SAONE (88410)", value: "88410" },
  { label: "CHAUFFECOURT (88500)", value: "88500" },
  { label: "CHAUMOUSEY (88390)", value: "88390" },
  { label: "CHAVELOT (88150)", value: "88150" },
  { label: "CHEF HAUT (88500)", value: "88500" },
  { label: "CHENIMENIL (88460)", value: "88460" },
  { label: "CHERMISEY (88630)", value: "88630" },
  { label: "CIRCOURT (88270)", value: "88270" },
  { label: "CIRCOURT SUR MOUZON (88300)", value: "88300" },
  { label: "CLAUDON (88410)", value: "88410" },
  { label: "BAN SUR MEURTHE CLEFCY (88230)", value: "88230" },
  { label: "BAN SUR MEURTHE CLEFCY (88230)", value: "88230" },
  { label: "CLEREY LA COTE (88630)", value: "88630" },
  { label: "LE CLERJUS (88240)", value: "88240" },
  { label: "CLEURIE (88120)", value: "88120" },
  { label: "CLEZENTAINE (88700)", value: "88700" },
  { label: "COINCHES (88100)", value: "88100" },
  { label: "COMBRIMONT (88490)", value: "88490" },
  { label: "CONTREXEVILLE (88140)", value: "88140" },
  { label: "CORCIEUX (88430)", value: "88430" },
  { label: "CORNIMONT (88310)", value: "88310" },
  { label: "COURCELLES SOUS CHATENOIS (88170)", value: "88170" },
  { label: "COUSSEY (88630)", value: "88630" },
  { label: "CRAINVILLIERS (88140)", value: "88140" },
  { label: "LA CROIX AUX MINES (88520)", value: "88520" },
  { label: "DAMAS AUX BOIS (88330)", value: "88330" },
  { label: "DAMAS ET BETTEGNEY (88270)", value: "88270" },
  { label: "DAMBLAIN (88320)", value: "88320" },
  { label: "DARNEY (88260)", value: "88260" },
  { label: "DARNEY AUX CHENES (88170)", value: "88170" },
  { label: "DARNIEULLES (88390)", value: "88390" },
  { label: "DEINVILLERS (88700)", value: "88700" },
  { label: "DENIPAIRE (88210)", value: "88210" },
  { label: "DERBAMONT (88270)", value: "88270" },
  { label: "DESTORD (88600)", value: "88600" },
  { label: "DEYCIMONT (88600)", value: "88600" },
  { label: "DEYVILLERS (88000)", value: "88000" },
  { label: "DIGNONVILLE (88000)", value: "88000" },
  { label: "DINOZE (88000)", value: "88000" },
  { label: "DOCELLES (88460)", value: "88460" },
  { label: "DOGNEVILLE (88000)", value: "88000" },
  { label: "DOLAINCOURT (88170)", value: "88170" },
  { label: "DOMBASLE DEVANT DARNEY (88260)", value: "88260" },
  { label: "DOMBASLE EN XAINTOIS (88500)", value: "88500" },
  { label: "DOMBROT LE SEC (88140)", value: "88140" },
  { label: "DOMBROT SUR VAIR (88170)", value: "88170" },
  { label: "DOMEVRE SUR AVIERE (88390)", value: "88390" },
  { label: "DOMEVRE SUR DURBION (88330)", value: "88330" },
  { label: "DOMEVRE SOUS MONTFORT (88500)", value: "88500" },
  { label: "DOMFAING (88600)", value: "88600" },
  { label: "DOMJULIEN (88800)", value: "88800" },
  { label: "DOMJULIEN (88800)", value: "88800" },
  { label: "DOMMARTIN AUX BOIS (88390)", value: "88390" },
  { label: "DOMMARTIN LES REMIREMONT (88200)", value: "88200" },
  { label: "DOMMARTIN LES VALLOIS (88260)", value: "88260" },
  { label: "DOMMARTIN SUR VRAINE (88170)", value: "88170" },
  { label: "DOMPAIRE (88270)", value: "88270" },
  { label: "DOMPIERRE (88600)", value: "88600" },
  { label: "DOMPTAIL (88700)", value: "88700" },
  { label: "DOMREMY LA PUCELLE (88630)", value: "88630" },
  { label: "DOMVALLIER (88500)", value: "88500" },
  { label: "DONCIERES (88700)", value: "88700" },
  { label: "DOUNOUX (88220)", value: "88220" },
  { label: "ELOYES (88510)", value: "88510" },
  { label: "ENTRE DEUX EAUX (88650)", value: "88650" },
  { label: "EPINAL (88000)", value: "88000" },
  { label: "ESCLES (88260)", value: "88260" },
  { label: "ESLEY (88260)", value: "88260" },
  { label: "ESSEGNEY (88130)", value: "88130" },
  { label: "ESTRENNES (88500)", value: "88500" },
  { label: "ETIVAL CLAIREFONTAINE (88480)", value: "88480" },
  { label: "EVAUX ET MENIL (88450)", value: "88450" },
  { label: "FAUCOMPIERRE (88460)", value: "88460" },
  { label: "FAUCONCOURT (88700)", value: "88700" },
  { label: "FAYS (88600)", value: "88600" },
  { label: "FERDRUPT (88360)", value: "88360" },
  { label: "FIGNEVELLE (88410)", value: "88410" },
  { label: "FIMENIL (88600)", value: "88600" },
  { label: "FLOREMONT (88130)", value: "88130" },
  { label: "FOMEREY (88390)", value: "88390" },
  { label: "FONTENAY (88600)", value: "88600" },
  { label: "FONTENOY LE CHATEAU (88240)", value: "88240" },
  { label: "FONTENOY LE CHATEAU (88240)", value: "88240" },
  { label: "LA FORGE (88530)", value: "88530" },
  { label: "LES FORGES (88390)", value: "88390" },
  { label: "FOUCHECOURT (88320)", value: "88320" },
  { label: "FRAIN (88320)", value: "88320" },
  { label: "FRAIZE (88230)", value: "88230" },
  { label: "FRAPELLE (88490)", value: "88490" },
  { label: "FREBECOURT (88630)", value: "88630" },
  { label: "FREMIFONTAINE (88600)", value: "88600" },
  { label: "FRENELLE LA GRANDE (88500)", value: "88500" },
  { label: "FRENELLE LA PETITE (88500)", value: "88500" },
  { label: "FRENOIS (88270)", value: "88270" },
  { label: "FRESSE SUR MOSELLE (88160)", value: "88160" },
  { label: "FREVILLE (88350)", value: "88350" },
  { label: "FRIZON (88440)", value: "88440" },
  { label: "GELVECOURT ET ADOMPT (88270)", value: "88270" },
  { label: "GELVECOURT ET ADOMPT (88270)", value: "88270" },
  { label: "GEMAINGOUTTE (88520)", value: "88520" },
  { label: "GEMMELAINCOURT (88170)", value: "88170" },
  { label: "GENDREVILLE (88140)", value: "88140" },
  { label: "GERARDMER (88400)", value: "88400" },
  { label: "GERBAMONT (88120)", value: "88120" },
  { label: "GERBEPAL (88430)", value: "88430" },
  { label: "GIGNEVILLE (88320)", value: "88320" },
  { label: "GIGNEY (88390)", value: "88390" },
  { label: "GIRANCOURT (88390)", value: "88390" },
  { label: "GIRCOURT LES VIEVILLE (88500)", value: "88500" },
  { label: "GIRECOURT SUR DURBION (88600)", value: "88600" },
  { label: "GIRMONT VAL D AJOL (88340)", value: "88340" },
  { label: "GIRONCOURT SUR VRAINE (88170)", value: "88170" },
  { label: "GODONCOURT (88410)", value: "88410" },
  { label: "GOLBEY (88190)", value: "88190" },
  { label: "GORHEY (88270)", value: "88270" },
  { label: "GRAND (88350)", value: "88350" },
  { label: "LA GRANDE FOSSE (88490)", value: "88490" },
  { label: "GRANDRUPT DE BAINS (88240)", value: "88240" },
  { label: "GRANDRUPT (88210)", value: "88210" },
  { label: "GRANDVILLERS (88600)", value: "88600" },
  { label: "GRANGES AUMONTZEY (88640)", value: "88640" },
  { label: "GRANGES AUMONTZEY (88640)", value: "88640" },
  { label: "GREUX (88630)", value: "88630" },
  { label: "GRIGNONCOURT (88410)", value: "88410" },
  { label: "GRUEY LES SURANCE (88240)", value: "88240" },
  { label: "GUGNECOURT (88600)", value: "88600" },
  { label: "GUGNEY AUX AULX (88450)", value: "88450" },
  { label: "HADIGNY LES VERRIERES (88330)", value: "88330" },
  { label: "HADOL (88220)", value: "88220" },
  { label: "HAGECOURT (88270)", value: "88270" },
  { label: "HAGNEVILLE ET RONCOURT (88300)", value: "88300" },
  { label: "HAGNEVILLE ET RONCOURT (88300)", value: "88300" },
  { label: "HAILLAINVILLE (88330)", value: "88330" },
  { label: "HARCHECHAMP (88300)", value: "88300" },
  { label: "HARDANCOURT (88700)", value: "88700" },
  { label: "HAREVILLE (88800)", value: "88800" },
  { label: "HARMONVILLE (88300)", value: "88300" },
  { label: "HAROL (88270)", value: "88270" },
  { label: "LA HAYE (88240)", value: "88240" },
  { label: "HENNECOURT (88270)", value: "88270" },
  { label: "HENNEZEL (88260)", value: "88260" },
  { label: "HERGUGNEY (88130)", value: "88130" },
  { label: "HERPELMONT (88600)", value: "88600" },
  { label: "HOUECOURT (88170)", value: "88170" },
  { label: "HOUEVILLE (88300)", value: "88300" },
  { label: "HOUSSERAS (88700)", value: "88700" },
  { label: "LA HOUSSIERE (88430)", value: "88430" },
  { label: "HURBACHE (88210)", value: "88210" },
  { label: "HYMONT (88500)", value: "88500" },
  { label: "IGNEY (88150)", value: "88150" },
  { label: "ISCHES (88320)", value: "88320" },
  { label: "JAINVILLOTTE (88300)", value: "88300" },
  { label: "JARMENIL (88550)", value: "88550" },
  { label: "JEANMENIL (88700)", value: "88700" },
  { label: "JESONVILLE (88260)", value: "88260" },
  { label: "JEUXEY (88000)", value: "88000" },
  { label: "JORXEY (88500)", value: "88500" },
  { label: "JUBAINVILLE (88630)", value: "88630" },
  { label: "JUSSARUPT (88640)", value: "88640" },
  { label: "JUVAINCOURT (88500)", value: "88500" },
  { label: "LAMARCHE (88320)", value: "88320" },
  { label: "LANDAVILLE (88300)", value: "88300" },
  { label: "LANGLEY (88130)", value: "88130" },
  { label: "LAVAL SUR VOLOGNE (88600)", value: "88600" },
  { label: "LAVELINE DEVANT BRUYERES (88600)", value: "88600" },
  { label: "LAVELINE DU HOUX (88640)", value: "88640" },
  { label: "LEGEVILLE ET BONFAYS (88270)", value: "88270" },
  { label: "LEMMECOURT (88300)", value: "88300" },
  { label: "LEPANGES SUR VOLOGNE (88600)", value: "88600" },
  { label: "LERRAIN (88260)", value: "88260" },
  { label: "LESSEUX (88490)", value: "88490" },
  { label: "LIEZEY (88400)", value: "88400" },
  { label: "LIFFOL LE GRAND (88350)", value: "88350" },
  { label: "LIGNEVILLE (88800)", value: "88800" },
  { label: "LIRONCOURT (88410)", value: "88410" },
  { label: "LONGCHAMP (88000)", value: "88000" },
  { label: "LONGCHAMP SOUS CHATENOIS (88170)", value: "88170" },
  { label: "LUBINE (88490)", value: "88490" },
  { label: "LUSSE (88490)", value: "88490" },
  { label: "LUVIGNY (88110)", value: "88110" },
  { label: "MACONCOURT (88170)", value: "88170" },
  { label: "MADECOURT (88270)", value: "88270" },
  { label: "MADEGNEY (88450)", value: "88450" },
  { label: "MADONNE ET LAMEREY (88270)", value: "88270" },
  { label: "MALAINCOURT (88140)", value: "88140" },
  { label: "MANDRAY (88650)", value: "88650" },
  { label: "MANDRES SUR VAIR (88800)", value: "88800" },
  { label: "MARAINVILLE SUR MADON (88130)", value: "88130" },
  { label: "MAREY (88320)", value: "88320" },
  { label: "MARONCOURT (88270)", value: "88270" },
  { label: "MARTIGNY LES BAINS (88320)", value: "88320" },
  { label: "MARTIGNY LES GERBONVAUX (88300)", value: "88300" },
  { label: "MARTINVELLE (88410)", value: "88410" },
  { label: "MATTAINCOURT (88500)", value: "88500" },
  { label: "MAXEY SUR MEUSE (88630)", value: "88630" },
  { label: "MAZELEY (88150)", value: "88150" },
  { label: "MAZIROT (88500)", value: "88500" },
  { label: "MEDONVILLE (88140)", value: "88140" },
  { label: "MEMENIL (88600)", value: "88600" },
  { label: "MENARMONT (88700)", value: "88700" },
  { label: "MENIL EN XAINTOIS (88500)", value: "88500" },
  { label: "MENIL DE SENONES (88210)", value: "88210" },
  { label: "MENIL SUR BELVITTE (88700)", value: "88700" },
  { label: "LE MENIL (88160)", value: "88160" },
  { label: "MIDREVAUX (88630)", value: "88630" },
  { label: "MIRECOURT (88500)", value: "88500" },
  { label: "MONCEL SUR VAIR (88630)", value: "88630" },
  { label: "LE MONT (88210)", value: "88210" },
  { label: "MONT LES LAMARCHE (88320)", value: "88320" },
  { label: "MONT LES NEUFCHATEAU (88300)", value: "88300" },
  { label: "MONTHUREUX LE SEC (88800)", value: "88800" },
  { label: "MONTHUREUX SUR SAONE (88410)", value: "88410" },
  { label: "MONTMOTIER (88240)", value: "88240" },
  { label: "MORELMAISON (88170)", value: "88170" },
  { label: "MORIVILLE (88330)", value: "88330" },
  { label: "MORIZECOURT (88320)", value: "88320" },
  { label: "MORTAGNE (88600)", value: "88600" },
  { label: "MORVILLE (88140)", value: "88140" },
  { label: "MOUSSEY (88210)", value: "88210" },
  { label: "MOYEMONT (88700)", value: "88700" },
  { label: "MOYENMOUTIER (88420)", value: "88420" },
  { label: "NAYEMONT LES FOSSES (88100)", value: "88100" },
  { label: "NEUFCHATEAU (88300)", value: "88300" },
  { label: "LA NEUVEVILLE DEVANT LEPANGES (88600)", value: "88600" },
  { label: "LA NEUVEVILLE DEVANT LEPANGES (88600)", value: "88600" },
  { label: "LA NEUVEVILLE DEVANT LEPANGES (88600)", value: "88600" },
  { label: "LA NEUVEVILLE SOUS CHATENOIS (88170)", value: "88170" },
  { label: "LA NEUVEVILLE SOUS MONTFORT (88800)", value: "88800" },
  { label: "NEUVILLERS SUR FAVE (88100)", value: "88100" },
  { label: "NOMEXY (88440)", value: "88440" },
  { label: "NOMPATELIZE (88470)", value: "88470" },
  { label: "NONVILLE (88260)", value: "88260" },
  { label: "NONZEVILLE (88600)", value: "88600" },
  { label: "NORROY (88800)", value: "88800" },
  { label: "NOSSONCOURT (88700)", value: "88700" },
  { label: "OELLEVILLE (88500)", value: "88500" },
  { label: "OFFROICOURT (88500)", value: "88500" },
  { label: "OLLAINVILLE (88170)", value: "88170" },
  { label: "ORTONCOURT (88700)", value: "88700" },
  { label: "PADOUX (88700)", value: "88700" },
  { label: "PAIR ET GRANDRUPT (88100)", value: "88100" },
  { label: "PALLEGNEY (88330)", value: "88330" },
  { label: "PAREY SOUS MONTFORT (88800)", value: "88800" },
  { label: "PARGNY SOUS MUREAU (88350)", value: "88350" },
  { label: "LA PETITE FOSSE (88490)", value: "88490" },
  { label: "LA PETITE RAON (88210)", value: "88210" },
  { label: "PIERREFITTE (88270)", value: "88270" },
  { label: "PIERREPONT SUR L ARENTELE (88600)", value: "88600" },
  { label: "PLAINFAING (88230)", value: "88230" },
  { label: "PLEUVEZAIN (88170)", value: "88170" },
  { label: "PLOMBIERES LES BAINS (88370)", value: "88370" },
  { label: "PLOMBIERES LES BAINS (88370)", value: "88370" },
  { label: "PLOMBIERES LES BAINS (88370)", value: "88370" },
  { label: "POMPIERRE (88300)", value: "88300" },
  { label: "PONT LES BONFAYS (88260)", value: "88260" },
  { label: "PONT SUR MADON (88500)", value: "88500" },
  { label: "PORTIEUX (88330)", value: "88330" },
  { label: "LES POULIERES (88600)", value: "88600" },
  { label: "POUSSAY (88500)", value: "88500" },
  { label: "POUXEUX (88550)", value: "88550" },
  { label: "PREY (88600)", value: "88600" },
  { label: "PROVENCHERES LES DARNEY (88260)", value: "88260" },
  { label: "PROVENCHERES ET COLROY (88490)", value: "88490" },
  { label: "PROVENCHERES ET COLROY (88490)", value: "88490" },
  { label: "LE PUID (88210)", value: "88210" },
  { label: "PUNEROT (88630)", value: "88630" },
  { label: "PUZIEUX (88500)", value: "88500" },
  { label: "RACECOURT (88270)", value: "88270" },
  { label: "RAINVILLE (88170)", value: "88170" },
  { label: "RAMBERVILLERS (88700)", value: "88700" },
  { label: "RAMECOURT (88500)", value: "88500" },
  { label: "RAMONCHAMP (88160)", value: "88160" },
  { label: "RANCOURT (88270)", value: "88270" },
  { label: "RAON AUX BOIS (88220)", value: "88220" },
  { label: "RAON L ETAPE (88110)", value: "88110" },
  { label: "RAON L ETAPE (88110)", value: "88110" },
  { label: "RAON SUR PLAINE (88110)", value: "88110" },
  { label: "RAPEY (88130)", value: "88130" },
  { label: "RAVES (88520)", value: "88520" },
  { label: "REBEUVILLE (88300)", value: "88300" },
  { label: "REGNEVELLE (88410)", value: "88410" },
  { label: "REGNEY (88450)", value: "88450" },
  { label: "REHAINCOURT (88330)", value: "88330" },
  { label: "REHAUPAL (88640)", value: "88640" },
  { label: "RELANGES (88260)", value: "88260" },
  { label: "REMICOURT (88500)", value: "88500" },
  { label: "REMIREMONT (88200)", value: "88200" },
  { label: "REMONCOURT (88800)", value: "88800" },
  { label: "REMOMEIX (88100)", value: "88100" },
  { label: "REMOVILLE (88170)", value: "88170" },
  { label: "RENAUVOID (88390)", value: "88390" },
  { label: "REPEL (88500)", value: "88500" },
  { label: "ROBECOURT (88320)", value: "88320" },
  { label: "ROCHESSON (88120)", value: "88120" },
  { label: "ROLLAINVILLE (88300)", value: "88300" },
  { label: "ROMAIN AUX BOIS (88320)", value: "88320" },
  { label: "ROMONT (88700)", value: "88700" },
  { label: "LES ROUGES EAUX (88600)", value: "88600" },
  { label: "LE ROULIER (88460)", value: "88460" },
  { label: "ROUVRES EN XAINTOIS (88500)", value: "88500" },
  { label: "ROUVRES LA CHETIVE (88170)", value: "88170" },
  { label: "ROVILLE AUX CHENES (88700)", value: "88700" },
  { label: "ROZEROTTE (88500)", value: "88500" },
  { label: "ROZIERES SUR MOUZON (88320)", value: "88320" },
  { label: "RUGNEY (88130)", value: "88130" },
  { label: "RUPPES (88630)", value: "88630" },
  { label: "RUPT SUR MOSELLE (88360)", value: "88360" },
  { label: "ST AME (88120)", value: "88120" },
  { label: "STE BARBE (88700)", value: "88700" },
  { label: "ST BASLEMONT (88260)", value: "88260" },
  { label: "ST BENOIT LA CHIPOTTE (88700)", value: "88700" },
  { label: "ST DIE DES VOSGES (88100)", value: "88100" },
  { label: "ST ETIENNE LES REMIREMONT (88200)", value: "88200" },
  { label: "ST GENEST (88700)", value: "88700" },
  { label: "ST GORGON (88700)", value: "88700" },
  { label: "STE HELENE (88700)", value: "88700" },
  { label: "ST JEAN D ORMONT (88210)", value: "88210" },
  { label: "ST JULIEN (88410)", value: "88410" },
  { label: "ST LEONARD (88650)", value: "88650" },
  { label: "STE MARGUERITE (88100)", value: "88100" },
  { label: "ST MAURICE SUR MORTAGNE (88700)", value: "88700" },
  { label: "ST MAURICE SUR MOSELLE (88560)", value: "88560" },
  { label: "ST MENGE (88170)", value: "88170" },
  { label: "ST MICHEL SUR MEURTHE (88470)", value: "88470" },
  { label: "ST NABORD (88200)", value: "88200" },
  { label: "ST OUEN LES PAREY (88140)", value: "88140" },
  { label: "ST PAUL (88170)", value: "88170" },
  { label: "ST PIERREMONT (88700)", value: "88700" },
  { label: "ST PRANCHER (88500)", value: "88500" },
  { label: "ST REMIMONT (88800)", value: "88800" },
  { label: "ST REMY (88480)", value: "88480" },
  { label: "ST STAIL (88210)", value: "88210" },
  { label: "ST VALLIER (88270)", value: "88270" },
  { label: "LA SALLE (88470)", value: "88470" },
  { label: "SANCHEY (88390)", value: "88390" },
  { label: "SANDAUCOURT (88170)", value: "88170" },
  { label: "SANS VALLOIS (88260)", value: "88260" },
  { label: "SAPOIS (88120)", value: "88120" },
  { label: "SARTES (88300)", value: "88300" },
  { label: "LE SAULCY (88210)", value: "88210" },
  { label: "SAULCY SUR MEURTHE (88580)", value: "88580" },
  { label: "SAULXURES LES BULGNEVILLE (88140)", value: "88140" },
  { label: "SAULXURES SUR MOSELOTTE (88290)", value: "88290" },
  { label: "SAUVILLE (88140)", value: "88140" },
  { label: "SAVIGNY (88130)", value: "88130" },
  { label: "SENAIDE (88320)", value: "88320" },
  { label: "SENONES (88210)", value: "88210" },
  { label: "SENONGES (88260)", value: "88260" },
  { label: "SERAUMONT (88630)", value: "88630" },
  { label: "SERCOEUR (88600)", value: "88600" },
  { label: "SERECOURT (88320)", value: "88320" },
  { label: "SEROCOURT (88320)", value: "88320" },
  { label: "SIONNE (88630)", value: "88630" },
  { label: "SOCOURT (88130)", value: "88130" },
  { label: "SONCOURT (88170)", value: "88170" },
  { label: "SOULOSSE SOUS ST ELOPHE (88630)", value: "88630" },
  { label: "SURIAUVILLE (88140)", value: "88140" },
  { label: "LE SYNDICAT (88120)", value: "88120" },
  { label: "LE SYNDICAT (88120)", value: "88120" },
  { label: "TAINTRUX (88100)", value: "88100" },
  { label: "TENDON (88460)", value: "88460" },
  { label: "THAON LES VOSGES (88150)", value: "88150" },
  { label: "THAON LES VOSGES (88150)", value: "88150" },
  { label: "THAON LES VOSGES (88150)", value: "88150" },
  { label: "THEY SOUS MONTFORT (88800)", value: "88800" },
  { label: "THIEFOSSE (88290)", value: "88290" },
  { label: "LE THILLOT (88160)", value: "88160" },
  { label: "THIRAUCOURT (88500)", value: "88500" },
  { label: "LE THOLY (88530)", value: "88530" },
  { label: "LE THOLY (88530)", value: "88530" },
  { label: "LES THONS (88410)", value: "88410" },
  { label: "THUILLIERES (88260)", value: "88260" },
  { label: "TIGNECOURT (88320)", value: "88320" },
  { label: "TILLEUX (88300)", value: "88300" },
  { label: "TOLLAINCOURT (88320)", value: "88320" },
  { label: "TOLLAINCOURT (88320)", value: "88320" },
  { label: "TOTAINVILLE (88500)", value: "88500" },
  { label: "TRAMPOT (88350)", value: "88350" },
  { label: "TRANQUEVILLE GRAUX (88300)", value: "88300" },
  { label: "TREMONZEY (88240)", value: "88240" },
  { label: "UBEXY (88130)", value: "88130" },
  { label: "URIMENIL (88220)", value: "88220" },
  { label: "URVILLE (88140)", value: "88140" },
  { label: "UXEGNEY (88390)", value: "88390" },
  { label: "UZEMAIN (88220)", value: "88220" },
  { label: "LA VACHERESSE ET LA ROUILLIE (88140)", value: "88140" },
  { label: "VAGNEY (88120)", value: "88120" },
  { label: "LE VAL D AJOL (88340)", value: "88340" },
  { label: "VALFROICOURT (88270)", value: "88270" },
  { label: "VALLEROY AUX SAULES (88270)", value: "88270" },
  { label: "VALLEROY LE SEC (88800)", value: "88800" },
  { label: "LES VALLOIS (88260)", value: "88260" },
  { label: "LE VALTIN (88230)", value: "88230" },
  { label: "VARMONZEY (88450)", value: "88450" },
  { label: "VAUBEXY (88500)", value: "88500" },
  { label: "VAUDEVILLE (88000)", value: "88000" },
  { label: "VAUDONCOURT (88140)", value: "88140" },
  { label: "VAXONCOURT (88330)", value: "88330" },
  { label: "VECOUX (88200)", value: "88200" },
  { label: "VELOTTE ET TATIGNECOURT (88270)", value: "88270" },
  { label: "VENTRON (88310)", value: "88310" },
  { label: "LE VERMONT (88210)", value: "88210" },
  { label: "VERVEZELLE (88600)", value: "88600" },
  { label: "VEXAINCOURT (88110)", value: "88110" },
  { label: "VICHEREY (88170)", value: "88170" },
  { label: "VIENVILLE (88430)", value: "88430" },
  { label: "VIEUX MOULIN (88210)", value: "88210" },
  { label: "VILLERS (88500)", value: "88500" },
  { label: "VILLE SUR ILLON (88270)", value: "88270" },
  { label: "VILLONCOURT (88150)", value: "88150" },
  { label: "VILLOTTE (88320)", value: "88320" },
  { label: "VILLOUXEL (88350)", value: "88350" },
  { label: "VIMENIL (88600)", value: "88600" },
  { label: "VINCEY (88450)", value: "88450" },
  { label: "VIOCOURT (88170)", value: "88170" },
  { label: "VIOMENIL (88260)", value: "88260" },
  { label: "VITTEL (88800)", value: "88800" },
  { label: "VIVIERS LE GRAS (88260)", value: "88260" },
  { label: "VIVIERS LES OFFROICOURT (88500)", value: "88500" },
  { label: "LA VOIVRE (88470)", value: "88470" },
  { label: "LES VOIVRES (88240)", value: "88240" },
  { label: "VOMECOURT (88700)", value: "88700" },
  { label: "VOMECOURT SUR MADON (88500)", value: "88500" },
  { label: "VOUXEY (88170)", value: "88170" },
  { label: "VOUXEY (88170)", value: "88170" },
  { label: "VRECOURT (88140)", value: "88140" },
  { label: "VROVILLE (88500)", value: "88500" },
  { label: "WISEMBACH (88520)", value: "88520" },
  { label: "XAFFEVILLERS (88700)", value: "88700" },
  { label: "XAMONTARUPT (88460)", value: "88460" },
  { label: "XARONVAL (88130)", value: "88130" },
  { label: "XERTIGNY (88220)", value: "88220" },
  { label: "XERTIGNY (88220)", value: "88220" },
  { label: "XONRUPT LONGEMER (88400)", value: "88400" },
  { label: "ZINCOURT (88330)", value: "88330" },
  { label: "AIGREMONT (89800)", value: "89800" },
  { label: "MONTHOLON (89110)", value: "89110" },
  { label: "MONTHOLON (89110)", value: "89110" },
  { label: "MONTHOLON (89710)", value: "89710" },
  { label: "MONTHOLON (89710)", value: "89710" },
  { label: "AISY SUR ARMANCON (89390)", value: "89390" },
  { label: "ANCY LE FRANC (89160)", value: "89160" },
  { label: "ANCY LE FRANC (89160)", value: "89160" },
  { label: "ANCY LE LIBRE (89160)", value: "89160" },
  { label: "ANDRYES (89480)", value: "89480" },
  { label: "ANGELY (89440)", value: "89440" },
  { label: "ANNAY LA COTE (89200)", value: "89200" },
  { label: "ANNAY SUR SEREIN (89310)", value: "89310" },
  { label: "ANNEOT (89200)", value: "89200" },
  { label: "ANNOUX (89440)", value: "89440" },
  { label: "APPOIGNY (89380)", value: "89380" },
  { label: "ARCES DILO (89320)", value: "89320" },
  { label: "ARCES DILO (89320)", value: "89320" },
  { label: "ARCY SUR CURE (89270)", value: "89270" },
  { label: "ARGENTENAY (89160)", value: "89160" },
  { label: "ARGENTEUIL SUR ARMANCON (89160)", value: "89160" },
  { label: "ARMEAU (89500)", value: "89500" },
  { label: "ARTHONNAY (89740)", value: "89740" },
  { label: "ASNIERES SOUS BOIS (89660)", value: "89660" },
  { label: "ASQUINS (89450)", value: "89450" },
  { label: "ATHIE (89440)", value: "89440" },
  { label: "AUGY (89290)", value: "89290" },
  { label: "AUXERRE (89000)", value: "89000" },
  { label: "AUXERRE (89290)", value: "89290" },
  { label: "AVALLON (89200)", value: "89200" },
  { label: "BAGNEAUX (89190)", value: "89190" },
  { label: "BAON (89430)", value: "89430" },
  { label: "BASSOU (89400)", value: "89400" },
  { label: "BAZARNES (89460)", value: "89460" },
  { label: "BEAUMONT (89250)", value: "89250" },
  { label: "BEAUVILLIERS (89630)", value: "89630" },
  { label: "BEAUVOIR (89240)", value: "89240" },
  { label: "BEINE (89800)", value: "89800" },
  { label: "BELLECHAUME (89210)", value: "89210" },
  { label: "LA BELLIOLE (89150)", value: "89150" },
  { label: "BEON (89410)", value: "89410" },
  { label: "BERNOUIL (89360)", value: "89360" },
  { label: "BERU (89700)", value: "89700" },
  { label: "BESSY SUR CURE (89270)", value: "89270" },
  { label: "BEUGNON (89570)", value: "89570" },
  { label: "BIERRY LES BELLES FONTAINES (89420)", value: "89420" },
  { label: "BLACY (89440)", value: "89440" },
  { label: "BLANNAY (89200)", value: "89200" },
  { label: "BLEIGNY LE CARREAU (89230)", value: "89230" },
  { label: "BLENEAU (89220)", value: "89220" },
  { label: "BOEURS EN OTHE (89770)", value: "89770" },
  { label: "BOIS D ARCY (89660)", value: "89660" },
  { label: "BONNARD (89400)", value: "89400" },
  { label: "LES BORDES (89500)", value: "89500" },
  { label: "BRANCHES (89113)", value: "89113" },
  { label: "BRANNAY (89150)", value: "89150" },
  { label: "BRIENON SUR ARMANCON (89210)", value: "89210" },
  { label: "BRIENON SUR ARMANCON (89210)", value: "89210" },
  { label: "BRION (89400)", value: "89400" },
  { label: "BROSSES (89660)", value: "89660" },
  { label: "BUSSIERES (89630)", value: "89630" },
  { label: "BUSSY EN OTHE (89400)", value: "89400" },
  { label: "BUSSY LE REPOS (89500)", value: "89500" },
  { label: "BUTTEAUX (89360)", value: "89360" },
  { label: "CARISEY (89360)", value: "89360" },
  { label: "LA CELLE ST CYR (89116)", value: "89116" },
  { label: "CENSY (89310)", value: "89310" },
  { label: "CERILLY (89320)", value: "89320" },
  { label: "CERISIERS (89320)", value: "89320" },
  { label: "CEZY (89410)", value: "89410" },
  { label: "CHABLIS (89800)", value: "89800" },
  { label: "CHABLIS (89800)", value: "89800" },
  { label: "CHABLIS (89800)", value: "89800" },
  { label: "CHABLIS (89800)", value: "89800" },
  { label: "CHAILLEY (89770)", value: "89770" },
  { label: "CHAMOUX (89660)", value: "89660" },
  { label: "CHAMPCEVRAIS (89220)", value: "89220" },
  { label: "CHAMPIGNELLES (89350)", value: "89350" },
  { label: "CHAMPIGNELLES (89350)", value: "89350" },
  { label: "CHAMPIGNY (89340)", value: "89340" },
  { label: "CHAMPLAY (89300)", value: "89300" },
  { label: "CHAMPLOST (89210)", value: "89210" },
  { label: "CHAMPS SUR YONNE (89290)", value: "89290" },
  { label: "CHAMVRES (89300)", value: "89300" },
  { label: "LA CHAPELLE SUR OREUSE (89260)", value: "89260" },
  { label: "LA CHAPELLE VAUPELTEIGNE (89800)", value: "89800" },
  { label: "CHARBUY (89113)", value: "89113" },
  { label: "CHARENTENAY (89580)", value: "89580" },
  { label: "CHARMOY (89400)", value: "89400" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHARNY OREE DE PUISAYE (89120)", value: "89120" },
  { label: "CHASSIGNELLES (89160)", value: "89160" },
  { label: "CHASSY (89110)", value: "89110" },
  { label: "CHASTELLUX SUR CURE (89630)", value: "89630" },
  { label: "CHATEL CENSOIR (89660)", value: "89660" },
  { label: "CHATEL GERARD (89310)", value: "89310" },
  { label: "CHAUMONT (89340)", value: "89340" },
  { label: "CHAUMOT (89500)", value: "89500" },
  { label: "CHEMILLY SUR SEREIN (89800)", value: "89800" },
  { label: "CHEMILLY SUR YONNE (89250)", value: "89250" },
  { label: "CHENEY (89700)", value: "89700" },
  { label: "CHENY (89400)", value: "89400" },
  { label: "CHEROY (89690)", value: "89690" },
  { label: "CHEU (89600)", value: "89600" },
  { label: "CHEVANNES (89240)", value: "89240" },
  { label: "CHICHEE (89800)", value: "89800" },
  { label: "CHICHERY (89400)", value: "89400" },
  { label: "CHITRY (89530)", value: "89530" },
  { label: "LES CLERIMOIS (89190)", value: "89190" },
  { label: "COLLAN (89700)", value: "89700" },
  { label: "COLLEMIERS (89100)", value: "89100" },
  { label: "COMPIGNY (89140)", value: "89140" },
  { label: "CORNANT (89500)", value: "89500" },
  { label: "COULANGERON (89580)", value: "89580" },
  { label: "COULANGES LA VINEUSE (89580)", value: "89580" },
  { label: "COULANGES SUR YONNE (89480)", value: "89480" },
  { label: "COULOURS (89320)", value: "89320" },
  { label: "COURGENAY (89190)", value: "89190" },
  { label: "COURGIS (89800)", value: "89800" },
  { label: "COURLON SUR YONNE (89140)", value: "89140" },
  { label: "COURSON LES CARRIERES (89560)", value: "89560" },
  { label: "COURTOIN (89150)", value: "89150" },
  { label: "COURTOIS SUR YONNE (89100)", value: "89100" },
  { label: "COUTARNOUX (89440)", value: "89440" },
  { label: "CRAIN (89480)", value: "89480" },
  { label: "DEUX RIVIERES (89460)", value: "89460" },
  { label: "DEUX RIVIERES (89460)", value: "89460" },
  { label: "CRUZY LE CHATEL (89740)", value: "89740" },
  { label: "CRY (89390)", value: "89390" },
  { label: "CUDOT (89116)", value: "89116" },
  { label: "CUSSY LES FORGES (89420)", value: "89420" },
  { label: "CUY (89140)", value: "89140" },
  { label: "DANNEMOINE (89700)", value: "89700" },
  { label: "DIGES (89240)", value: "89240" },
  { label: "DISSANGIS (89440)", value: "89440" },
  { label: "DIXMONT (89500)", value: "89500" },
  { label: "DOLLOT (89150)", value: "89150" },
  { label: "DOMATS (89150)", value: "89150" },
  { label: "DOMECY SUR CURE (89450)", value: "89450" },
  { label: "DOMECY SUR LE VAULT (89200)", value: "89200" },
  { label: "DRACY (89130)", value: "89130" },
  { label: "DRUYES LES BELLES FONTAINES (89560)", value: "89560" },
  { label: "DYE (89360)", value: "89360" },
  { label: "EGLENY (89240)", value: "89240" },
  { label: "EGRISELLES LE BOCAGE (89500)", value: "89500" },
  { label: "EPINEAU LES VOVES (89400)", value: "89400" },
  { label: "EPINEUIL (89700)", value: "89700" },
  { label: "ESCAMPS (89240)", value: "89240" },
  { label: "ESCOLIVES STE CAMILLE (89290)", value: "89290" },
  { label: "ESNON (89210)", value: "89210" },
  { label: "ETAIS LA SAUVIN (89480)", value: "89480" },
  { label: "ETAULE (89200)", value: "89200" },
  { label: "ETIGNY (89510)", value: "89510" },
  { label: "ETIVEY (89310)", value: "89310" },
  { label: "EVRY (89140)", value: "89140" },
  { label: "LA FERTE LOUPIERE (89110)", value: "89110" },
  { label: "FESTIGNY (89480)", value: "89480" },
  { label: "FLACY (89190)", value: "89190" },
  { label: "FLEURY LA VALLEE (89113)", value: "89113" },
  { label: "FLEYS (89800)", value: "89800" },
  { label: "FLOGNY LA CHAPELLE (89360)", value: "89360" },
  { label: "FOISSY LES VEZELAY (89450)", value: "89450" },
  { label: "FOISSY SUR VANNE (89190)", value: "89190" },
  { label: "FONTAINE LA GAILLARDE (89100)", value: "89100" },
  { label: "FONTAINES (89130)", value: "89130" },
  { label: "FONTENAY PRES CHABLIS (89800)", value: "89800" },
  { label: "FONTENAY PRES VEZELAY (89450)", value: "89450" },
  { label: "FONTENAY SOUS FOURONNES (89660)", value: "89660" },
  { label: "FONTENOY (89520)", value: "89520" },
  { label: "FOUCHERES (89150)", value: "89150" },
  { label: "FOURNAUDIN (89320)", value: "89320" },
  { label: "FOURONNES (89560)", value: "89560" },
  { label: "FRESNES (89310)", value: "89310" },
  { label: "FULVY (89160)", value: "89160" },
  { label: "GERMIGNY (89600)", value: "89600" },
  { label: "GIGNY (89160)", value: "89160" },
  { label: "GIROLLES (89200)", value: "89200" },
  { label: "GISY LES NOBLES (89140)", value: "89140" },
  { label: "GIVRY (89200)", value: "89200" },
  { label: "GLAND (89740)", value: "89740" },
  { label: "GRIMAULT (89310)", value: "89310" },
  { label: "GRON (89100)", value: "89100" },
  { label: "VALRAVILLON (89110)", value: "89110" },
  { label: "VALRAVILLON (89113)", value: "89113" },
  { label: "VALRAVILLON (89113)", value: "89113" },
  { label: "VALRAVILLON (89113)", value: "89113" },
  { label: "GUILLON TERRE PLAINE (89420)", value: "89420" },
  { label: "GUILLON TERRE PLAINE (89420)", value: "89420" },
  { label: "GUILLON TERRE PLAINE (89420)", value: "89420" },
  { label: "GUILLON TERRE PLAINE (89420)", value: "89420" },
  { label: "GUILLON TERRE PLAINE (89420)", value: "89420" },
  { label: "GURGY (89250)", value: "89250" },
  { label: "GY L EVEQUE (89580)", value: "89580" },
  { label: "HAUTERIVE (89250)", value: "89250" },
  { label: "HERY (89550)", value: "89550" },
  { label: "IRANCY (89290)", value: "89290" },
  { label: "ISLAND (89200)", value: "89200" },
  { label: "L'ISLE SUR SEREIN (89440)", value: "89440" },
  { label: "JAULGES (89360)", value: "89360" },
  { label: "JOIGNY (89300)", value: "89300" },
  { label: "JOUANCY (89310)", value: "89310" },
  { label: "JOUX LA VILLE (89440)", value: "89440" },
  { label: "JOUY (89150)", value: "89150" },
  { label: "JULLY (89160)", value: "89160" },
  { label: "JUNAY (89700)", value: "89700" },
  { label: "JUSSY (89290)", value: "89290" },
  { label: "LAILLY (89190)", value: "89190" },
  { label: "LAIN (89560)", value: "89560" },
  { label: "LAINSECQ (89520)", value: "89520" },
  { label: "LALANDE (89130)", value: "89130" },
  { label: "LAROCHE ST CYDROINE (89400)", value: "89400" },
  { label: "LASSON (89570)", value: "89570" },
  { label: "LAVAU (89170)", value: "89170" },
  { label: "LEUGNY (89130)", value: "89130" },
  { label: "LEVIS (89520)", value: "89520" },
  { label: "LEZINNES (89160)", value: "89160" },
  { label: "LICHERES PRES AIGREMONT (89800)", value: "89800" },
  { label: "LICHERES SUR YONNE (89660)", value: "89660" },
  { label: "LIGNORELLES (89800)", value: "89800" },
  { label: "LIGNY LE CHATEL (89144)", value: "89144" },
  { label: "LINDRY (89240)", value: "89240" },
  { label: "LIXY (89140)", value: "89140" },
  { label: "LOOZE (89300)", value: "89300" },
  { label: "LUCY LE BOIS (89200)", value: "89200" },
  { label: "LUCY SUR CURE (89270)", value: "89270" },
  { label: "LUCY SUR CURE (89270)", value: "89270" },
  { label: "LUCY SUR YONNE (89480)", value: "89480" },
  { label: "MAGNY (89200)", value: "89200" },
  { label: "MAILLOT (89100)", value: "89100" },
  { label: "MAILLY LA VILLE (89270)", value: "89270" },
  { label: "MAILLY LE CHATEAU (89660)", value: "89660" },
  { label: "MALAY LE GRAND (89100)", value: "89100" },
  { label: "MALAY LE PETIT (89100)", value: "89100" },
  { label: "MALIGNY (89800)", value: "89800" },
  { label: "MARMEAUX (89420)", value: "89420" },
  { label: "MARSANGY (89500)", value: "89500" },
  { label: "MASSANGIS (89440)", value: "89440" },
  { label: "MASSANGIS (89440)", value: "89440" },
  { label: "MELISEY (89430)", value: "89430" },
  { label: "MENADES (89450)", value: "89450" },
  { label: "MERCY (89210)", value: "89210" },
  { label: "MERE (89144)", value: "89144" },
  { label: "MERRY LA VALLEE (89110)", value: "89110" },
  { label: "MERRY SEC (89560)", value: "89560" },
  { label: "MERRY SUR YONNE (89660)", value: "89660" },
  { label: "MEZILLES (89130)", value: "89130" },
  { label: "MICHERY (89140)", value: "89140" },
  { label: "MIGE (89580)", value: "89580" },
  { label: "MIGENNES (89400)", value: "89400" },
  { label: "MOLAY (89310)", value: "89310" },
  { label: "MOLINONS (89190)", value: "89190" },
  { label: "MOLOSMES (89700)", value: "89700" },
  { label: "MONETEAU (89470)", value: "89470" },
  { label: "MONETEAU (89470)", value: "89470" },
  { label: "MONTACHER VILLEGARDIN (89150)", value: "89150" },
  { label: "MONTIGNY LA RESLE (89230)", value: "89230" },
  { label: "MONTILLOT (89660)", value: "89660" },
  { label: "MONTREAL (89420)", value: "89420" },
  { label: "MONT ST SULPICE (89250)", value: "89250" },
  { label: "MOUFFY (89560)", value: "89560" },
  { label: "MOULINS EN TONNERROIS (89310)", value: "89310" },
  { label: "MOULINS SUR OUANNE (89130)", value: "89130" },
  { label: "MOUTIERS EN PUISAYE (89520)", value: "89520" },
  { label: "NAILLY (89100)", value: "89100" },
  { label: "NEUVY SAUTOUR (89570)", value: "89570" },
  { label: "NITRY (89310)", value: "89310" },
  { label: "NOE (89320)", value: "89320" },
  { label: "NOYERS (89310)", value: "89310" },
  { label: "NUITS (89390)", value: "89390" },
  { label: "LES ORMES (89110)", value: "89110" },
  { label: "ORMOY (89400)", value: "89400" },
  { label: "OUANNE (89560)", value: "89560" },
  { label: "OUANNE (89560)", value: "89560" },
  { label: "PACY SUR ARMANCON (89160)", value: "89160" },
  { label: "PAILLY (89140)", value: "89140" },
  { label: "PARLY (89240)", value: "89240" },
  { label: "PARON (89100)", value: "89100" },
  { label: "PAROY EN OTHE (89210)", value: "89210" },
  { label: "PAROY SUR THOLON (89300)", value: "89300" },
  { label: "PASILLY (89310)", value: "89310" },
  { label: "PASSY (89510)", value: "89510" },
  { label: "PERCEY (89360)", value: "89360" },
  { label: "PERRIGNY (89000)", value: "89000" },
  { label: "PERRIGNY SUR ARMANCON (89390)", value: "89390" },
  { label: "PIERRE PERTHUIS (89450)", value: "89450" },
  { label: "PIFFONDS (89330)", value: "89330" },
  { label: "PIMELLES (89740)", value: "89740" },
  { label: "PISY (89420)", value: "89420" },
  { label: "PLESSIS ST JEAN (89140)", value: "89140" },
  { label: "POILLY SUR SEREIN (89310)", value: "89310" },
  { label: "POILLY SUR THOLON (89110)", value: "89110" },
  { label: "PONTAUBERT (89200)", value: "89200" },
  { label: "PONTIGNY (89230)", value: "89230" },
  { label: "PONT SUR VANNE (89190)", value: "89190" },
  { label: "PONT SUR YONNE (89140)", value: "89140" },
  { label: "LA POSTOLLE (89260)", value: "89260" },
  { label: "POURRAIN (89240)", value: "89240" },
  { label: "PRECY LE SEC (89440)", value: "89440" },
  { label: "PRECY SUR VRIN (89116)", value: "89116" },
  { label: "PREGILBERT (89460)", value: "89460" },
  { label: "PREHY (89800)", value: "89800" },
  { label: "PROVENCY (89200)", value: "89200" },
  { label: "QUARRE LES TOMBES (89630)", value: "89630" },
  { label: "QUENNE (89290)", value: "89290" },
  { label: "QUINCEROT (89740)", value: "89740" },
  { label: "RAVIERES (89390)", value: "89390" },
  { label: "ROFFEY (89700)", value: "89700" },
  { label: "ROGNY LES SEPT ECLUSES (89220)", value: "89220" },
  { label: "RONCHERES (89170)", value: "89170" },
  { label: "ROSOY (89100)", value: "89100" },
  { label: "ROUSSON (89500)", value: "89500" },
  { label: "ROUVRAY (89230)", value: "89230" },
  { label: "RUGNY (89430)", value: "89430" },
  { label: "SAINPUITS (89520)", value: "89520" },
  { label: "ST AGNAN (89340)", value: "89340" },
  { label: "ST ANDRE EN TERRE PLAINE (89420)", value: "89420" },
  { label: "LE VAL D OCRE (89110)", value: "89110" },
  { label: "LE VAL D OCRE (89110)", value: "89110" },
  { label: "ST AUBIN SUR YONNE (89300)", value: "89300" },
  { label: "ST BRANCHER (89630)", value: "89630" },
  { label: "ST BRIS LE VINEUX (89530)", value: "89530" },
  { label: "ST CLEMENT (89100)", value: "89100" },
  { label: "STE COLOMBE (89440)", value: "89440" },
  { label: "ST CYR LES COLONS (89800)", value: "89800" },
  { label: "ST DENIS LES SENS (89100)", value: "89100" },
  { label: "ST FARGEAU (89170)", value: "89170" },
  { label: "ST FARGEAU (89170)", value: "89170" },
  { label: "ST FLORENTIN (89600)", value: "89600" },
  { label: "ST FLORENTIN (89600)", value: "89600" },
  { label: "ST GEORGES SUR BAULCHE (89000)", value: "89000" },
  { label: "ST GERMAIN DES CHAMPS (89630)", value: "89630" },
  { label: "ST JULIEN DU SAULT (89330)", value: "89330" },
  { label: "ST LEGER VAUBAN (89630)", value: "89630" },
  { label: "ST LOUP D ORDON (89330)", value: "89330" },
  { label: "STE MAGNANCE (89420)", value: "89420" },
  { label: "ST MARTIN DES CHAMPS (89170)", value: "89170" },
  { label: "ST MARTIN D ORDON (89330)", value: "89330" },
  { label: "ST MARTIN DU TERTRE (89100)", value: "89100" },
  { label: "ST MARTIN SUR ARMANCON (89700)", value: "89700" },
  { label: "ST MAURICE AUX RICHES HOMMES (89190)", value: "89190" },
  { label: "ST MAURICE LE VIEIL (89110)", value: "89110" },
  { label: "ST MAURICE THIZOUAILLE (89110)", value: "89110" },
  { label: "ST MORE (89270)", value: "89270" },
  { label: "STE PALLAYE (89460)", value: "89460" },
  { label: "ST PERE (89450)", value: "89450" },
  { label: "ST PRIVE (89220)", value: "89220" },
  { label: "SAINTS EN PUISAYE (89520)", value: "89520" },
  { label: "ST SAUVEUR EN PUISAYE (89520)", value: "89520" },
  { label: "ST SEROTIN (89140)", value: "89140" },
  { label: "ST VALERIEN (89150)", value: "89150" },
  { label: "STE VERTU (89310)", value: "89310" },
  { label: "SALIGNY (89100)", value: "89100" },
  { label: "SAMBOURG (89160)", value: "89160" },
  { label: "SANTIGNY (89420)", value: "89420" },
  { label: "SARRY (89310)", value: "89310" },
  { label: "SAUVIGNY LE BEUREAL (89420)", value: "89420" },
  { label: "SAUVIGNY LE BOIS (89200)", value: "89200" },
  { label: "SAVIGNY EN TERRE PLAINE (89420)", value: "89420" },
  { label: "SAVIGNY SUR CLAIRIS (89150)", value: "89150" },
  { label: "SEIGNELAY (89250)", value: "89250" },
  { label: "SEMENTRON (89560)", value: "89560" },
  { label: "SENAN (89710)", value: "89710" },
  { label: "SENNEVOY LE BAS (89160)", value: "89160" },
  { label: "SENNEVOY LE HAUT (89160)", value: "89160" },
  { label: "SENS (89100)", value: "89100" },
  { label: "SEPEAUX ST ROMAIN (89116)", value: "89116" },
  { label: "SEPEAUX ST ROMAIN (89116)", value: "89116" },
  { label: "SERBONNES (89140)", value: "89140" },
  { label: "SERGINES (89140)", value: "89140" },
  { label: "SERMIZELLES (89200)", value: "89200" },
  { label: "SERRIGNY (89700)", value: "89700" },
  { label: "SERY (89270)", value: "89270" },
  { label: "LES SIEGES (89190)", value: "89190" },
  { label: "SOMMECAISE (89110)", value: "89110" },
  { label: "SORMERY (89570)", value: "89570" },
  { label: "SOUCY (89100)", value: "89100" },
  { label: "SOUGERES EN PUISAYE (89520)", value: "89520" },
  { label: "SOUMAINTRAIN (89570)", value: "89570" },
  { label: "STIGNY (89160)", value: "89160" },
  { label: "SUBLIGNY (89100)", value: "89100" },
  { label: "LES HAUTS DE FORTERRE (89560)", value: "89560" },
  { label: "LES HAUTS DE FORTERRE (89560)", value: "89560" },
  { label: "LES HAUTS DE FORTERRE (89560)", value: "89560" },
  { label: "TALCY (89420)", value: "89420" },
  { label: "TANLAY (89430)", value: "89430" },
  { label: "TANLAY (89430)", value: "89430" },
  { label: "TANLAY (89430)", value: "89430" },
  { label: "TANNERRE EN PUISAYE (89350)", value: "89350" },
  { label: "THAROISEAU (89450)", value: "89450" },
  { label: "THAROT (89200)", value: "89200" },
  { label: "LES VALLEES DE LA VANNE (89190)", value: "89190" },
  { label: "LES VALLEES DE LA VANNE (89320)", value: "89320" },
  { label: "LES VALLEES DE LA VANNE (89320)", value: "89320" },
  { label: "THIZY (89420)", value: "89420" },
  { label: "THOREY (89430)", value: "89430" },
  { label: "THORIGNY SUR OREUSE (89260)", value: "89260" },
  { label: "THORIGNY SUR OREUSE (89260)", value: "89260" },
  { label: "THORIGNY SUR OREUSE (89260)", value: "89260" },
  { label: "THORY (89200)", value: "89200" },
  { label: "THURY (89520)", value: "89520" },
  { label: "TISSEY (89700)", value: "89700" },
  { label: "TONNERRE (89700)", value: "89700" },
  { label: "TOUCY (89130)", value: "89130" },
  { label: "TREIGNY PERREUSE STE COLOMBE (89520)", value: "89520" },
  { label: "TREIGNY PERREUSE STE COLOMBE (89520)", value: "89520" },
  { label: "TREIGNY PERREUSE STE COLOMBE (89520)", value: "89520" },
  { label: "TRICHEY (89430)", value: "89430" },
  { label: "TRONCHOY (89700)", value: "89700" },
  { label: "TRUCY SUR YONNE (89460)", value: "89460" },
  { label: "TURNY (89570)", value: "89570" },
  { label: "VAL DE MERCY (89580)", value: "89580" },
  { label: "VALLAN (89580)", value: "89580" },
  { label: "VALLERY (89150)", value: "89150" },
  { label: "VARENNES (89144)", value: "89144" },
  { label: "VASSY SOUS PISY (89420)", value: "89420" },
  { label: "VAUDEURS (89320)", value: "89320" },
  { label: "VAULT DE LUGNY (89200)", value: "89200" },
  { label: "VAUMORT (89320)", value: "89320" },
  { label: "VENIZY (89210)", value: "89210" },
  { label: "VENOUSE (89230)", value: "89230" },
  { label: "VENOY (89290)", value: "89290" },
  { label: "VERGIGNY (89600)", value: "89600" },
  { label: "VERGIGNY (89600)", value: "89600" },
  { label: "VERGIGNY (89600)", value: "89600" },
  { label: "VERLIN (89330)", value: "89330" },
  { label: "VERMENTON (89270)", value: "89270" },
  { label: "VERMENTON (89270)", value: "89270" },
  { label: "VERNOY (89150)", value: "89150" },
  { label: "VERON (89510)", value: "89510" },
  { label: "VEZANNES (89700)", value: "89700" },
  { label: "VEZELAY (89450)", value: "89450" },
  { label: "VEZINNES (89700)", value: "89700" },
  { label: "VILLEBLEVIN (89340)", value: "89340" },
  { label: "VILLEBOUGIS (89150)", value: "89150" },
  { label: "VILLECHETIVE (89320)", value: "89320" },
  { label: "VILLECIEN (89300)", value: "89300" },
  { label: "VILLEFARGEAU (89240)", value: "89240" },
  { label: "VILLEMANOCHE (89140)", value: "89140" },
  { label: "VILLENAVOTTE (89140)", value: "89140" },
  { label: "VILLENEUVE LA DONDAGRE (89150)", value: "89150" },
  { label: "VILLENEUVE LA GUYARD (89340)", value: "89340" },
  { label: "VILLENEUVE L ARCHEVEQUE (89190)", value: "89190" },
  { label: "VILLENEUVE LES GENETS (89350)", value: "89350" },
  { label: "VILLENEUVE ST SALVES (89230)", value: "89230" },
  { label: "VILLENEUVE SUR YONNE (89500)", value: "89500" },
  { label: "VILLEPERROT (89140)", value: "89140" },
  { label: "VILLEROY (89100)", value: "89100" },
  { label: "VILLETHIERRY (89140)", value: "89140" },
  { label: "VILLEVALLIER (89330)", value: "89330" },
  { label: "PERCENEIGE (89260)", value: "89260" },
  { label: "PERCENEIGE (89260)", value: "89260" },
  { label: "PERCENEIGE (89260)", value: "89260" },
  { label: "PERCENEIGE (89260)", value: "89260" },
  { label: "PERCENEIGE (89260)", value: "89260" },
  { label: "PERCENEIGE (89260)", value: "89260" },
  { label: "VILLIERS LES HAUTS (89160)", value: "89160" },
  { label: "VILLIERS LOUIS (89320)", value: "89320" },
  { label: "VILLIERS ST BENOIT (89130)", value: "89130" },
  { label: "VILLIERS ST BENOIT (89130)", value: "89130" },
  { label: "VILLIERS VINEUX (89360)", value: "89360" },
  { label: "VILLON (89740)", value: "89740" },
  { label: "VILLY (89800)", value: "89800" },
  { label: "VINCELLES (89290)", value: "89290" },
  { label: "VINCELOTTES (89290)", value: "89290" },
  { label: "VINNEUF (89140)", value: "89140" },
  { label: "VIREAUX (89160)", value: "89160" },
  { label: "VIVIERS (89700)", value: "89700" },
  { label: "VOISINES (89260)", value: "89260" },
  { label: "VOUTENAY SUR CURE (89270)", value: "89270" },
  { label: "YROUERRE (89700)", value: "89700" },
  { label: "ANDELNANS (90400)", value: "90400" },
  { label: "ANDELNANS (90400)", value: "90400" },
  { label: "ANGEOT (90150)", value: "90150" },
  { label: "ANJOUTEY (90170)", value: "90170" },
  { label: "ARGIESANS (90800)", value: "90800" },
  { label: "AUXELLES BAS (90200)", value: "90200" },
  { label: "AUXELLES HAUT (90200)", value: "90200" },
  { label: "BANVILLARS (90800)", value: "90800" },
  { label: "BAVILLIERS (90800)", value: "90800" },
  { label: "BEAUCOURT (90500)", value: "90500" },
  { label: "BELFORT (90000)", value: "90000" },
  { label: "BERMONT (90400)", value: "90400" },
  { label: "BESSONCOURT (90160)", value: "90160" },
  { label: "BETHONVILLIERS (90150)", value: "90150" },
  { label: "BORON (90100)", value: "90100" },
  { label: "BOTANS (90400)", value: "90400" },
  { label: "BOURG SOUS CHATELET (90110)", value: "90110" },
  { label: "BOUROGNE (90140)", value: "90140" },
  { label: "BREBOTTE (90140)", value: "90140" },
  { label: "BRETAGNE (90130)", value: "90130" },
  { label: "BUC (90800)", value: "90800" },
  { label: "CHARMOIS (90140)", value: "90140" },
  { label: "CHATENOIS LES FORGES (90700)", value: "90700" },
  { label: "CHAUX (90330)", value: "90330" },
  { label: "CHAVANATTE (90100)", value: "90100" },
  { label: "CHAVANNES LES GRANDS (90100)", value: "90100" },
  { label: "CHEVREMONT (90340)", value: "90340" },
  { label: "COURCELLES (90100)", value: "90100" },
  { label: "COURTELEVANT (90100)", value: "90100" },
  { label: "CRAVANCHE (90300)", value: "90300" },
  { label: "CROIX (90100)", value: "90100" },
  { label: "CUNELIERES (90150)", value: "90150" },
  { label: "DANJOUTIN (90400)", value: "90400" },
  { label: "DELLE (90100)", value: "90100" },
  { label: "DENNEY (90160)", value: "90160" },
  { label: "DORANS (90400)", value: "90400" },
  { label: "EGUENIGUE (90150)", value: "90150" },
  { label: "ELOIE (90300)", value: "90300" },
  { label: "ESSERT (90850)", value: "90850" },
  { label: "ETUEFFONT (90170)", value: "90170" },
  { label: "EVETTE SALBERT (90350)", value: "90350" },
  { label: "FAVEROIS (90100)", value: "90100" },
  { label: "FELON (90110)", value: "90110" },
  { label: "FECHE L EGLISE (90100)", value: "90100" },
  { label: "FLORIMONT (90100)", value: "90100" },
  { label: "FONTAINE (90150)", value: "90150" },
  { label: "FONTENELLE (90340)", value: "90340" },
  { label: "FOUSSEMAGNE (90150)", value: "90150" },
  { label: "FRAIS (90150)", value: "90150" },
  { label: "FROIDEFONTAINE (90140)", value: "90140" },
  { label: "GIROMAGNY (90200)", value: "90200" },
  { label: "GRANDVILLARS (90600)", value: "90600" },
  { label: "GROSMAGNY (90200)", value: "90200" },
  { label: "GROSNE (90100)", value: "90100" },
  { label: "JONCHEREY (90100)", value: "90100" },
  { label: "LACHAPELLE SOUS CHAUX (90300)", value: "90300" },
  { label: "LACHAPELLE SOUS ROUGEMONT (90360)", value: "90360" },
  { label: "LACOLLONGE (90150)", value: "90150" },
  { label: "LAGRANGE (90150)", value: "90150" },
  { label: "LAMADELEINE VAL DES ANGES (90170)", value: "90170" },
  { label: "LARIVIERE (90150)", value: "90150" },
  { label: "LEBETAIN (90100)", value: "90100" },
  { label: "LEPUIX NEUF (90100)", value: "90100" },
  { label: "LEPUIX (90200)", value: "90200" },
  { label: "LEVAL (90110)", value: "90110" },
  { label: "MENONCOURT (90150)", value: "90150" },
  { label: "MEROUX MOVAL (90400)", value: "90400" },
  { label: "MEROUX MOVAL (90400)", value: "90400" },
  { label: "MEZIRE (90120)", value: "90120" },
  { label: "MONTBOUTON (90500)", value: "90500" },
  { label: "MONTREUX CHATEAU (90130)", value: "90130" },
  { label: "MORVILLARS (90120)", value: "90120" },
  { label: "NOVILLARD (90340)", value: "90340" },
  { label: "OFFEMONT (90300)", value: "90300" },
  { label: "PEROUSE (90160)", value: "90160" },
  { label: "PETIT CROIX (90130)", value: "90130" },
  { label: "PETITEFONTAINE (90360)", value: "90360" },
  { label: "PETITMAGNY (90170)", value: "90170" },
  { label: "PHAFFANS (90150)", value: "90150" },
  { label: "RECHESY (90370)", value: "90370" },
  { label: "AUTRECHENE (90140)", value: "90140" },
  { label: "RECOUVRANCE (90140)", value: "90140" },
  { label: "REPPE (90150)", value: "90150" },
  { label: "RIERVESCEMONT (90200)", value: "90200" },
  { label: "ROMAGNY SOUS ROUGEMONT (90110)", value: "90110" },
  { label: "ROPPE (90380)", value: "90380" },
  { label: "ROUGEGOUTTE (90200)", value: "90200" },
  { label: "ROUGEMONT LE CHATEAU (90110)", value: "90110" },
  { label: "ST DIZIER L EVEQUE (90100)", value: "90100" },
  { label: "ST GERMAIN LE CHATELET (90110)", value: "90110" },
  { label: "SERMAMAGNY (90300)", value: "90300" },
  { label: "SEVENANS (90400)", value: "90400" },
  { label: "SUARCE (90100)", value: "90100" },
  { label: "THIANCOURT (90100)", value: "90100" },
  { label: "TREVENANS (90400)", value: "90400" },
  { label: "URCEREY (90800)", value: "90800" },
  { label: "VALDOIE (90300)", value: "90300" },
  { label: "VAUTHIERMONT (90150)", value: "90150" },
  { label: "VELLESCOT (90100)", value: "90100" },
  { label: "VESCEMONT (90200)", value: "90200" },
  { label: "VETRIGNE (90300)", value: "90300" },
  { label: "VEZELOIS (90400)", value: "90400" },
  { label: "VILLARS LE SEC (90100)", value: "90100" },
  { label: "ABBEVILLE LA RIVIERE (91150)", value: "91150" },
  { label: "ANGERVILLE (91670)", value: "91670" },
  { label: "ANGERVILLE (91670)", value: "91670" },
  { label: "ANGERVILLIERS (91470)", value: "91470" },
  { label: "ARPAJON (91290)", value: "91290" },
  { label: "ARRANCOURT (91690)", value: "91690" },
  { label: "ATHIS MONS (91200)", value: "91200" },
  { label: "AUTHON LA PLAINE (91410)", value: "91410" },
  { label: "AUVERNAUX (91830)", value: "91830" },
  { label: "AUVERS ST GEORGES (91580)", value: "91580" },
  { label: "AVRAINVILLE (91630)", value: "91630" },
  { label: "BALLAINVILLIERS (91160)", value: "91160" },
  { label: "BALLANCOURT SUR ESSONNE (91610)", value: "91610" },
  { label: "BAULNE (91590)", value: "91590" },
  { label: "BIEVRES (91570)", value: "91570" },
  { label: "BLANDY (91150)", value: "91150" },
  { label: "BOIGNEVILLE (91720)", value: "91720" },
  { label: "BOIS HERPIN (91150)", value: "91150" },
  { label: "BOISSY LA RIVIERE (91690)", value: "91690" },
  { label: "BOISSY LE CUTTE (91590)", value: "91590" },
  { label: "BOISSY LE SEC (91870)", value: "91870" },
  { label: "BOISSY SOUS ST YON (91790)", value: "91790" },
  { label: "BONDOUFLE (91070)", value: "91070" },
  { label: "BOULLAY LES TROUX (91470)", value: "91470" },
  { label: "BOURAY SUR JUINE (91850)", value: "91850" },
  { label: "BOUSSY ST ANTOINE (91800)", value: "91800" },
  { label: "BOUTERVILLIERS (91150)", value: "91150" },
  { label: "BOUTIGNY SUR ESSONNE (91820)", value: "91820" },
  { label: "BOUVILLE (91880)", value: "91880" },
  { label: "BRETIGNY SUR ORGE (91220)", value: "91220" },
  { label: "BREUILLET (91650)", value: "91650" },
  { label: "BREUX JOUY (91650)", value: "91650" },
  { label: "BRIERES LES SCELLES (91150)", value: "91150" },
  { label: "BRIIS SOUS FORGES (91640)", value: "91640" },
  { label: "BROUY (91150)", value: "91150" },
  { label: "BRUNOY (91800)", value: "91800" },
  { label: "BRUYERES LE CHATEL (91680)", value: "91680" },
  { label: "BUNO BONNEVAUX (91720)", value: "91720" },
  { label: "BURES SUR YVETTE (91440)", value: "91440" },
  { label: "BURES SUR YVETTE (91440)", value: "91440" },
  { label: "CERNY (91590)", value: "91590" },
  { label: "CHALO ST MARS (91780)", value: "91780" },
  { label: "CHALOU MOULINEUX (91740)", value: "91740" },
  { label: "CHAMARANDE (91730)", value: "91730" },
  { label: "CHAMPCUEIL (91750)", value: "91750" },
  { label: "CHAMPLAN (91160)", value: "91160" },
  { label: "CHAMPMOTTEUX (91150)", value: "91150" },
  { label: "CHATIGNONVILLE (91410)", value: "91410" },
  { label: "CHAUFFOUR LES ETRECHY (91580)", value: "91580" },
  { label: "CHEPTAINVILLE (91630)", value: "91630" },
  { label: "CHEVANNES (91750)", value: "91750" },
  { label: "CHILLY MAZARIN (91380)", value: "91380" },
  { label: "CORBEIL ESSONNES (91100)", value: "91100" },
  { label: "CORBREUSE (91410)", value: "91410" },
  { label: "LE COUDRAY MONTCEAUX (91830)", value: "91830" },
  { label: "LE COUDRAY MONTCEAUX (91830)", value: "91830" },
  { label: "COURANCES (91490)", value: "91490" },
  { label: "COURDIMANCHE SUR ESSONNE (91720)", value: "91720" },
  { label: "COURSON MONTELOUP (91680)", value: "91680" },
  { label: "CROSNE (91560)", value: "91560" },
  { label: "DANNEMOIS (91490)", value: "91490" },
  { label: "D HUISON LONGUEVILLE (91590)", value: "91590" },
  { label: "DOURDAN (91410)", value: "91410" },
  { label: "DRAVEIL (91210)", value: "91210" },
  { label: "DRAVEIL (91210)", value: "91210" },
  { label: "ECHARCON (91540)", value: "91540" },
  { label: "EGLY (91520)", value: "91520" },
  { label: "EPINAY SOUS SENART (91860)", value: "91860" },
  { label: "EPINAY SUR ORGE (91360)", value: "91360" },
  { label: "ETAMPES (91150)", value: "91150" },
  { label: "ETIOLLES (91450)", value: "91450" },
  { label: "ETRECHY (91580)", value: "91580" },
  { label: "EVRY COURCOURONNES (91000)", value: "91000" },
  { label: "EVRY COURCOURONNES (91080)", value: "91080" },
  { label: "LA FERTE ALAIS (91590)", value: "91590" },
  { label: "FLEURY MEROGIS (91700)", value: "91700" },
  { label: "FONTAINE LA RIVIERE (91690)", value: "91690" },
  { label: "FONTENAY LES BRIIS (91640)", value: "91640" },
  { label: "FONTENAY LE VICOMTE (91540)", value: "91540" },
  { label: "LA FORET LE ROI (91410)", value: "91410" },
  { label: "LA FORET STE CROIX (91150)", value: "91150" },
  { label: "FORGES LES BAINS (91470)", value: "91470" },
  { label: "GIF SUR YVETTE (91190)", value: "91190" },
  { label: "GIRONVILLE SUR ESSONNE (91720)", value: "91720" },
  { label: "GOMETZ LA VILLE (91400)", value: "91400" },
  { label: "GOMETZ LE CHATEL (91940)", value: "91940" },
  { label: "LES GRANGES LE ROI (91410)", value: "91410" },
  { label: "GRIGNY (91350)", value: "91350" },
  { label: "GUIBEVILLE (91630)", value: "91630" },
  { label: "GUIGNEVILLE SUR ESSONNE (91590)", value: "91590" },
  { label: "GUILLERVAL (91690)", value: "91690" },
  { label: "IGNY (91430)", value: "91430" },
  { label: "IGNY (91430)", value: "91430" },
  { label: "ITTEVILLE (91760)", value: "91760" },
  { label: "JANVILLE SUR JUINE (91510)", value: "91510" },
  { label: "JANVRY (91640)", value: "91640" },
  { label: "JUVISY SUR ORGE (91260)", value: "91260" },
  { label: "LARDY (91510)", value: "91510" },
  { label: "LEUDEVILLE (91630)", value: "91630" },
  { label: "LEUVILLE SUR ORGE (91310)", value: "91310" },
  { label: "LIMOURS (91470)", value: "91470" },
  { label: "LINAS (91310)", value: "91310" },
  { label: "LISSES (91090)", value: "91090" },
  { label: "LONGJUMEAU (91160)", value: "91160" },
  { label: "LONGJUMEAU (91160)", value: "91160" },
  { label: "LONGPONT SUR ORGE (91310)", value: "91310" },
  { label: "MAISSE (91720)", value: "91720" },
  { label: "MARCOUSSIS (91460)", value: "91460" },
  { label: "MAROLLES EN BEAUCE (91150)", value: "91150" },
  { label: "MAROLLES EN HUREPOIX (91630)", value: "91630" },
  { label: "MASSY (91300)", value: "91300" },
  { label: "MAUCHAMPS (91730)", value: "91730" },
  { label: "MENNECY (91540)", value: "91540" },
  { label: "LE MEREVILLOIS (91660)", value: "91660" },
  { label: "LE MEREVILLOIS (91660)", value: "91660" },
  { label: "MEROBERT (91780)", value: "91780" },
  { label: "MESPUITS (91150)", value: "91150" },
  { label: "MILLY LA FORET (91490)", value: "91490" },
  { label: "MOIGNY SUR ECOLE (91490)", value: "91490" },
  { label: "LES MOLIERES (91470)", value: "91470" },
  { label: "MONDEVILLE (91590)", value: "91590" },
  { label: "MONNERVILLE (91930)", value: "91930" },
  { label: "MONTGERON (91230)", value: "91230" },
  { label: "MONTLHERY (91310)", value: "91310" },
  { label: "MORANGIS (91420)", value: "91420" },
  { label: "MORIGNY CHAMPIGNY (91150)", value: "91150" },
  { label: "MORSANG SUR ORGE (91390)", value: "91390" },
  { label: "MORSANG SUR SEINE (91250)", value: "91250" },
  { label: "NAINVILLE LES ROCHES (91750)", value: "91750" },
  { label: "LA NORVILLE (91290)", value: "91290" },
  { label: "NOZAY (91620)", value: "91620" },
  { label: "OLLAINVILLE (91340)", value: "91340" },
  { label: "ONCY SUR ECOLE (91490)", value: "91490" },
  { label: "ORMOY (91540)", value: "91540" },
  { label: "ORMOY LA RIVIERE (91150)", value: "91150" },
  { label: "ORSAY (91400)", value: "91400" },
  { label: "ORSAY (91400)", value: "91400" },
  { label: "ORVEAU (91590)", value: "91590" },
  { label: "PALAISEAU (91120)", value: "91120" },
  { label: "PARAY VIEILLE POSTE (91550)", value: "91550" },
  { label: "PARAY VIEILLE POSTE (94390)", value: "94390" },
  { label: "PECQUEUSE (91470)", value: "91470" },
  { label: "LE PLESSIS PATE (91220)", value: "91220" },
  { label: "PLESSIS ST BENOIST (91410)", value: "91410" },
  { label: "PRUNAY SUR ESSONNE (91720)", value: "91720" },
  { label: "PUISELET LE MARAIS (91150)", value: "91150" },
  { label: "PUSSAY (91740)", value: "91740" },
  { label: "QUINCY SOUS SENART (91480)", value: "91480" },
  { label: "RICHARVILLE (91410)", value: "91410" },
  { label: "RIS ORANGIS (91130)", value: "91130" },
  { label: "ROINVILLE (91410)", value: "91410" },
  { label: "ROINVILLIERS (91150)", value: "91150" },
  { label: "SACLAS (91690)", value: "91690" },
  { label: "SACLAY (91400)", value: "91400" },
  { label: "SACLAY (91400)", value: "91400" },
  { label: "ST AUBIN (91190)", value: "91190" },
  { label: "ST AUBIN (91190)", value: "91190" },
  { label: "ST CHERON (91530)", value: "91530" },
  { label: "ST CYR LA RIVIERE (91690)", value: "91690" },
  { label: "ST CYR SOUS DOURDAN (91410)", value: "91410" },
  { label: "ST ESCOBILLE (91410)", value: "91410" },
  { label: "STE GENEVIEVE DES BOIS (91700)", value: "91700" },
  { label: "ST GERMAIN LES ARPAJON (91180)", value: "91180" },
  { label: "ST GERMAIN LES CORBEIL (91250)", value: "91250" },
  { label: "ST HILAIRE (91780)", value: "91780" },
  { label: "ST JEAN DE BEAUREGARD (91940)", value: "91940" },
  { label: "ST JEAN DE BEAUREGARD (91940)", value: "91940" },
  { label: "ST MAURICE MONTCOURONNE (91530)", value: "91530" },
  { label: "ST MICHEL SUR ORGE (91240)", value: "91240" },
  { label: "ST PIERRE DU PERRAY (91280)", value: "91280" },
  { label: "SAINTRY SUR SEINE (91250)", value: "91250" },
  { label: "ST SULPICE DE FAVIERES (91910)", value: "91910" },
  { label: "ST VRAIN (91770)", value: "91770" },
  { label: "ST YON (91650)", value: "91650" },
  { label: "SAULX LES CHARTREUX (91160)", value: "91160" },
  { label: "SAVIGNY SUR ORGE (91600)", value: "91600" },
  { label: "SERMAISE (91530)", value: "91530" },
  { label: "SOISY SUR ECOLE (91840)", value: "91840" },
  { label: "SOISY SUR SEINE (91450)", value: "91450" },
  { label: "SOUZY LA BRICHE (91580)", value: "91580" },
  { label: "CONGERVILLE THIONVILLE (91740)", value: "91740" },
  { label: "CONGERVILLE THIONVILLE (91740)", value: "91740" },
  { label: "TIGERY (91250)", value: "91250" },
  { label: "TORFOU (91730)", value: "91730" },
  { label: "VALPUISEAUX (91720)", value: "91720" },
  { label: "LE VAL ST GERMAIN (91530)", value: "91530" },
  { label: "VARENNES JARCY (91480)", value: "91480" },
  { label: "VAUGRIGNEUSE (91640)", value: "91640" },
  { label: "VAUHALLAN (91430)", value: "91430" },
  { label: "VAYRES SUR ESSONNE (91820)", value: "91820" },
  { label: "VERRIERES LE BUISSON (91370)", value: "91370" },
  { label: "VERT LE GRAND (91810)", value: "91810" },
  { label: "VERT LE PETIT (91710)", value: "91710" },
  { label: "VIDELLES (91890)", value: "91890" },
  { label: "VIGNEUX SUR SEINE (91270)", value: "91270" },
  { label: "VILLABE (91100)", value: "91100" },
  { label: "VILLEBON SUR YVETTE (91140)", value: "91140" },
  { label: "VILLECONIN (91580)", value: "91580" },
  { label: "LA VILLE DU BOIS (91620)", value: "91620" },
  { label: "VILLEJUST (91140)", value: "91140" },
  { label: "VILLEMOISSON SUR ORGE (91360)", value: "91360" },
  { label: "VILLENEUVE SUR AUVERS (91580)", value: "91580" },
  { label: "VILLIERS LE BACLE (91190)", value: "91190" },
  { label: "VILLIERS SUR ORGE (91700)", value: "91700" },
  { label: "VIRY CHATILLON (91170)", value: "91170" },
  { label: "WISSOUS (91320)", value: "91320" },
  { label: "YERRES (91330)", value: "91330" },
  { label: "LES ULIS (91940)", value: "91940" },
  { label: "ANTONY (92160)", value: "92160" },
  { label: "ASNIERES SUR SEINE (92600)", value: "92600" },
  { label: "BAGNEUX (92220)", value: "92220" },
  { label: "BOIS COLOMBES (92270)", value: "92270" },
  { label: "BOULOGNE BILLANCOURT (92100)", value: "92100" },
  { label: "BOURG LA REINE (92340)", value: "92340" },
  { label: "CHATENAY MALABRY (92290)", value: "92290" },
  { label: "CHATILLON (92320)", value: "92320" },
  { label: "CHAVILLE (92370)", value: "92370" },
  { label: "CLAMART (92140)", value: "92140" },
  { label: "CLAMART (92140)", value: "92140" },
  { label: "CLICHY (92110)", value: "92110" },
  { label: "COLOMBES (92700)", value: "92700" },
  { label: "COURBEVOIE (92400)", value: "92400" },
  { label: "FONTENAY AUX ROSES (92260)", value: "92260" },
  { label: "GARCHES (92380)", value: "92380" },
  { label: "LA GARENNE COLOMBES (92250)", value: "92250" },
  { label: "GENNEVILLIERS (92230)", value: "92230" },
  { label: "ISSY LES MOULINEAUX (92130)", value: "92130" },
  { label: "LEVALLOIS PERRET (92300)", value: "92300" },
  { label: "MALAKOFF (92240)", value: "92240" },
  { label: "MARNES LA COQUETTE (92430)", value: "92430" },
  { label: "MEUDON (92190)", value: "92190" },
  { label: "MEUDON (92360)", value: "92360" },
  { label: "MONTROUGE (92120)", value: "92120" },
  { label: "NANTERRE (92000)", value: "92000" },
  { label: "NEUILLY SUR SEINE (92200)", value: "92200" },
  { label: "LE PLESSIS ROBINSON (92350)", value: "92350" },
  { label: "LE PLESSIS ROBINSON (92350)", value: "92350" },
  { label: "PUTEAUX (92800)", value: "92800" },
  { label: "RUEIL MALMAISON (92500)", value: "92500" },
  { label: "RUEIL MALMAISON (92500)", value: "92500" },
  { label: "ST CLOUD (92210)", value: "92210" },
  { label: "SCEAUX (92330)", value: "92330" },
  { label: "SEVRES (92310)", value: "92310" },
  { label: "SURESNES (92150)", value: "92150" },
  { label: "VANVES (92170)", value: "92170" },
  { label: "VAUCRESSON (92420)", value: "92420" },
  { label: "VILLE D AVRAY (92410)", value: "92410" },
  { label: "VILLENEUVE LA GARENNE (92390)", value: "92390" },
  { label: "AUBERVILLIERS (93300)", value: "93300" },
  { label: "AULNAY SOUS BOIS (93600)", value: "93600" },
  { label: "BAGNOLET (93170)", value: "93170" },
  { label: "LE BLANC MESNIL (93150)", value: "93150" },
  { label: "BOBIGNY (93000)", value: "93000" },
  { label: "BONDY (93140)", value: "93140" },
  { label: "LE BOURGET (93350)", value: "93350" },
  { label: "CLICHY SOUS BOIS (93390)", value: "93390" },
  { label: "COUBRON (93470)", value: "93470" },
  { label: "LA COURNEUVE (93120)", value: "93120" },
  { label: "DRANCY (93700)", value: "93700" },
  { label: "DUGNY (93440)", value: "93440" },
  { label: "EPINAY SUR SEINE (93800)", value: "93800" },
  { label: "GAGNY (93220)", value: "93220" },
  { label: "GOURNAY SUR MARNE (93460)", value: "93460" },
  { label: "L'ILE ST DENIS (93450)", value: "93450" },
  { label: "LES LILAS (93260)", value: "93260" },
  { label: "LIVRY GARGAN (93190)", value: "93190" },
  { label: "MONTFERMEIL (93370)", value: "93370" },
  { label: "MONTREUIL (93100)", value: "93100" },
  { label: "NEUILLY PLAISANCE (93360)", value: "93360" },
  { label: "NEUILLY SUR MARNE (93330)", value: "93330" },
  { label: "NOISY LE GRAND (93160)", value: "93160" },
  { label: "NOISY LE SEC (93130)", value: "93130" },
  { label: "PANTIN (93500)", value: "93500" },
  { label: "LES PAVILLONS SOUS BOIS (93320)", value: "93320" },
  { label: "PIERREFITTE SUR SEINE (93380)", value: "93380" },
  { label: "LE PRE ST GERVAIS (93310)", value: "93310" },
  { label: "LE RAINCY (93340)", value: "93340" },
  { label: "ROMAINVILLE (93230)", value: "93230" },
  { label: "ROSNY SOUS BOIS (93110)", value: "93110" },
  { label: "ST DENIS (93200)", value: "93200" },
  { label: "ST DENIS (93210)", value: "93210" },
  { label: "ST OUEN SUR SEINE (93400)", value: "93400" },
  { label: "SEVRAN (93270)", value: "93270" },
  { label: "STAINS (93240)", value: "93240" },
  { label: "TREMBLAY EN FRANCE (93290)", value: "93290" },
  { label: "VAUJOURS (93410)", value: "93410" },
  { label: "VILLEMOMBLE (93250)", value: "93250" },
  { label: "VILLEPINTE (93420)", value: "93420" },
  { label: "VILLETANEUSE (93430)", value: "93430" },
  { label: "ABLON SUR SEINE (94480)", value: "94480" },
  { label: "ALFORTVILLE (94140)", value: "94140" },
  { label: "ARCUEIL (94110)", value: "94110" },
  { label: "BOISSY ST LEGER (94470)", value: "94470" },
  { label: "BONNEUIL SUR MARNE (94380)", value: "94380" },
  { label: "BRY SUR MARNE (94360)", value: "94360" },
  { label: "CACHAN (94230)", value: "94230" },
  { label: "CHAMPIGNY SUR MARNE (94500)", value: "94500" },
  { label: "CHAMPIGNY SUR MARNE (94500)", value: "94500" },
  { label: "CHARENTON LE PONT (94220)", value: "94220" },
  { label: "CHENNEVIERES SUR MARNE (94430)", value: "94430" },
  { label: "CHEVILLY LARUE (94550)", value: "94550" },
  { label: "CHOISY LE ROI (94600)", value: "94600" },
  { label: "CRETEIL (94000)", value: "94000" },
  { label: "FONTENAY SOUS BOIS (94120)", value: "94120" },
  { label: "FRESNES (94260)", value: "94260" },
  { label: "GENTILLY (94250)", value: "94250" },
  { label: "L'HAY LES ROSES (94240)", value: "94240" },
  { label: "IVRY SUR SEINE (94200)", value: "94200" },
  { label: "JOINVILLE LE PONT (94340)", value: "94340" },
  { label: "LE KREMLIN BICETRE (94270)", value: "94270" },
  { label: "LIMEIL BREVANNES (94450)", value: "94450" },
  { label: "MAISONS ALFORT (94700)", value: "94700" },
  { label: "MANDRES LES ROSES (94520)", value: "94520" },
  { label: "MAROLLES EN BRIE (94440)", value: "94440" },
  { label: "NOGENT SUR MARNE (94130)", value: "94130" },
  { label: "NOISEAU (94880)", value: "94880" },
  { label: "ORLY (94310)", value: "94310" },
  { label: "ORMESSON SUR MARNE (94490)", value: "94490" },
  { label: "PERIGNY (94520)", value: "94520" },
  { label: "LE PERREUX SUR MARNE (94170)", value: "94170" },
  { label: "LE PLESSIS TREVISE (94420)", value: "94420" },
  { label: "LA QUEUE EN BRIE (94510)", value: "94510" },
  { label: "RUNGIS (94150)", value: "94150" },
  { label: "ST MANDE (94160)", value: "94160" },
  { label: "ST MAUR DES FOSSES (94100)", value: "94100" },
  { label: "ST MAUR DES FOSSES (94210)", value: "94210" },
  { label: "ST MAURICE (94410)", value: "94410" },
  { label: "SANTENY (94440)", value: "94440" },
  { label: "SUCY EN BRIE (94370)", value: "94370" },
  { label: "SUCY EN BRIE (94370)", value: "94370" },
  { label: "THIAIS (94320)", value: "94320" },
  { label: "VALENTON (94460)", value: "94460" },
  { label: "VALENTON (94460)", value: "94460" },
  { label: "VILLECRESNES (94440)", value: "94440" },
  { label: "VILLEJUIF (94800)", value: "94800" },
  { label: "VILLENEUVE LE ROI (94290)", value: "94290" },
  { label: "VILLENEUVE ST GEORGES (94190)", value: "94190" },
  { label: "VILLIERS SUR MARNE (94350)", value: "94350" },
  { label: "VINCENNES (94300)", value: "94300" },
  { label: "VITRY SUR SEINE (94400)", value: "94400" },
  { label: "ABLEIGES (95450)", value: "95450" },
  { label: "AINCOURT (95510)", value: "95510" },
  { label: "AMBLEVILLE (95420)", value: "95420" },
  { label: "AMBLEVILLE (95710)", value: "95710" },
  { label: "AMENUCOURT (95510)", value: "95510" },
  { label: "ANDILLY (95580)", value: "95580" },
  { label: "ARGENTEUIL (95100)", value: "95100" },
  { label: "ARNOUVILLE (95400)", value: "95400" },
  { label: "ARRONVILLE (95810)", value: "95810" },
  { label: "ARTHIES (95420)", value: "95420" },
  { label: "ASNIERES SUR OISE (95270)", value: "95270" },
  { label: "ATTAINVILLE (95570)", value: "95570" },
  { label: "AUVERS SUR OISE (95430)", value: "95430" },
  { label: "AVERNES (95450)", value: "95450" },
  { label: "AVERNES (95450)", value: "95450" },
  { label: "BAILLET EN FRANCE (95560)", value: "95560" },
  { label: "BANTHELU (95420)", value: "95420" },
  { label: "BEAUCHAMP (95250)", value: "95250" },
  { label: "BEAUMONT SUR OISE (95260)", value: "95260" },
  { label: "LE BELLAY EN VEXIN (95750)", value: "95750" },
  { label: "BELLEFONTAINE (95270)", value: "95270" },
  { label: "BELLOY EN FRANCE (95270)", value: "95270" },
  { label: "BERNES SUR OISE (95340)", value: "95340" },
  { label: "BERVILLE (95810)", value: "95810" },
  { label: "BESSANCOURT (95550)", value: "95550" },
  { label: "BETHEMONT LA FORET (95840)", value: "95840" },
  { label: "BEZONS (95870)", value: "95870" },
  { label: "BOISEMONT (95000)", value: "95000" },
  { label: "BOISSY L AILLERIE (95650)", value: "95650" },
  { label: "BONNEUIL EN FRANCE (95500)", value: "95500" },
  { label: "BOUFFEMONT (95570)", value: "95570" },
  { label: "BOUQUEVAL (95720)", value: "95720" },
  { label: "BRAY ET LU (95710)", value: "95710" },
  { label: "BREANCON (95640)", value: "95640" },
  { label: "BRIGNANCOURT (95640)", value: "95640" },
  { label: "BRUYERES SUR OISE (95820)", value: "95820" },
  { label: "BUHY (95770)", value: "95770" },
  { label: "BUTRY SUR OISE (95430)", value: "95430" },
  { label: "CERGY (95000)", value: "95000" },
  { label: "CERGY (95000)", value: "95000" },
  { label: "CERGY (95800)", value: "95800" },
  { label: "CHAMPAGNE SUR OISE (95660)", value: "95660" },
  { label: "LA CHAPELLE EN VEXIN (95420)", value: "95420" },
  { label: "CHARMONT (95420)", value: "95420" },
  { label: "CHARS (95750)", value: "95750" },
  { label: "CHATENAY EN FRANCE (95190)", value: "95190" },
  { label: "CHAUMONTEL (95270)", value: "95270" },
  { label: "CHAUSSY (95710)", value: "95710" },
  { label: "CHAUVRY (95560)", value: "95560" },
  { label: "CHENNEVIERES LES LOUVRES (95380)", value: "95380" },
  { label: "CHERENCE (95510)", value: "95510" },
  { label: "CLERY EN VEXIN (95420)", value: "95420" },
  { label: "COMMENY (95450)", value: "95450" },
  { label: "COMMENY (95450)", value: "95450" },
  { label: "CONDECOURT (95450)", value: "95450" },
  { label: "CORMEILLES EN PARISIS (95240)", value: "95240" },
  { label: "CORMEILLES EN VEXIN (95830)", value: "95830" },
  { label: "COURCELLES SUR VIOSNE (95650)", value: "95650" },
  { label: "COURDIMANCHE (95800)", value: "95800" },
  { label: "DEUIL LA BARRE (95170)", value: "95170" },
  { label: "DOMONT (95330)", value: "95330" },
  { label: "EAUBONNE (95600)", value: "95600" },
  { label: "ECOUEN (95440)", value: "95440" },
  { label: "ENGHIEN LES BAINS (95880)", value: "95880" },
  { label: "ENNERY (95300)", value: "95300" },
  { label: "EPIAIS LES LOUVRES (95380)", value: "95380" },
  { label: "EPIAIS RHUS (95810)", value: "95810" },
  { label: "EPINAY CHAMPLATREUX (95270)", value: "95270" },
  { label: "ERAGNY (95610)", value: "95610" },
  { label: "ERMONT (95120)", value: "95120" },
  { label: "EZANVILLE (95460)", value: "95460" },
  { label: "FONTENAY EN PARISIS (95190)", value: "95190" },
  { label: "FOSSES (95470)", value: "95470" },
  { label: "FRANCONVILLE (95130)", value: "95130" },
  { label: "FREMAINVILLE (95450)", value: "95450" },
  { label: "FREMECOURT (95830)", value: "95830" },
  { label: "FREPILLON (95740)", value: "95740" },
  { label: "LA FRETTE SUR SEINE (95530)", value: "95530" },
  { label: "FROUVILLE (95690)", value: "95690" },
  { label: "GARGES LES GONESSE (95140)", value: "95140" },
  { label: "GENAINVILLE (95420)", value: "95420" },
  { label: "GENICOURT (95650)", value: "95650" },
  { label: "GONESSE (95500)", value: "95500" },
  { label: "GOUSSAINVILLE (95190)", value: "95190" },
  { label: "GRISY LES PLATRES (95810)", value: "95810" },
  { label: "GROSLAY (95410)", value: "95410" },
  { label: "GUIRY EN VEXIN (95450)", value: "95450" },
  { label: "HARAVILLIERS (95640)", value: "95640" },
  { label: "HAUTE ISLE (95780)", value: "95780" },
  { label: "LE HEAULME (95640)", value: "95640" },
  { label: "HEDOUVILLE (95690)", value: "95690" },
  { label: "HERBLAY SUR SEINE (95220)", value: "95220" },
  { label: "HERBLAY SUR SEINE (95220)", value: "95220" },
  { label: "HEROUVILLE EN VEXIN (95300)", value: "95300" },
  { label: "HODENT (95420)", value: "95420" },
  { label: "L'ISLE ADAM (95290)", value: "95290" },
  { label: "JAGNY SOUS BOIS (95850)", value: "95850" },
  { label: "JOUY LE MOUTIER (95280)", value: "95280" },
  { label: "JOUY LE MOUTIER (95280)", value: "95280" },
  { label: "JOUY LE MOUTIER (95280)", value: "95280" },
  { label: "LABBEVILLE (95690)", value: "95690" },
  { label: "LASSY (95270)", value: "95270" },
  { label: "LIVILLIERS (95300)", value: "95300" },
  { label: "LONGUESSE (95450)", value: "95450" },
  { label: "LOUVRES (95380)", value: "95380" },
  { label: "LUZARCHES (95270)", value: "95270" },
  { label: "MAFFLIERS (95560)", value: "95560" },
  { label: "MAGNY EN VEXIN (95420)", value: "95420" },
  { label: "MAREIL EN FRANCE (95850)", value: "95850" },
  { label: "MARGENCY (95580)", value: "95580" },
  { label: "MARINES (95640)", value: "95640" },
  { label: "MARLY LA VILLE (95670)", value: "95670" },
  { label: "MAUDETOUR EN VEXIN (95420)", value: "95420" },
  { label: "MENOUVILLE (95810)", value: "95810" },
  { label: "MENUCOURT (95180)", value: "95180" },
  { label: "MERIEL (95630)", value: "95630" },
  { label: "MERY SUR OISE (95540)", value: "95540" },
  { label: "LE MESNIL AUBRY (95720)", value: "95720" },
  { label: "MOISSELLES (95570)", value: "95570" },
  { label: "MONTGEROULT (95650)", value: "95650" },
  { label: "MONTIGNY LES CORMEILLES (95370)", value: "95370" },
  { label: "MONTLIGNON (95680)", value: "95680" },
  { label: "MONTMAGNY (95360)", value: "95360" },
  { label: "MONTMORENCY (95160)", value: "95160" },
  { label: "MONTREUIL SUR EPTE (95770)", value: "95770" },
  { label: "MONTSOULT (95560)", value: "95560" },
  { label: "MOURS (95260)", value: "95260" },
  { label: "MOUSSY (95640)", value: "95640" },
  { label: "NERVILLE LA FORET (95590)", value: "95590" },
  { label: "NESLES LA VALLEE (95690)", value: "95690" },
  { label: "NEUILLY EN VEXIN (95640)", value: "95640" },
  { label: "NEUVILLE SUR OISE (95000)", value: "95000" },
  { label: "NOINTEL (95590)", value: "95590" },
  { label: "NOISY SUR OISE (95270)", value: "95270" },
  { label: "NUCOURT (95420)", value: "95420" },
  { label: "OMERVILLE (95420)", value: "95420" },
  { label: "OSNY (95520)", value: "95520" },
  { label: "PARMAIN (95620)", value: "95620" },
  { label: "LE PERCHAY (95450)", value: "95450" },
  { label: "PERSAN (95340)", value: "95340" },
  { label: "PIERRELAYE (95220)", value: "95220" },
  { label: "PIERRELAYE (95480)", value: "95480" },
  { label: "PISCOP (95350)", value: "95350" },
  { label: "LE PLESSIS BOUCHARD (95130)", value: "95130" },
  { label: "LE PLESSIS GASSOT (95720)", value: "95720" },
  { label: "LE PLESSIS LUZARCHES (95270)", value: "95270" },
  { label: "PONTOISE (95000)", value: "95000" },
  { label: "PONTOISE (95300)", value: "95300" },
  { label: "PRESLES (95590)", value: "95590" },
  { label: "PUISEUX EN FRANCE (95380)", value: "95380" },
  { label: "PUISEUX PONTOISE (95650)", value: "95650" },
  { label: "LA ROCHE GUYON (95780)", value: "95780" },
  { label: "ROISSY EN FRANCE (95700)", value: "95700" },
  { label: "ROISSY EN FRANCE (95700)", value: "95700" },
  { label: "RONQUEROLLES (95340)", value: "95340" },
  { label: "SAGY (95450)", value: "95450" },
  { label: "ST BRICE SOUS FORET (95350)", value: "95350" },
  { label: "ST CLAIR SUR EPTE (95770)", value: "95770" },
  { label: "ST CYR EN ARTHIES (95510)", value: "95510" },
  { label: "ST GERVAIS (95420)", value: "95420" },
  { label: "ST GRATIEN (95210)", value: "95210" },
  { label: "ST LEU LA FORET (95320)", value: "95320" },
  { label: "ST MARTIN DU TERTRE (95270)", value: "95270" },
  { label: "ST OUEN L AUMONE (95310)", value: "95310" },
  { label: "ST PRIX (95390)", value: "95390" },
  { label: "ST WITZ (95470)", value: "95470" },
  { label: "SANNOIS (95110)", value: "95110" },
  { label: "SANTEUIL (95640)", value: "95640" },
  { label: "SARCELLES (95200)", value: "95200" },
  { label: "SERAINCOURT (95450)", value: "95450" },
  { label: "SEUGY (95270)", value: "95270" },
  { label: "SOISY SOUS MONTMORENCY (95230)", value: "95230" },
  { label: "SURVILLIERS (95470)", value: "95470" },
  { label: "TAVERNY (95150)", value: "95150" },
  { label: "THEMERICOURT (95450)", value: "95450" },
  { label: "THEUVILLE (95810)", value: "95810" },
  { label: "LE THILLAY (95500)", value: "95500" },
  { label: "US (95450)", value: "95450" },
  { label: "VALLANGOUJARD (95810)", value: "95810" },
  { label: "VALMONDOIS (95760)", value: "95760" },
  { label: "VAUDHERLAND (95500)", value: "95500" },
  { label: "VAUREAL (95490)", value: "95490" },
  { label: "VEMARS (95470)", value: "95470" },
  { label: "VETHEUIL (95510)", value: "95510" },
  { label: "VIARMES (95270)", value: "95270" },
  { label: "VIENNE EN ARTHIES (95510)", value: "95510" },
  { label: "VIGNY (95450)", value: "95450" },
  { label: "VILLAINES SOUS BOIS (95570)", value: "95570" },
  { label: "VILLERON (95380)", value: "95380" },
  { label: "VILLERS EN ARTHIES (95510)", value: "95510" },
  { label: "VILLIERS ADAM (95840)", value: "95840" },
  { label: "VILLIERS LE BEL (95400)", value: "95400" },
  { label: "VILLIERS LE SEC (95720)", value: "95720" },
  { label: "WY DIT JOLI VILLAGE (95420)", value: "95420" },
  { label: "LES ABYMES (97139)", value: "97139" },
  { label: "LES ABYMES (97142)", value: "97142" },
  { label: "ANSE BERTRAND (97121)", value: "97121" },
  { label: "BAIE MAHAULT (97122)", value: "97122" },
  { label: "BAILLIF (97123)", value: "97123" },
  { label: "BASSE TERRE (97100)", value: "97100" },
  { label: "BOUILLANTE (97125)", value: "97125" },
  { label: "BOUILLANTE (97125)", value: "97125" },
  { label: "CAPESTERRE BELLE EAU (97130)", value: "97130" },
  { label: "CAPESTERRE BELLE EAU (97130)", value: "97130" },
  { label: "CAPESTERRE BELLE EAU (97130)", value: "97130" },
  { label: "CAPESTERRE DE MARIE GALANTE (97140)", value: "97140" },
  { label: "GOURBEYRE (97113)", value: "97113" },
  { label: "LA DESIRADE (97127)", value: "97127" },
  { label: "DESHAIES (97126)", value: "97126" },
  { label: "GRAND BOURG (97112)", value: "97112" },
  { label: "LE GOSIER (97190)", value: "97190" },
  { label: "GOYAVE (97128)", value: "97128" },
  { label: "LAMENTIN (97129)", value: "97129" },
  { label: "MORNE A L EAU (97111)", value: "97111" },
  { label: "LE MOULE (97160)", value: "97160" },
  { label: "PETIT BOURG (97170)", value: "97170" },
  { label: "PETIT CANAL (97131)", value: "97131" },
  { label: "PETIT CANAL (97131)", value: "97131" },
  { label: "POINTE A PITRE (97110)", value: "97110" },
  { label: "POINTE NOIRE (97116)", value: "97116" },
  { label: "PORT LOUIS (97117)", value: "97117" },
  { label: "ST CLAUDE (97120)", value: "97120" },
  { label: "ST FRANCOIS (97118)", value: "97118" },
  { label: "ST LOUIS (97134)", value: "97134" },
  { label: "STE ANNE (97180)", value: "97180" },
  { label: "STE ANNE (97180)", value: "97180" },
  { label: "STE ROSE (97115)", value: "97115" },
  { label: "TERRE DE BAS (97136)", value: "97136" },
  { label: "TERRE DE HAUT (97137)", value: "97137" },
  { label: "TROIS RIVIERES (97114)", value: "97114" },
  { label: "VIEUX FORT (97141)", value: "97141" },
  { label: "VIEUX HABITANTS (97119)", value: "97119" },
  { label: "L'AJOUPA BOUILLON (97216)", value: "97216" },
  { label: "LES ANSES D ARLET (97217)", value: "97217" },
  { label: "BASSE POINTE (97218)", value: "97218" },
  { label: "LE CARBET (97221)", value: "97221" },
  { label: "CASE PILOTE (97222)", value: "97222" },
  { label: "LE DIAMANT (97223)", value: "97223" },
  { label: "DUCOS (97224)", value: "97224" },
  { label: "FONDS ST DENIS (97250)", value: "97250" },
  { label: "FORT DE FRANCE (97200)", value: "97200" },
  { label: "FORT DE FRANCE (97234)", value: "97234" },
  { label: "LE FRANCOIS (97240)", value: "97240" },
  { label: "GRAND RIVIERE (97218)", value: "97218" },
  { label: "GROS MORNE (97213)", value: "97213" },
  { label: "LE LAMENTIN (97232)", value: "97232" },
  { label: "LE LORRAIN (97214)", value: "97214" },
  { label: "MACOUBA (97218)", value: "97218" },
  { label: "LE MARIGOT (97225)", value: "97225" },
  { label: "LE MARIN (97290)", value: "97290" },
  { label: "LE MORNE ROUGE (97260)", value: "97260" },
  { label: "LE PRECHEUR (97250)", value: "97250" },
  { label: "RIVIERE PILOTE (97211)", value: "97211" },
  { label: "RIVIERE SALEE (97215)", value: "97215" },
  { label: "RIVIERE SALEE (97215)", value: "97215" },
  { label: "LE ROBERT (97231)", value: "97231" },
  { label: "LE ROBERT (97231)", value: "97231" },
  { label: "ST ESPRIT (97270)", value: "97270" },
  { label: "ST JOSEPH (97212)", value: "97212" },
  { label: "ST PIERRE (97250)", value: "97250" },
  { label: "STE ANNE (97227)", value: "97227" },
  { label: "STE LUCE (97228)", value: "97228" },
  { label: "STE MARIE (97230)", value: "97230" },
  { label: "STE MARIE (97230)", value: "97230" },
  { label: "SCHOELCHER (97233)", value: "97233" },
  { label: "LA TRINITE (97220)", value: "97220" },
  { label: "LES TROIS ILETS (97229)", value: "97229" },
  { label: "LE VAUCLIN (97280)", value: "97280" },
  { label: "LE MORNE VERT (97226)", value: "97226" },
  { label: "BELLEFONTAINE (97222)", value: "97222" },
  { label: "REGINA (97353)", value: "97353" },
  { label: "REGINA (97390)", value: "97390" },
  { label: "CAYENNE (97300)", value: "97300" },
  { label: "IRACOUBO (97350)", value: "97350" },
  { label: "KOUROU (97310)", value: "97310" },
  { label: "MACOURIA (97355)", value: "97355" },
  { label: "MANA (97318)", value: "97318" },
  { label: "MANA (97360)", value: "97360" },
  { label: "MATOURY (97351)", value: "97351" },
  { label: "ST GEORGES (97313)", value: "97313" },
  { label: "REMIRE MONTJOLY (97354)", value: "97354" },
  { label: "ROURA (97311)", value: "97311" },
  { label: "ROURA (97352)", value: "97352" },
  { label: "ST LAURENT DU MARONI (97320)", value: "97320" },
  { label: "SINNAMARY (97315)", value: "97315" },
  { label: "MONTSINERY TONNEGRANDE (97356)", value: "97356" },
  { label: "OUANARY (97380)", value: "97380" },
  { label: "SAUL (97314)", value: "97314" },
  { label: "MARIPASOULA (97370)", value: "97370" },
  { label: "CAMOPI (97330)", value: "97330" },
  { label: "GRAND SANTI (97340)", value: "97340" },
  { label: "ST ELIE (97312)", value: "97312" },
  { label: "APATOU (97317)", value: "97317" },
  { label: "AWALA YALIMAPO (97319)", value: "97319" },
  { label: "PAPAICHTON (97316)", value: "97316" },
  { label: "LES AVIRONS (97425)", value: "97425" },
  { label: "LES AVIRONS (97425)", value: "97425" },
  { label: "BRAS PANON (97412)", value: "97412" },
  { label: "BRAS PANON (97412)", value: "97412" },
  { label: "ENTRE DEUX (97414)", value: "97414" },
  { label: "L'ETANG SALE (97427)", value: "97427" },
  { label: "L'ETANG SALE (97427)", value: "97427" },
  { label: "PETITE ILE (97429)", value: "97429" },
  { label: "PETITE ILE (97429)", value: "97429" },
  { label: "LA PLAINE DES PALMISTES (97431)", value: "97431" },
  { label: "LE PORT (97420)", value: "97420" },
  { label: "LE PORT (97420)", value: "97420" },
  { label: "LE PORT (97420)", value: "97420" },
  { label: "LA POSSESSION (97419)", value: "97419" },
  { label: "LA POSSESSION (97419)", value: "97419" },
  { label: "LA POSSESSION (97419)", value: "97419" },
  { label: "LA POSSESSION (97419)", value: "97419" },
  { label: "ST ANDRE (97440)", value: "97440" },
  { label: "ST ANDRE (97440)", value: "97440" },
  { label: "ST ANDRE (97440)", value: "97440" },
  { label: "ST BENOIT (97437)", value: "97437" },
  { label: "ST BENOIT (97470)", value: "97470" },
  { label: "ST BENOIT (97470)", value: "97470" },
  { label: "ST DENIS (97400)", value: "97400" },
  { label: "ST DENIS (97400)", value: "97400" },
  { label: "ST DENIS (97400)", value: "97400" },
  { label: "ST DENIS (97400)", value: "97400" },
  { label: "ST DENIS (97400)", value: "97400" },
  { label: "ST DENIS (97400)", value: "97400" },
  { label: "ST DENIS (97417)", value: "97417" },
  { label: "ST DENIS (97417)", value: "97417" },
  { label: "ST DENIS (97490)", value: "97490" },
  { label: "ST DENIS (97490)", value: "97490" },
  { label: "ST DENIS (97490)", value: "97490" },
  { label: "ST DENIS (97490)", value: "97490" },
  { label: "ST DENIS (97490)", value: "97490" },
  { label: "ST JOSEPH (97480)", value: "97480" },
  { label: "ST JOSEPH (97480)", value: "97480" },
  { label: "ST JOSEPH (97480)", value: "97480" },
  { label: "ST JOSEPH (97480)", value: "97480" },
  { label: "ST LEU (97416)", value: "97416" },
  { label: "ST LEU (97424)", value: "97424" },
  { label: "ST LEU (97424)", value: "97424" },
  { label: "ST LEU (97436)", value: "97436" },
  { label: "ST LOUIS (97421)", value: "97421" },
  { label: "ST LOUIS (97421)", value: "97421" },
  { label: "ST LOUIS (97450)", value: "97450" },
  { label: "ST PAUL (97411)", value: "97411" },
  { label: "ST PAUL (97422)", value: "97422" },
  { label: "ST PAUL (97423)", value: "97423" },
  { label: "ST PAUL (97434)", value: "97434" },
  { label: "ST PAUL (97434)", value: "97434" },
  { label: "ST PAUL (97435)", value: "97435" },
  { label: "ST PAUL (97435)", value: "97435" },
  { label: "ST PAUL (97435)", value: "97435" },
  { label: "ST PAUL (97460)", value: "97460" },
  { label: "ST PAUL (97460)", value: "97460" },
  { label: "ST PAUL (97460)", value: "97460" },
  { label: "ST PIERRE (97410)", value: "97410" },
  { label: "ST PIERRE (97410)", value: "97410" },
  { label: "ST PIERRE (97410)", value: "97410" },
  { label: "ST PIERRE (97410)", value: "97410" },
  { label: "ST PIERRE (97410)", value: "97410" },
  { label: "ST PIERRE (97410)", value: "97410" },
  { label: "ST PIERRE (97432)", value: "97432" },
  { label: "ST PHILIPPE (97442)", value: "97442" },
  { label: "ST PHILIPPE (97442)", value: "97442" },
  { label: "STE MARIE (97438)", value: "97438" },
  { label: "STE MARIE (97438)", value: "97438" },
  { label: "STE MARIE (97438)", value: "97438" },
  { label: "STE MARIE (97438)", value: "97438" },
  { label: "STE ROSE (97439)", value: "97439" },
  { label: "STE ROSE (97439)", value: "97439" },
  { label: "STE SUZANNE (97441)", value: "97441" },
  { label: "SALAZIE (97433)", value: "97433" },
  { label: "SALAZIE (97433)", value: "97433" },
  { label: "LE TAMPON (97418)", value: "97418" },
  { label: "LE TAMPON (97418)", value: "97418" },
  { label: "LE TAMPON (97430)", value: "97430" },
  { label: "LE TAMPON (97430)", value: "97430" },
  { label: "LE TAMPON (97430)", value: "97430" },
  { label: "LE TAMPON (97430)", value: "97430" },
  { label: "LES TROIS BASSINS (97426)", value: "97426" },
  { label: "CILAOS (97413)", value: "97413" },
  { label: "CILAOS (97413)", value: "97413" },
  { label: "MIQUELON LANGLADE (97500)", value: "97500" },
  { label: "MIQUELON LANGLADE (97500)", value: "97500" },
  { label: "ST PIERRE (97500)", value: "97500" },
  { label: "ACOUA (97630)", value: "97630" },
  { label: "BANDRABOUA (97650)", value: "97650" },
  { label: "BANDRABOUA (97650)", value: "97650" },
  { label: "BANDRELE (97660)", value: "97660" },
  { label: "BOUENI (97620)", value: "97620" },
  { label: "CHICONI (97670)", value: "97670" },
  { label: "CHIRONGUI (97620)", value: "97620" },
  { label: "DEMBENI (97660)", value: "97660" },
  { label: "DZAOUDZI (97615)", value: "97615" },
  { label: "DZAOUDZI (97615)", value: "97615" },
  { label: "KANI KELI (97625)", value: "97625" },
  { label: "KOUNGOU (97600)", value: "97600" },
  { label: "MAMOUDZOU (97600)", value: "97600" },
  { label: "MAMOUDZOU (97600)", value: "97600" },
  { label: "MAMOUDZOU (97605)", value: "97605" },
  { label: "MTSAMBORO (97630)", value: "97630" },
  { label: "M TSANGAMOUJI (97650)", value: "97650" },
  { label: "OUANGANI (97670)", value: "97670" },
  { label: "OUANGANI (97670)", value: "97670" },
  { label: "PAMANDZI (97615)", value: "97615" },
  { label: "SADA (97640)", value: "97640" },
  { label: "TSINGONI (97680)", value: "97680" },
  { label: "TSINGONI (97680)", value: "97680" },
  { label: "ST BARTHELEMY (97133)", value: "97133" },
  { label: "ST MARTIN (97150)", value: "97150" },
  { label: "ALO (98610)", value: "98610" },
  { label: "SIGAVE (98620)", value: "98620" },
  { label: "UVEA (98600)", value: "98600" },
  { label: "ANAA (98760)", value: "98760" },
  { label: "ANAA (98786)", value: "98786" },
  { label: "ANAA (98790)", value: "98790" },
  { label: "ARUE (98701)", value: "98701" },
  { label: "ARUTUA (98761)", value: "98761" },
  { label: "ARUTUA (98762)", value: "98762" },
  { label: "ARUTUA (98785)", value: "98785" },
  { label: "BORA BORA (98730)", value: "98730" },
  { label: "BORA BORA (98730)", value: "98730" },
  { label: "BORA BORA (98730)", value: "98730" },
  { label: "BORA BORA (98730)", value: "98730" },
  { label: "FAAA (98704)", value: "98704" },
  { label: "FAKARAVA (98763)", value: "98763" },
  { label: "FAKARAVA (98764)", value: "98764" },
  { label: "FAKARAVA (98787)", value: "98787" },
  { label: "FAKARAVA (98790)", value: "98790" },
  { label: "FAKARAVA (98790)", value: "98790" },
  { label: "FAKARAVA (98790)", value: "98790" },
  { label: "FANGATAU (98765)", value: "98765" },
  { label: "FANGATAU (98766)", value: "98766" },
  { label: "FATU HIVA (98740)", value: "98740" },
  { label: "FATU HIVA (98740)", value: "98740" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98755)", value: "98755" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98792)", value: "98792" },
  { label: "GAMBIER (98793)", value: "98793" },
  { label: "HAO (98767)", value: "98767" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HAO (98790)", value: "98790" },
  { label: "HIKUERU (98768)", value: "98768" },
  { label: "HIKUERU (98790)", value: "98790" },
  { label: "HIKUERU (98790)", value: "98790" },
  { label: "HIKUERU (98790)", value: "98790" },
  { label: "HIKUERU (98790)", value: "98790" },
  { label: "HITIAA O TE RA (98705)", value: "98705" },
  { label: "HITIAA O TE RA (98706)", value: "98706" },
  { label: "HITIAA O TE RA (98707)", value: "98707" },
  { label: "HITIAA O TE RA (98708)", value: "98708" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98741)", value: "98741" },
  { label: "HIVA OA (98749)", value: "98749" },
  { label: "HIVA OA (98796)", value: "98796" },
  { label: "HIVA OA (98796)", value: "98796" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98731)", value: "98731" },
  { label: "HUAHINE (98732)", value: "98732" },
  { label: "MAHINA (98709)", value: "98709" },
  { label: "MAHINA (98710)", value: "98710" },
  { label: "MAKEMO (98769)", value: "98769" },
  { label: "MAKEMO (98789)", value: "98789" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MAKEMO (98790)", value: "98790" },
  { label: "MANIHI (98770)", value: "98770" },
  { label: "MANIHI (98771)", value: "98771" },
  { label: "MAUPITI (98732)", value: "98732" },
  { label: "MAUPITI (98732)", value: "98732" },
  { label: "MAUPITI (98732)", value: "98732" },
  { label: "MAUPITI (98732)", value: "98732" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98728)", value: "98728" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "MOOREA MAIAO (98729)", value: "98729" },
  { label: "NAPUKA (98772)", value: "98772" },
  { label: "NUKU HIVA (98742)", value: "98742" },
  { label: "NUKU HIVA (98742)", value: "98742" },
  { label: "NUKU HIVA (98742)", value: "98742" },
  { label: "NUKU HIVA (98742)", value: "98742" },
  { label: "NUKU HIVA (98742)", value: "98742" },
  { label: "NUKU HIVA (98748)", value: "98748" },
  { label: "NUKU HIVA (98796)", value: "98796" },
  { label: "NUKU HIVA (98796)", value: "98796" },
  { label: "NUKU HIVA (98796)", value: "98796" },
  { label: "NUKUTAVAKE (98773)", value: "98773" },
  { label: "NUKUTAVAKE (98788)", value: "98788" },
  { label: "PAEA (98711)", value: "98711" },
  { label: "PAPARA (98712)", value: "98712" },
  { label: "PAPEETE (98714)", value: "98714" },
  { label: "PIRAE (98716)", value: "98716" },
  { label: "PUKAPUKA (98774)", value: "98774" },
  { label: "PUNAAUIA (98703)", value: "98703" },
  { label: "PUNAAUIA (98718)", value: "98718" },
  { label: "RAIVAVAE (98750)", value: "98750" },
  { label: "RAIVAVAE (98750)", value: "98750" },
  { label: "RAIVAVAE (98750)", value: "98750" },
  { label: "RAIVAVAE (98750)", value: "98750" },
  { label: "RANGIROA (98775)", value: "98775" },
  { label: "RANGIROA (98776)", value: "98776" },
  { label: "RANGIROA (98777)", value: "98777" },
  { label: "RANGIROA (98778)", value: "98778" },
  { label: "RANGIROA (98790)", value: "98790" },
  { label: "RAPA (98751)", value: "98751" },
  { label: "RAPA (98794)", value: "98794" },
  { label: "REAO (98779)", value: "98779" },
  { label: "REAO (98780)", value: "98780" },
  { label: "RIMATARA (98752)", value: "98752" },
  { label: "RIMATARA (98752)", value: "98752" },
  { label: "RIMATARA (98752)", value: "98752" },
  { label: "RIMATARA (98752)", value: "98752" },
  { label: "RIMATARA (98795)", value: "98795" },
  { label: "RURUTU (98753)", value: "98753" },
  { label: "RURUTU (98753)", value: "98753" },
  { label: "RURUTU (98753)", value: "98753" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98733)", value: "98733" },
  { label: "TAHAA (98734)", value: "98734" },
  { label: "TAHAA (98734)", value: "98734" },
  { label: "TAHAA (98734)", value: "98734" },
  { label: "TAHAA (98734)", value: "98734" },
  { label: "TAHAA (98734)", value: "98734" },
  { label: "TAHUATA (98743)", value: "98743" },
  { label: "TAHUATA (98743)", value: "98743" },
  { label: "TAHUATA (98743)", value: "98743" },
  { label: "TAHUATA (98743)", value: "98743" },
  { label: "TAIARAPU EST (98719)", value: "98719" },
  { label: "TAIARAPU EST (98719)", value: "98719" },
  { label: "TAIARAPU EST (98720)", value: "98720" },
  { label: "TAIARAPU EST (98721)", value: "98721" },
  { label: "TAIARAPU EST (98722)", value: "98722" },
  { label: "TAIARAPU OUEST (98723)", value: "98723" },
  { label: "TAIARAPU OUEST (98724)", value: "98724" },
  { label: "TAIARAPU OUEST (98725)", value: "98725" },
  { label: "TAKAROA (98781)", value: "98781" },
  { label: "TAKAROA (98782)", value: "98782" },
  { label: "TAKAROA (98790)", value: "98790" },
  { label: "TAPUTAPUATEA (98735)", value: "98735" },
  { label: "TAPUTAPUATEA (98735)", value: "98735" },
  { label: "TAPUTAPUATEA (98735)", value: "98735" },
  { label: "TAPUTAPUATEA (98735)", value: "98735" },
  { label: "TATAKOTO (98783)", value: "98783" },
  { label: "TEVA I UTA (98726)", value: "98726" },
  { label: "TEVA I UTA (98727)", value: "98727" },
  { label: "TUBUAI (98754)", value: "98754" },
  { label: "TUBUAI (98754)", value: "98754" },
  { label: "TUBUAI (98754)", value: "98754" },
  { label: "TUMARAA (98735)", value: "98735" },
  { label: "TUMARAA (98735)", value: "98735" },
  { label: "TUMARAA (98735)", value: "98735" },
  { label: "TUMARAA (98735)", value: "98735" },
  { label: "TUREIA (98784)", value: "98784" },
  { label: "UA HUKA (98744)", value: "98744" },
  { label: "UA HUKA (98747)", value: "98747" },
  { label: "UA POU (98745)", value: "98745" },
  { label: "UA POU (98745)", value: "98745" },
  { label: "UA POU (98745)", value: "98745" },
  { label: "UA POU (98745)", value: "98745" },
  { label: "UA POU (98746)", value: "98746" },
  { label: "UA POU (98746)", value: "98746" },
  { label: "UTUROA (98735)", value: "98735" },
  { label: "BELEP (98811)", value: "98811" },
  { label: "BOULOUPARI (98812)", value: "98812" },
  { label: "BOURAIL (98870)", value: "98870" },
  { label: "CANALA (98813)", value: "98813" },
  { label: "DUMBEA (98830)", value: "98830" },
  { label: "DUMBEA (98835)", value: "98835" },
  { label: "DUMBEA (98836)", value: "98836" },
  { label: "DUMBEA (98837)", value: "98837" },
  { label: "DUMBEA (98839)", value: "98839" },
  { label: "FARINO (98881)", value: "98881" },
  { label: "HIENGHENE (98815)", value: "98815" },
  { label: "HOUAILOU (98816)", value: "98816" },
  { label: "HOUAILOU (98838)", value: "98838" },
  { label: "L'ILE DES PINS (98832)", value: "98832" },
  { label: "KAALA GOMEN (98817)", value: "98817" },
  { label: "KONE (98859)", value: "98859" },
  { label: "KONE (98860)", value: "98860" },
  { label: "KOUMAC (98850)", value: "98850" },
  { label: "LA FOA (98880)", value: "98880" },
  { label: "LIFOU (98820)", value: "98820" },
  { label: "LIFOU (98884)", value: "98884" },
  { label: "LIFOU (98885)", value: "98885" },
  { label: "MARE (98828)", value: "98828" },
  { label: "MARE (98878)", value: "98878" },
  { label: "MOINDOU (98819)", value: "98819" },
  { label: "LE MONT DORE (98809)", value: "98809" },
  { label: "LE MONT DORE (98810)", value: "98810" },
  { label: "LE MONT DORE (98874)", value: "98874" },
  { label: "LE MONT DORE (98875)", value: "98875" },
  { label: "LE MONT DORE (98876)", value: "98876" },
  { label: "NOUMEA (98800)", value: "98800" },
  { label: "OUEGOA (98821)", value: "98821" },
  { label: "OUVEA (98814)", value: "98814" },
  { label: "PAITA (98840)", value: "98840" },
  { label: "PAITA (98889)", value: "98889" },
  { label: "PAITA (98890)", value: "98890" },
  { label: "POINDIMIE (98822)", value: "98822" },
  { label: "PONERIHOUEN (98823)", value: "98823" },
  { label: "POUEBO (98824)", value: "98824" },
  { label: "POUEMBOUT (98825)", value: "98825" },
  { label: "POUM (98826)", value: "98826" },
  { label: "POYA (98827)", value: "98827" },
  { label: "POYA (98877)", value: "98877" },
  { label: "SARRAMEA (98882)", value: "98882" },
  { label: "THIO (98829)", value: "98829" },
  { label: "TOUHO (98831)", value: "98831" },
  { label: "VOH (98833)", value: "98833" },
  { label: "VOH (98883)", value: "98883" },
  { label: "YATE (98834)", value: "98834" },
  { label: "KOUAOUA (98818)", value: "98818" },
  { label: "ILE DE CLIPPERTON (98799)", value: "98799" },
  { label: "MONACO (98000)", value: "98000" },
];
